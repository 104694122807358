import { ArrowForward } from "@clockwise/icons";
import { Interval } from "luxon";
import React from "react";

export const OriginalTimeInfo = ({ originalInterval }: { originalInterval: Interval }) => {
  const timeStart = originalInterval.start.toFormat("h:mma");
  const timeEnd = originalInterval.end.toFormat("h:mma");
  const date = originalInterval.start.toFormat("ccc, MMMM dd yyyy");

  return (
    <div className="cw-flex cw-items-center cw-justify-between cw-flex-1 cw-mt-2 ">
      <div className="cw-flex cw-items-center cw-gap-5 cw-body-sm cw-text-default-disabled">
        <span className="cw-line-through">{date}</span>
        <div className="cw-flex cw-items-center cw-gap-1">
          <span className="cw-line-through">{timeStart}</span>
          <ArrowForward className="cw-w-4 cw-h-4 cw-text-muted-disabled" aria-hidden />
          <span className="cw-line-through">{timeEnd}</span>
        </div>
      </div>
      <span className="cw-body-sm cw-text-12 cw-text-default-disabled">Original</span>
    </div>
  );
};
