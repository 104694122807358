import { fg_brand } from "@clockwise/design-system/tokens";
import classNames from "classnames";
import { CheckCircle } from "phosphor-react";
import React from "react";
import { AttendeeAvatar } from "../AttendeeAvatar";
import { Person } from "./types";

interface PersonOptionProps<T extends Person> {
  person: T;
  isSelected: boolean;
  isActive: boolean;
  onSelect: (person: T) => void;
  onRemove?: (person: T) => void;
}

export const PersonProfileOption = <T extends Person>({
  person,
  isSelected,
  isActive,
  onSelect,
  onRemove,
}: PersonOptionProps<T>) => {
  const { name, email, externalImageUrl } = person;
  const fullName = name.givenName || name.familyName ? `${name.givenName} ${name.familyName}` : "";

  return (
    <div
      onClick={() => {
        if (isSelected) {
          onRemove?.(person);
        } else {
          onSelect(person);
        }
      }}
      className={classNames(
        "cw-flex cw-group cw-justify-between cw-items-center cw-rounded-lg cw-p-1 cw-m-1 cw-cursor-pointer hover:cw-bg-neutral-inset",
        { "cw-bg-neutral-inset": isActive },
      )}
    >
      <div className="cw-flex cw-gap-3 cw-items-center cw-truncate">
        <AttendeeAvatar
          calendarId={email}
          profile={{ givenName: name.givenName, familyName: name.familyName, externalImageUrl }}
          size="large"
        />
        <div className="cw-body-sm cw-font-medium">
          <div>{fullName || email}</div>
          {fullName && <div className="cw-text-11 cw-text-muted cw-font-normal">{email}</div>}
        </div>
      </div>
      {isSelected && (
        <CheckCircle size={16} weight="fill" color={fg_brand} className="cw-shrink-0" />
      )}
    </div>
  );
};
