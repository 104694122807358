import {
  QuestionType,
  ResponseType,
  SaveUserSurveyResponseWrapperMutationInput,
  UserSurveyResponse,
} from "@clockwise/schema";

export const TYPE_OF_WORK_QUESTION = `What type of work do you do?`;
export const CURRENT_POSITION_QUESTION = `What's your current position?`;
export const TIME_MANAGEMENT_GOAL_QUESTION = `What's your main time management goal?`;

export const TYPE_OF_WORK_RESPONSES = [
  {
    responseType: ResponseType.Administrative,
    responseText: "Administrative",
  },
  {
    responseType: ResponseType.AccountingOrFinance,
    responseText: "Accounting/Finance",
  },
  {
    responseType: ResponseType.BusinessOwner,
    responseText: "Business Owner",
  },
  {
    responseType: ResponseType.CustomerSupport,
    responseText: "Customer Support",
  },
  {
    responseType: ResponseType.DesignOrUxResearch,
    responseText: "Design/UX Research",
  },
  {
    responseType: ResponseType.SoftwareEngineering,
    responseText: "Engineering (Software)",
  },
  {
    responseType: ResponseType.It,
    responseText: "IT",
  },
  {
    responseType: ResponseType.Legal,
    responseText: "Legal",
  },
  {
    responseType: ResponseType.Marketing,
    responseText: "Marketing",
  },
  {
    responseType: ResponseType.Operations,
    responseText: "Operations",
  },
  {
    responseType: ResponseType.PeopleOpsOrHr,
    responseText: "People Operations/HR",
  },
  {
    responseType: ResponseType.ProductManagement,
    responseText: "Product Management",
  },
  {
    responseType: ResponseType.ProgramOrProjectManagement,
    responseText: "Program/Project Management",
  },
  {
    responseType: ResponseType.Recruiting,
    responseText: "Recruiting",
  },
  {
    responseType: ResponseType.SalesOrBusinessDevelopment,
    responseText: "Sales/Business Development",
  },
  {
    responseType: ResponseType.Other,
    responseText: "Other",
  },
];

export const CURRENT_POSITION_RESPONSES = [
  {
    responseType: ResponseType.ExecutiveOrBusinessLeader,
    responseText: "C-level executive or business owner",
  },
  {
    responseType: ResponseType.VpOrDivisionLeader,
    responseText: "VP / division leader",
  },
  {
    responseType: ResponseType.DirectorOrDepartmentLeader,
    responseText: "Director / department leader",
  },
  {
    responseType: ResponseType.Manager,
    responseText: "Manager",
  },
  {
    responseType: ResponseType.IndividualContributor,
    responseText: "Individual contributor",
  },
  {
    responseType: ResponseType.Other,
    responseText: "Other",
  },
];

export const TIME_MANAGEMENT_GOAL_RESPONSES = [
  {
    responseType: ResponseType.CreateMoreTimeForFocusedWork,
    responseText: "Create more time for focused work",
  },
  {
    responseType: ResponseType.ResolveConflictsAutomatically,
    responseText: "Resolve conflicts automatically",
  },
  {
    responseType: ResponseType.ScheduleExternalMeetingsSeamlessly,
    responseText: "Schedule external meetings seamlessly",
  },
  {
    responseType: ResponseType.ScheduleEasilyWithMyTeam,
    responseText: "Schedule easily with my team",
  },
  {
    responseType: ResponseType.ReserveTimeForPersonalObligations,
    responseText: "Reserve time for personal obligations",
  },
  {
    responseType: ResponseType.JustLookingAround,
    responseText: "I'm just looking around",
  },
  {
    responseType: ResponseType.Other,
    responseText: "Other",
  },
];

export const getDefaultCurrentPosition = () => {
  return {
    questionType: QuestionType.WhatIsYourCurrentPosition,
    questionText: CURRENT_POSITION_QUESTION,
    responseType: null,
    responseText: null,
  };
};

export const getDefaultTeamType = () => {
  return {
    questionType: QuestionType.WhatTypeOfWorkDoYouDo,
    questionText: TYPE_OF_WORK_QUESTION,
    responseType: null,
    responseText: null,
  };
};

export const getDefaultTimeManagementGoal = () => {
  return {
    questionType: QuestionType.WhatsYourTimeManagementGoal,
    questionText: TIME_MANAGEMENT_GOAL_QUESTION,
    responseType: null,
    responseText: null,
  };
};

export type OnboardingGetToKnowYouStepQuestion =
  | {
      questionType: string;
      questionText: string;
      responseType?: string | null;
      responseText?: string | null;
    }
  | UserSurveyResponse;

export const getDefaultInput = (orgRelayId: string) => {
  return {
    userSurveyResponseWrapper: {
      id: orgRelayId,
      userSurveyResponses: [
        getDefaultTeamType(),
        getDefaultCurrentPosition(),
        getDefaultTimeManagementGoal(),
      ],
    },
  } as SaveUserSurveyResponseWrapperMutationInput;
};
