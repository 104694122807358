import { ApolloCache, useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";
import {
  ToggleTravelTimeDocument,
  ToggleTravelTimeMutation,
} from "../graphql/__generated__/ToggleTravelTime.generated";

import { logger } from "@clockwise/client-commons/src/util/logger";
import { isEqual } from "lodash";
import { TravelTimeSettings } from "../types";

export const useTravelTimeToggle = (
  orgId: string,
  travelTimeSettings: TravelTimeSettings,
  {
    onCompleted,
    onError,
    onUpdate,
  }: {
    onCompleted?: () => void;
    onError?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUpdate?: (cache: ApolloCache<any>) => void;
  },
) => {
  const [toggleTravelTime, { loading, error }] = useMutation(ToggleTravelTimeDocument);

  const onErrorHandler = useMemo(() => onError, [onError]);
  const onUpdateHandler = useMemo(() => onUpdate, [onUpdate]);

  const onCompleteHandler = useCallback(
    (expectedData: ToggleTravelTimeMutation) => (data: ToggleTravelTimeMutation) => {
      if (!isEqual(expectedData.ToggleTravelTime, data.ToggleTravelTime)) {
        // @scott - cleanup as part of AI-2044
        // logging to sentry to confrim error severtiy
        logger.error("Travel Time Toggle: Optimistic update does not match server response.", {
          expectedData,
          data,
        });

        onError?.();
      } else {
        onCompleted?.();
      }
    },
    [onCompleted, onError],
  );

  const toggle = useCallback(
    (enabled: boolean) => {
      const variables = { orgId, enabled };

      const optimisticResponse: ToggleTravelTimeMutation = {
        ToggleTravelTime: {
          travelTimeGoal: {
            id: travelTimeSettings.id,
            enabled,
            userAddress: travelTimeSettings.address
              ? {
                  __typename: "Address",
                  rawAddress: travelTimeSettings.address,
                }
              : {
                  __typename: "NoAddress",
                },
            remoteHoldSettings: {
              scheduleAs: travelTimeSettings.scheduleAs,
              notificationStrategy: travelTimeSettings.notificationStrategy,
              __typename: "RemoteSmartHoldSettings",
            },
            __typename: "TravelTimeGoals",
          },
          __typename: "ToggleTravelTimeResponse",
        },
        __typename: "Mutation",
      };

      return toggleTravelTime({
        variables,
        optimisticResponse,
        onCompleted: onCompleteHandler(optimisticResponse),
        onError: onErrorHandler,
        update: onUpdateHandler,
      });
    },
    [
      onCompleteHandler,
      onErrorHandler,
      onUpdateHandler,
      orgId,
      toggleTravelTime,
      travelTimeSettings,
    ],
  );

  return { toggle, loading, error };
};

export default useTravelTimeToggle;
