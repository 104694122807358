import { DateTime } from "luxon";
import pluralize from "pluralize";
import React from "react";
import { formatNumberAsAbbreviation } from "../overview.util";

const HeroStatUsers = ({ count, date }: { count: number; date: Date }) => {
  const hasCount = !!count;
  const subtitle = count ? `As of ${DateTime.fromJSDate(date).toFormat("MMM d")}` : "None yet";
  const formattedUserCount = hasCount ? formatNumberAsAbbreviation(count) : "-";

  return (
    <div className="cw-space-y-1 cw-w-1/2 lg:cw-w-1/4">
      <div
        className="cw-heading-3xl"
        style={{ fontVariantNumeric: "proportional-nums lining-nums" }}
      >
        {formattedUserCount}
      </div>
      <div className="cw-heading-caption cw-uppercase">{pluralize("User", count)}</div>
      <div className="cw-body-base cw-text-subtle">{subtitle}</div>
    </div>
  );
};

export default HeroStatUsers;
