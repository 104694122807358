import { TrackingEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { EventColorCategory } from "@clockwise/client-commons/src/util/event-category-coloring";
import { createTagForMutation, Tags } from "@clockwise/client-commons/src/util/event-tag";
import { isNotNull } from "@clockwise/client-commons/src/util/null";
import { Divider } from "@clockwise/design-system";
import { RepeatingEventSaveOption } from "@clockwise/schema";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { Interval } from "luxon";
import React, { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useToggle } from "usehooks-ts";
import { clearAllSelected as clearAllSelectedCalendarsInMultiCalendar } from "../../state/actions/multi-calendar.actions";
import { shouldUpdateThisAndFutureEvents } from "../chat/ai-chat/utils/getRecurrenceToSave";
import useEditExistingEvent from "../web-app-calendar/hooks/useEditExistingEvent";
import {
  getBodyForSaveChanges,
  getDisabledSaveOptions,
} from "../web-app-calendar/hooks/useEditExistingEvent.util";
import { CategoryOption, EventDetails } from "../web-app-calendar/hooks/useEventDetails";
import { EnableOrDisableTeamSyncDocument } from "./__generated__/EnableOrDisableTeamSync.v2.generated";
import { UserTeamNamesDocument } from "./__generated__/UserTeamNames.v2.generated";
import { EventVisibility } from "./atoms/SelectEventVisibility";
import { Transparency } from "./atoms/SelectTransparency";
import { WarningMessage } from "./atoms/WarningMessage";
import { CardWrapper } from "./CardWrapper";
import { useEventAttendees } from "./hooks/EventAttendeesContext";
import { useReadEventTimeDetails } from "./hooks/EventCardContext";
import { AllDay } from "./molecules/ECAllDay";
import { ECCalendarInfo } from "./molecules/ECCalendarInfo";
import { ECCategorySelector as CategorySelector } from "./molecules/ECCategorySelect";
import { ECFooter } from "./molecules/ECFooter";
import { ECRecurrence } from "./molecules/ECRecurrence";
import { ECTeamSyncSection as TeamSyncSection } from "./molecules/ECTeamSyncSection";
import { ECTitle as Title } from "./molecules/ECTitle";
import { ECVisibilitySettings } from "./molecules/ECVisibilitySettings";
import { Time } from "./molecules/Time";
import { EventPermissionsInfo } from "./types";
import { EventCardWarning } from "./utils/getEventCardWarning";
import { isWaitingOnAsync } from "./utils/getUserEventPermissions.util";

type Props = Pick<EventDetails, "title" | "category" | "attendeesOmitted" | "eventPermissions"> & {
  colorSettings?: CategoryOption[];
  eventCalendarId: string;
  id: string;
  isOwnEvent: boolean;
  isRecurring: boolean;
  isSynced: boolean;
  permissions: EventPermissionsInfo;
  recurrenceRule: RecurrenceRule | null;
  visibility?: EventVisibility;
  transparency?: Transparency;
};

export const OutOfOfficeCard = ({
  attendeesOmitted,
  category: initCategory = EventColorCategory.OutOfOffice,
  colorSettings,
  eventCalendarId,
  eventPermissions,
  id = "",
  isOwnEvent,
  isRecurring,
  isSynced: initIsSynced,
  permissions,
  recurrenceRule: initRecurrenceRule,
  title: initTitle = "Out of Office",
  visibility,
  transparency,
}: Props) => {
  const track = useTracking();
  const dispatch = useDispatch();
  const updateActiveEvent = useUpdateActiveEvent();
  const { attendees } = useEventAttendees();
  const { startTime, endTime, timesHaveChanged, isAllDay, timeError } = useReadEventTimeDetails();

  const [syncing, toggleSyncing] = useToggle(initIsSynced);
  const [title, setTitle] = useState<string>(initTitle);
  const [category, setCategory] = useState<EventColorCategory>(initCategory);
  const [recurrenceRule, setRecurrenceRule] = useState<RecurrenceRule | null>(initRecurrenceRule);

  const { data: userTeamData } = useGatewayQuery(UserTeamNamesDocument, {
    skip: !isOwnEvent,
  });
  const teams =
    userTeamData?.currentUser?.teams.filter(isNotNull).filter((team) => team.manageTeamCalendar) ??
    [];

  const closeEventCard = () => {
    updateActiveEvent(null);
  };

  const { onSaveEventChanges, savingChanges, onDeleteEvent, deletingEvent } = useEditExistingEvent(
    id,
    eventCalendarId,
    isRecurring,
    {
      onEditCompleted: () => {
        track(TrackingEvents.EVENT.UPDATED, {
          externalEventId: id,
          calendarId: eventCalendarId,
          isOOO: true,
        });
        toast.success("Event saved");
        closeEventCard();
      },
      onEditError: () => toast.error("Event failed to save"),
      onDeletionCompleted: () => {
        dispatch(clearAllSelectedCalendarsInMultiCalendar());
        if (permissions.canDelete) {
          track(TrackingEvents.EVENT.DELETED, {
            externalEventId: id,
            calendarId: eventCalendarId,
            isOOO: true,
          });
          toast.success("Event deleted.");
        } else {
          track(TrackingEvents.EVENT.REMOVED, {
            externalEventId: id,
            calendarId: eventCalendarId,
            isOOO: true,
          });
          toast.success("Event removed.");
        }
        closeEventCard();
      },
      onDeletionError: (err) => {
        toast.error("Failed to update event.");
        logger.error("unable to delete event", {
          error: err || "unable to delete event",
          externalEventId: id,
        });
      },
    },
  );

  const [updateTeamSync] = useGatewayMutation(EnableOrDisableTeamSyncDocument, {
    onCompleted: () => {
      toast.success("Event sync settings updated");
      track(TrackingEvents.EVENT.TEAM_TOGGLE_SYNC);
      closeEventCard();
    },
    onError: () => toast.error("Sorry, event sync settings not updated"),
  });

  const handleEventNameChange = (evnt: ChangeEvent<HTMLInputElement>) =>
    setTitle(() => evnt.target.value);

  const handleChangeCategory = (newCategory: EventColorCategory) => {
    setCategory(newCategory);
  };

  const handleSubmit = (saveOption: RepeatingEventSaveOption) => {
    void asyncSubmit(saveOption);
  };

  const asyncSubmit = async (saveOption: RepeatingEventSaveOption) => {
    if (!!startTime && !!endTime) {
      if (
        changesMap.category ||
        changesMap.eventRecurrence ||
        changesMap.times ||
        changesMap.title
      ) {
        await onSaveEventChanges(
          getBodyForSaveChanges(
            {
              recurrence: changesMap.eventRecurrence
                ? {
                    recurrenceRule,
                    eventStartTime: startTime,
                    timeZone: getRenderTimeZone(),
                  }
                : null,
              sendNotifications: false,
              time: Interval.fromDateTimes(startTime, endTime).toISO(),
              title,
              repeatingEventSaveOption: shouldUpdateThisAndFutureEvents(
                saveOption,
                initRecurrenceRule,
                recurrenceRule,
              ),
              category: createTagForMutation(Tags.EventColoringCategory, category),
              isAllDay: isAllDay,
            },
            eventPermissions,
          ),
        );
      }
      if (changesMap.teamSync) {
        await updateTeamSync({
          variables: {
            externalEventId: id,
            enabled: syncing,
          },
        });
      }
    }
  };

  const changesMap = {
    category: category !== initCategory,
    eventRecurrence: recurrenceRule?.toString() !== initRecurrenceRule?.toString(),
    teamSync: syncing !== initIsSynced,
    times: timesHaveChanged,
    title: title !== initTitle,
  };

  const changesCount = getChangesCount(changesMap);

  const userIsOnlyAttendee = attendees.length <= 1 && !attendeesOmitted;

  const disabledRecurrenceSaveOptions = getDisabledSaveOptions({
    hasFlexBeenModified: false,
    hasRecurrenceBeenModified: changesMap.eventRecurrence,
    isDescriptionOmitted: false,
    canModifyFuture: permissions.canModifyFuture,
    canModifyAll: permissions.canModifyAll,
  });

  return (
    <CardWrapper
      changesCount={changesCount}
      header={<div className="cw-font-semibold">Event</div>}
      onClose={closeEventCard}
      footer={
        <ECFooter
          canDelete={permissions.canDelete}
          canModify={permissions.canModify}
          canRemove={permissions.canRemove}
          disabled={isWaitingOnAsync(savingChanges, deletingEvent) || !!timeError}
          disabledRecurrenceSaveOptions={disabledRecurrenceSaveOptions}
          onSubmit={handleSubmit}
          showDropdownOnSave={
            !!recurrenceRule && (permissions.canModifyAll || permissions.canModifyFuture)
          }
          shouldSendUpdatesAfterSave={!userIsOnlyAttendee}
          updatesPresent={changesCount > 0}
          onUpdateCancel={onDeleteEvent}
        />
      }
    >
      <div>
        {!isOwnEvent && <WarningMessage type={EventCardWarning.NotYourEvent} />}
        <Title eventName={title} onEventNameChange={handleEventNameChange} readOnly={!isOwnEvent} />
      </div>
      <Time readOnly={!isOwnEvent} timesHaveChanged={timesHaveChanged} />
      <AllDay readOnly={!isOwnEvent} />
      <CategorySelector
        allColorSettings={colorSettings}
        currentCategory={category}
        onCategoryChange={handleChangeCategory}
        readOnly={!isOwnEvent}
      />
      <ECRecurrence
        readOnly={!isOwnEvent}
        recurrenceRule={recurrenceRule}
        date={startTime?.toISODate()}
        onSelect={setRecurrenceRule}
        showChanges={changesMap.eventRecurrence}
        originalRecurrence={initRecurrenceRule}
      />
      <ECCalendarInfo calendarId={eventCalendarId} />
      {(transparency || visibility) && (
        <ECVisibilitySettings
          // Dev note: existing events display this info as readonly as per A-498
          // When rolling out the new edit event experience, make these editable
          readonly
          visibility={visibility}
          transparency={transparency}
        />
      )}
      {isOwnEvent && (
        <>
          <Divider spacing="sm" inset />
          <TeamSyncSection syncing={syncing} teams={teams} onChange={toggleSyncing} />
        </>
      )}
    </CardWrapper>
  );
};

const getChangesCount = (obj: { [key: string]: boolean }) => {
  let count = 0;
  for (const k in obj) {
    if (obj[k]) {
      count++;
    }
  }
  return count;
};
