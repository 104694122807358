import { Button, Modal } from "@clockwise/design-system";
import React from "react";
import ConfirmationHeader from "./assets/PMF_survey_confirmation_header.jpg";

export const ConfirmationModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size={400}
      centered={true}
      radius={11}
      padding="lg"
      withCloseButton={false}
      transitionProps={{ duration: 300, transition: "fade" }}
    >
      <div className="cw-flex cw-flex-col cw-gap-y-2 cw-font-body">
        <img
          src={ConfirmationHeader}
          className="cw-w-full cw-rounded-lg cw-border cw-border-solid cw-border-subtle"
        />
        <div className="cw-flex cw-flex-col cw-gap-y-2 cw-my-2">
          <div className="cw-text-16 cw-font-semibold"> Your voice is important to us.</div>
          <div className="cw-text-13 cw-leading-5">
            Thank you for taking the time to leave feedback. Each response helps shape the future of
            Clockwise.
          </div>
        </div>
        <div className="cw-flex cw-justify-end cw-mt-1">
          <Button sentiment="neutral" variant="outlined" onClick={onClose} size="small">
            Dismiss
          </Button>
        </div>
      </div>
    </Modal>
  );
};
