import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import { getRenderTimeZone } from "../../../util/time-zone.util";

export const MiniCalendarDate = ({
  dateTime,
  onClick,
}: {
  dateTime: DateTime;
  onClick: (date: DateTime) => void;
}) => {
  const zone = getRenderTimeZone();
  const isToday = dateTime.hasSame(DateTime.local({ zone }), "day");
  const isPast = dateTime.startOf("day") < DateTime.now().startOf("day");

  const renderDay = dateTime.toLocaleString({ weekday: "short" });
  const renderDate = dateTime.toLocaleString({ day: "numeric" });

  const handleClick = () => onClick(dateTime);

  return (
    <div role="button" tabIndex={0} onClick={handleClick}>
      <div
        className={classNames(
          "cw-flex cw-flex-col cw-items-center",
          "cw-w-full cw-rounded",
          "hover:cw-bg-default-hover",
          "cw-text-center",
          isPast ? "cw-text-subtle" : "cw-text-default",
          isToday ? "cw-font-bold" : "",
          "cw-cursor-pointer",
          "cw-py-1 cw-px-2",
        )}
      >
        <span
          className={classNames(
            "cw-rounded-md",
            isToday ? "cw-bg-destructive-emphasis cw-text-onEmphasis cw-px-1" : "",
            "cw-font-bold cw-body-base",
          )}
        >
          {renderDate}
        </span>
        <span className="cw-body-small">{renderDay}</span>
      </div>
    </div>
  );
};
