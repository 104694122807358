import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { useMutation } from "@apollo/client";
import { Button, TextArea } from "@clockwise/design-system";
import { SaveUserSurveyResponseWrapperMutationInput } from "@clockwise/schema";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { ChangeEvent, useRef, useState } from "react";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { ButtonGroup, StepCounter, StepLayout } from "../../shared-components";
import { setError } from "../../utils/setError";
import { GetToKnowYouSlide } from "./GetToKnowYouSlide";
import { getDefaultInput } from "./GetToKnowYouStep.util";
import { OnboardingSaveUserSurveyResponseMutationDocument } from "./__generated__/OnboardingSaveUserSurveyResponse.generated";

export const GetToKnowYouStep = ({
  orgId,
  canSkipOnboarding,
}: {
  orgId: string;
  canSkipOnboarding: boolean;
}) => {
  const track = useTracking();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [saveSurveyResponses] = useMutation(OnboardingSaveUserSurveyResponseMutationDocument);
  const [disableButtons, setDisableButtons] = useState(false);

  const [isOnOnboardingScheduleQuestionFlag] = useFeatureFlag("onboarding-schedule-question");
  const {
    goForward,
    goBack,
    shouldShowSecondaryButton,

    skipOnboarding,
  } = useWebOnboardingSteps();

  const [
    userSurveyInput,
    setUserSurveyInput,
  ] = useState<SaveUserSurveyResponseWrapperMutationInput>(getDefaultInput(orgId));

  const onChangeResponse = (
    input: SaveUserSurveyResponseWrapperMutationInput,
    isButtonDisabled: boolean,
  ) => {
    setUserSurveyInput(input);
    setDisableButtons(isButtonDisabled);
  };

  const sendAnalyticsEvent = () => {
    if (!isOnOnboardingScheduleQuestionFlag || !textAreaRef.current) return;
    const inputValue = textAreaRef.current?.value;

    // Send the input value to the analytics event if it's not empty
    if (!isEmpty(inputValue?.trim())) {
      track(TrackingEvents.ONBOARDING.IDEAL_SCHEDULE_INPUT_VALUE, { text: inputValue });
    } else {
      track(TrackingEvents.ONBOARDING.IDEAL_SCHEDULE_INPUT_VALUE, { text: null });
    }
  };

  const saveAndGoForward = () => {
    setDisableButtons(true);

    sendAnalyticsEvent();

    let input = { ...userSurveyInput };

    if (isOnOnboardingScheduleQuestionFlag) {
      // Remove null inputs if on experiment
      const surveyResponses = input.userSurveyResponseWrapper.userSurveyResponses.filter(
        (response) => response.responseType !== null,
      );
      input = {
        ...input,
        userSurveyResponseWrapper: {
          ...input.userSurveyResponseWrapper,
          userSurveyResponses: [...surveyResponses],
        },
      };
    }

    void saveSurveyResponses({
      variables: { input },
      onCompleted: () => {
        setDisableButtons(false);
        goForward();
      },
      onError: (error) => {
        setDisableButtons(false);
        setError({
          message: "failed to save user survey results",
          error: error,
          showUserMessage: true,
        });
      },
    });
  };

  const SkipButton = () => {
    return (
      <div className="cw-mt-8">
        <Button
          variant="outlined"
          sentiment="neutral"
          onClick={() => skipOnboarding()}
          cw-id="skip-web-onboarding"
        >
          Skip onboarding
        </Button>
      </div>
    );
  };
  return (
    <StepLayout>
      <StepCounter />
      <GetToKnowYouSlide orgId={orgId} onChange={onChangeResponse} />
      {isOnOnboardingScheduleQuestionFlag && <IdealScheduleInput textAreaRef={textAreaRef} />}
      <ButtonGroup
        shouldShowSecondary={shouldShowSecondaryButton}
        onClickPrimary={saveAndGoForward}
        onClickSecondary={goBack}
        disabled={disableButtons}
      />
      {canSkipOnboarding && <SkipButton />}
    </StepLayout>
  );
};

const IdealScheduleInput = ({
  textAreaRef,
}: {
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
}) => {
  const [inputLength, setInputLength] = useState(0);

  const onChangeInputValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputLength(e.target.value.length);
  };

  const atMaxCharLimit = inputLength === 1024;
  return (
    <div className="cw-flex cw-flex-col cw-w-full md:cw-w-[516px] cw-px-1 md:cw-px-2 -cw-mt-4">
      <div className="cw-flex cw-flex-row cw-justify-between cw-items-center">
        <div className="cw-block cw-text-12 cw-font-body cw-font-semibold cw-text-default cw-mb-1">
          In a few words, explain what your ideal schedule looks like:
        </div>
        <div
          className={classNames("cw-text-11", {
            "cw-text-destructive": atMaxCharLimit,
            "cw-text-subtle": !atMaxCharLimit,
          })}
        >{`${inputLength}/1024`}</div>
      </div>
      <TextArea
        onChange={onChangeInputValue}
        maxLength={1024}
        minRows={5}
        fullWidth
        ref={textAreaRef}
        placeholder="Daily, my first meeting wouldn't start until 9:30 am. I would have floating lunch between 11:45 am and 2 PM. I would stack meetings from 2:30-4:30 and wrap up my day through 5:30-6 PM. I'd prefer no meetings on Monday and a 2 hour project hour on Wednesday uninterrupted."
      />
    </div>
  );
};
