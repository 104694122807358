import { Day } from "@clockwise/schema";
import React from "react";
import { DayOnOffMap } from "../working-hours.types";
import { DayCheckbox } from "./day-checkbox";

export interface DayCheckboxesProps {
  dayOnOffMap: DayOnOffMap;
  disabledDays?: DayOnOffMap; // Allows for certain days to render with a reduced opacity and no click target.
  isValid: boolean;
  invalidText?: string;
  planRestricted?: boolean;
  onToggleDay: (day: Day) => void;
}

const daysInWeek: Day[] = [
  Day.Sunday,
  Day.Monday,
  Day.Tuesday,
  Day.Wednesday,
  Day.Thursday,
  Day.Friday,
  Day.Saturday,
];

export const DayCheckboxes = ({
  dayOnOffMap,
  isValid,
  onToggleDay,
  disabledDays = {},
  planRestricted = false,
  invalidText = "You must set working hours for at least one day.",
}: DayCheckboxesProps) => (
  <div>
    <div className="cw-flex cw-gap-2 cw-justify-center md:cw-justify-start">
      {daysInWeek
        .filter((day) => day in dayOnOffMap)
        .map((day) => {
          const checked = !!dayOnOffMap[day];
          const disabled = !!disabledDays[day];

          return (
            <DayCheckbox
              key={day}
              checked={checked}
              disabled={disabled}
              error={!isValid && day === Day.Monday}
              restricted={planRestricted}
              day={day}
              onChange={() => onToggleDay(day)}
            />
          );
        })}
    </div>
    {!isValid && <div className="cw-caption cw-mt-1 cw-text-destructive">{invalidText}</div>}
  </div>
);
