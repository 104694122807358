import { ProposalRenderSurface } from "#webapp/src/components/chat/constants";
import { Warning } from "@clockwise/design-system/icons";
import { LockFilled } from "@clockwise/icons";
import { TradeoffInactionReason, TradeoffTypeEnum } from "@clockwise/schema";
import { EventThread } from "@clockwise/web-commons/src/ui/event-thread/";
import {
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { Profile } from "@clockwise/web-commons/src/util/profile.util";
import classNames from "classnames";
import { uniqBy } from "lodash";
import React from "react";
import { useUpdateHoverEvent } from "../../../../../chat-plus-calendar/util/HoverEventContext";
import { EventCardCollapsed } from "../../../../../event-card/EventCardCollapsed";
import { useAIMessageContext } from "../../../hooks/AIMessageContext";
import { TradeoffBlock } from "../../../utils/types";
import { useEventLayersToggle } from "../../multi-calendar/useEventLayersToggle";
import { ThreadItemHeader } from "./ThreadItemHeader";
import {
  ThreadEventTooltip,
  ThreadNonEventTooltip,
} from "./thread-event-tooltip/ThreadEventTooltip";
import { useProposalThreadEventDetails } from "./thread-event-tooltip/useProposalThreadEventDetails";

export const getTradeOffTextFromInactionType = (reason: TradeoffInactionReason) => {
  switch (reason) {
    case TradeoffInactionReason.ExternalMeeting:
      return { text: "Conflict (External attendees)", icon: Warning };
    case TradeoffInactionReason.LowVisibility:
      return { text: "Conflict (No permission)", icon: LockFilled };
    case TradeoffInactionReason.Other:
      return { text: "Conflict (No times found)", icon: Warning };
    case TradeoffInactionReason.IsActionable:
      return { text: "Fixable (Suggestion Declined)" };
  }
};

type ListOfSchedulingTradeoffsWithProfiles = {
  schedulingTradeoff: TradeoffBlock["schedulingTradeoffs"][0];
  profiles: (Profile & { calendarId: string })[];
}[];

export const TradeoffsWrapper = ({
  tradeoffs,
  isCollapsed,
  onCollapse,
  onExpand,
  variant = "AI_CHAT",
  tradeoffGroupType,
}: {
  tradeoffs: TradeoffBlock[];
  isCollapsed: boolean;
  onCollapse: () => void;
  onExpand: () => void;
  variant?: ProposalRenderSurface;
  tradeoffGroupType: "unavailable" | "inconvenience";
}) => {
  const allProfiles: (Profile & { isYou?: boolean; calendarId: string })[] = [];
  const infoForNonEventTradeoffs: ListOfSchedulingTradeoffsWithProfiles = [];
  const infoForEventTradeoffs: ListOfSchedulingTradeoffsWithProfiles = [];

  const containsALowVizInconvenience = tradeoffs.some(
    (tradeOff) => tradeOff.tradeoffType === TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE,
  );
  if (containsALowVizInconvenience) {
    // Low viz tradeoffs have no `schedulingTradeoffs`, so the mapping is slightly different than the logic below
    const lowVizTradeoffsProfiles = tradeoffs.flatMap((tradeoffBlock) =>
      tradeoffBlock.aggregateAttendees.map((attendee) => {
        return {
          givenName: attendee?.profile?.givenName || null,
          familyName: attendee?.profile?.familyName || null,
          externalImageUrl: attendee?.profile?.externalImageUrl || null,
          calendarId: attendee?.primaryCalendarId,
          isYou: attendee?.isYou,
        };
      }),
    );
    return (
      <div className="cw-flex cw-flex-col cw-gap-1.5">
        <ThreadItemHeader
          isCollapsed={true}
          profiles={uniqBy(lowVizTradeoffsProfiles, "calendarId")}
          type={tradeoffGroupType}
        />
      </div>
    );
  }

  for (const tradeoff of tradeoffs) {
    for (const schedulingTradeoff of tradeoff.schedulingTradeoffs) {
      if (schedulingTradeoff.externalEventId) {
        infoForEventTradeoffs.push({
          schedulingTradeoff,
          profiles: schedulingTradeoff.attendees.map((attendee) => {
            const profile = {
              givenName: attendee?.profile?.givenName || null,
              familyName: attendee?.profile?.familyName || null,
              externalImageUrl: attendee?.profile?.externalImageUrl || null,
              calendarId: attendee?.primaryCalendarId,
              isYou: attendee?.isYou,
            };
            allProfiles.push(profile);
            return profile;
          }),
        });
      } else {
        infoForNonEventTradeoffs.push({
          schedulingTradeoff,
          profiles: schedulingTradeoff.attendees.map((attendee) => {
            const profile = {
              givenName: attendee?.profile?.givenName || null,
              familyName: attendee?.profile?.familyName || null,
              externalImageUrl: attendee?.profile?.externalImageUrl || null,
              calendarId: attendee?.primaryCalendarId,
              isYou: attendee?.isYou,
            };
            allProfiles.push(profile);
            return profile;
          }),
        });
      }
    }
  }

  return (
    <div className="cw-flex cw-flex-col cw-gap-1.5">
      <ThreadItemHeader
        isCollapsed={isCollapsed}
        onCollapse={onCollapse}
        onExpand={onExpand}
        profiles={uniqBy(allProfiles, "calendarId")}
        type={tradeoffGroupType}
        variant={variant}
      />
      {!isCollapsed && (
        <>
          {infoForNonEventTradeoffs.map((info) => (
            <TradeOffNonEvent
              profiles={info.profiles}
              schedulingTradeoff={info.schedulingTradeoff}
              key={info.schedulingTradeoff.id}
              variant={variant}
            />
          ))}
          {infoForEventTradeoffs.map((info) => (
            <TradeoffEvent
              key={info.schedulingTradeoff.id}
              schedulingTradeoff={info.schedulingTradeoff}
              profiles={info.profiles}
              variant={variant}
            />
          ))}
        </>
      )}
    </div>
  );
};

const TradeoffEvent = ({
  schedulingTradeoff,
  profiles,
  variant = "AI_CHAT",
}: {
  schedulingTradeoff: TradeoffBlock["schedulingTradeoffs"][0];
  profiles: (Profile & { calendarId: string })[];
  variant: ProposalRenderSurface;
}) => {
  const {
    attendees: attendeesFromEvent,
    description,
    title,
    loading,
  } = useProposalThreadEventDetails({
    externalEventId: schedulingTradeoff?.externalEventId || "",
    calendarId: profiles[0]?.calendarId || "",
  });

  const { processingMessage } = useAIMessageContext();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateHoverEvent = useUpdateHoverEvent();

  const { attendees } = schedulingTradeoff;
  const calendarID = attendees?.[0]?.primaryCalendarId || "";

  const { toggle, disabled, active } = useEventLayersToggle({
    startingIsToggledOn: false,
    externalEventId: schedulingTradeoff.externalEventId || "",
    calendarId: calendarID,
    focusedToggle: true,
  });

  const handleExpand = () => {
    if (processingMessage || !schedulingTradeoff.externalEventId) return;
    updateActiveEvent({
      externalEventId: schedulingTradeoff.externalEventId,
      calendarId: calendarID,
      type: EventType.Event,
    });
    updateHoverEvent(null);
  };

  return (
    <EventThread.Event profiles={profiles} variant={variant}>
      <ThreadEventTooltip
        title={schedulingTradeoff.eventTitle || title}
        attendees={attendeesFromEvent}
        description={description}
        loadingDetails={loading}
        tradeoffInfo={getTradeOffTextFromInactionType(schedulingTradeoff.reasonForInaction)}
      >
        <EventCardCollapsed
          eventName={schedulingTradeoff.eventTitle}
          onExpandCard={handleExpand}
          subText={null}
          onToggleLayers={disabled ? undefined : toggle} // Hide toggle if disabled
          isLayersActive={active}
          isLayersDisabled={disabled}
          toggleLayersTooltip="Show attendee calendars"
          customSeeMoreIcon={
            getTradeOffTextFromInactionType(schedulingTradeoff.reasonForInaction)?.icon
          }
        />
      </ThreadEventTooltip>
    </EventThread.Event>
  );
};

const TradeOffNonEvent = ({
  schedulingTradeoff,
  profiles,
  variant = "AI_CHAT",
}: {
  schedulingTradeoff: TradeoffBlock["schedulingTradeoffs"][0];
  profiles: (Profile & { calendarId: string })[];
  variant: ProposalRenderSurface;
}) => {
  return (
    <EventThread.Event profiles={profiles} variant={variant}>
      <ThreadNonEventTooltip profiles={profiles}>
        <div
          cw-id="proposal-eventless-tradeoffs"
          className="cw-bg-light-subtle-striped cw-w-full cw-rounded-xl cw-border cw-border-solid cw-border-muted hover:cw-border-default cw-group focus:cw-border-brand-emphasis-pressed cw-body-base cw-overflow-hidden"
        >
          <div className="cw-w-full cw-flex cw-flex-col cw-gap-1 cw-py-2 cw-px-3 cw-border-none cw-body-sm cw-font-medium cw-text-default hover:cw-text-default-hover">
            <div className="cw-w-full cw-flex cw-justify-between cw-text-start cw-items-center cw-h-[24px]">
              <button
                cw-id="non-event-tradeoff"
                title={schedulingTradeoff.title}
                aria-label={schedulingTradeoff.title}
                className={classNames(
                  "cw-border-none cw-text-start cw-p-0 cw-m-0",
                  "cw-body-base cw-font-semibold cw-text-12 cw-truncate cw-flex-1 cw-mr-3",
                  "hover:cw-cursor-pointer cw-bg-transparent",
                )}
                tabIndex={0}
              >
                {schedulingTradeoff.title}
              </button>
            </div>
          </div>
        </div>
      </ThreadNonEventTooltip>
    </EventThread.Event>
  );
};
