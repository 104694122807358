import React from "react";
import { ToggleCalendarLayers } from "./ToggleCalendarLayers";
import { useEventLayersToggle } from "./useEventLayersToggle";

export const ToggleCalendarsLayersForEvents = ({
  startingIsToggledOn,
  externalEventId,
  calendarId,
  // If `focusedToggle` is on, remove all the calendars on this event individually
  // regardless of all the other events and diffs that are toggled on
  focusedToggle,
}: {
  startingIsToggledOn: boolean;
  externalEventId: string;
  calendarId: string;
  focusedToggle?: boolean;
}) => {
  const { toggle, disabled, reasonForDisabled, active } = useEventLayersToggle({
    startingIsToggledOn,
    externalEventId,
    calendarId,
    focusedToggle,
  });

  return (
    <ToggleCalendarLayers
      onClick={toggle}
      disabled={disabled}
      isToggledOn={active}
      reasonForDisabled={reasonForDisabled}
    />
  );
};
