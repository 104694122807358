//////////////////
// IMPORTS
//////////////////
// libraries
import React from "react";

// components
import { browserSitePermissionsScreenshot } from "./img";

// material-ui
import { Button } from "@clockwise/design-system";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

// ~-~-~-~-~-~-~-
// Web Settings Emails and notifications
// ~-~-~-~-~-~-~-
interface IProps {
  onClose: () => void;
}

export const BrowserNotificationPermissionDialog = (props: IProps) => {
  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  return (
    <Dialog
      open={true}
      onClose={() => props.onClose()}
      PaperProps={{ style: { maxWidth: 600, width: 600, padding: 10 } }}
    >
      <DialogTitle>
        <h1 className="cw-heading-3xl">You need to adjust the permissions in your browser</h1>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <p className="cw-body-lg cw-mb-4">
          It looks like your browser has notifications disabled for Clockwise. Please click on the
          icon to the left of the search bar and re-enable notifications for Clockwise.
        </p>
        <img className="cw-w-full" src={browserSitePermissionsScreenshot} />
      </DialogContent>
      <DialogActions>
        <Button variant="solid" sentiment="positive" onClick={() => props.onClose()}>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};
