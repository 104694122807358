import { ChromeAppWrapperView } from "#webapp/src/util/chrome-extension.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { useQuery } from "@apollo/client";
import { OrgCrawlTest } from "@clockwise/schema";
import { useEffect, useMemo, useState } from "react";
import {
  OrgCrawlFailureDocument,
  OrgCrawlFailureQuery,
} from "./__generated__/OrgCrawlFailure.generated";

const getCrawlFailureView = (orgCrawlFailure?: OrgCrawlTest) => {
  if (!orgCrawlFailure) {
    return ChromeAppWrapperView.NoOrg;
  }
  switch (orgCrawlFailure) {
    case "GoogleContactSharingDisabled":
      return ChromeAppWrapperView.GoogleContactSharingDisabled;
    case "GoogleApiAccessDisabled":
      return ChromeAppWrapperView.GoogleApiAccessDisabled;
    case "ShouldCrawl":
      return ChromeAppWrapperView.ShouldCrawl;
    case "NoCrawl":
      return ChromeAppWrapperView.NoCrawl;
    case "NoExternalOrganization":
      return ChromeAppWrapperView.NoExternalOrganization;
    default:
      return ChromeAppWrapperView.GenericCrawlFailure;
  }
};

export function getOrgCrawlFailure(data: OrgCrawlFailureQuery) {
  const org = getCurrentOrg(data?.viewer);
  const user = data?.viewer?.user;

  let orgCrawlFailure: OrgCrawlTest | null = null;
  if (org && user) {
    if (org.crawlTest && org.crawlTest !== "Skipped" && org.crawlTest !== "StartedCrawl") {
      const userAge = new Date().getTime() - user.createdTime;
      const brandNewUser = userAge < 60 * 1000; // 1 minute

      // ShouldCrawl for brand new users is not an error
      if (org.crawlTest !== "ShouldCrawl" || !brandNewUser) {
        orgCrawlFailure = org.crawlTest;
      }
    }
  }
  return orgCrawlFailure;
}

export const useOrgCrawlFailure = () => {
  const [orgCrawlFailure, setOrgCrawlFailure] = useState<OrgCrawlTest | null>(null);
  const { data, loading, refetch } = useQuery<OrgCrawlFailureQuery>(OrgCrawlFailureDocument, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      const detectedOrgCrawlFailure = getOrgCrawlFailure(data);
      setOrgCrawlFailure(detectedOrgCrawlFailure);
    }
  }, [data]);

  const failureView = useMemo(() => {
    if (!!orgCrawlFailure) {
      getCrawlFailureView(orgCrawlFailure);
    }
    return null;
  }, [orgCrawlFailure]);

  return { loading, refetch, orgCrawlFailure, failureView };
};
