import { Modal } from "@clockwise/design-system";
import { bg_busy_emphasis } from "@clockwise/design-system/tokens";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useMemo, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import ClickDrag from "./assets/clickdrag_new.gif";
import DragNDrop from "./assets/dnd_tradeoffs.gif";

const slides = [
  {
    title: "Click to schedule events",
    imgSrc: ClickDrag,
    textComponent: (
      <div className="cw-flex cw-flex-col cw-body-base cw-text-14 cw-text-onEmphasis cw-gap-y-2">
        <div>
          Click directly on the calendar to create an event, instantly receiving a proposal that
          highlights conflicts along with potential solutions.
        </div>
        <div>
          <span className="cw-font-bold">
            Try clicking & dragging a new event on top of an existing Flexible Meeting
          </span>{" "}
          and see how Clockwise finds new times for the conflicts.
        </div>
      </div>
    ),
    showSecondaryButton: false,
  },
  {
    title: "Reschedule with drag and drop",
    imgSrc: DragNDrop,
    textComponent: (
      <div className="cw-flex cw-flex-col cw-body-base cw-text-14 cw-text-onEmphasis cw-gap-y-2">
        <div>
          Intelligent highlighting and fixing of conflicts also works when you drag and drop to
          reschedule.
        </div>
        <div>
          <span className="cw-font-bold">Try dragging an event on top of a Flexible Meeting</span>{" "}
          to see how Clockwise offers to fix the conflict immediately.
        </div>
      </div>
    ),
    showSecondaryButton: true,
  },
];

export const PrismEducationModal = () => {
  const [hasUserDismissedModal, setHasUserDissmissedModal] = useLocalStorage<boolean>(
    "ClockwiseAI:PrismEducationModalDismissed",
    false,
  );
  const [isOpen, setIsOpen] = useState(!hasUserDismissedModal);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const maxSlideIndex = slides.length - 1;

  const handleClose = () => {
    setIsOpen(false);
    setHasUserDissmissedModal(true);
    track(TrackingEvents.PRISM_EDUCATION_MODAL.DISMISSED, {
      furthestSlide: slides[currentSlideIndex].title,
      finished: isLastSlide,
    });
  };
  const track = useTracking();

  const stepForward = () => {
    if (isLastSlide) {
      handleClose();
    } else {
      // In the future there will be one more slide for command bar and this will be more verbose
      setCurrentSlideIndex(1);
      track(TrackingEvents.PRISM_EDUCATION_MODAL.STEP_FORWARD);
    }
  };
  const setBack = () => {
    // In the future there will be one more slide for command bar and this will be more verbose
    setCurrentSlideIndex(0);
    track(TrackingEvents.PRISM_EDUCATION_MODAL.STEP_FORWARD);
  };

  const imageForIndex = useMemo(() => slides[currentSlideIndex].imgSrc, [currentSlideIndex]);
  const textComponentForIndex = useMemo(() => slides[currentSlideIndex].textComponent, [
    currentSlideIndex,
  ]);
  const showSecondaryButton = useMemo(() => slides[currentSlideIndex].showSecondaryButton, [
    currentSlideIndex,
  ]);
  const titleForIndex = useMemo(() => slides[currentSlideIndex].title, [currentSlideIndex]);

  const isLastSlide = currentSlideIndex === maxSlideIndex;

  if (hasUserDismissedModal) {
    return null;
  }

  return (
    <Modal
      withOverlay={false}
      trapFocus={false}
      size={500}
      centered={true}
      radius={11}
      padding="xl"
      opened={isOpen}
      onClose={handleClose}
      classNames={{
        title: "cw-text-onEmphasis",
      }}
      styles={{
        header: {
          backgroundColor: bg_busy_emphasis,
          position: "relative",
        },
        title: {
          fontSize: "22px",
          fontWeight: 700,
        },
        close: {
          color: "#fff",
          backgroundColor: bg_busy_emphasis,
          cursor: "pointer",
        },
        content: { backgroundColor: bg_busy_emphasis, position: "relative" },
      }}
      title={titleForIndex}
      closeOnClickOutside={true}
    >
      <div className="cw-flex cw-flex-col cw-h-full cw-bg-busy-empahsis cw-text-onEmphasis">
        <Content imgSrc={imageForIndex} textComponent={textComponentForIndex} />
        <div>
          <div className="cw-flex cw-justify-between cw-items-center">
            <div className="cw-text-13">{`${currentSlideIndex + 1} of ${maxSlideIndex + 1}`}</div>
            <div className="cw-flex cw-items-center cw-gap-x-3 cw-text-13">
              {showSecondaryButton && (
                <div>
                  <button
                    onClick={setBack}
                    cw-id="ai-modal-primary-button"
                    className="cw-py-1 cw-border cw-border-solid cw-border-[#fff] cw-bg-transparent cw-w-full cw-text-onEmphasis hover:cw-text-onEmphasis-hover focus:cw-text-onEmphasis-focus cw-flex cw-justify-center cw-items-center cw-body-base cw-font-semibold cw-px-3 cw-rounded-lg cw-cursor-pointer "
                  >
                    Back
                  </button>
                </div>
              )}

              <div>
                <button
                  onClick={stepForward}
                  cw-id="ai-modal-primary-button"
                  className="cw-py-1 cw-bg-default cw-border cw-border-solid cw-border-[#fff] cw-w-full cw-text-busy cw-flex cw-justify-center cw-items-center cw-body-base cw-font-semibold cw-px-3 cw-rounded-lg cw-cursor-pointer hover:cw-bg-default-hover"
                >
                  {isLastSlide ? "Let's go!" : "Next"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Content = ({ imgSrc, textComponent }: { imgSrc: string; textComponent: React.ReactNode }) => {
  const TextComponent = () => textComponent;
  return (
    <div className="cw-flex cw-flex-col cw-gap-y-3 cw-mb-8">
      <img
        src={imgSrc}
        className="cw-rounded cw-border cw-border-solid cw-border-brand-emphasis cw-drop-shadow-[3px_3px_0px_rgba(0,63,46)] cw-h-[283px]"
      />
      <div className="cw-body-base cw-text-onEmphasis cw-font-bold cw-text-12 cw-mt-2">
        NEW IN YOUR INTELLIGENT CALENDAR
      </div>
      <TextComponent />
    </div>
  );
};
