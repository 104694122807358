import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { Interval } from "luxon";
import React, { ReactNode } from "react";
import { EventCardContextProvider } from "../../../../../event-card/hooks/EventCardContext";
import { EventTradeoffsProvider } from "../../../../../event-card/hooks/EventTradeoffsContext";
import { EventCardAttendee } from "../../../../../event-card/types";
import { DiffDetailsById } from "../../../utils/types";

export const DiffContext: React.FC<{
  time: string | null;
  diffDetails: {
    attendeePeople: EventCardAttendee[];
    tradeoffBlocks: DiffDetailsById["tradeoffBlocks"];
  };
  eventId?: string;
  children: ReactNode;
}> = ({ children, time, diffDetails, eventId = null }) => {
  const interval = time ? Interval.fromISO(time, { zone: getRenderTimeZone() }) : null;
  return (
    <EventCardContextProvider
      initValue={{
        isAllDay: false,
        startTime: interval?.start,
        endTime: interval?.end,
        attendees: diffDetails.attendeePeople,
        eventId,
      }}
    >
      {/* NB: the tradeoffs provider must be within the event card context */}
      <EventTradeoffsProvider tradeoffs={diffDetails.tradeoffBlocks}>
        {children}
      </EventTradeoffsProvider>
    </EventCardContextProvider>
  );
};
