import * as Types from "@clockwise/schema";

type Org = {
  settings: {
    __typename: "SettingConnection";
    edges: Array<{
      __typename: "SettingEdge";
      node: {
        __typename: "Setting";
        id: string;
        value: Array<string | null> | null;
        key: {
          __typename: "SettingKey";
          id: string;
          name: string | null;
          scope: {
            __typename: "Urn";
            id: string;
            type: Types.UrnType | null;
            value: string | null;
          } | null;
          target: {
            __typename: "Urn";
            id: string;
            type: Types.UrnType | null;
            value: string | null;
          } | null;
        } | null;
        type: {
          __typename: "SettingType";
          id: string;
          valueType: Types.ValueType | null;
          containerType: Types.ContainerType | null;
          required: boolean | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

function genericGetSetting<O extends Org>(org: O, key: string) {
  if (!org || !org.settings || !org.settings.edges) {
    return;
  }
  const settingEdge = org.settings.edges.find((edge) => edge?.node?.key?.name === key);
  return (settingEdge && settingEdge.node) || undefined;
}

export const KEY_NAMES = {
  ReceiveDefragMoveValueSummaryEmail: "ReceiveDefragMoveValueSummaryEmail",
  ReceiveInviteSuggestionsEmail: "ReceiveInviteSuggestionsEmail",
  ReceiveNowOnAutopilotEmail: "ReceiveNowOnAutopilotEmail",
  ReceiveWeekInReviewEmail: "ReceiveWeekInReviewEmail",
  ReceiveYourWeekAheadEmail: "ReceiveYourWeekAheadEmail",
  SendNowOnAutopilotEmail: "SendNowOnAutopilotEmail",
  // M365
  ReceiveManageOneOnOnesEmail: "ReceiveManageOneOnOnesEmail",
  // Browser notifications
  BrowserNotificationPermissionGranted: "BrowserNotificationPermissionGranted",
  MeetingFlexibilitySuggestionNotificationEnabled:
    "MeetingFlexibilitySuggestionNotificationEnabled",
};

function generateBooleanSetting(name: string) {
  return {
    get: <O extends Org>(org: O) => {
      return genericGetSetting(org, name);
    },
    isOn: <O extends Org>(org: O) => {
      const setting = genericGetSetting(org, name);
      return !!setting && !!setting.value && setting.value[0] === "true";
    },
  };
}

export const receiveDefragMoveValueSummaryEmailSetting = generateBooleanSetting(
  KEY_NAMES.ReceiveDefragMoveValueSummaryEmail,
);

export const receiveInviteSuggestionsEmailSetting = generateBooleanSetting(
  KEY_NAMES.ReceiveInviteSuggestionsEmail,
);

export const receiveNowOnAutopilotEmailSetting = generateBooleanSetting(
  KEY_NAMES.ReceiveNowOnAutopilotEmail,
);

export const receiveWeekInReviewEmailSetting = generateBooleanSetting(
  KEY_NAMES.ReceiveWeekInReviewEmail,
);

export const receiveYourWeekAheadEmailSetting = generateBooleanSetting(
  KEY_NAMES.ReceiveYourWeekAheadEmail,
);

export const sendNowOnAutopilotEmailSetting = generateBooleanSetting(
  KEY_NAMES.SendNowOnAutopilotEmail,
);

export const receiveManageOneOnOnesEmailSetting = generateBooleanSetting(
  KEY_NAMES.ReceiveManageOneOnOnesEmail,
);

export const browserNotificationPermissionGranted = generateBooleanSetting(
  KEY_NAMES.BrowserNotificationPermissionGranted,
);

export const receiveMeetingFlexibilitySuggestionNotification = generateBooleanSetting(
  KEY_NAMES.MeetingFlexibilitySuggestionNotificationEnabled,
);
