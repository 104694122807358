import { find } from "lodash";
import { DateTime } from "luxon";
import { IOrgOverview } from "../../overview.types";

const DATA_DATE_FORMAT = "yyyy-MM-dd";

export type PeriodCumulativeValues = {
  current: number;
  previous: number;
};

export type AttributesPeriodCumulativeValues = Record<keyof IOrgOverview, PeriodCumulativeValues>;

const transformOrgOvierviewToCumulativePerPeriodStats = (
  orgOverview: IOrgOverview,
  dailyAttributes: (keyof IOrgOverview)[],
  cumlativeAttributes: (keyof IOrgOverview)[],
  maxDate: Date,
  midDate: Date,
  minDate: Date,
) => {
  const result = {} as AttributesPeriodCumulativeValues;
  const maxLux = DateTime.fromJSDate(maxDate);
  const midLux = DateTime.fromJSDate(midDate);
  const minLux = DateTime.fromJSDate(minDate);

  dailyAttributes.forEach((attribute) => {
    const attributeData = orgOverview[attribute];

    if (typeof attributeData !== "object") {
      return;
    }

    result[attribute] = { current: 0, previous: 0 };

    attributeData.forEach((attributeDatum) => {
      const attributeLux = DateTime.fromFormat(attributeDatum.label, DATA_DATE_FORMAT);
      const attributeValue = attributeDatum.value;

      if (attributeValue) {
        if (attributeLux <= maxLux && attributeLux > midLux) {
          result[attribute].current += attributeValue;
        } else if (attributeLux <= midLux && attributeLux > minLux) {
          result[attribute].previous += attributeValue;
        }
      }
    });
  });

  cumlativeAttributes.forEach((attribute) => {
    const attributeData = orgOverview[attribute];

    if (typeof attributeData !== "object") {
      return;
    }

    result[attribute] = {
      current: find(attributeData, { label: maxLux.toFormat(DATA_DATE_FORMAT) })?.value || 0,
      previous: find(attributeData, { label: midLux.toFormat(DATA_DATE_FORMAT) })?.value || 0,
    };
  });

  return result;
};

export default transformOrgOvierviewToCumulativePerPeriodStats;
