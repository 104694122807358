import {
  FeatureSetting,
  FeatureSettingAside,
  FeatureSettingBody,
  FeatureSettingHeading,
  FeatureSettingMain,
  FeatureSettingsHoverCard,
} from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import React from "react";
import MeetingHours from "./MeetingHours";
import WorkingHours from "./WorkingHours";
import MeetingHoursTooltip from "./components/MeetingHoursTooltip";
import WorkingHoursIllustration from "./components/WorkingHoursIllustration";
import WorkingHoursTooltip from "./components/WorkingHoursTooltip";
import { useWorkingHours } from "./hooks/useWorkingHours";

const WorkingMeetingHours = () => {
  const {
    loading,
    workingHours,
    onUpdateWorkingHours,
    onUpdateTimeZone,
    meetingHours,
    onUpdateMeetingHours,
    showMeetingHours,
    onCheckShowMeetingHours,
  } = useWorkingHours();

  return (
    <FeatureSetting enabled={true} loading={loading} title="Working Meeting Hours Settings">
      <FeatureSettingAside>
        <WorkingHoursIllustration />
      </FeatureSettingAside>
      <FeatureSettingMain>
        <FeatureSettingHeading>
          <div className="cw-flex cw-flex-row cw-items-center">
            <h2
              className="cw-heading-2xl cw-m-0"
              aria-label="Do you need Focus Time to get things done?"
              id="FocusTime"
            >
              When do you start and stop{" "}
              <FeatureSettingsHoverCard id="ft-desc-tooltip" content={<WorkingHoursTooltip />}>
                <span
                  className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1"
                  aria-describedby="ft-desc-tooltip"
                  data-testid="ft-tooltipper"
                >
                  working
                </span>
              </FeatureSettingsHoverCard>
              ?
            </h2>
          </div>
        </FeatureSettingHeading>
        <FeatureSettingBody>
          <div>
            <WorkingHours
              workingHours={workingHours}
              loading={loading}
              onUpdateWorkingHours={onUpdateWorkingHours}
              onUpdateTimeZone={onUpdateTimeZone}
            />
          </div>
        </FeatureSettingBody>
        <FeatureSettingHeading>
          <div className="cw-flex cw-flex-row cw-items-center">
            <h2
              className="cw-heading-2xl cw-m-0"
              aria-label="Do you need Focus Time to get things done?"
              id="FocusTime"
            >
              When are you available to{" "}
              <FeatureSettingsHoverCard id="ft-desc-tooltip" content={<MeetingHoursTooltip />}>
                <span
                  className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1"
                  aria-describedby="ft-desc-tooltip"
                  data-testid="ft-tooltipper"
                >
                  meet
                </span>
              </FeatureSettingsHoverCard>{" "}
              with others?
            </h2>
          </div>
        </FeatureSettingHeading>
        <FeatureSettingBody>
          <div>
            <MeetingHours
              workingHours={workingHours}
              meetingHours={meetingHours}
              showMeetingHours={showMeetingHours}
              onCheckShowMeetingHours={onCheckShowMeetingHours}
              onUpdateMeetingHours={onUpdateMeetingHours}
              loading={loading}
            />
          </div>
        </FeatureSettingBody>
      </FeatureSettingMain>
    </FeatureSetting>
  );
};

export default WorkingMeetingHours;
