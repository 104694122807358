import type { Dictionary } from "lodash";
import { clone, concat, findLast, groupBy, uniqBy, values } from "lodash";
import { DateTime, Duration, Interval } from "luxon";
import { ColumnedItem, ICalPositionable } from "../types";

const PROTECT_DURATION = Duration.fromObject({ minutes: 30 });
const UNDER_SPAN = 0.6;

/**
 * This function generates a column placement for a set of positionable items.
 * The column placement is an array of ColumnedItems, where each ColumnedItem contains the positionable item and its placement information.
 * The placement information includes the column index, sub-column index, sub-column count, sub-column offset, and sub-column span.
 *
 * @param {boolean} allowOverlap - A flag indicating whether overlapping intervals are allowed.
 * @param {ICalPositionable[]} positionables - The positionable items to generate a column placement for.
 * @param {DateTime[]} dateTimes - The date times to use for column placement.
 * @param {Duration} minDuration - The minimum duration for a sub-column item.
 * @returns {ColumnedItem[]} The column placement for the given positionable items.
 */
export const getColumnPlacement = ({
  allowOverlap = false,
  doNotUseSubcolumns = false,
  positionables,
  dateTimes,
  minDuration,
  columnIndexOverride,
}: {
  allowOverlap?: boolean;
  doNotUseSubcolumns?: boolean;
  positionables: ICalPositionable[];
  dateTimes: DateTime[];
  minDuration: Duration;
  columnIndexOverride?: number;
}) => {
  let columnedItems: ColumnedItem[] = positionables.sort(byStartTimeAndDuration).reduce(
    (acc: ColumnedItem[], positionable) => [
      ...acc,
      {
        item: positionable,
        placement: {
          columnIndex:
            columnIndexOverride ??
            dateTimes.findIndex(
              (dt) => dt.toISODate() === positionable.interval.start.toISODate(),
            ) ??
            -1,
          subColumnIndex: 0,
          subColumnCount: 1,
          subColumnOffset: 0,
          subColumnSpan: 1,
        },
      },
    ],
    [],
  );

  if (doNotUseSubcolumns) {
    return columnedItems;
  }

  const { map, keys, protectedMap, protectedKeys } = getCollisionMap(positionables);

  const conflictMap = allowOverlap ? protectedMap : map;
  const conflictMapKeyMap = allowOverlap ? protectedKeys : keys;

  // Determine subColumnIndex
  columnedItems = getColumnedItemsWithSubColumnIndexes(columnedItems, minDuration, allowOverlap);

  // Determine subcolumns per conflict group
  for (const columnedItem of columnedItems) {
    const subColumnCount = getSubColumnCount(
      columnedItem,
      conflictMap,
      conflictMapKeyMap,
      columnedItems,
    );
    columnedItem.placement.subColumnCount = subColumnCount;
  }

  if (allowOverlap) {
    map.forEach((conflicts) => {
      const affectedItems = columnedItems.filter((ci) => conflicts.includes(ci.item));
      const columnedItemsBySubColumnDict = groupBy(
        affectedItems,
        (columnedItem) => columnedItem?.placement.subColumnIndex,
      );
      const columnedItemsBySubColumn = dictionaryToArray(columnedItemsBySubColumnDict);

      columnedItemsBySubColumn.forEach((columnedItems) => {
        columnedItems.sort(byItemIntervalStart);
        columnedItems.forEach((columnedItem, columnedItemIndex) => {
          const previousColumnItems = columnedItems.slice(0, columnedItemIndex);
          const subColumnOffset = getSubColumnOffset(columnedItem, previousColumnItems);
          const subColumnSpan = getSubColumnSpan(columnedItem, columnedItemsBySubColumn);

          columnedItem.placement.subColumnOffset = subColumnOffset;
          columnedItem.placement.subColumnSpan = subColumnSpan;
        });
      });
    });
  }

  return columnedItems;
};

/**
 * This function generates a collision map for a set of positionable items.
 * The collision map is a Map where the keys are positionable items (first of collision) and the values are arrays of other positionable items that they collide with.
 *
 * @param {ICalPositionable[]} positionables - The positionable items to generate a collision map for.
 * @returns {Map<ICalPositionable, ICalPositionable[]>} The collision map and a key map to quickly lookup an items key in collision map, and a version that includes protected durations.
 */
const getCollisionMap = (positionables: ICalPositionable[]) => {
  const map = new Map<ICalPositionable, ICalPositionable[]>();
  const keys = new Map<ICalPositionable, ICalPositionable>();
  const protectedMap = new Map<ICalPositionable, ICalPositionable[]>();
  const protectedKeys = new Map<ICalPositionable, ICalPositionable>();

  const posIntervalTuple: [ICalPositionable, Interval, Interval][] = positionables
    .sort((left, right) => left.interval.start.toSeconds() - right.interval.start.toSeconds())
    .map((p) => {
      const interval = p.interval;
      const protectedInterval = Interval.fromISO(
        `${interval.start.toISO()}/${PROTECT_DURATION.toISO()}`,
      );
      return [p, interval, protectedInterval];
    });

  const addToMap = (
    map: Map<ICalPositionable, ICalPositionable[]>,
    keys: Map<ICalPositionable, ICalPositionable>,
  ) => (
    aPositioner: ICalPositionable,
    aInterval: Interval,
    bPositioner: ICalPositionable,
    bInterval: Interval,
  ) => {
    // exit early
    if (aInterval.end < bInterval.start) {
      return;
    }

    if (aInterval.overlaps(bInterval)) {
      const aPlaced = keys.has(aPositioner);
      const bPlaced = keys.has(bPositioner);

      if (!aPlaced && !bPlaced) {
        map.set(
          aPositioner,
          uniqBy([aPositioner, bPositioner], (p) => p.key),
        );
        keys.set(aPositioner, aPositioner);
        keys.set(bPositioner, aPositioner);
      } else if (aPlaced && !bPlaced) {
        const placedTo = keys.get(aPositioner) || aPositioner;
        const placedToConflicts = map.get(placedTo) || [];
        map.set(
          placedTo,
          uniqBy(concat(placedToConflicts, bPositioner), (p) => p.key),
        );
        keys.set(bPositioner, placedTo);
      } else if (!aPlaced && bPlaced) {
        const placedTo = keys.get(bPositioner) || bPositioner;
        const placedToConflicts = map.get(placedTo) || [];
        map.set(
          placedTo,
          uniqBy(concat(placedToConflicts, aPositioner), (p) => p.key),
        );
        keys.set(aPositioner, placedTo);
      } else {
        // do nothing
      }
    }
  };

  const addToBaseMap = addToMap(map, keys);
  const addToProtectedMap = addToMap(protectedMap, protectedKeys);

  for (let i = 0; i < posIntervalTuple.length; i++) {
    for (let j = i + 1; j < posIntervalTuple.length; j++) {
      const [aPositioner, aInterval, aIntervalProtected] = posIntervalTuple[i];
      const [bPositioner, bInterval, bIntervalProtected] = posIntervalTuple[j];

      addToBaseMap(aPositioner, aInterval, bPositioner, bInterval);
      addToProtectedMap(aPositioner, aIntervalProtected, bPositioner, bIntervalProtected);
    }

    if (!keys.has(positionables[i])) {
      map.set(positionables[i], [positionables[i]]);
      keys.set(positionables[i], positionables[i]);
    }

    if (!protectedKeys.has(positionables[i])) {
      protectedMap.set(positionables[i], [positionables[i]]);
      protectedKeys.set(positionables[i], positionables[i]);
    }
  }

  return { map, keys, protectedMap, protectedKeys };
};

const getColumnedItemsWithSubColumnIndexes = (
  columnItems: ColumnedItem[],
  minDuration: Duration,
  allowOverlap: boolean,
) => {
  const result: ColumnedItem[] = [];
  const items = clone(columnItems);

  const filledSubColumnsIntervals: Interval[][] = [];
  const protectedSubColumnsIntervals: Interval[][] = [];
  let unplacedColumnedItemIndex = 0;
  let unplacedSubColumnIndex = 0;
  let placementPhase: "filled" | "protected" = "filled";
  while (items.length > 0) {
    const columnItem = items[unplacedColumnedItemIndex];
    filledSubColumnsIntervals[unplacedSubColumnIndex] =
      filledSubColumnsIntervals[unplacedSubColumnIndex] || [];
    protectedSubColumnsIntervals[unplacedSubColumnIndex] =
      protectedSubColumnsIntervals[unplacedSubColumnIndex] || [];

    const protectedDuration =
      columnItem.item.interval.toDuration() < PROTECT_DURATION ? minDuration : PROTECT_DURATION;
    const protectedInterval = Interval.fromISO(
      `${columnItem.item.interval.start.toISO()}/${protectedDuration.toISO()}`,
    );

    const checkIntervals =
      placementPhase === "filled" ? filledSubColumnsIntervals : protectedSubColumnsIntervals;
    const hasIntervalOverlap = checkIntervals[unplacedSubColumnIndex].some((interval) =>
      interval.overlaps(columnItem.item.interval),
    );

    if (hasIntervalOverlap) {
      unplacedColumnedItemIndex += 1;
    } else {
      const filledInterval =
        columnItem.item.interval.toDuration() > protectedInterval.toDuration()
          ? columnItem.item.interval
          : protectedInterval;
      filledSubColumnsIntervals[unplacedSubColumnIndex].push(filledInterval);
      protectedSubColumnsIntervals[unplacedSubColumnIndex].push(protectedInterval);
      // remove from unplacedColumnedItems
      items.splice(unplacedColumnedItemIndex, 1);
      result.push({
        item: columnItem.item,
        placement: { ...columnItem.placement, subColumnIndex: unplacedSubColumnIndex },
      });
    }

    if (unplacedColumnedItemIndex >= items.length) {
      unplacedColumnedItemIndex = 0;
      if (placementPhase === "filled" && allowOverlap) {
        placementPhase = "protected";
      } else {
        unplacedSubColumnIndex += 1;
      }
    }
  }

  return result;
};

/**
 * Calculates the sub-column count for a given columned item.
 * @param columnedItem - The columned item for which to calculate the sub-column count.
 * @param conflictMap - The map of conflicts between positionable items.
 * @param conflictMapKeys - The map of positionable items to their conflict keys.
 * @param itemToColumnMap - The map of positionable items to their columned items.
 * @returns The sub-column count for the given columned item.
 */
const getSubColumnCount = (
  columnedItem: ColumnedItem,
  conflictMap: Map<ICalPositionable, ICalPositionable[]>,
  conflictMapKeys: Map<ICalPositionable, ICalPositionable>,
  columnedItems: ColumnedItem[],
) => {
  let result = columnedItem.placement.subColumnIndex;

  const conflictMapKey = conflictMapKeys.get(columnedItem.item);
  const mappedConflicts = conflictMapKey ? conflictMap.get(conflictMapKey) || [] : [];

  result = mappedConflicts.reduce((acc, conflict) => {
    const conflictingColumnItem = columnedItems.find((ci) => ci.item === conflict);

    if (conflictingColumnItem && conflictingColumnItem.placement.subColumnIndex > acc) {
      return conflictingColumnItem.placement.subColumnIndex;
    }

    return acc;
  }, result);

  return result + 1;
};

/**
 * Calculates the sub-column offset for a given columned item.
 * @param columnedItem - The columned item for which to calculate the sub-column offset.
 * @param priorColumnedItems - The array of columned items that precede the given columned item.
 * @returns The sub-column offset for the given columned item.
 */
const getSubColumnOffset = (columnedItem: ColumnedItem, priorColumnedItems: ColumnedItem[]) => {
  let result = columnedItem.placement.subColumnOffset;

  const priorOverlap = findLast(priorColumnedItems, (priorColumnedItem) =>
    priorColumnedItem.item.interval.overlaps(columnedItem.item.interval),
  );

  if (priorOverlap) {
    result = priorOverlap.placement.subColumnOffset + 1;
  }

  return result;
};

/**
 * Calculates the sub-column span for a given columned item.
 * @param columnedItem - The columned item for which to calculate the sub-column span.
 * @param columnedItemsBySubColumn - The array of columned items grouped by sub-column.
 * @returns The sub-column span for the given columned item.
 */
const getSubColumnSpan = (
  columnedItem: ColumnedItem,
  columnedItemsBySubColumn: ColumnedItem[][],
) => {
  let result = columnedItem.placement.subColumnSpan;
  const { subColumnIndex: subColumn = 0, subColumnCount = 0 } = columnedItem?.placement || {};

  if (subColumn + 1 >= subColumnCount) {
    return result;
  }

  for (let i = subColumn + 1; i < subColumnCount; i++) {
    const collision = columnedItemsBySubColumn[i]?.some((ci) =>
      ci?.item.interval.overlaps(columnedItem?.item.interval),
    );

    if (collision) {
      result += UNDER_SPAN;
      break;
    }

    result += 1;
  }

  return result;
};

/**
 * Converts a dictionary of arrays into a two-dimensional array.
 * Each key in the dictionary corresponds to a row in the resulting array,
 * and the values in the arrays are filtered to remove any undefined elements.
 *
 * @param dict - The dictionary to convert.
 * @returns The two-dimensional array representation of the dictionary.
 */
const dictionaryToArray = <T>(dict: Dictionary<(T | undefined)[]>): T[][] =>
  values(dict).map((items) => items.filter(Boolean) as T[]);

type WithItemInterval = {
  item: {
    interval: Interval;
  };
};

/**
 * This function compares the start times of two intervals and returns a number indicating their order.
 * If the start time of the left interval is less than the right, it returns a negative number.
 * If the start time of the left interval is greater than the right, it returns a positive number.
 * If the start times are equal, it returns 0.
 * If either interval is null or undefined, it treats its start time as 0.
 *
 * @param {WithItemInterval} left - The first object to compare.
 * @param {WithItemInterval} right - The second object to compare.
 * @returns {number} A negative number if the start time of the left interval is less than the right, a positive number if it's greater, or 0 if they're equal.
 */
export const byItemIntervalStart = (left: WithItemInterval, right: WithItemInterval) =>
  (left?.item.interval?.start?.toSeconds() || 0) - (right?.item.interval?.start?.toSeconds() || 0);

type WithInterval = {
  interval: Interval;
};

/**
 * This function compares two intervals by their start times and durations.
 * It first compares the start times of the intervals. If they are not equal, it returns the difference.
 * If the start times are equal, it then compares the durations of the intervals (end time - start time) and returns the difference.
 * The function is designed to be used as a comparator in sorting functions.
 *
 * @param {WithInterval} left - The first interval to compare.
 * @param {WithInterval} right - The second interval to compare.
 * @returns {number} A number indicating the order of the intervals. A negative number if the left interval should come first, a positive number if the right interval should come first, or 0 if they are equal.
 */
const byStartTimeAndDuration = (left: WithInterval, right: WithInterval): number => {
  const startDiff = left.interval.start.toSeconds() - right.interval.start.toSeconds();
  if (startDiff !== 0) {
    return startDiff;
  }

  const endDiff = right.interval.end.toSeconds() - left.interval.end.toSeconds();
  return endDiff;
};
