import { ProposalState } from "@clockwise/schema/v2";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { Maybe } from "purify-ts";
import { useEffect, useMemo, useState } from "react";
import { ProposalTradeoffsForEventQueryDocument } from "./__generated__/GetProposalTradeoffsForEvent.v2.generated";

const PROPOSAL_POLLING_INTERVAL = 1000;
const DO_NOT_POLL = 0;
export const useProposalTradeoffBlocks = (id?: string) => {
  const [pollInterval, setPollInterval] = useState(DO_NOT_POLL);

  const { data } = useGatewayQuery(ProposalTradeoffsForEventQueryDocument, {
    variables: { id: id! },
    skip: !id,
    errorPolicy: "all",
    pollInterval,
  });

  const proposalState = data?.proposal?.state;
  const diff = data?.proposal?.diffBlocks[0].diffs[0];

  useEffect(() => {
    const pollInterval =
      proposalState === ProposalState.LoadingSuggestions ? PROPOSAL_POLLING_INTERVAL : DO_NOT_POLL;
    setPollInterval(pollInterval);
  }, [proposalState]);

  return useMemo(() => {
    const tradeoffBlocks = Maybe.fromNullable(diff)
      .map((diff) =>
        diff.__typename === "ModifyDiff" || diff.__typename === "AddDiff"
          ? diff.tradeoffBlocks
          : [],
      )
      .extractNullable();

    const recurrence = Maybe.fromNullable(diff)
      .map((diff) => (diff.__typename === "ModifyDiff" ? diff.event?.recurrenceRule : null))
      .extractNullable();

    return { tradeoffBlocks, proposalState, recurrence };
  }, [diff, proposalState]);
};
