import { Button, ButtonSet, Switch } from "@clockwise/design-system";
import { Check, Edit } from "@clockwise/design-system/icons";
import { Delete } from "@clockwise/icons";
import { AvailabilityRestrictionEnum } from "@clockwise/schema/v2";
import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { TrackingEvents, useTracking } from "../../util/analytics.util";
import { formatDuration } from "../../util/duration.util";
import { getSchedulingLinkPath, getSchedulingLinkUrl } from "../../util/scheduling.util";
import { AttendeeAvatar } from "../AttendeeAvatar";
import { AttendeeAvatarStack } from "../AttendeeAvatarStack";
import { CwDialogConfirmRed } from "../cw-dialog";
import { AVAILABILITY_TEXT } from "./constants";
import { useResettingBoolean } from "./hooks/useResettingBoolean";

export type Link = {
  id: string | null;
  slug: string;
  name: string;
  active: boolean;
  durations: number[];
  defaultDuration: number;
  availabilityRestriction: AvailabilityRestrictionEnum;
  linkMembers: Array<{
    id: string;
    member: {
      person: {
        givenName: string | null;
        familyName: string | null;
        externalImageUrl: string | null;
      };
    };
  }>;
  singleUse: boolean;
};

export type Props = {
  linkName: string;
  link: Link;
  onChangeActive: (active: boolean) => Promise<void>;
  onClickCard: (linkUrl: string) => void;
  onShareTimes: () => void;
  onEditLink: (linkName: string, slug: string) => void;
  onCloneLink?: (linkName: string, slug: string) => Promise<void>;
  onDeleteLink: (meetingSettingsId: string) => void;
  deleting: boolean;
};

export const LinkAdminCard = ({
  linkName,
  link: {
    id,
    name,
    active,
    slug,
    durations,
    defaultDuration,
    availabilityRestriction,
    linkMembers,
    singleUse,
  },
  onChangeActive,
  onClickCard,
  onShareTimes,
  onEditLink,
  onCloneLink,
  onDeleteLink,
  deleting,
}: Props) => {
  const formattedDuration =
    durations.length > 1 ? `${durations.length} duration options` : formatDuration(defaultDuration);
  const trackEvent = useTracking();
  const [updating, setUpdating] = useState(false);
  const [copied, setCopied] = useResettingBoolean(false);
  const [cloning, setCloning] = useState(false);
  const [deleteLinkDialogOpen, setDeleteLinkDialogOpen] = useState(false);
  const linkUrl = useMemo(() => getSchedulingLinkUrl(linkName, slug), [linkName, slug]);
  const linkPath = useMemo(() => getSchedulingLinkPath(linkName, slug), [linkName, slug]);

  const handleActiveChange = useCallback(
    async (checked: boolean) => {
      setUpdating(true);
      await onChangeActive(checked);
      setUpdating(false);
    },
    [onChangeActive],
  );

  const handleCardClick = useCallback(() => onClickCard(linkPath), [onClickCard, linkPath]);

  const handleEdit = useCallback(() => {
    onEditLink(linkName, slug);
  }, [linkName, onEditLink, slug]);

  const handleClone = useCallback(async () => {
    setCloning(true);
    await onCloneLink?.(linkName, slug);
    setCloning(false);
  }, [linkName, onCloneLink, setCloning, slug]);

  const handleDelete = useCallback(() => {
    if (id) {
      onDeleteLink(id);
    }
  }, [id, onDeleteLink]);

  const handleCopyLink = useCallback(async () => {
    // Copy the given link to the clipboard
    await navigator.clipboard.writeText(linkUrl);
    setCopied(true);
    trackEvent(TrackingEvents.LINKS.LINK.COPY_LINK, {
      meetingSettingsId: id,
      surface: "dashboard",
    });
  }, [id, linkUrl, setCopied, trackEvent]);

  const handleShareTimes = useCallback(() => {
    onShareTimes();
    trackEvent(TrackingEvents.LINKS.LINK.COPY_TIMES, {
      meetingSettingsId: id,
      surface: "dashboard",
    });
  }, [id, trackEvent, onShareTimes]);

  return (
    <>
      <div
        className={classNames(
          "cw-box-border cw-flex cw-flex-col  cw-px-4 cw-py-3 cw-rounded-lg cw-shadow-md cw-border-solid cw-border-2 cw-border-default cw-gap-2 cw-w-full",
          {
            "cw-bg-default": active,
            "cw-bg-neutral cw-cursor-not-allowed": !active,
          },
        )}
        cw-id="dashboard-card"
        aria-label={name}
        tabIndex={0}
      >
        <div className="cw-flex cw-justify-between cw-items-center">
          <Switch
            checked={active}
            onChange={handleActiveChange}
            disabled={updating}
            label={
              <span
                className={classNames(
                  "cw-transition-colors cw-duration-200",
                  active ? "cw-text-positive" : "cw-text-muted",
                )}
              >
                {active ? "Active" : "Inactive"}
              </span>
            }
            name="active"
            cw-id="dashboard-card-active-switch"
          />

          <div className="cw-justify-self-end cw-flex cw-flex-row">
            <Button
              rounded
              size="large"
              sentiment="neutral"
              variant="text"
              startIcon={Edit}
              aria-label="Edit link"
              onClick={handleEdit}
            />
            {id && (
              <Button
                rounded
                size="large"
                sentiment="neutral"
                variant="text"
                startIcon={Delete}
                aria-label="Delete link"
                onClick={() => setDeleteLinkDialogOpen(true)}
                disabled={deleting}
              />
            )}
          </div>
        </div>
        <div className="cw-flex cw-flex-col cw-gap-2">
          <div
            className={classNames("cw-heading-xl cw-line-clamp-2", {
              "hover:cw-text-brand-hover focus:cw-text-brand-hover active:cw-text-brand-pressed cw-cursor-pointer": active,
              "cw-text-brand-disabled": !active,
            })}
            cw-id="dashboard-card-meeting-title"
            onClick={active ? handleCardClick : undefined}
            aria-label="View booking page"
            aria-disabled={!active}
            tabIndex={0}
          >
            {name}
          </div>
          {linkMembers.length > 1 ? ( // Display in an AvatarStack of UserAvatars
            <>
              <AttendeeAvatarStack maxShown={8} size="large" overlap>
                {linkMembers.map((linkMember) => (
                  <AttendeeAvatar
                    key={linkMember.id}
                    profile={linkMember.member.person}
                    size="large"
                  />
                ))}
              </AttendeeAvatarStack>
              <div className="cw-text-neutral cw-leading-tight cw-font-body">
                {formattedDuration}
                {singleUse ? ", single-use" : ""}
              </div>
            </>
          ) : (
            <div className="cw-text-neutral cw-leading-tight cw-font-body">
              {formattedDuration}, {singleUse ? "single-use, " : ""}
              {AVAILABILITY_TEXT[availabilityRestriction]}
            </div>
          )}
        </div>
        <div className="cw-flex-1" />
        <ButtonSet>
          <Button
            startIcon={copied ? Check : undefined}
            sentiment="positive"
            disabled={!active}
            onClick={handleCopyLink}
            cw-id="dashboard-card-copy-link-button"
          >
            {copied ? "Copied" : "Copy link"}
          </Button>
          <Button
            variant="outlined"
            disabled={!active}
            onClick={handleShareTimes}
            cw-id="dashboard-card-edit-link-button"
          >
            Share times
          </Button>
          {onCloneLink && (
            <Button
              variant="outlined"
              disabled={!active || cloning}
              onClick={handleClone}
              cw-id="dashboard-card-clone-link-button"
            >
              Duplicate
            </Button>
          )}
        </ButtonSet>
      </div>
      <CwDialogConfirmRed
        header={"Are you sure you want to delete this Scheduling Link?"}
        body={"Doing so will make this link inoperable for those you have shared it with."}
        open={deleteLinkDialogOpen}
        onConfirm={() => {
          handleDelete();
          setDeleteLinkDialogOpen(false);
        }}
        onCancel={() => setDeleteLinkDialogOpen(false)}
        confirmText="Delete"
      />
    </>
  );
};
