import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateUserWorkingHoursOnboardingMutationVariables = Types.Exact<{
  input: Types.UpdateUserWorkingHoursInput;
}>;


export type UpdateUserWorkingHoursOnboardingMutation = { __typename: 'Mutation', updateUserWorkingHours: Array<{ __typename: 'WorkingHours', id: string, weekDay: string, uninterruptedRange: { __typename: 'TimeRange', start: string | null, end: string | null } | null }> };


export const UpdateUserWorkingHoursOnboardingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserWorkingHoursOnboarding"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserWorkingHoursInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserWorkingHours"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"weekDay"}},{"kind":"Field","name":{"kind":"Name","value":"uninterruptedRange"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"start"}},{"kind":"Field","name":{"kind":"Name","value":"end"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateUserWorkingHoursOnboardingMutation, UpdateUserWorkingHoursOnboardingMutationVariables>;