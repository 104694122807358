import { useMutation, useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useMonetization } from "../../../../hooks/useMonetization";
import { SendBillingGroupRequestEmailAdminMembersDocument } from "../ap-members/__generated__/APMembers.generated";
import { isAdminOfBg } from "../ap-members/ui/member-row/member-row.util";
import {
  AddManualMembersForBillingsPageDocument,
  BillingGroupsPageDocument,
  SendRequestToUpdateSeatCountDocument,
} from "./__generated__/APBillingGroups.generated";
import { APBillingGroup, formatBillingGroupForAP } from "./apBillingGroups.util";

type AdminPanelBillingGroupsResult = {
  onSendBillingGroupRequest: (bgId: string) => void;
  onAddUserToBg: (bgId: string) => void;
  onSaveSeatRequest: (bgId: string, requestedSeatCount: number) => void;
  primaryOrAdminBillingGroups: APBillingGroup[];
  otherBillingGroups: APBillingGroup[];
  loadingBillingGroups: boolean;
  calendarId: string | null;
  refetch: () => void;
};

export const useAdminPanelBillingGroups = ({
  orgId,
  primaryOrgCalendar,
}: {
  orgId: string;
  primaryOrgCalendar: string;
}): AdminPanelBillingGroupsResult => {
  const { primaryBillingGroupId } = useMonetization();
  const { data, loading, refetch } = useQuery(BillingGroupsPageDocument, {
    variables: { orgId: orgId, searchQuery: primaryOrgCalendar },
  });
  const [addManualMembers] = useMutation(AddManualMembersForBillingsPageDocument);
  const [sendSeatChangeReqest] = useMutation(SendRequestToUpdateSeatCountDocument);
  const [sendBillingGroupRequestEmail] = useMutation(
    SendBillingGroupRequestEmailAdminMembersDocument,
  );

  const { personId, calendarId } = useMemo(() => {
    const orgObject = getValue(data?.node, "Org");
    const orgPersons = getValue(
      orgObject?.orgPersonListPaginatedErrorable,
      "OrgPersonListPaginated",
    );
    if (orgPersons) {
      return {
        personId: orgPersons?.searchPersons?.[0]?.person?.personId || null,
        calendarId: orgPersons?.searchPersons?.[0]?.person?.primaryCalendarId || null,
      };
    } else {
      return { personId: null, calendarId: null };
    }
  }, [data]);

  const loadingBillingGroups = loading || !data;

  const { primaryOrAdminBillingGroups, otherBillingGroups } = useMemo(() => {
    const otherBgList = [];
    const primaryOrAdminBGsFormatted = [];
    const orgFromData = getValue(data?.node, "Org");
    const bgsFromData = getValue(orgFromData?.billingGroups, "BillingGroupList");
    const teamsErrorable = getValue(data?.node, "Org")?.userTeams;
    const teamsList = getValue(teamsErrorable)?.list || [];
    if (bgsFromData) {
      for (const bg of bgsFromData.list) {
        if (bg.id === primaryBillingGroupId) {
          // unshift, we want primary to sit on top of the list
          primaryOrAdminBGsFormatted.unshift(
            formatBillingGroupForAP(primaryOrgCalendar, bg, teamsList),
          );
        } else if (isAdminOfBg(bg)) {
          primaryOrAdminBGsFormatted.push(
            formatBillingGroupForAP(primaryOrgCalendar, bg, teamsList),
          );
        } else {
          otherBgList.push(formatBillingGroupForAP(primaryOrgCalendar, bg, teamsList));
        }
      }
    }
    return {
      primaryOrAdminBillingGroups: primaryOrAdminBGsFormatted,
      otherBillingGroups: otherBgList,
    };
  }, [data, primaryBillingGroupId, primaryOrgCalendar]);

  const onSendBillingGroupRequest = useCallback(
    async (bgId: string) => {
      if (!personId) {
        logger.error("PersonId not found for user in APBillingGroups");
        return;
      }
      await sendBillingGroupRequestEmail({
        variables: {
          input: {
            orgRelayId: orgId,
            billingGroupId: bgId,
            personId: personId,
          },
        },
        onCompleted: () => {
          toast.success("Request sent");
        },
        onError: () => {
          logger.error("Failed to send request to join billing group");
          toast.error("Request failed to send");
        },
      });
    },
    [orgId, personId, sendBillingGroupRequestEmail],
  );

  const onSaveSeatRequest = useCallback(
    async (bgId: string, requestedSeatCount: number) => {
      await sendSeatChangeReqest({
        variables: {
          input: {
            orgId: orgId,
            billingGroupId: bgId,
            requestedQuantity: requestedSeatCount,
          },
        },
        onCompleted: () => {
          toast.success("Seat change request sent");
          void refetch();
        },
        onError: () => {
          logger.error("Failed to send request to change seat count");
          toast.error("Request failed to send");
        },
      });
    },
    [sendSeatChangeReqest, orgId, refetch],
  );

  const onAddUserToBg = useCallback(
    async (bgId: string) => {
      if (!personId) {
        logger.error("PersonId not found for user in APBillingGroups");
        return;
      }

      await addManualMembers({
        variables: {
          input: {
            orgRelayId: orgId,
            billingGroupId: bgId,
            personIds: [personId],
          },
        },
        onCompleted: () => {
          toast.success("Successfully added you to billing group");
          void refetch();
        },
        onError: () => {
          logger.error("Failed to add user to billing group");
          toast.error("Billing group addition failed");
        },
      });
    },
    [addManualMembers, personId, orgId, refetch],
  );

  return useMemo(() => {
    return {
      onSendBillingGroupRequest,
      onAddUserToBg,
      primaryOrAdminBillingGroups,
      otherBillingGroups,
      loadingBillingGroups,
      calendarId,
      onSaveSeatRequest,
      refetch,
    };
  }, [
    onSendBillingGroupRequest,
    onAddUserToBg,
    primaryOrAdminBillingGroups,
    otherBillingGroups,
    loadingBillingGroups,
    calendarId,
    onSaveSeatRequest,
    refetch,
  ]);
};
