import React, { useEffect } from "react";
import Helmet from "react-helmet";
import PlansAndBilling from "../components/admin-plans-and-billing/PlansAndBilling";
import { PageEvents, page } from "../util/analytics.util";

const PlansAndBillingPage = () => {
  useEffect(() => {
    page(PageEvents.PLANS_AND_BILLING_PAGE);
  }, []);

  return (
    <>
      <Helmet>
        <title>Plans and billing · Clockwise</title>
      </Helmet>
      <PlansAndBilling />
    </>
  );
};

export default PlansAndBillingPage;
