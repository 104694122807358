import { ResponseType } from "@clockwise/schema";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useCallback, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { ConfirmationModal } from "./ConfirmationModal";
import { useSavePMFSurveyResponses } from "./hooks/useSavePMFSurveyResponses";
import { PMFSatisfactionDialog } from "./PMFSatisfactionDialog";
import { PMFSurveyQuestionsModal } from "./PMFSurveyQuestionsModal";
import { DisappointmentResponseType } from "./types";

const getDisplayTextForDisappointmentResponseType = (level: DisappointmentResponseType) => {
  switch (level) {
    case ResponseType.NotDisappointed:
      return "not disappointed";
    case ResponseType.SomewhatDisappointed:
      return "somewhat disappointed";
    case ResponseType.VeryDisappointed:
      return "very disappointed";
  }
};

const enum SurveyStep {
  DIALOG = "dialog",
  MODAL = "modal",
  CONFIRMATION = "confirmation",
  FINISHED = "finished",
}

export const PMFSurveyWrapper = () => {
  const [_, setHasUserDismissedPMFDialog] = useLocalStorage<boolean>(
    "Clockwise:PMFDialogDismissed",
    false,
  );
  const track = useTracking();
  const [currentStep, setCurrentStep] = useState<SurveyStep>(SurveyStep.DIALOG);
  const [
    disappointmentResponseType,
    setDisappointmentResponseType,
  ] = useState<DisappointmentResponseType | null>(null);

  const { saveDissapointmentResponse, saveSurveyFreeformFields } = useSavePMFSurveyResponses();

  const handleSubmitDisappointmentResponseType = (level: DisappointmentResponseType) => {
    setDisappointmentResponseType(level);
    setCurrentStep(SurveyStep.MODAL);
    saveDissapointmentResponse(level);
    track(TrackingEvents.PMF_SURVEY.INITIAL_SUBMIT);
  };

  const submitSurveyFields = useCallback(
    (whyText: string, benefitText: string, improvementText: string, alternativeText: string) => {
      setCurrentStep(SurveyStep.CONFIRMATION);
      saveSurveyFreeformFields(
        disappointmentResponseType,
        whyText,
        benefitText,
        improvementText,
        alternativeText,
      );
      track(TrackingEvents.PMF_SURVEY.FREEFORM_SUBMIT);
    },
    [disappointmentResponseType],
  );
  const finishSurveyFlow = () => {
    setCurrentStep(SurveyStep.FINISHED);
  };
  const exitSurveyModal = () => {
    finishSurveyFlow();
    track(TrackingEvents.PMF_SURVEY.EXIT_MODAL);
  };

  const exitDialog = () => {
    finishSurveyFlow();
    track(TrackingEvents.PMF_SURVEY.EXIT_DIALOG);
    setHasUserDismissedPMFDialog(true);
  };

  return (
    <div>
      <PMFSatisfactionDialog
        isOpen={currentStep === SurveyStep.DIALOG}
        onClose={exitDialog}
        onSubmit={handleSubmitDisappointmentResponseType}
      />
      {disappointmentResponseType && (
        <PMFSurveyQuestionsModal
          isOpen={currentStep === SurveyStep.MODAL}
          onClose={exitSurveyModal}
          onSubmit={submitSurveyFields}
          satisfactionResponse={getDisplayTextForDisappointmentResponseType(
            disappointmentResponseType,
          )}
        />
      )}
      <ConfirmationModal
        isOpen={currentStep === SurveyStep.CONFIRMATION}
        onClose={finishSurveyFlow}
      />
    </div>
  );
};
