import { fg_positive } from "@clockwise/design-system/tokens";
import { Menu as HeadlessMenu, Portal, Transition } from "@headlessui/react";
import classNames from "classnames";
import { CheckCircle } from "phosphor-react";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";
import { useBoolean } from "usehooks-ts";
import { useNuxPopoverStates } from "../superpowers-checklist/useNuxPopoverStates";
import { AppNavLinkContent } from "./AppNavLink";

const ABOVE_EVERYTHING = 10000;

// TODO: Refactor this?
export const AppNavMenu = ({
  button,
  children,
}: {
  activePaths?: string[];
  button: React.ReactNode;
  children: React.ReactNode;
}) => {
  const { showAppOverlay } = useNuxPopoverStates();
  const [isActive, setIsActive] = useState(false);
  const { value: isOpen, setTrue: setOpenTrue, setFalse: setOpenFalse } = useBoolean(isActive);

  useEffect(() => {
    const CLOSE_DELAY = 200;
    let timer: ReturnType<typeof setTimeout> | null = null;

    if (isActive && !isOpen) {
      setOpenTrue();
      if (timer) {
        clearTimeout(timer);
      }
    } else if (!isActive && isOpen) {
      timer = setTimeout(() => {
        setOpenFalse();
      }, CLOSE_DELAY);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isActive, isOpen, setOpenFalse, setOpenTrue]);

  const menuItemsRef = useRef<HTMLDivElement | null>(null);
  const [trigger, setTrigger] = useState<HTMLButtonElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update: updatePopperInstance } = usePopper(trigger, container, {
    placement: "right-start",
    strategy: "fixed",
    modifiers: [
      {
        name: "flip",
        enabled: true,
      },
      { name: "offset", options: { offset: [0, 8] } },
    ],
  });

  const removeMenuContent = () => {
    if (!showAppOverlay) {
      setIsActive(false);
    }
  };

  const addMenuContent = () => {
    if (!showAppOverlay) {
      setIsActive(true);
    }
  };

  return (
    <>
      <HeadlessMenu>
        <div
          onMouseEnter={addMenuContent}
          onMouseLeave={removeMenuContent}
          onFocus={addMenuContent}
          onBlur={removeMenuContent}
        >
          <HeadlessMenu.Button
            as="div"
            className={classNames("cw-group")}
            ref={setTrigger}
            tabIndex={0}
          >
            {button}
          </HeadlessMenu.Button>
          <Portal>
            <div
              ref={setContainer}
              style={{
                ...styles.popper,
                minWidth: trigger?.scrollWidth,
                zIndex: ABOVE_EVERYTHING,
              }}
              {...attributes.popper}
            >
              <Transition
                show={isOpen}
                leave="cw-transition cw-ease-in cw-duration-[25ms]"
                leaveFrom="cw-opacity-90"
                leaveTo="cw-opacity-10 cw-translate-x-[-4px]"
                enter="cw-transition cw-ease-in cw-duration-75"
                enterFrom="cw-opacity-10 cw-translate-x-[-4px]"
                enterTo="cw-opacity-90"
              >
                <HeadlessMenu.Items
                  className={classNames(
                    "cw-z-50",
                    "cw-min-w-[220px] cw-overflow-auto",
                    "cw-rounded-lg",
                    "cw-shadow-selectPopup",
                    "cw-bg-default",
                    "cw-flex cw-flex-col cw-space-y-1",
                    "focus:cw-ring-0 focus:cw-ring-offset-0 focus:cw-outline-none",
                    "cw-body-base cw-font-body cw-font-normal cw-text-13",
                  )}
                  ref={(element) => {
                    // Update popper positioning after the content is rendered
                    void updatePopperInstance?.();
                    menuItemsRef.current = element;
                  }}
                >
                  {children}
                </HeadlessMenu.Items>
              </Transition>
            </div>
          </Portal>
        </div>
      </HeadlessMenu>
    </>
  );
};

export const AppNavLinkMenu = ({
  activePaths = [],
  children,
  icon,
  label,
}: {
  activePaths?: string[];
  children: React.ReactNode;
  icon: React.FC<{ isActive: boolean }>;
  label?: string;
}) => {
  const location = useLocation();
  const hasActivePath = activePaths.some((path) => location.pathname.includes(path));

  return (
    <AppNavMenu button={<AppNavLinkContent isActive={hasActivePath} icon={icon} label={label} />}>
      {children}
    </AppNavMenu>
  );
};

export const AppNavLinkMenuGroup = ({
  children,
  label,
}: {
  children: React.ReactNode;
  label?: string;
}) => {
  return (
    <>
      <div>
        {label && (
          <span
            role="label"
            className={classNames(
              "cw-text-subtle cw-flex cw-items-center",
              "cw-text-12 cw-font-semibold cw-cursor-pointer cw-select-none cw-relative cw-px-2 cw-py-[5px]",
            )}
          >
            {label}
          </span>
        )}
        {children}
      </div>
      <hr className="last:cw-hidden cw-border-solid cw-border-subtle cw-border-b cw-border-t-0 cw-block cw-mx-2 cw-my-1" />
    </>
  );
};

export const AppNavLinkMenuItem = ({
  icon,
  label,
  sentiment = "neutral",
  to,
}: {
  icon: ReactElement;
  label?: string;
  sentiment?: "neutral" | "warning" | "positive";
  to: NavLinkProps["to"];
}) => {
  return (
    <HeadlessMenu.Item>
      <NavLink to={to} className={classNames("cw-group", "cw-no-underline")}>
        {({ isActive }) => (
          <div
            className={classNames(
              "cw-body-sm",
              {
                "cw-text-default": sentiment === "neutral",
                "cw-text-warning": sentiment === "warning",
                "cw-text-positive": sentiment === "positive",
              },
              "cw-px-3 cw-py-1",
              "cw-flex cw-flex-row cw-items-center cw-space-x-2",
              "cw-select-none",
              "hover:cw-bg-neutral-inset",
              isActive ? "cw-bg-neutral" : "",
              isActive ? "cw-font-medium" : "",
            )}
            tabIndex={0}
          >
            {icon}
            <div
              className={classNames("cw-text-13", "cw-grow", "cw-max-w-[160px] cw-line-clamp-1")}
            >
              {label}
            </div>
            {isActive && (
              <CheckCircle className="cw-mr-2" size={16} color={fg_positive} weight="fill" />
            )}
          </div>
        )}
      </NavLink>
    </HeadlessMenu.Item>
  );
};

export const AppActionMenuItem = ({
  icon,
  label,
  sentiment = "neutral",
  onClick,
}: {
  icon: ReactElement;
  label?: string;
  sentiment?: "neutral" | "warning";
  onClick: () => void;
}) => {
  return (
    <HeadlessMenu.Item>
      <div
        className={classNames(
          "cw-body-sm",
          {
            "cw-text-default": sentiment === "neutral",
            "cw-text-warning": sentiment === "warning",
          },
          "cw-px-3 cw-py-1",
          "cw-flex cw-flex-row cw-items-center cw-space-x-2",
          "hover:cw-bg-default-hover",
          "cw-select-none",
          "cw-cursor-pointer",
        )}
        onClick={onClick}
        tabIndex={0}
      >
        {icon}
        <div className={classNames("cw-text-13")}>{label}</div>
      </div>
    </HeadlessMenu.Item>
  );
};
