import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar";
import { ICalPositionable } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useReadHoverEvent } from "../../chat-plus-calendar/util/HoverEventContext";
import { usePlannerContext } from "../Context";
import { MIN_DURATION } from "../planner-event/PlannerEvents";
import { ProposalSlot } from "./ProposalSlot";
import { getDateStringKeyFromDateTime } from "./utils/getDateStringKeyFromDateTime";
import { getFullWidthPositionerData } from "./utils/getFullWidthPositionerData";

export const ProposalOverlay = ({
  dateTime,
  calendarIds,
  primaryCalendarId,
  minWidthPercent,
  maxFullColumns,
}: {
  dateTime: DateTime;
  calendarIds: string[];
  primaryCalendarId: string;
  minWidthPercent: number;
  maxFullColumns: number;
}) => {
  const hoveredEventIdOrDiffId = useReadHoverEvent();

  const { eventsByDayAndCalendar } = usePlannerContext();
  const dateToRender = getDateStringKeyFromDateTime(dateTime);

  const eventsByCalendarForDay = useMemo(() => {
    return eventsByDayAndCalendar ? eventsByDayAndCalendar[dateToRender] : null;
  }, [dateToRender, eventsByDayAndCalendar]);

  const proposalEvents = useMemo(() => eventsByCalendarForDay?.proposalEvents ?? [], [
    eventsByCalendarForDay,
  ]);

  const event = proposalEvents[0];
  const proposalAttendeeIds = event?.proposalMetaData?.attendeeIds ?? [];

  const { widthPercent, maxColumnsExceeded, columnCountOverride } = getFullWidthPositionerData(
    proposalAttendeeIds,
    calendarIds,
    maxFullColumns,
    minWidthPercent,
  );

  const fade = hoveredEventIdOrDiffId ? hoveredEventIdOrDiffId !== event?.diffSummaryId : false;

  const positionables: ICalPositionable[] = useMemo(
    () => [
      {
        key: event?.interval?.toISO(),
        interval: event?.interval,
        render: () => (
          <ProposalSlot calendarId={primaryCalendarId} widthPercent={widthPercent} fade={fade} />
        ),
      },
    ],
    [event?.interval, primaryCalendarId, widthPercent, fade],
  );

  if (isEmpty(proposalEvents)) {
    return null;
  }

  return (
    <div>
      <CalendarPositioner
        dateTimes={[dateTime]}
        positionables={positionables}
        minWidth={minWidthPercent}
        columnCountOverride={columnCountOverride}
        fullWidth={maxColumnsExceeded}
        minDuration={MIN_DURATION}
      />
    </div>
  );
};
