import { ApolloError } from "@apollo/client";
import {
  useReadTimeSuggestion,
  useUpdateTimeSuggestion,
} from "@clockwise/web-commons/src/util/TimeSuggestionContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { clearAllSelected as clearAllSelectedCalendarsInMultiCalendar } from "../../../state/actions/multi-calendar.actions";
import { useActiveProposal } from "../hooks/useActiveProposal";
import { useSetAIError } from "./hooks/AIErrorContext";
import { useOnSplitView } from "./hooks/useOnSplitView";
import { useSelectSchedulingOption } from "./hooks/useSelectSchedulingOption";

export const ChatPlusCalendarTimeSuggestionSync = () => {
  const track = useTracking();
  const setError = useSetAIError();
  const dispatch = useDispatch();

  const isOnSplitView = useOnSplitView();

  // set when the user clicks on a time suggestion in the chat or calendar
  const { index: userSelectedIndex } = useReadTimeSuggestion();

  const { proposal } = useActiveProposal();
  const proposalSelectedIndex =
    proposal?.options.__typename === "SchedulingOptions"
      ? proposal.options.selectedIndex
      : undefined;

  const { setTimeSuggestionLoading, setTimeSuggestionIndex } = useUpdateTimeSuggestion();

  const resetState = useCallback(() => {
    setTimeSuggestionLoading(false);
    // set userSelectedTimeIndex to null to prevent the effect from running again
    setTimeSuggestionIndex(null);
    // prevent useEffect immediately (even before `setTimeSuggestionIndex` resolves)
    justCalledMutationAndStillWaitingForResponseRef.current = false;
  }, [setTimeSuggestionLoading, setTimeSuggestionIndex]);

  const justCalledMutationAndStillWaitingForResponseRef = useRef(false);

  const onError = useCallback(
    (error: ApolloError) => {
      resetState();

      setError({ error, message: "Failed to select time", showUserMessage: true });
    },
    [setError, resetState],
  );

  const onCompleted = useCallback(() => {
    resetState();
  }, [resetState]);

  const [onSelectSchedulingOption] = useSelectSchedulingOption({
    onError,
    onCompleted,
  });

  useEffect(() => {
    if (
      userSelectedIndex === null ||
      userSelectedIndex === proposalSelectedIndex ||
      justCalledMutationAndStillWaitingForResponseRef.current
    ) {
      return;
    }

    void onSelectSchedulingOption(userSelectedIndex);
    // Reset MultiCalendar state if the user selected a `FIXABLE_CONFLICT` option
    if (
      proposal?.options.__typename == "SchedulingOptions" &&
      proposal?.options?.optionDetails?.[userSelectedIndex].category === "FIXABLE_CONFLICT" &&
      !isOnSplitView
    ) {
      dispatch(clearAllSelectedCalendarsInMultiCalendar());
    }

    justCalledMutationAndStillWaitingForResponseRef.current = true;
    track(TrackingEvents.CHAT.PROPOSAL.SELECT_OPTION_CLICKED);
  }, [userSelectedIndex, proposalSelectedIndex, onSelectSchedulingOption, track]);

  return null;
};
