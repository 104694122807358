import { ApolloCache, useMutation } from "@apollo/client";
import { UpdateNotificationStrategyInput, UpdateScheduleAs } from "@clockwise/schema";
import { isEqual, merge } from "lodash";
import { useCallback, useMemo } from "react";
import {
  UpdateTravelTimeDocument,
  UpdateTravelTimeMutation,
} from "../graphql/__generated__/UpdateTravelTime.generated";

import { logger } from "@clockwise/client-commons/src/util/logger";
import { TravelTimeSettings } from "../types";

export const useTravelTimeUpdate = (
  orgId: string,
  travelTimeSettings: TravelTimeSettings,
  {
    onCompleted,
    onError,
    onUpdate,
  }: {
    onCompleted?: () => void;
    onError?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUpdate?: (cache: ApolloCache<any>) => void;
  },
) => {
  const [updateTravelTime, { loading, error }] = useMutation(UpdateTravelTimeDocument);

  const onErrorHandler = useMemo(() => onError, [onError]);
  const onUpdateHandler = useMemo(() => onUpdate, [onUpdate]);

  const onCompleteHandler = useCallback(
    (expectedData: UpdateTravelTimeMutation) => (data: UpdateTravelTimeMutation) => {
      if (!isEqual(expectedData.UpdateTravelTime, data.UpdateTravelTime)) {
        // @scott - cleanup as part of AI-2044
        // logging to sentry to confrim error severtiy
        logger.error("Travel Time Update: Optimistic update does not match server response.", {
          expectedData,
          data,
        });

        onError?.();
      } else {
        onCompleted?.();
      }
    },
    [onCompleted, onError],
  );

  const update = useCallback(
    (updatedSettings: Partial<TravelTimeSettings>) => {
      const newSettings = merge(travelTimeSettings, updatedSettings);

      const variables = {
        orgId: orgId,
        userAddress: newSettings.address,
        clearAddress: !newSettings.address,
        scheduleAs: UpdateScheduleAs[newSettings.scheduleAs],
        notificationStrategy: UpdateNotificationStrategyInput[newSettings.notificationStrategy],
      };

      const optimisticResponse: UpdateTravelTimeMutation = {
        UpdateTravelTime: {
          travelTimeGoals: {
            id: newSettings.id,
            enabled: newSettings.enabled,
            userAddress: newSettings.address
              ? {
                  __typename: "Address",
                  rawAddress: newSettings.address,
                }
              : {
                  __typename: "NoAddress",
                },
            remoteHoldSettings: {
              scheduleAs: newSettings.scheduleAs,
              notificationStrategy: newSettings.notificationStrategy,
              __typename: "RemoteSmartHoldSettings",
            },
            __typename: "TravelTimeGoals",
          },
          __typename: "UpdateTravelTimeGoalResponse",
        },
        __typename: "Mutation",
      };

      return updateTravelTime({
        variables,
        optimisticResponse,
        onCompleted: onCompleteHandler(optimisticResponse),
        onError: onErrorHandler,
        update: onUpdateHandler,
      });
    },
    [
      onCompleteHandler,
      onErrorHandler,
      onUpdateHandler,
      orgId,
      travelTimeSettings,
      updateTravelTime,
    ],
  );

  return { update, loading, error };
};

export default useTravelTimeUpdate;
