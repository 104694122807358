import { Button, Modal } from "@clockwise/design-system";
import React from "react";

export const NuxStartingModal = ({
  isOpen,
  onClose,
  srcImage,
  givenName,
}: {
  isOpen: boolean;
  onClose: () => void;
  srcImage: string;
  givenName: string | null;
}) => {
  return (
    <Modal
      centered={true}
      radius={11}
      size="550px"
      opened={isOpen}
      withCloseButton={false}
      styles={{
        body: {
          height: "284px",
          overflow: "hidden",
          backgroundColor: "#FDF9F7", // Marketing color
        },
      }}
      onClose={onClose}
      padding={0}
      closeOnClickOutside={true}
    >
      <img className={`cw-w-[550px]`} src={srcImage} aria-hidden="true" />
      <div className="cw-absolute cw-bottom-6 cw-right-6">
        <Button onClick={onClose} size="small" sentiment="accent">
          Get started
        </Button>
      </div>
      <div className="cw-absolute cw-top-[20%] cw-left-[15%] cw-max-w-[390px]">
        <div className="cw-flex cw-flex-col cw-gap-1">
          {givenName ? (
            <p className="cw-text-[#003f2e] cw-font-bold cw-heading-2xl">
              {givenName}, let's give your calendar superpowers
            </p>
          ) : (
            <p className="cw-text-[#003f2e] cw-font-bold cw-heading-2xl">
              Let's give your calendar superpowers
            </p>
          )}
          <div className="cw-text-[#003f2e] cw-body-base">
            Make the most of your time with Clockwise, the intelligent assistant that helps you
            effortlessly <span className="cw-font-semibold">schedule meetings</span> and{" "}
            <span className="cw-font-semibold">manage your calendar.</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};
