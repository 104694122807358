import { IIntegerDataPoint, IOrgOverview } from "#webapp/src/__schema__";
import {
  CURRENT_POSITION_RESPONSES,
  TYPE_OF_WORK_RESPONSES,
} from "#webapp/src/components/web-onboarding/step-components/get-to-know-you-step/GetToKnowYouStep.util";
import { IResponse } from "./AdminPanelOverviewTypes";

function remapResponseLabels(
  data: IIntegerDataPoint[],
  responses: IResponse[],
): IIntegerDataPoint[] {
  const mappedLabels: { [type: string]: string } = {};
  responses.forEach((r) => {
    mappedLabels[r.responseType] = r.responseText;
  });

  return data.map((d) => {
    return { ...d, label: mappedLabels[d.label] || d.label };
  });
}

export function remapOrgOverviewLabels(orgOverview: IOrgOverview) {
  const mappedOrgOverview = { ...orgOverview };
  const { numUsersByFunction, numUsersByRole } = orgOverview;

  if (numUsersByFunction) {
    mappedOrgOverview.numUsersByFunction = remapResponseLabels(
      numUsersByFunction,
      TYPE_OF_WORK_RESPONSES,
    );
  }

  if (numUsersByRole) {
    mappedOrgOverview.numUsersByRole = remapResponseLabels(
      numUsersByRole,
      CURRENT_POSITION_RESPONSES,
    );
  }

  return mappedOrgOverview;
}
