import { SchedulingOptionCategoryTypeEnum, SchedulingOptionTypeEnum } from "@clockwise/schema";
import { Interval } from "luxon";
import { SchedulingOptionsTradeoffs } from "../../../utils/types";
import { OptionWithIndex } from "./SchedulingPills";

export type SelectOption = {
  value: number;
  label: string;
  interval: string;
  tradeoffBlocks: SchedulingOptionsTradeoffs;
};
export type SchedulingOptionsByCategoryResult = {
  hasConflicts: SelectOption[];
  hasFixableConflicts: SelectOption[];
  worksForEveryone: SelectOption[];
};

const schedulingOptionToSelectOption = (option: OptionWithIndex): SelectOption => ({
  value: option.index,
  label: option.label,
  interval: option.interval,
  tradeoffBlocks: option.tradeoffBlocks,
});

const sortByStartTime = (a: OptionWithIndex, b: OptionWithIndex) => {
  const startMillisA = Interval.fromISO(a.interval).start.toMillis();
  const startMillisB = Interval.fromISO(b.interval).start.toMillis();

  return startMillisA - startMillisB;
};

export const parseSchedulingOptionsGQLByCategory = (
  options: OptionWithIndex[],
): SchedulingOptionsByCategoryResult => ({
  hasConflicts: options
    .filter(
      (option) =>
        option.category === SchedulingOptionCategoryTypeEnum.AVAILABILITY_ISSUE &&
        option.type !== SchedulingOptionTypeEnum.CUSTOM,
    )
    .sort(sortByStartTime)
    .map(schedulingOptionToSelectOption),
  hasFixableConflicts: options
    .filter(
      (option) =>
        option.category === SchedulingOptionCategoryTypeEnum.FIXABLE_CONFLICT &&
        option.type !== SchedulingOptionTypeEnum.CUSTOM,
    )
    .sort(sortByStartTime)
    .map(schedulingOptionToSelectOption),
  worksForEveryone: options
    .filter(
      (option) =>
        (option.category === null ||
          option.category === SchedulingOptionCategoryTypeEnum.INCONVENIENCE ||
          option.category === SchedulingOptionCategoryTypeEnum.OUT_OF_TIME_RANGE) &&
        option.type !== SchedulingOptionTypeEnum.CUSTOM,
    )
    .sort(sortByStartTime)
    .map(schedulingOptionToSelectOption),
});
