import { noop } from "lodash";
import React, { ReactNode, UIEvent, createContext, useContext, useMemo, useRef } from "react";
import invariant from "tiny-invariant";

type HorizonalScrollState = {
  headerRef: React.RefObject<HTMLDivElement> | null;
  calendarRef: React.RefObject<HTMLDivElement> | null;
};

const emptyState: HorizonalScrollState = {
  headerRef: null,
  calendarRef: null,
};

type HorizonalScrollSyncActions = {
  handleScrollHeader: (scroll: UIEvent<HTMLDivElement>) => void;
  handleScrollCalendar: (scroll: UIEvent<HTMLDivElement>) => void;
};

type ProviderProps = {
  children: ReactNode;
};

const ReadContext = createContext<HorizonalScrollState>(emptyState);
const WriteContext = createContext<HorizonalScrollSyncActions>({
  handleScrollHeader: noop,
  handleScrollCalendar: noop,
});

export const HorizontalScrollSyncProvider = ({ children }: ProviderProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  const readContext = useMemo(
    () => ({
      headerRef,
      calendarRef,
    }),
    [headerRef, calendarRef],
  );
  // Scroll event typed as any because typescript says UIEvent<HTMLDivElement> doesn't have attribute scrollLeft
  // even though it exists  when you console.log the event
  const handleScrollHeader = (scroll: any) => {
    if (calendarRef?.current) {
      calendarRef.current.scrollLeft = scroll.target.scrollLeft;
    }
  };

  // Scroll event typed as any because typescript says UIEvent<HTMLDivElement> doesn't have attribute scrollLeft
  // even though it exists  when you console.log the event
  const handleScrollCalendar = (scroll: any) => {
    if (headerRef?.current) {
      headerRef.current.scrollLeft = scroll.target.scrollLeft;
    }
  };

  const writeContext = useMemo(
    () => ({
      handleScrollHeader,
      handleScrollCalendar,
    }),
    [handleScrollHeader, handleScrollCalendar],
  );

  return (
    <WriteContext.Provider value={writeContext}>
      <ReadContext.Provider value={readContext}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};
export const useReadHorizontalScrollSync = () => useContext(ReadContext);

export const useUpdateHorizontalScrollSync = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdateHorizontalScrollSync must be within HorizontalScrollSyncProvider");
  return context;
};
