import classNames from "classnames";
import React, { ReactNode } from "react";
import { Source } from "./Source";

export const EventThread: React.FC<{ children: ReactNode; spacing?: "Default" | "Compact" }> = ({
  children,
  spacing = "Default",
}) => {
  const sourceChildren: React.ReactChild[] = [];
  const lineChildren: React.ReactChild[] = [];
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && typeof child.type !== "string") {
      if (child.type.name === Source.name) {
        sourceChildren.push(child);
      } else {
        lineChildren.push(child);
      }
    }
  });

  return (
    <div cw-id="event-thread" className="cw-body-base cw-z-0 cw-relative">
      <div>{sourceChildren}</div>
      <div
        className={classNames("cw-relative cw-flex cw-flex-col", {
          "cw-gap-3": spacing === "Default",
          "cw-gap-1.5": spacing === "Compact",
        })}
      >
        {lineChildren}
      </div>
    </div>
  );
};
