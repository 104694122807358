import { LunchGoals, NotificationStrategy, ScheduleAs, TimeSlot } from "@clockwise/schema";

import { Duration } from "luxon";
import { LunchSettings } from "../types";

export const defaultSettings: LunchSettings = {
  id: "",
  enabled: false,
  idealDuration: Duration.fromObject({ minute: 60 }),
  latestEnd: TimeSlot.T_23_30,
  minimumDuration: Duration.fromObject({ minute: 45 }),
  earliestStart: TimeSlot.T_00_00,
  notificationStrategy: NotificationStrategy.NotifyInGCal,
  scheduleAs: ScheduleAs.Busy,
};

export const transformLunchGoalsToSettings = (lunchGoal: LunchGoals | undefined) => {
  const settings = { ...defaultSettings };

  if (lunchGoal) {
    const { id, enabled, remoteHoldSettings, preferredLunchTimes } = lunchGoal;

    if (id) settings.id = id;
    settings.enabled = !!enabled;

    if (preferredLunchTimes) {
      const { earliestStart, latestEnd, minimumDuration, idealDuration } = preferredLunchTimes;

      if (earliestStart) settings.earliestStart = earliestStart;
      if (latestEnd) settings.latestEnd = latestEnd;
      if (minimumDuration && typeof minimumDuration === "string") {
        settings.minimumDuration = Duration.fromISO(minimumDuration);
      }
      if (idealDuration && typeof idealDuration === "string") {
        settings.idealDuration = Duration.fromISO(idealDuration);
      }
    }

    if (remoteHoldSettings) {
      const { notificationStrategy, scheduleAs } = remoteHoldSettings;
      if (notificationStrategy) settings.notificationStrategy = notificationStrategy;
      if (scheduleAs) settings.scheduleAs = scheduleAs;
    }
  }

  return settings;
};

export default transformLunchGoalsToSettings;
