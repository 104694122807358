import React, { useEffect } from "react";
import Helmet from "react-helmet";
import AdminAnalytics from "../components/admin-analytics/AdminAnalytics";
import { PageEvents, page } from "../util/analytics.util";

const AdminAnalyticsPage = () => {
  useEffect(() => {
    page(PageEvents.ANALYTICS_PAGE);
  }, []);

  return (
    <>
      <Helmet>
        <title>Analytics · Clockwise</title>
      </Helmet>
      <AdminAnalytics />
    </>
  );
};

export default AdminAnalyticsPage;
