import { DateRange } from "@clockwise/design-system/icons";
import React from "react";
import AdminSelect, { AdminSelectOptions, AdminSelectProps } from "../admin-select";
import getTimeframeDescription from "./utils/getTimeframeDescription";

type Props = Omit<AdminSelectProps, "icon" | "options">;

const TimeframeSelect = (props: Props) => {
  const TimeframeSelectOptionsTuple: AdminSelectOptions = React.useMemo(
    () => [
      ["week", getTimeframeDescription("week", "Last week")],
      ["month", getTimeframeDescription("month", "Last month")],
      ["year", getTimeframeDescription("year", "Last year")],
    ],
    [],
  );

  return <AdminSelect icon={DateRange} options={TimeframeSelectOptionsTuple} {...props} />;
};

export default TimeframeSelect;
