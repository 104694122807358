import { FLOW_STATE_KEYS } from "#webapp/src/state/flow-state";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { WebAppSlackQueryQuery } from "./__generated__/WebAppSlack.generated";

export type UserWithFlowStates = {
  flowStates: FlowStateConnectionWithEdges | null;
};

export type FlowStateConnectionWithEdges = {
  edges?: Array<FlowStateEdgeWithNode | null> | null;
};

export type FlowStateEdgeWithNode = {
  node: FlowStateNode | null;
};

export type FlowStateNode = {
  id: string;
  flowKey: string | null;
  previousState: string | null;
  current: FlowStatus | null;
  pending: FlowStatus | null;
};

export type FlowStatus = {
  id: string;
  state: string | null;
  percentComplete: number | null;
  errorMessage: string | null;
};

const getGenericFlowState = (flowStates: FlowStateConnectionWithEdges | null, flowKey: string) => {
  if (!flowStates || !flowStates.edges) {
    return undefined;
  }
  const flowState = flowStates.edges.filter((fs) => fs?.node?.flowKey === flowKey);
  return (flowState && flowState.length && flowState?.[0]?.node) || undefined;
};

const getOnboardingFlowState = (user: UserWithFlowStates | null) => {
  if (!user) {
    return undefined;
  }
  return getGenericFlowState(user.flowStates, FLOW_STATE_KEYS.INITIAL_ONBOARDING);
};

export const maybeTrackOnboardingFromSlackAndStoreInSession = (
  data: WebAppSlackQueryQuery | undefined,
) => {
  const viewer = data?.viewer;
  // Check if user has completed onboarding yet
  const onboardingState = viewer && getOnboardingFlowState(viewer.user);
  const currentOnboardingState = onboardingState?.current?.state;
  track(TrackingEvents.APP_SLACK.STARTED_ONBOARDING_FROM_SLACK, {
    onboardingStep: currentOnboardingState,
  });
  sessionStorage.setItem("onboardingExternalAppSource", "slack");
};

export const trackSlackComplete = () => {
  // google tag manager
  const dataLayer = window && (window as any).dataLayer;
  if (dataLayer) {
    dataLayer.push({ event: "slackConnectedToClockwise" });
  }
};

export const getInstallSlackAppCode = (searchParams: URLSearchParams) => {
  const installSlackAppSearchCodeParam = "code";
  return searchParams.has(installSlackAppSearchCodeParam)
    ? searchParams.get(installSlackAppSearchCodeParam)
    : "";
};

export const getInstallSlackAppState = (searchParams: URLSearchParams) => {
  const installSlackAppSearchStateParam = "state";
  return searchParams.has(installSlackAppSearchStateParam)
    ? searchParams.get(installSlackAppSearchStateParam)
    : "";
};
