import { DateTime } from "luxon";
import { TimeframeDuration, Timeframes } from "../consts";

const getTimeframeDates = (timeframe: Timeframes, maxDate?: Date) => {
  const duration = TimeframeDuration[timeframe];
  maxDate = maxDate || new Date();
  const maxLux = DateTime.fromJSDate(maxDate).minus({ days: 1 }).startOf("day");
  let minLux = maxLux.minus(duration);

  if (timeframe === "year") {
    // If year, start a year ago plus one month
    minLux = minLux.minus({ month: 1 }).startOf("month");
  }

  return { maxDate: maxLux.toJSDate(), minDate: minLux.toJSDate() };
};

export default getTimeframeDates;
