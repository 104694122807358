import {
  ArrowForward,
  ClockwiseIntelligence,
  Lightbulb,
  RamenDining,
  WaterDropFilled,
} from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import React from "react";
import { FeatureCard } from "../feature-card";
import { MAX_H_TALL } from "./FeatureCardGrid";
import { Tile } from "./Tile";

export const YOUR_IDEAL_DAY_NAV_ITEM_LABEL = "Your ideal day";

export const Preferences = () => {
  const withIconClasses = "cw-flex-col cw-gap-1";
  return (
    <FeatureCard
      layout="column"
      feature={{
        name: YOUR_IDEAL_DAY_NAV_ITEM_LABEL,
        path: appPaths.accountPreferences,
        endIcon: <ArrowForward />,
      }}
      className={`${MAX_H_TALL} cw-flex-wrap`}
    >
      <Tile className={`cw-basis-[82px] cw-grow ${withIconClasses}`}>
        <Lightbulb />
        Focus Time
      </Tile>
      <Tile className={`cw-basis-[82px] cw-grow ${withIconClasses}`}>
        <ClockwiseIntelligence />
        Resolve Conflicts
      </Tile>
      <Tile className={`cw-basis-[66px] cw-grow ${withIconClasses}`}>
        <RamenDining />
        Lunch
      </Tile>
      <Tile className={`cw-basis-[66px] cw-grow ${withIconClasses}`}>
        <WaterDropFilled />
        Meeting Breaks
      </Tile>
      <Tile className="cw-basis-[23px]">3 more</Tile>
    </FeatureCard>
  );
};
