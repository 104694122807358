import { HoverCard, Switch } from "@clockwise/design-system";
import { border_muted } from "@clockwise/design-system/tokens";
import { Box, withStyles } from "@material-ui/core";
import classNames from "classnames";
import { noop } from "lodash";
import React, { createContext, useContext } from "react";

export type FeatureSettingContextType = {
  enabled?: boolean;
  loading?: boolean;
  locked?: boolean;
  saving?: boolean;
  onEnabledChange?: (enable: boolean) => void;
};

export const FeatureSettingContext = createContext<FeatureSettingContextType>({
  enabled: false,
  loading: false,
  onEnabledChange: noop,
});

// DevNote: Tailwind Divider class is broken in web-commons so lets hack this in with mui CSS in JS
export const DividerBox = withStyles((theme) => ({
  root: {
    "& > * + *": {
      borderTop: `1px solid ${border_muted}`,
      paddingTop: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
  },
}))(Box);

export const FeatureSettingsContainer = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return <DividerBox className="cw-flex cw-flex-col cw-mb-12">{children}</DividerBox>;
};

type FeatureSettingProps = {
  children: JSX.Element | JSX.Element[];
  highlight?: boolean;
  id?: string;
  title: string; // Plain text title for screen readers
} & FeatureSettingContextType;

export const FeatureSetting = ({
  children,
  enabled,
  loading,
  locked,
  onEnabledChange,
  title,
}: FeatureSettingProps) => {
  return (
    <FeatureSettingContext.Provider value={{ enabled, loading, locked, onEnabledChange }}>
      <article
        aria-label={title}
        className={`
            cw-flex cw-flex-row
            md:cw-space-x-10
            cw-body-base
          `}
      >
        {children}
      </article>
    </FeatureSettingContext.Provider>
  );
};

export const FeatureSettingHeading = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { enabled, loading, locked, onEnabledChange } = useContext(FeatureSettingContext);

  return (
    <div
      className={`
        cw-flex cw-flex-row cw-flex-wrap
        cw-items-center
  `}
    >
      <span className="cw-mr-4">{children}</span>
      {onEnabledChange && (
        <span className="cw-text-brand">
          <Switch
            size="medium"
            checked={!!enabled}
            onChange={onEnabledChange}
            disabled={loading || locked}
            label={
              <>
                {!loading && !locked && enabled && <span className="cw-text-brand">Yes</span>}
                {!loading && !locked && !enabled && <span className="cw-text-default">No</span>}
              </>
            }
          />
        </span>
      )}
    </div>
  );
};

export const FeatureSettingMain = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <div className="cw-py-1 cw-w-full">{children}</div>;
};

export const FeatureSettingBody = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { enabled } = useContext(FeatureSettingContext);

  return (
    <div
      className={classNames(
        "cw-body-lg cw-py-6",
        enabled ? "cw-text-default" : "cw-text-brand-disabled",
      )}
    >
      {children}
    </div>
  );
};

export const FeatureSettingOptions = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <div>{children}</div>;
};

export const FeatureSettingAside = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <aside
      aria-hidden
      className={`
      cw-w-[160px]
      cw-hidden md:cw-block
      cw-shrink-0
  `}
    >
      {children}
    </aside>
  );
};

export const FeatureSettingsHoverCard = ({
  content,
  children,
  id,
}: {
  children: React.ReactNode;
  content: React.JSX.Element;
  id?: string;
}) => {
  return (
    <HoverCard id={id}>
      <HoverCard.Target>{children}</HoverCard.Target>
      <HoverCard.Dropdown className="cw-text-onEmphasis cw-bg-accent-emphasis cw-max-w-[280px] cw-rounded-md cw-p-2.5 cw-body-base">
        {content}
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
