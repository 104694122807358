import { TradeoffBlockDetailsFragment } from "../../chat/ai-chat/apollo/__generated__/TradeoffBlockDetails.fragment.generated";
import { SchedulingOptionsTradeoffs } from "../../chat/ai-chat/utils/types";

export function filterTradeoffsForUser(tradeoffs: SchedulingOptionsTradeoffs, calendarId: string) {
  const tradeoffsForUser: SchedulingOptionsTradeoffs = [];
  tradeoffs.forEach((tradeoffBlock) => {
    if (
      tradeoffBlock.aggregateAttendees.some((attendee) => attendee.primaryCalendarId === calendarId)
    ) {
      const tradeoffBlockForUser = {
        ...tradeoffBlock,
        aggregateAttendees: tradeoffBlock.aggregateAttendees.filter(
          (attendee) => attendee.primaryCalendarId === calendarId,
        ),
        schedulingTradeoffs: filterSchedulingTradeoffsForUser(
          tradeoffBlock.schedulingTradeoffs,
          calendarId,
        ),
      };
      tradeoffsForUser.push(tradeoffBlockForUser);
    }
  });
  return tradeoffsForUser;
}

function filterSchedulingTradeoffsForUser(
  schedulingTradeoffs: TradeoffBlockDetailsFragment["schedulingTradeoffs"],
  calendarId: string,
) {
  const schedulingTradeoffsForUser: typeof schedulingTradeoffs = [];
  schedulingTradeoffs.forEach((schedulingTradeoff) => {
    if (
      schedulingTradeoff.attendees.some((attendee) => attendee.primaryCalendarId === calendarId)
    ) {
      schedulingTradeoffsForUser.push({
        ...schedulingTradeoff,
        attendees: schedulingTradeoff.attendees.filter(
          (attendee) => attendee.primaryCalendarId === calendarId,
        ),
      });
    }
  });
  return schedulingTradeoffsForUser;
}
