import { Button } from "@clockwise/design-system";
import { LeftPanelCloseFilled } from "@clockwise/icons";
import { useAIChatCollapseContext } from "@clockwise/web-commons/src/util/AIChatCollapseContext";
import { useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { DateTime } from "luxon";
import React from "react";
import { useFeatureFlag } from "../../../../launch-darkly";
import { TrackingEvents } from "../../../../util/analytics.util";
import DebugModelDropdown from "../../web/DebugModelDropdown";
import { LinksDropdown } from "../../web/LinksDropdown";

export const AIChatToolbar = () => {
  const track = useTracking();
  const { setChatIsCollapsed } = useAIChatCollapseContext();
  const currentDate = DateTime.now().toLocaleString({
    weekday: "short",
    month: "short",
    day: "numeric",
  });
  const [isOnShowChatDebugInfo] = useFeatureFlag("ShowChatDebugInfo");

  const handleCollapseChat = () => {
    track(TrackingEvents.CHAT.SIDEBAR_COLLAPSED);
    setChatIsCollapsed(true);
  };

  return (
    <div className="cw-w-full cw-bg-neutral">
      <div className="cw-flex cw-px-4 cw-pb-3 cw-pt-3 cw-flex-row cw-items-center cw-justify-between">
        <div className="cw-flex cw-items-center">
          <div className="cw-heading-base cw-text-default cw-font-body cw-font-bold">
            {currentDate}
          </div>
        </div>
        <div className="cw-flex cw-gap-2 cw-items-center">
          <LinksDropdown />
          <Button
            onClick={handleCollapseChat}
            aria-label="Collapse chat sidebar"
            variant="outlined"
            size="xsmall"
          >
            <LeftPanelCloseFilled className="cw-text-muted cw-w-4 cw-h-4" />
          </Button>
          {isOnShowChatDebugInfo && <DebugModelDropdown />}
        </div>
      </div>
    </div>
  );
};
