// schema
import * as ISchema from "#webapp/src/__schema__";

// libraries
import * as React from "react";
import { createRefetchContainer } from "react-relay";

// sudo-modify-user imports
import { styles as sudoStyles } from "#webapp/src/components/sudo-wrapper/SudoWrapper.styles";
import withSudo from "#webapp/src/components/sudo-wrapper/WithSudo.hoc";
import { sudoResetFlowState } from "#webapp/src/mutations";
import { sudoModifyUserFragments, sudoModifyUserQuery } from "./SudoModifyUser.gql";
import { styles } from "./SudoModifyUser.styles";
import { IContainer, IProps, IState } from "./SudoModifyUserTypes";

// state
import { FLOW_STATE_KEYS } from "#webapp/src/state/flow-state";

// util
import { fromGlobalId } from "#webapp/src/util/graphql.util";
import { copyTextToClipboard } from "@clockwise/web-commons/src/util/html.util";

// material-ui
import { ITeam } from "#webapp/src/__schema__";
import { Button, LinearProgress, TextField } from "@clockwise/design-system";
import { Paper } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { SyntheticEvent } from "react";
import toast from "react-hot-toast";

export class ClickToCopyIdSpan extends React.Component<any, { bc: string }> {
  constructor(props: any) {
    super(props);
    this.state = { bc: "transparent" };
  }
  changeBorderColor = (event: SyntheticEvent, bc: string) => {
    event.stopPropagation(); // prevent parent handling to allow nesting
    this.setState({ bc });
  };
  handleClick = (event: SyntheticEvent) => {
    const s = event && event.currentTarget && event.currentTarget.textContent;
    if (s) {
      event.stopPropagation(); // prevent parent handling to allow nesting
      copyTextToClipboard(s, true);
      this.changeBorderColor(event, "#ccc");
    }
  };
  render() {
    const s = { ...sudoStyles.clickToCopyCwId, borderColor: this.state.bc };
    return (
      <span
        title="Click to copy!"
        onClick={this.handleClick}
        style={s}
        onMouseOver={(event: SyntheticEvent) => this.changeBorderColor(event, "#800")}
        onMouseOut={(event: SyntheticEvent) => this.changeBorderColor(event, "transparent")}
      >
        {this.props.children}
      </span>
    );
  }
}

///////////////
// Component //
///////////////
class SudoModifyUserCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      email: "",
    };
  }

  public handleEmailChange = (e: any) => {
    this.setState({
      email: e.target.value,
    });
  };

  private handleSearch = () => {
    const email = this.state.email.trim();
    if (!email) {
      return;
    }

    this.setState({ loading: true });
    this.props.sudoRefetch(email, {}, () => {
      this.setState({ loading: false });
    });
  };

  private handleResetFlowState = (flowKey: string, orgRelayId?: string) => {
    const email = this.state.email.trim();
    if (!email) {
      return;
    }

    this.setState({ loading: true });

    this.props.sudoCommitMutation(
      email,
      sudoResetFlowState,
      { flowKey, orgRelayId },
      () => this.onResetSuccess(),
      () => this.onResetFailure(),
    );
  };

  private onResetSuccess() {
    this.setState({ loading: false });
    toast.success("Success! Reset flow state.");
  }

  private onResetFailure() {
    this.setState({ loading: false });
    toast.error("Failed to reset flow state.");
  }

  public renderFlowStateAction(flowState: ISchema.IFlowState, orgRelayId?: string) {
    // note: the absence/presence of orgId determines whether this is a user or org flow state mutation
    switch (flowState.flowKey) {
      case FLOW_STATE_KEYS.INITIAL_ONBOARDING:
        return (
          <span>
            {" "}
            -{" "}
            <a
              style={sudoStyles.miniLink}
              onClick={() => this.handleResetFlowState(flowState.flowKey, orgRelayId)}
              key={flowState.id}
            >
              reset
            </a>
          </span>
        );
      case FLOW_STATE_KEYS.WEB_ONBOARDING:
        return (
          <span>
            {" "}
            -{" "}
            <a
              style={sudoStyles.miniLink}
              onClick={() => this.handleResetFlowState(flowState.flowKey, orgRelayId)}
              key={flowState.id}
            >
              reset
            </a>
          </span>
        );
      case FLOW_STATE_KEYS.CHECKLIST_FLEX_MEETINGS:
        return (
          <span>
            {" "}
            -{" "}
            <a
              style={sudoStyles.miniLink}
              onClick={() => this.handleResetFlowState(flowState.flowKey, orgRelayId)}
              key={flowState.id}
            >
              reset
            </a>
          </span>
        );
      case FLOW_STATE_KEYS.CHECKLIST_HOLDS:
        return (
          <span>
            {" "}
            -{" "}
            <a
              style={sudoStyles.miniLink}
              onClick={() => this.handleResetFlowState(flowState.flowKey, orgRelayId)}
              key={flowState.id}
            >
              reset
            </a>
          </span>
        );
      case FLOW_STATE_KEYS.CHECKLIST_PREFERENCES:
        return (
          <span>
            {" "}
            -{" "}
            <a
              style={sudoStyles.miniLink}
              onClick={() => this.handleResetFlowState(flowState.flowKey, orgRelayId)}
              key={flowState.id}
            >
              reset
            </a>
          </span>
        );
      default:
        return <span></span>;
    }
  }

  public renderFlowState(
    flowStateEdge: ISchema.IFlowStateEdge,
    index: number,
    orgRelayId?: string,
  ) {
    const fs = flowStateEdge.node;
    return (
      <div key={index} style={sudoStyles.vline}>
        {fs.flowKey} - {fs.current.state}
        {fs.pending.state
          ? fs.pending.state + " " + fs.pending.percentComplete + " " + fs.pending.errorMessage
          : ""}
        {this.renderFlowStateAction(fs, orgRelayId)}
      </div>
    );
  }

  public render() {
    const user = this.props.viewer.sudo.modifyUser.user;
    const userId = user && user.id && fromGlobalId(user.id).id;
    const ecosystem = this.props.viewer.sudo.modifyUser.ecosystem;

    const teamsRaw = this.props.viewer.sudo.modifyUser.userTeams;
    const teams: Array<ITeam> = teamsRaw && teamsRaw.__typename == "TeamList" ? teamsRaw.list : [];

    const featureStatesJson: string | null = this.props.viewer.sudo.modifyUser.featureStatesJson;

    const billingGroups = user.orgs.edges.flatMap((org) => {
      const orgBillingGroups = org.node.myBillingGroups;

      return (
        orgBillingGroups?.allGroups?.map((group) => ({
          id: group.id,
          name: group.name,
          isPrimary: orgBillingGroups?.primaryBillingGroup?.id === group.id,
        })) || []
      );
    });

    return (
      <Paper
        elevation={2}
        className="cw-font-body cw-p-5 cw-flex cw-flex-grow cw-flex-col cw-relative cw-gap-4"
      >
        <div style={sudoStyles.title}>Find User</div>
        <div style={sudoStyles.row}>
          <TextField
            label="Email Address or User Id"
            onChange={this.handleEmailChange}
            value={this.state.email}
          />
        </div>
        <div style={sudoStyles.row}>
          <Button
            sentiment="positive"
            onClick={() => this.handleSearch()}
            disabled={this.state.loading}
          >
            Search
          </Button>
        </div>
        {user && user.emails && user.emails.length ? (
          <div style={sudoStyles.section}>
            <div style={sudoStyles.row}>
              <label style={sudoStyles.label}>Id</label>
              <ClickToCopyIdSpan>
                User::<ClickToCopyIdSpan>{userId}</ClickToCopyIdSpan>
              </ClickToCopyIdSpan>
            </div>
            <div style={sudoStyles.row}>
              <label style={sudoStyles.label}>Ecosystem</label>
              {ecosystem}
            </div>
            <div style={sudoStyles.row}>
              <label style={sudoStyles.label}>Name</label>
              {user.givenName}&nbsp;{user.familyName}
              {user.externalImageUrl ? (
                <img
                  style={sudoStyles.profilePhoto}
                  src={
                    user.externalImageUrl +
                    (user.externalImageUrl.startsWith("data:") ? "" : "?sz=100")
                  }
                />
              ) : (
                ""
              )}
            </div>
            <div style={sudoStyles.row}>
              <label style={sudoStyles.label}>Emails</label>
              {user.emails.map((email, i) => (
                <span key={i}>
                  <ClickToCopyIdSpan>{email}</ClickToCopyIdSpan>
                  {i !== user.emails.length - 1 ? ", " : ""}
                </span>
              ))}
            </div>
            <div style={sudoStyles.row}>
              <label style={sudoStyles.label}>UserFlows</label>
              <div style={sudoStyles.labelContent}>
                {user.flowStates.edges.map((flowState, i) => this.renderFlowState(flowState, i))}
              </div>
            </div>
            <div style={sudoStyles.row}>
              <label style={sudoStyles.label}>OrgFlows</label>
              <div style={sudoStyles.labelContent}>
                {user.orgs.edges.map((org, i) => {
                  const orgId = fromGlobalId(org.node.id).id;
                  return (
                    <div key={i} style={sudoStyles.labelContentSection}>
                      <div style={sudoStyles.miniHeading}>
                        <div style={sudoStyles.vline}>
                          <ClickToCopyIdSpan>{org.node.name}</ClickToCopyIdSpan>
                        </div>
                        <div style={sudoStyles.vline}>
                          <ClickToCopyIdSpan>
                            Org::<ClickToCopyIdSpan>{orgId}</ClickToCopyIdSpan>
                          </ClickToCopyIdSpan>
                        </div>
                      </div>
                      {org.node.flowStates.edges.map((flowState, j) =>
                        this.renderFlowState(flowState, j, org.node.id),
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={sudoStyles.row}>
              <label style={sudoStyles.label}>Teams</label>
              <div style={sudoStyles.labelContent}>
                {teams.length !== 0 ? (
                  teams.map((team, i) => {
                    const teamId = team.teamId;
                    return (
                      <div key={i} style={sudoStyles.labelContentSection}>
                        <div style={sudoStyles.miniHeading}>
                          <div style={sudoStyles.vline}>
                            {team.teamName}
                            &nbsp;/
                            <ClickToCopyIdSpan>
                              Team::<ClickToCopyIdSpan>{teamId}</ClickToCopyIdSpan>
                            </ClickToCopyIdSpan>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="cw-italic">No teams</span>
                )}
              </div>
            </div>

            <div style={sudoStyles.row}>
              <label style={sudoStyles.label}>Billing Groups</label>
              <div style={sudoStyles.labelContent}>
                {billingGroups.length === 0 ? (
                  <span className="cw-italic">No billing groups</span>
                ) : (
                  billingGroups.map((billingGroup) => (
                    <div key={billingGroup.id} style={sudoStyles.labelContentSection}>
                      <div style={sudoStyles.miniHeading}>
                        <div style={sudoStyles.vline}>
                          {billingGroup.name}
                          {billingGroup.isPrimary && " (Primary)"}
                          &nbsp;/&nbsp;
                          <ClickToCopyIdSpan>{billingGroup.id}</ClickToCopyIdSpan>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div style={sudoStyles.row}>
              <label style={sudoStyles.label}>FeatureGrid</label>
              <div style={sudoStyles.labelContent}>
                {featureStatesJson ? (
                  <div style={sudoStyles.labelContentSection}>
                    <div style={sudoStyles.miniHeading}>
                      <pre style={sudoStyles.preJson}>{featureStatesJson}</pre>
                    </div>
                  </div>
                ) : (
                  "No feature grid"
                )}
              </div>
            </div>
          </div>
        ) : null}

        {this.state.loading ? <LinearProgress /> : null}
      </Paper>
    );
  }
}

export const SudoModifyUserStyled = withStyles(styles)(SudoModifyUserCmpt);

const SudoModifyUserWrapped = withSudo(SudoModifyUserStyled as any);

export const SudoModifyUser = createRefetchContainer<IContainer>(
  SudoModifyUserWrapped,
  sudoModifyUserFragments,
  sudoModifyUserQuery,
);
