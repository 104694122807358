import { Tooltip } from "@clockwise/design-system";
import { InfoFilled } from "@clockwise/icons";
import React from "react";
import { canAccessExperiments } from "../../../../util/auth.util";

export const OmittedAttendeesMessage = () => {
  const isInternal = canAccessExperiments();
  const tooltipText = `Clockwise is optimized to read up to ${isInternal ? 50 : 200} attendees`;
  return (
    <div className="cw-flex cw-items-center" cw-id="ec-attendee-omitted-message">
      <div className="cw-text-12 cw-text-muted cw-mt-2">All attendees can't be shown </div>
      <Tooltip placement="top" title={tooltipText}>
        <InfoFilled className="cw-w-[14px] cw-h-[14px] cw-text-subtle cw-mt-[6px] cw-ml-1" />
      </Tooltip>
    </div>
  );
};
