import {
  EventContainer,
  NamedEvent,
  UnnamedEvent,
} from "@clockwise/web-commons/src/components/feature-illustration/";
import React from "react";

export const CalendarSyncEvent = () => (
  <NamedEvent duration={45} startTime="12:15pm" title="Busy (via Clockwise)" />
);

const CalendarSyncIllustration = () => {
  return (
    <EventContainer>
      <UnnamedEvent duration={20} />
      <CalendarSyncEvent />
      <UnnamedEvent duration={20} />
    </EventContainer>
  );
};

export default CalendarSyncIllustration;
