import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SendProposeNewTimeEmailMutationVariables = Types.Exact<{
  proposalId: Types.Scalars['ID'];
  isPreview?: Types.InputMaybe<Types.Scalars['Boolean']>;
  reasonForProposing?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SendProposeNewTimeEmailMutation = { __typename: 'Mutation', sendProposeNewTimeEmail: boolean | null };


export const SendProposeNewTimeEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendProposeNewTimeEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"proposalId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"isPreview"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"reasonForProposing"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendProposeNewTimeEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"proposalId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"proposalId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"isPreview"},"value":{"kind":"Variable","name":{"kind":"Name","value":"isPreview"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"reasonForProposing"},"value":{"kind":"Variable","name":{"kind":"Name","value":"reasonForProposing"}}}]}}]}]}}]} as unknown as DocumentNode<SendProposeNewTimeEmailMutation, SendProposeNewTimeEmailMutationVariables>;