import { DateTime } from "luxon";
import React from "react";
import { ALLDAY_MAX_HEIGHT } from "../single-day-head/SingleDayHead";
import { PlannerEventCard } from "../types";
import { AllDayEventColumn } from "./SingleDayAllDayEventColumn";
import { getHeightFromAllDayEvents } from "./utils/getHeightFromAllDayEvents";

export const SingleDayAllDayEvents = ({
  calendarIds,
  minWidth,
  dateTime,
  allDayEventsByCalendar,
  expanded,
  toggleExpanded,
}: {
  calendarIds: string[];
  minWidth: number;
  dateTime: DateTime;
  allDayEventsByCalendar: {
    [calendarId: string]: PlannerEventCard[];
  };

  expanded: boolean;
  toggleExpanded: () => void;
}) => {
  const height = expanded ? getHeightFromAllDayEvents(allDayEventsByCalendar, 32) : "40px";

  return (
    <div
      style={{ height, maxHeight: ALLDAY_MAX_HEIGHT }}
      className="cw-w-full cw-flex cw-flex-row cw-bg-default cw-py-1 cw-relative"
    >
      {calendarIds.map((calendarId, index) => {
        const events = allDayEventsByCalendar[calendarId] ?? [];
        return (
          <AllDayEventColumn
            key={calendarId}
            events={events}
            calendarId={calendarId}
            index={index}
            dateTime={dateTime}
            columnCount={calendarIds.length}
            minWidth={minWidth}
            toggleExpanded={toggleExpanded}
            showEvents={expanded}
          />
        );
      })}
    </div>
  );
};
