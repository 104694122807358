import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Button, Modal } from "@clockwise/design-system";
import { GetApp } from "@clockwise/design-system/icons";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import { TrackingEvents, useTracking } from "../../../util/analytics.util";
import { ExportableUserListBillingGroupsDocument } from "./__generated__/exportableUsers.generated";
import { OrgResponse, addCorrectBillingGroupToUser } from "./exportableUsers.util";

const CSV_USER_FIELDS = [
  { label: "Email address", key: "primaryCalendarId" },
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Signup Date", key: "signupDate" },
  { label: "Plan", key: "plan" },
  { label: "Role", key: "role" },
];

export const ExportableUsersDialog = ({ onClose }: { onClose: () => void }) => {
  const [startFetching, setStartFetching] = useState(false);
  const [csvOrgData, setCsvOrgData] = useState<OrgResponse | null>(null);
  const csvRef = React.useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const track = useTracking();

  const { loading } = useQuery(ExportableUserListBillingGroupsDocument, {
    onCompleted: (res) => {
      const currentOrg = getValue(res?.viewer.user?.orgs.edges?.[0]?.node, "Org");
      const billingGroups = getValue(currentOrg?.billingGroups)?.list;
      const currentOrgPersons = getValue(currentOrg?.currentOrgPersons)?.list;
      setCsvOrgData({
        billingGroups,
        currentOrgPersons,
      });
      setTimeout(() => {
        if (currentOrgPersons && billingGroups) {
          const link = csvRef?.current?.link;
          if (link) {
            link.click();
            track(TrackingEvents.ADMIN_PANEL.OVERVIEW_USER_LIST_DOWNLOAD_SUCCESS);
            closeModal();
          } else {
            toast.error("There was an error attempting to download. Please try again.");
          }
        }
      }, 0);
    },
    onError: () => {
      setStartFetching(false);
    },
    skip: !startFetching || Boolean(csvOrgData),
  });

  const rows = useMemo(() => {
    if (csvOrgData?.billingGroups && csvOrgData?.currentOrgPersons) {
      return addCorrectBillingGroupToUser(csvOrgData?.currentOrgPersons, csvOrgData?.billingGroups);
    } else {
      return [];
    }
  }, [csvOrgData]);

  const getFileName = () => {
    const currentDay = DateTime.local().toFormat("MM-dd-yyyy");
    return `${currentDay}-clockwise-user-list.csv`;
  };

  const closeModal = () => {
    setStartFetching(false);
    setCsvOrgData(null);
    onClose();
  };

  return (
    <Modal title="Clockwise User List" opened padding="md" onClose={closeModal}>
      <div className="cw-body-base cw-pb-6 cw-pt-4">
        Export all of your organization's current Clockwise users in a CSV file, along with key info
        to help you sort and filter as needed.
      </div>
      <div className="cw-flex cw-gap-4 cw-justify-end">
        <CSVLink
          ref={csvRef}
          className="cw-display-none"
          filename={getFileName()}
          data={rows}
          headers={CSV_USER_FIELDS}
        />
        <Button
          variant="text"
          onClick={() => {
            track(TrackingEvents.ADMIN_PANEL.OVERVIEW_USER_LIST_CANCEL_CLICKED);
            closeModal();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="solid"
          color="primary"
          sentiment="positive"
          onClick={() => {
            setStartFetching(true);
            track(TrackingEvents.ADMIN_PANEL.OVERVIEW_USER_LIST_DOWNLOAD_CLICKED);
          }}
        >
          {loading ? "Preparing..." : "Download"}
        </Button>
      </div>
    </Modal>
  );
};

export const DownloadUserButtonWithDialog = () => {
  const [showDownLoadModal, setShowDownloadModal] = useState(false);
  const track = useTracking();

  return (
    <>
      <Button
        variant="outlined"
        size="large"
        startIcon={GetApp}
        onClick={() => {
          setShowDownloadModal(true);
          track(TrackingEvents.ADMIN_PANEL.OVERVIEW_USER_LIST_MODAL_OPENED);
        }}
        aria-label="Download current users"
      >
        Download
      </Button>
      {showDownLoadModal && (
        <ExportableUsersDialog
          onClose={() => {
            setShowDownloadModal(false);
          }}
        />
      )}
    </>
  );
};
