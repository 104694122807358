import {
  SaveAddEventDiffDetailsInput,
  SaveSharedProposalEventDiffDetailsInput,
} from "@clockwise/schema";
import React from "react";
import toast from "react-hot-toast";
import { useUpdateActiveEventDiff } from "../chat-plus-calendar/util/ActiveDiffContext";
import { AddDiffEventCardExpanded } from "../chat/ai-chat/components/diffs/diff-event-card-expanded/AddDiffEventCardExpanded";
import { DiffContext } from "../chat/ai-chat/components/diffs/diff-event-card-expanded/DiffContext";
import { getDefaultEventInterval } from "../chat/ai-chat/utils/getDefaultEventInterval";
import { toastStyles } from "../chat/util/toastStyles";
import { LoadingCard } from "../event-card/LoadingCard";
import { useSetProposalError } from "./hooks/ProposalErrorContext";
import { useSharedProposalContext } from "./hooks/SharedProposalProvider";
import { useSaveSharedProposalDiffEventDetails } from "./hooks/useSaveSharedProposalDiffEventDetails";
import { useSharedProposalDiffEventDetails } from "./hooks/useSharedProposalDiffEventDetails";

type DiffEventCardExpandedProps = {
  id: string;
  proposalId: string;
};

export const SharedProposalDiffEventCardExpanded = ({
  id,
  proposalId,
}: DiffEventCardExpandedProps) => {
  const setActiveDiff = useUpdateActiveEventDiff();
  const { diffEventDetails, error } = useSharedProposalDiffEventDetails(proposalId, id);
  const { viewerIsProposalOwner } = useSharedProposalContext();
  const setError = useSetProposalError();
  const onCloseCard = () => {
    setActiveDiff(null);
  };

  const [
    onSaveSharedProposalDiffEventDetails,
    { saveSharedProposalDiffEventDetailsLoading },
  ] = useSaveSharedProposalDiffEventDetails({
    onError: (error) => {
      setError({
        error,
        message: "Error saving diff details",
        proposalId: proposalId,
      });
    },
    onCompleted: () => {
      onCloseCard();
      toast.success("Event details updated", {
        position: "bottom-center",
        style: toastStyles,
      });
    },
  });

  if (error) {
    setError({ error: error, message: "Failed to fetch event details", proposalId: proposalId });
    onCloseCard();

    return null;
  }

  const onSubmit = (input: SaveAddEventDiffDetailsInput) => {
    const inputWithProposalId: SaveSharedProposalEventDiffDetailsInput = { ...input, proposalId };
    onSaveSharedProposalDiffEventDetails(inputWithProposalId);
  };

  if (!diffEventDetails || saveSharedProposalDiffEventDetailsLoading) {
    return <LoadingCard onClose={onCloseCard} />;
  }

  // For shared proposal, we only support AddDiffDetails
  if (diffEventDetails.__typename !== "AddDiffDetails") {
    return null;
  }

  return (
    <DiffContext
      time={diffEventDetails.time ?? getDefaultEventInterval().toISO()}
      diffDetails={diffEventDetails}
    >
      <AddDiffEventCardExpanded
        diffEventDetails={diffEventDetails}
        onClose={onCloseCard}
        onSubmit={onSubmit}
        viewerIsProposalOwner={viewerIsProposalOwner}
      />
    </DiffContext>
  );
};
