import { InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import { EditExistingEventInput, RepeatingEventSaveOption } from "@clockwise/schema";
import { useCallback } from "react";
import {
  DeleteExistingEventDocument,
  EditExistingEventDocument,
} from "../apollo/__generated__/PlannerEvent.generated";

export type InputForSaveEventChanges = Omit<
  EditExistingEventInput,
  "externalEventId" | "organizerCalendarId"
>;

export const useEditExistingEvent = (
  externalEventId: string,
  calendarId: string,
  isRecurring: boolean,
  {
    refetchQueriesOnEdit,
    onEditCompleted,
    onEditError,
    refetchQueriesOnDelete,
    onDeletionCompleted,
    onDeletionError,
  }: {
    refetchQueriesOnEdit?: InternalRefetchQueriesInclude;
    onEditCompleted?: () => void;
    onEditError?: (error: Error) => void;
    onDeletionCompleted?: () => void;
    onDeletionError?: (error: Error) => void;
    refetchQueriesOnDelete?: InternalRefetchQueriesInclude;
  },
) => {
  const [editExistingEvent, { loading: savingChanges }] = useMutation(EditExistingEventDocument);
  const [deleteEvent, { loading: deletingEvent }] = useMutation(DeleteExistingEventDocument);

  const onDeleteEvent = useCallback(
    async (repeatingDeleteOption?: RepeatingEventSaveOption) => {
      await deleteEvent({
        variables: {
          input: {
            calendarId,
            emailAddress: calendarId,
            externalEventId,
            isRecurring,
            repeatingDeleteOption,
          },
        },
        onCompleted: onDeletionCompleted,
        onError: onDeletionError,
        refetchQueries: refetchQueriesOnDelete,
      });
    },
    [
      deleteEvent,
      externalEventId,
      onDeletionCompleted,
      onDeletionError,
      calendarId,
      isRecurring,
      refetchQueriesOnDelete,
    ],
  );

  const onSaveEventChanges = useCallback(
    async (input: InputForSaveEventChanges) => {
      await editExistingEvent({
        variables: {
          input: {
            ...input,
            // NB: webserver actually wants to know the specific calendar to fetch the event from, in order to understand
            //  how to make the correct changes. Services will modify the copy that allows changes to propagate; this means
            //  that we're unable to make copies that are local to a specific calendar, but that's not general what people want
            organizerCalendarId: calendarId,
            externalEventId,
          },
          typedCalendarId: calendarId,
        },
        onCompleted: onEditCompleted,
        onError: onEditError,
        refetchQueries: refetchQueriesOnEdit,
      });
    },
    [
      editExistingEvent,
      externalEventId,
      onEditCompleted,
      onEditError,
      calendarId,
      refetchQueriesOnEdit,
    ],
  );

  return { onSaveEventChanges, savingChanges, onDeleteEvent, deletingEvent };
};

export default useEditExistingEvent;
