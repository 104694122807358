import { useIsLayersDisabledWithReason } from "#webapp/src/components/chat/ai-chat/components/multi-calendar/calendar-layers.util";
import { ToggleCalendarLayers } from "#webapp/src/components/chat/ai-chat/components/multi-calendar/ToggleCalendarLayers";
import { PopoverWrapper } from "#webapp/src/components/web-app-calendar/calendar-popover/PopoverWrapper";
import {
  addCalendarIds,
  removeCalendarIds,
} from "#webapp/src/state/actions/multi-calendar.actions";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { Button } from "@clockwise/design-system";
import { ExpandMore } from "@clockwise/icons";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { getFullName } from "@clockwise/web-commons/src/util/profile.util";
import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AttendeeStack } from "../../../attendee-stack";
import { Attendee, toEventCardAttendee } from "../../proposals/utils/attendee";
import { EventCardAttendee } from "../../types";
import { CannotViewGuesListMessage } from "../ECAttendeesV2/CannotViewGuesListMessage";
import { ECAttendeeList } from "../ECAttendeesV2/ECAttendeeList";
import { ECAttendeeSelect } from "../ECAttendeesV2/ECAttendeeSelect";
import { getListOfNonRemovableAttendeeIds } from "../ECAttendeesV2/ECAttendeeWrapper";
import { OmittedAttendeesMessage } from "../ECAttendeesV2/OmittedAttendeesMessage";

const toAttendee = (eventCardAttendee: EventCardAttendee): Attendee => {
  const displayName =
    eventCardAttendee.profile?.givenName && eventCardAttendee.profile?.familyName
      ? getFullName(eventCardAttendee.profile)
      : eventCardAttendee.primaryCalendar;

  return {
    id: eventCardAttendee.id,
    isOptional: !!eventCardAttendee.isOptional,
    user: eventCardAttendee.userId ? { id: eventCardAttendee.userId } : null,
    isOrganizer: eventCardAttendee.isOrganizer ?? null,
    responseStatus: eventCardAttendee.attendingStatus ?? null,
    person: {
      id: eventCardAttendee.primaryCalendar,
      email: eventCardAttendee.primaryEmail,
      displayName,
      givenName: eventCardAttendee.profile?.givenName ?? null,
      familyName: eventCardAttendee.profile?.familyName ?? null,
      externalImageUrl: eventCardAttendee.profile?.externalImageUrl ?? null,
      isMe: eventCardAttendee.isYou,
    },
  };
};

export const TimeSuggestionsAttendeePicker = ({
  attendees,
  attendeesOmitted,
  readOnly,
  loading = false,
  permissions,
  onRemoveAttendee,
  onAddAttendee,
  onUpdateAttendeeOptionality,
}: {
  attendees: Attendee[];
  permissions: {
    canModify: boolean;
    canInviteOthers: boolean;
    canSeeOtherGuests: boolean;
  };
  readOnly: boolean;
  attendeesOmitted: boolean;
  loading?: boolean;
  onRemoveAttendee: (email: string) => void;
  onAddAttendee: (attendee: Attendee) => void;
  onUpdateAttendeeOptionality: (email: string, isOptional: boolean) => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const ecosystem = useEcosystem();

  const eventCardAttendees = attendees.map(toEventCardAttendee);
  const attendeeCalendarIds = attendees.map((a) => a.person.id);

  const calendarIdsToFullyShow = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsToFullyShow,
  );
  const isToggledOn = attendeeCalendarIds.every((id) => calendarIdsToFullyShow.includes(id));
  const dispatch = useDispatch();
  const { disabled, reason: reasonForDisabled } = useIsLayersDisabledWithReason(
    attendeeCalendarIds,
  );

  const attendeesLoading = loading;

  const nonEditableAttendeeIds = getListOfNonRemovableAttendeeIds(
    permissions.canInviteOthers,
    permissions.canModify,
    eventCardAttendees,
    ecosystem,
  );

  const handleToggleCalendarLayers = () => {
    if (isToggledOn) {
      dispatch(removeCalendarIds(attendeeCalendarIds));
    } else {
      dispatch(addCalendarIds(attendeeCalendarIds));
    }
  };

  const handleRemoveAttendee = (email: string) => {
    onRemoveAttendee?.(email);
    dispatch(removeCalendarIds([email]));
  };

  const handleUpdateAttendeeOptionality = (attendee: EventCardAttendee, isOptional: boolean) => {
    onUpdateAttendeeOptionality?.(attendee.primaryEmail, isOptional);
  };

  const handleAddAttendee = (attendee: EventCardAttendee) => {
    onAddAttendee?.(toAttendee(attendee));
    dispatch(addCalendarIds([attendee.primaryCalendar]));
  };

  const eventAttendeesRef = React.useRef(attendees);
  React.useEffect(() => {
    eventAttendeesRef.current = attendees;
  }, [attendees]);

  if (attendeesLoading) {
    return "loading...";
  }

  return (
    <div className={classNames("cw-flex cw-items-center cw-justify-between cw-w-full")}>
      <Button
        variant="outlined"
        size="xsmall"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        endIcon={ExpandMore}
      >
        <AttendeeStack attendees={attendees} maxShown={5} />
      </Button>
      <PopoverWrapper
        id="cw-menu-suggestions-attendee-picker"
        anchorEl={anchorEl ? anchorEl : null}
        onClose={() => setAnchorEl(null)}
        verticalPlacement="bottom"
        side="left"
        width={350}
      >
        <div className={classNames("cw-py-3 cw-px-2")}>
          <ECAttendeeSelect
            disabled={readOnly}
            currentAttendees={eventCardAttendees}
            onAddAttendee={handleAddAttendee}
          />
          {!permissions.canSeeOtherGuests && <CannotViewGuesListMessage />}
          <ECAttendeeList
            attendees={eventCardAttendees}
            nonEditableAttendeeIds={nonEditableAttendeeIds}
            removeAttendee={handleRemoveAttendee}
            changeOptionalityOfAttendee={handleUpdateAttendeeOptionality}
            readOnly={readOnly}
            attendeeDisplayLimit={50}
          />
          {attendeesOmitted && <OmittedAttendeesMessage />}
        </div>
      </PopoverWrapper>
      <ToggleCalendarLayers
        onClick={handleToggleCalendarLayers}
        disabled={attendeeCalendarIds.length <= 1 || disabled}
        isToggledOn={isToggledOn}
        reasonForDisabled={reasonForDisabled}
      />
    </div>
  );
};
