import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Button } from "@clockwise/design-system";
import { InfoOutlined } from "@clockwise/design-system/icons";
import { BillingGroup, BillingGroupTypeEnum } from "@clockwise/schema";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { Callout } from "@clockwise/web-commons/src/ui/callout";
import pluralize from "pluralize";
import React from "react";
import { useAPMembers } from "../../useAPMembers";

import { useNavigate } from "react-router-dom";
import { getPersonListCountFromBillingGroup } from "../members-content.util";

const Bold = ({ children }: { children: React.ReactNode }) => {
  return <span className="cw-font-bold">{children}</span>;
};

const SSOConfigCallout = ({ bgName }: { bgName: string }) => {
  return (
    <div>
      You're an administrator of <Bold>{bgName}</Bold>. Please configure SCIM to manage members in
      your subscription.
    </div>
  );
};

const AdminCallout = ({ bg }: { bg: BillingGroup }) => {
  const seatCount =
    bg.subscription.__typename === "SubscriptionDetails"
      ? bg.subscription.subscription.seatCount
      : 0;

  const personListCount = getPersonListCountFromBillingGroup(bg) || 0;

  return (
    <div>
      You're an administrator of <Bold>{bg.name}</Bold> with <Bold>{seatCount}</Bold>{" "}
      {pluralize("seat", seatCount)} purchased, and <Bold>{personListCount}</Bold> in use.
    </div>
  );
};

const MemberCallout = ({ bg }: { bg: BillingGroup }) => {
  const seatCount =
    bg.subscription.__typename === "SubscriptionDetails"
      ? bg.subscription.subscription.seatCount
      : 0;

  const personListCount = getPersonListCountFromBillingGroup(bg) || 0;

  const admins = getValue(bg?.adminOrgPersons)?.list || [];

  return (
    <div>
      You're part of <Bold>{bg.name}</Bold>, with <Bold>{seatCount}</Bold>{" "}
      {pluralize("seat", seatCount)} purchased, and <Bold>{personListCount}</Bold> in use.{" "}
      {admins.length ? `Need changes? Please contact your admin.` : ""}
    </div>
  );
};

export const AdminMembersInfoCalloutWrapper = () => {
  const { currentUsersBillingGroups } = useAPMembers();
  const navigate = useNavigate();

  const bgsUserIsAdminTo = currentUsersBillingGroups.filter((bg) => {
    return (
      bg.adminOrgPersons.__typename === "OrgPersonList" &&
      bg.adminOrgPersons.list.findIndex((a) => a.isYou) >= 0
    );
  });

  const BGsUserIsAdminTooAndAreSSO = bgsUserIsAdminTo.filter((bg) => {
    const dsyncConfigured = getValue(bg.dsyncConfigured);
    return (
      bg.type === BillingGroupTypeEnum.SSO && dsyncConfigured && !dsyncConfigured.isDsyncConfigured
    );
  });

  const getCalloutText = () => {
    if (BGsUserIsAdminTooAndAreSSO.length) {
      return <SSOConfigCallout bgName={BGsUserIsAdminTooAndAreSSO[0].name} />;
    } else if (bgsUserIsAdminTo.length) {
      return <AdminCallout bg={bgsUserIsAdminTo[0]} />;
    } else {
      return <MemberCallout bg={currentUsersBillingGroups[0]} />;
    }
  };

  if (!currentUsersBillingGroups.length) return null;

  return (
    <>
      <Callout level="info" className="cw-body-base cw-p-2 cw-min-h-11 cw-mb-7">
        <div className="cw-flex cw-items-center cw-gap-1 cw-w-full">
          <InfoOutlined className="cw-text-info cw-mr-2" />
          {getCalloutText()}
          <div className="cw-ml-auto">
            <Button
              variant="text"
              onClick={() => {
                navigate(appPaths.plansAndBilling, { replace: true });
              }}
            >
              Manage plan
            </Button>
          </div>
        </div>
      </Callout>
    </>
  );
};
