// libraries
import { Button } from "@clockwise/design-system";
import { PersonAdd } from "@clockwise/design-system/icons";
import { DownloadUserButtonWithDialog } from "@clockwise/web-commons/src/ui/admin-panel/exportable-users";
import { Title } from "@clockwise/web-commons/src/ui/admin-panel/title";
import { Container } from "@material-ui/core";
import React from "react";
import { useAPMembers } from "../useAPMembers";
import { AdminMembersInfoCalloutWrapper } from "./info-callout/info-callout";
import { MembersTable } from "./members-table/members-table";
import { MultiFilterWrapper } from "./multi-filter";
import { SearchField } from "./search-field";
export interface MembersContentProps {
  onSetInviteDialogOpen: (open: boolean) => void;
}

export const MembersContent = ({ onSetInviteDialogOpen }: MembersContentProps) => {
  const { memberSearchQuery, handleSearch } = useAPMembers();

  const onClickInvite = () => {
    onSetInviteDialogOpen(true);
  };

  return (
    <Container maxWidth={false}>
      <div className="cw-flex cw-w-full cw-justify-between cw-items-baseline">
        <Title>Users</Title>
        <div className="cw-flex cw-gap-4">
          <Button size="large" sentiment="positive" onClick={onClickInvite} startIcon={PersonAdd}>
            Invite
          </Button>
          <DownloadUserButtonWithDialog />
        </div>
      </div>
      <AdminMembersInfoCalloutWrapper />

      <div className="cw-flex cw-items-center cw-w-full cw-justify-between">
        <MultiFilterWrapper />
        <SearchField value={memberSearchQuery} onSearch={handleSearch} />
      </div>
      <div className="cw-mt-1 cw-mb-5">
        <MembersTable />
      </div>
    </Container>
  );
};
