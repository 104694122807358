import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { OrgPersonsByCalendarIdDocument } from "../graphql/__generated__/OrgPersonsByCalendarId.generated";

export type CalendarProfile = {
  primaryCalendar: string;
  profile: {
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  } | null;
  isYou: boolean;
};

type QueryResponse = {
  calendarProfiles: CalendarProfile[];
  loading: boolean;
};

export const useCalendarProfilesByCalendarId = (
  orgId: string,
  selectedCalendarIds: string[],
): QueryResponse => {
  const { data, loading } = useQuery(OrgPersonsByCalendarIdDocument, {
    variables: {
      orgId: orgId,
      calendarIds: selectedCalendarIds || [],
    },
  });

  const org = getValue(data?.org, "Org");
  const orgPersonList = getValue(org?.orgPersonsByCalendarIdsErrorable, "OrgPersonList");

  const calendarProfiles = (orgPersonList?.list.map((a) => {
    return {
      primaryCalendar: a.primaryCalendarId,
      profile: a.profile,
      isYou: a.isYou,
    };
  }) || []) as CalendarProfile[];

  const calendarProfilesSortedByTheOrderOfSelectedCalendarIds = [...calendarProfiles].sort(
    (a, b) => {
      return (
        selectedCalendarIds.findIndex((v) => v == a.primaryCalendar) -
        selectedCalendarIds.findIndex((v) => v == b.primaryCalendar)
      );
    },
  );

  return { calendarProfiles: calendarProfilesSortedByTheOrderOfSelectedCalendarIds, loading };
};
