import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { Button, Select, SelectOption } from "@clockwise/design-system";
import {
  CreateTestSubscriptionPaidProductTypeEnum,
  PaymentStatusBadgeTextEnum,
} from "@clockwise/schema";
import { CwDialog } from "@clockwise/web-commons/src/ui/cw-dialog";
import toast from "react-hot-toast";
import {
  CancelTestSubscriptionDocument,
  CreateTestSubscriptionStripeDocument,
  CreateTrialDocument,
} from "./mutations/__generated__/SwitchBillingGroupButtonMutations.generated";

import { PlanBadge } from "#webapp/src/components/chrome-nav/nav-content/PlanBadge";
import { useBillingGroups } from "#webapp/src/components/hooks/useBillingGroups";
import {
  get30DaysFromNow,
  get3DaysAgo,
} from "#webapp/src/components/invite-modal/InviteModal.utils";
import { useMonetization } from "#webapp/src/hooks/useMonetization";
import { updateShouldRefetch } from "#webapp/src/state/actions/payments.actions";
import { logger } from "#webapp/src/util/logger.util";
import { generateStringFromStripeErrorCode } from "#webapp/src/util/pricing.util";
import { fg_warning } from "@clockwise/design-system/tokens";
import { PaymentStatusBadgeTypeEnum } from "@clockwise/web-commons/src/util/feature-grid.util";
import { XCircle } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "../../../../state/reducers/root.reducer";

enum FreeProductTypeEnum {
  Free_NoTrial = "Free_NoTrial",
  Free_ActiveTrial = "Free_ActiveTrial",
  Free_ExpiredTrial = "Free_ExpiredTrial",
}
type ProductTypeEnum = FreeProductTypeEnum | CreateTestSubscriptionPaidProductTypeEnum;
interface Option {
  name: string;
  value: ProductTypeEnum;
}
const selectOptions: Option[] = [
  { name: "Teams", value: CreateTestSubscriptionPaidProductTypeEnum.Pro_Standard },
  { name: "Business", value: CreateTestSubscriptionPaidProductTypeEnum.Business_Standard },
  { name: "Enterprise", value: CreateTestSubscriptionPaidProductTypeEnum.Enterprise_Standard },
  { name: "Free (active trial)", value: FreeProductTypeEnum.Free_ActiveTrial },
  {
    name: "Free (expired trial)",
    value: FreeProductTypeEnum.Free_ExpiredTrial,
  },
];

interface SwitchBillingGroupButtonProps {
  orgId: string;
  currentUserId: string;
}

export const SwitchBillingGroupButton = ({
  orgId,
  currentUserId,
}: SwitchBillingGroupButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Option>(selectOptions[0]);

  const { refetchFeatureGrid } = useMonetization();
  const paymentStatusBadge = useSelector(
    (state: IReduxState) => state.featureGrid.planTierFeatures.paymentStatusBadge,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // set default option based on current paymentStatusBadge
    const badgeText = paymentStatusBadge?.text;
    switch (badgeText) {
      case PaymentStatusBadgeTextEnum.Business:
        setSelectedPlan(selectOptions[1]);
        break;
      case PaymentStatusBadgeTextEnum.Enterprise:
        setSelectedPlan(selectOptions[2]);
        break;
      case PaymentStatusBadgeTextEnum.Pro:
        setSelectedPlan(selectOptions[0]);
        break;
      case PaymentStatusBadgeTextEnum.Free:
        setSelectedPlan(selectOptions[4]);
        break;
      default:
        setSelectedPlan(selectOptions[3]);
        break;
    }
  }, [paymentStatusBadge]);

  const { userBillingGroups } = useBillingGroups();

  const [createTestSubscriptionStripeMutation] = useMutation(CreateTestSubscriptionStripeDocument, {
    onCompleted: (data) => {
      const requestStripeError = data?.createTestSubscriptionStripe?.stripeError;
      if (requestStripeError) {
        toast.error(`${generateStringFromStripeErrorCode(requestStripeError)}`);
        return logger.error(
          `failed to createOrgSubscriptionStripe because of stripe error: ${requestStripeError?.code}`,
        );
      }
      toast.success(`Success! You are now on a ${selectedPlan.name} plan`);
      refetchFeatureGrid(); // update moentization hooks
      setDialogOpen(false);
      dispatch(updateShouldRefetch(true)); //update redux store
    },
    onError: (error) => {
      logger.error(`failed to createTestSubscriptionStripe: ${error}`);
      toast.error(`Failed to switch plan. Please try again.`);
      setDialogOpen(false);
    },
  });
  const [cancelTestSubscriptionMutation] = useMutation(CancelTestSubscriptionDocument);

  const [createTrialMutation] = useMutation(CreateTrialDocument, {
    onCompleted: () => {
      toast.success(`Success! You are now on a ${selectedPlan.name} plan`);
      setDialogOpen(false);
      refetchFeatureGrid(); // update moentization hooks
      dispatch(updateShouldRefetch(true)); //update redux store
    },
    onError: (error) => {
      logger.error(`failed to createTrial: ${error}`);
      toast.error(`Failed to switch plan. Please try again.`);
      setDialogOpen(false);
    },
  });

  const renderListOfBillingGroups = () => {
    const orgBillingGroups = userBillingGroups?.orgBillingGroups;
    if (!!orgBillingGroups) {
      return (
        <div className="cw-flex cw-flex-col cw-mb-4 cw-body-base">
          <div className="cw-mb-4">
            Once you select a new plan, all active billing groups and trials will be deleted for
            your org.
          </div>
          {orgBillingGroups.length > 0 && (
            <div>
              {orgBillingGroups.map((bg) => (
                <div className="cw-py-1 cw-ml-4 cw-flex cw-items-center">
                  {" "}
                  <XCircle className="cw-mr-2" color={fg_warning} size={16} />
                  <div>{bg.name} </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const setSelectionPlanFromValue = (value: ProductTypeEnum) => {
    const newSelectedOption = selectOptions.find((option) => option.value === value);
    if (!!newSelectedOption) {
      setSelectedPlan(newSelectedOption);
    }
  };

  const DialogBody = () => {
    return (
      <div className="cw-flex cw-flex-col">
        <div>{renderListOfBillingGroups()}</div>
        <div className="cw-flex cw-justify-start cw-w-full cw-mt-4">
          <Select
            label="Select a new plan"
            value={selectedPlan.value}
            onChange={(value) => setSelectionPlanFromValue(value)}
            fullWidth
            size="large"
          >
            {selectOptions.map((option) => {
              return (
                <SelectOption key={option.value} value={option.value}>
                  {option.name}
                </SelectOption>
              );
            })}
          </Select>
        </div>
      </div>
    );
  };

  const isSelectedPlanPaid = () => {
    return (
      selectedPlan.value === CreateTestSubscriptionPaidProductTypeEnum.Pro_Standard ||
      selectedPlan.value === CreateTestSubscriptionPaidProductTypeEnum.Business_Standard ||
      selectedPlan.value === CreateTestSubscriptionPaidProductTypeEnum.Enterprise_Standard
    );
  };

  const onClickConfirm = () => {
    if (isSelectedPlanPaid()) {
      createTestSubscriptionStripeMutation({
        variables: {
          input: {
            orgRelayId: orgId,
            paidProductType: selectedPlan.value as CreateTestSubscriptionPaidProductTypeEnum,
            initialUsers: [currentUserId],
          },
        },
      });
    } else {
      if (selectedPlan.value === FreeProductTypeEnum.Free_ActiveTrial) {
        const activeEndDate = get30DaysFromNow();
        cancelTestSubscriptionMutation({
          variables: { input: { orgRelayId: orgId } },
          onCompleted: () => {
            createTrialMutation({ variables: { input: { endDate: activeEndDate } } });
          },
          onError: (error) => {
            logger.error(`failed to cancelTestSubscription: ${error}`);
            toast.error(`Failed to switch plan. Please try again.`);
            setDialogOpen(false);
          },
        });
      } else {
        const expiredEndDate = get3DaysAgo();
        cancelTestSubscriptionMutation({
          variables: { input: { orgRelayId: orgId } },
          onCompleted: () => {
            createTrialMutation({ variables: { input: { endDate: expiredEndDate } } });
          },
          onError: (error) => {
            logger.error(`failed to cancelTestSubscription: ${error}`);
            toast.error(`Failed to switch plan. Please try again.`);
            setDialogOpen(false);
          },
        });
      }
    }
  };

  const getBadgeText = () => {
    if (paymentStatusBadge?.text === PaymentStatusBadgeTextEnum.Pro) {
      return "Teams";
    } else if (paymentStatusBadge?.text === null || !paymentStatusBadge) {
      return "Trial";
    }
    return paymentStatusBadge?.text || "";
  };

  return (
    <>
      <CwDialog
        onBackdropClick={() => setDialogOpen(false)}
        header={"Are you sure that you want to change your Billing Group?"}
        body={<DialogBody />}
        open={dialogOpen}
      >
        <div className="cw-flex cw-w-full cw-justify-end">
          <div className="cw-mr-2">
            <Button
              size="large"
              sentiment="neutral"
              variant="text"
              onClick={() => setDialogOpen(false)}
              cw-id="switch-plan"
            >
              {"Cancel"}
            </Button>
          </div>
          <Button size="large" sentiment="destructive" onClick={onClickConfirm}>
            {"Select and delete current plans"}
          </Button>
        </div>
      </CwDialog>
      <div className="cw-flex cw-items-center">
        <Button
          size="large"
          sentiment="destructive"
          variant="outlined"
          onClick={() => setDialogOpen(true)}
          cw-id="switch-plan"
        >
          {"Switch Billing Group"}
        </Button>
        <PlanBadge
          className="cw-h-[20px] cw-ml-4"
          type={PaymentStatusBadgeTypeEnum.Blue}
          text={getBadgeText()}
        />
      </div>
    </>
  );
};
