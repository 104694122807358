import { DayOfWeek } from "@clockwise/schema";
import { FlexRange } from "@clockwise/schema/v2";

export const ALL_WORK_DAYS_SELECTED = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
];

type SelectedWorkdayOption = typeof ALL_WORK_DAYS_SELECTED[number];

export const convertArrayOfDayWeekToDayMap = (
  days: SelectedWorkdayOption[],
  range: FlexRange,
  possibleWorkDays: SelectedWorkdayOption[],
) => {
  const dayMap: { [keyof: string]: boolean } = {};
  for (const day of possibleWorkDays) {
    if (days.includes(day) || range === FlexRange.Week) {
      dayMap[day] = true;
    } else {
      dayMap[day] = false;
    }
  }
  return dayMap;
};

export const convertDayMapToFlexRangeAndAllowedDays = (dayMap: { [keyof: string]: boolean }) => {
  const flexRange = Object.values(dayMap).every((value) => value)
    ? FlexRange.Week
    : FlexRange.SpecificDays;

  const allowedDays: DayOfWeek[] = Object.entries(dayMap)
    .filter(([_, enabled]) => enabled)
    .map(([day, _]) => day)
    .filter((day): day is DayOfWeek => Object.values(DayOfWeek).includes(day as DayOfWeek));

  return { flexRange, allowedDays };
};
