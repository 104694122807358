import { useCurrentTime as _useCurrentTime } from "#clockwise/web-commons/src/hooks/useCurrentTime";
import classNames from "classnames";
import { map, range } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { getRenderTimeZone } from "../../../util/time-zone.util";

type Props = {
  useCurrentDateTime?: typeof _useCurrentTime;
};

export const CalendarTimeDial = ({ useCurrentDateTime = _useCurrentTime }: Props) => {
  const renderTimeZone = getRenderTimeZone();
  const currentTime = useCurrentDateTime().setZone(renderTimeZone);

  return (
    <div className="cw-relative cw-h-full cw-caption cw-w-10">
      {map(tickTimes, (dateTime) => {
        const hidden = isClose(dateTime, currentTime);
        return (
          <TimeMark
            dateTime={dateTime}
            hidden={hidden}
            key={`${dateTime.toISOTime()}-${hidden ? "hidden" : "visible"}`}
          />
        );
      })}
      <CurrentTimeMark currentTime={currentTime} />
    </div>
  );
};

const TimeMark = ({ dateTime, hidden = false }: { dateTime: DateTime; hidden?: boolean }) => {
  const top = getTopOffsetPercent(dateTime, tickTimes.length) || 100;

  return (
    <div
      style={{ top: `${top}%` }}
      className={classNames("cw-absolute cw-mt-[-8px] cw-text-subtle cw-text-11 cw-right-[2px]", {
        "cw-hidden": hidden,
      })}
    >
      {dateTime.toFormat("ha").toLocaleLowerCase()}
    </div>
  );
};

const CurrentTimeMark = ({ currentTime }: { currentTime: DateTime }) => {
  const top = getTopOffsetPercent(currentTime, tickTimes.length) || 100;
  return (
    <div
      className="cw-absolute cw-body-caption cw-mt-[-8px] cw-text-11 cw-right-[2px] cw-text-destructive cw-bg-default"
      style={{ top: `${top}%` }}
    >
      {currentTime.toFormat("h:mm").toLocaleLowerCase()}
    </div>
  );
};

const refDateTime = DateTime.fromObject({ hour: 1 });
const tickTimes = range(0, 24).map((hour) =>
  DateTime.fromObject(refDateTime.plus({ hour }).toObject()),
);

const isClose = (dateTime: DateTime, currentTime: DateTime) => {
  const deltaMin = dateTime.diff(currentTime, "minutes").minutes;
  return deltaMin >= -25 && deltaMin <= 20;
};

const getTopOffsetPercent = (dateTime: DateTime, count: number) => {
  const fractionalHour = dateTime.hour + dateTime.minute / 60 + dateTime.second / 3600;
  const top = (100 * fractionalHour) / count;
  return top;
};
