import { Dialog } from "@clockwise/design-system";
import { Close } from "@clockwise/icons";
import { ResponseType } from "@clockwise/schema";
import React from "react";
import SurveyHeader from "./assets/PMF_survey_popup_header.png";
import { DisappointmentResponseType, DisappointmentResponseTypeToText } from "./types";

const getEmojiForVariant = (variant: DisappointmentResponseType) => {
  switch (variant) {
    case ResponseType.VeryDisappointed:
      return "😞";
    case ResponseType.SomewhatDisappointed:
      return "😕";
    case ResponseType.NotDisappointed:
      return "😐";
  }
};

export const PMFSatisfactionDialog = ({
  isOpen,
  onClose,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (variant: DisappointmentResponseType) => void;
}) => {
  const onClickButton = (variant: DisappointmentResponseType) => {
    onSubmit(variant);
  };
  return (
    <Dialog opened={isOpen} onClose={onClose} size={260} radius="md">
      <div className="cw-flex cw-flex-col cw-p-1 cw-font-body">
        <div
          className="cw-p-[1px] cw-flex cw-rounded-lg cw-w-full cw-mb-2"
          style={{
            background:
              "conic-gradient(from 180deg at 50% 50%, #B6FF80 0deg, #00A972 90deg, #4459DD 180deg, #FFE5FF 270deg, #B6FF80 360deg)",
          }}
        >
          <img src={SurveyHeader} className="cw-h-[92px] cw-w-full cw-rounded-[7px]" />
        </div>
        <div className="cw-flex cw-items-start cw-justify-between cw-my-1 ">
          <div className="cw-text-13 cw-leading-5 cw-font-semibold cw-mb-3">
            How would you feel if you could no longer use clockwise?
          </div>
          <Close
            onClick={onClose}
            className="cw-text-18 cw-cursor-pointer cw-text-muted hover:cw-text-muted-hover"
          />
        </div>
        <div className="cw-flex cw-flex-col cw-gap-y-2">
          <SatisfactionButton
            variant={ResponseType.VeryDisappointed}
            onClick={() => onClickButton(ResponseType.VeryDisappointed)}
          />
          <SatisfactionButton
            variant={ResponseType.SomewhatDisappointed}
            onClick={() => onClickButton(ResponseType.SomewhatDisappointed)}
          />
          <SatisfactionButton
            variant={ResponseType.NotDisappointed}
            onClick={() => onClickButton(ResponseType.NotDisappointed)}
          />
        </div>
      </div>
    </Dialog>
  );
};

const SatisfactionButton = ({
  variant,
  onClick,
}: {
  variant: DisappointmentResponseType;
  onClick: () => void;
}) => {
  const text = DisappointmentResponseTypeToText[variant];
  const emoji = getEmojiForVariant(variant);
  return (
    <div
      className="cw-flex cw-rounded-lg cw-border cw-border-solid cw-border-muted cw-p-2 cw-w-full cw-cursor-pointer hover:cw-bg-default-hover"
      onClick={onClick}
    >
      <div className="cw-flex cw-items-center cw-gap-x-2 cw-justify-start">
        <span className="cw-text-18">{emoji}</span> <span className="cw-text-13">{text}</span>
      </div>
    </div>
  );
};
