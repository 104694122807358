import { CalendarMode } from "@clockwise/web-commons/src/components/calendar";
import { DATE_HASH_REGEX } from "../constants";
import { HashParams } from "../types";

export const getParamsFromHash = (hash: string): HashParams => {
  const match = DATE_HASH_REGEX.exec(hash);

  return (
    (match && {
      mode: match[1] as CalendarMode,
      year: match[3],
      month: match[5],
      day: match[7],
    }) || { mode: "week" }
  );
};
