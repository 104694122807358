/**
 * @generated SignedSource<<789ba84e485a45b6d0d76d64858f03fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnsubscribeWrapper_viewer$data = {
  readonly id: string;
  readonly " $fragmentType": "UnsubscribeWrapper_viewer";
};
export type UnsubscribeWrapper_viewer$key = {
  readonly " $data"?: UnsubscribeWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnsubscribeWrapper_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnsubscribeWrapper_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "78b3d2b798f95af41d5c16af79f098f5";

export default node;
