import classNames from "classnames";
import { MagicWand } from "phosphor-react";
import * as React from "react";
import { MAKE_IT_WORK_BLUE } from "../../../components/make-it-work/MakeItWork";
import { colors, greys } from "../../../styles/color.styles";
import { opacity } from "../../../util/color.util";

interface BaseMaskProps {
  text?: string;
  className?: string;
  style?: React.HTMLAttributes<HTMLDivElement>["style"];
}

export interface MaskProps extends BaseMaskProps {
  variant?:
    | "outside-meeting-hours"
    | "outside-meeting-hours-busy-overlay"
    | "busy"
    | "hold"
    | "selected-slot"
    | "suggested-move";
}

export const Mask: React.FC<MaskProps> = ({ variant = "outside-meeting-hours", ...props }) => {
  const components = {
    "outside-meeting-hours": OutsideMeetingHoursMask,
    "outside-meeting-hours-busy-overlay": OutsideMeetingHoursBusyOverlayMask,
    busy: BusyMask,
    hold: HoldMask,
    "selected-slot": SelectedSlotMask,
    "suggested-move": SuggestedMoveMask,
  };

  const Component = components[variant];
  return <Component {...props} />;
};

const OutsideMeetingHoursMask: React.FC<BaseMaskProps> = ({ className, style }) => (
  <div
    className={classNames(
      "cw-bg-[repeating-linear-gradient(-45deg,transparent,transparent_10px,#F7F7F7_10px,#F7F7F7_20px)]",
      className,
    )}
    style={style}
  />
);

const OutsideMeetingHoursBusyOverlayMask: React.FC<BaseMaskProps> = ({ className, style }) => (
  <div
    className={className}
    style={{
      ...style,
      background: opacity(greys.dark, 0.1),
    }}
  />
);

const BusyMask: React.FC<BaseMaskProps> = ({ className, style }) => (
  <div
    className={className}
    style={{
      ...style,
      background: greys.cloudyBlue,
    }}
  />
);

const HoldMask: React.FC<BaseMaskProps> = ({ className, style }) => (
  <div
    className={classNames("cw-rounded", className)}
    style={{
      ...style,
      background: `linear-gradient(180deg, ${opacity(colors.blueSky.lightest, 0.12)} 0%, ${opacity(
        colors.blueSky.lightest,
        0.04,
      )} 100%)`,
      border: `1px solid ${colors.blueSky.lightest}`,
      zIndex: 2,
    }}
  />
);

const SelectedSlotMask: React.FC<BaseMaskProps> = ({ className, style, text }) => (
  <div
    className={classNames(
      "cw-flex cw-items-center cw-rounded cw-text-onEmphasis cw-text-xs cw-truncate",
      className,
    )}
    style={{
      ...style,
      background: opacity("#455556", 0.8),
      border: `2px solid ${greys.darkestGrey}`,
    }}
  >
    {text}
  </div>
);

const SuggestedMoveMask: React.FC<BaseMaskProps> = ({ className, style, text }) => (
  <div
    className={classNames(
      "cw-flex cw-items-center cw-rounded cw-text-xs cw-truncate cw-pr-2",
      className,
    )}
    style={{
      ...style,
      background: opacity("#455556", 0.8),
      border: `2px solid ${MAKE_IT_WORK_BLUE}`,
      backgroundColor: colors.green.lightest,
      color: greys.darkestGrey,
    }}
  >
    {text}
    <div className="cw-flex cw-items-center cw-gap-1 cw-mb-1 cw-font-bold cw-absolute cw-right-0 cw-top-1 cw-bg-[#d3f7d9]">
      <MagicWand color="#4459dd" weight="fill" size="14px" />
    </div>
  </div>
);
