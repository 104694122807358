import { IBannerMessage } from "@clockwise/web-commons/src/components/banner-messages/BannerMessage";
import {
  BannerMessages,
  IBannerMessages,
} from "@clockwise/web-commons/src/components/banner-messages/BannerMessages";
import React, { ReactNode, useMemo } from "react";
import { usePlannerContext } from "../../web-app-calendar/Context";
import { AI_EXCLUSION_TOOLTIP, UNABLE_TO_LOAD_CALENDAR_DATA } from "../constants";
import { useAIMessageContext } from "./hooks/AIMessageContext";

export const AIBannerMessages: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { shouldExcludeAi } = useAIMessageContext();
  const { calendarError } = usePlannerContext();

  const messages = useMemo<IBannerMessages["messages"]>(() => {
    const banners: IBannerMessage[] = [];
    if (calendarError) {
      banners.push({ text: UNABLE_TO_LOAD_CALENDAR_DATA, type: "loading" });
    }
    if (shouldExcludeAi) {
      banners.push({ text: AI_EXCLUSION_TOOLTIP, type: "info" });
    }

    return banners;
  }, [calendarError, shouldExcludeAi]);

  return <BannerMessages messages={messages}>{children}</BannerMessages>;
};
