import {
  TrialProBadgeType,
  TrialProBadgeWithTooltip,
} from "@clockwise/web-commons/src/ui/trial-pro-badge-with-tooltip";
import React from "react";
import { useMonetization } from "../../hooks/useMonetization";

const MonetizationBadge = () => {
  const { daysLeftInTrial } = useMonetization();

  const isOnTrialStill = daysLeftInTrial && daysLeftInTrial > 0;
  if (!isOnTrialStill) return null;

  return (
    <div className="cw-ml-2">
      <TrialProBadgeWithTooltip
        colorOption="green"
        daysLeftOnTrial={daysLeftInTrial || undefined}
        showToolTip={true}
        tooltipType={TrialProBadgeType.FocusTimeSettings}
      />
    </div>
  );
};

export default MonetizationBadge;
