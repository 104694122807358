import classNames from "classnames";
import React, { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  label?: string;
}>;

export const MenuGroup: React.FC<Props> = ({ label, children }) => {
  return (
    <>
      <div>
        {label && (
          <span
            role="label"
            className={classNames(
              "cw-text-subtle cw-flex cw-items-center",
              "cw-text-12 cw-font-semibold cw-cursor-pointer cw-select-none cw-relative cw-px-2 cw-py-[5px]",
            )}
          >
            {label}
          </span>
        )}
        {children}
      </div>
      <hr className="last:cw-hidden cw-border-solid cw-border-muted cw-border-b cw-border-t-0 cw-block cw-mx-2 cw-my-1" />
    </>
  );
};
