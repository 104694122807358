import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FlexibilityEventFragmentDoc } from '../../../../flexible-meetings/__generated__/MyFlexibleMeetings.v2.generated';
export type FlexOnboardingEventsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FlexOnboardingEventsQuery = { __typename: 'Query', flexibleMeetingsDashboardV2: { __typename: 'FlexibleMeetingsDashboardV2', id: string, oneOnOnes: Array<{ __typename: 'Event', id: string, calendarId: string, externalEventId: string, title: string, isOutOfOffice: boolean, recurrenceRule: string | null, canMarkFlexible: boolean, dateOrTimeRange: { __typename: 'DateRange', dateRange: string } | { __typename: 'DateTimeRange', timeRange: string }, flexibility: { __typename: 'FlexDetails', isFlexible: boolean, status: Types.FlexStatus, flexRange: Types.FlexRange | null, allowedDays: Array<Types.DayOfWeek> | null, timeOfDayFlexRange: { __typename: 'TimeRange', start: string | null, end: string | null } | null } | null, attendees: Array<{ __typename: 'Attendee', id: string, isExternal: boolean | null, isOptional: boolean | null, isOrganizer: boolean | null, person: { __typename: 'Person', id: string, email: string, givenName: string | null, familyName: string | null, externalImageUrl: string | null, isMe: boolean } }> }> } };


export const FlexOnboardingEventsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FlexOnboardingEvents"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flexibleMeetingsDashboardV2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"oneOnOnes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FlexibilityEvent"}}]}}]}}]}},...FlexibilityEventFragmentDoc.definitions]} as unknown as DocumentNode<FlexOnboardingEventsQuery, FlexOnboardingEventsQueryVariables>;