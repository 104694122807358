import { Button } from "@clockwise/design-system";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { PowerMeter, PowerStep } from "./PowerMeter";

interface Props {
  shouldShowSecondary?: boolean;
  shouldShowSkip?: boolean;
  primaryLabel?: string;
  secondaryLabel?: string;
  skipLabel?: string;
  onClickPrimary: () => void;
  onClickSecondary?: () => void;
  onSkip?: () => void;
  disabled?: boolean;
  disablePrimary?: boolean;
  disableSecondary?: boolean;
  disableSkip?: boolean;
  powerMeeterStrength?: PowerStep | null;
}
export const ButtonGroup = ({
  shouldShowSecondary = false,
  shouldShowSkip = false,
  primaryLabel = "Next",
  secondaryLabel = "Back",
  skipLabel = "Skip",
  onClickPrimary,
  onClickSecondary,
  onSkip,
  disabled = false,
  disablePrimary = false,
  disableSecondary = false,
  disableSkip = false,
  powerMeeterStrength = null,
}: Props) => {
  return (
    <div className="cw-bg-default cw-bg-opacity-90 cw-absolute cw-bottom-0 cw-inset-x-0 cw-flex cw-flex-col cw-items-center cw-z-[10] cw-pt-2 cw-pb-4">
      {shouldShowSkip && !!onSkip && (
        <div className="cw-mb-2">
          <SkipButton onClick={onSkip} disabled={disabled || disableSkip}>
            {skipLabel}
          </SkipButton>
        </div>
      )}
      {powerMeeterStrength && <PowerMeter strength={powerMeeterStrength} />}
      <div
        className={classNames(
          "cw-flex cw-items-center cw-justify-center cw-w-full",
          "md:cw-w-[500px]",
          {
            "md:cw-w-[500px]": shouldShowSecondary,
            "md:cw-w-[250px]": !shouldShowSecondary,
          },
        )}
      >
        {shouldShowSecondary && (
          <div className="cw-px-2 cw-w-full">
            <Button
              cw-id="onboarding-secondary-button"
              sentiment="neutral"
              variant="outlined"
              size="large"
              fullWidth
              onClick={onClickSecondary}
              disabled={disabled || disableSecondary}
            >
              {secondaryLabel}
            </Button>
          </div>
        )}
        <div className="cw-px-2 cw-w-full">
          <Button
            cw-id="onboarding-primary-button"
            sentiment="positive"
            size="large"
            fullWidth
            onClick={onClickPrimary}
            disabled={disabled || disablePrimary}
          >
            {primaryLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

interface SkipButtonProps {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
}

const SkipButton = ({ onClick, disabled = false, children }: SkipButtonProps) => {
  return (
    <div
      role="button"
      cw-id="onboarding-skip-button"
      aria-disabled={disabled}
      className={classNames("cw-body-lg cw-text-muted cw-cursor-pointer hover:cw-underline", {
        "cw-pointer-events-none cw-text-muted-disabled": disabled,
      })}
      onClick={() => !disabled && onClick()}
    >
      {children}
    </div>
  );
};
