import React, { useState } from "react";
// util
import { logger } from "#webapp/src/util/logger.util";
import { googleAuthNonce } from "../state/local-storage";
import { OAUTH_COMPLETE_MESSAGE, sendMessageToParentWindow } from "./auth.util";

const onSuccess = (authCode: string) => {
  sendMessageToParentWindow({ message: OAUTH_COMPLETE_MESSAGE, success: true, authCode });
};

const onFailure = (error: string) => {
  sendMessageToParentWindow({ message: OAUTH_COMPLETE_MESSAGE, success: false, error });
};

export const GoogleAuthRedirect = () => {
  const [error, setError] = useState("");

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const authCode = searchParams.get("code");
    const error = searchParams.get("error");
    const state = searchParams.get("state");

    if (error || !authCode || !state) {
      setError("Something went wrong with your authentication, missing a required field.");
      logger.error(`google auth error - ${error ? error : "missing required fields"}`);
      onFailure(error ?? "no error message");
      return;
    }
    let nonce: string | undefined;
    try {
      const parsedState: { nonce?: string } = JSON.parse(state) ?? {};
      nonce = parsedState.nonce;
    } catch (e) {
      setError("Something went wrong with your authentication, could not parse state parameter.");
      logger.error(`google auth error - could not parse state parameter`);
      onFailure("could not parse state parameter");
      return;
    }

    const expectedNonce = googleAuthNonce.get();
    if (!expectedNonce || nonce !== expectedNonce) {
      console.error(
        `Nonce did not match expectation (expected: ${expectedNonce}, actual: ${nonce})`,
      );
      // TODO: Investigate FF local storage inconsistency
      // setError("Something went wrong with your authentication, state parameter is incorrect.");
      // logger.error("google auth error - bad state parameter");
      // onFailure("bad state parameter");
      // return;
    }

    onSuccess(authCode);
    window.close();
  }, []);

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  if (error) {
    return <div className="cw-p-3">There was a problem authenticating. {error}</div>;
  }

  return <div className="cw-p-3">verifying...</div>;
};
