import { logger } from "#webapp/src/util/logger.util";
import { Link as ClockwiseLink } from "@clockwise/design-system";
import { WorkspacePremium } from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { ExitToApp } from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMonetization } from "../../hooks/useMonetization";
import { useLogout } from "../hooks/useLogout";
import {
  AppActionMenuItem,
  AppNavLinkMenuGroup,
  AppNavLinkMenuItem,
  AppNavMenu,
} from "./AppNavLinkMenu";
import { CurrentUserInfoDocument } from "./__generated__/CurrentUserInfo.v2.generated";

export const AppNavAvatar = () => {
  const [showIds, setShowIds] = useState(false);
  const { usersProductType, userIsOnActiveTrial } = useMonetization();
  const { data } = useGatewayQuery(CurrentUserInfoDocument, { fetchPolicy: "cache-first" });

  const userId = data?.currentUser?.id;
  const orgId = data?.currentOrg?.id;
  const displayName = data?.currentUser?.member?.person.displayName;
  const email = data?.currentUser?.member?.person.email;
  const person = data?.currentUser?.member?.person;

  const [logoutUser] = useLogout({
    onError: (error) => logger.error("An error occurred on logout", error),
  });

  const handleLogout = () => {
    void logoutUser();
  };

  let planText = usersProductType.toString();
  let showPlanUpgrade = false;
  if (userIsOnActiveTrial) {
    planText = "Trial";
    showPlanUpgrade = true;
  } else if (usersProductType === "Free") {
    showPlanUpgrade = true;
  }

  return (
    <AppNavMenu
      button={
        <div className={classNames("cw-relative", "cw-flex cw-flex-col cw-items-center")}>
          <AttendeeAvatar profile={person} size="xlarge" />
          {showPlanUpgrade && (
            <Link
              to={appPaths.plansAndBilling}
              className={classNames(
                "cw-absolute -cw-bottom-1",
                "cw-h-3.5",
                "cw-px-0.5",
                "cw-bg-accent",
                " cw-text-brand",
                "cw-text-center",
                "cw-font-bold cw-text-[11px]",
                "cw-rounded-sm",
                "cw-uppercase",
                "cw-cursor-pointer",
                "cw-no-underline",
              )}
            >
              {planText}
            </Link>
          )}
        </div>
      }
    >
      <div className={classNames("cw-body-base", "cw-pb-1.5")}>
        <div
          className={classNames(
            "cw-px-4 cw-py-2 cw-mb-1.5",
            "cw-bg-neutral",
            "cw-border-b cw-border-solid cw-border-subtle",
            "cw-text-muted",
          )}
        >
          <div className={classNames("cw-font-medium cw-text-13")}>{displayName ?? "User"}</div>
          <div className={classNames("cw-text-12")}>{email}</div>
          <div className={classNames("cw-text-12")}>{planText} plan</div>
          {!showIds && (
            <div className="cw-text-12">
              <ClockwiseLink onClick={() => setShowIds(true)}>Show Ids</ClockwiseLink>
            </div>
          )}
          {showIds && (
            <>
              <div className="cw-text-12">User ID: {userId}</div>
              <div className="cw-text-12">Org ID: {orgId}</div>
            </>
          )}
        </div>
        {showPlanUpgrade && (
          <AppNavLinkMenuGroup>
            <AppNavLinkMenuItem
              to={appPaths.plansAndBilling}
              icon={<WorkspacePremium className="cw-w-4 cw-h-4 cw-text-muted" />}
              label="Upgrade plan"
            />
          </AppNavLinkMenuGroup>
        )}
        <AppNavLinkMenuGroup>
          <AppActionMenuItem
            onClick={handleLogout}
            icon={<ExitToApp className="cw-w-4 cw-h-4 cw-text-warning" />}
            label="Logout"
            sentiment="warning"
          />
        </AppNavLinkMenuGroup>
      </div>
    </AppNavMenu>
  );
};
