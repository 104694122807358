import { CalendarIds } from "#webapp/src/state/actions/multi-calendar.actions.js";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer.js";
import { every, isEmpty, union } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";

// Webserver has a allowance factor that should approximately allow 100 calendars to query at once
// before webserver errors with `"Request too complex` on the `PlannerEventsQuery`
// Thus I'm allowing 50 here to be safe
export const QUERYING_LIMIT_OF_CALENDARS_TO_SHOW_IN_PLANNER = 50;

export const TOO_MANY_CALENDARS_TO_SHOW_MESSAGE = "Too many calendars to load at once";

export type DisableReason = string | null;
export const useIsLayersDisabledWithReason = (
  calendarIdsToAdd: CalendarIds,
): { disabled: boolean; reason: DisableReason } => {
  const calendarIdsToFullyShow = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsToFullyShow,
  );
  const reaseonToDisableIfAny = useMemo(() => {
    return getReasonForLayersDisabled(calendarIdsToAdd, calendarIdsToFullyShow);
  }, [calendarIdsToFullyShow, calendarIdsToAdd]);

  return {
    disabled: !isEmpty(reaseonToDisableIfAny),
    reason: reaseonToDisableIfAny,
  };
};

export const getReasonForLayersDisabled = (
  calendarIdsToAdd: CalendarIds,
  calendarsAlreadyShowing: CalendarIds,
): string | null => {
  const isTooManyCalendarsToShow = calculateIfTooManyCalendarsToShow(
    calendarIdsToAdd,
    calendarsAlreadyShowing,
  );

  if (isEmpty(calendarIdsToAdd)) {
    return "No attendees calendars to show";
  }

  if (every(calendarIdsToAdd, (calendar) => calendarsAlreadyShowing.includes(calendar))) {
    //  All attendees calendars already shown
    return null;
  }

  return isTooManyCalendarsToShow ? TOO_MANY_CALENDARS_TO_SHOW_MESSAGE : null;
};

export const calculateIfIsAtOrBeyondCalendarLimit = (calendarsAlreadyShowing: CalendarIds) => {
  const allCalendarsToShow = calendarsAlreadyShowing.filter(
    (calendarId) => !isGroupCalendarId(calendarId),
  );

  return allCalendarsToShow.length >= QUERYING_LIMIT_OF_CALENDARS_TO_SHOW_IN_PLANNER;
};

export const calculateIfTooManyCalendarsToShow = (
  calendarIdsToAdd: CalendarIds,
  calendarsAlreadyShowing: CalendarIds,
) => {
  const allCalendarsToShow = union(calendarsAlreadyShowing, calendarIdsToAdd).filter(
    (calendarId) => !isGroupCalendarId(calendarId),
  );

  return allCalendarsToShow.length > QUERYING_LIMIT_OF_CALENDARS_TO_SHOW_IN_PLANNER;
};

export const isGroupCalendarId = (calendarId: string) =>
  calendarId.includes("@group.calendar.google.com");
