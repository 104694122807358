// schema
import * as ISchema from "#webapp/src/__schema__";

// libraries
import * as React from "react";

// internal
import {
  getInstallSlackAppCode,
  getInstallSlackAppState,
  maybeTrackOnboardingFromSlackAndStoreInSession,
  trackSlackComplete,
} from "./WebAppSlack.util";

// constants
import { paths } from "#webapp/src/constants/site.constant";

// components
import { LoginBtn } from "#webapp/src/components/login-btn";
import * as loginWrapperStyles from "#webapp/src/components/login-wrapper/LoginWrapper.styles";

// util
import { page, PageEvents, track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { isAuthed } from "#webapp/src/util/auth.util";
import { windowLocation } from "#webapp/src/util/location.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { cleanUpSlackNonce, generateSlackAuthUrl } from "#webapp/src/util/third-party-oauth.util";
import { getCookie } from "@clockwise/web-commons/src/util/cookie.util";
import { getServerUrl } from "@clockwise/web-commons/src/util/post-message-common.util";

// state
import { lastSignInDate, slackNonce } from "#webapp/src/state/local-storage";
import { Button, Link, Typography } from "@clockwise/design-system";

// material-ui
import { useMutation, useQuery } from "@apollo/client";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { DateTime } from "luxon";
import Helmet from "react-helmet";
import toast from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFeatureFlag } from "../../launch-darkly";
import { MsftLoginButton } from "../msft-login-button";
import {
  AddSlackAccountV2Document,
  InstallSlackAppMutationDocument,
  WebAppSlackQueryDocument,
} from "./__generated__/WebAppSlack.generated";

export const WebAppSlack = () => {
  const { step } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [slackDogfoodEnabled] = useFeatureFlag("SlackDogfoodApp");

  const { data, loading } = useQuery(WebAppSlackQueryDocument);
  const viewer = data?.viewer;

  const [addSlackAccountV2] = useMutation(AddSlackAccountV2Document, {
    onCompleted: () => {
      maybeTrackOnboardingFromSlackAndStoreInSession(data);
      track(TrackingEvents.APP_SLACK.SUCCESSFUL_INSTALL);
      track(TrackingEvents.APP_SLACK.SUCCESSFUL_CONNECT);

      trackSlackComplete();

      cleanUpSlackNonce();
      navigate(paths.webApp);
    },
    onError: () => {
      track(TrackingEvents.APP_SLACK.FAIL_INSTALL);
      doInstallSlackAppFailure();
    },
  });

  const [installSlackApp] = useMutation(InstallSlackAppMutationDocument, {
    onCompleted: (res) => {
      const payload = res.installSlackApp;
      if (payload && payload.installSlackAppResult) {
        maybeTrackOnboardingFromSlackAndStoreInSession(data);

        track(TrackingEvents.APP_SLACK.SUCCESSFUL_INSTALL);
        toast.success("Success! The Clockwise app is now installed in your Slack.");
        navigate("/slack/connect", { replace: true });
      } else {
        track(TrackingEvents.APP_SLACK.FAIL_INSTALL);
        doInstallSlackAppFailure();
      }
    },
    onError: () => {
      track(TrackingEvents.APP_SLACK.FAIL_INSTALL);
      doInstallSlackAppFailure();
    },
  });

  const [verifyCodeError] = React.useState("");
  const [installSlackAppError] = React.useState(
    !getInstallSlackAppCode(searchParams) && step === "connect"
      ? "You are missing a Slack verification code"
      : "",
  );

  React.useEffect(() => {
    page(PageEvents.HOME_SLACK);
  }, []);

  React.useEffect(() => {
    const installSlackAppCode = getInstallSlackAppCode(searchParams);
    const installSlackAppState = getInstallSlackAppState(searchParams);
    const redirect = `${getServerUrl()}${paths.slackLanding}/connect`;
    const nonce = slackNonce.get() || getCookie("SlackNonce");

    if (step === "start") {
      redirectToSlackAuth();
    } else if (installSlackAppCode) {
      // google tag manager
      const dataLayer = window && (window as any).dataLayer;
      if (dataLayer) {
        dataLayer.push({ event: "slackOAuthComplete" });
        dataLayer.push({
          event: "funnel_step",
          universal_step: "UNIQUE1",
          funnel_name: "slack_funnel",
          step_name: "slack_auth",
          step_number: "2",
        });
      }

      // check state
      if (!nonce || nonce !== installSlackAppState) {
        doInstallSlackAppFailure();
        return;
      }

      if (isAuthed()) {
        const org = getCurrentOrg(viewer);

        if (!org) {
          return;
        }

        const input: ISchema.IAddSlackAccountMutationInput = {
          orgRelayId: org.id,
          verificationCode: installSlackAppCode,
          redirect,
        };

        addSlackAccountV2({ variables: { input } });
      } else {
        const input: ISchema.IInstallSlackAppMutationInput = {
          installSlackAppCode,
          redirect,
          useOAuthV2: true,
        };

        installSlackApp({ variables: { input } });
      }
    } else if (step === "connect" && isAuthed()) {
      navigate(paths.webApp);
    }
  }, [viewer, step, searchParams]);

  const onClickAddToSlack = (e: any) => {
    e.preventDefault();
    track(TrackingEvents.APP_SLACK.ADD_TO_SLACK);
    redirectToSlackAuth();
  };

  const redirectToSlackAuth = () => {
    windowLocation.assign(
      "WebAppSlackRedirect",
      generateSlackAuthUrl("slack", slackDogfoodEnabled),
    );
  };

  const doInstallSlackAppFailure = () => {
    navigate("/slack", { replace: true });
    toast.error("Sorry, there was a problem installing the Clockwise Slack app. Please try again.");
  };

  const onLoginBtnSuccess = () => {
    track(TrackingEvents.APP_SLACK.SUCCESSFUL_CONNECT);
    lastSignInDate.set(DateTime.now().toISO());
    trackSlackComplete();
    navigate(paths.slackLanding);
  };

  const renderError = () => {
    return (
      <React.Fragment>
        <Typography variant="h6">Sorry, there was a problem</Typography>
        <Typography variant="body1" className="cw-text-default">
          {verifyCodeError || installSlackAppError}
        </Typography>
        <div className="cw-mt-9">
          <Button onClick={() => windowLocation.reload(paths.slackLanding)} sentiment="positive">
            Try Again
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const renderDisclaimer = () => {
    return (
      <div className="cw-caption cw-text-muted cw-text-center">
        By signing in, you agree to Clockwise's{" "}
        <Link target="_blank" href={paths.terms}>
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link target="_blank" href={paths.privacy}>
          Privacy Policy
        </Link>
        .
      </div>
    );
  };

  const renderStartRedirect = () => {
    return (
      <React.Fragment>
        <Typography variant="h6">Welcome to Clockwise!</Typography>
        <Typography className="cw-mt-9" variant="body2">
          One moment while we redirect to Slack...
        </Typography>
      </React.Fragment>
    );
  };

  const renderLandingCopy = () => {
    const installCode = getInstallSlackAppCode(searchParams);

    if (!!installCode && !installSlackAppError) {
      return <div />;
    } else if (installSlackAppError) {
      return renderError();
    }

    return (
      <React.Fragment>
        <Typography variant="h6">Clockwise for Slack</Typography>
        <a className="cw-mt-9" href="" onClick={onClickAddToSlack}>
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack@2x.png"
          />
        </a>
      </React.Fragment>
    );
  };

  const renderConnectCalendar = () => {
    return (
      <React.Fragment>
        <Typography variant="h6">Sign in to setup Slack application</Typography>
        <div className="cw-mt-8 cw-flex cw-flex-col cw-items-center">
          <div className="cw-w-[215px] cw-mb-4">
            <LoginBtn
              hideSnackBarMessages
              style={loginWrapperStyles.signupBtn.base}
              labelStyle={loginWrapperStyles.signupBtn.label}
              label="Sign in with Google"
              onSuccess={onLoginBtnSuccess}
              preventLoginRedirect={true}
            />
          </div>
          <div className="cw-w-[215px] cw-mb-4">
            <MsftLoginButton
              hideSnackBarMessages
              onSuccess={onLoginBtnSuccess}
              preventLoginRedirect={true}
            />
          </div>
        </div>
        {renderDisclaimer()}
      </React.Fragment>
    );
  };

  const installCode = getInstallSlackAppCode(searchParams);

  if (loading) {
    return <Loader size="xl" sentiment="positive" />;
  }

  return (
    <div className="cw-flex cw-flex-col cw-items-center cw-justify-center cw-h-full">
      <Helmet>
        <title>Clockwise for Slack · Clockwise</title>
      </Helmet>
      <div className="cw-flex cw-flex-col cw-justify-center cw-items-center cw-bg-white cw-p-16 cw-rounded-lg">
        {!isAuthed() ? (
          renderConnectCalendar()
        ) : installCode ? (
          <Loader size="xl" sentiment="positive" />
        ) : step === "start" ? (
          renderStartRedirect()
        ) : step === "connect" ? (
          renderConnectCalendar()
        ) : (
          renderLandingCopy()
        )}
      </div>
    </div>
  );
};
