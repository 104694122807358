import { m365Nonce } from "#webapp/src/state/local-storage";
import { generateNonce } from "#webapp/src/util/third-party-oauth.util";
import { getSiteUrl } from "@clockwise/client-commons/src/config/api";
import { getDevenv } from "@clockwise/client-commons/src/config/devenv";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { paths } from "@clockwise/client-commons/src/constants/site";
import { makeOAuthHook } from "./auth.util";

///////////////////////////////////
// App setup
///////////////////////////////////
function getMicrosoftClientId() {
  switch (getEnvironment()) {
    case "preprod":
    case "production":
      return "52d932e1-6ff8-4730-87b3-27544063a3ad";
    case "staging":
      return "0082c14b-42bd-4258-abf4-543b931bfbf9";
    default:
      return "5a4122b5-63ee-4bf2-a582-cb3625d1cd31";
  }
}

function generateNonceAndStoreForM365() {
  const nonce = generateNonce();
  m365Nonce.set(nonce);
  return nonce;
}

export const getAuthRedirectUri = () => {
  const origin = getDevenv() ? "https://auth.dev.getclockwise.com" : getSiteUrl();
  return `${origin}${paths.microsoftAuthRedirect}`;
};

const getMicrosoftAuthUrl = (scopes: string[]) => {
  const redirectUri = getAuthRedirectUri();

  const state = {
    origin: window.location.origin,
    nonce: generateNonceAndStoreForM365(),
  };

  const params = new URLSearchParams();
  params.set("client_id", getMicrosoftClientId());
  params.set("response_type", "code");
  params.set("redirect_uri", redirectUri);
  params.set("response_mode", "query");
  params.set("scope", scopes.join(" "));
  params.set("state", JSON.stringify(state));

  const microsoftAuthBaseUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
  const microsoftAuthUrl = `${microsoftAuthBaseUrl}?${params.toString()}`;
  return microsoftAuthUrl;
};

export const useMicrosoftOAuth = makeOAuthHook(getMicrosoftAuthUrl);
