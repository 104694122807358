import { LoginBtn } from "#webapp/src/components/login-btn";
import { signupBtn } from "#webapp/src/components/login-wrapper/LoginWrapper.styles";
import { paths } from "#webapp/src/constants/site.constant";
import { hasSeenLoginPage } from "#webapp/src/state/local-storage";
import { PostMessageManager } from "#webapp/src/util/post-message.util";
import { LinearProgress, Link } from "@clockwise/design-system";
import { CookieDialog } from "@clockwise/web-commons/src/components/cookie-dialog";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { PageEvents, TrackingEvents, page, track } from "../../../util/analytics.util";
import { CustomerLogos } from "../../customer-logos";
import { LottiePlayer } from "../../lottie-player/LottiePlayer";
import { MsftLoginButton } from "../../msft-login-button";
import defragEventAnimation from "./welcome-cw-animate.lottie";

export type LoginInviteProps = {
  isWebExtension: boolean;
  redirect?: string;
  isUserPresent: boolean;
  onSuccess: () => void;
  isM365Only?: boolean;
  preventLoginRedirect?: boolean;
};

export const LoginInvite = ({
  isWebExtension,
  redirect,
  isUserPresent,
  onSuccess,
  isM365Only,
  preventLoginRedirect,
}: LoginInviteProps) => {
  const [loading, setLoading] = useState(false);
  const [showCookieErrorDialog, setShowCookieErrorDialog] = useState(false);

  useEffect(() => {
    isM365Only
      ? page(PageEvents.M365, {
          redirect: redirect,
        })
      : page(PageEvents.SIGNUP, {
          redirect: redirect,
        });

    hasSeenLoginPage.set(true);
    if (isWebExtension) {
      PostMessageManager.openSidebar(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // only run on mount

  useEffect(() => {
    if (isUserPresent) {
      setLoading(false);
    }
  }, [isUserPresent]);

  const onLoginStart = () => {
    track(TrackingEvents.AUTH.BEGIN_LOGIN);
    setLoading(true);
  };

  const onLoginFailure = () => {
    track(TrackingEvents.AUTH.LOGIN_FAILED);
    setLoading(false);
  };

  const onGoogleFailure = (e: { error: string }) => {
    if (e.error === "idpiframe_initialization_failed") {
      setShowCookieErrorDialog(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>Join Clockwise · Clockwise</title>
      </Helmet>
      <CookieDialog isChromeExtension={isWebExtension} open={showCookieErrorDialog} />
      <div className="cw-px-4 lg:cw-px-[124px] md:cw-px-[54px] cw-flex cw-flex-col cw-flex-1">
        <div className="cw-flex cw-flex-wrap-reverse md:cw-mt-20 cw-mt-8 cw-gap-8 lg:cw-gap-32">
          <div className="cw-max-w-[600px] cw-flex-1 cw-flex-col cw-flex cw-justify-center">
            <div className="cw-heading-3xl cw-text-positive-muted cw-mb-2">
              Join Clockwise and make your calendar work for you.
            </div>
            <div className="cw-body-lg cw-mb-8">
              Clockwise is an intelligent calendar assistant that frees up your time so you can
              focus on what matters.
            </div>
            {!isM365Only && (
              <div className="cw-mb-4 cw-max-w-[220px]">
                <LoginBtn
                  label="Join with Google"
                  style={signupBtn.base}
                  labelStyle={{ ...signupBtn.label, width: 175 }}
                  redirect={redirect}
                  onStart={onLoginStart}
                  onSuccess={onSuccess}
                  onFailure={onLoginFailure}
                  onGoogleFailure={onGoogleFailure}
                  preventLoginRedirect={preventLoginRedirect}
                />
              </div>
            )}
            <div className="cw-w-[215px] cw-mb-4 cw-flex cw-items-center cw-w-5">
              <div className="cw-grow cw-border-b cw-border-solid cw-border-muted"></div>
              <div className="cw-px-2 cw-caption cw-text-muted cw-text-center">
                {isM365Only ? "Try our latest beta" : "or try out our latest beta"}
              </div>
              <div className="cw-grow cw-border-b cw-border-solid cw-border-muted"></div>
            </div>
            <div className="cw-mb-4 cw-max-w-[220px]">
              <MsftLoginButton
                redirect={redirect}
                variant="microsoftStandard"
                preventLoginRedirect={preventLoginRedirect}
              />
            </div>
            <div className="cw-caption cw-text-muted">
              By signing in, you agree to Clockwise's{" "}
              <Link target="_blank" href={paths.terms}>
                {" "}
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link target="_blank" href={paths.privacy}>
                Privacy Policy
              </Link>
              .
            </div>
          </div>
          <div className="cw-flex-1 cw-min-w-[318px] cw-max-w-[580px] ">
            <LottiePlayer className="cw-w-full" autoplay loop src={defragEventAnimation} />
            {loading && (
              <LinearProgress className="cw-bg-transparent cw-absolute cw-bottom-0 cw-w-full" />
            )}
          </div>
        </div>
        <div className="md:cw-mt-20 cw-mt-8">
          <CustomerLogos />
        </div>
      </div>
    </>
  );
};
