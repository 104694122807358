import * as ISchema from "#webapp/src/__schema__";
import { CalloutBox } from "#webapp/src/components/callout-box";
import {
  saveTeamUserSettings,
  sendM365TeamAvailabilityCalendarInvite,
} from "#webapp/src/mutations";
import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import {
  maybeGetTeamSettingsFromTeam,
  maybeGetTeamUserSettingsFromTeam,
} from "#webapp/src/util/team-settings.util";
import { maybeGetPrimaryTeamFromOrg } from "#webapp/src/util/team.util";
import { Button, Tooltip, Typography } from "@clockwise/design-system";
import { colors, greys } from "@clockwise/web-commons/src/styles/color.styles";
import { Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { noop } from "lodash";
import * as React from "react";
import toast from "react-hot-toast";

export enum TeamAvailabilityCalendarInviteStatus {
  Subscribed = "Subscribed",
  Pending = "Pending",
  Unsubscribed = "Unsubscribed",
}

// ~-~-~-~-~-~-~-
// Team Availability Calendar Settings
// ~-~-~-~-~-~-~-
interface IProps {
  org: ISchema.IOrg;
  team: ISchema.ITeam;
  canTeamAvailabilityBeEnabled: boolean;
}

const useStyles = makeStyles({
  h3: {
    display: "flex",
    alignItems: "center",
  },
  toggleText: {
    flexGrow: 1,
    color: greys.darkestGrey,
    fontSize: 16,
    marginLeft: 10,
  },
  flexLabelContainer: {
    display: "flex",
    alignItems: "center",
  },
  labelPrimary: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  labelSecondary: {
    fontSize: 14,
    color: greys.slate,
  },
  disabledCursor: {
    cursor: "not-allowed",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.4,
    WebkitFilter: "grayscale(45%)",
    filter: "grayscale(45%)",
  },
  primaryBadge: {
    textTransform: "uppercase",
    color: greys.white,
    background: colors.blueCool.standard,
    borderRadius: 3,
    fontSize: 12,
    fontWeight: "bold",
    marginLeft: 10,
    padding: 5,
    paddingTop: 7,
    width: "fit-content",
  },
});

export const M365AvailabilityCalendarSetting = (props: IProps) => {
  const classes = useStyles();

  const teamSettings = maybeGetTeamSettingsFromTeam(props.team);
  const teamUserSettings = maybeGetTeamUserSettingsFromTeam(props.team);

  const [oooCalendarAvailable, _setOooCalendarAvailable] = React.useState(
    teamSettings ? teamSettings.manageTeamCalendar : undefined,
  );
  const [syncOOOCalendar, setSyncOOOCalendar] = React.useState(
    teamUserSettings ? teamUserSettings.useTeamCalendar : undefined,
  );

  // ~-~-~-~-~-~-~-
  // Handlers
  // ~-~-~-~-~-~-~-
  const setSyncSetting = (newSyncValue: boolean) => {
    track(TrackingEvents.TEAMS.TEAM_SETTINGS_USER_TOGGLED_OOO_CALENDAR, {
      useTeamCalendar: newSyncValue,
    });

    setSyncOOOCalendar(newSyncValue);

    saveTeamUserSettings(
      getCurrentEnvironment(),
      { orgRelayId: props.org.id, teamId: props.team.teamId, useTeamCalendar: newSyncValue },
      () => {
        // if (newSyncValue) {
        //   PostMessageManager.toggleCalendar(team.teamCalendarId, true);
        // }

        toast.success(`${newSyncValue ? "Enabled" : "Disabled"} your team's calendar!`);
      },
      () => {
        const msg = `Failed to ${newSyncValue ? "enable" : "disable"} your team's calendar!`;
        toast.error(msg);
        logger.error(msg);
      },
    );

    const personId = getCurrentUserPersonId();
    if (!personId) {
      return logger.error(
        "Failed to sendM365TeamAvailabilityCalendarInvite because of no personId",
      );
    }

    sendM365TeamAvailabilityCalendarInvite(
      getCurrentEnvironment(),
      { teamId: props.team.teamId, personIds: [personId] },
      noop,
      () => logger.error("failed to sendM365TeamAvailabilityCalendarInvite"),
    );
  };

  const getCurrentUserPersonId = () => {
    return (
      (props.org.orgPersonForUserErrorable.__typename === "OrgPerson" &&
        props.org.orgPersonForUserErrorable.personId) ||
      null
    );
  };

  const getCurrentUserInviteStatus = () => {
    const userPersonId = getCurrentUserPersonId();

    const teamCalendarDetails =
      teamSettings?.m365TeamCalendarDetails?.__typename === "TeamCalendarDetails"
        ? teamSettings.m365TeamCalendarDetails
        : null;

    const userMember = (teamCalendarDetails?.members || []).find(
      (member) => member.personId === userPersonId,
    );

    return userMember ? userMember.status : ISchema.TeamCalendarMemberInviteStatus.Unknown;
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  const { org, team } = props;

  const maybePrimaryTeam = maybeGetPrimaryTeamFromOrg(org.primaryTeam);
  const isPrimaryTeam = maybePrimaryTeam && maybePrimaryTeam.teamId === team.teamId;

  const isSubscribed = !!syncOOOCalendar && props.canTeamAvailabilityBeEnabled;
  const disabled = !oooCalendarAvailable || !props.canTeamAvailabilityBeEnabled;

  const onSubscribe = () => {
    setSyncSetting(true);
    track(TrackingEvents.TEAMS.M365_TEAM_SETTINGS_SUBSCRIBED_TEAM_CALENDAR);
  };

  const onUnsubscribe = () => {
    setSyncSetting(false);
    track(TrackingEvents.TEAMS.M365_TEAM_SETTINGS_UNSUBSCRIBED_TEAM_CALENDAR);
  };

  const InvitePendingBadge = () => {
    return (
      <div className="cw-self-center cw-flex cw-flex-row cw-items-center cw-px-2 cw-rounded cw-text-12 cw-uppercase cw-bg-busy-inset cw-text-busy">
        Invite Pending{" "}
        <Tooltip title="Invite has been sent! When you subscribe to a calendar, Outlook will send a request to enable it. You must approve the invite in order to see this team calendar on Outlook.">
          <Info className="cw-ml-1 cw-w-4" />
        </Tooltip>
      </div>
    );
  };

  const SubscribeButton = () => {
    return (
      <>
        <Button variant="outlined" sentiment="neutral" onClick={onSubscribe} disabled={disabled}>
          Subscribe to team calendar
        </Button>
      </>
    );
  };

  const UnsubscribeButton = () => {
    return (
      <Button
        variant="outlined"
        sentiment="destructive"
        onClick={onUnsubscribe}
        disabled={disabled}
      >
        Unsubscribe from team calendar
      </Button>
    );
  };

  const renderAction = (state: TeamAvailabilityCalendarInviteStatus) => {
    switch (state) {
      case TeamAvailabilityCalendarInviteStatus.Subscribed:
        return <UnsubscribeButton />;
      case TeamAvailabilityCalendarInviteStatus.Pending:
        return (
          <div className="cw-flex cw-flex-row items-center cw-gap-[12px]">
            <SubscribeButton />
            <InvitePendingBadge />
          </div>
        );
      case TeamAvailabilityCalendarInviteStatus.Unsubscribed:
      default:
        return <SubscribeButton />;
    }
  };

  const inviteStatus = getCurrentUserInviteStatus();
  const state =
    isSubscribed && inviteStatus === ISchema.TeamCalendarMemberInviteStatus.Accepted
      ? TeamAvailabilityCalendarInviteStatus.Subscribed
      : isSubscribed && inviteStatus === ISchema.TeamCalendarMemberInviteStatus.Pending
      ? TeamAvailabilityCalendarInviteStatus.Pending
      : TeamAvailabilityCalendarInviteStatus.Unsubscribed;

  return (
    <React.Fragment>
      <Typography variant="h3" className={`cw-mb-2 ${classes.h3}`}>
        {props.team.teamName}{" "}
        {isPrimaryTeam && <div className={classes.primaryBadge}>Primary team</div>}
      </Typography>
      <h6 className="cw-body-lg cw-m-0 cw-text-muted">
        When subscribed, this calendar will be visible on Outlook as “Clockwise: {team.teamName}”
      </h6>
      {!oooCalendarAvailable && (
        <CalloutBox className="cw-mb-11 cw-mt-10" warning>
          You need to enable the team calendar first before changing this setting
        </CalloutBox>
      )}
      <div className="cw-mt-5">{renderAction(state)}</div>
    </React.Fragment>
  );
};
