import { isEmailInDomains } from "@clockwise/client-commons/src/util/email";

const byNameInDomainFilter = (domains: string[]) => ({
  name,
}: {
  name: string | undefined | null;
}) => {
  return name && !isEmailInDomains(name, domains);
};

export default byNameInDomainFilter;
