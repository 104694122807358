import { ResponseStatus } from "@clockwise/client-commons/src/util/attendees";
import { AnnotatedEvent } from "@clockwise/client-commons/src/util/event";
import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import { round } from "lodash";
import { DateTime } from "luxon";
import { CALENDAR_SLOTS_RENDERED } from "../components/calendar/calendar-positioner/constants";

export type TypeEnum = "Add" | "Move" | "UndoMove" | "Remove";

export interface IEventKey {
  externalEventId: string;
}

export interface IEventTime {
  dateOrDateTime: string;
  millis: number;
}

interface CalendarDayIAttendee {
  responseStatus?: ResponseStatus | null;
  urnValue: string;
}

export interface IEvent {
  isClockwiseEvent: boolean;
  isAllDay: boolean | null;
  startTime: IEventTime;
  endTime: IEventTime;
  eventKey: IEventKey;
  calendarIds: string[];
  location?: string | null;
  title?: string | null;
  attendees: CalendarDayIAttendee[];
  organizer?: CalendarDayIAttendee | null;
  annotatedEvent?: AnnotatedEvent | null;
}

export interface IEventMapObj {
  normal: EventPositioner;
  defragged: EventPositioner;
}

export interface IEventMap {
  [s: string]: IEventMapObj;
}

interface IEventMoveSuggestion {
  id: string;
  eventKey: IEventKey;
  originalTime: IEventTime;
  proposedTime: IEventTime;
  addedMinutes: number;
  type: TypeEnum;
}

export interface IDefragSuggestion {
  eventMoveSuggestion: IEventMoveSuggestion;
}

function getDayIndex(start: ZonedMoment, days: ZonedMoment[]) {
  return days.findIndex((day) => start.isSame(day, "d"));
}

function sortPositioners(positioners: EventPositioner[]) {
  return positioners.sort((positioner1, positioner2) => {
    return positioner1.start.unix() - positioner2.start.unix();
  });
}

export type CalendarLayer = "default" | "event" | "mask" | "currentTimeDial" | "tooltip";
export const getCalendarZIndex = (layer: CalendarLayer) => {
  switch (layer) {
    case "event":
      return 11;
    case "mask":
      return 19;
    case "currentTimeDial":
      return 20;
    case "tooltip":
      return 21;
    case "default":
    default:
      return 1;
  }
};

type CalendarPosition = {
  top: number;
  left: number;
  width: number;
  height: number;
  floatOffset?: number;
};

// TODO: multi day and all day positions
export const getCalendarPosition = ({
  startTime,
  endTime,
  daysRendered,
  minHeightMinutes = 30,
}: {
  startTime: DateTime;
  endTime: DateTime;
  daysRendered: DateTime[];
  minHeightMinutes?: number;
}): CalendarPosition | null => {
  const dayIndex = daysRendered.findIndex((day) => day.hasSame(startTime, "day"));
  if (dayIndex === -1) {
    return null;
  }

  const heightPercent = 100 / CALENDAR_SLOTS_RENDERED;
  const minHeight = round((minHeightMinutes / 30) * heightPercent, 3);
  const duration = endTime.diff(startTime).as("minutes") / 30;

  const widthPercent = 100 / daysRendered.length;

  const top = round((startTime.hour * 2 + startTime.minute / 30) * heightPercent, 3);
  const left = round(widthPercent * dayIndex, 3);
  const width = round(widthPercent, 3);
  const height = Math.max(minHeight, round(duration * heightPercent, 3));

  return { top, left, width, height };
};

export function getNormalPositioners(
  events: IEvent[],
  days: ZonedMoment[],
  minHeightInMinutes = 30,
  showSmartHolds = true,
  getMinMaxHours = () => ({ minStart: 0, maxEnd: 48 }),
) {
  // create addedPreEventMap = EventIds[]
  const normalPositioners: EventPositioner[] = [];

  events.forEach((event) => {
    // this enables the smart-hold toggle
    // remove them from the bucket if smartHolds isn't on
    // could probably do this at a better stage...
    if (!showSmartHolds && event.isClockwiseEvent) {
      return;
    }

    // add every event positioner to the normal, non-defragged set
    const normalPositioner = new EventPositioner(
      event,
      days,
      false,
      minHeightInMinutes,
      undefined,
      undefined,
      getMinMaxHours,
    );

    if (normalPositioner.dayIndex >= 0) {
      // nuts to these stupid out of range days
      normalPositioners.push(normalPositioner);
    }
  });

  // need to process set of positioners in one batch b/c order matters
  return setHorizontalPosition(normalPositioners);
}

/**
 * Set top/left positioning.
 * This is the trickiest because you need to know what's overlapping.
 * Based on algorithm described here: http://stackoverflow.com/questions/11311410
 *
 * @param eventPositioners
 */
function setHorizontalPosition(eventPositioners: EventPositioner[]) {
  let columns: EventPositioner[][] = [];
  let lastEventEnding: ZonedMoment | undefined;

  // Create an array of all events
  const positioners = sortPositioners(eventPositioners);

  // Iterate over the sorted array
  positioners.forEach((eventPositioner) => {
    // ignore removed events
    if (eventPositioner.suggestionType === "Remove") {
      return;
    }

    // Check if a new event group needs to be started
    if (!!lastEventEnding && eventPositioner.start.isSameOrAfter(lastEventEnding)) {
      // The latest event is later than any of the event in the
      // current group. There is no overlap. Output the current
      // event group and start a new event group.
      columns = sortColumns(columns);
      packEvents(columns);
      columns = []; // This starts new event group.
      lastEventEnding = undefined;
    }

    // Try to place the event inside the existing columns
    let placed = false;
    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      if (first30MinsCollide(col[col.length - 1], eventPositioner)) {
        break;
      } else {
        for (let y = col.length - 1; y >= 0; y--) {
          const currEP = col[y];
          if (collidesWith(currEP, eventPositioner)) {
            if (currEP.floatOffset) {
              placed = true;
              eventPositioner.floatOffset = currEP.floatOffset + 5;
              break;
            } else {
              eventPositioner.floatOffset = 5;
              placed = true;
              break;
            }
          } else if (currEP.floatOffset) {
            continue;
          } else {
            placed = true;
            break;
          }
        }

        if (placed) {
          col.push(eventPositioner);
          break;
        }
      }
    }

    // It was not possible to place the event. Add a new column
    // for the current event group.
    if (!placed) {
      columns.push([eventPositioner]);
    }

    // Remember the latest event end time of the current group.
    // This is later used to determine if a new groups starts.
    const epDiff = eventPositioner.end.diff(eventPositioner.start, "minutes");
    const adjustedEPEnd = eventPositioner.end
      .clone()
      .add(
        epDiff < eventPositioner.minHeightInMinutes
          ? eventPositioner.minHeightInMinutes - epDiff
          : 0,
        "minutes",
      );
    if (!lastEventEnding || adjustedEPEnd.isSameOrAfter(lastEventEnding)) {
      lastEventEnding = adjustedEPEnd;
    }
  });

  if (columns.length > 0) {
    columns = sortColumns(columns);
    packEvents(columns);
  }

  return positioners;
}

/**
 * Sorts columns so that the 'heavier' ones sit towards the left.
 *
 * @param columns
 */
function sortColumns(columns: EventPositioner[][]) {
  return columns.sort((aCols, bCols) => {
    const aColDiffs = aCols
      .map((ep) => ep.end.diff(ep.start, "milliseconds"))
      .reduce((prev, curr) => prev + curr, 0);
    const bColDiffs = bCols
      .map((ep) => ep.end.diff(ep.start, "milliseconds"))
      .reduce((prev, curr) => prev + curr, 0);

    return bColDiffs - aColDiffs;
  });
}

/**
 * Function does the layout for a group of events.
 *
 * @param columns
 */
function packEvents(columns: EventPositioner[][]) {
  const n = columns.length;
  for (let i = 0; i < n; i++) {
    const col = columns[i];
    for (let j = 0; j < col.length; j++) {
      const bubble = col[j];
      const colWidth = bubble.colWidth / n;
      const colSpan = expandEvent(bubble, i, columns);
      const width = colWidth * colSpan;
      const calcLeft = bubble.colLeft + colWidth * i;
      bubble.left = Math.min(calcLeft, bubble.colLeft + bubble.colWidth - width);
      bubble.width = width;
      bubble.position = i;
    }
  }
}

/**
 * Check if first 30 mins of event a collides with b start.
 * @param a
 * @param b
 */
function first30MinsCollide(a: EventPositioner, b: EventPositioner) {
  return Math.abs(a.start.diff(b.start, "minutes")) < 30;
}

/**
 * Check if two events collide.
 * @param a
 * @param b
 */
function collidesWith(a: EventPositioner, b: EventPositioner) {
  return a.bottom > b.top && a.top < b.bottom;
}

/**
 * Expand events at the far right to use up any remaining space.
 * Checks how many columns the event can expand into, without
 * colliding with other events. Step 5 in the algorithm.
 * @param iColumn
 * @param columns
 */
function expandEvent(ev: EventPositioner, iColumn: number, columns: EventPositioner[][]) {
  let colSpan = 1;

  // To see the output without event expansion, uncomment
  // the line below. Watch column 3 in the output.
  for (let i = iColumn + 1; i < columns.length; i++) {
    const col = columns[i];
    for (let j = 0; j < col.length; j++) {
      const ev1 = col[j];
      if (first30MinsCollide(ev, ev1)) {
        return colSpan;
      }
    }
    colSpan++;
  }
  return colSpan;
}

export class EventPositioner {
  public readonly event: IEvent;
  public readonly start: ZonedMoment;
  public readonly end: ZonedMoment;
  public readonly week: ZonedMoment[];
  public readonly day: ZonedMoment;
  public readonly dayIndex: number;
  public readonly top: number;
  public readonly bottom: number;
  public readonly height: number;
  public readonly colWidth: number;
  public readonly colLeft: number;
  public readonly isDefragged: boolean;
  public readonly isAddedPreEvent: boolean;
  public readonly suggestionType: TypeEnum | undefined;
  public readonly minHeightInMinutes: number;
  public width: number;
  public left: number;
  public position = 0;
  public floatOffset = 0;
  public isFocused = false;
  public displayNone = false; // We use this to display fake all day events that we need to use as padding to increase
  // the index of other all day events. We need to display the event in the layout, but display it visibility none with no onClick.

  /**
   * Create an event positioner to be used with the event positioning algorithms
   *
   * @param event the event to wrap
   * @param days which days this event can span accross (YYYY-MM-DD)
   * @param isDefragged whether it's defragged
   * @param minHeightInMinutes the min height it must have, in minutes
   * @param isAddedPreEvent whether added pre event
   * @param suggestionType optional suggestion type
   * @param isFocused true when event is open in the sidebar
   * @param getMinMaxHours a method to determine the min / max slots this can range across, assumes 48 slots
   *                       TODO: may need a safer mechanism here
   */
  constructor(
    event: IEvent,
    days: ZonedMoment[],
    isDefragged?: boolean,
    minHeightInMinutes = 30,
    isAddedPreEvent?: boolean,
    suggestionType?: TypeEnum,
    getMinMaxHours = () => ({ minStart: 0, maxEnd: 48 }),
  ) {
    const { minStart, maxEnd } = getMinMaxHours();

    this.event = event;
    this.isDefragged = isDefragged || false;
    this.isAddedPreEvent = isAddedPreEvent || false;
    this.suggestionType = suggestionType;
    this.minHeightInMinutes = minHeightInMinutes;

    this.week = days.sort();

    this.start = new ZonedMoment(event.startTime.dateOrDateTime);
    this.end = new ZonedMoment(event.endTime.dateOrDateTime);

    // Adjust start and end for events that span multiple days.
    if (days?.length > 0) {
      const firstDay = new ZonedMoment(days[0]).startOf("day");
      if (this.start.isBefore(firstDay)) {
        this.start = firstDay;
      }
      const lastDay = new ZonedMoment(days[days.length - 1]).endOf("day");
      if (this.end.isAfter(lastDay)) {
        this.end = lastDay;
      }
    }

    const startDate = this.start;

    if (event.isAllDay) {
      this.dayIndex = days.findIndex((d) =>
        d.isSame(new ZonedMoment(event.startTime.dateOrDateTime), "days"),
      );
      this.top = 0;
      this.height = 100;
      this.bottom = 0;

      this.day = days[this.dayIndex];
      this.colWidth = 100 / days.length;
      this.colLeft = this.colWidth * this.dayIndex;

      this.width = 100;
      this.left = 0;
    } else {
      this.dayIndex = getDayIndex(startDate, days);

      const top =
        ((this.start.hour() * 2 + this.start.minute() / 30 - minStart) / (maxEnd - minStart)) * 100;
      const bottom =
        (((this.end.day() - this.start.day()) * 48 +
          this.end.hour() * 2 +
          this.end.minute() / 30 -
          minStart) /
          (maxEnd - minStart)) *
        100;

      this.top = Math.max(0, Math.min(100, top));
      this.bottom = Math.max(0, Math.min(100, bottom));
      this.height = Math.min(
        100,
        Math.max((minHeightInMinutes / 1440) * 100, this.bottom - this.top),
      );

      this.day = days[this.dayIndex];
      this.colWidth = 100 / days.length;
      this.colLeft = this.colWidth * this.dayIndex;
      this.width = this.colWidth + 0;
      this.left = this.colLeft + 0;
    }
  }
}
