import { PublicPerson } from "@clockwise/schema/v2";
import React from "react";
import { GQLPerson } from "../chat/ai-chat/utils/types";
import { ProposalOwnerAvatar } from "./ProposalOwnerAvatar";

type ProposalOwnerProps = {
  owner: GQLPerson | PublicPerson;
  titleBody: string | null;
};

export const ProposalOwner = ({ owner, titleBody }: ProposalOwnerProps) => {
  return (
    <div className="cw-pb-2 cw-flex cw-flex-row">
      <div className="cw-flex cw-items-center">
        <ProposalOwnerAvatar owner={owner} />
      </div>
      {titleBody && (
        <div className="cw-flex cw-font-semibold cw-items-center cw-p-2 cw-text-base">
          {titleBody}
        </div>
      )}
    </div>
  );
};
