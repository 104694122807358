import classNames from "classnames";
import React, { PropsWithChildren, ReactNode } from "react";

export const Card = ({
  title,
  warning,
  action,
  children,
}: PropsWithChildren<{ title: ReactNode; warning?: boolean; action?: ReactNode }>) => (
  <div
    className={classNames("cw-border cw-border-solid cw-rounded  cw-py-2 cw-px-3 cw-mb-3", {
      "cw-border-default cw-bg-default": !warning,
      "cw-border-warning cw-bg-warning": warning,
    })}
    cw-id="event-status-card"
  >
    <div className={classNames("cw-body-sm cw-font-bold", { "cw-text-warning": warning })}>
      {title}
    </div>
    <div className="cw-caption">{children}</div>
    {action && <div className="cw-pt-2">{action}</div>}
  </div>
);
