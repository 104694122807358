/**
 * @generated SignedSource<<f7ca327089006c82dea5609b993386df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FocusTimeExternalTransparency = "None" | "Opaque" | "Transparent" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SmartHoldSync_org$data = {
  readonly id: string;
  readonly primaryOrgCalendar: string;
  readonly shadowCalendarSettingsErrorable: {
    readonly __typename: "ShadowCalendarSettings";
    readonly focusTimeSyncEnabled: boolean;
    readonly lunchSyncEnabled: boolean;
    readonly travelTimeSyncEnabled: boolean;
    readonly focusTimeExternalTransparency: FocusTimeExternalTransparency | null;
    readonly focusTimeIdealMinutesPerWeek: number;
    readonly focusTimeSyncAll: boolean;
    readonly focusTimeRemindersEnabled: boolean;
    readonly lunchRemindersEnabled: boolean;
    readonly travelTimeRemindersEnabled: boolean;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly focusTimeStats: {
    readonly __typename: "FocusTimeStats";
    readonly minutesPerWeek: number | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly " $fragmentType": "SmartHoldSync_org";
};
export type SmartHoldSync_org$key = {
  readonly " $data"?: SmartHoldSync_org$data;
  readonly " $fragmentSpreads": FragmentRefs<"SmartHoldSync_org">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SmartHoldSync_org",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryOrgCalendar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "shadowCalendarSettingsErrorable",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "focusTimeSyncEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lunchSyncEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "travelTimeSyncEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "focusTimeExternalTransparency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "focusTimeIdealMinutesPerWeek",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "focusTimeSyncAll",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "focusTimeRemindersEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lunchRemindersEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "travelTimeRemindersEnabled",
              "storageKey": null
            }
          ],
          "type": "ShadowCalendarSettings",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "focusTimeStats",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minutesPerWeek",
              "storageKey": null
            }
          ],
          "type": "FocusTimeStats",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Org",
  "abstractKey": null
};
})();

(node as any).hash = "619690075cbaebdce4c33086a1e730ff";

export default node;
