import { useMutation } from "@apollo/client";
import { SaveAddEventDiffDetailsInput, SaveModifyEventDiffDetailsInput } from "@clockwise/schema";
import { useCallback } from "react";
import {
  SaveAddEventDiffDetailsDocument,
  SaveAddEventDiffDetailsMutation,
} from "../apollo/__generated__/SaveAddEventDiffDetails.generated";
import {
  SaveModifyEventDiffDetailsDocument,
  SaveModifyEventDiffDetailsMutation,
} from "../apollo/__generated__/SaveModifyEventDiffDetails.generated";

type SaveDiffEventDetailsProps = {
  onError?: (error: Error) => void;
  onCompleted?: (
    data: SaveAddEventDiffDetailsMutation | SaveModifyEventDiffDetailsMutation,
  ) => void;
};

type SaveDiffEventDetailsResult = [
  (input: SaveAddEventDiffDetailsInput | SaveModifyEventDiffDetailsInput) => Promise<void>,
  {
    saveAddDiffEventDetailsLoading: boolean;
    saveAddDiffEventDetailsError: Error | undefined;
    saveModifyDiffEventDetailsLoading: boolean;
    saveModifyDiffEventDetailsError: Error | undefined;
  },
];

export const useSaveDiffEventDetails = ({
  onError,
  onCompleted,
}: SaveDiffEventDetailsProps): SaveDiffEventDetailsResult => {
  const [
    saveAddDiffEventDetails,
    { loading: saveAddDiffEventDetailsLoading, error: saveAddDiffEventDetailsError },
  ] = useMutation(SaveAddEventDiffDetailsDocument, {
    onError,
    onCompleted,
  });
  const [
    saveModifyDiffEventDetails,
    { loading: saveModifyDiffEventDetailsLoading, error: saveModifyDiffEventDetailsError },
  ] = useMutation(SaveModifyEventDiffDetailsDocument, { onError, onCompleted });

  const onSaveDiffEventDetails = useCallback(
    async (input: SaveAddEventDiffDetailsInput | SaveModifyEventDiffDetailsInput) => {
      if ("rsvp" in input) {
        await saveModifyDiffEventDetails({
          variables: {
            input,
          },
        });
      } else {
        await saveAddDiffEventDetails({
          variables: {
            input,
          },
        });
      }
    },
    [saveAddDiffEventDetails, saveModifyDiffEventDetails],
  );

  return [
    onSaveDiffEventDetails,
    {
      saveAddDiffEventDetailsLoading,
      saveAddDiffEventDetailsError,
      saveModifyDiffEventDetailsLoading,
      saveModifyDiffEventDetailsError,
    },
  ];
};
