import { Link, Tooltip } from "@clockwise/design-system";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import pluralize from "pluralize";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TrackingEvents, track } from "../../../../../util/analytics.util";
import { SimplePlan, TeamInfo } from "../apBillingGroups.util";

const FirstSectionOfLink = ({ planType }: { planType: SimplePlan }) => {
  const onPricingPageClick = () => {
    track(TrackingEvents.ADMIN_PANEL.APBILLING_CLICK_PRICING);
    window.open(appPaths.pricingPage, "_blank");
  };

  return (
    <>
      You're currently on the <Link onClick={onPricingPageClick}>{planType} Plan</Link>
    </>
  );
};

const TeamPlanLinks = ({
  planType,
  teamInfo,
}: {
  planType: SimplePlan;
  teamInfo: TeamInfo | null;
}) => {
  const navigate = useNavigate();

  const onTeamPageClick = () => {
    if (!teamInfo?.teamId) return;
    track(TrackingEvents.ADMIN_PANEL.APBILLING_CLICK_PRIMARY_TEAM);
    navigate(`${appPaths.teamSettings}/${teamInfo.teamId}`);
  };

  if (planType === SimplePlan.Teams && teamInfo?.teamName) {
    return (
      <>
        <FirstSectionOfLink planType={planType} /> for{" "}
        <Link onClick={onTeamPageClick}>{teamInfo.teamName}</Link>.
      </>
    );
  } else {
    return (
      <>
        <FirstSectionOfLink planType={planType} />.
      </>
    );
  }
};

const UserCountLink = ({ planType, userCount }: { planType: SimplePlan; userCount: number }) => {
  const navigate = useNavigate();

  const onUserPageClick = () => {
    track(TrackingEvents.ADMIN_PANEL.APBILLING_CLICK_PRIMARY_MEMBERS);
    navigate(appPaths.members);
  };

  if (planType === SimplePlan.Free) return null;
  return (
    <>
      There {pluralize("is", userCount)}{" "}
      <Link onClick={onUserPageClick}>
        {userCount} {pluralize("user", userCount)}
      </Link>{" "}
      on your subscription.
    </>
  );
};

const AdminListLink = ({ planType, adminList }: { planType: SimplePlan; adminList: string[] }) => {
  if (planType === SimplePlan.Free) return null;
  if (!adminList.length) {
    return (
      <span>
        Currently, your plan doesn't have an admin, please contact support@getclockwise.com to claim
        or assign an admin.
      </span>
    );
  }
  const firstAdmin = adminList[0];
  const remainingAdmins = adminList.slice(1);
  const hasMultipleAdmins = Boolean(remainingAdmins.length);
  return (
    <span>
      The admin of your plan is{" "}
      <Link target="_blank" href={`mailto:${firstAdmin}`}>
        {firstAdmin}
      </Link>
      {hasMultipleAdmins ? " " : "."}
      {hasMultipleAdmins && (
        <>
          {" "}
          and{" "}
          <Tooltip
            title={remainingAdmins.map((admin) => (
              <div key={admin}>{admin}</div>
            ))}
          >
            <span className="cw-underline">
              {remainingAdmins.length} {pluralize("other", remainingAdmins.length)}
            </span>
          </Tooltip>
          .
        </>
      )}
    </span>
  );
};

export const CardCopy = ({
  planType,
  teamInfo,
  userCount,
  adminList,
}: {
  planType: SimplePlan;
  teamInfo: TeamInfo | null;
  userCount: number;
  adminList: string[];
}) => {
  return (
    <div className="cw-body-lg cw-my-5">
      <TeamPlanLinks planType={planType} teamInfo={teamInfo} />{" "}
      <UserCountLink planType={planType} userCount={userCount} />{" "}
      <AdminListLink planType={planType} adminList={adminList} />
    </div>
  );
};
