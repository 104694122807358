//////////////////
// IMPORTS
//////////////////
// libraries
import * as React from "react";
// slack-button imports
import { IProps, IState } from "./SlackButtonTypes";
// util
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import {
  generateSlackAuthUrl,
  generateSlackAuthUrlForTeam,
} from "#webapp/src/util/third-party-oauth.util";
import { useFeatureFlag } from "../../launch-darkly";

type PropsWithDogfoodEnabled = IProps & { dogfoodEnabled: boolean };

//////////////////
// COMPONENT
//////////////////
class SlackButtonComponent extends React.Component<PropsWithDogfoodEnabled, IState> {
  private popup: any;
  private popupInterval: ReturnType<typeof setInterval>;

  constructor(props: PropsWithDogfoodEnabled) {
    super(props);
  }

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-
  public componentWillUnmount() {
    if (this.popupInterval) {
      clearInterval(this.popupInterval);
    }
  }

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  private addSlackTeams = (teamId: string) => {
    const url = generateSlackAuthUrlForTeam("chrome", teamId, this.props.dogfoodEnabled);
    this.popup = window.open(url, "_blank", "width=650,height=780");
    track(TrackingEvents.SLACK.BEGIN_ADD);

    // detect when the popup is closed
    clearInterval(this.popupInterval);
    this.popupInterval = setInterval(() => {
      if (this.popup && this.popup.closed) {
        track(TrackingEvents.SLACK.OAUTH_WINDOW_CLOSED);

        // it was closed, refetch
        clearInterval(this.popupInterval);
        this.props.onAuthPopupClosed();
      }
    }, 500);
  };

  private addSlackV2 = () => {
    this.popup = window.open(
      generateSlackAuthUrl("chrome", this.props.dogfoodEnabled),
      "_blank",
      "width=650,height=780",
    );
    track(TrackingEvents.SLACK.BEGIN_ADD);

    // detect when the popup is closed
    clearInterval(this.popupInterval);
    this.popupInterval = setInterval(() => {
      if (this.popup && this.popup.closed) {
        track(TrackingEvents.SLACK.OAUTH_WINDOW_CLOSED);

        // it was closed, refetch
        clearInterval(this.popupInterval);
        this.props.onAuthPopupClosed();
      }
    }, 500);
  };

  private addSlack(event: any, isTeamsSlack?: boolean, teamId?: string) {
    event.preventDefault();

    if (isTeamsSlack && teamId) {
      return this.addSlackTeams(teamId);
    } else {
      return this.addSlackV2();
    }
  }

  // ~-~-~-~-~-~-~-
  // Rendering
  // ~-~-~-~-~-~-~-
  private renderDefault = () => {
    const { isTeamsSlack, teamId } = this.props;
    return (
      <div>
        <a
          href=""
          onClick={(event: any) => {
            this.addSlack(event, isTeamsSlack, teamId);
            if (this.props.onClick) {
              this.props.onClick();
            }
          }}
        >
          <img
            alt=""
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </div>
    );
  };

  private renderCustom = (ButtonCmpt: JSX.Element) => {
    const { isTeamsSlack, teamId } = this.props;
    return React.cloneElement(ButtonCmpt, {
      onClick: (e: any) => {
        this.addSlack(e, isTeamsSlack, teamId);
        if (this.props.onClick) {
          this.props.onClick();
        }
      },
      key: "slackButtonCustom",
    });
  };

  public render() {
    const { target } = this.props;

    if (target) {
      return this.renderCustom(target);
    } else {
      return this.renderDefault();
    }
  }
}

export const SlackButton: React.FC<IProps> = (props) => {
  const [dogfoodEnabled] = useFeatureFlag("SlackDogfoodApp");

  return <SlackButtonComponent {...props} dogfoodEnabled={dogfoodEnabled} />;
};
