import { DateTimeUnit } from "luxon";
import { getTimeframeFromDates } from "../../admin-select/timeframe";

const getChartGroupBy = (maxDate: Date, minDate: Date) => {
  const timeframe = getTimeframeFromDates(maxDate, minDate);

  let groupBy: DateTimeUnit = "day";
  if (timeframe === "month") groupBy = "week";
  if (timeframe === "year") groupBy = "month";

  return groupBy;
};

export default getChartGroupBy;
