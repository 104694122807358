import { DateTime, Interval } from "luxon";
import React from "react";
import { CalendarPositioner } from "../calendar-positioner/CalendarPositioner";
import { CalendarAvailabilityBlock } from "./CalendarAvailability";

export const SingleCalendarAvailability = ({
  availability,
  dateTime,
  columnIndex,
  columnCount,
  minWidth,
}: {
  availability: Interval;
  dateTime: DateTime;
  columnIndex: number;
  columnCount: number;
  minWidth: number;
}) => {
  const positionables = [
    {
      key: `${availability.toISO()}-${columnIndex}`,
      interval: availability,
      render: CalendarAvailabilityBlock,
    },
  ];
  return (
    <div className="cw-pointer-events-none">
      <CalendarPositioner
        columnCountOverride={columnCount}
        columnIndexOverride={columnIndex}
        dateTimes={[dateTime]}
        positionables={positionables}
        minWidth={minWidth}
      />
    </div>
  );
};
