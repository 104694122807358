import { Button, TextField } from "@clockwise/design-system";
import { useWindowSize } from "@clockwise/web-commons/src/util/react.util";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import { TrackingEvents, track } from "../../../../../util/analytics.util";
import { LockedDialog } from "../../../../invite-modal/LockedDialog";

export const SeatAdjustmentForm = ({
  isOpen,
  closeSeatAdjustmentModal,
  currentUsageQuantity,
  billedQuantity,
  onSaveSeatRequest,
}: {
  isOpen: boolean;
  closeSeatAdjustmentModal: () => void;
  currentUsageQuantity: number;
  billedQuantity: number;
  onSaveSeatRequest: (newSeatCount: number) => void;
}) => {
  const { width: windowWidth } = useWindowSize();
  const [inputError, setInputError] = useState<string>("");
  const [seatCountInput, setSeatCountInput] = useState<string>("");

  useEffect(() => {
    if (isOpen) {
      const largerOfTheTwo = Math.max(currentUsageQuantity, billedQuantity);
      setSeatCountInput(String(largerOfTheTwo));
      track(TrackingEvents.ADMIN_PANEL.APBILLING_SEAT_CHANGE_FORM);
      setInputError("");
    }
  }, [isOpen, billedQuantity, currentUsageQuantity]);

  const verifyAndConfirmChanges = () => {
    const finalRequestedCount = parseInt(seatCountInput);
    if (!isNaN(finalRequestedCount) && finalRequestedCount > 0) {
      if (finalRequestedCount < currentUsageQuantity) {
        setInputError("Seat count cannot be less than the number of users in your plan");
      } else {
        onSaveSeatRequest(finalRequestedCount);
        closeSeatAdjustmentModal();
        setInputError("");
      }
    } else {
      setInputError("Invalid seat count");
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (e.target.value.length > 3) return;
    if (!/\D/.test(value) || value === "") {
      setSeatCountInput(e.target.value);
    }
  };

  return (
    <LockedDialog
      disableRestoreFocus
      disableScrollLock
      fullScreen={windowWidth < 500}
      maxWidth={false}
      onClose={closeSeatAdjustmentModal}
      open={isOpen}
    >
      <div className="cw-invisible cw-fixed cw-w-auto cw-overflow-auto" />{" "}
      <div className="cw-rounded-md cw-w-[680px] cw-p-8">
        <h3 className="cw-heading-xl cw-text-accent">Update seat count</h3>
        <p className="cw-mt-4">
          You're currently using {currentUsageQuantity} {pluralize("seat", currentUsageQuantity)} in
          your {billedQuantity}
          -seat plan. Update your plan size to:
        </p>
        <div className="cw-relative cw-my-4">
          <TextField
            type="number"
            error={Boolean(inputError)}
            errorMessage={inputError}
            value={seatCountInput}
            onChange={onInputChange}
            fullWidth
          />
          <span className="cw-absolute cw-top-[7px] cw-left-[50px] cw-body-base">seat(s)</span>
        </div>
        <span className="cw-body-base cw-text-subtle">
          Any changes made will take effect on your next billing date. We will send an email 3 days
          prior to confirm the final seat count.
        </span>
        <div className="cw-flex cw-mt-[30px] cw-justify-between">
          <Button variant="outlined" onClick={closeSeatAdjustmentModal}>
            Cancel
          </Button>
          <Button variant="solid" sentiment="positive" onClick={verifyAndConfirmChanges}>
            Confirm
          </Button>
        </div>
      </div>
    </LockedDialog>
  );
};
