import { OutOfOfficeCards } from "@clockwise/web-commons/src/components/calendar";
import {
  EventType,
  useReadActiveEvent,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getOwnCalendarColor } from "@clockwise/web-commons/src/util/calendar-coloring";
import { DateTime } from "luxon";
import * as React from "react";
import { useUpdateActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import { usePlannerContext } from "../Context";
import { useReadCalendarColors } from "../hooks/CalendarColorsContext";
import { hasIntervalAllDayLong } from "./utils/hasIntervalAllDayLong";

type Props = {
  dateTimes: DateTime[];
};

export const OOOEventsUnderlay = ({ dateTimes }: Props) => {
  const { allDayOOOEvents: allDayOOOEventsNullable } = usePlannerContext();
  const activeEvent = useReadActiveEvent();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateActiveEventDiff = useUpdateActiveEventDiff();
  const calendarColorSets = useReadCalendarColors();

  const activeEventIsProposal = activeEvent && activeEvent.type === EventType.Proposal;
  const allDayOOOEvents = allDayOOOEventsNullable ?? {};
  const eventsFlat = Object.values(allDayOOOEvents).flatMap((events) => events);

  const handleSelectEvent = (calendarId: string, externalEventId: string) => {
    if (!activeEventIsProposal) {
      updateActiveEventDiff(null);
      updateActiveEvent({ calendarId, externalEventId, type: EventType.Event });
    }
  };

  return (
    <OutOfOfficeCards
      dateTimes={dateTimes}
      outOfOfficeIntervals={eventsFlat.map((event) => ({
        colorSet: calendarColorSets[event.calendarIds[0]] || getOwnCalendarColor(),
        headless: hasIntervalAllDayLong(event) || event.interval.toDuration().as("days") >= 1,
        interval: event.interval,
        key: event.key,
        onClick: () => handleSelectEvent(event.calendarIds[0], event.externalEventId || ""),
        subText: event.subText,
        text: event.text,
      }))}
    />
  );
};
