import { Loader } from "@clockwise/design-system/src/components/Loader";
import React from "react";

export const Loading = () => {
  return (
    <div className="cw-flex cw-flex-col cw-justify-center cw-h-[500px] cw-items-center">
      <Loader size="xl" sentiment="positive" />
    </div>
  );
};
