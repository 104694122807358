import { useMutation } from "@apollo/client";
import { Button, Switch, TextField } from "@clockwise/design-system";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { SudoToggleAutoSyncForOrgDocument } from "../graphql/__generated__/SudoToggleAutoSyncForOrg.generated";
import { ButtonWrapper } from "./ButtonWrapper";
import { FormWrapper } from "./FormWrapper";

export const ToggleAutoSync = () => {
  const [orgId, setOrgId] = useState<string | undefined>();
  const [adminEmail, setAdminEmail] = useState<string | undefined>();
  const [billingGroupId, setBillingGroupId] = useState<string | undefined>();
  const [enableAutoSync, setEnableAutoSync] = useState<boolean>(true);

  const [toggleAutoSync, { loading }] = useMutation(SudoToggleAutoSyncForOrgDocument);

  const resetFields = () => {
    setOrgId("");
    setAdminEmail("");
    setBillingGroupId("");
  };

  const onToggleAutoSync = () => {
    if (!orgId || !adminEmail || !billingGroupId) {
      return;
    }

    void toggleAutoSync({
      variables: { input: { orgId, adminEmail, billingGroupId, autoSyncEnabled: enableAutoSync } },
      onCompleted: () => {
        toast.success("Auto sync updated successfully");
        resetFields();
      },
      onError: (error) => {
        toast.error("Error updating auto sync " + error.message);
      },
    });
  };

  const allFieldsPopulated = orgId && adminEmail && billingGroupId;
  return (
    <FormWrapper>
      <div className="cw-flex cw-gap-x-2 cw-flex-wrap">
        <TextField
          label="Billing Group ID"
          value={billingGroupId}
          onChange={(e) => setBillingGroupId(e.target.value)}
        />
        <TextField
          label="Admin Email"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
        />
        <TextField label="Org ID" value={orgId} onChange={(e) => setOrgId(e.target.value)} />
      </div>
      <div className="cw-flex cw-items-center cw-flex-wrap">
        <div className="cw-text-default s cw-mr-2">
          {enableAutoSync ? "Enable" : "Disable"} Auto Sync
        </div>
        <Switch checked={enableAutoSync} onChange={(enabled) => setEnableAutoSync(enabled)} />
      </div>

      <ButtonWrapper>
        <Button
          size="medium"
          disabled={loading || !allFieldsPopulated}
          sentiment={"positive"}
          fullWidth={false}
          onClick={onToggleAutoSync}
        >
          Update Auto Sync
        </Button>
      </ButtonWrapper>
    </FormWrapper>
  );
};
