import { IOrg } from "#webapp/src/__schema__";
import { useMutation, useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { ActivityFilter, BillingGroup, SortDirection, SortType } from "@clockwise/schema";
import { keyBy } from "lodash";
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useMonetization } from "../../../../hooks/useMonetization";
import { TrackingEvents, track } from "../../../../util/analytics.util";
import { getCurrentOrg } from "../../../../util/org.util";
import {
  AugmentedPersonMap,
  BillingGroupByCalendarId,
  BillingGroupRoleEnum,
  MAX_MEMBERS_DISPLAYED,
  NewMemberPerson,
  getAugmentedPersons,
  getCalendarIdsToQuery,
  getCurrentPersonsList,
  getPersonFromCalId,
  getVisibleBillingGroupList,
} from "./APMembers.util";
import {
  AddAdminForBillingGroupDocument,
  AddManualMembersForUsersPageDocument,
  AdminPanelUsersListPaginatedDocument,
  BillingGroupsByUserDocument,
  RemoveAdminForBillingGroupDocument,
  RemoveManualMembersForBillingGroupDocument,
} from "./__generated__/APMembers.generated";

export type ContextOfAPMembers = ReturnType<typeof useAPMembersForProvider>;

const Context = createContext<ContextOfAPMembers | null>(null);

export const useAPMembers = (): ContextOfAPMembers => {
  const context = useContext(Context);

  if (!context) {
    console.error(
      "Tried to access Admin BG context but it is not available. Please ensure you are inside a AdminBillingGroupsMembersProvider.",
    );
  }

  return context as ContextOfAPMembers;
};

const useAPMembersForProvider = () => {
  const [listOfCurrentCalendarIds, setListOfCurrentCalendarIds] = useState<string[]>([]);
  const [currentPersons, setCurrentPersons] = useState<NewMemberPerson[]>([]);
  const [isInitialUnsortedPersonList, setIsInitialUnsortedPersonList] = useState<boolean>(true);
  const [adminIdToRemove, setAdminIdToRemove] = useState<string>("");
  const [reassignAdminDialogOpen, setReassignAdminDialogOpen] = useState<boolean>(false);
  const [adminSelectDialogBillingGroup, setAdminSelectDialogBillingGroup] = useState<string>("");
  const [
    billingGroupsToCalendarId,
    setBillingGroupsToCalendarId,
  ] = useState<BillingGroupByCalendarId | null>({});
  const [org, setOrg] = useState<IOrg | null>(null);
  const [memberSearchQuery, setMemberSearchQuery] = useState<string>("");
  const [billingGroupAndTeamIdFilter, setBillingGroupAndTeamIdFilters] = useState<{
    bgId: string;
    teamId: string;
  } | null>(null);
  const [currentSortType, setCurrentSortType] = useState<SortType>(SortType.FullName);
  const [currentSortDirection, setCurrentSortDirection] = useState<SortDirection>(
    SortDirection.Ascending,
  );
  const [currentUsersBillingGroups, setCurrentUsersBillingGroups] = useState<BillingGroup[]>([]);
  const [pagination, setPagination] = useState<{ token: string; next: boolean }>({
    token: "",
    next: false,
  });
  const [recentlyInvitedPersons, setRecentlyInvitedPersons] = useState<AugmentedPersonMap>({});
  const [initialQueriesFetched, setInitialQueriesFetched] = useState(false);
  const [
    recentlyRequestedInvitePersons,
    setRecentlyRequestedInvitePersons,
  ] = useState<AugmentedPersonMap>({});
  const { primaryBillingGroupId, isFeatureGridFetched } = useMonetization();
  const [bulkAddMembers] = useMutation(AddManualMembersForUsersPageDocument);
  const [addAdminToBillingGroup] = useMutation(AddAdminForBillingGroupDocument);
  const [removeAdminFromBillingGroup] = useMutation(RemoveAdminForBillingGroupDocument);
  const [bulkRemoveMember] = useMutation(RemoveManualMembersForBillingGroupDocument);

  useEffect(() => {
    setIsInitialUnsortedPersonList(false);
  }, [currentSortType, currentSortDirection]);

  useEffect(() => {
    if (isFeatureGridFetched && !org) {
      // Only set the billing group filter if the user is on the billing group page and a billingGroupId param is present
      // TODO, fix broken interaction, currently this param does not seem to work
      // const idFromGlobalState = params?.billingGroupId ?? "";
      setBillingGroupAndTeamIdFilters({ bgId: "", teamId: "" });
    }
  }, [isFeatureGridFetched, org, primaryBillingGroupId]);

  const activityFilter = useMemo(() => {
    if (!!billingGroupAndTeamIdFilter?.bgId || !!billingGroupAndTeamIdFilter?.teamId) {
      return ActivityFilter.CurrentUser;
    } else {
      return ActivityFilter.CurrentUserOrInvited;
    }
  }, [billingGroupAndTeamIdFilter]);

  const currentUserBgIdList = useMemo(() => {
    return currentUsersBillingGroups.map((bg) => bg.id);
  }, [currentUsersBillingGroups]);

  useQuery(BillingGroupsByUserDocument, {
    variables: {
      calendarIds: getCalendarIdsToQuery(listOfCurrentCalendarIds, org?.primaryOrgCalendar),
    },
    skip: !listOfCurrentCalendarIds.length,
    onCompleted: (res) => {
      const newOrgResponse = getCurrentOrg(res?.viewer);
      const newResponse = newOrgResponse?.billingGroupsByCalendarIds;
      const hashedOutBillingGroups = keyBy(newResponse, "calendarId");
      if (org?.primaryOrgCalendar) {
        const currentUsersBillingGroups = hashedOutBillingGroups[org?.primaryOrgCalendar];
        if (currentUsersBillingGroups) {
          setCurrentUsersBillingGroups(
            ((currentUsersBillingGroups?.allBillingGroupsOfCalendarId as unknown) ||
              []) as BillingGroup[],
          );
        }
      }
      if (hashedOutBillingGroups) {
        setBillingGroupsToCalendarId(
          (hashedOutBillingGroups as unknown) as BillingGroupByCalendarId,
        );
      }
      //This just shows the initial loading screen on load if its false (users show up but look free without this)
      setInitialQueriesFetched(true);
    },
  });

  useQuery(AdminPanelUsersListPaginatedDocument, {
    variables: {
      sortType: currentSortType,
      sortDirection: currentSortDirection,
      searchQuery: memberSearchQuery || null,
      activityFilter: activityFilter,
      limit: MAX_MEMBERS_DISPLAYED,
      billingGroupId: billingGroupAndTeamIdFilter?.bgId || null,
      teamId: billingGroupAndTeamIdFilter?.teamId || null,
      pageToken: pagination.token || null,
      fetchNext: pagination.next || null,
    },
    skip: !billingGroupAndTeamIdFilter,
    onCompleted: (res) => {
      const newOrgResponse = getCurrentOrg(res?.viewer);
      if (newOrgResponse) {
        setOrg(newOrgResponse as IOrg);
        const orgPersons = getValue(newOrgResponse.orgPersonListPaginatedErrorable)?.searchPersons;
        const usersCalendarIds = orgPersons?.map((orgPerson) => orgPerson.person.primaryCalendarId);
        if (usersCalendarIds) {
          setListOfCurrentCalendarIds(usersCalendarIds);
        }
      }
    },
  });

  const updateRecentlyRequestedInvitePersons = useCallback(
    (calId: string) => {
      const person = getPersonFromCalId(currentPersons, calId);
      const requestedInvitePersons = { ...recentlyRequestedInvitePersons };
      if (person) {
        person.recentlyRequested = true;
        requestedInvitePersons[calId] = person;
      }
      setRecentlyRequestedInvitePersons(requestedInvitePersons);
    },
    [currentPersons, recentlyRequestedInvitePersons],
  );

  const handlePagination = useCallback((newPage: string, newFetchNext: boolean) => {
    setPagination({
      token: newPage,
      next: newFetchNext,
    });
  }, []);

  useEffect(() => {
    if (!org || !billingGroupsToCalendarId) return;
    const newAugmentedPersons = getAugmentedPersons(
      org,
      billingGroupsToCalendarId,
      recentlyInvitedPersons,
      recentlyRequestedInvitePersons,
      memberSearchQuery,
    );
    const newPersons = getCurrentPersonsList(newAugmentedPersons, isInitialUnsortedPersonList);
    setCurrentPersons(newPersons);
  }, [org, billingGroupsToCalendarId]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateCurrentPersonInviteStatus = useCallback(
    (calId: string) => {
      const updatedPersons = currentPersons.map((person) => {
        if (person.targetCalendarId === calId) {
          const updatedPerson = {
            ...person,
            isPending: true,
          };
          return updatedPerson;
        }
        return person;
      });
      setCurrentPersons(updatedPersons);
    },
    [currentPersons],
  );

  const updateRecentlyInvitedPersons = useCallback(
    (calId: string) => {
      const person = getPersonFromCalId(currentPersons, calId);
      const invitedPersons = { ...recentlyInvitedPersons };
      if (person) {
        person.recentlySent = true;
        invitedPersons[calId] = person;
      }
      setRecentlyInvitedPersons(invitedPersons);
    },
    [currentPersons, recentlyInvitedPersons],
  );

  const billingGroupsForOrg = useMemo(() => {
    return getVisibleBillingGroupList(billingGroupsToCalendarId || {});
  }, [billingGroupsToCalendarId]);

  const onAdminChangeFailure = (_role: BillingGroupRoleEnum) => {
    const errorMsg = "Failed to update role";
    logger.error(errorMsg);
    toast.error(errorMsg);
  };

  const onRemoveMember = useCallback(
    async (personIds: string[], billingGroupId: string, errorMsg?: string) => {
      track(TrackingEvents.ADMIN_PANEL.MEMBERS_REMOVE_FROM_BILLING_GROUP, { billingGroupId });
      if (errorMsg) {
        setAdminIdToRemove(personIds[0]);
        setAdminSelectDialogBillingGroup(billingGroupId);
        setReassignAdminDialogOpen(true);
      } else {
        await bulkRemoveMember({
          variables: {
            input: {
              orgRelayId: org?.id || "",
              billingGroupId,
              personIds: personIds,
            },
          },
          onCompleted: () => {
            const message =
              personIds.length > 1
                ? "Successfully removed members!"
                : "Successfully removed member!";
            toast.success(message);
          },
          onError: () => {
            const errorMsg = "Failed to remove member";
            logger.error(errorMsg);
            toast.error(errorMsg);
          },
        });
      }
    },
    [org?.id, bulkRemoveMember],
  );

  const onAddMember = useCallback(
    async (personIds: string[], billingGroupId: string) => {
      track(TrackingEvents.ADMIN_PANEL.MEMBERS_ADD_TO_BILLING_GROUP, { billingGroupId });
      await bulkAddMembers({
        variables: {
          input: {
            orgRelayId: org?.id || "",
            billingGroupId,
            personIds: personIds,
          },
        },
        onCompleted: () => {
          const message =
            personIds.length > 1
              ? "Successfully added members! Refresh for updated user list."
              : "Successfully added member! Refresh for updated user list.";
          toast.success(message);
        },
        onError: () => {
          const errorMsg = "Failed to add member";
          logger.error(errorMsg);
          toast.error(errorMsg);
        },
      });
    },
    [org?.id, bulkAddMembers],
  );

  const handleSearch = useCallback(
    (newSearchQuery: string) => {
      setMemberSearchQuery(newSearchQuery);
      handlePagination("", false);
    },
    [handlePagination],
  );

  const handleFilterChange = useCallback(
    (newBgId: string, newTeamId: string) => {
      setBillingGroupAndTeamIdFilters({ bgId: newBgId, teamId: newTeamId });
      handlePagination("", false);
    },
    [handlePagination],
  );

  const onAdminChangeSuccess = useCallback(
    (role: BillingGroupRoleEnum, userId: string) => {
      toast.success("Successfully updated role!");
      const updatedPersons = currentPersons.map((person) => {
        if (person.userId === userId) {
          const updatedPerson = {
            ...person,
            role,
          };
          return updatedPerson;
        }
        return person;
      });
      setCurrentPersons(updatedPersons);
    },
    [currentPersons],
  );

  const onAdminSelectChange = useCallback(
    async (
      userId: string,
      billingGroupId: string,
      role: BillingGroupRoleEnum,
      personId: string,
      errorMsg?: string,
    ) => {
      track(TrackingEvents.ADMIN_PANEL.MEMBERS_SET_BILLING_GROUP_ROLE, { role });
      if (errorMsg && personId) {
        setAdminIdToRemove(personId);
        setAdminSelectDialogBillingGroup(billingGroupId);
        setReassignAdminDialogOpen(true);
      } else if (role === BillingGroupRoleEnum.Admin) {
        await addAdminToBillingGroup({
          variables: {
            input: {
              orgRelayId: org?.id || "",
              billingGroupId,
              userId,
            },
          },
          onCompleted: () => onAdminChangeSuccess(BillingGroupRoleEnum.Admin, userId),
          onError: () => onAdminChangeFailure(BillingGroupRoleEnum.Admin),
        });
      } else {
        await removeAdminFromBillingGroup({
          variables: {
            input: {
              orgRelayId: org?.id || "",
              billingGroupId,
              userId,
            },
          },
          onCompleted: () => onAdminChangeSuccess(BillingGroupRoleEnum.Member, userId),
          onError: () => onAdminChangeFailure(BillingGroupRoleEnum.Member),
        });
      }
    },
    [addAdminToBillingGroup, onAdminChangeSuccess, org?.id, removeAdminFromBillingGroup],
  );

  return useMemo(() => {
    return {
      billingGroupsToCalendarId: billingGroupsToCalendarId || {},
      org,
      handlePagination,
      setCurrentSortDirection,
      setCurrentSortType,
      currentSortType,
      currentSortDirection,
      memberSearchQuery,
      handleSearch,
      bgAndTeamFilters: billingGroupAndTeamIdFilter || { bgId: "", teamId: "" },
      currentUserBgIdList,
      setRecentlyRequestedInvitePersons,
      nextPersonId: getValue(org?.orgPersonListPaginatedErrorable)?.nextPersonId || null,
      previousPersonId: getValue(org?.orgPersonListPaginatedErrorable)?.previousPersonId || null,
      currentPersons,
      onAddMember,
      onRemoveMember,
      billingGroupsForOrg,
      adminSelectDialogBillingGroup,
      adminIdToRemove,
      addAdminToBillingGroup,
      onAdminSelectChange,
      handleFilterChange,
      reassignAdminDialogOpen,
      setReassignAdminDialogOpen,
      currentUsersBillingGroups,
      updateRecentlyRequestedInvitePersons,
      updateRecentlyInvitedPersons,
      updateCurrentPersonInviteStatus,
      initialQueriesFetched,
      primaryBillingGroupId,
    };
  }, [
    billingGroupsToCalendarId,
    org,
    handlePagination,
    currentUserBgIdList,
    setCurrentSortDirection,
    setCurrentSortType,
    setRecentlyRequestedInvitePersons,
    currentSortType,
    currentSortDirection,
    memberSearchQuery,
    handleSearch,
    billingGroupAndTeamIdFilter,
    currentPersons,
    onAddMember,
    onRemoveMember,
    billingGroupsForOrg,
    adminSelectDialogBillingGroup,
    adminIdToRemove,
    addAdminToBillingGroup,
    onAdminSelectChange,
    reassignAdminDialogOpen,
    handleFilterChange,
    setReassignAdminDialogOpen,
    currentUsersBillingGroups,
    updateRecentlyRequestedInvitePersons,
    updateRecentlyInvitedPersons,
    updateCurrentPersonInviteStatus,
    initialQueriesFetched,
    primaryBillingGroupId,
  ]);
};

export const APMembersProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useAPMembersForProvider();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const _APMembersProvider = Context.Provider;
