import { AddDiffSummary, ModifyDiffSummary } from "@clockwise/schema";
import { DateTime, Interval } from "luxon";

type objWithStart =
  | Pick<AddDiffSummary, "time">
  | Pick<ModifyDiffSummary, "updatedTime" | "currentTime">;

export const getEarliestDiffStartAndEnd = (diffSummaries: objWithStart[]) => {
  let firstStart = DateTime.invalid("No time found");
  let firstEnd = DateTime.invalid("No time found");

  diffSummaries.forEach((diffSummary) => {
    const start = getDiffStart(diffSummary);
    const end = getDiffEnd(diffSummary);
    if (start.isValid) {
      if (!firstStart.isValid || start < firstStart) {
        firstStart = start;
        firstEnd = end;
      }
    }
  });

  return { firstStart, firstEnd };
};

export const getEarliestDiffStart = (diffSummaries: objWithStart[]) => {
  let firstStart = DateTime.invalid("No time found");

  diffSummaries.forEach((diffSummary) => {
    const start = getDiffStart(diffSummary);
    if (start.isValid) {
      if (!firstStart.isValid || start < firstStart) {
        firstStart = start;
      }
    }
  });

  return firstStart;
};

export const getDiffStart = (diffSummary: objWithStart) => {
  if ("time" in diffSummary && typeof diffSummary.time === "string") {
    return Interval.fromISO(diffSummary.time).start;
  } else if ("updatedTime" in diffSummary && typeof diffSummary.updatedTime === "string") {
    return Interval.fromISO(diffSummary.updatedTime).start;
  } else if ("currentTime" in diffSummary && typeof diffSummary.currentTime === "string") {
    return Interval.fromISO(diffSummary.currentTime).start;
  } else {
    return DateTime.invalid("No time found");
  }
};

export const getDiffEnd = (diffSummary: objWithStart) => {
  if ("time" in diffSummary && typeof diffSummary.time === "string") {
    return Interval.fromISO(diffSummary.time).end;
  } else if ("updatedTime" in diffSummary && typeof diffSummary.updatedTime === "string") {
    return Interval.fromISO(diffSummary.updatedTime).end;
  } else if ("currentTime" in diffSummary && typeof diffSummary.currentTime === "string") {
    return Interval.fromISO(diffSummary.currentTime).end;
  } else {
    return DateTime.invalid("No time found");
  }
};
