import React from "react";

export const ClockwiseLogo = ({
  width = "24",
  height = "24",
  viewBox = "0 0 24 24",
  fill,
}: {
  width?: string;
  height?: string;
  viewBox?: string;
  fill?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0476 21.2194C11.0476 21.8285 10.9862 22.4236 10.8706 23C9.37125 22.7993 7.96167 21.8822 7.27694 20.2487L7.21792 20.1086C6.41278 18.1881 4.87097 16.6619 2.92542 15.8661L2.77903 15.8055C1.13333 15.1334 0.205417 13.7402 0 12.2607C0.583194 12.1464 1.18764 12.0857 1.80625 12.0857C6.91097 12.0857 11.0476 16.1742 11.0476 21.2194ZM11.0476 2.03947C11.0476 1.4304 10.9862 0.832994 10.8706 0.256592C9.37125 0.457283 7.96167 1.37439 7.27931 3.00792L7.22028 3.15027C6.4175 5.07084 4.87333 6.59469 2.9325 7.39045L2.78375 7.45112C1.13097 8.12554 0.205417 9.51637 0 10.9959C0.583194 11.1102 1.18764 11.1709 1.80625 11.1709C6.91097 11.1709 11.0476 7.08241 11.0476 2.03714V2.03947ZM15.7368 20.2487L15.7958 20.1086C16.601 18.1881 18.1451 16.6619 20.0883 15.8661L20.2324 15.8078C21.8828 15.1334 22.8107 13.7402 23.0161 12.2584C22.4329 12.144 21.8285 12.0833 21.2099 12.0833C16.1051 12.0833 11.9685 16.1718 11.9685 21.2171C11.9685 21.8262 12.0299 22.4213 12.1456 22.9977C13.6449 22.797 15.0544 21.8799 15.7368 20.2463V20.2487ZM21.2099 11.1732C21.8285 11.1732 22.4306 11.1126 23.0161 10.9982C22.8107 9.5187 21.8851 8.12554 20.2324 7.45112L20.086 7.39045C18.1428 6.59469 16.601 5.07084 15.7958 3.15027L15.7368 3.00792C15.0544 1.37439 13.6449 0.459616 12.1456 0.256592C12.0299 0.832994 11.9685 1.42807 11.9685 2.03947C11.9685 7.08474 16.1051 11.1732 21.2099 11.1732Z"
        fill={fill || "#B6FF80"}
      />
    </svg>
  );
};
