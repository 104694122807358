import React from "react";
import Arrow from "./assets/arrow.svg";
import Burst from "./assets/burst.svg";
import Check from "./assets/check.svg";
import Heart from "./assets/heart.svg";
import Underline from "./assets/line.svg";
import Slack from "./assets/slack.svg";
import ThinnerLine from "./assets/thin-line.svg";
export enum IconVariant {
  Heart = "Heart",
  Burst = "Burst",
  Check = "Check",
  Arrow = "Arrow",
  Line = "Line",
  Thinnerline = "Thinnerline",
  Slack = "Slack",
}
export const Header = ({
  children,
  iconVariant,
}: {
  children: React.ReactNode;
  iconVariant?: IconVariant;
}) => {
  const renderWithIcon = () => {
    switch (iconVariant) {
      case IconVariant.Heart:
        return (
          <div className="cw-flex cw-items-center cw-justify-center">
            {children}
            <img src={Heart} className="cw-pl-3 cw-hidden md:cw-block" />
          </div>
        );
      case IconVariant.Burst:
        return (
          <div className="cw-flex cw-items-center cw-justify-center">
            <img src={Burst} className="cw-pr-3 cw-pt-2 cw-hidden md:cw-block" />
            {children}
            <img src={Burst} className="cw-pl-3 cw-pb-2 cw-hidden md:cw-block" />
          </div>
        );
      case IconVariant.Line:
        return (
          <div className="cw-flex cw-flex-col cw-items-end">
            {children}
            <img src={Underline} className="-cw-mt-4 cw-hidden md:cw-block cw-w-[90px]" />
          </div>
        );
      case IconVariant.Thinnerline:
        return (
          <div className="cw-flex cw-flex-col cw-items-end">
            {children}
            <img
              src={ThinnerLine}
              className="-cw-mt-2 cw-hidden lg:cw-block cw-w-[198px] cw-mr-[337px]"
            />
          </div>
        );
      case IconVariant.Arrow:
        return (
          <div className="cw-flex cw-items-center cw-justify-center">
            <img src={Arrow} className="cw-pr-3 cw-pb-10 cw-hidden md:cw-block" />
            {children}
          </div>
        );
      case IconVariant.Slack:
        return (
          <div className="cw-flex cw-items-center cw-justify-center">
            <img src={Arrow} className="cw-pr-3 cw-pb-10 cw-hidden md:cw-block" />
            {children}
            <img src={Slack} className="cw-pb-3 cw-hidden md:cw-block cw-w-[75px] -cw-ml-2" />
          </div>
        );
      case IconVariant.Check:
        return (
          <div className="cw-flex cw-items-center cw-justify-center">
            <img src={Check} className="cw-pr-3 cw-pb-2 cw-hidden md:cw-block" />
            {children}
          </div>
        );
      default:
        return children;
    }
  };

  return (
    <div className="cw-flex cw-items-center cw-max-w-[800px] cw-text-center md:cw-heading-3xl cw-heading-2xl cw-py-2">
      {!!iconVariant ? renderWithIcon() : children}
    </div>
  );
};
