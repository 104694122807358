import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar";
import { SingleCalendarAvailability } from "@clockwise/web-commons/src/components/calendar/calendar-availability/SingleCalendarAvailability";
import { DateTime, Interval } from "luxon";
import React from "react";
import { SingleColumnOOOEventsUnderlay } from "../ooo-events/SingleColumnOOOEventsUnderlay";
import { SingleColumnPlannerEvents } from "./SingleColumnPlannerEvents";

export const SingleDayEventsColumn = ({
  calendarId,
  dateTime,
  columnIndex,
  columnCount,
  availability,
  minWidthPercent,
}: {
  calendarId: string;
  dateTime: DateTime;
  columnIndex: number;
  columnCount: number;
  availability: Interval | null;
  minWidthPercent: number;
}) => {
  return (
    <>
      <div className="cw-pointer-events-none">
        {availability && (
          <SingleCalendarAvailability
            availability={availability}
            dateTime={dateTime}
            columnIndex={columnIndex}
            columnCount={columnCount}
            minWidth={minWidthPercent}
          />
        )}
        <SingleDayHoriztonalGuideLines
          dateTime={dateTime}
          columnIndex={columnIndex}
          columnCount={columnCount}
          minWidth={minWidthPercent}
        />
        <VerticalGuideLines
          dateTime={dateTime}
          columnIndex={columnIndex}
          columnCount={columnCount}
          minWidth={minWidthPercent}
        />
      </div>
      <SingleColumnOOOEventsUnderlay
        calendarId={calendarId}
        dateTime={dateTime}
        columnIndex={columnIndex}
        columnCount={columnCount}
        minWidth={minWidthPercent}
      />
      <SingleColumnPlannerEvents
        calendarId={calendarId}
        dateTime={dateTime}
        columnIndex={columnIndex}
        columnCount={columnCount}
        minWidth={minWidthPercent}
      />
    </>
  );
};

const VerticalGuideLines = ({
  dateTime,
  columnIndex,
  columnCount,
  minWidth,
}: {
  dateTime: DateTime;
  columnIndex: number;
  columnCount: number;
  minWidth: number;
}) => {
  const positionables = [
    {
      key: dateTime.toISO(),
      interval: Interval.fromDateTimes(dateTime.startOf("day"), dateTime.endOf("day")),
      render: () => (
        <section
          aria-label={`Events on ${dateTime.toLocaleString(DateTime.DATETIME_FULL)}`}
          className="cw-h-full cw-w-full cw-border-r cw-border-solid cw-border-r-subtle"
        ></section>
      ),
    },
  ];

  return (
    <div className="cw-pointer-events-none">
      <CalendarPositioner
        dateTimes={[dateTime]}
        positionables={positionables}
        columnCountOverride={columnCount}
        columnIndexOverride={columnIndex}
        minWidth={minWidth}
      />
    </div>
  );
};

export const SingleDayHoriztonalGuideLines = ({
  columnCount,
  columnIndex,
  dateTime,
  minWidth,
}: {
  columnCount: number;
  columnIndex: number;
  dateTime: DateTime;
  minWidth: number;
}) => {
  const positionables = new Array(25).fill(null).map((_n, i) => {
    return {
      key: `line-${i}-index-${columnIndex}`,
      interval: Interval.fromDateTimes(
        dateTime.startOf("day").plus({ hours: i }),
        dateTime.startOf("day").plus({ hours: i }),
      ),
      render: () => (
        <section
          key={i}
          className="cw-absolute cw-border-b cw-border-solid cw-border-b-subtle cw-right-0 cw-left-0 cw-w-full"
        />
      ),
    };
  });

  return (
    <CalendarPositioner
      positionables={positionables}
      dateTimes={[dateTime]}
      columnCountOverride={columnCount}
      columnIndexOverride={columnIndex}
      minWidth={minWidth}
    />
  );
};
