//////////////////
// IMPORTS
//////////////////

// libraries
import { useQuery } from "@apollo/client";
import React from "react";

import { WebSettingsOrganizationAnalyticsQueryDocument } from "./__generated__/WebSettingsOrganizationAnalytics.generated";

// components
import { AdminPanelOverviewContainer } from "#webapp/src/components/admin-panel/admin-panel-view/admin-panel-overview";
import { WebSettingsContainer } from "../web-settings-container";

// constants
// import { paths } from "@clockwise/client-commons/src/constants/site";

// util
import { page, PageEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";

// material-ui
// import { makeStyles } from "@material-ui/styles";

// ~-~-~-~-~-~-~-
// Web Settings Organization Analytics
// ~-~-~-~-~-~-~-
interface IProps {}

export const WebSettingsOrganizationAnalytics = (_props: IProps) => {
  React.useEffect(() => {
    page(PageEvents.WEB_SETTINGS_ORGANIZATION_ANALYTICS);
  }, []);

  const { loading, data, error } = useQuery(WebSettingsOrganizationAnalyticsQueryDocument);

  if (error) {
    logger.error(`failed to load WebSettingsOrganizationAnalytics: ${error}`);
    return (
      <WebSettingsContainer>
        An error occurred while loading Clockwise:
        <br />
        {error.message}
      </WebSettingsContainer>
    );
  }

  if (!data || loading) {
    return <WebSettingsContainer>Loading…</WebSettingsContainer>;
  }

  const viewer = data.viewer;
  const org = getCurrentOrg(viewer);

  if (!org) {
    return <WebSettingsContainer>Loading…</WebSettingsContainer>;
  }

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  return (
    <WebSettingsContainer>
      <AdminPanelOverviewContainer orgRelayId={org.id} isWebSettings />
    </WebSettingsContainer>
  );
};
