import { Duration } from "luxon";

type DurationObj = {
  days: null | string;
  hours: string;
  minutes: string;
  seconds: string;
};

export function getFormattedDuration(duration: Duration): DurationObj {
  const totalDuration = duration.toFormat("dd:hh:mm:ss");
  const totalDurationSplit = totalDuration.split(":");
  return {
    days: duration.as("day") < 1 ? null : totalDurationSplit[0],
    hours: totalDurationSplit[1],
    minutes: totalDurationSplit[2],
    seconds: totalDurationSplit[3],
  };
}
