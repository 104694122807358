import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { isUserFlagSet, UserFlags } from "@clockwise/client-commons/src/util/flags";
import { isBrave } from "@clockwise/web-commons/src/util/browser.util";
import { epochTimeUserCompletedOnboarding } from "@clockwise/web-commons/src/util/local-storage";
import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import { browserName, CustomView } from "react-device-detect";
import { ExtensionDownloadButton, sendTrackingWebApp } from "../../extension-download-button";
import { GetFlagsDocument } from "./__generated__/GetFlags.generated";

const WebExtensionDownLoadButton = () => {
  const isChrome = browserName === "Chrome" && !isBrave;
  const isFF = browserName === "Firefox";

  const { data, loading } = useQuery(GetFlagsDocument);
  const user = data?.viewer?.user;
  const userId = user?.id || null;
  const flags = getValue(user?.chromeExtensionFlagsErrorable, "Flags")?.flags;
  const userHasInstalledChromeOrFirefoxExtension = isUserFlagSet(
    UserFlags.Onboarding.HasInstalledChromeExtension.name,
    flags,
  );

  let within24HoursFromOnboarding;
  const epochTimeOnboardingCompleted = epochTimeUserCompletedOnboarding.get();

  if (epochTimeOnboardingCompleted === null) {
    within24HoursFromOnboarding = false;
  } else {
    const oneDayAfterOnboarding = DateTime.fromMillis(epochTimeOnboardingCompleted).plus({
      days: 1,
    });
    within24HoursFromOnboarding = DateTime.now() <= oneDayAfterOnboarding;
  }

  return (
    <div>
      {within24HoursFromOnboarding && !userHasInstalledChromeOrFirefoxExtension && !loading && (
        <CustomView condition={isChrome || isFF}>
          <div
            className={classNames(
              "cw-flex cw-items-center cw-justify-evenly",
              "cw-px-2 cw-py-3",
              "cw-rounded-lg cw-border-transparent",
            )}
          >
            <ExtensionDownloadButton
              browserName={isChrome ? "Chrome" : "Firefox"}
              onClick={() => {
                sendTrackingWebApp("CW_WEB_HOME", userId);
              }}
            />
          </div>
        </CustomView>
      )}
    </div>
  );
};

export default WebExtensionDownLoadButton;
