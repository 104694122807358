import { SmartHoldSettingsReadable } from "#webapp/src/hooks/useUserSmartHoldSettings/useUserSmartHoldSettings";
import { SmartHoldType } from "@clockwise/schema/v2";
import { webappDefaultWindowName } from "@clockwise/web-commons/src/constants/route.constants";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import React from "react";
import { InlineAction } from "../atoms/InlineAction";

export const GoToSettings = ({
  label,
  ariaLabel = "",
  onClick,
}: {
  label: string;
  ariaLabel?: string;
  onClick: () => void;
}) => {
  return <InlineAction onClick={onClick} ariaLabel={ariaLabel} label={label} />;
};

export const SmartHoldSettingsBrief = ({
  type,
  allSettings,
}: {
  type: SmartHoldType;
  allSettings: SmartHoldSettingsReadable;
}) => {
  const onClickEditSettings = () => {
    window.open(getUrlForFeature("accountPreferences"), webappDefaultWindowName);
  };

  switch (type) {
    case SmartHoldType.Lunch:
      return <LunchBrief onClick={onClickEditSettings} setting={allSettings.lunchHoldSettings} />;
    case SmartHoldType.MeetingRelief:
      return (
        <MeetingReliefBrief
          onClick={onClickEditSettings}
          setting={allSettings.meetingReliefSettings}
        />
      );
    case SmartHoldType.TravelTime:
      return <TravelTimeBrief onClick={onClickEditSettings} />;
    case SmartHoldType.FocusTime:
      return (
        <FocusTimeBrief onClick={onClickEditSettings} setting={allSettings.focusTimeSettings} />
      );
    default:
      return null;
  }
};

const LunchBrief = ({
  setting,
  onClick,
}: {
  setting: {
    lunchDurationMinMinutes: number;
    lunchDurationIdealMinutes: number;
    minStartTime: string | null;
    maxStartTime: string | null;
  };
  onClick: () => void;
}) => {
  const {
    lunchDurationMinMinutes,
    lunchDurationIdealMinutes,
    minStartTime,
    maxStartTime,
  } = setting;

  return (
    <div className="cw-text-13">
      You asked Clockwise to schedule{" "}
      <span className="cw-font-medium">
        {lunchDurationMinMinutes} - {lunchDurationIdealMinutes} mins for lunch
      </span>{" "}
      between {minStartTime} - {maxStartTime}.{" "}
      <GoToSettings label="Edit settings" onClick={onClick} />
    </div>
  );
};

const MeetingReliefBrief = ({
  setting,
  onClick,
}: {
  setting: {
    consecutiveMeetingDurationHours: number;
    breakDurationMinutes: number;
  };
  onClick: () => void;
}) => {
  const { breakDurationMinutes, consecutiveMeetingDurationHours } = setting;

  return (
    <div className="cw-text-13">
      You asked Clockwise to schedule{" "}
      <span className="cw-font-medium">{breakDurationMinutes} min breaks</span> to prevent{" "}
      {consecutiveMeetingDurationHours} hrs or more of continuous meetings.{" "}
      <div>
        <GoToSettings label="Edit settings" onClick={onClick} />
      </div>
    </div>
  );
};

const TravelTimeBrief = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="cw-text-13">
      Clockwise is holding travel time for all external meetings that have a location set.{" "}
      <span className="cw-font-medium">
        Travel Time is calculated from your working location during your working hours.
      </span>
      <div>
        <GoToSettings label="Edit settings" onClick={onClick} />
      </div>
    </div>
  );
};

const FocusTimeBrief = ({
  setting,
  onClick,
}: {
  setting: {
    maxPerWeekHours: number;
    minPerWeekHours: number;
    isProtected: boolean;
  };
  onClick: () => void;
}) => {
  const { isProtected, minPerWeekHours, maxPerWeekHours } = setting;

  return (
    <div className="cw-text-13">
      You asked Clockwise to schedule up to{" "}
      <span className="cw-font-medium">{maxPerWeekHours} hours of Focus Time per week</span>
      {isProtected &&
        `, and automatically protect Focus Time below a minimum of ${minPerWeekHours} hours.`}
      <div>
        <GoToSettings label="Edit settings" onClick={onClick} />
      </div>
    </div>
  );
};
