import { PositionedItem } from "../types";

/**
 * This function compares two PositionedItems by their renderOrder and top position.
 * It first compares the renderOrder of the items. If they are not equal, it returns the difference.
 * If the renderOrder are equal, it then compares the top position of the items and returns the difference.
 * The function is designed to be used as a comparator in sorting functions.
 *
 * @param {PositionedItem} left - The first item to compare.
 * @param {PositionedItem} right - The second item to compare.
 * @returns {number} A number indicating the order of the items. A negative number if the left item should come first, a positive number if the right item should come first, or 0 if they are equal.
 */
export const byRenderOrderAndTop = (left: PositionedItem, right: PositionedItem) => {
  if (left.position.renderOrder === right.position.renderOrder) {
    return left.position.top - right.position.top;
  }
  return left.position.renderOrder - right.position.renderOrder;
};
