import { SlackButton } from "#webapp/src/components/slack-button";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { cleanUpSlackNonce } from "#webapp/src/util/third-party-oauth.util";
import { Button, ButtonSet } from "@clockwise/design-system";
import { Delete, Warning } from "@clockwise/design-system/icons";
import { CalloutBox } from "@clockwise/web-commons/src/components/callout-box/CalloutBox.tailwind";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import * as React from "react";
import { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { SlackChannel, SlackChannelPicker } from "../../../slack-channel-picker";
import { SlackDailyReminderSelect } from "./SlackDailyReminderSelect";
import {
  RemoveTeamSlackAccountDocument,
  TeamSlackSettingsDocument,
  UpdateTeamSlackSettingsDocument,
  ValidateSlackChannelDocument,
} from "./__generated__/TeamSlackSettings.v2.generated";

type State = {
  slackNotificationEnabled: boolean;
  slackChannel?: SlackChannel;
  slackNotificationTimeSlot: string;
  showSlackDialog: boolean;
  showRemoveSlackDialog: boolean;
  savingSlackSettings: boolean;
  slackChannelError?: "unselected" | "invalid";
};

export const TeamSettingsAdminSlack: React.FC<{ teamId: string }> = ({ teamId }) => {
  const { data, refetch } = useGatewayQuery(TeamSlackSettingsDocument, {
    variables: { teamId },
  });
  const [removeTeamSlackAccount] = useGatewayMutation(RemoveTeamSlackAccountDocument, {
    variables: { teamId },
  });
  const [updateTeamSlackSettings] = useGatewayMutation(UpdateTeamSlackSettingsDocument, {
    variables: { teamId },
  });
  const [validateSlackChannel] = useGatewayMutation(ValidateSlackChannelDocument);

  const slackAccount = data?.team?.slackAccount;
  const slackSettings = data?.team?.slackSettings;

  const initialSlackChannel = useMemo<SlackChannel | undefined>(
    () =>
      slackSettings?.channelId && slackSettings.channelName
        ? {
            channelId: slackSettings.channelId,
            name: slackSettings.channelName,
          }
        : undefined,
    [slackSettings],
  );

  const [state, setState] = React.useState<State>({
    slackNotificationEnabled: !!slackAccount,
    slackChannel: initialSlackChannel,
    slackNotificationTimeSlot: slackSettings?.notificationTime ?? "09:00",
    showSlackDialog: false,
    showRemoveSlackDialog: false,
    savingSlackSettings: false,
    slackChannelError: !initialSlackChannel ? "unselected" : undefined,
  });

  useEffect(() => {
    if (slackSettings) {
      setState((prevState) => ({
        ...prevState,
        slackNotificationEnabled: !!slackAccount,
        slackChannel: initialSlackChannel,
        slackNotificationTimeSlot: slackSettings.notificationTime ?? "09:00",
        slackChannelError: !initialSlackChannel ? "unselected" : undefined,
      }));
    }
  }, [initialSlackChannel, slackAccount, slackSettings]);

  const onRemoveSlackDialogClose = () => {
    setState((prevState) => ({ ...prevState, showRemoveSlackDialog: false }));
  };

  const onSlackSettingsDialogClose = () => {
    setState((prevState) => ({ ...prevState, showSlackDialog: false }));
  };

  const onSlackSettingsDialogConfirm = () => {
    const successCallback = () => {
      toast.success(`Set Slack notification preferences`);
      cleanUpSlackNonce();
      onSlackSettingsDialogClose();
    };

    const failureCallback = () => {
      const msg = `Failed to set Slack notification preferences`;
      toast.error(msg);
      logger.error(msg);
    };

    maybeValidateSlackChannelAndUpdateTeamSettings(successCallback, failureCallback);
  };

  const onSlackAuthPopupClosed = async () => {
    const result = await refetch();

    const slackAccount = result.data?.team?.slackAccount;
    setState((prevState) => ({
      ...prevState,
      slackNotificationEnabled: !!slackAccount,
      showSlackDialog: !!slackAccount,
    }));
    maybeValidateSlackChannelAndUpdateTeamSettings();
  };

  const onRemoveSlackAccount = () => {
    setState((prevState) => ({
      ...prevState,
      slackNotificationEnabled: false,
      slackChannel: undefined,
    }));

    void removeTeamSlackAccount({
      variables: { teamId },
      onCompleted: () => {
        toast.success("Removed Slack account from team.");
        onRemoveSlackDialogClose();
      },
      onError: () => {
        const msg = "Failed to remove Slack account.";
        toast.error(msg);
        logger.error(msg);
      },
    });
    track(TrackingEvents.SLACK.TEAMS_REMOVED_SLACK);
  };

  const onClickSaveSlackSettings = () => {
    const successCallback = () => {
      toast.success(`Set Slack notification preferences`);
    };

    const failureCallback = () => {
      const msg = `Failed to set Slack notification preferences`;
      toast.error(msg);
      logger.error(msg);
    };

    maybeValidateSlackChannelAndUpdateTeamSettings(successCallback, failureCallback);
  };

  const onChangeSlackChannel = (slackChannel: SlackChannel, postOAuthModal?: boolean) => {
    setState((prevState) => ({ ...prevState, slackChannel, slackChannelError: undefined }));

    track(TrackingEvents.SLACK.SLACK_CHANNEL_PICKER_SELECT, {
      channelId: slackChannel.channelId,
      channelName: slackChannel.name,
      postOAuthModal,
    });
  };

  const onChangeSlackDailyReminder = (
    slackNotificationTimeSlot: string,
    postOAuthModal?: boolean,
  ) => {
    setState((prevState) => ({ ...prevState, slackNotificationTimeSlot }));

    track(TrackingEvents.SLACK.TEAMS_CHANGED_DAILY_REMINDER_TIME, { postOAuthModal });
  };

  const isStateSlackChannelSameAsSaved = () => {
    const { slackChannel } = state;
    return (slackSettings && slackSettings.channelId) === (slackChannel && slackChannel.channelId);
  };

  const isStateSlackNotificationTimeslotSameAsSaved = () => {
    const { slackNotificationTimeSlot } = state;
    return (slackSettings && slackSettings.notificationTime) === slackNotificationTimeSlot;
  };

  const maybeValidateSlackChannelAndUpdateTeamSettings = async (
    successCallback?: () => void,
    failureCallback?: () => void,
  ) => {
    const { slackChannel } = state;

    setState((prevState) => ({ ...prevState, savingSlackSettings: true }));

    if (!slackChannel || isStateSlackChannelSameAsSaved()) {
      return updateTeamSettings(successCallback, failureCallback);
    }

    const slackChannelId = slackChannel ? slackChannel.channelId : "";
    const channelName =
      slackChannel && slackChannelId.startsWith("CustomSlackEntry-")
        ? slackChannelId.split("CustomSlackEntry-")[1]
        : slackChannel && slackChannel.name
        ? slackChannel.name
        : "";

    try {
      const result = await validateSlackChannel({ variables: { teamId, channelName } });
      const isValid = result.data?.validateTeamSlackChannel.isValid;
      const channel = result.data?.validateTeamSlackChannel.channel;

      if (isValid && channel) {
        // We need to wait for the state to update before calling updateTeamSettings
        // because the state is used in the updateTeamSettings function to determine
        // if the channel has changed.
        setState((prevState) => {
          const newState = {
            ...prevState,
            slackChannel: {
              channelId: channel.id,
              name: channel.name,
            },
            slackChannelError: undefined,
          };
          updateTeamSettings(successCallback, failureCallback, newState);
          return newState;
        });
        return;
      }
      setState((prevState) => ({ ...prevState, slackChannelError: "invalid" }));
      toast.error("We couldn't find a public Slack channel with this name.");
      setState((prevState) => ({ ...prevState, savingSlackSettings: false }));
    } catch (error) {
      logger.error("Failed to validate Slack channel", error);
      setState((prevState) => ({ ...prevState, savingSlackSettings: false }));
      failureCallback?.();
    }
  };

  const updateTeamSettings = (
    successCallback?: () => void,
    failureCallback?: () => void,
    updatedState?: State,
  ) => {
    const { slackNotificationEnabled, slackChannel, slackNotificationTimeSlot } =
      updatedState ?? state;

    setState((prevState) => ({ ...prevState, savingSlackSettings: true }));
    const slackChannelId = slackChannel ? slackChannel.channelId : undefined;
    const slackChannelName = slackChannel ? slackChannel.name : undefined;

    const onSuccess = () => {
      setState((prevState) => ({ ...prevState, savingSlackSettings: false }));
      if (successCallback) {
        successCallback();
      }
    };

    const onFailure = () => {
      setState((prevState) => ({ ...prevState, savingSlackSettings: false }));
      if (failureCallback) {
        failureCallback();
      }
    };

    void updateTeamSlackSettings({
      variables: {
        teamId,
        notificationEnabled: slackNotificationEnabled,
        channelId: slackChannelId,
        channelName: slackChannelName,
        notificationTime: slackNotificationTimeSlot,
      },
      onCompleted: onSuccess,
      onError: onFailure,
    });
  };

  const maybeRenderSlackChannelError = (postOAuthModal?: boolean) => {
    const { slackChannelError, slackChannel } = state;

    if (!slackChannelError) {
      return null;
    }

    if (!postOAuthModal && slackChannelError === "unselected") {
      return null;
    }

    const getErrorMessage = () => {
      if (slackChannelError === "unselected") {
        return "Your team won't receive a daily summary in Slack until a Slack channel is chosen below.";
      }
      if (slackChannelError === "invalid") {
        const channelName = slackChannel ? slackChannel.name : "";
        const teamName = slackAccount ? slackAccount.displayName : "your team";
        return `Oops! we couldn't find channel "${channelName}" in the ${teamName} workspace. Please check if you have the right channel name.`;
      }
      return null;
    };

    return (
      <div className="cw-mb-4">
        <CalloutBox
          sentiment="warning"
          icon={<Warning className="cw-text-warning" />}
          body={<span className="cw-body-base cw-text-warning">{getErrorMessage()}</span>}
        />
      </div>
    );
  };

  const maybeRenderRemoveSlackDialog = () => {
    const { showRemoveSlackDialog } = state;

    return (
      <Dialog open={showRemoveSlackDialog} onClose={onRemoveSlackDialogClose}>
        <DialogTitle>Remove Slack for everyone?</DialogTitle>
        <DialogContent className="cw-body-base">
          Are you sure you want to remove Slack for everyone on this team? This action cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <ButtonSet>
            <Button variant="outlined" onClick={onRemoveSlackDialogClose}>
              Cancel
            </Button>
            <Button variant="outlined" sentiment="destructive" onClick={onRemoveSlackAccount}>
              Remove Slack
            </Button>
          </ButtonSet>
        </DialogActions>
      </Dialog>
    );
  };

  const renderSlackSettings = (postOAuthModal?: boolean) => {
    const {
      slackNotificationEnabled,
      slackChannel,
      slackNotificationTimeSlot,
      savingSlackSettings,
      slackChannelError,
    } = state;

    return (
      <>
        <div className="cw-grid cw-grid-cols-2 cw-gap-4">
          <SlackChannelPicker
            teamId={teamId}
            onChange={(channel) => onChangeSlackChannel(channel, postOAuthModal)}
            value={slackChannel}
            disabled={!slackNotificationEnabled}
            error={!!slackChannelError}
          />
          <SlackDailyReminderSelect
            selectedTimeSlot={slackNotificationTimeSlot}
            onChange={(timeSlot) => onChangeSlackDailyReminder(timeSlot, postOAuthModal)}
          />
        </div>
        {postOAuthModal && (
          <div className="cw-flex cw-items-center cw-justify-between cw-gap-2 cw-mt-4">
            <Button
              onClick={onClickSaveSlackSettings}
              sentiment="positive"
              disabled={
                (isStateSlackNotificationTimeslotSameAsSaved() &&
                  isStateSlackChannelSameAsSaved()) ||
                savingSlackSettings
              }
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                setState((prevState) => ({ ...prevState, showRemoveSlackDialog: true }))
              }
              startIcon={Delete}
              disabled={savingSlackSettings}
            >
              Remove Slack account
            </Button>
          </div>
        )}
      </>
    );
  };

  const maybeRenderSlackDialog = () => {
    const { showSlackDialog, slackChannel, slackNotificationTimeSlot } = state;

    return (
      <Dialog open={showSlackDialog} onClose={onSlackSettingsDialogClose} maxWidth="md">
        <DialogTitle>Next up, set up the integration</DialogTitle>
        <DialogContent>
          <div className="cw-body-base">
            You have successfully connected Clockwise to Slack! Set up the integration to start
            receiving a daily summary of events on your team availability calendar.
          </div>
          {maybeRenderSlackChannelError()}
          <div className="cw-mt-4">{renderSlackSettings()}</div>
        </DialogContent>
        <DialogActions style={{ padding: "12px 24px" }}>
          <ButtonSet>
            <Button variant="outlined" onClick={onSlackSettingsDialogClose}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              sentiment="positive"
              onClick={onSlackSettingsDialogConfirm}
              disabled={!slackChannel || !slackNotificationTimeSlot}
            >
              Confirm
            </Button>
          </ButtonSet>
        </DialogActions>
      </Dialog>
    );
  };

  const { slackNotificationEnabled, showSlackDialog } = state;
  const manageTeamCalendar = data?.team?.manageTeamCalendar ?? false;

  if (!manageTeamCalendar) {
    return null;
  }

  return (
    <>
      <div className="cw-mt-7 cw-space-y-2">
        <div className="cw-heading-xl">Connect team availability calendar with Slack?</div>
        <div className="cw-body-base">
          Clockwise can send a daily summary of events on the team availability calendar to your
          selected Slack channel.
        </div>
        <div>
          {showSlackDialog
            ? "Authenticating..."
            : !slackNotificationEnabled && (
                <SlackButton
                  onAuthPopupClosed={onSlackAuthPopupClosed}
                  teamId={teamId}
                  isTeamsSlack
                />
              )}
          {slackNotificationEnabled && !showSlackDialog && (
            <div>
              {maybeRenderSlackChannelError(true)}
              {renderSlackSettings(true)}
            </div>
          )}
        </div>
      </div>
      {maybeRenderSlackDialog()}
      {maybeRenderRemoveSlackDialog()}
    </>
  );
};
