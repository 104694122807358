import { Select, SelectOption, Switch } from "@clockwise/design-system";
import { EcosystemEnum } from "@clockwise/schema";
import { EcosystemContext } from "@clockwise/web-commons/src/util/ecosystem";
import cx from "classnames";
import React, { useContext } from "react";
import { SMART_HOLD_LABEL } from "./SmartHoldSync.util";
import { SmartHoldType } from "./types";

type Props = {
  on: boolean;
  type: SmartHoldType;
  recommended?: boolean;
  disabled?: boolean;
  reminders?: boolean | null;
  available?: boolean;
  onChange: (enabled: boolean) => void;
  onChangeReminders: (enabled: "on" | "off") => void;
  onChangeAvailability: (availability: "Opaque" | "Transparent") => void;
};

export function SmartHoldToggle({
  on,
  type,
  recommended = false,
  disabled = false,
  reminders = false,
  available,
  onChange,
  onChangeReminders,
  onChangeAvailability,
}: Props) {
  const ecosystem = useContext(EcosystemContext);

  const label = SMART_HOLD_LABEL[type];
  return (
    <div>
      <div
        className={cx("cw-flex cw-items-center cw-mb-4", {
          disabled: "cw-opacity-40 cw-pointer-events-none",
        })}
      >
        <div className="cw-mr-2">
          <Switch
            checked={on && !disabled}
            disabled={disabled}
            onChange={onChange}
            label={`Schedule ${label} events on my calendar ${recommended ? " (recommended)" : ""}`}
          />
        </div>
      </div>
      {type === "Lunch" && (
        <div className="cw-font-body cw-font-normal cw-text-sm cw-text-subtle cw-mb-3">
          Prevent others from scheduling over your lunch break by allowing Clockwise to block it on
          your shared calendar. Clockwise won’t schedule over lunch meetings and you can remove
          individual events if they are not needed.
        </div>
      )}
      <div className="cw-flex cw-gap-8">
        {type === "FocusTime" && (
          <div className={cx({ "cw-cursor-not-allowed": !on })}>
            <div className={cx("cw-w-[310px]", { "cw-opacity-40 cw-pointer-events-none": !on })}>
              <Select
                label="Availability during Focus Time events"
                value={available ? "Transparent" : "Opaque"}
                onChange={onChangeAvailability}
                disabled={!on}
                name="Availability during Focus Time events"
                fullWidth
                size="large"
              >
                <SelectOption value="Opaque">Busy, unavailable to meet</SelectOption>
                <SelectOption value="Transparent">Free, others can schedule with me</SelectOption>
              </Select>
            </div>
          </div>
        )}
        <div className={cx({ "cw-cursor-not-allowed": !on })}>
          <div className={cx("cw-w-[310px]", { "cw-opacity-40 cw-pointer-events-none": !on })}>
            <Select
              value={reminders ? "on" : "off"}
              onChange={onChangeReminders}
              disabled={!on || disabled}
              label={`Event notifications for ${label}`}
              name={`Event notifications for ${label}`}
              fullWidth
              size="large"
            >
              <SelectOption value="on">
                Use {ecosystem === EcosystemEnum.Microsoft ? "Outlook" : "Google Calendar"} defaults
              </SelectOption>
              <SelectOption value="off">Off</SelectOption>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}
