//////////////////
// IMPORTS
//////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";
// libraries
import * as React from "react";
// components
import { CalloutBox } from "#webapp/src/components/callout-box";
import { Switch } from "@clockwise/design-system";
// util
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import {
  maybeGetTeamSettingsFromTeam,
  maybeGetTeamUserSettingsFromTeam,
} from "#webapp/src/util/team-settings.util";
import { maybeGetPrimaryTeamFromOrg } from "#webapp/src/util/team.util";
// state
import { saveTeamUserSettings } from "#webapp/src/mutations";
import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
// material-ui
import { Typography } from "@clockwise/design-system";
import { makeStyles } from "@material-ui/styles";

import { colors, greys } from "@clockwise/web-commons/src/styles/color.styles";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import classNames from "classnames";
import toast from "react-hot-toast";

// ~-~-~-~-~-~-~-
// Team Availability Calendar Settings
// ~-~-~-~-~-~-~-
interface IProps {
  org: ISchema.IOrg;
  team: ISchema.ITeam;
  canTeamAvailabilityBeEnabled: boolean;
}

const useStyles = makeStyles({
  h3: {
    display: "flex",
    alignItems: "center",
  },
  toggleText: {
    flexGrow: 1,
    color: greys.darkestGrey,
    fontSize: 16,
    marginLeft: 10,
  },
  flexLabelContainer: {
    display: "flex",
    alignItems: "center",
  },
  labelPrimary: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  labelSecondary: {
    fontSize: 14,
    color: greys.slate,
  },
  disabledCursor: {
    cursor: "not-allowed",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.4,
    WebkitFilter: "grayscale(45%)",
    filter: "grayscale(45%)",
  },
  primaryBadge: {
    textTransform: "uppercase",
    color: greys.white,
    background: colors.blueCool.standard,
    borderRadius: 3,
    fontSize: 12,
    fontWeight: "bold",
    marginLeft: 10,
    padding: 5,
    paddingTop: 7,
    width: "fit-content",
  },
});

export const AvailabilityCalendarSetting = (props: IProps) => {
  const classes = useStyles();
  const ecosystem = useEcosystem();

  const teamSettings = maybeGetTeamSettingsFromTeam(props.team);
  const teamUserSettings = maybeGetTeamUserSettingsFromTeam(props.team);

  const [oooCalendarAvailable, _setOooCalendarAvailable] = React.useState(
    teamSettings ? teamSettings.manageTeamCalendar : undefined,
  );
  const [syncOOOCalendar, setSyncOOOCalendar] = React.useState(
    teamUserSettings ? teamUserSettings.useTeamCalendar : undefined,
  );

  // ~-~-~-~-~-~-~-
  // Handlers
  // ~-~-~-~-~-~-~-
  const handleToggle = () => {
    const newSyncValue = !syncOOOCalendar;

    setSyncOOOCalendar(newSyncValue);

    saveTeamUserSettings(
      getCurrentEnvironment(),
      { orgRelayId: props.org.id, teamId: props.team.teamId, useTeamCalendar: newSyncValue },
      () => {
        // if (newSyncValue) {
        //   PostMessageManager.toggleCalendar(team.teamCalendarId, true);
        // }

        toast.success(`${newSyncValue ? "Enabled" : "Disabled"} your team's calendar!`);
      },
      () => {
        const msg = `Failed to ${newSyncValue ? "enable" : "disable"} your team's calendar!`;
        toast.error(msg);
        logger.error(msg);
      },
    );

    track(TrackingEvents.TEAMS.TEAM_SETTINGS_USER_TOGGLED_OOO_CALENDAR, {
      useTeamCalendar: newSyncValue,
    });
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  const { org, team } = props;

  const maybePrimaryTeam = maybeGetPrimaryTeamFromOrg(org.primaryTeam);
  const isPrimaryTeam = maybePrimaryTeam && maybePrimaryTeam.teamId === team.teamId;

  return (
    <React.Fragment>
      <Typography variant="h3" className={`cw-mb-4 ${classes.h3}`}>
        {props.team.teamName}{" "}
        {isPrimaryTeam && <div className={classes.primaryBadge}>Primary team</div>}
      </Typography>
      {!oooCalendarAvailable && (
        <CalloutBox className="cw-mb-11" warning>
          You need to enable the team calendar first before changing this setting
        </CalloutBox>
      )}
      <Switch
        checked={!!syncOOOCalendar && props.canTeamAvailabilityBeEnabled}
        onChange={handleToggle}
        disabled={!oooCalendarAvailable || !props.canTeamAvailabilityBeEnabled}
        label={
          <>
            <div>Subscribe to the “Clockwise: {team.teamName}” calendar</div>
            <div
              className={classNames("cw-body-base", {
                "cw-text-slate-standard": oooCalendarAvailable,
                "cw-text-grey-darker": !oooCalendarAvailable,
              })}
            >
              When enabled, visible in your calendars list in{" "}
              {ecosystem === "Google" ? "Google Calendar" : "Outlook"}.
            </div>
          </>
        }
      />
    </React.Fragment>
  );
};
