import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteUserLevelZoomAccountMutationMutationVariables = Types.Exact<{
  orgId: Types.Scalars['String'];
}>;


export type DeleteUserLevelZoomAccountMutationMutation = { __typename: 'Mutation', deleteUserLevelZoomAccount: { __typename: 'DeleteUserLevelZoomAccountMutationPayload', clientMutationId: string | null } | null };


export const DeleteUserLevelZoomAccountMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteUserLevelZoomAccountMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteUserLevelZoomAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"orgRelayId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}}]}}]}}]} as unknown as DocumentNode<DeleteUserLevelZoomAccountMutationMutation, DeleteUserLevelZoomAccountMutationMutationVariables>;