import { EcosystemEnum } from "@clockwise/schema";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { first } from "lodash";
import pluralize from "pluralize";
import React, { useMemo } from "react";
import { useHandleEditsToAttendees as useHandleEditsToAttendeesForMultiCalendar } from "../../../chat/ai-chat/hooks/multicalendar/event-card-edit/useUpdateEditsToAttndees";
import { EventElementsWrap } from "../../atoms/EventElementsWrap";
import { useEventAttendees } from "../../hooks/EventAttendeesContext";
import { EventCardAttendee } from "../../types";
import { haveAllAttendeesButYouDeclined } from "../../utils/haveAllAttendeesButYouDeclined";
import { ECAttendeeList } from "./ECAttendeeList";
import { ECAttendeeSelect } from "./ECAttendeeSelect";
import { MultiCalendarLayers } from "./MultiCalendarLayers";
import { OmittedAttendeesMessage } from "./OmittedAttendeesMessage";
import { RescheduleWithAIButton } from "./RescheduleWIthAIMessage";

export const getListOfNonRemovableAttendeeIds = (
  canInviteOthers: boolean,
  canModify: boolean,
  originalAttendees: EventCardAttendee[],
  ecosystem: EcosystemEnum,
) => {
  const userCanOnlyInvite = canInviteOthers && !canModify;
  const attendeeIdsThatCanNotBeEdited = new Set<string>();
  for (const attendee of originalAttendees) {
    if ((ecosystem === EcosystemEnum.Microsoft && attendee.isOrganizer) || userCanOnlyInvite) {
      attendeeIdsThatCanNotBeEdited.add(attendee.primaryCalendar);
    }
  }
  return attendeeIdsThatCanNotBeEdited;
};

export type Props = {
  canInviteOthers: boolean;
  canModify: boolean;
  attendeesOmitted?: boolean;
  diffId?: string;
  externalEventId?: string;
  calendarId?: string;
  onReschedule?: () => void;
};
export const ECAttendeeWrapper = ({
  canInviteOthers,
  canModify,
  attendeesOmitted,
  diffId,
  externalEventId,
  calendarId,
  onReschedule,
}: Props) => {
  const ecosystem = useEcosystem();
  const [handleChangesToAttendeesForMultiCalendar] = useHandleEditsToAttendeesForMultiCalendar({
    eventId: externalEventId,
    diffId,
  });

  const {
    attendees,
    initialAttendees,
    setAttendees: onEditAttendees,
    attendeesChanged,
  } = useEventAttendees();
  const nonEditableAttendeeIds = getListOfNonRemovableAttendeeIds(
    canInviteOthers,
    canModify,
    initialAttendees,
    ecosystem,
  );
  const showRescheduleWithAI =
    onReschedule && attendees && haveAllAttendeesButYouDeclined(attendees, !!attendeesOmitted);

  const onlyOrganizerYouAttendee = useMemo(
    () =>
      attendees.length === 1 &&
      first(attendees)?.isYou &&
      first(attendees)?.isOrganizer &&
      !attendeesOmitted,
    [attendees, attendeesOmitted],
  );

  const removeAttendee = (primaryCalendar: string) => {
    if (!nonEditableAttendeeIds.has(primaryCalendar)) {
      const listDupe = [...attendees].filter((p) => p.primaryCalendar !== primaryCalendar);
      onEditAttendees(listDupe);

      const newAttendeeCalendarIds = listDupe.map((attendee) => attendee.primaryCalendar);
      const currentAttendeeCalendarIds = attendees.map((attendee) => attendee.primaryCalendar);

      handleChangesToAttendeesForMultiCalendar({
        newAttendeeCalendarIds,
        currentAttendeeCalendarIds,
      });
    }
  };

  const addAttendee = (attendee: EventCardAttendee) => {
    const listDupe = [...attendees, attendee];
    onEditAttendees(listDupe);

    const newAttendeeCalendarIds = listDupe.map((attendee) => attendee.primaryCalendar);
    const currentAttendeeCalendarIds = attendees.map((attendee) => attendee.primaryCalendar);

    handleChangesToAttendeesForMultiCalendar({
      newAttendeeCalendarIds,
      currentAttendeeCalendarIds,
    });
  };

  const changeOptionalityOfAttendee = (attendee: EventCardAttendee, isOptional: boolean) => {
    if (!nonEditableAttendeeIds.has(attendee.primaryCalendar)) {
      const indexOfAttendeeToUpdate = attendees.findIndex(
        (p) => p.primaryCalendar === attendee.primaryCalendar,
      );
      const newAttendee = {
        ...attendee,
        isOptional,
      };

      const updatedAttendeeList = [
        ...attendees.slice(0, indexOfAttendeeToUpdate),
        { ...newAttendee },
        ...attendees.slice(indexOfAttendeeToUpdate + 1),
      ];

      onEditAttendees(updatedAttendeeList);
    }
  };

  return (
    <EventElementsWrap name="attendee" showChanges={attendeesChanged}>
      <div className="cw-w-full cw-flex cw-flex-col cw-items-start cw-gap-2">
        {Boolean(attendees.length) && (
          <div className="cw-flex cw-items-center cw-justify-between cw-w-full">
            <div className="cw-text-muted cw-text-xs cw-font-medium">
              {`${attendees.length} ${pluralize("attendee", attendees.length)}`}
            </div>
            <MultiCalendarLayers
              diffId={diffId}
              externalEventId={externalEventId}
              calendarId={calendarId}
            />
          </div>
        )}
        {canInviteOthers && (
          <ECAttendeeSelect
            disabled={!canInviteOthers}
            currentAttendees={attendees}
            onAddAttendee={addAttendee}
          />
        )}
        {showRescheduleWithAI && <RescheduleWithAIButton onReschedule={onReschedule} />}
        {!onlyOrganizerYouAttendee && (
          <ECAttendeeList
            attendees={attendees}
            nonEditableAttendeeIds={nonEditableAttendeeIds}
            readOnly={!canInviteOthers}
            removeAttendee={removeAttendee}
            changeOptionalityOfAttendee={changeOptionalityOfAttendee}
          />
        )}
        {attendeesOmitted && <OmittedAttendeesMessage />}
      </div>
    </EventElementsWrap>
  );
};
