//////////////////
// IMPORTS
//////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";
// libraries
import * as React from "react";
// components
import { CalloutBox } from "#webapp/src/components/callout-box";
import { Switch } from "@clockwise/design-system";

// util
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import {
  getNoMeetingDayOfWeekFromTeam,
  maybeGetTeamSettingsFromTeam,
  maybeGetTeamUserSettingsFromTeam,
} from "#webapp/src/util/team-settings.util";
import { maybeGetPrimaryTeamFromOrg } from "#webapp/src/util/team.util";
// state
import { saveTeamUserSettings } from "#webapp/src/mutations";
import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
// material-ui
import { Typography } from "@clockwise/design-system";
import { makeStyles } from "@material-ui/styles";

import { colors, greys } from "@clockwise/web-commons/src/styles/color.styles";
import toast from "react-hot-toast";

// ~-~-~-~-~-~-~-
// Team Availability Calendar Settings
// ~-~-~-~-~-~-~-
interface IProps {
  org: ISchema.IOrg;
  team: ISchema.ITeam;
  canNMDBeEnabled: boolean;
}

const useStyles = makeStyles({
  h3: {
    display: "flex",
    alignItems: "center",
  },
  toggleText: {
    flexGrow: 1,
    color: greys.darkestGrey,
    fontSize: 16,
    marginLeft: 10,
  },
  flexLabelContainer: {
    display: "flex",
    alignItems: "center",
  },
  labelPrimary: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  disabledCursor: {
    cursor: "not-allowed",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.4,
    WebkitFilter: "grayscale(45%)",
    filter: "grayscale(45%)",
  },
  primaryBadge: {
    textTransform: "uppercase",
    color: greys.white,
    background: colors.blueCool.standard,
    borderRadius: 3,
    fontSize: 12,
    fontWeight: "bold",
    marginLeft: 10,
    padding: 5,
    paddingTop: 7,
    width: "fit-content",
  },
});

export const NoMeetingDaySetting = (props: IProps) => {
  const classes = useStyles();

  const teamSettings = maybeGetTeamSettingsFromTeam(props.team);
  const teamUserSettings = maybeGetTeamUserSettingsFromTeam(props.team);

  const [noMeetingDayAvailable, _setNoMeetingDayAvailable] = React.useState(
    teamSettings ? teamSettings.useNoMeetingDay : false,
  );
  const [noMeetingDayEnabled, setNoMeetingDayEnabled] = React.useState(
    teamUserSettings ? teamUserSettings.useNoMeetingDay : false,
  );

  // ~-~-~-~-~-~-~-
  // Handlers
  // ~-~-~-~-~-~-~-

  const shouldSwitchBeDisabled = () => {
    return !noMeetingDayAvailable || !isPrimaryTeam || !props.canNMDBeEnabled;
  };

  const handleNoMeetingDayEnabledDayToggle = () => {
    const newSyncValue = !noMeetingDayEnabled;

    setNoMeetingDayEnabled(newSyncValue);
    saveTeamUserSettings(
      getCurrentEnvironment(),
      {
        orgRelayId: props.org.id,
        teamId: props.team.teamId,
        useNoMeetingDay: newSyncValue,
      },
      () => {
        toast.success(`Updated your no-meeting day preferences!`);
      },
      () => {
        const msg = `Failed to update your no-meeting day preferences calendar!`;
        toast.error(msg);
        logger.error(msg);
      },
    );

    track(TrackingEvents.TEAMS.TEAMS_NO_MEETING_DAY_TOGGLED_FOR_USER, {
      noMeetingDayEnabled: newSyncValue,
    });
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  const { org, team } = props;

  const noMeetingDayOfWeek = getNoMeetingDayOfWeekFromTeam(team);
  const maybePrimaryTeam = maybeGetPrimaryTeamFromOrg(org.primaryTeam);
  const isPrimaryTeam = maybePrimaryTeam && maybePrimaryTeam.teamId === team.teamId;

  return (
    <React.Fragment>
      <Typography variant="h3" className={`cw-mb-4 ${classes.h3}`}>
        {props.team.teamName}{" "}
        {isPrimaryTeam && <div className={classes.primaryBadge}>Primary team</div>}
      </Typography>
      {(!isPrimaryTeam && (
        <CalloutBox className="cw-mb-11" warning>
          You can only apply your primary team's no-meeting day
        </CalloutBox>
      )) ||
        (!noMeetingDayAvailable && (
          <CalloutBox className="cw-mb-11" warning>
            You need to enable no-meeting day for your team before you can use this feature
          </CalloutBox>
        ))}
      <Switch
        checked={noMeetingDayEnabled && props.canNMDBeEnabled}
        onChange={handleNoMeetingDayEnabledDayToggle}
        disabled={shouldSwitchBeDisabled()}
        label={`Keep ${noMeetingDayOfWeek}s meeting-free for me when possible`}
      />
    </React.Fragment>
  );
};
