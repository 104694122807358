import { Button } from "@clockwise/design-system";
import React from "react";
import { Card } from "./Card";

export const TrialExpired = ({
  onViewPlans,
  showJoinExistingPlan,
  onGoToBilling,
}: {
  onViewPlans: () => void;
  showJoinExistingPlan?: boolean;
  onGoToBilling: () => void;
}) => {
  const description = "Your Teams trial has expired. Please upgrade to keep this flexible event.";

  const onClickCTA = () => {
    if (showJoinExistingPlan) {
      onGoToBilling();
    } else {
      onViewPlans();
    }
  };

  return (
    <Card
      title="Teams trial expired"
      warning
      action={
        <Button size="small" variant="outlined" onClick={onClickCTA}>
          {showJoinExistingPlan ? "Join a plan" : "Upgrade"}
        </Button>
      }
    >
      {description}
    </Card>
  );
};
