import { IOrg } from "#webapp/src/__schema__";
import { ZoomPersonalSettings } from "#webapp/src/components/zoom-personal-settings";
import { useQuery } from "@apollo/client";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { logger } from "../../util/logger.util";
import { getCurrentOrg } from "../../util/org.util";
import { ZoomIntegrationQueryDocument } from "./graphql/__generated__/ZoomIntegration.generated";

const ZoomIntegration = () => {
  const { loading, data, error, refetch } = useQuery(ZoomIntegrationQueryDocument);

  if (error) {
    logger.error(error?.message);
    return <ZoomIntegrationError />;
  }

  if (!data || loading) {
    return <ZoomIntegrationSkeleton />;
  }

  const viewer = data.viewer;
  const org = getCurrentOrg(viewer) as IOrg;

  if (!org) {
    logger.error("Failed to fetch org.");
    return <ZoomIntegrationError />;
  }

  return <ZoomPersonalSettings org={org} refetch={refetch} hideDescription />;
};

const ZoomIntegrationError = () => (
  <div className="cw-text-warning">Unable to load Zoom settings.</div>
);

const ZoomIntegrationSkeleton = () => <Skeleton variant="rect" width="160px" height="45px" />;

export default ZoomIntegration;
