import React from "react";
import { APBillingGroup } from "../apBillingGroups.util";
import { OtherBGRow } from "./OtherBGRow";

export type APOtherBGTableProps = {
  billingGroups: APBillingGroup[];
  isUserInNoBGs: boolean;
  orgId: string;
  orgDomain?: string;
  onUserAddSelect: (bgId: string, canUserAddSelf: boolean) => void;
};

export const APOtherBGTable = ({
  billingGroups,
  isUserInNoBGs,
  orgId,
  orgDomain,
  onUserAddSelect,
}: APOtherBGTableProps) => {
  const billingGroupList = billingGroups.map((bg) => {
    return (
      <OtherBGRow
        orgId={orgId}
        key={bg.id}
        bg={bg}
        isUserInNoBGs={isUserInNoBGs}
        orgDomain={orgDomain}
        onUserAddSelect={onUserAddSelect}
      />
    );
  });

  return (
    <>
      <div className="cw-pl-5 cw-grid cw-grid-cols-12 cw-w-full cw-font-semibold cw-bg-neutral-inset cw-body-base cw-py-4 cw-border-b cw-border-solid cw-border-neutral">
        <div className={isUserInNoBGs ? "cw-col-span-3" : "cw-col-span-5"}>Subscription</div>
        <div className="cw-col-span-2">Plan tier</div>
        <div className="cw-col-span-4">Admin</div>
      </div>
      {billingGroupList}
    </>
  );
};
