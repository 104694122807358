import { ChevronLeft, ContentCopy } from "@clockwise/icons";
import classNames from "classnames";
import React, { memo } from "react";

const ACTION_TYPES = {
  GO_BACK: {
    Icon: ChevronLeft,
    placement: "left",
    visible: "always",
    label: "Go Back",
  },
  COPY_EMAIL: {
    Icon: ContentCopy,
    placement: "right",
    visible: "hover",
    label: "Copy Email Address",
  },
  NONE: {
    Icon: false,
    placement: false,
    visible: false,
    label: false,
  },
};

export const Header = memo(function Header({
  title,
  onAction,
  actionType = "NONE",
}: {
  title: string;
  onAction?: () => void;
  /**
   * Indicates type of action the header will take. Controls the icons and label in the header.
   * Note that this doesn't actually affect what happens on click; the `onAction` implementation
   * should match the intent conveyed using `actionType`.
   * @default "NONE"
   */
  actionType?: keyof typeof ACTION_TYPES;
}) {
  const {
    Icon,
    placement: iconPlacement,
    visible: iconVisibility,
    label: iconLabel,
  } = ACTION_TYPES[actionType];
  const hasActionIcon = typeof onAction === "function" && Icon;

  return (
    <button
      cw-id="calendar-popover-header"
      onClick={onAction}
      className="cw-group cw-text-muted cw-w-full cw-px-3 cw-py-2 cw-cursor-pointer cw-bg-neutral cw-flex cw-gap-2 cw-items-center cw-border-t-0 cw-border-x-0 cw-border-b cw-border-solid cw-border-muted cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis"
    >
      <div className="cw-flex cw-items-center cw-justify-between cw-gap-[3px] cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis cw-w-full">
        {hasActionIcon && iconPlacement === "left" && (
          <Icon
            className={classNames(
              "cw-text-muted cw-w-[14px] cw-h-[14px]",
              iconVisibility === "hover" && "cw-hidden group-hover:cw-block",
            )}
          />
        )}

        <span className="cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis">{title}</span>
        {hasActionIcon && <span className="cw-sr-only">{`- ${iconLabel}`}</span>}

        {hasActionIcon && iconPlacement === "right" && (
          <Icon
            className={classNames(
              "cw-text-muted cw-w-[14px] cw-h-[14px]",
              iconVisibility === "hover" && "cw-hidden group-hover:cw-block",
            )}
          />
        )}
      </div>
    </button>
  );
});
