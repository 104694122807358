import React from "react";

const leftHrClass =
  "cw-grow cw-border-0 cw-h-[1px] cw-bg-gradient-to-r cw-from-[#dbdbdb] cw-to-[#757575]";
const rightHrClass =
  "cw-grow cw-border-0 cw-h-[1px] cw-bg-gradient-to-r cw-from-[#757575] cw-to-[#dbdbdb]";

export const WebSettingsDivider = ({ title }: { title: string }) => {
  if (!title) return <hr />;

  return (
    <div className="cw-flex cw-justify-stretch cw-items-center cw-my-20">
      <hr className={leftHrClass} />
      <div className="cw-body-lg cw-font-medium cw-text-subtle cw-uppercase cw-mx-10">{title}</div>
      <hr className={rightHrClass} />
    </div>
  );
};
