import { OptionWithIndex } from "./SchedulingPills";
import { parseSchedulingOptionsGQLByType } from "./parseSchedulingOptionsByType";

export const getClickableSelectedOptions = (options: OptionWithIndex[], selectedIndex: number) => {
  const { initialOptions, customOption, additionalOptions } = parseSchedulingOptionsGQLByType(
    options,
  );

  const additionalSelectedOption = additionalOptions.find(({ index }) => index === selectedIndex);
  const clickableSchedulingOptions = [...initialOptions];

  customOption && clickableSchedulingOptions.push(customOption);
  additionalSelectedOption && clickableSchedulingOptions.push(additionalSelectedOption);

  return clickableSchedulingOptions;
};
