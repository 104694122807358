import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type OnboardingSaveUserSurveyResponseMutationMutationVariables = Types.Exact<{
  input: Types.SaveUserSurveyResponseWrapperMutationInput;
}>;


export type OnboardingSaveUserSurveyResponseMutationMutation = { __typename: 'Mutation', userSurveyResponseWrapperMutations: { __typename: 'SaveUserSurveyResponseWrapperMutationPayload', userSurveyResponseWrapper: { __typename: 'UserSurveyResponseWrapper', id: string, userSurveyResponses: Array<{ __typename: 'UserSurveyResponse', questionType: Types.QuestionType | null, responseType: Types.ResponseType | null, questionText: string | null, responseText: string | null, freeformResponse: string | null }> } } | null };


export const OnboardingSaveUserSurveyResponseMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"OnboardingSaveUserSurveyResponseMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveUserSurveyResponseWrapperMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userSurveyResponseWrapperMutations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userSurveyResponseWrapper"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userSurveyResponses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"questionType"}},{"kind":"Field","name":{"kind":"Name","value":"responseType"}},{"kind":"Field","name":{"kind":"Name","value":"questionText"}},{"kind":"Field","name":{"kind":"Name","value":"responseText"}},{"kind":"Field","name":{"kind":"Name","value":"freeformResponse"}}]}}]}}]}}]}}]} as unknown as DocumentNode<OnboardingSaveUserSurveyResponseMutationMutation, OnboardingSaveUserSurveyResponseMutationMutationVariables>;