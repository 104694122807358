import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { Select, SelectOption } from "@clockwise/design-system";
import { SaveUserSurveyResponseWrapperMutationInput } from "@clockwise/schema";
import React, { useEffect } from "react";
import { Header, SlideLayout, Text } from "../../shared-components";
import {
  CURRENT_POSITION_QUESTION,
  CURRENT_POSITION_RESPONSES,
  TIME_MANAGEMENT_GOAL_QUESTION,
  TIME_MANAGEMENT_GOAL_RESPONSES,
  TYPE_OF_WORK_QUESTION,
  TYPE_OF_WORK_RESPONSES,
} from "./GetToKnowYouStep.util";
import { useUserSurvey } from "./useUserSurvey";

export const GetToKnowYouSlide = ({
  orgId,
  onChange,
}: {
  orgId: string;
  onChange: (input: SaveUserSurveyResponseWrapperMutationInput, isButtonDisabled: boolean) => void;
}) => {
  const {
    currentPosition,
    teamType,
    onCurrentPositionChange,
    onTeamTypeChange,
    timeManagementGoal,
    onTimeManagementGoalChange,
  } = useUserSurvey(orgId);

  const [isOnOnboardingScheduleQuestionFlag] = useFeatureFlag("onboarding-schedule-question");

  useEffect(() => {
    const saveUserReponsesInput = {
      userSurveyResponseWrapper: {
        id: orgId,
        userSurveyResponses: [teamType, currentPosition, timeManagementGoal],
      },
    } as SaveUserSurveyResponseWrapperMutationInput;

    const isButtonDisabled = () => {
      return isOnOnboardingScheduleQuestionFlag
        ? teamType.responseType === null || currentPosition.responseType === null
        : currentPosition.responseType === null ||
            timeManagementGoal.responseType === null ||
            teamType.responseType === null;
    };
    onChange(saveUserReponsesInput, isButtonDisabled());
  }, [currentPosition, teamType, timeManagementGoal]);

  return (
    <SlideLayout>
      <Header> Help us get to know you </Header>
      <Text>
        We'll tailor Clockwise to your needs. The more we know the more helpful we can be.
      </Text>
      <div className="cw-space-y-8 cw-w-full md:cw-w-[500px] cw-py-4 cw-mb-8">
        <Select
          fullWidth
          size="large"
          label={TYPE_OF_WORK_QUESTION}
          value={teamType.responseType || ""}
          onChange={onTeamTypeChange}
          name={TYPE_OF_WORK_QUESTION}
          cw-id="onboarding-type-of-work-select"
        >
          <SelectOption value="">Select...</SelectOption>
          {TYPE_OF_WORK_RESPONSES.map((r) => (
            <SelectOption key={r.responseText} value={r.responseType}>
              <div className="cw-flex cw-items-center cw-gap-1">{r.responseText}</div>
            </SelectOption>
          ))}
        </Select>

        <Select
          fullWidth
          size="large"
          label={CURRENT_POSITION_QUESTION}
          value={currentPosition.responseType || ""}
          onChange={onCurrentPositionChange}
          name={CURRENT_POSITION_QUESTION}
          cw-id="onboarding-current-position-select"
        >
          <SelectOption value="">Select...</SelectOption>
          {CURRENT_POSITION_RESPONSES.map((r) => (
            <SelectOption key={r.responseText} value={r.responseType}>
              <div className="cw-flex cw-items-center cw-gap-1">{r.responseText}</div>
            </SelectOption>
          ))}
        </Select>
        {!isOnOnboardingScheduleQuestionFlag && (
          <Select
            fullWidth
            size="large"
            label={TIME_MANAGEMENT_GOAL_QUESTION}
            value={timeManagementGoal.responseType || ""}
            onChange={onTimeManagementGoalChange}
            name={TIME_MANAGEMENT_GOAL_QUESTION}
            cw-id="onboarding-management-goal-select"
          >
            <SelectOption value="">Select...</SelectOption>
            {TIME_MANAGEMENT_GOAL_RESPONSES.map((r) => (
              <SelectOption key={r.responseText} value={r.responseType}>
                <div className="cw-flex cw-items-center cw-gap-1">{r.responseText}</div>
              </SelectOption>
            ))}
          </Select>
        )}
      </div>
    </SlideLayout>
  );
};
