// ErrorBoundary must use a class component because it needs to use componentDidCatch
/* eslint-disable clockwise/no-class-component */
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import React, { Component, ErrorInfo, ReactNode } from "react";
import toast from "react-hot-toast";
import { logger } from "../../util/logger.util";

const isProd = getEnvironment() === "production";

export const defaultHandleError = (error: Error, errorInfo: ErrorInfo) => {
  console.error("Uncaught error", isProd ? null : { error, errorInfo });
  logger.error("Error boundry reached", { error, errorInfo });
  toast.error("There was an error");
};

interface Props {
  children: ReactNode;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    } else {
      defaultHandleError(error, errorInfo);
    }
  }

  public render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      } else {
        return (
          <div className="cw-body-base cw-bg-default cw-text-warning">
            Unable to display this content.
          </div>
        );
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
