import { Link, Tooltip } from "@clockwise/design-system";
import { ArrowDownward, ArrowUpward, HelpFilled } from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TrackingEvents, track } from "../../util/analytics.util";
import { accentBorderClasses } from "./PersonalizedStatsRowGateway";
import { getDurationAsHours } from "./util/getDurationAsHours";
import { getStatDelta } from "./util/getStatDelta";

type FocusTimeHeldProps = {
  currentWeek: string;
  previousWeek: string;
  focusTimeEnabled: boolean;
};

const StatChange = ({ delta, focusTimeEnabled }: { delta: number; focusTimeEnabled: boolean }) => {
  const percentChange = delta ? `${Math.abs(delta)}%` : "No change";
  const navigate = useNavigate();

  if (!focusTimeEnabled) {
    return (
      <div className="cw-body-base">
        <Link
          href={appPaths.accountPreferences}
          onClick={(event) => {
            event.preventDefault();
            navigate(appPaths.accountPreferences);
            track(TrackingEvents.WEB_LANDING_PAGE.PERSONALIZED_STATS.ZERO_STATE_CTA_CLICKED, {
              type: "FocusTime",
            });
          }}
        >
          Turn on Focus Time Holds
        </Link>
      </div>
    );
  }

  return (
    <div className="cw-flex cw-body-base" aria-hidden>
      <p
        className={classNames("cw-flex cw-items-center cw-justify-center cw-mr-1", {
          "cw-text-destructive": delta < 0,
          "cw-text-positive": delta > 0,
          "cw-text-[#A6A6A6]": delta === 0,
        })}
      >
        {delta < 0 && <ArrowDownward fontSize="inherit" />}
        {delta > 0 && <ArrowUpward fontSize="inherit" />}
        {percentChange}
      </p>
      vs previous week
    </div>
  );
};

export const FocusTimeHeld = ({
  currentWeek,
  previousWeek,
  focusTimeEnabled,
}: FocusTimeHeldProps) => {
  const currentFocusTimeHeld = getDurationAsHours(currentWeek);
  const previousFocusTimeHeld = getDurationAsHours(previousWeek);
  const delta = getStatDelta(currentFocusTimeHeld, previousFocusTimeHeld);
  const percentChange = delta ? `${Math.abs(delta)}%` : "No change";
  const ariaChange = delta
    ? delta > 0
      ? `Up ${percentChange}`
      : `Down ${percentChange}`
    : "No Change";

  const title = currentFocusTimeHeld ? `${currentFocusTimeHeld} hr` : "Zero";
  const tooltipText = "Focus Time holds scheduled by Clockwise";

  return (
    <div
      className={classNames(accentBorderClasses, "cw-flex cw-flex-col cw-items-center", {
        "cw-border-t-[#00A972] ": currentFocusTimeHeld,
        "cw-border-t-[#A6A6A6]": !currentFocusTimeHeld,
      })}
    >
      <p
        className={classNames("cw-heading-3xl cw-text-41", {
          "cw-text-[#00A972] ": currentFocusTimeHeld,
          "cw-text-[#A6A6A6]": !currentFocusTimeHeld,
        })}
        aria-label={`${currentFocusTimeHeld} hours of Focus Time. ${ariaChange} vs previous week.`}
      >
        {title}
      </p>
      <div
        className={classNames("cw-heading-base cw-flex cw-justify-center cw-items-center", {
          "cw-text-[#A6A6A6]": !currentFocusTimeHeld,
        })}
        aria-hidden
      >
        Focus Time held
        <Tooltip title={tooltipText} placement="bottom">
          <HelpFilled fontSize="inherit" className="cw-ml-1 cw-text-subtle" />
        </Tooltip>
      </div>
      <StatChange delta={delta} focusTimeEnabled={focusTimeEnabled} />
    </div>
  );
};
