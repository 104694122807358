import { feedbackURL } from "#webapp/src/components/chat/constants";
import { getFormattedIdWithDashes } from "#webapp/src/components/chat/util/getFormattedIdWithDashes";
import { UserProfile } from "#webapp/src/components/hooks/useUserProfile";
import { isEmpty } from "lodash";

export const getFeedbackUrls = (
  conversationId: string,
  proposalId: string,
  userProfile: UserProfile,
  currentLLMUsed: string,
) => {
  const params = new URLSearchParams();
  if (!isEmpty(conversationId)) {
    params.set("conversationId", getFormattedIdWithDashes(conversationId));
  }
  if (proposalId) {
    params.set("proposalId", getFormattedIdWithDashes(proposalId));
  }
  if (userProfile.primaryEmail) {
    params.set("email", userProfile.primaryEmail);
  }
  if (!isEmpty(currentLLMUsed)) {
    params.set("llmName", currentLLMUsed);
  }

  const positiveSentimentURL = `${feedbackURL}/prefill/645179906231300a18dd6fe6dd96?${params.toString()}`;
  const negativeSentimentURL = `${feedbackURL}/prefill/64507ed663343305c5fca14459ad?${params.toString()}`;
  const neutralSentimentURL = `${feedbackURL}?${params.toString()}`;

  return [positiveSentimentURL, negativeSentimentURL, neutralSentimentURL];
};
