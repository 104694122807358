import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { createFragmentContainer } from "react-relay";

// unsubscribe-wrapper imports
import { unsubscribeWrapperFragments, unsubscribeWrapperQuery } from "./UnsubscribeWrapper.gql";
import { IContainer, IProps } from "./UnsubscribeWrapperTypes";

// state
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";

// util
import { supportEmail } from "#webapp/src/constants/site.constant";
import { unsubscribeEmail } from "#webapp/src/mutations";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";

// routing
import { withRouterQuery } from "#webapp/src/routing/routes.util";

// material-ui
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { Paper } from "@material-ui/core";

///////////////
// Component //
///////////////

const emailTemplateToCopyMap: { [keyof: string]: string } = {
  NowOnAutopilotV2: "emails about flexible meetings you're invited to.",
  DefragMoveValueSummary: "updates about flexible meetings on your calendar.",
  WeekInReview: "updates about your week in review.",
  InviteSuggestion: "suggestions to invite your coworkers.",
};

const UnsubLink = ({
  link,
  id,
  text,
  onLinkClick,
}: {
  link: string;
  id: string;
  text: string;
  onLinkClick: () => void;
}) => {
  return (
    <div className="cw-mt-3">
      <a
        onClick={onLinkClick}
        className="cw-text-slate-standard hover:cw-text-black cw-underline"
        cw-id={id}
        target="_blank"
        rel="noopener noreferrer"
        href={link}
      >
        {text}
      </a>
    </div>
  );
};

export const UnsubscribeComponent = (props: IProps) => {
  const {
    params: { email = "", token = "" },
    searchParams,
  } = props;
  const templateType = searchParams.get("template");

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const onUnsubscribeEmailSuccess = (_response: any) => {
    setLoading(false);
  };

  const onUnsubscribeEmailFailure = (_response: any) => {
    setLoading(false);
    setError(true);
  };

  const unsubscribeCopy = useMemo(() => {
    if (templateType && emailTemplateToCopyMap[templateType]) {
      return emailTemplateToCopyMap[templateType];
    }
    return "this specific product notification.";
  }, [searchParams]);

  useEffect(() => {
    const { relay } = props;
    logger.debug(email);
    logger.debug(token);
    unsubscribeEmail(
      relay.environment,
      { email, token },
      onUnsubscribeEmailSuccess,
      onUnsubscribeEmailFailure,
    );
  }, []);

  return (
    <div>
      <div className="cw-mt-s80">
        <Paper elevation={2} className="cw-p-s20 cw-w-[500px]">
          {loading ? (
            <div className="cw-text-center">
              <Loader size="xl" sentiment="positive" />
            </div>
          ) : (
            <div>
              {error ? (
                <div>
                  Sorry, we could not process your request. Please contact{" "}
                  <a href={`mailto:${supportEmail}`}>support</a>
                  &nbsp;so we can help you with this.
                </div>
              ) : (
                <div className="cw-leading-5">
                  <div className="cw-mb-2">
                    {`Your email`} <span className="cw-underline">{email}</span>{" "}
                    {`will no longer receive ${unsubscribeCopy}`}
                  </div>
                  <div>{`You'll still continue to get other product notifications and marketing messages.`}</div>

                  <UnsubLink
                    link={`${appPaths.preferences}#email`}
                    text="Change other product notifications here"
                    id="edit-product-email-notifications"
                    onLinkClick={() => {
                      track(TrackingEvents.EMAIL_UNSUBSCRIBE.GO_TO_PRODUCT_NOTIFICATIONS);
                    }}
                  />
                  <UnsubLink
                    link="http://hs-5550145.s.hubspotemail.net/hs/manage-preferences/unsubscribe-simple?via_redirect=true"
                    text="Unsubscribe from marketing emails here"
                    id="edit-marketing-email-notifications"
                    onLinkClick={() => {
                      track(TrackingEvents.EMAIL_UNSUBSCRIBE.GO_TO_MARKETING_NOTIFICATIONS);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
};

///////////
// Redux //
///////////
function mapStateToProps(_state: IReduxState, _ownProps: IContainer) {
  return {};
}

const UnsubscribeWrapperRedux = connect(mapStateToProps, {})(UnsubscribeComponent);

///////////
// Relay //
///////////
export const UnsubscribeWrapper = createFragmentContainer<IContainer>(
  UnsubscribeWrapperRedux,
  unsubscribeWrapperFragments,
);

export const UnsubscribeWrapperPage = withRouterQuery(UnsubscribeWrapper, unsubscribeWrapperQuery);
