import {
  useReadCalendar,
  useUpdateCalendar,
} from "@clockwise/web-commons/src/util/CalendarContext";
import { includes, isEmpty } from "lodash";
import { Interval } from "luxon";
import { useEffect, useMemo } from "react";
import { useCurrentProposal } from "../../chat-plus-calendar/CurrentProposalContext";
import { useActiveConflictClusters } from "../hooks/useActiveConflictClusters";
import { useActiveDiffSummaries } from "../hooks/useActiveDiffSummary";
import { useActiveViewEventsResponse } from "../hooks/useActiveViewEventsResponse";
import { getEarliestConflictClusterStartTime } from "./utils/getConflictClusterStart";
import { getEarliestDiffStart } from "./utils/getDiffStart";

export const ChatPlusCalendarDateSync = () => {
  const { activeDiffSummaries } = useActiveDiffSummaries();
  const { visibleDates } = useReadCalendar();
  const calendarDispatch = useUpdateCalendar();
  const { activeViewEventsResponse } = useActiveViewEventsResponse();
  const { activeConflictClusters } = useActiveConflictClusters();
  const { currentProposal } = useCurrentProposal();

  /* eslint-disable react-hooks/exhaustive-deps */
  const visibleDatesOnDiffChange = useMemo(() => {
    return visibleDates;
  }, [
    JSON.stringify(activeDiffSummaries),
    JSON.stringify(activeViewEventsResponse),
    // visibleDates -> IGNOIRE! -> prevent update on user date navigation
  ]);
  /* eslint-enable */

  useEffect(() => {
    const isDayView = visibleDates.length === 1;

    if (activeDiffSummaries.length > 0) {
      const diffDate = getEarliestDiffStart(activeDiffSummaries);

      if (diffDate.isValid) {
        const diffISODate = diffDate.toISODate();
        // visibleDatesOnDiffChange is a list of ISODates
        const isDiffDateVisible = includes(visibleDatesOnDiffChange, diffISODate);

        calendarDispatch({
          type: "set-anchor-date",
          payload: diffISODate,
        });
        if (!isDiffDateVisible) {
          calendarDispatch({
            type: isDayView ? "jumpTo-date" : "jumpTo-weekOf",
            payload: diffISODate,
          });
        }
      }
    } else if (activeViewEventsResponse) {
      const { dateRange } = activeViewEventsResponse;
      const viewEventsStartDate = Interval.fromISO(dateRange).start.toISODate();

      // visibleDatesOnDiffChange is a list of ISODates
      const isViewEventsDateVisible = includes(visibleDatesOnDiffChange, viewEventsStartDate);

      if (!isViewEventsDateVisible) {
        calendarDispatch({
          type: isDayView ? "jumpTo-date" : "jumpTo-weekOf",
          payload: viewEventsStartDate,
        });
      }
    } else if (currentProposal.initialized) {
      const proposalStartISODate = currentProposal.startTime.toISODate();
      const isProposalStartVisible = includes(visibleDates, proposalStartISODate);

      if (!isProposalStartVisible) {
        calendarDispatch({
          type: isDayView ? "jumpTo-date" : "jumpTo-weekOf",
          payload: proposalStartISODate,
        });
      }
    } else if (activeConflictClusters.length > 0) {
      const conflictDate = getEarliestConflictClusterStartTime(activeConflictClusters);
      if (conflictDate.isValid) {
        const conflictISODate = conflictDate.toISODate();
        // visibleDatesOnDiffChange is a list of ISODates
        const isConflictDateVisible = includes(visibleDates, conflictISODate);
        if (!isConflictDateVisible) {
          calendarDispatch({
            type: isDayView ? "jumpTo-date" : "jumpTo-weekOf",
            payload: conflictISODate,
          });
        }
      }
    } else if (isEmpty(activeDiffSummaries)) {
      calendarDispatch({
        type: "set-anchor-date",
        payload: null,
      });
    }
  }, [
    activeDiffSummaries,
    calendarDispatch,
    visibleDatesOnDiffChange,
    activeViewEventsResponse,
    currentProposal,
  ]);

  return null;
};
