import {
  areTeammatesIdentified,
  isUserCrawled,
} from "@clockwise/client-commons/src/util/onboarding";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useFlowState } from "../../hooks/useFlowState";
import { TrackingEvents, track } from "../../util/analytics.util";
import { WebOnboardingStep } from "./WebOnboarding.util";
import { useWebOnboardingSteps } from "./hooks/useWebOnboardingSteps";
import { AnalyzingLoader } from "./shared-components/AnalyzingLoader";

export const FastCrawlRequired = ({ children }: { children: ReactNode }) => {
  const [waiting, setWaiting] = useState(true);
  const [timeout, setTimeout] = useState(10);
  const { fastCrawlState } = useFlowState();
  const { currentStep, goForward } = useWebOnboardingSteps();

  const memoziedChildren = useMemo(() => children, [currentStep]);

  const decrementTimer = useCallback(() => {
    setTimeout((oldTimer) => oldTimer - 1);
  }, []);

  useEffect(() => {
    if (waiting) {
      const timeoutFunction = setInterval(decrementTimer, 1000);
      if (timeout <= 0) {
        goForward();
        track(TrackingEvents.WEB_ONBOARDING.FAST_CRAWL_SKIP, { currentStep: currentStep });
      }
      return () => clearInterval(timeoutFunction);
    }
    return;
  }, [timeout, decrementTimer, waiting]);

  useEffect(() => {
    const currentFastCrawlState = fastCrawlState?.current?.state;
    if (currentFastCrawlState) {
      if (currentStep === WebOnboardingStep.TeamJoinCreate) {
        if (areTeammatesIdentified(currentFastCrawlState)) {
          setWaiting(false);
        } else {
          track(TrackingEvents.WEB_ONBOARDING.FAST_CRAWL_LOADING, { currentStep: currentStep });
        }
      } else if (currentStep === WebOnboardingStep.FlexMeeting) {
        if (isUserCrawled(currentFastCrawlState)) {
          setWaiting(false);
        } else {
          track(TrackingEvents.WEB_ONBOARDING.FAST_CRAWL_LOADING, { currentStep: currentStep });
        }
      } else if (currentStep === WebOnboardingStep.Invite) {
        if (areTeammatesIdentified(currentFastCrawlState)) {
          setWaiting(false);
        } else {
          track(TrackingEvents.WEB_ONBOARDING.FAST_CRAWL_LOADING, { currentStep: currentStep });
        }
      } else if (currentStep === WebOnboardingStep.FocusTime) {
        if (areTeammatesIdentified(currentFastCrawlState)) {
          setWaiting(false);
        } else {
          track(TrackingEvents.WEB_ONBOARDING.FAST_CRAWL_LOADING, { currentStep: currentStep });
        }
      } else if (currentStep === WebOnboardingStep.Lunch) {
        if (areTeammatesIdentified(currentFastCrawlState)) {
          setWaiting(false);
        } else {
          track(TrackingEvents.WEB_ONBOARDING.FAST_CRAWL_LOADING, { currentStep: currentStep });
        }
      }
    }
  }, [fastCrawlState]);

  const getAnalzyingVariant =
    currentStep === WebOnboardingStep.FlexMeeting ? "calendar" : "organization";

  return <> {waiting ? <AnalyzingLoader variant={getAnalzyingVariant} /> : memoziedChildren} </>;
};
