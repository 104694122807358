import { maybeGetWarningFromDomains } from "../../../util/event.util";
import { EventCardAttendee } from "../types";

export enum EventCardWarning {
  ExternalUser = "ExternalUser",
  NotYourEvent = "NotYourEvent",
}

export const getEventCardWarning = (
  orgDomains: string[],
  attendees: EventCardAttendee[],
  canRemove: boolean,
): EventCardWarning | null => {
  if (!canRemove) {
    return EventCardWarning.NotYourEvent;
  }
  const attendeeCalIds = attendees.map((attendee) => attendee.primaryCalendar);
  const containsExternalAttendees =
    orgDomains.length && maybeGetWarningFromDomains(orgDomains, attendeeCalIds);
  if (containsExternalAttendees) {
    return EventCardWarning.ExternalUser;
  }
  return null;
};
