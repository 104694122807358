import { Popover } from "@clockwise/design-system";
import React, { ReactNode } from "react";

export const NuxPopover = ({
  children,
  addOffset = false,
  content,
}: {
  children: ReactNode;
  addOffset?: boolean;
  content: React.JSX.Element;
}) => {
  return (
    // 8 is the default offset given by DS
    <Popover opened={true} offset={addOffset ? 23 : 8} position="right" withArrow shadow="md">
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown>{content}</Popover.Dropdown>
    </Popover>
  );
};
