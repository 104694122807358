import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TeamSettingsNoMeetingDayQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
}>;


export type TeamSettingsNoMeetingDayQuery = { __typename: 'Query', team: { __typename: 'Team', id: string, isPrimary: boolean, useNoMeetingDay: boolean, noMeetingDay: { __typename: 'NoMeetingDayOptions', enabled: boolean, day: Types.DayEnum | null, autoreplyEnabled: boolean } } | null };

export type UpdateNoMeetingDayEnabledMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
  enabled: Types.Scalars['Boolean'];
}>;


export type UpdateNoMeetingDayEnabledMutation = { __typename: 'Mutation', updateNoMeetingDayEnabled: { __typename: 'Team', id: string, noMeetingDay: { __typename: 'NoMeetingDayOptions', enabled: boolean, day: Types.DayEnum | null } } | null };

export type UpdateNoMeetingDayMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
  day: Types.DayEnum;
}>;


export type UpdateNoMeetingDayMutation = { __typename: 'Mutation', updateNoMeetingDay: { __typename: 'Team', id: string, noMeetingDay: { __typename: 'NoMeetingDayOptions', day: Types.DayEnum | null } } | null };

export type UpdateNoMeetingAutoreplyEnabledMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
  enabled: Types.Scalars['Boolean'];
}>;


export type UpdateNoMeetingAutoreplyEnabledMutation = { __typename: 'Mutation', updateNoMeetingAutoreplyEnabled: { __typename: 'Team', id: string, noMeetingDay: { __typename: 'NoMeetingDayOptions', autoreplyEnabled: boolean } } | null };

export type UpdateNoMeetingDaySubscribedMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
  subscribed: Types.Scalars['Boolean'];
}>;


export type UpdateNoMeetingDaySubscribedMutation = { __typename: 'Mutation', updateNoMeetingDaySubscribed: { __typename: 'Team', id: string, useNoMeetingDay: boolean } | null };


export const TeamSettingsNoMeetingDayDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TeamSettingsNoMeetingDay"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"team"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isPrimary"}},{"kind":"Field","name":{"kind":"Name","value":"noMeetingDay"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"day"}},{"kind":"Field","name":{"kind":"Name","value":"autoreplyEnabled"}}]}},{"kind":"Field","name":{"kind":"Name","value":"useNoMeetingDay"}}]}}]}}]} as unknown as DocumentNode<TeamSettingsNoMeetingDayQuery, TeamSettingsNoMeetingDayQueryVariables>;
export const UpdateNoMeetingDayEnabledDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateNoMeetingDayEnabled"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateNoMeetingDayEnabled"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}}},{"kind":"Argument","name":{"kind":"Name","value":"enabled"},"value":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"noMeetingDay"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"day"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateNoMeetingDayEnabledMutation, UpdateNoMeetingDayEnabledMutationVariables>;
export const UpdateNoMeetingDayDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateNoMeetingDay"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"day"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DayEnum"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateNoMeetingDay"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}}},{"kind":"Argument","name":{"kind":"Name","value":"day"},"value":{"kind":"Variable","name":{"kind":"Name","value":"day"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"noMeetingDay"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"day"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateNoMeetingDayMutation, UpdateNoMeetingDayMutationVariables>;
export const UpdateNoMeetingAutoreplyEnabledDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateNoMeetingAutoreplyEnabled"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateNoMeetingAutoreplyEnabled"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}}},{"kind":"Argument","name":{"kind":"Name","value":"enabled"},"value":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"noMeetingDay"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"autoreplyEnabled"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateNoMeetingAutoreplyEnabledMutation, UpdateNoMeetingAutoreplyEnabledMutationVariables>;
export const UpdateNoMeetingDaySubscribedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateNoMeetingDaySubscribed"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"subscribed"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateNoMeetingDaySubscribed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}}},{"kind":"Argument","name":{"kind":"Name","value":"subscribed"},"value":{"kind":"Variable","name":{"kind":"Name","value":"subscribed"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"useNoMeetingDay"}}]}}]}}]} as unknown as DocumentNode<UpdateNoMeetingDaySubscribedMutation, UpdateNoMeetingDaySubscribedMutationVariables>;