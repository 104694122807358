import { ApolloError } from "@apollo/client";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useMemo } from "react";
import { RescheduleOptionsDocument } from "./__generated__/RescheduleOptions.v2.generated";

export const useRescheduleOptions = ({
  externalEventId,
  calendarId,
  skip = false,
  onError,
}: {
  externalEventId: string;
  calendarId: string;
  skip?: boolean;
  onError?: (error: ApolloError) => void;
}) => {
  const { data, loading, error } = useGatewayQuery(RescheduleOptionsDocument, {
    variables: {
      externalEventId,
      calendarId,
    },
    onError: (error: ApolloError) => {
      onError?.(error);
    },
    skip,
    context: {
      useBatching: false,
    },
    fetchPolicy: "no-cache",
  });

  const rescheduleOptions = data?.rescheduleOptions.rescheduleOptions;

  return useMemo(() => {
    return { rescheduleOptions, loading, error };
  }, [rescheduleOptions, loading, error]);
};
