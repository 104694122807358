import { useMutation } from "@apollo/client";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { Button, Menu, MenuItem } from "@clockwise/design-system";
import { MoreHoriz } from "@clockwise/design-system/icons";
import {
  BillingGroup,
  SearchPersonStatusResponse,
  SuggestedOrgInviteResolution,
} from "@clockwise/schema";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useFeatureFlag } from "../../../../../../launch-darkly";
import { TrackingEvents, track } from "../../../../../../util/analytics.util";
import { NewMemberPerson, getPersonFromCalId } from "../../APMembers.util";
import {
  OrgInviteAdminMembersDocument,
  SendBillingGroupRequestEmailAdminMembersDocument,
  UpdateSuggestedOrgInviteeAdminMembersDocument,
} from "../../__generated__/APMembers.generated";
import { useAPMembers } from "../../useAPMembers";
import {
  hasPermissionToAdd,
  hasPermissionToRemove,
  hasPermissionToRequestToJoin,
  isBillingGroupSso,
  isPersonInBillingGroup,
} from "./member-row.util";

export const MemberRowMoreOptions = ({
  person,
  setDialogBillingGroup,
  setDialogOpen,
  setCustomRequestToJoinDialogOpen,
}: {
  person: NewMemberPerson;
  setDialogBillingGroup: (bg: BillingGroup) => void;
  setDialogOpen: (open: boolean) => void;
  setCustomRequestToJoinDialogOpen: (open: boolean) => void;
}) => {
  const [sendingInvite, setSendingInvite] = useState("");

  const [sendBillingGroupRequestEmail] = useMutation(
    SendBillingGroupRequestEmailAdminMembersDocument,
  );
  const [updateSuggestedOrgInvite] = useMutation(UpdateSuggestedOrgInviteeAdminMembersDocument);
  const [updateOrgInvite] = useMutation(OrgInviteAdminMembersDocument);

  const {
    onAddMember,
    updateRecentlyRequestedInvitePersons,
    org,
    currentPersons,
    updateRecentlyInvitedPersons,
    updateCurrentPersonInviteStatus,
    billingGroupsForOrg,
    currentUserBgIdList,
  } = useAPMembers();

  const [hasCustomRequestToJoinRequest] = useFeatureFlag("CustomJoinAPlanRequest");

  const handleAddMember = async (bg: BillingGroup) => {
    const eMsg = `cannot add member to billing group`;
    if (!hasPermissionToAdd(bg, person)) {
      logger.error(`${eMsg} because user does not have permission`);
      return;
    }
    await onAddMember([person.personId], bg.id);
  };

  const onRequestToJoinMember = async (personId: string, billingGroupId: string, email: string) => {
    track(TrackingEvents.ADMIN_PANEL.MEMBERS_REQUEST_TO_JOIN_BILLING_GROUP, { billingGroupId });
    await sendBillingGroupRequestEmail({
      variables: { input: { orgRelayId: org?.id || "", billingGroupId, personId } },
      onCompleted: () => {
        toast.success("Request sent!");
        updateRecentlyRequestedInvitePersons(email);
      },
      onError: () => {
        const errorMsg = "Request failed to send";
        logger.error(errorMsg);
        toast.error(errorMsg);
      },
    });
  };

  const handleRequestToJoinMember = async (bg: BillingGroup) => {
    if (hasCustomRequestToJoinRequest && person.targetCalendarId) {
      setCustomRequestToJoinDialogOpen(true);
    } else {
      await onRequestToJoinMember(person.personId, bg.id, person.targetCalendarId);
    }
  };

  const sendInvite = async (calId: string) => {
    setSendingInvite(calId);
    const suggested = getPersonFromCalId(currentPersons, calId)?.isSuggested;
    const calendarIds = [calId];
    if (suggested) {
      const resolution = "Accepted" as SuggestedOrgInviteResolution;
      track(TrackingEvents.ADMIN_PANEL.MEMBERS_SEND_SUGGESTED_INVITE, { calendarId: calId });
      await updateSuggestedOrgInvite({
        variables: { input: { calendarIds, resolution, orgRelayId: org?.id || "" } },
        onCompleted: () => {
          setSendingInvite("");
          updateRecentlyInvitedPersons(calId);
          updateCurrentPersonInviteStatus(calId);
          toast.success("Invitation sent!");
        },
        onError: () => {
          setSendingInvite("");
          toast.error("Invitation failed to send! :(");
        },
      });
    } else if (!suggested) {
      track(TrackingEvents.ADMIN_PANEL.MEMBERS_SEND_INVITE);
      await updateOrgInvite({
        variables: { input: { calendarIds, orgRelayId: org?.id || "" } },
        onCompleted: () => {
          setSendingInvite("");
          updateRecentlyInvitedPersons(calId);
          updateCurrentPersonInviteStatus(calId);
          toast.success("Invitation sent!");
        },
        onError: () => {
          setSendingInvite("");
          toast.error("Invitation failed to send! :(");
        },
      });
    }
  };

  const handleOnInvite = () => {
    if (person.recentlySent) {
      return;
    }
    sendInvite(person.targetCalendarId);
  };

  const maybeRenderInviteResend = () => {
    if (person.recentlySent || person.recentlyRequested) {
      return (
        <Button disabled variant="text" sentiment="positive">
          Sent!
        </Button>
      );
    } else if (sendingInvite === person.targetCalendarId) {
      return (
        <Button disabled variant="text">
          Sending
        </Button>
      );
    } else {
      return (
        <Button variant="text" sentiment="positive" onClick={handleOnInvite}>
          Resend invite
        </Button>
      );
    }
  };

  if (person.status === SearchPersonStatusResponse.Invited || person.recentlyRequested) {
    return (
      <div className="cw-flex cw-items-center" style={{ flexDirection: "row-reverse" }}>
        {maybeRenderInviteResend()}
      </div>
    );
  } else if (person.status !== SearchPersonStatusResponse.User) {
    return null;
  }

  const hasMenuItems = billingGroupsForOrg.some((bg) => {
    // render if user can add, remove, or request
    return (
      !isBillingGroupSso(bg) && // if no SOO
      ((hasPermissionToRemove(bg, currentUserBgIdList, [person.isYou]) &&
        isPersonInBillingGroup(bg, person)) || // if the user has the ability to remove and is in the billing group
        hasPermissionToAdd(bg, person) ||
        hasPermissionToRequestToJoin(bg, person, currentUserBgIdList))
    );
  });

  const isDisabled = !hasMenuItems;

  return (
    <div className="cw-flex cw-justify-end">
      <Menu
        disabled={isDisabled}
        label=""
        size="large"
        icon={MoreHoriz}
        variant="inline"
        sentiment="neutral"
      >
        {billingGroupsForOrg.map((bg, i) => {
          if (isBillingGroupSso(bg)) {
            return "";
          } else if (
            hasPermissionToRemove(bg, currentUserBgIdList, [person.isYou]) &&
            isPersonInBillingGroup(bg, person)
          ) {
            return (
              <MenuItem
                key={`more-option-menu-item-remove-${i}`}
                onClick={() => {
                  setDialogBillingGroup(bg);
                  setDialogOpen(true);
                }}
              >
                {person.isYou ? `Leave` : `Remove user from`} {bg.name}
              </MenuItem>
            );
          } else if (hasPermissionToAdd(bg, person)) {
            return (
              <MenuItem
                key={`more-option-menu-item-add-${i}`}
                onClick={() => {
                  handleAddMember(bg);
                }}
              >
                Add to {bg.name}
              </MenuItem>
            );
          } else if (hasPermissionToRequestToJoin(bg, person, currentUserBgIdList)) {
            return (
              <MenuItem
                key={`more-option-menu-item-request-${i}`}
                onClick={() => {
                  handleRequestToJoinMember(bg);
                }}
              >
                Request to join {bg.name}
              </MenuItem>
            );
          }
          return "";
        })}
      </Menu>
    </div>
  );
};
