import { InviteSearch } from "#webapp/src/components/invite-search";
import React from "react";
import { InviteSearchPerson } from "../../../invite-search";
import { Header, Loading, SlideLayout, Text } from "../../shared-components";

export const InviteSlide = ({
  onCheck,
  selectedInvitees,
  suggestions,
  loading,
}: {
  onCheck: (invitees: InviteSearchPerson[], checked: boolean) => void;
  selectedInvitees: InviteSearchPerson[];
  suggestions: InviteSearchPerson[];
  loading: boolean;
}) => {
  return loading ? (
    <Loading />
  ) : (
    <SlideLayout>
      <Header>Try Clockwise with your teammates</Header>
      <Text>
        Clockwise is your personal calendar assistant, but it is even more powerful when it can
        orchestrate an entire organization's schedules.
      </Text>
      <div className="cw-w-full md:cw-w-[475px] cw-mt-8">
        <div className="cw-heading-sm cw-mb-2">Invite teammates </div>
        <InviteSearch
          selectedInvitees={selectedInvitees}
          suggestions={suggestions}
          onCheck={onCheck}
          trackingVariant="Onboarding"
        />
      </div>
    </SlideLayout>
  );
};
