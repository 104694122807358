import React, { useEffect } from "react";
import Helmet from "react-helmet";
import WebLanding from "../components/web-landing-page/WebLanding";
import { PageEvents, page } from "../util/analytics.util";

const WebLandingPage = () => {
  useEffect(() => {
    page(PageEvents.HOME);
  }, []);

  return (
    <>
      <Helmet>
        <title>Home · Clockwise</title>
      </Helmet>
      <WebLanding />
    </>
  );
};

export default WebLandingPage;
