import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import React from "react";
import Helmet from "react-helmet";
import { Navigate } from "react-router-dom";
import { CredentialsLoginForm } from "../components/credentials-login-form/CredentialsLoginForm";
import { useUserProfile } from "../components/hooks/useUserProfile";
import { NoRouteView } from "../components/no-route-view";

export function AutomationPage() {
  const env = getEnvironment();
  const isProd = env === "production" || env === "preprod";
  const { userProfile, loading } = useUserProfile();

  const isAuthenticated = !!userProfile.userId;

  if (loading) {
    return null;
  }

  if (isProd) {
    return <NoRouteView />;
  }

  if (isAuthenticated) {
    return <Navigate to={appPaths.ai} />;
  }

  return (
    <>
      <Helmet>
        <title>Clockwise | Automation</title>
      </Helmet>
      <div className="cw-p-4 cw-flex cw-flex-col cw-items-center cw-justify-center cw-h-screen">
        <CredentialsLoginForm />
      </div>
    </>
  );
}
