import { RouteRenderer } from "#webapp/src/components/route-renderer";
import { useMonetization } from "#webapp/src/hooks/useMonetization";
import { logger } from "#webapp/src/util/logger.util";
import { EcosystemEnum } from "@clockwise/schema";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import {
  DEFAULT_TEMPLATE,
  GROUP_OR_SINGLE_USE_TEMPLATE,
} from "@clockwise/web-commons/src/ui/link-title-template/link-title-template";
import { SchedulingLinkMeetingDetails } from "@clockwise/web-commons/src/ui/setup-scheduling-link";
import { SetupSchedulingLink } from "@clockwise/web-commons/src/ui/setup-scheduling-link/setup-scheduling-link";
import { getDefaultLinkSettings } from "@clockwise/web-commons/src/ui/setup-scheduling-link/utils";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { omit } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { SchedulingLinksQueryDocument } from "../scheduling-link-dashboard/__generated__/SchedulingLinksQuery.v2.generated";
import {
  CreateSchedulingLinkMutationDocument,
  CreateSchedulingLinkQueryDocument,
} from "./__generated__/CreateSchedulingLink.v2.generated";
import { buildSchedulingLinkTrackingData } from "./tracking.util";
import { buildAvailabilityRange } from "./utils";

export const CreateSchedulingLinkPageGateway = ({
  onReturnToDashboard,
  onViewLink,
}: {
  onReturnToDashboard: () => void;
  onViewLink: (linkName: string, slug: string, shouldPushNotReplace?: boolean) => void;
}) => {
  const location = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const single_use = searchParams.get("single_use") ?? "";
  const group_link = searchParams.get("group_link") ?? "";
  const isGroupLink = group_link === "true";

  const { canUserMakeGroupLinks, shouldUserSeeJoinExistingPlan } = useMonetization();

  const trackEvent = useTracking();

  // Local state

  const [saving, setSaving] = useState(false);
  const [mutationError, setMutationError] = useState<string | null>(null);

  // GQL queries and mutations

  const { data, loading, error } = useGatewayQuery(CreateSchedulingLinkQueryDocument, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });
  const linkName = data?.currentUser?.linkName;
  const orgId = data?.currentOrg?.id;

  const [createLink] = useGatewayMutation(CreateSchedulingLinkMutationDocument, {
    refetchQueries: [{ query: SchedulingLinksQueryDocument }],
  });

  // Callbacks

  const handleSuccess = useCallback(
    ({ active, slug, linkName }: { active: boolean; slug: string; linkName: string }) => {
      if (active && slug && linkName) {
        onViewLink(linkName, slug);
      } else {
        onReturnToDashboard();
      }
    },
    [onReturnToDashboard, onViewLink],
  );

  // Convert the newLinkSettings to the mutation input type, CreateMeetingSettingsVariables.
  const handleSave = useCallback(
    async ({
      active,
      allowBookingOnNoMeetingDay,
      allowQuarterHourBooking,
      attendeeQuestions,
      availabilityRange,
      availabilityRestriction,
      bookableHours,
      bufferHours,
      customMeetingHours,
      durations,
      defaultDuration,
      highlightBestTimes,
      linkMembers,
      location,
      name,
      singleUse,
      slug,
      description,
      id,
      allowAdditionalAttendees,
      titleTemplate,
      textOnlyTitle,
      maxBookings,
      groupSchedulingStrategy,
    }: SchedulingLinkMeetingDetails) => {
      if (id) {
        throw new Error("Cannot create a link that already has an id!");
      }

      if (!defaultDuration) {
        throw new Error("Cannot create a link without a default duration!");
      }

      setSaving(true);

      try {
        const { data } = await createLink({
          variables: {
            input: {
              active,
              allowBookingOnNoMeetingDay,
              allowQuarterHourBooking,
              attendeeQuestions: attendeeQuestions.map((q) => omit(q, ["renderId"])),
              availabilityRange: buildAvailabilityRange(availabilityRange),
              availabilityRestriction,
              bookableHours,
              bufferHours,
              customMeetingHours: customMeetingHours
                ? {
                    schedule: customMeetingHours.schedule,
                    dateOverrides: customMeetingHours.dateOverrides,
                  }
                : undefined,
              durations,
              defaultDuration,
              highlightBestTimes,
              linkMembers: linkMembers.map(({ member, owner, required, schedulingPriority }) => ({
                owner,
                required,
                memberId: member.id,
                schedulingPriority,
              })),
              name,
              singleUse,
              slug,
              description,
              location,
              allowAdditionalAttendees,
              titleTemplate,
              textOnlyTitle,
              maxBookings: maxBookings.active
                ? { count: maxBookings.count, period: maxBookings.period }
                : undefined,
              groupSchedulingStrategy,
            },
          },
        });
        if (data) {
          // Success!
          handleSuccess({
            active: data.createSchedulingLink.active,
            slug: data.createSchedulingLink.slug,
            linkName: linkName ?? "",
          });
          trackEvent(
            TrackingEvents.LINKS.EDIT_PAGE.CREATE,
            buildSchedulingLinkTrackingData({
              ...data.createSchedulingLink,
              availabilityRange: buildAvailabilityRange(
                data.createSchedulingLink.availabilityRange,
              ),
            }),
          );
        }
      } catch (e) {
        setMutationError("Problem creating Link, please try again.");
        logger.error("Mutation error creating link via CreateSchedulingLinkPage", e);
        throw e;
      } finally {
        setSaving(false);
      }
    },
    [trackEvent, createLink, linkName, handleSuccess],
  );

  const handleDelete = useCallback((_meetingSettingsId: string) => {
    logger.warn("Users shouldn't be able to delete links from the create page!");
  }, []);

  const onBack = useCallback(() => {
    if (history.length > 1) {
      history.back();
    } else {
      // We arrived here in a new tab from sidebar create button or directly via url.
      onReturnToDashboard();
    }
  }, [onReturnToDashboard]);

  const org = data?.currentOrg;
  const canUseZoom = !!org?.zoomAccount?.valid || !!data?.currentUser?.zoomAccount?.valid;
  const canUseTeams = useEcosystem() === EcosystemEnum.Microsoft;

  const member = data?.currentUser?.member;
  const initialLinkSettings = useMemo(() => {
    if (member) {
      const { email, givenName, familyName, externalImageUrl } = member.person;
      return getDefaultLinkSettings({
        canUseZoom,
        memberId: member.id,
        email,
        givenName,
        familyName,
        externalImageUrl,
        singleUse: single_use === "true",
        titleTemplate:
          group_link === "true" || single_use === "true"
            ? GROUP_OR_SINGLE_USE_TEMPLATE
            : DEFAULT_TEMPLATE,
        roundRobinEnabled: member.roundRobinEnabled,
      });
    }
    return null;
  }, [member, canUseZoom, single_use, group_link]);

  return (
    <>
      <Helmet>
        <title>Create Clockwise Link</title>
      </Helmet>
      <RouteRenderer loading={loading} error={!!error}>
        {initialLinkSettings && linkName && (
          <SetupSchedulingLink
            initialLinkSettings={initialLinkSettings}
            canUseZoom={canUseZoom}
            canUseTeams={canUseTeams}
            canMarkMeetingAsActive={true}
            shouldUserSeeJoinExistingPlan={shouldUserSeeJoinExistingPlan}
            canUserMakeGroupLinks={canUserMakeGroupLinks}
            onBack={onBack}
            onSave={handleSave}
            onDelete={handleDelete}
            createUpdateError={mutationError}
            saving={loading || saving}
            linkName={linkName}
            deleting={false}
            clearError={() => setMutationError(null)}
            onPreview={() => {
              // TODO: We need better separation of edit vs create UI so we can get rid of this prop
              // in the create case. There's nothing to preview since we don't have a slug until
              // after the link is saved.
              throw new Error("Cannot preview a link that hasn't been created yet!");
            }}
            orgId={orgId}
            isGroupLink={isGroupLink}
          />
        )}
      </RouteRenderer>
    </>
  );
};
