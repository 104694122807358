import { Person } from "@clockwise/design-system/icons";
import { AssistantFilled } from "@clockwise/icons";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import React from "react";

type FlexibleEventSkeletonProps = {
  width?: string | number;
  avatarCount?: number;
};

export const FlexibleEventSkeleton = ({
  width = "50px",
  avatarCount = 0,
}: FlexibleEventSkeletonProps) => (
  <div className="cw-flex cw-items-center cw-justify-between cw-w-full">
    <div className="cw-flex cw-items-center">
      <AssistantFilled />
      <div className="cw-ml-1 cw-bg-[#00A972] cw-rounded cw-h-2" style={{ width: width }}></div>
    </div>
    {avatarCount > 0 && (
      <AttendeeAvatarStack disableAnimation>
        {new Array(avatarCount).fill(null).map((_n, i) => (
          // TODO (nikita): replace HEX color with DS token
          <Person
            key={i}
            className="cw-h-7 cw-w-7 cw-bg-[#00A972] cw-rounded-full cw-text-[#054F3E] cw-border-2 cw-border-solid cw-border-[#054F3E]"
          />
        ))}
      </AttendeeAvatarStack>
    )}
  </div>
);
