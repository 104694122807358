import { Button, DateInput, Kbd } from "@clockwise/design-system";
import { FindTime } from "@clockwise/design-system/icons";
import { fg_muted } from "@clockwise/design-system/tokens";
import { RightPanelCloseFilled } from "@clockwise/icons";
import { CalendarDensity, CalendarMode } from "@clockwise/web-commons/src/components/calendar";
import { CalendarMenu } from "@clockwise/web-commons/src/components/calendar/calenar-menu/CalendarMenu";
import { CalendarModeToggle } from "@clockwise/web-commons/src/components/calendar/calendar-mode/CalendarMode";
import {
  CalendarStepNavigation,
  HighlightedNavButton,
} from "@clockwise/web-commons/src/components/calendar/calendar-step-navigation/CalendarStepNavigation";
import React from "react";
import { useFeatureFlag } from "../../../launch-darkly/useLaunchDarkly";
import { changelogURL } from "../../chat/constants";
import { PrismButton } from "../../prism/Prism";

const MutedFindTime = () => <FindTime fill={fg_muted} />;

type Props = {
  currentDate: string;
  dateFormat: "MMM YYYY" | "MMMM YYYY" | "ddd, MMMM DD YYYY" | "MMM DD YYYY";
  declinedEventsEnabled: boolean;
  density: CalendarDensity;
  feedbackURL: string;
  loading: boolean;
  mode: CalendarMode;
  onCalendarDensityChange: (density: CalendarDensity) => void;
  onCalendarModeChange: (view: CalendarMode) => void;
  onFadePastEventsChange: (fadePastEvents: boolean) => void;
  onFindTime?: () => void;
  onNavigateToDate: (date: string) => void;
  onShiftNext: () => void;
  onShiftPrevious: () => void;
  onShowDeclinedEventsChange: (shown: boolean) => void;
  onShowWeekendsChange: (shown: boolean) => void;
  onTodayClick: () => void;
  onExpandChat: () => void;
  chatIsCollapsed: boolean;
  weekendsEnabled: boolean;
  fadePastEvents: boolean;
  navButtonToHighlight: HighlightedNavButton;
  highlightedNavButtonTooltip:
    | (({ anchorEl }: { anchorEl: React.RefObject<HTMLDivElement> }) => React.JSX.Element)
    | null;
};

export const PlannerToolbarRender = ({
  currentDate,
  dateFormat,
  declinedEventsEnabled,
  density,
  feedbackURL,
  mode,
  onCalendarDensityChange,
  onCalendarModeChange,
  onFadePastEventsChange,
  onFindTime,
  onNavigateToDate,
  onShiftNext,
  onShiftPrevious,
  onShowDeclinedEventsChange,
  onShowWeekendsChange,
  onTodayClick,
  onExpandChat,
  chatIsCollapsed,
  weekendsEnabled,
  fadePastEvents,
  navButtonToHighlight,
  highlightedNavButtonTooltip,
}: Props) => {
  const [enableCommandBar] = useFeatureFlag("CommandBar");

  const handleNavigateToDate = (dateMoment: string) => {
    if (!dateMoment) {
      return;
    }
    onNavigateToDate(dateMoment);
  };

  return (
    <div className="cw-w-full cw-px-3 cw-mt-2.5 cw-mb-3 cw-flex cw-flex-row cw-items-center cw-justify-between cw-z-[51] cw-sticky">
      <div className="cw-flex cw-flex-row cw-items-center cw-gap-2 xs:cw-gap-2 cw-flex-wrap">
        {chatIsCollapsed && (
          <Button
            onClick={onExpandChat}
            aria-label="Expand chat sidebar"
            variant="outlined"
            size="xsmall"
          >
            <RightPanelCloseFilled className="cw-text-muted cw-w-4 cw-h-4" />
          </Button>
        )}
        {!!onFindTime && (
          <PrismButton
            onClick={onFindTime}
            size="xsmall"
            startIcon={MutedFindTime}
            variant="outlined"
          >
            Find time
            {enableCommandBar && (
              <span className="cw-text-muted cw-flex cw-gap-[2px] cw-h-[18px] cw-leading-[18px]">
                <Kbd size="xs" className="cw-leading-[11px]">
                  ⌘
                </Kbd>
                <Kbd size="xs" className="cw-leading-[11px]">
                  K
                </Kbd>
              </span>
            )}
          </PrismButton>
        )}
        <CalendarStepNavigation
          mode={mode}
          onPrevious={onShiftPrevious}
          onToday={onTodayClick}
          onNext={onShiftNext}
          highlightedButton={navButtonToHighlight}
          highlightedTooltip={highlightedNavButtonTooltip}
        />
        <DateInput
          value={currentDate}
          valueFormat={dateFormat}
          showBorder={false}
          onChange={handleNavigateToDate}
        />
      </div>
      <div className="cw-flex cw-items-center cw-space-x-2">
        <CalendarModeToggle calendarMode={mode} onCalendarModeChange={onCalendarModeChange} />
        <CalendarMenu
          declinedEvents={declinedEventsEnabled}
          density={density}
          onCalendarDensityChange={onCalendarDensityChange}
          onShowDeclinedEventsChange={onShowDeclinedEventsChange}
          onShowWeekendsChange={onShowWeekendsChange}
          onFadePastEventsChange={onFadePastEventsChange}
          weekends={weekendsEnabled}
          fadePastEvents={fadePastEvents}
          changelogUrl={changelogURL}
          feedbackURL={feedbackURL}
        />
      </div>
    </div>
  );
};

export default PlannerToolbarRender;
