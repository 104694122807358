import { Today } from "@clockwise/design-system/icons";
import {
  PageLayout,
  PageLayoutContent,
} from "@clockwise/web-commons/src/ui/page-layout/PageLayout";
import { SecurityRounded } from "@material-ui/icons";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { PageEvents, page } from "../util/analytics.util";

import { Tabs } from "@clockwise/design-system";
import { TrialProBadgeWithTooltip } from "@clockwise/web-commons/src/ui/trial-pro-badge-with-tooltip";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useLocation } from "react-router-dom";
import { FlexibleHoldsSection } from "../components/flexible-meetings/FlexibleHoldsSection";
import { FlexibleMeetings } from "../components/flexible-meetings/FlexibleMeetings";
import { useUnvisitedPageFlags } from "../hooks/useUnvisitedPageFlags";
const FlexibleMeetingsPage = () => {
  const track = useTracking();
  const {
    setHasVisitedFlexibilityPage,
    shouldShowUnvisitedBadges,
    hasVisitedFlexibilityPage,
  } = useUnvisitedPageFlags();
  useEffect(() => {
    page(PageEvents.FLEXIBLE_MEETINGS_PAGE);
  }, []);

  useEffect(() => {
    if (!hasVisitedFlexibilityPage && shouldShowUnvisitedBadges) {
      setHasVisitedFlexibilityPage();
      track(TrackingEvents.NUX_2025.UNVISITED_LINKS.FLEXIBILITY_VISITED);
    }
  }, [hasVisitedFlexibilityPage, shouldShowUnvisitedBadges]);

  const { hash } = useLocation();

  const tabData = [
    {
      title: (
        <div className="cw-flex cw-flex-row cw-items-center cw-justify-center cw-w-full cw-space-x-1.5">
          <Today className="cw-text-16" />
          <div className="cw-max-w-full cw-relative">Meetings</div>
        </div>
      ),
      content: <FlexibleMeetings />,
      key: "meetings",
    },
    {
      title: (
        <div className="cw-flex cw-flex-row cw-items-center cw-justify-center cw-w-full cw-space-x-2">
          <SecurityRounded className="cw-text-16" />
          <div className="cw-max-w-full cw-relative">Holds</div>
        </div>
      ),
      content: <FlexibleHoldsSection />,
      key: "holds",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Flexibility · Clockwise</title>
      </Helmet>
      <PageLayout
        title={
          <div className="cw-flex cw-items-center">
            Flexibility
            <span className="cw-mx-4 cw-flex cw-items-center">
              <TrialProBadgeWithTooltip showToolTip={false} badgeText="Teams" colorOption="green" />
            </span>
          </div>
        }
      >
        <PageLayoutContent>
          <Tabs tabs={tabData} defaultTabIndex={hash === "#holds" ? 1 : 0} />
        </PageLayoutContent>
      </PageLayout>
    </>
  );
};

export default FlexibleMeetingsPage;
