import { graphql } from "react-relay";

export const chromeZoomVerifierFragments = {
  viewer: graphql`
    fragment ChromeZoomVerifier_viewer on Viewer {
      user {
        id
        emails
        orgs(first: 1000000) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `,
};

export const chromeZoomVerifierQuery = graphql`
  query ChromeZoomVerifierQuery {
    viewer {
      ...ChromeZoomVerifier_viewer
    }
  }
`;
