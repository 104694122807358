/**
 * @generated SignedSource<<4ab7cf621ce75b8d3d4303490f68fbb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WebAppOnboardingCompleteQuery$variables = {
  orgRelayId: string;
};
export type WebAppOnboardingCompleteQuery$data = {
  readonly org: {
    readonly " $fragmentSpreads": FragmentRefs<"WebAppOnboardingComplete_org">;
  } | null;
};
export type WebAppOnboardingCompleteQuery = {
  variables: WebAppOnboardingCompleteQuery$variables;
  response: WebAppOnboardingCompleteQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgRelayId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "orgRelayId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WebAppOnboardingCompleteQuery",
    "selections": [
      {
        "alias": "org",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WebAppOnboardingComplete_org"
              }
            ],
            "type": "Org",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WebAppOnboardingCompleteQuery",
    "selections": [
      {
        "alias": "org",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "slackSettingsErrorable",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SlackSettings",
                        "kind": "LinkedField",
                        "name": "list",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slackTeamId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "SlackSettingsList",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Org",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70b30676b6186a206629bc890cbded43",
    "id": null,
    "metadata": {},
    "name": "WebAppOnboardingCompleteQuery",
    "operationKind": "query",
    "text": "query WebAppOnboardingCompleteQuery(\n  $orgRelayId: ID!\n) {\n  org: node(id: $orgRelayId) {\n    __typename\n    ... on Org {\n      ...WebAppOnboardingComplete_org\n    }\n    id\n  }\n}\n\nfragment WebAppOnboardingComplete_org on Org {\n  id\n  slackSettingsErrorable {\n    __typename\n    ... on SlackSettingsList {\n      list {\n        id\n        slackTeamId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b507d9bc27c07124e457a7f07732c42";

export default node;
