import { GetUsersWorkingHoursQuery } from "#webapp/src/components/web-app-calendar/graphql/__generated__/GetUsersWorkingHours.generated";
import {
  EventFlexConstraintsDocument,
  EventFlexConstraintsQuery,
} from "#webapp/src/hooks/useEventFlexConstraints/__generated__/EventFlexConstraints.generated";
import { useQuery } from "@apollo/client";
import {
  all as allTimeSlots,
  timeSlotsCeiling,
  timeSlotsFloor,
} from "@clockwise/client-commons/src/constants/time-slot";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import {
  calculateDayOffsetTimeSlotIndex,
  getAutopilotTimeOfDayFlexibilitySummaryText,
  getEventMoveRangeMinMaxRange,
} from "@clockwise/client-commons/src/util/time-slot";
import { AutopilotEventStatus, AutopilotEventStatusEnum, TimeSlot } from "@clockwise/schema";
import { DayOfWeek, FlexRange } from "@clockwise/schema/v2";
import { notEmpty } from "@clockwise/web-commons/src/util/notEmpty";
import { compact, find } from "lodash";
import { DateTime } from "luxon";
import React, { PropsWithChildren, createContext, useContext } from "react";
import invariant from "tiny-invariant";
import { useWorkingHours } from "../../account-preferences-working-meeting-hours/hooks/useWorkingHours";
import { GetUsersWorkingHoursDocument } from "../../web-app-calendar/graphql/__generated__/GetUsersWorkingHours.generated";
import { ALL_WORK_DAYS_SELECTED, EventCardAttendee, SelectedWorkdayOption } from "../types";
import { useEventAttendees } from "./EventAttendeesContext";

export type WorkingHoursByAttendee = {
  profile: {
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  } | null;
  calendarId: string;
  workingHours: {
    start: number | null;
    end: number | null;
  };
};

type EventFlexConstraintsContextType = {
  minMaxRecommendedValues: [number, number];
  meetingHoursConflict: boolean;
  availableWorkDays: SelectedWorkdayOption[];
  usersWorkingHours: WorkingHoursByAttendee[];
  flexStatus: AutopilotEventStatus["status"];
  getFlexDescription: (
    start?: string | null,
    end?: string | null,
    flexRange?: FlexRange | null,
    allowedDays?: DayOfWeek[] | null,
  ) => string;
  loading: boolean;
};

export type EventFlexConstraintsProps = {
  startTime?: DateTime;
  calendarId?: string;
  eventId: string | null;
};

const EventFlexConstraintsContext = createContext<EventFlexConstraintsContextType>(
  undefined as never,
);

export const EventFlexConstraintsProvider: React.FC<
  PropsWithChildren<EventFlexConstraintsProps>
> = ({ children, startTime, calendarId: calendarIdInitial, eventId }) => {
  const { workingHours } = useWorkingHours();
  const { attendees } = useEventAttendees();

  const weekOffset = Math.floor(
    startTime?.startOf("week").diff(DateTime.local().startOf("week"), "weeks").weeks ?? 0,
  );

  const calendarId = calendarIdInitial ?? attendees.find((att) => att.isYou)?.primaryCalendar;

  const calIds = compact(
    attendees.map((att) => {
      // We only want attendees with userIds, or the query will fail for all attendees
      if (att.userId) {
        return att.primaryCalendar;
      } else {
        return null;
      }
    }),
  );

  const { data: usersWorkingHoursData, loading: workingHoursLoading } = useQuery(
    GetUsersWorkingHoursDocument,
    {
      variables: {
        calendarIds: calIds.length > 0 ? calIds : calendarId,
        resolveOverrides: true,
        weekOffset,
      },
    },
  );

  const { data: constraintsData, loading: constraintsLoading } = useQuery(
    EventFlexConstraintsDocument,
    {
      variables: {
        externalEventId: eventId || "",
        calendarId: calendarId || "",
        typedCalendarId: calendarId || "",
      },
      skip: !calendarId || !eventId,
    },
  );

  const dataOrg = getValue(constraintsData?.viewer.user?.orgs.edges?.[0]?.node, "Org");
  const eventParent = getValue(dataOrg?.forceFetchEventParentErrorable);
  const suggestedAutopilotSettingsFetched = getValue(
    eventParent?.events[0]?.suggestedAutopilotSettingsResponseV3,
  );

  const usersWorkingHours = parseWorkingHours(
    usersWorkingHoursData,
    constraintsData,
    attendees,
    startTime ?? DateTime.now(),
    eventId,
  );

  let flexStatus = AutopilotEventStatusEnum.CanMove;
  if (eventId) {
    const events = eventParent?.events;
    const event = find(events, (event) => event?.eventKey?.externalEventId === eventId);
    if (event && event.autopilotEventStatus?.__typename === "AutopilotEventStatus") {
      flexStatus = event.autopilotEventStatus.status || AutopilotEventStatusEnum.CanMove;
    }
  }

  const intersectionOfWorkingHours = getUnionOfAllWorkingHours(usersWorkingHours);
  let attendeeIntersectionHours;

  if (suggestedAutopilotSettingsFetched) {
    attendeeIntersectionHours =
      suggestedAutopilotSettingsFetched.intersectionOfAttendeesMeetingHours;
  } else {
    attendeeIntersectionHours = undefined;
  }
  const availableWorkDays = ALL_WORK_DAYS_SELECTED;

  const meetingHoursConflict =
    !attendeeIntersectionHours &&
    !intersectionOfWorkingHours &&
    !constraintsLoading &&
    !workingHoursLoading;
  const minMaxRecommendedValues = getEventMoveRangeMinMaxRange(
    attendeeIntersectionHours || intersectionOfWorkingHours || undefined,
    workingHours || undefined,
    startTime?.toISODate(),
  );

  const getFlexDescription = (
    start?: string | null,
    end?: string | null,
    flexRange?: FlexRange | null,
    allowedDays?: DayOfWeek[] | null,
  ) => {
    const [minPossibleStart, maxPossibleEnd] = minMaxRecommendedValues;
    const allowedStartSlot = start
      ? timeSlotsFloor.indexOf(start) + 0 * timeSlotsFloor.length
      : minPossibleStart;
    const allowedEndSlot = end
      ? timeSlotsCeiling.indexOf(end) + 0 * timeSlotsCeiling.length
      : maxPossibleEnd;
    const minAtAttendeeMin = allowedStartSlot === minPossibleStart;
    const maxAtAttendeeMax = allowedEndSlot === maxPossibleEnd;
    const textSummary = getAutopilotTimeOfDayFlexibilitySummaryText(
      allTimeSlots[allowedStartSlot],
      allTimeSlots[allowedEndSlot],
      minAtAttendeeMin,
      maxAtAttendeeMax,
    );

    const rangeDescription = getRangeDescription(flexRange, allowedDays);
    if (rangeDescription) {
      return `${rangeDescription} ⸱ ${textSummary}`;
    }
    return textSummary;
  };

  return (
    <EventFlexConstraintsContext.Provider
      value={{
        availableWorkDays,
        meetingHoursConflict,
        minMaxRecommendedValues,
        usersWorkingHours,
        flexStatus,
        getFlexDescription,
        loading: constraintsLoading || workingHoursLoading,
      }}
    >
      {children}
    </EventFlexConstraintsContext.Provider>
  );
};

export const useEventFlexConstraints = () => {
  const context = useContext(EventFlexConstraintsContext);
  invariant(context, "useEventAttendees must be used within a EventAttendeesProvider");
  return context;
};

const getUnionOfAllWorkingHours = (hours: WorkingHoursByAttendee[]) => {
  const allStart = compact(hours.map((hour) => hour.workingHours.start));
  const allEnds = compact(hours.map((hour) => hour.workingHours.end));

  // If anything was removed, we know that someone cannot work this day, so there is no union
  if (allStart.length !== hours.length || allEnds.length !== hours.length) return null;

  if (!allStart.length || !allEnds.length) return null;

  return {
    start: Math.max(...allStart),
    end: Math.min(...allEnds),
  };
};

export const getRangeDescription = (
  flexRange?: FlexRange | null,
  allowedDays?: DayOfWeek[] | null,
) => {
  if (!flexRange) return null;

  switch (flexRange) {
    case FlexRange.Day:
      return "Within day";
    case FlexRange.Week:
      return "Within week";
    case FlexRange.SpecificDays:
      if (allowedDays && allowedDays.length > 0) {
        return "Within specific days";
      }
      return null;
    default:
      return null;
  }
};

const parseWorkingHours = (
  usersWorkingHoursData: GetUsersWorkingHoursQuery | undefined,
  constraintsData: EventFlexConstraintsQuery | undefined,
  attendees: EventCardAttendee[],
  date: DateTime,
  eventId: string | null,
) => {
  if (eventId) {
    // This grabs the working hours of the attendees from the event. This should be used whenever there is an event, as it allows us to fetch non-clockwise users
    // This will not work for add diff cards, as they do not have an eventId
    return parseWorkingHoursFromEvent(constraintsData, attendees);
  } else {
    // Use this only if there is no eventId, since we cant only grab clockwise user info
    // Currently the only instance we need this info with no eventid is add diff cards
    return parseWorkingHoursFromOrg(usersWorkingHoursData, attendees, date);
  }
};

const parseWorkingHoursFromEvent = (
  data: EventFlexConstraintsQuery | undefined,
  attendees: EventCardAttendee[],
) => {
  const dataOrg = getValue(data?.viewer.user?.orgs.edges?.[0]?.node, "Org");
  const eventParent = getValue(dataOrg?.forceFetchEventParentErrorable);
  const suggestedAutopilotSettingsFetched = getValue(
    eventParent?.events[0]?.suggestedAutopilotSettingsResponseV3,
  );
  const calendarIdToMeetingHours = suggestedAutopilotSettingsFetched?.calendarIdToMeetingHours;

  if (!calendarIdToMeetingHours) return [];

  const workingHoursToCalId = compact(
    calendarIdToMeetingHours?.map((range) => {
      const attendee = attendees.find((att) => att.primaryCalendar === range.calendarId);
      const currentTimeSlotRange = range?.dayOffsetTimeSlotRanges?.[0] || undefined;
      if (attendee) {
        return {
          calendarId: range.calendarId,
          profile: attendee?.profile,
          workingHours: {
            start: calculateDayOffsetTimeSlotIndex(currentTimeSlotRange?.minSlot || undefined),
            end: calculateDayOffsetTimeSlotIndex(currentTimeSlotRange?.maxSlot || undefined),
          },
        };
      } else {
        return null;
      }
    }),
  );

  return workingHoursToCalId;
};

const parseWorkingHoursFromOrg = (
  data: GetUsersWorkingHoursQuery | undefined,
  attendees: EventCardAttendee[],
  date: DateTime,
) => {
  if (!data) return [];

  const day = date.toFormat("EEEE");

  const workingHours = compact(
    data.viewer.user?.orgs.edges
      ?.flatMap((edge) => edge?.node?.usersWorkingHours)
      .filter(notEmpty)
      .map((allhours) => {
        const attendee = attendees.find((att) => att.userId === allhours.userId);

        const workingHourDay = allhours.workingHours?.daySettings?.find(
          (daySetting) => daySetting?.day === day,
        );
        if (attendee) {
          const end = workingHourDay
            ? allTimeSlots.indexOf(
                (workingHourDay?.setting?.maxSlot || ("" as unknown)) as TimeSlot,
              )
            : null;
          const start = workingHourDay
            ? allTimeSlots.indexOf(
                (workingHourDay?.setting?.minSlot || ("" as unknown)) as TimeSlot,
              )
            : null;
          return {
            profile: attendee.profile,
            calendarId: attendee.primaryCalendar,
            workingHours: {
              start,
              end,
            },
          };
        } else {
          return null;
        }
      }),
  );
  return workingHours;
};
