import { useLazyQuery } from "@apollo/client";
import { Button } from "@clockwise/design-system";
import { KeyboardArrowDown, KeyboardArrowUp } from "@clockwise/icons";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { track, TrackingEvents } from "../../../../../util/analytics.util";
import { OnDemandDefragDocument } from "../../../../chat-plus-calendar/apollo/__generated__/OnDemandDefrag.generated";
import { useUpdateDefragProposal } from "../../../../chat-plus-calendar/DefragProposalContext";
import { eventToast } from "../../../../web-app-calendar/notifiation-event/EventToast";
import { optimizeOptionIcon } from "../../ChatInput/Icons";
import { useAIMessageContext } from "../../hooks/AIMessageContext";
import { DefaultWeights, PreferencesWeights } from "./constants";
import { PreferencesTuner } from "./PreferencesTuner";

const useFetchOptimizationProposal = () => {
  const [getOnDemandDefrag, { data, loading }] = useLazyQuery(OnDemandDefragDocument);
  const { handleOnDemandDefragRequest } = useUpdateDefragProposal();
  const { setProcessingMessage } = useAIMessageContext();

  const fetchOptimizationProposal = (weights: PreferencesWeights) => {
    void getOnDemandDefrag({
      variables: {
        startTime: DateTime.now(),
        weightOverrides: weights,
      },
    });
  };

  useEffect(() => {
    if (data) {
      const defragProposal = data?.viewer?.user?.onDemandDefrag || null;
      track(TrackingEvents.CHAT.ODD.ODD_SUGGESTIONS_RECEIVED, { proposalId: defragProposal?.id });
      setProcessingMessage(false);

      if (defragProposal?.diffBlocks?.[0]?.diffs?.length) {
        handleOnDemandDefragRequest(defragProposal);
      } else {
        eventToast.error({
          operation: "DEFRAG",
          title: "Nothing available to move",
        });
      }
    }
  }, [data]);

  return { fetchOptimizationProposal, loading };
};

export const OptimizeWeek = () => {
  const [expanded, setExpanded] = useState(false);

  const { fetchOptimizationProposal, loading } = useFetchOptimizationProposal();
  const [preferencesValues, setPreferencesValues] = useState(DefaultWeights);

  const onClick = () => {
    fetchOptimizationProposal(preferencesValues);
    setExpanded(false);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const updatePreference = (key: keyof typeof DefaultWeights, value: number) => {
    setPreferencesValues((prev) => ({ ...prev, [key]: value }));
  };

  const resetPreferences = () => {
    setPreferencesValues(DefaultWeights);
  };
  return (
    <div
      className={classNames(
        "cw-text-neutral cw-border-muted hover:cw-bg-neutral-hover active:cw-bg-neutral-hover cw-bg-default",
        "cw-text-12 cw-font-medium",
        "cw-rounded-md cw-w-full cw-mt-2 cw-mb-3",
        "focus-visible:cw-outline-2 focus-visible:cw-outline-offset-2",
        "cw-border cw-border-solid cw-transition-colors cw-ease-out cw-duration-200",
        "cw-leading-none cw-font-body cw-whitespace-nowrap",
        "cw-flex cw-flex-col cw-cursor-pointer cw-h-full",
        { "cw-bg-neutral-hover": expanded },
      )}
    >
      <div
        className="cw-flex cw-items-center cw-justify-between cw-px-2 cw-py-2"
        onClick={toggleExpanded}
      >
        <div className="cw-flex cw-items-center cw-gap-1.5">
          {optimizeOptionIcon}
          {loading ? "Optimizing..." : "Optimize my week"}
          {expanded && !loading && <KeyboardArrowUp className="cw-w-4 cw-h-4" />}
          {!expanded && !loading && <KeyboardArrowDown className="cw-w-4 cw-h-4" />}
        </div>
        <div className="cw-font-semibold cw-text-subtle cw-text-[9px]">ALPHA</div>
      </div>
      {expanded && (
        <div className="cw-flex cw-flex-col cw-gap-y-3 cw-px-2 cw-pb-2">
          <PreferencesTuner values={preferencesValues} onChange={updatePreference} />
          <div className="cw-flex cw-justify-between">
            <Button sentiment="neutral" variant="outlined" onClick={resetPreferences} size="mini">
              Reset
            </Button>
            <Button sentiment="positive" onClick={onClick} size="mini">
              Optimize
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
