import { MeetingReliefGoals } from "@clockwise/schema";
import { MeetingReliefGoalsQuery } from "../graphql/__generated__/MeetingReliefGoals.generated";
import { getCurrentOrg } from "../../../util/org.util";

export const parseMeetingReliefGoalsQuery = (data: MeetingReliefGoalsQuery | undefined) => {
  const org = getCurrentOrg(data?.viewer);
  const meetingReliefGoal = org?.smartHoldGoals.meetingRelief as MeetingReliefGoals | undefined;
  return meetingReliefGoal;
};

export default parseMeetingReliefGoalsQuery;
