import { useMutation } from "@apollo/client";
import { TeamMemberRole, TeamType } from "@clockwise/schema";
import { TrackingEvents } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useState } from "react";
import { IOrg, IOrgPerson } from "../../../../__schema__";
import { track } from "../../../../util/analytics.util";
import { TeamNameCreateInput } from "../../../team-name-create-input";
import { TeamSettingsSearch } from "../../../teams-settings/team-settings-search";
import { ButtonGroup, Header, SlideLayout, Text } from "../../shared-components";
import { setError } from "../../utils/setError";
import { FunnelType } from "../../WebOnboarding.util";
import { TeamCreateOnboardingDocument } from "./__generated__/TeamJoinCreateStep.generated";

type TeamCreateSlideProps = {
  showSecondaryButton: boolean;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton: () => void;
  orgHasOtherTeams: boolean;
  org: IOrg;
  currentPersonId: string;
  funnelType: FunnelType;
};

const EMPTY_ARRAY: [] = [];
const EMPTY_OBJECT = {};

export const TeamCreateSlide = ({
  showSecondaryButton,
  onClickPrimaryButton,
  onClickSecondaryButton,
  orgHasOtherTeams,
  org,
  currentPersonId,
  funnelType,
}: TeamCreateSlideProps) => {
  const [teamName, setTeamName] = useState("");
  const [teamMembers, setTeamMembers] = useState<{ [keyof: string]: { calendarId: string } }>({});

  const [createTeam, { loading: updating }] = useMutation(TeamCreateOnboardingDocument, {
    onError: (error) => {
      setError({
        error: error,
        message: "Failed to add user to team",
        showUserMessage: true,
      });
    },
    onCompleted: (res) => {
      const teamId = res.createTeam?.team.teamId;
      const teamMemberList = Object.keys(teamMembers).map(
        (personId) => teamMembers[personId].calendarId,
      );
      track(TrackingEvents.ONBOARDING.ONBOARDING_TEAM_CREATED, {
        nInvited: teamMemberList.length,
        teamName: teamName,
        teamId: teamId || "unknown",
        calendarIds: teamMemberList,
        funnelType,
      });
    },
  });

  const onChangeTeamName = (newTeamName: string, teamNameIsValid: boolean) => {
    if (teamNameIsValid) {
      setTeamName(newTeamName);
    } else {
      setTeamName("");
    }
  };

  const onCheckTeammate = (person: IOrgPerson, checked: boolean) => {
    const dupeOfMembers = { ...teamMembers };
    if (checked) {
      dupeOfMembers[person.personId] = { calendarId: person.primaryCalendarId };
    } else {
      delete dupeOfMembers[person.personId];
    }
    setTeamMembers(dupeOfMembers);
  };

  const onCreateSkip = () => {
    track(TrackingEvents.ONBOARDING.ONBOARDING_TEAM_CREATE_SKIPPED, { funnelType });
    onClickPrimaryButton();
  };

  const onCreateAndMoveForward = async () => {
    const teamMembersWithAssignedRole = Object.keys(teamMembers).map((personId) => ({
      personId,
      role: TeamMemberRole.Member,
    }));
    teamMembersWithAssignedRole.push({ personId: currentPersonId, role: TeamMemberRole.Member });
    await createTeam({
      variables: {
        input: {
          teamName,
          orgRelayId: org.id,
          teamType: TeamType.Other,
          teamMembers: teamMembersWithAssignedRole,
          setAsPrimary: true,
        },
      },
    });
    onClickPrimaryButton();
  };

  return (
    <SlideLayout>
      <Header>
        {orgHasOtherTeams ? "Create a new team" : "Let's get your teammates on board"}
      </Header>
      <Text>
        The best way to work together is for our schedules to do the same. Bring your team onboard
        so that Clockwise can make time for everyone.
      </Text>
      <div className="md:cw-w-[500px] cw-w-full cw-relative">
        <div className="cw-flex cw-flex-col cw-px-3">
          <div className="cw-body-base cw-font-bold">Team name</div>
          <TeamNameCreateInput orgId={org.id} onChange={onChangeTeamName} initialTeamName={""} />
          <div className="cw-body-base cw-font-bold cw-mt-5 cw-mb-[-14px]">Invite teammates</div>
        </div>
        <TeamSettingsSearch
          mode="create"
          containerClassName="cw-p-0"
          org={org}
          team={null}
          onCheck={onCheckTeammate}
          defaultMembers={EMPTY_ARRAY} // Empty because we dont have defaults in onboarding
          invitedMembers={EMPTY_OBJECT} // Empty because this is a new team
        />
      </div>
      <ButtonGroup
        shouldShowSkip
        shouldShowSecondary={showSecondaryButton}
        primaryLabel={"Create team"}
        onClickPrimary={onCreateAndMoveForward}
        onClickSecondary={onClickSecondaryButton}
        onSkip={onCreateSkip}
        disabled={updating}
        disablePrimary={!teamName}
      />
    </SlideLayout>
  );
};
