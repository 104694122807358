import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { QuestionType } from "@clockwise/schema";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useOrgId } from "../../hooks/useOrgId";
import { useUserCreatedTime } from "../../hooks/useUserCreatedTime";
import { UserSurveyResponsesDocument } from "../graphql/__generated__/UserSurveyResponses.generated";

export const is14DaysOrOlder = (createdTime: number | null, zone: string) => {
  if (isNil(createdTime)) return false;
  const createdTimeZoned = DateTime.fromMillis(createdTime, { zone }).toMillis();
  const now = DateTime.now().setZone(zone).toMillis();
  const fourteenDaysMillis = 14 * 24 * 60 * 60 * 1000;

  return now - createdTimeZoned >= fourteenDaysMillis;
};

export const useShowPMFSurvey = () => {
  const [hasUserDismissedPMFDialog] = useLocalStorage<boolean>(
    "Clockwise:PMFDialogDismissed",
    false,
  );
  const [isOnPMFExperiment] = useFeatureFlag("PMFSurvey");
  const [isOnNewUserExperiment] = useFeatureFlag("PMFSurveyNewUsers");
  const { orgId } = useOrgId();
  const { createdTime } = useUserCreatedTime();
  const zone = getRenderTimeZone();

  const user14DayOrOlderAndOnNewUserExperiment = useMemo(() => {
    return is14DaysOrOlder(createdTime, zone) && isOnNewUserExperiment;
  }, [isOnNewUserExperiment, createdTime, zone]);

  const { data } = useQuery(UserSurveyResponsesDocument, {
    variables: { orgId: orgId ?? "" },
    skip: !orgId,
    fetchPolicy: "network-only", // important - only want to fetch from network so we can show the survey immediately
  });

  const hasSubmittedSatisfactionSurvey = useMemo(() => {
    if (!data) return true;
    const surveyResultsErrorable = getValue(data.node, "Org")?.userSurveyResponseWrapperErrorable;
    if (surveyResultsErrorable?.__typename !== "UserSurveyResponseWrapper") return true; // don't show survey if there was an error fetching the responses
    return surveyResultsErrorable.userSurveyResponses.some(
      (survey) => survey.questionType === QuestionType.HowWouldYouFeelIfNoClockwise,
    );
  }, [data]);

  return (
    (isOnPMFExperiment || user14DayOrOlderAndOnNewUserExperiment) &&
    !hasSubmittedSatisfactionSurvey &&
    !hasUserDismissedPMFDialog
  );
};
