/**
 * @generated SignedSource<<88c3d38dca3ff5f4648ce93a3209eec9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdminPanelOverviewQuery$variables = {
  orgRelayId: string;
  maxDate?: string | null;
  minDate?: string | null;
};
export type AdminPanelOverviewQuery$data = {
  readonly org: {
    readonly id?: string;
    readonly orgOverview?: {
      readonly __typename: "GraphEntityError";
      readonly statusCode: number | null;
      readonly message: string | null;
    } | {
      readonly __typename: "OrgOverview";
      readonly userCount: ReadonlyArray<{
        readonly label: string;
        readonly value: number;
      }>;
      readonly scheduleAssistCount: ReadonlyArray<{
        readonly label: string;
        readonly value: number;
      }>;
      readonly scheduleAssistLimit: number;
      readonly focusTimeHoursCreatedCount: ReadonlyArray<{
        readonly label: string;
        readonly value: number;
      }>;
      readonly conflictsResolvedCount: ReadonlyArray<{
        readonly label: string;
        readonly value: number;
      }>;
      readonly numUsersByRole: ReadonlyArray<{
        readonly label: string;
        readonly value: number;
      }>;
      readonly numUsersByFunction: ReadonlyArray<{
        readonly label: string;
        readonly value: number;
      }>;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | null;
};
export type AdminPanelOverviewQuery = {
  variables: AdminPanelOverviewQuery$variables;
  response: AdminPanelOverviewQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "minDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orgRelayId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "orgRelayId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "maxDate",
      "variableName": "maxDate"
    },
    {
      "kind": "Variable",
      "name": "minDate",
      "variableName": "minDate"
    }
  ],
  "concreteType": null,
  "kind": "LinkedField",
  "name": "orgOverview",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statusCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "type": "GraphEntityError",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IntegerDataPoint",
          "kind": "LinkedField",
          "name": "userCount",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntegerDataPoint",
          "kind": "LinkedField",
          "name": "scheduleAssistCount",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scheduleAssistLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntegerDataPoint",
          "kind": "LinkedField",
          "name": "focusTimeHoursCreatedCount",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntegerDataPoint",
          "kind": "LinkedField",
          "name": "conflictsResolvedCount",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntegerDataPoint",
          "kind": "LinkedField",
          "name": "numUsersByRole",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntegerDataPoint",
          "kind": "LinkedField",
          "name": "numUsersByFunction",
          "plural": true,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "type": "OrgOverview",
      "abstractKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminPanelOverviewQuery",
    "selections": [
      {
        "alias": "org",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v7/*: any*/)
            ],
            "type": "Org",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdminPanelOverviewQuery",
    "selections": [
      {
        "alias": "org",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/)
            ],
            "type": "Org",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ebd19e4b9cd909cb5b198a5b341db15",
    "id": null,
    "metadata": {},
    "name": "AdminPanelOverviewQuery",
    "operationKind": "query",
    "text": "query AdminPanelOverviewQuery(\n  $orgRelayId: ID!\n  $maxDate: String\n  $minDate: String\n) {\n  org: node(id: $orgRelayId) {\n    __typename\n    ... on Org {\n      id\n      orgOverview(maxDate: $maxDate, minDate: $minDate) {\n        __typename\n        ... on GraphEntityError {\n          statusCode\n          message\n        }\n        ... on OrgOverview {\n          userCount {\n            label\n            value\n          }\n          scheduleAssistCount {\n            label\n            value\n          }\n          scheduleAssistLimit\n          focusTimeHoursCreatedCount {\n            label\n            value\n          }\n          conflictsResolvedCount {\n            label\n            value\n          }\n          numUsersByRole {\n            label\n            value\n          }\n          numUsersByFunction {\n            label\n            value\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d3955878efa25938ca957c0016583082";

export default node;
