import confetti from "canvas-confetti";
import { useCallback, useEffect, useRef, useState } from "react";

const ANIMATION_DURATION = 5500;
/**
 * Display confetti from the left and right edges of the screen.
 *
 * @param durationMs How long the animation should last. Defaults to 5.5s
 * @returns Method to kick off confetti launch
 */
export const useLaunchConfetti = (durationMs: number = ANIMATION_DURATION) => {
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const animationRequestRef = useRef<number | undefined>();

  const animate = useCallback(
    (timestamp: number) => {
      if (!showConfetti) {
        if (animationRequestRef.current) {
          cancelAnimationFrame(animationRequestRef.current);
        }
        return;
      }

      // launch a few from the left edge
      void confetti({
        particleCount: 20,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        disableForReducedMotion: true,
      });

      // and launch a few from the right edge
      void confetti({
        particleCount: 20,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        disableForReducedMotion: true,
      });

      if (timestamp < durationMs) {
        animationRequestRef.current = requestAnimationFrame(animate);
      }
    },
    [showConfetti, durationMs],
  );

  useEffect(() => {
    animationRequestRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRequestRef.current) {
        cancelAnimationFrame(animationRequestRef.current);
      }
    };
  }, [showConfetti, animate]);

  const launchConfetti = useCallback(() => {
    setShowConfetti(true);
  }, [setShowConfetti]);

  return launchConfetti;
};
