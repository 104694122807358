import React, { RefObject } from "react";

export const calendarDimensionsContextDefault = {
  calendarWeekWidthInPx: 0,
  calendarWeekHeightInPx: 0,
  numOfDaysShown: 0,
  dayWidthInPx: 0,
  scrollContainerRef: null,
  scrollTopOfScrollContainerBeforeDragging: 0,
};
export const CalendarDimensionsContext = React.createContext<{
  calendarWeekWidthInPx: number;
  calendarWeekHeightInPx: number;
  numOfDaysShown: number;
  dayWidthInPx: number;
  scrollContainerRef: RefObject<HTMLDivElement> | null;
  scrollTopOfScrollContainerBeforeDragging: number;
}>(calendarDimensionsContextDefault);
