import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { getFullName } from "@clockwise/web-commons/src/util/profile.util";
import { Skeleton } from "@material-ui/lab";
import { noop } from "lodash";
import pluralize from "pluralize";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCalendarIds, removeCalendarIds } from "../../../state/actions/multi-calendar.actions";
import { IReduxState } from "../../../state/reducers/root.reducer";
import { useIsLayersDisabledWithReason } from "../../chat/ai-chat/components/multi-calendar/calendar-layers.util";
import { ToggleCalendarLayers } from "../../chat/ai-chat/components/multi-calendar/ToggleCalendarLayers";
import { EventElementsWrap } from "../atoms/EventElementsWrap";
import { CannotViewGuesListMessage } from "../molecules/ECAttendeesV2/CannotViewGuesListMessage";
import { ECAttendeeList } from "../molecules/ECAttendeesV2/ECAttendeeList";
import { ECAttendeeSelect } from "../molecules/ECAttendeesV2/ECAttendeeSelect";
import { getListOfNonRemovableAttendeeIds } from "../molecules/ECAttendeesV2/ECAttendeeWrapper";
import { OmittedAttendeesMessage } from "../molecules/ECAttendeesV2/OmittedAttendeesMessage";
import { EventCardAttendee } from "../types";
import { Attendee, toEventCardAttendee } from "./utils/attendee";

type Permissions = {
  canModify: boolean;
  canInviteOthers: boolean;
  canSeeOtherGuests: boolean;
};

type AttendeePanelProps = {
  attendees: Attendee[];
  permissions: Permissions;
  readOnly: boolean;
  attendeesOmitted: boolean;
  loading?: boolean;
  onRemoveAttendee: (email: string) => void;
  onAddAttendee: (attendee: Attendee) => void;
  onUpdateAttendeeOptionality: (email: string, isOptional: boolean) => void;
};

const toAttendee = (eventCardAttendee: EventCardAttendee): Attendee => {
  const displayName =
    eventCardAttendee.profile?.givenName && eventCardAttendee.profile?.familyName
      ? getFullName(eventCardAttendee.profile)
      : eventCardAttendee.primaryCalendar;

  return {
    id: eventCardAttendee.id,
    isOptional: !!eventCardAttendee.isOptional,
    user: eventCardAttendee.userId ? { id: eventCardAttendee.userId } : null,
    isOrganizer: eventCardAttendee.isOrganizer ?? null,
    responseStatus: eventCardAttendee.attendingStatus ?? null,
    person: {
      id: eventCardAttendee.primaryCalendar,
      email: eventCardAttendee.primaryEmail,
      displayName,
      givenName: eventCardAttendee.profile?.givenName ?? null,
      familyName: eventCardAttendee.profile?.familyName ?? null,
      externalImageUrl: eventCardAttendee.profile?.externalImageUrl ?? null,
      isMe: eventCardAttendee.isYou,
    },
  };
};

const AttendeePanelSkeleton = () => {
  return (
    <EventElementsWrap name="attendee">
      <div className="cw-flex cw-flex-col cw-gap-2 cw-w-full">
        <div className="cw-flex cw-items-center cw-justify-between">
          <Skeleton variant="text" width={80} height={20} />
          <ToggleCalendarLayers
            onClick={noop}
            disabled={true}
            isToggledOn={false}
            reasonForDisabled={null}
          />
        </div>
        <ECAttendeeSelect disabled={true} currentAttendees={[]} onAddAttendee={noop} />
        <ECAttendeeList
          attendees={[]}
          nonEditableAttendeeIds={new Set()}
          removeAttendee={noop}
          changeOptionalityOfAttendee={noop}
          readOnly={true}
        />
      </div>
    </EventElementsWrap>
  );
};

export const AttendeePanel = ({
  attendees,
  attendeesOmitted,
  readOnly,
  loading = false,
  permissions,
  onRemoveAttendee,
  onAddAttendee,
  onUpdateAttendeeOptionality,
}: AttendeePanelProps) => {
  const ecosystem = useEcosystem();
  const mappedAttendees = attendees.map((a) => toEventCardAttendee(a));
  const attendeeCalendarIds = attendees.map((a) => a.person.id);
  const calendarIdsToFullyShow = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsToFullyShow,
  );
  const isToggledOn = attendeeCalendarIds.every((id) => calendarIdsToFullyShow.includes(id));
  const dispatch = useDispatch();
  const { disabled, reason: reasonForDisabled } = useIsLayersDisabledWithReason(
    attendeeCalendarIds,
  );

  const attendeesLoading = loading;

  const nonEditableAttendeeIds = getListOfNonRemovableAttendeeIds(
    permissions.canInviteOthers,
    permissions.canModify,
    mappedAttendees,
    ecosystem,
  );

  const handleToggleCalendarLayers = () => {
    if (isToggledOn) {
      dispatch(removeCalendarIds(attendeeCalendarIds));
    } else {
      dispatch(addCalendarIds(attendeeCalendarIds));
    }
  };

  const handleRemoveAttendee = (email: string) => {
    onRemoveAttendee?.(email);
    dispatch(removeCalendarIds([email]));
  };

  const handleUpdateAttendeeOptionality = (attendee: EventCardAttendee, isOptional: boolean) => {
    onUpdateAttendeeOptionality?.(attendee.primaryEmail, isOptional);
  };

  const handleAddAttendee = (attendee: EventCardAttendee) => {
    onAddAttendee?.(toAttendee(attendee));
    dispatch(addCalendarIds([attendee.primaryCalendar]));
  };

  const eventAttendeesRef = React.useRef(attendees);
  useEffect(() => {
    eventAttendeesRef.current = attendees;
  }, [attendees]);

  if (attendeesLoading) {
    return <AttendeePanelSkeleton />;
  }

  return (
    <EventElementsWrap name="attendee">
      <div className="cw-flex cw-flex-col cw-gap-2 cw-w-full">
        {attendees.length > 0 && (
          <div className="cw-flex cw-items-center cw-justify-between">
            <div className="cw-text-subtle cw-text-xs cw-font-medium">
              {permissions.canSeeOtherGuests
                ? `${pluralize("attendee", attendees.length, true)}`
                : "Attendees"}
            </div>
            <ToggleCalendarLayers
              onClick={handleToggleCalendarLayers}
              disabled={attendeeCalendarIds.length <= 1 || disabled}
              isToggledOn={isToggledOn}
              reasonForDisabled={reasonForDisabled}
            />
          </div>
        )}
        <ECAttendeeSelect
          disabled={readOnly}
          currentAttendees={attendees.map(toEventCardAttendee)}
          onAddAttendee={handleAddAttendee}
        />
        {!permissions.canSeeOtherGuests && <CannotViewGuesListMessage />}
        {/* TODO: disallow removal of attendees if user lacks appropriate permissions
        For more details, look at the implementation in ECAttendeeWrapper.tsx
        https://linear.app/getclockwise/issue/A-698/disallow-removal-of-non-removable-attendee-ids
        */}
        <ECAttendeeList
          attendees={attendees.map(toEventCardAttendee)}
          nonEditableAttendeeIds={nonEditableAttendeeIds}
          removeAttendee={handleRemoveAttendee}
          changeOptionalityOfAttendee={handleUpdateAttendeeOptionality}
          readOnly={readOnly}
        />
        {attendeesOmitted && <OmittedAttendeesMessage />}
      </div>
    </EventElementsWrap>
  );
};
