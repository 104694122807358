import { Button } from "@clockwise/design-system";
import { SvgIconComponent } from "@clockwise/icons";
import { NuxPopover } from "@clockwise/web-commons/src/ui/nux-popover/NuxPopover";
import { ArrowForward } from "@material-ui/icons";
import React, { ReactNode } from "react";
import calendarPopover from "../../components/app-nav/assets/calendarPopover.png";
import flexPopover from "../../components/app-nav/assets/flexPopover.png";
import linksPopover from "../../components/app-nav/assets/linksPopover.png";
import { useNuxPopoverStates } from "./useNuxPopoverStates";
import { NavEducationStep } from "./useNuxPopoverStates.util";

type PopoverMeta = {
  currentStep: number;
  allStepCount: number;
  title: string;
  body: string;
  img: string;
  actionButtonLabel?: string;
  actionButtonIcon?: SvgIconComponent;
};

const popoverMap: Partial<Record<NavEducationStep, PopoverMeta>> = {
  FlexMeetingsPage: {
    currentStep: 1,
    allStepCount: 4,
    title: "Flexible meetings",
    body: "Let Clockwise proactively reschedule meetings to keep your calendar clean.",
    img: flexPopover,
    actionButtonLabel: "Next",
    actionButtonIcon: ArrowForward,
  },
  SchedulingLinksPage: {
    currentStep: 2,
    allStepCount: 4,
    title: "Scheduling Links",
    body: "Create a link to share your availability without the back and forth.",
    img: linksPopover,
    actionButtonLabel: "Next",
    actionButtonIcon: ArrowForward,
  },
  CalendarPage: {
    currentStep: 3,
    allStepCount: 4,
    title: "Powerful scheduling",
    body: "An AI-powered calendar that helps you schedule meetings hassle-free.",
    img: calendarPopover,
    actionButtonLabel: "Next",
    actionButtonIcon: ArrowForward,
  },
  SuperpowerChecklist: {
    currentStep: 4,
    allStepCount: 4,
    title: "Meet your new assistant!",
    body: "Make the most of Clockwise by trying out the basics.",
    img: "",
    actionButtonLabel: "Let's do it",
  },
};

export const NuxPopoverContent = ({
  title,
  body,
  img,
  currentStep,
  allStepCount,
  actionButtonLabel,
  actionButtonIcon,
}: PopoverMeta) => {
  const { setPopoverComplete } = useNuxPopoverStates();
  return (
    <div className="cw-flex cw-flex-col cw-gap-4 cw-w-[176px] cw-py-2 cw-px-1">
      {img && <img src={img} />}
      <div>
        <div className="cw-font-semibold cw-body-sm cw-text-12">{title}</div>
        <div className="cw-text-muted cw-body-sm cw-text-12">{body}</div>
      </div>
      <div className="cw-flex cw-justify-between cw-items-center">
        <div className="cw-text-subtle cw-body-sm cw-text-10 cw-font-medium">
          {currentStep} of {allStepCount}
        </div>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setPopoverComplete();
          }}
          sentiment="accent"
          size="mini"
          endIcon={actionButtonIcon}
          variant="solid"
        >
          {actionButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export const NuxPopoverWrapper = ({
  type,
  children,
}: {
  type: NavEducationStep;
  children: ReactNode;
}) => {
  const content = type && popoverMap[type];

  return content ? (
    <NuxPopover
      addOffset={type !== NavEducationStep.SuperpowerChecklist}
      content={content && <NuxPopoverContent {...content} />}
    >
      {children}
    </NuxPopover>
  ) : (
    children
  );
};
