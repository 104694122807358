import { useReadCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime, Interval } from "luxon";
import React, { useEffect } from "react";
import { getEarliestDiffStartAndEnd } from "../../chat/ai-chat/utils/getDiffStart";
import { useActiveDiffSummaries } from "../../chat/hooks/useActiveDiffSummary";
import { usePlannerContext } from "../Context";

import { floor, isEmpty } from "lodash";
import { calculateWorkingHoursFitsWithinCalendarView } from "./utils/calculateWorkingHoursFitsWithinCalendarView";
import { dateToDayOfWeek } from "./utils/dateToDayOfWeek";
import { parseWorkingHourBounds } from "./utils/parseWorkingHourBounds";

type CalendarScrollSyncProps = {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  calendarContainerRef: React.RefObject<HTMLDivElement>;
  scrollBehavior: ScrollBehavior;
};

export const CalendarScrollSync = ({
  scrollContainerRef,
  calendarContainerRef,
  scrollBehavior,
}: CalendarScrollSyncProps) => {
  const { workingHours } = usePlannerContext();
  const { activeDiffSummaries } = useActiveDiffSummaries();
  const { visibleDates } = useReadCalendar();
  const visibleDays = visibleDates.map((date) => DateTime.fromFormat(date, "yyyy-MM-dd"));
  const visibleDaysOfWeek = visibleDays.map(dateToDayOfWeek);
  const daySettingsForVisibleDaysOfWeek = (workingHours?.daySettings || []).filter((daySetting) => {
    if (!daySetting || !daySetting.day) {
      return false;
    }

    return visibleDaysOfWeek.includes(daySetting.day);
  });
  const hasScrolledToCenterOfWorkingHoursRef = React.useRef(false);

  const workingHourBoundsForVisibleDays = parseWorkingHourBounds(daySettingsForVisibleDaysOfWeek);
  const workingHourBounds = workingHourBoundsForVisibleDays;
  const [startWorkingHours, endWorkingHours] = workingHourBounds || [0, 24];
  // Math note: the logic is eaiser to understand if we pad the working hours by 30 minutes on each side here as opposed to later on
  const startWorkingHoursWith30Padding = startWorkingHours - 0.5;
  const endWorkingHoursWith30Padding = endWorkingHours + 0.5;
  const endWorkingHoursWith30TopPadding = endWorkingHours - 0.5;
  // This is now for both /app/ai and /app/ai-scheduler
  // The goal is to have as much stay in view as possible,
  // with the theory that working hours are a good indication of what people care about
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const calendarContainer = calendarContainerRef.current;

    if (
      !scrollContainer ||
      !workingHourBounds ||
      !calendarContainer ||
      hasScrolledToCenterOfWorkingHoursRef.current
    ) {
      return;
    }
    // UX Note: Once this is possible execute, we only want to do this once
    // This is because when we change calendar view from week to day
    // users are probably 'drilling down' into their calendar to get more detail
    // We want to keep the same scroll position for them
    hasScrolledToCenterOfWorkingHoursRef.current = true;

    const centerOfWorkingHours =
      (startWorkingHoursWith30Padding + endWorkingHoursWith30Padding) / 2;

    const scrollFromTop =
      calendarContainer.clientHeight * (centerOfWorkingHours / 24) - // This part scrolls the centerOfWorkHours to the top of the screen
      scrollContainerRef.current.offsetHeight / 2; // This part adjusts so the current time is in the middle of the screen

    scrollContainerRef.current.scrollTo({
      top: scrollFromTop,
      behavior: scrollBehavior,
    });
  }, [startWorkingHoursWith30Padding, endWorkingHoursWith30Padding]); // eslint-disable-line react-hooks/exhaustive-deps

  // When there are active diffs
  // Scroll to the earliest start time in a conversation, if there is one
  useEffect(() => {
    if (isEmpty(activeDiffSummaries)) return;

    const scrollContainer = scrollContainerRef.current;
    const calendarContainer = calendarContainerRef.current;
    const { firstStart, firstEnd } = getEarliestDiffStartAndEnd(activeDiffSummaries);
    const diffStart = firstStart.setZone(getRenderTimeZone());
    const diffEnd = firstEnd.setZone(getRenderTimeZone());
    const isDiffDateVisible = visibleDates.includes(diffStart.toISODate());

    // Need all the prerequisites to scroll
    if (
      !scrollContainer ||
      !calendarContainer ||
      !isDiffDateVisible ||
      !diffStart.isValid ||
      !diffEnd.isValid
    ) {
      return;
    }

    const workingHoursFitsWithinCalendarView = calculateWorkingHoursFitsWithinCalendarView(
      workingHourBounds,
      scrollContainer.clientHeight,
      calendarContainer.clientHeight,
    );
    const diffTimeStartHoursAsFloat = diffStart.hour + diffStart.minute / 60;
    const diffTimeEndHoursAsFloat = diffEnd.hour + diffEnd.minute / 60;
    const minutesInView = (scrollContainer.clientHeight / calendarContainer.clientHeight) * 24 * 60;

    // If the diff is before the start of the working hours
    if (diffTimeStartHoursAsFloat < startWorkingHours) {
      const scrollToTopHour = diffTimeStartHoursAsFloat - 0.5; // Add atleast 30 minutes of top padding

      const scrollFromTop = calendarContainer.scrollHeight * (scrollToTopHour / 24);

      scrollContainerRef.current.scrollTo({
        top: scrollFromTop,
        behavior: scrollBehavior,
      });
      return;
    }

    // If the diff is after the end of the working hours
    if (diffTimeEndHoursAsFloat > endWorkingHours) {
      const lowerBoundOfViewHours = diffTimeEndHoursAsFloat + 0.5; // Add atleast 30 minutes of top padding
      const dateTimeLowerBoundOfView = DateTime.fromISO(diffEnd.toISO()).set({
        hour: floor(lowerBoundOfViewHours),
        minute: (lowerBoundOfViewHours - floor(lowerBoundOfViewHours)) * 60,
      });
      const dateTimeUpperBoundOfView = dateTimeLowerBoundOfView.minus({
        minutes: minutesInView,
      });
      const timeToScrollToAsFloat =
        dateTimeUpperBoundOfView.hour + dateTimeUpperBoundOfView.minute / 60;

      const scrollFromTop = calendarContainer.scrollHeight * (timeToScrollToAsFloat / 24);

      scrollContainerRef.current.scrollTo({
        top: scrollFromTop,
        behavior: scrollBehavior,
      });
      return;
    }

    if (workingHoursFitsWithinCalendarView) {
      // Note that we get to assume that the diff will be in the working hours
      // Because of the negation of the previous two if statements
      // `(diffTimeStartHoursAsFloat < startWorkingHours)` and `(diffTimeEndHoursAsFloat > endWorkingHours)`
      // Thus, we can just scroll to the center of the working hours and it'll be in view

      const centerOfWorkingHours =
        (startWorkingHoursWith30Padding + endWorkingHoursWith30Padding) / 2;

      const scrollFromTop =
        calendarContainer.clientHeight * (centerOfWorkingHours / 24) - // This part scrolls the centerOfWorkHours to the top of the screen
        scrollContainerRef.current.offsetHeight / 2; // This part adjusts so the current time is in the middle of the screen

      scrollContainerRef.current.scrollTo({
        top: scrollFromTop,
        behavior: scrollBehavior,
      });

      return;
    }

    if (!workingHoursFitsWithinCalendarView) {
      // If the working hours don't fit within the view, we handle 3 cases
      // 1. The diff is in the upper part of the working hours
      // 2. The diff is in the lower part of the working hours
      // 3. The diff is in neither the upper or lower part of the working hours
      // In case 1. we will show the start of the working hours with 30 minutes padding and the diff
      // In case 2. we will show the end of the working hours with 30 minutes padding and the diff
      // In case 3. we will show the diff's start in the middle of the screen

      const dateTimeStartOfUpperWorkHoursBound = DateTime.fromISO(diffStart.toISO()).set({
        hour: startWorkingHoursWith30Padding,
      });

      const dateTimeEndOfUpperWorkHoursBound = dateTimeStartOfUpperWorkHoursBound.plus({
        minutes: minutesInView,
      });

      const intervalForUpperWorkHoursBound = Interval.fromDateTimes(
        dateTimeStartOfUpperWorkHoursBound,
        dateTimeEndOfUpperWorkHoursBound,
      );

      const dateTimeEndOfLowerWorkHoursBound = DateTime.fromISO(diffStart.toISO()).set({
        hour: endWorkingHoursWith30TopPadding,
      });
      const dateTimeStartOfLowerWorkHoursBound = dateTimeEndOfLowerWorkHoursBound.minus({
        minutes: minutesInView,
      });

      const intervalForLowerWorkHoursBound = Interval.fromDateTimes(
        dateTimeStartOfLowerWorkHoursBound,
        dateTimeEndOfLowerWorkHoursBound,
      );

      // When it's in the `Upper Bound Work Hours` Hours (not upper half, this is the upper view)
      if (
        intervalForUpperWorkHoursBound.contains(diffStart) &&
        intervalForUpperWorkHoursBound.contains(diffEnd)
      ) {
        // scroll to upper work bound with 30 minutes padding
        const scrollFromTop =
          calendarContainer.scrollHeight * (startWorkingHoursWith30Padding / 24);

        scrollContainerRef.current.scrollTo({
          top: scrollFromTop,
          behavior: scrollBehavior,
        });

        return;
      }

      // When it's in the `Lower Bound Work Hours` Hours (not lower half, this is the lower view)
      if (
        intervalForLowerWorkHoursBound.contains(diffStart) &&
        intervalForLowerWorkHoursBound.contains(diffEnd)
      ) {
        // scroll to lower work bound with 30 minutes padding
        const scrollFromTop =
          calendarContainer.scrollHeight * (endWorkingHoursWith30TopPadding / 24);

        scrollContainerRef.current.scrollTo({
          top: scrollFromTop,
          behavior: scrollBehavior,
        });

        return;
      }

      // the diff is not in view of `Upper Bound Work Hours` or `Lower Bound Work Hours`
      // so we need to scroll the diff's start to the center of the screen
      const scrollFromTop =
        calendarContainer.scrollHeight * (diffTimeStartHoursAsFloat / 24) -
        scrollContainer.clientHeight / 2;
      scrollContainerRef.current.scrollTo({
        top: scrollFromTop,
        behavior: scrollBehavior,
      });
    }
  }, [activeDiffSummaries, visibleDates, scrollContainerRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
