import { NotificationStrategy, ScheduleAs, TravelTimeGoals } from "@clockwise/schema";

import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { TravelTimeSettings } from "../types";

export const defaultSettings: TravelTimeSettings = {
  id: "",
  address: null,
  enabled: false,
  notificationStrategy: NotificationStrategy.NotifyInGCal,
  scheduleAs: ScheduleAs.Busy,
};

export const transformTravelTimeGoalsToSettings = (travelTimeGoal: TravelTimeGoals | undefined) => {
  const settings = { ...defaultSettings };

  if (travelTimeGoal) {
    const { id, enabled, remoteHoldSettings, userAddress } = travelTimeGoal;
    const address = getValue(userAddress, "Address");

    if (id) settings.id = id;
    settings.enabled = !!enabled;

    if (enabled) settings.enabled = enabled;

    if (address) settings.address = address.rawAddress;

    if (remoteHoldSettings) {
      const { notificationStrategy, scheduleAs } = remoteHoldSettings;
      if (notificationStrategy) settings.notificationStrategy = notificationStrategy;
      if (scheduleAs) settings.scheduleAs = scheduleAs;
    }
  }

  return settings;
};

export default transformTravelTimeGoalsToSettings;
