import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { Select, SelectOption } from "@clockwise/design-system";
import { getRelativeInterval } from "@clockwise/web-commons/src/util/date.util";
import { DateTime, Interval } from "luxon";
import React from "react";
import {
  getOptionFromSearchTimeRanges,
  getRelativeTimeSpecFromOption,
  getSearchTimeRangeOptions,
} from "./options";
import { Option } from "./types";

export const TimeSuggestionsSearchTimeRangePicker = ({
  anchorDate,
  searchTimeRanges,
  onUpdateSearchTimeRanges,
}: {
  anchorDate: DateTime;
  searchTimeRanges: Interval[];
  onUpdateSearchTimeRanges: (intervals: Interval[]) => void;
}) => {
  const [isOnSearchTimeRanges] = useFeatureFlag("SearchTimeRanges");
  const [selectedOption, setSelectedOption] = React.useState<Option | undefined>(undefined);
  const searchTimeRangeOptions = React.useMemo(() => getSearchTimeRangeOptions(anchorDate), [
    anchorDate,
  ]);

  React.useEffect(() => {
    const option =
      searchTimeRanges.length > 0
        ? getOptionFromSearchTimeRanges(searchTimeRanges, anchorDate)
        : undefined;
    setSelectedOption(option);
  }, [searchTimeRanges, anchorDate]);

  const showCustomOption = selectedOption?.value === "custom";

  const onSelect = (value: Option["value"]) => {
    if (value === "custom") {
      return;
    }

    const option = searchTimeRangeOptions.find((option) => option.value === value);
    const relativeTimeSpec = getRelativeTimeSpecFromOption(option);

    if (!relativeTimeSpec) {
      return;
    }

    setSelectedOption(option);
    onUpdateSearchTimeRanges(getRelativeInterval(anchorDate, relativeTimeSpec));
  };

  if (!isOnSearchTimeRanges) {
    return (
      <Select size="small" variant="default" value={undefined} onChange={onSelect} disabled>
        <SelectOption value="this-week" disabled>
          {/* NB: This is the current BE default search time range */}
          This week
        </SelectOption>
      </Select>
    );
  }

  return (
    <Select size="small" variant="default" value={selectedOption?.value} onChange={onSelect}>
      {searchTimeRanges.length === 0 && (
        <SelectOption key="select-time-range" value="select-time-range" disabled>
          Select time range
        </SelectOption>
      )}
      {searchTimeRangeOptions.map((option) => {
        return (
          <SelectOption key={option.label} value={option.value}>
            {option.label}
          </SelectOption>
        );
      })}
      {showCustomOption && (
        <SelectOption key="custom" value="custom" disabled>
          Custom
        </SelectOption>
      )}
    </Select>
  );
};
