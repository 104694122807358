//////////////////
// IMPORTS
//////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";

// libraries
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import React from "react";
import { QueryRenderer, ReadyState } from "react-relay";

// web-settings-team-no-meeting-day-renderer imports
import { NoMeetingDaySetting } from "./NoMeetingDaySetting";
import { query } from "./WebSettingsTeamNoMeetingDayRelay.gql";

// components
import { WebSettingsContainer } from "#webapp/src/components/web-settings/web-settings-container";
import { PaywallBannerUpgradeCta } from "@clockwise/web-commons/src/ui/paywall-banner-upgrade-cta";
import { TrialProBadgeWithTooltip } from "@clockwise/web-commons/src/ui/trial-pro-badge-with-tooltip";
// util
import { page, PageEvents } from "#webapp/src/util/analytics.util";
import { handleReadyStateError } from "#webapp/src/util/relay.util";
import { maybeGetPrimaryTeamFromOrg } from "#webapp/src/util/team.util";
// state
import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
// material-ui
import { Divider, Link } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { TrialProBadgeType } from "@clockwise/web-commons/src/ui/trial-pro-badge-with-tooltip";

export interface IRendererProps {
  orgRelayId: string;
  daysLeftInTrial: number | null;
  tooltipType?: TrialProBadgeType;
  canNMDBeEnabled: boolean;
  showJoinExistingPlan: boolean;
}

export interface IRendererState {
  key: number;
  loading: boolean;
}

//////////////////
// COMPONENT
//////////////////
export class WebSettingsTeamNoMeetingDayRelay extends React.Component<
  IRendererProps,
  IRendererState
> {
  // ~-~-~-~-~-~-~-
  // Setup
  // ~-~-~-~-~-~-~-
  private shouldForce = true;

  private cachedViewer?: ISchema.IViewer;
  private cachedOrg?: ISchema.IOrg;

  constructor(props: IRendererProps) {
    super(props);
    this.state = {
      key: 0,
      loading: false,
    };
  }

  // ~-~-~-~-~-~-~-
  // Life-Cycle
  // ~-~-~-~-~-~-~-
  public componentDidMount() {
    page(PageEvents.WEB_SETTINGS_TEAM_NO_MEETING_DAY);
  }

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  // private refetch = () => {
  //   this.shouldForce = true;
  //   this.setState({ key: this.state.key + 1 });
  // };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  public render() {
    const environment = getCurrentEnvironment();

    return (
      <QueryRenderer
        key={this.state.key}
        environment={environment}
        query={query}
        variables={{
          orgRelayId: this.props.orgRelayId,
        }}
        cacheConfig={{ force: this.shouldForce }}
        render={(readyState: ReadyState) => {
          // check for error or show a loader for first load
          if (readyState.error) {
            handleReadyStateError(readyState.error, "WebSettingsTeamNoMeetingDayRelay");
            return null;
          }

          const freshViewer: ISchema.IViewer = readyState.props && readyState.props.viewer;
          const freshOrg: ISchema.IOrg = readyState.props && readyState.props.org;

          if (freshViewer) {
            this.cachedViewer = freshViewer;
          }

          if (freshOrg) {
            this.cachedOrg = freshOrg;
          }

          if (!this.cachedViewer || !this.cachedOrg || this.state.loading) {
            return (
              <Loader
                className="cw-w-full cw-mt-2 cw-flex cw-flex-row cw-items-center cw-justify-center"
                sentiment="positive"
              />
            );
          }

          this.shouldForce = false;

          const maybePrimaryTeam = maybeGetPrimaryTeamFromOrg(this.cachedOrg?.primaryTeam);
          const userTeams =
            this.cachedOrg.userTeams.__typename === "TeamList"
              ? [...this.cachedOrg.userTeams.list]
              : [];
          const sortedTeams = userTeams.sort((a, b) => {
            return a.teamId === maybePrimaryTeam?.teamId
              ? -1
              : b.teamId === maybePrimaryTeam?.teamId
              ? 1
              : a.teamName.localeCompare(b.teamName);
          });

          return (
            <WebSettingsContainer>
              <div className="cw-flex cw-gap-2 cw-m-0 cw-mb-4 cw-items-center">
                <h1 className="cw-heading-3xl"> Team no-meeting day</h1>
                <TrialProBadgeWithTooltip
                  tooltipType={this.props.tooltipType}
                  showToolTip={true}
                  daysLeftOnTrial={this.props.daysLeftInTrial || undefined}
                  badgeText="Teams"
                  width={400}
                  colorOption="green"
                />
              </div>
              <div className="cw-mb-11">
                <h6 className="cw-body-lg cw-m-0 cw-text-muted">
                  Clockwise will update your availability and avoid scheduling meetings on your
                  team's no-meeting day.{" "}
                  <Link
                    href="https://getclockwise.helpscoutdocs.com/article/44-team-no-meeting-day"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </Link>
                  .
                </h6>
                {!this.props.canNMDBeEnabled && (
                  <PaywallBannerUpgradeCta
                    bannerType="NMDLocked"
                    showJoinExistingPlan={this.props.showJoinExistingPlan}
                    pricingTracking={() =>
                      track(TrackingEvents.PAYWALLS.M2_TEAM_SETTINGS_PRICING_CLICKED)
                    }
                    billingTracking={() =>
                      track(TrackingEvents.PAYWALLS.M2_TEAM_SETTINGS_JOIN_PLAN_CLICKED)
                    }
                  />
                )}
              </div>

              {sortedTeams.map((team, i) => {
                return (
                  <div key={`team-nmd-setting-${team.teamId}`}>
                    <NoMeetingDaySetting
                      canNMDBeEnabled={this.props.canNMDBeEnabled}
                      org={this.cachedOrg as ISchema.IOrg}
                      team={team}
                    />
                    {i !== sortedTeams.length - 1 && <Divider spacing={64} />}
                  </div>
                );
              })}
            </WebSettingsContainer>
          );
        }}
      />
    );
  }
}
