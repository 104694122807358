import { Button, ButtonSet, Tooltip, Typography } from "@clockwise/design-system";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import toast from "react-hot-toast";
import * as ISchema from "../../../__schema__";
import { deleteTeam } from "../../../mutations";
import { getCurrentEnvironment } from "../../../state/relay-environment";
import { TrackingEvents, track } from "../../../util/analytics.util";
import { logger } from "../../../util/logger.util";
import { isTeamInPaidSubscription } from "../../../util/pricing.util";
import {
  TeamSections,
  TeamsSections,
  WebSettingsContext,
  WebSettingsSections,
  createSettingsViewId,
} from "../web-settings";
interface TeamDeleteProps {
  org: ISchema.IOrg;
  team: ISchema.ITeam;
}

enum DeleteStatus {
  None = "None",
  Deleting = "Deleting",
  Deleted = "Deleted",
}

export const TeamDelete = (props: TeamDeleteProps) => {
  const { onChangeSettingsView, webSettingsRefetch } = React.useContext(WebSettingsContext);

  const [deleteStatus, setDeleteStatus] = React.useState<DeleteStatus>(DeleteStatus.None);
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const onDeleteTeamButtonClick = () => {
    if (isTeamInPaidSubscription(props.org, props.team)) {
      return;
    }

    setShowConfirmation(true);
  };

  const onDeleteTeam = () => {
    const { org, team } = props;

    if (isTeamInPaidSubscription(org, team)) {
      return;
    }

    setDeleteStatus(DeleteStatus.Deleting);

    deleteTeam(
      getCurrentEnvironment(),
      { orgRelayId: org.id, teamId: team.teamId },
      () => {
        toast.success("Deleted team");
        setDeleteStatus(DeleteStatus.Deleted);
        webSettingsRefetch();
        onChangeSettingsView(createSettingsViewId(WebSettingsSections.Teams, TeamsSections.Team));
      },
      () => {
        toast.error("Failed to delete team");
        logger.error("Failed to delete team");
      },
    );

    track(TrackingEvents.TEAMS.TEAM_SETTINGS_ADMIN_DELETE_TEAM, { deletedTeamId: team.teamId });
  };

  if (deleteStatus === DeleteStatus.Deleted) {
    return <div>Deleted!</div>;
  } else if (deleteStatus === DeleteStatus.Deleting) {
    return <div>Deleting team..</div>;
  }

  const { org, team } = props;
  const isPaid = isTeamInPaidSubscription(org, team);

  return (
    <div cw-id={`web-settings-${TeamSections.Delete}`} className="cw-mb-7">
      <Tooltip
        placement="top"
        title="Paid teams cannot be deleted, please contact support"
        open={!isPaid ? false : undefined}
      >
        <Button
          sentiment="destructive"
          variant="text"
          disabled={isPaid}
          onClick={onDeleteTeamButtonClick}
        >
          Delete Team
        </Button>
      </Tooltip>
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        PaperProps={{ style: { maxWidth: 700, width: 700 } }}
      >
        <DialogTitle>
          <Typography variant="h1">Delete this team for everyone?</Typography>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Typography variant="subtitle1" className="cw-mb-6">
            Are you sure you want to permanently delete "{team.teamName}"? This action cannot be
            undone.
            <br />
            {team.teamMembers.length} team members will lose access to the team and all existing
            team features.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonSet>
            <Button variant="text" onClick={() => setShowConfirmation(false)}>
              Cancel
            </Button>
            <Button sentiment="destructive" variant="text" onClick={onDeleteTeam}>
              Delete team
            </Button>
          </ButtonSet>
        </DialogActions>
      </Dialog>
    </div>
  );
};
