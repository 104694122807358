import { Link } from "@clockwise/design-system";
import React from "react";

const Tooltip = () => (
  <>
    Clockwise will sync meetings from other Google Calendars to your work calendar. Coworkers will
    see an event with a title of "Busy (via Clockwise)" and Clockwise will move flexible meetings to
    automatically fix conflicts with those times.{" "}
    <Link
      onEmphasis
      href="https://getclockwise.helpscoutdocs.com/article/170-personal-calendar-sync"
    >
      Learn more
    </Link>
  </>
);

export default Tooltip;
