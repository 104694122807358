import { useMutation } from "@apollo/client";
import { Button, TextArea, TextField } from "@clockwise/design-system";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { SudoRemoveMembersFromBillingGroupDocument } from "../graphql/__generated__/SudoRemoveMembersFromBillingGroup.generated";
import { ButtonWrapper } from "./ButtonWrapper";
import { FormWrapper } from "./FormWrapper";

const parseEmails = (emails: string) => {
  return emails.split(",").map((email) => email.trim());
};

export const RemoveMembersFromBillingGroup = () => {
  const [orgId, setOrgId] = useState<string | undefined>();
  const [adminEmail, setAdminEmail] = useState<string | undefined>();
  const [billingGroupId, setBillingGroupId] = useState<string | undefined>();
  const [removeUsersFromBillingGroup, { loading }] = useMutation(
    SudoRemoveMembersFromBillingGroupDocument,
  );
  const [membersToRemove, setMembersToRemove] = useState<string | undefined>();
  const allFieldsPopulated = orgId && adminEmail && billingGroupId;

  const resetFields = () => {
    setOrgId("");
    setAdminEmail("");
    setBillingGroupId("");
    setMembersToRemove("");
  };

  const onRemoveMembersToBillingGroup = () => {
    if (!orgId || !adminEmail || !billingGroupId || !membersToRemove) {
      return;
    }

    const emails = parseEmails(membersToRemove);
    void removeUsersFromBillingGroup({
      variables: {
        input: {
          orgId,
          adminEmail,
          billingGroupId,
          emails,
        },
      },
      onCompleted: () => {
        toast.success("Members removed from billing group successfully");
        resetFields();
      },
      onError: (error) => {
        toast.error("Error removing members from billing group " + error.message);
      },
    });
  };

  return (
    <FormWrapper>
      <div className="cw-flex cw-gap-x-2 cw-flex-wrap">
        <TextField
          label="Billing Group ID"
          value={billingGroupId}
          onChange={(e) => setBillingGroupId(e.target.value)}
        />
        <TextField
          label="Admin Email"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
        />
        <TextField label="Org ID" value={orgId} onChange={(e) => setOrgId(e.target.value)} />
      </div>
      <div className="cw-flex">
        <TextArea
          label="Emails"
          value={membersToRemove}
          onChange={(e) => setMembersToRemove(e.target.value)}
          minRows={3}
          placeholder="Comma separted emails of memebrs to remove"
        />
      </div>

      <ButtonWrapper>
        <Button
          size="medium"
          disabled={loading || !allFieldsPopulated}
          sentiment="destructive"
          fullWidth={false}
          onClick={onRemoveMembersToBillingGroup}
        >
          Remove from Billing Group
        </Button>
      </ButtonWrapper>
    </FormWrapper>
  );
};
