import { TradeoffTypeEnum } from "@clockwise/schema";
import { DiffSummaryTradeoffBlocks } from "./types";

export const getHighestPriorityTradeoffTypeFromTradeoffBlocks = (
  tradeoffBlocks: DiffSummaryTradeoffBlocks,
): TradeoffTypeEnum | null => {
  if (tradeoffBlocks.length === 0) return null;

  const tradeoffBlocksWithMultipleTradeoffs = tradeoffBlocks.filter(
    (tradeoff) => tradeoff.schedulingTradeoffs.length > 1,
  );

  if (tradeoffBlocksWithMultipleTradeoffs.length === 0) return null;

  // Tradeoff blocks are returned from the webserver sorted in order of priority
  const highestPriorityTradeoff = tradeoffBlocks[0];

  if (highestPriorityTradeoff.tradeoffType === TradeoffTypeEnum.OUT_OF_TIME_RANGE) {
    if (tradeoffBlocks.length > 1) {
      // If out of time range is first, expand the next tradeoff because OOO cannot be expanded
      return tradeoffBlocks[1].tradeoffType;
    }
    return null;
  } else if (highestPriorityTradeoff.schedulingTradeoffs.length === 1) {
    // find next in order tradeoff that can be expanded (has more than one schedulingTradeoff)

    return tradeoffBlocksWithMultipleTradeoffs[0].tradeoffType;
  }
  return highestPriorityTradeoff.tradeoffType;
};
