import classNames from "classnames";
import React, { KeyboardEvent, useCallback } from "react";

interface IToggleButtonOption {
  value: string;
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}

type ToggleButtonSize = "sm" | "md" | "lg" | "xl";

interface IToggleButtonGroupProps {
  "aria-label": string;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  onChange: (value: string) => void;
  options: IToggleButtonOption[];
  size?: ToggleButtonSize;
  value: string;
}

export const ToggleButtonGroup: React.FC<IToggleButtonGroupProps> = ({
  "aria-label": ariaLabel,
  className,
  disabled = false,
  fullWidth = false,
  label,
  onChange,
  options,
  size = "md",
  value,
}) => {
  const sizeClasses = {
    sm: {
      container: "cw-h-5",
      button: "cw-text-[11px] cw-px-1 cw-py-0.5",
      icon: "cw-h-3 cw-w-3 cw-flex cw-items-center cw-justify-center",
      minWidth: "cw-min-w-[60px]",
    },
    md: {
      container: "cw-h-6",
      button: "cw-text-xs cw-px-1 cw-py-0.5",
      icon: "cw-h-4 cw-w-4 cw-flex cw-items-center cw-justify-center",
      minWidth: "cw-min-w-[70px]",
    },
    lg: {
      container: "cw-h-7",
      button: "cw-text-sm cw-px-2 cw-py-1",
      icon: "cw-h-4.5 cw-w-4.5 cw-flex cw-items-center cw-justify-center",
      minWidth: "cw-min-w-[75px]",
    },
    xl: {
      container: "cw-h-8",
      button: "cw-text-sm cw-px-2.5 cw-py-1.5",
      icon: "cw-h-5 cw-w-5 cw-flex cw-items-center cw-justify-center",
      minWidth: "cw-min-w-[80px]",
    },
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const currentIndex = options.findIndex((option) => option.value === value);
      let nextIndex: number;

      switch (event.key) {
        case "ArrowRight":
        case "ArrowDown":
          event.preventDefault();
          nextIndex = currentIndex + 1 >= options.length ? 0 : currentIndex + 1;
          while (nextIndex !== currentIndex && options[nextIndex].disabled) {
            nextIndex = nextIndex + 1 >= options.length ? 0 : nextIndex + 1;
          }
          if (!options[nextIndex].disabled) {
            onChange(options[nextIndex].value);
          }
          break;
        case "ArrowLeft":
        case "ArrowUp":
          event.preventDefault();
          nextIndex = currentIndex - 1 < 0 ? options.length - 1 : currentIndex - 1;
          while (nextIndex !== currentIndex && options[nextIndex].disabled) {
            nextIndex = nextIndex - 1 < 0 ? options.length - 1 : nextIndex - 1;
          }
          if (!options[nextIndex].disabled) {
            onChange(options[nextIndex].value);
          }
          break;
      }
    },
    [options, value, onChange],
  );

  return (
    <div
      role="radiogroup"
      aria-label={ariaLabel}
      aria-disabled={disabled}
      cw-id="cw-toggle-button-group"
      className={classNames(
        "cw-flex cw-flex-col cw-gap-1",
        {
          "cw-inline-block": !fullWidth,
          "cw-w-full": fullWidth,
          "cw-opacity-30": disabled,
        },
        className,
      )}
    >
      {label && (
        <div
          className="cw-text-muted cw-text-[12px] cw-font-medium"
          id="toggle-button-group-label"
          cw-id="cw-toggle-button-group-label"
        >
          {label}
        </div>
      )}
      <div
        cw-id="cw-toggle-button-group-container"
        className={classNames(
          "cw-flex cw-bg-neutral-inset cw-gap-0.5 cw-p-0.5",
          sizeClasses[size].container,
          "cw-border-solid cw-border-muted cw-border-[0.5px] cw-rounded-lg",
          "cw-font-medium",
          {
            "cw-w-fit": !fullWidth,
            "cw-w-full": fullWidth,
          },
        )}
        onKeyDown={handleKeyDown}
      >
        {options.map((option) => {
          const isSelected = option.value === value;
          const buttonId = `toggle-button-${option.value}`;
          const ariaLabel = option.icon
            ? `${option.label} ${option.icon ? "(with icon)" : ""}`
            : undefined;

          return (
            <button
              key={option.value}
              id={buttonId}
              role="radio"
              aria-checked={isSelected}
              aria-label={ariaLabel}
              aria-disabled={disabled || option.disabled}
              onClick={() => !disabled && !option.disabled && onChange(option.value)}
              disabled={disabled || option.disabled}
              tabIndex={isSelected ? 0 : -1}
              className={classNames(
                "cw-flex cw-items-center cw-justify-center cw-space-x-1",
                "cw-transition-colors cw-duration-150",
                "cw-rounded-md cw-border-none",
                sizeClasses[size].button,
                {
                  [sizeClasses[size].minWidth]: !fullWidth,
                  "cw-flex-1": fullWidth,
                  "cw-cursor-not-allowed": disabled || option.disabled,
                  "cw-cursor-pointer": !disabled && !option.disabled,
                },
                isSelected
                  ? ["cw-bg-default", "cw-text-default", "cw-shadow-sm"]
                  : ["cw-bg-neutral-inset hover:cw-bg-neutral-inset-hover", "cw-text-muted"],
              )}
              cw-id={`cw-toggle-button-${option.value}`}
              data-selected={isSelected}
            >
              {option.icon && (
                <span
                  cw-id={`cw-toggle-button-icon-${option.value}`}
                  className={classNames(sizeClasses[size].icon, "cw-inline-flex")}
                  aria-hidden="true"
                >
                  {option.icon}
                </span>
              )}
              <span className="cw-truncate" cw-id={`cw-toggle-button-label-${option.value}`}>
                {option.label}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};
