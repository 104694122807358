import { useMutation } from "@apollo/client";
import { LoginCredentialsUserDocument } from "./__generated__/LoginCredentialsUser.generated";

export const useLoginCredentialsUserMutation = ({
  onCompleted,
  onError,
}: {
  onCompleted: () => void;
  onError: () => void;
}) => {
  return useMutation(LoginCredentialsUserDocument, {
    onCompleted,
    onError,
  });
};
