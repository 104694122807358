import { useQuery } from "@apollo/client";
import { cloneDeep } from "lodash";
import {
  EMPTY_FLEX_DETAILS,
  EventCardFlex,
  SelectedWorkdayOption,
} from "../../../event-card/types";
import { DiffDetailsByIdDocument } from "../apollo/__generated__/DiffDetailsById.generated";
import { DiffDetailsAttendees, DiffEventDetails } from "../utils/types";

type UseDiffEventDetails = {
  diffEventDetails: DiffEventDetails | null;
  loading: boolean;
  error: Error | undefined;
};

export const getAttendeeWithRSVP = (attendees: DiffDetailsAttendees) => {
  if (!attendees?.proposalAttendees || !attendees?.proposalAttendees?.length) return [];
  return attendees.proposalAttendees.map((person) => {
    return {
      ...person.attendeePerson,
      attendingStatus: person.attendeeResponseStatus,
      isOptional: person.isOptional ?? false,
    };
  });
};

export const useDiffEventDetails = (diffId: string): UseDiffEventDetails => {
  const { data, loading, error } = useQuery(DiffDetailsByIdDocument, {
    variables: {
      diffId,
    },
    // NB: reflects changes in the diff details card
    // after a diff is updated by fetching the latest
    // diff details from the server
    fetchPolicy: "network-only",
  });

  const diffDetailsbyId = data?.viewer?.user?.diffDetailsById ?? null;

  if (!diffDetailsbyId) {
    return { diffEventDetails: null, loading, error };
  }

  const { attendees, ...diffDetails } = diffDetailsbyId;

  const attendeePeople = getAttendeeWithRSVP(attendees);

  const flexDetails: EventCardFlex = cloneDeep(EMPTY_FLEX_DETAILS);
  const flex = diffDetailsbyId.flexibility;
  if (flex) {
    flexDetails.isFlexible = flex.isFlexible;
    if (flex.timeOfDayFlexibility) {
      flexDetails.timeOfDayFlexibility = flex.timeOfDayFlexibility;
    }
    if (flex.timeRangeFlexibility.__typename === "RangeFlexibility") {
      flexDetails.timeRangeFlexibility.range = flex.timeRangeFlexibility.range;
    } else if (flex.timeRangeFlexibility.allowedDays.length) {
      flexDetails.timeRangeFlexibility.range = null;
      flexDetails.timeRangeFlexibility.daysOfWeekFlexibility = flex.timeRangeFlexibility
        .allowedDays as SelectedWorkdayOption[];
    }
  }

  const diffEventDetails = { ...diffDetails, attendeePeople, flexDetails };

  return { diffEventDetails, loading, error };
};
