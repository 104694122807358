import { PlannerEventCard } from "../../types";

export const getHeightFromAllDayEvents = (
  allDayEvents: {
    [calendarId: string]: PlannerEventCard[];
  },
  height: number,
): string => {
  const maxNumber = Math.max(
    ...Object.keys(allDayEvents).map((calendarId) => allDayEvents[calendarId].length),
  );
  return maxNumber > 1 ? `${maxNumber * height}px` : "40px";
};
