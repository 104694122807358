import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetOrgId } from "../../hooks/useGetOrgId";
import {
  NUXChecklistState,
  NUXChecklistStepList,
  useNUXChecklist,
} from "../../hooks/useNUXChecklist";
import { OnboardingFlowState } from "../../hooks/useOnboarding/useOnboarding";
import { DefragCountdownItem } from "./components/DefragCountdownItem";
import { NUXChecklistItem } from "./components/NUXChecklistItem";
import {
  NUXChecklistVariant,
  isItemComplete,
  isItemDisabled,
  isItemSkipped,
  translateFlowStateToVariant,
} from "./utils/NUXChecklist.util";

export const NUXChecklist = () => {
  const { org, loading: orgLoading } = useGetOrgId();
  const navigate = useNavigate();
  const {
    loading: checklistLoading,
    activeStep,
    flexMeetingCurrentState,
    holdsCurrentState,
    preferencesCurrentState,
    skipStep,
    finishStep,
    showFlexMeetingStep,
  } = useNUXChecklist();

  const [expandedItem, setExpandedItem] = useState<NUXChecklistVariant | null>(null);

  useEffect(() => {
    if (activeStep) {
      setExpandedItem(translateFlowStateToVariant(activeStep));
    }
  }, [activeStep]);

  const onClickExpand = (itemVariant: NUXChecklistVariant) => {
    setExpandedItem(itemVariant);
  };

  const onCompleteStep = (variant: NUXChecklistVariant) => {
    switch (variant) {
      case NUXChecklistVariant.FlexMeeting:
        navigate(appPaths.flexibleMeetings);
        break;
      case NUXChecklistVariant.Holds:
        const goToHolds = () => navigate(appPaths.flexibleHolds);
        finishStep(OnboardingFlowState.ChecklistHolds, goToHolds);
        break;
      case NUXChecklistVariant.IdealDay:
        const goToIdealDay = () => navigate(appPaths.accountPreferences);
        finishStep(OnboardingFlowState.ChecklistPreferences, goToIdealDay);
        break;
      default:
        break;
    }
  };

  const loading = orgLoading || checklistLoading;

  const onSkipStep = (flowStep: OnboardingFlowState) => {
    const alreadySkipped = stateForFlowState(flowStep) === NUXChecklistState.Skipped;

    if (alreadySkipped) {
      setExpandedItem(null);
    } else {
      skipStep(flowStep);
    }
  };

  const stateForFlowState = (flowStep: OnboardingFlowState) => {
    switch (flowStep) {
      case OnboardingFlowState.ChecklistFlexMeetings:
        return flexMeetingCurrentState;
      case OnboardingFlowState.ChecklistHolds:
        return holdsCurrentState;
      case OnboardingFlowState.ChecklistPreferences:
        return preferencesCurrentState;
      default:
        return null;
    }
  };
  const stateForVariant = (variant: NUXChecklistVariant) => {
    switch (variant) {
      case NUXChecklistVariant.FlexMeeting:
        return flexMeetingCurrentState;
      case NUXChecklistVariant.Holds:
        return holdsCurrentState;
      case NUXChecklistVariant.IdealDay:
        return preferencesCurrentState;
      default:
        return NUXChecklistState.Unstarted;
    }
  };

  return (
    <div className="cw-flex cw-flex-col cw-w-full">
      <NUXChecklistItem
        variant={NUXChecklistVariant.ConnectCalendar}
        canExpand={false} // always false
        expanded={false} // always false
        complete={true} // always true
        loading={loading}
        disabled={true}
      />
      {NUXChecklistStepList.map((step: OnboardingFlowState) => {
        const variant = translateFlowStateToVariant(step) as NUXChecklistVariant;
        const currentStateForStep = stateForVariant(variant);
        if (step === OnboardingFlowState.ChecklistFlexMeetings && !showFlexMeetingStep) {
          return null;
        }
        return (
          <NUXChecklistItem
            variant={variant}
            canExpand={isItemSkipped(currentStateForStep, activeStep, variant)}
            expanded={expandedItem === variant}
            complete={isItemComplete(currentStateForStep)}
            disabled={isItemDisabled(currentStateForStep, activeStep, variant)}
            loading={loading}
            onSkip={() => onSkipStep(step)}
            onComplete={() => onCompleteStep(variant)}
            onExpand={onClickExpand}
            key={`nux-checklist-${variant}`}
          />
        );
      })}
      <DefragCountdownItem orgId={org?.id} loading={loading} />
    </div>
  );
};
