/**
 * @generated SignedSource<<b5e14450e1fd2c4a1aa06c9e534c8050>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WebAppOnboardingComplete_org$data = {
  readonly id: string;
  readonly slackSettingsErrorable: {
    readonly list?: ReadonlyArray<{
      readonly id: string;
      readonly slackTeamId: string;
    }>;
  };
  readonly " $fragmentType": "WebAppOnboardingComplete_org";
};
export type WebAppOnboardingComplete_org$key = {
  readonly " $data"?: WebAppOnboardingComplete_org$data;
  readonly " $fragmentSpreads": FragmentRefs<"WebAppOnboardingComplete_org">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WebAppOnboardingComplete_org",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "slackSettingsErrorable",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SlackSettings",
              "kind": "LinkedField",
              "name": "list",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slackTeamId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "SlackSettingsList",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Org",
  "abstractKey": null
};
})();

(node as any).hash = "6f2994759d188f9951324a43717d8054";

export default node;
