import {
  Frequency,
  RecurrenceRule,
  RRuleFrequency,
  SupportedReccurenceOptions,
} from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime } from "luxon";

const FREQS: Frequency[] = [
  Frequency.DAILY,
  Frequency.WEEKLY,
  Frequency.MONTHLY,
  Frequency.YEARLY,
] as const;
export type SupportedFrequency = typeof FREQS[number];
export const isSupportedFrequency = (frequency: Frequency): frequency is SupportedFrequency =>
  FREQS.includes(frequency);

export const getInitialEndDate = (date: string) => {
  const zone = getRenderTimeZone();
  // Use max of dateString or current date to ensure end date is in the future
  const startDate = DateTime.max(DateTime.fromISO(date, { zone }), DateTime.now().setZone(zone));
  return startDate.plus({ days: 7 }).startOf("day");
};

export const getInitialByDay = (date: string) => [DateTime.fromISO(date).weekday];

/**
 * Determine if a monthly or yearly recurrence is relative to a specific date, nth weekday, or last weekday
 */
export const getRepetitionType = (rRule: RecurrenceRule, date: string) => {
  const dt = DateTime.fromISO(date);
  const nthWeekday = Math.floor(dt.day / 7);
  const isLastWeekday = dt.daysInMonth - dt.day < 7;
  const initialByNWeekday = rRule.byNthDay;

  let type: "date" | "nth" | "last" = "date";
  if (initialByNWeekday.length > 0) {
    // We only consider the first value of bynweekday since our current UI doesn't support
    // bynweekday with multiple values
    if (initialByNWeekday[0][1] >= 0) {
      // If positive n, prefer "nth" when valid
      type = nthWeekday < 4 ? "nth" : "last";
    } else {
      // If negative n, prefer "last" when valid
      type = isLastWeekday ? "last" : "nth";
    }
  }
  return type;
};

const getWeekdayConfigForRepetitionType = (
  repetitionType: "date" | "nth" | "last",
  date: string,
) => {
  const dt = DateTime.fromISO(date);
  const rruleWeekday = RecurrenceRule.getDay(dt.weekday);
  switch (repetitionType) {
    case "last":
      return [rruleWeekday.nth(-1)];
    case "nth":
      // Weekday#nth is 1-indexed so we need to add 1
      return [rruleWeekday.nth(Math.floor(dt.day / 7) + 1)];
    default:
      return undefined;
  }
};

export const buildNewRRule = (
  date: string,
  {
    frequency,
    interval,
    end,
    byDay,
    relativeRepetitionType,
  }: {
    frequency: RRuleFrequency;
    interval: number;
    end:
      | {
          type: "count";
          count: number;
        }
      | { type: "until"; until: DateTime }
      | { type: "none" };
    byDay: number[];
    relativeRepetitionType: "date" | "nth" | "last";
  },
) => {
  // const opts: Partial<Options> = {
  const opts: SupportedReccurenceOptions = {
    freq: frequency,
    interval,
    count: end.type === "count" ? end.count : undefined,
    until: end.type === "until" ? end.until : undefined,
  };

  // Set frequency-specific options
  if (frequency === RRuleFrequency.WEEKLY) {
    opts.byDay = byDay.map((day) => RecurrenceRule.getDay(day));
  } else if (frequency === RRuleFrequency.MONTHLY) {
    opts.byDay = getWeekdayConfigForRepetitionType(relativeRepetitionType, date);
  } else if (frequency === RRuleFrequency.YEARLY) {
    if (relativeRepetitionType !== "date") {
      opts.byDay = getWeekdayConfigForRepetitionType(relativeRepetitionType, date);
      opts.byMonth = [DateTime.fromISO(date).month];
    }
  }
  return RecurrenceRule.fromOpts(opts);
};
