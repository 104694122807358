/**
 * @generated SignedSource<<c80f053fa6af3a5786469cec22827958>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NoMeetingDayOfWeek = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "%future added value";
export type WebSettingsTeamNoMeetingDayRelayQuery$variables = {
  orgRelayId: string;
};
export type WebSettingsTeamNoMeetingDayRelayQuery$data = {
  readonly viewer: {
    readonly id: string;
  };
  readonly org: {
    readonly id?: string;
    readonly primaryTeam?: {
      readonly __typename: "Team";
      readonly teamId: string;
    } | {
      readonly __typename: "GraphEntityError";
      readonly statusCode: number | null;
      readonly message: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
    readonly userTeams?: {
      readonly __typename: "TeamList";
      readonly list: ReadonlyArray<{
        readonly __typename: string;
        readonly teamId: string;
        readonly id: string;
        readonly teamName: string;
        readonly teamCalendarId: string | null;
        readonly settings: {
          readonly __typename: "TeamSettings";
          readonly manageTeamCalendar: boolean;
          readonly useNoMeetingDay: boolean;
          readonly noMeetingDayOfWeek: NoMeetingDayOfWeek;
          readonly noMeetingDayEmailOnScheduleOver: boolean;
        } | {
          readonly __typename: "GraphEntityError";
          readonly statusCode: number | null;
          readonly message: string | null;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        };
        readonly userSettings: {
          readonly __typename: "TeamUserSettings";
          readonly useNoMeetingDay: boolean;
        } | {
          readonly __typename: "GraphEntityError";
          readonly statusCode: number | null;
          readonly message: string | null;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        };
      }>;
    } | {
      readonly __typename: "GraphEntityError";
      readonly statusCode: number | null;
      readonly message: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  } | null;
};
export type WebSettingsTeamNoMeetingDayRelayQuery = {
  variables: WebSettingsTeamNoMeetingDayRelayQuery$variables;
  response: WebSettingsTeamNoMeetingDayRelayQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgRelayId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Viewer",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "orgRelayId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamId",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/)
  ],
  "type": "Team",
  "abstractKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "GraphEntityError",
  "abstractKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamCalendarId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useNoMeetingDay",
  "storageKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manageTeamCalendar",
      "storageKey": null
    },
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "noMeetingDayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "noMeetingDayEmailOnScheduleOver",
      "storageKey": null
    }
  ],
  "type": "TeamSettings",
  "abstractKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/)
  ],
  "type": "TeamUserSettings",
  "abstractKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WebSettingsTeamNoMeetingDayRelayQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": "org",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "primaryTeam",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "userTeams",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Team",
                        "kind": "LinkedField",
                        "name": "list",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v1/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v12/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "userSettings",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v13/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "TeamList",
                    "abstractKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Org",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WebSettingsTeamNoMeetingDayRelayQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": "org",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "primaryTeam",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "userTeams",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Team",
                        "kind": "LinkedField",
                        "name": "list",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v1/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v12/*: any*/),
                              (v8/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "userSettings",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v13/*: any*/),
                              (v8/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "TeamList",
                    "abstractKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Org",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00aac8a40b30658f2a2a32e67ba0627f",
    "id": null,
    "metadata": {},
    "name": "WebSettingsTeamNoMeetingDayRelayQuery",
    "operationKind": "query",
    "text": "query WebSettingsTeamNoMeetingDayRelayQuery(\n  $orgRelayId: ID!\n) {\n  viewer {\n    id\n  }\n  org: node(id: $orgRelayId) {\n    __typename\n    ... on Org {\n      id\n      primaryTeam {\n        __typename\n        ... on Team {\n          teamId\n        }\n        ... on GraphEntityError {\n          statusCode\n          message\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      userTeams {\n        __typename\n        ... on TeamList {\n          list {\n            __typename\n            teamId\n            id\n            teamName\n            teamCalendarId\n            settings {\n              __typename\n              ... on TeamSettings {\n                manageTeamCalendar\n                useNoMeetingDay\n                noMeetingDayOfWeek\n                noMeetingDayEmailOnScheduleOver\n              }\n              ... on GraphEntityError {\n                statusCode\n                message\n              }\n              ... on Node {\n                __isNode: __typename\n                id\n              }\n            }\n            userSettings {\n              __typename\n              ... on TeamUserSettings {\n                useNoMeetingDay\n              }\n              ... on GraphEntityError {\n                statusCode\n                message\n              }\n              ... on Node {\n                __isNode: __typename\n                id\n              }\n            }\n          }\n        }\n        ... on GraphEntityError {\n          statusCode\n          message\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d68aa5ad0d7ffaf7925be48495f9cfe";

export default node;
