import classNames from "classnames";
import React from "react";

export const CalloutBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className={classNames(
        "cw-border cw-border-subtle cw-border-solid cw-rounded-md",
        "cw-bg-neutral",
        "cw-p-2",
        "cw-text-12 cw-text-muted",
      )}
    >
      {children}
    </div>
  );
};
