type ObjectWithName = {
  name?: string | null;
};

const byNameSorter = (lhs: ObjectWithName, rhs: ObjectWithName) => {
  const lhsName = lhs.name || "";
  const rhsName = rhs.name || "";

  if (lhsName === rhsName) return 0;

  return lhsName > rhsName ? 1 : -1;
};

export default byNameSorter;
