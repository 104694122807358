import { getDaySuffix } from "@clockwise/client-commons/src/util/date";
import { Select, SelectOption, SelectOptionsGroup } from "@clockwise/design-system";
import { DateTime } from "luxon";
import React, { ReactNode, useMemo } from "react";
import { EventElementsWrap } from "../../atoms/EventElementsWrap";

import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { compact } from "lodash";
import { CustomRecurrenceModal } from "../ECCustomRecurrence";
import { LegacyRecurrence } from "./LegacyRecurrence";
import {
  StandardRecurrenceType,
  getNthWeekday,
  getNthWeekdayString,
  getStandardRRule,
  identifyStandardRecurrenceType,
  isLastWeekdayOfMonth,
  standardRecurrenceTypes,
} from "./recurrence.util";

export const DEFAULT_RECURRENCE = new RecurrenceRule("RRULE:FREQ=WEEKLY;INTERVAL=1");

type Props = {
  readOnly: boolean;
  recurrenceRule: RecurrenceRule | null;
  date?: string;
  onSelect: (recurrenceRule: RecurrenceRule | null) => void;
  showChanges?: boolean;
};

export const ECRecurrence = ({
  originalRecurrence,
  ...props
}: Props & { originalRecurrence: RecurrenceRule | null }) => {
  const [enableCustomRecurrence] = useFeatureFlag("CustomRecurrenceUI");

  if (enableCustomRecurrence) {
    return <Recurrence {...props} />;
  } else {
    return <LegacyRecurrence {...props} originalRecurrence={originalRecurrence} />;
  }
};

export const Recurrence = ({ readOnly, recurrenceRule, date, onSelect, showChanges }: Props) => {
  const timezone = getRenderTimeZone();
  const dt = useMemo(
    () =>
      (date
        ? DateTime.fromISO(date, { zone: timezone })
        : DateTime.now().setZone(timezone)
      ).startOf("day"),
    [date, timezone],
  );
  const isoDate = dt.toISODate();

  const [customRecurrenceOpen, setCustomRecurrenceOpen] = React.useState(false);

  const handleChange = (option: StandardRecurrenceType) => {
    // If selected value is a standard option, get the corresponding recurrence rule
    if (["None", ...standardRecurrenceTypes].includes(option)) {
      onSelect(getStandardRRule(option, dt));
    } else {
      setCustomRecurrenceOpen(true);
    }
  };

  const selectedOption = useMemo(() => {
    // Determine if the provided recurrence rule is a standard one
    const standardOption = identifyStandardRecurrenceType(recurrenceRule, dt);
    return customRecurrenceOpen ? "EditCustom" : standardOption ?? "Custom";
  }, [customRecurrenceOpen, recurrenceRule, dt]);

  const showLastWeekdayOption =
    selectedOption === "MonthlyByLastWeekday" || isLastWeekdayOfMonth(dt);
  const showNthWeekdayOption = selectedOption === "MonthlyByNthWeekday" || getNthWeekday(dt) < 5;
  const standardOptions: StandardRecurrenceType[] = useMemo(
    () =>
      compact([
        "Weekdays",
        "Weekly",
        "Biweekly",
        showNthWeekdayOption && "MonthlyByNthWeekday",
        showLastWeekdayOption && "MonthlyByLastWeekday",
        "MonthlyByDate",
      ]),
    [showLastWeekdayOption, showNthWeekdayOption],
  );

  return (
    <EventElementsWrap name="recurrence" showChanges={showChanges}>
      <Select
        disabled={readOnly}
        value={selectedOption}
        onChange={handleChange}
        fullWidth={true}
        size="small"
      >
        <SelectOptionsGroup>
          <SelectOption key="recurrence-none" value="None">
            {standardRecurrenceLabels.None(dt)}
          </SelectOption>
        </SelectOptionsGroup>
        <SelectOptionsGroup>
          {standardOptions.map((option) => {
            return (
              <SelectOption key={`recurrence-${option}`} value={option}>
                {standardRecurrenceLabels[option](dt)}
              </SelectOption>
            );
          })}
        </SelectOptionsGroup>
        <SelectOptionsGroup>
          {selectedOption === "Custom" && recurrenceRule && (
            <SelectOption key="recurrence-custom" value="Custom">
              <RecurrenceLabel label={recurrenceRule.toText({ date: isoDate, timezone })} />
            </SelectOption>
          )}
          <SelectOption key="recurrence-edit-custom" value="EditCustom">
            <RecurrenceLabel label="Custom…" />
          </SelectOption>
        </SelectOptionsGroup>
      </Select>
      <CustomRecurrenceModal
        open={customRecurrenceOpen}
        date={isoDate}
        recurrence={recurrenceRule ?? DEFAULT_RECURRENCE}
        onSubmit={(rrule) => {
          onSelect(rrule);
          setCustomRecurrenceOpen(false);
        }}
        onClose={() => setCustomRecurrenceOpen(false)}
      />
    </EventElementsWrap>
  );
};

const standardRecurrenceLabels: Record<StandardRecurrenceType, (dt: DateTime) => ReactNode> = {
  Weekdays: () => <RecurrenceLabel label="Every weekday" />,
  Weekly: (dt) => <RecurrenceLabel label="Every week" detail={`on ${dt.weekdayLong}`} />,
  Biweekly: (dt) => <RecurrenceLabel label="Every 2 weeks" detail={`on ${dt.weekdayLong}`} />,
  MonthlyByNthWeekday: (dt) => (
    <RecurrenceLabel
      label="Monthly"
      detail={`on the ${getNthWeekdayString(dt)} ${dt.weekdayLong}`}
    />
  ),
  MonthlyByLastWeekday: (dt) => (
    <RecurrenceLabel label="Monthly" detail={`on the last ${dt.weekdayLong}`} />
  ),
  MonthlyByDate: (dt) => (
    <RecurrenceLabel label="Monthly" detail={`on the ${dt.day}${getDaySuffix(dt.day)}`} />
  ),
  None: () => <RecurrenceLabel label="Does not repeat" />,
};

const RecurrenceLabel = ({ label, detail }: { label: string; detail?: string }) => {
  return (
    <span>
      {label}
      {detail && <span className="cw-text-subtle"> {detail}</span>}
    </span>
  );
};
