import { VideocamFilled } from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { ArrowForward } from "@material-ui/icons";
import React from "react";
import { FeatureCard } from "../feature-card";
import { MAX_H_SHORT } from "./FeatureCardGrid";
import { Tile } from "./Tile";
import { Slack } from "./svgs/Slack";

export const ConnectedApps = () => {
  return (
    <FeatureCard
      layout="row"
      feature={{
        name: "Connected apps",
        path: appPaths.appSettings,
        endIcon: <ArrowForward />,
      }}
      className={MAX_H_SHORT}
    >
      <Tile className="cw-basis-1/3 cw-h-20">
        <VideocamFilled fontSize="large" />
      </Tile>
      <Tile className="cw-basis-1/3 cw-h-20">
        <Slack />
      </Tile>
    </FeatureCard>
  );
};
