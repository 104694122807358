import { useMutation, useQuery } from "@apollo/client";
import React from "react";

import { title as sudoTitleStyle } from "#webapp/src/components/sudo-wrapper/SudoWrapper.styles";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { CliCommandDef, getCommandText } from "@clockwise/web-commons/src/ui/cli-command";
import { Paper } from "@material-ui/core";
import { ControlPane } from "./ControlPane";
import { ResultsEntry, ResultsEntryType } from "./ResultsEntry";
import { ResultsPane } from "./ResultsPane";
import { ExecuteCliCommandDocument } from "./__generated__/ExecuteCliCommand.generated";
import { ListCliCommandsDocument } from "./__generated__/ListCliCommands.generated";

export const SudoCli = () => {
  const { data, loading, error } = useQuery(ListCliCommandsDocument);

  if (error) {
    return (
      <SudoCliContainer>
        An error occurred while loading Sudo CLI:
        <br />
        {error.message}
      </SudoCliContainer>
    );
  }

  if (!data || loading) {
    return <SudoCliContainer>Loading…</SudoCliContainer>;
  }

  const commands = getValue(data.viewer.sudo?.listCliCommands)?.list;

  if (!commands) {
    return <SudoCliContainer>Failed to load schema for CLI commands</SudoCliContainer>;
  }

  return (
    <SudoCliContainer>
      <SudoCliContent commands={commands} />
    </SudoCliContainer>
  );
};

const SudoCliContainer = ({ children }: { children: React.ReactNode }) => (
  <Paper elevation={2} className="cw-p-5">
    {children}
  </Paper>
);

const SudoCliContent = ({ commands }: { commands: CliCommandDef[] }) => {
  const [executeCliCommand, { data, error, loading, reset, called }] = useMutation(
    ExecuteCliCommandDocument,
  );

  const [executedCommand, setExecutedCommand] = React.useState<string>();

  const handleReset = () => {
    setExecutedCommand(undefined);
    reset();
  };

  const formatCommandResult = (commandResult?: string) => {
    if (!commandResult) {
      return;
    }

    try {
      return JSON.stringify(JSON.parse(commandResult), undefined, 4);
    } catch (error) {
      return commandResult;
    }
  };

  const resultsEntries = [];
  if (executedCommand) {
    resultsEntries.push(
      <ResultsEntry key="command-input" entryType={ResultsEntryType.Input}>
        {executedCommand}
      </ResultsEntry>,
    );
  }
  if (error) {
    resultsEntries.push(
      <ResultsEntry key="command-error" entryType={ResultsEntryType.Error}>
        {error.message}
      </ResultsEntry>,
    );
  } else if (loading) {
    resultsEntries.push(<ResultsEntry key="command-loading">Executing…</ResultsEntry>);
  } else if (data) {
    resultsEntries.push(
      <ResultsEntry key="command-result" entryType={ResultsEntryType.Output}>
        {formatCommandResult(data?.executeCliCommand?.result)}
      </ResultsEntry>,
    );
  }

  return (
    <>
      <div style={sudoTitleStyle} className="cw-font-brand">
        Run CLI command
      </div>
      <div className="cw-flex cw-flex-col md:cw-flex-row md:cw-space-x-4 cw-flex-nowrap">
        <ControlPane
          className="cw-flex-1"
          disabled={called}
          commands={commands}
          onExecute={(command, options) => {
            void executeCliCommand({
              variables: {
                commandInput: { command, options },
              },
            });
            setExecutedCommand(getCommandText(command, options));
          }}
          onReset={handleReset}
        />

        <ResultsPane className="cw-flex-[2_2_0%]">
          {resultsEntries.length > 0 ? (
            resultsEntries
          ) : (
            <ResultsEntry className="cw-italic">Select a command to run</ResultsEntry>
          )}
        </ResultsPane>
      </div>
    </>
  );
};
