import { InfoOutlined, LockOutlined } from "@clockwise/design-system/icons";
import React from "react";
import { openBillingPage, openPricingPage } from "../../util/upgradeLinks.util";

type BannerType =
  | "Links"
  | "LinksSingleUserFree"
  | "RoundRobin"
  | "AvailabilityLocked"
  | "NMDLocked"
  | "FlexMeetingPage"
  | "FocusTimeSettings";

const bannerStyleMap: {
  [k in BannerType]: {
    mainText: string;
    joinAPlan: string;
    ctaText: string;
    status: "info" | "locked";
  };
} = {
  Links: {
    mainText:
      "You're currently on the free plan. Upgrade to access unlimited links, group scheduling, and more.",
    joinAPlan: "Join a Plan",
    ctaText: "View Plans",
    status: "info",
  },
  LinksSingleUserFree: {
    mainText:
      "You're currently on the free plan. Upgrade to access group scheduling links, branding controls, and more.",
    joinAPlan: "Join a Plan",
    ctaText: "View Plans",
    status: "info",
  },
  RoundRobin: {
    mainText:
      "Make the most out of your links. Get access to more flexible and powerful settings with our Business plan.",
    joinAPlan: "Join a Plan",
    ctaText: "View Plans",
    status: "info",
  },
  AvailabilityLocked: {
    mainText:
      "You've been downgraded to the Free plan. Upgrade your team to use the availability calendar.",
    joinAPlan: "Join a Plan",
    ctaText: "Upgrade",
    status: "locked",
  },
  NMDLocked: {
    mainText:
      "You've been downgraded to the Free plan. Upgrade your team to protect your no-meeting day",
    joinAPlan: "Join a Plan",
    ctaText: "Upgrade",
    status: "locked",
  },
  FlexMeetingPage: {
    mainText: "You've been downgraded to the Free plan. Please upgrade to use flexible meetings",
    ctaText: "Upgrade",
    status: "locked",
    joinAPlan: "Join a Plan",
  },
  FocusTimeSettings: {
    mainText: "You're currently on the free plan. Please upgrade to keep your Focus Time holds.",
    ctaText: "Upgrade",
    status: "locked",
    joinAPlan: "Join a Plan",
  },
};

export type PaywallBannerUpgradeCtaProps = {
  showJoinExistingPlan: boolean;
  bannerType: BannerType;
  showPricingwDialog?: boolean;
  pricingTracking?: () => void;
  billingTracking?: () => void;
};

export const PaywallBannerUpgradeCta = ({
  showJoinExistingPlan,
  bannerType,
  billingTracking,
  pricingTracking,
}: PaywallBannerUpgradeCtaProps) => {
  const bannerStyle = bannerStyleMap[bannerType];
  const textAndIconColor = bannerStyle.status === "locked" ? "cw-text-warning" : "cw-text-info";
  const borderColor =
    bannerStyle.status === "locked" ? "cw-bg-warning-emphasis" : "cw-bg-info-emphasis";

  const onGoToPricing = () => {
    pricingTracking?.();
    openPricingPage();
  };

  const onGoToBillingPage = () => {
    billingTracking?.();
    openBillingPage();
  };

  return (
    <div className="cw-my-4 cw-flex cw-font-body cw-justify-between cw-h-12 cw-items-center cw-rounded-md cw-text-sm cw-border-solid cw-border cw-border-subtle">
      <div className="cw-flex cw-items-center">
        <div
          style={{ borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" }}
          className={`cw-h-12 cw-w-1 ${borderColor}`}
        />
        {bannerStyle.status === "locked" ? (
          <LockOutlined className={`${textAndIconColor} cw-mr-3 cw-ml-4`} />
        ) : (
          <InfoOutlined className={`${textAndIconColor} cw-mr-3 cw-ml-4`} />
        )}
        <span>{bannerStyle.mainText}</span>
      </div>
      <div
        onClick={showJoinExistingPlan ? onGoToBillingPage : onGoToPricing}
        className={`cw-mr-4 cw-font-semibold cw-uppercase ${textAndIconColor} cw-cursor-pointer hover:cw-underline`}
      >
        {showJoinExistingPlan ? bannerStyle.joinAPlan : bannerStyle.ctaText}
      </div>
    </div>
  );
};
