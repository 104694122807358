import { all } from "@clockwise/client-commons/src/constants/time-slot";
import { Day, DayOfWeek, TimeSlot } from "@clockwise/schema";
import { keys } from "lodash";
import {
  DayOnOffMap,
  DaySettingAttributes,
  IDaySetting,
  IWorkingHours,
  SlotRange,
  SlotRangesMap,
  StartOrEndSlot,
  WorkDay,
} from "./working-hours.types";

export const DEFAULT_MIN_SLOT = TimeSlot.T_09_00;
export const DEFAULT_MAX_SLOT = TimeSlot.T_16_30;

export const ORDERED_WEEKDAYS = [
  Day.Sunday,
  Day.Monday,
  Day.Tuesday,
  Day.Wednesday,
  Day.Thursday,
  Day.Friday,
  Day.Saturday,
];

export function validateSlotChange(
  slotRanges: SlotRange[],
  startOrEndSlot: StartOrEndSlot = StartOrEndSlot.StartSlot,
) {
  let isValid = true;
  const validatedSlots = [...slotRanges];

  validatedSlots.forEach((slotRange, i) => {
    slotRange.startError = false;
    slotRange.endError = false;

    // set a start error if the current start slot is
    // equal to or greater than the current end slot
    // and the start slot was changed
    if (startOrEndSlot === StartOrEndSlot.StartSlot && slotRange.startSlot > slotRange.endSlot) {
      slotRange.startError = true;
      isValid = false;
    }

    // set an end error if the current end slot is
    // equal to or less than the current end slot
    // and the end slot was changed
    if (startOrEndSlot === StartOrEndSlot.EndSlot && slotRange.endSlot < slotRange.startSlot) {
      validatedSlots[i].endError = true;
      isValid = false;
    }

    // set a start error for the current start slot
    // and end error for the previous end slot if
    // the start slot is less than or equal to
    // the previous end slot
    if (
      i > 0 &&
      // ensure the index on all is valid
      all.indexOf(validatedSlots[i - 1].endSlot) <= all.length - 3 &&
      slotRange.startSlot <= all[all.indexOf(validatedSlots[i - 1].endSlot) + 1]
    ) {
      slotRange.startError = true;
      validatedSlots[i - 1].endError = true;
      isValid = false;
    }

    // set an end error for the current end slot
    // and start error for the next start slot if
    // the end slot is greater than or equal to
    // the next start slot
    if (i !== validatedSlots.length - 1 && slotRange.endSlot >= validatedSlots[i + 1].startSlot) {
      slotRange.endError = true;
      validatedSlots[i + 1].startError = true;
      isValid = false;
    }
  });

  return { slots: validatedSlots, isValid };
}

export function getSlotRangesFromSettings({
  minSlot,
  maxSlot,
  excludedSlots,
}: DaySettingAttributes) {
  if (!excludedSlots || excludedSlots?.length === 0) {
    return [
      { startSlot: minSlot, endSlot: maxSlot, startError: false, endError: false },
    ] as SlotRange[];
  }

  const startSlotIndex = all.indexOf(minSlot);
  const endSlotIndex = all.indexOf(maxSlot);
  const workingDaySlots = [...all].splice(startSlotIndex, endSlotIndex);
  const workingDaySlotRanges: SlotRange[] = [];

  let startSlot: TimeSlot = minSlot;
  let endSlot: TimeSlot = maxSlot;

  for (let i = 0; i < workingDaySlots.length - 1; i++) {
    if (excludedSlots.includes(workingDaySlots[i])) {
      if (i > 0 && !excludedSlots.includes(workingDaySlots[i - 1])) {
        const endSlotIndex = all.indexOf(workingDaySlots[i]);
        endSlot = all[endSlotIndex - 1];

        workingDaySlotRanges.push({
          startSlot,
          endSlot,
          startError: false,
          endError: false,
        });
      }
      startSlot = workingDaySlots[i + 1];
    }
  }

  workingDaySlotRanges.push({
    startSlot,
    endSlot: maxSlot,
    startError: false,
    endError: false,
  });
  return workingDaySlotRanges;
}

export function getDaySettingFromSlotRanges(slotRanges: SlotRange[]): DaySettingAttributes {
  if (slotRanges.length === 1) {
    const [range] = slotRanges;
    return { minSlot: range.startSlot, maxSlot: range.endSlot, excludedSlots: [], isOff: false };
  }

  const minSlot = slotRanges[0].startSlot;
  const maxSlot = slotRanges[slotRanges.length - 1].endSlot;

  const excludedSlots: TimeSlot[] = [];
  const isOff = false;

  slotRanges.forEach((range, index) => {
    if (index === slotRanges.length - 1) {
      return;
    }

    const startIndex = all.indexOf(range.endSlot) + 1;
    const endIndex = all.indexOf(slotRanges[index + 1].startSlot);

    excludedSlots.push(...all.slice(startIndex, endIndex));
  });

  return { minSlot, maxSlot, excludedSlots, isOff };
}

export function validateDayMap(dayOnOffMap: DayOnOffMap) {
  return Object.values(dayOnOffMap).includes(true);
}

export function getSlotRangesMap(workDays: WorkDay[]) {
  const map: SlotRangesMap = {};

  workDays.forEach((workDay) => {
    map[workDay.day] = {
      slots: getSlotRangesFromSettings({
        minSlot: workDay.setting.minSlot,
        maxSlot: workDay.setting.maxSlot,
        excludedSlots: workDay.setting.excludedSlots ?? [],
        isOff: workDay.setting.isOff || false,
      }),
      isValid: true,
    };
  });
  return map;
}

export function getDayOnOffMap(workDays: WorkDay[]) {
  const map: DayOnOffMap = {
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  };

  workDays.forEach((workDay) => {
    map[workDay.day] = !workDay.setting.isOff;
  });
  return map;
}

export function getDayOnOffMapFromAvailableDays(availableMeetingDays: DayOfWeek[] = []) {
  const map: DayOnOffMap = {};

  availableMeetingDays.forEach((day) => (map[day] = true));

  return map;
}

export function getWorkDaysFromWorkingHours(workingHours: IWorkingHours): WorkDay[] {
  return ORDERED_WEEKDAYS.map((day) => {
    const daySetting = workingHours.daySettings.find((ds) => ds.day === day);
    return {
      day,
      setting: daySetting
        ? { ...daySetting.setting, isOff: false }
        : {
            minSlot: DEFAULT_MIN_SLOT,
            maxSlot: DEFAULT_MAX_SLOT,
            isOff: true,
            excludedSlots: [] as TimeSlot[],
          },
    };
  });
}

export function validateMeetingHours(workingHours: IWorkingHours, meetingHours: IWorkingHours) {
  let isValid = true;

  meetingHours.daySettings.forEach(({ day, setting }) => {
    const matchingWorkingHoursDS = workingHours.daySettings.find((ds) => ds.day === day);

    // if working hours doesn't have a day matching the
    // current meeting hours day, meeting hours are on
    // for an non - working day; mark as invalid
    // eg. if WH Sunday off, MH Sunday on
    if (!matchingWorkingHoursDS) {
      isValid = false;
      return;
    }

    const allWorkingHoursSlotsForDay: { [key: string]: boolean } = {};
    for (
      let i = all.indexOf(matchingWorkingHoursDS.setting.minSlot);
      i < all.indexOf(matchingWorkingHoursDS.setting.maxSlot);
      i++
    ) {
      if (matchingWorkingHoursDS.setting.excludedSlots?.includes(all[i])) {
        continue;
      }

      allWorkingHoursSlotsForDay[all[i]] = true;
    }

    for (let i = all.indexOf(setting.minSlot); i < all.indexOf(setting.maxSlot); i++) {
      if (!setting.excludedSlots?.includes(all[i]) && !allWorkingHoursSlotsForDay[all[i]]) {
        // found an invalid meeting hours slot
        isValid = false;
        break;
      }
    }

    return;
  });

  return isValid;
}

export function getDaySettingsFromWorkingHours(workingHours: IWorkingHours, day: Day) {
  return workingHours.daySettings.find((ds) => ds.day === day) || null;
}

export function getNumSplitHoursForDay({ setting }: IDaySetting) {
  const { minSlot, maxSlot, excludedSlots } = setting;
  const numSplitHoursForDay =
    getSlotRangesFromSettings({ minSlot, maxSlot, excludedSlots, isOff: false }).length - 1;
  return numSplitHoursForDay;
}

export function getAreWorkingHoursForDaySplit(daySettings: IDaySetting) {
  return !!daySettings.setting.excludedSlots?.length;
}

export const defaultDayOnOffMap: DayOnOffMap = {
  [Day.Monday]: true,
  [Day.Tuesday]: true,
  [Day.Wednesday]: true,
  [Day.Thursday]: true,
  [Day.Friday]: true,
};

export function getDaysOfWeekFromDayOnOffMap(dayOnOffMap: DayOnOffMap = {}): Day[] {
  return (keys(dayOnOffMap) as Day[]).filter((d) => dayOnOffMap[d]);
}

export const getNewDayOnOffMap = (
  newAllowedDays: Day[],
  availableDayOnOffMap: Partial<Record<Day, boolean>>,
): Partial<Record<Day, boolean>> => {
  const newDayOnOffMap = { ...availableDayOnOffMap };
  keys(newDayOnOffMap).forEach((day) => (newDayOnOffMap[day as Day] = false));
  newAllowedDays.forEach((day) => (newDayOnOffMap[day] = true));

  return newDayOnOffMap;
};
