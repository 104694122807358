export const getFormattedIdWithDashes = (s: string): string => {
  let result = s.length > 8 ? s.slice(0, 8) + "-" + s.slice(8) : s;
  for (let i = 1; i <= 3; i++) {
    const dashPosition = 8 + i * 5; // Calculate position for the next dash
    if (result.length > dashPosition) {
      result = result.slice(0, dashPosition) + "-" + result.slice(dashPosition);
    }
  }
  return result;
};
