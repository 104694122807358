//////////////////
// IMPORTS
//////////////////
// schema
import { updateSmartHoldSyncSettings } from "#webapp/src/mutations";
import {
  setRequireSaveFocusTimeSync,
  setRequireSaveLunchSync,
  setRequireSaveTravelTimeSync,
} from "#webapp/src/state/actions/settings.actions";
import withStyles from "@material-ui/core/styles/withStyles";
import toast from "react-hot-toast";
// state
import * as ISchema from "#webapp/src/__schema__";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { logger } from "#webapp/src/util/logger.util";
// util
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
// libraries
import * as React from "react";
import { connect } from "react-redux";
import { createFragmentContainer } from "react-relay";
import { smartHoldSyncFragments } from "./SmartHoldSync.gql";
import { styles } from "./SmartHoldSync.styles";
import { IContainer, IProps, IState, TFreeBusy, TReminders } from "./SmartHoldSyncTypes";
import { SmartHoldToggle } from "./SmartHoldToggle";

//////////////////
// COMPONENT
//////////////////
class SmartHoldSyncCmpt extends React.Component<IProps, IState> {
  private saveTimeout: ReturnType<typeof setTimeout>;

  constructor(props: IProps) {
    super(props);

    let freeBusy: TFreeBusy = null;
    let syncAll: boolean | null = null;
    let idealMinutesPerWeek: number | null = null;
    let remindersEnabled: boolean | null = null;

    // default to focus time
    let sync = false;
    let smartHoldLabel = "Smart Hold";
    if (props.smartHoldType === "FocusTime") {
      smartHoldLabel = "Focus Time";
      if (props.useDefaultValues) {
        sync = true;
        syncAll = false;
      } else {
        if (
          props.org.shadowCalendarSettingsErrorable &&
          props.org.shadowCalendarSettingsErrorable.__typename === "ShadowCalendarSettings"
        ) {
          if (props.org.shadowCalendarSettingsErrorable.focusTimeSyncEnabled) {
            sync = true;
          }
          syncAll = props.org.shadowCalendarSettingsErrorable.focusTimeSyncAll;
          idealMinutesPerWeek =
            props.org.shadowCalendarSettingsErrorable.focusTimeIdealMinutesPerWeek;
          remindersEnabled = props.org.shadowCalendarSettingsErrorable.focusTimeRemindersEnabled;
          if (props.org.shadowCalendarSettingsErrorable.focusTimeExternalTransparency) {
            freeBusy = props.org.shadowCalendarSettingsErrorable.focusTimeExternalTransparency;
          }
        }
      }
    }

    // lunch
    if (props.smartHoldType === "Lunch") {
      smartHoldLabel = "lunch";
      if (props.useDefaultValues) {
        sync = true;
      } else {
        if (
          props.org.shadowCalendarSettingsErrorable &&
          props.org.shadowCalendarSettingsErrorable.__typename === "ShadowCalendarSettings"
        ) {
          if (props.org.shadowCalendarSettingsErrorable.lunchSyncEnabled) {
            sync = true;
          }
          remindersEnabled = props.org.shadowCalendarSettingsErrorable.lunchRemindersEnabled;
        }
      }
    }

    // travel time
    if (props.smartHoldType === "TravelTime") {
      smartHoldLabel = "travel time";
      if (props.useDefaultValues) {
        sync = true;
      } else {
        if (
          props.org.shadowCalendarSettingsErrorable &&
          props.org.shadowCalendarSettingsErrorable.__typename === "ShadowCalendarSettings"
        ) {
          if (props.org.shadowCalendarSettingsErrorable.travelTimeSyncEnabled) {
            sync = true;
          }
          remindersEnabled = props.org.shadowCalendarSettingsErrorable.travelTimeRemindersEnabled;
        }
      }
    }

    this.state = {
      smartHoldLabel,
      freeBusy,
      syncAll,
      idealMinutesPerWeek,
      remindersEnabled,
      sync: !!sync,
    };
  }

  public componentWillUnmount() {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }
  }

  private handleToggle = () => {
    const { sync } = this.state;
    const newSyncValue = !sync;

    this.handleSyncChange(newSyncValue);
  };

  private handleSyncChange = (newSyncValue: boolean, newSyncAllValue?: boolean) => {
    const { isOnboarding, smartHoldType } = this.props;

    track(TrackingEvents.SETTINGS.UPDATE_SMART_HOLD_SYNC, {
      isOnboarding,
      sync: newSyncValue,
      type: smartHoldType,
      syncAll: newSyncAllValue,
    });

    let state: any = { sync: newSyncValue };
    if (newSyncAllValue !== undefined) {
      state = { ...state, syncAll: newSyncAllValue };
    }

    this.setState(state);

    if (this.props.onChange) {
      this.props.onChange(newSyncValue, newSyncAllValue);
    }

    // begin save process
    this.beginSave();
  };

  private handleFreeBusyUpdate = (freeBusyValue: TFreeBusy): void => {
    const { isOnboarding, smartHoldType } = this.props;
    track(TrackingEvents.SETTINGS.UPDATE_SMART_HOLD_FREE_BUSY, {
      isOnboarding,
      freeBusy: freeBusyValue,
      type: smartHoldType,
    });
    this.setState({
      freeBusy: freeBusyValue,
    });

    // begin save process
    this.beginSave();
  };

  private handleRemindersUpdate = (remindersEnabled: TReminders): void => {
    const remindersEnabledValue = remindersEnabled === "on";
    const { isOnboarding, smartHoldType } = this.props;
    track(TrackingEvents.SETTINGS.UPDATE_SMART_HOLD_REMINDERS, {
      isOnboarding,
      remindersEnabled: remindersEnabledValue,
      type: smartHoldType,
    });
    this.setState({
      remindersEnabled: remindersEnabledValue,
    });

    // begin save process
    this.beginSave();
  };

  private onSuccess = () => {
    const { isOnboarding } = this.props;
    const { smartHoldLabel } = this.state;
    if (!isOnboarding) {
      toast.success(
        `Your ${smartHoldLabel} settings were updated. It may take a few minutes for changes to take effect.`,
      );
    }
    this.clearRequireSave();
  };

  private onFailure = () => {
    const { smartHoldLabel } = this.state;
    toast.error(`Sorry, there was a problem updating your ${smartHoldLabel} settings`);
    this.clearRequireSave();
    logger.error("Error updating smart hold sync settings");
  };

  private clearRequireSave = () => {
    const { smartHoldType } = this.props;

    // update redux
    if (smartHoldType === "FocusTime") {
      this.props.setRequireSaveFocusTimeSync(false);
    } else if (smartHoldType === "Lunch") {
      this.props.setRequireSaveLunchSync(false);
    } else if (smartHoldType === "TravelTime") {
      this.props.setRequireSaveTravelTimeSync(false);
    }
  };

  private beginSave = () => {
    const { smartHoldType } = this.props;

    // kick off the save delay and other redux state
    // update required save
    if (smartHoldType === "FocusTime") {
      this.props.setRequireSaveFocusTimeSync(true);
    } else if (smartHoldType === "Lunch") {
      this.props.setRequireSaveLunchSync(true);
    } else if (smartHoldType === "TravelTime") {
      this.props.setRequireSaveTravelTimeSync(true);
    }

    // create the save timeout
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }
    this.saveTimeout = setTimeout(() => {
      this.doSave();
    }, 1000);
  };

  private doSave = () => {
    const { org, relay, smartHoldType, isOnboarding } = this.props;
    const { sync, freeBusy, syncAll, idealMinutesPerWeek, remindersEnabled } = this.state;

    let input: ISchema.IUpdateSmartHoldSyncSettingsMutationInput = { orgRelayId: org.id };
    if (smartHoldType === "FocusTime") {
      input = { orgRelayId: org.id, focusTimeSyncEnabled: sync };
      if (sync && freeBusy) {
        const focusTimeExternalTransparency = freeBusy as ISchema.FocusTimeExternalTransparency;
        input = { ...input, focusTimeExternalTransparency };
      }
      if (sync && syncAll !== null) {
        input = { ...input, focusTimeSyncAll: syncAll };
      }
      if (sync && idealMinutesPerWeek !== null) {
        input = { ...input, focusTimeIdealMinutesPerWeek: idealMinutesPerWeek };
      }
      if (sync && remindersEnabled !== null) {
        input = { ...input, focusTimeRemindersEnabled: remindersEnabled };
      }
    } else if (smartHoldType === "Lunch") {
      input = { orgRelayId: org.id, lunchSyncEnabled: sync };
      if (sync && remindersEnabled !== null) {
        input = { ...input, lunchRemindersEnabled: remindersEnabled };
      }
    } else if (smartHoldType === "TravelTime") {
      input = { orgRelayId: org.id, travelTimeSyncEnabled: sync };
      if (sync && remindersEnabled !== null) {
        input = { ...input, travelTimeRemindersEnabled: remindersEnabled };
      }
    }
    track(TrackingEvents.SETTINGS.SAVE_SMART_HOLD_SYNC, {
      sync,
      freeBusy,
      isOnboarding,
      idealMinutesPerWeek,
      remindersEnabled,
      syncAll,
      type: this.props.smartHoldType,
    });
    updateSmartHoldSyncSettings(relay.environment, input, this.onSuccess, this.onFailure);

    if (this.props.onSave) {
      this.props.onSave(sync);
    }
  };

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { smartHoldType } = this.props;

    // check for triggered save
    if (
      smartHoldType === "FocusTime" &&
      nextProps.triggerSaveFocusTimeSyncMillis > this.props.triggerSaveFocusTimeSyncMillis
    ) {
      this.doSave();
    }
    if (
      smartHoldType === "Lunch" &&
      nextProps.triggerSaveLunchSyncMillis > this.props.triggerSaveLunchSyncMillis
    ) {
      this.doSave();
    }
    if (
      smartHoldType === "TravelTime" &&
      nextProps.triggerSaveTravelTimeSyncMillis > this.props.triggerSaveTravelTimeSyncMillis
    ) {
      this.doSave();
    }
  }

  public render() {
    const { smartHoldType } = this.props;
    return (
      <SmartHoldToggle
        on={this.state.sync}
        type={smartHoldType}
        reminders={this.state.remindersEnabled}
        available={this.state.freeBusy === "Transparent"}
        recommended={this.props.recommendEnable}
        onChange={this.handleToggle}
        onChangeReminders={this.handleRemindersUpdate}
        onChangeAvailability={this.handleFreeBusyUpdate}
        disabled={this.props.disabled}
      />
    );
  }
}

//////////////////
// WITH STYLES
//////////////////
export const SmartHoldSyncStyled = withStyles(styles)(SmartHoldSyncCmpt);

//////////////////
// REDUX
//////////////////
function mapStateToProps(state: IReduxState, _ownProps: IContainer) {
  return {
    triggerSaveFocusTimeSyncMillis: state.settings.triggerSaveFocusTimeSyncMillis,
    triggerSaveLunchSyncMillis: state.settings.triggerSaveLunchSyncMillis,
    triggerSaveTravelTimeSyncMillis: state.settings.triggerSaveTravelTimeSyncMillis,
  };
}

const SmartHoldSyncRedux = connect(mapStateToProps, {
  setRequireSaveFocusTimeSync,
  setRequireSaveLunchSync,
  setRequireSaveTravelTimeSync,
})(SmartHoldSyncStyled);

//////////////////
// RELAY
//////////////////
export const SmartHoldSync = createFragmentContainer<IContainer>(
  SmartHoldSyncRedux,
  smartHoldSyncFragments,
);
