import React from "react";
import assistantImage from "./step-components/ai-scheduler-step/assets/ai-summary.svg";
import defragvideo from "./step-components/defrag-step/assets/defrag.webm";
import sidecarImage from "./step-components/download-clockwise-step/assets/sidecar.png";
import offUrl from "./step-components/focus-time-step/assets/off.svg";
import onUrl from "./step-components/focus-time-step/assets/on.svg";
import linksImage from "./step-components/links-step/assets/links.svg";
import lunchImage from "./step-components/lunch/assets/lunch.svg";
import scheduleImage from "./step-components/schedule-step/assets/schedule.svg";
import slackImage from "./step-components/slack-step/assets/slackai.png";
export const WebOnboardingAssetPreloader = () => {
  // Preload the images early to prevent the user waiting for them later on
  return (
    <>
      <link rel="preload" as="image" href={assistantImage} />
      <link rel="preload" as="image" href={scheduleImage} />
      <link rel="preload" as="image" href={linksImage} />
      <link rel="preload" as="video" href={defragvideo} />
      <link rel="preload" as="image" href={offUrl} />
      <link rel="preload" as="image" href={onUrl} />
      <link rel="preload" as="image " href={lunchImage} />
      <link rel="preload" as="image " href={slackImage} />
      <link rel="preload" as="image " href={sidecarImage} />
    </>
  );
};
