import { CheckCircleFilled, EventUpcomingFilled, PublicFilled } from "@clockwise/icons";
import React from "react";
import { ContentContainer, ContentRow, Divider, EducationModal } from "../education-modal";
import { RESCHEDULING_VIDEO_URL } from "../superpowers-checklist/videos";
const PrimaryContent = (
  <ContentContainer>
    <ContentRow>
      <PublicFilled className="cw-text-busy cw-w-4 cw-h-4" />
      <div className="cw-text-13 cw-leading-5">
        Finds availability across <span className="cw-font-medium">attendees' meeting hours</span>{" "}
        and timezones, even for non-users of Clockwise
      </div>
    </ContentRow>
    <Divider />
    <ContentRow>
      <CheckCircleFilled className="cw-text-busy cw-w-4 cw-h-4" />
      <div className="cw-text-13 cw-leading-5">
        Clockwise will try to find a slot that's close to the{" "}
        <span className="cw-font-medium">event's current time</span>
      </div>
    </ContentRow>
    <Divider />
    <ContentRow>
      <EventUpcomingFilled className="cw-text-busy cw-w-4 cw-h-4" />
      <div className="cw-text-13 cw-leading-5">
        No edit permissions? Let Clockwise{" "}
        <span className="cw-font-medium">propose the best time</span> to the meeting organizer
      </div>
    </ContentRow>
  </ContentContainer>
);

export const NUXReschedulingModal = ({
  onClose,
  onClickAction,
}: {
  onClose: () => void;
  onClickAction: () => void;
}) => {
  return (
    <EducationModal
      header="Quick rescheduling"
      subheader="Reschedule on the fly with smart 1-click suggestions"
      primaryButtonText="Reschedule a meeting"
      mainContent={PrimaryContent}
      isOpen={true}
      onClose={onClose}
      onClickPrimaryButton={onClickAction}
      videoLink={RESCHEDULING_VIDEO_URL}
      onClickSecondaryButton={onClose}
    />
  );
};
