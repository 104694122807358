import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { TravelTimeGoalSettingsFragmentDoc } from './TravelTimeGoals.generated';
export type ToggleTravelTimeMutationVariables = Types.Exact<{
  orgId: Types.Scalars['ID'];
  enabled: Types.Scalars['Boolean'];
}>;


export type ToggleTravelTimeMutation = { __typename: 'Mutation', ToggleTravelTime: { __typename: 'ToggleTravelTimeResponse', travelTimeGoal: { __typename: 'TravelTimeGoals', id: string, enabled: boolean | null, userAddress: { __typename: 'Address', rawAddress: string } | { __typename: 'NoAddress' } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null } } | null };


export const ToggleTravelTimeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ToggleTravelTime"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"ToggleTravelTime"},"name":{"kind":"Name","value":"toggleTravelTime"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"orgId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}},{"kind":"Argument","name":{"kind":"Name","value":"enabled"},"value":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"travelTimeGoal"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TravelTimeGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TravelTimeGoalSettings"}}]}}]}}]}}]}},...TravelTimeGoalSettingsFragmentDoc.definitions]} as unknown as DocumentNode<ToggleTravelTimeMutation, ToggleTravelTimeMutationVariables>;