type WeekAnalytics = {
  focusTimeHeld: string | null;
  meetingTime: string | null;
  conflictsResolved: number;
};

type HomeAnalytics = {
  currentWeek: WeekAnalytics | null;
  previousWeek: WeekAnalytics | null;
};

export const parsePersonalizedStatsRowGQL = (homeAnalytics?: HomeAnalytics | null) => {
  if (!homeAnalytics) {
    return null;
  }

  const { currentWeek, previousWeek } = homeAnalytics;

  if (!currentWeek) {
    return null;
  }

  const focusTimeHeld = {
    currentWeek: currentWeek?.focusTimeHeld,
    previousWeek: previousWeek?.focusTimeHeld,
  };

  const meetingTime = {
    currentWeek: currentWeek?.meetingTime,
    previousWeek: previousWeek?.meetingTime,
  };

  const conflictsResolved = {
    currentWeek: currentWeek?.conflictsResolved,
    previousWeek: previousWeek?.conflictsResolved,
  };

  return {
    focusTimeHeld,
    meetingTime,
    conflictsResolved,
  };
};
