import {
  DayOfWeek,
  EventFlexibilityInput,
  FlexDetails,
  FlexRange,
  FlexStatus,
  Mutation,
  TimeRange,
  TimeRangeInput,
} from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import pluralize from "pluralize";
import toast from "react-hot-toast";
import { logger } from "../../util/logger.util";
import { UpdateEventsFlexibilityDocument } from "./__generated__/UpdateEventsFlexibility.v2.generated";

type EventForFlexUpdates = {
  __typename: "Event";
  id: string;
  externalEventId: string;
  canMarkFlexible: boolean;
  flexibility: {
    status: FlexStatus;
  } | null;
};

export function useSetFlexibility<E extends EventForFlexUpdates>(
  loadedEvents: E[],
  source: string,
) {
  const [updateFlexibility, { loading: handleFlexibilityUpdating }] = useGatewayMutation(
    UpdateEventsFlexibilityDocument,
  );

  const trackEvent = useTracking();

  const createOptimisticResponse = (
    events: {
      eventId: string;
      isFlexible: boolean;
      flexRange?: FlexRange | null;
      timeRange?: TimeRangeInput | null;
      allowedDays?: DayOfWeek[] | null;
    }[],
  ) => {
    const eventsUpdates = events.map(
      ({ eventId, isFlexible, timeRange, allowedDays, flexRange }) => {
        const eventFromFetch = loadedEvents.find((e) => e.externalEventId === eventId);
        const status = eventFromFetch?.flexibility?.status || FlexStatus.CanMove;
        const eventTimeRange = timeRange
          ? {
              start: timeRange.start,
              end: timeRange.end,
            }
          : null;
        return {
          id: eventFromFetch?.id || "",
          flexibility: {
            isFlexible,
            flexRange: flexRange || null,
            status,
            allowedDays: allowedDays?.length ? allowedDays : null,
            timeOfDayFlexRange: eventTimeRange
              ? {
                  ...eventTimeRange,
                  __typename: "TimeRange" as TimeRange["__typename"],
                }
              : null,
            __typename: "FlexDetails" as FlexDetails["__typename"],
          },
          __typename: "Event" as EventForFlexUpdates["__typename"],
        };
      },
    );
    return {
      updateEventsFlexibility: eventsUpdates,
      __typename: "Mutation" as Mutation["__typename"],
    };
  };

  const handleAllFlexibilities = (events: { externalEventId: string; isFlexible: boolean }[]) => {
    const optimisticResponse = createOptimisticResponse(
      events.map((e) => {
        return {
          ...e,
          eventId: e.externalEventId,
        };
      }),
    );
    void updateFlexibility({
      variables: { events },
      optimisticResponse: optimisticResponse,
      onCompleted: () => {
        toast.success(`Successfully updated ${pluralize("flexibility", events.length)}`);
        trackEvent(TrackingEvents.FLEXIBLE_MEETING.UPDATE_FLEXIBILITY, {
          events,
          source,
        });
      },
      onError: () => {
        toast.error(`Unable to update ${pluralize("flexibility", events.length)}`);
        logger.error("Failed to update flexibility", { events });
      },
    });
  };

  const handleFlexibilityChange = (
    eventId: string,
    isFlexible: boolean,
    flexRange?: FlexRange,
    timeRange?: TimeRangeInput,
    allowedDays?: DayOfWeek[],
  ) => {
    let event: EventFlexibilityInput = {
      externalEventId: eventId,
      isFlexible,
      allowedDays: allowedDays?.length ? allowedDays : null,
    };
    const eventTimeRange = timeRange
      ? {
          start: timeRange.start,
          end: timeRange.end,
        }
      : null;
    if (flexRange) {
      event = {
        ...event,
        flexRange,
      };
    }
    if (eventTimeRange) {
      event = {
        ...event,
        timeRange: eventTimeRange,
      };
    }

    void updateFlexibility({
      variables: {
        events: [event],
      },
      optimisticResponse: createOptimisticResponse([{ ...event, eventId }]),
      onCompleted: () => {
        toast.success(`Successfully updated`);
      },
      onError: () => {
        toast.error(`Unable to update`);
      },
    });
  };

  const handleAllFlexibilityChange = (allFlexible: boolean) => {
    const editableEvents = loadedEvents
      .filter((e) => e.canMarkFlexible)
      .map(({ externalEventId }) => ({ externalEventId, isFlexible: allFlexible }));

    handleAllFlexibilities(editableEvents);
  };

  return {
    handleFlexibilityChange,
    handleAllFlexibilityChange,
    handleFlexibilityUpdating,
  };
}
