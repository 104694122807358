import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { OrganizationSettings } from "../components/admin-organization-settings/OrganizationSettings";
import { PageEvents, page } from "../util/analytics.util";

const OrganizationSettingsPage = () => {
  useEffect(() => {
    page(PageEvents.ORGANIZATION_SETTINGS_PAGE);
  }, []);

  return (
    <>
      <Helmet>
        <title>Organization settings · Clockwise</title>
      </Helmet>
      <OrganizationSettings />
    </>
  );
};

export default OrganizationSettingsPage;
