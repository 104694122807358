import { CountdownNextDefragTimeDocument } from "#webapp/src/graphql/__generated__/CountdownNextDefragTime.generated";
import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Skeleton } from "@material-ui/lab";
import { DateTime, Duration } from "luxon";
import React, { useState } from "react";
import { useInterval } from "usehooks-ts";
import { useOrgId } from "../../../hooks/useOrgId";
import { getFormattedDuration } from "../../../web-onboarding/step-components/flex-meeting-step/utils/getFormattedDuration";

export const AIDefragCountdown = () => {
  const { orgId } = useOrgId();
  const { data, loading: diffLoading, error } = useQuery(CountdownNextDefragTimeDocument, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      orgId: orgId!,
    },
    skip: !orgId,
  });
  const [_, setSeconds] = useState(0);

  useInterval(() => {
    setSeconds((seconds) => seconds + 1);
  }, 1000);

  if (error) {
    return null;
  }

  const org = getValue(data?.org, "Org");
  const nextDefragTimeMillis: number | undefined = getValue(org?.nextDefragTime, "DefragTime")
    ?.millis;

  if (!nextDefragTimeMillis) {
    return null;
  }

  const now = DateTime.local();
  const nextDefrag = DateTime.fromMillis(nextDefragTimeMillis);
  const diff = nextDefrag.diff(now, ["days", "hours", "minutes", "seconds", "milliseconds"]); // Specifiying "milliseconds" forces "seconds" to be an integer

  if (now > nextDefrag) {
    // This is can happen if data is stale
    return null;
  }

  const formatDuration = (defragDiff: Duration) => {
    const timeDiff = getFormattedDuration(defragDiff);
    if (timeDiff.days) {
      return `${timeDiff.days}:${timeDiff.hours}:${timeDiff.minutes}:${timeDiff.seconds}`;
    }
    return `${timeDiff.hours}:${timeDiff.minutes}:${timeDiff.seconds}`;
  };

  return diffLoading ? (
    <Skeleton variant="rect" />
  ) : (
    <div className="cw-text-11 cw-text-muted cw-text-center">
      Your next optimization is in {formatDuration(diff)}
    </div>
  );
};
