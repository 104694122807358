import { ArrowForward } from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import React from "react";
import { FeatureCard } from "../feature-card";
import { BestTimeTag } from "./BestTimeTag";
import { Tile } from "./Tile";

export const SchedulingLinks = () => {
  return (
    <FeatureCard
      layout="column"
      feature={{
        name: "Scheduling links",
        path: appPaths.schedulingLinks,
        endIcon: <ArrowForward />,
      }}
    >
      <Tile className="cw-basis-20 cw-grow">
        10:45 am <BestTimeTag />
      </Tile>
    </FeatureCard>
  );
};
