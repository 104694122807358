import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateTrialMutationVariables = Types.Exact<{
  input: Types.ExtendTrialInput;
}>;


export type CreateTrialMutation = { __typename: 'Mutation', extendTrial: { __typename: 'ExtendTrialPayload', clientMutationId: string | null, trialMetadata: { __typename: 'TrialMetadata', isTrialExpired: boolean, trialEndDate: string } } | null };

export type CreateTestSubscriptionStripeMutationVariables = Types.Exact<{
  input: Types.CreateTestSubscriptionStripeInput;
}>;


export type CreateTestSubscriptionStripeMutation = { __typename: 'Mutation', createTestSubscriptionStripe: { __typename: 'CreateTestSubscriptionStripePayload', clientMutationId: string | null, stripeError: { __typename: 'StripeError', code: string | null, message: string | null } | null } | null };

export type CancelTestSubscriptionMutationVariables = Types.Exact<{
  input: Types.CancelTestSubscriptionsInput;
}>;


export type CancelTestSubscriptionMutation = { __typename: 'Mutation', cancelTestSubscription: { __typename: 'CancelTestSubscriptionsPayload', clientMutationId: string | null } | null };


export const CreateTrialDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTrial"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExtendTrialInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"extendTrial"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}},{"kind":"Field","name":{"kind":"Name","value":"trialMetadata"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TrialMetadata"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isTrialExpired"}},{"kind":"Field","name":{"kind":"Name","value":"trialEndDate"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CreateTrialMutation, CreateTrialMutationVariables>;
export const CreateTestSubscriptionStripeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateTestSubscriptionStripe"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateTestSubscriptionStripeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTestSubscriptionStripe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}},{"kind":"Field","name":{"kind":"Name","value":"stripeError"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]} as unknown as DocumentNode<CreateTestSubscriptionStripeMutation, CreateTestSubscriptionStripeMutationVariables>;
export const CancelTestSubscriptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelTestSubscription"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CancelTestSubscriptionsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelTestSubscription"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}}]}}]}}]} as unknown as DocumentNode<CancelTestSubscriptionMutation, CancelTestSubscriptionMutationVariables>;