import { border_default } from "@clockwise/design-system/tokens";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { CalendarColorSet } from "@clockwise/web-commons/src/util/calendar-coloring";
import { getFullName } from "@clockwise/web-commons/src/util/profile.util";
import { MoreVertRounded } from "@material-ui/icons";
import classNames from "classnames";
import { isNil } from "lodash";
import React, { MouseEvent } from "react";
import { useDispatch } from "react-redux";
import {
  addCalendarIds,
  removeCalendarIds,
} from "../../../../state/actions/multi-calendar.actions";
import { EventCardAttendee } from "../../types";
import { AttendeeActionsPopover } from "./AttendeeActionsPopover";

type MultiCalendarConfig = {
  calendarColors: CalendarColorSet | undefined;
  isIncluded: boolean;
  isToggleable: boolean;
};

export const ECAttendee = ({
  attendee,
  isEditable,
  removeAttendee,
  updateOptionality,
  multiCalConfig,
  isExternal,
}: {
  attendee: EventCardAttendee;
  isEditable: boolean;
  removeAttendee: (primaryCalendar: string) => void;
  updateOptionality: (attendee: EventCardAttendee, isOptional: boolean) => void;
  multiCalConfig?: MultiCalendarConfig;
  isExternal?: boolean;
}) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const getAttendeeName = () => {
    if (attendee.profile && attendee.profile.givenName && attendee.profile.familyName) {
      return getFullName(attendee.profile);
    }
    return attendee.primaryCalendar;
  };
  const showToggleCalendar = multiCalConfig && multiCalConfig.isToggleable && !attendee.isYou;

  const canEditWithPopover = isEditable || showToggleCalendar;

  const showPopover = (event: MouseEvent<HTMLDivElement>) => {
    if (canEditWithPopover) {
      setAnchorEl(event.currentTarget);
    }
  };

  const onClickButton = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    showPopover(event);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const onRemoveAttendee = () => {
    removeAttendee(attendee.primaryCalendar);
  };

  const toggleCalendarForMultiCalView = (primaryCalendar: string) => {
    if (!multiCalConfig) return;

    const { isIncluded, isToggleable } = multiCalConfig;

    if (!isToggleable) return;

    if (isIncluded) {
      closePopover();
      dispatch(removeCalendarIds([primaryCalendar]));
    } else {
      closePopover();
      dispatch(addCalendarIds([primaryCalendar]));
    }
  };

  const onUpdateOptionality = (optional?: boolean) => {
    if (!isNil(optional)) {
      updateOptionality(attendee, optional);
    }
  };

  const attendeeBorderColor =
    multiCalConfig?.calendarColors && multiCalConfig?.isIncluded
      ? multiCalConfig?.calendarColors.border
      : border_default;

  return (
    <div
      className={classNames("cw-flex cw-items-center cw-justify-between cw-rounded-lg", {
        "hover:cw-bg-neutral cw-cursor-pointer": canEditWithPopover,
      })}
      onClick={(e) => {
        if (!canEditWithPopover) return;
        onClickButton(e);
      }}
      role="button"
    >
      <div
        key={attendee.primaryCalendar}
        className={classNames(
          "cw-flex cw-mr-2 cw-ml-1 cw-transition-colors cw-duration-200 cw-ease-in-out",
          {
            "cw-cursor-pointer": showToggleCalendar,
          },
        )}
        onClick={(e) => {
          e.stopPropagation();
          toggleCalendarForMultiCalView(attendee.primaryCalendar);
        }}
      >
        <AttendeeAvatar
          iconType={attendee.attendingStatus}
          key={attendee.primaryCalendar}
          profile={
            attendee.profile || {
              familyName: attendee.primaryCalendar || null,
              givenName: null,
              externalImageUrl: null,
            }
          }
          isOrganizer={attendee.isOrganizer || false}
          isExternalUser={isExternal}
          borderColor={attendeeBorderColor}
          size="large"
        />
      </div>
      <div className="cw-flex-col cw-overflow-hidden cw-w-full cw-py-1">
        <div
          className={classNames(
            "cw-max-w-[95%] cw-text-13 cw-font-medium cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis",
            {
              "cw-cursor-pointer": canEditWithPopover,
            },
          )}
          cw-id="ec-attendee-name"
        >
          {getAttendeeName()}
        </div>
        {attendee.isOrganizer && (
          <div className="cw-text-12 cw-text-muted" cw-id="ec-attendee-organizer">
            Organizer
          </div>
        )}
        {!!attendee.isOptional && (
          <div className="cw-text-12 cw-text-muted" cw-id="ec-attendee-optional">
            Optional
          </div>
        )}
        {isExternal && (
          <div className="cw-text-12 cw-text-muted" cw-id="ec-attendee-optional">
            External
          </div>
        )}
      </div>
      {canEditWithPopover && (
        <MoreVertRounded
          cw-id="ec-attendee-action-button"
          className="cw-w-4 cw-h-4 cw-text-muted cw-mr-2 cw-cursor-pointer"
          aria-label={`Menu for ${getAttendeeName()}`}
        />
      )}
      <AttendeeActionsPopover
        calendarId={attendee.primaryCalendar}
        anchorEl={anchorEl}
        onClose={closePopover}
        onBlurCapture={closePopover}
        onRemoveAttendee={isEditable ? () => onRemoveAttendee() : undefined}
        onUpdateOptionality={
          isEditable ? (optional: false) => onUpdateOptionality(optional) : undefined
        }
        onToggleCalendar={
          showToggleCalendar
            ? () => toggleCalendarForMultiCalView(attendee.primaryCalendar)
            : undefined
        }
        isCalendarVisible={showToggleCalendar ? !!multiCalConfig?.isIncluded : undefined}
        isOptional={!!attendee.isOptional}
      />
    </div>
  );
};
