import React from "react";
import { textSplitByNewLine } from "../utils/textSplitByNewLine";
import { GQLAssistantMessageViewEventsResponse } from "../utils/types";

export const ViewEventsResponse = ({
  message,
}: {
  message: GQLAssistantMessageViewEventsResponse;
}) => {
  return (
    <div className="cw-flex-col cw-items-start cw-body-sm">{textSplitByNewLine(message.text)}</div>
  );
};
