import { ArrowForward } from "@clockwise/icons";
import { EventThread, SecondaryCard } from "@clockwise/web-commons/src/ui/event-thread";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime } from "luxon";
import { X } from "phosphor-react";
import React from "react";
import { formattedDateTime } from "../web-app-calendar/calendar-popover/utils/formattedDateTime";
import { OnClickX } from "./TradeoffBlock";

type Attendee = {
  person: {
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  };
};

type Tradeoff = {
  title: string;
  attendees: Attendee[];
  event: {
    title: string;
  } | null;
  updatedStartTime?: DateTime | null;
  diffId?: string | null;
};

type Props = {
  tradeoff: Tradeoff;
  onClickX?: OnClickX;
  type: SecondaryCard;
  loading?: boolean;
};

export const TradeoffDetail = ({ tradeoff, onClickX, type, loading = false }: Props) => {
  const { attendees, event, updatedStartTime } = tradeoff;
  const displayTitle = event?.title ? event?.title : tradeoff.title;
  const profiles = attendees.map((attendee) => attendee.person);
  const diffId = tradeoff.diffId;
  const zone = getRenderTimeZone();

  const isRemovable = onClickX && diffId;
  const tradeoffHasBeenRemoved = !diffId && type == "sbm";
  const showUpdatedStartTime = updatedStartTime && !tradeoffHasBeenRemoved;
  const showFindingFix = type === "unavailable" && !!event && loading;
  const hasEndContent = showFindingFix || showUpdatedStartTime || tradeoffHasBeenRemoved;

  return (
    <EventThread.Event
      profiles={profiles}
      overflowLimit={1}
      variant="MODAL_PROPOSAL"
      spacing="Compact"
    >
      <div className="cw-flex cw-items-center cw-gap-1 cw-caption cw-group">
        <div className="cw-bg-neutral cw-border cw-border-solid cw-border-subtle cw-px-1 cw-rounded cw-truncate">
          {displayTitle}
        </div>
        {hasEndContent && (
          <div className="cw-flex cw-flex-row cw-items-center cw-gap-1 cw-shrink-0 cw-text-muted cw-body-sm">
            <ArrowForward fontSize="inherit" color="inherit" className="cw-mb-[1px]" />
            {showFindingFix && <>Finding fix</>}
            {showUpdatedStartTime && formattedDateTime(updatedStartTime, zone)}
            {tradeoffHasBeenRemoved && <>Won't Fix</>}
          </div>
        )}
        {isRemovable && (
          <div
            className="cw-flex cw-flex-row cw-grow cw-items-center cw-opacity-0 group-hover:cw-opacity-100"
            role="button"
            aria-label="Don't fix"
          >
            <X
              className="cw-text-positive-muted cw-h-3 cw-w-3 cw-stroke-2 cw-cursor-pointer cw-flex cw-grow-0"
              size={12}
              onClick={() => {
                onClickX?.(diffId);
              }}
            />
          </div>
        )}
      </div>
    </EventThread.Event>
  );
};
