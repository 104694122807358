import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UploadLogoMutationVariables = Types.Exact<{
  logoImage: Types.Scalars['Upload'];
}>;


export type UploadLogoMutation = { __typename: 'Mutation', uploadCustomLinkLogo: { __typename: 'User', id: string, linkLogoUrl: string | null, resolvedLinkLogoUrl: string | null } | null };

export type DeleteLogoMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DeleteLogoMutation = { __typename: 'Mutation', deleteCustomLinkLogo: { __typename: 'User', id: string, linkLogoUrl: string | null, resolvedLinkLogoUrl: string | null } };

export type UploadBillingGroupLogoMutationVariables = Types.Exact<{
  billingGroupId: Types.Scalars['ID'];
  logoImage: Types.Scalars['Upload'];
}>;


export type UploadBillingGroupLogoMutation = { __typename: 'Mutation', uploadLinkLogoForBillingGroup: { __typename: 'BillingGroup', id: string, linkLogoUrl: string | null } | null };

export type DeleteBillingGroupLogoMutationVariables = Types.Exact<{
  billingGroupId: Types.Scalars['ID'];
}>;


export type DeleteBillingGroupLogoMutation = { __typename: 'Mutation', deleteLinkLogoForBillingGroup: { __typename: 'BillingGroup', id: string, linkLogoUrl: string | null } };


export const UploadLogoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UploadLogo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"logoImage"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadCustomLinkLogo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"logoImage"},"value":{"kind":"Variable","name":{"kind":"Name","value":"logoImage"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"linkLogoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"resolvedLinkLogoUrl"}}]}}]}}]} as unknown as DocumentNode<UploadLogoMutation, UploadLogoMutationVariables>;
export const DeleteLogoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteLogo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteCustomLinkLogo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"linkLogoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"resolvedLinkLogoUrl"}}]}}]}}]} as unknown as DocumentNode<DeleteLogoMutation, DeleteLogoMutationVariables>;
export const UploadBillingGroupLogoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UploadBillingGroupLogo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"billingGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"logoImage"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadLinkLogoForBillingGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"billingGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"billingGroupId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"logoImage"},"value":{"kind":"Variable","name":{"kind":"Name","value":"logoImage"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"linkLogoUrl"}}]}}]}}]} as unknown as DocumentNode<UploadBillingGroupLogoMutation, UploadBillingGroupLogoMutationVariables>;
export const DeleteBillingGroupLogoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteBillingGroupLogo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"billingGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteLinkLogoForBillingGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"billingGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"billingGroupId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"linkLogoUrl"}}]}}]}}]} as unknown as DocumentNode<DeleteBillingGroupLogoMutation, DeleteBillingGroupLogoMutationVariables>;