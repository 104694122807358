import { ResponseStatusEnum } from "@clockwise/schema";
import { isNil } from "lodash";
import { compareBooleans } from "../../../../../util/sort.util";
import { EventCardAttendee } from "../../../types";

export const sortedAttendeesByOrganizerYouAndStatus = (
  attendees: EventCardAttendee[],
): EventCardAttendee[] => {
  return attendees.sort((a, b) => {
    return (
      compareBooleans(isNil(a.attendingStatus), isNil(b.attendingStatus)) ||
      compareBooleans(
        a.attendingStatus === ResponseStatusEnum.NotApplicable,
        b.attendingStatus === ResponseStatusEnum.NotApplicable,
      ) ||
      compareBooleans(
        a.attendingStatus === ResponseStatusEnum.NeedsAction,
        b.attendingStatus === ResponseStatusEnum.NeedsAction,
      ) ||
      compareBooleans(
        a.attendingStatus === ResponseStatusEnum.Tentative,
        b.attendingStatus === ResponseStatusEnum.Tentative,
      ) ||
      compareBooleans(
        a.attendingStatus === ResponseStatusEnum.Declined,
        b.attendingStatus === ResponseStatusEnum.Declined,
      ) ||
      compareBooleans(
        a.attendingStatus === ResponseStatusEnum.Accepted,
        b.attendingStatus === ResponseStatusEnum.Accepted,
      ) ||
      compareBooleans(!!b.isOrganizer, !!a.isOrganizer) ||
      compareBooleans(b.isYou, a.isYou)
    );
  });
};
