import { ProposalState } from "@clockwise/schema/v2";
import { RangeHighlightCards } from "@clockwise/web-commons/src/components/calendar/calendar-range-highlight/RangeHighlightCards";
import { DateTime } from "luxon";
import * as React from "react";
import { useCurrentProposal } from "../../chat-plus-calendar/CurrentProposalContext";
import { usePersistedProposal } from "../../chat-plus-calendar/PersistedProposalContext";

export const ProposalSearchRangeUnderlay = ({ dateTimes }: { dateTimes: DateTime[] }) => {
  const {
    currentProposal: { searchTimeRanges },
  } = useCurrentProposal();

  if (!searchTimeRanges || searchTimeRanges.length === 0) {
    return null;
  }

  return (
    <div cw-id="cw-proposal-search-range-underlay">
      <RangeHighlightCards
        cw-id="cw-proposal-search-range-underlay"
        type="underlay"
        dateTimes={dateTimes}
        intervals={searchTimeRanges}
      />
    </div>
  );
};

export const ProposalSearchRangeOverlay = ({ dateTimes }: { dateTimes: DateTime[] }) => {
  const {
    currentProposal: { searchTimeRanges },
  } = useCurrentProposal();

  const { status } = usePersistedProposal();
  const isLoading = status === ProposalState.LoadingSuggestions;

  if (!searchTimeRanges || searchTimeRanges.length === 0) {
    return null;
  }

  return (
    <div cw-id="cw-proposal-search-range-overlay">
      <RangeHighlightCards
        cw-id="cw-proposal-search-range-overlay"
        type="overlay"
        isLoading={isLoading}
        dateTimes={dateTimes}
        intervals={searchTimeRanges}
      />
    </div>
  );
};
