import { Button } from "@clockwise/design-system";
import { AssistantFilled } from "@clockwise/icons";
import { SchedulingMove, SchedulingSlot } from "@clockwise/schema";
import { first, isEmpty } from "lodash";
import { DateTime } from "luxon";
import { MagicWand } from "phosphor-react";
import pluralize from "pluralize";
import * as React from "react";
import { LoadingSpinner } from "../../ui/LoadingSpinner";
import { getFormattedEventTime } from "../../util/date.util";

type IsMakeItWorkSlot = {
  additionalChanges?: SchedulingMove[];
};

export const isMakeItWorkSlot = (slot?: IsMakeItWorkSlot | null) => {
  return !isEmpty(slot?.additionalChanges);
};

interface MakeItWorkEvent {
  title?: string | null;
  startTime: { dateOrDateTime: string };
  endTime: { dateOrDateTime: string };
}
interface MakeItWorkMove {
  event?: {
    id: string;
    events?: MakeItWorkEvent[];
  } | null;
  to: string;
  from: string;
}

export interface IProps {
  selectedSlot: SchedulingSlot | undefined;
  onClickFixItNow: () => void;
  onClickScheduleAndLeaveTheConflict: () => void;
  isSaving: boolean;
}

export const MAKE_IT_WORK_BLUE = "#4459dd";

const getEventTitle = (eventParent: { events?: MakeItWorkEvent[] }) => {
  const event = first(eventParent?.events);
  return event?.title || "A private meeting";
};

const MakeItWorkDiff = ({
  eventParent,
  to,
  from,
}: {
  eventParent: {
    id: string;
    events?: MakeItWorkEvent[];
  };
  to: string;
  from: string;
}) => {
  const firstEvent = first(eventParent.events);

  if (!firstEvent) {
    return null;
  }

  const startTime = DateTime.fromISO(firstEvent.startTime.dateOrDateTime);
  const endTime = DateTime.fromISO(firstEvent.endTime.dateOrDateTime);
  const durationInMinutes = endTime.diff(startTime).as("minutes");
  const formattedEventTimeBefore = getFormattedEventTime({
    start: to,
    end: DateTime.fromISO(to).plus({ minutes: durationInMinutes }).toISO(),
  });
  const formattedEventTimeAfter = getFormattedEventTime({
    start: from,
    end: DateTime.fromISO(from).plus({ minutes: durationInMinutes }).toISO(),
  });

  return (
    <div className="cw-ml-1 cw-my-2 cw-flex cw-flex-col">
      <div>
        "<strong className="cw-font-semibold">{getEventTitle(eventParent)}</strong>"
      </div>
      <div className="cw-text-positive">
        <strong className="cw-font-semibold">{formattedEventTimeBefore}</strong>
      </div>
      <div className="cw-text-warning cw-line-through">{formattedEventTimeAfter}</div>
    </div>
  );
};

const MakeItWorkDiffs = ({ additionalChanges }: { additionalChanges: MakeItWorkMove[] }) => {
  return (
    <div>
      {additionalChanges.map((move) =>
        move.event ? (
          <MakeItWorkDiff
            key={move.event.id}
            to={move.to}
            from={move.from}
            eventParent={move.event}
          />
        ) : null,
      )}
    </div>
  );
};

export const MakeItWorkCard = ({
  selectedSlot,
  onClickFixItNow,
  onClickScheduleAndLeaveTheConflict,
  isSaving,
}: IProps) => {
  const additionalChanges = selectedSlot?.additionalChanges || [];
  const numOfMeetingsToMove = additionalChanges?.length || 0;

  return (
    <div
      className="cw-px-3 cw-py-2 cw-absolute cw-w-[250px] cw-z-50 cw-rounded-md cw-bg-[#ffffff] cw-text-sm cw-font-body cw-text-onEmphasis"
      id="make-it-work"
    >
      <div className="cw-flex cw-items-center cw-gap-1 cw-mb-1 cw-font-bold cw-text-[#4459dd]">
        <MagicWand color="#4459dd" weight="fill" size="14px" />
        <span> Make it work</span>
      </div>
      <div className="cw-font-bold cw-text-[#003f2e]">
        Do you want Clockwise to automatically fix the conflict?
      </div>
      <div className="cw-flex cw-flex-col cw-gap-3 cw-p-3 cw-mt-2 cw-mb-5 cw-bg-neutral-inset cw-rounded-md cw-text-xs cw-text-neutral">
        <div className="cw-align-middle">
          If you choose this time, Clockwise will reschedule {numOfMeetingsToMove || "some"}{" "}
          {pluralize("meeting", numOfMeetingsToMove)}:
          <div className="cw-my-1">
            <MakeItWorkDiffs additionalChanges={additionalChanges} />
          </div>
          to {numOfMeetingsToMove == 1 ? "a time" : "times"} that works well for all the attendees
        </div>
      </div>
      <div>
        <div className="cw-flex cw-flex-col cw-gap-2">
          <Button
            size="small"
            startIcon={AssistantFilled}
            variant="solid"
            sentiment="positive"
            onClick={onClickFixItNow}
          >
            Fix it now
          </Button>
          <Button
            size="small"
            variant="outlined"
            sentiment="neutral"
            onClick={onClickScheduleAndLeaveTheConflict}
          >
            {isSaving && <LoadingSpinner />}
            Leave the conflict
          </Button>
        </div>
      </div>
    </div>
  );
};
