interface LinkSettingsTrackingData {
  newLinkSettings: {
    __typename: string;
    allowFifteenMinuteMarkBooking: boolean;
    availabilityType: string;
    bookableHours: string;
    bookingTimeBufferHours: number;
    bookOnNoMeetingDay: boolean;
    description: string | null;
    highlightBestTimes: boolean;
    id: string;
    isActive: boolean;
    locationSettings: {
      locationType: string;
    };
    name: string;
    slug: string;
    timingSettings: {
      durationMinutes: number;
      periodType: string;
      timeRange: {
        windowSizeDays: number | null;
      };
    };
  };
}

export const buildSchedulingLinkTrackingData = (linkSettings: {
  id: string;
  name: string;
  description?: string | null;
  slug: string;
  active: boolean;
  durations: number[];
  location: string;
  availabilityRange: {
    windowSize?: number | null;
  };
  allowQuarterHourBooking: boolean;
  availabilityRestriction: string;
  bookableHours: string;
  bufferHours: number;
  allowBookingOnNoMeetingDay: boolean;
  highlightBestTimes: boolean;
}): LinkSettingsTrackingData => {
  return {
    newLinkSettings: {
      __typename: "MeetingSettings",
      allowFifteenMinuteMarkBooking: linkSettings.allowQuarterHourBooking,
      availabilityType: linkSettings.availabilityRestriction,
      bookableHours: linkSettings.bookableHours,
      bookingTimeBufferHours: linkSettings.bufferHours,
      bookOnNoMeetingDay: linkSettings.allowBookingOnNoMeetingDay,
      description: linkSettings.description ?? null,
      highlightBestTimes: linkSettings.highlightBestTimes,
      id: linkSettings.id,
      isActive: linkSettings.active,
      locationSettings: {
        locationType: linkSettings.location,
      },
      name: linkSettings.name,
      slug: linkSettings.slug,
      timingSettings: {
        // TODO: How to track multiple durations?
        durationMinutes: linkSettings.durations[0],
        periodType: linkSettings.availabilityRange.windowSize ? "RollingWindow" : "FixedWindow",
        timeRange: {
          windowSizeDays: linkSettings.availabilityRange.windowSize ?? null,
        },
      },
    },
  };
};
