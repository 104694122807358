import { Select, SelectOption } from "@clockwise/design-system";
import { range } from "lodash";
import React from "react";
import {
  FOCUS_TIME_IDEAL_MINUTES_DEFAULT,
  FOCUS_TIME_RANGE_MAX,
  FOCUS_TIME_RANGE_MIN,
} from "../../../smart-hold-sync";

type Props = {
  /**
   * In minutes
   */
  threshold?: number;
  disabled?: boolean;
  onChange: (minutes: number) => void;
};

export function ThresholdLabel({
  threshold = FOCUS_TIME_IDEAL_MINUTES_DEFAULT,
  disabled,
  onChange,
}: Props) {
  return (
    <div className="cw-flex cw-items-center cw-gap-2 cw-flex-wrap md:cw-flex-nowrap">
      Schedule up to
      <Select
        disabled={disabled}
        name="focusTimeThreshold"
        value={threshold}
        onChange={onChange}
        size="large"
      >
        {range(FOCUS_TIME_RANGE_MIN, FOCUS_TIME_RANGE_MAX).map((hours) => {
          return (
            <SelectOption value={hours * 60} key={hours}>
              {hours} hrs
            </SelectOption>
          );
        })}
      </Select>
      Focus Time/week
    </div>
  );
}
