import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TravelTimeGoalSettingsFragment = { __typename: 'TravelTimeGoals', id: string, enabled: boolean | null, userAddress: { __typename: 'Address', rawAddress: string } | { __typename: 'NoAddress' } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null };

export type TravelTimeGoalsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TravelTimeGoalsQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', id: string, user: { __typename: 'User', id: string, orgs: { __typename: 'OrgConnection', edges: Array<{ __typename: 'OrgEdge', node: { __typename: 'Org', id: string, smartHoldGoals: { __typename: 'SmartHoldGoals', id: string, travelTime: { __typename: 'TravelTimeGoals', id: string, enabled: boolean | null, userAddress: { __typename: 'Address', rawAddress: string } | { __typename: 'NoAddress' } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null } } } | null } | null> | null } } | null } };

export const TravelTimeGoalSettingsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TravelTimeGoalSettings"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TravelTimeGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"userAddress"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Address"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rawAddress"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"remoteHoldSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"scheduleAs"}},{"kind":"Field","name":{"kind":"Name","value":"notificationStrategy"}}]}}]}}]} as unknown as DocumentNode<TravelTimeGoalSettingsFragment, unknown>;
export const TravelTimeGoalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TravelTimeGoals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"orgs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"smartHoldGoals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"travelTime"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TravelTimeGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TravelTimeGoalSettings"}}]}}]}}]}}]}}]}}]}}]}}]}}]}},...TravelTimeGoalSettingsFragmentDoc.definitions]} as unknown as DocumentNode<TravelTimeGoalsQuery, TravelTimeGoalsQueryVariables>;