import { DateTime } from "luxon";
import React from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";
import { useReadEventTimeDetails, useUpdateEventTimeDetails } from "../hooks/EventCardContext";
import { ECTime } from "./ECTime";

export const Time = ({
  readOnly,
  updateFlexToggle,
  timesHaveChanged = false,
  hideUIWrapper = false,
}: {
  readOnly: boolean;
  updateFlexToggle?: () => void;
  timesHaveChanged?: boolean;
  hideUIWrapper?: boolean;
}) => {
  const { startTime, endTime, isAllDay, timeError } = useReadEventTimeDetails();
  const { setStartTime, setEndTime } = useUpdateEventTimeDetails();

  const validateAndCallback = (newStart: DateTime, newEnd: DateTime) => {
    setStartTime(newStart);
    setEndTime(newEnd);

    const startChanged = !startTime || !newStart.equals(startTime);
    const endChanged = !endTime || !newEnd.equals(endTime);

    if (startChanged || endChanged) {
      updateFlexToggle?.();
    }
  };

  return hideUIWrapper ? (
    <ECTime
      startTime={startTime}
      endTime={endTime}
      readOnly={readOnly}
      onChange={validateAndCallback}
      isAllDay={isAllDay}
      timeError={timeError}
    />
  ) : (
    <EventElementsWrap name="time" showChanges={timesHaveChanged}>
      <ECTime
        startTime={startTime}
        endTime={endTime}
        readOnly={readOnly}
        onChange={validateAndCallback}
        isAllDay={isAllDay}
        timeError={timeError}
      />
    </EventElementsWrap>
  );
};
