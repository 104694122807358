import React from "react";
import { NUXChecklistWrapper } from "../nux-checklist";

export const GetStarted = () => {
  return (
    <div className="cw-flex cw-flex-col cw-items-center cw-w-full">
      <NUXChecklistWrapper />
    </div>
  );
};
