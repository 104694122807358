import React from "react";
import { QueryRenderer, ReadyState } from "react-relay";
import { Route, Routes } from "react-router-dom";
import {
  ChromeSlackWrapperPage,
  chromeSlackWrapperQuery,
} from "../components/chrome-slack-wrapper";
import { paths } from "../constants/site.constant";
import { getCurrentEnvironment } from "../state/relay-environment";

export const ChromeSlackRouter = () => {
  return (
    <Routes>
      <Route index element={<ChromeSlackRouteWrapped />} />
      <Route
        path={`${paths.chromeSlackVerifier}/team/:teamId`}
        element={<ChromeSlackRouteWrapped />}
      />
    </Routes>
  );
};

const ChromeSlackRouteWrapped = () => {
  const environment = getCurrentEnvironment();
  return (
    <QueryRenderer
      environment={environment}
      query={chromeSlackWrapperQuery}
      variables={{}}
      render={(readyState: ReadyState) => {
        if (!Object.keys(readyState.props ?? {}).length) {
          return null;
        }
        if (!readyState.props.viewer) {
          return null;
        }

        return <ChromeSlackWrapperPage {...readyState.props} />;
      }}
    />
  );
};
