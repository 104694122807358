import { EventDetailsAttendees } from "../hooks/useEventPopoverDetails";

export const getStringListOfAttendees = (attendees: EventDetailsAttendees, calendarId: string) => {
  const numAttendees = attendees.length;

  const attendeeNameList = attendees
    .map((attendee, index) => {
      const baseName = attendee.person.email === calendarId ? "You" : attendee.person.displayName;
      if (index === numAttendees - 2) {
        return baseName + " and";
      }
      if (index === numAttendees - 1) {
        return baseName;
      }
      return baseName + ",";
    })
    .join(" ");

  return attendeeNameList;
};
