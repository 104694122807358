import { isEqual, noop } from "lodash";
import React, { createContext, useCallback, useState } from "react";
import invariant from "tiny-invariant";

export type DiffInitValue = {
  id: string;
};

const ReadContext = createContext<DiffInitValue | null>(null);
const WriteContext = createContext<(diffId: DiffInitValue | null) => void>(noop);

export const ActiveEventDiffProvider = ({
  children,
  initValue = null,
}: {
  children: React.ReactNode;
  initValue?: DiffInitValue | null;
}) => {
  const [activeDiff, setActiveDiff] = useState<DiffInitValue | null>(initValue);

  const handleSetActiveDiff = useCallback(
    (newActiveDiff: DiffInitValue | null) => {
      if (isEqual(newActiveDiff, activeDiff)) return;
      setActiveDiff(newActiveDiff);
    },
    [activeDiff],
  );

  return (
    <WriteContext.Provider value={handleSetActiveDiff}>
      <ReadContext.Provider value={activeDiff}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadActiveEventDiff = () => React.useContext(ReadContext);
export const useUpdateActiveEventDiff = () => {
  const context = React.useContext(WriteContext);
  invariant(context, "useUpdateActiveEventDiff must be within ActiveEventDiffProvider");
  return context;
};
