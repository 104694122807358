import { convertTimeSlotsFromString } from "@clockwise/client-commons/src/constants/time-slot";
import {
  ConferencingType,
  DayOfWeek,
  DayRangeFlexibilityType,
  EventConferenceType,
  EventPermissions,
  RecurrenceIntervalTypeEnum,
  RepeatingEventSaveOption,
  ResponseStatusEnum,
  UpdateEventFlexibility,
  UpdateTimeOfDayTimeSlot,
} from "@clockwise/schema";
import { DateTime } from "luxon";
import {
  StandardRecurrenceType,
  standardRecurrenceTypes,
} from "../../event-card/molecules/ECRecurrence";
import { EventCardAttendee, EventCardFlex, SelectedWorkdayOption } from "../../event-card/types";
import { calculateIsAllDay } from "../../event-card/utils/eventCardTime.util";
import { InputForSaveEventChanges } from "./useEditExistingEvent";

export const formatAttendeesToSaveToEvent = (
  attendeeOrgPeople: EventCardAttendee[],
  currentUserResponseStatus: ResponseStatusEnum,
) => {
  return attendeeOrgPeople.map((attendee) => {
    const responseStatus = attendee.isYou ? currentUserResponseStatus : attendee.attendingStatus;
    return {
      isOrganizer: Boolean(attendee.isOrganizer),
      responseStatus: responseStatus || ResponseStatusEnum.NotApplicable,
      calendarId: attendee.primaryCalendar,
      isOptional: attendee.isOptional, //this is nullable on the input and in the BE api call
    };
  });
};

export const formatVCToSave = (
  videoType: ConferencingType | undefined,
  originalVideoType: ConferencingType | undefined,
): EventConferenceType | undefined => {
  if (videoType === originalVideoType) {
    return undefined;
  }

  if (!videoType) return EventConferenceType.Removed; // If there is no longer a videoType, we set to "Removed"

  switch (videoType) {
    case ConferencingType.MEET:
      return EventConferenceType.Meet;
    case ConferencingType.TEAMS_FOR_BUSINESS:
      return EventConferenceType.TeamsForBusiness;
    case ConferencingType.ZOOM:
      return EventConferenceType.Zoom;
    default:
      // We don't cover saving other values (yet)
      return undefined;
  }
};

export const isStandardRecurrence = (recurrence: string): recurrence is StandardRecurrenceType => {
  return standardRecurrenceTypes.includes(recurrence as StandardRecurrenceType);
};

export const formatSparkleInTitle = (eventName: string, isFlexble: boolean) => {
  const alreadyHasSparkle = eventName.startsWith("❇️");
  if (isFlexble) {
    if (!alreadyHasSparkle) {
      return `❇️ ${eventName}`;
    }
  } else {
    if (alreadyHasSparkle) {
      return eventName.replace("❇️ ", "");
    }
  }
  return eventName;
};

export const formatRecurrenceToSelectableOption = ({
  intervalCount,
  intervalType,
  supportCustomRecurrence,
}: {
  intervalCount: number;
  intervalType: RecurrenceIntervalTypeEnum;
  supportCustomRecurrence?: boolean;
}): StandardRecurrenceType | "Custom" => {
  if (intervalType === RecurrenceIntervalTypeEnum.WEEKLY && intervalCount === 1) {
    return "Weekly";
  }
  if (intervalType === RecurrenceIntervalTypeEnum.DAILY && intervalCount === 1) {
    return "Weekdays";
  }
  if (intervalType === RecurrenceIntervalTypeEnum.MONTHLY && intervalCount === 1) {
    return "MonthlyByDate";
  }
  if (intervalType === RecurrenceIntervalTypeEnum.WEEKLY && intervalCount === 2) {
    return "Biweekly";
  }
  return supportCustomRecurrence ? "Custom" : "None";
};

const convertStringToDayOfWeekFlexibility = (range: "Day" | "Week" | null) => {
  switch (range) {
    case "Day":
      return DayRangeFlexibilityType.Day;
    case "Week":
      return DayRangeFlexibilityType.Week;
    default:
      return DayRangeFlexibilityType.DayOfWeek;
  }
};

export const convertStringToDayOfWeek = (day: SelectedWorkdayOption) => {
  switch (day) {
    case "Monday":
      return DayOfWeek.Monday;
    case "Tuesday":
      return DayOfWeek.Tuesday;
    case "Wednesday":
      return DayOfWeek.Wednesday;
    case "Thursday":
      return DayOfWeek.Thursday;
    case "Friday":
      return DayOfWeek.Friday;
    case "Saturday":
      return DayOfWeek.Saturday;
    case "Sunday":
      return DayOfWeek.Sunday;
    default:
      return DayOfWeek.Monday;
  }
};

export const formatFlexUpdates = (
  flexDetails: EventCardFlex,
  eventId: string,
): UpdateEventFlexibility => {
  const range = convertStringToDayOfWeekFlexibility(flexDetails.timeRangeFlexibility?.range);
  const startTime = convertTimeSlotsFromString(flexDetails.timeOfDayFlexibility.startTime, true);
  const endTime = convertTimeSlotsFromString(flexDetails.timeOfDayFlexibility.endTime, false);
  const body: UpdateEventFlexibility = {
    eventId,
    dayRangeFlexibility: {
      type: range,
      allowedDays:
        range === DayRangeFlexibilityType.DayOfWeek
          ? flexDetails.timeRangeFlexibility.daysOfWeekFlexibility.map(convertStringToDayOfWeek)
          : null,
    },
  };
  if (startTime && endTime) {
    body.timeOfDayFlexibility = {
      allowedStart: startTime as UpdateTimeOfDayTimeSlot,
      allowedEnd: endTime as UpdateTimeOfDayTimeSlot,
    };
  }
  return body;
};

export const getBodyForSaveChanges = (
  body: InputForSaveEventChanges,
  permissions: EventPermissions,
) => {
  let eventFields = {};
  switch (permissions) {
    case EventPermissions.ISATTENDEE:
      eventFields = {
        responseStatus: body.responseStatus,
      };
      break;
    case EventPermissions.ALL:
    case EventPermissions.MODIFY:
      eventFields = body;
      break;
    case EventPermissions.INVITE:
      eventFields = {
        responseStatus: body.responseStatus,
        attendees: body.attendees,
      };
      break;
  }
  return {
    ...eventFields,
    sendNotifications: body.sendNotifications,
    repeatingEventSaveOption: body.repeatingEventSaveOption,
  };
};

export const getDisabledSaveOptions = ({
  hasFlexBeenModified,
  hasRecurrenceBeenModified,
  isDescriptionOmitted,
  canModifyFuture,
  canModifyAll,
}: {
  hasFlexBeenModified: boolean;
  hasRecurrenceBeenModified: boolean;
  isDescriptionOmitted: boolean;
  canModifyFuture: boolean;
  canModifyAll: boolean;
}) => {
  const disabledSaveOptions: RepeatingEventSaveOption[] = [];
  if (isDescriptionOmitted || !canModifyFuture) {
    // Modifying this and future instances actually modifies the current event to end
    // immediately, and creates a new, duplicate event with the new recurrence. To do
    // this we must copy all of the details from the current event to make the new one,
    // but if we don't have permissions to view those fields, then we can't do this seamlessly.
    // Therefore, prevent this kind of creation in the rare instances where we cannot read some
    // fields.
    disabledSaveOptions.push(RepeatingEventSaveOption.ThisAndFutureInstances);
  }
  if (hasFlexBeenModified || hasRecurrenceBeenModified || !canModifyAll) {
    disabledSaveOptions.push(RepeatingEventSaveOption.ThisInstanceOnly);
  }
  return disabledSaveOptions;
};

export const isAllDayBasedOnTimes = (startISO?: DateTime, endISO?: DateTime) => {
  if (!startISO || !endISO) return false;
  return calculateIsAllDay(startISO, endISO);
};
