import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const ToggleOffFlex = (args: SvgIconProps) => (
  <SvgIcon {...args}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1188_25429)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 5C3.13401 5 0 8.13401 0 12C0 15.866 3.13401 19 7 19H17C20.866 19 24 15.866 24 12C24 8.13401 20.866 5 17 5H7ZM7 17C9.76142 17 12 14.7614 12 12C12 9.23858 9.76142 7 7 7C4.23858 7 2 9.23858 2 12C2 14.7614 4.23858 17 7 17Z"
          fill="#757575"
        />
      </g>
      <defs>
        <clipPath id="clip0_1188_25429">
          <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
