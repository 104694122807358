import { EFlex } from "@clockwise/client-commons/src/util/event-tag";
import { Day, TimeSlot } from "@clockwise/schema";

export enum StartOrEndSlot {
  StartSlot = "StartSlot",
  EndSlot = "EndSlot",
}

export enum MeetingOrWorkingHours {
  MeetingHours = "meeting hours",
  WorkingHours = "working hours",
}

export enum WorkingHoursTypeEnum {
  Base = "Base",
  PreferredFocusTime = "PreferredFocusTime",
  Meeting = "Meeting",
}

export enum CopyButtonStyle {
  Compact = "Compact",
  Standard = "Standard",
}

export interface SlotRange {
  startSlot: TimeSlot;
  endSlot: TimeSlot;
  startError: boolean;
  endError: boolean;
}

export interface SlotRangeWrapper {
  slots: SlotRange[];
  isValid: boolean;
}
export interface DaySettingAttributes {
  minSlot: TimeSlot;
  maxSlot: TimeSlot;
  excludedSlots: TimeSlot[] | null;
  isOff: boolean;
}

export interface IDaySetting {
  day: Day;
  setting: IWorkingSetting;
}
export interface IWorkingSetting {
  minSlot: TimeSlot;
  maxSlot: TimeSlot;
  excludedSlots: TimeSlot[] | null;
}

export interface IDayOverride {
  day: string;
  setting: IWorkingSetting;
}

export interface IWorkingHours {
  daySettings: IDaySetting[];
}

export type WorkDay = {
  day: Day;
  setting: DaySettingAttributes;
};

export type DayOnOffMap = Partial<Record<Day, boolean>>;

type EFlexWithoutDayOfWeek = Exclude<EFlex, typeof EFlex.DayOfWeek>;

type DayOfWeekData = {
  flex: typeof EFlex.DayOfWeek;
  daysOnOffMap: DayOnOffMap;
};

export type EFlexData =
  | {
      flex: EFlexWithoutDayOfWeek;
    }
  | DayOfWeekData;

export type SlotRangesMap = {
  [key: string]: SlotRangeWrapper;
};
