import {
  all as allTimeSlots,
  maxSlotMaxIndex,
  max as maxSlotsMap,
  min as minSlotsMap,
  timeSlotsCeiling,
  timeSlotsFloor,
} from "@clockwise/client-commons/src/constants/time-slot";
import { getAutopilotTimeOfDayFlexibilitySummaryText } from "@clockwise/client-commons/src/util/time-slot";
import { Tooltip } from "@clockwise/design-system";
import { disabled_border_default } from "@clockwise/design-system/tokens";
import { getStringListOfProfiles } from "@clockwise/web-commons/src/util/profile.util";
import classNames from "classnames";
import { isEqual } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { WorkingHoursByAttendee } from "../hooks/EventFlexConstraintsContext";

type ThumbState = {
  value: number;
  active: boolean;
};

export const FlexTimeRangeSelector = ({
  allowedStart,
  allowedEnd,
  onChange,
  minMaxRecommendedValues,
  showMeetingHoursConflict,
  allUsersWorkingHours,
}: {
  onChange: (allowedStartTime: string, allowedEndTime: string) => void;
  allowedStart: string;
  allowedEnd: string;
  minMaxRecommendedValues: [number, number];
  showMeetingHoursConflict: boolean;
  allUsersWorkingHours: WorkingHoursByAttendee[];
}) => {
  const [minPossibleStart, maxPossibleEnd] = minMaxRecommendedValues;
  const sliderRef0 = useRef<HTMLDivElement>(null);
  const sliderRef1 = useRef<HTMLDivElement>(null);
  const grid = 1;
  const [thumbs, setThumbs] = useState<ThumbState[]>([
    { value: 0, active: false },
    { value: maxSlotMaxIndex, active: false },
  ]);

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(maxSlotMaxIndex);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  // Tracks the movement of the active thumb, and sets thumbs, min, and max state accordingly.
  const onMouseMove = (e: MouseEvent) => {
    if (e.button !== 0) {
      return;
    }

    const thumbIndex = thumbs.findIndex((thumb) => thumb.active);
    if (thumbIndex !== -1 && sliderRef?.current) {
      const rect = sliderRef?.current?.getBoundingClientRect();

      let nextValue = ((e.clientX - rect.left) / rect.width) * (max - min) + min;
      nextValue = Math.max(
        min,
        Math.min(
          max,
          nextValue - (nextValue % grid) + Math.round((nextValue % grid) * grid) * grid, // With grid = 1 this is always just nextValue
        ),
      );

      if (nextValue !== thumbs[thumbIndex].value) {
        const newThumb = thumbs[thumbIndex];
        newThumb.value = nextValue;

        const minValue = Math.min(thumbs[0].value, thumbs[1].value);
        const maxValue = Math.max(thumbs[0].value, thumbs[1].value);

        const newThumbs = [...thumbs];
        newThumbs[thumbIndex] = newThumb;
        setThumbs(newThumbs);

        setMin(Math.min(Math.max(0, minValue - 2), Math.max(0, minPossibleStart - 2)));
        setMax(
          Math.max(
            Math.min(maxSlotMaxIndex, maxValue + 2),
            Math.min(maxSlotMaxIndex, maxPossibleEnd + 2),
          ),
        );
      }
    }
  };

  // If any thumb is active, and the value of that thumb has changed from allowedStart or allowedEnd props,
  // fire onChange to update the event's move range.
  const onMouseUp = (e: MouseEvent) => {
    if (e.button !== 0) {
      return;
    }

    let wasActive = false;

    // Set active back to false, and check if either were active on this mouseUp.
    const nextThumbs = thumbs.map(({ value, active }) => {
      wasActive = wasActive || active;

      return {
        value,
        active: false,
      };
    });

    const nextStartTimeSlotIndex = Math.min(nextThumbs[0].value, nextThumbs[1].value);
    const nextEndTimeSlotIndex = Math.max(nextThumbs[0].value, nextThumbs[1].value);

    const nextAllowedStart = timeSlotsFloor[nextStartTimeSlotIndex];
    const nextAllowedEnd = timeSlotsCeiling[nextEndTimeSlotIndex];

    // Check if we changed anything, if so, send it on up.
    if (
      wasActive &&
      (!isEqual(nextAllowedStart, allowedStart) || !isEqual(nextAllowedEnd, allowedEnd))
    ) {
      onChange(timeSlotsFloor[nextStartTimeSlotIndex], timeSlotsCeiling[nextEndTimeSlotIndex]);
    }
    setThumbs(nextThumbs);
  };

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return function cleanup() {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  });

  // This is our initialization as well as our willReceiveProps. Updates the thumbs based on
  // the event's move range if set, else the attendees' working hours.
  useEffect(() => {
    // Check the existing move range on the event and set or update the current thumbs.
    const allowedStartSlot = timeSlotsFloor.indexOf(allowedStart) + 0 * timeSlotsFloor.length;
    const allowedEndSlot = timeSlotsCeiling.indexOf(allowedEnd) + 0 * timeSlotsCeiling.length;

    // Use the move ranges set on the event, else fallback to the min and max constraints.
    const nextMinSlot = allowedStartSlot ?? minPossibleStart;
    const nextMaxSlot = allowedEndSlot ?? maxPossibleEnd;

    const currentMinSlot = Math.min(thumbs[0].value, thumbs[1].value);
    const currentMaxSlot = Math.max(thumbs[0].value, thumbs[1].value);

    // Active is true if either of the thumbs are currently being dragged by the user.
    const draggingHappening = thumbs[0].active || thumbs[1].active;

    if ((nextMinSlot !== currentMinSlot || nextMaxSlot !== currentMaxSlot) && !draggingHappening) {
      const newThumbs = [...thumbs];
      newThumbs[0].value = nextMinSlot;
      newThumbs[1].value = nextMaxSlot;
      setThumbs(newThumbs);
    }

    setMin(Math.min(Math.max(0, nextMinSlot - 2), Math.max(0, minPossibleStart - 2)));
    setMax(
      Math.max(
        Math.min(maxSlotMaxIndex, nextMaxSlot + 2),
        Math.min(maxSlotMaxIndex, maxPossibleEnd + 2),
      ),
    );
  }, [allowedStart, allowedEnd]);

  const isMinDynamic = thumbs[0].value === minPossibleStart;
  const isMaxDynamic = thumbs[1].value === maxPossibleEnd;
  const summaryText = getAutopilotTimeOfDayFlexibilitySummaryText(
    allTimeSlots[thumbs[0].value],
    allTimeSlots[thumbs[1].value],
    isMinDynamic,
    isMaxDynamic,
  );

  const sendUpChanges = (newThumbs: ThumbState[]) => {
    const nextStartTimeSlotIndex = Math.min(newThumbs[0].value, newThumbs[0].value);
    const nextEndTimeSlotIndex = Math.max(newThumbs[1].value, newThumbs[1].value);
    onChange(timeSlotsFloor[nextStartTimeSlotIndex], timeSlotsCeiling[nextEndTimeSlotIndex]);
  };

  // This is fired upon a mouse down of the slider component track, not the thumbs themselves. It sets the closest thumb to active.
  // When a thumb is active onMouseMove and onMouseUp are engaged.
  const onMouseDownTrack = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button !== 0) {
      return;
    }

    if (sliderRef?.current) {
      const rect = sliderRef?.current?.getBoundingClientRect();

      let value = ((e.clientX - rect.left) / rect.width) * (max - min) + min;
      value = value - (value % grid);

      const closestThumb = [...thumbs].sort((a, b) => {
        const aDiff = Math.abs(value - a.value);
        const bDiff = Math.abs(value - b.value);
        return aDiff - bDiff;
      })[0];

      closestThumb.value = value;
      closestThumb.active = true;

      const minValue = Math.min(thumbs[0].value, thumbs[1].value);
      const maxValue = Math.max(thumbs[0].value, thumbs[1].value);

      const newMin = Math.min(Math.max(0, minPossibleStart - 2), Math.max(0, minValue - 2), min);
      const newMax = Math.max(
        Math.min(maxSlotMaxIndex, maxPossibleEnd + 2),
        Math.min(maxSlotMaxIndex, maxValue + 2),
        max,
      );

      setMin(newMin);
      setMax(newMax);
    }
  };

  // This tracks the click of a thumb, and sets it to active.
  const onMouseDownThumb = (thumbIndex: number) => (e: React.MouseEvent) => {
    if (e.button !== 0) {
      return;
    }

    const nextThumbs = thumbs.map(({ value }, i) => ({
      value,
      active: thumbIndex === i,
    }));
    setThumbs(nextThumbs);
  };

  const refocusThumb = (thumbIndex: number) => {
    if (thumbIndex === 0) {
      sliderRef0.current?.focus();
    } else {
      sliderRef1.current?.focus();
    }
  };

  const onKeydownThumb = (e: React.KeyboardEvent, thumbIndex: number) => {
    const newThumbs = [...thumbs];
    const newThumb = { ...newThumbs[thumbIndex] };
    if (e.key === "ArrowLeft") {
      if (thumbIndex === 0) {
        if (newThumb.value !== 0) {
          if (newThumb.value - 1 <= min) {
            const newMin = Math.max(min - 2, 0);
            setMin(newMin);
          }
          newThumbs[thumbIndex] = {
            value: newThumb.value - 1,
            active: false,
          };
          setThumbs(newThumbs);
          sendUpChanges(newThumbs);
          setTimeout(() => {
            refocusThumb(thumbIndex);
          }, 0);
        }
      } else {
        if (newThumbs[1].value > newThumbs[0].value) {
          if (newThumb.value < max - 2) {
            const newMax = Math.max(max - 2, maxPossibleEnd + 2);
            setMax(newMax);
          }
          newThumbs[thumbIndex] = {
            value: newThumb.value - 1,
            active: false,
          };
          setThumbs(newThumbs);
          sendUpChanges(newThumbs);
          setTimeout(() => {
            refocusThumb(thumbIndex);
          }, 0);
        }
      }
    } else if (e.key === "ArrowRight") {
      if (thumbIndex === 1) {
        if (newThumb.value < maxSlotMaxIndex) {
          if (newThumb.value + 2 > max && max < maxSlotMaxIndex) {
            const newMax = Math.min(maxSlotMaxIndex, max + 2);
            setMax(newMax);
          }
          newThumbs[thumbIndex] = {
            value: newThumb.value + 1,
            active: false,
          };
          setThumbs(newThumbs);
          sendUpChanges(newThumbs);
          setTimeout(() => {
            refocusThumb(thumbIndex);
          }, 0);
        }
      } else if (newThumbs[1].value > newThumbs[0].value) {
        // decrease left when left thumb is in increasing
        if (newThumb.value > min + 2) {
          const newMin = Math.min(min + 2, minPossibleStart - 2);
          setMin(newMin);
        }
        newThumbs[thumbIndex] = {
          value: newThumb.value + 1,
          active: false,
        };
        setThumbs(newThumbs);
        sendUpChanges(newThumbs);
        setTimeout(() => {
          refocusThumb(thumbIndex);
        }, 0);
      }
    }
  };

  const attendeesWithConflicts: {
    primaryEmail: string;
    profile?: {
      givenName?: string | null;
      familyName?: string | null;
    } | null;
    isYou: boolean;
  }[] = [];
  const calIdsWithConflicts: string[] = [];
  for (const workingHours of allUsersWorkingHours) {
    let withinBounds = false;
    const allowedStartSlot = timeSlotsFloor.indexOf(allowedStart) + 0 * timeSlotsFloor.length;
    const allowedEndSlot = timeSlotsCeiling.indexOf(allowedEnd) + 0 * timeSlotsCeiling.length;
    if (
      workingHours.workingHours.start !== null &&
      workingHours.workingHours.end !== null &&
      workingHours.workingHours.start <= allowedStartSlot &&
      workingHours.workingHours.end >= allowedStartSlot &&
      workingHours.workingHours.end >= allowedEndSlot &&
      workingHours.workingHours.start <= allowedEndSlot
    ) {
      withinBounds = true;
    }
    if (!withinBounds) {
      if (workingHours?.profile) {
        attendeesWithConflicts.push({
          primaryEmail: workingHours.calendarId,
          profile: workingHours?.profile,
          isYou: false,
        });
      } else {
        calIdsWithConflicts.push(workingHours.calendarId);
      }
    }
  }

  const attendeesText = getStringListOfProfiles(attendeesWithConflicts.map((a) => a.profile));

  const getThumbLeftPosition = (thumb: ThumbState) => {
    return `${((thumb.value - min) / (max - min)) * 100}%`;
  };

  const getActiveTrackStyle = (): React.CSSProperties => {
    const sortedThumbs = [...thumbs].sort((a, b) => a.value - b.value);
    const minThumb = sortedThumbs[0];
    const maxThumb = sortedThumbs[1];
    const minRelative = ((minThumb.value - min) / (max - min)) * 100;
    const maxRelative = ((maxThumb.value - min) / (max - min)) * 100;

    return {
      left: `${minRelative}%`,
      right: `${100 - maxRelative}%`,
    };
  };

  const ErrorTracks = () => {
    const bothOutsideSingleConstraint =
      (thumbs[0].value <= minPossibleStart && thumbs[1].value <= minPossibleStart) ||
      (thumbs[0].value >= maxPossibleEnd && thumbs[1].value >= maxPossibleEnd);

    if (bothOutsideSingleConstraint) {
      const minLeft = ((thumbs[0].value - min) / (max - min)) * 100;
      const maxLeft = ((thumbs[1].value - min) / (max - min)) * 100;

      return (
        <div
          className="cw-h-1 cw-absolute cw-bg-warning-emphasis"
          style={{ left: `${minLeft}%`, right: `${100 - maxLeft}%` }}
        />
      );
    }

    return (
      <>
        {thumbs.map((thumb, i) => {
          if (thumb.value < minPossibleStart) {
            const minLeft = ((thumb.value - min) / (max - min)) * 100;
            const maxLeft = ((minPossibleStart - min) / (max - min)) * 100;

            return (
              <div
                key={i}
                className="cw-h-1 cw-absolute cw-bg-warning-emphasis"
                style={{ left: `${minLeft}%`, right: `${100 - maxLeft}%` }}
              />
            );
          } else if (thumb.value > maxPossibleEnd) {
            const minLeft = ((maxPossibleEnd - min) / (max - min)) * 100;
            const maxLeft = ((thumb.value - min) / (max - min)) * 100;

            return (
              <div
                key={i}
                className="cw-h-1 cw-absolute cw-bg-warning-emphasis"
                style={{ left: `${minLeft}%`, right: `${100 - maxLeft}%` }}
              />
            );
          }

          return null;
        })}
      </>
    );
  };

  const StartEndConstraints = () => {
    const minLeft = ((minPossibleStart - min) / (max - min)) * 100;
    const maxLeft = ((maxPossibleEnd - min) / (max - min)) * 100;

    const isMinConstraintBroken =
      thumbs[0].value < minPossibleStart || thumbs[1].value < minPossibleStart;
    const isMaxConstraintBroken =
      thumbs[0].value > maxPossibleEnd || thumbs[1].value > maxPossibleEnd;

    return (
      <>
        <div
          className={classNames("cw-w-2 cw-h-2 cw-rounded-full cw-absolute", {
            "cw-bg-default": isMinConstraintBroken,
            "cw-bg-busy-emphasis": !isMinConstraintBroken,
          })}
          style={{
            transform: "translate(-50%, calc(-50% - 2px))",
            left: `${minLeft}%`,
          }}
        />
        <div
          className={classNames("cw-w-2 cw-h-2 cw-rounded-full cw-absolute", {
            "cw-bg-default": isMaxConstraintBroken,
            "cw-bg-busy-emphasis": !isMaxConstraintBroken,
          })}
          style={{
            transform: "translate(-50%, calc(-50% - 2px))",
            left: `${maxLeft}%`,
          }}
        />
      </>
    );
  };

  const NoonMark = () => {
    const minLeft = ((24 - min) / (max - min)) * 100;

    if (minPossibleStart < 24 && maxPossibleEnd > 24) {
      return (
        <div
          className="cw-w-[12px] cw-h-[12px] cw-rounded-full cw-absolute cw-bg-busy-emphasis"
          style={{ left: `${minLeft}%`, transform: "translate(-50%, calc(-50% - 2px))" }}
        >
          <div
            style={{ transform: "translateX(calc(-50% + 3.5px))" }}
            className="cw-absolute cw-body-sm cw-text-busy cw-text-11 cw-whitespace-nowrap	 cw-font-semibold cw-top-full cw-mt-1"
          >
            ☀️ Noon
          </div>
        </div>
      );
    }

    return null;
  };

  const ThumbTooltip = ({ thumb, i }: { thumb: ThumbState; i: number }) => {
    let roundedTimeSlot = thumb.value % allTimeSlots.length;
    roundedTimeSlot = roundedTimeSlot < 0 ? roundedTimeSlot + allTimeSlots.length : roundedTimeSlot;

    const isMin = thumb.value === Math.min(thumbs[0].value, thumbs[1].value);
    const isDynamic = thumb.value === (isMin ? minPossibleStart : maxPossibleEnd);

    let title: JSX.Element | string = isMin
      ? minSlotsMap[allTimeSlots[roundedTimeSlot]]
      : maxSlotsMap[allTimeSlots[roundedTimeSlot]];

    title = isDynamic ? (
      <>
        {`${title} - ${isMin ? "start" : "end"} of all`}
        <br />
        attendee's meeting hours
      </>
    ) : (
      title
    );

    return (
      <Tooltip title={title} open={thumb.active ? true : undefined} placement="top" arrow={true}>
        <div
          ref={i === 0 ? sliderRef0 : sliderRef1}
          onMouseDown={onMouseDownThumb(i)}
          onKeyDown={(e) => {
            onKeydownThumb(e, i);
          }}
          tabIndex={0}
          role="button"
          className="cw-w-1 cw-h-4 cw-absolute cw-rounded-full cw-bg-accent-emphasis cw-cursor-pointer cw-outline-positive-emphasis"
          style={{
            left: getThumbLeftPosition(thumb),
            transform: "translate(-50%, calc(-50% + 6.5px))",
          }}
          cw-id={`${isMin ? "time-of-day-slider-thumb-min" : "time-of-day-slider-thumb-max"}`}
        />
      </Tooltip>
    );
  };

  return (
    <div>
      <div ref={sliderRef} className="cw-select-none cw-relative ">
        <div
          onMouseDown={onMouseDownTrack}
          className="cw-w-full cw-h-full cw-absolute cw-cursor-pointer"
        >
          <div className="cw-relative cw-rounded-full cw-w-full cw-h-1 cw-overflow-hidden cw-py-1">
            <div
              style={{ backgroundColor: disabled_border_default }}
              className="cw-h-1 cw-absolute cw-left-0 cw-right-0"
            />
            <div
              className={`${
                showMeetingHoursConflict ? "cw-bg-warning-emphasis" : "cw-bg-positive-emphasis"
              } cw-h-1 cw-absolute`}
              style={getActiveTrackStyle()}
            />
            <ErrorTracks />
          </div>
          <StartEndConstraints />
          <NoonMark />
        </div>
        {thumbs.map((thumb: ThumbState, i) => (
          <ThumbTooltip key={`${i}-${String(thumb.value)}`} thumb={thumb} i={i} />
        ))}
      </div>
      <div className="cw-body-sm cw-pt-[35px]">
        <div className="">{summaryText}</div>
        <div className="cw-text-12 cw-text-subtle">
          {minSlotsMap[allTimeSlots[thumbs[0].value]]}
          {" - "}
          {maxSlotsMap[allTimeSlots[thumbs[1].value]]}
        </div>
        {attendeesText && (
          <div className="cw-text-warning cw-flex cw-items-start cw-text-12">
            Outside hours for {attendeesText}
          </div>
        )}
      </div>
    </div>
  );
};
