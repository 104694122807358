import { TradeoffTypeEnum } from "@clockwise/schema";
import { Interval } from "luxon";
import React from "react";
import { DiffDetailsById } from "../../chat/ai-chat/utils/types";
import { ECTradeoffs } from "../molecules/ECTradeoffs";
import { Time } from "../molecules/Time";
import { EventElementsWrap } from "./EventElementsWrap";
import { OriginalTimeInfo } from "./OriginalTimeInfo";

export const TimeTradeoffs = ({
  hasDateTimeChanged = false,
  originalInterval,
  readOnly,
  defaultExpandedTradeoff,
  onFlexChange,
  timesHaveChanged = false,
  tradeoffBlocks,
  tradeoffsNeedRecalculation = false,
}: {
  hasDateTimeChanged?: boolean;
  originalInterval?: Interval;
  readOnly: boolean;
  defaultExpandedTradeoff: TradeoffTypeEnum | null;
  onFlexChange?: () => void;
  timesHaveChanged?: boolean;
  tradeoffBlocks: DiffDetailsById["tradeoffBlocks"];
  tradeoffsNeedRecalculation?: boolean;
}) => {
  return (
    <EventElementsWrap name="time" showChanges={timesHaveChanged}>
      <div className="cw-flex-1 cw-flex cw-flex-col cw-gap-1">
        <Time
          readOnly={readOnly}
          updateFlexToggle={onFlexChange}
          timesHaveChanged={timesHaveChanged}
          hideUIWrapper
        />
        <ECTradeoffs
          defaultExpandedTradeoff={defaultExpandedTradeoff}
          tradeoffBlocks={tradeoffBlocks}
          tradeoffsNeedRecalculation={tradeoffsNeedRecalculation}
        />
        {hasDateTimeChanged && originalInterval && (
          <OriginalTimeInfo originalInterval={originalInterval} />
        )}
      </div>
    </EventElementsWrap>
  );
};
