import { ApolloError } from "@apollo/client";
import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { ConferencingType, FlexDetailsInput } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useCallback } from "react";
import { CalendarEventsDocument } from "../../web-app-calendar/apollo/__generated__/CalendarEvents.v2.generated";
import { CreateEventDocument, CreateEventMutation } from "./__generated__/CreateEvent.v2.generated";

export const useCreateEvent = () => {
  const [createEvent, { loading, error }] = useGatewayMutation(CreateEventDocument);

  const onCreateEvent = useCallback(
    async ({
      timeRange,
      timeZone,
      attendees,
      title,
      description,
      recurrenceRule,
      location,
      conferenceType,
      flexDetails,
      callback,
      errorCallback,
      optimisticResponse,
    }: {
      timeRange: string;
      timeZone: string;
      attendees: Array<{ email: string; optional: boolean }>;
      title?: string;
      description?: string;
      recurrenceRule?: RecurrenceRule | null;
      location?: string | null;
      conferenceType?: ConferencingType | null;
      flexDetails?: FlexDetailsInput;
      callback?: (data: CreateEventMutation) => void;
      errorCallback?: (error: ApolloError) => void;
      optimisticResponse?: CreateEventMutation;
    }) => {
      await createEvent({
        variables: {
          timeRange,
          timeZone,
          title,
          description,
          attendees,
          recurrenceRule: recurrenceRule?.toString(),
          location,
          conferenceType,
          flexDetails,
        },
        onCompleted: (data) => {
          callback?.(data);
        },
        onError: (error) => {
          errorCallback?.(error);
        },
        optimisticResponse,
        refetchQueries: [CalendarEventsDocument],
        awaitRefetchQueries: true,
      });
    },
    [createEvent],
  );

  return { onCreateEvent, loading, error };
};
