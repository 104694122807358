import { Button } from "@clockwise/design-system";
import { Close, SvgIconComponent } from "@clockwise/design-system/icons";
import classNames from "classnames";
import React, { ReactNode } from "react";

interface TagProps {
  label: ReactNode;
  icon?: SvgIconComponent;
  onDelete?: () => void;
  disabled?: boolean;
}

const focusClasses =
  "focus:cw-outline-none focus:cw-ring-1 focus:cw-ring-neutral focus:cw-ring-offset-1 focus:cw-ring-offset-neutral focus:cw-bg-neutral-inset-hover";
const colorClasses = "cw-bg-neutral-inset cw-text-neutral";

export const Tag = ({ label, onDelete, disabled = false, icon: Icon }: TagProps) => {
  // Tags should be focusable only if interactive (can be deleted, clicked, etc) and not disabled
  const canFocus = !disabled && !!onDelete;
  return (
    <div
      // TODO Multiselect: Handle noninteractive colors?
      className={classNames(
        "cw-inline-flex cw-gap-1 cw-items-center cw-rounded cw-h-5 cw-caption cw-px-1",
        colorClasses,
        focusClasses,
        { "cw-text-neutral-disabled": disabled },
      )}
      tabIndex={canFocus ? 0 : undefined}
      onKeyUp={({ key }) => {
        switch (key) {
          case "Delete":
          case "Backspace":
            if (!disabled && onDelete) {
              onDelete();
            }
            break;
          default:
            break;
        }
      }}
    >
      {Icon && <Icon className="cw-w-3 cw-h-3" />}
      <span className="group-hover/tag:cw-text-destructive cw-truncate">{label}</span>
      {onDelete && (
        <Button
          startIcon={Close}
          variant="text"
          rounded
          size="mini"
          disabled={disabled}
          tabIndex={-1}
          onClick={onDelete}
        />
      )}
    </div>
  );
};
