import { Link } from "@clockwise/design-system";
import { OpenInNewIcon } from "@clockwise/design-system/icons";
import { fg_brand } from "@clockwise/design-system/src/themes/__generated__/tokens.new";
import React from "react";
import { CalloutBox } from "../atoms/CalloutBox";

export const PaywallControll = () => {
  return (
    <CalloutBox>
      Flexible meeting aren’t supported on the Free plan.{" "}
      <Link>
        <span className="cw-inline-flex cw-flex-row cw-items-center cw-gap-1">
          Upgrade <OpenInNewIcon size={11} fill={fg_brand} />
        </span>
      </Link>{" "}
      to let your flexible meetings move again.
    </CalloutBox>
  );
};
