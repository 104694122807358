type DataSource = {
  id: string;
  name: string | null;
  hasSyncTargets: boolean;
};

const toDataSourceReducer = (
  acc: DataSource[],
  edge: {
    node: { name: string | null; id: string; syncTargets: any[] | null } | null | undefined;
  } | null,
) => {
  const dataSource = edge?.node;

  if (dataSource && dataSource.name) {
    const { id, name } = dataSource;
    const hasSyncTargets = !!dataSource?.syncTargets && dataSource.syncTargets.length > 0;

    acc.push({ id, name, hasSyncTargets });
  }

  return acc;
};

export default toDataSourceReducer;
