import { HomeNav } from "#webapp/src/components/home-nav";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { isElectron } from "@clockwise/web-commons/src/util/browser.util";
import { lastSignInDate } from "@clockwise/web-commons/src/util/local-storage";
import { useIsInIframe } from "@clockwise/web-commons/src/util/react.util";
import { DateTime } from "luxon";
import * as React from "react";
import { useEffect } from "react";
import Div100vh from "react-div-100vh";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

export const AppPublicLayout = () => {
  const isChromeExtension = useSelector((state: IReduxState) => state.webExtension.isWebExtension);
  const authed = useSelector((state: IReduxState) => state.auth.authed);
  const isInIframe = useIsInIframe();

  useEffect(() => {
    // important!
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);

    if (!lastSignInDate.get() && authed) {
      // If signed in and this is not set, set it.
      lastSignInDate.set(DateTime.now().toISO());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Div100vh>
      <div className="cw-flex cw-h-full cw-font-brand cw-bg-default">
        {!isChromeExtension && !isInIframe && !isElectron && <HomeNav />}
        <div className="cw-flex cw-flex-col cw-flex-1 cw-items-center cw-overflow-y-auto cw-mt-20 cw-bg-underlay">
          <Outlet />
        </div>
      </div>
    </Div100vh>
  );
};
