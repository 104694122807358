//////////////////
// IMPORTS
//////////////////

// libraries
import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";

import { EcosystemEnum } from "@clockwise/schema";
import { UpdateFlagsForUserDocument } from "./__generated__/UpdateUserFlagsForUser.generated";
import { WebSettingsExperimentsQueryDocument } from "./__generated__/WebSettingsExperiments.generated";
import { getExperiments } from "./web-settings-experiments";
// components
import { Button, Switch } from "@clockwise/design-system";
import { WebSettingsContainer } from "../web-settings-container";

import { useForceUpdate } from "#webapp/src/hooks";

// state
import { experiments, suggestionsDebugFetchDate } from "#webapp/src/state/local-storage";

// constants

// util
import { page, PageEvents, track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { FlagNamespaces, UserFlags } from "#webapp/src/util/flags.util";
import { logger } from "#webapp/src/util/logger.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { ZonedMoment } from "@clockwise/web-commons/src/util/time-zone.util";

// material-ui
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useOrgPersonForUser } from "../../hooks/useOrgPersonForUser";
import { ClearUserDataButton, ResetOnboardingButton, SwitchBillingGroupButton } from "./buttons";

// ~-~-~-~-~-~-~-
// Experiment Form
// ~-~-~-~-~-~-~-
interface ExperimentFormProps {
  org: any;
}

const ExperimentsForm = ({ org }: ExperimentFormProps) => {
  const forceUpdate = useForceUpdate();
  const experimentsMap = org ? getExperiments(org) : [];

  const onToggleExperiment = (flag: string, on: boolean) => {
    track(TrackingEvents.WEB_SETTINGS.EXPERIMENTS.EXPERIMENT_TOGGLED, { flag, on });
    experiments.setFlag(flag, on);
    forceUpdate();
  };

  return (
    <div>
      <div style={{ fontStyle: "italic", marginBottom: 10, marginTop: 6 }}>
        Note: most experiments require a refresh before taking effect.
      </div>
      {experimentsMap.map((experiment: any) => {
        const { flag, isOn } = experiment;
        const isServicesExperiment = org.experiments.findIndex((exp: any) => exp === flag) > -1;
        return (
          <div key={flag}>
            <Switch
              cw-id={`${flag}${isServicesExperiment ? "-disabled" : ""}`}
              checked={isOn}
              onChange={(checked) => onToggleExperiment(flag, checked)}
              label={flag}
              disabled={isServicesExperiment}
            />
          </div>
        );
      })}
    </div>
  );
};

// ~-~-~-~-~-~-~-
// Suggestions Date Override Form
// ~-~-~-~-~-~-~-
const SuggestionsDateOverrideForm = () => {
  const [suggestionsFetchDate, setSuggestionFetchDate] = useState<ZonedMoment | undefined>(
    suggestionsDebugFetchDate.get(),
  );

  const updateSuggestionDate = (date: Date | undefined) => {
    if (!date) {
      suggestionsDebugFetchDate.remove();
      setSuggestionFetchDate(undefined);
    } else {
      const dateAtNoonPST = date.setHours(17);
      const zonedSuggestionDate = new ZonedMoment(dateAtNoonPST);
      suggestionsDebugFetchDate.set(zonedSuggestionDate);
      setSuggestionFetchDate(zonedSuggestionDate);
    }

    track(TrackingEvents.WEB_SETTINGS.EXPERIMENTS.SUGGESTIONS_FETCH_DATE_OVERRIDE_SET);
  };

  const onChangeSuggestionDate = (muiDate: MaterialUiPickersDate) => {
    const date = muiDate ? muiDate.toDate() : undefined;
    updateSuggestionDate(date);
  };

  return (
    <div className="cw-flex cw-gap-1 cw-mt-4 cw-max-w-sm">
      <DatePicker
        variant="inline"
        value={(suggestionsFetchDate && suggestionsFetchDate.toDate()) || undefined}
        onChange={onChangeSuggestionDate}
        label="Suggestions fetch date override"
        helperText="This will override the fetch date for the suggestions. So, if you want to see what will be fetched on Friday, enter an override date here."
        format="MM/DD/YYYY"
        autoOk
        disableToolbar
        fullWidth
        cw-id="date-override-input-box"
      />
      <div className="cw-pt-3">
        <Button
          variant="outlined"
          onClick={() => updateSuggestionDate(undefined)}
          cw-id="date-override-reset-button"
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

// ~-~-~-~-~-~-~-
// Clear User Flags Button
// ~-~-~-~-~-~-~-
const ClearUserFlagsButton = () => {
  const [clearing, setClearing] = useState(false);
  const [updateFlagsForUser] = useMutation(UpdateFlagsForUserDocument, {
    onCompleted: () => setClearing(false),
    onError: (error) => {
      setClearing(false);
      logger.error(`failed to clearUserFlags: ${error}`);
    },
  });

  const clearUserFlags = () => {
    setClearing(true);
    const synchronousUpdateFlagsForUser = async (
      flagNamespace: string,
      flagNames: string[],
      enable: boolean,
    ) => {
      await updateFlagsForUser({
        variables: {
          input: { flagNamespace, flagNames, enable },
        },
      });
    };

    const flagNames = Object.values(UserFlags.Onboarding).map((v) => v.name);
    const nuxFlagNames = Object.values(UserFlags.NUX).map((v) => v.name);
    synchronousUpdateFlagsForUser(FlagNamespaces.ChromeExtension, flagNames, false);
    synchronousUpdateFlagsForUser(FlagNamespaces.Webapp, flagNames, false);
    synchronousUpdateFlagsForUser(FlagNamespaces.Webapp, nuxFlagNames, false);
    track(TrackingEvents.WEB_SETTINGS.EXPERIMENTS.CLEAR_USER_FLAGS_CLICKED);
  };

  return (
    <Button
      variant="outlined"
      sentiment="destructive"
      onClick={clearUserFlags}
      cw-id="clear-user-flags-button"
      disabled={clearing}
    >
      {clearing ? "Clearing" : "Clear user flags"}
    </Button>
  );
};

// ~-~-~-~-~-~-~-
// Web Settings Experiments
// ~-~-~-~-~-~-~-
interface IProps {}

export const WebSettingsExperiments = (_props: IProps) => {
  React.useEffect(() => {
    page(PageEvents.WEB_SETTINGS_EXPERIMENTS);
  }, []);

  const { loading, data, error } = useQuery(WebSettingsExperimentsQueryDocument);
  const {
    orgPersonForUser: { userId },
  } = useOrgPersonForUser();
  const ecosystem = useEcosystem();

  if (error) {
    logger.error(`failed to load WebSettingsExperiments: ${error}`);
    return (
      <WebSettingsContainer>
        An error occurred while loading Clockwise:
        <br />
        {error.message}
      </WebSettingsContainer>
    );
  }

  if (!data || loading) {
    return <WebSettingsContainer>Loading…</WebSettingsContainer>;
  }

  const viewer = data.viewer;
  const org = getCurrentOrg(viewer);

  if (!org) {
    return <WebSettingsContainer>Loading…</WebSettingsContainer>;
  }

  // do not show in prod or in m365, putting this in parent so hooks/ queries in button aren't called unnecessarily
  const showSwitchBillingGroupButton =
    getEnvironment() !== "production" && ecosystem !== EcosystemEnum.Microsoft && userId;

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  return (
    <WebSettingsContainer>
      <div className="cw-heading-3xl">Experiments</div>
      <ExperimentsForm org={org} />
      <div className="cw-flex cw-gap-5 cw-flex-wrap">
        <div>
          <SuggestionsDateOverrideForm />
          <div className="cw-space-y-2 cw-mt-3">
            <ClearUserFlagsButton />
            <ResetOnboardingButton />
            {showSwitchBillingGroupButton && (
              <SwitchBillingGroupButton orgId={org.id} currentUserId={userId} />
            )}
            <ClearUserDataButton />
          </div>
        </div>
      </div>
    </WebSettingsContainer>
  );
};
