import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import { ProposalOptionsOverlayWrapperV2 } from "./ProposalOptionsOverlayWrapperV2";

export function ProposalOptionsSidebar({
  isVisible,
  dateTime,
  onClick,
}: {
  isVisible: boolean;
  dateTime: DateTime;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}) {
  return (
    <div
      aria-hidden={!isVisible}
      className={classNames(
        "cw-relative cw-h-[calc(100%+1px)] cw-ml-5 cw-flex-shrink-0 cw-transition-[width] cw-duration-300 motion-reduce:cw-duration-0 cw-origin-left cw-overflow-x-scroll cw-scrollbar-gutter-stable",
        {
          "cw-w-0 cw-ease-in": !isVisible,
          "cw-w-12 cw-ease-out cw-border-r cw-border-solid cw-border-r-subtle": isVisible,
        },
      )}
    >
      <div
        className={classNames(
          "cw-absolute cw-top-0 cw-bottom-0 cw-left-0 cw-w-10 cw-transition-transform cw-duration-300 motion-reduce:cw-duration-0",
          {
            "-cw-translate-x-full cw-ease-in": !isVisible,
            "cw-translate-x-0 cw-ease-out": isVisible,
          },
        )}
      >
        <ProposalOptionsOverlayWrapperV2 dateTime={dateTime} onClick={onClick} />
      </div>
    </div>
  );
}
