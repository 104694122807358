import { Autocomplete } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { SearchFilled } from "@clockwise/icons";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

export type AddressSearchMode = "standard" | "onboarding";

const Search = <SearchFilled className="cw-w-4 cw-h-4" />;
export interface IContainer {
  disabled?: boolean;
  label: string;
  loadingIcon?: React.ReactElement;
  onCancel: () => void;
  onSelectAddress: (address: string) => void;
}

export interface IProps extends IContainer {
  mode: AddressSearchMode;
  initialQuery: string;
}

export interface IState {
  query: string;
  anchorEl: HTMLElement | null;
  googleLoaded: boolean;
  focused: boolean;
}

export class AddressSearch extends React.Component<IProps, IState> {
  static defaultProps = {
    mode: "standard" as AddressSearchMode,
    initialQuery: "",
  };

  private googleInterval?: ReturnType<typeof setInterval>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      query: props.initialQuery,
      anchorEl: null,
      googleLoaded: false,
      focused: false,
    };
  }

  public componentDidMount() {
    const googleLoaded = (window as any).google && (window as any).google.maps;
    if (googleLoaded) {
      this.setState({ googleLoaded });
    } else {
      this.googleInterval = setInterval(() => {
        const googleLoaded = (window as any).google && (window as any).google.maps;
        if (googleLoaded) {
          this.setState({ googleLoaded });
          this.googleInterval && clearInterval(this.googleInterval);
          delete this.googleInterval;
        }
      }, 5000);
    }
  }

  public componentWillUnmount() {
    if (this.googleInterval) {
      clearInterval(this.googleInterval);
    }
  }

  // ~-~-~-~-~-~-~-
  // Autosuggest Helpers
  // ~-~-~-~-~-~-~-
  // When suggestion is clicked, Autosuggest needs to populate the input

  private onChange = (query: string) => {
    if (this.state.query === query) {
      return;
    }
    this.setState({ query });
  };

  public render() {
    if (!this.state.googleLoaded) {
      return this.props.loadingIcon || <Loader size="sm" />;
    }

    return (
      <div className={`${this.state.focused ? " active" : ""}`}>
        <PlacesAutocomplete
          value={this.state.query}
          onSelect={() => null}
          onChange={() => null}
          searchOptions={{
            types: ["address"],
          }}
        >
          {({ suggestions, getInputProps }) => {
            return (
              <Autocomplete
                placeholder="Work location"
                leftSectionPointerEvents="none"
                leftSection={Search}
                inputSize="large"
                data={suggestions.map((suggestion) => suggestion.description)}
                onChange={(value) => {
                  this.onChange(value);
                  getInputProps().onChange({ target: { value } });
                }}
                onOptionSubmit={(value) => {
                  this.props.onSelectAddress(value);
                }}
              />
            );
          }}
        </PlacesAutocomplete>
      </div>
    );
  }
}
