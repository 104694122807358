import { daysOfWeek } from "#webapp/src/constants/date.constant";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { Checkbox, Link, Select, SelectOption, Switch, Tooltip } from "@clockwise/design-system";
import { DayEnum } from "@clockwise/schema/v2";
import { FeatureSettingsHoverCard } from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { PaywallBannerUpgradeCta } from "@clockwise/web-commons/src/ui/paywall-banner-upgrade-cta";
import classNames from "classnames";
import React from "react";
import toast from "react-hot-toast";
import { useMonetization } from "../../../../hooks/useMonetization";
import {
  TeamSettingsNoMeetingDayDocument,
  UpdateNoMeetingAutoreplyEnabledDocument,
  UpdateNoMeetingDayDocument,
  UpdateNoMeetingDayEnabledDocument,
  UpdateNoMeetingDaySubscribedDocument,
} from "./__generated__/TeamSettingsNoMeetingDay.v2.generated";

type Props = {
  teamId: string;
};

export const TeamSettingsAdminNoMeetingDay = ({ teamId }: Props) => {
  const { canTeamAvailabilityAndNMDBeEnabled, shouldUserSeeJoinExistingPlan } = useMonetization();
  const { data } = useGatewayQuery(TeamSettingsNoMeetingDayDocument, { variables: { teamId } });

  const isNoMeetingDayEnabled = data?.team?.noMeetingDay.enabled ?? false;
  const noMeetingDayOfWeek = data?.team?.noMeetingDay.day ?? DayEnum.Wednesday;
  const autoreplyEnabled = data?.team?.noMeetingDay.autoreplyEnabled ?? false;
  const useNoMeetingDay = data?.team?.useNoMeetingDay ?? false;
  const isPrimaryTeam = data?.team?.isPrimary ?? false;
  const nonPrimaryTeamTooltip =
    "You can only follow the no-meeting day for your primary team. Switch this team to be your primary team if you want to follow its no-meeting day.";

  const [updateNoMeetingDayEnabled] = useGatewayMutation(UpdateNoMeetingDayEnabledDocument, {
    onCompleted: (res) =>
      toast.success(
        res.updateNoMeetingDayEnabled?.noMeetingDay.enabled
          ? "No-meeting day enabled!"
          : "No-meeting day disabled.",
      ),
    onError: () => toast.error("Failed to update no-meeting day."),
  });
  const [updateNoMeetingDay] = useGatewayMutation(UpdateNoMeetingDayDocument, {
    onCompleted: () => toast.success("No-meeting day updated!"),
    onError: () => toast.error("Failed to update no-meeting day."),
  });
  const [updateNoMeetingAutoreplyEnabled] = useGatewayMutation(
    UpdateNoMeetingAutoreplyEnabledDocument,
    {
      onCompleted: (res) =>
        toast.success(
          res.updateNoMeetingAutoreplyEnabled?.noMeetingDay.autoreplyEnabled
            ? "No-meeting day autoreply enabled!"
            : "No-meeting day autoreply disabled.",
        ),
      onError: () => toast.error("Failed to update autoreply setting."),
    },
  );
  const [updateNoMeetingDaySubscribed] = useGatewayMutation(UpdateNoMeetingDaySubscribedDocument, {
    onCompleted: (res) =>
      toast.success(
        res.updateNoMeetingDaySubscribed?.useNoMeetingDay
          ? "Subscribed to your team's no-meeting day!"
          : "Unsubscribed from your team's no-meeting day.",
      ),
    onError: () => toast.error("Failed to update no-meeting day subscription."),
  });

  const handleNoMeetingDayEnabledDayToggle = () => {
    void updateNoMeetingDayEnabled({
      variables: {
        teamId,
        enabled: !isNoMeetingDayEnabled,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNoMeetingDayEnabled: {
          __typename: "Team",
          id: teamId,
          noMeetingDay: {
            __typename: "NoMeetingDayOptions",
            day: data?.team?.noMeetingDay.day ?? DayEnum.Wednesday,
            enabled: !isNoMeetingDayEnabled,
          },
        },
      },
    });
    track(TrackingEvents.TEAMS.TEAMS_NO_MEETING_DAY_TOGGLED, {
      noMeetingDayEnabled: !isNoMeetingDayEnabled,
    });
  };

  const onDayOfWeekChange = (day: DayEnum) => {
    void updateNoMeetingDay({
      variables: {
        teamId,
        day,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNoMeetingDay: {
          __typename: "Team",
          id: teamId,
          noMeetingDay: {
            __typename: "NoMeetingDayOptions",
            day,
          },
        },
      },
    });
    track(TrackingEvents.TEAMS.TEAMS_NO_MEETING_DAY_OF_WEEK_SELECTED, {
      dayOfWeek: day,
    });
  };

  const handleAutoreplyEnabledDayToggle = () => {
    void updateNoMeetingAutoreplyEnabled({
      variables: {
        teamId,
        enabled: !autoreplyEnabled,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNoMeetingAutoreplyEnabled: {
          __typename: "Team",
          id: teamId,
          noMeetingDay: {
            __typename: "NoMeetingDayOptions",
            autoreplyEnabled: !autoreplyEnabled,
          },
        },
      },
    });
    track(TrackingEvents.TEAMS.TEAMS_NO_MEETING_DAY_AUTOREPLY_TOGGLED, {
      autoreplyEnabled: !autoreplyEnabled,
    });
  };

  const handleUseNoMeetingDayToggle = () => {
    void updateNoMeetingDaySubscribed({
      variables: {
        teamId,
        subscribed: !useNoMeetingDay,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNoMeetingDaySubscribed: {
          __typename: "Team",
          id: teamId,
          useNoMeetingDay: !useNoMeetingDay,
        },
      },
    });
    track(TrackingEvents.TEAMS.TEAMS_NO_MEETING_DAY_TOGGLED_FOR_USER, {
      useNoMeetingDay: !useNoMeetingDay,
    });
  };

  return (
    <div>
      <div className="cw-flex cw-gap-2 cw-mb-4">
        <div className="cw-heading-2xl">
          Establish a{" "}
          <FeatureSettingsHoverCard
            id="nmd-desc-tooltip"
            content={
              <>
                We can dedicate one day of the week as a “no-meeting day.” Clockwise will try to
                keep that day meeting-free for team members, and can optionally notify meeting
                organizers when they schedule over your team's no-meeting day. To increase Focus
                Time, we strongly suggest you use this feature.{" "}
                <Link
                  href="https://getclockwise.helpscoutdocs.com/article/44-team-no-meeting-day"
                  target="_blank"
                  rel="noreferrer"
                  onEmphasis
                >
                  Learn more
                </Link>
              </>
            }
          >
            <span
              className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1"
              aria-describedby="nmd-desc-tooltip"
            >
              no-meeting day
            </span>
          </FeatureSettingsHoverCard>
          ?
        </div>
        <Switch
          checked={isNoMeetingDayEnabled && canTeamAvailabilityAndNMDBeEnabled}
          onChange={handleNoMeetingDayEnabledDayToggle}
          disabled={!canTeamAvailabilityAndNMDBeEnabled}
          label={
            <div
              className={classNames({
                "cw-text-positive": isNoMeetingDayEnabled && canTeamAvailabilityAndNMDBeEnabled,
              })}
            >
              {isNoMeetingDayEnabled && canTeamAvailabilityAndNMDBeEnabled ? "Yes" : "No"}
            </div>
          }
        />
      </div>
      {!canTeamAvailabilityAndNMDBeEnabled && (
        <PaywallBannerUpgradeCta
          showJoinExistingPlan={shouldUserSeeJoinExistingPlan}
          bannerType="NMDLocked"
          pricingTracking={() => track(TrackingEvents.PAYWALLS.M2_ADMIN_NMD_PRICING_CLICKED)}
          billingTracking={() => track(TrackingEvents.PAYWALLS.M2_ADMIN_NMD_JOIN_PLAN_CLICKED)}
        />
      )}
      <div
        className={classNames("cw-flex cw-flex-wrap cw-items-center cw-body-lg cw-gap-2 cw-mb-4", {
          "cw-text-default-disabled": !isNoMeetingDayEnabled,
        })}
      >
        Which day should the no-meeting day be?
        <Select
          value={noMeetingDayOfWeek}
          onChange={onDayOfWeekChange}
          name="No Meeting Day of Week"
          disabled={!isNoMeetingDayEnabled || !canTeamAvailabilityAndNMDBeEnabled}
        >
          {daysOfWeek.map((d) => (
            <SelectOption key={d} value={d}>
              {d}
            </SelectOption>
          ))}
        </Select>
      </div>
      <div>
        <Checkbox
          disabled={!isNoMeetingDayEnabled}
          checked={autoreplyEnabled}
          onChange={handleAutoreplyEnabledDayToggle}
          label={
            <div>
              <div
                className={classNames("cw-body-lg", {
                  "cw-text-default-disabled": !isNoMeetingDayEnabled,
                })}
              >
                Autoreply to meeting organizers when they schedule over your team’s no-meeting day
              </div>
              <div
                className={classNames("cw-body-base", {
                  "cw-text-muted": isNoMeetingDayEnabled,
                  "cw-text-default-disabled": !isNoMeetingDayEnabled,
                })}
              >
                Clockwise will send an email informing them of your no-meeting day and suggesting
                they reschedule
              </div>
            </div>
          }
        />
      </div>
      <div>
        {isPrimaryTeam ? (
          <Checkbox
            disabled={!isNoMeetingDayEnabled}
            checked={useNoMeetingDay}
            onChange={handleUseNoMeetingDayToggle}
            label={
              <div>
                <div
                  className={classNames("cw-body-lg", {
                    "cw-text-default-disabled": !isNoMeetingDayEnabled,
                  })}
                >
                  Keep {noMeetingDayOfWeek} meeting-free for me when possible
                </div>
              </div>
            }
          />
        ) : (
          <Tooltip title={nonPrimaryTeamTooltip} placement="bottom-start">
            <div>
              <Checkbox
                disabled
                checked={false}
                label={
                  <div>
                    <div className="cw-body-lg cw-text-default-disabled">
                      Keep {noMeetingDayOfWeek} meeting-free for me when possible
                    </div>
                  </div>
                }
              />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
