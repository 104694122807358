import React from "react";

export const CalendarHorizontalGuideLines = () => (
  <>
    <CalendarHorizontalGuideLinesForHour />
    <CalendarHorizontalGuideLinesForHalfHour />
  </>
);

const CalendarHorizontalGuideLinesForHour = () => (
  <div className="cw-h-full cw-w-full cw-absolute cw-pointer-events-none">
    {new Array(25).fill(null).map((_n, i) => {
      const top = i === 0 ? -1 : `${(100 * i) / 24}%`;
      return (
        <div
          key={i}
          className="cw-absolute cw-border-b cw-border-solid cw-border-b-subtle cw-right-0 cw-left-0 cw-w-full"
          style={{ top }}
        />
      );
    })}
  </div>
);

const CalendarHorizontalGuideLinesForHalfHour = () => (
  <div className="cw-h-full cw-w-full cw-absolute cw-pointer-events-none">
    {new Array(25).fill(null).map((_n, i) => {
      const top = i === 0 ? -1 : `${(100 * (i - 0.5)) / 24}%`;
      return (
        <div
          key={`thirty-min-lines-${i}`}
          className="cw-absolute cw-border-b cw-border-dashed cw-border-b-subtle cw-right-0 cw-left-0 cw-w-full"
          style={{ top }}
        />
      );
    })}
  </div>
);
