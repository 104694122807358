import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import React from "react";
import { accentBorderClasses } from "./PersonalizedStatsRowGateway";
import { getCurrentWeek } from "./util/getCurrentWeek";

export const PersonalizedStatsRowSkeleton = () => {
  return (
    <div
      className={classNames(
        "cw-grid cw-gap-4 cw-mb-16",
        "cw-grid-cols-1 md:cw-grid-cols-3 lg:cw-grid-cols-4",
      )}
    >
      <div
        className={classNames(
          accentBorderClasses,
          "cw-border-t-[#003f2e] cw-flex cw-flex-col cw-items-center",
          "md:cw-col-span-3 lg:cw-col-span-1 lg:cw-items-start",
        )}
      >
        <p className="cw-heading-lg">This week</p>
        <p className="cw-heading-lg cw-font-light">{getCurrentWeek()}</p>
      </div>
      <div className="cw-flex cw-flex-col cw-items-center cw-border-t cw-border-solid cw-border-t-[#A6A6A6]">
        <Skeleton width={90} height={60} />
        <Skeleton width={80} height={22} />
        <Skeleton width={130} height={18} />
      </div>
      <div className="cw-flex cw-flex-col cw-items-center cw-border-t cw-border-solid cw-border-t-[#A6A6A6]">
        <Skeleton width={90} height={60} />
        <Skeleton width={140} height={22} />
        <Skeleton width={130} height={18} />
      </div>
      <div className="cw-flex cw-flex-col cw-items-center cw-border-t cw-border-solid cw-border-t-[#A6A6A6]">
        <Skeleton width={90} height={60} />
        <Skeleton width={120} height={22} />
        <Skeleton width={130} height={18} />
      </div>
    </div>
  );
};
