import copyToClipboard from "#webapp/src/util/copyToClipboard";
import {
  FileCopyOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@clockwise/design-system/icons";
import { isEmpty, isNil } from "lodash";
import React from "react";
import toast from "react-hot-toast";
import { CopyPasteableScores, RawCalendarScores } from "./types";

export const CalendarScores = ({
  scores,
  eventId,
}: {
  scores: RawCalendarScores;
  eventId: string;
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const copyText = () => {
    // Convert the scores to a format that can be copied to the clipboard
    // {
    //   "eventId": {
    //     "calendarId": {
    //       "feature": "delta"
    //     }
    //   }
    // }
    const scoresJSON = scores.reduce((acc: CopyPasteableScores, score) => {
      const readableScores = score.scores?.reduce((acc: Record<string, number>, score) => {
        acc[score.feature] = score.delta;
        return acc;
      }, {});

      if (!isEmpty(readableScores)) {
        acc[score.calendarId] = readableScores;
      }
      return acc;
    }, {});

    copyToClipboard(JSON.stringify({ [eventId]: scoresJSON }, null, 2));
    toast.success("Scores copied to clipboard");
  };

  return (
    <div className="cw-flex cw-flex-col cw-gap-y-1 cw-px-2 cw-py-1 cw-rounded cw-cursor-pointer cw-bg-neutral-inset cw-mx-1">
      <div className="cw-flex cw-flex-row cw-justify-between cw-items-center">
        <div className="cw-flex cw-items-center cw-gap-1 cw-flex-grow" onClick={toggleExpanded}>
          <div className="cw-text-12 cw-font-medium cw-text-default"> Scores</div>
          {isExpanded && <KeyboardArrowUp className="cw-w-4 cw-h-4" />}
          {!isExpanded && <KeyboardArrowDown className="cw-w-4 cw-h-4" />}
        </div>
        <div onClick={copyText} className="cw-flex cw-items-center">
          <FileCopyOutlined className="cw-w-4 cw-h-4 cw-cursor-pointer hover:cw-text-muted" />
        </div>
      </div>
      {isExpanded && (
        <div className="cw-flex cw-flex-col cw-gap-y-1" onClick={toggleExpanded}>
          {scores?.map((score) => {
            const { calendarId, scores } = score;
            if (isEmpty(scores) || isNil(scores)) return null;
            return (
              <div key={score.calendarId} onClick={toggleExpanded}>
                <div className="cw-text-xs cw-font-semibold">{score.calendarId}</div>
                <div>
                  {scores.map((rawScore) => (
                    <div
                      key={calendarId + rawScore.feature}
                      className="cw-flex cw-flex-row cw-justify-between cw-text-xs cw-items-center"
                    >
                      <div>{rawScore.feature}</div>
                      <div className="cw-flex-grow cw-mx-1 cw-border-b cw-border-dotted cw-border-muted" />
                      <div>{rawScore.delta}</div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
