import { noop } from "lodash";
import React, { ReactNode, createContext, useContext, useMemo } from "react";
import invariant from "tiny-invariant";

interface ProposalOverlayState {
  isVisible: boolean;
  isEnabled: boolean;
  hoveredSuggestion: string | null;
}

const defaultState: ProposalOverlayState = {
  isVisible: false,
  isEnabled: false,
  hoveredSuggestion: null,
};

const ReadContext = createContext<ProposalOverlayState>(defaultState);
const WriteContext = createContext<{
  setIsVisible: (isVisible: boolean) => void;
  setIsEnabled: (isEnabled: boolean) => void;
  setHoveredSuggestion: (interval: string | null) => void;
}>({
  setIsVisible: noop,
  setIsEnabled: noop,
  setHoveredSuggestion: noop,
});

type ProviderProps = {
  children: ReactNode;
  initValue: ProposalOverlayState;
};

export type ProposalOptionsOverlayVariant = "rail" | "default";

export const ProposalOptionsOverlayProviderV2 = ({ children, initValue }: ProviderProps) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(
    initValue.isVisible ?? defaultState.isVisible,
  );
  const [isEnabled, setIsEnabled] = React.useState<boolean>(
    initValue.isEnabled ?? defaultState.isEnabled,
  );
  const [hoveredSuggestion, setHoveredSuggestion] = React.useState<string | null>(
    initValue.hoveredSuggestion ?? defaultState.hoveredSuggestion,
  );

  const writeContext = useMemo(
    () => ({
      setIsVisible,
      setIsEnabled,
      setHoveredSuggestion,
    }),
    [],
  );

  const readContext = useMemo(
    () => ({
      isVisible,
      isEnabled,
      hoveredSuggestion,
    }),
    [isVisible, isEnabled, hoveredSuggestion],
  );

  return (
    <WriteContext.Provider value={writeContext}>
      <ReadContext.Provider value={readContext}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadProposalOptionsOverlayV2 = () => useContext(ReadContext);

export const useUpdateProposalOptionsOverlayV2 = () => {
  const context = useContext(WriteContext);
  invariant(
    context,
    "useUpdateProposalOptionsOverlay must be within ProposalOptionsOverlayProvider",
  );
  return context;
};
