import { logger } from "#webapp/src/util/logger.util";
import { Divider } from "@clockwise/design-system";
import { useEventCategory } from "@clockwise/web-commons/src/hooks/useEventCategory";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { generateRescheduleCQL } from "@clockwise/web-commons/src/util/generateCQL";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { truncate } from "lodash";
import { DateTime } from "luxon";
import { default as React } from "react";
import { useDispatch } from "react-redux";
import { ResponseStatusEnum } from "../../../../__schema__";
import {
  addSelectedRescheduleOption,
  SelectedRescheduleOptionEntrypoint,
} from "../../../../state/actions/reschedule-event-modal.actions";
import { useAIMessageContext } from "../../../chat/ai-chat/hooks/AIMessageContext";
import { useFocusChatTextInput } from "../../../chat/ai-chat/hooks/useFocusChatTextInput";
import { useProcessMessage } from "../../../chat/ai-chat/hooks/useProcessMessage";
import { useScrollChatToBottom } from "../../../chat/ai-chat/hooks/useScrollChatToBottom";
import { usePlannerContext } from "../../Context";
import useEditExistingEvent from "../../hooks/useEditExistingEvent";
import { eventToast } from "../../notifiation-event/EventToast";
import { RSVPButtons } from "../../rsvp-buttons";
import { EventPermissions } from "../../types";
import { EventCategory } from "../atoms/EventCategory";
import { ClickableContainer, EventDetailsHeader } from "../atoms/EventDetails";
import { EventFlexibility } from "../atoms/EventFlexibility";
import { JoinVideoLink } from "../atoms/JoinVideoLink";
import { QuickReschedule } from "../atoms/QuickReschedule";
import { ToggleAttendeeCalendars } from "../atoms/ToggleAttendeeCalendars";
import { useEventPopoverDetails } from "../hooks/useEventPopoverDetails";
import { Footer } from "../molecules/Footer";
import { DeleteButton, EditButton, ExpandButton, Header } from "../molecules/Header";
import { VideoLink } from "../types";

export const EventContextMenu = ({
  externalEventId,
  title,
  onClose,
  responseState,
  onOpenCard,
  startTime,
  calendarId,
  videoLink,
  eventPermissions: { canModify, canRSVP, canDelete, canRemove },
  duration,
  readOnly = false,
}: {
  externalEventId: string;
  title: string;
  onClose: () => void;
  responseState: ResponseStatusEnum | "Proposed";
  onOpenCard: () => void;
  startTime: DateTime;
  calendarId: string;
  videoLink: VideoLink | null;
  eventPermissions: EventPermissions;
  duration: number;
  readOnly?: boolean;
}) => {
  const track = useTracking();
  const scrollChatToBottom = useScrollChatToBottom();
  const focusChatTextInput = useFocusChatTextInput();
  const { processMessage } = useProcessMessage(focusChatTextInput);
  const updateActiveEvent = useUpdateActiveEvent();
  const { shouldExcludeAi } = useAIMessageContext();

  const dispatch = useDispatch();

  const details = useEventPopoverDetails({ externalEventId, calendarId });

  const {
    attendees,
    hasExternalAttendees,
    id,
    isHold,
    isMyEvent,
    loading: loadingDetails,
    eventTimeString,
    ownerDisplayName,
  } = details;

  const { category, categoryOptions, update: updateEventCategory } = useEventCategory({
    calendarId: calendarId ?? "",
    eventId: externalEventId ?? "",
    useQuery: useGatewayQuery,
    useMutation: useGatewayMutation,
  });

  const validRSVPState =
    responseState === "Proposed" ? ResponseStatusEnum.NotApplicable : responseState;

  const onOpenEventDetails = () => {
    onOpenCard();
  };

  const submitRescheduleMessage = () => {
    if (canModify) {
      track(TrackingEvents.EVENT_POPOVER.RESCHEDULE_CLICKED);
    } else {
      track(TrackingEvents.EVENT_POPOVER.PROPOSE_NEW_TIME_CLICKED);
    }
    const startTimeZoned = startTime.setZone(getRenderTimeZone());
    updateActiveEvent(null);

    const cql = externalEventId ? generateRescheduleCQL(externalEventId) : undefined;

    scrollChatToBottom();
    void processMessage(
      `Reschedule ${title}`,
      {
        eventMentions: [
          {
            externalEventId,
            startTime: startTimeZoned.toISO(),
            title,
          },
        ],
        personMentions: [],
      },
      cql,
    );
    onClose();
  };

  const truncatedTitleForToasts = truncate(title, { length: 20 });

  // If multi cal, owner of event will not match the current user
  const isMultiCalEvent = !isMyEvent;

  const showFooter = canRSVP || isMultiCalEvent;

  const selectSchedulingOption = (start: string) => {
    dispatch(
      addSelectedRescheduleOption({
        id: id ?? "",
        externalEventId,
        duration,
        title,
        proposedStartTimeISO: start,
        currentStartTimeISO: startTime.toISO(),
        entryPoint: SelectedRescheduleOptionEntrypoint.QuickReschedule,
        isHold,
      }),
    );
    onClose();
  };

  const zone = getRenderTimeZone();

  const showQuickRescheduleDivider = !isMultiCalEvent && !readOnly;
  const canShowVideoLink = !!videoLink?.uri && !isMultiCalEvent;

  //zone properly
  const isUpcomingMeeting = startTime.diffNow().as("minutes") >= -5;

  return (
    <>
      <Header
        onClose={onClose}
        PrimaryButton={
          canShowVideoLink ? (
            <JoinVideoLink
              videoLink={videoLink}
              onClose={onClose}
              isUpcomingMeeting={isUpcomingMeeting}
              hideIcon
            />
          ) : null
        }
      >
        {canModify && <EditButton onClick={onOpenEventDetails} />}
        {!canModify && <ExpandButton onClick={onOpenEventDetails} />}
        {canDelete || canRemove ? (
          <DeleteHeaderButton
            calendarId={calendarId}
            canDelete={canDelete}
            externalEventId={externalEventId}
            eventTitle={title}
            onClose={onClose}
          />
        ) : null}
      </Header>
      <div className="cw-pt-1.5 cw-pb-3 cw-px-2">
        <ClickableContainer onClick={onOpenEventDetails}>
          <EventDetailsHeader
            attendees={attendees}
            calendarId={calendarId}
            loading={loadingDetails}
            title={title}
            isLocked={false}
            eventTimeString={eventTimeString}
            categoryColor={category.color || undefined}
          />
        </ClickableContainer>
        {attendees.filter((a) => a.email !== calendarId).length > 0 && (
          <>
            <Divider spacing="xs" />
            <ToggleAttendeeCalendars attendees={attendees} loading={loadingDetails} />
          </>
        )}
        <Divider spacing="xs" />
        <EventFlexibility
          eventId={externalEventId}
          calendarId={calendarId}
          loadingEventContextMenu={loadingDetails}
        />
        <EventCategory
          category={category}
          categoryOptions={categoryOptions}
          onChange={(category) => {
            updateEventCategory(category.value);
          }}
          loading={loadingDetails}
        />
        {showQuickRescheduleDivider && <Divider spacing="xs" />}
        {!readOnly && (
          <QuickReschedule
            hasExternalAttendees={hasExternalAttendees}
            shouldExcludeAi={!!shouldExcludeAi}
            submitRescheduleMessage={submitRescheduleMessage}
            onSelectSchedulingOption={selectSchedulingOption}
            externalEventId={externalEventId}
            timeZone={zone}
            duration={duration}
            calendarId={calendarId}
            canModify={canModify}
          />
        )}
      </div>
      {showFooter && (
        <Footer
          canRSVP={canRSVP}
          showEventOwner={isMultiCalEvent}
          ownerDisplayName={ownerDisplayName}
          loading={loadingDetails}
          calendarId={calendarId}
          rsvpButtons={
            canRSVP && (
              <RSVPButtons
                externalEventId={externalEventId}
                responseState={validRSVPState}
                onClose={onClose}
                calendarId={calendarId}
                toastOptions={{
                  title: truncatedTitleForToasts,
                  showViewEventButton: true,
                }}
                variant="compact"
              />
            )
          }
        />
      )}
    </>
  );
};

const DeleteHeaderButton = ({
  calendarId,
  canDelete,
  eventTitle,
  externalEventId,
  onClose,
}: {
  calendarId: string;
  canDelete: boolean;
  eventTitle: string;
  externalEventId: string;
  onClose: () => void;
}) => {
  const { refetchEvents } = usePlannerContext();
  const { onDeleteEvent, deletingEvent } = useEditExistingEvent(
    externalEventId,
    calendarId,
    false,
    {
      onDeletionCompleted: () => {
        eventToast.success({
          operation: "CANCEL",
          title: eventTitle,
        });
        onClose();
        void refetchEvents?.();
      },
      onDeletionError: (err) => {
        eventToast.error({
          operation: "CANCEL",
          title: eventTitle,
        });
        logger.error("unable to delete event from event popover", {
          error: err || "unable to delete event from event popover",
          externalEventId,
          calendarId,
        });
      },
    },
  );

  const handleDeleteEvent = () => {
    void onDeleteEvent();
  };

  return (
    <DeleteButton
      tooltip={canDelete ? "Delete event" : "Remove from my calendar"}
      onClick={handleDeleteEvent}
      disabled={deletingEvent}
    />
  );
};
