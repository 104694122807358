import { Menu, MenuItem } from "@clockwise/design-system";
import { Business } from "@clockwise/design-system/icons";
import { Home, LocationOff } from "@clockwise/icons";
import { LocationTypeEnum, Maybe } from "@clockwise/schema";
import { WorkingLocationType } from "@clockwise/schema/v2";
import classNames from "classnames";
import React from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";

type Props = {
  onSelect: (value: {
    id: string;
    type: WorkingLocationType;
    name: string | null;
    timeZone: string | null;
    address: string | null;
  }) => void;
  readOnly?: boolean;
  selected?: string;
  options: {
    id: string;
    type: WorkingLocationType;
    name: Maybe<string> | undefined;
    timeZone: Maybe<string> | undefined;
    address: Maybe<string> | undefined;
  }[];
  showChanges?: boolean;
};

export const convertLocationTypeFromWebserverToGateway = (type: LocationTypeEnum) => {
  switch (type) {
    case LocationTypeEnum.HOME:
      return WorkingLocationType.Home;
    case LocationTypeEnum.OFFICE:
      return WorkingLocationType.Office;
    case LocationTypeEnum.OTHER:
      return WorkingLocationType.Other;
  }
};

const workingLocationTypeIcon = (type: WorkingLocationType) => {
  switch (type) {
    case WorkingLocationType.Home:
      return Home;
    case WorkingLocationType.Office:
      return Business;
    case WorkingLocationType.Other:
      return LocationOff;
  }
};

export const ECWorkingLocation = ({
  readOnly = false,
  onSelect,
  selected,
  showChanges,
  options,
}: Props) => {
  const selectedOption = options.find((o) => o.id === selected);

  const handleChange = (value: {
    id: string;
    type: WorkingLocationType;
    name: string | null;
    timeZone: string | null;
    address: string | null;
  }) => {
    if (readOnly) return;

    onSelect(value);
  };

  return (
    <EventElementsWrap
      name="workingLocation"
      showChanges={showChanges}
      icon={selectedOption ? workingLocationTypeIcon(selectedOption.type) : undefined}
    >
      <div className="cw-flex cw-flex-1 cw-justify-between cw-items-center">
        <div className={classNames("cw-flex cw-flex-1 cw-items-center")}>
          <Menu disabled={readOnly} fullWidth size="small" label={selectedOption?.name}>
            {options.map((option) => {
              return (
                <MenuItem
                  key={`working-locatino-option-${option.id}`}
                  icon={workingLocationTypeIcon(option.type)}
                  onClick={() => {
                    handleChange({
                      id: option.id,
                      type: option.type,
                      name: option.name || null,
                      timeZone: option.timeZone || null,
                      address: option.address || null,
                    });
                  }}
                >
                  {option.name}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
    </EventElementsWrap>
  );
};
