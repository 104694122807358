import {
  FeatureSetting,
  FeatureSettingAside,
  FeatureSettingBody,
  FeatureSettingHeading,
  FeatureSettingMain,
  FeatureSettingsHoverCard,
} from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import React from "react";
import { TrackingEvents, track } from "../../util/analytics.util";
import { PersonalDataSource } from "../personal-data-sources";
import { ScrollIntoView } from "../ui-focus-wrappers/ScrollIntoView";
import CalendarSyncIllustration from "./CalendarSyncIllustration";
import Tooltip from "./Tooltip";

const CalendarSync = () => {
  const handleAdded = () => {
    track(TrackingEvents.SETTINGS.DATA_SOURCE_ADD);
  };

  const handleRemoved = () => {
    track(TrackingEvents.SETTINGS.DATA_SOURCE_DELETED);
  };

  return (
    <ScrollIntoView focusSection="sync" highlight>
      <FeatureSetting title="Personal Calendar Sync">
        <FeatureSettingAside>
          <CalendarSyncIllustration />
        </FeatureSettingAside>
        <FeatureSettingMain>
          <FeatureSettingHeading>
            <h2
              className="cw-heading-2xl cw-m-0"
              aria-label="Do you want to sync events from another calendar?"
            >
              Do you want to{" "}
              <FeatureSettingsHoverCard content={<Tooltip />}>
                <span className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1">
                  sync events
                </span>
              </FeatureSettingsHoverCard>{" "}
              from another calendar?
            </h2>
          </FeatureSettingHeading>
          <FeatureSettingBody>
            <p className={"cw-mt-0 cw-mb-1 cw-body-base"}>
              Coworkers will never see the title of your personal event. It will appear as "Busy
              (via Clockwise)"
            </p>
            <PersonalDataSource onAdded={handleAdded} onRemoved={handleRemoved} />
          </FeatureSettingBody>
        </FeatureSettingMain>
      </FeatureSetting>
    </ScrollIntoView>
  );
};

export default CalendarSync;
