import { makeStyles } from "@material-ui/core";
import * as React from "react";

import { colors, fontFamilySans } from "@clockwise/web-commons/src/styles";
import {
  CopyButtonStyle,
  IWorkingHours,
  MeetingOrWorkingHours,
  validateMeetingHours,
} from "@clockwise/web-commons/src/ui/working-hours";
import { getValidMeetingHoursFromWorkingHours } from "@clockwise/web-commons/src/ui/working-hours/getValidMeetingHoursFromWorkingHours";
import { WeekSettingContainer } from "../week-setting";

export interface SplitMeetingHoursProps {
  workingHours: IWorkingHours;
  meetingHours: IWorkingHours;
  copyButtonStyle: CopyButtonStyle | null;
  onUpdateMeetingHours: (updatedMeetingHours: IWorkingHours) => void;
}

const useStyles = makeStyles({
  invalidText: {
    marginBottom: 15,
    fontSize: 14,
    fontFamily: fontFamilySans,
    color: colors.red.standard,
  },
});

export const SplitMeetingHours = ({
  workingHours,
  meetingHours,
  copyButtonStyle,
  onUpdateMeetingHours,
}: SplitMeetingHoursProps) => {
  const classes = useStyles();
  const [isValid, setIsValid] = React.useState(validateMeetingHours(workingHours, meetingHours));

  React.useEffect(() => {
    setIsValid(validateMeetingHours(workingHours, meetingHours));
  }, [workingHours, meetingHours]);

  // On mount, if meeting hours are not valid, update to a valid state
  React.useEffect(() => {
    if (!isValid) {
      // need to do some logic here to update the specific meeting hour and make it type: Meeting
      const validMeetingHoursFromWorkingHours = getValidMeetingHoursFromWorkingHours(
        workingHours,
        meetingHours,
      );
      onUpdateMeetingHours(validMeetingHoursFromWorkingHours);
    }
  }, []);

  const handleUpdateMeetingHours = (updatedMeetingHours: IWorkingHours) => {
    const updatedValidity = validateMeetingHours(workingHours, updatedMeetingHours);
    setIsValid(updatedValidity);

    if (updatedValidity) {
      onUpdateMeetingHours(updatedMeetingHours);
    }
  };

  return (
    <>
      {!isValid && (
        <div className={classes.invalidText}>Meeting hours must be within your working hours.</div>
      )}
      <WeekSettingContainer
        isMeetingOrWorkingHours={MeetingOrWorkingHours.MeetingHours}
        workingHours={meetingHours}
        copyButtonStyle={copyButtonStyle}
        onUpdateWorkingHours={handleUpdateMeetingHours}
      />
    </>
  );
};
