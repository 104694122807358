import { round } from "lodash";
import { Duration } from "luxon";
import { CALENDAR_SLOTS_RENDERED, GUTTER_WIDTH_PERCENT } from "../constants";
import { ColumnedItem, ICalPosition } from "../types";

export const getPosition = ({
  columnedItem,
  columnCount,
  minDuration = Duration.fromObject({ minutes: 30 }),
  gutters = false,
  minWidth,
  fullWidth,
}: {
  columnedItem: ColumnedItem;
  columnCount: number;
  minDuration?: Duration;
  gutters?: boolean;
  minWidth?: number;
  fullWidth?: boolean;
}): ICalPosition => {
  const {
    item: { interval },
    placement: {
      columnIndex: column,
      subColumnIndex: subColumn,
      subColumnCount,
      subColumnOffset,
      subColumnSpan,
    },
  } = columnedItem;

  const heightPercent = 100 / CALENDAR_SLOTS_RENDERED;
  const minHeight = round((minDuration.as("minutes") / 30) * heightPercent, 3);
  const duration = interval.length("minutes") / 30;
  const gutterReservePercent = gutters ? GUTTER_WIDTH_PERCENT : 0;

  const columnWidthPercentFromCount = 100 / columnCount;
  const minWidthExceeded = minWidth && minWidth > columnWidthPercentFromCount;
  const showCalculatedFullWidth = fullWidth && minWidthExceeded;
  const columnWidthPercent =
    minWidth && minWidth > columnWidthPercentFromCount ? minWidth : columnWidthPercentFromCount;

  const subColumnWidthPercent = (columnWidthPercent - gutterReservePercent) / subColumnCount;

  const top = round((interval.start.hour * 2 + interval.start.minute / 30) * heightPercent, 3);

  const left = round(columnWidthPercent * column + subColumnWidthPercent * subColumn, 3);

  const width = showCalculatedFullWidth
    ? minWidth * columnCount
    : round(subColumnWidthPercent * subColumnSpan, 3);
  const height = Math.max(minHeight, round(duration * heightPercent, 3));
  const floatOffset = showCalculatedFullWidth ? 0 : subColumnOffset;

  const subColumnLeft = round(left - columnWidthPercent * column, 3) + floatOffset; // We have to define it this way so that rounding errors don't accumulate
  const renderOrder = subColumn;

  return { top, left, width, height, floatOffset, renderOrder, subColumnLeft };
};
