import { useChecklistFlags } from "#webapp/src/components/superpowers-checklist/useChecklistFlags";
import { FLOW_STATE_KEYS } from "#webapp/src/state/flow-state";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { windowLocation } from "#webapp/src/util/location.util";
import { logger } from "#webapp/src/util/logger.util";
import { useMutation } from "@apollo/client";
import { paths } from "@clockwise/client-commons/src/constants/site";
import { Button } from "@clockwise/design-system";
import { onboardingMeta } from "@clockwise/web-commons/src/util/local-storage";
import { OnboardingStep } from "@clockwise/web-commons/src/util/onboarding.util";
import React, { useState } from "react";
import { UpdateFlowStateUserDocument } from "../__generated__/UpdateFlowStateUser.generated";

export const ResetOnboardingButton = () => {
  const [resetting, setResetting] = useState(false);
  const [updateFlowStateUser] = useMutation(UpdateFlowStateUserDocument);
  const { resetAllChecklistItems: resetNUXChecklistUserFlags } = useChecklistFlags();
  const resetOnboarding = async () => {
    setResetting(true);

    await updateFlowStateUser({
      variables: {
        input: { flowKey: FLOW_STATE_KEYS.INITIAL_ONBOARDING, newState: OnboardingStep.Unstarted },
      },
    });
    await updateFlowStateUser({
      variables: {
        input: {
          flowKey: FLOW_STATE_KEYS.CHECKLIST_FLEX_MEETINGS,
          newState: OnboardingStep.Unstarted,
        },
      },
    });
    await updateFlowStateUser({
      variables: {
        input: { flowKey: FLOW_STATE_KEYS.CHECKLIST_HOLDS, newState: OnboardingStep.Unstarted },
      },
    });
    await updateFlowStateUser({
      variables: {
        input: {
          flowKey: FLOW_STATE_KEYS.CHECKLIST_PREFERENCES,
          newState: OnboardingStep.Unstarted,
        },
      },
    });
    await updateFlowStateUser({
      variables: {
        input: {
          flowKey: FLOW_STATE_KEYS.CHECKLIST_SCHEDULE_MEETING,
          newState: OnboardingStep.Unstarted,
        },
      },
    });
    await updateFlowStateUser({
      variables: {
        input: {
          flowKey: FLOW_STATE_KEYS.AI_CHECKLIST_CLOSED,
          newState: OnboardingStep.Unstarted,
        },
      },
    });
    await updateFlowStateUser({
      variables: {
        input: {
          flowKey: FLOW_STATE_KEYS.NAVIGATION_EDUCATION,
          newState: OnboardingStep.Unstarted,
        },
      },
    });
    await updateFlowStateUser({
      variables: {
        input: { flowKey: FLOW_STATE_KEYS.WEB_ONBOARDING, newState: OnboardingStep.Unstarted },
      },
      onCompleted: () => {
        setResetting(false);
        windowLocation.assign("SettingsExperimentResetOnboarding", paths.webApp);
      },
      onError: (error) => {
        logger.error(`failed to resetOnboarding: ${error}`);
        setResetting(false);
      },
    });
    resetNUXChecklistUserFlags();
    onboardingMeta.clear();
    resetNUXChecklistUserFlags();
    track(TrackingEvents.WEB_SETTINGS.EXPERIMENTS.RESET_ONBOARDING_CLICKED);
  };

  return (
    <Button
      variant="outlined"
      sentiment="destructive"
      onClick={resetOnboarding}
      cw-id={"reset-onboarding-button"}
      disabled={resetting}
    >
      {resetting ? "Resetting" : "Reset onboarding"}
    </Button>
  );
};
