import { ApolloError, useMutation } from "@apollo/client";
import { useUpdateTimeSuggestion } from "@clockwise/web-commons/src/util/TimeSuggestionContext";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setSwitchingProposalUIInFlight } from "../../../state/actions/ui-flow-on-screen.actions";
import { GQLProposal } from "../../chat/ai-chat/utils/types";
import {
  SelectSharedOptionDocument,
  SelectSharedOptionMutation,
} from "../apollo/__generated__/SelectSharedOptions.generated";

type UseSelectSharedSchedulingOptionProps = {
  onCompleted?: (data: SelectSharedOptionMutation) => void;
  onError?: (error: ApolloError) => void;
  proposal: GQLProposal | null;
};

type UseSelectSharedSchedulingOptionResult = [
  (selectedIndex: number) => Promise<void>,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

const getOptimisticResponse = (
  proposal: GQLProposal,
  selectedIndex: number,
): SelectSharedOptionMutation | undefined => {
  if (!proposal) return undefined;

  const newOptionDetails =
    proposal.options.__typename === "SchedulingOptions" ? proposal.options.optionDetails : [];

  // Given that all proposals that have scheduling options to be selected will have only one diffBlock and one diff,
  // we are going to extract that diff and optimistically update the time to match the new selected option.
  const [diffBlock, ...otherDiffBlocks] = proposal.diffBlocks;
  const [diff, ...otherDiffs] = diffBlock.diffs;
  const newDiff = {
    ...diff,
    time: newOptionDetails[selectedIndex].interval,
  };

  return {
    __typename: "Mutation",
    selectSharedOption: {
      __typename: "SelectSharedOptionPayload",
      proposal: {
        ...proposal,
        diffBlocks: [
          {
            ...diffBlock,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TS doesn't understand how to merge these types with the mutation types.
            diffs: [newDiff, ...otherDiffs],
          },
          ...otherDiffBlocks,
        ],
        options: {
          __typename: "SchedulingOptions",
          selectedIndex,
          optionDetails:
            proposal.options.__typename === "SchedulingOptions"
              ? proposal.options.optionDetails
              : [],
        },
      },
    },
  };
};

export const useSelectSharedSchedulingOption = ({
  onCompleted,
  onError,
  proposal,
}: UseSelectSharedSchedulingOptionProps): UseSelectSharedSchedulingOptionResult => {
  const [selectSharedSchedulingOption, { loading, error }] = useMutation(
    SelectSharedOptionDocument,
    {
      context: { useBatching: false },
    },
  );
  const { setTimeSuggestionLoading } = useUpdateTimeSuggestion();
  const dispatch = useDispatch();

  const onSelectSharedSchedulingOption = useCallback(
    async (selectedIndex: number) => {
      dispatch(setSwitchingProposalUIInFlight());
      setTimeSuggestionLoading(true);

      if (proposal) {
        const optimisticResponse = getOptimisticResponse(proposal, selectedIndex);
        const { proposalId } = proposal;
        await selectSharedSchedulingOption({
          onCompleted,
          onError,
          variables: { input: { proposalId, selectedIndex } },
          optimisticResponse,
        });
      }
    },
    [
      selectSharedSchedulingOption,
      setTimeSuggestionLoading,
      dispatch,
      onCompleted,
      onError,
      proposal,
    ],
  );

  return [onSelectSharedSchedulingOption, { loading, error }];
};
