import { Link } from "@clockwise/design-system";
import {
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import React from "react";
import usePlannerMetaData from "../../../../web-app-calendar/hooks/usePlannerMetaData";

export const EventLink = ({ title, eventId }: { title: string; eventId?: string }) => {
  const setActiveEvent = useUpdateActiveEvent();
  const { primaryCalendarId } = usePlannerMetaData(); // right now only show on current user's calendar

  const openEventIdCard = () => {
    if (primaryCalendarId && eventId) {
      const event = {
        externalEventId: eventId,
        calendarId: primaryCalendarId,
        type: EventType.Event,
      };
      setActiveEvent(event);
    }
  };
  return <Link onClick={openEventIdCard}> {title}</Link>;
};
