import { useMutation } from "@apollo/client";
import { Button } from "@clockwise/design-system";
import { Delete } from "@material-ui/icons";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { logger } from "../../util/logger.util";
import { useOrgId } from "../hooks/useOrgId";
import RemoveDialog from "./RemoveDialog";
import { DeletePersonalDataSourceMutationDocument } from "./graphql/__generated__/DeletePersonalDataSourceMutation.generated";
import { DataSource } from "./types";

type Props = {
  dataSource: DataSource;
  disabled?: boolean;
  onSaveEnd?: () => void;
  onSaveError?: (error?: Error) => void;
  onSaveStart?: () => void;
};

const ListItem = ({ dataSource, disabled, onSaveEnd, onSaveError, onSaveStart }: Props) => {
  const [removeDataSource] = useMutation(DeletePersonalDataSourceMutationDocument);
  const { orgId } = useOrgId();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => setDialogOpen(false);
  const handleDialogOpen = () => setDialogOpen(true);

  const handleConfirmRemove = () => {
    onSaveStart?.();
    setDialogOpen(false);

    if (!dataSource.name) return handleSaveError();
    if (!orgId) return handleSaveError();

    void removeDataSource({
      variables: {
        input: {
          orgRelayId: orgId,
          sourceCalId: dataSource.name,
        },
      },
      update: (cache) => {
        const normalizedId = cache.identify({ id: dataSource.id, __typename: "DataSource" });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      onError: handleSaveError,
      onCompleted: handleSaveSuccess,
    });
  };

  const handleSaveSuccess = () => {
    onSaveEnd?.();

    toast.success("Your personal calendar is no longer connected and syncing.");
  };
  const handleSaveError = (error?: Error) => {
    onSaveError?.(error);

    toast.error("There was a problem disconnecting this calendar as a personal calendar.");
    logger.debug("There was a problem disconnecting a calendar as a personal calendar.");
  };

  return (
    <>
      <div className="cw-flex cw-row cw-items-center cw-justify-between cw-space-y-1">
        <div>{dataSource.name}</div>
        <Button
          onClick={handleDialogOpen}
          sentiment="neutral"
          size="small"
          startIcon={Delete}
          variant="outlined"
          disabled={disabled}
        >
          Remove
        </Button>
      </div>
      <RemoveDialog
        open={dialogOpen}
        onCancel={handleDialogClose}
        onConfirm={handleConfirmRemove}
      />
    </>
  );
};

export default ListItem;
