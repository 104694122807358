import { AddLocation } from "@clockwise/icons";
import classNames from "classnames";
import React, { useState } from "react";

type Props = {};

export const AddWorkingLocationCell = ({}: Props) => {
  const [hovered, _setHovered] = useState(false);

  const onHover = (_h: boolean) => {
    // setHovered(h);
  };

  const Empty = () => {
    return (
      <div className="cw-h-[26px]">
        <div
          className={classNames(
            "cw-flex cw-items-center",
            "cw-leading-4 cw-overflow-hidden cw-text-clip cw-whitespace-nowrap",
            "cw-z-[41]",
            "cw-body-base",
            "cw-rounded cw-border-[1px] cw-border-solid",
            "cw-text-[12px] cw-leading-[13px]",
            "cw-font-medium",
            "cw-no-underline",
          )}
        >
          <AddLocation />
          <div>Add location</div>
        </div>
        <div></div>
      </div>
    );
  };

  return (
    <div onMouseOver={() => onHover(true)} onMouseOut={() => onHover(false)}>
      {hovered ? <Empty /> : <div className="cw-h-[26px]"></div>}
    </div>
  );
};
