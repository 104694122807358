import {
  Frequency,
  RecurrenceRule,
  RRuleFrequency,
} from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { isEmpty, isEqual } from "lodash";
import { DateTime } from "luxon";

export const standardRecurrenceTypes = [
  "Weekdays",
  "Weekly",
  "Biweekly",
  "MonthlyByNthWeekday",
  "MonthlyByLastWeekday",
  "MonthlyByDate",
  "None",
] as const;

export type StandardRecurrenceType = typeof standardRecurrenceTypes[number];

/// DateTime helpers
export const getNthWeekday = (dt: DateTime) => {
  return dt.set({ day: 1 }).weekday > dt.weekday
    ? Math.ceil(dt.day / 7)
    : Math.floor(dt.day / 7) + 1;
};

export const getNthWeekdayString = (dt: DateTime) => {
  return ["first", "second", "third", "fourth", "fifth"][getNthWeekday(dt) - 1];
};

export const isLastWeekdayOfMonth = (dt: DateTime) => dt.daysInMonth - dt.day < 7;

/// RecurrenceRule classification helpers

// For use with WEEKLY recurrences:
export const isEveryWeekdayRule = ({ byDay, byNthDay }: RecurrenceRule): boolean => {
  return isEqual(byDay, [1, 2, 3, 4, 5]) && isEmpty(byNthDay);
};
export const isSpecificWeekdayRule = (
  { byDay, byNthDay }: RecurrenceRule,
  dt?: DateTime,
): boolean => {
  return (
    isEmpty(byNthDay) &&
    byDay.length === 1 &&
    // We only care about the specific weekday if a date is provided
    (!dt || isEqual(byDay, [dt.weekday]))
  );
};
// For use with MONTHLY or YEARLY recurrences:
export const isNthWeekdayRule = ({ byDay, byNthDay }: RecurrenceRule, dt?: DateTime): boolean => {
  if (dt) {
    return isEmpty(byDay) && isEqual(byNthDay, [[dt.weekday, getNthWeekday(dt)]]);
  } else {
    // If no date is provided, we don't care which weekday as long as n is positive
    return isEmpty(byDay) && byNthDay.length === 1 && byNthDay[0][1] > 0;
  }
};
export const isLastWeekdayRule = ({ byDay, byNthDay }: RecurrenceRule, dt?: DateTime): boolean => {
  if (dt) {
    return isLastWeekdayOfMonth(dt) && isEmpty(byDay) && isEqual(byNthDay, [[dt.weekday, -1]]);
  } else {
    // If no date is provided, we don't care which weekday as long as it's the last one
    return isEmpty(byDay) && byNthDay.length === 1 && byNthDay[0][1] === -1;
  }
};

export const identifyStandardRecurrenceType = (
  recurrenceRule: RecurrenceRule | null,
  dt?: DateTime,
): StandardRecurrenceType | null => {
  if (!recurrenceRule) return "None";

  const { freq, interval } = recurrenceRule;

  const rulePartKeys = recurrenceRule.keys();

  // Allowlist of standard recurrence rule parts
  const standardRuleParts = ["FREQ", "INTERVAL", "BYDAY", "WKST", "TZID"];
  if (!rulePartKeys.every((rulePart) => standardRuleParts.includes(rulePart))) {
    return null;
  }

  if (freq === Frequency.WEEKLY) {
    if (interval === 1 && isEveryWeekdayRule(recurrenceRule)) {
      return "Weekdays";
    } else if (isEmpty(recurrenceRule.byDay) || isSpecificWeekdayRule(recurrenceRule, dt)) {
      if (interval === 1) return "Weekly";
      if (interval === 2) return "Biweekly";
    }
  } else if (freq === Frequency.MONTHLY) {
    if (interval === 1) {
      if (isNthWeekdayRule(recurrenceRule, dt)) {
        return "MonthlyByNthWeekday";
      } else if (isLastWeekdayRule(recurrenceRule, dt)) {
        return "MonthlyByLastWeekday";
      } else if (isEmpty(recurrenceRule.byNthDay) && isEmpty(recurrenceRule.byDay)) {
        return "MonthlyByDate";
      }
    }
  }
  return null;
};

export const getStandardRRule = (
  type: StandardRecurrenceType,
  dt: DateTime,
): RecurrenceRule | null => {
  const nthWeekday = getNthWeekday(dt);
  const isLastWeekday = isLastWeekdayOfMonth(dt);

  switch (type) {
    case "Weekdays":
      return new RecurrenceRule("RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR");
    case "Weekly":
      return RecurrenceRule.fromOpts({
        freq: RRuleFrequency.WEEKLY,
        byDay: [dt.weekday].map(RecurrenceRule.getDay),
      });
    case "Biweekly":
      return RecurrenceRule.fromOpts({
        freq: RRuleFrequency.WEEKLY,
        interval: 2,
        byDay: [dt.weekday].map(RecurrenceRule.getDay),
      });
    case "MonthlyByNthWeekday":
      return RecurrenceRule.fromOpts({
        freq: RRuleFrequency.MONTHLY,
        byDay: [RecurrenceRule.getDay(dt.weekday).nth(nthWeekday)],
      });
    case "MonthlyByLastWeekday":
      return isLastWeekday
        ? RecurrenceRule.fromOpts({
            freq: RRuleFrequency.MONTHLY,
            byDay: [RecurrenceRule.getDay(dt.weekday).nth(-1)],
          })
        : null;
    case "MonthlyByDate":
      return new RecurrenceRule("RRULE:FREQ=MONTHLY;INTERVAL=1");
    default:
      return null;
  }
};
