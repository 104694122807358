import { Lock } from "@clockwise/icons";
import React from "react";

export const PrivateLock = () => {
  return (
    <div className="cw-flex cw-items-center cw-w-full">
      <Lock className="cw-w-[14px] cw-h-[14px] cw-text-muted cw-mr-2" />
      <div className="cw-text-12 cw-text-subtle cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis">
        Private
      </div>
    </div>
  );
};
