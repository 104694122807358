import { paths } from "@clockwise/client-commons/src/constants/site";
import { getSchedulingLinkPath } from "@clockwise/web-commons/src/util/scheduling.util";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CreateSchedulingLinkPageGateway } from "../components/scheduling-link/CreateSchedulingLinkPageGateway";
import { EditSchedulingLinkPageGateway } from "../components/scheduling-link/EditSchedulingLinkPageGateway";
import { EnsureLinkName } from "../components/scheduling-link/EnsureLinkName";

/**
 * Handle the route params and determine if we should render the Create or Edit page.
 */
export const SetupSchedulingLinkPage = () => {
  const navigate = useNavigate();

  const mode = window.location.href.includes(paths.createSchedulingLink) ? "create" : "edit";

  const onReturnToDashboard = useCallback(() => navigate(paths.schedulingLinks), [navigate]);

  const onViewLink = useCallback(
    (linkName: string, slug: string, shouldPushNotReplace = false) => {
      if (shouldPushNotReplace) {
        navigate(getSchedulingLinkPath(linkName, slug));
      } else {
        navigate(getSchedulingLinkPath(linkName, slug));
      }
    },
    [navigate],
  );

  switch (mode) {
    case "create":
      return (
        <EnsureLinkName>
          <CreateSchedulingLinkPageGateway
            onViewLink={onViewLink}
            onReturnToDashboard={onReturnToDashboard}
          />
        </EnsureLinkName>
      );
    case "edit":
      return (
        <EnsureLinkName>
          <EditSchedulingLinkPageGateway
            onViewLink={onViewLink}
            onReturnToDashboard={onReturnToDashboard}
          />
        </EnsureLinkName>
      );
  }
};
