import React from "react";

export const CannotViewGuesListMessage = () => {
  return (
    <div
      className="cw-flex cw-items-center cw-px-3 cw-py-2 cw-bg-neutral cw-rounded-lg"
      cw-id="ec-guest-list-hidden-message"
    >
      <div className="cw-text-12 cw-font-medium cw-text-subtle">
        The full guest list has been hidden at the organizer's request.
      </div>
    </div>
  );
};
