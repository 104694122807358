import { Button, TextField } from "@clockwise/design-system";
import { Check, Search } from "@clockwise/design-system/icons";
import classNames from "classnames";
import { isEmpty, some } from "lodash";
import React, { useEffect, useState } from "react";
import { TrackingEvents, track } from "../../util/analytics.util";
import { InviteSearchResultRow } from "./InviteSearchResultRow";
import { useInviteSearch } from "./hooks/useInviteSearch";
import { InviteSearchPerson } from "./types";

const Results = ({
  results,
  onCheck,
  selectedUsers,
}: {
  results: InviteSearchPerson[];
  onCheck: (selectedPerson: InviteSearchPerson, checked: boolean) => void;
  selectedUsers: InviteSearchPerson[];
}) => {
  return (
    <>
      {results.map((result) => {
        const isUserSelected = selectedUsers.some(
          (user) => user.primaryCalendarId === result.primaryCalendarId,
        );
        return (
          <div key={result.primaryCalendarId} className="cw-my-1">
            <InviteSearchResultRow
              searchPerson={result}
              onCheck={onCheck}
              isChecked={isUserSelected}
            />
          </div>
        );
      })}
    </>
  );
};

const getFeaturedInvitees = (
  selectedInvitees: InviteSearchPerson[],
  suggestions: InviteSearchPerson[],
) => {
  return [
    ...selectedInvitees,
    ...suggestions.filter(
      ({ primaryCalendarId }) => !some(selectedInvitees, { primaryCalendarId }),
    ),
  ];
};

interface Props {
  selectedInvitees: InviteSearchPerson[];
  suggestions: InviteSearchPerson[];
  onCheck: (invitees: InviteSearchPerson[], checked: boolean) => void;
  trackingVariant: string;
}

export const InviteSearch = ({
  selectedInvitees,
  onCheck: onSelect,
  suggestions,
  trackingVariant,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState("");
  // Featured invitees are selected invitees + suggestions that are not already selected
  const [featuredInvitees, setFeaturedInvitees] = useState(() =>
    getFeaturedInvitees(selectedInvitees, suggestions),
  );

  const { results, loading, search } = useInviteSearch();

  // Tracking
  useEffect(() => {
    const suggestedInvites = suggestions.filter((suggestion) => suggestion.suggested === true); // some are just non users
    track(TrackingEvents.INVITE_SEARCH.INVITE_SUGGESTIONS_SEEN, {
      numSuggestions: suggestedInvites.length,
      suggestions: suggestedInvites,
      variant: trackingVariant,
    });
  }, []);

  const hasSearchQuery = !isEmpty(searchQuery);
  const displayUsers = hasSearchQuery ? results : featuredInvitees;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (value.length > 0) {
      void search({ variables: { query: value } });
    } else {
      setFeaturedInvitees(getFeaturedInvitees(selectedInvitees, suggestions));
    }
  };

  const onUpdateSelectedUsers = (selectedPerson: InviteSearchPerson, selected: boolean) => {
    onSelect([selectedPerson], selected);
  };

  const isActivelySearching = hasSearchQuery && loading;
  const noResults = !isActivelySearching && isEmpty(displayUsers);

  // Collect all displayed invitees who actually have the "Suggested" badge
  const suggestedInvitees = displayUsers.filter(({ suggested }) => suggested);
  const hasSelectedAllSuggestions = suggestedInvitees.every(({ primaryCalendarId }) =>
    some(selectedInvitees, { primaryCalendarId }),
  );
  const selectAllStatus =
    selectedInvitees.length === 0 ? "none" : hasSelectedAllSuggestions ? "all" : "some";
  const showSelectAll = !hasSearchQuery && suggestedInvitees.length > 0;

  // Select all currently displayed invitees with the suggested badge
  const handleSelectAll = (selectAll: boolean) => {
    track(TrackingEvents.INVITE_SEARCH.SELECT_ALL_CLICKED, {
      suggestionsSelected: suggestedInvitees.length,
    });
    onSelect(suggestedInvitees, selectAll);
  };

  return (
    <div>
      <TextField
        placeholder="Search for teammates"
        startIcon={Search}
        value={searchQuery}
        onChange={onInputChange}
        autoFocus={true}
        loading={loading}
        fullWidth
      />
      {noResults && <div className="cw-py-4 cw-text-center cw-text-muted"> No results found </div>}
      <div className="cw-mb-2 cw-pt-2 cw-overflow-auto">
        {showSelectAll && <SelectAll checked={selectAllStatus} onToggle={handleSelectAll} />}
        <div
          className={classNames(
            "cw-border-default cw-rounded-xl cw-mt-4 md:cw-max-h-[310px] cw-overflow-y-scroll",
            {
              "cw-border cw-border-solid": !isEmpty(displayUsers),
            },
          )}
        >
          <Results
            results={displayUsers}
            onCheck={onUpdateSelectedUsers}
            selectedUsers={selectedInvitees}
          />
        </div>
      </div>
    </div>
  );
};

type SelectAllProps = {
  checked: "all" | "some" | "none";
  onToggle: (selectAll: boolean) => void;
};
const SelectAll = ({ checked, onToggle }: SelectAllProps) => (
  <Button
    onClick={() => onToggle(true)}
    disabled={checked === "all"}
    fullWidth
    sentiment="positive"
    startIcon={checked === "all" ? Check : undefined}
  >
    {checked === "all" ? "All suggested teammates selected" : "Select all suggested teammates"}
  </Button>
);
