import { logger } from "@clockwise/client-commons/src/util/logger";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { noop } from "lodash";
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import invariant from "tiny-invariant";
import { toastStyles } from "../../chat/util/toastStyles";

interface ProposalErrorType {
  proposalId: string | null;
  message: string;
  error: Error;
}

const ProposalErrorContext = createContext<(error: ProposalErrorType | null) => void>(() => noop);

const useErrorForProvider = () => {
  const [errorWrapper, setErrorWrapper] = useState<ProposalErrorType | null>(null);
  const track = useTracking();

  useEffect(() => {
    if (errorWrapper) {
      const { proposalId, message, error } = errorWrapper;

      track(TrackingEvents.SHAREABLE_PROPOSAL.ERROR, {
        message: message,
        proposalId: proposalId,
        error: error,
      });
      logger.error(
        `SHAREABLE PROPOSAL ERROR: ${message} for propsalId of ${proposalId || "unknown"} - ${
          error.message
        }`,
        error,
      );
      toast.error(message, {
        position: "bottom-center",
        style: toastStyles,
      });
    }
  }, [errorWrapper, track]);

  const setError = (error: ProposalErrorType) => {
    setErrorWrapper(() => error);
  };

  return setError;
};
export function ProposalErrorProvider({ children }: PropsWithChildren<{}>) {
  const value = useErrorForProvider();

  return <ProposalErrorContext.Provider value={value}>{children}</ProposalErrorContext.Provider>;
}

export function useSetProposalError() {
  const context = useContext(ProposalErrorContext);
  invariant(context, "useSetProposalError must be within ProposalErrorProvider");
  return context;
}
