import { Switch } from "@clockwise/design-system";
import React from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";

type Props = {
  isAllDay: boolean;
  readOnly: boolean;
  onChange: (isAllDay: boolean) => void;
  showChanges?: boolean;
};

export function AllDayToggle({ readOnly, isAllDay, onChange, showChanges = false }: Props) {
  return (
    <EventElementsWrap name="allDay" showChanges={showChanges}>
      <div className="cw-flex cw-justify-between cw-items-center cw-flex-1 cw-h-7 cw-text-13 cw-font-medium cw-ml-2">
        <div>All day</div>
        <Switch size="xs" disabled={readOnly} checked={isAllDay} onChange={onChange} />
      </div>
    </EventElementsWrap>
  );
}
