import { fg_muted } from "@clockwise/design-system/tokens";
import { OpenInNew, PageInfo } from "@clockwise/icons";
import { webappDefaultWindowName } from "@clockwise/web-commons/src/constants/route.constants";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import React from "react";
import { ListItemButton } from "../../calendar-popover/atoms/ListItemButton";

export const EditSettingsLink = () => {
  return (
    <ListItemButton
      startIcon={PageInfo}
      endIcon={OpenInNew}
      onClick={openPersonalSyncSettings}
      startIconColor={fg_muted}
      endIconColor={fg_muted}
    >
      Edit settings
    </ListItemButton>
  );
};

const openPersonalSyncSettings = () =>
  window.open(getUrlForFeature("accountPreferences"), webappDefaultWindowName);
