import { EcosystemEnum, EventPermissions, ResponseStatusEnum } from "@clockwise/schema";

export const isWaitingOnAsync = (...loadingStatuses: boolean[]) => {
  return loadingStatuses.some((status) => status);
};

// Applies to all fields in the event card EXCEPT for:
// Attendee Adding
// RSVP
// Event Deletion
export const canModifyWholeEvent = (
  userEventPermission: EventPermissions,
  isAnythingLoading?: boolean,
  locked?: boolean,
) => {
  const userCanFullyEdit =
    userEventPermission === EventPermissions.ALL || userEventPermission === EventPermissions.MODIFY;
  return !isAnythingLoading && !locked && userCanFullyEdit;
};

export const canUserInviteAttendees = (
  userEventPermission: EventPermissions,
  isAnythingLoading?: boolean,
) => {
  const userCanInvite =
    userEventPermission !== EventPermissions.NONE &&
    userEventPermission !== EventPermissions.ISATTENDEE;
  return !isAnythingLoading && userCanInvite;
};

export const canUserRSVP = (
  rsvpStatus: ResponseStatusEnum,
  userEventPermission: EventPermissions,
  ecosystem: EcosystemEnum,
  isOrganizer: boolean,
  isAnythingLoading?: boolean,
) => {
  if (ecosystem === EcosystemEnum.Microsoft && isOrganizer) {
    return false; // if M365 user is an organizer, they can't RSVP
  }
  return (
    rsvpStatus !== ResponseStatusEnum.NotApplicable &&
    !isAnythingLoading &&
    userEventPermission !== EventPermissions.NONE
  );
};

export const canUserSeeOptionToRemoveEvent = (userEventPermission: EventPermissions) => {
  return userEventPermission !== EventPermissions.NONE;
};

export const canUserFullyDeleteEvent = (
  userEventPermission: EventPermissions,
  isAnythingLoading?: boolean,
) => {
  return !isAnythingLoading && userEventPermission === EventPermissions.ALL;
};
