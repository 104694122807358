import { visit } from "unist-util-visit";
/**
 * This custom plugin turns special text like `:event_link` into links.
 * @type {import('unified').Plugin<[], import('mdast').Root>}*/
export function customEventLinkPlugin() {
  return (tree: any) => {
    visit(tree, (node: any) => {
      if (node.type === "textDirective") {
        if (node.name !== "event_link") return;

        const data = node.data || (node.data = {});
        const attributes = node.attributes || {};

        data.hName = "eventlink";
        data.hProperties = {
          ...attributes,
        };
      }
    });
  };
}

/**
 * This custom plugin turns special text like `:pill` into Sticker UI components.
 * The BE sends the TradeoffType as the ID, so that StickerWrapper can decide the variant based on tradeoff type
 * @type {import('unified').Plugin<[], import('mdast').Root>}*/
export function customPillPlugin() {
  return (tree: any) => {
    visit(tree, (node: any) => {
      if (node.type === "textDirective") {
        if (node.name !== "pill") return;

        const data = node.data || (node.data = {});
        const attributes = node.attributes || {};

        data.hName = "pill";
        data.hProperties = {
          ...attributes,
        };
      }
    });
  };
}

/**
 * This custom plugin turns special text like `:color` and wraps the text with a color.
 * @type {import('unified').Plugin<[], import('mdast').Root>}*/
export function customColorPlugin() {
  return (tree: any) => {
    visit(tree, (node: any) => {
      if (node.type === "textDirective") {
        if (node.name !== "color") return;

        const data = node.data || (node.data = {});
        const attributes = node.attributes || {};

        data.hName = "color";
        data.hProperties = {
          ...attributes,
        };
      }
    });
  };
}

/**
 * This custom plugin turns special text like `:prompt` and wraps with a button that will send the prompt directly
 * @type {import('unified').Plugin<[], import('mdast').Root>}*/
export function customPromptPlugin() {
  return (tree: any) => {
    visit(tree, (node: any) => {
      if (node.type === "textDirective") {
        if (node.name !== "prompt") return;

        const data = node.data || (node.data = {});
        const attributes = node.attributes || {};

        data.hName = "prompt";
        data.hProperties = {
          ...attributes,
        };
      }
    });
  };
}

/**
 * This custom plugin turns special text like `:subText` and wraps it with smaller grey styling
 * @type {import('unified').Plugin<[], import('mdast').Root>}*/
export function customSubTextPlugin() {
  return (tree: any) => {
    visit(tree, (node: any) => {
      if (node.type === "textDirective") {
        if (node.name !== "subText") return;

        const data = node.data || (node.data = {});
        const attributes = node.attributes || {};

        data.hName = "subText";
        data.hProperties = {
          ...attributes,
        };
      }
    });
  };
}

/**
 * This custom plugin turns special text like `:subText` and wraps it with smaller grey styling
 * @type {import('unified').Plugin<[], import('mdast').Root>}*/
export function customInviteModalPlugin() {
  return (tree: any) => {
    visit(tree, (node: any) => {
      if (node.type === "textDirective") {
        if (node.name !== "invite_modal") return;

        const data = node.data || (node.data = {});
        const attributes = node.attributes || {};

        data.hName = "inviteModal";
        data.hProperties = {
          ...attributes,
        };
      }
    });
  };
}

/**
 * This custom plugin turns special text like `:italic` into an italic font
 * @type {import('unified').Plugin<[], import('mdast').Root>}*/
export function customItalicPlugin() {
  return (tree: any) => {
    visit(tree, (node: any) => {
      if (node.type === "textDirective") {
        if (node.name !== "italic") return;

        const data = node.data || (node.data = {});
        const attributes = node.attributes || {};

        data.hName = "italic";
        data.hProperties = {
          ...attributes,
        };
      }
    });
  };
}
