import { Switch } from "@clockwise/design-system";
import { fg_muted } from "@clockwise/design-system/tokens";
import { ChevronRight } from "@clockwise/icons";
import { FlexOutlineIcon } from "@clockwise/web-commons/src/components/module-flex-settings/atoms/FlexOutlineIcon";
import { FlexibleStatusSimplified } from "@clockwise/web-commons/src/components/module-flex-settings/types";
import classNames from "classnames";
import React from "react";
import { EventFlexibilityStatusIcon } from "../../web-app-calendar/event-context-menu/atoms/EventFlexibilityHoverCard";

export const EventFlexibilityTarget = ({
  flexibleEditable,
  flexible,
  onChangeFlexible,
  updatingFlexible,
  flexibleStatus,
}: {
  flexibleEditable: boolean;
  flexible: boolean;
  onChangeFlexible: (flexible: boolean) => void;
  updatingFlexible: boolean;
  flexibleStatus: FlexibleStatusSimplified;
}) => {
  const onEnable = () => {
    if (flexible) {
      return;
    }
    const paywall = flexibleStatus === "paywall";
    const canUpdate = !paywall && !updatingFlexible && flexibleEditable;
    if (canUpdate) {
      onChangeFlexible(!flexible);
    }
  };

  return (
    <div
      className={classNames(
        "cw-flex cw-border cw-border-subtle cw-border-solid cw-rounded-lg cw-flex-row cw-items-center cw-justify-between cw-w-full cw-px-2 cw-py-1.5",
        {
          "hover:cw-border-brand-interactable-hover hover:cw-shadow-md cw-cursor-pointer": flexibleEditable,
          "cw-opacity-50": !flexibleEditable,
        },
      )}
      onClick={onEnable}
      role="button"
      aria-disabled={!flexibleEditable}
      tabIndex={flexibleEditable ? 0 : undefined}
    >
      <div className="cw-flex cw-items-center cw-gap-1">
        <FlexOutlineIcon />
        <div className="cw-font-semibold cw-text-subtle cw-body-sm cw-text-12">
          {flexible ? "Flexibility settings" : "Mark as flexible?"}
        </div>
      </div>
      <div className="cw-flex cw-items-center cw-gap-2">
        {flexible ? (
          <>
            <EventFlexibilityStatusIcon flexibleStatus={flexibleStatus} />
            <ChevronRight fill={fg_muted} style={{ color: fg_muted }} className="cw-h-4 cw-w-4" />
          </>
        ) : (
          <Switch
            checked={flexible}
            disabled={updatingFlexible || !flexibleEditable}
            sentiment="neutral"
            size="xs"
          />
        )}
      </div>
    </div>
  );
};
