import { Transition } from "@headlessui/react";
import { Popover } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

export const PopoverWrapper = ({
  id,
  anchorEl,
  onClose,
  onBlurCapture,
  children,
  width = 250,
  side = "right",
  verticalPlacement = "top",
}: {
  id: string;
  anchorEl: HTMLDivElement | HTMLButtonElement | null;
  onClose: () => void;
  onBlurCapture?: () => void;
  children: React.ReactNode;
  width?: number;
  side?: "left" | "right" | "center";
  verticalPlacement?: "top" | "center" | "bottom";
}) => {
  return (
    <Popover
      id={id}
      open={!!anchorEl}
      anchorEl={anchorEl}
      PaperProps={{ className: "cw-rounded-xl" }}
      onClose={onClose}
      anchorOrigin={{
        vertical: verticalPlacement,
        horizontal: side,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: side,
      }}
      onBlurCapture={onBlurCapture}
      role="dialog"
    >
      <Transition
        show={!!anchorEl}
        leave="cw-transition cw-ease-in cw-duration-100"
        leaveFrom="cw-opacity-100"
        leaveTo="cw-opacity-0"
        enter="cw-transition cw-ease-in cw-duration-100"
        enterFrom="cw-opacity-0"
        enterTo="cw-opacity-100"
      >
        <div
          className={classNames(
            `cw-z-50 cw-w-[${width}px] cw-overflow-auto cw-body-sm cw-bg-default cw-shadow-selectPopup`,
          )}
        >
          {children}
        </div>
      </Transition>
    </Popover>
  );
};
