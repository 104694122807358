import { useAIChatCollapseContext } from "@clockwise/web-commons/src/util/AIChatCollapseContext";
import { animated, config, useSpring } from "@react-spring/web";
import classNames from "classnames";
import { useReducedMotion } from "framer-motion";
import React, { useState } from "react";
import { AIChatLayout } from "../chat/ai-chat/AIChatLayout";

// to change these, also edit the className below
export const COLLAPSIBLE_SIDEBAR_WIDTH = 350;

const AnimatedAIChat = () => {
  const { chatIsCollapsed } = useAIChatCollapseContext();
  const userPrefersReducedMotion = useReducedMotion();
  const [chatIsVisible, setChatIsVisible] = useState(true);
  const animationStyles = useSpring({
    left: chatIsCollapsed ? -COLLAPSIBLE_SIDEBAR_WIDTH : 0,
    config: {
      duration: userPrefersReducedMotion ? 0 : 150,
      ...config.stiff,
    },
    onRest: () => {
      // don't hide chat until exit animation is complete
      if (chatIsCollapsed) {
        setChatIsVisible(false);
      } else {
        setChatIsVisible(true);
      }
    },
  });

  return (
    <animated.div
      className={classNames(
        `cw-absolute cw-top-0 cw-bottom-0 cw-border-r cw-border-subtle cw-border-solid cw-w-[350px]`,
        {
          "cw-hidden": !chatIsVisible && chatIsCollapsed,
        },
      )}
      style={{ ...animationStyles, willChange: "left" }}
    >
      <AIChatLayout />
    </animated.div>
  );
};

export default AnimatedAIChat;
