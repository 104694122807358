import { InviteModal, InviteModalMode } from "#webapp/src/components/invite-modal";
import React, { useState } from "react";

export const InviteModalWrapper = ({ text }: { text: string }) => {
  const [isModalOpen, setIsOpenModal] = useState(false);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  return (
    <span>
      <InviteModal isOpen={isModalOpen} onClose={closeModal} mode={InviteModalMode.Invite} />
      <span className="cw-cursor-pointer cw-text-positive cw-underline" onClick={openModal}>
        {text}
      </span>
    </span>
  );
};
