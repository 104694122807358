import { AddressSearch } from "#webapp/src/components/address-search";
import { Button } from "@clockwise/design-system";
import { fg_muted } from "@clockwise/design-system/tokens";
import {
  FeatureSetting,
  FeatureSettingAside,
  FeatureSettingBody,
  FeatureSettingHeading,
  FeatureSettingMain,
  FeatureSettingOptions,
  FeatureSettingsHoverCard,
} from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import Options, {
  OptionsState,
} from "@clockwise/web-commons/src/components/feature-setting/Options";
import { Delete } from "@material-ui/icons";
import { CircleNotch } from "phosphor-react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { TrackingEvents, track } from "../../util/analytics.util";
import { ScrollIntoView } from "../ui-focus-wrappers/ScrollIntoView";
import Tooltip from "./Tooltip";
import TravelTimeExample from "./TravelTimeIllustration";
import useTravelTime from "./hooks/useTravelTime";

const notifySuccess = () => toast.success("Updated travel time setting.");
const notifyError = () => toast.error("Failed to updated travel time setting.");

const TravelTime = () => {
  const { settings: state, update, loading, updating, toggle, toggling } = useTravelTime({
    onSuccess: notifySuccess,
    onFail: notifyError,
  });
  const [isEditing, setIsEditing] = useState(false);

  const handleEnabledChange = (value: boolean) => {
    void toggle(value);

    track(TrackingEvents.SETTINGS.UPDATE_SMART_HOLD_SYNC, {
      sync: value,
      type: "Travel Time",
    });
  };

  const handleAddressChange = (value: string) => {
    void update({ address: value });

    setIsEditing(false);

    track(TrackingEvents.SETTINGS.WORK_ADDRESS_SELECT_RESULT);
  };

  const handleAddressRemove = () => {
    void update({ address: null });

    setIsEditing(true);

    track(TrackingEvents.SETTINGS.WORK_ADDRESS_REMOVE);
  };

  const handleOptionsChange = (value: OptionsState) => {
    void update({
      scheduleAs: value.scheduleAs,
      notificationStrategy: value.notificationStrategy,
    });

    track(TrackingEvents.SETTINGS.UPDATE_OPTIONS, {
      scheduleAs: value.scheduleAs,
      notificationStrategy: value.notificationStrategy,
      type: "Travel Time",
    });
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  return (
    <ScrollIntoView focusSection="travel" highlight>
      <FeatureSetting
        enabled={!loading && state.enabled}
        loading={loading}
        onEnabledChange={handleEnabledChange}
        saving={toggling || updating}
        title="Travel Time Settings"
      >
        <FeatureSettingAside>
          <TravelTimeExample />
        </FeatureSettingAside>
        <FeatureSettingMain>
          <FeatureSettingHeading>
            <h2
              className="cw-heading-2xl cw-m-0"
              aria-label="Do you want travel time held on your calendar?"
            >
              Do you want{" "}
              <FeatureSettingsHoverCard content={<Tooltip />}>
                <span className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1">
                  travel time
                </span>
              </FeatureSettingsHoverCard>{" "}
              held on your calendar?
            </h2>
          </FeatureSettingHeading>
          <FeatureSettingBody>
            <div className="cw-flex cw-flex-row cw-space-x-2">
              {!isEditing && state.address ? (
                <div className="cw-flex cw-flex-row cw-justify-between cw-space-x-2 cw-items-center">
                  <span>
                    I usually work at{" "}
                    <span className="cw-font-semibold cw-italic">{state.address}</span>
                  </span>
                  <Button
                    disabled={!state.enabled}
                    variant="outlined"
                    sentiment="neutral"
                    startIcon={Delete}
                    onClick={handleAddressRemove}
                  >
                    Remove
                  </Button>
                </div>
              ) : (
                <div className="cw-flex cw-flex-row cw-items-center cw-space-x-1">
                  <span>I usually work at</span>
                  <AddressSearch
                    disabled={loading || !state.enabled}
                    label="Work location"
                    loadingIcon={
                      <CircleNotch
                        className="cw-animate-spin cw-h-9"
                        color={fg_muted}
                        data-chromatic="ignore"
                      />
                    }
                    mode="standard"
                    onCancel={handleEditCancel}
                    onSelectAddress={handleAddressChange}
                  />
                </div>
              )}
            </div>
          </FeatureSettingBody>
          <FeatureSettingOptions>
            <Options
              enabled={state.enabled}
              notificationStrategy={state.notificationStrategy}
              onChange={handleOptionsChange}
            />
          </FeatureSettingOptions>
        </FeatureSettingMain>
      </FeatureSetting>
    </ScrollIntoView>
  );
};

export default TravelTime;
