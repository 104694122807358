import { useQuery } from "@apollo/client";
import { notEmpty } from "@clockwise/web-commons/src/util/notEmpty";
import { getRenderTimeZone, intervalSetZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { difference } from "lodash";
import { DateTime, Interval, Zone } from "luxon";
import { useMemo } from "react";
import { usePlannerContext } from "../Context";
import {
  GetUsersWorkingHoursDocument,
  GetUsersWorkingHoursQuery,
} from "../graphql/__generated__/GetUsersWorkingHours.generated";
import { intervalIntersection } from "../util/intervalIntersections";
import { intervalsFromWorkingHours } from "../util/intervalsFromWorkingHours";
import usePlannerMetaData from "./usePlannerMetaData";

export const useAvailablities = ({
  dateTimes,
  minCalendarCount = 1,
}: {
  dateTimes: DateTime[];
  minCalendarCount: number;
}) => {
  const { teamCalendarIds } = usePlannerMetaData();
  const { calendarIds } = usePlannerContext();
  const meetingHourCalendarIds = difference(calendarIds, teamCalendarIds);
  const weekOffset = Math.floor(
    dateTimes[0].startOf("week").diff(DateTime.local().startOf("week"), "weeks").weeks,
  );

  const { data } = useQuery(GetUsersWorkingHoursDocument, {
    skip: meetingHourCalendarIds.length < minCalendarCount,
    // Do not use `cache-first`
    // We incorrectly use the same cache for meetingHours with and without week-specific overrides
    // See: hooks/useWorkingHours.ts
    fetchPolicy: "cache-and-network",
    variables: {
      calendarIds: meetingHourCalendarIds,
      resolveOverrides: true,
      weekOffset,
    },
  });

  const dates = dateTimes.map((dateTime) => dateTime.toISODate());
  const intervalsFromWorkingHoursForDates = intervalsFromWorkingHours(dateTimes);

  const timeZone = (getRenderTimeZone() as unknown) as Zone;
  const setIntervalToRenderTimeZone = intervalSetZone(timeZone);
  const usersWorkingHours = parseWorkingHours(data);

  const intervalsByDay = useMemo(() => {
    const usersWorkingIntervals = usersWorkingHours.map(intervalsFromWorkingHoursForDates);
    return intervalIntersection(usersWorkingIntervals).map(setIntervalToRenderTimeZone);

    // limit re-calculating intervals unless calendarIds change as day settings is unlikely to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingHourCalendarIds, dates, usersWorkingHours]);

  // This is used in split view and makes the assumption that there is one interval per calendar
  const intervalsByCalendarId = useMemo(() => {
    const usersWorkingIntervals = usersWorkingHours.map(intervalsFromWorkingHoursForDates);
    const intervalsByCalendarIdMap: Record<string, Interval> = {};

    const flatMapIntervals = usersWorkingIntervals
      .flatMap((intervalList) => intervalList)
      .map(setIntervalToRenderTimeZone);

    flatMapIntervals.forEach((interval, index) => {
      const calendarId = meetingHourCalendarIds[index];
      intervalsByCalendarIdMap[calendarId] = interval;
    });

    return intervalsByCalendarIdMap;
    // limit re-calculating intervals unless calendarIds change as day settings is unlikely to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingHourCalendarIds, dates, usersWorkingHours]);

  // Returns the timezone abbreviation for each calendarId's working hours
  const timeZoneByCalendarId = useMemo(() => {
    const timeZonesByCalendarIdMap: Record<string, string | null> = {};

    const now = DateTime.now();
    usersWorkingHours?.forEach(({ timeZone }, index) => {
      const calendarId = meetingHourCalendarIds[index];
      timeZonesByCalendarIdMap[calendarId] = timeZone ? now.setZone(timeZone).toFormat("z") : null;
    });

    return timeZonesByCalendarIdMap;
  }, [usersWorkingHours, meetingHourCalendarIds]);

  return useMemo(() => {
    return { intervalsByDay, intervalsByCalendarId, timeZoneByCalendarId };
  }, [intervalsByDay, intervalsByCalendarId, timeZoneByCalendarId]);
};

/** Parse gql response from `GetUsersWorkingHoursQuery` into a list of `DaySetting` */
export const parseWorkingHours = (data: GetUsersWorkingHoursQuery | undefined) => {
  if (!data) return [];

  const result = data.viewer.user?.orgs.edges
    ?.flatMap((edge) => edge?.node?.usersWorkingHours)
    .filter(notEmpty)
    .map(({ workingHours }) => workingHours)
    .filter(notEmpty);

  if (!result) return [];

  return result;
};
