import classNames from "classnames";
import React from "react";

export const InlineAction = ({
  onClick,
  label,
  ariaLabel,
  disabled = false,
  icon,
  fontSize = "cw-text-12",
}: {
  onClick: () => void;
  label: string;
  ariaLabel?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  fontSize?: string;
}) => {
  return (
    <span
      className={classNames(fontSize, "cw-body-sm cw-leading-[18px] cw-font-medium ", {
        "cw-text-positive-disabled cw-cursor-not-allowed": disabled,
        "hover:cw-underline cw-cursor-pointer cw-outline-positive-emphasis cw-text-positive": !disabled,
      })}
      onClick={onClick}
      role="button"
      onKeyDown={(e) => {
        if (e.key === "Enter" && !disabled) {
          onClick();
        }
      }}
      tabIndex={0}
      aria-label={ariaLabel}
    >
      {label}
      {icon && icon}
    </span>
  );
};
