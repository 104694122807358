import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { getCurrentOrg } from "../../../util/org.util";
import { PersonalizedStatsRowQuery } from "../graphql/__generated__/PersonalizedStatsRow.generated";

export const parseFeatureUsageGQL = (data?: PersonalizedStatsRowQuery) => {
  const currentOrg = getCurrentOrg(data?.viewer);
  const onboardingChecklist = getValue(currentOrg?.onboardingChecklist);
  const flexMeetingEnabled =
    getValue(onboardingChecklist?.flexMeetingEnabled)?.flexMeetingEnabled ?? false;
  const focusTimeEnabled =
    getValue(onboardingChecklist?.focusTimeEnabled)?.focusTimeEnabled ?? false;

  return {
    flexMeetingEnabled,
    focusTimeEnabled,
  };
};
