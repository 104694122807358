import { ThumbDownOutlined, ThumbUpOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React, { CSSProperties } from "react";

export const FeedbackThumbs = ({
  feedbackUrlNegative,
  onClick,
  feedbackUrlPositive,
  size = "20",
}: {
  feedbackUrlNegative: string;
  onClick?: (isPositive: boolean) => void;
  feedbackUrlPositive: string;
  size?: CSSProperties["height"];
}) => {
  return (
    <div className={classNames("cw-flex cw-gap-1.5 cw-items-center cw-mt-0.5")}>
      <a
        href={feedbackUrlPositive}
        target="_blank"
        rel="noreferrer"
        title="Link to feedback form"
        onClick={() => onClick?.(true)}
      >
        <ThumbUpOutlined
          className="cw-text-subtle hover:cw-text-subtle-hover"
          style={{
            height: size,
            width: size,
          }}
        />
      </a>
      <a
        href={feedbackUrlNegative}
        target="_blank"
        rel="noreferrer"
        title="Link to feedback form"
        onClick={() => onClick?.(false)}
      >
        <ThumbDownOutlined
          className="cw-text-subtle hover:cw-text-subtle-hover"
          style={{
            height: size,
            width: size,
          }}
        />
      </a>
    </div>
  );
};
