import { useQuery } from "@apollo/client";
import { OrgDomainsDocument } from "../../graphql/__generated__/OrgDomains.generated";
import { getCurrentOrg } from "../../util/org.util";

const useOrgDomains = () => {
  const { data, loading, error } = useQuery(OrgDomainsDocument);

  const org = getCurrentOrg(data?.viewer);
  const domains = org?.domains || [];

  return { domains, loading, error };
};

export default useOrgDomains;
