import React from "react";

const MeetingHoursTooltip = () => {
  return (
    <>
      We'll use this to protect time during your workday where you'd prefer to avoid meeting.
      Meeting hours must be within your working hours.
    </>
  );
};

export default MeetingHoursTooltip;
