import { InternalRefetchQueriesInclude } from "@apollo/client";
import {
  DeleteWorkingLocationEventInput,
  UpdateWorkingLocationEventInput,
} from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useCallback } from "react";
import {
  DeleteWorkingLocationEventDocument,
  UpdateWorkingLocationEventDocument,
} from "../apollo/__generated__/WorkingLocationEvent.v2.generated";

export const useUpdateWorkingLocationEvent = (
  externalEventId: string,
  calendarId: string,
  {
    refetchQueriesOnEdit,
    onEditCompleted,
    onEditError,
    onDeleteCompleted,
    onDeleteError,
  }: {
    refetchQueriesOnEdit?: InternalRefetchQueriesInclude;
    onEditCompleted?: () => void;
    onEditError?: (error: Error) => void;
    onDeleteCompleted?: () => void;
    onDeleteError?: (error: Error) => void;
  },
) => {
  const [updateWorkingLocationEvent, { loading: savingChanges }] = useGatewayMutation(
    UpdateWorkingLocationEventDocument,
  );

  const [deleteWorkingLocationEvent, { loading: deletingEvent }] = useGatewayMutation(
    DeleteWorkingLocationEventDocument,
  );

  const onSaveWorkingLocationEventChanges = useCallback(
    async (input: UpdateWorkingLocationEventInput) => {
      await updateWorkingLocationEvent({
        variables: { input },
        onCompleted: onEditCompleted,
        onError: onEditError,
        refetchQueries: refetchQueriesOnEdit,
      });
    },
    [
      updateWorkingLocationEvent,
      externalEventId,
      onEditCompleted,
      onEditError,
      calendarId,
      refetchQueriesOnEdit,
    ],
  );

  const onDeleteWorkingLocationEvent = useCallback(
    async (input: DeleteWorkingLocationEventInput) => {
      await deleteWorkingLocationEvent({
        variables: { input },
        onCompleted: onDeleteCompleted,
        onError: onDeleteError,
        refetchQueries: refetchQueriesOnEdit,
        update(cache, { data }, { variables }) {
          if (data?.deleteWorkingLocationEvent && variables?.input) {
            cache.evict({
              id: cache.identify({
                __typename: "WorkingLocationEvent",
                id: `${calendarId}-${externalEventId}`,
              }),
            });
            cache.gc();
          }
        },
      });
    },
    [
      deleteWorkingLocationEvent,
      externalEventId,
      onDeleteCompleted,
      onDeleteError,
      calendarId,
      refetchQueriesOnEdit,
    ],
  );

  return {
    onSaveWorkingLocationEventChanges,
    savingChanges,
    onDeleteWorkingLocationEvent,
    deletingEvent,
  };
};

export default useUpdateWorkingLocationEvent;
