import { TradeoffTypeEnum } from "@clockwise/schema";
import classNames from "classnames";
import React from "react";
import { FixableConflictTooltip } from "../atoms/FixableConflictTootlip";
import { LowVizInconvenienceTooltip } from "../atoms/LowVizInconvenienceTooltip";
import { getIconForTradeoffType } from "../utils/getStylesForTradeoffType";
import { TradeoffItem } from "./ECTradeoffList";
import { Tradeoff } from "./ECTradeoffsWithDrawer";
export const ECSingleTradeoff = ({
  tradeoff,
  type,
  title,
}: {
  tradeoff: Tradeoff | null;
  type: TradeoffTypeEnum;
  title: string;
}) => {
  const renderTooltip = () => {
    switch (type) {
      case TradeoffTypeEnum.FIXABLE_CONFLICT:
        return <FixableConflictTooltip />;
      case TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE:
        return <LowVizInconvenienceTooltip />;
      default:
        return <></>;
    }
  };

  const tradeOffTitleLong = Boolean(tradeoff?.title?.length && tradeoff.title.length > 26);

  return (
    <div
      className={classNames(
        "cw-flex cw-items-center cw-flex-wrap cw-w-full cw-px-2 cw-pt-1 cw-mt-1",
        "cw-rounded-md cw-bg-neutral cw-border cw-border-solid cw-border-subtle",
      )}
    >
      <div className="cw-mr-1 cw-flex cw-items-center"> {getIconForTradeoffType(type)}</div>
      <div
        className={classNames("cw-flex cw-items-center", {
          "cw-mr-1": tradeOffTitleLong,
          "cw-mr-2": !tradeOffTitleLong,
        })}
      >
        <div
          className={classNames("cw-body-sm cw-text-12 cw-font-medium cw-whitespace-nowrap", {
            "cw-text-warning ": type === TradeoffTypeEnum.AVAILABILITY_ISSUE,
            "cw-text-busy":
              type === TradeoffTypeEnum.FIXABLE_CONFLICT ||
              type === TradeoffTypeEnum.INCONVENIENCE ||
              type === TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE,
          })}
        >
          {title}
        </div>
        {renderTooltip()}
      </div>
      {tradeoff && (
        <div className="cw-justify-end cw-flex-1">
          <TradeoffItem tradeoff={tradeoff} variant="neutral" />
        </div>
      )}
    </div>
  );
};
