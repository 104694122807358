import { isEmpty } from "lodash";
import { Interval } from "luxon";
import { formattedDateTime } from "../../../../web-app-calendar/calendar-popover/utils/formattedDateTime";
import { TradeoffBlock } from "../../utils/types";
import { CalDiffMini } from "./ShareSubMenu";

type GetCopyTextEmail = {
  copyTitle: string;
  calDiffs: CalDiffMini[];
  conflictTradeoffs: TradeoffBlock[];
  sharedProposalLink: string;
  targetTimeZone: string;
  isProposeNewTime: boolean;
  recipientNames: string[];
};

const getConsequenceCopy = ({
  calDiff,
  targetTimeZone,
}: {
  calDiff: CalDiffMini;
  targetTimeZone: string;
}) => {
  const time = Interval.fromISO(calDiff.updatedTime || calDiff.currentTime).start;
  return `%E2%80%A2 ${calDiff.title} %E2%86%92 ${formattedDateTime(
    time,
    targetTimeZone,
    true,
  )}%0D%0A`;
};

export const getCopyTextEmail = ({
  copyTitle,
  calDiffs,
  conflictTradeoffs,
  sharedProposalLink,
  targetTimeZone,
  isProposeNewTime,
  recipientNames,
}: GetCopyTextEmail) => {
  const hasConsequences = !isEmpty(calDiffs);
  const hasConflicts = !isEmpty(conflictTradeoffs);

  let body = "";
  if (recipientNames.length > 1) {
    body += "Hi All,";
  } else {
    body = `Hi ${recipientNames[0] || "there"},`;
  }
  body += `%0D%0A%0D%0A${copyTitle}`;
  body += isProposeNewTime
    ? " I would do it, but I don't have edit rights.%0D%0A%0D%0A"
    : "%0D%0A%0D%0A";

  if (hasConsequences || hasConflicts) {
    body += "There are a few issues with that time, which Clockwise will fix if it can:%0D%0A";
    calDiffs.map((calDiff) => (body += getConsequenceCopy({ calDiff, targetTimeZone })));
    conflictTradeoffs.map((tradeoffBlock) => {
      tradeoffBlock.schedulingTradeoffs.map(
        (tradeOff) => (body += `%E2%80%A2 ${tradeOff.eventTitle} %E2%86%92 Conflict%0D%0A`),
      );
    });
  }

  body += "%0D%0ACan you let me know via this link?%0D%0A";
  body += `${sharedProposalLink}?cw_link_source=email`;

  // NOTE: Add this with correct message when BE change is merged and deployed
  // body += "%0D%0A%0D%0A";
  // body += "NOTE: ";
  // if (recipients.length > 1) {
  //   body += "This proposal will create a meeting when ";
  //   body += hasConflicts ? "everyone " : "one person ";
  //   body += "confirms it OR when no one responds in 36 hours. ";
  //   body += "If one person declines, it will cancel the proposal.";
  // } else {
  //   body += "If you don't respond in 36 hours, it will automatically be confirmed.%0D%0A";
  // }
  return body;
};
