import { TextField } from "@clockwise/design-system";
import { CliOptionInput } from "@clockwise/schema";
import React from "react";

type CliOptionValues = NonNullable<CliOptionInput["values"]>;
interface IProps {
  values: CliOptionValues;
  placeholder?: string;
  onChange: (values: CliOptionValues) => void;
  disabled?: boolean;
}

export const CliOptionValues = ({
  values,
  placeholder = "Option value",
  onChange,
  disabled = false,
}: IProps) => {
  const handleValueChange = (value: string, i: number) => {
    const newValues = values.slice();
    newValues.splice(i, 1, value);
    onChange(newValues);
  };

  return (
    <>
      {values.map((value, i) => {
        return (
          <TextField
            key={`option-value-${i}`}
            value={value}
            placeholder={placeholder}
            required
            onChange={(e) => {
              handleValueChange(e.target.value, i);
            }}
            disabled={disabled}
          />
        );
      })}
    </>
  );
};
