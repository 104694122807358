import { getDomainOffEmail } from "@clockwise/client-commons/src/util/email";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { Title } from "@clockwise/web-commons/src/ui/admin-panel/title";
import { TrackingEvents } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useState } from "react";
import { useFeatureFlag } from "../../../../launch-darkly";
import { track } from "../../../../util/analytics.util";
import { CustomRequestToJoinDialog } from "./CustomRequestToJoinDialog";
import { APOtherBGTable } from "./OtherBGTable";
import { APPrimaryBillingCard } from "./PrimaryBillingCard";
import { useAdminPanelBillingGroups } from "./useAdminPanelBillingGroups";

export const APBillingGroups = ({
  orgId,
  primaryOrgCalendar,
}: {
  orgId: string;
  primaryOrgCalendar: string;
}) => {
  const {
    primaryOrAdminBillingGroups,
    otherBillingGroups,
    loadingBillingGroups,
    onSendBillingGroupRequest,
    calendarId,
    onAddUserToBg,
    onSaveSeatRequest,
    refetch,
  } = useAdminPanelBillingGroups({
    orgId,
    primaryOrgCalendar,
  });
  const [hasCustomRequestToJoinRequest] = useFeatureFlag("CustomJoinAPlanRequest");
  const [customRequestToJoinDialogOpen, setCustomRequestToJoinDialogOpen] = useState<boolean>(
    false,
  );

  const orgDomain = getDomainOffEmail(primaryOrgCalendar);

  const sendBGAddRequest = (bgId: string) => {
    track(TrackingEvents.ADMIN_PANEL.APBILLING_USER_REQUEST_JOIN, {
      billingGroupId: bgId,
    });
    if (hasCustomRequestToJoinRequest && calendarId) {
      setCustomRequestToJoinDialogOpen(true);
    } else {
      onSendBillingGroupRequest(bgId);
    }
  };

  const addUserToBg = (bgId: string) => {
    track(TrackingEvents.ADMIN_PANEL.APBILLING_USER_ADD_SELF, { billingGroupId: bgId });
    onAddUserToBg(bgId);
  };

  const handleCloseCustomRequestToJoinDialog = () => {
    setCustomRequestToJoinDialogOpen(false);
  };

  if (loadingBillingGroups) {
    return (
      <div className="cw-flex cw-items-center cw-justify-center">
        <Loader size="lg" sentiment="positive" />
      </div>
    );
  }

  return (
    <>
      <CustomRequestToJoinDialog
        open={customRequestToJoinDialogOpen}
        calendarId={calendarId}
        handleClose={handleCloseCustomRequestToJoinDialog}
      />
      <Title>Plans and billing</Title>
      <div className="cw-heading-xl cw-my-5">Manage your subscriptions</div>
      <div className="cw-flex cw-flex-col cw-gap-5">
        {primaryOrAdminBillingGroups.length ? (
          primaryOrAdminBillingGroups.map((bg) => {
            return (
              <APPrimaryBillingCard
                key={bg.id}
                bg={bg}
                orgDomain={orgDomain}
                orgId={orgId}
                onSaveSeatRequest={onSaveSeatRequest}
                refetchBillingsPage={refetch}
              />
            );
          })
        ) : (
          <APPrimaryBillingCard
            key={"free-card"}
            bg={null}
            orgDomain={orgDomain}
            orgId={orgId}
            onSaveSeatRequest={onSaveSeatRequest}
            refetchBillingsPage={refetch}
          />
        )}
      </div>
      {otherBillingGroups.length > 0 && (
        <>
          <div className="cw-heading-xl cw-mt-[60px] cw-mb-5">
            Other subscriptions in your organization
          </div>
          <APOtherBGTable
            orgId={orgId}
            orgDomain={orgDomain}
            billingGroups={otherBillingGroups}
            isUserInNoBGs={!primaryOrAdminBillingGroups.length}
            onUserAddSelect={(bgId: string, canUserAddSelf: boolean) => {
              if (canUserAddSelf) {
                addUserToBg(bgId);
              } else {
                sendBGAddRequest(bgId);
              }
            }}
          />
        </>
      )}
    </>
  );
};
