import { Button } from "@clockwise/design-system";
import { Close } from "@clockwise/design-system/icons";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { narrowMediaQuery } from "@clockwise/web-commons/src/ui/scheduling-link/scheduling-link.styles";
import { Dialog, Paper, PaperProps, makeStyles } from "@material-ui/core";
import { animated, config, useSpring } from "@react-spring/web";
import React, { useState } from "react";
import { ShareBestTimesLinkModal } from "../../../../scheduling-link";
import { SidebarLink } from "../../../../scheduling-link/LinksSection";

const useStyles = makeStyles({
  paper: {
    padding: "26px 34px 30px",
    minWidth: 320,
    maxWidth: 550,
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    borderRadius: 8,

    [narrowMediaQuery]: {
      height: "100%",
    },
  },
});

interface IProps {
  schedulingLink: SidebarLink | null;
  reschedulingLinkUrl: string | null;
  schedulingLinkLoading: boolean;
  fullscreen?: boolean;
  onClose: () => void;
}

export const ShareProposalModal = ({
  schedulingLink,
  reschedulingLinkUrl,
  schedulingLinkLoading,
  fullscreen = false,
  onClose,
}: IProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(true);

  const modalStyles = useSpring({
    from: {
      scale: 0.83,
      opacity: 0,
    },
    to: {
      scale: 1,
      opacity: 1,
    },
    config: config.stiff,
  });

  const AnimatedPaper = animated(Paper);

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false);
        onClose();
      }}
      fullScreen={fullscreen}
      disableRestoreFocus
      disableScrollLock
      PaperComponent={AnimatedPaper as React.ComponentType<PaperProps>}
      PaperProps={{
        style: {
          // PaperProps wants CSS properties so must cast the animated values to number
          scale: (modalStyles.scale as unknown) as number,
          opacity: (modalStyles.opacity as unknown) as number,
          visibility: dialogOpen ? "visible" : "hidden",
        },
        className: classes.paper,
      }}
      cw-id="share-proposal-modal"
    >
      <div className="cw-flex cw-justify-between cw-items-center cw-mb-3">
        <h2 className="cw-heading-xl">Share this meeting proposal</h2>
        <div className="cw-mr-[10px]">
          <Button
            variant="text"
            size="large"
            startIcon={Close}
            onClick={onClose}
            cw-id="close-copy-best-times-modal"
          />
        </div>
      </div>

      {schedulingLinkLoading && (
        <div className="cw-flex cw-justify-center cw-mt-3 cw-gap-3 cw-body-base">
          <Loader size="md" sentiment="positive" />
        </div>
      )}
      {!schedulingLinkLoading && schedulingLink && schedulingLink.linkName && (
        <ShareBestTimesLinkModal
          url={schedulingLink.url}
          rescheduleUrl={reschedulingLinkUrl}
          linkName={schedulingLink.linkName}
          name={schedulingLink.schedulingLink.name}
          slug={schedulingLink.schedulingLink.slug}
          durations={schedulingLink.schedulingLink.durations}
          defaultDuration={schedulingLink.schedulingLink.defaultDuration}
          windowSizeDays={
            schedulingLink.schedulingLink.availabilityRange?.__typename === "RollingWindow"
              ? schedulingLink.schedulingLink.availabilityRange.windowSize
              : null
          }
          multipleUser={schedulingLink.schedulingLink.linkMembers.length > 1}
          onClose={() => {}}
          fullscreen={false}
          renderedFrom={"shareable-proposal"}
          renderContentInModal={false}
        />
      )}
    </Dialog>
  );
};
