import { Day } from "@clockwise/schema";
import { DayCheckboxes } from "@clockwise/web-commons/src/ui/working-hours";
import React, { useMemo } from "react";

const getDayOfWeekISO = (day: Day) =>
  [
    Day.Monday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
    Day.Saturday,
    Day.Sunday,
  ].indexOf(day) + 1;

type DayOfWeekFieldProps = {
  selected: number[];
  onChange: (selected: number[]) => void;
};

export const DayOfWeekField = ({ selected, onChange }: DayOfWeekFieldProps) => {
  const dayOnOffMap: Record<Day, boolean> = useMemo(() => {
    const selectedDays = new Set(selected);
    return {
      Monday: selectedDays.has(1),
      Tuesday: selectedDays.has(2),
      Wednesday: selectedDays.has(3),
      Thursday: selectedDays.has(4),
      Friday: selectedDays.has(5),
      Saturday: selectedDays.has(6),
      Sunday: selectedDays.has(7),
    };
  }, [selected]);
  const handleDayToggle = (day: Day) => {
    const dayOfWeekISO = getDayOfWeekISO(day);
    if (selected.includes(dayOfWeekISO)) {
      onChange(selected.filter((d) => d !== dayOfWeekISO).sort());
    } else {
      onChange([...selected, dayOfWeekISO].sort());
    }
  };

  return <DayCheckboxes dayOnOffMap={dayOnOffMap} isValid onToggleDay={handleDayToggle} />;
};
