import { ApolloCache, useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";
import {
  ToggleFocusTimeDocument,
  ToggleFocusTimeMutation,
} from "../graphql/__generated__/ToggleFocusTime.generated";

import { logger } from "@clockwise/client-commons/src/util/logger";
import { isEqual } from "lodash";
import { FocusTimeSettings } from "../types";

export const useFocusTimeToggle = (
  orgId: string,
  focusTimeSettings: FocusTimeSettings,
  {
    onCompleted,
    onError,
    onUpdate,
  }: {
    onCompleted?: () => void;
    onError?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUpdate?: (cache: ApolloCache<any>) => void;
  },
) => {
  const [toggleFocusTime, { loading, error }] = useMutation(ToggleFocusTimeDocument);

  const onErrorHandler = useMemo(() => onError, [onError]);
  const onUpdateHandler = useMemo(() => onUpdate, [onUpdate]);

  const onCompleteHandler = useCallback(
    (expectedData: ToggleFocusTimeMutation) => (data: ToggleFocusTimeMutation) => {
      if (!isEqual(expectedData.ToggleFocusTime, data.ToggleFocusTime)) {
        // @scott - cleanup as part of AI-2044
        // logging to sentry to confrim error severtiy
        logger.error("Focus Time Toggle: Optimistic update does not match server response.", {
          expectedData,
          data,
        });

        onError?.();
      } else {
        onCompleted?.();
      }
    },
    [onCompleted, onError],
  );

  const toggle = useCallback(
    (enabled: boolean) => {
      const variables = { orgId, enabled };

      const optimisticResponse: ToggleFocusTimeMutation = {
        ToggleFocusTime: {
          focusTimeGoal: {
            id: focusTimeSettings.id,
            enabled,
            threshold:
              focusTimeSettings.threshold === "all"
                ? { __typename: "AllFocusTime" }
                : { __typename: "SomeFocusTime", idealFocus: focusTimeSettings.threshold.toISO() },
            remoteHoldSettings: {
              scheduleAs: focusTimeSettings.scheduleAs,
              notificationStrategy: focusTimeSettings.notificationStrategy,
              __typename: "RemoteSmartHoldSettings",
            },
            autoDeclineSettings: {
              __typename: "AutoDeclineSettings",
              enabled: focusTimeSettings.autoDeclineSettings.enabled,
              threshold: focusTimeSettings.autoDeclineSettings.threshold.toISO(),
            },
            dailyMaxSettings: {
              __typename: "DailyMaxSettings",
              enabled: focusTimeSettings.dailyMaxSettings.enabled,
              maxAmount: focusTimeSettings.dailyMaxSettings.maxAmount.toISO(),
            },
            preferredFocusIn: focusTimeSettings.preferredFocusIn,
            __typename: "FocusTimeGoals",
          },
          __typename: "ToggleFocusTimeGoalResponse",
        },
        __typename: "Mutation",
      };

      return toggleFocusTime({
        variables,
        optimisticResponse,
        onCompleted: onCompleteHandler(optimisticResponse),
        onError: onErrorHandler,
        update: onUpdateHandler,
      });
    },
    [
      focusTimeSettings.id,
      focusTimeSettings.notificationStrategy,
      focusTimeSettings.preferredFocusIn,
      focusTimeSettings.scheduleAs,
      focusTimeSettings.threshold,
      onCompleteHandler,
      onErrorHandler,
      onUpdateHandler,
      orgId,
      toggleFocusTime,
      focusTimeSettings.autoDeclineSettings,
    ],
  );

  return { toggle, loading, error };
};

export default useFocusTimeToggle;
