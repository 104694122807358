import { Variants } from "framer-motion";

/**
 * A collection of Framer Motion animation variants designed to work together to provide smooth
 * transitions into the page.
 */

/** Fade in the element, and stagger the children animations to create a flowing effect. */
export const fadeInContainerVariants: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { staggerChildren: 0.3, delayChildren: 0.3 },
  },
};

/** Fade in and transition down at the same time. */
export const fadeDownVariants: Variants = {
  hidden: { y: -10, opacity: 0 },
  show: { y: 0, opacity: 1 },
};

/** Fade in and scale up. */
export const fadeScaleVariants: Variants = {
  hidden: { scale: 0.5, opacity: 0 },
  show: { scale: 1, opacity: 1 },
};
