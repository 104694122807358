import React, { useEffect } from "react";
import { TrackingEvents, track } from "../../../../util/analytics.util";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { ButtonGroup, StepCounter, StepLayout } from "../../shared-components";
import { AISchedulerSlide } from "./AISchedulerSlide";

export const AISchedulerStep = () => {
  const { goForward, goBack, shouldShowSecondaryButton, funnelType } = useWebOnboardingSteps();

  useEffect(() => {
    track(TrackingEvents.WEB_ONBOARDING.AI_SCHEDULER_STEP_VIEWED, { funnelType });
  }, []);

  return (
    <StepLayout>
      <StepCounter />
      <AISchedulerSlide />
      <ButtonGroup
        shouldShowSecondary={shouldShowSecondaryButton}
        onClickPrimary={goForward}
        onClickSecondary={goBack}
        primaryLabel="Show me"
      />
    </StepLayout>
  );
};
