import { Break } from "./Break";
import { Event } from "./Event";
import { EventThread } from "./EventThread";
import { Source } from "./Source";
export type { ColoredProfile, SecondaryCard } from "./types";

type EventThreadComposite = typeof EventThread & {
  Break: typeof Break;
  Event: typeof Event;
  Source: typeof Source;
};

const Composite: EventThreadComposite = EventThread as EventThreadComposite;

Composite.Break = Break;
Composite.Event = Event;
Composite.Source = Source;

export { Composite as EventThread };
