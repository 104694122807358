import { ArrowForward, SettingsFilled } from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import React from "react";
import { FeatureCard } from "../feature-card";
import { Tile } from "./Tile";

export const PREFERENCES_NAV_ITEM_LABEL = "Preferences";

export const Account = () => {
  return (
    <FeatureCard
      layout="row"
      feature={{
        name: PREFERENCES_NAV_ITEM_LABEL,
        path: appPaths.preferences,
        endIcon: <ArrowForward />,
      }}
    >
      <Tile className="cw-basis-1/3 cw-h-20">
        <SettingsFilled fontSize="large" />
      </Tile>
    </FeatureCard>
  );
};
