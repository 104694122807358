import { ApolloCache, ApolloQueryResult } from "@apollo/client";

import { DocumentNode } from "graphql";
import { logger } from "./logger.util";

export const makeMutationUpdateRefetchBadCacheHandler = <T>(
  name: string,
  query: DocumentNode,
  refetch: () => Promise<ApolloQueryResult<T>>,
) => (
  cache: ApolloCache<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const cached = cache.readQuery({ query });

  if (!cached) {
    const warning = `Apollo cache is invalid for ${name}.  Attempting Refetch.`;
    logger.info(warning);
    console.warn(warning);

    void refetch();
  }
};

export default makeMutationUpdateRefetchBadCacheHandler;
