import { fg_muted, fg_positive, fg_warning } from "@clockwise/design-system/tokens";
import classNames from "classnames";
import { CheckCircle, X, XCircle } from "phosphor-react";
import React from "react";
import toast from "react-hot-toast";
import { useBoolean } from "usehooks-ts";
import { FeedbackThumbs } from "../FeedbackThumbs";
import { EventOperations } from "./types";
import { getOperationText } from "./util/getOperationText";

const TOAST_DURATION = 10000;

type EventToastProps = {
  feedbackUrlNegative?: string;
  feedbackUrlPositive?: string;
  onDismiss?: () => void;
  onFeedback?: (isPositive: boolean) => void;
  onViewEvent?: () => void;
  operation: EventOperations;
  success: boolean;
  title: string;
};

export const eventToast = {
  success: (props: Omit<EventToastProps, "success">) => {
    commonEventToaster({
      ...props,
      success: true,
    });
  },
  error: (props: Omit<EventToastProps, "success">) => {
    commonEventToaster({
      ...props,
      success: false,
    });
  },
};

const commonEventToaster = ({
  feedbackUrlNegative,
  feedbackUrlPositive,
  onDismiss,
  onFeedback,
  onViewEvent,
  operation,
  success,
  title,
}: EventToastProps) => {
  const handleDismiss = (toastId: string) => {
    toast.dismiss(toastId);
    onDismiss?.();
  };

  toast.custom(
    (t) => (
      <EventToast
        operation={operation}
        feedbackUrlNegative={feedbackUrlNegative}
        feedbackUrlPositive={feedbackUrlPositive}
        onDismiss={() => handleDismiss(t.id)}
        onFeedback={onFeedback}
        onViewEvent={onViewEvent}
        success={success}
        title={title}
      />
    ),
    {
      duration: TOAST_DURATION,
      position: "bottom-right",
    },
  );
};

export const EventToast = ({
  operation,
  feedbackUrlNegative,
  feedbackUrlPositive,
  onDismiss,
  onFeedback,
  onViewEvent,
  success = true,
  title,
}: EventToastProps) => {
  const { value: dismissed, setTrue: setDismissedTrue } = useBoolean(false);

  const handleDismiss = () => {
    onDismiss?.();
    setDismissedTrue();
  };

  const handleViewEvent = () => {
    onViewEvent?.();
  };

  const operationText = getOperationText(operation, success);

  if (dismissed) {
    // DevNote: Reach Hot Toast is slow to update when dismissed.
    // This makes the dismiss feel more immediate.
    return null;
  }

  return (
    <div
      className={classNames(
        "cw-w-[290px]",
        "cw-z-50",
        "cw-rounded-xl",
        "cw-shadow-lg hover:cw-shadow-xl",
        "cw-border cw-border-solid cw-border-subtle",
        "cw-bg-default",
        "cw-flex cw-flex-row cw-space-x-2",
        "cw-body-sm cw-font-body cw-font-normal cw-text-13",
        "cw-px-4 cw-py-3",
      )}
    >
      <div className={classNames("cw-mt-[1px]")}>
        {success ? (
          <CheckCircle size={16} color={fg_positive} weight="fill" />
        ) : (
          <XCircle size={16} color={fg_warning} weight="fill" />
        )}
      </div>
      <div className={classNames("cw-grow", "cw-flex cw-flex-col cw-space-y-1")}>
        <div>
          <div className={classNames("cw-flex cw-flex-row cw-justify-between")}>
            <div className={classNames("cw-font-semibold", "cw-line-clamp-1")}>
              {operation === "DEFRAG" ? operationText : `Event ${operationText}`}
            </div>
            <div className={classNames("cw-mt-[1px]", "cw-cursor-pointer")} onClick={handleDismiss}>
              <X size={16} color={fg_muted} weight="fill" />
            </div>
          </div>
          {title && <div className={classNames("cw-line-clamp-1")}>{title}</div>}
        </div>
        <div className={classNames("cw-flex cw-flex-row cw-justify-between cw-items-center")}>
          <div
            className={classNames(
              "cw-text-positive-muted",
              "cw-cursor-pointer",
              "cw-font-medium",
              "hover:cw-underline",
            )}
            onClick={handleViewEvent}
          >
            {onViewEvent && <>View event</>}
          </div>
          <div>
            {feedbackUrlNegative && feedbackUrlPositive && (
              <FeedbackThumbs
                feedbackUrlNegative={feedbackUrlNegative}
                onClick={onFeedback}
                feedbackUrlPositive={feedbackUrlPositive}
                size={16}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
