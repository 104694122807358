import { SecurityFilled } from "@clockwise/icons";
import {
  EventContainer,
  NamedEvent,
} from "@clockwise/web-commons/src/components/feature-illustration/";
import React from "react";
import { TeamSettingsAdminNoMeetingDay } from "../../teams-settings/team-settings-admin-form/team-settings-admin-no-meeting-day";
import { TeamSections } from "../web-settings";
import { TeamSettingsSection } from "./TeamSettingsSection";

type Props = {
  teamId: string;
};

const NoMeetingIllustration = () => (
  <EventContainer>
    <NamedEvent
      duration={90}
      Icon={SecurityFilled}
      startTime="All day Wednesday"
      title="No-meeting day"
    />
  </EventContainer>
);

export const TeamNoMeetingDay = ({ teamId }: Props) => {
  return (
    <TeamSettingsSection graphic={<NoMeetingIllustration />}>
      <div cw-id={`web-settings-${TeamSections.TeamNoMeetingDay}`} id="team-no-meeting-day">
        <TeamSettingsAdminNoMeetingDay teamId={teamId} />
      </div>
    </TeamSettingsSection>
  );
};
