import { DateTime, Duration } from "luxon";

/** ISO 86011 formatted local time (e.g., 02:30, 10:00, 18:15) */
export type Time = string;

const parseTime = (time: Time) => {
  const [hours, minutes] = time.split(":").map((part) => parseInt(part, 10));
  return { hours, minutes };
};

export const addMinutes = (time: Time, minutesToAdd: number) => {
  const { hours, minutes } = parseTime(time);
  const totalMinutes = hours * 60 + minutes + minutesToAdd;
  const newHours = Math.floor(totalMinutes / 60);
  const newMinutes = totalMinutes % 60;

  return `${newHours.toString(10).padStart(2, "0")}:${newMinutes.toString(10).padStart(2, "0")}`;
};

const formatDateTime = (dateTime: DateTime) => {
  // Luxon doesn't have options for adjusting formatting of the meridiem so we manually lowercase
  // the string so AM/PM -> am/pm
  return dateTime.toFormat("h:mm a").toLowerCase();
};

export const formatTime = (time: Time) => {
  const parsedTime = DateTime.fromISO(time);
  return formatDateTime(parsedTime);
};

export const formatTimeSlot = (time: Time, isEndSlot?: boolean) => {
  let parsedTime = DateTime.fromISO(time);

  // Round to nearest 30 minute mark
  const minutes = parsedTime.minute;
  const roundedMinutes = Math.round(minutes / 30) * 30;
  parsedTime = parsedTime.set({ minute: roundedMinutes });

  if (isEndSlot) {
    // Add 30 minutes for display as end slots are better understood as times not "slots"
    parsedTime = parsedTime.plus({ minutes: 30 });
  }

  return formatDateTime(parsedTime);
};

export const parseSearchText = (search: string) => {
  // Match h:mm, hh:mm, h:m, hhmm, hmm, h:m, h, hh
  const timelikeRegex = /^\s*(?<hour>\d{1,2})(?:(?<minute1>\d{2})|:(?<minute2>\d{1,2}))?\s*(?<meridiem>[ap])?\D*$/i;
  const match = timelikeRegex.exec(search.trim());
  if (!match?.groups) {
    throw new Error(`Invalid time format: ${search}`);
  }

  const hourRaw = match.groups.hour ?? "";
  const minuteRaw = (match.groups.minute1 ?? match.groups.minute2 ?? "").padEnd(2, "0");
  const meridiemRaw = match.groups.meridiem;

  const hour = parseInt(hourRaw, 10);
  // Hour is required and must be a valid 24-hour time
  if (!hourRaw || hour < 0 || hour >= 24) {
    throw new Error(`Invalid hour value: ${hourRaw}`);
  }

  const minute = parseInt(minuteRaw, 10);
  if (minute < 0 || minute >= 60) {
    throw new Error(`Invalid minute value: ${minuteRaw}`);
  }

  let meridiem: "am" | "pm" | null = null;
  if (meridiemRaw) {
    meridiem = meridiemRaw.toLowerCase().startsWith("p") ? "pm" : "am";
  }

  return { hour, minute, meridiem };
};

const makeTime = ({ hour, minute }: { hour: number; minute: number }): Time => {
  return Duration.fromObject({ hours: hour, minutes: minute }).toISOTime({ suppressSeconds: true });
};

export const findBestTimeMatch = (search: string, { minTime }: { minTime?: Time } = {}) => {
  if (!search) return null;

  let hour: number;
  let minute: number;
  let meridiem: "am" | "pm" | null;
  try {
    ({ hour, minute, meridiem } = parseSearchText(search));
  } catch (e) {
    return null;
  }
  let searchTime: Time;

  if (!meridiem && minTime && hour <= 12) {
    // If the search time is ambiguous (hour is 0-12 with no explicit meridiem), use the minTime
    // to determine the meridiem
    const searchTimeAm = makeTime({ hour: hour % 12, minute });
    const searchTimePm = makeTime({ hour: (hour % 12) + 12, minute });
    searchTime = searchTimeAm < minTime && searchTimePm > minTime ? searchTimePm : searchTimeAm;
  } else {
    // If the hour is definitely 24 hour time, ignore the meridiem and assume PM
    // Otherwise, use the meridiem and convert to 24 hour time
    const adjustedHour = hour > 12 || meridiem === "pm" ? (hour % 12) + 12 : hour % 12;
    searchTime = makeTime({ hour: adjustedHour, minute });
  }
  return searchTime;
};
