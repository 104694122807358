import { TradeoffTypeEnum } from "@clockwise/schema";
import { uniqueId } from "lodash";
import React from "react";
import { ECTradeoffsCollapsed } from "../../../../event-card/molecules/ECTradeoffsCollapsed";
import { useUpdateDefaultExpandedTradeoff } from "../../hooks/ExpandedTradeoffContext";
import { DiffSummaryTradeoffBlocks } from "../../utils/types";

export const Tradeoffs = ({
  tradeoffBlocks,
  onClick,
}: {
  tradeoffBlocks: DiffSummaryTradeoffBlocks;
  onClick: () => void;
}) => {
  const updateDefaultExpandedTradeoff = useUpdateDefaultExpandedTradeoff();
  const onClickTradeoff = (type: TradeoffTypeEnum) => {
    if (type !== TradeoffTypeEnum.OUT_OF_TIME_RANGE) {
      updateDefaultExpandedTradeoff(type);
    }
    onClick();
  };

  return (
    <>
      {tradeoffBlocks.map((tradeoff) => {
        const { title, tradeoffType, aggregateAttendees, schedulingTradeoffs } = tradeoff;
        return (
          <ECTradeoffsCollapsed
            key={`${title}-${uniqueId()}`}
            text={title}
            type={tradeoffType}
            attendees={aggregateAttendees}
            onClick={() => onClickTradeoff(tradeoffType)}
            tradeoffList={schedulingTradeoffs}
          />
        );
      })}
    </>
  );
};
