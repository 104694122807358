import { CALENDAR_HASH } from "../../constants";
import { getParamsFromHash } from "../../util/getParamsFromHash";
import { getSelectedDateTime } from "../../util/getSelectedDateTime";

export const getCalendarDatesFromStorage = (storage: Storage) => {
  const stored = storage.getItem(CALENDAR_HASH);
  if (!stored) return null;

  const params = getParamsFromHash(stored);
  if (!params) return null;

  return [params.mode, getSelectedDateTime(params).toISODate()];
};
