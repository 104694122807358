import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import Highcharts, { AxisTickPositionsArray, Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateTime, Duration } from "luxon";
import React from "react";
import { ChartTooltipProps, TimeSeriesProps } from "../chart/chart";
import {
  PELLEGRINO,
  highChartsConfig,
  placeholderData,
  placeholderTickPositions,
} from "../chart/chart.constants";
import { constructDatePointTooltip, constructDateRangeTooltip } from "../chart/chart.util";
import { formatOrgDataForTimeSeries } from "../overview.util";

export interface ColumnProps {
  series: TimeSeriesProps;
  step: "day" | "week" | "month";
  tooltipFormat?: ChartTooltipProps;
  shouldAnimate?: boolean;
}

export const Column = ({ series, step, tooltipFormat, shouldAnimate = true }: ColumnProps) => {
  const stepDuration = Duration.fromDurationLike({ [step]: 1 });
  const withoutData = series.data.length === 0;
  const tickPositions = withoutData ? placeholderTickPositions : undefined;
  const data = withoutData ? placeholderData : formatOrgDataForTimeSeries(series.data);

  const options: Options = {
    ...highChartsConfig,

    tooltip: {
      useHTML: true,
      formatter: function (this) {
        if (stepDuration.as("days") <= 1) {
          return constructDatePointTooltip(
            this.point.x,
            this.point.y || 0,
            this.series.name,
            PELLEGRINO,
            tooltipFormat?.valueSuffix,
          );
        }

        const minLux = DateTime.fromMillis(this.point.x);
        const maxLux = minLux.plus(stepDuration);
        const days = maxLux.diff(minLux, ["days"]).as("days");

        return constructDateRangeTooltip(
          days,
          this.point.x,
          this.point.y || 0,
          this.series.name,
          PELLEGRINO,
          tooltipFormat?.valueSuffix,
        );
      },
    },
    series: [{ ...series, data, type: "column" }],
    chart: {
      ...highChartsConfig.chart,
      type: "column",
      animation: shouldAnimate,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
      },
    },
    xAxis: {
      ...highChartsConfig.xAxis,
      tickPositioner: function (this) {
        const tickDates: AxisTickPositionsArray = data.map(([date]) => date);
        tickDates.info = this.tickPositions?.info;
        return tickDates;
      },
      labels: {
        formatter: function () {
          if (step === "month") {
            return new ZonedMoment(this.value).clone().format("MMM 'YY");
          }
          return new ZonedMoment(this.value).clone().format("MMM D");
        },
      },
    },
    yAxis: {
      ...highChartsConfig.yAxis,
      tickPositions,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
