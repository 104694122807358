// library
import React, { useEffect } from "react";
// internal
import { OrgPricingGrid } from "../pricing-grid/org-pricing-grid";
// util
import { page, PageEvents } from "#webapp/src/util/analytics.util";

export const WebAppPricingPage = () => {
  useEffect(() => {
    page(PageEvents.PRICING);
  }, []);

  return (
    <div className="cw-w-full cw-h-full">
      <div className="cw-w-full cw-flex cw-flex-1 cw-flex-col cw-items-center cw-justify-center cw-bg-default cw-relative cw-p-10">
        <OrgPricingGrid isDialog={false} />
      </div>
    </div>
  );
};
