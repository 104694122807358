import { HoverCard } from "@clockwise/design-system";
import { SvgIconComponent } from "@clockwise/icons";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { Profile, getFullName } from "@clockwise/web-commons/src/util/profile.util";
import React from "react";
import { TooltipHeader } from "./TooltipHeader";
import { ProposalThreadAttendees } from "./useProposalThreadEventDetails";

type CurrentAndUpdatedTimes = {
  currentTime: string;
  updatedTime: string;
};

const TooltipContent = ({
  title,
  description,
  attendees,
  loadingDetails,
  timeIntervals,
  tradeoffInfo,
}: {
  title: string;
  description: string | undefined;
  attendees: ProposalThreadAttendees;
  loadingDetails: boolean;
  timeIntervals?: CurrentAndUpdatedTimes;
  tradeoffInfo?: {
    icon?: SvgIconComponent;
    text: string;
  };
}) => {
  const Icon = tradeoffInfo?.icon;

  return (
    <div className="cw-rounded-xl cw-w-[250px] cw-overflow-auto cw-body-sm cw-text-12 cw-bg-default cw-shadow-selectPopup">
      <TooltipHeader
        title={title}
        description={description}
        attendees={attendees}
        loading={loadingDetails}
      />
      {timeIntervals && (
        <div className="cw-flex cw-justify-between cw-leading-5 cw-px-3 cw-py-1.5 ">
          <div className="cw-flex cw-flex-col cw-flex-1">
            <div>{timeIntervals.updatedTime}</div>
            <div className="cw-line-through cw-text-subtle">{timeIntervals.currentTime}</div>
          </div>
          <div>
            <div className="cw-text-default-disabled">New</div>
            <div className="cw-text-default-disabled">Original</div>
          </div>
        </div>
      )}
      {tradeoffInfo && (
        <div className="cw-text-muted cw-px-3 cw-py-1 cw-flex cw-flex-1 cw-gap-1 cw-items-center  cw-bg-neutral">
          {Icon && <Icon className="cw-h-3.5 cw-w-3.5 cw-text-warning-muted" />}
          {tradeoffInfo.text}
        </div>
      )}
    </div>
  );
};

const TooltipContentNonEvent = ({
  profiles,
}: {
  profiles: (Profile & { calendarId: string })[];
}) => {
  return (
    <div className="cw-rounded-xl cw-p-1 cw-w-[250px] cw-overflow-auto cw-body-sm cw-bg-default cw-shadow-selectPopup">
      {profiles.map((profile) => {
        return (
          <div
            className="cw-px-2 cw-py-1 cw-flex cw-items-center cw-gap-1"
            key={profile.calendarId}
          >
            <AttendeeAvatar profile={profile} size="medium" />
            <div>{getFullName(profile) || profile.calendarId}</div>
          </div>
        );
      })}
    </div>
  );
};

export const ThreadEventTooltip = ({
  title,
  description,
  attendees,
  loadingDetails,
  timeIntervals,
  tradeoffInfo,
  children,
}: {
  title: string;
  description: string | undefined;
  attendees: ProposalThreadAttendees;
  loadingDetails: boolean;
  timeIntervals?: CurrentAndUpdatedTimes;
  tradeoffInfo?: {
    icon?: SvgIconComponent;
    text: string;
  };
  children: React.ReactNode;
}) => {
  return (
    <HoverCard position="right">
      <HoverCard.Target>
        <span>{children}</span>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <TooltipContent
          title={title}
          description={description}
          loadingDetails={loadingDetails}
          attendees={attendees}
          timeIntervals={timeIntervals}
          tradeoffInfo={tradeoffInfo}
        />
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export const ThreadNonEventTooltip = ({
  profiles,
  children,
}: {
  profiles: (Profile & { calendarId: string })[];
  children: React.ReactNode;
}) => {
  const showTooltip = profiles?.length > 1;

  if (!showTooltip) {
    return children;
  } else {
    return (
      <HoverCard position="right">
        <HoverCard.Target>
          <span>{children}</span>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <TooltipContentNonEvent profiles={profiles} />
        </HoverCard.Dropdown>
      </HoverCard>
    );
  }
};
