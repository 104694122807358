import { LogoutBtn } from "#webapp/src/components/logout-btn";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { paths } from "@clockwise/client-commons/src/constants/site";
import { Button, Link } from "@clockwise/design-system";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";

interface IProps {
  email: string;
}

export const AccountDetails = ({ email }: IProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div className="cw-flex cw-flex-col cw-gap-8 cw-m-y-4">
      <div className="cw-flex cw-justify-between">
        {email && <div className="cw-heading-xl">{email} </div>}
        <div className="cw-flex cw-items-center cw-gap-4 cw-caption">
          <Link onClick={() => setDialogOpen(true)}>Deactivate your Clockwise account</Link>
          <LogoutBtn anchor />
        </div>
      </div>
      <div className="cw-flex cw-items-center cw-gap-4">
        <Link target="_blank" href={paths.terms}>
          Terms of Service
        </Link>
        <Link target="_blank" href={paths.privacy}>
          Privacy Policy
        </Link>
      </div>
      <ConfirmDeactivationModal open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </div>
  );
};

const ConfirmDeactivationModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => (
  <Dialog open={open}>
    <DialogTitle>
      <div className="cw-heading-xl">
        Are you sure you want to deactivate your Clockwise account?
      </div>
    </DialogTitle>
    <DialogContent>
      <div className="cw-body-base">
        This action cannot be undone. It may take up to a few hours to process your deactivation
        request.
      </div>
    </DialogContent>
    <DialogActions>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button
        sentiment="destructive"
        onClick={() => {
          track(TrackingEvents.SETTINGS.DATA_SOURCE_DEACTIVATE_ACCOUNT);
          window.open(paths.uninstall, "_blank");
          onClose();
        }}
      >
        Deactivate
      </Button>
    </DialogActions>
  </Dialog>
);
