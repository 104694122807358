import {
  AltRouteFilled,
  ClockwiseIntelligenceFilled,
  EventAvailableFilled,
} from "@clockwise/icons";
import React from "react";
import { ContentContainer, ContentRow, Divider, EducationModal } from "../education-modal";
import { SCHEDULING_VIDEO_URL } from "../superpowers-checklist/videos";
const PrimaryContent = (
  <ContentContainer>
    <ContentRow>
      <EventAvailableFilled className="cw-text-busy cw-w-4 cw-h-4" />
      <div className="cw-text-13 cw-leading-5">
        Checks <span className="cw-font-medium">attendees' availability </span> across
        timezones—even if they aren't Clockwise users
      </div>
    </ContentRow>
    <Divider />
    <ContentRow>
      <AltRouteFilled className="cw-text-busy cw-w-4 cw-h-4" />
      <div className="cw-text-13 cw-leading-5">
        Offers <span className="cw-font-medium">multiple time suggestions</span>, putting you in
        control
      </div>
    </ContentRow>
    <Divider />
    <ContentRow>
      <ClockwiseIntelligenceFilled className="cw-text-busy cw-w-4 cw-h-4" />
      <div className="cw-text-13 cw-leading-5">
        When calendars are packed, Clockwise can <span className="cw-font-medium">move events</span>{" "}
        to create availability
      </div>
    </ContentRow>
  </ContentContainer>
);

export const NUXSchedulingModal = ({
  onClose,
  onClickAction,
}: {
  onClose: () => void;
  onClickAction: () => void;
}) => {
  return (
    <EducationModal
      header="Powerful scheduling"
      subheader="Clockwise navigates calendars to find the best time for you and your teammates"
      primaryButtonText="Schedule a meeting"
      mainContent={PrimaryContent}
      isOpen={true}
      onClose={onClose}
      onClickPrimaryButton={onClickAction}
      onClickSecondaryButton={onClose}
      videoLink={SCHEDULING_VIDEO_URL}
    />
  );
};
