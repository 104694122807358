import { useWindowSize } from "usehooks-ts";

const DEFAULT_NARROW_WIDTH = 420;

const useIsNarrow = (narrowWidth = DEFAULT_NARROW_WIDTH) => {
  const width = useWindowSize()?.width;
  return width < narrowWidth;
};

export default useIsNarrow;
