import { Button, Select, SelectOption } from "@clockwise/design-system";
import { Close } from "@clockwise/design-system/icons";
import { colors, fontFamilySans, greys, helpers } from "@clockwise/web-commons/src/styles";
import { makeStyles } from "@material-ui/core";
import { animated, config, useSpring } from "@react-spring/web";
import React from "react";
import { useSelectTable } from "../../../../hooks/useSelectTable";

export type BillingGroupAction = {
  actionLabel?: string;
  type: string;
  billingGroupId: string;
};

export type BillingGroupActionMap = {
  [k: string]: BillingGroupAction;
};

export interface BulkActionSnackbarProps {
  onSelect: (billingGroupId: string, actionType: string) => void;
}
const useStyles = makeStyles({
  container: {
    position: "absolute",
    color: greys.white,
    backgroundColor: greys.darkGreyBlue,
    bottom: 20,
    borderRadius: "60px",
    display: "flex",
    padding: "15px 20px",
    alignItems: "center",
  },
  label: {
    margin: "0 5px",
    minWidth: "150px",
  },
  deleteIcon: {
    color: greys.white,
    "&:hover": {
      color: helpers.opacity(greys.white, 0.4),
    },
    marginLeft: "10px",
  },
  button: {
    color: greys.white,
    fontWeight: 500,
    fontFamily: fontFamilySans,
    textTransform: "none",
    border: `1px solid ${greys.standard}`,
    "&:hover": {
      color: colors.green.standard,
    },
  },
  emptyMessage: {
    fontWeight: 400,
    color: greys.lightishGrey,
  },
});

export const BulkActionSnackbar = ({ onSelect }: BulkActionSnackbarProps) => {
  const classes = useStyles();
  const {
    isSelectDialogShowing,
    setIsSelectDialogShowing,
    selectedMembers,
    clearSelectedMembers,
    availableActions,
  } = useSelectTable();

  const label = `${selectedMembers.length} users selected`;

  const snackbarStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.default,
  });

  const onClose = (shouldDeleteSelectedMembers: boolean) => {
    setIsSelectDialogShowing(false);
    if (shouldDeleteSelectedMembers) {
      clearSelectedMembers();
    }
  };

  const renderOptions = (billingGroups: string[]) => {
    return billingGroups.map((bg: string) => {
      const action = availableActions[bg];

      return (
        <SelectOption key={action.billingGroupId} value={`${action.billingGroupId}-${action.type}`}>
          {action.actionLabel}
        </SelectOption>
      );
    });
  };

  const onSelectChange = (idActionTuple: string) => {
    const valueSplit = idActionTuple.split("-");
    const billingGroupId = valueSplit[0];
    const actionType = valueSplit[1];

    onSelect(billingGroupId, actionType);
  };

  const maybeRenderActions = () => {
    const billingGroups = Object.keys(availableActions);

    const isSelectShowing = billingGroups.length > 1;
    if (isSelectShowing) {
      return (
        <Select value="" onChange={onSelectChange}>
          <SelectOption value="">Select Action ...</SelectOption>
          {renderOptions(billingGroups)}
        </Select>
      );
    } else if (!isSelectShowing && billingGroups.length === 1) {
      const bg = billingGroups[0];
      const action = availableActions[bg];
      const onClickButton = () => {
        onSelect(action.billingGroupId, action.type);
        onClose(false);
      };
      if (action.type === "RemoveRequest" || action.type === "AddRequest") {
        return <div>{action.actionLabel}</div>;
      } else {
        return (
          <Button variant="outlined" sentiment="neutral" onClick={onClickButton}>
            {action.actionLabel}
          </Button>
        );
      }
    } else {
      return <p className={classes.emptyMessage}>No bulk actions available</p>;
    }
  };

  if (!isSelectDialogShowing) return null;

  return (
    <animated.div style={snackbarStyle} className={classes.container}>
      <p className={classes.label}> {`${label}: `} </p>
      {maybeRenderActions()}
      <div className="cw-ml-2">
        <Button
          variant="text"
          sentiment="accent"
          startIcon={Close}
          onClick={() => onClose(true)}
          iconColorClassOverride="cw-text-white"
        />
      </div>
    </animated.div>
  );
};
