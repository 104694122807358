//////////////////
// IMPORTS
//////////////////
// libraries
import { PhosphorProvider } from "@clockwise/web-commons/src/icons/phosphor";
import MomentUtils from "@date-io/moment";
import { isEqual, memoize } from "lodash";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
// modern-wrapper imports
import { ErrorPage } from "#webapp/src/components/error-page";
import { NetworkOffline } from "@clockwise/web-commons/src/components/network-offline";
import { SnackBar } from "../snack-bar/";
import * as s from "./ModernWrapper.styles";
// other components
import Div100vh from "react-div-100vh";
// state
import { setExperiments } from "#webapp/src/state/actions/auth.actions";
import { setIsOnline } from "#webapp/src/state/actions/chrome-extension.actions";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
// util
import { paths } from "#webapp/src/constants/site.constant";
import { group } from "#webapp/src/util/analytics.util";
import { EcosystemProvider } from "@clockwise/web-commons/src/util/ecosystem";
import { ZonedMoment } from "@clockwise/web-commons/src/util/time-zone.util";

import { fromGlobalId } from "#webapp/src/util/graphql.util";
import { getOnlineStatus } from "#webapp/src/util/health.util";
import { windowLocation } from "#webapp/src/util/location.util";
import { logger } from "#webapp/src/util/logger.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { recordDuration } from "#webapp/src/util/stats.util";
import { MonetizationProvider } from "../../hooks/useMonetization";
// material-ui
import { getBulkExperiments } from "#webapp/src/util/experiment.util";
import { TranslationContext } from "@clockwise/client-commons/src/providers/TranslationContext";
import { FlagNamespaces } from "@clockwise/client-commons/src/util/flags";
import { translate } from "@clockwise/client-commons/src/util/translations";
import { ExperimentsProvider } from "@clockwise/web-commons/src/util/ExperimentsContext";
import { FlagsProvider } from "@clockwise/web-commons/src/util/FlagsContext";
import { lastSignInDate, orgId } from "@clockwise/web-commons/src/util/local-storage";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTime } from "luxon";
import { LaunchDarklyProvider } from "../../launch-darkly";

import { OnboardingProvider } from "#webapp/src/hooks/useOnboarding/useOnboarding";
import { jwt, xsrf } from "#webapp/src/state/local-storage";
import { ServiceWorkerPostMessagePayload } from "#webapp/src/util/service-worker.util";
import { useQuery } from "@apollo/client";
import { ThemeProvider } from "@clockwise/design-system";
import { LoaderClock } from "@clockwise/web-commons/src/components/loader-clock";
import { isElectron } from "@clockwise/web-commons/src/util/browser.util";
import { ReactNode } from "react";
import { Location, useLocation } from "react-router-dom";
import { WelcomePageCanonicalTag } from "../seo/welcome-page-canonical-tag";
import { HelpScoutBeacon } from "../support/helpscout-beacon-widget";
import { ModernWrapperQueryDocument } from "./__generated__/ModernWrapper.generated";

interface IProps {
  isChromeExtension: IReduxState["webExtension"]["isWebExtension"];
  isOnline: boolean;
  setIsOnline: typeof setIsOnline;
  setExperiments: typeof setExperiments;
  authed: boolean;
  children: ReactNode;
  viewer: any;
  location: Location;
}

interface IState {
  boundaryError: boolean;
}

//////////////////
// COMPONENT
//////////////////
class ModernWrapperCmpt extends React.Component<IProps, IState> {
  private isRetrying = false;
  private retryCounts = 0;
  private healthCheckTimer?: ReturnType<typeof setTimeout>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      boundaryError: false,
    };
  }

  private storageChangeHandler = (event: any) => {
    // listen for jwt changes
    if (event.key === "Jwt") {
      if (event.newValue && !event.oldValue) {
        // sign in
        if (this.props.isChromeExtension) {
          windowLocation.reload("ModernWrapperJwtChrome");
        } else if (this.props.location) {
          const isLogin =
            this.props.location.pathname.startsWith(paths.login) ||
            this.props.location.pathname.startsWith(paths.signup) ||
            this.props.location.pathname.startsWith(paths.welcome);
          if (isLogin) {
            windowLocation.assign("ModernWrapperLogin", "/app");
          }
        }
      } else if (event.oldValue && !event.newValue) {
        // sign out
        // PostMessageManager.openSidebar();
        windowLocation.reload("ModernWrapperLogout");
      }
    }
  };

  private checkHealth = () => {
    if (this.isRetrying) {
      return Promise.resolve();
    }

    if (this.healthCheckTimer) {
      clearTimeout(this.healthCheckTimer);
      delete this.healthCheckTimer;
    }

    this.isRetrying = true;

    return getOnlineStatus().then((isOnline) => {
      if (isOnline) {
        if (!this.props.isOnline) {
          this.props.setIsOnline(true);
          recordDuration("client.reconnected", this.retryCounts, { project: "modern" });
        }
        this.healthCheckTimer = setTimeout(this.checkHealth, 1000 * 30);
      } else {
        if (this.props.isOnline) {
          this.props.setIsOnline(false);
          this.retryCounts = 0;
        }

        this.retryCounts++;
      }

      this.isRetrying = false;
    });
  };

  public componentDidCatch(error: Error) {
    logger.error("ModernWrapper componentDidCatch", error);

    this.setState({
      boundaryError: true,
    });
  }

  public componentDidMount() {
    this.checkHealth();

    // analytics
    const org = getCurrentOrg(this.props.viewer);
    if (org) {
      group(`Org::${fromGlobalId(org.id).id}`, {
        name: org.name,
        created: new ZonedMoment(org.createdTime),
      });

      if (org.experiments) {
        this.props.setExperiments(org.experiments);
      } else {
        logger.warn("User has org object but no experiments");
      }

      this.onLoginMount(org.id);
    }

    // listen for storage changes to detect sign in
    window.addEventListener("storage", this.storageChangeHandler, false);

    if (!lastSignInDate.get() && this.props.authed) {
      // If signed in and this is not set, set it.
      lastSignInDate.set(DateTime.now().toISO());
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const org = getCurrentOrg(this.props.viewer);
    const nextOrg = getCurrentOrg(nextProps.viewer);

    if (org && nextOrg && !isEqual(org.experiments, nextOrg.experiments)) {
      this.props.setExperiments(nextOrg.experiments);
    }

    if (!org && nextOrg) {
      this.onLoginMount(nextOrg.id);
    }
  }

  private onLoginMount = (id: string) => {
    // let service worker know that app has loaded
    void navigator.serviceWorker.ready.then((registration) => {
      const payload: ServiceWorkerPostMessagePayload = {
        message: "cw-app-loaded",
        data: {
          jwt: jwt.get(),
          xsrf: xsrf.get(),
          orgId: id ? id : orgId.get(),
          browserNotificationPermission: Notification.permission,
        },
      };
      registration.active?.postMessage(payload);
    });
  };

  public componentWillUnmount() {
    window.removeEventListener("storage", this.storageChangeHandler, false);
  }

  public render(): JSX.Element {
    const isElectronAndTrayClosed = isElectron && !document.hasFocus();
    if (!this.props.isOnline && !isElectronAndTrayClosed) {
      // ClockwiseMini needs to stay rendered in Electron Desktop app, even when offline, to be able to send messages
      // messages to the main process to update the event title in the menu bar.
      return (
        <Div100vh
          style={{
            height: "100rvh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <ThemeProvider>
            <NetworkOffline onRetry={this.checkHealth} />
          </ThemeProvider>
        </Div100vh>
      );
    }

    if (this.state.boundaryError) {
      return <ErrorPage errors={[]} />;
    }

    const description =
      "Clockwise optimizes your team's calendars to create more time in everyone's day. Get started for free.";

    const org = getCurrentOrg(this.props.viewer);
    const webAppFlagsErrorable = this.props.viewer.user?.webAppFlagsErrorable ?? null;
    const featureFlags = getBulkExperiments();
    const memoizedTranslate = memoize(translate);
    const user = this.props.viewer.user;

    return (
      <PhosphorProvider>
        <ThemeProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <EcosystemProvider>
              <ExperimentsProvider experiments={featureFlags}>
                <LaunchDarklyProvider
                  org={org}
                  user={user}
                  loading={false /* Relay won't be rendering this until loading is false */}
                >
                  <MonetizationProvider orgId={org?.id || null}>
                    <FlagsProvider
                      namespace={FlagNamespaces.Webapp}
                      flagsErrorable={webAppFlagsErrorable}
                    >
                      <HelpScoutBeacon />
                      <WelcomePageCanonicalTag />
                      <OnboardingProvider orgId={org?.id || null}>
                        <TranslationContext.Provider value={memoizedTranslate()}>
                          <div className="cw-font-body cw-bg-default" style={s.modernStyles}>
                            <Helmet>
                              <title>Clockwise</title>
                              <meta name="description" content={description} />
                              <meta property="og:description" content={description} />
                              <meta name="twitter:description" content={description} />
                              <meta itemProp="description" content={description} />
                            </Helmet>
                            {this.props.children}
                            <SnackBar />
                          </div>
                        </TranslationContext.Provider>
                      </OnboardingProvider>
                    </FlagsProvider>
                  </MonetizationProvider>
                </LaunchDarklyProvider>
              </ExperimentsProvider>
            </EcosystemProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </PhosphorProvider>
    );
  }
}

export const ModernWrapperPage = ({ children }: { children: ReactNode }) => {
  const isWebExtension = useSelector((state: IReduxState) => state.webExtension.isWebExtension);
  const isOnline = useSelector((state: IReduxState) => state.webExtension.isOnline);
  const authed = useSelector((state: IReduxState) => state.auth.authed);

  const dispatch = useDispatch();
  const location = useLocation();

  const { data, loading } = useQuery(ModernWrapperQueryDocument);
  const viewer = data?.viewer;

  if (loading || !viewer) {
    return (
      <div className="cw-w-full cw-h-screen cw-flex cw-items-center cw-justify-center">
        <LoaderClock />
      </div>
    );
  }

  return (
    <ModernWrapperCmpt
      isChromeExtension={isWebExtension}
      isOnline={isOnline}
      authed={authed}
      setIsOnline={(isOnline) => dispatch(setIsOnline(isOnline))}
      setExperiments={(experiments) => dispatch(setExperiments(experiments))}
      location={location}
      viewer={viewer}
    >
      {children}
    </ModernWrapperCmpt>
  );
};
