import { TradeoffTypeEnum } from "@clockwise/schema";
import { Sticker } from "@clockwise/web-commons/src/components/sticker/Sticker";
import React from "react";

export const StickerWrapper = ({ text, variant }: { text: string; variant?: TradeoffTypeEnum }) => {
  const getSentimentFromVariant = () => {
    switch (variant) {
      case TradeoffTypeEnum.AVAILABILITY_ISSUE:
        return "warning";
      case TradeoffTypeEnum.FIXABLE_CONFLICT:
        return "info";
      default:
        return undefined;
    }
  };

  return (
    <div className="cw-inline-flex" aria-label={variant?.toString()} cw-id="markdown-pill">
      <Sticker maxWidth={200} text={text} size="inline" sentiment={getSentimentFromVariant()} />
    </div>
  );
};
