import { graphql } from "react-relay";

export const chromeSlackVerifierFragments = {
  viewer: graphql`
    fragment ChromeSlackVerifier_viewer on Viewer {
      user {
        id
        emails
        orgs(first: 1000000) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `,
};
