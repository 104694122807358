import { ConflictEvent } from "@clockwise/schema";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useState } from "react";
import {
  useReadDismissedConflictClusters,
  useUpdateDismissedConflictClusters,
} from "../../chat-plus-calendar/util/DismissedConflictClusterContext";
import { ConflictClusterWithConversationId } from "../../chat-plus-calendar/util/parseConflictClustersGQL";
import { ConflictEventActions } from "./ConflictEventsActions";
import { ConflictEventsList } from "./ConflictEventsList";
import { PopoverWrapper } from "./PopoverWrapper";

export const ConflictPopover = ({
  calendarId,
  activeConflict,
  anchorEl,
  onClose,
}: {
  calendarId: string;
  activeConflict: ConflictClusterWithConversationId | null;
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
}) => {
  const track = useTracking();
  const dismissedConflictIds = useReadDismissedConflictClusters();
  const setDismissedConflicts = useUpdateDismissedConflictClusters();
  const [selectedEvent, setSelectedEvent] = useState<ConflictEvent | null>(null);

  const onClosePopover = () => {
    setSelectedEvent(null);
    onClose();
  };

  const onGoBack = () => {
    setSelectedEvent(null);
  };

  const onSelectEvent = (eventID: string) => {
    const newActiveEvent = activeConflict?.conflictEvents.find((event) => {
      return event.id === eventID;
    });
    setSelectedEvent(newActiveEvent || null);
  };

  const onDismissConflict = () => {
    if (activeConflict) {
      track(TrackingEvents.CONFLICT_RESOLUTION.DISMISSED_CONFLICT, {
        clusterId: activeConflict.id,
        conversationId: activeConflict.conversationId,
      });
      setDismissedConflicts([...dismissedConflictIds, activeConflict.id]);
    }
    onClosePopover();
  };

  return (
    <PopoverWrapper
      id={activeConflict?.id || "popoverId"}
      anchorEl={anchorEl}
      onClose={onClosePopover}
    >
      {selectedEvent ? (
        <ConflictEventActions
          event={selectedEvent}
          onGoBack={onGoBack}
          calendarId={calendarId}
          onClose={onClose}
          clusterId={activeConflict?.id || ""}
          conversationId={activeConflict?.conversationId || ""}
        />
      ) : (
        <ConflictEventsList
          events={activeConflict?.conflictEvents || []}
          onClickOfEvent={onSelectEvent}
          onDismissConflict={onDismissConflict}
        />
      )}
    </PopoverWrapper>
  );
};
