import { ZoomButton } from "#webapp/src/components/zoom-button";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
import * as React from "react";

import { Delete } from "@clockwise/design-system/icons";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import { Button, Link } from "@clockwise/design-system";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import toast from "react-hot-toast";
import { DeleteZoomAccountDocument } from "../admin-organization-settings/__generated__/OrganizationSettings.v2.generated";
import { RefetchOrgAccount } from "../admin-organization-settings/OrganizationSettings";

export type ZoomAccountOrgSettings = {
  id: string;
  valid: boolean;
  displayName: string;
};

export const ZoomSettings = ({
  userId,
  zoomAccount,
  refetchOrgAccount,
}: {
  userId: string;
  zoomAccount: ZoomAccountOrgSettings | null;
  refetchOrgAccount: RefetchOrgAccount;
}) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = React.useState(false);
  const [deleteZoomOrgAccount] = useGatewayMutation(DeleteZoomAccountDocument);

  const onLearnMore = () => {
    track(TrackingEvents.HELP.ZOOM);
  };

  const onClickConfirmDelete = (zoomAccount: ZoomAccountOrgSettings) => {
    track(TrackingEvents.ZOOM.DELETE);
    void deleteZoomOrgAccount({
      variables: {
        input: { accountId: zoomAccount.id },
      },
      onCompleted: () => {
        toast.success("Deleted your Zoom integration!");
        setShowDeleteConfirmationModal(false);
        void refetchOrgAccount();
      },
      onError: () => {
        const msg = `Failed to delete Zoom integration`;
        logger.error(msg);
        toast.error(msg);
      },
    });
  };

  const renderZoomAuthFlow = () => {
    return (
      <div className="cw-py-4">
        <ZoomButton
          onAuthPopupClosed={() => {
            refetchOrgAccount();
          }}
        />
      </div>
    );
  };

  const maybeRenderDeleteConfirmationModal = (zoomAccount: ZoomAccountOrgSettings) => {
    return (
      <Dialog
        open={showDeleteConfirmationModal}
        onClose={() => setShowDeleteConfirmationModal(false)}
        PaperProps={{ style: { maxWidth: 700, width: 700, textAlign: "center" } }}
      >
        <DialogTitle>
          <p className="cw-heading-xl">Are you sure you want to delete your Zoom integration?</p>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <p className="cw-body-lg cw-text-neutral">
            If you delete this integration, you will no longer be able to schedule with Zoom with
            Clockwise.
          </p>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setShowDeleteConfirmationModal(false)}>
            Cancel
          </Button>
          <Button
            variant="text"
            onClick={() => onClickConfirmDelete(zoomAccount)}
            sentiment="destructive"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderZoomSettings = (zoomAccount: ZoomAccountOrgSettings) => {
    const isConnectingUserCurrentUser = userId === zoomAccount.id;

    return (
      <div className="cw-py-4">
        <div className="cw-flex cw-items-center cw-justify-between">
          <div>
            <div className="cw-body-lg cw-text-neutral">
              {isConnectingUserCurrentUser
                ? `You have successfully set up your Zoom integration!`
                : `Your Zoom integration has been set up. Please contact your zoom account admin if you have any concerns.`}
            </div>
            <div className="cw-body-base cw-text-neutral cw-font-bold">
              Zoom account domain: {zoomAccount.displayName}
            </div>
          </div>
          {isConnectingUserCurrentUser ? (
            <Button
              onClick={() => setShowDeleteConfirmationModal(true)}
              startIcon={Delete}
              variant="text"
              sentiment="neutral"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="cw-heading-2xl cw-mb-2 cw-mt-5">Zoom admin integration</div>
      <div className="cw-body-base">
        Connect your organization's Zoom admin account to Clockwise to enable Zoom scheduling for
        everyone. Once set up, every user that has a Zoom account matching their work email account
        can schedule a Zoom meeting. Note: only your Zoom admin can set this up.{" "}
        <Link target="_blank" href={articleUrls.zoom} onClick={onLearnMore}>
          Learn more.
        </Link>
      </div>
      {zoomAccount ? renderZoomSettings(zoomAccount) : renderZoomAuthFlow()}
      {zoomAccount ? maybeRenderDeleteConfirmationModal(zoomAccount) : ""}
    </div>
  );
};
