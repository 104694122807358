import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import React, { ReactNode, useEffect } from "react";
import { FindOrCreateLinkNameDocument } from "./__generated__/FindOrCreateLinkName.v2.generated";

/**
 * Ensure the user has a link name set before rendering children
 */
export const EnsureLinkName = ({ children }: { children: ReactNode }) => {
  const [findOrCreateLinkName, { error, loading, data }] = useGatewayMutation(
    FindOrCreateLinkNameDocument,
  );

  useEffect(() => {
    void findOrCreateLinkName();
  }, []);

  if (error) {
    throw new Error(`Failed to find or create linkName for user: ${error.message}`);
  } else if (loading || !data) {
    return null;
  }

  return <>{children}</>;
};
