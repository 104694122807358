import React from "react";

import { Button } from "@clockwise/design-system";
import {
  CliCommandConfig,
  CliCommandConfigProps,
  SelectedOption,
} from "@clockwise/web-commons/src/ui/cli-command";

interface IProps {
  commands: CliCommandConfigProps["commands"];
  onExecute: (command: string, options: { name: string; values: string[] }[]) => void;
  onReset: () => void;
  className?: string;
  disabled?: boolean;
}

export const ControlPane = ({
  commands,
  onExecute,
  onReset,
  className = "",
  disabled = false,
}: IProps) => {
  const [command, setCommand] = React.useState<string>();
  const [options, setOptions] = React.useState<SelectedOption[]>();

  const canExecute = !disabled && !!command && !!options;

  return (
    <div className={className}>
      <CliCommandConfig
        commands={commands}
        disabled={disabled}
        onChange={(config) => {
          setCommand(config?.command);
          setOptions(config?.options);
          if (!config) {
            onReset();
          }
        }}
        onReset={onReset}
      />
      <div className="cw-my-3">
        <Button
          sentiment="positive"
          disabled={!canExecute}
          onClick={() => {
            if (canExecute) {
              onExecute(command, options);
            }
          }}
        >
          Execute
        </Button>
      </div>
    </div>
  );
};
