// libraries
import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { Button, Modal } from "@clockwise/design-system";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import classNames from "classnames";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { getFullName } from "../ui/members-content.util";
import { SearchField } from "../ui/search-field";
import { useAPMembers } from "../useAPMembers";
import { BillingGroupUsersInAdminDocument } from "./__generated__/AdminSelect.generated";

type AdminSelectProps = {
  orgId: string;
  billingGroupId: string;
  closeAdminSelectDialog: () => void;
  shouldFetchData: boolean;
};

type ReassignAdminDialogProps = {
  open: boolean;
  onClose: () => void;
  orgId: string;
  billingGroupName?: string;
  billingGroupId: string;
};

type OrgPersonSimple = {
  familyName: string;
  givenName: string;
  userId: string;
  primaryCalendarId: string;
  isYou: boolean;
};

export const ReassignAdminDialog = ({
  open,
  onClose,
  billingGroupName,
  orgId,
  billingGroupId,
}: ReassignAdminDialogProps) => {
  return (
    <Modal opened={open} onClose={onClose} title="Select a new admin" size="lg">
      <div className="cw-body-base cw-pb-4">
        You're the only admin of {billingGroupName}, please select a new admin before you leave the
        plan.
      </div>
      <AdminSelectForAdminPage
        billingGroupId={billingGroupId}
        orgId={orgId}
        closeAdminSelectDialog={onClose}
        shouldFetchData={open}
      />
    </Modal>
  );
};

const AdminSelectForAdminPage = ({ orgId, billingGroupId, shouldFetchData }: AdminSelectProps) => {
  const [billingGroupPersons, setBillingGroupPersons] = useState<OrgPersonSimple[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAdminUserId, setSelectedAdminUserId] = useState<string | null>(null);

  const {
    addAdminToBillingGroup,
    adminIdToRemove,
    org,
    onRemoveMember,
    setReassignAdminDialogOpen,
  } = useAPMembers();

  const { loading } = useQuery(BillingGroupUsersInAdminDocument, {
    variables: {
      orgId: orgId,
      billingGroupId: billingGroupId,
      searchQuery: searchQuery,
    },
    skip: !shouldFetchData,
    onCompleted: (res) => {
      const billingGroupResponse = getValue(res.node, "Org")?.billingGroupForId;
      if (billingGroupResponse?.__typename === "BillingGroup") {
        const personList = getValue(billingGroupResponse.personListPaginatedErrorable);
        if (personList && personList.searchPersons) {
          const formattedPersons = personList.searchPersons
            .map((person) => {
              return {
                familyName: person?.person?.profile?.familyName || "",
                givenName: person?.person?.profile?.givenName || "",
                userId: person?.person?.userId || "",
                primaryCalendarId: person?.person?.primaryCalendarId,
                isYou: person?.person?.isYou,
              };
            })
            .filter((p) => !p.isYou);
          setBillingGroupPersons(formattedPersons);
        }
      }
    },
  });

  const onReassignNewAdmin = async (userId: string, billingGroupId: string) => {
    if (adminIdToRemove.length > 0) {
      await addAdminToBillingGroup({
        variables: {
          input: {
            orgRelayId: org?.id || "",
            billingGroupId,
            userId,
          },
        },
        onCompleted: () => onReassignNewAdminSuccess(adminIdToRemove, billingGroupId),
        onError: () => onReassignNewAdminFailure(),
      });
    }
  };

  const onReassignNewAdminSuccess = async (userIdToRemove: string, billingGroupId: string) => {
    toast.success("New Admin added sucessfully");
    await onRemoveMember([userIdToRemove], billingGroupId);
    closeAdminSelectDialog();
  };

  const closeAdminSelectDialog = () => {
    setReassignAdminDialogOpen(false);
  };

  const onReassignNewAdminFailure = () => {
    const errorMsg = "Failed to add new admin";
    logger.error(errorMsg);
    toast.error(errorMsg);
  };

  const onSelectSuggestedPerson = (person: OrgPersonSimple) => {
    setSelectedAdminUserId(person.userId);
  };

  const onClickConfirm = () => {
    if (!!selectedAdminUserId && !!billingGroupId) {
      onReassignNewAdmin(selectedAdminUserId, billingGroupId);
    }
  };

  const handleAdminSearch = (query: string) => {
    setSearchQuery(query);
  };

  const renderSuggestions = () => {
    return billingGroupPersons.map((person) => {
      const name = getFullName({
        givenName: person.givenName,
        familyName: person.familyName,
      });
      const cellClass = classNames(
        "cw-flex cw-items-center cw-p-2 cw-pl-0 hover:cw-bg-default-hover cw-cursor-pointer cw-rounded-md",
        {
          "cw-bg-default-pressed": person.userId === selectedAdminUserId,
          "cw-bg-default": person.userId !== selectedAdminUserId,
        },
      );
      return (
        <li
          className={cellClass}
          key={person.userId}
          onClick={() => onSelectSuggestedPerson(person)}
        >
          <AttendeeAvatar profile={person} size="xlarge" />
          <div className="cw-ml-2">
            <div className="cw-flex cw-items-center cw-mb-1">
              <div className="cw-body-base">{name}</div>
            </div>
            <div className="cw-body-sm">{person.primaryCalendarId}</div>
          </div>
        </li>
      );
    });
  };

  return (
    <div>
      <SearchField value={searchQuery} onSearch={handleAdminSearch} />
      <ul className="cw-flex cw-flex-col cw-overflow-y-scroll cw-max-h-[170px]">
        {renderSuggestions()}
      </ul>
      {!billingGroupPersons.length && !loading && <p> No results found </p>}
      <div className="cw-flex cw-justify-end">
        <Button variant="text" onClick={closeAdminSelectDialog}>
          Close
        </Button>
        <Button variant="text" sentiment="positive" onClick={onClickConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
};
