import { Button } from "@clockwise/design-system";
import { DiffActionTypeEnum } from "@clockwise/schema";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { newUserSignedupViaSharedProposal } from "@clockwise/web-commons/src/util/local-storage";
import { isEmpty } from "lodash";
import { Interval } from "luxon";
import React from "react";
import {
  GQLAddDiffSummary,
  GQLModifyDiffSummary,
  GQLOtherCalDiff,
  GQLPerson,
  GQLYourCalDiff,
} from "../chat/ai-chat/utils/types";
import { formattedDateTime } from "../web-app-calendar/calendar-popover/utils/formattedDateTime";
import { ProposalOwnerAvatar } from "./ProposalOwnerAvatar";

type ProposalExpandedInactiveProps = {
  proposalOwner: GQLPerson;
  viewerIsProposalOwner: boolean;
  yourCalDiffs: GQLYourCalDiff | [];
  otherCalDiffs: GQLOtherCalDiff | [];
  proposalState: string;
  diff: GQLAddDiffSummary | GQLModifyDiffSummary;
};

const getDiffActionText = (diffAction: { type: DiffActionTypeEnum }) => {
  switch (diffAction.type) {
    case DiffActionTypeEnum.ADD:
      return "Added";
    case DiffActionTypeEnum.EDIT_DETAILS:
      return "Edited";
    case DiffActionTypeEnum.RESCHEDULE:
      return "Moved";
    case DiffActionTypeEnum.CANCEL:
      return "Cancelled";
    default:
      return null;
  }
};

const getTimeText = (time: string | null) => {
  return time ? formattedDateTime(Interval.fromISO(time).start) : null;
};

const renderDiffAction = (diff: {
  id: string;
  title: string;
  action: { type: DiffActionTypeEnum };
  updatedTime: string | null;
}) => {
  const diffActionText = getDiffActionText(diff.action);
  if (!diffActionText) return null;

  const diffUpdatedTime = getTimeText(diff.updatedTime || null);

  return (
    <li key={diff.id}>
      {diffActionText} <span className="cw-font-semibold">{diff.title}</span>
      {diffUpdatedTime && ` - ${diffUpdatedTime}`}
    </li>
  );
};

export const ProposalExpandedInactive = ({
  proposalOwner,
  viewerIsProposalOwner,
  yourCalDiffs,
  otherCalDiffs,
  proposalState,
  diff,
}: ProposalExpandedInactiveProps) => {
  const diffTime = diff.__typename === "AddDiffSummary" ? diff.time : null;
  const intervalFormatted = getTimeText(diffTime);

  const handleGoToClockwise = () => {
    if (newUserSignedupViaSharedProposal.get()) {
      newUserSignedupViaSharedProposal.set(false);
      window.location.assign(appPaths.onboarding);
    } else {
      window.location.assign("/app");
    }
  };

  const getTitle = () => {
    switch (proposalState) {
      case "COMPLETED":
        return "Proposal confirmed";
      case "CANCELLED":
        return "Proposal cancelled";
      default:
        return null;
    }
  };

  const getDescription = () => {
    switch (proposalState) {
      case "COMPLETED":
        return `Thank you for replying! The meeting has been booked${
          isEmpty(yourCalDiffs) && isEmpty(otherCalDiffs)
            ? "."
            : " and the follwing changes were made."
        }`;
      case "CANCELLED":
        return "The proposal has been cancelled.";
      default:
        return null;
    }
  };

  const title = getTitle();
  const description = getDescription();
  const buttonText = newUserSignedupViaSharedProposal.get()
    ? "Explore Clockwise"
    : "Go to Clockwise";

  return (
    <>
      <div className="cw-relative cw-flex cw-flex-col cw-bg-default cw-overflow-hidden cw-h-full cw-border cw-shadow-md cw-rounded-lg cw-divide-y cw-p-3">
        <div>
          <ProposalOwnerAvatar owner={proposalOwner} />
          {title && <span className="cw-font-semibold cw-pl-2">{title}</span>}
        </div>
        {description && <div className="cw-font-semibold cw-mt-4">{description}</div>}
        {proposalState === "COMPLETED" && (
          <>
            <div>
              <ul className="cw-space-y-2 cw-list-disc cw-px-4 cw-mt-2">
                <li>
                  Booked <span className="cw-font-semibold">{diff.title}</span>
                  {intervalFormatted && ` - ${intervalFormatted}`}
                </li>
                {yourCalDiffs.map((yourCalDiff) => renderDiffAction(yourCalDiff))}
                {viewerIsProposalOwner &&
                  otherCalDiffs.map((otherCaldiff) => renderDiffAction(otherCaldiff))}
              </ul>
            </div>
          </>
        )}
        <div className="cw-flex cw-flex-wrap cw-items-center cw-mt-4">
          <Button size="small" sentiment="positive" onClick={handleGoToClockwise}>
            {buttonText}
          </Button>
        </div>
      </div>
    </>
  );
};
