import React, { useEffect } from "react";
import Helmet from "react-helmet";
import OrganizationMembers from "../components/admin-organization-members/OrganizationMembers";
import { PageEvents, page } from "../util/analytics.util";

const OrganizationMembersPage = () => {
  useEffect(() => {
    page(PageEvents.ORGANIZATION_MEMBERS_PAGE);
  }, []);

  return (
    <>
      <Helmet>
        <title>Users · Clockwise</title>
      </Helmet>
      <OrganizationMembers />
    </>
  );
};

export default OrganizationMembersPage;
