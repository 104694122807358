import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FocusTimeGoalSettingsFragment = { __typename: 'FocusTimeGoals', id: string, enabled: boolean | null, preferredFocusIn: Types.PreferredFocusTime | null, threshold: { __typename: 'AllFocusTime' } | { __typename: 'SomeFocusTime', idealFocus: any } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null, autoDeclineSettings: { __typename: 'AutoDeclineSettings', enabled: boolean, threshold: any } | null, dailyMaxSettings: { __typename: 'DailyMaxSettings', enabled: boolean, maxAmount: any } | null };

export type FocusTimeGoalsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FocusTimeGoalsQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', id: string, user: { __typename: 'User', id: string, orgs: { __typename: 'OrgConnection', edges: Array<{ __typename: 'OrgEdge', node: { __typename: 'Org', id: string, smartHoldGoals: { __typename: 'SmartHoldGoals', id: string, focusTime: { __typename: 'FocusTimeGoals', id: string, enabled: boolean | null, preferredFocusIn: Types.PreferredFocusTime | null, threshold: { __typename: 'AllFocusTime' } | { __typename: 'SomeFocusTime', idealFocus: any } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null, autoDeclineSettings: { __typename: 'AutoDeclineSettings', enabled: boolean, threshold: any } | null, dailyMaxSettings: { __typename: 'DailyMaxSettings', enabled: boolean, maxAmount: any } | null } } } | null } | null> | null } } | null } };

export const FocusTimeGoalSettingsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FocusTimeGoalSettings"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FocusTimeGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"threshold"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AllFocusTime"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SomeFocusTime"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"idealFocus"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"remoteHoldSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"scheduleAs"}},{"kind":"Field","name":{"kind":"Name","value":"notificationStrategy"}}]}},{"kind":"Field","name":{"kind":"Name","value":"preferredFocusIn"}},{"kind":"Field","name":{"kind":"Name","value":"autoDeclineSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"threshold"}}]}},{"kind":"Field","name":{"kind":"Name","value":"dailyMaxSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"maxAmount"}}]}}]}}]} as unknown as DocumentNode<FocusTimeGoalSettingsFragment, unknown>;
export const FocusTimeGoalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FocusTimeGoals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"orgs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"smartHoldGoals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"focusTime"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FocusTimeGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FocusTimeGoalSettings"}}]}}]}}]}}]}}]}}]}}]}}]}}]}},...FocusTimeGoalSettingsFragmentDoc.definitions]} as unknown as DocumentNode<FocusTimeGoalsQuery, FocusTimeGoalsQueryVariables>;