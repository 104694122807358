import { Button, Tooltip } from "@clockwise/design-system";
import { Info } from "@clockwise/icons";
import React from "react";
import { CalloutBox } from "../atoms/CalloutBox";

export const PausedControl = ({ onResume }: { onResume: () => void }) => {
  return (
    <div className="cw-flex cw-flex-col cw-gap-2">
      <div className="cw-inline-block">
        <Tooltip title="This meeting is paused because it was manually rescheduled. Clockwise will not move a flexible meeting that has been manually rescheduled. Future meetings in this series will still move.">
          <Button variant="outlined" size="xsmall" onClick={onResume}>
            Let it move again
            <Info className="cw-w-3.5 cw-h-3.5" />
          </Button>
        </Tooltip>
      </div>
      <CalloutBox>
        Other events in this series will still move within the day at the best time for attendees’
      </CalloutBox>
    </div>
  );
};
