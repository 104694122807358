import { map } from "lodash";
import { DateTime } from "luxon";
import React, { ReactNode } from "react";
import { CalendarDate } from "./CalendarDate";

export const CalendarDates = ({
  dateTimes,
  onClick,
  TimeZoneWrap = NonWrap,
}: {
  dateTimes: DateTime[];
  onClick: (date: DateTime) => void;
  TimeZoneWrap?: React.FC<{ children: ReactNode }>;
}) => {
  const timeZone = dateTimes[0].toFormat("ZZZZ");

  return (
    <div className={"cw-flex cw-flex-row cw-flex-1 cw-flex-grow-0 cw-bg-default cw-py-[2px]"}>
      <div className="cw-flex cw-flex-row cw-flex-1 cw-overflow-auto">
        <div className="cw-w-[40px] cw-flex cw-items-center">
          <div className="cw-body-base cw-text-[10px] cw-text-subtle">
            <TimeZoneWrap>{timeZone}</TimeZoneWrap>
          </div>
        </div>
        {map(dateTimes, (dateTime) => (
          <div key={dateTime.toISODate()} className="cw-flex-1 cw-min-w-0 cw-mr-[2px]">
            <CalendarDate dateTime={dateTime} onClick={onClick} />
          </div>
        ))}
      </div>
    </div>
  );
};

const NonWrap: React.FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;
