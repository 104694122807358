import { TextField } from "@clockwise/design-system";
import { Search } from "@clockwise/design-system/icons";
import React from "react";

export interface SearchFieldProps {
  value: string;
  onSearch: (query: string) => void;
}

export const SearchField = ({ value, onSearch }: SearchFieldProps) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <div>
      <TextField
        placeholder="Search for teammates"
        startIcon={Search}
        value={value}
        onChange={handleSearch}
      />
    </div>
  );
};
