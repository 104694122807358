import { IWorkingHours } from "#webapp/src/__schema__";
import { fg_muted } from "@clockwise/design-system/tokens";
import {
  TimeZoneDisplayMode,
  TimeZonePicker,
} from "@clockwise/web-commons/src/ui/time-zone-picker";
import {
  CopyButtonStyle,
  MeetingOrWorkingHours,
} from "@clockwise/web-commons/src/ui/working-hours";
import { CircleNotch } from "phosphor-react";
import React from "react";
import WorkingHoursSkeleton from "./components/WorkingHoursSkeleton";
import { WeekSettingContainer } from "./components/week-setting";

const WorkingHours = ({
  workingHours,
  loading,
  onUpdateWorkingHours,
  onUpdateTimeZone,
}: {
  workingHours: IWorkingHours | null;
  loading: boolean;
  onUpdateWorkingHours: (updatedWorkingHours: IWorkingHours) => void;
  onUpdateTimeZone: (timeZone: string) => void;
}) => {
  return (
    <div className="cw-w-full">
      <>
        {loading ? (
          <WorkingHoursSkeleton />
        ) : (
          <WeekSettingContainer
            copyButtonStyle={CopyButtonStyle.Standard}
            workingHours={workingHours as any} // TODO: https://linear.app/getclockwise/issue/GM-1603/once-onboardingnux-is-deleted-clean-up-types-for-splitworkinghours-and
            onUpdateWorkingHours={onUpdateWorkingHours as any}
            isMeetingOrWorkingHours={MeetingOrWorkingHours.WorkingHours}
          />
        )}
        <div className="cw-flex cw-items-center cw-w-full cw-py-2 cw-mt-4">
          <div className="cw-heading-base cw-mr-6"> Timezone</div>
          {loading ? (
            <CircleNotch
              className="cw-animate-spin cw-h-9"
              color={fg_muted}
              data-chromatic="ignore"
            />
          ) : (
            <TimeZonePicker
              timeZoneDisplayMode={TimeZoneDisplayMode.JustPicker}
              onChange={onUpdateTimeZone}
              value={workingHours?.timeZone ?? undefined}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default WorkingHours;
