//////////////////
// IMPORTS
//////////////////

// libraries
import React, { useEffect } from "react";

// components
import {
  APMembers,
  APMembersProvider,
} from "#webapp/src/components/admin-panel/admin-panel-view/ap-members";
import { WebSettingsContainer } from "../web-settings-container";

// util
import { page, PageEvents } from "#webapp/src/util/analytics.util";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { useAPMembers } from "../../admin-panel/admin-panel-view/ap-members/useAPMembers";

// ~-~-~-~-~-~-~-
// Web Settings Organization Members
// ~-~-~-~-~-~-~-

const APWrapper = () => {
  const { org, initialQueriesFetched } = useAPMembers();
  if (!org || !initialQueriesFetched) {
    return <Loader size="xl" sentiment="positive" className="cw-mt-auto cw-mb-auto" />;
  }
  return <APMembers org={org} />;
};

export const WebSettingsOrganizationMembers = () => {
  useEffect(() => {
    page(PageEvents.WEB_SETTINGS_ORGANIZATION_MEMBERS);
  }, []);

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  return (
    <WebSettingsContainer>
      <APMembersProvider>
        <APWrapper />
      </APMembersProvider>
    </WebSettingsContainer>
  );
};
