import React from "react";
import { fg_default } from "../../../tokens";

type Props = {
  fill?: string;
  className?: string;
};

export function Prism({ fill = fg_default, className }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1853_4759)">
        <path
          d="M16.875 6.87251V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H11"
          stroke={fill}
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.75 1.875V4.0625"
          stroke={fill}
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.25 1.875V4.0625"
          stroke={fill}
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.125 6.875H16.875"
          stroke={fill}
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9458 13.2328L16.7433 14.0428C16.6974 14.0596 16.6558 14.0862 16.6212 14.1208C16.5867 14.1553 16.5601 14.1969 16.5433 14.2428L15.7333 16.4453C15.7094 16.51 15.6662 16.5659 15.6096 16.6054C15.5529 16.6449 15.4855 16.666 15.4165 16.666C15.3475 16.666 15.2801 16.6449 15.2235 16.6054C15.1668 16.5659 15.1236 16.51 15.0997 16.4453L14.2897 14.2428C14.2729 14.1969 14.2463 14.1553 14.2118 14.1208C14.1772 14.0862 14.1356 14.0596 14.0897 14.0428L11.8872 13.2328C11.8225 13.2089 11.7666 13.1657 11.7271 13.1091C11.6877 13.0524 11.6665 12.985 11.6665 12.916C11.6665 12.847 11.6877 12.7796 11.7271 12.723C11.7666 12.6663 11.8225 12.6231 11.8872 12.5992L14.0897 11.7892C14.1356 11.7724 14.1772 11.7458 14.2118 11.7113C14.2463 11.6767 14.2729 11.6351 14.2897 11.5892L15.0997 9.38675C15.1236 9.32199 15.1668 9.26611 15.2235 9.22664C15.2801 9.18717 15.3475 9.16602 15.4165 9.16602C15.4855 9.16602 15.5529 9.18717 15.6096 9.22664C15.6662 9.26611 15.7094 9.32199 15.7333 9.38675L16.5433 11.5892C16.5601 11.6351 16.5867 11.6767 16.6212 11.7113C16.6558 11.7458 16.6974 11.7724 16.7433 11.7892L18.9458 12.5992C19.0105 12.6231 19.0664 12.6663 19.1059 12.723C19.1453 12.7796 19.1665 12.847 19.1665 12.916C19.1665 12.985 19.1453 13.0524 19.1059 13.1091C19.0664 13.1657 19.0105 13.2089 18.9458 13.2328Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1853_4759">
          <rect width="20" height="20" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}
