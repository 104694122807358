import React, { ReactNode } from "react";

interface Props {
  title: string;
  description: ReactNode;
  children?: ReactNode;
}

export const HomeUninstallCard = ({ title, description, children }: Props) => (
  <div className="cw-flex cw-flex-col cw-items-center cw-justify-center cw-my-8 cw-px-12 cw-py-8 cw-rounded-lg cw-bg-default">
    <h1 className="cw-heading-2xl md:cw-heading-3xl cw-mb-3 cw-text-center">{title}</h1>
    <p className="cw-body-lg cw-max-w-[500px] cw-mb-8 cw-text-center">{description}</p>
    {children}
  </div>
);
