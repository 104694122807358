import { graphql } from "react-relay";

export const query = graphql`
  query WebSettingsTeamNoMeetingDayRelayQuery($orgRelayId: ID!) {
    viewer: viewer {
      id
    }
    org: node(id: $orgRelayId) {
      ... on Org {
        id
        primaryTeam {
          __typename
          ... on Team {
            teamId
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
        userTeams {
          __typename
          ... on TeamList {
            list {
              __typename
              teamId
              id
              teamId
              teamName
              teamCalendarId
              settings {
                __typename
                ... on TeamSettings {
                  manageTeamCalendar
                  useNoMeetingDay
                  noMeetingDayOfWeek
                  noMeetingDayEmailOnScheduleOver
                }
                ... on GraphEntityError {
                  statusCode
                  message
                }
              }
              userSettings {
                __typename
                ... on TeamUserSettings {
                  useNoMeetingDay
                }
                ... on GraphEntityError {
                  statusCode
                  message
                }
              }
            }
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
      }
    }
  }
`;
