import { PageLayout } from "@clockwise/web-commons/src/ui/page-layout/PageLayout";
import React from "react";
import Helmet from "react-helmet";
import { SudoProvisioning } from "./SudoProvisioning";

export const SudoProvisioningPage = () => {
  return (
    <>
      <Helmet>
        <title>Provisioning · Clockwise</title>
      </Helmet>
      <PageLayout>
        <SudoProvisioning />
      </PageLayout>
    </>
  );
};
