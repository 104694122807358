import { border_positive_muted } from "@clockwise/design-system/tokens";
import React from "react";

export const EmphasisBadge = () => {
  return (
    <div className="cw-flex cw-items-center cw-justify-center">
      <span
        style={{ backgroundColor: border_positive_muted }}
        className={"cw-rounded-full cw-w-1.5 cw-h-1.5 cw-absolute motion-safe:cw-animate-ping-slow"}
      />
      <span
        style={{ backgroundColor: border_positive_muted }}
        className="cw-rounded-full cw-w-1 cw-h-1 cw-absolute cw-rounded-full"
      />
    </div>
  );
};
