import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { OrgPersonSearchDocument } from "../../apollo/__generated__/OrgPersonSearch.generated";

export type CalendarProfile = {
  primaryCalendar: string;
  profile: {
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  } | null;
  isYou: boolean;
};

type QueryResponse = {
  calendarProfiles: CalendarProfile[];
  loading: boolean;
};

export const useCalendarProfilesFromSearch = (
  orgId: string,
  query: string,
  excludeCalendars?: string[],
): QueryResponse => {
  const { data, loading } = useQuery(OrgPersonSearchDocument, {
    variables: {
      orgRelayId: orgId,
      queryInput: query,
      excludeCalendars: excludeCalendars || [],
    },
  });

  const viewer = getValue(data?.viewer, "Viewer");
  const orgPersonQueryResult = getValue(
    viewer?.orgPersonQueryResultErrorable,
    "OrgPersonQueryResult",
  );

  const calendarProfiles =
    orgPersonQueryResult?.persons.map((a) => {
      return {
        primaryCalendar: a?.primaryCalendarId || "",
        profile: a?.profile || null,
        isYou: a?.isYou || false,
      };
    }) || [];

  return { calendarProfiles, loading };
};
