import { ConflictCluster } from "@clockwise/schema";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useActiveProposal } from "./useActiveProposal";

export const useActiveConflictClusters = () => {
  const { proposal, loading } = useActiveProposal();
  const activeConflictClusters: ConflictCluster[] = useMemo(() => {
    const activeClusters: ConflictCluster[] = [];
    if (proposal && !isEmpty(proposal.conflictClusters)) {
      proposal.conflictClusters.forEach((cluster) => {
        if (cluster.isActiveConflict) {
          activeClusters.push(cluster);
        }
      });
    }
    return activeClusters;
  }, [proposal]);

  return { activeConflictClusters, loading };
};
