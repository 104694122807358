import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SudoToggleAutoSyncForOrgMutationVariables = Types.Exact<{
  input: Types.SudoToggleAutoSyncForOrgMutationInput;
}>;


export type SudoToggleAutoSyncForOrgMutation = { __typename: 'Mutation', sudoToggleAutoSyncForOrg: { __typename: 'SudoToggleAutoSyncForOrgMutationPayload', clientMutationId: string | null } | null };


export const SudoToggleAutoSyncForOrgDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SudoToggleAutoSyncForOrg"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SudoToggleAutoSyncForOrgMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sudoToggleAutoSyncForOrg"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}}]}}]}}]} as unknown as DocumentNode<SudoToggleAutoSyncForOrgMutation, SudoToggleAutoSyncForOrgMutationVariables>;