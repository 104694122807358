import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UnpauseEventMutationVariables = Types.Exact<{
  externalEventId: Types.Scalars['ID'];
}>;


export type UnpauseEventMutation = { __typename: 'Mutation', unpauseEventFlexibility: { __typename: 'Event', id: string, canMarkFlexible: boolean, flexibility: { __typename: 'FlexDetails', allowedDays: Array<Types.DayOfWeek> | null, flexRange: Types.FlexRange | null, isFlexible: boolean, status: Types.FlexStatus, timeOfDayFlexRange: { __typename: 'TimeRange', start: string | null, end: string | null } | null } | null } | null };


export const UnpauseEventDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnpauseEvent"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unpauseEventFlexibility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"externalEventId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"canMarkFlexible"}},{"kind":"Field","name":{"kind":"Name","value":"flexibility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"allowedDays"}},{"kind":"Field","name":{"kind":"Name","value":"flexRange"}},{"kind":"Field","name":{"kind":"Name","value":"isFlexible"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"timeOfDayFlexRange"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"start"}},{"kind":"Field","name":{"kind":"Name","value":"end"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UnpauseEventMutation, UnpauseEventMutationVariables>;