//////////////////
// IMPORTS
//////////////////
// libraries
import { map } from "lodash";
import React from "react";
// calendar-color-picker imports
import { IColorOptionProps, INullOptionProps, IProps } from "./CalendarColorPickerTypes";
// util
import { useCalendarColors } from "@clockwise/web-commons/src/util/event-category-coloring";
// material-ui
import { Button, Tooltip } from "@clockwise/design-system";
import { Block, Brightness1, CheckCircle } from "@clockwise/design-system/icons";

/////////////////////
// COMMON COMPONENTS
/////////////////////
const NullColorOption = (props: INullOptionProps) => {
  const { active, onSelect, tooltipPosition, selectedColorIndex } = props;
  const defaultIsActive = !active || !selectedColorIndex;
  return (
    <Tooltip title="Default" placement={tooltipPosition || "top-end"}>
      <Button
        variant="text"
        sentiment={defaultIsActive ? "destructive" : "neutral"}
        startIcon={Block}
        onClick={() => onSelect(null)}
      />
    </Tooltip>
  );
};

const ColorOption = (props: IColorOptionProps) => {
  const { selectedColorIndex, active, onSelect, color, colorKey, tooltipPosition } = props;
  const colorIsActive = active && colorKey === selectedColorIndex;
  const Icon = colorIsActive ? CheckCircle : Brightness1;

  return (
    <Tooltip title={color.name} placement={tooltipPosition || "top-end"}>
      <Button
        variant="text"
        sentiment="accent"
        startIcon={Icon}
        onClick={() => onSelect(colorKey)}
        iconColorClassOverride={color.twForeground}
      />
    </Tooltip>
  );
};

/////////////////////
// LIST COMPONENT
/////////////////////
export const CalendarColorPicker = (props: IProps) => {
  const { calendarColors } = useCalendarColors();

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  return (
    <div className="cw-flex">
      <NullColorOption {...props} />
      {map(calendarColors, (color, key) => (
        <ColorOption key={key} color={color} colorKey={key} {...props} />
      ))}
    </div>
  );
};
