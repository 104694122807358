import { Day } from "@clockwise/schema";
import { makeStyles } from "@material-ui/core";
import * as React from "react";

import {
  CopyButtonStyle,
  DayCheckboxes,
  DayOnOffMap,
  MeetingOrWorkingHours,
  SlotRangesMap,
  SlotRangeWrapper,
} from "@clockwise/web-commons/src/ui/working-hours";
import { DaySetting } from "../day-setting";

export interface WeekSettingProps {
  slotRangesMap: SlotRangesMap;
  currentDayOnOffMap: DayOnOffMap;
  copyButtonStyle: CopyButtonStyle | null;
  isValidDayMap: boolean;
  isMeetingOrWorkingHours: MeetingOrWorkingHours;
  onSlotChange: (day: Day, updatedSlots: SlotRangeWrapper) => void;
  onClickCopy: (day: Day) => void;
  onToggleDay: (day: Day) => void;
  onLocationChange: (day: Day, workingLocationId: string | null) => void;
}

const useStyles = makeStyles({
  daySettings: {
    marginTop: 16,
  },
});

export const WeekSetting = ({
  slotRangesMap,
  currentDayOnOffMap,
  copyButtonStyle,
  isMeetingOrWorkingHours,
  isValidDayMap,
  onToggleDay,
  onClickCopy,
  onSlotChange,
  onLocationChange,
}: WeekSettingProps) => {
  const classes = useStyles();

  const renderDaySetting = () => {
    const firstDayOn = Object.keys(currentDayOnOffMap).find(
      (day) => currentDayOnOffMap[day as Day],
    );

    return Object.keys(currentDayOnOffMap).map((day) => {
      if (!currentDayOnOffMap[day as Day]) {
        return null;
      }

      return (
        <DaySetting
          key={day}
          label={day}
          copyButtonStyle={day === firstDayOn ? copyButtonStyle : null}
          isMeetingOrWorkingHours={isMeetingOrWorkingHours}
          slotRanges={slotRangesMap[day]}
          onSlotChange={(updatedSlots) => onSlotChange(day as Day, updatedSlots)}
          onClickCopy={() => onClickCopy(day as Day)}
          onLocationChange={(workingLocationId) => onLocationChange(day as Day, workingLocationId)}
        />
      );
    });
  };

  return (
    <div>
      <DayCheckboxes
        dayOnOffMap={currentDayOnOffMap}
        onToggleDay={onToggleDay}
        isValid={isValidDayMap}
      />
      <div className={classes.daySettings}>{renderDaySetting()}</div>
    </div>
  );
};
