import { useMutation, useQuery } from "@apollo/client";
import {
  CreateWorkingLocationMutationInput,
  DeleteWorkingLocationMutationInput,
  LocationTypeEnum,
  UpdateWorkingLocationMutationInput,
  UpdateWorkingLocationWeeklyScheduleMutation2Input,
  WorkingLocation,
} from "@clockwise/schema";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { throttle } from "throttle-debounce";
import { useFeatureFlag } from "../../../launch-darkly";
import { logger } from "../../../util/logger.util";
import {
  CreateWorkingLocationDocument,
  DeleteWorkingLocationDocument,
  UpdateWorkingLocationDocument,
  UpdateWorkingLocationWeeklySchedule2Document,
  WorkingLocationDocument,
} from "../graphql/__generated__/WorkingLocation.generated";

const notifySuccess = () => toast.success(`Your working location settings were updated`);
const notifyError = (error: Error) => {
  toast.error(`Failed to update working location`);
  logger.error(`Failed to update working location`, error);
};

export const useWorkingLocation = () => {
  const [isOnWorkingLocation] = useFeatureFlag("WorkingLocation");
  const { data, loading: workingLocationLoading } = useQuery(WorkingLocationDocument, {
    skip: !isOnWorkingLocation,
  });

  const [workingLocations, setWorkingLocations] = useState<WorkingLocation[] | null>(null);
  const [workingLocationWeeklySchedule, setWorkingLocationWeeklySchedule] = useState<
    {
      day: string;
      workingLocation: {
        id: string;
        name: string | null;
        locationType: LocationTypeEnum;
        address: string | null;
        timeZone: string | null;
      };
    }[]
  >([]);

  const [createWorkingLocation] = useMutation(CreateWorkingLocationDocument);
  const [updateWorkingLocation] = useMutation(UpdateWorkingLocationDocument);
  const [deleteWorkingLocation] = useMutation(DeleteWorkingLocationDocument);
  const [updateWorkingLocationWeeklySchedule] = useMutation(
    UpdateWorkingLocationWeeklySchedule2Document,
  );

  const throttledCreateWorkingLocation = useRef(throttle(250, createWorkingLocation)).current;
  const throttledUpdateWorkingLocation = useRef(throttle(250, updateWorkingLocation)).current;
  const throttledDeleteWorkingLocation = useRef(throttle(250, deleteWorkingLocation)).current;
  const throttledUpdateWorkingLocationWeeklySchedule = useRef(
    throttle(250, updateWorkingLocationWeeklySchedule),
  ).current;

  useEffect(() => {
    if (data) {
      const userWorkingLocations = data.viewer?.user?.orgs?.edges?.[0]?.node?.userWorkingLocations;
      if (userWorkingLocations && userWorkingLocations.__typename === "WorkingLocationList") {
        setWorkingLocations(userWorkingLocations.list);
      }

      const workingLocationWeeklySchedule =
        data.viewer?.user?.orgs?.edges?.[0]?.node?.workingLocationWeeklySchedule;
      if (
        workingLocationWeeklySchedule &&
        workingLocationWeeklySchedule.__typename === "WorkingLocationWeeklySchedule"
      ) {
        setWorkingLocationWeeklySchedule(
          workingLocationWeeklySchedule.workingLocationByDay.map((w) => ({
            day: w.day,
            workingLocation: w.workingLocation,
          })),
        );
      }
    }
  }, [data]);

  const onCreateWorkingLocation = (input: CreateWorkingLocationMutationInput) => {
    void throttledCreateWorkingLocation({
      variables: {
        input: input,
      },
      onCompleted: () => {
        notifySuccess();
      },
      onError: (error) => notifyError(error),
      refetchQueries: ["WorkingLocation"],
    });
  };

  const onUpdateWorkingLocation = (input: UpdateWorkingLocationMutationInput) => {
    void throttledUpdateWorkingLocation({
      variables: {
        input: input,
      },
      onCompleted: () => {
        notifySuccess();
      },
      onError: (error) => notifyError(error),
      refetchQueries: ["WorkingLocation"],
    });
  };

  const onDeleteWorkingLocation = (input: DeleteWorkingLocationMutationInput) => {
    void throttledDeleteWorkingLocation({
      variables: {
        input: input,
      },
      onCompleted: () => {
        notifySuccess();
      },
      onError: (error) => notifyError(error),
      refetchQueries: ["WorkingLocation"],
    });
  };

  const onUpdateWorkingLocationWeeklySchedule = (
    input: UpdateWorkingLocationWeeklyScheduleMutation2Input,
  ) => {
    void throttledUpdateWorkingLocationWeeklySchedule({
      variables: {
        input: input,
      },
      onCompleted: () => {
        notifySuccess();
      },
      onError: (error) => notifyError(error),
      refetchQueries: ["WorkingLocation"],
    });
  };

  const loading = !workingLocations || workingLocationLoading;

  return {
    loading,
    workingLocations,
    workingLocationWeeklySchedule,
    onCreateWorkingLocation,
    onUpdateWorkingLocation,
    onDeleteWorkingLocation,
    onUpdateWorkingLocationWeeklySchedule,
  };
};
