import { RepeatingEventSaveOption } from "@clockwise/schema";
import { EditEventAppliesTo, ProposalAppliesTo } from "@clockwise/schema/v2";

export function toProposalAppliesTo(appliesTo?: RepeatingEventSaveOption) {
  switch (appliesTo) {
    case RepeatingEventSaveOption.ThisInstanceOnly:
      return ProposalAppliesTo.Instance;
    case RepeatingEventSaveOption.AllInstances:
      return ProposalAppliesTo.AllInstances;
    case RepeatingEventSaveOption.ThisAndFutureInstances:
      return ProposalAppliesTo.ThisAndFuture;
    default:
      return ProposalAppliesTo.Instance;
  }
}
export function toEditEventAppliesTo(appliesTo?: RepeatingEventSaveOption) {
  switch (appliesTo) {
    case RepeatingEventSaveOption.ThisInstanceOnly:
      return EditEventAppliesTo.Instance;
    case RepeatingEventSaveOption.AllInstances:
      return EditEventAppliesTo.AllInstances;
    case RepeatingEventSaveOption.ThisAndFutureInstances:
      return EditEventAppliesTo.ThisAndFuture;
    default:
      return EditEventAppliesTo.Instance;
  }
}
