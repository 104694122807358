import classnames from "classnames";
import * as React from "react";
import { Doughnut } from "react-chartjs-2";

import { colors } from "../../../../styles/color.styles";
import { IIntegerDataPoint } from "../overview.types";

export interface DonutProps {
  data: IIntegerDataPoint[];
}

const DonutWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={classnames(
      "cw-relative",
      "cw-mt-[35px]",
      "cw-h-[220px] md:cw-h-[200px]",
      "cw-w-[500px] md:cw-w-[400px]",
      className,
    )}
  >
    {children}
  </div>
);

const options = {
  cutoutPercentage: 70,
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    position: "bottom",
    onClick: null,
    labels: {
      usePointStyle: true,
      boxWidth: 5,
      padding: 15,
      generateLabels: (chart: any) => {
        const { data } = chart;
        const dataset = data?.datasets?.[0];
        let totalValue: number;

        if (dataset) {
          totalValue = dataset.data.reduce((prev: number, curr: number) => prev + curr);
        }

        if (data?.labels?.length && data?.datasets?.length) {
          return data.labels.map((label: string, i: number) => {
            const currentValue = dataset.data[i];
            const currentPercent = totalValue ? Math.round((currentValue * 100) / totalValue) : 0;
            return {
              text: `${label}: ${currentPercent}%`,
              fillStyle: dataset.backgroundColor[i],
              strokeStyle: dataset.backgroundColor[i],
              lineWidth: 1,
              hidden: isNaN(data.datasets[0].data[i]) || dataset.data[i].hidden,
              // Extra data used for toggling the correct item
              index: i,
            };
          });
        }
        return [];
      },
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem: any, data: any) => {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const totalValue = dataset.data.reduce((prev: number, curr: number) => prev + curr);
        const currentLabel = data.labels[tooltipItem.index];
        const currentValue = dataset.data[tooltipItem.index];
        const currentPercent = totalValue ? Math.round((currentValue * 100) / totalValue) : 0;
        if (!currentLabel) {
          return " None yet";
        }

        return ` ${currentLabel}: ${currentPercent}%`;
      },
    },
  },
};

const backgroundColor = [
  colors.blueDusk.lighter,
  colors.green.lighter,
  colors.blueSky.lighter,
  colors.yellow.lighter,
  colors.purple.lighter,
  colors.peach.lighter,
];

const EmptyDonut = () => {
  return (
    <DonutWrapper>
      <Doughnut
        data={{
          labels: [],
          datasets: [{ label: "# active users", data: [100], backgroundColor, borderWidth: 1 }],
        }}
        options={options}
      />
    </DonutWrapper>
  );
};

export const Donut = ({ data }: DonutProps) => {
  const labels: string[] = [];
  const values: number[] = [];
  const MAX_CATEGORIES = 5;
  const CATEGORY_OTHER = "Other";

  if (!data.length) {
    return <EmptyDonut />;
  }

  // Display the 5 largest categories of data and lump the rest, if any, into "Other"
  const sortedData = data
    .slice()
    .sort((dataPoint1, dataPoint2) => dataPoint2.value - dataPoint1.value);

  const categorizedByLabelData = sortedData.slice(0, MAX_CATEGORIES);
  const categorizedAsOtherData = sortedData.slice(MAX_CATEGORIES, sortedData.length);

  for (const dataPoint of categorizedByLabelData) {
    labels.push(dataPoint.label);
    values.push(dataPoint.value);
  }

  // If there's already an "Other" category being displayed, skip this
  if (categorizedAsOtherData.length > 0 && !labels.includes(CATEGORY_OTHER)) {
    labels.push(CATEGORY_OTHER);
    const otherPercent = categorizedAsOtherData
      .map((dataPoint) => dataPoint.value)
      .reduce((prev, curr) => prev + curr);
    values.push(otherPercent);
  }

  const dataConfig = {
    labels,
    datasets: [{ label: "# active users", data: values, backgroundColor, borderWidth: 1 }],
  };

  return (
    <DonutWrapper className="chromatic-ignore">
      <Doughnut data={dataConfig} options={options} />
    </DonutWrapper>
  );
};
