import { Menu, MenuItem } from "@clockwise/design-system";
import { RepeatingEventSaveOption } from "@clockwise/schema";
import { EditEventAppliesTo } from "@clockwise/schema/v2";
import React, { ComponentProps } from "react";
import { SubmitDeleteSelectCopy } from "../utils/getSubmitButtonCopy";

export const SaveDropdown = ({
  onSelect,
  disabledRecurrenceSaveOptions,
  copy,
  disabled,
}: {
  disabledRecurrenceSaveOptions: RepeatingEventSaveOption[];
  onSelect: (option: RepeatingEventSaveOption) => void;
  copy: SubmitDeleteSelectCopy;
  disabled: boolean;
}) => {
  return (
    <Menu
      disabled={disabled}
      size="small"
      variant="filled"
      sentiment="positive"
      label={copy.default}
    >
      <MenuItem
        onClick={() => onSelect(RepeatingEventSaveOption.ThisInstanceOnly)}
        disabled={disabledRecurrenceSaveOptions.includes(RepeatingEventSaveOption.ThisInstanceOnly)}
      >
        {copy.singleEventOnly}
      </MenuItem>
      <MenuItem
        onClick={() => onSelect(RepeatingEventSaveOption.ThisAndFutureInstances)}
        disabled={disabledRecurrenceSaveOptions.includes(
          RepeatingEventSaveOption.ThisAndFutureInstances,
        )}
      >
        {copy.thisAndFutureEvents}
      </MenuItem>
      <MenuItem
        onClick={() => onSelect(RepeatingEventSaveOption.AllInstances)}
        disabled={disabledRecurrenceSaveOptions.includes(RepeatingEventSaveOption.AllInstances)}
      >
        {copy.allEvents}
      </MenuItem>
    </Menu>
  );
};

export const SaveDropdownForRecurringEvent = ({
  onSelect,
  submissionCopy,
  disabled,
  sentiment = "positive",
  variant = "filled",
}: {
  onSelect: (option: EditEventAppliesTo) => void;
  submissionCopy: SubmitDeleteSelectCopy;
  disabled: boolean;
  sentiment?: ComponentProps<typeof Menu>["sentiment"];
  variant?: ComponentProps<typeof Menu>["variant"];
}) => {
  return (
    <Menu
      disabled={disabled}
      size="small"
      variant={variant}
      sentiment={sentiment}
      label={submissionCopy.default}
    >
      <MenuItem onClick={() => onSelect(EditEventAppliesTo.Instance)}>
        {submissionCopy.singleEventOnly}
      </MenuItem>
      <MenuItem onClick={() => onSelect(EditEventAppliesTo.ThisAndFuture)}>
        {submissionCopy.thisAndFutureEvents}
      </MenuItem>
      <MenuItem onClick={() => onSelect(EditEventAppliesTo.AllInstances)}>
        {submissionCopy.allEvents}
      </MenuItem>
    </Menu>
  );
};
