import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { getOwnCalendarColor } from "@clockwise/web-commons/src/util/calendar-coloring";
import { Profile } from "@clockwise/web-commons/src/util/profile.util";
import React from "react";
import { useUserProfile } from "../../../../hooks/useUserProfile";
import { useReadCalendarColors } from "../../../../web-app-calendar/hooks/CalendarColorsContext";
import { GQLOthersConsequenceDiffSummary, GQLYourConsequenceDiffSummary } from "../../utils/types";
import { AssuranceProps } from "./Assurances";
import { DiffEventCardCollapsed } from "./DiffEventCardCollapsed";

type Diff = GQLYourConsequenceDiffSummary | GQLOthersConsequenceDiffSummary;

export const ConsequencesDiffs = ({ diffs }: { diffs: Diff[] }) => {
  const calendarColorMap = useReadCalendarColors();
  const { userProfile: currentUserProfile, loading: userProfileLoading } = useUserProfile();
  // Since we're inside of a `ConsequencesDiffs`, `isSBM` must be `true`
  // (because any proposal with consequences is a SBM proposal)
  const isSBM = true;

  return (
    <div className="cw-flex-col cw-mb-2 cw-flex cw-gap-2">
      {diffs.map((diff: Diff) => {
        const { title, id, isFlexible } = diff;

        let profile: null | Profile = null;
        let borderColor = "";
        switch (diff.__typename) {
          case "OthersConsequenceDiffSummary":
            // DevNote: future imporvements will allow for mulitple affected attendes to be displayed; for now we just show the first one
            if (diff.affectedAttendees.length > 0) {
              borderColor = calendarColorMap[diff.affectedAttendees[0].calendarId]?.border || "";
              profile = {
                givenName: diff.affectedAttendees[0].person?.profile?.givenName || null,
                familyName: diff.affectedAttendees[0].person?.profile?.familyName || null,
                externalImageUrl:
                  diff.affectedAttendees[0].person?.profile?.externalImageUrl || null,
              };
            }
            break;
          case "YourConsequenceDiffSummary":
            if (!userProfileLoading) {
              borderColor = getOwnCalendarColor().border;
              profile = currentUserProfile;
            }
            break;
        }

        const Avatar = (
          <AttendeeAvatar profile={profile || undefined} size="large" borderColor={borderColor} />
        );

        // TODO: this may need to allow assurances for low viz: AI-3546
        const assurances: AssuranceProps | null = isFlexible
          ? {
              clockwiseUsersFirstNames: [],
              flexible: isFlexible,
            }
          : null;

        return (
          <DiffEventCardCollapsed
            action={diff.action}
            ambiguities={[]}
            assurances={assurances}
            avatar={Avatar}
            id={id}
            isSBM={isSBM}
            key={`diff-${id}`}
            title={title}
            isConsequenceBlock
          />
        );
      })}
    </div>
  );
};
