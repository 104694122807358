import {
  PageLayout,
  PageLayoutContent,
} from "@clockwise/web-commons/src/ui/page-layout/PageLayout";
import { useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import SchedulingLinkDashboard from "../components/scheduling-link-dashboard/SchedulingLinkDashboard";
import { EnsureLinkName } from "../components/scheduling-link/EnsureLinkName";
import { useUnvisitedPageFlags } from "../hooks/useUnvisitedPageFlags";
import { PageEvents, TrackingEvents, page } from "../util/analytics.util";

const AppSettingsPage = () => {
  const track = useTracking();
  const {
    setHasVisitedLinksPage,
    shouldShowUnvisitedBadges,
    hasVisitedLinksPage,
  } = useUnvisitedPageFlags();

  useEffect(() => {
    page(PageEvents.CONNECTED_APPS_SETTINGS);
  }, []);

  useEffect(() => {
    if (!hasVisitedLinksPage && shouldShowUnvisitedBadges) {
      setHasVisitedLinksPage();
      track(TrackingEvents.NUX_2025.UNVISITED_LINKS.LINKS_VISITED);
    }
  }, [hasVisitedLinksPage, shouldShowUnvisitedBadges]);

  return (
    <>
      <Helmet>
        <title>Scheduling links · Clockwise</title>
      </Helmet>
      <PageLayout title="Scheduling links">
        <PageLayoutContent>
          <EnsureLinkName>
            <SchedulingLinkDashboard />
          </EnsureLinkName>
        </PageLayoutContent>
      </PageLayout>
    </>
  );
};

export default AppSettingsPage;
