import { Checkbox } from "@clockwise/design-system";
import { CliOptionType } from "@clockwise/schema";
import React from "react";

export interface IProps {
  name: string;
  description: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  children?: React.ReactNode;
  type: CliOptionType;
}

export const CliOption = ({
  name,
  description,
  checked,
  onChange,
  disabled = false,
  children,
  type,
}: IProps) => {
  return (
    <div className="cw-flex cw-flex-col cw-items-start cw-p-2 cw-space-y-1">
      {type === CliOptionType.Boolean ? (
        <>
          <Checkbox
            checked={checked}
            onChange={onChange}
            size="small"
            disabled={disabled}
            label={
              <>
                <code className="cw-font-mono">{name}</code>
                <div className="cw-body-base cw-text-slate-standard">{description}</div>
              </>
            }
          />
          {/* Margin aligns children with label */}
          <div className="cw-ml-9">{children}</div>
        </>
      ) : (
        <div className="cw-ml-9">
          <code className="cw-font-mono">{name}</code>
          <div className="cw-body-base cw-text-slate-standard">{description}</div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};
