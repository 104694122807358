import { fg_onEmphasis } from "@clockwise/design-system/tokens";
import {
  CheckCircleFilled,
  ClockwiseIntelligenceFilled,
  EmergencyHomeFilled,
} from "@clockwise/icons";
import { ProposalState } from "@clockwise/schema/v2";
import {
  CalendarDensity,
  CalendarPositioner,
} from "@clockwise/web-commons/src/components/calendar";
import { ICalPositionable } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import classNames from "classnames";
import { round } from "lodash";
import { DateTime, Interval } from "luxon";
import React, { useId, useMemo, useRef } from "react";
import { useReadLocalStorage, useUnmount } from "usehooks-ts";
import {
  useCurrentProposal,
  useUpdateCurrentProposal,
} from "../../chat-plus-calendar/CurrentProposalContext";
import {
  SuggestedTimeOption,
  usePersistedProposal,
} from "../../chat-plus-calendar/PersistedProposalContext";
import {
  useReadProposalOptionsOverlayV2,
  useUpdateProposalOptionsOverlayV2,
} from "../../chat-plus-calendar/util/ProposalOptionsOverlayContextV2";
import { TradeoffsPopover } from "../../tradeoffs-popover-v2";
import { MIN_DURATION } from "../planner-event/PlannerEvents";
import { COLORS } from "../proposal-option-card/types";
import { ProposalOptionSpacing } from "../proposal-options-overlay/ProposalOption";
import { ProposalOptionCardVariant } from "./types";
import { calcCardVariantFromTradeoffBlocks, getSpacing } from "./utils";

interface DrilledHookDependencies {
  track: ReturnType<typeof useTracking>;
  setHoveredSuggestion: ReturnType<
    typeof useUpdateProposalOptionsOverlayV2
  >["setHoveredSuggestion"];
  hoveredSuggestion: ReturnType<typeof useReadProposalOptionsOverlayV2>["hoveredSuggestion"];
  suggestedTimeOptions: ReturnType<typeof usePersistedProposal>["suggestedTimeOptions"];
  updateTime: ReturnType<typeof useUpdateCurrentProposal>["updateTime"];
  currentProposal: ReturnType<typeof useCurrentProposal>["currentProposal"];
}

/**
 * The ProposalOptionsOverlay shows recommended times for an event that's
 * currently being created or edited on the calendar.
 * The overlay is made up of one or more ProposalOptionGroupRailCards, which
 * collect a group of recommendations that all are adjacent to each other.
 */
export const ProposalOptionsOverlayV2 = ({
  dateTimes,
  popoverPosition,
  isDayView = false,
  onClick,
}: {
  dateTimes: DateTime[];
  popoverPosition?: "left" | "right" | "bottom";
  isDayView?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  const track = useTracking();
  const { setHoveredSuggestion } = useUpdateProposalOptionsOverlayV2();
  const { hoveredSuggestion } = useReadProposalOptionsOverlayV2();
  const { suggestedTimeOptions = [], status } = usePersistedProposal();
  const { updateTime } = useUpdateCurrentProposal();
  const { currentProposal } = useCurrentProposal();
  const calendarDensity = useReadLocalStorage<CalendarDensity>("calendarDensity");

  if (status !== ProposalState.LoadingSuggestions && suggestedTimeOptions.length > 0) {
    return (
      <ProposalOptionsOverlayV2Render
        dateTimes={dateTimes}
        popoverPosition={popoverPosition}
        isDayView={isDayView}
        onClick={onClick}
        suggestedTimeOptions={suggestedTimeOptions}
        calendarDensity={calendarDensity}
        track={track}
        setHoveredSuggestion={setHoveredSuggestion}
        hoveredSuggestion={hoveredSuggestion}
        updateTime={updateTime}
        currentProposal={currentProposal}
      />
    );
  }

  return null;
};

/**
 * This component is broken apart from ProposalOptionsOverlayV2 in order to isolate the
 * rendering from the data loading. If you're not Storybook, you shouldn't use
 * this directly.
 */
export const ProposalOptionsOverlayV2Render = ({
  dateTimes,
  popoverPosition,
  isDayView = false,
  onClick,
  calendarDensity,
  track,
  setHoveredSuggestion,
  hoveredSuggestion,
  suggestedTimeOptions = [],
  updateTime,
  currentProposal,
}: {
  dateTimes: DateTime[];
  popoverPosition?: "left" | "right" | "bottom";
  isDayView?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  calendarDensity: CalendarDensity | null;
} & DrilledHookDependencies) => {
  return (
    <div className="cw-w-full cw-h-full" cw-id="proposal-options-overlay" onClick={onClick}>
      <div className="cw-absolute cw-top-0 cw-right-0 cw-bottom-0 cw-left-0 cw-pointer-events-none" />
      <PositionedCardGroups
        dateTimes={dateTimes}
        popoverPosition={popoverPosition}
        isDayView={isDayView}
        calendarDensity={calendarDensity}
        track={track}
        setHoveredSuggestion={setHoveredSuggestion}
        hoveredSuggestion={hoveredSuggestion}
        suggestedTimeOptions={suggestedTimeOptions}
        updateTime={updateTime}
        currentProposal={currentProposal}
      />
    </div>
  );
};

/**
 * Calculates the different groups that should be rendered based on the provided
 * time options, then renders one or more RailCardGroups positioned on the calendar.
 */
const PositionedCardGroups = ({
  dateTimes,
  popoverPosition,
  isDayView,
  calendarDensity,
  track,
  setHoveredSuggestion,
  hoveredSuggestion,
  suggestedTimeOptions = [],
  updateTime,
  currentProposal,
}: {
  dateTimes: DateTime[];
  popoverPosition?: "left" | "right" | "bottom";
  isDayView: boolean;
  calendarDensity: CalendarDensity | null;
} & DrilledHookDependencies) => {
  const groups = useMemo(
    () =>
      suggestedTimeOptions
        .sort((a, b) => a.interval.start.toMillis() - b.interval.start.toMillis())
        .reduce<
          {
            variant: ProposalOptionCardVariant;
            options: SuggestedTimeOption[];
          }[]
        >((prev, curr) => {
          const lastGroup = prev.at(-1);
          const lastItem = lastGroup?.options.at(-1);
          const currVariant = calcCardVariantFromTradeoffBlocks(curr.tradeoffBlocks);
          if (
            lastGroup &&
            lastItem &&
            (lastItem.interval.overlaps(curr.interval) ||
              lastItem.interval.abutsStart(curr.interval)) &&
            // TODO: stopgap - we need a better way to handle the overflowing day case
            lastItem.interval.start.hasSame(curr.interval.end, "day") &&
            currVariant === lastGroup.variant
          ) {
            lastGroup.options.push(curr);
          } else {
            prev.push({
              variant: currVariant,
              options: [curr],
            });
          }
          return prev;
        }, []),
    [suggestedTimeOptions],
  );

  const positionables: ICalPositionable[] = useMemo(
    () =>
      groups.map((group, idx) => {
        // Check the next group ahead of us and see if they would overlap.
        // If so, trim this interval so they don't.
        let interval = Interval.fromDateTimes(
          group.options.at(0)!.interval.start,
          group.options.at(-1)!.interval.end,
        );
        if (idx < groups.length - 1) {
          const nextInterval = Interval.fromDateTimes(
            groups[idx + 1].options.at(0)!.interval.start,
            groups[idx + 1].options.at(-1)!.interval.end,
          );
          if (interval.overlaps(nextInterval)) {
            interval = Interval.fromDateTimes(
              group.options.at(0)!.interval.start,
              groups[idx + 1].options.at(0)!.interval.start,
            );
          }
        }

        return {
          interval: interval,
          key: interval.toISO(),
          rail: !isDayView,
          render: ({ position }) => (
            <ProposalOptionGroupRailCard
              interval={interval}
              variant={group.variant}
              options={group.options}
              popoverSide={
                popoverPosition ? popoverPosition : position.left < 60 ? "right" : "left"
              } // Open on the left for Thursday and Friday, because of space constraints
              calendarDensity={calendarDensity}
              track={track}
              setHoveredSuggestion={setHoveredSuggestion}
              hoveredSuggestion={hoveredSuggestion}
              suggestedTimeOptions={suggestedTimeOptions}
              updateTime={updateTime}
              currentProposal={currentProposal}
            />
          ),
        };
      }),
    [
      groups,
      calendarDensity,
      currentProposal,
      hoveredSuggestion,
      isDayView,
      popoverPosition,
      setHoveredSuggestion,
      suggestedTimeOptions,
      track,
      updateTime,
    ],
  );

  const nonInteractivePositionables = [];
  if (currentProposal.startTime && currentProposal.endTime) {
    const selectedInterval = Interval.fromDateTimes(
      currentProposal.startTime,
      currentProposal.endTime,
    );
    const matchingSlice = suggestedTimeOptions.find((a) => a.interval.equals(selectedInterval));
    if (matchingSlice) {
      nonInteractivePositionables.push({
        interval: selectedInterval,
        key: `${selectedInterval?.toISO()}-selected`,
        rail: !isDayView,
        render: () => (
          <ProposalOptionFloatingSliceIndicator
            interval={selectedInterval}
            type="selected"
            variant={calcCardVariantFromTradeoffBlocks(matchingSlice.tradeoffBlocks ?? [])}
            calendarDensity={calendarDensity}
          />
        ),
      });
    }
  }
  if (hoveredSuggestion) {
    const matchingSlice = suggestedTimeOptions.find(
      (a) => a.interval.toISO() === hoveredSuggestion,
    );
    if (matchingSlice) {
      nonInteractivePositionables.push({
        interval: matchingSlice.interval,
        key: `${matchingSlice.interval.toISO()}-hovered`,
        rail: !isDayView,
        render: () => (
          <ProposalOptionFloatingSliceIndicator
            interval={matchingSlice.interval}
            type="hovered"
            variant={calcCardVariantFromTradeoffBlocks(matchingSlice.tradeoffBlocks ?? [])}
            calendarDensity={calendarDensity}
          />
        ),
      });
    }
  }

  return (
    <>
      <CalendarPositioner
        positionables={positionables}
        dateTimes={dateTimes}
        fullWidth={true}
        conflictResolution="none"
        minDuration={MIN_DURATION}
      />
      <CalendarPositioner
        positionables={nonInteractivePositionables}
        dateTimes={dateTimes}
        fullWidth={true}
        conflictResolution="none"
        minDuration={MIN_DURATION}
        nonInteractive
      />
    </>
  );
};

/**
 * A rail card for a given group of proposal options. Each group is made up of
 * one or more adjacent options, which are individually rendered as
 * RailCardSlices.
 */
const ProposalOptionGroupRailCard = ({
  interval,
  variant,
  options,
  popoverSide,
  renderPopover = true,
  calendarDensity,
  track,
  setHoveredSuggestion,
  hoveredSuggestion,
  suggestedTimeOptions,
  updateTime,
  currentProposal,
}: {
  interval: Interval;
  variant: ProposalOptionCardVariant;
  options: SuggestedTimeOption[];
  popoverSide: "left" | "right" | "bottom";
  calendarDensity: CalendarDensity | null;
  renderPopover?: boolean;
} & DrilledHookDependencies) => {
  const totalGroupSlices = Math.max(interval.count("minutes"), MIN_DURATION.as("minutes")) / 15;

  const colorClass = COLORS[variant];
  const borderColorClass = COLORS[variant];
  const spacing = getSpacing(interval, calendarDensity);

  return (
    <div
      cw-id="proposal-option-group-rail-card"
      className={classNames(
        "cw-relative cw-h-full cw-w-full cw-border-solid cw-border cw-border-transparent cw-drop-shadow cw-p-0",
      )}
    >
      <div
        style={{
          borderColor: borderColorClass,
          color: colorClass,
        }}
        className={classNames(
          "cw-h-full cw-w-full cw-border cw-border-solid cw-rounded cw-cursor-pointer cw-flex cw-justify-center cw-items-start cw-bg-default",
          {
            "cw-pt-1": spacing === "full",
          },
        )}
      >
        <RailCardIcon variant={variant} colorClass={colorClass} spacing={spacing} />
      </div>
      {options.map((curr, idx) => {
        return (
          <ProposalOptionRailCardSlice
            key={curr.interval.toISO()}
            option={curr}
            variant={variant}
            groupSlices={totalGroupSlices}
            durationSlices={Math.max(curr.interval.count("minutes") / 15, 1)}
            startSlice={curr.interval.start.diff(interval.start, "minutes").as("minutes") / 15}
            isLastOption={idx === options.length - 1}
            popoverSide={popoverSide}
            renderPopover={renderPopover}
            calendarDensity={calendarDensity}
            track={track}
            setHoveredSuggestion={setHoveredSuggestion}
            hoveredSuggestion={hoveredSuggestion}
            suggestedTimeOptions={suggestedTimeOptions}
            updateTime={updateTime}
            currentProposal={currentProposal}
          />
        );
      })}
    </div>
  );
};

/**
 * An individual 15m slice in a rail card group. The button that the user actually
 * interacts with is invisible... the visible part that shows when the given slice
 * is selected will span the full selection range.
 */
const ProposalOptionRailCardSlice = ({
  option,
  groupSlices,
  durationSlices,
  startSlice,
  isLastOption,
  popoverSide,
  renderPopover,
  track,
  setHoveredSuggestion,
  hoveredSuggestion,
  updateTime,
}: {
  option: SuggestedTimeOption;
  variant: ProposalOptionCardVariant;
  groupSlices: number;
  durationSlices: number;
  startSlice: number;
  isLastOption: boolean;
  popoverSide: "left" | "right" | "bottom";
  calendarDensity: CalendarDensity | null;
  renderPopover?: boolean;
} & DrilledHookDependencies) => {
  const popoverUid = useId();
  const popoverAnchorRef = useRef<HTMLButtonElement | null>(null);

  const hovered = hoveredSuggestion === option.interval.toISO();

  const onMouseEnter = () => {
    setHoveredSuggestion(option.interval.toISO());
  };

  const onMouseLeave = () => {
    setHoveredSuggestion(null);
  };

  useUnmount(() => {
    // Remove the time suggestion overlay when the component unmounts.
    setHoveredSuggestion(null);
  });

  const onClickOption = () => {
    updateTime(option.interval.start, option.interval.end);
    setHoveredSuggestion(null);
    track(TrackingEvents.PROPOSAL_OPTIONS_OVERLAY.OPTION_CLICKED);
  };

  return (
    <>
      <button
        className={classNames(
          "cw-absolute cw-opacity-0 cw-w-full cw-left-0 cw-box-border cw-border cw-border-solid cw-rounded cw-cursor-pointer",
        )}
        style={{
          top: `${round(startSlice * (100 / groupSlices), 5)}%`,
          height: `${round((isLastOption ? durationSlices : 1.001) * (100 / groupSlices), 5)}%`,
        }}
        aria-describedby={hovered ? popoverUid : undefined}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClickOption}
        ref={popoverAnchorRef}
      >
        <span className="cw-sr-only">{option.label}</span>
      </button>
      {hovered && renderPopover && (
        <TradeoffsPopover
          id={popoverUid}
          tradeoffBlocks={option.tradeoffBlocks}
          side={popoverSide}
          anchorEl={popoverAnchorRef}
          headerText={option.label}
          showAttendeeCount
        />
      )}
    </>
  );
};

const ProposalOptionFloatingSliceIndicator = ({
  interval,
  type,
  variant,
  calendarDensity,
}: {
  interval: Interval;
  type: "selected" | "hovered";
  variant: ProposalOptionCardVariant;
  calendarDensity: CalendarDensity | null;
}) => {
  const spacing = getSpacing(interval, calendarDensity);

  return (
    <div
      cw-id="proposal-option-floating-slice-indicator"
      className={classNames(
        "cw-absolute cw-w-full cw-h-full cw-border cw-border-solid cw-rounded cw-flex cw-justify-center cw-items-start cw-pointer-events-none",
        {
          "cw-bg-busy-emphasis cw-border-busy cw-z-10": type === "selected",
          "cw-bg-neutral-emphasis cw-border-neutral cw-z-20": type === "hovered",
        },
        {
          "cw-pt-1": spacing === "full",
        },
      )}
      role="presentation"
    >
      <RailCardIcon variant={variant} colorClass={fg_onEmphasis} spacing={spacing} />
    </div>
  );
};

/**
 * The icon that's shown in the rail card.
 */
const RailCardIcon = ({
  variant,
  colorClass,
  spacing,
}: {
  variant: ProposalOptionCardVariant;
  colorClass: string;
  spacing: ProposalOptionSpacing;
}) => {
  let sizeClass: string;
  switch (spacing) {
    case "full":
      sizeClass = "lg:cw-w-4 lg:cw-h-4 cw-w-3 cw-h-3";
      break;
    case "none":
      sizeClass = "cw-w-2.5 cw-h-2.5";
      break;
    case "small":
      sizeClass = "lg:cw-w-3 lg:cw-h-3 cw-w-2.5 cw-h-2.5";
      break;
  }

  switch (variant) {
    case "no-conflict":
      return (
        <CheckCircleFilled
          style={{ fill: colorClass }}
          className={classNames("cw-transition-none", sizeClass)}
        />
      );
    case "sbm":
      return (
        <ClockwiseIntelligenceFilled
          style={{ fill: colorClass }}
          className={classNames("cw-transition-none", sizeClass)}
        />
      );
    case "conflict":
      return (
        <EmergencyHomeFilled
          style={{ fill: colorClass }}
          className={classNames("cw-transition-none", sizeClass)}
        />
      );
  }
};
