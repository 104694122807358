import { ApolloError } from "@apollo/client";
import {
  useReadTimeSuggestion,
  useUpdateTimeSuggestion,
} from "@clockwise/web-commons/src/util/TimeSuggestionContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSetProposalError } from "./hooks/ProposalErrorContext";
import { useSharedProposalContext } from "./hooks/SharedProposalProvider";
import { useSelectSharedSchedulingOption } from "./hooks/useSelectSharedSchedulingOption";

export const SharedProposalTimeSuggestionSync = () => {
  const track = useTracking();
  const setError = useSetProposalError();
  const dispatch = useDispatch();

  // set when the user clicks on a time suggestion in the chat or calendar
  const { index: userSelectedIndex } = useReadTimeSuggestion();
  const { proposal, errorMessage } = useSharedProposalContext();
  const proposalId = proposal?.proposalId ?? null;

  if (errorMessage) {
    setError({
      error: new Error("Failed to select time"),
      message: "Failed to select time",
      proposalId: proposalId,
    });
  }

  const proposalSelectedIndex =
    proposal?.options.__typename === "SchedulingOptions"
      ? proposal.options.selectedIndex
      : undefined;

  const { setTimeSuggestionLoading, setTimeSuggestionIndex } = useUpdateTimeSuggestion();

  const resetState = useCallback(() => {
    setTimeSuggestionLoading(false);
    // set userSelectedTimeIndex to null to prevent the effect from running again
    setTimeSuggestionIndex(null);
    // prevent useEffect immediately (even before `setTimeSuggestionIndex` resolves)
    justCalledMutationAndStillWaitingForResponseRef.current = false;
  }, [setTimeSuggestionLoading, setTimeSuggestionIndex]);

  const justCalledMutationAndStillWaitingForResponseRef = useRef(false);

  const onError = useCallback(
    (error: ApolloError) => {
      resetState();

      setError({
        error,
        message: "Failed to select time",
        proposalId: proposalId,
      });
    },
    [setError, resetState, proposalId],
  );

  const onCompleted = useCallback(() => {
    resetState();
  }, [resetState]);

  const [onSelectSharedSchedulingOption] = useSelectSharedSchedulingOption({
    onError,
    onCompleted,
    proposal,
  });

  useEffect(() => {
    if (
      userSelectedIndex === null ||
      userSelectedIndex === proposalSelectedIndex ||
      justCalledMutationAndStillWaitingForResponseRef.current
    ) {
      return;
    }

    void onSelectSharedSchedulingOption(userSelectedIndex);

    justCalledMutationAndStillWaitingForResponseRef.current = true;
    track(TrackingEvents.SHAREABLE_PROPOSAL.VIEW.SELECT_OPTION_CLICKED);
  }, [
    userSelectedIndex,
    proposalSelectedIndex,
    dispatch,
    proposal?.options,
    onSelectSharedSchedulingOption,
    track,
  ]);

  return null;
};
