import { VisibilityEnum } from "@clockwise/schema/v2";
import { EventVisibility } from "../atoms/SelectEventVisibility";

export const toECVisibility = (visibility: VisibilityEnum | null): EventVisibility => {
  switch (visibility) {
    case VisibilityEnum.Private:
      return "private";
    case VisibilityEnum.Public:
      return "public";
    case VisibilityEnum.Default:
    default:
      return "default";
  }
};
