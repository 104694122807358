import { Button } from "@clockwise/design-system";
import { ExpandLess, ExpandMore } from "@clockwise/design-system/icons";
import { CalendarEvent } from "@clockwise/web-commons/src/components/calendar/calendar-event/CalendarEvent";
import { GUTTER_WIDTH_PERCENT } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/constants";
import {
  EventType,
  useReadActiveEvent,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { track, TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { useDraggable } from "@dnd-kit/core";
import classNames from "classnames";
import { compact, includes, isNil, map, max, min, noop, slice, some, uniqBy } from "lodash";
import { DateTime } from "luxon";
import React, { createContext, useContext, useEffect, useRef } from "react";
import { useReadLocalStorage, useToggle } from "usehooks-ts";
import { useFeatureFlag } from "../../../launch-darkly";
import {
  useCurrentProposal,
  useUpdateCurrentProposal,
} from "../../chat-plus-calendar/CurrentProposalContext";
import { useUpdateActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import { useAIMessageContext } from "../../chat/ai-chat/hooks/AIMessageContext";
import { usePlannerContext } from "../Context";
import { useReadCalendarColorForCalendarId } from "../hooks/CalendarColorsContext";
import { usePrefetchEventDetails } from "../hooks/useGatewayEventDetails";
import usePlannerMetaData from "../hooks/usePlannerMetaData";
import { plannerToolbarSettingKeys } from "../planner-toolbar/PlannerToolbar";
import { PlannerEventCard } from "../types";
import { getCalendarEventStatus } from "../util/getCalendarEventStatus";
import { hasCategoryOutOfOffice } from "../util/hasCategoryOutOfOffice";
import { Cell, ElementColumn, positionObjectsInDates } from "./utils/PositionObjectsInDates";

const MAX_ALL_DAY_EVENT_COUNT = 3;

const isProposalEvent = (event: PlannerEventCard) => !isNil(event.proposalType);

type Props = {
  dateTimes: DateTime[];
  events?: PlannerEventCard[];
  onClick: (calendarId: string, externalEventId: string, type: EventType) => void;
};

type State = {
  dateToColumnMap: Map<string, ElementColumn<PlannerEventCard>>;
  isExpanded: boolean;
  objectToCellMap: Map<PlannerEventCard, Cell>;
  onClick: (calendarId: string, externalEventId: string, type: EventType) => void;
  toggleIsExpanded: () => void;
};

const defaultState = {
  dateToColumnMap: new Map(),
  isExpanded: false,
  objectToCellMap: new Map(),
  onClick: noop,
  toggleIsExpanded: noop,
};

const AllDayEventsContext = createContext<State>(defaultState);

export const AllDayEvents = ({ dateTimes, events = [], onClick }: Props) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const uniqueEvents = uniqBy(
    events,
    ({ externalEventId = "", text }) => `${externalEventId}-${text}`,
  );

  const { dateToColumnMap, objectToCellMap } = positionObjectsInDates(
    dateTimes,
    uniqueEvents,
    isProposalEvent,
  );

  return (
    <AllDayEventsContext.Provider
      value={{ isExpanded, toggleIsExpanded, dateToColumnMap, objectToCellMap, onClick }}
    >
      <div className={classNames("cw-flex cw-flex-row cw-flex-1 cw-flex-grow-0")}>
        <ExpandToggle />
        <div
          className={classNames(
            "cw-max-h-[227px] cw-w-full cw-pb-[2px] [scrollbar-gutter:stable]",
            isExpanded ? "cw-overflow-y-scroll" : "cw-overflow-y-hidden",
          )}
        >
          <div className="cw-relative cw-w-full">
            <AllDayColumns />
            <AllDayCards />
          </div>
        </div>
      </div>
    </AllDayEventsContext.Provider>
  );
};

const ExpandToggle = () => {
  const { isExpanded, toggleIsExpanded, objectToCellMap } = useContext(AllDayEventsContext);

  const expandable = some(
    [...objectToCellMap.values()],
    (cell) => cell.column + 1 > MAX_ALL_DAY_EVENT_COUNT,
  );

  return (
    <div className="cw-w-[40px] cw-max-h-[227px] cw-relative">
      {expandable ? (
        <div className="-cw-ml-2 -cw-mt-[7px] cw-absolute cw-bottom-1">
          <Button
            onClick={toggleIsExpanded}
            variant="text"
            size="small"
            aria-label="Show all all-day events"
          >
            <span>{isExpanded ? <ExpandLess /> : <ExpandMore />}</span>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const MoreEvents = ({ onClick, moreCount }: { onClick: () => void; moreCount: number }) => {
  const { dateToColumnMap } = useContext(AllDayEventsContext);

  // Hack to show a gutter based on the number of days shown
  const showingWeekends = dateToColumnMap.size === 7;
  const showingWeekdays = dateToColumnMap.size === 5;
  const widthPercent = showingWeekends ? 82 : showingWeekdays ? 87 : 100;

  return (
    <div className="cw-pt-1" style={{ width: `${widthPercent}%` }}>
      <Button variant="text" size="mini" fullWidth onClick={onClick}>
        {`${moreCount} more`}
      </Button>
    </div>
  );
};

const AllDayColumns = () => {
  const { dateToColumnMap } = useContext(AllDayEventsContext);

  return (
    <div className="cw-flex cw-flex-row cw-flex-1 cw-overflow-auto">
      {map([...dateToColumnMap], ([date, elementColumn]) => (
        <AllDayColumn key={date} date={date} column={elementColumn} />
      ))}
    </div>
  );
};

const AllDayColumn = ({
  date,
  column,
}: {
  date: string;
  column: ElementColumn<PlannerEventCard>;
}) => {
  const [isOnClickToCreate] = useFeatureFlag("ClickToCreate");
  const { isExpanded, toggleIsExpanded } = useContext(AllDayEventsContext);
  const { currentProposal } = useCurrentProposal();
  const { initOrRescheduleProposal, updateProposalMeta, updateTime } = useUpdateCurrentProposal();
  const {
    primaryCalendarId,
    multiCalendarIds,
    popoverClosing,
    setIsDraggingEvent,
  } = usePlannerContext();
  const { containsActiveProposal: chatContainsActiveProposal } = useAIMessageContext();
  const activeEvent = useReadActiveEvent();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateActiveEventDiff = useUpdateActiveEventDiff();
  const { listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: `all-day-column-${date}`,
    data: {
      isHorizontalClickDragToCreate: true,
    },
  });

  const { dateToColumnMap } = useContext(AllDayEventsContext);
  const earliestVisibleDate = min([...dateToColumnMap.keys()]);

  const sectionRef = useRef<HTMLElement | null>(null);
  const chosenTimeRef = useRef<DateTime>();
  const hasInitializedClickDragToCreateState = useRef<boolean>(false);
  const isPreviousDragging = useRef<boolean>(false);

  useEffect(() => {
    const initialClickDate = chosenTimeRef.current;

    if (!initialClickDate || !isDragging) return;

    // If there is an active event, e.g. the sidebar is open with an existing event, we don't want to create a new event.
    if (activeEvent && activeEvent.type !== EventType.Proposal) {
      return;
    }

    // If there is an active proposal in the chat, we don't want to create a new event.
    if (chatContainsActiveProposal) {
      return;
    }

    // If the popover is closing, we don't want to create a new event.
    if (popoverClosing) {
      return;
    }

    if (!hasInitializedClickDragToCreateState.current && !currentProposal.initialized) {
      setIsDraggingEvent?.(true);
      initOrRescheduleProposal({
        proposalId: null,
        title: defaultTitle,
        eventCalendarId: primaryCalendarId,
        description: "",
        startTime: initialClickDate.startOf("day"),
        endTime: initialClickDate.plus({ days: 1 }).startOf("day"),
        timeZone: getRenderTimeZone(),
        initialAttendeeIds: attendees,
        attendees: [],
        allDay: true,
        recurrenceRule: null,
        location: null,
        conferenceType: null,
        flexDetails: {
          isFlexible: false,
        },
        meta: {
          isDragging: true,
          isFindATime: false,
        },
        transparency: "opaque",
        visibility: "default",
      });
      hasInitializedClickDragToCreateState.current = true;
    } else {
      const sectionWidth = sectionRef.current?.offsetWidth ?? 0;
      const numDaysDragged = Math.ceil((transform?.x ?? 0) / sectionWidth);

      let startTime, endTime: DateTime;

      // If the user is dragging to the right, or is still within the same day
      // we set the start and end time to reflect the current day
      if (numDaysDragged > 0 || Math.abs(numDaysDragged) === 0) {
        startTime = initialClickDate.startOf("day");
        endTime = initialClickDate.plus({ days: Math.abs(numDaysDragged) + 1 }).startOf("day");
      } else {
        // If the user is dragging to the left, we set the end time to the day they started dragging from
        // and the start time to the day(s) before that
        startTime = initialClickDate.minus({ days: Math.abs(numDaysDragged) }).startOf("day");
        endTime = initialClickDate.plus({ days: 1 }).startOf("day");
      }

      // If the user is dragging to a date that is before the earliest visible date, do nothing
      if (earliestVisibleDate && startTime < DateTime.fromISO(earliestVisibleDate)) {
        return;
      }

      updateTime(startTime, endTime);
      updateProposalMeta({ isDragging: true });
    }
  }, [transform?.x, isDragging]);

  // Detect when dragging has stopped
  useEffect(() => {
    const isOnDragEnd = isPreviousDragging.current && !isDragging;
    isPreviousDragging.current = isDragging;
    if (!isOnDragEnd) return;

    // If a proposal hasn't been initialized, we don't want to create a new event.
    // Without this, click-drag events on the time rail will trigger downstream logic and bugs
    if (currentProposal.initialized === false) {
      return;
    }

    track(TrackingEvents.DIRECT_MANIPULATION.CALENDAR.CLICK_DRAG_TO_CREATE);
    updateActiveEvent({ calendarId: "", externalEventId: "", type: EventType.Proposal });
    updateProposalMeta({ isDragging: false });
    setIsDraggingEvent?.(false);
  }, [isDragging]);

  const dateTime = DateTime.fromISO(date);
  const hasMoreInColumn = column.lastPosition + 1 > MAX_ALL_DAY_EVENT_COUNT;
  const showAllCells = isExpanded || !hasMoreInColumn;

  // get the correct number of cells to display;
  // if not expanded make sure to grab minimum number of cells to correctly place the expand button
  // see "Multiple Multi Day Events" storybook
  const displayCells = showAllCells
    ? slice(column.elements, 0, max([column.lastPosition + 1, MAX_ALL_DAY_EVENT_COUNT - 1]))
    : slice(column.elements, 0, MAX_ALL_DAY_EVENT_COUNT - 1);

  const nonEmptyDisplayCells = displayCells.filter((cell) => !!cell);

  const moreCount = column.elementCount - nonEmptyDisplayCells.length;

  const attendees = compact([primaryCalendarId, ...(multiCalendarIds ?? [])]);

  const defaultTitle = attendees.length > 1 ? "Sync" : "Hold";

  const initializeClickDragToCreate = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    // If the user is dragging, we don't want to reset the clicked date
    if (isDragging) {
      return;
    }

    // If there is an active event, e.g. the sidebar is open with an existing event, we don't want to create a new event.
    if (activeEvent && activeEvent.type !== EventType.Proposal) {
      return;
    }

    // If there is an active proposal in the chat, we don't want to create a new event.
    if (chatContainsActiveProposal) {
      return;
    }

    // If the popover is closing, we don't want to create a new event.
    if (popoverClosing) {
      return;
    }

    chosenTimeRef.current = dateTime;
    hasInitializedClickDragToCreateState.current = false;
  };

  const onCreateProposal = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    // If there is an active event, e.g. the sidebar is open with an existing event, we don't want to create a new event.
    if (activeEvent && activeEvent.type !== EventType.Proposal) {
      return;
    }

    // If there is an active proposal in the chat, we don't want to create a new event.
    if (chatContainsActiveProposal) {
      return;
    }

    // If the popover is closing, we don't want to create a new event.
    if (popoverClosing) {
      return;
    }

    updateActiveEventDiff(null);
    updateActiveEvent({ calendarId: "", externalEventId: "", type: EventType.Proposal });
    initOrRescheduleProposal({
      proposalId: null,
      title: defaultTitle,
      eventCalendarId: primaryCalendarId,
      description: "",
      startTime: dateTime.startOf("day"),
      endTime: dateTime.plus({ days: 1 }).startOf("day"),
      timeZone: getRenderTimeZone(),
      initialAttendeeIds: attendees,
      attendees: [],
      allDay: true,
      recurrenceRule: null,
      location: null,
      conferenceType: null,
      flexDetails: {
        isFlexible: false,
      },
      meta: {
        isDragging: false,
        isFindATime: false,
      },
      transparency: "opaque",
      visibility: "default",
    });
  };

  return (
    <div className="cw-flex-1 cw-min-w-0">
      <section
        className={classNames("cw-w-full cw-h-full")}
        aria-label={`All-day events on ${dateTime.toLocaleString(DateTime.DATETIME_FULL)}`}
        onClick={isOnClickToCreate ? onCreateProposal : undefined}
        onMouseDown={initializeClickDragToCreate}
        ref={(el) => {
          sectionRef.current = el;
          setNodeRef(el);
        }}
        {...listeners}
      >
        {map(displayCells, (event, index) => {
          const key = event
            ? `${date}-${event?.key || "nokey"}`
            : `${date}-for-placement-only-${index}`;

          return <AllDayCell key={key} event={event || null} />;
        })}
        {!isExpanded && moreCount > 0 && (
          <MoreEvents onClick={toggleIsExpanded} moreCount={moreCount} />
        )}
      </section>
    </div>
  );
};

const AllDayCell = ({ event }: { event: PlannerEventCard | null }) =>
  event ? <AllDayCellTabable event={event} /> : <AllDayCellEmpty />;

const AllDayCellTabable = ({ event: { text, interval } }: { event: PlannerEventCard }) => (
  <div
    role="button"
    tabIndex={0}
    className="cw-text-muted cw-h-[26px] cw-pointer-events-none"
    aria-label={`${text} - ${interval.toLocaleString()}`}
  ></div>
);

const AllDayCellEmpty = () => <div className="cw-h-[26px] cw-pointer-events-none"></div>;

const AllDayCards = () => {
  const { objectToCellMap } = useContext(AllDayEventsContext);
  const events = map([...objectToCellMap.keys()], (event) => event);

  return (
    <div>
      {map(events, (event) => (
        <AllDayCard key={event.key} event={event} />
      ))}
    </div>
  );
};

export const AllDayCard = ({ event }: { event: PlannerEventCard }) => {
  const track = useTracking();
  const { primaryCalendarId } = usePlannerMetaData();
  const { isExpanded, objectToCellMap, dateToColumnMap, onClick } = useContext(AllDayEventsContext);
  const activeEvent = useReadActiveEvent();
  const [prefetchEventDetails] = usePrefetchEventDetails();
  const { selectExistingEvent } = useUpdateCurrentProposal();

  const updateActiveEventDiff = useUpdateActiveEventDiff();
  const updateActiveEvent = useUpdateActiveEvent();

  const isOwnEvent = includes(event.calendarIds, primaryCalendarId);
  const calendarColorSet = useReadCalendarColorForCalendarId(event.calendarIds[0], isOwnEvent);

  const unFadePastEvents = useReadLocalStorage<boolean>(plannerToolbarSettingKeys.unFadePastEvents);
  const isPast = event.interval.end < DateTime.local() && !unFadePastEvents;

  const cell = objectToCellMap.get(event);
  const dayConst = (1 / dateToColumnMap.size) * 100;

  if (!cell) {
    return null;
  }

  const isMaxCellAndLastCell = cell?.column + 1 === MAX_ALL_DAY_EVENT_COUNT && cell?.isLast;
  const isPastMaxCell = cell?.column + 1 >= MAX_ALL_DAY_EVENT_COUNT;
  if (!isExpanded && !isMaxCellAndLastCell && isPastMaxCell) {
    return null;
  }

  const handleClick = () => {
    const externalEventId = event.externalEventId;
    const calendarId = event.calendarIds[0];

    if (
      !externalEventId ||
      !calendarId ||
      // Do not set active event if the user is currently viewing a proposal
      activeEvent?.type === EventType.Proposal
    ) {
      return;
    }

    const canDisplayProposalForEvent =
      !event.locked &&
      !hasCategoryOutOfOffice({ eventCategory: event.eventCategory }) &&
      !event.smartHold &&
      event.type !== EventType.PersonalCalendarSync &&
      event.type !== EventType.WorkingLocation;

    if (canDisplayProposalForEvent) {
      track(TrackingEvents.DIRECT_MANIPULATION.CALENDAR.CLICK_TO_EXPAND, {
        externalEventId,
        calendarId,
      });

      selectExistingEvent(externalEventId, calendarId);
      updateActiveEventDiff(null);
      updateActiveEvent({ externalEventId: "", calendarId: "", type: EventType.Proposal });
    } else {
      onClick(calendarId, externalEventId, EventType.Event);
    }
  };

  return (
    <div
      className="cw-p-[2px] cw-absolute"
      style={{
        width: `${cell.colSpan * dayConst - GUTTER_WIDTH_PERCENT}%`,
        left: `${cell.row * dayConst}%`,
        top: `${(cell.column || 0) * 26}px`,
      }}
      aria-hidden="true"
      onMouseOver={() => {
        if (event.externalEventId && event.calendarIds[0]) {
          void prefetchEventDetails({
            variables: { externalEventId: event.externalEventId, calendarId: event.calendarIds[0] },
          });
        }
      }}
    >
      <CalendarEvent
        active={false}
        annotation={undefined}
        fade={isPast}
        badge={undefined}
        calendarColorSet={calendarColorSet}
        deemphasis={event.deemphasis}
        eventColorSet={isOwnEvent ? event.eventCategoryColor : undefined}
        onClick={handleClick}
        status={getCalendarEventStatus(event)}
        pulse={event.pulse}
        spacing="Full"
        text={event.text}
        variant="SingleLine"
      />
    </div>
  );
};
