import { Link } from "@clockwise/design-system";
import { LockOutlined } from "@clockwise/design-system/icons";
import pluralize from "pluralize";
import React from "react";

interface ReadonlyLogoProps {
  admins: { name: string; email: string }[];
  logoUrl?: string | null;
}

export const ReadonlyLogo = ({ admins, logoUrl }: ReadonlyLogoProps) => {
  return (
    <div className="cw-flex cw-flex-col cw-gap-2">
      <div className="cw-relative cw-flex cw-flex-col cw-items-center cw-justify-center cw-p-8 cw-rounded-lg cw-border-default-disabled cw-border cw-border-dashed cw-bg-default-disabled">
        <div className="cw-absolute cw-right-2 cw-top-2">
          <LockOutlined className="cw-text-16 cw-font-medium cw-text-default-disabled" />
        </div>
        {logoUrl ? (
          <img
            className="cw-w-[300px] cw-h-[150px] cw-object-contain"
            src={logoUrl}
            alt="Current booking logo"
          />
        ) : (
          <div className="cw-body-base cw-text-subtle cw-text-center">No logo uploaded</div>
        )}
      </div>
      <div className="cw-caption cw-text-subtle">
        Only admins can set a shared logo. Contact your plan's {pluralize("admin", admins.length)} (
        {admins.map((admin, i) => (
          <React.Fragment key={admin.email}>
            <Link href={`mailto:${admin.email}`} target="_blank">
              {admin.name}
            </Link>
            {i < admins.length - 1 ? ", " : ""}
          </React.Fragment>
        ))}
        ) to upload a shared logo.
      </div>
    </div>
  );
};
