export const calculateWorkingHoursFitsWithinCalendarView = (
  workingHourBounds: [number, number] | null,
  scrollContainerHeight: number,
  calendarContainerHeight: number,
): boolean => {
  if (!workingHourBounds) {
    return false;
  }

  const workingHoursHeight =
    ((workingHourBounds[1] - workingHourBounds[0]) / 24) * calendarContainerHeight;

  return workingHoursHeight <= scrollContainerHeight;
};
