import { useQuery } from "@apollo/client";
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import invariant from "tiny-invariant";
import { ScoreTradeoffsDocument } from "../../chat/ai-chat/apollo/__generated__/DiffDetailsById.generated";
import { DiffDetailsById, TradeoffBlocks } from "../../chat/ai-chat/utils/types";
import { useEventAttendees } from "./EventAttendeesContext";
import { useReadEventTimeDetails } from "./EventCardContext";

const EventTradeoffsContext = createContext<TradeoffBlocks | null>(null);

export const EventTradeoffsProvider: React.FC<
  PropsWithChildren<{ tradeoffs: DiffDetailsById["tradeoffBlocks"] }>
> = ({ tradeoffs: initialTradeoffs, children }) => {
  const { attendees, attendeesChanged } = useEventAttendees();
  const { startTime, endTime, timesHaveChanged, timeError } = useReadEventTimeDetails();

  const [tradeoffs, setTradeoffs] = useState<TradeoffBlocks>({
    tradeoffs: initialTradeoffs,
    loading: false,
    error: false,
  });

  const { data, loading, error } = useQuery(ScoreTradeoffsDocument, {
    // only run the query if something has changed
    skip: !!timeError || (!timesHaveChanged && !attendeesChanged && !startTime && !endTime),
    variables: {
      startTime: startTime?.toISO(),
      endTime: endTime?.toISO(),
      attendees: attendees.map((attendee) => attendee.primaryCalendar),
    },
  });

  useEffect(() => {
    let newTradeoffBlocks: TradeoffBlocks | undefined;
    // overwrite the tradeoffs in state if the query has some information (indicating we actually ran it)
    if (data?.viewer?.user?.scoreTime) {
      newTradeoffBlocks = {
        tradeoffs: data.viewer.user.scoreTime.tradeoffs,
        loading: false,
        error: false,
      };
    } else if (loading) {
      newTradeoffBlocks = { tradeoffs: null, loading: true, error: false };
    } else if (error) {
      newTradeoffBlocks = { tradeoffs: null, loading: false, error: true };
    }

    if (newTradeoffBlocks) {
      setTradeoffs(newTradeoffBlocks);
    }
  }, [data, loading, error]);

  return (
    <EventTradeoffsContext.Provider value={tradeoffs}>{children}</EventTradeoffsContext.Provider>
  );
};

export const useEventTradeoffs = () => {
  const context = useContext(EventTradeoffsContext);
  invariant(context, "useEventTradeoffs must be used within a EventTradeoffsProvider");
  return context;
};
