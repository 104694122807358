/**
 * @generated SignedSource<<5e40c604504441daa7c93b6067e9ff65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SudoModifyUserQuery$variables = {};
export type SudoModifyUserQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"SudoModifyUser_viewer">;
  };
};
export type SudoModifyUserQuery = {
  variables: SudoModifyUserQuery$variables;
  response: SudoModifyUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "state",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentComplete",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "errorMessage",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": "FlowStateConnection",
  "kind": "LinkedField",
  "name": "flowStates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FlowStateEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FlowState",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "flowKey",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FlowStatus",
              "kind": "LinkedField",
              "name": "current",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FlowStatus",
              "kind": "LinkedField",
              "name": "pending",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "flowStates(first:1000000)"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SudoModifyUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SudoModifyUser_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SudoModifyUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Sudo",
            "kind": "LinkedField",
            "name": "sudo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAndExtendedInfo",
                "kind": "LinkedField",
                "name": "modifyUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ecosystem",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "givenName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "familyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emails",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "externalImageUrl",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": (v1/*: any*/),
                        "concreteType": "OrgConnection",
                        "kind": "LinkedField",
                        "name": "orgs",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrgEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Org",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MyBillingGroups",
                                    "kind": "LinkedField",
                                    "name": "myBillingGroups",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "BillingGroup",
                                        "kind": "LinkedField",
                                        "name": "primaryBillingGroup",
                                        "plural": false,
                                        "selections": [
                                          (v0/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "BillingGroup",
                                        "kind": "LinkedField",
                                        "name": "allGroups",
                                        "plural": true,
                                        "selections": [
                                          (v0/*: any*/),
                                          (v4/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "orgs(first:1000000)"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "userTeams",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Team",
                            "kind": "LinkedField",
                            "name": "list",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "teamId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "teamName",
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "TeamList",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "featureStatesJson",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a929ca810304fa14dfdb3934ea5a54d3",
    "id": null,
    "metadata": {},
    "name": "SudoModifyUserQuery",
    "operationKind": "query",
    "text": "query SudoModifyUserQuery {\n  viewer {\n    ...SudoModifyUser_viewer\n    id\n  }\n}\n\nfragment SudoModifyUser_viewer on Viewer {\n  id\n  sudo {\n    modifyUser {\n      ecosystem\n      user {\n        id\n        givenName\n        familyName\n        emails\n        status\n        externalImageUrl\n        flowStates(first: 1000000) {\n          edges {\n            node {\n              id\n              flowKey\n              current {\n                id\n                state\n                percentComplete\n                errorMessage\n              }\n              pending {\n                id\n                state\n                percentComplete\n                errorMessage\n              }\n            }\n          }\n        }\n        orgs(first: 1000000) {\n          edges {\n            node {\n              id\n              name\n              myBillingGroups {\n                primaryBillingGroup {\n                  id\n                }\n                allGroups {\n                  id\n                  name\n                }\n              }\n              flowStates(first: 1000000) {\n                edges {\n                  node {\n                    id\n                    flowKey\n                    current {\n                      id\n                      state\n                      percentComplete\n                      errorMessage\n                    }\n                    pending {\n                      id\n                      state\n                      percentComplete\n                      errorMessage\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n      userTeams {\n        __typename\n        ... on TeamList {\n          list {\n            teamId\n            teamName\n            id\n          }\n        }\n      }\n      featureStatesJson\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d446497d8d72d9f360d13f818b8b600b";

export default node;
