import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
import { fg_brand } from "@clockwise/design-system/tokens";
import {
  DisabledWrapper,
  flexibilityExpiredTooltipCopy,
} from "@clockwise/web-commons/src/ui/disabled-wrapper";
import { PaywallBannerUpgradeCta } from "@clockwise/web-commons/src/ui/paywall-banner-upgrade-cta";
import { OpenInNew } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";
import { useMonetization } from "../../hooks/useMonetization";
import { InlineAction } from "../event-card/atoms/InlineAction";
import { shouldHighlightFlexibleMeetings } from "../ui-focus-wrappers/ui-focus.utils";
import { MyFlexibleMeetings } from "./MyFlexibleMeetings";
import { OneOnOnes } from "./OneOnOnes";

const onClickLearnMore = () => {
  window.open(articleUrls.autopilot, "_blank", "noreferrer");
};

export const FlexibleMeetings = () => {
  const { canUserAccessFlexMeetings, shouldUserSeeJoinExistingPlan } = useMonetization();

  return (
    <div className="cw-space-y-5">
      <div className="cw-body-base">
        <span className="cw-p-3 cw-flex cw-rounded-lg cw-bg-neutral">
          <div className="cw-body-base cw-flex cw-flex-col cw-gap-1">
            <span className="cw-font-semibold">How does it work?</span>
            <div className="cw-text-13 cw-text-muted">
              When you mark meetings flexible, Clockwise will automatically move them to give you
              blocks of Focus Time and resolve conflicts.
            </div>
            <InlineAction
              onClick={onClickLearnMore}
              ariaLabel="Learn More"
              label="Learn More"
              icon={
                <OpenInNew
                  style={{
                    fontSize: "13px",
                    color: fg_brand,
                    bottom: "-3px",
                    position: "relative",
                    marginBottom: "1px",
                    marginLeft: "4px",
                  }}
                />
              }
            />
          </div>
        </span>

        {!canUserAccessFlexMeetings && (
          <PaywallBannerUpgradeCta
            showJoinExistingPlan={shouldUserSeeJoinExistingPlan}
            bannerType="FlexMeetingPage"
          />
        )}
      </div>
      <div
        className={classNames({
          "cw-animate-serene-spotlight": shouldHighlightFlexibleMeetings(),
        })}
      >
        <OneOnOnes />
      </div>
      <DisabledWrapper
        isDisabled={!canUserAccessFlexMeetings}
        tooltipBody={flexibilityExpiredTooltipCopy.body}
        tooltipHeader={flexibilityExpiredTooltipCopy.header}
      >
        <div>
          <MyFlexibleMeetings />
        </div>
      </DisabledWrapper>
      <div className="cw-w-full cw-py-6 cw-flex cw-flex-col cw-items-center cw-px-4 cw-bg-neutral cw-rounded-xl cw-text-center cw-border cw-border-solid cw-border-[#F1F1F1]">
        <div className="cw-font-medium cw-body-sm">Missing a meeting?</div>
        <div className="cw-text-subtle cw-body-sm cw-text-12 cw-max-w-[510px]">
          Not all meetings are meant to be flexible. External meetings, meetings you don't have edit
          access to, and large team meetings with a predictable schedule should not be set to
          flexible.
        </div>
      </div>
    </div>
  );
};
