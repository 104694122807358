import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { Select, SelectOption, SelectOptionsGroup } from "@clockwise/design-system";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";
import { EventElementsWrap } from "../../atoms/EventElementsWrap";
import {
  StandardRecurrenceType,
  getStandardRRule,
  identifyStandardRecurrenceType,
} from "./recurrence.util";

export const optionsList = ["Weekdays", "Weekly", "Biweekly", "Monthly", "None"] as const;
type SelectableRecurrenceOption = typeof optionsList[number];

const groupedOptions: SelectableRecurrenceOption[] = ["Weekdays", "Weekly", "Biweekly", "Monthly"];

const createOptionsList = () => {
  const selectedOptionMap: { [k in SelectableRecurrenceOption]: string | null } = {
    Weekdays: "Daily on weekdays",
    Weekly: "Weekly",
    Biweekly: "Every 2 weeks",
    Monthly: "Monthly",
    None: "Does not repeat",
  };
  return selectedOptionMap;
};

type Props = {
  readOnly: boolean;
  recurrenceRule: RecurrenceRule | null;
  date?: string;
  onSelect: (recurrenceRule: RecurrenceRule | null) => void;
  showChanges?: boolean;
  originalRecurrence: RecurrenceRule | null;
};

const TO_LEGACY_TYPE: Record<StandardRecurrenceType, SelectableRecurrenceOption | null> = {
  None: "None",
  Weekdays: "Weekdays",
  Weekly: "Weekly",
  Biweekly: "Biweekly",
  MonthlyByDate: "Monthly",
  MonthlyByLastWeekday: null,
  MonthlyByNthWeekday: null,
};

const FROM_LEGACY_TYPE: Record<SelectableRecurrenceOption, StandardRecurrenceType> = {
  None: "None",
  Weekdays: "Weekdays",
  Weekly: "Weekly",
  Biweekly: "Biweekly",
  Monthly: "MonthlyByDate",
};

export const LegacyRecurrence = ({
  recurrenceRule,
  date,
  onSelect,
  readOnly,
  showChanges,
  originalRecurrence,
}: Props) => {
  const timezone = getRenderTimeZone();
  const dt = useMemo(
    () =>
      (date
        ? DateTime.fromISO(date, { zone: timezone })
        : DateTime.now().setZone(timezone)
      ).startOf("day"),
    [date, timezone],
  );

  const legacyOption: SelectableRecurrenceOption | "Custom" = useMemo(() => {
    const type = identifyStandardRecurrenceType(recurrenceRule, dt);
    return (type && TO_LEGACY_TYPE[type]) || "Custom";
  }, [recurrenceRule, dt]);

  // Either a standard recurrence type or the human-readable description of a custom recurrence
  const fetchedRecurrenceString = useMemo(() => {
    const type = identifyStandardRecurrenceType(originalRecurrence, dt);
    return (
      (type && TO_LEGACY_TYPE[type]) ||
      originalRecurrence?.toText({ date: dt.toISODate(), timezone })
    );
  }, [originalRecurrence, dt]);

  const handleLegacySelect = useCallback(
    (option: SelectableRecurrenceOption | "Custom") => {
      // Convert legacy option to standard recurrence rule type
      if (option === "Custom") {
        onSelect(originalRecurrence);
      } else {
        onSelect(getStandardRRule(FROM_LEGACY_TYPE[option], dt));
      }
    },
    [originalRecurrence, dt, onSelect],
  );

  return (
    <Recurrence
      onSelect={handleLegacySelect}
      readOnly={readOnly}
      selectedOption={legacyOption}
      fetchedRecurrenceString={fetchedRecurrenceString}
      showChanges={showChanges}
    />
  );
};

const Recurrence = ({
  readOnly,
  selectedOption,
  fetchedRecurrenceString,
  onSelect,
  showChanges,
}: {
  readOnly: boolean;
  fetchedRecurrenceString?: string;
  selectedOption: SelectableRecurrenceOption | string;
  onSelect: (option: SelectableRecurrenceOption) => void;
  showChanges?: boolean;
}) => {
  return (
    <ECRecurrence
      readOnly={readOnly}
      fetchedRecurrenceString={fetchedRecurrenceString}
      selectedOption={selectedOption}
      onSelect={onSelect}
      showChanges={showChanges}
    />
  );
};

export const ECRecurrence = ({
  readOnly,
  selectedOption,
  fetchedRecurrenceString,
  onSelect,
  showChanges,
}: {
  readOnly: boolean;
  fetchedRecurrenceString?: string;
  selectedOption: SelectableRecurrenceOption | string;
  onSelect: (option: SelectableRecurrenceOption) => void;
  showChanges?: boolean;
}) => {
  const listOfOptions = createOptionsList();
  const fetchedOptionAlreadyExists = optionsList.includes(
    fetchedRecurrenceString as SelectableRecurrenceOption,
  );

  return (
    <EventElementsWrap name="recurrence" showChanges={showChanges}>
      <Select
        disabled={readOnly}
        value={selectedOption}
        onChange={(value: SelectableRecurrenceOption) => {
          onSelect(value);
        }}
        fullWidth={true}
        size="small"
      >
        {fetchedRecurrenceString &&
          fetchedRecurrenceString !== "None" &&
          !fetchedOptionAlreadyExists && (
            <SelectOptionsGroup>
              {["Custom"].map(() => {
                return (
                  <SelectOption key="recurrence-custom" value="Custom">
                    {fetchedRecurrenceString}
                  </SelectOption>
                );
              })}
            </SelectOptionsGroup>
          )}
        <SelectOptionsGroup>
          {groupedOptions.map((option) => {
            return (
              <SelectOption key={`recurrence-${option}`} value={option}>
                {listOfOptions[option]}
              </SelectOption>
            );
          })}
        </SelectOptionsGroup>
        <SelectOption key="recurrence-none" value="None">
          {listOfOptions["None"]}
        </SelectOption>
      </Select>
    </EventElementsWrap>
  );
};
