/* eslint-disable testing-library/await-async-query */
import { DateTime } from "luxon";
import * as React from "react";
import { HeroStatPeriodData, HeroStatUsers } from "../hero-stat";
import { IOrgOverview } from "../overview.types";
import transformOrgOvierviewToCumulativePerPeriodStats from "./utils/transformOrgOvierviewToCumulativePerPeriodStats";

const DATA_DATE_FORMAT = "yyyy-MM-dd";
export interface HeroStatGridProps {
  maxDate: Date;
  midDate: Date;
  minDate: Date;
  orgOverview: IOrgOverview;
}

export const HeroStatGrid = ({ maxDate, midDate, minDate, orgOverview }: HeroStatGridProps) => {
  const cumulativeStats = transformOrgOvierviewToCumulativePerPeriodStats(
    orgOverview,
    ["scheduleAssistCount", "focusTimeHoursCreatedCount", "conflictsResolvedCount"],
    ["userCount"],
    maxDate,
    midDate,
    minDate,
  );

  // DevNote: We should only allow hero stats to compare current to previous period if previous
  //          period is "whole". (ex don't compare 1mo to 12mo as year-over-year if we have 13mo
  //          of data) the stats for scheduleAssistCount, focusTimeHoursCreatedCount, and
  //          conflictsResolvedCount may return data with gaps and so we cannot assume omitted
  //          dates means incomplete data, can mean 0. So, we will infer a periods "wholeness"
  //          useing user count; which does not return with gaps.
  const minDateLabel = DateTime.fromJSDate(minDate).toFormat(DATA_DATE_FORMAT);
  const findByLabel = (label: string) => (obj: { label: string }) => obj.label === label;
  const minDateUserCountRecord = orgOverview?.userCount?.find(findByLabel(minDateLabel));
  const hasUsersAtMinDate = (minDateUserCountRecord && minDateUserCountRecord?.value > 0) || false;

  const midDateDisplayString = DateTime.fromJSDate(midDate).toFormat("MM/dd/yyyy");
  const minDateDisplayString = DateTime.fromJSDate(minDate).toFormat("MM/dd/yyyy");
  const previousPeriodString = `${minDateDisplayString} - ${midDateDisplayString}`;

  return (
    <div className="cw-mt-7 cw-mb-8 cw-pb-5 cw-border-b cw-border-solid cw-border-default cw-flex cw-flex-wrap">
      <HeroStatUsers count={cumulativeStats.userCount.current} date={maxDate} />
      <HeroStatPeriodData
        previousPeriodHelpText={previousPeriodString}
        period={{
          current: cumulativeStats.scheduleAssistCount.current,
          previous: hasUsersAtMinDate ? cumulativeStats.scheduleAssistCount.previous : 0,
        }}
        title={"Schedule assists"}
      />
      <HeroStatPeriodData
        previousPeriodHelpText={previousPeriodString}
        period={{
          current: cumulativeStats.focusTimeHoursCreatedCount.current,
          previous: hasUsersAtMinDate ? cumulativeStats.focusTimeHoursCreatedCount.previous : 0,
        }}
        title={"Focus Time hours created"}
      />
      <HeroStatPeriodData
        previousPeriodHelpText={previousPeriodString}
        period={{
          current: cumulativeStats.conflictsResolvedCount.current,
          previous: hasUsersAtMinDate ? cumulativeStats.conflictsResolvedCount.previous : 0,
        }}
        title={"Conflicts resolved"}
      />
    </div>
  );
};
