import { ALL_WORK_DAYS_SELECTED } from "#webapp/src/components/event-card/types";
import { useQuery } from "@apollo/client";
import { Skeleton, Tooltip } from "@clockwise/design-system";
import { DayOfWeek, FlexRange, FlexStatus } from "@clockwise/schema/v2";
import { useAttendeeWorkingBounds } from "@clockwise/web-commons/src/hooks/useAttendeeWorkingHours";
import { useEventFlexibility } from "@clockwise/web-commons/src/hooks/useEventFlexibility";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { DayOnOffMap } from "@clockwise/web-commons/src/ui/working-hours";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import {
  convertArrayOfDayWeekToDayMap,
  convertDayMapToFlexRangeAndAllowedDays,
} from "@clockwise/web-commons/src/util/dayMap";
import { getSimplifiedStatus } from "@clockwise/web-commons/src/util/getFlexibleStatusSimplifed";
import React from "react";
import { useCurrentProposal } from "../../chat-plus-calendar/CurrentProposalContext";
import { EventFlexibilityHoverCard } from "../../web-app-calendar/event-context-menu/atoms/EventFlexibilityHoverCard";
import { useUnpauseEvent } from "../../web-app-calendar/hooks/useUnpauseEvent";
import { eventToast } from "../../web-app-calendar/notifiation-event/EventToast";
import { EventFlexibilityTarget } from "./EventFlexibilityTarget";

export const EventFlexibility = ({
  eventId,
  calendarId,
}: {
  eventId: string;
  calendarId: string;
}) => {
  const track = useTracking();
  const {
    canMarkFlexible,
    dayEnd: flexibleEnd,
    dayStart: flexibleStart,
    daysAllowed,
    flexible,
    range: flexibleRange,
    status,
    update: updateFlexibility,
    updating: updatingFlexible,
    refetchFlexibility,
  } = useEventFlexibility({
    id: eventId ?? "",
    calendarId: calendarId ?? "",
    useQuery: useGatewayQuery,
    useMutation: useGatewayMutation,
  });

  const {
    currentProposal: { initialAttendeeIds, title },
  } = useCurrentProposal();

  const {
    start: attendeeBoundStart,
    end: attendeeBoundEnd,
    conflict: attendeeBoundConflict,
  } = useAttendeeWorkingBounds({
    eventId,
    calendarId,
    calendarIds: initialAttendeeIds.map((id) => id.toLowerCase()),
    useQuery: useQuery,
  });

  const { update: unpause, updating: updatingUnpause } = useUnpauseEvent(eventId);

  const flexibleStatus = getSimplifiedStatus(status, attendeeBoundConflict);
  const flexibleDayOnOffMap = convertArrayOfDayWeekToDayMap(
    daysAllowed,
    flexibleRange,
    ALL_WORK_DAYS_SELECTED,
  );

  const onUpdateFlexibilitySuccess = () => {
    eventToast.success({
      operation: "FLEX",
      title,
    });
  };

  const onUpdateFlexibilityFailure = () => {
    eventToast.error({
      operation: "FLEX",
      title,
    });
  };

  const handleChangeFlexible = (isFlexible: boolean) => {
    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_FLEXIBILITY, {
      eventId,
      calendarId,
      settings: "flexible",
    });

    updateFlexibility({
      isFlexible: isFlexible,
      flexRange: flexibleRange,
      allowedDays: daysAllowed,
      start: flexibleStart,
      end: flexibleEnd,
      onSuccess: onUpdateFlexibilitySuccess,
      onFailure: onUpdateFlexibilityFailure,
    });
  };

  const handleChangeFlexibleDays = (dayOnOffMap: DayOnOffMap) => {
    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_FLEXIBILITY, {
      eventId,
      calendarId,
      settings: "flexible days",
    });

    const { flexRange, allowedDays } = convertDayMapToFlexRangeAndAllowedDays(dayOnOffMap);

    updateFlexibility({
      isFlexible: flexible,
      flexRange,
      allowedDays,
      start: flexibleStart,
      end: flexibleEnd,
      onSuccess: onUpdateFlexibilitySuccess,
      onFailure: onUpdateFlexibilityFailure,
    });
  };

  const handleChangeFlexibleRange = (range: FlexRange) => {
    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_FLEXIBILITY, {
      eventId,
      settings: "flexible range",
    });

    let newAllowedDays: DayOfWeek[] | undefined;
    switch (range) {
      case FlexRange.Day:
        newAllowedDays = undefined;
        break;
      case FlexRange.Week:
        newAllowedDays = undefined;
        break;
      case FlexRange.SpecificDays:
        newAllowedDays = daysAllowed;
        break;
    }

    updateFlexibility({
      isFlexible: flexible,
      flexRange: range,
      allowedDays: newAllowedDays,
      start: flexibleStart,
      end: flexibleEnd,
      onSuccess: onUpdateFlexibilitySuccess,
      onFailure: onUpdateFlexibilityFailure,
    });
  };

  const handleChangeFlexibleTimeRange = (start: string, end: string) => {
    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_FLEXIBILITY, {
      eventId,
      settings: "flexible time range",
    });

    updateFlexibility({
      isFlexible: flexible,
      flexRange: flexibleRange,
      allowedDays: daysAllowed,
      start,
      end,
      onSuccess: onUpdateFlexibilitySuccess,
      onFailure: onUpdateFlexibilityFailure,
    });
  };

  const handleChangeFlexibleResume = () => {
    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_FLEXIBILITY, {
      eventId,
      settings: "resume",
    });

    unpause({
      onCompleted: () => {
        refetchFlexibility();
      },
    });
  };

  const tooltipTitle =
    status === FlexStatus.External
      ? "Meetings with external attendees can't be marked as flexible."
      : status === FlexStatus.TrialExpired
      ? "Flexible meetings aren't supported on the Free plan. Upgrade to let your flexible meetings move again."
      : "";

  if (updatingUnpause) {
    return (
      <div className="cw-py-1 cw-my-1 cw-px-3 cw-flex cw-items-center cw-gap-3">
        <Skeleton variant="circle" height={16} width={16} className="cw-ml-[2px]" />
        <Skeleton variant="rect" height={24} className="cw-flex-grow cw-rounded-md" />
      </div>
    );
  }

  return (
    <Tooltip title={tooltipTitle}>
      <div>
        <EventFlexibilityHoverCard
          flexible={flexible}
          flexibleDayOnOffMap={flexibleDayOnOffMap}
          flexibleEditable={canMarkFlexible}
          flexibleEnd={flexibleEnd}
          flexibleRange={flexibleRange}
          flexibleStart={flexibleStart}
          flexibleStatus={flexibleStatus}
          onChangeFlexible={handleChangeFlexible}
          onChangeFlexibleDays={handleChangeFlexibleDays}
          onChangeFlexibleRange={handleChangeFlexibleRange}
          onChangeFlexibleResume={handleChangeFlexibleResume}
          onChangeFlexibleTimeRange={handleChangeFlexibleTimeRange}
          updatingFlexible={updatingFlexible}
          attendeeBoundStart={attendeeBoundStart}
          attendeeBoundEnd={attendeeBoundEnd}
        >
          <EventFlexibilityTarget
            onChangeFlexible={handleChangeFlexible}
            flexibleEditable={canMarkFlexible}
            flexible={flexible}
            updatingFlexible={updatingFlexible}
            flexibleStatus={flexibleStatus}
          />
        </EventFlexibilityHoverCard>
      </div>
    </Tooltip>
  );
};
