import { greys } from "@clockwise/web-commons/src/styles/color.styles";
import spacing from "@clockwise/web-commons/src/styles/spacing.styles";
import { fontFamilySans, size } from "@clockwise/web-commons/src/styles/type.styles";
import { CSSProperties } from "react";

export const signupBtn = {
  base: {
    height: 45,
    lineHeight: `45px`,
    width: "100%",
    maxWidth: 220,
  } as CSSProperties,
  label: {
    fontFamily: fontFamilySans,
    fontSize: size.md,
    paddingLeft: spacing.std,
    paddingRight: spacing.lg,
    color: greys.white,
    textTransform: "none",
  } as CSSProperties,
};
