import { graphql } from "react-relay";

export const unsubscribeWrapperFragments = {
  viewer: graphql`
    fragment UnsubscribeWrapper_viewer on Viewer {
      id
    }
  `,
};

export const unsubscribeWrapperQuery = graphql`
  query UnsubscribeWrapperQuery {
    viewer {
      ...UnsubscribeWrapper_viewer
    }
  }
`;
