import { getRenderTimeZone, intervalSetZone } from "#clockwise/web-commons/src/util/time-zone.util";
import { round } from "lodash";
import { Duration, Zone } from "luxon";
import { CALENDAR_SLOTS_RENDERED, GUTTER_WIDTH_PERCENT } from "../constants";
import { ColumnedItem, ICalPosition } from "../types";
export const getRailPosition = ({
  columnedItem,
  columnCount,
  minDuration = Duration.fromObject({ minutes: 30 }),
}: {
  columnedItem: ColumnedItem;
  columnCount: number;
  minDuration?: Duration;
}): ICalPosition => {
  const {
    item,
    placement: { columnIndex: column },
  } = columnedItem;

  const timeZone = (getRenderTimeZone() as unknown) as Zone;
  const setIntervalToRenderTimeZone = intervalSetZone(timeZone);
  const interval = setIntervalToRenderTimeZone(item.interval);

  const heightPercent = 100 / CALENDAR_SLOTS_RENDERED;
  const minHeight = round((minDuration.as("minutes") / 30) * heightPercent, 3);
  const duration = interval.length("minutes") / 30;

  const columnWidthPercent = 100 / columnCount;

  const top = round((interval.start.hour * 2 + interval.start.minute / 30) * heightPercent, 3);

  const left = round(columnWidthPercent + columnWidthPercent * column - GUTTER_WIDTH_PERCENT, 3);

  const width = GUTTER_WIDTH_PERCENT;

  const height = Math.max(minHeight, round(duration * heightPercent, 3));

  return { top, left, width, height, floatOffset: 0, renderOrder: 0, subColumnLeft: 0 };
};
