import { Select, SelectOption } from "@clockwise/design-system";
import {
  FeatureSetting,
  FeatureSettingAside,
  FeatureSettingBody,
  FeatureSettingHeading,
  FeatureSettingMain,
  FeatureSettingOptions,
  FeatureSettingsHoverCard,
} from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import Options, {
  OptionsState,
} from "@clockwise/web-commons/src/components/feature-setting/Options";
import { TrackingEvents, track } from "../../util/analytics.util";
import { getTimeSlotsAfter, getTimeSlotsBefore } from "./utils/getTimeSlots";

import { fg_muted } from "@clockwise/design-system/tokens";
import { TimeSlot } from "@clockwise/schema";
import classNames from "classnames";
import { Duration } from "luxon";
import { CircleNotch } from "phosphor-react";
import React from "react";
import toast from "react-hot-toast";
import { ScrollIntoView } from "../ui-focus-wrappers/ScrollIntoView";
import LunchHoldIllustration from "./LunchHoldIllustration";
import Tooltip from "./Tooltip";
import useLunch from "./hooks/useLunch";
import diffTimeSlots from "./utils/diffTimeSlots";

const notifySuccess = () => toast.success("Updated lunch hold setting.");
const notifyError = () => toast.error("Failed to updated lunch hold setting.");

const LunchHold = () => {
  const { settings: state, update, loading, updating, toggle, toggling } = useLunch({
    onSuccess: notifySuccess,
    onFail: notifyError,
  });

  let isError = false;
  let message = "";
  const availableLunchWindowDuration = diffTimeSlots(state.earliestStart, state.latestEnd, true);

  if (state.minimumDuration > availableLunchWindowDuration) {
    isError = true;
    message =
      "Your minimum lunch duration is greater than the time available between your specified start and end times.";
  } else if (state.idealDuration > availableLunchWindowDuration) {
    message =
      "Your preferred lunch duration is greater than the time available between your specified start and end times.";
  }

  const handleEnabledChange = (value: boolean) => {
    void toggle(value);

    track(TrackingEvents.SETTINGS.UPDATE_LUNCH, { enabled: value });
  };

  const handleEarliestStartChange = (value: TimeSlot) => {
    void update({ earliestStart: value });

    track(TrackingEvents.SETTINGS.UPDATE_LUNCH_START, {
      minLunchStart: value,
      maxLunchEnd: state.latestEnd,
    });
  };

  const handleLatestEndChange = (value: TimeSlot) => {
    void update({ latestEnd: value });

    track(TrackingEvents.SETTINGS.UPDATE_LUNCH_END, {
      minLunchStart: state.earliestStart,
      maxLunchEnd: value,
    });
  };

  const handleMinimumDurationChange = (value: Duration) => {
    let { idealDuration } = state;
    if (value > idealDuration) {
      idealDuration = value;
    }

    void update({ minimumDuration: value, idealDuration });

    track(TrackingEvents.SETTINGS.UPDATE_LUNCH_DURATION_MIN, {
      maxLunchDuration: idealDuration.shiftTo("minutes").minutes,
      minLunchDuration: value.shiftTo("minutes").minutes,
    });
  };

  const handleIdealDurationChange = (value: Duration) => {
    let { minimumDuration } = state;
    if (value < minimumDuration) {
      minimumDuration = value;
    }

    void update({ idealDuration: value, minimumDuration });

    track(TrackingEvents.SETTINGS.UPDATE_LUNCH_DURATION_PREFERRED, {
      maxLunchDuration: value.shiftTo("minutes").minutes,
      minLunchDuration: minimumDuration.shiftTo("minutes").minutes,
    });
  };

  const handleOptionsChange = (value: OptionsState) => {
    void update({
      scheduleAs: value.scheduleAs,
      notificationStrategy: value.notificationStrategy,
    });

    track(TrackingEvents.SETTINGS.UPDATE_OPTIONS, {
      scheduleAs: value.scheduleAs,
      notificationStrategy: value.notificationStrategy,
      type: "Lunch Hold",
    });
  };

  return (
    <ScrollIntoView focusSection="lunch" highlight>
      <FeatureSetting
        enabled={!loading && state.enabled}
        loading={loading}
        onEnabledChange={handleEnabledChange}
        saving={toggling || updating}
        title="Lunch Settings"
      >
        <FeatureSettingAside>
          <LunchHoldIllustration />
        </FeatureSettingAside>
        <FeatureSettingMain>
          <FeatureSettingHeading>
            <h2
              className="cw-heading-2xl cw-m-0"
              aria-label="How about having time for lunch every day?"
            >
              How about having time for{" "}
              <FeatureSettingsHoverCard content={<Tooltip />}>
                <span className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1">
                  lunch
                </span>
              </FeatureSettingsHoverCard>{" "}
              every day?
            </h2>
          </FeatureSettingHeading>
          <FeatureSettingBody>
            <ul
              className={`
                cw-flex cw-flex-col cw-space-y-2
                cw-list-none cw-p-0 cw-my-1
              `}
            >
              <li>
                <div className="cw-flex cw-flex-row cw-flex-wrap cw-space-x-2 cw-items-center">
                  <span className="cw-whitespace-nowrap">Lunch can start as early as</span>
                  {loading ? (
                    <CircleNotch
                      className="cw-animate-spin cw-h-9"
                      color={fg_muted}
                      data-chromatic="ignore"
                    />
                  ) : (
                    <Select
                      disabled={!state.enabled}
                      onChange={handleEarliestStartChange}
                      value={state.earliestStart}
                    >
                      {getTimeSlotsBefore(state.latestEnd).map(([key, text]) => (
                        <SelectOption key={key} value={key}>
                          {text}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                  <span className="cw-whitespace-nowrap">and should end by</span>
                  {loading ? (
                    <CircleNotch
                      className="cw-animate-spin cw-h-9"
                      color={fg_muted}
                      data-chromatic="ignore"
                    />
                  ) : (
                    <Select
                      disabled={!state.enabled}
                      onChange={handleLatestEndChange}
                      value={state.latestEnd}
                    >
                      {getTimeSlotsAfter(state.earliestStart).map(([key, _, text]) => (
                        <SelectOption key={key} value={key}>
                          {text}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                </div>
              </li>
              <li>
                <div className="cw-flex cw-flex-row cw-flex-wrap cw-space-x-2 cw-items-center">
                  <span className="cw-whitespace-nowrap">I prefer to take</span>
                  {loading ? (
                    <CircleNotch
                      className="cw-animate-spin cw-h-9"
                      color={fg_muted}
                      data-chromatic="ignore"
                    />
                  ) : (
                    <Select
                      disabled={!state.enabled}
                      onChange={(value) => handleIdealDurationChange(Duration.fromISO(value))}
                      value={state.idealDuration.shiftTo("minutes").toISO()}
                    >
                      <SelectOption value="PT15M">15 minutes</SelectOption>
                      <SelectOption value="PT30M">30 minutes</SelectOption>
                      <SelectOption value="PT45M">45 minutes</SelectOption>
                      <SelectOption value="PT60M">60 minutes</SelectOption>
                      <SelectOption value="PT90M">90 minutes</SelectOption>
                    </Select>
                  )}
                  <span className="cw-whitespace-nowrap">and need a minimum of</span>
                  {loading ? (
                    <CircleNotch
                      className="cw-animate-spin cw-h-9"
                      color={fg_muted}
                      data-chromatic="ignore"
                    />
                  ) : (
                    <Select
                      disabled={!state.enabled}
                      onChange={(value) => handleMinimumDurationChange(Duration.fromISO(value))}
                      value={state.minimumDuration.shiftTo("minutes").toISO()}
                    >
                      <SelectOption value="PT15M">15 minutes</SelectOption>
                      <SelectOption value="PT30M">30 minutes</SelectOption>
                      <SelectOption value="PT45M">45 minutes</SelectOption>
                      <SelectOption value="PT60M">60 minutes</SelectOption>
                      <SelectOption value="PT90M">90 minutes</SelectOption>
                    </Select>
                  )}
                </div>
              </li>
            </ul>
            <div
              className={classNames(
                "cw-mt-4 cw-body-sm",
                isError ? "cw-text-warning" : "cw-text-info",
              )}
            >
              {message}
            </div>
          </FeatureSettingBody>
          <FeatureSettingOptions>
            <Options
              enabled={state.enabled}
              notificationStrategy={state.notificationStrategy}
              onChange={handleOptionsChange}
            />
          </FeatureSettingOptions>
        </FeatureSettingMain>
      </FeatureSetting>
    </ScrollIntoView>
  );
};

export default LunchHold;
