// material-ui
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { getSettingsViewIdForRoute, scroll } from "../web-settings";

interface IProps {
  children: React.ReactNode;
}

// ~-~-~-~-~-~-~-
// Web Settings Integrations
// ~-~-~-~-~-~-~-
const useStyles = makeStyles({
  container: {
    padding: "40px 60px",
    maxWidth: 1040,
    "@media (max-width: 768px)": {
      padding: "20px 5px 20px 60px",
    },
  },
});

export const WebSettingsContainer = (props: IProps) => {
  const classes = useStyles();
  const settingsViewId = getSettingsViewIdForRoute(window.location);

  React.useEffect(() => {
    // handle scroll
    if (!settingsViewId) {
      return;
    }

    scroll(settingsViewId);
  }, [settingsViewId]);

  return (
    <div
      cw-id="web-settings-container"
      className={`cw-w-full cw-flex cw-flex-col cw-bg-default ${classes.container}`}
    >
      {props.children}
    </div>
  );
};
