import { Button, Checkbox } from "@clockwise/design-system";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@clockwise/design-system/icons";
import { SortDirection, SortType } from "@clockwise/schema";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import * as React from "react";
import { track, TrackingEvents } from "../../../../../../util/analytics.util";
import { useSelectTable } from "../../../../hooks/useSelectTable";
import { NewMemberPerson } from "../../APMembers.util";
import { useAPMembers } from "../../useAPMembers";
import { MemberRow } from "../member-row";

export const MembersTable = () => {
  const {
    handlePagination,
    currentPersons,
    currentSortType,
    currentSortDirection,
    setCurrentSortType,
    handleSearch,
    setCurrentSortDirection,
    previousPersonId,
    nextPersonId,
  } = useAPMembers();

  const {
    selectedMembers,
    isSelectAllChecked,
    isSelectTableDisabled,
    onSelectAllMembers,
  } = useSelectTable();

  const sortByDirection = () => {
    const newSortDirection =
      currentSortDirection === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending;

    track(TrackingEvents.ADMIN_PANEL.MEMBERS_SORT, {
      sortType: currentSortType,
      sortDirection: newSortDirection,
    });
    setCurrentSortDirection(newSortDirection);
  };

  const sortByType = (newSortType: SortType) => {
    setCurrentSortType(newSortType);
    track(TrackingEvents.ADMIN_PANEL.MEMBERS_SORT, {
      sortType: newSortType,
      sortDirection: currentSortDirection,
    });
  };

  const handleSort = (type: SortType) => {
    handleSearch("");
    if (type === currentSortType) {
      sortByDirection();
    } else {
      sortByType(type);
    }
  };

  const isPersonSelected = (person: NewMemberPerson) => {
    return selectedMembers
      ? selectedMembers.some((member) => member.targetCalendarId === person.targetCalendarId)
      : false;
  };

  const isSelectIndeterminate =
    isSelectAllChecked &&
    selectedMembers.length !== currentPersons.length &&
    selectedMembers.length > 0;

  /**
   * pageToken as first personId in the current page along with
   * fetchNext false fetches previous page, pageToken as last personId
   * in the current page along with fetchNext true fetches next page.
   */
  const handleBackButtonClick = () => {
    if (previousPersonId) {
      handlePagination(previousPersonId || "", false);
    }
  };

  const handleNextButtonClick = () => {
    if (nextPersonId) {
      handlePagination(nextPersonId || "", true);
    }
  };

  const maybeRenderPaginationActions = () => {
    if (!nextPersonId && !previousPersonId) {
      return null;
    }

    return (
      <TableFooter>
        <TableRow>
          <TableCell colSpan={5} align="right">
            <div className="cw-flex cw-flex-row cw-items-center cw-justify-end">
              <Button
                variant="text"
                sentiment="neutral"
                startIcon={KeyboardArrowLeft}
                disabled={!previousPersonId}
                onClick={handleBackButtonClick}
              />
              <Button
                variant="text"
                sentiment="neutral"
                startIcon={KeyboardArrowRight}
                disabled={!nextPersonId}
                onClick={handleNextButtonClick}
              />
            </div>
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  };

  const isSelectAllActive = isSelectAllChecked && selectedMembers.length > 0;

  if (!currentPersons.length) {
    return <div className="cw-py-3">No teammates found</div>;
  }

  return (
    <Table className="cw-table-fixed cw-min-w-[550px]">
      <TableHead className={"cw-bg-default-hover"}>
        <TableRow>
          {!isSelectTableDisabled && (
            <TableCell className="cw-w-[5%]">
              <Checkbox
                size="small"
                onChange={onSelectAllMembers}
                checked={isSelectAllActive}
                indeterminate={isSelectIndeterminate}
                disabled={isSelectTableDisabled}
              />
            </TableCell>
          )}
          <TableCell className="cw-w-[30%]">
            <TableSortLabel
              className="cw-font-bold"
              active={currentSortType === SortType.FullName}
              direction={currentSortDirection === SortDirection.Ascending ? "asc" : "desc"}
              onClick={() => handleSort(SortType.FullName)}
            >
              Name
            </TableSortLabel>
          </TableCell>
          <TableCell className="cw-w-[15%] cw-font-bold">Sign-up date</TableCell>
          <TableCell className="cw-w-[20%] cw-font-bold">Plan</TableCell>
          <TableCell className="cw-w-[15%] cw-font-bold">Role</TableCell>
          <TableCell className="cw-w-[15%]"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {currentPersons.map((person) => (
          <MemberRow
            key={person.targetCalendarId}
            person={person}
            isChecked={isPersonSelected(person)}
          />
        ))}
      </TableBody>
      {maybeRenderPaginationActions()}
    </Table>
  );
};
