import * as ISchema from "#webapp/src/__schema__";
import { upsertPreferredWork } from "#webapp/src/mutations";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { standardGoals } from "#webapp/src/util/goal.util";
import { Checkbox } from "@clockwise/design-system";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { createFragmentContainer } from "react-relay";
import { fragments } from "./WorkPreferenceStartEndGoal.gql";

//////////////////
// IMPORTS
//////////////////
// schema
import { findGoal } from "#webapp/src/util/goal.util";
import { IProps } from "./goals-wrapperTypes";

const WorkPreferenceStartEndGoalFnComponent = ({ org, relay }: IProps) => {
  const goalId = standardGoals.PreferredWork;
  const goal = findGoal(org.userGoals, goalId);
  const goalOptions = goal && goal.options;

  const preferredWorkOptions =
    goalOptions && (goalOptions.specificOptions as ISchema.IGoalPreferredWorkOptions);

  const [keepStartOfDayOpen, setKeepStartOfDayOpen] = useState(
    preferredWorkOptions?.keepStartOfDayOpen || false,
  );
  const [keepEndOfDayOpen, setKeepEndOfDayOpen] = useState(
    preferredWorkOptions?.keepEndOfDayOpen || false,
  );

  const onStartOfDayToggle = useCallback(
    (checked: boolean) => {
      setKeepStartOfDayOpen(checked);

      track(TrackingEvents.SETTINGS.UPDATE_PREFERRED_WORK, { keepStartOfDayOpen: checked });

      upsertPreferredWork(
        relay.environment,
        {
          options: { keepStartOfDayOpen: checked },
          orgId: org.id,
        },
        () => {
          const message = checked
            ? "Great! We will try not to schedule meetings at the start of your day"
            : "Okay, we'll slot meetings according to your other preferences";

          toast.success(message);
        },

        onWorkPreferenceMutationFailure,
      );
    },
    [org.id, relay.environment],
  );

  const onEndOfDayToggle = useCallback(
    (checked: boolean): void => {
      setKeepEndOfDayOpen(checked);
      track(TrackingEvents.SETTINGS.UPDATE_PREFERRED_WORK, { keepEndOfDayOpen: checked });

      upsertPreferredWork(
        relay.environment,
        {
          options: { keepEndOfDayOpen: checked },
          orgId: org.id,
        },
        () => {
          const message = checked
            ? "Great! We will try not to schedule meetings at the end of your day"
            : "Okay, we'll slot meetings according to your other preferences";

          toast.success(message);
        },
        onWorkPreferenceMutationFailure,
      );
    },
    [org.id, relay.environment],
  );

  const onWorkPreferenceMutationFailure = (): void => {
    const message = "An error occurred while updating work preference";
    toast.error(message);
  };

  return (
    <div className="cw-pb-2">
      <div>
        <div>
          <Checkbox
            checked={keepStartOfDayOpen}
            onChange={onStartOfDayToggle}
            label="I prefer not to meet at the very beginning of the day"
          />
        </div>
        <div>
          <Checkbox
            checked={keepEndOfDayOpen}
            onChange={onEndOfDayToggle}
            label="I prefer not to meet at the very end of the day"
          />
        </div>
      </div>
    </div>
  );
};

export const WorkPreferenceStartEndGoal = createFragmentContainer(
  WorkPreferenceStartEndGoalFnComponent,
  fragments,
);
