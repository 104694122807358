import { Location } from "react-router-dom";

export const utmParams =
  "?utm_source=referral&utm_medium=getclockwise.com&utm_campaign=2022_GM_SignupCTATest";

export const isWelcomeSignupExperiment = (location: Location | undefined) => {
  const isRouteWelcome = location?.pathname === "/welcome";
  const doesUtmMatchCampaign = location?.search === utmParams;

  return isRouteWelcome && doesUtmMatchCampaign;
};
