//////////////////
// IMPORTS
//////////////////
import { getNewStartTimeConstraintedToSpecificDay } from "@clockwise/web-commons/src/components/calendar/calendar-event/CalendarEvent";
import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { Interval } from "luxon";
import * as React from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  addSelectedRescheduleOption,
  SelectedRescheduleOptionEntrypoint,
} from "../../state/actions/reschedule-event-modal.actions";

const IS_DRAGGING_DEFAULT = false;
const DRAG_DATA_DEFAULT = null;

type DragData = {
  current: {
    externalEventId: string;
    interval: Interval;
    columnIndex: number | null;
    title: string;
    inPx15minIncrement: 16;
    canModify: boolean;
    isPersonalEvent: boolean;
  };
} | null;

export const CalendarWeekDNDContext = React.createContext<{ isDragging: boolean; data: DragData }>({
  isDragging: IS_DRAGGING_DEFAULT,
  data: DRAG_DATA_DEFAULT,
});

const DNDWrapperContext = ({ children }: { children: React.ReactNode }) => {
  const [isDragging, setIsDragging] = React.useState(IS_DRAGGING_DEFAULT);
  const [dragData, setDragData] = React.useState<DragData>(DRAG_DATA_DEFAULT);
  const mouseSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(mouseSensor);
  const dispatch = useDispatch();
  const onDragEndDragAndDrop = (e: any) => {
    const over = e.over;

    // Tech Debt: We should probably set this explicitly
    // instead of inferring it like this
    const singleDayView = e.over === null;

    const y = e.delta.y;
    const data = e.active.data;
    const canModify = data?.current?.canModify;
    const inPx15minIncrement = data?.current?.inPx15minIncrement || 0;
    const deltaTime = Math.ceil(y / inPx15minIncrement) * 15;
    const deltaDays = singleDayView ? 0 : over?.id - (data?.current?.columnIndex ?? 0);

    const currentStartTime = data?.current.interval.start;
    const externalEventId = data?.current.externalEventId;
    const title = data?.current.title;
    const duration = data?.current.interval.end.diff(currentStartTime, "minutes").minutes;
    const newStartTimeDisregardingDay = currentStartTime.plus({ minutes: deltaTime });
    const isRecurring = data.current.isRecurring;
    const recurringEventId = data.current.recurringEventId;
    const isHold = data.current.isHold;

    const newStartTimeWithEventContraintedToDay = getNewStartTimeConstraintedToSpecificDay({
      newStartTime: newStartTimeDisregardingDay,
      duration,
      currentStartTime,
    });
    const newStartTimeConstrainedWithDeltaDays = newStartTimeWithEventContraintedToDay.plus({
      days: deltaDays,
    });

    // Always reset drag data in any of the situations
    setIsDragging(IS_DRAGGING_DEFAULT);
    setDragData(DRAG_DATA_DEFAULT);

    if (!canModify) {
      return;
    }

    if (deltaTime == 0 && deltaDays == 0) {
      // Didn't move
      return;
    }

    // Opens the modal
    dispatch(
      addSelectedRescheduleOption({
        id: "", // Since this not a option from the BE, we don't have an id
        externalEventId,
        duration,
        title,
        proposedStartTimeISO: newStartTimeConstrainedWithDeltaDays.toISO(),
        currentStartTimeISO: currentStartTime.toISO(),
        showACopyOfNewProposedEvent: true,
        // UX Note: We always want to show the new event without the original event
        hideOriginalEvent: true,
        entryPoint: SelectedRescheduleOptionEntrypoint.DirectManipulation,
        showFindMoreTimes: true,
        isRecurring,
        recurringEventId,
        isHold,
      }),
    );
  };
  const onDragEndResize = (e: any) => {
    const data = e.active.data;
    const canModify = data?.current?.canModify;

    const newDuration = data.current.newDuration;
    const interval = data.current.interval;
    const title = data.current.title;
    const currentStartTime = interval.start;
    const duration = data.current.interval.end.diff(currentStartTime, "minutes").minutes;
    const externalEventId = data.current.externalEventId;
    const isRecurring = data.current.isRecurring;
    const recurringEventId = data.current.recurringEventId;
    const isHold = data.current.isHold;

    if (!canModify) {
      return;
    }

    if (newDuration <= 0) {
      // Resize but is not sensical
      return;
    }

    if (newDuration == duration) {
      // Didn't resize
      return;
    }

    // Opens the modal
    dispatch(
      addSelectedRescheduleOption({
        id: "", // Since this not a option from the BE, we don't have an id
        externalEventId,
        duration,
        newDuration,
        title,
        proposedStartTimeISO: currentStartTime.toISO(),
        currentStartTimeISO: currentStartTime.toISO(),
        showACopyOfNewProposedEvent: true,
        // UX Note: We always want to show the new event without the original event
        hideOriginalEvent: true,
        entryPoint: SelectedRescheduleOptionEntrypoint.DirectManipulation,
        showFindMoreTimes: true,
        isRecurring,
        recurringEventId,
        isHold,
      }),
    );
  };
  const onDragEnd = (e: any) => {
    const data = e.active.data;
    if (data?.current?.isDragAndDrop) {
      return onDragEndDragAndDrop(e);
    }

    if (data?.current?.isResize) {
      return onDragEndResize(e);
    }

    if (data?.current?.isHorizontalClickDragToCreate) {
      setIsDragging(IS_DRAGGING_DEFAULT);
      setDragData(DRAG_DATA_DEFAULT);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={(e: any) => {
        const data = e.active.data;

        if (data?.current?.isClickDragToCreate) {
          return;
        }

        // Set the dragging state to true when dragging horizontally to create an  all-day event
        // Used by CalendarWeek to disable calendar vertical scrolling
        if (data?.current?.isHorizontalClickDragToCreate) {
          setIsDragging(true);
          setDragData({ current: data.current });
          return;
        }

        const canModify = data?.current?.canModify;

        if (!canModify) {
          toast.error("You don't have permission to modify this event");
        }

        if (data?.current?.isDragAndDrop) {
          setIsDragging(true);
          setDragData(data);
        }
      }}
      onDragEnd={onDragEnd}
    >
      <CalendarWeekDNDContext.Provider
        value={{
          data: dragData,
          isDragging,
        }}
      >
        {children}
      </CalendarWeekDNDContext.Provider>
    </DndContext>
  );
};

export default DNDWrapperContext;
