import { DateTime, Duration } from "luxon";
import React, { useMemo } from "react";

import { DateInput } from "@clockwise/design-system";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { pick } from "lodash";
import { ECEndTimePicker, ECTimePicker } from "../molecules/ECTimePicker";

const toDateTime = (date: string, time: string): DateTime =>
  DateTime.fromISO(`${date}T${time}`, { zone: getRenderTimeZone() });

export const ECTime = ({
  readOnly,
  startTime: start,
  endTime: end,
  onChange,
  isAllDay,
  timeError,
}: {
  readOnly: boolean;
  startTime?: DateTime;
  endTime?: DateTime;
  onChange: (start: DateTime, end: DateTime) => void;
  isAllDay: boolean;
  timeError: string | null;
}) => {
  const startDate = useMemo(() => start?.toISODate(), [start]);
  const startTime = useMemo(
    () => start?.toISOTime({ includeOffset: false, suppressSeconds: true }),
    [start],
  );
  const endDate = useMemo(() => end?.toISODate(), [end]);
  const endTime = useMemo(() => end?.toISOTime({ includeOffset: false, suppressSeconds: true }), [
    end,
  ]);

  const isSameDay = startDate && endDate && startDate === endDate;
  const duration = useMemo(
    () => (start && end ? end.diff(start) : Duration.fromDurationLike({ minutes: 30 })),
    [start, end],
  );

  const handleStartChange = (_startDate: string | undefined, _startTime: string | undefined) => {
    const updatedStart = DateTime.fromISO(`${_startDate}T${_startTime ?? "09:00"}`, {
      zone: getRenderTimeZone(),
    });

    const updatedEnd = updatedStart.plus(duration);

    onChange(updatedStart, updatedEnd);
  };
  const handleEndChange = (_endDate: string | undefined, _endTime: string | undefined) => {
    const timeChanged = _endTime !== endTime;

    const newEndDate = _endDate ?? startDate ?? DateTime.now().toISODate();
    const newEndTime = _endTime ?? startTime ?? "09:00";

    let updatedEnd = toDateTime(newEndDate, newEndTime);
    let updatedStart = startDate && startTime ? toDateTime(startDate, startTime) : null;

    // // If we don't have a start defined for the event, initialize it to a standard duration
    if (!updatedStart) {
      updatedStart = updatedEnd.minus({ minutes: 30 });
    } else if (timeChanged && updatedEnd <= updatedStart) {
      // If we got into this state by changing the end time, adjust the end date
      updatedEnd = updatedEnd.set(pick(updatedStart, "year", "month", "day")).plus({ day: 1 });
    }

    onChange(updatedStart, updatedEnd);
  };

  const endTimePicker =
    isSameDay && startTime ? (
      <ECEndTimePicker
        value={endTime ?? null}
        onChange={(time) => handleEndChange(endDate, time)}
        startTime={isSameDay && startTime}
        readOnly={readOnly}
        error={timeError}
      />
    ) : (
      <ECTimePicker
        value={endTime ?? null}
        onChange={(time) => handleEndChange(endDate, time)}
        readOnly={readOnly}
        error={timeError}
      />
    );

  return (
    <div className="cw-flex cw-flex-col cw-gap-2 cw-flex-1">
      <div className="cw-flex cw-flex-1 cw-items-center cw-gap-2">
        <DateInput
          aria-label="Start date"
          size="small"
          value={startDate}
          readOnly={readOnly}
          onChange={(date) => date && handleStartChange(date, startTime)}
          error={!!timeError}
        />
        {!isAllDay && (
          <ECTimePicker
            value={startTime ?? null}
            onChange={(time) => handleStartChange(startDate, time)}
            readOnly={readOnly}
            error={timeError}
          />
        )}
      </div>

      <div className="cw-flex cw-flex-1 cw-items-center cw-gap-2">
        <DateInput
          aria-label="End date"
          size="small"
          value={endDate}
          readOnly={readOnly}
          onChange={(date) => date && handleEndChange(date, endTime)}
          error={!!timeError}
        />
        {!isAllDay && endTimePicker}
      </div>

      {timeError && (
        <div className="cw-text-destructive cw-text-13 cw-font-normal cw-text-center">
          {timeError}
        </div>
      )}
    </div>
  );
};
