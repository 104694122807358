import { ApolloCache, useMutation } from "@apollo/client";
import { UpdateNotificationStrategyInput, UpdateScheduleAs } from "@clockwise/schema";
import { isEqual, merge } from "lodash";
import { useCallback, useMemo } from "react";
import {
  UpdateLunchDocument,
  UpdateLunchMutation,
} from "../graphql/__generated__/UpdateLunch.generated";

import { logger } from "@clockwise/client-commons/src/util/logger";
import { LunchSettings } from "../types";

export const useLunchUpdate = (
  orgId: string,
  lunchSettings: LunchSettings,
  {
    onCompleted,
    onError,
    onUpdate,
  }: {
    onCompleted?: () => void;
    onError?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUpdate?: (cache: ApolloCache<any>) => void;
  },
) => {
  const [updateLunch, { loading, error }] = useMutation(UpdateLunchDocument);

  const onErrorHandler = useMemo(() => onError, [onError]);
  const onUpdateHandler = useMemo(() => onUpdate, [onUpdate]);

  const onCompleteHandler = useCallback(
    (expectedData: UpdateLunchMutation) => (data: UpdateLunchMutation) => {
      if (!isEqual(expectedData.UpdateLunch, data.UpdateLunch)) {
        // @scott - cleanup as part of AI-2044
        // logging to sentry to confrim error severtiy
        logger.error("Lunch Hold Update: Optimistic update does not match server response.", {
          expectedData,
          data,
        });

        onError?.();
      } else {
        onCompleted?.();
      }
    },
    [onCompleted, onError],
  );

  const update = useCallback(
    (updatedSettings: Partial<LunchSettings>) => {
      const newSettings = merge(lunchSettings, updatedSettings);

      const variables = {
        orgId: orgId,
        latestEnd: newSettings.latestEnd,
        earliestStart: newSettings.earliestStart,
        idealDuration: newSettings.idealDuration,
        minimumDuration: newSettings.minimumDuration,
        scheduleAs: UpdateScheduleAs[newSettings.scheduleAs],
        notificationStrategy: UpdateNotificationStrategyInput[newSettings.notificationStrategy],
      };

      const optimisticResponse: UpdateLunchMutation = {
        UpdateLunch: {
          lunchGoal: {
            id: newSettings.id,
            enabled: newSettings.enabled,
            preferredLunchTimes: {
              earliestStart: newSettings.earliestStart,
              latestEnd: newSettings.latestEnd,
              minimumDuration: newSettings.minimumDuration.shiftTo("hours", "minutes").toISO(),
              idealDuration: newSettings.idealDuration.shiftTo("hours", "minutes").toISO(),
              __typename: "PreferredLunchTimes",
            },
            remoteHoldSettings: {
              scheduleAs: newSettings.scheduleAs,
              notificationStrategy: newSettings.notificationStrategy,
              __typename: "RemoteSmartHoldSettings",
            },
            __typename: "LunchGoals",
          },
          __typename: "UpdateLunchGoalResponse",
        },
        __typename: "Mutation",
      };

      return updateLunch({
        variables,
        optimisticResponse,
        onCompleted: onCompleteHandler(optimisticResponse),
        onError: onErrorHandler,
        update: onUpdateHandler,
      });
    },
    [lunchSettings, orgId, updateLunch, onCompleteHandler, onErrorHandler, onUpdateHandler],
  );

  return { update, loading, error };
};

export default useLunchUpdate;
