import { DayOfWeek, FlexDetailsInput, FlexRange } from "@clockwise/schema/v2";
import { EventCardFlex } from "../types";

export const toDayOfWeek = (day: string) => {
  switch (day) {
    case "Monday":
      return DayOfWeek.Monday;
    case "Tuesday":
      return DayOfWeek.Tuesday;
    case "Wednesday":
      return DayOfWeek.Wednesday;
    case "Thursday":
      return DayOfWeek.Thursday;
    case "Friday":
      return DayOfWeek.Friday;
    case "Saturday":
      return DayOfWeek.Saturday;
    case "Sunday":
      return DayOfWeek.Sunday;
    default:
      return DayOfWeek.Monday;
  }
};

export const toFlexRange = (rangeFlex: {
  range: "Day" | "Week" | null;
  daysOfWeekFlexibility: string[];
}) => {
  switch (rangeFlex.range) {
    case "Day":
      return FlexRange.Day;
    case "Week":
      return FlexRange.Week;
    default:
      if (rangeFlex.daysOfWeekFlexibility.length > 0) {
        return FlexRange.SpecificDays;
      }
      return FlexRange.Day;
  }
};

export const toDetailsInput = (details: EventCardFlex): FlexDetailsInput => {
  return {
    isFlexible: details.isFlexible,
    flexRange: toFlexRange(details.timeRangeFlexibility),
    allowedDays: details.timeRangeFlexibility.daysOfWeekFlexibility.map(toDayOfWeek),
    timeOfDayRange:
      details.timeOfDayFlexibility.startTime && details.timeOfDayFlexibility.endTime
        ? {
            start: details.timeOfDayFlexibility.startTime,
            end: details.timeOfDayFlexibility.endTime,
          }
        : null,
  };
};

export const toEventCardFlex = (details: FlexDetailsInput): EventCardFlex => {
  return {
    isFlexible: details.isFlexible,
    timeRangeFlexibility: {
      range:
        details.flexRange === FlexRange.SpecificDays
          ? null
          : (details.flexRange as "Day" | "Week" | null),
      daysOfWeekFlexibility: details.allowedDays?.map(toDayOfWeek) ?? [],
    },
    timeOfDayFlexibility: {
      startTime: details.timeOfDayRange?.start ?? null,
      endTime: details.timeOfDayRange?.end ?? null,
    },
  };
};
