import { noop } from "lodash";
import { DateTime } from "luxon";
import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import invariant from "tiny-invariant";
import { isAllDayBasedOnTimes } from "../../web-app-calendar/hooks/useEditExistingEvent.util";
import { EventAttendeesProps, EventAttendeesProvider } from "./EventAttendeesContext";
import {
  EventFlexConstraintsProps,
  EventFlexConstraintsProvider,
} from "./EventFlexConstraintsContext";

export type EventTimeDetailsState = {
  isAllDay: boolean;
  startTime?: DateTime;
  endTime?: DateTime;
};

export type EventTimeDetailsActions = {
  setIsAllDay: (isAllDay: boolean) => void;
  setStartTime: (time: DateTime) => void;
  setEndTime: (time: DateTime) => void;
};

const ReadContext = createContext<
  EventTimeDetailsState & { timeError: string | null; timesHaveChanged: boolean }
>(undefined as never);
const WriteContext = createContext<EventTimeDetailsActions>({
  setIsAllDay: noop,
  setStartTime: noop,
  setEndTime: noop,
});

export const EventTimeDetailsProvider = ({
  children,
  initValue,
}: {
  children: ReactNode;
  initValue: EventTimeDetailsState;
}) => {
  const [startTime, setStartTime] = useState<EventTimeDetailsState["startTime"]>(
    initValue.startTime,
  );
  const [endTime, setEndTime] = useState<EventTimeDetailsState["endTime"]>(initValue.endTime);
  const [isAllDay, setIsAllDay] = useState<EventTimeDetailsState["isAllDay"]>(initValue.isAllDay);

  const isAllDayFromTimes = isAllDayBasedOnTimes(startTime, endTime);

  useEffect(() => {
    setIsAllDay(isAllDayFromTimes);
  }, [isAllDayFromTimes]);

  const timeError = useMemo(() => {
    if (!startTime || !endTime) {
      return null;
    }

    const isSameDay = endTime.hasSame(startTime, "day");
    if (isAllDay && isSameDay) {
      return "All day events must have different start and end dates";
    } else if (endTime <= startTime) {
      return "Start time must be before end time";
    }

    return null;
  }, [startTime, endTime, isAllDay]);

  const timesHaveChanged =
    startTime?.toMillis() !== initValue?.startTime?.toMillis() ||
    endTime?.toMillis() !== initValue?.endTime?.toMillis() ||
    isAllDay !== initValue.isAllDay;

  return (
    <WriteContext.Provider
      value={{
        setIsAllDay,
        setStartTime,
        setEndTime,
      }}
    >
      <ReadContext.Provider value={{ startTime, endTime, isAllDay, timeError, timesHaveChanged }}>
        {children}
      </ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const EventCardContextProvider = ({
  children,
  initValue,
}: {
  children: ReactNode;
  initValue: EventTimeDetailsState & EventAttendeesProps & EventFlexConstraintsProps;
}) => {
  return (
    <EventAttendeesProvider attendees={initValue.attendees}>
      <EventTimeDetailsProvider initValue={initValue}>
        <EventFlexConstraintsProvider
          startTime={initValue.startTime}
          calendarId={initValue.calendarId}
          eventId={initValue.eventId}
        >
          {children}
        </EventFlexConstraintsProvider>
      </EventTimeDetailsProvider>
    </EventAttendeesProvider>
  );
};

// NB: these values don't actually matter, we just need data in context
export const DEFAULT_CONTEXT_VALUES = {
  startTime: DateTime.now(),
  endTime: DateTime.now(),
  isAllDay: false,
  timeError: null,
  attendees: [],
};

export const useReadEventTimeDetails = () => {
  const context = useContext(ReadContext);
  invariant(context, "useReadEventTimeDetails must be within EventTimeDetailsProvider");
  return context;
};

export const useUpdateEventTimeDetails = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdateEventTimeDetails must be within EventTimeDetailsProvider");
  return context;
};
