import React from "react";

export enum ResultsEntryType {
  Input,
  Output,
  Error,
  Default,
}

interface IProps {
  className?: string;
  children: React.ReactNode;
  entryType?: ResultsEntryType;
}

const getTextStyle = (entryType: ResultsEntryType) => {
  switch (entryType) {
    case ResultsEntryType.Input:
      return "cw-text-subtle";
    case ResultsEntryType.Output:
      return "cw-text-default";
    case ResultsEntryType.Error:
      return "cw-text-destructive";
    default:
      return "cw-text-muted";
  }
};

export const ResultsEntry = ({
  className = "",
  children,
  entryType = ResultsEntryType.Default,
}: IProps) => (
  <code className={`${className} ${getTextStyle(entryType)} cw-whitespace-pre-wrap cw-block`}>
    {children}
  </code>
);
