import { Loader } from "@clockwise/design-system/src/components/Loader";
import React from "react";
import { StepLayout } from "../../shared-components";
export const FinishedLoadingStep = () => {
  return (
    <StepLayout>
      <div className="cw-flex cw-flex-col cw-justify-center cw-mt-[150px] cw-h-full">
        <Loader size="xl" sentiment="positive" />
      </div>
    </StepLayout>
  );
};
