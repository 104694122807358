import { useMutation } from "@apollo/client";
import { Button, TextField } from "@clockwise/design-system";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { SudoAddAdminToBillingGroupDocument } from "../graphql/__generated__/SudoAddAdminToBillingGroup.generated";
import { ButtonWrapper } from "./ButtonWrapper";
import { FormWrapper } from "./FormWrapper";

export const AddAdmin = () => {
  const [orgId, setOrgId] = useState<string | undefined>();
  const [adminEmail, setAdminEmail] = useState<string | undefined>();
  const [newAdminEmail, setNewAdminEmail] = useState<string | undefined>();

  const [billingGroupId, setBillingGroupId] = useState<string | undefined>();

  const [addAdminToBillingGroup, { loading }] = useMutation(SudoAddAdminToBillingGroupDocument);

  const resetFields = () => {
    setOrgId("");
    setAdminEmail("");
    setBillingGroupId("");
    setNewAdminEmail("");
  };

  const onAddAdminToBillingGroup = () => {
    if (!orgId || !adminEmail || !billingGroupId || !newAdminEmail) {
      return;
    }
    void addAdminToBillingGroup({
      variables: {
        input: {
          orgId,
          existingAdminEmail: adminEmail,
          billingGroupId,
          adminEmailToAdd: newAdminEmail,
        },
      },
      onCompleted: () => {
        toast.success(newAdminEmail + " added to billing group successfully");
        resetFields();
      },
      onError: (error) => {
        toast.error("Error adding admin to billing group " + error.message);
      },
    });
  };
  const allFieldsPopulated = orgId && adminEmail && billingGroupId && newAdminEmail;

  return (
    <FormWrapper>
      <div className="cw-flex cw-gap-x-2 cw-flex-wrap">
        <TextField
          label="Billing Group ID"
          value={billingGroupId}
          onChange={(e) => setBillingGroupId(e.target.value)}
        />
        <TextField
          label="Existing Admin Email"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
        />

        <TextField label="Org ID" value={orgId} onChange={(e) => setOrgId(e.target.value)} />
      </div>
      <div className="cw-flex">
        <TextField
          label="Admin to Add"
          value={newAdminEmail}
          onChange={(e) => setNewAdminEmail(e.target.value)}
        />
      </div>

      <ButtonWrapper>
        <Button
          size="medium"
          disabled={loading || !allFieldsPopulated}
          sentiment={"positive"}
          fullWidth={false}
          onClick={onAddAdminToBillingGroup}
        >
          Add Admin
        </Button>
      </ButtonWrapper>
    </FormWrapper>
  );
};
