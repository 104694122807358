import { EventLogType } from "@clockwise/schema/v2";
import { ClockwiseLogoCircle } from "@clockwise/web-commons/src/components/svgs/ClockwiseLogoCircle";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import {
  EVENT_HISTORY_DEFAULT_USERNAME,
  Timeline,
  TimelineLogType,
} from "@clockwise/web-commons/src/ui/timeline/timeline";
import { shouldRenderEventAutopilotStatus as shouldRenderEventFlexStatus } from "@clockwise/web-commons/src/util/event-history.util";
import { findLastIndex, isEmpty } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { Header } from "./Header";
import { AugmentedEventLogEntry } from "./History";
import { DefragInfoCard } from "./cards/DefragInfoCard";
import { EventFlexibilityStatusCard } from "./cards/EventFlexibilityStatusCard";
import { EventFlexibilityStatusIcon } from "./icons/EventFlexibilityStatus";
import { getTimelineIcon } from "./icons/getTimelineIcon";
import { Flexibility } from "./types";

const EVENT_LOG_TYPES_TO_SHOW_USER = new Set([
  EventLogType.ClockwiseEventRescheduled,
  EventLogType.FlexTurnedOff,
  EventLogType.FlexTurnedOn,
  EventLogType.EventCreated,
]);

export const HistoryOn = ({
  entries,
  flexibility,
  nextDefrag,
  onGoToBilling,
  onTimelineExpanded,
  onUnpauseEvent,
  onViewPlans,
  showJoinExistingPlan,
}: {
  entries: AugmentedEventLogEntry[];
  flexibility: Flexibility | null;
  nextDefrag: string | null;
  onGoToBilling: () => void;
  onTimelineExpanded: () => void;
  onUnpauseEvent: () => void;
  onViewPlans: () => void;
  showJoinExistingPlan: boolean;
}) => {
  const shouldRenderDefragLog =
    flexibility?.isFlexible && shouldRenderEventFlexStatus(flexibility.status);

  const futureLogs = shouldRenderDefragLog
    ? [
        {
          id: "Flexibility Status",
          description: (
            <EventFlexibilityStatusCard
              flexibility={flexibility}
              nextDefrag={nextDefrag}
              onGoToBilling={onGoToBilling}
              onUnpauseEvent={onUnpauseEvent}
              onViewPlans={onViewPlans}
              showJoinExistingPlan={showJoinExistingPlan}
            />
          ),
          defragInfo: null,
          timestamp: null,
          type: TimelineLogType.FutureLog,
          icon: EventFlexibilityStatusIcon[flexibility.status],
          eventLogType: EventLogType.NextFlexRun,
          eventOriginalStartTime: null,
          eventNewStartTime: null,
          username: EVENT_HISTORY_DEFAULT_USERNAME,
        },
      ]
    : [];

  const lastConflictIndex = findLastIndex(
    entries,
    (entry) => entry.type === EventLogType.ClockwiseCouldNotResolveConflict,
  );
  const historicalLogs = entries.map((entry, index) => {
    const defragInfo = !isEmpty(entry.details) ? (
      <DefragInfoCard
        details={entry.details}
        eventLogType={entry.type}
        userName={entry.displayName}
      />
    ) : null;
    const showWarning = index === lastConflictIndex;

    const eventActionType = ((type: EventLogType) => {
      switch (type) {
        case EventLogType.ClockwiseSecondaryReschedule:
          return "prism";
        default:
          return null;
      }
    })(entry.type);

    const avatarToShow = (type: EventLogType): "user" | "clockwise" | null => {
      if (EVENT_LOG_TYPES_TO_SHOW_USER.has(type)) {
        return "user";
      }
      if (
        type === EventLogType.ClockwiseSecondaryReschedule ||
        type === EventLogType.ClockwiseMeetingMove
      ) {
        return "clockwise";
      }
      return null;
    };

    const { Icon, username } = ((
      type: EventLogType,
      displayName: string,
      externalImageUrl: string | null,
    ) => {
      switch (avatarToShow(type)) {
        case "user":
          // mav todo: figure out how to show clockwise intelligence filled
          let Icon = (
            <AttendeeAvatar
              iconType={eventActionType}
              size="medium"
              hideTooltip
              profile={{ givenName: displayName, externalImageUrl }}
            />
          );
          return {
            Icon,
            username: displayName,
          };
        case "clockwise":
          Icon = <ClockwiseLogoCircle size={20} />;
          return {
            Icon,
            username: EVENT_HISTORY_DEFAULT_USERNAME,
          };
        case null:
          Icon = getTimelineIcon(entry.type, showWarning);
          return {
            Icon,
            username: EVENT_HISTORY_DEFAULT_USERNAME,
          };
      }
    })(entry.type, entry.displayName, entry.externalImageUrl);

    return {
      id: `${entry.type}-${entry.timestamp}`,
      description: entry.title,
      defragInfo,
      timestamp: DateTime.fromISO(entry.timestamp).toMillis(),
      type: TimelineLogType.PastLog,
      icon: Icon,
      eventLogType: entry.type,
      eventOriginalStartTime: entry.eventOriginalStartTime
        ? DateTime.fromISO(entry.eventOriginalStartTime).toMillis()
        : null,
      eventNewStartTime: entry.eventNewStartTime
        ? DateTime.fromISO(entry.eventNewStartTime).toMillis()
        : null,
      username,
    };
  });

  const timelineLogs = [...historicalLogs, ...futureLogs];

  return (
    <>
      <Header />
      <div className="cw-pt-2">
        <Timeline
          timelineLogs={timelineLogs}
          isMissingHistory={false}
          trackTimelineExpanded={onTimelineExpanded}
        />
      </div>
    </>
  );
};
