import { EventDetailsDocument } from "#webapp/src/components/event-card/__generated__/EventDetails.v2.generated";
import { Divider } from "@clockwise/design-system";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import React from "react";
import { Title } from "../atoms/Title";
import { useEventPopoverDetails } from "../hooks/useEventPopoverDetails";
import { Footer } from "../molecules/Footer";
import { EditButton, ExpandButton, Header } from "../molecules/Header";
import { EditSettingsLink } from "./EditSettingsLink";

export const PersonalSyncMenu = ({
  isOwnEvent,
  calendarId,
  eventId,
  onClose,
  onOpenCard,
  canExpand = true,
}: {
  calendarId: string;
  eventId: string;
  isOwnEvent: boolean;
  onClose: () => void;
  onOpenCard: () => void;
  canExpand?: boolean;
}) =>
  isOwnEvent ? (
    <PersonalSyncMenuOwn
      calendarId={calendarId}
      eventId={eventId}
      onClose={onClose}
      onOpenCard={onOpenCard}
      canExpand={canExpand}
    />
  ) : (
    <PersonalSyncMenuOthers
      calendarId={calendarId}
      eventId={eventId}
      onClose={onClose}
      onOpenCard={onOpenCard}
      canExpand={canExpand}
    />
  );

const PersonalSyncMenuOwn = ({
  calendarId,
  eventId,
  onClose,
  onOpenCard,
  canExpand = true,
}: {
  calendarId: string;
  eventId: string;
  onClose: () => void;
  onOpenCard: () => void;
  canExpand?: boolean;
}) => {
  const { data, loading } = useGatewayQuery(EventDetailsDocument, {
    variables: {
      id: eventId,
      calendarId,
    },
  });

  const title = data?.event?.syncedFromEvent?.title ?? "(No title)";

  return (
    <div className="cw-flex-col cw-space-y-1">
      <Header onClose={onClose}>{canExpand && <EditButton onClick={onOpenCard} />}</Header>
      <div className="cw-px-2">
        <Title
          loading={loading}
          title={title}
          subTitle="Synced personal event"
          onClick={onOpenCard}
        />
        <Divider spacing="xs" />
        <div className="cw-px-2">
          Only you can see this event's title and details. Coworkers with access to your calendar
          will see an event titled "Busy (via Clockwise)".
        </div>
        <Divider spacing="xs" />
        <EditSettingsLink />
      </div>
    </div>
  );
};

const PersonalSyncMenuOthers = ({
  calendarId,
  eventId,
  onClose,
  onOpenCard,
  canExpand = true,
}: {
  calendarId: string;
  eventId: string;
  onClose: () => void;
  onOpenCard: () => void;
  canExpand?: boolean;
}) => {
  const { ownerDisplayName } = useEventPopoverDetails({ externalEventId: eventId, calendarId });

  return (
    <div className="cw-flex-col cw-space-y-1">
      <Header onClose={onClose}>{canExpand && <ExpandButton onClick={onOpenCard} />}</Header>
      <div className="cw-px-2">
        <Title title="Busy" subTitle="Synced personal event" onClick={onOpenCard} />
        <Divider spacing="sm" />
        <div className="cw-px-2">
          Clockwise automatically synced this event from a personal calendar.
        </div>
      </div>
      <Footer
        loading={false}
        canRSVP={false}
        showEventOwner={true}
        ownerDisplayName={ownerDisplayName}
        calendarId={calendarId}
      />
    </div>
  );
};
