import { difference, isEmpty } from "lodash";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarIds,
  addCalendarIds,
  addDiffIdWithCalendarIds,
  addEventIdWithCalendarIds,
  removeDiffIds,
  removeEventIds,
} from "../../../../../../state/actions/multi-calendar.actions";
import { IReduxState } from "../../../../../../state/reducers/root.reducer";
import { calculateIfTooManyCalendarsToShow } from "../../../components/multi-calendar/calendar-layers.util";

export const useHandleEditsToAttendees = ({
  eventId,
  diffId,
}: {
  eventId?: string;
  diffId?: string;
}) => {
  const dispatch = useDispatch();
  const calendarIdsToFullyShow = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsToFullyShow,
  );

  const handler = useCallback(
    ({
      newAttendeeCalendarIds,
      currentAttendeeCalendarIds,
    }: {
      newAttendeeCalendarIds: CalendarIds;
      currentAttendeeCalendarIds: CalendarIds;
    }) => {
      const tooManyCalendarsToShow = calculateIfTooManyCalendarsToShow(
        newAttendeeCalendarIds,
        calendarIdsToFullyShow,
      );

      const calendarIdsToRemove = currentAttendeeCalendarIds.filter(
        (calendarId) => !newAttendeeCalendarIds.includes(calendarId),
      );
      const isRemovingAttendees = !isEmpty(calendarIdsToRemove);

      if (tooManyCalendarsToShow) {
        // In this case, we want to keep the individual attendee calendars on but turn off the diff
        // This requires a very specific sequence of actions
        if (diffId) {
          if (isRemovingAttendees) {
            dispatch(addDiffIdWithCalendarIds(diffId, newAttendeeCalendarIds));

            // This is a hack to make sure that the `calendarIdsToFullyShow` is updated
            // and that the `selectedDiffIds` is updated
            dispatch(addCalendarIds(difference(calendarIdsToFullyShow, calendarIdsToRemove)));
          } else {
            dispatch(removeDiffIds([diffId]));
            dispatch(addDiffIdWithCalendarIds(diffId, newAttendeeCalendarIds));
            dispatch(addCalendarIds(calendarIdsToFullyShow));
          }
        } else if (eventId) {
          if (isRemovingAttendees) {
            dispatch(addEventIdWithCalendarIds(eventId, newAttendeeCalendarIds));

            // This is a hack to make sure that the `calendarIdsToFullyShow` is updated
            // and that the `selectedDiffIds` is updated
            dispatch(addCalendarIds(difference(calendarIdsToFullyShow, calendarIdsToRemove)));
          } else {
            dispatch(removeEventIds([eventId]));
            dispatch(addEventIdWithCalendarIds(eventId, newAttendeeCalendarIds));
            dispatch(addCalendarIds(calendarIdsToFullyShow));
          }
        }
      } else {
        // When there's not too many calendar we can just add the edits
        if (diffId) {
          dispatch(addDiffIdWithCalendarIds(diffId, newAttendeeCalendarIds));
        } else if (eventId) {
          dispatch(addEventIdWithCalendarIds(eventId, newAttendeeCalendarIds));
        }
      }
    },
    [eventId, diffId, calendarIdsToFullyShow],
  );

  return [handler];
};
