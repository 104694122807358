import { DateTime } from "luxon";
import { DISPLAY_DATE_FORMAT, DISPLAY_DATE_FORMAT_WITH_YEAR, Timeframes } from "../consts";
import getTimeframeDates from "./getTimeframeDates";

const getTimeframeDescription = (timeframe: Timeframes, prepend: string) => {
  const { minDate, maxDate } = getTimeframeDates(timeframe);
  const toFormattedDate = (d: Date) =>
    DateTime.fromJSDate(d).toFormat(
      timeframe === "year" ? DISPLAY_DATE_FORMAT_WITH_YEAR : DISPLAY_DATE_FORMAT,
    );
  const formmatedMinDate = toFormattedDate(minDate);
  const formmatedMaxDate = toFormattedDate(maxDate);

  return `${prepend} (${formmatedMinDate} - ${formmatedMaxDate})`;
};

export default getTimeframeDescription;
