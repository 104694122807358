import { Tooltip } from "@clockwise/design-system";
import { Info } from "@clockwise/icons";
import React from "react";

export const MeetingHoursMessage = () => {
  return (
    <div className="cw-text-muted cw-text-[12px] cw-flex cw-flex-row cw-items-center cw-gap-1">
      <div>Attendees meeting hours</div>
      <Tooltip title="Clockwise will never move events outside attendees' meeting hours.">
        <Info className="cw-w-3.5 cw-h-3.5" />
      </Tooltip>
    </div>
  );
};
