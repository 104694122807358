import { EventOperations } from "../types";
export const getOperationText = (operation: EventOperations, success: boolean) => {
  let operationText;
  switch (operation) {
    case "ADD":
      operationText = success ? "created" : "not created";
      break;
    case "RESCHEDULE":
      operationText = success ? "rescheduled" : "not rescheduled";
      break;
    case "EDIT_DETAILS":
      operationText = success ? "saved" : "not saved";
      break;
    case "CANCEL":
      operationText = success ? "deleted" : "not deleted";
      break;
    case "RSVP":
      operationText = success ? "RSVP updated" : "RSVP not updated";
      break;
    case "DEFRAG":
      operationText = success ? "Optimizations fetched" : "No optimizations found";
      break;
    case "FLEX":
      operationText = success ? "flexibility updated" : "flexibility not updated";
      break;
    default:
      operationText = success ? "updated" : "not updated";
      break;
  }
  return operationText;
};
