import { Select, SelectOption } from "@clockwise/design-system";
import { ScheduleAs } from "@clockwise/schema";
import { Visibility } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";

type Props = {
  enabled: boolean;
  value: ScheduleAs;
  onChange: (newValue: ScheduleAs) => void;
};

const ScheduleAsOption = ({ enabled, value, onChange }: Props) => {
  return (
    <div className="cw-flex cw-flex-row cw-items-center">
      <Visibility
        fontSize="small"
        className={classNames(enabled ? "" : "cw-text-default-disabled")}
      />
      <Select onChange={onChange} size="small" value={value} variant="inline" disabled={!enabled}>
        <SelectOption value={ScheduleAs.Free}>Show as "Free" to other people</SelectOption>
        <SelectOption value={ScheduleAs.Busy}>Show as "Busy" to other people</SelectOption>
      </Select>
    </div>
  );
};

export default ScheduleAsOption;
