import { CSSProperties } from "@material-ui/core/styles/withStyles";

// styles
import { greys } from "@clockwise/web-commons/src/styles/color.styles";
import spacing from "@clockwise/web-commons/src/styles/spacing.styles";

const container: CSSProperties = {
  textAlign: "center",
  minWidth: 928,
  maxWidth: 928,
  marginTop: 72,
  marginBottom: 24,
};

const slackContainer: CSSProperties = {
  maxWidth: 715,
  margin: `${spacing.xxlg}px auto 0`,
};

const successIcon: CSSProperties = {
  fontSize: 80,
  marginBottom: spacing.xxlg,
};

const slackText: CSSProperties = {
  color: greys.slate,
};

const textWithDivider: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: 50,
  "& hr": {
    flex: 1,
    borderStyle: "solid",
    borderWidthTop: 1,
    borderColor: greys.cloudyBlue,
  },
  "& hr:nth-child(1)": {
    marginRight: 40,
  },
  "& hr:nth-child(3)": {
    marginLeft: 40,
  },
  "& div": {
    color: greys.slate,
    fontSize: 16,
  },
};

const cards: CSSProperties = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginTop: 30,

  "& > div": {
    flex: 1,
    maxWidth: 500,
    display: "flex",
    flexDirection: "column",
    backgroundColor: greys.white,
    boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.1)",
    textAlign: "left",
    borderRadius: 5,
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
    },
    "& > div": {
      padding: 20,
      cursor: "pointer",
      userSelect: "none",
    },
    "& > img": {
      backgroundColor: "#ECF5FE",
      flex: 1,
      width: "calc(100% - 16px)",
      margin: 8,
    },
  },
  "& > div:nth-child(n + 2)": {
    marginLeft: spacing.xxlg,
  },
};

const cardH6: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const cardBody: CSSProperties = {
  marginTop: 8,
  fontSize: 13,
  lineHeight: "24px",
  color: greys.slate,
};

export const styles = {
  cards,
  cardH6,
  cardBody,
  container,
  slackContainer,
  successIcon,
  slackText,
  textWithDivider,
};
