import { Menu, MenuItem } from "@clockwise/design-system";
import React from "react";

export type EventVisibility = "default" | "public" | "private";

type Props = {
  disabled?: boolean;
  selected?: EventVisibility;
  onSelect?: (visibility: EventVisibility) => void;
};

const getLabel = (visibility: EventVisibility) => {
  switch (visibility) {
    case "default":
      return "Default visibility";
    case "public":
      return "Public";
    case "private":
      return "Private";
  }
};

export const SelectEventVisibility = ({
  disabled = false,
  selected = "default",
  onSelect,
}: Props) => {
  return (
    <Menu disabled={disabled} fullWidth size="small" label={getLabel(selected)}>
      <MenuItem onClick={() => onSelect?.("default")}>Default visibility</MenuItem>
      <MenuItem onClick={() => onSelect?.("public")}>Public</MenuItem>
      <MenuItem onClick={() => onSelect?.("private")}>Private</MenuItem>
    </Menu>
  );
};
