import { Link } from "@clockwise/design-system";
import pluralize from "pluralize";
import React from "react";
import useFocusTimeStats from "./hooks/useFocusTimeStats";

const Tooltip = () => {
  const { focusTimeStats } = useFocusTimeStats();
  const usualHours = Math.floor(focusTimeStats.minutesPerWeek / 60);

  let usualHoursText = "";

  if (usualHours <= 0) {
    usualHoursText = "";
  } else {
    usualHoursText = `You usually get ${usualHours} ${pluralize("hour", usualHours)} per week.`;
  }

  return (
    <>
      Focus Time is defined as two or more hours of uninterrupted time. Clockwise can protect the
      Focus Time you have, and move flexible meetings to make more, up to your goal.{" "}
      {usualHoursText}{" "}
      <Link onEmphasis href="https://getclockwise.helpscoutdocs.com/article/189-focus-time">
        Learn more
      </Link>
    </>
  );
};

export default Tooltip;
