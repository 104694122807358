import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import Highcharts, { AxisTickPositionsArray, Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { greys } from "../../../../styles/color.styles";
import { ChartTooltipProps, TimeSeriesProps } from "../chart/chart";
import {
  PELLEGRINO,
  highChartsConfig,
  placeholderData,
  placeholderTickPositions,
} from "../chart/chart.constants";
import { constructDatePointTooltip } from "../chart/chart.util";
import { formatOrgDataForTimeSeries } from "../overview.util";

export interface AreaSplineProps {
  series: TimeSeriesProps;
  tooltipFormat?: ChartTooltipProps;
  step: "day" | "week" | "month";
}

export const AreaSpline = ({ series, tooltipFormat, step }: AreaSplineProps) => {
  const withoutData = series.data.length === 0;
  const tickPositions = withoutData ? placeholderTickPositions : undefined;
  const data = withoutData ? placeholderData : formatOrgDataForTimeSeries(series.data);

  const options: Options = {
    ...highChartsConfig,
    tooltip: {
      useHTML: true,
      formatter: function (this) {
        return constructDatePointTooltip(
          this.point.x,
          this.point.y || 0,
          this.series.name,
          PELLEGRINO,
          tooltipFormat?.valueSuffix,
        );
      },
    },
    plotOptions: {
      areaspline: {
        lineColor: PELLEGRINO,
      },
      series: {
        marker: {
          fillColor: PELLEGRINO,
          lineColor: greys.white,
          lineWidth: 1,
        },
      },
    },
    series: [{ ...series, data, color: PELLEGRINO, type: "areaspline" }],
    chart: { ...highChartsConfig.chart, type: "areaspline" },
    xAxis: {
      ...highChartsConfig.xAxis,
      tickPositioner: function (this) {
        if (step !== "month") {
          const tickDates: AxisTickPositionsArray = data.map(([date]) => date);
          tickDates.info = this.tickPositions?.info;
          return tickDates;
        } else {
          return this.tickPositions as AxisTickPositionsArray;
        }
      },
      labels: {
        step: step === "week" ? 6 : 0,
        formatter: function (this) {
          if (step === "month") {
            return new ZonedMoment(this.value).clone().format("MMM 'YY");
          }
          return new ZonedMoment(this.value).clone().format("MMM D");
        },
      },
    },
    yAxis: {
      ...highChartsConfig.yAxis,
      tickPositions,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
