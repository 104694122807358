import { useReadProposalOptionsOverlay } from "#webapp/src/components/chat-plus-calendar/util/ProposalOptionsOverlayContext";
import { getIconForTradeoffType } from "#webapp/src/components/event-card/utils/getStylesForTradeoffType";
import { usePlannerContext } from "#webapp/src/components/web-app-calendar/Context";
import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import {
  DiffActionTypeEnum,
  ResponseStatusEnum,
  SchedulingProposalStateEnum,
  TradeoffTypeEnum,
} from "@clockwise/schema";
import { useReadTimeSuggestion } from "@clockwise/web-commons/src/util/TimeSuggestionContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { isEmpty } from "lodash";
import { Interval } from "luxon";
import pluralize from "pluralize";
import React, { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  clearAllSelected as clearAllSelectedCalendarsInMultiCalendar,
  removeDiffIdWithCalendarIds,
  removeDiffIds,
} from "../../../../../state/actions/multi-calendar.actions";
import { useUpdateActiveEventDiff } from "../../../../chat-plus-calendar/util/ActiveDiffContext";
import { useUpdateDismissedConflictClusters } from "../../../../chat-plus-calendar/util/DismissedConflictClusterContext";
import { useUpdateHoverEvent } from "../../../../chat-plus-calendar/util/HoverEventContext";
import { EventCardCollapsed, SubDrawerContent } from "../../../../event-card/EventCardCollapsed";
import { LowVizInconvenienceTooltip } from "../../../../event-card/atoms/LowVizInconvenienceTooltip";
import { EventCardAttendee } from "../../../../event-card/types";
import { ProposalRenderSurface } from "../../../constants";
import { useActiveProposal } from "../../../hooks/useActiveProposal";
import { toastStyles } from "../../../util/toastStyles";
import { useSetAIError } from "../../hooks/AIErrorContext";
import { useAIMessageContext } from "../../hooks/AIMessageContext";
import { useUpdateDefaultExpandedTradeoff } from "../../hooks/ExpandedTradeoffContext";
import { useCancelChatProposal } from "../../hooks/useCancelChatProposal";
import { useOnSplitView } from "../../hooks/useOnSplitView";
import { useRemoveDiffFromProposal } from "../../hooks/useRemoveDiffFromProposal";
import { getHasMultipleDiffs } from "../../utils/getHasMultipleDiffs";
import { getHighestPriorityTradeoffTypeFromTradeoffBlocks } from "../../utils/getHighestPriorityTradeoffTypeFromTradeoffBlocks";
import {
  DiffSummaryTradeoffBlocks,
  GQLProposalAmbiguity,
  GQLSchedulingOptions,
} from "../../utils/types";
import { useDiffLayersToggle } from "../multi-calendar/useDiffLayersToggle";
import { SchedulingPills } from "../proposal/scheduling-pills";
import { Ambiguities } from "./Ambiguities";
import { AssuranceProps, Assurances } from "./Assurances";
import { Tradeoffs } from "./Tradeoffs";
import { calculateStartingToggleIsOn } from "./calculateStartingToggleIsOn";
import { getIconFromAction } from "./getIconFromAction";
import { useSetCalendarIdsForCalendarPeek } from "./useSetCalendarIdsForCalendarPeek";

export type DiffAction = {
  type: DiffActionTypeEnum;
  label: string;
  rsvp?: ResponseStatusEnum | null;
};

export const DiffEventCardCollapsed = ({
  action,
  ambiguities,
  assurances,
  attendeePeople,
  avatar = null,
  id,
  interval,
  isOnlyDiffInProposal = false,
  isSBM,
  recurrenceRule,
  schedulingOptions,
  title,
  tradeoffBlocks,
  totalNumberOfAttendeesOnAllDiffs = 0,
  isFlexible,
  variant = "AI_CHAT",
  viewerIsProposalOwner = true,
  isConsequenceBlock = false,
}: {
  action: DiffAction;
  ambiguities: GQLProposalAmbiguity[];
  assurances?: AssuranceProps | null;
  attendeePeople?: EventCardAttendee[];
  avatar?: React.ReactNode;
  id: string;
  interval?: Interval;
  isOnlyDiffInProposal?: boolean;
  isSBM: boolean;
  schedulingOptions?: GQLSchedulingOptions | null;
  title: string;
  tradeoffBlocks?: DiffSummaryTradeoffBlocks;
  recurrenceRule?: RecurrenceRule | null;
  totalNumberOfAttendeesOnAllDiffs?: number;
  isFlexible?: boolean;
  variant?: ProposalRenderSurface;
  viewerIsProposalOwner?: boolean;
  isConsequenceBlock?: boolean;
}) => {
  const hasAmbiguities = !isEmpty(ambiguities);
  const updateActiveDiff = useUpdateActiveEventDiff();
  const updateHoverEvent = useUpdateHoverEvent();
  const { processingMessage } = useAIMessageContext();
  const { proposal, loading: proposalLoading } = useActiveProposal();
  const selectedTimeSuggestionState = useReadTimeSuggestion();
  const { refetchEvents } = usePlannerContext();
  const dispatch = useDispatch();
  const setError = useSetAIError();
  const updateDefaultExpandedTradeoff = useUpdateDefaultExpandedTradeoff();
  const track = useTracking();
  const setDismissedConflicts = useUpdateDismissedConflictClusters();
  const isOnSplitView = useOnSplitView();

  const propoalOptionsOverlayVisible = useReadProposalOptionsOverlay();

  const startingIsToggledOn = useMemo(
    () =>
      calculateStartingToggleIsOn(
        isSBM,
        isOnSplitView,
        action.type,
        totalNumberOfAttendeesOnAllDiffs,
        isConsequenceBlock,
      ),
    [isSBM, isOnSplitView, action.type, totalNumberOfAttendeesOnAllDiffs, isConsequenceBlock],
  );

  const isBulkSchedulingProposal = getHasMultipleDiffs(proposal ? proposal?.diffBlocks : []);
  useSetCalendarIdsForCalendarPeek(proposal?.consequencesBlock);

  const {
    toggle: toggleLayers,
    active: isLayersActive,
    reasonForDisabled: toggleLayersTooltip,
    disabled: isLayersDisabled,
  } = useDiffLayersToggle({
    diffId: id,
    startingIsToggledOn,
    focusedToggle: false,
  });

  useEffect(() => {
    if (tradeoffBlocks) {
      const highestPriorityTradeoffType = getHighestPriorityTradeoffTypeFromTradeoffBlocks(
        tradeoffBlocks,
      );
      updateDefaultExpandedTradeoff(highestPriorityTradeoffType);
    }
  }, [tradeoffBlocks]);

  const filteredTradeoffs = tradeoffBlocks?.filter(
    (tradeoff) =>
      tradeoff.tradeoffType !== TradeoffTypeEnum.INCONVENIENCE &&
      tradeoff.tradeoffType !== TradeoffTypeEnum.FIXABLE_CONFLICT &&
      tradeoff.tradeoffType !== TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE,
  );
  const hasTradeoffs = filteredTradeoffs && !isEmpty(filteredTradeoffs);
  const hasAssurances = assurances && !isEmpty(assurances);

  const [onRemoveDiff, { loading: removeDiffLoading }] = useRemoveDiffFromProposal({
    onCompleted: () => {
      dispatch(removeDiffIds([id])); // This turns off the calendar layer for the diff
      // This removes the diff from the list of possible diffs in the multi-calendar reducer
      // that way it doesn't get accidentally added back
      dispatch(removeDiffIdWithCalendarIds(id));

      toast.success("Proposal updated", {
        position: "bottom-center",
        style: toastStyles,
      });
    },
    onError: (error) => {
      setError({
        error,
        message: "Failed to remove diff from proposal",
        userMessage: "Failed to update proposal",
        showUserMessage: true,
      });
    },
  });

  const isAConflictProposal = proposal?.conflictClusters?.length || proposal?.conflictReceipt;
  const [onCancelChatProposal, { loading: cancelLoading }] = useCancelChatProposal({
    onError: (error) => {
      setError({ error: error, message: "Failed to cancel proposal", showUserMessage: true });
    },
    refetchQueries: ["ChatHistoryQuery", "MentionsSearchEvents"],
    onCompleted: () => {
      if (isAConflictProposal) {
        setDismissedConflicts([]);
      }
      void refetchEvents?.();
    },
  });

  const handleExpand = () => {
    if (processingMessage) return;

    updateActiveDiff({ id });
    updateHoverEvent(null);
  };

  const handleCancel = () => {
    track(TrackingEvents.CHAT.PROPOSAL.CANCEL);
    dispatch(clearAllSelectedCalendarsInMultiCalendar());
    void onCancelChatProposal();
  };

  const handleRemove = () => {
    void onRemoveDiff({ input: { diffId: id } });
  };

  const handleHover = (isHovering: boolean) => {
    const hoverId = isHovering ? id : null;
    // Only update if the proposal overlay is not visible
    if (!propoalOptionsOverlayVisible) {
      updateHoverEvent(hoverId);
    } else {
      updateHoverEvent(null);
    }
  };

  const showLineThrough =
    action.type === DiffActionTypeEnum.CANCEL ||
    (action.type === DiffActionTypeEnum.RSVP && action.rsvp === ResponseStatusEnum.Declined);

  const isAddDiffWithoutSchedulingOptions =
    action.type === DiffActionTypeEnum.ADD && !schedulingOptions;
  const isSpecificUpdateAction = [
    DiffActionTypeEnum.CANCEL,
    DiffActionTypeEnum.RESCHEDULE,
    DiffActionTypeEnum.RSVP,
    DiffActionTypeEnum.UNCHANGED,
    DiffActionTypeEnum.PROPOSE_NEW_TIME,
  ].includes(action.type);
  const subText = isSpecificUpdateAction || isAddDiffWithoutSchedulingOptions ? action.label : null;
  const subTextIcon = getIconFromAction({ action: action.type }) || undefined;

  const subDrawerContent: SubDrawerContent = [];
  let schedulingOptionsContent: React.ReactNode = null;
  if (hasAmbiguities) {
    subDrawerContent.push({
      muted: false,
      content: <Ambiguities ambiguities={ambiguities} diffId={id} />,
    });
  }

  if (!isBulkSchedulingProposal) {
    const inconvenienceCount =
      tradeoffBlocks?.filter(
        (t) =>
          t.tradeoffType === TradeoffTypeEnum.INCONVENIENCE ||
          t.tradeoffType === TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE,
      ).length ?? 0;
    if (inconvenienceCount > 0) {
      const sad = getIconForTradeoffType(TradeoffTypeEnum.INCONVENIENCE);
      subDrawerContent.push({
        muted: true,
        content: (
          <div className="cw-flex cw-items-center cw-gap-1">
            {sad}
            <span className="cw-text-busy">
              {pluralize("inconvenience", inconvenienceCount, true)} for attendees
            </span>
            <LowVizInconvenienceTooltip />
          </div>
        ),
      });
    }
  }

  const renderSchedulingOptions =
    !isEmpty(schedulingOptions) && (variant === "AI_CHAT" || viewerIsProposalOwner);
  if (renderSchedulingOptions) {
    const { state } = proposal ?? {};
    const disableSelectOption =
      (!!state && state !== SchedulingProposalStateEnum.ACTIVE) ||
      !!selectedTimeSuggestionState?.loading ||
      proposalLoading;

    if (!isBulkSchedulingProposal) {
      schedulingOptionsContent = (
        <SchedulingPills
          options={schedulingOptions.optionDetails}
          selectedIndex={schedulingOptions.selectedIndex}
          disabled={disableSelectOption}
          variant={variant}
          totalAttendeeCount={attendeePeople?.length}
        />
      );
    } else {
      subDrawerContent.push({
        muted: false,
        content: (
          <SchedulingPills
            options={schedulingOptions.optionDetails}
            selectedIndex={schedulingOptions.selectedIndex}
            disabled={disableSelectOption}
            variant={variant}
            totalAttendeeCount={attendeePeople?.length}
          />
        ),
      });
    }
  }

  if (hasTradeoffs) {
    subDrawerContent.push({
      muted: true,
      content: <Tradeoffs tradeoffBlocks={filteredTradeoffs} onClick={handleExpand} />,
    });
  }

  if (hasAssurances) {
    subDrawerContent.push({ muted: true, content: <Assurances {...assurances} /> });
  }

  let onRemoveHandler: (() => void) | undefined = undefined;
  if (isOnlyDiffInProposal) {
    onRemoveHandler = handleCancel;
  } else {
    onRemoveHandler = handleRemove;
  }

  return (
    <div
      className="cw-flex cw-gap-2 cw-items-start"
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      {avatar && <div className="cw-mt-1.5 cw-grow-0">{avatar}</div>}
      <div className="cw-grow cw-min-w-0">
        <EventCardCollapsed
          attendeePeople={attendeePeople}
          disabled={processingMessage || removeDiffLoading || cancelLoading}
          eventName={title}
          interval={interval}
          isLayersActive={isLayersActive}
          isLayersDisabled={isLayersDisabled}
          isRemoveCancel={isOnlyDiffInProposal}
          lineThrough={showLineThrough}
          onExpandCard={handleExpand}
          onRemoveCard={onRemoveHandler}
          onToggleLayers={toggleLayers}
          recurrenceRule={recurrenceRule}
          schedulingOptions={schedulingOptionsContent}
          subDrawerContent={subDrawerContent}
          subText={subText}
          subTextIcon={schedulingOptions ? undefined : subTextIcon}
          toggleLayersTooltip={toggleLayersTooltip || undefined}
          isAddDiff={action.type === DiffActionTypeEnum.ADD}
          isFlexible={isFlexible}
          variant={variant}
          diffId={id}
        />
      </div>
    </div>
  );
};
