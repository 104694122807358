import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateEventResponseMutationVariables = Types.Exact<{
  externalEventId: Types.Scalars['ID'];
  responseStatus: Types.ResponseStatusEnum;
}>;


export type UpdateEventResponseMutation = { __typename: 'Mutation', updateEventResponse: { __typename: 'Event', id: string, responseStatus: Types.ResponseStatusEnum, attendees: Array<{ __typename: 'Attendee', id: string, email: string, responseStatus: Types.ResponseStatusEnum | null, isOrganizer: boolean | null, isOptional: boolean | null }> } | null };


export const UpdateEventResponseDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateEventResponse"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"responseStatus"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResponseStatusEnum"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateEventResponse"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"externalEventId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"responseStatus"},"value":{"kind":"Variable","name":{"kind":"Name","value":"responseStatus"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"responseStatus"}},{"kind":"Field","name":{"kind":"Name","value":"attendees"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"responseStatus"}},{"kind":"Field","name":{"kind":"Name","value":"isOrganizer"}},{"kind":"Field","name":{"kind":"Name","value":"isOptional"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateEventResponseMutation, UpdateEventResponseMutationVariables>;