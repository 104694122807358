import { TradeoffTypeEnum } from "@clockwise/schema";
import { noop } from "lodash";
import React, { ReactNode, createContext, useContext, useState } from "react";
import invariant from "tiny-invariant";

export type DefaultExpandedTradeoffType = TradeoffTypeEnum;

const ReadContext = createContext<DefaultExpandedTradeoffType | null>(null);
const WriteContext = createContext<
  (DefaultExpandedTradeoff: DefaultExpandedTradeoffType | null) => void
>(noop);

type ProviderProps = {
  children: ReactNode;
  initValue: DefaultExpandedTradeoffType | null;
};

export const DefaultExpandedTradeoffProvider = ({ children, initValue = null }: ProviderProps) => {
  const [
    defaultExpandedTradeoff,
    setDefaultExpandedTradeoff,
  ] = useState<DefaultExpandedTradeoffType | null>(initValue);

  return (
    <WriteContext.Provider value={setDefaultExpandedTradeoff}>
      <ReadContext.Provider value={defaultExpandedTradeoff}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadDefaultExpandedTradeoff = () => useContext(ReadContext);
export const useUpdateDefaultExpandedTradeoff = () => {
  const context = React.useContext(WriteContext);
  invariant(
    context,
    "useUpdateDefaultExpandedTradeoff must be within DefaultExpandedTradeoffProvider",
  );
  return context;
};
