import * as React from "react";

import { Mask, MaskProps } from "@clockwise/web-commons/src/ui/calendar/mask";

import classNames from "classnames";
import { DateTime } from "luxon";
import usePlannerPosition from "./hooks/usePlannerPosition";

const PlannerMask = ({
  type,
  start,
  end,
  text,
}: {
  type: MaskProps["variant"];
  start: DateTime;
  end: DateTime;
  text?: string;
}) => {
  const { style } = usePlannerPosition(start, end, "mask");

  return (
    <Mask
      className={classNames("cw-absolute")}
      variant={type}
      style={{
        ...style,
        pointerEvents: "none",
      }}
      text={text}
    />
  );
};

export default PlannerMask;
