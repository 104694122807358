import React from "react";
import calendarPopover from "./assets/calendarPopover.png";
import flexPopover from "./assets/flexPopover.png";
import linksPopover from "./assets/linksPopover.png";
import modalBackground from "./assets/modalBackground.png";

export const NavImagePreloader = () => {
  // Preload the images early to prevent the user waiting for them later on
  return (
    <>
      <link rel="preload" as="image" href={flexPopover} />
      <link rel="preload" as="image" href={linksPopover} />
      <link rel="preload" as="image" href={calendarPopover} />
      <link rel="preload" as="image" href={modalBackground} />
    </>
  );
};
