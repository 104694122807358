import { Slider } from "@clockwise/design-system";
import React from "react";
import { DefaultWeights, PreferencesWeights } from "./constants";

export const PreferencesTuner = ({
  values,
  onChange,
}: {
  values: PreferencesWeights;
  onChange: (defaultsKey: keyof typeof DefaultWeights, value: number) => void;
}) => {
  const {
    focusTime,
    meetingConflict,
    outsideWorkingHours,
    preferredWorkingHours,
    travelTime,
    eventChangeDisruption,
    meetingRelief,
    offGridPenalty,
    noMeetingDay,
    commonlyDislikedTimes,
    outsideMeetingHours,
    lunchConflict,
  } = values;
  return (
    <div className="cw-flex cw-flex-col cw-gap-4 cw-p-4 cw-bg-default cw-rounded-md cw-border cw-border-solid cw-border-muted">
      <SliderComponent
        label="Focus Time"
        value={focusTime}
        onChange={onChange}
        defaultsKey="focusTime"
      />
      <SliderComponent
        label="Meeting Conflict"
        value={meetingConflict}
        onChange={onChange}
        defaultsKey="meetingConflict"
      />
      <SliderComponent
        label="Outside Working Hours"
        value={outsideWorkingHours}
        onChange={onChange}
        defaultsKey="outsideWorkingHours"
      />
      <SliderComponent
        label="Preferred Working Hours"
        value={preferredWorkingHours}
        onChange={onChange}
        defaultsKey="preferredWorkingHours"
      />
      <SliderComponent
        label="Travel time"
        value={travelTime}
        onChange={onChange}
        defaultsKey="travelTime"
      />
      <SliderComponent
        label="Event Change Disruption"
        value={eventChangeDisruption}
        onChange={onChange}
        defaultsKey="eventChangeDisruption"
      />
      <SliderComponent
        label="Meeting Relief"
        value={meetingRelief}
        onChange={onChange}
        defaultsKey="meetingRelief"
      />
      <SliderComponent
        label="Off Grid Penalty"
        value={offGridPenalty}
        onChange={onChange}
        defaultsKey="offGridPenalty"
      />
      <SliderComponent
        label="No Meeting Day"
        value={noMeetingDay}
        onChange={onChange}
        defaultsKey="noMeetingDay"
      />
      <SliderComponent
        label="Commonly Disliked Times"
        value={commonlyDislikedTimes}
        onChange={onChange}
        defaultsKey="commonlyDislikedTimes"
      />
      <SliderComponent
        label="Outside Meeting Hours"
        value={outsideMeetingHours}
        onChange={onChange}
        defaultsKey="outsideMeetingHours"
      />
      <SliderComponent
        label="Lunch Conflict"
        value={lunchConflict}
        onChange={onChange}
        defaultsKey="lunchConflict"
      />
    </div>
  );
};

const Label = ({ children }: { children: React.ReactNode }) => {
  return <div className="cw-text-13 cw-font-semibold cw-p-1">{children}</div>;
};
const SliderComponent = ({
  label,
  value,
  onChange,
  defaultsKey,
}: {
  label: string;
  value: number;
  onChange: (defaultsKey: string, value: number) => void;
  defaultsKey: string;
}) => {
  const handleChange = (value: number) => {
    onChange(defaultsKey, value);
  };

  return (
    <div className="cw-flex cw-flex-col cw-gap-0.5">
      <div className="cw-flex cw-flex-row cw-justify-between">
        <div className="cw-text-12"> {label}</div>
        <div className="cw-text-12 cw-font-semibold"> {value}</div>
      </div>
      <Slider
        size="sm"
        value={value}
        label={(num: number) => <Label> {num} </Label>}
        onChange={handleChange}
        restrictToMarks
        step={0.05}
        min={0}
        max={10}
        styles={{ markLabel: { display: "none" } }}
        marks={[
          { value: 0, label: "0" },
          { value: 0.1, label: "0.1" },
          { value: 0.25, label: "0.25" },
          { value: 0.5, label: "0.5" },
          { value: 1, label: "1" },
          { value: 2, label: "2" },
          { value: 4, label: "4" },
          { value: 10, label: "10" },
        ]}
      />
    </div>
  );
};
