import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFeatureFlag } from "../../launch-darkly";
import useIsWebExtension from "../../util/useIsWebExtension";
// This component assumes that the Beacon snippet as been added to the page in moden.ejs,
// making the Beacon global variable available to use.
declare global {
  interface Window {
    Beacon: (action: string, params?: unknown) => void;
    HubSpotConversations: {
      widget: { status: () => { loaded: boolean; pending: boolean }; close: () => void };
    };
  }
}

// This list keeps track of pages where GTM uses the Hubspot chat widget and help prevents the Beacon widget
// from conflicting. Note though that if the Hubspot widget is loaded and online, the Beacon widget will be hidden.
const pagesWithHubSpotWidget = ["/app/plans-and-billing"];
// Only show the Beacon widget on these pages
const allowedPages = [
  "/app/your-ideal-day",
  "/app/flexibility",
  "/app/flexibility#holds",
  "/app/scheduling-links",
  "/app/integrations",
  "/app/team/",
  "/app/team-settings",
  "/app/analytics",
  "/app/users",
  "/app/plans-and-billing",
  "/app/organization-settings",
  "/app/welcome",
];

export const BEACON_SCRIPT_ID = "beacon-snippet";
export const BEACON_ID = "cf915ae6-de09-4973-a4e9-0f68428b834f";

// Function to escape special characters for regex
const escapeRegExp = (str: string): string => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const isAllowedPath = (path: string, allowedPages: string[]): boolean => {
  const regexes = allowedPages.map((allowedPath) => {
    if (allowedPath.endsWith("/")) {
      return new RegExp(`^${escapeRegExp(allowedPath)}[^/]*$`);
    } else {
      return new RegExp(`^${escapeRegExp(allowedPath)}$`);
    }
  });

  return regexes.some((regex) => regex.test(path));
};

export const HelpScoutBeacon = () => {
  const Beacon = window.Beacon;
  const location = useLocation();
  const [shouldShowBeacon] = useFeatureFlag("EnableHelpScoutBeacon");
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(true);
  const isLocalDevelopment = getEnvironment() === "development";
  const scriptLoadedCallback = () => {
    setIsScriptLoaded(true);
  };
  const isAllowedPage = isAllowedPath(location.pathname, allowedPages);

  const isExtension = useIsWebExtension();

  useEffect(() => {
    if (isLocalDevelopment || isExtension) {
      return;
    }
    if (!Beacon || !shouldShowBeacon || !isAllowedPage) {
      if (Beacon) {
        Beacon("destroy");
      } else {
        const script = document.querySelector(`script#${BEACON_SCRIPT_ID}`);
        script?.addEventListener("load", scriptLoadedCallback);
        setIsScriptLoaded(false);
      }
      return;
    }
    if (isScriptLoaded) {
      // If the HubSpot widget is loaded but not shown, we want to show the Beacon widget
      let isHubSpotWidgetLoaded = false;
      let isHubSpotWidgetPending = false;
      const isPageWithHubSpotWidget = pagesWithHubSpotWidget.includes(location.pathname);
      // Check on the status of the HubSpot chat widget
      if (window?.HubSpotConversations?.widget?.status()) {
        const { loaded, pending } = window.HubSpotConversations.widget.status();
        isHubSpotWidgetLoaded = loaded;
        isHubSpotWidgetPending = pending;
      }

      // Show the Beacon widget
      if (!isPageWithHubSpotWidget) {
        // Show the Beacon widget if the Hubspot widget is not loaded
        Beacon("init", BEACON_ID);
        // Fixes a bug where the Hubspot widget keeps showing on pages it is not supposed to
        if (isHubSpotWidgetLoaded) {
          window?.HubSpotConversations?.widget?.close();
        }
      } else if (isPageWithHubSpotWidget && !isHubSpotWidgetLoaded && !isHubSpotWidgetPending) {
        // Show the Beacon widget if the Hubspot widget is not loaded
        Beacon("init", BEACON_ID);
      } else {
        Beacon("destroy");
      }
    }
  }, [
    Beacon,
    location.pathname,
    shouldShowBeacon,
    isScriptLoaded,
    isAllowedPage,
    isLocalDevelopment,
    isExtension,
  ]);

  return null;
};
