const urlRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
const goLinkRegex = /go\/[\w-]+\|/;
const emailRegex = /[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}/;

export const hasSupportedUrl = (token: string) => {
  const isGoLinkMatch = goLinkRegex.test(token);
  const isUrlMatch = urlRegex.test(token);
  return isGoLinkMatch || isUrlMatch;
};

export const getSupportedLink = (token: string) => {
  let linkText = "",
    href = "";
  const goLinkMatch = token.match(goLinkRegex);
  if (goLinkMatch) {
    linkText = goLinkMatch[0].split("|")[0];
  }
  const hrefMatch = token.match(urlRegex);
  if (hrefMatch) {
    href = hrefMatch[0];
    if (!goLinkMatch) {
      linkText = href;
    }
  }
  const emailMatch = token.match(emailRegex);
  if (emailMatch) {
    href = `mailto:${emailMatch[0]}`;
    linkText = emailMatch[0];
  }
  return { linkText, href };
};

export const getSupportedUrlWithMailto = (token: string) => {
  let linkText = "",
    href = "";
  const goLinkMatch = token.match(goLinkRegex);
  if (goLinkMatch) {
    linkText = goLinkMatch[0].split("|")[0];
  }
  const hrefMatch = token.match(urlRegex);
  if (hrefMatch) {
    href = hrefMatch[0];
    if (!goLinkMatch) {
      linkText = href;
    }
  }

  return { linkText, href };
};
