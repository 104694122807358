import { Divider } from "@clockwise/design-system";
import { ChevronRight, Close, Event } from "@clockwise/icons";
import { ConflictEvent } from "@clockwise/schema";
import React from "react";
import { Header } from "./atoms/Header";
import { ListItemButton } from "./atoms/ListItemButton";

export const ConflictEventsList = ({
  onClickOfEvent,
  events,
  onDismissConflict,
}: {
  onClickOfEvent: (eventId: string) => void;
  events: ConflictEvent[];
  onDismissConflict: () => void;
}) => {
  return (
    <>
      <Header title="Conflict found" />
      <div className="cw-px-2">
        <ListItemButton>
          <span className="cw-text-subtle cw-font-semibold">Choose an event to modify</span>
        </ListItemButton>
        {events.map((event) => {
          return (
            <ListItemButton
              key={event.id}
              startIcon={Event}
              onClick={() => {
                onClickOfEvent(event.id);
              }}
              endIcon={ChevronRight}
            >
              {event.title}
            </ListItemButton>
          );
        })}
        <Divider spacing={0} />
        <ListItemButton startIcon={Close} onClick={onDismissConflict}>
          Dismiss
        </ListItemButton>
      </div>
    </>
  );
};
