import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { ChromeLogo } from "@clockwise/web-commons/src/components/svgs/ChromeLogo";
import { FirefoxLogo } from "@clockwise/web-commons/src/components/svgs/FirefoxLogo";
import { ExtensionURLs } from "@clockwise/web-commons/src/constants/stores.constant";
import classNames from "classnames";
import { noop } from "lodash";
import React from "react";
import { CustomView } from "react-device-detect";

type BrowserName = "Chrome" | "Firefox";

interface IProps {
  browserName: "Chrome" | "Firefox";
  size?: "medium" | "large";
  onClick?: () => void;
}

const extensionTerminologyByBrowser: Record<BrowserName, string> = {
  Chrome: "extension",
  Firefox: "plugin",
};

export const sendTrackingWebApp = (
  propertyName: keyof typeof TrackingEvents.FUNNELED_TO_DOWNLOAD_EXTENSION,
  userId: string | null,
) => {
  return track(TrackingEvents.FUNNELED_TO_DOWNLOAD_EXTENSION[propertyName], { userId });
};

export const ExtensionDownloadButton = ({
  browserName,
  size = "large",
  onClick = noop,
}: IProps) => {
  const isChrome = browserName === "Chrome";
  const isFirefox = browserName === "Firefox";
  let extensionLink = "";

  if (isChrome) {
    extensionLink = ExtensionURLs.chrome;
  } else if (isFirefox) {
    extensionLink = ExtensionURLs.firefox;
  }

  const iconSize = size === "large" ? 32 : 24;
  return (
    <a
      className={classNames(
        "cw-pl-2 cw-pr-4 cw-py-2",
        "cw-body-xl cw-font-bold cw-text-accent cw-no-underline",
        "cw-bg-default hover:cw-bg-default-hover active:cw-bg-default-pressed",
        "cw-rounded-full cw-border-positive-emphasis cw-border-[1px] cw-border-solid",
        "cw-drop-shadow-[2px_2px_0px_rgba(0,63,46)] hover:cw-drop-shadow-[3px_3px_0px_rgba(0,63,46)]",
        "cw-flex cw-items-center cw-justify-evenly",
        size === "large" ? "cw-body-xl" : "cw-body-lg",
      )}
      href={extensionLink}
      onClick={onClick}
      target={`${isChrome ? "Chrome" : "Firefox"} store`}
    >
      <CustomView condition={isChrome}>
        <ChromeLogo size={iconSize} className="cw-align-bottom cw-mr-2" />
      </CustomView>
      <CustomView condition={isFirefox}>
        <FirefoxLogo size={iconSize} className="cw-align-bottom cw-mr-2" />
      </CustomView>
      <div>{`Download the ${browserName} ${extensionTerminologyByBrowser[browserName]}`}</div>
    </a>
  );
};
