import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { compact, debounce, uniqWith } from "lodash";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { setError } from "../../utils/setError";
import { TeamMember } from "./TeamJoinCreateStep.util";
import {
  TeamJoinSlideDocument,
  TeamJoinSlideQuery,
} from "./__generated__/TeamJoinCreateStep.generated";

type UseSearchPersonsChatResult = {
  setQueryForTeams: Dispatch<SetStateAction<string>>;
  loadingTeams: boolean;
  onChangeDebounced: (teamName: string) => void;
  listOfTeams: TeamResult[];
  getTeamMembers: (team: TeamResult) => TeamMember[];
};

type TeamsResultErrorable = Exclude<TeamJoinSlideQuery["viewer"], null>["teamQueryResultErrorable"];
type TeamResult = Extract<TeamsResultErrorable, { __typename: "TeamQueryResult" }>["teams"][0] & {
  isSuggested?: boolean;
};

export const useTeamSearch = ({ orgId }: { orgId: string }): UseSearchPersonsChatResult => {
  const [queryForTeams, setQueryForTeams] = useState<string>("");
  const [listOfTeams, setListOfTeams] = useState<TeamResult[]>([]);

  const { data: teamsListData, loading: loadingTeams } = useQuery(TeamJoinSlideDocument, {
    variables: { orgId, query: queryForTeams },
    onError: (error) => {
      setError({
        error: error,
        message: "failed to fetch existing team information",
        showUserMessage: false,
      });
    },
  });

  const onChangeDebounced = useCallback(
    debounce((teamName: string) => {
      setQueryForTeams(teamName.trim());
    }, 250),
    [],
  );

  // here we prioritize the suggested teams for the user, and then add the rest of the teams,
  // but if the user is already searching a new team, just return the search results
  useEffect(() => {
    const teamsList = getValue(teamsListData?.viewer?.teamQueryResultErrorable)?.teams || [];
    const org = getValue(teamsListData?.viewer?.user?.orgs)?.edges?.[0]?.node;
    const suggestedTeamsForUser = getValue(org?.suggestedTeams)?.list || [];
    const formattedSuggestedTeams = suggestedTeamsForUser.map((team) => {
      return {
        ...team.team,
        isSuggested: true,
      };
    });

    if (formattedSuggestedTeams.length && !queryForTeams) {
      if (formattedSuggestedTeams.length < 4) {
        const newArray = uniqWith(
          [...formattedSuggestedTeams, ...teamsList],
          (a, b) => a.teamId === b.teamId,
        );
        setListOfTeams(newArray);
      } else {
        setListOfTeams([...formattedSuggestedTeams]);
      }
    } else if (!loadingTeams) {
      setListOfTeams(teamsList);
    }
  }, [teamsListData, queryForTeams, loadingTeams]);

  const getTeamMembers = useCallback(
    (team: TeamResult) => {
      const teamMembersData =
        getValue(teamsListData?.viewer?.teamQueryResultErrorable)?.teamMembers || [];
      return compact(
        team.teamRoleAndPersonIds.map((member) => {
          return teamMembersData.find(
            (teamMember) =>
              teamMember?.person?.personId === member.personId && !!teamMember.person.profile,
          )?.person;
        }),
      );
    },
    [teamsListData],
  );

  return useMemo(() => {
    return { setQueryForTeams, loadingTeams, listOfTeams, onChangeDebounced, getTeamMembers };
  }, [setQueryForTeams, loadingTeams, listOfTeams, onChangeDebounced, getTeamMembers]);
};
