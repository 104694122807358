import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MeetingReliefGoalSettingsFragmentDoc } from './MeetingReliefGoals.generated';
export type ToggleMeetingReliefMutationVariables = Types.Exact<{
  orgId: Types.Scalars['ID'];
  enabled: Types.Scalars['Boolean'];
}>;


export type ToggleMeetingReliefMutation = { __typename: 'Mutation', ToggleMeetingRelief: { __typename: 'ToggleMeetingReliefResponse', meetingReliefGoal: { __typename: 'MeetingReliefGoals', id: string, enabled: boolean | null, meetingReliefThreshold: { __typename: 'MeetingReliefThreshold', fatigueThreshold: any, breakDuration: any } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null } } | null };


export const ToggleMeetingReliefDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ToggleMeetingRelief"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"ToggleMeetingRelief"},"name":{"kind":"Name","value":"toggleMeetingRelief"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"orgId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}},{"kind":"Argument","name":{"kind":"Name","value":"enabled"},"value":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meetingReliefGoal"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MeetingReliefGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MeetingReliefGoalSettings"}}]}}]}}]}}]}},...MeetingReliefGoalSettingsFragmentDoc.definitions]} as unknown as DocumentNode<ToggleMeetingReliefMutation, ToggleMeetingReliefMutationVariables>;