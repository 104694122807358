import { TimeSlot } from "@clockwise/schema";
import { DateTime } from "luxon";

const ISO_TIME_FORMAT = "HH:mm";
const TIME_SLOT_FORMAT = "'T'_HH_mm";
const DISPLAY_FORMAT = "h:mm a";

/**
 * Converts a TimeSlot to a time string in ISO 8601 formatted time (e.g., TimeSlot.T_17_30 => "17:30").
 */
export const convertSlotToTime = (slot: TimeSlot, isEnd: boolean): string => {
  const dt = DateTime.fromFormat(slot, TIME_SLOT_FORMAT);
  if (isEnd) {
    const formattedTime = dt
      // Time slots are in 30 minute increments, so add 30 minutes to get the end time
      .plus({ minutes: 30 })
      .toFormat(ISO_TIME_FORMAT);
    return formattedTime === "00:00" ? "24:00" : formattedTime;
  } else {
    return dt.toFormat(ISO_TIME_FORMAT);
  }
};

export const convertTimeToSlot = (time: string, isEnd: boolean): TimeSlot => {
  let dt = DateTime.fromFormat(time, ISO_TIME_FORMAT);
  if (!dt.isValid) {
    throw new Error(`Invalid time: ${time}. Could not parse.`);
  }
  if (isEnd) {
    dt = dt.minus({ minutes: 30 });
  }
  if (dt.minute !== 0 && dt.minute !== 30) {
    throw new Error(`Invalid time: ${time}. TimeSlots must be on the hour or half hour.`);
  }
  return dt.toFormat(TIME_SLOT_FORMAT) as TimeSlot;
};

export const formatTime = (time: string): string => {
  const dt = DateTime.fromFormat(time, ISO_TIME_FORMAT);
  if (!dt.isValid) {
    throw new Error(`Invalid time: ${time}. Could not parse.`);
  }

  return dt.toFormat(DISPLAY_FORMAT);
};
