import { WebappSurface } from "@clockwise/schema";
import classNames from "classnames";
import React, { ReactNode, useCallback, useRef } from "react";
import { getLocationHashValue, shouldHighlightSurface } from "./ui-focus.utils";

type WebappTargetArea =
  | "lunch"
  | "travel"
  | "email"
  | "sync"
  | "test"
  | "zoom"
  | "slack"
  | "colorCoding";
type FocusSection = WebappSurface | WebappTargetArea;

export function useScrollIntoView(section: FocusSection) {
  const ref = useRef<HTMLDivElement>();
  const focusSectionParams = getLocationHashValue();

  const setRef = useCallback(
    (node: HTMLDivElement) => {
      if (node && focusSectionParams.toLowerCase() === section.toLowerCase()) {
        node.scrollIntoView({ behavior: "smooth", block: "center" });
      }

      // Save a reference to the node
      ref.current = node;
    },
    [focusSectionParams, section],
  );

  return [setRef];
}

export const ScrollIntoView = ({
  children,
  focusSection,
  highlight,
}: {
  children: ReactNode;
  focusSection: FocusSection;
  highlight?: "once" | "infinite" | boolean;
}) => {
  const [focusSectionWrapperRef] = useScrollIntoView(focusSection);
  const doHighlight = highlight && shouldHighlightSurface(focusSection);

  let highlightClass;
  switch (highlight) {
    case true:
    case "once":
      highlightClass = "cw-animate-serene-spotlight";
      break;
    case "infinite":
      highlightClass = "cw-animate-serene-spotlight-infinite";
      break;
    default:
      highlightClass = "";
      break;
  }

  return (
    <div>
      {/** extra div allows rounded highlight without rounding in container */}
      <div
        ref={focusSectionWrapperRef}
        id={focusSection}
        className={classNames({ [highlightClass]: doHighlight })}
      >
        {children}
      </div>
    </div>
  );
};
