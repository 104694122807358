import { TradeoffType } from "@clockwise/schema/v2";
import { ComponentProps } from "react";
import { TradeoffBlock } from "./TradeoffBlock";

export type ETradeoffBlock = {
  tradeoffs: ComponentProps<typeof TradeoffBlock>["tradeoffs"];
  tradeoffType: TradeoffType;
};

const tradeoffTypeOrder: Record<TradeoffType, number> = {
  [TradeoffType.AvailabilityIssue]: 1,
  [TradeoffType.FixableConflict]: 2,
  [TradeoffType.Inconvenience]: 3,
  [TradeoffType.LowVisibilityInconvenience]: 4,
  [TradeoffType.OutOfTimeRange]: 5,
};

export function getTradeoffBlocksToDisplay<T extends ETradeoffBlock>(blocks: T[]) {
  return (
    blocks
      // remove any OutOfTimeRange tradeoffs since these are specific time proposals
      // remove any LowViz tradeoffs since we don't have a way to handle them
      .filter(
        ({ tradeoffType }) =>
          tradeoffType !== TradeoffType.OutOfTimeRange &&
          tradeoffType !== TradeoffType.LowVisibilityInconvenience,
      )
      .sort((t1, t2) => tradeoffTypeOrder[t1.tradeoffType] - tradeoffTypeOrder[t2.tradeoffType])
  );
}
