import classNames from "classnames";
import React from "react";

type TileProps = {
  className?: string;
  alignment?: "center" | "start";
};

export const Tile = ({
  children,
  className = "",
  alignment = "center",
}: React.PropsWithChildren<TileProps>) => {
  return (
    <div
      className={classNames(
        className,
        // TODO: (nikita)  replace HEX colors with DS tokens
        "cw-bg-[#054F3E] cw-body-base cw-text-[#00A972] cw-font-bold cw-rounded-md cw-flex",
        {
          "cw-items-center cw-justify-center": alignment === "center",
          "cw-items-start cw-p-3": alignment === "start",
        },
      )}
      role="presentation"
    >
      {children}
    </div>
  );
};
