// schema
// import * as ISchema from 'src/__schema__';

// libraries
import * as React from "react";
import { createFragmentContainer } from "react-relay";

// sudo-trigger-client-update imports
import { styles as sudoStyles } from "#webapp/src/components/sudo-wrapper/SudoWrapper.styles";
import { sudoTriggerClientUpdate } from "#webapp/src/mutations";
import { sudoTriggerClientUpdateFragments } from "./SudoTriggerClientUpdate.gql";
import { styles } from "./SudoTriggerClientUpdate.styles";
import { IContainer, IProps, IState } from "./SudoTriggerClientUpdateTypes";

// state

// util
import { ZonedMoment } from "@clockwise/web-commons/src/util/time-zone.util";

// material-ui
import { Button, LinearProgress, Select, SelectOption } from "@clockwise/design-system";
import { Paper } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import toast from "react-hot-toast";

///////////////
// Component //
///////////////
class SudoTriggerClientUpdateCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      triggerType: "idle",
      loading: false,
    };
  }

  // trigger handler
  private handleTrigger = () => {
    const confirm = window.confirm("Are you sure?");
    if (!confirm) {
      return;
    }

    const force = this.state.triggerType === "force";
    this.setState({ loading: true });
    sudoTriggerClientUpdate(
      this.props.relay.environment,
      { force },
      () => this.onTriggerSuccess(),
      () => this.onTriggerFailure(),
    );
  };

  private onTriggerSuccess() {
    this.setState({ loading: false, triggerType: "idle" });
    toast.success("Success! Triggered a client update.");
  }

  private onTriggerFailure() {
    this.setState({ loading: false });
    toast.error("Failed to trigger a client update. ");
  }

  public render() {
    const { chromeAppUpdateTimes } = this.props.sudo;
    const idleMoment = new ZonedMoment(chromeAppUpdateTimes.idleTime).format(
      "dddd, MM/DD/YYYY @ h:mm:ss a",
    );
    const forceMoment = new ZonedMoment(chromeAppUpdateTimes.forceTime).format(
      "dddd, MM/DD/YYYY @ h:mm:ss a",
    );

    return (
      <Paper
        elevation={2}
        className="cw-font-body cw-p-5 cw-flex cw-flex-grow cw-flex-col cw-relative cw-gap-4"
      >
        <div style={sudoStyles.title}>Trigger Client Update</div>
        <div style={sudoStyles.instructions}>
          This form will cause all chrome users to refetch the latest code.
          <div style={{ paddingTop: 10 }}>Last Idle Update: {idleMoment}</div>
          <div>Last Force Update: {forceMoment}</div>
        </div>
        <div style={sudoStyles.row}>
          <Select
            value={this.state.triggerType}
            onChange={(triggerType) => this.setState({ triggerType })}
            size="large"
          >
            <SelectOption value="idle">Update when idle</SelectOption>
            <SelectOption value="force">Force immediate update</SelectOption>
          </Select>
        </div>
        <div style={{ ...sudoStyles.row, flexDirection: "row", alignItems: "center" }}>
          <Button
            sentiment="positive"
            onClick={() => this.handleTrigger()}
            disabled={this.state.loading}
          >
            Trigger client update
          </Button>
        </div>
        {this.state.loading ? <LinearProgress /> : null}
      </Paper>
    );
  }
}

export const SudoTriggerClientUpdateStyled = withStyles(styles)(SudoTriggerClientUpdateCmpt);

export const SudoTriggerClientUpdate = createFragmentContainer<IContainer>(
  SudoTriggerClientUpdateStyled,
  sudoTriggerClientUpdateFragments,
);
