import { Skeleton } from "@material-ui/lab";
import React from "react";

export const LinksDashboardLoadingCard = () => (
  <div
    className="cw-box-border cw-px-4 cw-py-3 cw-rounded-lg cw-shadow-md cw-border-solid cw-border-2 cw-border-neutral cw-bg-neutral cw-animate-pulse cw-gap-1 cw-w-full cw-h-[178px] cw-flex cw-flex-col"
    role="progressbar"
    aria-label="Loading a Link"
  >
    <div>
      <Skeleton
        variant="text"
        animation="wave"
        className="cw-rounded-md cw-mb-[-4px]"
        width={85}
        height={40}
      />
      <Skeleton variant="text" animation="wave" className="cw-rounded-md" width={190} height={55} />
    </div>
    <div className="cw-inline-flex cw-justify-start cw-align-bottom cw-gap-2">
      <Skeleton variant="text" animation="wave" className="cw-rounded-md" width={120} height={63} />
      <Skeleton variant="text" animation="wave" className="cw-rounded-md" width={120} height={63} />
    </div>
  </div>
);
