import { Button } from "@clockwise/design-system";
import React from "react";
import { Card } from "./Card";

export const Paused = ({ onUnpauseEvent }: { onUnpauseEvent: () => void }) => {
  return (
    <Card
      title={"This event won't move"}
      warning
      action={
        <Button variant="outlined" size="small" onClick={onUnpauseEvent}>
          Let it move again
        </Button>
      }
    >
      This event was manually rescheduled to a specific time. Clockwise will not move it.
    </Card>
  );
};
