/**
 * @generated SignedSource<<58fbaa6b3137d4cb39a1464ec61d32c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EcosystemEnum = "Google" | "Microsoft" | "NotApplicable" | "%future added value";
export type UserStatusEnum = "Active" | "Preview" | "Restricted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SudoModifyUser_viewer$data = {
  readonly id: string;
  readonly sudo: {
    readonly modifyUser: {
      readonly ecosystem: EcosystemEnum;
      readonly user: {
        readonly id: string;
        readonly givenName: string | null;
        readonly familyName: string | null;
        readonly emails: ReadonlyArray<string>;
        readonly status: UserStatusEnum | null;
        readonly externalImageUrl: string | null;
        readonly flowStates: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly flowKey: string | null;
              readonly current: {
                readonly id: string;
                readonly state: string | null;
                readonly percentComplete: number | null;
                readonly errorMessage: string | null;
              } | null;
              readonly pending: {
                readonly id: string;
                readonly state: string | null;
                readonly percentComplete: number | null;
                readonly errorMessage: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly orgs: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly name: string | null;
              readonly myBillingGroups: {
                readonly primaryBillingGroup: {
                  readonly id: string;
                } | null;
                readonly allGroups: ReadonlyArray<{
                  readonly id: string;
                  readonly name: string;
                }>;
              } | null;
              readonly flowStates: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly id: string;
                    readonly flowKey: string | null;
                    readonly current: {
                      readonly id: string;
                      readonly state: string | null;
                      readonly percentComplete: number | null;
                      readonly errorMessage: string | null;
                    } | null;
                    readonly pending: {
                      readonly id: string;
                      readonly state: string | null;
                      readonly percentComplete: number | null;
                      readonly errorMessage: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null;
          } | null> | null;
        };
      } | null;
      readonly userTeams: {
        readonly __typename: "TeamList";
        readonly list: ReadonlyArray<{
          readonly teamId: string;
          readonly teamName: string;
        }>;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null;
      readonly featureStatesJson: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "SudoModifyUser_viewer";
};
export type SudoModifyUser_viewer$key = {
  readonly " $data"?: SudoModifyUser_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SudoModifyUser_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "state",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentComplete",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "errorMessage",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": "FlowStateConnection",
  "kind": "LinkedField",
  "name": "flowStates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FlowStateEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FlowState",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "flowKey",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FlowStatus",
              "kind": "LinkedField",
              "name": "current",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FlowStatus",
              "kind": "LinkedField",
              "name": "pending",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "flowStates(first:1000000)"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SudoModifyUser_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Sudo",
      "kind": "LinkedField",
      "name": "sudo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserAndExtendedInfo",
          "kind": "LinkedField",
          "name": "modifyUser",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ecosystem",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "givenName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "familyName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emails",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "externalImageUrl",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": (v1/*: any*/),
                  "concreteType": "OrgConnection",
                  "kind": "LinkedField",
                  "name": "orgs",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrgEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Org",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v4/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "MyBillingGroups",
                              "kind": "LinkedField",
                              "name": "myBillingGroups",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "BillingGroup",
                                  "kind": "LinkedField",
                                  "name": "primaryBillingGroup",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "BillingGroup",
                                  "kind": "LinkedField",
                                  "name": "allGroups",
                                  "plural": true,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v4/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "orgs(first:1000000)"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "userTeams",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Team",
                      "kind": "LinkedField",
                      "name": "list",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "teamId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "teamName",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "TeamList",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "featureStatesJson",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "c28537e2982b72570ebb54c88d56775c";

export default node;
