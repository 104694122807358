import { Menu, MenuGroup, MenuItem } from "@clockwise/design-system";
import { BugReportFilled } from "@clockwise/icons";
import React, { useEffect } from "react";
import { DebugModel, useAIMessageContext } from "../ai-chat/hooks/AIMessageContext";

const MapGptModelToLabel: Record<DebugModel, string> = {
  default: "ClockwiseLLM",
};

const DebugModelDropdown = () => {
  const {
    debugModel,
    setDebugModel,
    pollAndRefetchOnFocus,
    setPollAndRefetchOnFocus,
  } = useAIMessageContext();

  useEffect(() => {
    if (!(debugModel in MapGptModelToLabel)) {
      setDebugModel("default");
    }
  }, []);

  return (
    <div title="Debug: Select which model to use to process message.">
      <Menu
        label={<BugReportFilled className="cw-text-busy cw-w-4 cw-h-4" />}
        variant="default"
        size="small"
        showExpandIcon={false}
      >
        <MenuGroup>
          <MenuItem checked={debugModel === "default"} onClick={() => setDebugModel("default")}>
            {MapGptModelToLabel["default"]}
          </MenuItem>
        </MenuGroup>
        <MenuGroup label="Chat settings">
          <MenuItem
            checked={pollAndRefetchOnFocus}
            onClick={() => setPollAndRefetchOnFocus(!pollAndRefetchOnFocus)}
          >
            Poll and refetch on focus
          </MenuItem>
        </MenuGroup>
      </Menu>
    </div>
  );
};

export default DebugModelDropdown;
