import { page, PageEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { ApolloQueryResult } from "@apollo/client";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { Exact } from "@clockwise/schema/v2";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { Title } from "@clockwise/web-commons/src/ui/admin-panel/title";
import { compact } from "lodash";
import React from "react";
import { OrgSettings } from "../org-settings/OrgSettings";
import { WebSettingsContainer } from "../web-settings/web-settings-container";
import {
  OrganizationSettingsDocument,
  OrganizationSettingsQuery,
} from "./__generated__/OrganizationSettings.v2.generated";

export type RefetchOrgAccount = (
  variables?:
    | Partial<
        Exact<{
          [key: string]: never;
        }>
      >
    | undefined,
) => Promise<ApolloQueryResult<OrganizationSettingsQuery>>;

export const OrganizationSettings = () => {
  React.useEffect(() => {
    page(PageEvents.WEB_SETTINGS_ORGANIZATION_SETTINGS);
  }, []);

  const { data, error, refetch } = useGatewayQuery(OrganizationSettingsDocument);

  console.log("NAME", data?.currentOrg?.name);

  if (error) {
    logger.error(`failed to load WebSettingsOrganizationSettings: ${error}`);
    return (
      <WebSettingsContainer>
        An error occurred while loading Clockwise:
        <br />
        {error.message}
      </WebSettingsContainer>
    );
  }

  if (!data?.currentOrg || !data?.currentUser) {
    return <Loader size="xl" sentiment="positive" className="cw-mt-auto cw-mb-auto" />;
  }

  const persons = compact(data?.currentOrg?.members).map((member) => {
    return {
      email: member.person.email,
      displayName: member.person.displayName,
      externalImageUrl: member.person.externalImageUrl,
      familyName: member.person.familyName,
      givenName: member.person.givenName,
    };
  });

  return (
    <WebSettingsContainer>
      <Title>Organization Settings</Title>
      <OrgSettings
        userId={data.currentUser.id}
        persons={persons}
        orgName={data.currentOrg.name}
        refetchOrgAccount={refetch}
        zoomAccount={data?.currentOrg.zoomAccount}
      />
    </WebSettingsContainer>
  );
};
