import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { EventColorCategory } from "@clockwise/client-commons/src/util/event-category-coloring";
import { createTagForMutation, Tags } from "@clockwise/client-commons/src/util/event-tag";
import { isImpersonated } from "@clockwise/client-commons/src/util/jwt";
import { Divider } from "@clockwise/design-system";
import { ConferencingType, RepeatingEventSaveOption, ResponseStatusEnum } from "@clockwise/schema";
import {
  EventId,
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { generateRescheduleCQL } from "@clockwise/web-commons/src/util/generateCQL";
import { jwt } from "@clockwise/web-commons/src/util/local-storage";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { cloneDeep, isEqual } from "lodash";
import { DateTime, Interval } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useBoolean } from "usehooks-ts";
import { useUserConfrencingTypes } from "../../hooks/useUserConfrencingTypes";
import { clearAllSelected as clearAllSelectedCalendarsInMultiCalendar } from "../../state/actions/multi-calendar.actions";
import { logger } from "../../util/logger.util";
import { useResetEditsToAttendees as useResetEditsToAttendeesForMultiCalendar } from "../chat/ai-chat/hooks/multicalendar/event-card-edit/useResetEditsToAttendees";
import { useSetOriginalAttendeesBeforeEdit as useSetOriginalAttendeesBeforeEditForMultiCalendar } from "../chat/ai-chat/hooks/multicalendar/event-card-edit/useSetOriginalAttendeesBeforeEdit";
import { useFocusChatTextInput } from "../chat/ai-chat/hooks/useFocusChatTextInput";
import { useProcessMessage } from "../chat/ai-chat/hooks/useProcessMessage";
import { shouldUpdateThisAndFutureEvents } from "../chat/ai-chat/utils/getRecurrenceToSave";
import useOrgDomains from "../hooks/useOrgDomains";
import { usePlannerContext } from "../web-app-calendar/Context";
import useEditExistingEvent from "../web-app-calendar/hooks/useEditExistingEvent";
import {
  formatAttendeesToSaveToEvent,
  formatFlexUpdates,
  formatSparkleInTitle,
  formatVCToSave,
  getBodyForSaveChanges,
  getDisabledSaveOptions,
} from "../web-app-calendar/hooks/useEditExistingEvent.util";
import { CategoryOption, EventDetails } from "../web-app-calendar/hooks/useEventDetails";
import { eventToast } from "../web-app-calendar/notifiation-event/EventToast";
import { CollapsibleSection } from "./atoms/CollapsibleSection";
import { WarningMessage } from "./atoms/WarningMessage";
import { CardWrapper } from "./CardWrapper";
import { useEventAttendees } from "./hooks/EventAttendeesContext";
import { useReadEventTimeDetails } from "./hooks/EventCardContext";
import { ECAgenda } from "./molecules/ECAgenda";
import { AllDay } from "./molecules/ECAllDay";
import { ECAttendeeWrapper } from "./molecules/ECAttendeesV2/ECAttendeeWrapper";
import { ECCalendarInfo } from "./molecules/ECCalendarInfo";
import { ECCategorySelector } from "./molecules/ECCategorySelect";
import { ECFlexibility } from "./molecules/ECFlexibility";
import { ECFooter } from "./molecules/ECFooter";
import { ECHistory } from "./molecules/ECHistory";
import { ECLocation } from "./molecules/ECLocation";
import { ECRecurrence } from "./molecules/ECRecurrence";
import { ECResponseStatus } from "./molecules/ECResponseStatus";
import { ECHeadTab, ECTabGroup, ECTabList, ECTabPanel, ECTabPanels } from "./molecules/ECTabs";
import { ECTitle } from "./molecules/ECTitle";
import { ECVideoConf } from "./molecules/ECVideoConf";
import { ECVisibilitySettings } from "./molecules/ECVisibilitySettings";
import { Time } from "./molecules/Time";
import { EMPTY_FLEX_DETAILS, EventCardFlex, EventPermissionsInfo } from "./types";
import { toDetailsInput, toEventCardFlex } from "./utils/flexibility";
import { getChangesCount } from "./utils/getChangesCount";
import { getEventCardWarning } from "./utils/getEventCardWarning";
import { canModifyWholeEvent, isWaitingOnAsync } from "./utils/getUserEventPermissions.util";

// Temporary constant to show save button
const IS_SENDING_TOO = false;

// render all the event card pieces, the parent component is responsible for fetching data/managing context
export const EventCard: React.FC<{
  eventDetails: EventDetails;
  permissions: EventPermissionsInfo;
  eventId: EventId;
  error?: boolean;
  colorSettings?: CategoryOption[];
}> = ({ eventDetails, permissions, eventId, error, colorSettings }) => {
  const track = useTracking();
  const dispatch = useDispatch();
  const focusChatTextInput = useFocusChatTextInput();

  const [lastFetchedEventId, setLastFetchedEventId] = useState<string | undefined>(undefined);
  const [eventName, setEventName] = useState<string>("");
  const [agenda, setAgenda] = useState<string>("");
  const [responseStatus, setResponseStatus] = useState<ResponseStatusEnum>(
    ResponseStatusEnum.NotApplicable,
  );
  const [recurrenceRule, setRecurrenceRule] = useState<RecurrenceRule | null>(null);
  const [flexDetails, setFlexDetails] = useState<EventCardFlex>(cloneDeep(EMPTY_FLEX_DETAILS));
  const [location, setLocation] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<ConferencingType | undefined>(undefined);
  const [videoLink, setVideoLink] = useState<string>("");
  const [category, setCategory] = useState<EventColorCategory>(EventColorCategory.AdHoc);
  const { domains: orgDomains } = useOrgDomains();
  const { conferencingTypes } = useUserConfrencingTypes();
  const updateActiveEvent = useUpdateActiveEvent();
  const { processMessage } = useProcessMessage(focusChatTextInput);
  const {
    value: agendaHasChanges,
    setTrue: setAgendaChanges,
    setFalse: setAgendaNoChanges,
  } = useBoolean(false);

  const { startTime, endTime, timesHaveChanged, isAllDay, timeError } = useReadEventTimeDetails();
  const { attendees: attendeesInEvent, attendeesChanged } = useEventAttendees();
  const isAllDayRef = useRef(isAllDay);

  const { externalEventId, calendarId } = eventId;

  const handleFlexChange = (flexible: boolean) => {
    setFlexDetails({
      ...flexDetails,
      isFlexible: flexible,
    });
    setEventName(formatSparkleInTitle(eventName, flexible));
  };

  const closeEventCard = (keepChatOpen?: boolean) => {
    updateActiveEvent(null, keepChatOpen);
  };
  const {
    id: fetchedEventId,
    calendarId: fetchedCalendarId,
    description: fetchedDescription,
    flexDetails: fetchedFlexDetails,
    location: fetchedLocation,
    responseStatus: fetchedResponseStatus,
    title: fetchedTitle,
    videoLink: fetchedVideoLink,
    videoType: fetchedVideoType,
    startISO: fetchedStartISO,
    eventPermissions: fetchedEventPermissions,
    organizerCalId: fetchedOrganizerCalId,
    attendeesOmitted: fetchedAttendeesOmitted,
    descriptionOmitted: fetchedDescriptionOmitted,
    // TODO: Is there a better way to handle this? The event details are guaranteed to be handled by
    // the time we get here but the types suggest any field could still be undefined. Can we clean
    // this up?
    recurrenceRule: fetchedRecurrenceRule = null,
    category: fetchedCategory,
    transparency: fetchedTransparency,
    visibility: fetchedVisibility,
  } = eventDetails;

  const hasFlexBeenModified = !isEqual(fetchedFlexDetails, flexDetails);
  const hasRecurrenceBeenModified =
    fetchedRecurrenceRule?.toString() !== recurrenceRule?.toString();

  const disabledRecurrenceSaveOptions = getDisabledSaveOptions({
    hasFlexBeenModified: hasFlexBeenModified,
    hasRecurrenceBeenModified: hasRecurrenceBeenModified,
    isDescriptionOmitted: !!fetchedDescriptionOmitted,
    canModifyFuture: permissions.canModifyFuture,
    canModifyAll: permissions.canModifyAll,
  });

  const handleViewEvent = () => {
    updateActiveEvent({
      calendarId,
      externalEventId,
      type: EventType.Event,
    });
  };

  const submitRescheduleMessage = () => {
    if (!fetchedStartISO || !fetchedTitle) return;
    const zonedStartISO = DateTime.fromISO(fetchedStartISO).setZone(getRenderTimeZone()).toISO();
    const cql = generateRescheduleCQL(externalEventId);
    void processMessage(
      `Reschedule ${fetchedTitle}`,
      {
        eventMentions: [{ externalEventId, startTime: zonedStartISO, title: fetchedTitle }],
        personMentions: [],
      },
      cql,
    );
    closeEventCard(true);
  };

  const { refetchEvents } = usePlannerContext();

  const { onSaveEventChanges, savingChanges, onDeleteEvent, deletingEvent } = useEditExistingEvent(
    externalEventId,
    calendarId,
    !!fetchedRecurrenceRule,
    {
      onEditCompleted: () => {
        dispatch(clearAllSelectedCalendarsInMultiCalendar());
        track(TrackingEvents.EVENT.UPDATED, { externalEventId, calendarId });
        eventToast.success({
          operation: "EDIT_DETAILS",
          title: eventName,
          onViewEvent: handleViewEvent,
        });
        void refetchEvents?.();
        closeEventCard();
      },
      onEditError: (err) => {
        eventToast.error({
          operation: "EDIT_DETAILS",
          title: eventName,
          onViewEvent: handleViewEvent,
        });
        logger.error("unable to edit event details for event", {
          error: err || "unable to edit event details for event",
          externalEventId,
          calendarId,
        });
      },
      onDeletionCompleted: () => {
        dispatch(clearAllSelectedCalendarsInMultiCalendar());
        if (permissions.canDelete) {
          track(TrackingEvents.EVENT.DELETED, { externalEventId, calendarId });
          eventToast.success({
            operation: "CANCEL",
            title: eventName,
            onViewEvent: handleViewEvent,
          });
        } else {
          track(TrackingEvents.EVENT.REMOVED, { externalEventId, calendarId });
          eventToast.success({
            operation: "CANCEL",
            title: eventName,
            onViewEvent: handleViewEvent,
          });
        }
        void refetchEvents?.();
        closeEventCard();
      },
      onDeletionError: (err) => {
        eventToast.error({
          operation: "CANCEL",
          title: eventName,
          onViewEvent: handleViewEvent,
        });

        logger.error("unable to delete event", {
          error: err || "unable to delete event",
          externalEventId,
          calendarId,
        });
      },
    },
  );

  const formatAndSubmitChanges = async (saveOption: RepeatingEventSaveOption) => {
    if (!!startTime && !!endTime) {
      if (!fetchedOrganizerCalId || !fetchedEventId) {
        toast.error("Unable to save changes. Please try again later.");
        return;
      }
      const inputFlexUpdates = formatFlexUpdates(flexDetails, fetchedEventId);
      const attendees = fetchedAttendeesOmitted
        ? undefined
        : formatAttendeesToSaveToEvent(attendeesInEvent, responseStatus);
      const timeAsInterval = Interval.fromISO(`${startTime}/${endTime}`, {
        zone: getRenderTimeZone(),
      }).toISO();

      const body = getBodyForSaveChanges(
        {
          attendees,
          description: agenda,
          isFlexible: hasFlexBeenModified ? flexDetails.isFlexible : null,
          location,
          flexUpdates: hasFlexBeenModified ? inputFlexUpdates : null,
          recurrence: hasRecurrenceBeenModified
            ? {
                recurrenceRule: recurrenceRule ? recurrenceRule.toString() : null,
                eventStartTime: startTime,
                timeZone: getRenderTimeZone(),
              }
            : null,
          responseStatus,
          sendNotifications: IS_SENDING_TOO,
          time: timeAsInterval,
          title: eventName,
          conferenceType: formatVCToSave(videoType, fetchedVideoType),
          repeatingEventSaveOption: shouldUpdateThisAndFutureEvents(
            saveOption,
            fetchedRecurrenceRule,
            recurrenceRule,
          ),
          category: createTagForMutation(Tags.EventColoringCategory, category),
          isAllDay: isAllDay,
        },

        fetchedEventPermissions,
      );

      await onSaveEventChanges?.(body);
    }
  };

  const resetCardToFetchedValues = () => {
    setEventName(fetchedTitle || "");
    setAgenda(fetchedDescription || "");
    setResponseStatus(fetchedResponseStatus || ResponseStatusEnum.NotApplicable);
    setFlexDetails(fetchedFlexDetails);
    setLocation(fetchedLocation);
    setVideoType(fetchedVideoType);
    setRecurrenceRule(fetchedRecurrenceRule);
    setVideoLink(fetchedVideoLink || "");
    setLastFetchedEventId(fetchedEventId);
    setCategory(fetchedCategory || EventColorCategory.AdHoc);
    setAgendaNoChanges();
  };

  useEffect(() => {
    if (fetchedEventId && fetchedEventId !== lastFetchedEventId) {
      resetCardToFetchedValues();
    }
  }, [fetchedEventId]);

  const warningMessage = getEventCardWarning(orgDomains, attendeesInEvent, permissions.canRemove);

  const isImpersonatedUser = isImpersonated(jwt.get());
  const readOnly = !permissions.canModify || isImpersonatedUser;
  const userIsOnlyAttendee = attendeesInEvent.length <= 1 && !fetchedAttendeesOmitted;

  const attendees = fetchedAttendeesOmitted
    ? undefined
    : formatAttendeesToSaveToEvent(attendeesInEvent, responseStatus);

  useSetOriginalAttendeesBeforeEditForMultiCalendar({
    eventId: externalEventId,
    calendarIds: attendees?.map((attendee) => attendee.calendarId) ?? [],
  });

  const [resetEditsToAttendeesForMultiCalendar] = useResetEditsToAttendeesForMultiCalendar({
    externalEventId,
  });

  const wrappedOnClose = () => {
    resetEditsToAttendeesForMultiCalendar();
    closeEventCard();
  };

  const changesMap = {
    eventName: eventName !== fetchedTitle,
    category: category !== fetchedCategory,
    agenda: agendaHasChanges,
    location: (location || "") !== (fetchedLocation || ""),
    videoType: videoType !== fetchedVideoType,
    eventRecurrence: fetchedRecurrenceRule?.toString() !== recurrenceRule?.toString(),
    flexDetails: !isEqual(flexDetails, fetchedFlexDetails),
    rsvp: Boolean(fetchedResponseStatus) && responseStatus !== fetchedResponseStatus,
    time: timesHaveChanged,
    attendees: attendeesChanged,
    isAllDay: isAllDayRef.current !== isAllDay,
  };

  const anyPropertyChanged = Object.values(changesMap).some((change) => change);
  const changesCount = getChangesCount(changesMap);

  const isOtherUsersEvent = !permissions.canRemove;

  return (
    <ECTabGroup defaultIndex={0}>
      <CardWrapper
        onClose={wrappedOnClose}
        hasFetchingError={error}
        shouldShowRSVP={permissions.canRSVP}
        changesCount={changesCount}
        rsvpModule={
          <ECResponseStatus onRSVPChange={setResponseStatus} rsvpStatus={responseStatus} />
        }
        header={
          <>
            <ECTabList>
              <ECHeadTab>Event</ECHeadTab>
              <ECHeadTab>History</ECHeadTab>
            </ECTabList>
          </>
        }
        footer={
          <ECFooter
            canModify={permissions.canModify}
            canRemove={permissions.canRemove}
            canDelete={permissions.canDelete}
            disabled={isWaitingOnAsync(savingChanges, deletingEvent) || !!timeError}
            onUpdateCancel={onDeleteEvent}
            onSubmit={formatAndSubmitChanges}
            updatesPresent={anyPropertyChanged}
            isCancelled={false}
            disabledRecurrenceSaveOptions={disabledRecurrenceSaveOptions}
            showDropdownOnSave={
              !!recurrenceRule && (permissions.canModifyAll || permissions.canModifyFuture)
            }
            shouldSendUpdatesAfterSave={!userIsOnlyAttendee}
            onReschedule={fetchedTitle && fetchedStartISO ? submitRescheduleMessage : undefined}
          />
        }
      >
        {warningMessage && <WarningMessage type={warningMessage} />}
        <ECTabPanels>
          <ECTabPanel>
            <div className="cw-flex cw-flex-col cw-gap-3">
              <ECTitle
                readOnly={readOnly}
                eventName={eventName}
                onEventNameChange={(e) => setEventName(() => e.target.value)}
              />
              <ECAttendeeWrapper
                attendeesOmitted={fetchedAttendeesOmitted}
                canInviteOthers={permissions.canInviteOthers}
                canModify={permissions.canModify}
                externalEventId={externalEventId}
                calendarId={calendarId}
                onReschedule={
                  canModifyWholeEvent(fetchedEventPermissions) ? submitRescheduleMessage : undefined
                }
              />
              <Divider spacing={0} inset />
              <ECFlexibility
                flexDetails={toDetailsInput(flexDetails)}
                onFlexibilityChange={handleFlexChange}
                onFlexDetailsChange={(flexDetails) => setFlexDetails(toEventCardFlex(flexDetails))}
                readOnly={readOnly}
                showChanges={changesMap.flexDetails}
              />
              <Divider spacing={0} inset />
              <Time readOnly={readOnly} timesHaveChanged={timesHaveChanged} />
              <AllDay readOnly={readOnly} />
              <ECRecurrence
                readOnly={readOnly}
                recurrenceRule={recurrenceRule}
                date={startTime?.toISODate()}
                onSelect={setRecurrenceRule}
                showChanges={changesMap.eventRecurrence}
                originalRecurrence={fetchedRecurrenceRule}
              />
              <Divider spacing={0} inset />
              <CollapsibleSection numberOfSections={4}>
                {fetchedCalendarId && isOtherUsersEvent && (
                  <ECCalendarInfo calendarId={fetchedCalendarId} />
                )}
                <ECCategorySelector
                  allColorSettings={isOtherUsersEvent ? undefined : colorSettings}
                  currentCategory={category}
                  onCategoryChange={setCategory}
                  showChanges={changesMap.category}
                />
                <ECVideoConf
                  onSelect={setVideoType}
                  readonly={readOnly}
                  selected={videoType}
                  videoLink={videoLink}
                  enabledTypes={conferencingTypes}
                  showChanges={changesMap.videoType}
                />
                <ECLocation
                  location={location}
                  onLocationChange={setLocation}
                  readonly={readOnly}
                  showChanges={changesMap.location}
                />
                {(fetchedTransparency || fetchedVisibility) && (
                  <ECVisibilitySettings
                    // Dev note: existing events display this info as readonly as per A-498
                    // When rolling out the new edit event experience, make these editable
                    readonly
                    visibility={fetchedVisibility}
                    transparency={fetchedTransparency}
                  />
                )}
                <ECAgenda
                  readOnly={readOnly}
                  agenda={agenda}
                  onChangeAgenda={(value, userModified) => {
                    setAgenda(value);
                    if (userModified) {
                      setAgendaChanges();
                    }
                  }}
                  descriptionOmitted={fetchedDescriptionOmitted}
                  showChanges={changesMap.agenda}
                />
              </CollapsibleSection>
            </div>
          </ECTabPanel>
          <ECTabPanel>
            <ECHistory eventId={externalEventId} calendarId={calendarId} />
          </ECTabPanel>
        </ECTabPanels>
      </CardWrapper>
    </ECTabGroup>
  );
};
