import { ExpandMore } from "@clockwise/design-system/icons";
import { Listbox } from "@headlessui/react";
import classNames from "classnames";
import React, { ReactNode, Ref } from "react";
import { Tag } from "./Tag";

export interface SelectedOption<V extends string> {
  label: ReactNode;
  value: V;
}

export function MultiSelectTrigger<V extends string>({
  value,
  placeholder,
  truncate,
  onDelete,
  disabled,
  error,
  fullWidth,
  triggerRef,
}: {
  value: SelectedOption<V>[];
  placeholder?: string;
  truncate: number | "none";
  onDelete: (value: V) => void;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  triggerRef?: Ref<HTMLButtonElement>;
}) {
  let selectedValues = value;
  let truncatedCount = 0;
  if (truncate !== "none") {
    if (selectedValues.length > truncate) {
      truncatedCount = selectedValues.length - truncate;
      selectedValues = selectedValues.slice(0, truncate);
    }
  }

  return (
    <Listbox.Button
      ref={triggerRef}
      role="button"
      as="div"
      className={classNames(
        "cw-body-base cw-text-13",
        "cw-h-8 cw-max-w-full cw-px-2",
        "cw-border-solid cw-border cw-rounded-lg",
        "cw-bg-default hover:cw-bg-neutral focus:cw-bg-neutral",
        "cw-inline-flex cw-justify-between cw-gap-2 cw-items-center",
        {
          "cw-w-full": fullWidth,
          "cw-border-muted  focus:cw-border-default": !error,
          "cw-border-destructive hover:cw-border-destructive-hover focus:cw-border-destructive-hover": error,
          "cw-cursor-pointer cw-text-default": !disabled,
          "cw-pointer-events-none cw-text-default-disabled": disabled,
        },
      )}
      tabIndex={disabled ? undefined : 0}
    >
      {selectedValues.length > 0 ? (
        <span className="cw-flex cw-gap-1" onClick={(e) => e.stopPropagation()}>
          {selectedValues.map((v) => (
            <Tag
              key={v.value}
              label={v.label}
              onDelete={() => onDelete(v.value)}
              disabled={disabled}
            />
          ))}
          {truncatedCount > 0 && <Tag label={`+${truncatedCount}`} disabled={disabled} />}
        </span>
      ) : (
        <span
          className={classNames({
            "cw-text-subtle": !disabled,
            "cw-text-subtle-disabled": disabled,
          })}
        >
          {placeholder}
        </span>
      )}

      <ExpandMore className="cw-h-3.5 cw-w-3.5" aria-hidden="true" />
    </Listbox.Button>
  );
}
