import { fg_busy, fg_onEmphasis } from "@clockwise/design-system/tokens";
import {
  CheckCircleFilled,
  ClockwiseIntelligenceFilled,
  EmergencyHomeFilled,
} from "@clockwise/icons";
import classNames from "classnames";
import React, { useRef } from "react";
import { TradeoffsPopover } from "../../tradeoffs-popover";
import { Tradeoff } from "../../tradeoffs-popover/types";
import { ProposalOptionSpacing } from "../proposal-options-overlay/ProposalOption";
import { COLORS, ProposalOptionCardVariant } from "./types";

type Props = {
  variant: ProposalOptionCardVariant;
  spacing: ProposalOptionSpacing;
  onClick: () => void;
  emphasized: boolean;
  popoverSide: "left" | "right" | "bottom";
  tradeoffs: Tradeoff[];
  attendeeCount: number;
  selected: boolean;
  label: string;
};

const Icon = ({
  variant,
  colorClass,
  sizeClass,
}: {
  variant: ProposalOptionCardVariant;
  colorClass: string;
  sizeClass: string;
}) => {
  switch (variant) {
    case "no-conflict":
      return <CheckCircleFilled style={{ fill: colorClass }} className={sizeClass} />;
    case "sbm":
      return <ClockwiseIntelligenceFilled style={{ fill: colorClass }} className={sizeClass} />;
    case "conflict":
      return <EmergencyHomeFilled style={{ fill: colorClass }} className={sizeClass} />;
  }
};

export const ProposalOptionRailCard = ({
  variant,
  spacing,
  onClick,
  emphasized,
  popoverSide,
  tradeoffs,
  attendeeCount,
  selected,
  label,
}: Props) => {
  const popoverAnchorRef = useRef<HTMLDivElement | null>(null);

  let sizeClass: string;

  switch (spacing) {
    case "full":
      sizeClass = "lg:cw-w-4 lg:cw-h-4 cw-w-3 cw-h-3";
      break;
    case "none":
      sizeClass = "cw-w-2.5 cw-h-2.5";
      break;
    case "small":
      sizeClass = "lg:cw-w-3 lg:cw-h-3 cw-w-2.5 cw-h-2.5";
      break;
  }

  const colorClass = emphasized || selected ? fg_onEmphasis : COLORS[variant];
  const borderColorClass = selected ? fg_busy : COLORS[variant];

  return (
    <div
      cw-id="proposal-option-rail-card"
      className={classNames(
        "cw-h-full cw-w-full cw-border-solid cw-border cw-border-transparent cw-drop-shadow",
        {
          "cw-animate-slowPulse": emphasized,
        },
      )}
      onMouseDown={onClick}
    >
      <div
        style={{
          borderColor: borderColorClass,
          color: colorClass,
        }}
        className={classNames(
          "cw-h-full cw-w-full cw-border cw-border-solid cw-rounded cw-cursor-pointer cw-flex cw-justify-center cw-items-start",
          {
            "cw-pt-1": spacing === "full",
            "cw-pt-0.5": spacing === "small",
          },
          {
            "cw-bg-busy-emphasis cw-text-onEmphasis": selected && !emphasized,
            "cw-bg-neutral-emphasis cw-text-onEmphasis": emphasized,
            "cw-bg-default": !emphasized && !selected,
          },
        )}
        ref={popoverAnchorRef}
      >
        <Icon variant={variant} colorClass={colorClass} sizeClass={sizeClass} />
      </div>
      {emphasized && (
        <TradeoffsPopover
          tradeoffs={tradeoffs}
          side={popoverSide}
          totalAttendeeCount={attendeeCount}
          anchorEl={popoverAnchorRef}
          headerText={label}
          showAttendeeCount
        />
      )}
    </div>
  );
};
