///////////////////////////////////
// IMPORTS
///////////////////////////////////
// schema
import { EcosystemEnum, LoginStatus } from "@clockwise/schema";
// libraries
import { ApolloError, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useSelector } from "react-redux";
// component
import { styles } from "./MsftLoginButton.styles";
// util
import { getAuthRedirectUri, useMicrosoftOAuth } from "#webapp/src/auth/msft-auth.util";
import { doUserLogin } from "#webapp/src/util/auth.util";
import { logger } from "#webapp/src/util/logger.util";
// constants
// state
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
// assets
import { m365Logo } from "#webapp/src/assets/img/microsoft";
// mutations
import {
  LoginMicrosoftUserDocument,
  LoginMicrosoftUserMutation,
} from "./__generated__/LoginMicrosoftUser.generated";
// material-ui
import { useOnboarding } from "#webapp/src/hooks/useOnboarding/useOnboarding";
import { Button, Sentiment } from "@clockwise/design-system";
import { SvgIconComponent } from "@clockwise/design-system/icons";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import {
  getInitialAuthTrackingFunnel,
  getStepBasedOnTrackingFunnel,
} from "@clockwise/web-commons/src/util/analytics.util";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import toast, { ToastOptions } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export interface IProps {
  onSuccess?: () => void;
  onFailure?: (error?: any) => void;
  onMicrosoftFailure?: () => void;
  onStart?: () => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  redirect?: string;
  hideSnackBarMessages?: boolean;
  preventLoginRedirect?: boolean;
  variant?: "microsoftStandard" | "default";
  sentiment?: Sentiment | "accentPositive" | "accentDestructive";
  label?: string | React.ReactNode;
  icon?: SvgIconComponent;
  iconClassNames?: string;
  disabled?: boolean;
}

const BASE_URL = "https://graph.microsoft.com";
const SCOPES = [
  `${BASE_URL}/Calendars.ReadWrite.Shared`,
  `${BASE_URL}/User.Read`,
  `${BASE_URL}/User.ReadBasic.All`,
  `${BASE_URL}/People.Read`,
  `${BASE_URL}/MailboxSettings.ReadWrite`,
  "offline_access",
];

const useStyles = makeStyles(styles);

///////////////////////////////////
// COMPONENT
///////////////////////////////////

export const MsftLoginButton = ({
  onSuccess,
  onFailure,
  onMicrosoftFailure,
  onStart,
  onClick,
  redirect,
  hideSnackBarMessages,
  preventLoginRedirect,
  variant = "microsoftStandard",
  label,
  icon,
  disabled,
  iconClassNames,
}: IProps) => {
  const classes = useStyles();
  const { setShouldRenderOnboarding } = useOnboarding();
  const navigate = useNavigate();

  const { prompt } = useMicrosoftOAuth();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);

  const isChromeExtension = useSelector((state: IReduxState) => state.webExtension.isWebExtension);
  const authed = useSelector((state: IReduxState) => state.auth.authed);

  React.useEffect(() => {
    if (authed) {
      if (preventLoginRedirect) {
        return;
      }
      if (isChromeExtension) {
        navigate("/chrome.html", { replace: true });
      } else if (redirect) {
        navigate(`/${redirect}`, { replace: true });
      } else {
        navigate("/app", { replace: true });
      }
    }
  });

  // ~-~-~-~-~-~-~-
  // Auth
  // ~-~-~-~-~-~-~-
  const [loginMicrosoftUser] = useMutation(LoginMicrosoftUserDocument, {
    onCompleted: (data: LoginMicrosoftUserMutation) => {
      processMicrosoftLoginMutationPayload(data);
    },
    onError: (error: ApolloError) => {
      logger.error("loginMicrosoftUser mutation failed", error);
      setIsConnecting(false);
    },
  });

  const processMicrosoftLoginMutationPayload = (data: LoginMicrosoftUserMutation) => {
    const payload = data.loginMicrosoftUser;

    if (!payload || !payload.viewer) {
      logger.error("Incomplete fragment for loginMicrosoftUser");
      return;
    }

    const toastOptions: ToastOptions = {
      position: isChromeExtension ? "bottom-right" : "bottom-center",
      style: isChromeExtension ? { width: "268px" } : undefined,
    };

    let badLoginStatusMessage: string | JSX.Element = "";
    switch (payload.loginStatus) {
      // success!
      case LoginStatus.Success:
        doUserLogin(
          payload.viewer,
          payload.operation,
          payload.orgOperation,
          EcosystemEnum.Microsoft,
          setShouldRenderOnboarding,
          navigate,
          isChromeExtension,
          redirect,
          preventLoginRedirect,
        );
        handleSuccess();
        if (!hideSnackBarMessages) {
          toast.success("Welcome to Clockwise!", toastOptions);
        }
        break;
      // successful user login, but bad status
      case LoginStatus.GoogleContactSharingDisabled:
        badLoginStatusMessage = (
          <span>
            Please update Google to allow Clockwise to access your contacts.{" "}
            <a href={articleUrls.contactSharing} className="cw-text-onEmphasis">
              Learn more
            </a>
          </span>
        );
        handleSuccess();
        break;
      // successful user login, but bad status
      case LoginStatus.GoogleApiAccessDisabled:
        badLoginStatusMessage = "Please update Google to allow Clockwise to have API access.";
        handleSuccess();
        break;
      // generic failure of onboarding the org, has a user, but bad status
      case LoginStatus.NoCrawl:
        badLoginStatusMessage = "Sorry, there was a problem onboarding your account.";
        handleSuccess();
        break;
      // no user, bad login status
      case LoginStatus.PersonalEmail:
        badLoginStatusMessage = "Please use your work email to log into Clockwise.";
        handlePersonalEmailRedirect();
        break;
      // no user, bad login status
      case LoginStatus.WaitlistedEmail:
        badLoginStatusMessage =
          "Thanks for your interest in Clockwise. We are working hard " +
          "to increase access to our service. We have added you to our waitlist and will be in touch soon.";
        handleFailure();
        break;
      case LoginStatus.SecondaryDomainUsed:
        badLoginStatusMessage =
          "It appears you are using a secondary domain for your " +
          "organization. Please sign in using your Google account for your primary domain.";
        handleFailure();
        break;
      case LoginStatus.Deactivating:
        badLoginStatusMessage =
          "You recently deactivated your Clockwise account. While we process " +
          "your deactivation it is not possible to sign in. Apologies for the inconvenience, please try again later.";
        handleFailure();
        break;
      case LoginStatus.BlockedByAdmin:
        badLoginStatusMessage =
          "Your admin removed your access to Clockwise. To remove Clockwise from your calendar, " +
          "please delete the Chrome extension. If this was a mistake, contact your admins. ";
        handleFailure();
        break;
      // meh
      default:
        handleFailure("Missing login status, sorry something went wrong.");
        break;
    }

    // if we have a bad login status, display it for 20 seconds
    if (badLoginStatusMessage && !hideSnackBarMessages) {
      toast.error(badLoginStatusMessage, {
        ...toastOptions,
        duration: 20000,
      });
    }
  };

  // ~-~-~-~-~-~-~-
  // Callbacks
  // ~-~-~-~-~-~-~-
  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleFailure = (error?: string) => {
    setIsConnecting(false);
    if (onFailure) {
      onFailure(error);
    }
  };

  const handlePersonalEmailRedirect = () => {
    // Using the href because this handler doesn't render anything
    window.location.href = "https://www.getclockwise.com/personal-email-not-supported";
  };

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    onClick?.(e);
    setIsConnecting(true);
    // google tag manager
    const dataLayer = window && (window as any).dataLayer;
    const tracking_funnel = getInitialAuthTrackingFunnel();
    const tracking_step = getStepBasedOnTrackingFunnel(tracking_funnel, "click_to_oauth");
    if (dataLayer) {
      dataLayer.push({ event: "userClickedLoginBtn", ecosystem: "microsoft" });
      // new GTM event for user login, dont delete one above until we confirm everything works correctly
      dataLayer.push({
        event: "funnel_step",
        universal_step: "OAUTH",
        funnel_name: tracking_funnel,
        step_name: "click_to_oauth",
        step_number: tracking_step,
        funnel_option: "microsoft",
      });
    }
    prompt(SCOPES, { onSuccess: onMsftSuccess, onFailure: onMsftFailure });
  };

  const onMsftSuccess = ({ code }: { code: string }) => {
    onStart?.();
    setIsConnecting(true);
    const redirectUri = getAuthRedirectUri();
    void loginMicrosoftUser({
      variables: {
        microsoftAuthCode: code,
        redirectUri,
      },
    });
  };
  const onMsftFailure = () => {
    setIsConnecting(false);
    onStart?.();
    onMicrosoftFailure?.();
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-

  switch (variant) {
    case "microsoftStandard":
      return (
        <button
          className={classNames(
            "cw-p-1 cw-bg-[#2F2F2F] hover:cw-bg-[#2a2a2a] active:cw-bg-[#1c1c1c]",
            "cw-w-full",
            "cw-shadow-md hover:cw-shadow-lg",
            "cw-flex cw-justify-unset cw-box-border cw-items-center cw-rounded-[4px]",
            "cw-text-sm cw-text-white cw-font-bold",
            "cw-transition-[background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms]",
            "cw-border-0 cw-m-0 cw-outline-0 cw-relative cw-select-none cw-align-middle",
            { "cw-pointer-events-none cw-opacity-75": isConnecting },
            disabled ? "cw-cursor-not-allowed" : "cw-cursor-pointer",
          )}
          disabled={isConnecting}
          onClick={handleClick}
        >
          <div
            className={classNames(
              "cw-w-[38px] cw-h-[38px]",
              "cw-m-0",
              "cw-flex cw-items-center cw-justify-center",
            )}
          >
            {isConnecting ? (
              <Loader size={20} sentiment="positive" />
            ) : (
              <img className={classes.logo} src={m365Logo} alt="microsoft-logo" />
            )}
          </div>
          <span className={classes.label}>
            {isConnecting ? "Connecting" : "Sign in with Microsoft"}{" "}
          </span>
        </button>
      );
    default:
      return (
        <Button
          variant="outlined"
          startIcon={icon}
          disabled={disabled ?? isConnecting}
          onClick={handleClick}
          fullWidth
          sentiment="accent"
          textAlign="left"
          iconColorClassOverride={iconClassNames}
        >
          {label}
        </Button>
      );
  }
};
