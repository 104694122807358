import { ALL_WORK_DAYS_SELECTED } from "#webapp/src/components/event-card/types";
import { useQuery } from "@apollo/client";
import { Skeleton } from "@clockwise/design-system";
import { DayOfWeek, FlexRange } from "@clockwise/schema/v2";
import { FlexSettingsModule } from "@clockwise/web-commons/src/components/module-flex-settings/FlexSettingsModule";
import { useAttendeeWorkingBounds } from "@clockwise/web-commons/src/hooks/useAttendeeWorkingHours";
import { DayOnOffMap } from "@clockwise/web-commons/src/ui/working-hours";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import {
  convertArrayOfDayWeekToDayMap,
  convertDayMapToFlexRangeAndAllowedDays,
} from "@clockwise/web-commons/src/util/dayMap";
import { getSimplifiedStatus } from "@clockwise/web-commons/src/util/getFlexibleStatusSimplifed";
import { ExpandMore } from "@material-ui/icons";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useUserProfile } from "../../hooks/useUserProfile";
import { PopperWrapper } from "../../web-app-calendar/calendar-popover/PopperWrapper";
import { useUnpauseEvent } from "../../web-app-calendar/hooks/useUnpauseEvent";
import { useSetFlexibility } from "../useSetFlexibility";
import { EventForFlexibility } from "../util/parseFlexibility";

export const MENU_LABELS: Record<FlexRange, string> = {
  [FlexRange.Day]: "within day",
  [FlexRange.Week]: "within week",
  [FlexRange.SpecificDays]: "within week",
};

export const FlexEventMenu = ({
  disabled = false,
  event,
  onOpenCallback,
}: {
  disabled?: boolean;
  event: EventForFlexibility;
  onOpenCallback?: (open: boolean) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { userProfile } = useUserProfile();
  const { handleFlexibilityChange } = useSetFlexibility(
    [
      {
        __typename: "Event",
        id: event.id,
        externalEventId: event.eventId,
        canMarkFlexible: event.canMarkFlexible,
        flexibility: {
          status: event.status,
        },
      },
    ],
    "Flexible Event Menu",
  );

  const onOpenCard = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    onOpenCallback?.(true);
  };

  const onCloseCard = () => {
    setAnchorEl(null);
    onOpenCallback?.(false);
  };

  const track = useTracking();

  const {
    start: attendeeBoundStart,
    end: attendeeBoundEnd,
    conflict: attendeeBoundConflict,
  } = useAttendeeWorkingBounds({
    eventId: event.eventId,
    calendarId: userProfile.primaryCalendar || "",
    calendarIds:
      event.attendees.map((attendee) => attendee?.person?.email?.toLocaleLowerCase()) || "",
    useQuery: useQuery,
  });

  const { update: unpause, updating } = useUnpauseEvent(event.eventId);

  const flexibleStatus = getSimplifiedStatus(event.status, attendeeBoundConflict);
  const flexibleDayOnOffMap = convertArrayOfDayWeekToDayMap(
    event.allowedDays,
    event.flexRange,
    ALL_WORK_DAYS_SELECTED,
  );

  const dayStart = event.timeOfDayFlexRange?.start ?? attendeeBoundStart ?? "09:00";
  const dayEnd = event.timeOfDayFlexRange?.end ?? attendeeBoundEnd ?? "17:00";

  const handleChangeFlexibleDays = (dayOnOffMap: DayOnOffMap) => {
    track(TrackingEvents.FLEX_SETTINGS.CHANGE_FLEXIBLE_SETTINGS, {
      eventId: event.eventId,
      settings: "flexible days",
    });

    const { flexRange, allowedDays } = convertDayMapToFlexRangeAndAllowedDays(dayOnOffMap);

    handleFlexibilityChange(
      event.eventId,
      event.isFlexible,
      flexRange,
      event.timeOfDayFlexRange || undefined,
      flexRange === FlexRange.SpecificDays ? allowedDays : undefined,
    );
  };

  const handleChangeFlexibleRange = (range: FlexRange) => {
    track(TrackingEvents.FLEX_SETTINGS.CHANGE_FLEXIBLE_SETTINGS, {
      eventId: event.eventId,
      settings: "flexible range",
    });

    let newAllowedDays: DayOfWeek[] | undefined;
    switch (range) {
      case FlexRange.Day:
        newAllowedDays = undefined;
        break;
      case FlexRange.Week:
        newAllowedDays = undefined;
        break;
      case FlexRange.SpecificDays:
        newAllowedDays = event.allowedDays;
        break;
    }
    handleFlexibilityChange(
      event.eventId,
      event.isFlexible,
      range,
      event.timeOfDayFlexRange || undefined,
      newAllowedDays,
    );
  };

  const handleChangeFlexibleTimeRange = (start: string, end: string) => {
    track(TrackingEvents.FLEX_SETTINGS.CHANGE_FLEXIBLE_SETTINGS, {
      eventId: event.eventId,
      settings: "flexible time range",
    });
    handleFlexibilityChange(
      event.eventId,
      event.isFlexible,
      event.flexRange,
      { start, end },
      event.flexRange === FlexRange.SpecificDays ? event.allowedDays : undefined,
    );
  };

  const handleChangeFlexibleResume = () => {
    track(TrackingEvents.FLEX_SETTINGS.CHANGE_FLEXIBLE_SETTINGS, {
      eventId: event.eventId,
      settings: "resume",
    });

    unpause();
  };

  const timeStartReadable = DateTime.fromFormat(dayStart, "HH:mm")
    .toFormat("h:mma")
    .toLocaleLowerCase();
  const timeEndReadable = DateTime.fromFormat(dayEnd, "HH:mm")
    .toFormat("h:mma")
    .toLocaleLowerCase();
  const timeRange = ` ⸱ ${timeStartReadable}-${timeEndReadable}`;

  if (updating) {
    return (
      <div className="cw-py-1 cw-my-1 cw-px-1 cw-flex cw-items-center cw-gap-2">
        <Skeleton variant="circle" height={16} width={16} className="cw-ml-[2px]" />
        <Skeleton variant="rect" height={24} className="cw-flex-grow cw-rounded-md" />
      </div>
    );
  }

  return (
    <>
      <button
        onClick={onOpenCard}
        disabled={disabled}
        className="cw-body-sm cw-text-11 cw-flex-1 cw-max-w-[200px] cw-cursor-pointer cw-font-medium cw-border-none cw-rounded-full cw-pl-2 cw-pr-1 cw-h-5 cw-bg-neutral-inset pressed:cw-bg-neutral-inset-pressed hover:cw-bg-neutral-inset-pressed"
      >
        <div className="cw-block cw-truncate cw-w-full">
          <div className="cw-flex cw-items-center cw-justify-between cw-gap-0.5">
            <div className="cw-flex cw-items-center cw-gap-1">{`${
              MENU_LABELS[event.flexRange]
            }${timeRange}`}</div>
            <ExpandMore className="cw-h-[14px] cw-w-[14px]" aria-hidden="true" />
          </div>
        </div>
      </button>
      {anchorEl && (
        <PopperWrapper
          anchorEl={anchorEl}
          placement="bottom-end"
          customWidth={210}
          onClose={onCloseCard}
        >
          <div
            cw-id="event-flexibility-page-popover"
            className={classNames("cw-overflow-auto cw-body-sm cw-text-12 cw-py-4 cw-px-3")}
          >
            <FlexSettingsModule
              flexible={event.isFlexible}
              flexibleDayOnOffMap={flexibleDayOnOffMap}
              flexibleEditable={event.canMarkFlexible}
              flexibleEnd={dayEnd}
              flexibleRange={event.flexRange}
              flexibleStart={dayStart}
              flexibleStatus={flexibleStatus}
              onChangeFlexibleDays={handleChangeFlexibleDays}
              onChangeFlexibleRange={handleChangeFlexibleRange}
              onChangeFlexibleResume={handleChangeFlexibleResume}
              onChangeFlexibleTimeRange={handleChangeFlexibleTimeRange}
              updating={updating}
              attendeeBoundStart={attendeeBoundStart}
              attendeeBoundEnd={attendeeBoundEnd}
            />
          </div>
        </PopperWrapper>
      )}
    </>
  );
};
