import { ArrowForward } from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import React from "react";
import { FeatureCard } from "../feature-card";
import { MAX_H_TALL } from "./FeatureCardGrid";
import { FlexibleEventSkeleton } from "./FlexibleEventSkeleton";
import { Tile } from "./Tile";

export const FlexibleHolds = () => {
  return (
    <FeatureCard
      layout="column"
      feature={{
        name: "Flexible holds",
        path: appPaths.flexibleHolds,
        endIcon: <ArrowForward />,
      }}
      className={MAX_H_TALL}
    >
      <Tile alignment="start" className="cw-basis-12 cw-grow ">
        <FlexibleEventSkeleton width={100} />
      </Tile>
      <Tile alignment="start" className="cw-basis-16 cw-grow">
        <FlexibleEventSkeleton width={80} />
      </Tile>
    </FeatureCard>
  );
};
