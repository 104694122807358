import { ApolloCache, ApolloError, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  CancelSharedProposalDocument,
  CancelSharedProposalMutation,
} from "../apollo/__generated__/CancelSharedProposal.generated";

type UseCancelSharedProposalResult = [
  () => Promise<void>,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

type UseCancelSharedProposalProps = {
  onCompleted?: (data: CancelSharedProposalMutation) => void;
  onError?: (error: ApolloError) => void;
  update?: (cache: ApolloCache<any>, { data }: { data: CancelSharedProposalMutation }) => void;
  proposalId: string;
};

export const useCancelSharedProposal = ({
  onCompleted,
  onError,
  update,
  proposalId,
}: UseCancelSharedProposalProps): UseCancelSharedProposalResult => {
  const [confirmSharedProposal, { loading, error }] = useMutation(CancelSharedProposalDocument);

  const onConfirmChatProposal = useCallback(async () => {
    await confirmSharedProposal({
      variables: { input: { proposalId } },
      onCompleted,
      onError,
      update,
    });
  }, [confirmSharedProposal, onCompleted, onError, update, proposalId]);

  return [onConfirmChatProposal, { loading, error }];
};
