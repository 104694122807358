import { TutorialDialog } from "#webapp/src/components/tutorial-dialog";
import header from "#webapp/src/components/tutorial-dialog/images/Extension.svg";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { UserFlags } from "@clockwise/client-commons/src/util/flags";
import { EcosystemEnum } from "@clockwise/schema";
import { useFlags } from "@clockwise/web-commons/src/util/FlagsContext";
import { browserName, extensionURLForBrowser } from "@clockwise/web-commons/src/util/browser.util";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import React from "react";
import { useSelector } from "react-redux";
import { sendTrackingWebApp } from "../extension-download-button";
import { useUserProfile } from "../hooks/useUserProfile";

export const ExtensionTutorialDialog = () => {
  const {
    userProfile: { userId },
  } = useUserProfile();

  const { isFlagSet, setFlag } = useFlags();
  const [hasDelayPassed, setHasDelayPassed] = React.useState(false);
  const isWebExtension = useSelector((state: IReduxState) => state.webExtension.isWebExtension);
  const ecosystem = useEcosystem();

  const shouldShowFromFlags =
    isFlagSet(UserFlags.Onboarding.ShouldSeeExtensionTutorialDialog.name) &&
    !isFlagSet(UserFlags.Onboarding.HasSeenExtensionTutorialDialog.name);

  React.useEffect(() => {
    const delay = setTimeout(() => setHasDelayPassed(true), 3000);
    return () => {
      clearTimeout(delay);
    };
  }, []);

  const isExtensionBrowser = browserName === "Chrome" || browserName === "Firefox";

  const onDismiss = () => setFlag(UserFlags.Onboarding.HasSeenExtensionTutorialDialog.name, true);

  const onConfirm = () => {
    setFlag(UserFlags.Onboarding.HasSeenExtensionTutorialDialog.name, true);
    if (extensionURLForBrowser) {
      window.open(extensionURLForBrowser, "_blank");
      sendTrackingWebApp("ONBOARDING_DIALOG_BOX", userId);
    }
  };

  // do not show if in a browser other than chrome or firefox
  // or if currently in the extension or if in M365 ecosystem
  // or not on the proper experiment
  if (!isExtensionBrowser || isWebExtension || ecosystem === EcosystemEnum.Microsoft) {
    return null;
  }

  return hasDelayPassed && shouldShowFromFlags ? (
    <TutorialDialog
      header="Let Clockwise assist you directly in Google Calendar"
      imagePath={header}
      visible={shouldShowFromFlags}
      onConfirm={onConfirm}
      trackingEventType={"ExtensionTutorialDialog"}
      onDismiss={onDismiss}
      confirmText={`Add to ${browserName}`}
    />
  ) : null;
};
