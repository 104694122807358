import * as React from "react";
import { LottiePlayer } from "../../lottie-player/LottiePlayer";
import animationSrc from "./assets/analyzing.lottie";

export const AnalyzingLoader = ({ variant }: { variant: string }) => {
  return (
    <div className="cw-flex cw-flex-col cw-items-center cw-h-full cw-overflow-hidden">
      <div className="cw-py-2 md:cw-py-6 cw-mb-2 cw-heading-2xl md:cw-heading-3xl">
        Analyzing your {variant}…
      </div>
      <div className="cw-max-w-full md:cw-max-w-screen-md cw-relative cw-bg-neutral">
        <LottiePlayer autoplay loop src={animationSrc} />
      </div>
    </div>
  );
};
