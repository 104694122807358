import { Link } from "@clockwise/design-system";
import React from "react";

const Tooltip = () => (
  <>
    Clockwise will detect when a meeting is not at your primary work location and schedule the right
    amount of travel time to get there and back.{" "}
    <Link onEmphasis href="https://getclockwise.helpscoutdocs.com/article/172-travel-time">
      Learn more
    </Link>
  </>
);

export default Tooltip;
