import { CSSProperties } from "@material-ui/core/styles/withStyles";

// styles
import { colors, greys } from "../../styles/color.styles";
import spacing from "../../styles/spacing.styles";
import { fontFamily, fontFamilyBody, fontFamilySans, size, weight } from "../../styles/type.styles";

export const container: CSSProperties = {
  position: "absolute",
  padding: spacing.std,
  fontSize: size.md,
  color: greys.white,
  backgroundColor: colors.blueDusk.standard,
  transition: "all .35s",
  WebkitFontSmoothing: "antialiased",
  borderRadius: 5,
  width: 260,
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.5)",
  zIndex: 1,
  "&.white": {
    backgroundColor: greys.white,
    color: colors.blueDusk.standard,
  },
};

export const header: CSSProperties = {
  fontFamily,
  fontSize: 16,
  fontWeight: weight.bold,
  lineHeight: 1.4,
  marginBottom: 8,
};

export const body: CSSProperties = {
  lineHeight: 1.5,
  fontFamily: fontFamilySans,
};

const icon: CSSProperties = {
  color: greys.white,
};

const close: CSSProperties = {
  ...icon,
  position: "absolute",
  right: -10,
  top: 45,
};

const arrow: CSSProperties = {
  width: 0,
  height: 0,
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderBottom: `10px solid ${colors.blueDusk.standard}`,
  position: "absolute",
  zIndex: 16,
  marginTop: 15,

  "&.white": {
    borderBottom: `10px solid ${greys.white}`,
  },

  "&.top": {
    left: 40,
    top: -25,
  },

  "&.bottom": {
    left: 40,
    bottom: -25,
    transform: "rotate(180deg)",
  },

  "&.left": {
    left: -15,
    top: 20,
    transform: "rotate(-90deg)",
  },

  // Tooltip arrow at the bottom left (instead of the top left)
  "&.leftBottom": {
    left: -15,
    bottom: 20,
    transform: "rotate(-90deg)",
  },

  "&.right": {
    right: -15,
    top: 20,
    transform: "rotate(90deg)",
  },
};

const button: CSSProperties = {
  display: "inline-block",
  marginTop: spacing.sm,
  minWidth: 100,
  borderColor: greys.white,
  color: greys.white,
  borderRadius: 1000000,
  fontFamily: fontFamilyBody,
  "&:hover": {
    backgroundColor: greys.white,
    color: colors.blueDusk.standard,
  },
  "&.white": {
    borderColor: colors.blueDusk.standard,
    color: colors.blueDusk.standard,
    backgroundColor: greys.white,

    "&:hover": {
      backgroundColor: colors.blueDusk.standard,
      color: greys.white,
    },
  },
};

const logo: CSSProperties = {
  style: {
    display: "block",
    width: 86,
    paddingBottom: 8,
  } as CSSProperties,
};

export const styles = {
  container,
  header,
  body,
  icon,
  close,
  arrow,
  button,
  logo,
};
