import { Duration } from "luxon";

export type Timeframes = "week" | "month" | "year";

export const TimeframeDuration: Record<Timeframes, Duration> = {
  week: Duration.fromDurationLike({ weeks: 1 }),
  month: Duration.fromDurationLike({ months: 1 }),
  year: Duration.fromDurationLike({ years: 1 }),
};

export const DISPLAY_DATE_FORMAT = "MM/dd";
export const DISPLAY_DATE_FORMAT_WITH_YEAR = "MM/dd/yy";
