import { Slot } from "@clockwise/client-commons/src/constants/time-slot";
import * as React from "react";
import {
  convertSlotToTime,
  convertTimeToSlot,
} from "../../setup-scheduling-link/CustomMeetingHours/utils";

import { TimeInput } from "@clockwise/design-system";
import { StartOrEndSlot } from "../../working-hours";

export interface AutocompleteTimeslotProps {
  type: StartOrEndSlot;
  defaultValue: Slot;
  onTimeSlotChange: (option: Slot) => void;
  error?: boolean;
  "aria-label"?: string;
}

export const AutocompleteTimeslot = ({
  type,
  defaultValue,
  onTimeSlotChange,
  error = false,
  "aria-label": ariaLabel,
}: AutocompleteTimeslotProps) => {
  function handleTimeInputChange(value: string | null) {
    if (value) {
      onTimeSlotChange(convertTimeToSlot(value, type === StartOrEndSlot.EndSlot));
    }
  }

  return (
    <TimeInput
      value={convertSlotToTime(defaultValue, type === StartOrEndSlot.EndSlot)}
      onChange={handleTimeInputChange}
      error={error}
      aria-label={ariaLabel}
    />
  );
};
