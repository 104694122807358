import { isImpersonated } from "@clockwise/client-commons/src/util/jwt";
import { Modal } from "@clockwise/design-system";
import { MentionsV2 } from "@clockwise/schema";
import { useAIChatCollapseContext } from "@clockwise/web-commons/src/util/AIChatCollapseContext";
import { jwt } from "@clockwise/web-commons/src/util/local-storage";
import { noop } from "lodash";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { ChatInput } from "../chat/ai-chat/ChatInput";
import { useAIMessageContext } from "../chat/ai-chat/hooks/AIMessageContext";
import { useCommandBarContext } from "../chat/ai-chat/hooks/CommandBarContext";
import { useUpdateSuggestedPrompt } from "../chat/ai-chat/hooks/SuggestedPromptContext";
import { useFocusChatTextInput } from "../chat/ai-chat/hooks/useFocusChatTextInput";
import { useProcessMessage } from "../chat/ai-chat/hooks/useProcessMessage";
import { useScrollChatToBottom } from "../chat/ai-chat/hooks/useScrollChatToBottom";
import { AI_EXCLUSION_TOOLTIP, AI_IMPERSONATION_TOOLTIP } from "../chat/constants";

export const CommandBar = () => {
  const { processingMessage, debugModel, shouldExcludeAi } = useAIMessageContext();
  const scrollChatToBottom = useScrollChatToBottom();
  const focusChatTextInput = useFocusChatTextInput();
  const { processMessage } = useProcessMessage(focusChatTextInput);
  const updatePromptText = useUpdateSuggestedPrompt();
  const isImpersonatedUser = isImpersonated(jwt.get());
  const { isCommandBarVisible, setIsCommandBarVisible } = useCommandBarContext();
  const { setChatIsCollapsed } = useAIChatCollapseContext();

  const toggleOpenCommandBar = () => {
    setIsCommandBarVisible(!isCommandBarVisible);
  };

  const openCommandBarSchedule = () => {
    setIsCommandBarVisible(true);
    updatePromptText("Schedule", "keyboard-shortcut");
  };

  const openCommandBarReschedule = () => {
    setIsCommandBarVisible(true);
    updatePromptText("Reschedule", "keyboard-shortcut");
  };

  const openCommandBarCreateLink = () => {
    setIsCommandBarVisible(true);
    updatePromptText("Create link", "keyboard-shortcut");
  };

  useHotkeys("meta+k", toggleOpenCommandBar, [], {
    enableOnFormTags: true,
    enableOnContentEditable: true,
    scopes: ["prism"],
    description: "Open the command bar",
  });

  useHotkeys("s", openCommandBarSchedule, [], {
    scopes: ["prism"],
    description: "Schedule in the command bar",
    preventDefault: true,
  });

  useHotkeys("r", openCommandBarReschedule, [], {
    scopes: ["prism"],
    description: "Reschedule in the command bar",
    preventDefault: true,
  });

  useHotkeys("a", openCommandBarCreateLink, [], {
    scopes: ["prism"],
    description: "Create link in the command bar",
    preventDefault: true,
  });

  const submitMessage = async (
    currentText: string,
    mentions: MentionsV2,
    cql?: string | null | undefined,
  ) => {
    setIsCommandBarVisible(false);
    setChatIsCollapsed(false);
    scrollChatToBottom();
    let submitText = currentText;
    if (debugModel !== "default") {
      submitText = `[${debugModel}] ${currentText}`;
    }
    void processMessage(submitText, mentions, cql);
  };

  return (
    <Modal
      opened={isCommandBarVisible}
      onClose={() => setIsCommandBarVisible(false)}
      withCloseButton={false}
      size="large"
      yOffset={200}
      overlayProps={{
        backgroundOpacity: 0.1,
      }}
      classNames={{
        root: "",
        content: "cw-bg-transparent cw-shadow-none",
      }}
    >
      <div className="cw-w-[520px]">
        <ChatInput
          disabled={isImpersonatedUser || shouldExcludeAi}
          disabledReason={
            isImpersonatedUser
              ? AI_IMPERSONATION_TOOLTIP
              : shouldExcludeAi
              ? AI_EXCLUSION_TOOLTIP
              : undefined
          }
          focusInput={true}
          hasActiveProposal={false}
          loading={processingMessage}
          onCancel={noop} // cancelable via sidebar chat
          onSubmit={submitMessage}
          commandBarEnabled={true}
          size="large"
        />
      </div>
    </Modal>
  );
};
