import React, { useMemo, useState } from "react";

export type CommandBarContextValue = {
  isCommandBarVisible: boolean;
  setIsCommandBarVisible: (collapsed: boolean) => void;
};

export const useCommandBarContext = () => {
  const context = React.useContext(CommandBarContext);
  if (!context) {
    throw new Error("useCommandBarContext must be used within a CommandBarProvider");
  }
  return context;
};

export const CommandBarContext = React.createContext<CommandBarContextValue | undefined>(undefined);

export const CommandBarProvider = ({ children }: { children: React.ReactNode }) => {
  const [isCommandBarVisible, setIsCommandBarVisible] = useState(false);

  const value = useMemo(() => ({ isCommandBarVisible, setIsCommandBarVisible }), [
    isCommandBarVisible,
  ]);

  return <CommandBarContext.Provider value={value}>{children}</CommandBarContext.Provider>;
};
