import React, { createContext, useContext, useRef } from "react";

// Create a context for the scroll functionality
const TeamScrollContext = createContext<(id: string) => void>(() => {
  console.error(
    "You are trying to use `useTeamScrollTo` but you are not within a `TeamScrollProvider`.",
  );
});

// Provider component that wraps your app
const TeamScrollProvider = ({ children }: { children?: React.ReactNode }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  // Function to scroll to a given ID on the page
  const scrollToId = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <TeamScrollContext.Provider value={scrollToId}>
      <div className="cw-w-full" ref={scrollRef}>
        {children}
      </div>
    </TeamScrollContext.Provider>
  );
};

// Custom hook to access the scroll functionality
const useTeamScrollTo = () => {
  return useContext(TeamScrollContext);
};

export { TeamScrollProvider, useTeamScrollTo };
