/**
 * @generated SignedSource<<539594f329b57bedd85ffa93b6c80fa8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WebAppCheckout_viewer$data = {
  readonly user: {
    readonly orgs: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly experiments: ReadonlyArray<string | null> | null;
          readonly primaryTeam: {
            readonly __typename: "Team";
            readonly teamId: string;
          } | {
            readonly __typename: "GraphEntityError";
            readonly statusCode: number | null;
            readonly message: string | null;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null;
        } | null;
      } | null> | null;
    };
  } | null;
  readonly " $fragmentType": "WebAppCheckout_viewer";
};
export type WebAppCheckout_viewer$key = {
  readonly " $data"?: WebAppCheckout_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"WebAppCheckout_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WebAppCheckout_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            }
          ],
          "concreteType": "OrgConnection",
          "kind": "LinkedField",
          "name": "orgs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrgEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Org",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "experiments",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "primaryTeam",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "teamId",
                              "storageKey": null
                            }
                          ],
                          "type": "Team",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "statusCode",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "message",
                              "storageKey": null
                            }
                          ],
                          "type": "GraphEntityError",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "orgs(first:1000000)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "ab45eee35afed30d824a8cddd7735c97";

export default node;
