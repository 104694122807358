import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { getAbsoluteDate } from "@clockwise/client-commons/src/util/format-time";
import {
  BillingGroup,
  BillingGroupTypeEnum,
  InvitePolicyEnum,
  SearchPersonStatusResponse,
} from "@clockwise/schema";
import { NewMemberPerson } from "../../APMembers.util";
import {
  getSubscriptionFromBillingGroup,
  isSubscriptionProductTypePro,
} from "../members-content.util";

export type BGWithAdminListErrorable = {
  id: string;
  adminOrgPersons:
    | {
        __typename: "GraphEntityError";
      }
    | {
        __typename: "OrgPersonList";
        list: {
          __typename: "OrgPerson";
          isYou: boolean;
        }[];
      };
};

export const getBillingGroupText = (person: NewMemberPerson) => {
  if (person.status !== SearchPersonStatusResponse.User) {
    return "";
  } else if (!person?.primaryBillingGroup) {
    return "Free";
  }

  return person?.primaryBillingGroup.name;
};

export const getSignUpDateText = (person: NewMemberPerson) => {
  if (!person.signUpMillis) {
    return "";
  }

  return `${getAbsoluteDate(new ZonedMoment(person.signUpMillis), undefined, undefined, true)}`;
};

export const isProductTypePro = (bg: BillingGroup) => {
  const billingGroupSubscription = getSubscriptionFromBillingGroup(bg);

  return (
    billingGroupSubscription && isSubscriptionProductTypePro(billingGroupSubscription.subscription)
  );
};

export const isPersonInBillingGroup = (bg: BillingGroup, person: NewMemberPerson) => {
  return Boolean(person.billingGroups.find((personBg) => personBg.id === bg.id));
};

export const isAdminOfBg = (bg: BGWithAdminListErrorable) =>
  bg.adminOrgPersons.__typename === "OrgPersonList"
    ? bg.adminOrgPersons.list.some((orgPerson) => orgPerson.isYou)
    : false;

export const hasPermissionToRemove = (
  bg: BGWithAdminListErrorable | null,
  currentUserBgIdList: string[],
  newMemberIsYouStatuses: boolean[],
) => {
  if (!bg) return false;
  const onlyTryingToRemoveYourself =
    newMemberIsYouStatuses.length === 1 && newMemberIsYouStatuses[0] === true;
  const admins = getValue(bg.adminOrgPersons)?.list || [];
  const isCurrentUserAlreadyMember = currentUserBgIdList.find((bgId) => bgId === bg.id);
  // Here we ideally only want admins to be able to remove members,
  // but if no admins are present (which is possible for Teams Plans), then we allow any member to remove other members
  return (
    isAdminOfBg(bg) || (isCurrentUserAlreadyMember && !admins.length) || onlyTryingToRemoveYourself
  );
};

export const getStatusText = (person: NewMemberPerson) => {
  if (person.recentlySent) {
    return "Invite pending";
  }
  if (person.recentlyRequested) {
    return "Request pending";
  }
  switch (person.status) {
    case SearchPersonStatusResponse.Invited:
      return "Invite pending";
    case SearchPersonStatusResponse.NonUser:
    case SearchPersonStatusResponse.Deactivated:
    case SearchPersonStatusResponse.User:
    default:
      return "";
  }
};

export const hasPermissionToAdd = (bg: BillingGroup, person: NewMemberPerson) => {
  const isInviteAnyMember = bg.invitePolicy === InvitePolicyEnum.AnyMember;
  const isPersonAlreadyMember = Boolean(
    person.billingGroups.find((personBg) => personBg.id === bg.id),
  );
  return !isPersonAlreadyMember && (isAdminOfBg(bg) || isInviteAnyMember);
};

export const hasPermissionToRequestToJoin = (
  bg: BillingGroup,
  person: NewMemberPerson,
  currentUserBgIdList: string[],
) => {
  // member row person is you not in any billing groups
  if (person.isYou && !person.billingGroups.length) {
    return true;
  }

  const isCurrentUserAlreadyMember = currentUserBgIdList.find((bgId) => bgId === bg.id);

  const isPersonMember = Boolean(person.billingGroups.find((personBg) => personBg.id === bg.id));

  return isCurrentUserAlreadyMember && !isPersonMember;
};

export const isBillingGroupSso = (billingGroup: BillingGroup) => {
  return billingGroup.type === BillingGroupTypeEnum.SSO;
};
