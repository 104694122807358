import { ExpandMore, Search } from "@clockwise/design-system/icons";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { Popover, Portal, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { useSearchPersonsChat } from "../../chat/hooks/useSearchPersons";
import { PersonTag } from "../atoms/PersonTag";
import { EventCardAttendee } from "../types";

const ABOVE_EVERYTHING = 10000;

type Profile = {
  givenName: string;
  familyName: string;
  externalImageUrl: string;
};

const AmbiguousPersonSearch = ({
  ambiguousText,
  SelectPerson,
}: {
  ambiguousText: string;
  SelectPerson: (newPerson: EventCardAttendee) => void;
}) => {
  const [value, setValue] = useState<string>("");
  const { searchPersons, handlePersonSearch } = useSearchPersonsChat({});
  const [trigger, setTrigger] = useState<HTMLButtonElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(trigger, container, {
    placement: "right-start",
  });

  useEffect(() => {
    handlePersonSearch(ambiguousText, []);
  }, [ambiguousText]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handlePersonSearch(e.target.value, []);
    setValue(e.target.value);
  };

  return (
    <Popover className="cw-relative">
      <Popover.Button
        ref={setTrigger}
        className={classNames(
          "cw-flex cw-gap-[2px] cw-items-center cw-h-5 cw-pl-2 cw-pr-1",
          "cw-bg-positive cw-text-positive-muted cw-body-sm",
          "cw-rounded-full cw-border-solid cw-border-positive-muted",
          "cw-cursor-pointer hover:cw-bg-positive-inset focus:cw-bg-positive-inset cw-outline-positive-emphasis cw-h-full",
        )}
      >
        someone else <ExpandMore className={classNames("cw-h-4 cw-w-4")} aria-hidden="true" />{" "}
      </Popover.Button>{" "}
      <Portal>
        <div
          ref={setContainer}
          style={{
            ...styles.popper,
            minWidth: trigger?.scrollWidth,
            zIndex: ABOVE_EVERYTHING,
          }}
          {...attributes.popper}
        >
          <Transition
            leave="cw-transition cw-ease-in cw-duration-100"
            leaveFrom="cw-opacity-100"
            leaveTo="cw-opacity-0"
            enter="cw-transition cw-ease-in cw-duration-100"
            enterFrom="cw-opacity-0"
            enterTo="cw-opacity-100"
          >
            <Popover.Panel className="cw-absolute cw-z-30 cw-max-w-[240px] cw-bg-default cw-shadow-lg cw-rounded-lg cw-text-muted cw-body-sm">
              <div className="cw-w-full cw-flex cw-body-sm cw-font-normal cw-py-2 cw-gap-2 cw-px-4 cw-border-b cw-border-solid cw-border-neutral cw-rounded-t-lg">
                <input
                  className="cw-border-none cw-flex-1 outline-positive-emphasis cw-outline-positive-emphasis"
                  placeholder="Search for people"
                  onChange={handleInputChange}
                  value={value}
                />
                <Search className="cw-h-4 cw-w-4 cw-text-default-muted" aria-hidden="true" />
              </div>
              <div className="cw-p-1 cw-bg-neutral cw-rounded-b-lg">
                {searchPersons.length ? (
                  searchPersons.map((person) => {
                    return (
                      <div key={person.id}>
                        <button
                          onClick={() => {
                            SelectPerson(person);
                          }}
                          className={classNames(
                            "cw-py-2 cw-px-3 cw-flex cw-bg-neutral cw-w-full cw-border-none cw-gap-[10px] cw-items-center cw-mx-0 cw-rounded-lg",
                            "hover:cw-bg-neutral-hover focus:cw-bg-neutral-hover",
                          )}
                        >
                          <AttendeeAvatar profile={person.profile as Profile} size="small" />
                          {person.profile?.givenName} {person.profile?.familyName}
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <div className="cw-py-2 cw-px-3">No results for your search</div>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      </Portal>
    </Popover>
  );
};

export const DisambiguationContent = ({
  listOfPossibleAttendees,
  ambiguousText,
  onConfirmPerson,
  disabled,
  isGroup,
}: {
  listOfPossibleAttendees: EventCardAttendee[];
  ambiguousText: string;
  onConfirmPerson: (person: EventCardAttendee) => void;
  disabled: boolean;
  isGroup: boolean;
}) => {
  const firstPerson = listOfPossibleAttendees?.[0];
  let maybeSecondPerson: EventCardAttendee | null = null;

  // We show two options, if there is only one option, we show that with search (this really shouldn't happen). If there are three options,
  // thats too many so we show the first and search
  if (listOfPossibleAttendees?.length === 2) {
    maybeSecondPerson = listOfPossibleAttendees[1];
  }

  return (
    <div
      className={classNames("cw-flex cw-items-center cw-gap-1 cw-flex-wrap cw-text-muted", {
        "cw-opacity-50 cw-cursor-not-allowed": disabled,
      })}
    >
      {!listOfPossibleAttendees.length ? (
        <EmptyText isGroup={isGroup} userText={ambiguousText} />
      ) : (
        <>
          Added{" "}
          <PersonTag
            calendarId={firstPerson.primaryCalendar}
            profile={firstPerson.profile as Profile}
            disabled
          />
          Did you mean{" "}
          {maybeSecondPerson && maybeSecondPerson.profile ? (
            <PersonTag
              calendarId={maybeSecondPerson.primaryCalendar}
              onClick={() => {
                disabled ? null : onConfirmPerson(maybeSecondPerson);
              }}
              profile={maybeSecondPerson.profile as Profile}
            />
          ) : (
            <AmbiguousPersonSearch
              SelectPerson={(person) => {
                disabled ? null : onConfirmPerson(person);
              }}
              ambiguousText={ambiguousText}
            />
          )}
          ?
        </>
      )}
    </div>
  );
};

const EmptyText = ({ isGroup, userText }: { isGroup: boolean; userText: string }) => {
  return isGroup ? (
    <div>
      I don't know who is on <span className="cw-font-medium">{userText}</span>. Let me know who
      that is and I'll add them to the meeting.
    </div>
  ) : (
    <div>
      I couldn't find a match for <span className="cw-font-medium">{userText}</span>. Let me know
      who this refers to and I'll add them to the meeting.
    </div>
  );
};
