import { useMutation } from "@apollo/client";
import { Button, RadioGroup, RadioOption, TextField } from "@clockwise/design-system";
import { Search } from "@clockwise/design-system/icons";
import { Add } from "@clockwise/icons";
import { TeamMemberRole } from "@clockwise/schema";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FunnelType } from "../../WebOnboarding.util";
import { ButtonGroup, Header, SlideLayout, Text } from "../../shared-components";
import { setError } from "../../utils/setError";
import { TeamCard } from "./TeamCard";
import { TeamJoinOnboardingDocument } from "./__generated__/TeamJoinCreateStep.generated";
import { useTeamSearch } from "./useTeamSearch";

type TeamCreateSlideProps = {
  showSecondaryButton: boolean;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton: () => void;
  onGoToTeamCreate: () => void;
  orgId: string;
  currentPersonId: string;
  funnelType: FunnelType;
};

export const TeamJoinSlide = ({
  showSecondaryButton,
  onClickPrimaryButton,
  onClickSecondaryButton,
  onGoToTeamCreate,
  orgId,
  currentPersonId,
  funnelType,
}: TeamCreateSlideProps) => {
  const track = useTracking();
  const { onChangeDebounced, listOfTeams, getTeamMembers, loadingTeams } = useTeamSearch({ orgId });
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedTeamId, setSelectedTeamId] = useState<string>("");
  // As of now, used exclusively for tracking
  const [isInit, setIsInit] = useState<boolean>(true);

  useEffect(() => {
    if (isInit) {
      track(TrackingEvents.ONBOARDING.ONBOARDING_TEAM_SHOWN, {
        nDisplayedTeams: listOfTeams?.length,
        nSuggestedTeams: listOfTeams.filter((team) => team.isSuggested).length,
        isInvited: false, // No longer show this component if user belongs to a team
        nInvitedTeams: 0, // No longer show this component if user belongs to a team
        funnelType,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfTeams]);

  const [addUserToTeam, { loading: updating }] = useMutation(TeamJoinOnboardingDocument, {
    onError: (error) => {
      setError({
        error: error,
        message: "Failed to add user to team",
        showUserMessage: true,
      });
    },
    onCompleted: () => {
      const teamOfTeamId = listOfTeams.find((team) => team.teamId === selectedTeamId);
      track(TrackingEvents.ONBOARDING.ONBOARDING_TEAM_JOINED, {
        selectedTeamId,
        isSuggested: !!teamOfTeamId?.isSuggested,
        funnelType,
      });
    },
  });

  const onSkipJoin = () => {
    track(TrackingEvents.ONBOARDING.ONBOARDING_TEAM_JOIN_SKIPPED, { funnelType });
    onClickPrimaryButton();
  };

  const onSaveAndMoveForward = async () => {
    await addUserToTeam({
      variables: {
        input: {
          teamId: selectedTeamId,
          orgRelayId: orgId,
          addedTeamMembers: [
            {
              personId: currentPersonId,
              role: TeamMemberRole.Member,
            },
          ],
        },
      },
    });
    onClickPrimaryButton();
  };

  return (
    <SlideLayout>
      <Header>Which team are you on?</Header>
      <Text>
        We found several teams within your organization. Join the team that you spend the most time
        with, so that Clockwise can make time for everyone.
      </Text>
      <div className="md:cw-w-[500px] cw-w-full cw-flex cw-flex-col cw-gap-3">
        <TextField
          autoFocus
          fullWidth
          startIcon={Search}
          loading={loadingTeams}
          placeholder="Search for a team"
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value);
            onChangeDebounced(e.target.value);
            setIsInit(false);
          }}
        />
        <Button onClick={onGoToTeamCreate} startIcon={Add} sentiment="positive" fullWidth>
          Or, create a new team
        </Button>
      </div>
      <div className="cw-self-stretch cw-flex cw-flex-col cw-items-center">
        {listOfTeams.length ? (
          <div className="md:cw-w-[500px] cw-w-full">
            <RadioGroup
              fullWidth
              value={selectedTeamId}
              onChange={(teamId) => {
                setSelectedTeamId(teamId);
              }}
              variant="outlined"
            >
              {listOfTeams.map((team) => {
                return (
                  <RadioOption
                    key={team.teamId}
                    value={team.teamId}
                    variant="outlined"
                    label={<TeamCard teamName={team.teamName} teamMembers={getTeamMembers(team)} />}
                  />
                );
              })}
            </RadioGroup>
          </div>
        ) : (
          <div>Could not find teams matching your search</div>
        )}
      </div>
      <ButtonGroup
        shouldShowSkip
        shouldShowSecondary={showSecondaryButton}
        primaryLabel="Confirm"
        onClickPrimary={onSaveAndMoveForward}
        onClickSecondary={onClickSecondaryButton}
        onSkip={onSkipJoin}
        disabled={updating}
        disablePrimary={!selectedTeamId}
      />
    </SlideLayout>
  );
};
