import { ApolloError } from "@apollo/client";
import { EventFlexibilityInput, FlexRange } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { UpdateEventsFlexibilityDocument } from "../../flexible-meetings/__generated__/UpdateEventsFlexibility.v2.generated";
import { EventForFlexibility } from "../../flexible-meetings/util/parseFlexibility";

export const useOnboardingUpdateEventFlexibility = () => {
  const [editEventFlexibility, { loading: updating, error: updateError }] = useGatewayMutation(
    UpdateEventsFlexibilityDocument,
  );

  const update = async ({
    variables: { events },
    onCompleted,
    onError,
  }: {
    variables: { events: EventForFlexibility[] };
    onCompleted?: () => void;
    onError?: (error: ApolloError) => void;
  }) => {
    const input: { events: EventFlexibilityInput[] } = {
      events: [],
    };
    for (const event of events) {
      // omit flex if they were marked to disable
      const eventId = event.eventId;
      if (!eventId) continue;
      input.events.push({
        isFlexible: event.isFlexible,
        externalEventId: eventId,
        flexRange: event.flexRange,
        timeRange: event.timeOfDayFlexRange,
        allowedDays:
          event.flexRange === FlexRange.SpecificDays && event.allowedDays?.length
            ? event.allowedDays
            : null,
      });
    }

    await editEventFlexibility({
      variables: {
        events: input.events,
      },
      onCompleted,
      onError,
    });
  };

  return {
    update,
    updating,
    updateError,
  };
};
