import { Person, PersonSelector } from "@clockwise/web-commons/src/ui/person-selector";
import React, { useState } from "react";
import { useOrgId } from "../../../hooks/useOrgId";
import {
  CalendarProfile,
  useCalendarProfilesFromSearch,
} from "../hooks/useCalendarProfilesFromSearch";
import { Collaborator } from "../hooks/useCollaborators";

export const CollaboratorSearch = ({
  remainingTopCollaborators,
  onSelect,
  hiddenCalIds,
}: {
  remainingTopCollaborators: Collaborator[];
  onSelect: (profile: CalendarProfile) => void;
  hiddenCalIds: string[];
}) => {
  const { orgId } = useOrgId();
  const [query, setQuery] = useState("");
  const { calendarProfiles, loading: searchLoading } = useCalendarProfilesFromSearch(
    orgId || "",
    query,
    hiddenCalIds,
  );

  const handleSelect = (person: Person) => {
    const calendarProfile: CalendarProfile = {
      primaryCalendar: person.email,
      profile: {
        givenName: person.name.givenName || null,
        familyName: person.name.familyName || null,
        externalImageUrl: person.externalImageUrl || null,
      },
      isYou: false,
    };
    onSelect(calendarProfile);
  };

  const people =
    query || !remainingTopCollaborators.length
      ? calendarProfiles.map((profile) => ({
          email: profile.primaryCalendar,
          name: {
            givenName: profile.profile?.givenName || null,
            familyName: profile.profile?.familyName || null,
          },
          externalImageUrl: profile.profile?.externalImageUrl || null,
        }))
      : remainingTopCollaborators.map((collab) => ({
          email: collab.calendarId,
          name: {
            givenName: collab?.fullName?.split(" ")[0] || null,
            familyName: collab?.fullName?.split(" ").slice(1).join(" ") || null,
          },
          externalImageUrl: collab?.externalImageUrl || null,
        }));

  return (
    <PersonSelector
      onSearch={setQuery}
      onSelect={handleSelect}
      people={people}
      placeholder="Find people"
      searchLoading={searchLoading}
      size="sm"
    />
  );
};
