import {
  asana,
  etsy,
  figma,
  gitlab,
  plaid,
  reddit,
  uber,
  webflow,
  zoom,
} from "#webapp/src/assets/img/customer-logos";
import React from "react";

const CustomerLogo = ({ src, alt }: { src: string; alt: string }) => (
  <div className="cw-flex cw-items-center cw-justify-center">
    <img
      className="cw-w-full cw-max-w-[85px] lg:cw-max-w-[100px] cw-max-h-12"
      src={src}
      alt={alt}
    />
  </div>
);

export const CustomerLogos = () => {
  return (
    <div>
      <div className="cw-flex lg:cw-mt-16 cw-mb-8 cw-mt-8 lg:cw-justify-start cw-justify-center cw-body-xl">
        Used and loved by teams at
      </div>
      <div className="cw-grid lg:cw-grid-cols-9 md:cw-grid-cols-4 cw-grid-cols-2 cw-gap-3 cw-gap-y-10">
        <CustomerLogo src={gitlab} alt="Gitlab" />
        <CustomerLogo src={etsy} alt="Etsy" />
        <CustomerLogo src={uber} alt="Uber" />
        <CustomerLogo src={figma} alt="Figma" />
        <CustomerLogo src={plaid} alt="Plaid" />
        <CustomerLogo src={zoom} alt="Zoom" />
        <CustomerLogo src={webflow} alt="Webflow" />
        <CustomerLogo src={reddit} alt="Reddit" />
        <CustomerLogo src={asana} alt="Asana" />
      </div>
    </div>
  );
};
