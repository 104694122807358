import { SvgIconComponent as MUIIcon } from "@material-ui/icons";
import classNames from "classnames";
import { Icon as PhosphorIcon } from "phosphor-react";
import React, { ReactNode } from "react";
import { EventDuration } from "./types";
import { durationToHeight } from "./utils/durationToHeight";

type Props = {
  duration: EventDuration;
  Icon?: PhosphorIcon | MUIIcon;
  startTime?: string;
  title: string;
  sentiment?: "default" | "warning" | "info";
  children?: ReactNode;
};

export const NamedEvent = ({
  duration,
  startTime,
  title,
  Icon,
  sentiment = "default",
  children,
}: Props) => {
  const height = durationToHeight(duration);

  return (
    <div
      style={{ height: `${height}px`, overflow: "hidden" }}
      className={classNames(
        "cw-body-base cw-rounded cw-relative",
        { "cw-bg-brand-inset cw-text-brand": sentiment === "default" },
        { "cw-bg-warning-inset cw-text-warning": sentiment === "warning" },
        { "cw-bg-info-inset cw-text-info": sentiment === "info" },
      )}
    >
      <div
        className={`
          cw-absolute cw-top-0 cw-left-0
          cw-py-1 cw-px-2
        `}
      >
        <div
          className={`
            cw-flex cw-items-center cw-flex-row
            cw-space-x-1
            cw-font-bold
          `}
        >
          {/* setting size and fontSize to support both Phosphor and mui icons */}
          {Icon && (
            <Icon size={14} style={{ fontSize: 14 }} weight="fill" className={"cw-text-warning"} />
          )}
          <div>{title}</div>
        </div>
        {startTime && <div>{startTime}</div>}
        {children}
      </div>
    </div>
  );
};

export default NamedEvent;
