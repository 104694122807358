import { FlexRange, FlexStatus } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { UnpauseEventDocument } from "../../event-card/molecules/__generated__/UnpauseEvent.v2.generated";

export const useUnpauseEvent = (externalEventId: string | null) => {
  const [unpauseEvent, { loading: updating, error: updateError }] = useGatewayMutation(
    UnpauseEventDocument,
  );

  const update = (options?: { onCompleted?: () => void; onError?: () => void }) => {
    if (!externalEventId) {
      options?.onError?.();
      return;
    }

    void unpauseEvent({
      variables: {
        externalEventId,
      },
      onCompleted: options?.onCompleted,
      onError: options?.onError,
      optimisticResponse: {
        __typename: "Mutation",
        unpauseEventFlexibility: {
          id: externalEventId,
          canMarkFlexible: true,
          flexibility: {
            isFlexible: true,
            status: FlexStatus.CanMove,
            flexRange: FlexRange.Day,
            timeOfDayFlexRange: null,
            allowedDays: null,
            __typename: "FlexDetails",
          },
          __typename: "Event",
        },
      },
    });
  };

  return {
    update,
    updating,
    updateError,
  };
};
