import classNames from "classnames";
import React from "react";

export const Item = ({
  left,
  right,
  alignTop,
  spacing = "Default",
}: {
  left: React.ReactNode;
  right: React.ReactNode;
  alignTop?: boolean;
  spacing?: "Default" | "Compact";
}) => {
  return (
    <div className="cw-relative [&:last-of-type>div.line]:cw-bottom-1/2">
      <div
        className={classNames("cw-flex cw-items-center", {
          "cw-gap-4": spacing === "Default",
          "cw-gap-2": spacing === "Compact",
        })}
      >
        <div
          className={classNames({
            "cw-self-start": alignTop,
          })}
        >
          {left}
        </div>
        <div className="cw-w-full cw-min-w-0">{right}</div>
      </div>
      <div
        className="line cw-absolute cw-border-muted cw-border-l cw-border-solid -cw-top-8 -cw-bottom-3 cw-z-[-1]"
        style={{
          left: spacing === "Default" ? 16 : 14, // expected item width of 32px
        }}
      />
    </div>
  );
};
