import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
import { EventColorCategory } from "@clockwise/client-commons/src/util/event-category-coloring";
import { createTagForMutation, Tags } from "@clockwise/client-commons/src/util/event-tag";
import { Button, Divider } from "@clockwise/design-system";
import { RepeatingEventSaveOption } from "@clockwise/schema";
import { webappDefaultWindowName } from "@clockwise/web-commons/src/constants/route.constants";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import { LinkOff } from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useBoolean } from "usehooks-ts";
import useEditExistingEvent from "../web-app-calendar/hooks/useEditExistingEvent";
import { getBodyForSaveChanges } from "../web-app-calendar/hooks/useEditExistingEvent.util";
import { CategoryOption, EventDetails } from "../web-app-calendar/hooks/useEventDetails";
import { DisablePersonalSyncDocument } from "./__generated__/DisablePersonalSync.v2.generated";
import { EventElementsWrap } from "./atoms/EventElementsWrap";
import { ExternalLinkIcon } from "./atoms/ExternalLinkIcon";
import { LoadingProgress } from "./atoms/history/LoadingProgress";
import { InlineAction } from "./atoms/InlineAction";
import { WarningMessage } from "./atoms/WarningMessage";
import { CardWrapper } from "./CardWrapper";
import { ECCategorySelector as CategorySelector } from "./molecules/ECCategorySelect";
import { ECDeleteConfirm } from "./molecules/ECDeleteConfirm";
import { ECTitle as Title } from "./molecules/ECTitle";
import { Time } from "./molecules/Time";
import { EventCardWarning } from "./utils/getEventCardWarning";

type Props = Pick<EventDetails, "title" | "category" | "eventPermissions"> & {
  colorSettings?: CategoryOption[];
  eventCalendarId: string;
  id: string;
  isOwnEvent: boolean;
  isRecurring: boolean;
};

export const PersonalSyncCard = (props: Props) =>
  props.isOwnEvent ? <OwnPersonalSyncCard {...props} /> : <OthersPersonalSyncCard />;

export const OwnPersonalSyncCard = ({
  category: initCategory = EventColorCategory.AdHoc,
  colorSettings,
  eventCalendarId,
  eventPermissions,
  id = "",
  isRecurring,
  title = "Personal Event",
}: Props) => {
  const track = useTracking();
  const updateActiveEvent = useUpdateActiveEvent();
  const [category, setCategory] = useState<EventColorCategory>(initCategory);
  const {
    value: isConfirmDeleteShowing,
    setTrue: setConfirmShowing,
    setFalse: setConfirmHiding,
  } = useBoolean(false);

  const [removeSync] = useGatewayMutation(DisablePersonalSyncDocument, {
    onCompleted: (data) => {
      if (data.disablePersonalSync?.success) {
        track(TrackingEvents.EVENT.REMOVE_SYNCED_PERSONAL_CALENDAR_EVENT);
        toast.success("This event will no longer be synced from your personal calendar");
        updateActiveEvent(null);
      } else {
        toast.error("Sorry, there was a problem removing your synced event");
      }
    },
    onError: () => toast.error("Sorry, there was a problem removing your synced event"),
  });

  const { onSaveEventChanges, savingChanges } = useEditExistingEvent(
    id,
    eventCalendarId,
    isRecurring,
    {
      onEditCompleted: () => {
        track(TrackingEvents.EVENT.UPDATED, {
          externalEventId: id,
          calendarId: eventCalendarId,
          isPersonalSync: true,
        });
        toast.success("Event saved");
        updateActiveEvent(null);
      },
      onEditError: () => toast.error("Event failed to save"),
    },
  );

  const changesMap = {
    category: category !== initCategory,
  };

  const changesCount = getChangesCount(changesMap);

  const handleClose = () => {
    updateActiveEvent(null);
  };

  const handleChangeCategory = (newCategory: EventColorCategory) => {
    setCategory(newCategory);
  };

  const handleSyncRemoved = () => {
    void removeSync({
      variables: {
        externalEventId: id,
      },
      refetchQueries: ["CalendarEventsQuery"],
    });
    track(TrackingEvents.EVENT.REMOVE_SYNCED_PERSONAL_CALENDAR_EVENT);
  };

  const handleSave = () => {
    void onSaveEventChanges(
      getBodyForSaveChanges(
        {
          sendNotifications: false,
          repeatingEventSaveOption: RepeatingEventSaveOption.ThisInstanceOnly,
          category: createTagForMutation(Tags.EventColoringCategory, category),
        },
        eventPermissions,
      ),
    );
  };

  return (
    <CardWrapper
      changesCount={changesCount}
      footer={
        <div className={classNames("cw-flex cw-justify-between", "cw-relative", "cw-p-3")}>
          {isConfirmDeleteShowing && (
            <ECDeleteConfirm onCancelEvent={handleSyncRemoved} onHide={setConfirmHiding} isHold />
          )}
          <Button
            aria-label="Unsync from calendar"
            onClick={setConfirmShowing}
            sentiment="destructive"
            size="xsmall"
            startIcon={LinkOff}
            variant="outlined"
          >
            Unsync from calendar
          </Button>
          <Button
            aria-label="Save"
            disabled={changesCount <= 0 || savingChanges}
            onClick={handleSave}
            sentiment="positive"
            size="xsmall"
            variant="solid"
          >
            Save
          </Button>
        </div>
      }
      header={<div className="cw-font-semibold">Event</div>}
      onClose={handleClose}
    >
      {savingChanges && <LoadingProgress />}
      <Title eventName={title} readOnly />
      <Time readOnly />
      <CategorySelector
        allColorSettings={colorSettings}
        currentCategory={category}
        onCategoryChange={handleChangeCategory}
      />
      <Divider spacing="sm" inset />
      <EventElementsWrap name="settings">
        <div className="cw-body-sm">
          This event was automatically synced from your personal calendar. Only you can see its
          details, coworkers with access to your calendar will see an event with a title of "Busy
          (via Clockwise)."{" "}
          <InlineAction
            ariaLabel="Edit Personal Calendar Sync Settings"
            fontSize="" // empty to inherit from container
            icon={ExternalLinkIcon}
            label="Edit Settings"
            onClick={openPersonalSyncSettings}
          />
        </div>
      </EventElementsWrap>
    </CardWrapper>
  );
};

export const OthersPersonalSyncCard = () => {
  const updateActiveEvent = useUpdateActiveEvent();

  const handleClose = () => {
    updateActiveEvent(null);
  };

  return (
    <CardWrapper
      footer={null}
      header={<div className="cw-font-semibold">Event</div>}
      onClose={handleClose}
    >
      <WarningMessage type={EventCardWarning.NotYourEvent} />
      <Title eventName="Busy (via Clockwise)" readOnly={true} />
      <Time readOnly />
      <Divider spacing="sm" inset />
      <EventElementsWrap name="info">
        <div className="cw-body-sm">
          This event was automatically synced from a personal calendar by Clockwise{" "}
          <InlineAction
            ariaLabel="Learn more about personal calendar sync"
            fontSize="" // empty to inherit from container
            icon={ExternalLinkIcon}
            label="Learn more"
            onClick={openPersonalSyncLearnMore}
          />
        </div>
      </EventElementsWrap>
    </CardWrapper>
  );
};

const getChangesCount = (obj: { [key: string]: boolean }) => {
  let count = 0;
  for (const k in obj) {
    if (obj[k]) {
      count++;
    }
  }
  return count;
};

const openPersonalSyncSettings = () =>
  window.open(getUrlForFeature("accountPreferences"), webappDefaultWindowName);

const openPersonalSyncLearnMore = () =>
  window.open(articleUrls.personalCalSync, webappDefaultWindowName);
