import { Person, PersonSelector } from "#clockwise/web-commons/src/ui/person-selector";
import { useMutation, useQuery } from "@apollo/client";
import { getCurrentOrg, getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Button, Divider, Modal, TextArea } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import * as ISchema from "@clockwise/schema";
import { debounce } from "lodash";
import pluralize from "pluralize";
import React, { useCallback } from "react";
import { TrackingEvents, useTracking } from "../../util/analytics.util";
import type { PendingPersons, SuggestedPersons } from "./OnboardingInviteDialog.util";
import {
  getPersonsFromEventAttendees,
  getPersonsFromSearchPersons,
  getPersonsFromSuggestions,
} from "./OnboardingInviteDialog.util";
import {
  OnboardingOrgInviteDocument,
  OnboardingOrgInviteQuery,
  UpdateOrgInviteMutationDocument,
} from "./__generated__/OnboardingInviteDialog.generated";

// Person Select Renderer //
interface PersonSelectorRendererProps {
  selected: Person[];
  addSelected: (person: Person) => void;
  removeSelected: (person: Person) => void;
  orgId: string;
  suggestedPersons: SuggestedPersons;
  pendingPersons: PendingPersons;
  variant: "flexible-meetings" | "group-link";
}

const PersonSelectorRenderer = ({
  selected,
  addSelected,
  removeSelected,
  orgId,
  suggestedPersons,
  pendingPersons,
  variant,
}: PersonSelectorRendererProps) => {
  const [query, setQuery] = React.useState("");

  const debouncedSetNetworkQuery = React.useCallback(
    debounce((value: string) => {
      setQuery(value);
    }, 250),
    [],
  );

  const { data } = useQuery(OnboardingOrgInviteDocument, {
    variables: { queryInput: query },
  });

  const getSuggestionsFromData = useCallback(
    (queryData: OnboardingOrgInviteQuery) => {
      const org = getCurrentOrg(queryData?.viewer, orgId);
      const orgPersons = org?.orgPersonListPaginatedErrorable;
      if (!orgPersons) {
        return [];
      }
      const orgPersonList = getValue(orgPersons)?.searchPersons || [];
      return getPersonsFromSearchPersons(orgPersonList, suggestedPersons, pendingPersons, selected);
    },
    [pendingPersons, selected, suggestedPersons],
  );

  const suggestions = React.useMemo(() => {
    if (query.length < 1) {
      return getPersonsFromSuggestions(suggestedPersons, selected);
    }
    return data ? getSuggestionsFromData(data) : [];
  }, [data, getSuggestionsFromData, query.length, selected, suggestedPersons]);

  const selectedPeople = React.useMemo(() => {
    return selected.map((selectedPerson) => ({
      ...selectedPerson,
      name: {
        givenName: selectedPerson.name.givenName || null,
        familyName: selectedPerson.name.familyName || null,
      },
    }));
  }, [selected]);

  const people = React.useMemo(() => {
    return suggestions.map((suggestion) => ({
      ...suggestion,
      name: {
        givenName: suggestion.name.givenName || null,
        familyName: suggestion.name.familyName || null,
      },
    }));
  }, [suggestions]);

  return (
    <div className="cw-mb-4 cw-border cw-rounded-lg">
      <PersonSelector
        onRemove={(person) => removeSelected(person)}
        onSelect={(person) => addSelected(person)}
        people={people}
        placeholder={variant === "group-link" ? "Search by email" : "Search by name or email"}
        selectedPeople={selectedPeople}
        onSearch={debouncedSetNetworkQuery}
        hasChips
      />
    </div>
  );
};

// Main component //

const COUNT_MAX = 250;

interface IProps {
  onClose: () => void;
  attendeePersonsToInvite: Pick<ISchema.Person, "primaryEmail" | "profile">[];
  orgId: string;
  suggestedPersons: SuggestedPersons;
  pendingPersons: PendingPersons;
  isLoading: boolean;
  setInvitationMessage: (msg: string) => void;
  variant?: "flexible-meetings" | "group-link";
}

export const OnboardingInviteDialog = ({
  onClose,
  attendeePersonsToInvite,
  orgId,
  isLoading,
  suggestedPersons,
  pendingPersons,
  setInvitationMessage,
  variant = "flexible-meetings",
}: IProps) => {
  const trackEvent = useTracking();
  const [selected, setSelected] = React.useState<Person[]>(
    getPersonsFromEventAttendees(attendeePersonsToInvite),
  );
  const [textValue, setTextValue] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [textLengthError, setTextLengthError] = React.useState(false);

  const [updateOrgInvite] = useMutation(UpdateOrgInviteMutationDocument, {
    onCompleted: () => {
      setInvitationMessage(`${pluralize("Invitation", selected.length)} sent`);
    },
  });

  React.useEffect(() => {
    if (count > COUNT_MAX) {
      setTextLengthError(true);
    } else if (textLengthError) {
      setTextLengthError(false);
    }
  }, [count, textLengthError]);

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
    setCount(e.target.value.length);
  };

  const onSendInvites = () => {
    if (textLengthError) return;
    if (selected.length < 1) {
      setInvitationMessage("No invitees selected");
    } else {
      const calendarIds = selected.map((i) => i.email);
      const input: ISchema.UpdateOrgInviteInput = { calendarIds: calendarIds, orgRelayId: orgId };
      if (textValue.length > 0) {
        input.note = textValue;
      }
      trackEvent(TrackingEvents.ONBOARDING.ONBOARDING_INVITES_SENT_VIA_DIALOG, {
        calendarIds: calendarIds,
      });
      void updateOrgInvite({ variables: { input: input } });
      onClose();
    }
  };

  const addSelected = (person: Person) => {
    setSelected((prev) => [...prev.filter((p) => p.email !== person.email), person]);
  };

  const removeSelected = (person: Person) => {
    setSelected((prev) => prev.filter((p) => p.email !== person.email));
  };

  const headerText =
    variant === "flexible-meetings"
      ? "Would you like to invite the attendees to try flexible meetings together?"
      : "Invite teammates you'd like to include on a group Scheduling Link";

  const renderContent = () => {
    return (
      <div className="cw-flex cw-flex-col">
        <div className="cw-flex cw-flex-col cw-pb-0">
          <div className="cw-text-base cw-my-2">
            When everyone you meet with is using Clockwise, it increases the likelihood of finding a
            better time for your meetings.{" "}
          </div>
          <div className="cw-flex cw-flex-col cw-pt-2 cw-pb-6">
            {isLoading ? (
              renderLoader()
            ) : (
              <PersonSelectorRenderer
                selected={selected}
                addSelected={addSelected}
                removeSelected={removeSelected}
                orgId={orgId}
                suggestedPersons={suggestedPersons}
                pendingPersons={pendingPersons}
                variant={variant}
              />
            )}
            <div className="cw-mb-1">
              <TextArea
                placeholder="Add an optional message"
                value={textValue}
                onChange={onTextChange}
                fullWidth
                minRows={3}
                error={textLengthError}
                errorMessage={textLengthError ? "Message is too long" : ""}
              />
            </div>
            <span className="cw-self-end cw-caption">{`${count} / ${COUNT_MAX}`} </span>
          </div>
        </div>
        <div className="cw-flex cw-flex-col">
          <Divider spacing={0} />
          <div className="cw-flex cw-justify-between cw-pt-2">
            <Button size="large" variant="text" sentiment="neutral" onClick={onClose}>
              {variant === "flexible-meetings" ? "Not Now" : "Close"}
            </Button>
            <Button
              size="large"
              variant="text"
              sentiment="positive"
              onClick={onSendInvites}
              disabled={!selected.length}
            >
              Send Invite
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderLoader = () => {
    return (
      <div className="cw-flex cw-justify-center cw-items-center cw-my-2">
        <Loader size="sm" />
      </div>
    );
  };

  // If this ever needs to be rendered within a ChromeDialog rather than a Dialog,
  // i'd consider moving the Dialog or ChromeDialog decision up to the parent and having this
  // component just render what is being renderd by renderContent().
  return (
    <Modal size="lg" padding="xl" title={headerText} opened onClose={onClose}>
      {renderContent()}
    </Modal>
  );
};
