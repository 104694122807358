import { fg_muted, fg_positive, fg_warning } from "@clockwise/design-system/tokens";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import classNames from "classnames";
import { CheckCircle, XCircle } from "phosphor-react";
import pluralize from "pluralize";
import React from "react";
import { useToggle } from "usehooks-ts";
import { EventOperations } from "./types";
import { getOperationText } from "./util/getOperationText";

type EventBatchItem = {
  externalEventId: string;
  operation: EventOperations;
  success: boolean;
  title: string;
};

type Props = {
  events: EventBatchItem[];
  onViewEvent?: (eventId: string) => void;
};

export const BatchSummary = ({ events, onViewEvent }: Props) => {
  if (events.length === 0) {
    return null;
  }

  const successEvents = events.filter((event) => event.success);
  const failureEvents = events.filter((event) => !event.success);

  return (
    <div className="cw-flex cw-flex-col cw-w-[400px]">
      <div className="cw-flex cw-flex-col cw-gap-2 ">
        <div className="cw-caption cw-group ">
          <div className="cw-flex cw-flex-col cw-gap-4">
            <EventGroup
              events={successEvents}
              icon={<CheckCircle size={16} color={fg_positive} weight="fill" />}
              initOpen={true}
              onViewEvent={onViewEvent}
            />
            <EventGroup
              error
              events={failureEvents}
              icon={<XCircle size={16} color={fg_warning} weight="fill" />}
              initOpen={successEvents.length === 0}
              onViewEvent={onViewEvent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const EventGroup = ({
  error = false,
  events,
  icon,
  initOpen = false,
  onViewEvent,
}: {
  error?: boolean;
  icon: React.ReactNode;
  initOpen?: boolean;
  events: EventBatchItem[];
  onViewEvent?: (eventId: string) => void;
}) => {
  const [expanded, toggleExpanded] = useToggle(initOpen);

  if (events.length === 0) {
    return null;
  }

  const operationSet = [...new Set(events.map((item) => item.operation))];
  const hasMixedOperations = operationSet.length > 1;
  const labelText = `${pluralize("event", events.length, true)} ${
    hasMixedOperations
      ? `${error ? "not updated " : "updated "}`
      : getOperationText(operationSet[0], !error)
  }`;

  return (
    <div className="cw-flex cw-flex-col cw-items-start cw-gap-2">
      <div
        className={classNames(
          "cw-flex cw-flex-row cw-items-center cw-w-full cw-justify-between",
          "cw-cursor-pointer",
        )}
        onClick={toggleExpanded}
      >
        <div className="cw-flex cw-flex-row cw-items-center cw-gap-1">
          {icon}
          <div className="cw-text-13 cw-font-semibold">{labelText}</div>
        </div>
        {expanded ? (
          <ExpandLess className="cw-h-4 cw-w-4 cw-stroke-2" fill={fg_muted} />
        ) : (
          <ExpandMore className="cw-h-4 cw-w-4 cw-stroke-2" fill={fg_muted} />
        )}
      </div>
      {expanded ? (
        <div className="cw-flex cw-flex-col cw-gap-1 cw-pl-5 cw-pr-3 cw-max-w-full cw-line-clamp-1">
          {events
            .sort(byTitle)
            .sort(byOperation)
            .map((event) => (
              <Event
                operation={event.operation}
                externalEventId={event.externalEventId}
                key={event.title}
                onViewEvent={onViewEvent}
                showOperation={hasMixedOperations}
                success={event.success}
                title={event.title}
              />
            ))}
        </div>
      ) : null}
    </div>
  );
};

const Event = ({
  externalEventId,
  onViewEvent,
  operation,
  showOperation,
  success,
  title,
}: {
  externalEventId: string;
  onViewEvent?: (eventId: string) => void;
  operation: EventOperations;
  showOperation: boolean;
  success: boolean;
  title: string;
}) => {
  const isSuccessfullyCancelled = operation === "CANCEL" && success;
  const isFailedCreate = operation === "ADD" && !success;
  const operationText = getOperationText(operation, success);

  const handleViewEvent = (e: React.MouseEvent) => {
    e.stopPropagation();
    onViewEvent?.(externalEventId);
  };

  return (
    <div
      className="cw-flex cw-flex-row cw-gap-1 cw-line-clamp-1"
      onClick={isSuccessfullyCancelled || isFailedCreate ? undefined : handleViewEvent}
    >
      <div
        className={classNames(
          "cw-bg-neutral cw-border cw-border-solid cw-border-subtle cw-px-1 cw-rounded",
          "cw-truncate",
          { "cw-bg-warning cw-border-warning cw-text-warning": !success },
          { "cw-cursor-pointer": onViewEvent && !isSuccessfullyCancelled },
          { "cw-line-through cw-italic": isSuccessfullyCancelled },
        )}
      >
        {title}
      </div>
      {showOperation && <div className="cw-italic cw-font-light">{operationText}</div>}
    </div>
  );
};

const byTitle = (a: { title: string }, b: { title: string }) => a.title.localeCompare(b.title);

const byOperation = (a: { operation: string }, b: { operation: string }) =>
  a.operation.localeCompare(b.operation);
