import { getEditSchedulingLinkPath } from "@clockwise/web-commons/src/util/scheduling.util";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CancelBookingRendererGateway } from "../components/scheduling-link/CancelBookingRendererGateway";

export function LinkCancelPage() {
  const navigate = useNavigate();

  return (
    <CancelBookingRendererGateway
      onClickMyLinks={() => navigate("/my-links")}
      onEditLink={(linkName, slug) => navigate(getEditSchedulingLinkPath(linkName, slug))}
    />
  );
}
