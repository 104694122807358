import classNames from "classnames";
import React from "react";
import { hashString } from "../hashString";

const TEAM_AVATAR_COLOR_ROTATION: TeamAvatarColor[] = [
  "lime",
  "sunset",
  "sorbet",
  "shore",
  "crystal",
  "lemon",
  "butter",
  "eggshell",
  "beige",
];

export const getColorFromString = (name: string) =>
  TEAM_AVATAR_COLOR_ROTATION[hashString(name) % TEAM_AVATAR_COLOR_ROTATION.length];

type TeamAvatarColor =
  | "lime"
  | "sunset"
  | "sorbet"
  | "shore"
  | "crystal"
  | "lemon"
  | "butter"
  | "eggshell"
  | "beige";

export const getTeamInitials = (teamName: string) => {
  const cleansedTeamName = teamName
    .split("")
    .filter((letter) => !["&", '"', "'", "#"].includes(letter))
    .join("")
    .trim();
  const firstInitial = (cleansedTeamName.split(/\s+/)[0] || "")[0]?.toUpperCase();
  const secondInitial = (cleansedTeamName.split(/\s+/)[1] || "")[0]?.toUpperCase();

  return [firstInitial, secondInitial];
};

export const TeamAvatar = ({
  id,
  teamName,
  size = "normal",
}: {
  id: string;
  teamName: string;
  size?: "normal" | "large";
}) => {
  const [firstInitial, secondInitial] = getTeamInitials(teamName);

  const sizePx = size === "normal" ? 24 : 40;
  const textSize = size === "normal" ? "cw-text-11" : "cw-text-20";
  const padding = size === "normal" ? "cw-pt-0" : "cw-pt-1";
  const color = getColorFromString(id);

  return (
    <>
      <div
        style={{
          width: sizePx,
          height: sizePx,
        }}
        className={classNames(
          " cw-text-[#003F2E]  cw-font-bold cw-flex cw-rounded-full cw-items-center cw-justify-center",
          textSize,
          padding,
          {
            // We have to use these cause they don't exist in the design system yet
            "cw-bg-[#B6FF80]": color == "lime",
            "cw-bg-[#FF7000]": color == "sunset",
            "cw-bg-[#FFE5FF]": color == "sorbet",
            "cw-bg-[#BBFDF4]": color == "shore",
            "cw-bg-[#E2FFF9]": color == "crystal",
            "cw-bg-[#FFE800]": color == "lemon",
            "cw-bg-[#FFFFBA]": color == "butter",
            "cw-bg-[#FFFFEC]": color == "eggshell",
            "cw-bg-[#FDF9F7]": color == "beige",
          },
        )}
        aria-label={`${teamName || "team"} avatar initials`}
      >
        {firstInitial}
        {secondInitial}
      </div>
    </>
  );
};
