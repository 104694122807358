import { graphql } from "react-relay";

export const query = graphql`
  query WebSettingsTeamAvailabilityCalendarRelayQuery($orgRelayId: ID!) {
    viewer: viewer {
      id
    }
    org: node(id: $orgRelayId) {
      ... on Org {
        id
        primaryTeam {
          __typename
          ... on Team {
            teamId
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
        userTeams {
          __typename
          ... on TeamList {
            list {
              __typename
              teamId
              id
              teamId
              teamName
              teamCalendarId
              settings {
                __typename
                ... on TeamSettings {
                  manageTeamCalendar
                  m365TeamCalendarDetails {
                    __typename
                    ... on TeamCalendarDetails {
                      members {
                        status
                        personId
                      }
                    }
                    ... on GraphEntityError {
                      statusCode
                      message
                    }
                  }
                }
                ... on GraphEntityError {
                  statusCode
                  message
                }
              }
              userSettings {
                __typename
                ... on TeamUserSettings {
                  useTeamCalendar
                }
                ... on GraphEntityError {
                  statusCode
                  message
                }
              }
            }
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
        orgPersonForUserErrorable {
          __typename
          ... on OrgPerson {
            personId
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
      }
    }
  }
`;
