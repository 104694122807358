import { isEmpty } from "lodash";
import React from "react";
import { GQLConsequencesBlock } from "../../utils/types";
import { AIMarkdown } from "../ai-markdown";
import { ConsequencesDiffs } from "./ConsequencesDiffs";
import { useSetCalendarIdsForCalendarPeek } from "./useSetCalendarIdsForCalendarPeek";

export const ConsequencesBlock = ({
  consequencesBlock,
}: {
  consequencesBlock: GQLConsequencesBlock;
}) => {
  const { changesText, yourCalDiffs, otherCalDiffs } = consequencesBlock;

  useSetCalendarIdsForCalendarPeek(consequencesBlock);

  return (
    <div className="cw-mb-2">
      {changesText && (
        <div className="cw-body-sm cw-mb-2">
          <AIMarkdown text={changesText} />
        </div>
      )}
      {yourCalDiffs && !isEmpty(yourCalDiffs) && <ConsequencesDiffs diffs={yourCalDiffs} />}
      {otherCalDiffs && !isEmpty(otherCalDiffs) && <ConsequencesDiffs diffs={otherCalDiffs} />}
    </div>
  );
};
