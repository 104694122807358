import { Select, SelectOption } from "@clockwise/design-system";
import { Videocam } from "@clockwise/design-system/icons";
import { SvgIconComponent } from "@clockwise/icons";
import { ConferencingType } from "@clockwise/schema";
import { PreferredVideoConference } from "@clockwise/schema/v2";
import { MeetLogo } from "@clockwise/web-commons/src/components/svgs/MeetLogo";
import { TeamsLogo } from "@clockwise/web-commons/src/components/svgs/TeamsLogo";
import { ZoomLogo } from "@clockwise/web-commons/src/components/svgs/ZoomLogo";
import { routeToFeature } from "@clockwise/web-commons/src/util/routes.util";
import React, { useEffect, useState } from "react";
import { useUserConfrencingTypes } from "../../hooks/useUserConfrencingTypes";
import { WebSettingsContainer } from "../web-settings/web-settings-container";
import { useUpdatePreferredVideoConference } from "./useUpdatePreferredVideoConference";

const routeToZoom = () =>
  routeToFeature({
    featureName: "appSettings",
    routerState: { kind: "window", target: "_blank" },
    options: {
      paramsObj: { highlight: "true" },
      hash: "#zoom",
      relative: true,
    },
  });

type PreferredVCOption = {
  type: PreferredVideoConference;
  label: string;
  Icon: SvgIconComponent;
};

type PreferredVCProps = {
  value: PreferredVideoConference;
  loading: boolean;
};
export const PreferredVideoConferencing = ({ value, loading }: PreferredVCProps) => {
  const { conferencingTypes } = useUserConfrencingTypes();
  const [selected, setSelected] = useState(value);
  const [updatePreferredVC, { loading: updateLoading }] = useUpdatePreferredVideoConference();

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const onSelect = (option: PreferredVideoConference) => {
    setSelected(option);
    void updatePreferredVC({ preferredVideoConference: option });
  };

  return (
    <WebSettingsContainer>
      <div className="cw-mb-4 cw-heading-xl">Preferred video conferencing</div>
      <div className="cw-mb-6 cw-body-lg cw-text-muted">
        Clockwise will use this as the default when you schedule a new meeting.
      </div>
      <SelectPreferredVC
        enabledTypes={conferencingTypes}
        selected={selected}
        disabled={loading || updateLoading}
        onSelect={onSelect}
      />
    </WebSettingsContainer>
  );
};

type Props = {
  enabledTypes: ConferencingType[];
  selected: PreferredVideoConference;
  disabled: boolean;
  onSelect: (optipn: PreferredVideoConference) => void;
};
const SelectPreferredVC = ({ enabledTypes, selected, disabled, onSelect }: Props) => {
  const value = selected;
  const options: PreferredVCOption[] = [AutoOption];
  if (enabledTypes.includes(ConferencingType.ZOOM)) {
    options.push(ZoomOption);
  } else {
    options.push(ConnectZoomOption);
  }
  if (enabledTypes.includes(ConferencingType.MEET)) {
    options.push(MeetOption);
  }
  if (enabledTypes.includes(ConferencingType.TEAMS_FOR_BUSINESS)) {
    options.push(TeamsOption);
  }

  const handleSelect = (option: PreferredVideoConference) => {
    if (option === selected) return;
    if (disabled) return;

    if (option === PreferredVideoConference.Zoom && !enabledTypes.includes(ConferencingType.ZOOM)) {
      routeToZoom();
      return;
    }

    onSelect(option);
  };

  return (
    <Select
      disabled={disabled}
      onChange={handleSelect}
      size="medium"
      variant="default"
      value={value}
    >
      {options.map(({ type, label, Icon }) => (
        <SelectOption
          value={type}
          key={type}
          icon={Icon}
          iconProps={{ className: "cw-text-muted" }}
        >
          {label}
        </SelectOption>
      ))}
    </Select>
  );
};

const ZoomOption: PreferredVCOption = {
  type: PreferredVideoConference.Zoom,
  label: "Zoom",
  Icon: ZoomLogo,
};

const ConnectZoomOption: PreferredVCOption = {
  type: PreferredVideoConference.Zoom,
  label: "Connect Zoom account",
  Icon: ZoomLogo,
};

const MeetOption: PreferredVCOption = {
  type: PreferredVideoConference.Meet,
  label: "Google Meet",
  Icon: MeetLogo,
};

const TeamsOption: PreferredVCOption = {
  type: PreferredVideoConference.TeamsForBusiness,
  label: "Microsoft Teams",
  Icon: TeamsLogo,
};

const AutoOption: PreferredVCOption = {
  type: PreferredVideoConference.Auto,
  label: "Auto",
  Icon: Videocam,
};
