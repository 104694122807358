import { ToggleButtonGroup } from "@clockwise/design-system";
import React from "react";
import toast from "react-hot-toast";
import { CalendarMode, isCalendarMode } from "..";

export const CalendarModeToggle = ({
  calendarMode,
  onCalendarModeChange,
}: {
  calendarMode: CalendarMode;
  onCalendarModeChange: (view: CalendarMode) => void;
}) => {
  const handleCalendarModeChange = (value: string) => {
    if (!isCalendarMode(value)) {
      toast.error(`Invalid calendar mode: ${value}`);
      return;
    }

    onCalendarModeChange(value);
  };

  return (
    <ToggleButtonGroup
      aria-label="calendar mode toggle"
      value={calendarMode}
      size="lg"
      onChange={handleCalendarModeChange}
      options={[
        { value: "day", label: "Day" },
        { value: "week", label: "Week" },
      ]}
    />
  );
};
