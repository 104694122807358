import { ApolloError } from "@apollo/client";
import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { ConferencingType, EditEventAppliesTo } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useCallback } from "react";
import { CalendarEventsDocument } from "../../web-app-calendar/apollo/__generated__/CalendarEvents.v2.generated";
import { UpdateEventDocument, UpdateEventMutation } from "./__generated__/UpdateEvent.v2.generated";

export const useUpdateEvent = () => {
  const [updateEvent, { loading, error }] = useGatewayMutation(UpdateEventDocument);

  const onUpdateEvent = useCallback(
    async ({
      externalEventId,
      appliesTo,
      timeRange,
      timeZone,
      upsertAttendees,
      removeAttendees,
      title,
      description,
      recurrenceRule,
      location,
      conferenceType,
      callback,
      errorCallback,
      optimisticResponse,
    }: {
      externalEventId: string;
      appliesTo: EditEventAppliesTo;
      timeRange: string;
      timeZone: string;
      upsertAttendees: Array<{ email: string; optional: boolean }>;
      removeAttendees: string[];
      title?: string;
      description?: string;
      recurrenceRule?: RecurrenceRule | null;
      location?: string | null;
      conferenceType?: ConferencingType | null;
      callback?: (data: UpdateEventMutation) => void;
      errorCallback?: (error: ApolloError) => void;
      optimisticResponse?: UpdateEventMutation;
    }) => {
      await updateEvent({
        variables: {
          externalEventId,
          appliesTo,
          timeRange,
          timeZone,
          title,
          description,
          upsertAttendees,
          removeAttendees,
          recurrenceRule: recurrenceRule?.toString(),
          location,
          conferenceType,
        },
        onCompleted: (data) => {
          callback?.(data);
        },
        onError: (error) => {
          errorCallback?.(error);
        },
        optimisticResponse,
        refetchQueries: [CalendarEventsDocument],
        awaitRefetchQueries: true,
      });
    },
    [updateEvent],
  );

  return { onUpdateEvent, loading, error };
};
