import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { BillingGroupTypeEnum, InvitePolicyEnum } from "@clockwise/schema";
import { UserBillingGroupsDocument } from "../../graphql/__generated__/UserBillingGroups.generated";

type BillingGroup = {
  id: string | null;
  name: string | null;
  invitePolicy: InvitePolicyEnum | null;
  type: BillingGroupTypeEnum | null;
};

type UserBillingGroups = {
  primaryBillingGroup: BillingGroup | null;
  orgBillingGroups: BillingGroup[] | null;
};

export function useBillingGroups() {
  const userBillingGroups: UserBillingGroups = {
    primaryBillingGroup: null,
    orgBillingGroups: null,
  };
  const { data, loading, error } = useQuery(UserBillingGroupsDocument);

  const getBillingGroupList = () => {
    const billingGroups = data?.viewer?.user?.orgs?.edges?.[0]?.node?.billingGroups;
    return getValue(billingGroups)?.list || null;
  };

  const getPrimaryBillingGroupId = () => {
    const orgPersonErrorable =
      data?.viewer?.user?.orgs?.edges?.[0]?.node?.orgPersonForUserErrorable;
    const orgPerson = getValue(orgPersonErrorable);
    return getValue(orgPerson?.primaryBillingGroupIdErrorable)?.primaryBillingGroupId || null;
  };

  const billingGroupList = getBillingGroupList();
  const primaryBillingGroupId = getPrimaryBillingGroupId();

  if (!!billingGroupList) {
    userBillingGroups.primaryBillingGroup =
      billingGroupList.find((bg: BillingGroup) => bg.id === primaryBillingGroupId) || null;
    userBillingGroups.orgBillingGroups = billingGroupList;
  }

  return { userBillingGroups, loading, error };
}
