import { round } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import _useCurrentDateTime from "./hooks/useCurrentDateTime";

const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;
export const MINUTES_PER_DAY = HOURS_PER_DAY * MINUTES_PER_HOUR;

type Props = {
  useDateTime?: typeof _useCurrentDateTime;
};

export const usePercentageDayElapsed = (
  useDateTime: typeof _useCurrentDateTime,
): [number, DateTime] => {
  const dateTime = useDateTime();
  const minutesElapsed = dateTime.diff(dateTime.startOf("day"), "minutes").as("minutes");
  const percentDayElapsed = round(100 * (minutesElapsed / MINUTES_PER_DAY), 2);

  return [percentDayElapsed, dateTime];
};

export const TimeMarker = ({ useDateTime = _useCurrentDateTime }: Props) => {
  const [percentDayElapsed, dateTime] = usePercentageDayElapsed(useDateTime);
  const markerTop = `${percentDayElapsed}%`;

  return (
    <div
      cw-id="cw-current-time"
      aria-label={`Current time: ${dateTime.toLocaleString(DateTime.DATETIME_MED)}`}
    >
      <div
        className="cw-absolute cw-z-30 cw-left-0 cw-ml-[-6px] cw-mt-[-5px] cw-w-3 cw-h-3 cw-rounded-full cw-bg-destructive-emphasis"
        style={{ top: markerTop }}
      />
      <div
        className="cw-absolute cw-z-30 cw-w-full cw-bg-destructive-emphasis cw-h-[2px]"
        style={{ top: markerTop }}
      />
    </div>
  );
};

export default TimeMarker;
