// copied `Transform` from node_modules/@dnd-kit/utilities/dist/css.d.ts

import { Over } from "@dnd-kit/core";
import { MotionValue } from "framer-motion";
import { isNil, isNumber } from "lodash";
import { DateTime, Interval } from "luxon";
import { ICalPosition } from "../../calendar-positioner/types";

// Which is the type `useDraggable` returns ... but doesn't expose
export type Transform = {
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
};

// UX Note: Match GCal's behaviour
// This means that even if an event starts off with a duration
// that's smaller than 15min, do any resize adjustments
// will cause it to be at least 15min
export const MIN_DURATION_WHEN_RESIZING_IN_MINS = 15; // UX Note: matches gCal

export function leashToProximity({
  transform,
  inPx15minIncrement,
  subColumnLeftInPx,
}: {
  transform: Transform;
  inPx15minIncrement: number;
  subColumnLeftInPx: number;
}) {
  const oneIntervalInPx = inPx15minIncrement;

  return {
    ...transform,
    // Account for events that are in a subcolumn
    // This occurs mostly with others' events when multical D&D is attempted
    // by the user
    x: -subColumnLeftInPx,
    // Bound the y-axis to the nearest 15min increment
    // but do not snap to the grid
    y:
      transform.y > oneIntervalInPx
        ? oneIntervalInPx
        : transform.y < -oneIntervalInPx
        ? -oneIntervalInPx
        : transform.y,
  };
}

export function snapToColumn({
  transform,
  numOfDaysShown,
  over,
  columnIndex,
  eventFullWidthInPx,
}: {
  transform: Transform;
  numOfDaysShown: number;
  over: Over | null;
  columnIndex: number | undefined;
  eventFullWidthInPx: number;
}) {
  const isMultiDayView = numOfDaysShown > 1;

  const x = isMultiDayView
    ? over && isNumber(columnIndex)
      ? eventFullWidthInPx * (parseInt(`${over.id}`) - columnIndex)
      : transform.x // Logic to snap to grid when dragging between columns
    : 0; // Single day view (just snap to left edge of the column)

  const retVal = {
    ...transform,
    x,
  };

  return retVal;
}

export function snapTo15MinIncrements({
  transform,
  inPx15minIncrement,
}: {
  transform: Transform;
  inPx15minIncrement: number;
}) {
  const y = Math.ceil(transform.y / inPx15minIncrement) * inPx15minIncrement;

  const retVal = {
    ...transform,
    y,
  };

  return retVal;
}

export function snapToLeftSideOfDropZone({
  transform,
  subColumnLeftInPx,
}: {
  transform: Transform;
  subColumnLeftInPx: number;
}) {
  const x = transform.x - subColumnLeftInPx;

  return {
    ...transform,
    x,
  };
}

export function restrictToCalendarLeftRightBoundaries({
  transform,
  columnIndex,
  eventFullWidthInPx,
  subColumnLeftInPx,
  canDragAcrossColumns,
  numOfDaysShown,
}: {
  transform: Transform;
  columnIndex: number | undefined;
  eventFullWidthInPx: number;
  subColumnLeftInPx: number;
  canDragAcrossColumns: boolean | undefined;
  numOfDaysShown: number;
}) {
  if (isNil(columnIndex)) {
    return transform;
  }

  // need the `-subColumnLeftInPx` to account for events that are not at the left edge of the column
  const xMin = -(columnIndex * eventFullWidthInPx) - subColumnLeftInPx;
  const xMax = canDragAcrossColumns
    ? (numOfDaysShown - columnIndex - 1) * eventFullWidthInPx - subColumnLeftInPx
    : 0;

  const xRestricted = transform.x < xMin ? xMin : transform.x > xMax ? xMax : transform.x;

  return {
    ...transform,
    x: xRestricted,
  };
}

export function adjustForScrollWhileDragging({
  transform,
  scrollContainerRef,
  scrollTopOfScrollContainerBeforeDragging,
  inPx15minIncrement,
}: {
  transform: Transform;
  scrollContainerRef: React.RefObject<HTMLDivElement> | null;
  scrollTopOfScrollContainerBeforeDragging: number;
  inPx15minIncrement: number;
}) {
  // UX Note: Scrolling can happen before and during D&D
  // scrolling can get the `scrollContainerRef` into any subpixel
  // which interferes with the `snapToGrid` logic
  // Thus this function accounts for the scrolling before and during

  const currentScrollTop = scrollContainerRef?.current?.scrollTop || 0;
  const scrollDiff =
    (currentScrollTop - scrollTopOfScrollContainerBeforeDragging) % inPx15minIncrement;

  const retVal = {
    ...transform,
    y: transform.y - scrollDiff,
  };

  return retVal;
}

export function restrictToCalendarTopBottomBoundariesDnD({
  transform,
  position,
  calendarWeekHeightInPx,
  scrollContainerRef,
  scrollTopOfScrollContainerBeforeDragging,
}: {
  transform: Transform;
  position: ICalPosition | undefined;
  calendarWeekHeightInPx: number;
  scrollContainerRef: React.RefObject<HTMLDivElement> | null;
  scrollTopOfScrollContainerBeforeDragging: number;
}) {
  if (!position) {
    return transform;
  }

  const { y } = transform;
  const minYDelta = -(position.top / 100) * calendarWeekHeightInPx;
  const maxYDelta =
    calendarWeekHeightInPx - ((position.top + position.height) / 100) * calendarWeekHeightInPx;

  const currentScrollTop = scrollContainerRef?.current?.scrollTop || 0;
  const scrollDiff = currentScrollTop - scrollTopOfScrollContainerBeforeDragging;

  const minYDeltaAccountingForScroll = minYDelta - scrollDiff;
  const maxYDeltaAccountingForScroll = maxYDelta - scrollDiff;

  const boundedY =
    y > maxYDeltaAccountingForScroll
      ? maxYDeltaAccountingForScroll
      : y < minYDeltaAccountingForScroll
      ? minYDeltaAccountingForScroll
      : y;

  const retVal = {
    ...transform,
    y: boundedY,
  };

  return retVal;
}

export function snapTo15MinOffsets({
  transform,
  interval,
  snappedHeight,
  newEndTimeResize,
  inPx15minIncrement,
}: {
  transform: Transform;
  interval: Interval | undefined;
  snappedHeight: MotionValue<number>;
  newEndTimeResize: DateTime | undefined;
  inPx15minIncrement: number;
}) {
  if (!interval || !snappedHeight || !newEndTimeResize) {
    return transform;
  }

  let deltaTimeFromDragging = Math.ceil(transform.y / inPx15minIncrement) * 15;
  const originalDuration = interval.end.diff(interval.start, "minutes").minutes;
  if (originalDuration < MIN_DURATION_WHEN_RESIZING_IN_MINS) {
    // Math to adjust for the fact that anything smaller than `MIN_DURATION_WHEN_RESIZING_IN_MINS` still renders
    // as with `minHeight`. This is a fundamental difference than gCal that causes lots of math nuances
    deltaTimeFromDragging += MIN_DURATION_WHEN_RESIZING_IN_MINS;
  }

  const currentStartTime = interval.start;

  // Math note: Calculating `newEndTime` is sepcial for `originalDuration < MIN_DURATION_WHEN_RESIZING_IN_MINS`
  // because of how event height minimums
  // Tech Debt: we should wire this up to event height minimums set in `getPosition` in the components above
  const newEndTime =
    originalDuration < MIN_DURATION_WHEN_RESIZING_IN_MINS
      ? currentStartTime.plus({
          minutes: deltaTimeFromDragging,
        })
      : currentStartTime.plus({
          minutes: deltaTimeFromDragging + originalDuration,
        });

  const justTheMinutesOfNewEndTime = newEndTime.diff(newEndTime.startOf("hour"), "minutes").minutes;
  const offsetRemainder = justTheMinutesOfNewEndTime % MIN_DURATION_WHEN_RESIZING_IN_MINS;
  const offsetRemainderInPx = inPx15minIncrement * (offsetRemainder / 15);
  const yAdjustedForOffset = transform.y - offsetRemainderInPx;

  const retVal = {
    ...transform,
    y: yAdjustedForOffset,
  };

  return retVal;
}

export function restrictToCalendarBottomBoundariesResize({
  transform,
  position,
  calendarWeekHeightInPx,
  scrollContainerRef,
  scrollTopOfScrollContainerBeforeDragging,
}: {
  transform: Transform;
  position: ICalPosition | undefined;
  calendarWeekHeightInPx: number;
  scrollContainerRef: React.RefObject<HTMLDivElement> | null;
  scrollTopOfScrollContainerBeforeDragging: number;
}) {
  if (!position) {
    return transform;
  }

  const { y } = transform;
  const maxYDelta =
    calendarWeekHeightInPx - ((position.top + position.height) / 100) * calendarWeekHeightInPx;

  const currentScrollTop = scrollContainerRef?.current?.scrollTop || 0;
  const scrollDiff = currentScrollTop - scrollTopOfScrollContainerBeforeDragging;

  const maxYDeltaAccountingForScroll = maxYDelta - scrollDiff;

  // Math Note: note that we don't have to handle minYDelta for resize in here
  // because it's already accounted for the minimum 15 minute logic somewhere else
  const boundedY = Math.min((y as unknown) as number, maxYDeltaAccountingForScroll);

  const retVal = {
    ...transform,
    y: boundedY,
  };

  return retVal;
}
