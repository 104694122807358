import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { SupportedConferenceTypesDocument } from "./__generated__/supportedConferenceTypes.v2.generated";

export const useSupportedConferenceTypes = () => {
  const { loading, data } = useGatewayQuery(SupportedConferenceTypesDocument);

  const supportedConferenceTypes = data?.supportedConferenceTypes || [];
  const preferredVideoConference = data?.userSettings?.preferredVideoConference;

  return { supportedConferenceTypes, preferredVideoConference, loading };
};
