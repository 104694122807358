import { ApolloError } from "@apollo/client";
import { logger } from "@clockwise/client-commons/src/util/logger";
import {
  PreferredVideoConference,
  UpdatePreferredVideoConferenceInput,
} from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useUserConfrencingTypes } from "../../hooks/useUserConfrencingTypes";
import { UpdatePreferredVideoConferenceDocument } from "./__generated__/UserSettings.v2.generated";

type UseUpdatePreferredVideoConferenceResult = [
  (input: UpdatePreferredVideoConferenceInput) => Promise<void>,
  { loading: boolean; error: ApolloError | undefined },
];

const successMessageVCLabel = (defaultVC: string): Record<PreferredVideoConference, string> => ({
  Auto: `Zoom, if available${defaultVC}`,
  Zoom: "Zoom",
  Meet: "Google Meet",
  TeamsForBusiness: "Microsoft Teams",
});

export const useUpdatePreferredVideoConference = (): UseUpdatePreferredVideoConferenceResult => {
  const { canUseTeams, canUseMeet } = useUserConfrencingTypes();

  const [updatePreferredVC, { loading, error }] = useGatewayMutation(
    UpdatePreferredVideoConferenceDocument,
    {
      onError: (error) => {
        logger.error(`Failed to update preferred video conference: ${error.message}`);
        toast.error("An error occurred while updating preferred video conference");
      },
    },
  );

  const onUpdatePreferredVC = useCallback(
    async (input: UpdatePreferredVideoConferenceInput) => {
      const result = await updatePreferredVC({ variables: { input } });
      let ecosystemVCLabel = "";
      if (canUseMeet) {
        ecosystemVCLabel = ", else Google Meet";
      } else if (canUseTeams) {
        ecosystemVCLabel = ", else Microsoft Teams";
      }

      if (
        result?.data?.updatePreferredVideoConference?.preferredVideoConference ===
        input.preferredVideoConference
      ) {
        toast.success(
          `Great! We will set ${
            successMessageVCLabel(ecosystemVCLabel)[input.preferredVideoConference]
          } as the default when you schedule a new meeting.`,
        );
      }
    },
    [updatePreferredVC, canUseMeet, canUseTeams],
  );

  return [onUpdatePreferredVC, { loading, error }];
};
