import React from "react";
import * as ISchema from "../../../__schema__";
import { TeamSettingsSearch } from "../../../components/teams-settings/team-settings-search";
import { augmentTeamMembers } from "../../../util/team.util";
import { getCurrentWorkingGroup } from "../../../util/working-group.util";
import {
  createSettingsViewId,
  TeamSections,
  TeamsSections,
  WebSettingsContext,
  WebSettingsSections,
} from "../web-settings";

interface TeamMembersProps {
  org: ISchema.IOrg;
  team: ISchema.ITeam;
}

export const TeamMembers = (props: TeamMembersProps) => {
  const { onChangeSettingsView, webSettingsRefetch } = React.useContext(WebSettingsContext);

  const [locallyInvitedMembers, setLocallyInvitedMembers] = React.useState<
    Record<string, { invited: boolean; person: ISchema.IOrgPerson }>
  >({});

  const onInvitedMembersChange = (
    locallyInvitedMembers: Record<string, { invited: boolean; person: ISchema.IOrgPerson }>,
  ) => {
    setLocallyInvitedMembers(locallyInvitedMembers);
  };

  const { org, team } = props;

  const workingGroup = getCurrentWorkingGroup(org);
  const suggestedCalendarIds = (workingGroup && workingGroup.calendarIds) || [];

  return (
    <div cw-id={`web-settings-${TeamSections.Members}`} id="teammates" className="cw-mb-7">
      <div className="cw-heading-xl cw-mb-4">Members</div>
      <TeamSettingsSearch
        org={org}
        team={team}
        defaultMembers={augmentTeamMembers(
          team.teamMembers,
          suggestedCalendarIds,
          locallyInvitedMembers,
        )}
        refetchTeams={() => {
          // refetch web-settings
          webSettingsRefetch();
          onChangeSettingsView(createSettingsViewId(WebSettingsSections.Teams, TeamsSections.Team));
        }}
        invitedMembers={locallyInvitedMembers}
        onInvitedMembersChange={onInvitedMembersChange}
      />
    </div>
  );
};
