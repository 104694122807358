import { colors, greys } from "@clockwise/web-commons/src/styles/color.styles";
import spacing from "@clockwise/web-commons/src/styles/spacing.styles";

export const scopeContainer = {
  display: "grid",
  gap: "15px 4%",
  gridTemplateColumns: "48% 48%",
} as const;

export const scope = {
  width: "100%",
} as const;

export const icon = {
  color: greys.slate,
  hoverColor: greys.standard,
} as const;

export const labelMarginCorrection = {
  margin: "3px 0 0 5px",
} as const;

export const deleteButton = {
  color: greys.slate,
  marginTop: spacing.std,
} as const;

export const needsUpdate = {} as const;

export const calloutContainer = {
  display: "flex",
  alignItems: "center",
} as const;

export const calloutIcon = {
  color: colors.yellow.darker,
  marginRight: 10,
} as const;

export const calloutLabel = {
  flexGrow: 1,
} as const;

export const updateButton = {
  color: colors.yellow.darker,
  justifyContent: "flex-end",
} as const;
