import classNames from "classnames";
import React from "react";
import { CustomWeeklyHoursEditor } from "./CustomWeeklyHoursEditor";
import { DateSpecificHours } from "./DateSpecificHours";
import { MeetingHours } from "./types";

interface Props {
  meetingHours: MeetingHours;
  onChange: (updatedMeetingHours: MeetingHours, isValid: boolean) => void;
  error?: boolean;
  errorMessage?: string;
}
export const CustomMeetingHours = ({
  meetingHours,
  onChange,
  error = false,
  errorMessage = "",
}: Props) => {
  const [valid, setValid] = React.useState(true);

  const handleDateSpecificChange = (meetingHours: MeetingHours) => {
    // Date-specific hours are always valid so we can just use the weekly hours validity
    onChange(meetingHours, valid);
  };

  const handleWeeklyHoursChange = (meetingHours: MeetingHours, isValid: boolean) => {
    // Weekly hours can be in an invalid state. Update the validity state and pass it to onChange.
    setValid(isValid);
    onChange(meetingHours, isValid);
  };

  return (
    <div
      className={classNames("cw-inline-block cw-min-w-[435px] cw-p-4 cw-space-y-2", {
        "cw-bg-neutral": !error,
        "cw-bg-destructive": error,
      })}
    >
      <div className="cw-space-y-2">
        <div className="cw-heading-sm">Weekly hours</div>
        <CustomWeeklyHoursEditor meetingHours={meetingHours} onChange={handleWeeklyHoursChange} />
      </div>
      <div className="cw-space-y-2">
        <div className="cw-heading-sm">Date-specific hours</div>
        <DateSpecificHours meetingHours={meetingHours} onChange={handleDateSpecificChange} />
      </div>
      <div className="cw-text-destructive cw-caption cw-mt-1 cw-max-w-full">{errorMessage}</div>
    </div>
  );
};
