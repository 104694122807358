import React from "react";

interface IProps {
  className?: string;
  children: React.ReactNode;
}

export const ResultsPane = ({ className = "", children }: IProps) => {
  return (
    <div className={`cw-bg-neutral cw-p-4 cw-rounded cw-space-y-2 cw-min-h-[100px] ${className}`}>
      {children}
    </div>
  );
};
