import makeMutationUpdateRefetchBadCacheHandler from "../../../util/makeMutationUpdateRefetchBadCacheHandler";
import { FocusTimeGoalsDocument } from "../graphql/__generated__/FocusTimeGoals.generated";
import useFocusTimeSettings from "./useFocusTimeSettings";
import useFocusTimeToggle from "./useFocusTimeToggle";
import useFocusTimeUpdate from "./useFocusTimeUpdate";

type Options = {
  onSuccess?: () => void;
  onFail?: () => void;
};

const useFocusTime = (options?: Options) => {
  const { settings, loading, error: loadError, orgId, refetch } = useFocusTimeSettings();

  const onUpdate = makeMutationUpdateRefetchBadCacheHandler(
    "focus time",
    FocusTimeGoalsDocument,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    refetch,
  );

  const { update, loading: updating, error: updateError } = useFocusTimeUpdate(
    String(orgId),
    settings,
    { onCompleted: options?.onSuccess, onError: options?.onFail, onUpdate },
  );

  const { toggle, loading: toggling, error: toggleError } = useFocusTimeToggle(
    String(orgId),
    settings,
    { onCompleted: options?.onSuccess, onError: options?.onFail, onUpdate },
  );

  return {
    settings,
    loadError,
    loading,
    update,
    updating,
    updateError,
    toggle,
    toggling,
    toggleError,
  };
};

export default useFocusTime;
