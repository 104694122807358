import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CheckLinkNameMutationVariables = Types.Exact<{
  linkName: Types.Scalars['String'];
}>;


export type CheckLinkNameMutation = { __typename: 'Mutation', checkLinkNameAvailable: boolean | null };


export const CheckLinkNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CheckLinkName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"linkName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkLinkNameAvailable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"linkName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"linkName"}}}]}]}}]} as unknown as DocumentNode<CheckLinkNameMutation, CheckLinkNameMutationVariables>;