import { Button } from "@clockwise/design-system";
import { Add, Close } from "@clockwise/icons";
import { sortBy } from "lodash";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { DateOverrideModal } from "./DateOverrideModal";
import { DateSetting, MeetingHours, TimeRange } from "./types";
import { formatTime } from "./utils";

const formatTimeRange = ({ startTime, endTime }: TimeRange) => {
  return `${formatTime(startTime)} – ${formatTime(endTime)}`;
};

interface DateSpecificHoursProps {
  meetingHours: MeetingHours;
  onChange: (updatedMeetingHours: MeetingHours) => void;
}

export const DateSpecificHours = ({ meetingHours, onChange }: DateSpecificHoursProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState(0);

  const openDateSpecificHoursModal = () => {
    setModalOpen(true);
    // Bump key to reset modal state before opening
    setModalKey((i) => i + 1);
  };

  const handleDateOverrideChange = (updatedDateOverrides: DateSetting[]) => {
    onChange({
      ...meetingHours,
      dateOverrides: meetingHours.dateOverrides
        .filter(({ date }) => updatedDateOverrides.every((setting) => setting.date !== date))
        .concat(updatedDateOverrides)
        .sort((a, b) => (a.date < b.date ? -1 : 1)),
    });
  };

  const handleRemoveDateOverride = (date: string) => {
    onChange({
      ...meetingHours,
      dateOverrides: meetingHours.dateOverrides.filter(
        (dateOverride) => dateOverride.date !== date,
      ),
    });
  };

  return (
    <div className="cw-space-y-2">
      {meetingHours.dateOverrides.map(({ date, timeRanges }) => (
        <div key={date} className="cw-body-base">
          <div className="cw-flex cw-gap-2">
            <div className="cw-font-bold">{DateTime.fromISO(date).toFormat("D")}</div>
            <div>
              {sortBy(timeRanges, "startTime").map(({ startTime, endTime }) => (
                <div key={startTime} className="cw-text-muted">
                  {formatTimeRange({ startTime, endTime })}
                </div>
              ))}
              {timeRanges.length === 0 && <span className="cw-text-muted">Unavailable</span>}
            </div>
            <div className="-cw-mt-[2px]">
              <Button
                rounded
                size="mini"
                variant="text"
                sentiment="neutral"
                startIcon={Close}
                onClick={() => {
                  handleRemoveDateOverride(date);
                }}
              />
            </div>
          </div>
        </div>
      ))}
      <Button
        size="small"
        variant="outlined"
        sentiment="neutral"
        startIcon={Add}
        onClick={openDateSpecificHoursModal}
      >
        Add date-specific hours
      </Button>
      <DateOverrideModal
        key={modalKey}
        meetingHours={meetingHours}
        open={modalOpen}
        onSubmit={handleDateOverrideChange}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};
