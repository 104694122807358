import { fg_muted } from "@clockwise/design-system/tokens";
import { ExpandLess, ExpandMore } from "@clockwise/icons";
import { EventThread, SecondaryCard } from "@clockwise/web-commons/src/ui/event-thread";
import React from "react";

export type Props = {
  type: SecondaryCard;
  children: React.ReactNode;
  isCollapsed?: boolean;
  loading?: boolean;
  onClick: () => void;
};

export const ExpandableHeader = ({
  type,
  onClick,
  isCollapsed = true,
  loading = false,
  children,
}: Props) => {
  return (
    <EventThread.Break type={type} variant="MODAL_PROPOSAL" loading={loading} spacing={"Compact"}>
      <div
        onClick={onClick}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClick();
          }
        }}
        role="button"
        className="cw-group cw-font-normal cw-flex cw-gap-1 cw-items-center cw-justify-between cw-rounded-md hover:cw-cursor-pointer"
        aria-label={isCollapsed ? "Expand" : "Collapse"}
      >
        {children}
        <div className="cw-self-start">{isCollapsed ? <ExpandIcon /> : <CollapseIcon />}</div>
      </div>
    </EventThread.Break>
  );
};

const ExpandIcon = () => {
  return (
    <div className="cw-h-6 cw-flex cw-items-center cw-justify-center">
      <ExpandMore className="cw-h-4 cw-w-4 cw-stroke-2" fill={fg_muted} />
    </div>
  );
};

const CollapseIcon = () => {
  return (
    <div className="cw-h-6 cw-flex cw-items-center cw-justify-center">
      <ExpandLess className="cw-h-4 cw-w-4 cw-stroke-2" fill={fg_muted} />
    </div>
  );
};
