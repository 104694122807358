export const minPerDay = 1440;
export const pixelsPerMin = 1;
export const calendarHeightOffset = 383; // unclear on why this is needed but math doesnt work without it

const pxPerMin = {
  compact: 40 / 60,
  default: 48 / 60,
  comfortable: 60 / 60,
};

export const CALENDAR_HEIGHT_COMPACT = minPerDay * pxPerMin.compact + calendarHeightOffset;
export const CALENDAR_HEIGHT_DEFAULT = minPerDay * pxPerMin.default + calendarHeightOffset;
export const CALENDAR_HEIGHT_COMFORTABLE = minPerDay * pxPerMin.comfortable + calendarHeightOffset;

export type CalendarDensity = "compact" | "default" | "comfortable";
