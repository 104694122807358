import { EcosystemEnum } from "@clockwise/schema";
import {
  ConferencingType,
  PreferredVideoConference,
  SupportedConferenceType,
} from "@clockwise/schema/v2";

const ecosystemToConferencingType = (ecosystem: EcosystemEnum) => {
  switch (ecosystem) {
    case EcosystemEnum.Google:
      return ConferencingType.Meet;
    case EcosystemEnum.Microsoft:
      return ConferencingType.TeamsForBusiness;
    case EcosystemEnum.NotApplicable:
    default:
      return null;
  }
};

const toConferencingType = (preferredVC: PreferredVideoConference): ConferencingType | null => {
  switch (preferredVC) {
    case PreferredVideoConference.Meet:
      return ConferencingType.Meet;
    case PreferredVideoConference.TeamsForBusiness:
      return ConferencingType.TeamsForBusiness;
    case PreferredVideoConference.Zoom:
      return ConferencingType.Zoom;
    default:
      return null;
  }
};

/**
 * Infer the preferred conferencing type based on the user's BE provided preferred video conference,
 * the supported conference types, and the ecosystem.
 *
 * If the user's BE provided preferred video conference is "Auto", the preferred conferencing type is
 * either Zoom if available or else the ecosystem-specific conferencing type.
 */
export const inferPreferredConferencingType = (
  preferredVC: PreferredVideoConference | undefined,
  supportedConferenceTypes: SupportedConferenceType[],
  ecosystem: EcosystemEnum,
): ConferencingType | null => {
  if (!preferredVC) {
    return null;
  }

  if (preferredVC !== PreferredVideoConference.Auto) {
    return toConferencingType(preferredVC);
  }

  return supportedConferenceTypes.includes(SupportedConferenceType.Zoom)
    ? ConferencingType.Zoom
    : ecosystemToConferencingType(ecosystem);
};
