import { Button, ButtonSet, Tooltip } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCalendarIds, clearAllSelected } from "../../state/actions/multi-calendar.actions";
import {
  setCancelProposalUIInFlight,
  setConfirmProposalUIInFlight,
} from "../../state/actions/ui-flow-on-screen.actions";
import { TrackingEvents, track } from "../../util/analytics.util";
import { ProposalThread } from "../chat/ai-chat/components/proposal/proposal-thread/ProposalThread";
import { GQLPerson, GQLProposal } from "../chat/ai-chat/utils/types";
import { FeedbackThumbs } from "../chat/web/FeedbackThumbs";
import { usePlannerContext } from "../web-app-calendar/Context";
import { ProposalOwnerAvatar } from "./ProposalOwnerAvatar";
import { useSetProposalError } from "./hooks/ProposalErrorContext";
import { useSharedProposalContext } from "./hooks/SharedProposalProvider";
import { useCancelSharedProposal } from "./hooks/useCancelSharedProposal";
import { useConfirmSharedProposal } from "./hooks/useConfirmSharedProposal";

type ProposalExpandedActiveProps = {
  proposal: GQLProposal;
  proposalOwner: GQLPerson;
  setUpdatedProposal: (proposal: GQLProposal | null) => void;
  triggerConfetti: () => void;
};

export const ProposalExpandedActive = ({
  proposal,
  proposalOwner,
  setUpdatedProposal,
  triggerConfetti,
}: ProposalExpandedActiveProps) => {
  const dispatch = useDispatch();
  const setError = useSetProposalError();
  const { refetchEvents } = usePlannerContext();
  const { viewerIsProposalOwner, hasEditPermission } = useSharedProposalContext();
  const proposalId = proposal.id;
  const ownerName = proposalOwner.profile?.givenName || proposalOwner.primaryEmail.split("@")[0];
  const sessionId = new URLSearchParams(window.location.search).get("sessionId");
  // For shared proposals, the first diff is the only diff
  const firstDiff = proposal.diffBlocks[0]?.diffs[0] || null;
  const firstDiffAttendees = firstDiff?.attendees?.proposalAttendees.map(
    (attendee) => attendee.attendeePerson,
  );

  useEffect(() => {
    firstDiffAttendees.forEach((attendee) => dispatch(addCalendarIds([attendee.primaryEmail])));
  }, [firstDiffAttendees]);

  const [onProposalConfirm, { loading: confirmLoading }] = useConfirmSharedProposal({
    proposalId: proposalId,
    onError: (error) => {
      setError({
        error: error,
        message: "Failed to confirm proposal",
        proposalId: proposalId,
      });
    },
    onCompleted: (data) => {
      triggerConfetti();
      setUpdatedProposal(data.confirmProposal?.proposal || null);
      void refetchEvents?.();
    },
  });

  const [onProposalCancel, { loading: cancelLoading }] = useCancelSharedProposal({
    proposalId: proposalId,
    onError: (error) => {
      setError({
        error: error,
        message: "Failed to confirm proposal",
        proposalId: proposalId,
      });
    },
    onCompleted: (data) => {
      setUpdatedProposal(data.cancelProposal?.proposal || null);
    },
  });

  const handleConfirm = () => {
    track(TrackingEvents.SHAREABLE_PROPOSAL.VIEW.CONFIRM, {
      sessionId,
      proposalId,
      proposalOwner: proposalOwner.primaryEmail,
    });

    dispatch(setConfirmProposalUIInFlight());
    dispatch(clearAllSelected());
    void onProposalConfirm();
  };

  const handleDecline = () => {
    track(TrackingEvents.SHAREABLE_PROPOSAL.VIEW.DECLINE, {
      sessionId,
      proposalId,
      proposalOwner: proposalOwner.primaryEmail,
    });

    dispatch(setCancelProposalUIInFlight());
    dispatch(clearAllSelected());
    void onProposalCancel();
  };

  const loading = confirmLoading || cancelLoading;
  const disableConfirm = loading || !hasEditPermission;
  const disableCancel = loading || (!hasEditPermission && !viewerIsProposalOwner);

  return (
    <>
      <div className="cw-relative cw-flex cw-flex-col cw-bg-default cw-overflow-hidden cw-h-full cw-border cw-shadow-md cw-rounded-lg cw-divide-y cw-p-3">
        <div className="cw-pb-2">
          <ProposalOwnerAvatar owner={proposalOwner} />
          <span className="cw-font-semibold cw-pl-2">{ownerName} shared a proposal</span>
        </div>
        <ProposalThread
          proposal={proposal}
          variant={"SHARED_PROPOSAL"}
          viewerIsProposalOwner={viewerIsProposalOwner}
        />
        {!viewerIsProposalOwner && (
          <div className="cw-font-semibold cw-mt-2">Reply to {ownerName}</div>
        )}
        <div className="cw-flex cw-flex-wrap cw-items-center cw-mt-3">
          {loading ? (
            <Loader size="sm" sentiment="positive" />
          ) : (
            <ButtonSet>
              <Tooltip title="This will create a calendar event.">
                <Button
                  size="small"
                  sentiment="positive"
                  onClick={handleConfirm}
                  disabled={disableConfirm}
                >
                  Confirm and book
                </Button>
              </Tooltip>
              <Tooltip title="This will cancel the proposal for everyone.">
                <Button
                  size="small"
                  sentiment="destructive"
                  variant="outlined"
                  onClick={handleDecline}
                  disabled={disableCancel}
                >
                  Cancel proposal
                </Button>
              </Tooltip>
              <FeedbackThumbs
                msgId={""}
                conversationId={""}
                proposalId={proposalId}
                orientation="horizontal"
                currentLLMUsed={""}
              />
            </ButtonSet>
          )}
          {!hasEditPermission && (
            <div className="cw-text-xs cw-italic cw-text-neutral-500 cw-mt-2">
              You don't have permission to confirm.
            </div>
          )}
        </div>
      </div>
    </>
  );
};
