import { ConferencingType as WebserverConferencingType } from "@clockwise/schema";
import { ConferencingType } from "@clockwise/schema/v2";
import React from "react";
import { useUserConfrencingTypes } from "../../../hooks/useUserConfrencingTypes";
import { ECVideoConf } from "../molecules/ECVideoConf";

const toConferencingType = (type?: WebserverConferencingType): ConferencingType | null => {
  switch (type) {
    case WebserverConferencingType.MEET:
      return ConferencingType.Meet;
    case WebserverConferencingType.ZOOM:
      return ConferencingType.Zoom;
    case WebserverConferencingType.TEAMS_FOR_BUSINESS:
      return ConferencingType.TeamsForBusiness;
    case WebserverConferencingType.OTHER:
    case WebserverConferencingType.AROUND:
      return ConferencingType.Other;
    default:
      return null;
  }
};

const toWebserverConferencingType = (
  type: ConferencingType | null,
): WebserverConferencingType | undefined => {
  switch (type) {
    case ConferencingType.Meet:
      return WebserverConferencingType.MEET;
    case ConferencingType.Zoom:
      return WebserverConferencingType.ZOOM;
    case ConferencingType.TeamsForBusiness:
      return WebserverConferencingType.TEAMS_FOR_BUSINESS;
    case ConferencingType.Other:
      return WebserverConferencingType.OTHER;
    default:
      return undefined;
  }
};

export const ConferenceTypeSelector = ({
  selected,
  onSelect,
  readOnly,
}: {
  selected: ConferencingType | null;
  onSelect: (type: ConferencingType | null) => void;
  readOnly: boolean;
}) => {
  const { conferencingTypes } = useUserConfrencingTypes();
  const handleSelect = (type?: WebserverConferencingType) => {
    onSelect(toConferencingType(type));
  };

  return (
    <ECVideoConf
      enabledTypes={conferencingTypes}
      selected={toWebserverConferencingType(selected)}
      onSelect={handleSelect}
      readonly={readOnly}
    />
  );
};
