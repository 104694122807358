import { SecondaryCard } from "@clockwise/web-commons/src/ui/event-thread";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { noop, uniqBy } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { useBoolean } from "usehooks-ts";
import { ExpandableHeader } from "./ExpandableHeader";
import { TradeoffDetail } from "./TradeoffDetail";
import { TradeoffEffectByType } from "./TradeoffEffectByType";

type AffectedAttendee = {
  person: {
    isMe: boolean;
    displayName: string;
    externalImageUrl: string | null;
    email: string;
  };
};

type TradeoffAttendee = {
  person: {
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  };
};

export type Tradeoff = {
  title: string;
  attendees: TradeoffAttendee[];
  event: {
    title: string;
  } | null;
  updatedStartTime?: DateTime | null;
};

export type OnClickX = (diffId: string) => void;
export type Props = {
  tradeoffs: Tradeoff[];
  affectedAttendees: AffectedAttendee[];
  type: SecondaryCard;
  defaultCollapsed?: boolean;
  loading?: boolean;
  onClickX?: OnClickX;
};

export const TradeoffBlock = ({
  type,
  tradeoffs,
  affectedAttendees,
  defaultCollapsed = true,
  loading = false,
  onClickX = noop,
}: Props) => {
  const track = useTracking();
  const { value: isCollapsed, setFalse: handleExpand, setTrue: handleCollapse } = useBoolean(
    defaultCollapsed,
  );

  const handleOnClick = () => {
    if (isCollapsed) {
      handleExpand();
      track(TrackingEvents.DIRECT_MANIPULATION.RESCHEDULE_MODAL.TRADEOFF.BLOCK_EXPANDED, {
        type,
        expanded: true,
      });
    } else {
      handleCollapse();
      track(TrackingEvents.DIRECT_MANIPULATION.RESCHEDULE_MODAL.TRADEOFF.BLOCK_EXPANDED, {
        type,
        expanded: false,
      });
    }
  };

  const uniqueAttendees = uniqBy(affectedAttendees, (attendee) => attendee.person.email);

  return (
    <>
      <ExpandableHeader
        type={type}
        loading={loading}
        isCollapsed={isCollapsed}
        onClick={handleOnClick}
      >
        {loading ? (
          <div className="cw-caption cw-font-medium">Evaluating conflicts</div>
        ) : (
          <TradeoffEffectByType type={type} attendees={uniqueAttendees} />
        )}
      </ExpandableHeader>
      {!isCollapsed &&
        tradeoffs.map((tradeoff, index) => (
          <TradeoffDetail
            tradeoff={tradeoff}
            key={`${tradeoff.title}-${index}`}
            onClickX={onClickX}
            type={type}
            loading={loading}
          />
        ))}
    </>
  );
};
