import { TradeoffTypeEnum } from "@clockwise/schema";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";
import { TradeoffAttendee } from "../../chat/ai-chat/utils/types";
import { ECAttendeeStack } from "../atoms/ECAttendeeStack";
import { getIconForTradeoffType } from "../utils/getStylesForTradeoffType";
import { ECTradeoffList } from "./ECTradeoffList";
import { Tradeoff } from "./ECTradeoffsWithDrawer";

const getVariantForTradeoffType = (type: TradeoffTypeEnum): "info" | "warning" => {
  switch (type) {
    case TradeoffTypeEnum.AVAILABILITY_ISSUE:
      return "warning";
    case TradeoffTypeEnum.FIXABLE_CONFLICT:
      return "info";
    case TradeoffTypeEnum.OUT_OF_TIME_RANGE:
      return "warning";
    case TradeoffTypeEnum.INCONVENIENCE:
    case TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE:
      return "info";
  }
};

export const SummaryTradeoffs = ({
  text,
  attendees,
  icon,
  variant,
}: {
  text: string;
  attendees: TradeoffAttendee[];
  variant: "info" | "warning";
  icon?: React.ReactNode;
}) => {
  const hasAttendees = !isEmpty(attendees);
  return (
    <div className="cw-flex cw-gap-2">
      <div
        className={classNames("cw-flex cw-gap-1 cw-items-center cw-justify-center", {
          "cw-text-warning ": variant === "warning", // yellow
          "cw-text-busy": variant === "info", //blue
        })}
      >
        {!!icon && <div className="cw-mt-[5px]">{icon}</div>}
        {text}
      </div>
      {hasAttendees && <ECAttendeeStack attendees={attendees} />}
    </div>
  );
};

const DetailedTradeoff = ({ tradeoffList }: { tradeoffList: Tradeoff[] }) => {
  return <ECTradeoffList variant="warning" tradeoffList={tradeoffList} />;
};

export const ECTradeoffsCollapsed = ({
  text,
  attendees,
  type,
  onClick,
  tradeoffList,
}: {
  text: string;
  attendees: TradeoffAttendee[];
  type: TradeoffTypeEnum;
  onClick: () => void;

  tradeoffList: Tradeoff[];
}) => {
  const showDetailedTradeoffList = type === TradeoffTypeEnum.AVAILABILITY_ISSUE;

  const variant = getVariantForTradeoffType(type);
  const icon = getIconForTradeoffType(type);
  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick();
        }
      }}
      onClick={onClick}
      className={classNames("cw-flex-col cw-justify-between cw-w-full", "cw-cursor-pointer")}
    >
      {showDetailedTradeoffList ? (
        <DetailedTradeoff tradeoffList={tradeoffList} />
      ) : (
        <SummaryTradeoffs text={text} attendees={attendees} icon={icon} variant={variant} />
      )}
    </div>
  );
};
