import { useMutation } from "@apollo/client";
import { Switch } from "@clockwise/design-system";
import { InvitePolicyEnum } from "@clockwise/schema";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { TrackingEvents, track } from "../../../../../util/analytics.util";
import { ApPatchBillingGroupDocument } from "../__generated__/APBillingGroups.generated";

export const ToggleInvitePolicy = ({
  invitePolicy,
  orgDomain,
  orgId,
  bgId,
}: {
  invitePolicy: InvitePolicyEnum;
  orgDomain?: string;
  orgId: string;
  bgId: string;
}) => {
  const [checkedStatus, setCheckedStatus] = useState(false);
  const [modifyInvitePolicy] = useMutation(ApPatchBillingGroupDocument);

  useEffect(() => {
    setCheckedStatus(invitePolicy === InvitePolicyEnum.AnyMember);
  }, []);

  const onEditInvitePolicy = (bgId: string, newInvitePolicy: InvitePolicyEnum) => {
    track(
      TrackingEvents.ADMIN_PANEL
        .PLANS_AND_BILLING_BILLING_GROUPS_SETTINGS_SET_ADMIN_PROVISIONING_PRIVILIGE,
    );
    void modifyInvitePolicy({
      variables: {
        input: { orgRelayId: orgId, billingGroupId: bgId, invitePolicy: newInvitePolicy },
      },
      onCompleted: () => {
        setCheckedStatus(newInvitePolicy === InvitePolicyEnum.AnyMember);
        toast.success("Invite Policy Updated Successfully");
      },
      onError: () => {
        toast.error("Failed to update Invite Policy");
      },
    });
  };

  const onToggleInvitePolicy = (checked: boolean) => {
    if (checked) {
      onEditInvitePolicy(bgId, InvitePolicyEnum.AnyMember);
    } else {
      onEditInvitePolicy(bgId, InvitePolicyEnum.AdminOnly);
    }
  };

  return (
    <>
      <Switch onChange={onToggleInvitePolicy} checked={checkedStatus} />
      <span className="cw-body-base">
        Allow anyone in your organization {orgDomain ? `(${orgDomain})` : ""} to join this
        subscription
      </span>
    </>
  );
};
