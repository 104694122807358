import { noop } from "lodash";
import React from "react";
import { ToggleCalendarLayers } from "./ToggleCalendarLayers";
import { useDiffLayersToggle } from "./useDiffLayersToggle";

export const ToggleCalendarsLayersForDiffs = ({
  startingIsToggledOn,
  diffId,
  // If `focusedToggle` is on, remove all the calendars on this event individually
  // regardless of all the other events and diffs that are toggled on
  focusedToggle,
}: {
  startingIsToggledOn: boolean;
  diffId: string;
  focusedToggle?: boolean;
}) => {
  const { active: isToggledOn, disabled, reasonForDisabled, toggle = noop } = useDiffLayersToggle({
    diffId,
    focusedToggle,
    startingIsToggledOn,
  });

  return (
    <ToggleCalendarLayers
      onClick={toggle}
      isToggledOn={isToggledOn}
      disabled={disabled}
      reasonForDisabled={reasonForDisabled}
    />
  );
};
