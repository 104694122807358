import {
  useCurrentProposal,
  useUpdateCurrentProposal,
} from "#webapp/src/components/chat-plus-calendar/CurrentProposalContext";
import { Select, SelectOption } from "@clockwise/design-system";
import { Duration, Interval } from "luxon";
import { default as React } from "react";

export const TimeSuggestionsDurationPicker = () => {
  const {
    currentProposal: { startTime, endTime },
  } = useCurrentProposal();
  const { updateTime } = useUpdateCurrentProposal();
  const selectedInterval = Interval.fromDateTimes(startTime, endTime);

  const currentDuration = selectedInterval.length("minutes");
  const [options, setOptions] = React.useState([
    { label: `15 minutes`, value: 15 },
    { label: `30 minutes`, value: 30 },
    { label: `45 minutes`, value: 45 },
    { label: `1 hour`, value: 60 },
    { label: `1 hour 15 mins`, value: 75 },
    { label: `1 hour 30 mins`, value: 90 },
    { label: `2 hours`, value: 120 },
    { label: `2 hours 30 mins`, value: 150 },
    { label: `3 hours`, value: 180 },
  ]);

  React.useEffect(() => {
    if (options.findIndex((o) => o.value === currentDuration) === -1) {
      const newList = [
        ...options,
        {
          label: `${Duration.fromObject({ minutes: currentDuration }).toFormat(`m 'minutes'`)}`,
          value: currentDuration,
        },
      ].sort((a, b) => a.value - b.value);
      setOptions(newList);
    }
  }, [currentDuration]);

  const onSelect = (value: number) => {
    updateTime(startTime, startTime.plus({ minutes: value }));
  };

  return (
    <Select size="small" variant="default" value={currentDuration} onChange={onSelect}>
      {options.map((option) => {
        return (
          <SelectOption key={option.value} value={option.value}>
            {option.label}
          </SelectOption>
        );
      })}
    </Select>
  );
};
