import { animated, config, useSpring } from "@react-spring/web";
import classnames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { track, TrackingEvents } from "../../util/analytics.util";

export type FeatureCardProps = {
  layout: "column" | "row";
  feature: { name: string; path: string; endIcon?: React.ReactNode };
  className?: string;
};

export const FeatureCard = ({
  layout,
  feature,
  children,
  className,
}: React.PropsWithChildren<FeatureCardProps>) => {
  const [hovering, setHovering] = useState(false);
  const navigate = useNavigate();

  const [{ scale }] = useSpring(
    {
      scale: hovering ? 1.035 : 1.0,
      config: { ...config.stiff },
    },
    [hovering],
  );

  const { name: featureName, path: featurePath, endIcon } = feature;

  return (
    <animated.a
      className="cw-h-full cw-p-6 cw-rounded-md cw-bg-accent-emphasis cw-flex cw-flex-col cw-min-w-[300px] cw-justify-between cw-cursor-pointer cw-no-underline"
      href={featurePath}
      onClick={(event) => {
        event.preventDefault();
        navigate(featurePath);
        track(TrackingEvents.WEB_LANDING_PAGE.FEATURE_CARD_CLICKED, {
          label: featureName,
          path: featurePath,
        });
      }}
      title={`Link to ${featureName} page`}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={{ scale }}
    >
      <div
        className={classnames(
          "cw-flex cw-gap-2 cw-grow",
          {
            "cw-flex-col": layout === "column",
          },
          className,
        )}
        aria-hidden="true"
      >
        {children}
      </div>
      <div className="cw-heading-xl cw-mt-6 cw-flex cw-items-center cw-text-white">
        <div className="cw-mr-1">{featureName}</div>
        {endIcon}
      </div>
    </animated.a>
  );
};
