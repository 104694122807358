import { SvgIconComponent } from "@clockwise/icons";
import {
  Combobox,
  ComboboxStore,
  InputBase,
  ScrollArea,
  useVirtualizedCombobox,
} from "@mantine/core";
import { useId } from "@mantine/hooks";
import classNames from "classnames";
import { sortBy } from "lodash";
import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Size } from "../../constants/types";
import { getMantineSize, renderIcon } from "./mantine.util";
import { Time, addMinutes, findBestTimeMatch, formatTime } from "./time.util";

type TimeOption = {
  label: ReactNode;
  value: Time;
  hidden?: boolean;
};

type Props = {
  value: Time | null;
  minTime?: Time;
  maxTime?: Time;
  stepSize?: number;
  /** Custom time options. If provided, minTime, maxTime, and stepSize are ignored. */
  options?: TimeOption[];
  sortOptions?: (a: TimeOption, b: TimeOption) => number;
  onChange?: (value: Time | null) => void;
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
  selectOnly?: boolean;
  size?: Size;
  label?: string;
  "aria-label"?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string | null;
  startIcon?: SvgIconComponent;
  fullWidth?: boolean;
  // exposing for use cases in GCal where rending in portal can be problematic
  withinPortal?: boolean;
};

/**
 * Find DOM elements rendered by Combobox.Option components
 */
const findComboboxOptions = (listId: string | null) => {
  if (!listId) return [];
  const list = document.getElementById(listId);
  const items = list?.querySelectorAll("[data-combobox-option]") ?? [];
  return Array.from(items);
};

const scrollToOption = (listId: string | null, value: string) => {
  if (!listId) return;
  const options = findComboboxOptions(listId);
  let index = options.findIndex((option) => option.getAttribute("value") === value);
  if (index === -1) return;

  // Find nearest visible option to the selected option
  let min = index;
  let max = index;
  let selectedOption: Element | null = null;
  while (!selectedOption && (min >= 0 || max < options.length)) {
    if (options[min]?.checkVisibility()) {
      selectedOption = options[min];
    } else if (options[max]?.checkVisibility()) {
      index = max;
      selectedOption = options[max];
    }
    max++;
    min--;
  }
  selectedOption?.scrollIntoView({ block: "nearest", behavior: "instant" });
};

/**
 * useVirtualizedCombobox doesn't correctly update listId or selectedOptionIndex. This hook
 * overrides some ComboboxStore methods to fix these issues.
 */
const useCustomCombobox = ({
  getSelectedOptionIndex,
  listIdRef,
  ...opts
}: Parameters<typeof useVirtualizedCombobox>[0] & {
  getSelectedOptionIndex: () => number;
  listIdRef: MutableRefObject<string | null>;
}): ComboboxStore => {
  const vcb = useVirtualizedCombobox({ ...opts });
  const combobox: ComboboxStore = {
    ...vcb,
    getSelectedOptionIndex,
    setListId: (id) => {
      listIdRef.current = id;
      return vcb.setListId(id);
    },
  };
  return combobox;
};

const toOption = (time: Time, hidden = false) => ({
  label: formatTime(time),
  value: time,
  hidden,
});

export const TimeInput = ({
  value,
  minTime = "00:00",
  maxTime = "23:59",
  options: customOptions,
  sortOptions,
  onChange,
  stepSize = 30,
  id: _id,
  disabled = false,
  readOnly = false,
  selectOnly = false,
  size = "medium",
  label,
  "aria-label": ariaLabel,
  placeholder = "Select a time",
  error = false,
  errorMessage,
  startIcon: StartIcon,
  fullWidth = false,
  withinPortal = true,
}: Props) => {
  const [query, setQuery] = useState(() => {
    return value ? formatTime(value) : "";
  });
  const [selectedTime, setSelectedTime] = useState(value);

  const defaultOptions = useMemo<TimeOption[]>(() => {
    let currentTime = minTime;
    const timeOptions: TimeOption[] = [];
    while (currentTime <= maxTime) {
      timeOptions.push(toOption(currentTime));
      currentTime = addMinutes(currentTime, stepSize);
    }
    return timeOptions;
  }, [minTime, maxTime, stepSize]);

  const options = useMemo<TimeOption[]>(() => {
    let timeOptions = customOptions ?? defaultOptions;
    // If the selectedTime isn't already included, add it as a custom time. This option will be
    // hidden in the dropdown but will be used for scroll positioning and selection logic.
    if (!!selectedTime && !timeOptions.some(({ value }) => value === selectedTime)) {
      timeOptions = timeOptions.concat(toOption(selectedTime, true));
    }
    return sortOptions ? [...timeOptions].sort(sortOptions) : sortBy(timeOptions, "value");
  }, [selectedTime, defaultOptions, customOptions, sortOptions]);

  const setQueryByValue = (v: Time | null) => {
    setQuery(v ? formatTime(v) : "");
  };

  const mantineSize = getMantineSize(size);

  const handleBlur = () => {
    if (selectedTime !== value) {
      onChange?.(selectedTime);
    }
    setQueryByValue(selectedTime);
  };

  const handleOptionSubmit = (optionValue: string) => {
    if (optionValue !== value) {
      onChange?.(optionValue);
    }
    setSelectedTime(optionValue);
    setQueryByValue(optionValue);
    combobox.closeDropdown();
  };
  const handleChange = (search: string) => {
    setQuery(search);
    const time = findBestTimeMatch(search, { minTime });
    if (time && time >= minTime && time <= maxTime) {
      setSelectedTime(time);
    }
  };

  const setSelectedOptionIndex = useCallback(
    (i: number) => {
      const time = options[i]?.value ?? null;
      setSelectedTime(time);
      setQueryByValue(time);
    },
    [options],
  );
  const onSelectedOptionSubmit = (i: number) => {
    const time = options[i]?.value ?? "";
    handleOptionSubmit(time);
  };

  const selectedOptionIndex = useRef(-1);
  selectedOptionIndex.current = options.findIndex(({ value }) => value === selectedTime);
  const getSelectedOptionIndex = useCallback(() => selectedOptionIndex.current, []);

  const listId = useRef<string | null>(null);
  const combobox = useCustomCombobox({
    onDropdownOpen: () => {
      setTimeout(() => scrollToOption(listId.current, selectedTime ?? ""), 0);
    },
    totalOptionsCount: options.length,
    getOptionId: (i) => findComboboxOptions(listId.current)[i]?.id ?? null,
    onSelectedOptionSubmit,
    activeOptionIndex: options.findIndex(({ value }) => value === selectedTime),
    selectedOptionIndex: getSelectedOptionIndex(),
    getSelectedOptionIndex,
    setSelectedOptionIndex,
    listIdRef: listId,
  });

  // Update `query` and `selectedTime` to match `value` when not actively editing
  useEffect(() => {
    if (!combobox.dropdownOpened) {
      setQueryByValue(value);
      setSelectedTime(value);
    }
  }, [value, combobox.dropdownOpened]);

  // Update selected option when `selectedTime` changes
  useEffect(() => {
    selectedTime && scrollToOption(listId.current, selectedTime);
  }, [selectedTime]);

  const id = useId(_id);

  const hidden = disabled || readOnly || options.every(({ hidden }) => hidden);

  const leftSection = StartIcon && renderIcon(StartIcon, size);

  return (
    <Combobox
      store={combobox}
      disabled={disabled}
      readOnly={readOnly}
      onOptionSubmit={(val) => {
        handleOptionSubmit(val);
      }}
      size={mantineSize}
      withinPortal={withinPortal}
    >
      <Combobox.Target>
        <InputBase
          value={query}
          placeholder={placeholder}
          size={mantineSize}
          disabled={disabled}
          readOnly={readOnly || selectOnly}
          label={label}
          aria-label={ariaLabel}
          error={(error && errorMessage) || error}
          leftSection={leftSection}
          classNames={{ root: classNames({ "cw-w-full": fullWidth }) }}
          onChange={(event) => {
            handleChange(event.currentTarget.value);
            combobox.openDropdown();
          }}
          onFocus={(e) => {
            !disabled && !readOnly && e.currentTarget.select();
            !disabled && !readOnly && combobox.openDropdown();
          }}
          onBlur={() => {
            combobox.closeDropdown();
            handleBlur();
          }}
          onClick={() => {
            !disabled && !readOnly && combobox.openDropdown();
          }}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              // Cancel editng and revert selected time and query to value
              setSelectedTime(value);
              setQueryByValue(value);
              combobox.closeDropdown();
            }
          }}
          id={id}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={hidden} p={0}>
        <ScrollArea.Autosize mah={220} scrollbars="y" type="auto">
          <Combobox.Options
            labelledBy={label ? `${id}-label` : undefined}
            aria-label={label ? undefined : ariaLabel}
            className="cw-p-1"
          >
            {options.map((option) => (
              <Combobox.Option
                key={option.value}
                value={option.value}
                active={option.value === value}
                selected={option.value === selectedTime}
                hidden={option.hidden}
              >
                {option.label}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>
  );
};
