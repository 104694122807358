import { useMutation } from "@apollo/client";
import { SaveSharedProposalEventDiffDetailsInput } from "@clockwise/schema";
import { useCallback } from "react";
import {
  SaveSharedProposalEventDiffDetailsDocument,
  SaveSharedProposalEventDiffDetailsMutation,
} from "../apollo/__generated__/SaveSharedProposalEventDiffDetails.generated";

type SaveSharedProposalDiffEventDetailsProps = {
  onError?: (error: Error) => void;
  onCompleted?: (data: SaveSharedProposalEventDiffDetailsMutation) => void;
};

type SaveSharedProposalDiffEventDetailsResult = [
  (input: SaveSharedProposalEventDiffDetailsInput) => Promise<void>,
  {
    saveSharedProposalDiffEventDetailsLoading: boolean;
    saveSharedProposalDiffEventDetailsError: Error | undefined;
  },
];

export const useSaveSharedProposalDiffEventDetails = ({
  onError,
  onCompleted,
}: SaveSharedProposalDiffEventDetailsProps): SaveSharedProposalDiffEventDetailsResult => {
  const [
    saveSharedProposalDiffEventDetails,
    {
      loading: saveSharedProposalDiffEventDetailsLoading,
      error: saveSharedProposalDiffEventDetailsError,
    },
  ] = useMutation(SaveSharedProposalEventDiffDetailsDocument, {
    onError,
    onCompleted,
  });

  const onSaveSharedProposalDiffEventDetails = useCallback(
    async (input: SaveSharedProposalEventDiffDetailsInput) => {
      await saveSharedProposalDiffEventDetails({
        variables: {
          input,
        },
      });
    },
    [saveSharedProposalDiffEventDetails],
  );

  return [
    onSaveSharedProposalDiffEventDetails,
    {
      saveSharedProposalDiffEventDetailsLoading,
      saveSharedProposalDiffEventDetailsError,
    },
  ];
};
