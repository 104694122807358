import { Link } from "@clockwise/design-system";
import { CalloutBox } from "@clockwise/web-commons/src/components/callout-box/CalloutBox.tailwind";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import { Skeleton } from "@material-ui/lab";
import pluralize from "pluralize";
import React from "react";
import { useNavigate } from "react-router-dom";
import useFlexibleMeetings from "../hooks/useFlexibleMeetings";

const FlexibleMeetingsCallout = () => {
  const { flexibleMeetings, loading } = useFlexibleMeetings();
  const flexibleMeetingCount = flexibleMeetings.length;
  const navigate = useNavigate();

  const linkTo = getUrlForFeature("flexibleMeetings", { relative: true });

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    navigate(linkTo);
  };

  const loadingText = <Skeleton variant="text" width="13em" className="cw-inline-block cw-mr-2" />;
  const displayText = `You have ${flexibleMeetingCount} flexible ${pluralize(
    "meetings",
    flexibleMeetingCount,
  )}.`;

  return (
    <CalloutBox
      sentiment="info"
      body={
        <>
          <span className="cw-font-bold">{loading ? loadingText : displayText}</span>{" "}
          <span>
            <Link href={linkTo} onClick={handleClick}>
              Mark more meetings flexible
            </Link>{" "}
            so Clockwise can try to create your ideal day.
          </span>
        </>
      }
    />
  );
};

export default FlexibleMeetingsCallout;
