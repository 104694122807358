import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UserSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserSettingsQuery = { __typename: 'Query', userSettings: { __typename: 'UserSettings', id: string, preferredVideoConference: Types.PreferredVideoConference } | null };

export type UpdatePreferredVideoConferenceMutationVariables = Types.Exact<{
  input: Types.UpdatePreferredVideoConferenceInput;
}>;


export type UpdatePreferredVideoConferenceMutation = { __typename: 'Mutation', updatePreferredVideoConference: { __typename: 'UserSettings', id: string, preferredVideoConference: Types.PreferredVideoConference } | null };


export const UserSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"preferredVideoConference"}}]}}]}}]} as unknown as DocumentNode<UserSettingsQuery, UserSettingsQueryVariables>;
export const UpdatePreferredVideoConferenceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdatePreferredVideoConference"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePreferredVideoConferenceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePreferredVideoConference"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"preferredVideoConference"}}]}}]}}]} as unknown as DocumentNode<UpdatePreferredVideoConferenceMutation, UpdatePreferredVideoConferenceMutationVariables>;