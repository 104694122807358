import { getOwnCalendarColor } from "@clockwise/web-commons/src/util/calendar-coloring";
import { CalendarColorsState } from "@clockwise/web-commons/src/util/calendarColorsReducer";
import { difference, reduce } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../state/reducers/root.reducer";
import { usePlannerContext } from "../Context";
import { useUpdateCalendarColors } from "./CalendarColorsContext";
import usePlannerMetaData from "./usePlannerMetaData";

export const CalendarColorsSync = () => {
  const dispatchCalendarColors = useUpdateCalendarColors();
  const { primaryCalendarId, teamCalendarIds } = usePlannerMetaData();
  const { calendarIds } = usePlannerContext();
  const [previousCalendarIds, setPreviousCalendarIds] = useState<string[]>([]);
  const calendarIdsForCalendarPeek = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsForCalendarPeek,
  );

  // manage users own calendar colors
  useEffect(() => {
    const colorSet = getOwnCalendarColor();
    const calendarsColors = reduce(
      [primaryCalendarId || ""],
      (acc, calendarId) => {
        acc[calendarId] = colorSet;
        return acc;
      },
      { "": colorSet } as CalendarColorsState,
    );
    dispatchCalendarColors({ type: "set-calendarColors", payload: calendarsColors });

    return () => {
      dispatchCalendarColors({ type: "remove-calendars", payload: [primaryCalendarId || ""] });
    };
  }, [primaryCalendarId, dispatchCalendarColors]);

  // set the colors for the user's team calendars (color not specifically assigned)
  useEffect(() => {
    dispatchCalendarColors({ type: "add-calendars", payload: teamCalendarIds });

    return () => {
      dispatchCalendarColors({ type: "remove-calendars", payload: teamCalendarIds });
    };
  }, [teamCalendarIds, dispatchCalendarColors]);

  // manage colors when selected calendarIds change
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const currentCalendarIds = calendarIds || ([] as string[]);
    const newCalendarIds = difference(currentCalendarIds, previousCalendarIds);
    const removedCalendarIds = difference(
      previousCalendarIds,
      currentCalendarIds,
      calendarIdsForCalendarPeek, // We want the calendarPeek calendars to have a stable color even when they are not selected
    );

    if (newCalendarIds.length > 0) {
      dispatchCalendarColors({ type: "add-calendars", payload: newCalendarIds });
    }

    if (removedCalendarIds.length > 0) {
      // DevNote: Debounce this so a calendar color is not immedialty lost when it is unselected
      dispatchCalendarColors({ type: "remove-calendars", payload: removedCalendarIds });
    }

    setPreviousCalendarIds(currentCalendarIds);
  }, [
    calendarIds,
    dispatchCalendarColors,
    //previousCalendarIds -> intentionally ommitted
  ]);
  /* eslint-enable */

  return null;
};
