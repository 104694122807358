import { AvTimer, CheckCircleHistory, ErrorHistory } from "@clockwise/design-system/icons";
import { AvgPace, PauseCircleFilled } from "@clockwise/icons";
import { FlexStatus } from "@clockwise/schema/v2";
import React from "react";

export const EventFlexibilityStatusIcon: Record<FlexStatus, React.ReactNode> = {
  CanMove: <AvgPace className="cw-w-4 cw-h-4 cw-text-positive" />,
  Paused: <ErrorHistory className="cw-w-4 cw-h-4 cw-text-warning" />,
  EventOutsideTimeRange: <CheckCircleHistory className="cw-w-4 cw-h-4 cw-text-positive" />,
  EventExceedsDefragRange: <AvTimer className="cw-w-4 cw-h-4 cw-text-positive" />,
  TrialExpired: <PauseCircleFilled className="cw-w-4 cw-h-4 cw-text-warning" />,
  Pinned: null,
  External: null,
  NoDuration: null,
  SmartHold: null,
  PrivateEvent: null,
  OverQuota: null,
};
