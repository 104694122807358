import { pick } from "lodash";
import React from "react";
import { useReadEventTimeDetails, useUpdateEventTimeDetails } from "../hooks/EventCardContext";
import { AllDayToggle } from "../proposals/AllDayToggle";

export const AllDay = ({
  readOnly,
  showChanges = false,
}: {
  readOnly: boolean;
  showChanges?: boolean;
}) => {
  const { startTime, endTime, isAllDay } = useReadEventTimeDetails();
  const { setEndTime, setIsAllDay } = useUpdateEventTimeDetails();

  const onToggleIsAllDay = (newVal: boolean) => {
    setIsAllDay(newVal);
    if (startTime && endTime) {
      if ((newVal && startTime?.hasSame(endTime, "day")) || endTime < startTime) {
        setEndTime(endTime.set(pick(startTime.plus({ days: 1 }), "year", "month", "day")));
      } else if (!newVal) {
        const updatedEndTime = endTime.set(pick(startTime, "year", "month", "day"));
        setEndTime(updatedEndTime > startTime ? updatedEndTime : startTime.plus({ minutes: 30 }));
      }
    }
  };

  return (
    <AllDayToggle
      isAllDay={isAllDay}
      onChange={onToggleIsAllDay}
      readOnly={readOnly}
      showChanges={showChanges}
    />
  );
};
