import {
  useGatewayLazyQuery,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { EventDetailsByIdDocument } from "./__generated__/EventDetailsById.v2.generated";

export function useGatewayEventDetails(externalEventId?: string, calendarId?: string) {
  const { data, loading, error, refetch } = useGatewayQuery(EventDetailsByIdDocument, {
    variables: { externalEventId: externalEventId!, calendarId: calendarId! },
    skip: !externalEventId || !calendarId,
    fetchPolicy: "cache-and-network",
  });

  return { data, loading: !data && loading, error, refetch };
}

export function usePrefetchEventDetails() {
  return useGatewayLazyQuery(EventDetailsByIdDocument, {
    fetchPolicy: "cache-first",
  });
}
