import { Button, ButtonSet, Link } from "@clockwise/design-system";
import { RemoveCircleOutline } from "@clockwise/design-system/icons";
import { CheckCircleFilled, GroupAdd } from "@clockwise/icons";
import { PeopleOutline } from "@material-ui/icons";
import pluralize from "pluralize";
import React, { ReactNode } from "react";
import { PrimaryTeamSelect } from "../web-settings/web-settings-team-settings/PrimaryTeamSelect";
import { TeamName } from "./TeamName";
import { useTeamScrollTo } from "./TeamScrollContext";

export type SimpleMember = {
  id: string;
  invitePending: boolean;
};

type Props = {
  id: string;
  name: string;
  focusTime: number;
  busyTime: number;
  fragmentedTime: number;
  disruptiveMeetingCount: number;
  noMeetingDay:
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday"
    | "Sunday"
    | null;
  manageTeamCalendar: boolean;
  members: SimpleMember[];
};

export const TeamStats = ({
  id,
  focusTime,
  busyTime,
  fragmentedTime,
  noMeetingDay,
  manageTeamCalendar,
  members,
}: Props) => {
  const focusHours = Math.floor(focusTime / 60);
  const fragmentedHours = Math.floor(fragmentedTime / 60);
  const busyHours = Math.floor(busyTime / 60);

  const invitesPending = members.filter((m) => m.invitePending).length;
  const scrollTo = useTeamScrollTo();

  const onGoToTeammates = () => {
    scrollTo("teammates");
  };

  const onGoToNoMeetingDay = () => {
    scrollTo("team-no-meeting-day");
  };

  const onGoToTeamCalendar = () => {
    scrollTo("team-calendar");
  };

  return (
    <div className="cw-flex cw-flex-col cw-gap-4">
      <TeamName id={id} />
      <div className="cw-heading-xl">
        Your team averages {focusHours}h of Focus Time, {fragmentedHours}h of fragmented time,{" "}
        {busyHours}h of meetings.
      </div>
      <div className="cw-space-y-4">
        <StatSection
          icon={<PeopleOutline fontSize="small" />}
          title={pluralize("teammate", members.length, true)}
          subtitle={
            invitesPending > 0 ? (
              <span>
                {pluralize("invite", invitesPending, true)} pending{" "}
                <Link onClick={onGoToTeammates}>Remind them</Link>
              </span>
            ) : undefined
          }
        />
        <StatSection
          icon={
            noMeetingDay ? (
              <CheckCircleFilled fontSize="small" color="primary" />
            ) : (
              <RemoveCircleOutline fontSize="small" />
            )
          }
          title={
            noMeetingDay
              ? `No-Meeting day on ${noMeetingDay}`
              : "Your team doesn't currently have a no-meeting day"
          }
          subtitle={
            noMeetingDay ? (
              "You're all set"
            ) : (
              <Link onClick={onGoToNoMeetingDay}>Set a no-meeting day</Link>
            )
          }
        />
        <StatSection
          icon={
            manageTeamCalendar ? (
              <CheckCircleFilled fontSize="small" color="primary" />
            ) : (
              <RemoveCircleOutline fontSize="small" />
            )
          }
          title={
            manageTeamCalendar
              ? "Your team has access to the team calendar"
              : "Your team doesn't currently have a team calendar"
          }
          subtitle={
            manageTeamCalendar ? (
              "You're all set"
            ) : (
              <Link onClick={onGoToTeamCalendar}>Add a team calendar</Link>
            )
          }
        />
        <div className="cw-body-sm">
          <PrimaryTeamSelect pageTeamId={id} />
        </div>
        <div>
          <ButtonSet>
            <Button startIcon={GroupAdd} size="xsmall" variant="outlined" onClick={onGoToTeammates}>
              Add teammates
            </Button>
          </ButtonSet>
        </div>
      </div>
    </div>
  );
};

const StatSection = ({
  icon,
  title,
  subtitle,
}: {
  icon: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
}) => {
  return (
    <div className="cw-flex cw-gap-3 cw-items-center">
      <div className="icon">{icon}</div>
      <div className="cw-space-y-1">
        <div className="title cw-font-bold cw-text-default cw-text-14">{title}</div>
        {subtitle && <div className="subtitle cw-text-subtle cw-text-13">{subtitle}</div>}
      </div>
    </div>
  );
};
