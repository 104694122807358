import { DateTime } from "luxon";

export const getCurrentWeek = () => {
  const currentDateTime = DateTime.now();

  const startOfWeek = currentDateTime.startOf("week");
  const endOfWeek = currentDateTime.endOf("week");

  if (startOfWeek.hasSame(endOfWeek, "month")) {
    return `${startOfWeek.toFormat("LLL d")} - ${endOfWeek.toFormat("d, yyyy")}`;
  }

  if (startOfWeek.hasSame(endOfWeek, "year")) {
    return `${startOfWeek.toFormat("LLL d")} - ${endOfWeek.toFormat("LLL d, yyyy")}`;
  }

  return `${startOfWeek.toFormat("LLL d, yyyy")} - ${endOfWeek.toFormat("LLL d, yyyy")}`;
};
