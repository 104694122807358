import { Divider } from "@clockwise/design-system";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import React from "react";
import { CardWrapper } from "./CardWrapper";
import { CollapsibleSection } from "./atoms/CollapsibleSection";
import { WarningMessage } from "./atoms/WarningMessage";
import { ECCalendarInfo } from "./molecules/ECCalendarInfo";
import { ECPrivateEventCallout } from "./molecules/ECPrivateEventCallout";
import { ECTitle } from "./molecules/ECTitle";
import { Time } from "./molecules/Time";
import { EventCardWarning } from "./utils/getEventCardWarning";

export const LockedEventCard = ({ calendarId, title }: { calendarId: string; title?: string }) => {
  const updateActiveEvent = useUpdateActiveEvent();

  return (
    <CardWrapper
      header={<div className="cw-font-semibold">Event</div>}
      onClose={() => {
        updateActiveEvent(null);
      }}
      footer={null}
    >
      <div>
        <WarningMessage type={EventCardWarning.NotYourEvent} />
        <ECTitle readOnly={true} eventName={title ?? "Busy"} />
      </div>
      <Time readOnly={true} />
      <Divider spacing="xs" inset />
      <CollapsibleSection numberOfSections={2}>
        <ECCalendarInfo calendarId={calendarId} />
        <ECPrivateEventCallout />
      </CollapsibleSection>
    </CardWrapper>
  );
};
