import React from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";
import { EventVisibility, SelectEventVisibility } from "../atoms/SelectEventVisibility";
import { SelectTransparency, Transparency } from "../atoms/SelectTransparency";

type Props = {
  readonly?: boolean;
  visibility?: EventVisibility;
  transparency?: Transparency;
  onVisibilityChange?: (visibility: EventVisibility) => void;
  onTransparencyChange?: (transparency: Transparency) => void;
};

export const ECVisibilitySettings = ({
  readonly = false,
  visibility,
  transparency,
  onVisibilityChange,
  onTransparencyChange,
}: Props) => {
  return (
    <EventElementsWrap name="visibility">
      <div className="cw-flex cw-justify-between cw-items-center cw-flex-1 cw-gap-2">
        {transparency && (
          <SelectTransparency
            disabled={readonly}
            selected={transparency}
            onSelect={onTransparencyChange}
          />
        )}
        {visibility && (
          <SelectEventVisibility
            disabled={readonly}
            selected={visibility}
            onSelect={onVisibilityChange}
          />
        )}
      </div>
    </EventElementsWrap>
  );
};
