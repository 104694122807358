import React, { useCallback, useMemo } from "react";

import { TimeInput } from "@clockwise/design-system";
import { DateTime, Duration } from "luxon";

type Props = {
  value: string | null;
  readOnly: boolean;
  onChange: (value: string) => void;
  error: string | null;
};

export const ECTimePicker = ({ readOnly, onChange, value, error }: Props) => {
  return (
    <TimeInput
      size="small"
      value={value}
      onChange={(value) => value && onChange(value)}
      error={!!error}
      readOnly={readOnly}
      placeholder="hh:mm"
    />
  );
};

const formatDuration = (duration: Duration) => {
  const hours = duration.as("hours");
  return hours < 1 ? `${duration.as("minutes")} min` : `${hours} hr`;
};

export const ECEndTimePicker = ({
  readOnly,
  onChange,
  value,
  startTime,
  error,
}: Props & { startTime: string }) => {
  // Generate a list of time options covering the 24 hour period following the start time
  const options = useMemo(() => {
    const timeOptions = [];
    const start = DateTime.fromFormat(startTime, "hh:mm");
    let time = start;
    while (timeOptions.length < 48) {
      const duration = time <= start ? time.plus({ day: 1 }).diff(start) : time.diff(start);
      const label = (
        <span className="cw-whitespace-nowrap">
          {time.toFormat("h:mm a").toLowerCase()} ({formatDuration(duration)})
        </span>
      );
      timeOptions.push({
        label,
        value: time.toISOTime({ includeOffset: false, suppressSeconds: true }),
      });
      time = time.plus({ minutes: 30 });
    }
    return timeOptions;
  }, [startTime]);

  //  Sort time options so the first option is the earliest time after the start time and any times
  //  that cross into the next day are sorted to the end of the list
  //
  //  Example: Given startTime=09:00, 08:30 should sort toward the end of the option list since it
  //  represents 8:30 the next day.
  //
  const sortOptions = useCallback(
    (a: { value: string }, b: { value: string }) => {
      const aOffset = a.value <= startTime ? 1 : 0;
      const bOffset = b.value <= startTime ? 1 : 0;
      if (aOffset !== bOffset) {
        return aOffset - bOffset;
      }
      return a.value < b.value ? -1 : 1;
    },
    [startTime],
  );

  return (
    <TimeInput
      size="small"
      value={value}
      onChange={(value) => value && onChange(value)}
      error={!!error}
      options={options}
      sortOptions={sortOptions}
      readOnly={readOnly}
      placeholder="hh:mm"
    />
  );
};
