import { Select, SelectOption } from "@clockwise/design-system";
import { Chip } from "@material-ui/core";
import React from "react";
import { FunnelIcon } from "../funnel-icon";

type Props = {
  onSelect: (teamId?: string, billingGroupId?: string) => void;
  teamOptions: { label: string; value: string }[];
  billingGroupOptions: { label: string; value: string }[];
  bgAndTeamFilters: {
    bgId: string;
    teamId: string;
  };
};

export const MultiFilter = ({
  teamOptions,
  billingGroupOptions,
  onSelect,
  bgAndTeamFilters,
}: Props) => {
  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  const clearFilter = () => {
    onSelect(undefined, undefined);
  };

  const maybeGetSelectedFilterLabel = () => {
    return {
      teamLabel: teamOptions.find((o) => o.value === bgAndTeamFilters.teamId)?.label,
      billingGroupLabel: billingGroupOptions.find((o) => o.value === bgAndTeamFilters.bgId)?.label,
    };
  };

  const billingGroupLabel = maybeGetSelectedFilterLabel().billingGroupLabel;
  const teamLabel = maybeGetSelectedFilterLabel().teamLabel;

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  const renderTeamFilter = () => {
    return (
      <div>
        <Select
          value={bgAndTeamFilters.teamId}
          onChange={(newTeamId: string) => {
            onSelect(newTeamId, undefined);
          }}
          disabled={!teamOptions.length}
        >
          <SelectOption value="">Teams</SelectOption>
          {teamOptions.map((option) => (
            <SelectOption key={`team-${option.value}`} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </Select>
      </div>
    );
  };

  const renderBillingGroupFilter = () => {
    return (
      <div>
        <Select
          value={bgAndTeamFilters.bgId}
          onChange={(newBillingGroupId: string) => {
            onSelect(undefined, newBillingGroupId);
          }}
          disabled={!billingGroupOptions.length}
        >
          <SelectOption value="">Plans</SelectOption>
          {billingGroupOptions.map((option) => (
            <SelectOption key={`subscription-${option.value}`} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </Select>
      </div>
    );
  };

  const renderSelectForms = () => {
    return (
      <div className="cw-flex cw-items-center cw-gap-2">
        {renderTeamFilter()}
        <div className="cw-body-lg cw-text-accent cw-font-bold">or</div>
        {renderBillingGroupFilter()}
      </div>
    );
  };

  const renderPill = () => {
    const groupChipLabel = billingGroupLabel ? "Plan" : "";

    const chipLabel = (
      <div className="cw-leading-snug">
        <span>{teamLabel ? "Team" : groupChipLabel}</span>
        {": "}
        <span className="cw-font-bold">{teamLabel || billingGroupLabel}</span>
      </div>
    );
    return (
      <Chip
        label={chipLabel}
        onDelete={(_event) => clearFilter()}
        classes={{
          root: "cw-bg-accent-emphasis cw-text-onEmphasis focus:cw-bg-accent-emphasis",
          deleteIcon: "cw-text-onEmphasis hover:cw-text-onEmphasis-hover",
        }}
      />
    );
  };

  return (
    <div className="cw-flex cw-items-center">
      <div className="cw-flex cw-items-center cw-mr-2 cw-gap-2 cw-text-accent">
        <FunnelIcon />
        <div className="cw-body-lg cw-text-accent cw-font-bold">
          {billingGroupLabel || teamLabel ? "Filtered" : "Filter"} by:
        </div>
      </div>
      {billingGroupLabel || teamLabel ? renderPill() : renderSelectForms()}
    </div>
  );
};
