import { ResponseStatusEnum } from "@clockwise/schema";
import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar/";
import { ICalPositionable } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import { DateTime, Duration } from "luxon";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../state/reducers/root.reducer";
import { usePlannerContext } from "../Context";
import { hasIntervalAllDayLong } from "../ooo-events/utils/hasIntervalAllDayLong";
import { PlannerEvent } from "../planner-event/PlannerEvent";
import { PlannerEventCard } from "../types";
import { hasCategoryOutOfOffice } from "../util/hasCategoryOutOfOffice";
import { getDateStringKeyFromDateTime } from "./utils/getDateStringKeyFromDateTime";

const MIN_DURATION = Duration.fromObject({ minutes: 15 });

export const SingleColumnPlannerEvents = ({
  calendarId,
  dateTime,
  columnIndex,
  columnCount,
  minWidth,
}: {
  calendarId: string;
  dateTime: DateTime;
  columnIndex: number;
  columnCount: number;
  minWidth: number;
}) => {
  const { hideDeclined, eventsByDayAndCalendar } = usePlannerContext();

  const highlightedSlotOnPlanner = useSelector(
    (state: IReduxState) => state.plannerHighlights.slot,
  );

  const eventIdsToFade = useMemo(
    () => (highlightedSlotOnPlanner?.additionalChanges || []).map((ac) => ac.event.externalEventId),
    [highlightedSlotOnPlanner?.additionalChanges],
  );

  const isHiddenBecauseDeclined = useMemo(
    () => isHiddenResponseStatus(hideDeclined ? [ResponseStatusEnum.Declined] : []),
    [hideDeclined],
  );

  const dateToRender = getDateStringKeyFromDateTime(dateTime);

  const eventsByCalendarForDay = useMemo(() => {
    return eventsByDayAndCalendar ? eventsByDayAndCalendar[dateToRender] : null;
  }, [dateToRender, eventsByDayAndCalendar]);

  const normalEvents = useMemo(() => eventsByCalendarForDay?.normalEvents[calendarId] ?? [], [
    eventsByCalendarForDay,
    calendarId,
  ]);
  const renderEvents = useMemo(
    () =>
      Object.values(normalEvents ?? {})
        .flatMap((events) => events)
        .filter((event) => !hasCategoryOutOfOffice(event) || !hasIntervalAllDayLong(event))
        .filter(isHiddenBecauseDeclined),
    [isHiddenBecauseDeclined, normalEvents],
  );

  const positionables: ICalPositionable[] = useMemo(
    () =>
      renderEvents.map((evnt) => ({
        // Note that we must use `positioner?.keyAddendum` for Schedule by Moving `Before` and `After` views
        // because `positioner.key`s alone are not unique, which leads to UI bugs
        // TODO (jimmy): let's fix `key` Linear AI-1422 it should be unique
        // and we separate the conflation by forcing downstream consumers to use `externalEventId` instead of `key`
        key: `${evnt.key + "SingleDay"}${evnt.keyAddendum ?? ""}`,
        interval: evnt.interval,
        render: ({ position, columnIndex: cIndex }) => (
          <PlannerEvent
            key={evnt.key}
            card={evnt}
            fadedWithAWandLabel={eventIdsToFade.includes(evnt?.externalEventId || "")}
            leftPad={hasCategoryOutOfOffice(evnt) && position.renderOrder > 0}
            eventConsolidation={false}
            position={position}
            columnIndex={cIndex}
            isSplitView
          />
        ),
      })),
    [eventIdsToFade, renderEvents],
  );

  return (
    <CalendarPositioner
      dateTimes={[dateTime]}
      conflictResolution="columnOverlap"
      gutters
      minDuration={MIN_DURATION}
      positionables={positionables}
      columnIndexOverride={columnIndex}
      columnCountOverride={columnCount}
      minWidth={minWidth}
    />
  );
};

const isHiddenResponseStatus = (hiddenResponseStatuses: (ResponseStatusEnum | "Proposed")[]) => (
  event: PlannerEventCard,
) => !hiddenResponseStatuses.includes(event.responseState);
