import { SchedulingProposalStateEnum } from "@clockwise/schema";
import { find, last } from "lodash";
import { useAIMessageContext } from "../ai-chat/hooks/AIMessageContext";

export const useActiveViewEventsResponse = () => {
  const { messages, processingMessage, loadingHistory } = useAIMessageContext();
  const mostRecentMessage = last(messages);

  const activeProposalResponse = find(
    messages,
    (message) =>
      message.__typename === "ProposalResponse" &&
      message.proposal.state === SchedulingProposalStateEnum.ACTIVE,
  );

  const activeViewEventsResponse =
    mostRecentMessage?.__typename === "ViewEventsResponse" && !activeProposalResponse
      ? mostRecentMessage
      : undefined;

  const loading = loadingHistory || processingMessage;

  return { activeViewEventsResponse, loading };
};
