import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { DiffActionTypeEnum } from "@clockwise/schema";
import { isEmpty, uniq } from "lodash";
import { Interval } from "luxon";
import React, { useEffect } from "react";
import { ProposalRenderSurface } from "../../../constants";
import { useSetAttendeeCalendarColors } from "../../hooks/useSetAttendeeCalendarColors";
import { getHasMultipleDiffs } from "../../utils/getHasMultipleDiffs";
import { getIntervalFromDiffSummary } from "../../utils/getIntervalFromDiffSummary";
import { GQLDiffBlocks, GQLSchedulingOptions } from "../../utils/types";
import { AIMarkdown } from "../ai-markdown";
import { DiffEventCardCollapsed } from "./DiffEventCardCollapsed";
import { SchedulingType } from "./types";

type DiffBlock = GQLDiffBlocks[0];

export const EmptyDivForSpacing = () => <div className="cw-px-3"></div>;

type DiffBlockProps = {
  diffBlock: DiffBlock;
  proposalDiffType: SchedulingType;
  tradeoffTypes?: string[];
  totalNumberOfAttendeesOnAllDiffs?: number;
  options: GQLSchedulingOptions | null;
  variant?: ProposalRenderSurface;
  viewerIsProposalOwner?: boolean;
};

const DiffBlock = ({
  diffBlock,
  proposalDiffType,
  tradeoffTypes = [],
  totalNumberOfAttendeesOnAllDiffs = 0,
  options,
  variant = "AI_CHAT",
  viewerIsProposalOwner = true,
}: DiffBlockProps) => {
  const renderDiffBlockText = variant === "AI_CHAT";

  return (
    <div>
      {renderDiffBlockText && (
        <div className="cw-body-sm cw-inline-block">
          <AIMarkdown text={diffBlock.text} />
        </div>
      )}
      <div className="cw-flex cw-flex-col cw-my-2 cw-gap-2">
        {diffBlock.diffs.map((diff) => {
          const { id, title, tradeoffBlocks, attendees, action, recurrenceRule, isFlexible } = diff;

          const attendeePeople = attendees.proposalAttendees.map(
            (attendee) => attendee.attendeePerson,
          );
          let interval: Interval | undefined;

          let recurrenceForCard: RecurrenceRule | null | undefined = undefined;
          if (action.type === DiffActionTypeEnum.ADD) {
            recurrenceForCard = recurrenceRule ? new RecurrenceRule(recurrenceRule) : null;
            interval = getIntervalFromDiffSummary(diff).extractNullable() ?? undefined;
          }

          const tradeoffBlocksToShow = tradeoffTypes.length
            ? tradeoffBlocks.filter((tradeoff) => tradeoffTypes.includes(tradeoff.tradeoffType))
            : tradeoffBlocks;

          return (
            <DiffEventCardCollapsed
              action={action}
              ambiguities={attendees?.ambiguities ?? []}
              attendeePeople={attendeePeople}
              totalNumberOfAttendeesOnAllDiffs={totalNumberOfAttendeesOnAllDiffs}
              id={id}
              interval={interval}
              isOnlyDiffInProposal={diffBlock.diffs.length <= 1}
              key={id}
              isSBM={proposalDiffType === SchedulingType.SBM}
              recurrenceRule={recurrenceForCard}
              schedulingOptions={options}
              title={title}
              isFlexible={isFlexible}
              tradeoffBlocks={tradeoffBlocksToShow}
              variant={variant}
              viewerIsProposalOwner={viewerIsProposalOwner}
              isConsequenceBlock={false}
            />
          );
        })}
      </div>
    </div>
  );
};

export const ProposalDiffBlocks = ({
  diffBlocks,
  isScheduleByMoving,
  tradeoffTypes = [],
  options,
  variant = "AI_CHAT",
  viewerIsProposalOwner = true,
}: {
  diffBlocks: GQLDiffBlocks;
  isScheduleByMoving: boolean;
  tradeoffTypes?: string[];
  options: GQLSchedulingOptions | null;
  variant?: ProposalRenderSurface;
  viewerIsProposalOwner?: boolean;
}) => {
  const isBulkSchedulingProposal = getHasMultipleDiffs(diffBlocks);
  const { updateProposalAttendeeCalendarColors } = useSetAttendeeCalendarColors();
  const schedulingOptions =
    options?.__typename === "SchedulingOptions" &&
    !isEmpty(options?.optionDetails) &&
    !isBulkSchedulingProposal
      ? options
      : null;

  useEffect(() => {
    updateProposalAttendeeCalendarColors(diffBlocks);
  }, [diffBlocks]);

  const allPrimaryCalendarOfAttendees = uniq(
    diffBlocks.reduce((acc, diffBlock) => {
      const primaryCalendarOfAttendeesOnDiffBlock = diffBlock.diffs.reduce((innerAcc, diff) => {
        const primaryCalendarOfAttendeesOnDiff = diff.attendees.proposalAttendees.map(
          (attendee) => attendee.attendeePerson.primaryCalendar,
        );

        return [...innerAcc, ...primaryCalendarOfAttendeesOnDiff];
      }, []);

      return [...acc, ...primaryCalendarOfAttendeesOnDiffBlock];
    }, []),
  );
  const totalNumberOfAttendeesOnAllDiffs = allPrimaryCalendarOfAttendees.length;

  return (
    <div className="cw-mb-2">
      {diffBlocks.map((diffBlock, i) => (
        <DiffBlock
          key={`${diffBlock.text.slice(15)}-${i}`}
          diffBlock={diffBlock}
          proposalDiffType={
            isScheduleByMoving
              ? SchedulingType.SBM
              : isBulkSchedulingProposal
              ? SchedulingType.BULK
              : SchedulingType.SINGLE
          }
          tradeoffTypes={tradeoffTypes}
          totalNumberOfAttendeesOnAllDiffs={totalNumberOfAttendeesOnAllDiffs}
          options={schedulingOptions}
          variant={variant}
          viewerIsProposalOwner={viewerIsProposalOwner}
        />
      ))}
    </div>
  );
};
