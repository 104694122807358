export const DATE_FORMAT_DISPLAY = "ddd, D MMM";
export const DEFAULT_WINDOW_SIZE_IN_DAYS = 14;
export const MAX_WINDOW_SIZE_IN_DAYS = 90;
export const MIN_WINDOW_SIZE_IN_DAYS = 1;
export const DURATIONS = [
  10,
  15,
  20,
  25,
  30,
  45,
  50,
  60,
  75,
  90,
  120,
  150,
  180,
  240,
  360,
  420,
] as const;
