import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar";
import { OutOfOfficeTab } from "@clockwise/web-commons/src/components/calendar/calendar-out-of-office/OutOfOfficeTabs";
import { OutOfOfficeInterval } from "@clockwise/web-commons/src/components/calendar/calendar-out-of-office/types";
import {
  EventType,
  useReadActiveEvent,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getOwnCalendarColor } from "@clockwise/web-commons/src/util/calendar-coloring";
import { flatMap, isEmpty, uniqBy } from "lodash";
import { DateTime, Interval } from "luxon";
import React, { useMemo } from "react";
import { useUpdateActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import { usePlannerContext } from "../Context";
import { useReadCalendarColors } from "../hooks/CalendarColorsContext";
import { PlannerEventCard } from "../types";
import { hasIntervalAllDayLong } from "./utils/hasIntervalAllDayLong";

type Props = {
  calendarIds: string[];
  dateTime: DateTime;
  dateToRender: string;
  columnCount: number;
  minWidth: number;
};

export const SingleDayAllDayEventsUnderlay = ({
  dateToRender,
  dateTime,
  calendarIds,
  columnCount,
  minWidth,
}: Props) => {
  const { eventsByDayAndCalendar } = usePlannerContext();

  const eventsByCalendarForDay = useMemo(() => {
    return eventsByDayAndCalendar ? eventsByDayAndCalendar[dateToRender] : null;
  }, [dateToRender, eventsByDayAndCalendar]);

  const allDayOOOEvents = eventsByCalendarForDay?.allDayOOOEvents ?? {};

  const eventsForAllCalendars = flatMap(
    calendarIds.map((calendarId) => allDayOOOEvents[calendarId] ?? []),
  );

  if (isEmpty(eventsForAllCalendars)) {
    return null;
  }

  return (
    <div className="cw-h-6 cw-relative cw-flex cw-flex-row cw-flex-1 cw-w-full">
      {calendarIds.map((calendarId, index) => {
        const eventsForCalendar = uniqBy(allDayOOOEvents[calendarId] ?? [], "externalEventId");
        const outOfOfficeEvents = eventsForCalendar.filter(
          (event) => event.interval.toDuration().as("days") >= 1 || hasIntervalAllDayLong(event),
        );
        if (isEmpty(outOfOfficeEvents)) {
          return null;
        }
        return (
          <AllDayOOOEventUnderlay
            key={calendarId}
            columnCount={columnCount}
            index={index}
            dateTime={dateTime}
            minWidth={minWidth}
            events={outOfOfficeEvents.filter((event) => event.calendarIds.includes(calendarId))}
          />
        );
      })}
    </div>
  );
};

const AllDayOOOEventUnderlay = ({
  columnCount,
  index,
  dateTime,
  minWidth,
  events,
}: {
  columnCount: number;
  index: number;
  dateTime: DateTime;
  minWidth: number;
  events: PlannerEventCard[];
}) => {
  const calendarColorSets = useReadCalendarColors();
  const activeEvent = useReadActiveEvent();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateActiveEventDiff = useUpdateActiveEventDiff();

  const handleClick = (calendarId: string, externalEventId: string) => {
    const activeEventIsProposal = activeEvent && activeEvent.type === EventType.Proposal;
    if (activeEventIsProposal) return;

    updateActiveEventDiff(null);
    updateActiveEvent({ externalEventId, calendarId, type: EventType.Event });
  };

  const outOfOfficeIntervals: OutOfOfficeInterval[] = events.map((event) => ({
    colorSet: calendarColorSets[event.calendarIds[0]] || getOwnCalendarColor(),
    interval: Interval.fromDateTimes(
      event.interval.start.startOf("day"),
      event.interval.start.endOf("day"),
    ),
    key: event.key,
    onClick: () => handleClick(event.calendarIds[0], event.externalEventId || ""),
    subText: event.subText,
    text: event.text,
  }));

  return (
    <CalendarPositioner
      columnCountOverride={columnCount}
      columnIndexOverride={index}
      dateTimes={[dateTime]}
      gutters
      conflictResolution="column"
      minWidth={minWidth}
      positionables={outOfOfficeIntervals.map(({ interval, key, colorSet, text, onClick }) => ({
        key: `${interval.toISO()}_${key}`,
        interval,
        render: ({ position }) => (
          <OutOfOfficeTab
            colorSet={colorSet}
            text={text}
            onClick={onClick}
            leftPad={position.renderOrder > 0}
          />
        ),
      }))}
    />
  );
};
