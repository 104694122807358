import { ApolloError } from "@apollo/client";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { InviteSearchPerson } from "../types";
import { SuggestedInvitesDocument } from "./__generated__/InviteSearch.v2.generated";

const MAX_SUGGESTIONS = 20;
export const useInviteSuggestions = (onError?: (error: ApolloError) => void) => {
  const { data, loading } = useGatewayQuery(SuggestedInvitesDocument, {
    variables: { limit: MAX_SUGGESTIONS },
    fetchPolicy: "no-cache", // needed to refetch suggestions when user goes back in onboarding
    onError,
  });
  let invitees = data?.suggestedInvitees ?? [];
  invitees = invitees.concat(
    data?.searchInvitees.filter((invitee) => {
      return invitees.every((suggestedInvitee) => invitee.id !== suggestedInvitee.id);
    }) ?? [],
  );

  const suggestions: InviteSearchPerson[] = invitees.slice(0, MAX_SUGGESTIONS).map((invitee) => {
    return {
      profile: {
        givenName: invitee.member.person.givenName,
        familyName: invitee.member.person.familyName,
        externalImageUrl: invitee.member.person.externalImageUrl,
      },
      isUser: invitee.joined,
      primaryCalendarId: invitee.member.person.email,
      pending: invitee.invited,
      suggested: invitee.suggested,
    };
  });

  return { suggestions, loading };
};
