import { Day, ExcludedSlots, MaxSlot, MinSlot, TimeSlotEnum } from "@clockwise/schema";
import { notEmpty } from "@clockwise/web-commons/src/util/notEmpty";
import { forEach } from "lodash";
import { DateObjectUnits, DateTime, Interval, WeekdayNumbers } from "luxon";

type WorkingHours = {
  timeZone: string | null;
  daySettings:
    | ({
        day: Day | null;
        setting: {
          minSlot: MinSlot | null;
          maxSlot: MaxSlot | null;
          excludedSlots: (ExcludedSlots | null)[] | null;
        } | null;
      } | null)[]
    | null;
};

export const intervalsFromWorkingHours = (dateTimes: DateTime[]) => (
  workingHours: WorkingHours,
) => {
  const intervals = [] as Interval[];

  if (!workingHours || !workingHours.daySettings || !workingHours.timeZone) return intervals;
  const { timeZone, daySettings } = workingHours;

  const weekdayStartEndTuples = new Map<WeekdayNumbers, [DateObjectUnits, DateObjectUnits][]>();
  forEach(daySettings, (daySetting) => {
    if (!daySetting || !daySetting.day || !daySetting.setting) return;

    const { day, setting } = daySetting;
    const weekdayNumber = getLuxonWeekdayFromSchemaDay(day);
    weekdayStartEndTuples.set(weekdayNumber, []);

    if (!setting.minSlot || !setting.maxSlot) return;
    const { minSlot, maxSlot, excludedSlots } = setting;

    const minSlotIndex = findIndexByValue(TimeSlotEnum, minSlot);
    const maxSlotIndex = findIndexByValue(TimeSlotEnum, maxSlot);
    const excludedSlotIndexes =
      excludedSlots?.filter(notEmpty).map((exclude) => findIndexByValue(TimeSlotEnum, exclude)) ||
      [];
    const slotIndexes = generateIndexArray(minSlotIndex, maxSlotIndex, excludedSlotIndexes);
    const sequentialSlotIndexes = splitIntoSequentialArrays(slotIndexes);
    const startAndEndSlotIndexes = toStartEndIndexTuple(sequentialSlotIndexes);
    const startAndEndSlots = toStartEndSlotTuple(startAndEndSlotIndexes);
    const startAndEndDateTimeUnits = toStartEndDateObjectUnitsTuple(startAndEndSlots);

    weekdayStartEndTuples.set(weekdayNumber, startAndEndDateTimeUnits);
  });

  dateTimes.forEach((dateTime) => {
    const weekdayNumber = dateTime.weekday;
    const startEndTuplesForDay = weekdayStartEndTuples.get(weekdayNumber);
    if (!startEndTuplesForDay) return;

    startEndTuplesForDay.forEach(([start, end]) => {
      const startDateTime = dateTime.set(start).setZone(timeZone, { keepLocalTime: true });
      const endDateTime = dateTime
        .set(end)
        .plus({ minutes: 30 }) // Add 30 to include this slot
        .setZone(timeZone, { keepLocalTime: true });
      intervals.push(Interval.fromDateTimes(startDateTime, endDateTime));
    });
  });

  return intervals;
};

const getLuxonWeekdayFromSchemaDay = (day: Day) => {
  switch (day) {
    case Day.Monday:
      return 1;
    case Day.Tuesday:
      return 2;
    case Day.Wednesday:
      return 3;
    case Day.Thursday:
      return 4;
    case Day.Friday:
      return 5;
    case Day.Saturday:
      return 6;
    case Day.Sunday:
      return 7;
  }
};

/** Find the index of an value in its enum */
const findIndexByValue = (enumObject: Record<string, string>, value: string): number => {
  const keys = Object.keys(enumObject);

  for (let i = 0; i < keys.length; i++) {
    if (enumObject[keys[i]] === value) {
      return i;
    }
  }

  return -1;
};

const generateIndexArray = (min: number, max: number, exclude: number[]): number[] => {
  const result: number[] = [];

  for (let i = min; i <= max; i++) {
    if (!exclude.includes(i)) {
      result.push(i);
    }
  }

  return result;
};

const splitIntoSequentialArrays = (numbers: number[]): number[][] => {
  if (numbers.length === 0) {
    return [];
  }

  const result: number[][] = [];
  let currentSequence: number[] = [numbers[0]];

  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] === numbers[i - 1] + 1) {
      // If the current number is sequential, add it to the current sequence
      currentSequence.push(numbers[i]);
    } else {
      // If the current number is not sequential, start a new sequence
      result.push(currentSequence);
      currentSequence = [numbers[i]];
    }
  }

  // Add the last sequence to the result
  result.push(currentSequence);

  return result;
};

const toStartEndIndexTuple = (arrays: number[][]): [number, number][] => {
  return arrays.map((innerArray) => [innerArray[0], innerArray[innerArray.length - 1]]);
};

const toStartEndSlotTuple = (indexTuples: [number, number][]): [TimeSlotEnum, TimeSlotEnum][] =>
  indexTuples.map(([start, end]) => [
    Object.values(TimeSlotEnum)[start],
    Object.values(TimeSlotEnum)[end],
  ]);

const toStartEndDateObjectUnitsTuple = (
  indexTuples: [TimeSlotEnum, TimeSlotEnum][],
): [DateObjectUnits, DateObjectUnits][] =>
  indexTuples.map(([startSlot, endSlot]) => [
    dateTimeUnitsFromDayAndSlot(startSlot),
    dateTimeUnitsFromDayAndSlot(endSlot),
  ]);

const dateTimeUnitsFromDayAndSlot = (slot: TimeSlotEnum): DateObjectUnits => {
  const [hour, minute] = slot
    .replace("T_", "")
    .split("_")
    .map((v) => parseInt(v, 10));
  return { hour, minute, second: 0, millisecond: 0 };
};
