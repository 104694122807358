import { ECResponseStatus } from "#webapp/src/components/event-card/molecules/ECResponseStatus";
import { Button } from "@clockwise/design-system";
import { ResponseStatusEnum } from "@clockwise/schema";
import {
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React from "react";
import { useGatewayEventResponseStatus } from "../../event-scheduling/hooks/useGatewayEventResponseStatus";
import { eventToast } from "../notifiation-event/EventToast";

const selectableRSVPs = [
  ResponseStatusEnum.Accepted,
  ResponseStatusEnum.Tentative,
  ResponseStatusEnum.Declined,
] as const;
type SelectableRSVP = typeof selectableRSVPs[number];

export const RSVPButtons = ({
  externalEventId,
  calendarId,
  responseState,
  onClose,
  toastOptions,
  variant,
}: {
  externalEventId: string;
  calendarId: string;
  responseState: ResponseStatusEnum;
  onClose?: () => void;
  toastOptions: {
    title: string;
    showViewEventButton?: boolean;
  };
  variant: "compact" | "expanded";
}) => {
  const { update } = useGatewayEventResponseStatus(externalEventId, calendarId);
  const track = useTracking();
  const updateActiveEvent = useUpdateActiveEvent();

  const handleViewEvent = () => {
    updateActiveEvent({
      calendarId,
      externalEventId,
      type: EventType.Event,
    });
  };

  const handleSelectRSVP = (value: SelectableRSVP) => {
    if (value === responseState) return;
    if (!update) {
      eventToast.error({
        operation: "EDIT_DETAILS",
        title: toastOptions.title,
        onViewEvent: toastOptions.showViewEventButton ? handleViewEvent : undefined,
      });
      onClose?.();
      return;
    }
    if (variant === "compact") {
      track(TrackingEvents.EVENT_POPOVER.RSVP_SELECTED, {
        rsvp: value,
      });
    }
    void update({
      responseStatus: value,
      onError: () => {
        eventToast.error({
          operation: "EDIT_DETAILS",
          title: toastOptions.title,
          onViewEvent: toastOptions?.showViewEventButton ? handleViewEvent : undefined,
        });
      },
    });
  };

  if (variant === "expanded") {
    return <ECResponseStatus rsvpStatus={responseState} onRSVPChange={handleSelectRSVP} />;
  }

  return (
    <div className="cw-flex">
      <Button
        sentiment={responseState === ResponseStatusEnum.Accepted ? "positive" : "neutral"}
        variant="outlined"
        size="xsmall"
        onClick={() => {
          handleSelectRSVP(ResponseStatusEnum.Accepted);
          onClose?.();
        }}
      >
        Yes
      </Button>

      <div className="cw-mx-1">
        <Button
          sentiment={responseState === ResponseStatusEnum.Declined ? "positive" : "neutral"}
          variant="outlined"
          size="xsmall"
          onClick={() => {
            handleSelectRSVP(ResponseStatusEnum.Declined);
            onClose?.();
          }}
        >
          No
        </Button>
      </div>
      <Button
        sentiment={responseState === ResponseStatusEnum.Tentative ? "positive" : "neutral"}
        variant="outlined"
        size="xsmall"
        onClick={() => {
          handleSelectRSVP(ResponseStatusEnum.Tentative);
          onClose?.();
        }}
      >
        Maybe
      </Button>
    </div>
  );
};
