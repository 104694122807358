import { SchedulingProposalStateEnum } from "@clockwise/schema";
import React from "react";

export const ProposalResolvedRow = ({ state }: { state: SchedulingProposalStateEnum }) => {
  switch (state) {
    case SchedulingProposalStateEnum.COMPLETED:
      return <>✅ All done. What's next?</>;
    case SchedulingProposalStateEnum.CANCELLED:
      return <>No problem! We can try again later</>;

    default:
      return null;
  }
};
