import { useGatewayLazyQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { debounce } from "lodash";
import { useMemo } from "react";
import { InviteSearchPerson } from "../types";
import { InviteSearchDocument } from "./__generated__/InviteSearch.v2.generated";

export const useInviteSearch = () => {
  const [search, { data: newData, loading, previousData }] = useGatewayLazyQuery(
    InviteSearchDocument,
  );
  const data = newData ?? previousData;
  const debouncedSearch = useMemo(() => debounce(search, 500), [search]);
  const results = useMemo((): InviteSearchPerson[] => {
    const searchInvitees = data?.searchInvitees ?? [];
    return searchInvitees.map(({ invited, suggested, joined, member: { person } }) => ({
      profile: {
        givenName: person.givenName,
        familyName: person.familyName,
        externalImageUrl: person.externalImageUrl,
      },
      primaryCalendarId: person.email,
      isUser: joined,
      pending: invited,
      suggested,
    }));
  }, [data?.searchInvitees]);

  return { loading, results, search: debouncedSearch };
};
