import React from "react";
import { useMonetization } from "../../hooks/useMonetization";
import { WebSettingsOrganizationPlansAndBilling } from "../web-settings/web-settings-organization-plans-and-billing";
import { PricingPlansAndBilling } from "./PricingPlansAndBilling";

const PlansAndBilling = () => {
  const { shouldUserSeePricingPagePlansAndBilling } = useMonetization();
  return shouldUserSeePricingPagePlansAndBilling ? (
    <PricingPlansAndBilling />
  ) : (
    <WebSettingsOrganizationPlansAndBilling />
  );
};

export default PlansAndBilling;
