import { DiffActionTypeEnum, ResponseStatusEnum } from "@clockwise/schema";
import { CalendarEventStatus } from "@clockwise/web-commons/src/components/calendar/calendar-event/CalendarEvent";
import { PlannerEventCard, RescheduleProposalType, TransparencyEnum } from "../types";

export const getCalendarEventStatus = (event: PlannerEventCard): CalendarEventStatus => {
  let status: CalendarEventStatus = "Default";
  if (event.responseState === "Proposed") {
    switch (event.proposalType) {
      case DiffActionTypeEnum.ADD:
        status = "Feature";
        break;
      case DiffActionTypeEnum.CANCEL:
        status = "Vestige";
        break;
      case DiffActionTypeEnum.RSVP:
        if (event.proposedResponseState === ResponseStatusEnum.Declined) {
          status = "Vestige";
        } else if (event.proposedResponseState === ResponseStatusEnum.Accepted) {
          status = "Feature";
        } else {
          status = "Prototype";
        }
        break;
      case DiffActionTypeEnum.EDIT_DETAILS:
      case DiffActionTypeEnum.RESCHEDULE:
        if (event.rescheduleProposalType === RescheduleProposalType.BEFORE) {
          status = "Before";
        } else {
          status = "Prototype";
        }
        break;
    }
  } else if (event.isCancelled) {
    status = "Vestige";
  } else {
    if (event.transparency === TransparencyEnum.Tentative) {
      status = "Possible";
    } else {
      switch (event.responseState) {
        case ResponseStatusEnum.Declined:
          status = "Vestige";
          break;
        case ResponseStatusEnum.NeedsAction:
          status = "Unknown";
          break;
        case ResponseStatusEnum.Tentative:
          status = "Possible";
          break;
      }
    }
  }
  return status;
};
