import { Select, SelectOption, Skeleton } from "@clockwise/design-system";
import React from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";

export function ECCalendarSelect({
  calendar,
  readOnly,
  loading,
}: {
  calendar: {
    displayName: string;
    calendarId: string;
  };
  readOnly: boolean;
  loading?: boolean;
}) {
  if (loading) {
    return (
      <EventElementsWrap name="calendar">
        <Skeleton variant="rect" height={24} />
      </EventElementsWrap>
    );
  }

  // TODO: should we render calendar/event color stuff here like gcal
  // const calendarColor = useReadCalendarColor(calendarId);
  const { displayName, calendarId } = calendar;

  return (
    <EventElementsWrap name="calendar">
      <Select
        showExpandIcon={!readOnly}
        disabled={readOnly}
        value={calendarId}
        onChange={() => {}}
        fullWidth
      >
        <SelectOption key={calendarId} value={calendarId}>
          <span className="cw-body-sm cw-font-medium cw-text-default-disabled">{displayName}</span>
        </SelectOption>
      </Select>
    </EventElementsWrap>
  );
}
