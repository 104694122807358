import {
  AttendeeQuestionTypeEnum,
  AvailabilityRestrictionEnum,
  BookableHoursEnum,
  BookingLimitPeriodEnum,
  GroupSchedulingStrategyEnum,
  LocationTypeEnum,
  SchedulingPriorityEnum,
} from "@clockwise/schema/v2";
import { nanoid } from "nanoid";
import { MeetingHours } from "./CustomMeetingHours";
import { DEFAULT_WINDOW_SIZE_IN_DAYS } from "./consts";

export type AttendeeQuestion = {
  question: string;
  required: boolean;
  type: AttendeeQuestionTypeEnum;
  // Must have unique id for Reorder.Group to work. This client-only value is discarded when saving.
  renderId: string;
};
export interface SchedulingLinkMeetingDetails {
  id?: string | null;
  slug: string;
  name: string;
  description?: string | null;
  durations: number[];
  defaultDuration: number | null;
  availabilityRange: {
    windowSize: number | null;
    startDate: string | null;
    endDate: string | null;
  };
  location: LocationTypeEnum;
  linkMembers: {
    member: {
      id: string;
      person: {
        email: string;
        externalImageUrl: string | null;
        familyName: string | null;
        givenName: string | null;
      };
    };
    required: boolean;
    owner: boolean;
    schedulingPriority: SchedulingPriorityEnum | null;
    roundRobinEnabled: boolean;
  }[];
  attendeeQuestions: AttendeeQuestion[];
  bookableHours: BookableHoursEnum;
  customMeetingHours: MeetingHours | null;
  bufferHours: number;
  availabilityRestriction: AvailabilityRestrictionEnum;
  active: boolean;
  allowQuarterHourBooking: boolean;
  allowBookingOnNoMeetingDay: boolean;
  highlightBestTimes: boolean;
  singleUse: boolean;
  allowAdditionalAttendees: boolean;
  titleTemplate: string;
  textOnlyTitle: boolean;
  maxBookings: {
    active: boolean;
    count: number;
    period: BookingLimitPeriodEnum;
  };
  groupSchedulingStrategy: GroupSchedulingStrategyEnum | null;
}

export const DEFAULT_MAX_BOOKINGS = {
  active: false,
  count: 5,
  period: BookingLimitPeriodEnum.Daily,
} as const;

export const getDefaulAttendeeQuestion = (): AttendeeQuestion => ({
  type: AttendeeQuestionTypeEnum.ShortText,
  required: true,
  question: "",
  // renderId is needed for Reorder.Group to work. Fails without each having a unique id.
  renderId: nanoid(),
});

// Build a new SchedulingLinkMeetingDetails object as a base for creating a new scheduling link
export const getDefaultLinkSettings = ({
  canUseZoom,
  memberId,
  email,
  givenName,
  familyName,
  externalImageUrl,
  singleUse,
  titleTemplate,
  roundRobinEnabled,
}: {
  canUseZoom: boolean;
  memberId: string;
  email: string;
  givenName: string | null;
  familyName: string | null;
  externalImageUrl: string | null;
  singleUse: boolean;
  titleTemplate: string;
  roundRobinEnabled: boolean;
}): SchedulingLinkMeetingDetails => ({
  name: "",
  slug: "",
  description: "",
  bookableHours: BookableHoursEnum.MeetingHours,
  customMeetingHours: null,
  availabilityRestriction: AvailabilityRestrictionEnum.BalanceAvailabilityAndFocus,
  active: true,
  allowBookingOnNoMeetingDay: false,
  highlightBestTimes: true,
  allowQuarterHourBooking: true,
  bufferHours: 4,
  singleUse,
  location: canUseZoom ? LocationTypeEnum.Zoom : LocationTypeEnum.Meet,
  durations: [30],
  defaultDuration: 30,
  availabilityRange: {
    windowSize: DEFAULT_WINDOW_SIZE_IN_DAYS,
    startDate: null,
    endDate: null,
  },
  linkMembers: [
    {
      member: {
        id: memberId,
        person: {
          email,
          givenName,
          familyName,
          externalImageUrl,
        },
      },
      required: true,
      owner: true,
      schedulingPriority: null,
      roundRobinEnabled,
    },
  ],
  attendeeQuestions: [],
  allowAdditionalAttendees: true,
  titleTemplate,
  textOnlyTitle: false,
  maxBookings: DEFAULT_MAX_BOOKINGS,
  groupSchedulingStrategy: null,
});
