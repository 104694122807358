import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ConfirmRescheduleProposalMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  appliesTo: Types.ProposalAppliesTo;
}>;


export type ConfirmRescheduleProposalMutation = { __typename: 'Mutation', confirmProposal: { __typename: 'Proposal', id: string, state: Types.ProposalState } | null };


export const ConfirmRescheduleProposalDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ConfirmRescheduleProposal"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"appliesTo"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProposalAppliesTo"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"confirmProposal"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"appliesTo"},"value":{"kind":"Variable","name":{"kind":"Name","value":"appliesTo"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]} as unknown as DocumentNode<ConfirmRescheduleProposalMutation, ConfirmRescheduleProposalMutationVariables>;