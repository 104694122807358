import {
  ApolloCache,
  ApolloError,
  InternalRefetchQueriesInclude,
  useMutation,
} from "@apollo/client";
import { useCallback } from "react";
import {
  ConfirmChatProposalDocument,
  ConfirmChatProposalMutation,
} from "../apollo/__generated__/ConfirmChatProposal.generated";
import { tempConfirmProposalMessage } from "../utils/getTempAssistantMessage";
import { useAIMessageContext } from "./AIMessageContext";

type UseConfirmChatProposalResult = [
  () => Promise<void>,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

type UseConfirmChatProposalProps = {
  clearHistory?: boolean;
  onCompleted?: (data: ConfirmChatProposalMutation) => void;
  onError?: (error: ApolloError) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
  update?: (cache: ApolloCache<any>, { data }: { data: ConfirmChatProposalMutation }) => void;
};

export const useConfirmChatProposal = ({
  clearHistory = false,
  onCompleted,
  onError,
  refetchQueries,
  update,
}: UseConfirmChatProposalProps): UseConfirmChatProposalResult => {
  const { chatHistoryId, messages, shouldExcludeAi } = useAIMessageContext();
  const [confirmChatProposal, { loading, error }] = useMutation(ConfirmChatProposalDocument);
  const onConfirmChatProposal = useCallback(async () => {
    const newMessage = tempConfirmProposalMessage;
    const optimisticResponse: ConfirmChatProposalMutation | undefined = chatHistoryId
      ? {
          __typename: "Mutation",
          confirmChatProposal: {
            __typename: "ConfirmChatProposalPayload",
            updatedHistory: {
              __typename: "ChatHistory",
              id: chatHistoryId,
              shouldExcludeAi,
              isProcessing: false,
              messages: [...messages, newMessage],
            },
            updatedEvents: [],
          },
        }
      : undefined;
    await confirmChatProposal({
      onCompleted,
      onError,
      update,
      refetchQueries,
      optimisticResponse,
      variables: {
        clearHistory,
      },
    });
  }, [
    chatHistoryId,
    clearHistory,
    confirmChatProposal,
    messages,
    onCompleted,
    onError,
    refetchQueries,
    shouldExcludeAi,
    update,
  ]);

  return [onConfirmChatProposal, { loading, error }];
};
