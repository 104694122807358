import {
  EventContainer,
  NamedEvent,
  UnnamedEvent,
} from "@clockwise/web-commons/src/components/feature-illustration/";
import React from "react";

export const TravelTimeEvent = () => (
  <NamedEvent duration={30} startTime="2:30pm" title="Travel Time" />
);

const TravelTimeIllustration = () => {
  return (
    <EventContainer>
      <UnnamedEvent duration={15} />
      <TravelTimeEvent />
      <UnnamedEvent duration={15} />
    </EventContainer>
  );
};

export default TravelTimeIllustration;
