import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MeetingReliefGoalSettingsFragment = { __typename: 'MeetingReliefGoals', id: string, enabled: boolean | null, meetingReliefThreshold: { __typename: 'MeetingReliefThreshold', fatigueThreshold: any, breakDuration: any } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null };

export type MeetingReliefGoalsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeetingReliefGoalsQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', id: string, user: { __typename: 'User', id: string, orgs: { __typename: 'OrgConnection', edges: Array<{ __typename: 'OrgEdge', node: { __typename: 'Org', id: string, smartHoldGoals: { __typename: 'SmartHoldGoals', id: string, meetingRelief: { __typename: 'MeetingReliefGoals', id: string, enabled: boolean | null, meetingReliefThreshold: { __typename: 'MeetingReliefThreshold', fatigueThreshold: any, breakDuration: any } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null } } } | null } | null> | null } } | null } };

export const MeetingReliefGoalSettingsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MeetingReliefGoalSettings"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MeetingReliefGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"meetingReliefThreshold"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fatigueThreshold"}},{"kind":"Field","name":{"kind":"Name","value":"breakDuration"}}]}},{"kind":"Field","name":{"kind":"Name","value":"remoteHoldSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"scheduleAs"}},{"kind":"Field","name":{"kind":"Name","value":"notificationStrategy"}}]}}]}}]} as unknown as DocumentNode<MeetingReliefGoalSettingsFragment, unknown>;
export const MeetingReliefGoalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MeetingReliefGoals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"orgs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"smartHoldGoals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"meetingRelief"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MeetingReliefGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MeetingReliefGoalSettings"}}]}}]}}]}}]}}]}}]}}]}}]}}]}},...MeetingReliefGoalSettingsFragmentDoc.definitions]} as unknown as DocumentNode<MeetingReliefGoalsQuery, MeetingReliefGoalsQueryVariables>;