import { Select, SelectOption } from "@clockwise/design-system";
import { WorkOutline } from "@clockwise/design-system/icons";
import { BookableHoursEnum } from "@clockwise/schema/v2";
import React from "react";
import { CustomMeetingHours, MeetingHours } from "./CustomMeetingHours";

interface Props {
  bookableHours: BookableHoursEnum;
  customMeetingHours?: MeetingHours | null;
  groupLink?: boolean;
  onChange: (
    bookableHours: BookableHoursEnum,
    customHours: MeetingHours | null,
    valid: boolean,
  ) => void;
  customMeetingHoursError?: string;
}

export const BookableHoursRow = ({
  bookableHours,
  customMeetingHours = null,
  onChange,
  customMeetingHoursError = "",
  groupLink = false,
}: Props) => {
  const handleChangeBookableHours = (newBookableHours: BookableHoursEnum) => {
    onChange(newBookableHours, customMeetingHours, !customMeetingHoursError);
  };

  return (
    <div className="cw-body-base cw-space-y-1">
      <div className="cw-flex cw-whitespace-nowrap cw-gap-3 cw-items-center cw-justify-start">
        <WorkOutline className="cw-w-5 cw-h-5 cw-text-muted cw-mr-1" />
        <span id="during-label">During your</span>
        <div>
          <Select
            cw-id="bookable-hours-type-dropdown"
            value={bookableHours}
            onChange={handleChangeBookableHours}
          >
            <SelectOption value={BookableHoursEnum.MeetingHours}>Meeting hours</SelectOption>
            {!groupLink && (
              <SelectOption value={BookableHoursEnum.WorkingHours}>Working hours</SelectOption>
            )}
            <SelectOption value={BookableHoursEnum.CustomHours}>Custom hours</SelectOption>
          </Select>
        </div>
      </div>
      {bookableHours === BookableHoursEnum.CustomHours && !!customMeetingHours && (
        <div className="cw-ml-9">
          <CustomMeetingHours
            meetingHours={customMeetingHours}
            error={!!customMeetingHoursError}
            errorMessage={customMeetingHoursError}
            onChange={(updatedMeetingHours, valid) => {
              onChange(bookableHours, updatedMeetingHours, valid);
            }}
          />
        </div>
      )}
    </div>
  );
};
