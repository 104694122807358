import { Link } from "@clockwise/design-system";
import { TradeoffTypeEnum } from "@clockwise/schema";
import React from "react";
import ReactMarkdown, { Components } from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkDirective from "remark-directive";
import remarkGfm from "remark-gfm";
import { SendChatMessageButton } from "../SendChatMessageButton";
import { ColoredTextWrapper } from "./ColoredTextWrapper";
import {
  customColorPlugin,
  customEventLinkPlugin,
  customInviteModalPlugin,
  customItalicPlugin,
  customPillPlugin,
  customPromptPlugin,
  customSubTextPlugin,
} from "./CustomPlugins";
import { EventLink } from "./EventLink";
import { InviteModalWrapper } from "./InviteModalWrapper";
import { PromptButton } from "./PromptButton";
import { StickerWrapper } from "./StickerWrapper";

// The package doesn't expose it at the top level and we don't need the type precision
// thus we type it as `any` for now
type ReactMarkdownNode = any;

// TODO: Combine with ClockwiseMarkdown after cleaup
//https://linear.app/getclockwise/issue/AI-1622/combine-aimarkdown-with-clockwisemarkdown

const convertPillIdToTradeoffType = (pillId?: string) => {
  switch (pillId) {
    case TradeoffTypeEnum.AVAILABILITY_ISSUE:
      return TradeoffTypeEnum.AVAILABILITY_ISSUE;
    case TradeoffTypeEnum.FIXABLE_CONFLICT:
      return TradeoffTypeEnum.FIXABLE_CONFLICT;
    default:
      return undefined;
  }
};

export const AIMarkdown = ({ text }: { text: string }) => {
  return (
    <ReactMarkdown
      linkTarget="_blank"
      remarkPlugins={[
        remarkBreaks,
        remarkGfm,
        // `remarkDirective` must go before the following custom plugins to work
        remarkDirective,
        customEventLinkPlugin,
        customPillPlugin,
        customColorPlugin,
        customPromptPlugin,
        customSubTextPlugin,
        customInviteModalPlugin,
        customItalicPlugin,
      ]}
      components={
        ({
          // fixes (validateDOMNesting(...): <div> cannot appear as a descendant of <p>.)
          p: ({ node, ...props }: { node: ReactMarkdownNode }) => {
            return <span {...props} />;
          },
          // the "bold" from the BE is techincally the md italic syntax
          // so overriding that here
          em: ({ node, ...props }: { node: ReactMarkdownNode }) => {
            return <strong className="cw-font-semibold" {...props} />;
          },
          eventlink: ({
            node,
            ...props
          }: { node: ReactMarkdownNode } & React.DetailedHTMLProps<
            React.LinkHTMLAttributes<HTMLLinkElement>,
            HTMLLinkElement
          > & { chlidren?: string[] }) => {
            const title: string = (((props?.children as unknown[]) || [])[0] as unknown) as string;
            const id = props?.id || "";

            return <EventLink {...props} title={title} eventId={id} />; // could be span or div depending on how many ":" we include
          },
          pill: ({
            node,
            ...props
          }: { node: ReactMarkdownNode } & React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
          > & { chlidren?: string[] }) => {
            const title: string = (((props?.children as unknown[]) || [])[0] as unknown) as string;
            const id = convertPillIdToTradeoffType(props?.id);

            return <StickerWrapper {...props} text={title} variant={id} />; // could be span or div depending on how many ":" we include
          },
          color: ({
            node,
            ...props
          }: { node: ReactMarkdownNode } & React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLSpanElement>,
            HTMLSpanElement
          > & { chlidren?: string[] }) => {
            const title: string = (((props?.children as unknown[]) || [])[0] as unknown) as string;
            const classes = props.className?.replaceAll(" ", ".") ?? undefined;

            return <ColoredTextWrapper {...props} text={title} calendarId={classes} />; // could be span or div depending on how many ":" we include
          },
          prompt: ({
            node,
            ...props
          }: { node: ReactMarkdownNode } & React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLButtonElement>,
            HTMLButtonElement
          > & { chlidren?: string[] }) => {
            const text: string = (((props?.children as unknown[]) || [])[0] as unknown) as string;
            const id = props?.id;
            const shouldExecute = !!id && id === "EXECUTE";
            return shouldExecute ? (
              <SendChatMessageButton text={text} />
            ) : (
              <PromptButton text={text} />
            );
          },
          a: ({
            node,
            ...props
          }: { node: ReactMarkdownNode } & React.DetailedHTMLProps<
            React.AnchorHTMLAttributes<HTMLAnchorElement>,
            HTMLAnchorElement
          > & { chlidren?: string[] }) => {
            return <Link {...props} />;
          },
          ul: ({ node, ...props }: { node: ReactMarkdownNode }) => {
            return <ul className="cw-pl-6" {...props} />;
          },
          li: ({ node, ...props }: { node: ReactMarkdownNode }) => {
            return <li className="cw-list-disc" {...props} />;
          },
          subText: ({
            node,
            ...props
          }: { node: ReactMarkdownNode } & React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
          > & { chlidren?: string[] }) => {
            const title: string = (((props?.children as unknown[]) || [])[0] as unknown) as string;
            return <div className="cw-caption cw-text-muted"> {title}</div>; // could be span or div depending on how many ":" we include
          },
          inviteModal: ({
            node,
            ...props
          }: { node: ReactMarkdownNode } & React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLSpanElement>,
            HTMLSpanElement
          > & { chlidren?: string[] }) => {
            const text: string = (((props?.children as unknown[]) || [])[0] as unknown) as string;

            return <InviteModalWrapper text={text} />; // could be span or div depending on how many ":" we include
          },
          italic: ({
            node,
            ...props
          }: { node: ReactMarkdownNode } & React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLElement>,
            HTMLElement
          > & { chlidren?: string[] }) => {
            const text: string = (((props?.children as unknown[]) || [])[0] as unknown) as string;
            return (
              <em className="cw-italic" {...props}>
                {text}
              </em>
            ); // could be span or div depending on how many ":" we include
          },
        } as unknown) as Components

        // We need to recast this type because want to accept custom mappers
        // that aren't just HTML tags, e.g. `phosphorIcon`
      }
    >
      {text}
    </ReactMarkdown>
  );
};
