import { AccessTime } from "@clockwise/design-system/icons";
import { bg_brand_emphasis, bg_neutral_inset } from "@clockwise/design-system/tokens";
import React from "react";
import { useNUXChecklist } from "../../../hooks/useNUXChecklist";
import { SegmentedBar } from "../../segmented-bar";

export const ChecklistProgress = () => {
  const { totalSteps, completedSteps } = useNUXChecklist();
  const getSegments = (complete: number, total: number) => {
    return [
      { color: bg_brand_emphasis, value: complete },
      { color: bg_neutral_inset, value: total - complete },
    ];
  };

  const percentString = `${Math.ceil((completedSteps / totalSteps) * 100)}%`;
  return (
    <div className="cw-flex cw-flex-col cw-w-full cw-my-8">
      <div className="cw-flex cw-items-center cw-justify-between cw-pb-2">
        <div className="cw-heading-lg"> Optimize your calendar </div>
        <div className="cw-flex cw-items-center cw-self-end">
          <div className="cw-relative cw-w-[150px] cw-h-2">
            <SegmentedBar segments={getSegments(completedSteps, totalSteps)} animateIn={true} />
          </div>
          <div className="cw-body-base cw-text-muted cw-mx-2">{percentString}</div>
          <AccessTime className="cw-mx-1 cw-text-muted cw-h-6 cw-w-6 cw-pb-[2px]" />
          <div className="cw-body-base cw-text-muted"> 4 min</div>
        </div>
      </div>
      <div className="cw-border-0 cw-border-b-2 cw-border-solid cw-border-default" />
    </div>
  );
};
