import {
  SelectOption,
  SelectOptionProps,
  SelectOptionsGroup,
} from "@clockwise/design-system/src/components/SelectOptions";
import { CwIdProps } from "@clockwise/design-system/src/types/cw-id";
import { Listbox, Portal, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, {
  ComponentProps,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from "react";
import { usePopper } from "react-popper";
import { MultiSelectTrigger, SelectedOption } from "./MultiSelectTrigger";

interface MultiselectProps<V> extends CwIdProps {
  label?: string;
  /**
    Placeholder to show when empty.
    Defaults to `label` value, if set.
  */
  placeholder?: string;
  value: V[];
  name?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  errorMessage?: string | null;
  onChange: (newValue: V[]) => void;
  /**
    Controls the number of selected options to display before rendering a "+x"
    overflow tag.
    Eventually, we might add an "auto" option but the design spec calls it out
    as a "nice to have". 
    Default: 1
  */
  truncate?: number | "none";
  /**
    Controls if the tags should be allowed to wrap or if the selected option
    container is restricted to a single line.
    Default: false
  */
  wrap?: boolean;
}

// Prettier 2.2 can't parse `typeof GenericType<T>` so we have to import SelectOptionProps
// type OptionElement<X extends string> = ReactElement<ComponentProps<typeof SelectOption<X>>>;
type OptionElement<X extends string> = ReactElement<SelectOptionProps<X>, typeof SelectOption>;
type GroupElement = ReactElement<ComponentProps<typeof SelectOptionsGroup>>;

const isSelectOption = <X extends string>(child: ReactNode): child is OptionElement<X> => {
  return (
    !!child &&
    React.isValidElement<{ value?: unknown }>(child) &&
    typeof child.type !== "string" &&
    child.type.name === SelectOption.name &&
    typeof child.props?.value === "string"
  );
};

const isSelectOptionsGroup = (child: ReactNode): child is GroupElement => {
  return (
    !!child &&
    React.isValidElement(child) &&
    typeof child.type !== "string" &&
    child.type.name === SelectOptionsGroup.name
  );
};

/**
 * Seriously, menus need to be above everything else.
 */
const ABOVE_EVERYTHING = 10000;

export function MultiSelect<V extends string>({
  value: selectedValues,
  placeholder,
  label,
  name,
  disabled,
  fullWidth,
  truncate = 1,
  error,
  errorMessage,
  onChange,
  "cw-id": cwId,
  children,
}: PropsWithChildren<MultiselectProps<V>>) {
  const wasOpen = useRef(false);
  const [trigger, setTrigger] = useState<HTMLElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update: updatePopperInstance } = usePopper(trigger, container, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [
      { name: "flip", enabled: true },
      { name: "offset", options: { offset: [0, 4] } },
    ],
  });

  const selectedOptions = useMemo((): SelectedOption<V>[] => {
    const result: SelectedOption<V>[] = [];
    const arrayOfChildren: OptionElement<V>[] = [];
    React.Children.forEach(children, (child) => {
      if (isSelectOption<V>(child)) {
        arrayOfChildren.push(child);
      } else if (isSelectOptionsGroup(child)) {
        React.Children.forEach(child.props.children, (nestedChild) => {
          if (isSelectOption<V>(nestedChild)) {
            arrayOfChildren.push(nestedChild);
          }
        });
      }
    });
    arrayOfChildren.forEach((child) => {
      const value = child.props.value;
      if (typeof value === "string" && selectedValues.includes(value)) {
        const SelectedIcon = child.props.icon;
        let label: ReactNode = child.props.children ?? child.props.value;
        if (SelectedIcon) {
          label = (
            <div className="cw-flex cw-items-center cw-gap-1.5">
              <SelectedIcon
                {...child.props.iconProps}
                className={classNames("cw-h-4 cw-w-4", child.props.iconProps?.className)}
              />
              {label}
            </div>
          );
        }

        result.push({
          value: child.props.value,
          label,
        });
      }
    });
    return result;
  }, [children, selectedValues]);

  const handleChange = (newValues: V[]) => {
    void updatePopperInstance?.();
    onChange(newValues);
  };

  const handleDeselect = (deselectedValues: V[]) => {
    handleChange(selectedValues.filter((v) => !deselectedValues.includes(v)));
  };
  const onOptionSelect = (value: V[] | (() => void)) => {
    if (typeof value === "function") {
      value();
    } else {
      handleChange(value);
    }
  };

  return (
    <Listbox
      multiple
      value={selectedValues}
      onChange={onOptionSelect}
      cw-id={cwId}
      name={name}
      disabled={disabled}
      as="div"
      className={classNames(
        { "cw-w-full": fullWidth, "cw-cursor-not-allowed": disabled },
        "cw-max-w-full cw-relative",
      )}
    >
      {({ open }) => {
        /**
         * When the menu first transitions open, we need to tell Popper to update to ensure that the
         * menu is positioned correctly, because it won't be able to know how big the menu is until
         * it is open, and therefore won't know whether it can't fit into the current viewport in
         * the standard orientation.
         */
        if (open && !wasOpen.current) {
          wasOpen.current = true;
          void updatePopperInstance?.();
        } else if (!open && wasOpen) {
          wasOpen.current = false;
        }
        return (
          <>
            <div className="cw-font-body cw-max-w-full cw-relative">
              <Listbox.Label className="cw-sr-only">{name}</Listbox.Label>
              <MultiSelectTrigger
                triggerRef={setTrigger}
                value={selectedOptions}
                placeholder={placeholder ?? label}
                onDelete={(v) => handleDeselect([v])}
                truncate={truncate}
                disabled={disabled}
                error={error}
                fullWidth={fullWidth}
              />
              <Portal>
                <div
                  ref={setContainer}
                  style={{
                    ...styles.popper,
                    minWidth: trigger?.scrollWidth,
                    zIndex: ABOVE_EVERYTHING,
                  }}
                  {...attributes.popper}
                >
                  <Transition
                    leave="cw-transition cw-ease-in cw-duration-100"
                    leaveFrom="cw-opacity-100"
                    leaveTo="cw-opacity-0"
                    enter="cw-transition cw-ease-in cw-duration-100"
                    enterFrom="cw-opacity-0"
                    enterTo="cw-opacity-100"
                  >
                    <Listbox.Options
                      className={classNames(
                        "cw-z-50 cw-min-w-full cw-max-h-72 cw-p-1 cw-overflow-auto",
                        "cw-font-body cw-font-normal cw-text-13 cw-bg-default cw-rounded-lg cw-shadow-selectPopup cw-min-w-[180px]",
                      )}
                    >
                      {children}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Portal>
            </div>
            {error && errorMessage && (
              <div className="cw-text-destructive cw-caption cw-mt-1 cw-max-w-full">
                {errorMessage}
              </div>
            )}
          </>
        );
      }}
    </Listbox>
  );
}
