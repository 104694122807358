import { constructionBlockSvg } from "#webapp/src/assets/img/illustrations";
import { supportEmail } from "#webapp/src/constants/site.constant";
import { Button, Link } from "@clockwise/design-system";
import * as React from "react";

export interface IProps {
  onConfirm: () => void;
}

export const OnboardingFatalError = ({ onConfirm }: IProps) => {
  return (
    <div className="cw-flex cw-flex-col cw-items-center cw-justify-center cw-gap-6 cw-text-center">
      <img className="cw-w-[125px]" src={constructionBlockSvg} />
      <div>
        <div className="cw-heading-2xl cw-mb-2">Something went wrong, but we're working on it!</div>
        <div className="cw-body-lg">
          Sorry for the inconvenience! We're working on getting your Clockwise account back up and
          running, and will let you know when the problem is resolved. Meanwhile, please always feel
          free to <Link href={`mailto:${supportEmail}`}>message us</Link>.
        </div>
      </div>
      <Button size="large" sentiment="positive" onClick={onConfirm}>
        Got it
      </Button>
    </div>
  );
};
