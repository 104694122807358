import React from "react";

import { DisambiguationContent } from "../../../../event-card/molecules/ECDisambiguation";
import { EventCardAttendee } from "../../../../event-card/types";
import { useSetAIError } from "../../hooks/AIErrorContext";
import { useAIMessageContext } from "../../hooks/AIMessageContext";
import { useDisambiguateProposalAttendee } from "../../hooks/useDisambiguateProposalAttendee";
import { GQLProposalAmbiguity, GQLProposalDisambiguations } from "../../utils/types";

const getEventCardAttendeeListFromPersonList = (disambigations: GQLProposalDisambiguations) => {
  return disambigations.list.map((person) => {
    const eventCardAttendee: EventCardAttendee = {
      ...person,
    };
    return eventCardAttendee;
  });
};

const Ambiguity = ({ ambiguity, diffId }: { ambiguity: GQLProposalAmbiguity; diffId: string }) => {
  const setError = useSetAIError();
  const { processingMessage } = useAIMessageContext();

  const [onDisambiguateProposalAttendee, { loading }] = useDisambiguateProposalAttendee({
    onError: (error) => {
      setError({
        error,
        message: "Error disambiguating attendee",
        userMessage: "Failed to add attendee to event",
        showUserMessage: true,
      });
    },
  });

  const onClickPersonOption = (person: EventCardAttendee) => {
    void onDisambiguateProposalAttendee({
      input: {
        diffId,
        attendeeString: ambiguity.attendeeRequested,
        calendarId: person.primaryCalendar,
      },
    });
  };
  if (!ambiguity.disambiguations || ambiguity.disambiguations.__typename !== "PersonList") {
    return null;
  }

  return (
    <div key={`ambiguity-${ambiguity.attendeeRequested}`}>
      <DisambiguationContent
        ambiguousText={ambiguity.attendeeRequested}
        listOfPossibleAttendees={getEventCardAttendeeListFromPersonList(ambiguity.disambiguations)}
        onConfirmPerson={onClickPersonOption}
        disabled={loading || processingMessage}
        isGroup={ambiguity.isGroup}
      />
    </div>
  );
};

export const Ambiguities = ({
  ambiguities,
  diffId,
}: {
  ambiguities: GQLProposalAmbiguity[];
  diffId: string;
}) => {
  return (
    <>
      {ambiguities.map((ambiguity) => {
        return (
          <Ambiguity key={ambiguity.attendeeRequested} ambiguity={ambiguity} diffId={diffId} />
        );
      })}
    </>
  );
};
