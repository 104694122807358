import React from "react";

export const Slack = ({ className }: { className?: string }) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" className={className}>
    <path
      d="M7.73852 22.8196C7.73852 24.9039 6.13515 26.5073 4.05078 26.5073C1.9664 26.5073 0.363037 24.9039 0.363037 22.8196C0.363037 20.7352 1.9664 19.1318 4.05078 19.1318H7.73852V22.8196Z"
      fill="#00A972"
    />
    <path
      d="M9.6626 22.8196C9.6626 20.7352 11.266 19.1318 13.3503 19.1318C15.4347 19.1318 17.0381 20.7352 17.0381 22.8196V32.1191C17.0381 34.2035 15.4347 35.8068 13.3503 35.8068C11.266 35.8068 9.6626 34.2035 9.6626 32.1191V22.8196Z"
      fill="#00A972"
    />
    <path
      d="M13.3503 8.06884C11.266 8.06884 9.6626 6.46547 9.6626 4.3811C9.6626 2.29672 11.266 0.693359 13.3503 0.693359C15.4347 0.693359 17.0381 2.29672 17.0381 4.3811V8.06884H13.3503Z"
      fill="#00A972"
    />
    <path
      d="M13.3503 9.83301C15.4347 9.83301 17.038 11.4364 17.038 13.5207C17.038 15.6051 15.4347 17.2085 13.3503 17.2085H4.05078C1.9664 17.2085 0.363037 15.6051 0.363037 13.5207C0.363037 11.4364 1.9664 9.83301 4.05078 9.83301H13.3503Z"
      fill="#00A972"
    />
    <path
      d="M28.2617 13.6809C28.2617 11.5965 29.8651 9.99316 31.9495 9.99316C34.0338 9.99316 35.6372 11.5965 35.6372 13.6809C35.6372 15.7653 34.0338 17.3686 31.9495 17.3686H28.2617V13.6809Z"
      fill="#00A972"
    />
    <path
      d="M26.3376 13.6806C26.3376 15.765 24.7343 17.3684 22.6499 17.3684C20.5655 17.3684 18.9622 15.765 18.9622 13.6806V4.3811C18.9622 2.29672 20.5655 0.693359 22.6499 0.693359C24.7343 0.693359 26.3376 2.29672 26.3376 4.3811V13.6806Z"
      fill="#00A972"
    />
    <path
      d="M22.6499 28.4316C24.7343 28.4316 26.3376 30.035 26.3376 32.1194C26.3376 34.2038 24.7343 35.8071 22.6499 35.8071C20.5655 35.8071 18.9622 34.2038 18.9622 32.1194V28.4316H22.6499Z"
      fill="#00A972"
    />
    <path
      d="M22.6499 26.6675C20.5655 26.6675 18.9622 25.0641 18.9622 22.9797C18.9622 20.8954 20.5655 19.292 22.6499 19.292H31.9494C34.0338 19.292 35.6372 20.8954 35.6372 22.9797C35.6372 25.0641 34.0338 26.6675 31.9494 26.6675H22.6499Z"
      fill="#00A972"
    />
  </svg>
);
