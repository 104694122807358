import { ApolloError } from "@apollo/client";
import { EditEventAppliesTo } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { CalendarEventsDocument } from "../../web-app-calendar/apollo/__generated__/CalendarEvents.v2.generated";
import {
  MoveEventTimeDocument,
  MoveEventTimeMutation,
} from "../../web-app-calendar/apollo/__generated__/MoveEventTime.v2.generated";

export const useMoveEventTime = (
  callback: () => void,
  errorCallback?: (error: ApolloError) => void,
) => {
  const [moveEventTimeGateway, { loading }] = useGatewayMutation(MoveEventTimeDocument);

  const moveEventTime = ({
    externalEventId,
    timeRange,
    optimisticResponse,
    appliesTo,
  }: {
    externalEventId: string;
    timeRange: string;
    optimisticResponse?: MoveEventTimeMutation;
    appliesTo?: EditEventAppliesTo;
  }) => {
    const timeZone = getRenderTimeZone();
    return moveEventTimeGateway({
      variables: { externalEventId, timeRange, timeZone, appliesTo },
      onCompleted: () => {
        callback();
      },
      onError: (error) => {
        errorCallback?.(error);
      },
      optimisticResponse,
      refetchQueries: [CalendarEventsDocument],
      awaitRefetchQueries: true,
    });
  };

  return { moveEventTime, loading };
};
