//////////////////
// IMPORTS
//////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";
// libraries
import * as React from "react";
import { connect } from "react-redux";
import { createFragmentContainer } from "react-relay";
// chrome-slack-verifier imports
import { chromeSlackVerifierFragments } from "./ChromeSlackVerifier.gql";
import * as s from "./ChromeSlackVerifier.styles";
import { IContainer, IProps, IState } from "./ChromeSlackVerifierTypes";
// state
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
// util
import { paths } from "#webapp/src/constants/site.constant";
import { addSlackAccountForTeam, addSlackAccountV2 } from "#webapp/src/mutations";
import { slackNonce, slackNonceForTeam } from "#webapp/src/state/local-storage";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { getServerUrl } from "@clockwise/web-commons/src/util/post-message-common.util";
import toast from "react-hot-toast";

//////////////////
// COMPONENT
//////////////////
class ChromeSlackVerifierCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      error: "",
    };
  }
  private onRelayFailure = (_error: string) => {
    this.setState({ error: "Something went wrong with the verification of your Slack account." });
  };

  private onRelaySuccessV2 = (_payload: ISchema.IAddSlackAccountV2MutationPayload) => {
    window.close();
    if (!window.closed) {
      toast.success("Successfully connected to Clockwise!");
      setTimeout(() => {
        window.location.replace(`${getServerUrl()}${appPaths.appSettings}`);
      }, 1000);
    }
  };

  private onRelaySuccessTeam = (_payload: ISchema.IAddSlackAccountForTeamMutationPayload) => {
    window.close();
    if (!window.closed) {
      toast.success("Successfully connected to Clockwise!");
      setTimeout(() => {
        window.location.replace(`${getServerUrl()}${appPaths.appSettings}`);
      }, 1000);
    }
  };

  private isSlackNonceValid = (slackNonce: string | null) => {
    const searchParams = new URLSearchParams(window.location.search);
    const state = searchParams.get("state") as string;
    if (state && slackNonce) {
      return slackNonce === state;
    } else {
      return true;
    }
  };

  public UNSAFE_componentWillMount() {
    const org = getCurrentOrg(this.props.viewer);
    if (!org) {
      this.setState({ error: "There was a problem loading your data." });
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("error")) {
      this.setState({ error: "Slack error: " + searchParams.get("error") });
      return;
    }

    if (searchParams.has("code") && searchParams.has("state")) {
      const isForTeam = window.location.pathname.includes(`${paths.chromeSlackVerifier}/team/`);
      const isStateAvailableAndInvalidTeam = this.isSlackNonceValid(slackNonceForTeam.get());
      if (isForTeam && !isStateAvailableAndInvalidTeam) {
        this.setState({
          error:
            "Something went wrong with the verification of your Slack account, state parameter is incorrect.",
        });
        return;
      }
      const isStateAvailableAndInvalid = this.isSlackNonceValid(slackNonce.get());
      if (!isForTeam && !isStateAvailableAndInvalid) {
        this.setState({
          error:
            "Something went wrong with the verification of your Slack account, state parameter is incorrect.",
        });
        return;
      }

      const verificationCode = searchParams.get("code") as string;
      const orgRelayId = org.id;

      if (isForTeam) {
        const teamIdEncoded = window.location.pathname.split(
          `${paths.chromeSlackVerifier}/team/`,
        )[1];
        const teamId = atob(teamIdEncoded);
        const redirect = `${getServerUrl()}${paths.chromeSlackVerifier}/team/${teamIdEncoded}`;
        addSlackAccountForTeam(
          this.props.relay.environment,
          { orgRelayId, verificationCode, redirect, teamId },
          this.onRelaySuccessTeam,
          this.onRelayFailure,
        );
      } else {
        const redirect = `${getServerUrl()}${paths.chromeSlackVerifier}`;
        addSlackAccountV2(
          this.props.relay.environment,
          { orgRelayId, verificationCode, redirect },
          this.onRelaySuccessV2,
          this.onRelayFailure,
        );
      }
    } else {
      this.setState({
        error:
          "Something went wrong with the verification of your Slack account, missing a required field.",
      });
    }
  }

  public render() {
    if (this.state.error) {
      return (
        <div style={s.container}>
          There was a problem connecting your Slack. &nbsp;
          {this.state.error}
        </div>
      );
    }

    return <div style={s.container}>verifying...</div>;
  }
}

//////////////////
// REDUX
//////////////////
function mapStateToProps(_state: IReduxState, _ownProps: IContainer) {
  return {};
}

const ChromeSlackVerifierRedux = connect(mapStateToProps, {})(ChromeSlackVerifierCmpt);

//////////////////
// RELAY
//////////////////
export const ChromeSlackVerifier = createFragmentContainer<IContainer>(
  ChromeSlackVerifierRedux,
  chromeSlackVerifierFragments,
);
