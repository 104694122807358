export const DefaultWeights = {
  focusTime: 1.0,
  meetingConflict: 1.0,
  outsideWorkingHours: 1.0,
  preferredWorkingHours: 1.0,
  travelTime: 1.0,
  eventChangeDisruption: 1.0,
  meetingRelief: 1.0,
  offGridPenalty: 1.0,
  noMeetingDay: 1.0,
  commonlyDislikedTimes: 1.0,
  outsideMeetingHours: 1.0,
  lunchConflict: 1.0,
};

export type PreferencesWeights = {
  [key in keyof typeof DefaultWeights]: number;
};
