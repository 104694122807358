import { Link } from "@clockwise/design-system";
import { SlackLogo } from "@clockwise/web-commons/src/components/svgs/SlackLogo";
import { ZoomLogo } from "@clockwise/web-commons/src/components/svgs/ZoomLogo";
import React from "react";
import { ScrollIntoView } from "../ui-focus-wrappers/ScrollIntoView";
import BrowserIntegration from "./BrowserIntegration";
import SlackIntegration from "./SlackIntegration";
import ZoomIntegration from "./ZoomIntegration";

const AppSettings = () => {
  return (
    <div className="cw-flex cw-flex-col cw-space-y-10">
      <div>
        <section>
          <BrowserIntegration />
        </section>
      </div>
      <div>
        <ScrollIntoView focusSection="slack" highlight>
          <section>
            <h2 className="cw-heading-2xl">
              <SlackLogo size={22} className="cw-mr-2" />
              <span>Slack</span>
            </h2>
            <p className="cw-pb-2">
              Clockwise app for Slack helps you effortlessly communicate whereabouts, avoid
              disruptions, and prepare for work days. It can automatically update your Slack status,
              turn on Do Not Disturb when you're in meetings and/or Focus Time, updates you with
              Daily Forecast and any flexible meeting moves.{" "}
              <Link
                href="https://getclockwise.helpscoutdocs.com/article/160-slack-integration"
                rel="noreferrer"
                target="_blank"
              >
                Learn more
              </Link>
              .
            </p>
            <SlackIntegration />
          </section>
        </ScrollIntoView>
      </div>
      <div>
        <ScrollIntoView focusSection="zoom" highlight>
          <section>
            <h2 className="cw-heading-2xl">
              <ZoomLogo className="cw-mr-2" />
              <span>Zoom</span>
            </h2>
            <p className="cw-pb-2">
              Connect your Zoom account to automatically add Zoom video conferencing to meetings you
              schedule with Clockwise.{" "}
              <Link
                href="https://getclockwise.helpscoutdocs.com/article/162-zoom-integration"
                rel="noreferrer"
                target="_blank"
              >
                Learn more
              </Link>
              .
            </p>
            <ZoomIntegration />
          </section>
        </ScrollIntoView>
      </div>
    </div>
  );
};

export default AppSettings;
