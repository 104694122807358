//////////////////
// IMPORTS
//////////////////
// libraries
import * as React from "react";
// login-wrapper imports
import { LoginDefault } from "./login-default";
import { LoginInvite } from "./login-invite";
import { LoginWelcome } from "./login-welcome";

// state
import { setLoginTrackingEvents } from "#webapp/src/util/loginTrackingEvents.util";
import { LoaderClock } from "@clockwise/web-commons/src/components/loader-clock";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IReduxState } from "../../state/reducers/root.reducer";
import { LoginWrapperQueryDocument } from "./__generated__/LoginWrapper.v2.generated";

export interface IProps {
  variant: "default" | "invite" | "welcome" | "m365";
}

export const LoginWrapper = (props: IProps) => {
  const isWebExtension = useSelector((state: IReduxState) => state.webExtension.isWebExtension);
  const params = useParams();

  const { data, loading } = useGatewayQuery(LoginWrapperQueryDocument);
  const user = data?.currentUser;

  const onSuccessTracking = () => {
    setLoginTrackingEvents();
  };

  const getRedirect = () => {
    if (params && params.redirect) {
      let redirect = params.redirect;
      if (window.location && window.location.search) {
        redirect += window.location.search;
      }
      return redirect;
    }
    return undefined;
  };

  const redirect = getRedirect();

  if (loading) {
    return (
      <div className="cw-w-full cw-h-screen cw-flex cw-items-center cw-justify-center">
        <LoaderClock />
      </div>
    );
  }

  switch (props.variant) {
    case "invite":
      return (
        <LoginInvite
          isWebExtension={isWebExtension}
          isUserPresent={!!user}
          redirect={redirect}
          onSuccess={onSuccessTracking}
        />
      );
    case "welcome":
      return (
        <LoginWelcome userId={user?.id || null} redirect={redirect} onSuccess={onSuccessTracking} />
      );
    case "m365":
      return (
        <LoginInvite
          isWebExtension={isWebExtension}
          isUserPresent={!!user}
          redirect={redirect}
          onSuccess={onSuccessTracking}
          isM365Only={true}
        />
      );
    case "default":
    default:
      return (
        <LoginDefault userId={user?.id || null} redirect={redirect} onSuccess={onSuccessTracking} />
      );
  }
};
