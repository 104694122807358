import { MeetingReliefGoals, NotificationStrategy, ScheduleAs } from "@clockwise/schema";

import { Duration } from "luxon";
import { MeetingReliefSettings } from "../types";

export const defaultSettings: MeetingReliefSettings = {
  id: "",
  enabled: false,
  fatigueThreshold: Duration.fromObject({ hours: 4 }),
  breakDuration: Duration.fromObject({ minutes: 15 }),
  notificationStrategy: NotificationStrategy.NotifyInGCal,
  scheduleAs: ScheduleAs.Busy,
};

export const transformMeetingReliefGoalsToSettings = (
  meetingReliefGoal: MeetingReliefGoals | undefined,
) => {
  const settings = { ...defaultSettings };

  if (meetingReliefGoal) {
    const { id, enabled, remoteHoldSettings, meetingReliefThreshold } = meetingReliefGoal;

    if (id) settings.id = id;
    settings.enabled = !!enabled;

    if (meetingReliefThreshold) {
      const { breakDuration, fatigueThreshold } = meetingReliefThreshold;

      if (breakDuration && typeof breakDuration === "string") {
        settings.breakDuration = Duration.fromISO(breakDuration);
      }
      if (fatigueThreshold && typeof fatigueThreshold === "string") {
        settings.fatigueThreshold = Duration.fromISO(fatigueThreshold);
      }
    }

    if (remoteHoldSettings) {
      const { notificationStrategy, scheduleAs } = remoteHoldSettings;
      if (notificationStrategy) settings.notificationStrategy = notificationStrategy;
      if (scheduleAs) settings.scheduleAs = scheduleAs;
    }
  }

  return settings;
};

export default transformMeetingReliefGoalsToSettings;
