import { DiffActionTypeEnum, ResponseStatusEnum } from "@clockwise/schema";
import {
  ArrowForwardRounded,
  CreateRounded,
  EventBusyRounded,
  LockRounded,
} from "@material-ui/icons";

export const getIconFromAction = ({
  action,
  rsvp = null,
}: {
  action: DiffActionTypeEnum;
  rsvp?: ResponseStatusEnum | null;
}) => {
  if (action === DiffActionTypeEnum.ADD) return null;
  switch (action) {
    case DiffActionTypeEnum.RSVP: {
      if (rsvp && rsvp === ResponseStatusEnum.Declined) {
        return EventBusyRounded;
      } else {
        return CreateRounded;
      }
    }
    case DiffActionTypeEnum.RESCHEDULE:
      return ArrowForwardRounded;
    case DiffActionTypeEnum.EDIT_DETAILS:
      return ArrowForwardRounded;
    case DiffActionTypeEnum.UNCHANGED:
      return LockRounded;
    default:
      return null;
  }
};
