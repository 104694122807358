import { getRelativeDate } from "@clockwise/web-commons/src/components/suggested-times-row/utils/date";
import {
  getRelativeTimeSpecFromInterval,
  RelativeTimeSpec,
} from "@clockwise/web-commons/src/util/date.util";
import { DateTime, Interval } from "luxon";
import { Option } from "./types";

export const getOptionFromRelativeTimeSpec = (
  spec: RelativeTimeSpec | null,
  anchorDate: DateTime,
): Option => {
  if (spec === null) {
    return {
      label: "Custom",
      value: "custom",
    };
  }

  if (spec.type === "fixed") {
    switch (spec.spec) {
      case "same-day":
        return {
          label: getRelativeDate(anchorDate),
          value: "same-day",
        };

      case "next-day":
        return {
          label: getRelativeDate(anchorDate.plus({ days: 1 })),
          value: "next-day",
        };
    }
  }

  if (spec.type === "relative" && spec.duration === "week") {
    const now = DateTime.now();
    const weekStart = anchorDate.startOf("week");
    const isCurrentWeek = weekStart.hasSame(now, "week");
    const isNextWeek = weekStart.equals(now.plus({ weeks: 1 }).startOf("week"));

    switch (spec.spec) {
      case "same":
        return {
          label: isCurrentWeek ? "This week" : `Week of ${weekStart.toFormat("MMM d")}`,
          value: "same",
          duration: "week",
        };

      case "next":
        return {
          label: isNextWeek
            ? "Next week"
            : `Week of ${weekStart.plus({ weeks: 1 }).toFormat("MMM d")}`,
          value: "next",
          duration: "week",
        };

      case "later":
        return {
          label: "Later this week",
          value: "later",
          duration: "week",
        };
    }
  }

  return {
    label: "Custom",
    value: "custom",
  };
};

const isInNumWeeksFromNow = (date: DateTime, numWeeks: number) => {
  return date
    .plus({ weeks: numWeeks })
    .startOf("week")
    .hasSame(DateTime.now().plus({ weeks: numWeeks }).startOf("week"), "week");
};

// TODO: This is a temporary function to get the search time range options.
// In the future, we should take into account the attendees working hours and timezones.
export const getSearchTimeRangeOptions = (anchorDate: DateTime): Option[] => [
  {
    label: getRelativeDate(anchorDate),
    value: "same-day",
  },
  {
    label: getRelativeDate(anchorDate.plus({ days: 1 })),
    value: "next-day",
  },
  {
    label: isInNumWeeksFromNow(anchorDate, 0)
      ? "This week"
      : `Week of ${anchorDate.startOf("week").toFormat("MMM d")}`,
    value: "same",
    duration: "week",
  },
  {
    label: isInNumWeeksFromNow(anchorDate, 1)
      ? "Next week"
      : `Week of ${anchorDate.plus({ weeks: 1 }).startOf("week").toFormat("MMM d")}`,
    value: "next",
    duration: "week",
  },
  {
    label: isInNumWeeksFromNow(anchorDate, 0)
      ? "Later this week"
      : `Later in the week of ${anchorDate.startOf("week").toFormat("MMM d")}`,
    value: "later",
    duration: "week",
  },
];

export const getOptionFromSearchTimeRanges = (
  searchTimeRanges: Interval[],
  anchorDate: DateTime,
): Option => {
  const relativeTimeSpec = getRelativeTimeSpecFromInterval(searchTimeRanges, anchorDate);
  return getOptionFromRelativeTimeSpec(relativeTimeSpec, anchorDate);
};

export const getRelativeTimeSpecFromOption = (option?: Option): RelativeTimeSpec | null => {
  if (!option) {
    return null;
  }

  if (option.value === "custom") {
    return null;
  }

  if ("duration" in option) {
    return {
      type: "relative",
      spec: option.value,
      duration: option.duration,
    };
  }

  return {
    type: "fixed",
    spec: option.value,
  };
};
