import { UpdateUserWorkingHoursInput } from "@clockwise/schema/v2";
import { DateTime } from "luxon";

// Defaults 9-5
export const defaultWorkingHoursStart = "09:00";
export const defaultWorkingHoursEnd = "17:00";

export const getDefaultWorkingHoursInput = (): UpdateUserWorkingHoursInput => {
  return {
    saveMeetingAndWorkingHours: true,
    timeZone: DateTime.local().zoneName,
    workingHours: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map((weekday) => {
      return {
        weekday,
        uninterruptedRange: {
          start: defaultWorkingHoursStart,
          end: defaultWorkingHoursEnd,
        },
      };
    }),
  };
};
