import { useOnboarding } from "#webapp/src/hooks/useOnboarding/useOnboarding";
import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
import { Overlay } from "@clockwise/design-system";
import { EcosystemEnum } from "@clockwise/schema";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { NuxStartingModal } from "@clockwise/web-commons/src/ui/nux-popover/NuxStartingModal";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import classNames from "classnames";
import React, { ReactNode, useRef } from "react";
import { Navigate, Outlet } from "react-router-dom";
import modalBackground from "../../components/app-nav/assets/modalBackground.png";
import { AppNav } from "../app-nav/AppNav";
import { useUserProfile } from "../hooks/useUserProfile";
import { useNuxPopoverStates } from "../superpowers-checklist/useNuxPopoverStates";
import { NavEducationStep } from "../superpowers-checklist/useNuxPopoverStates.util";

export const AppLayout = () => {
  return (
    <AppLayoutCommon useRelativePaths>
      <Outlet />
    </AppLayoutCommon>
  );
};

// Tempory duplicate for AppLayout to support found
export const TEMP_FOUND_AppLayout = ({ children }: { children: ReactNode }) => {
  return <AppLayoutCommon useRelativePaths={false}>{children}</AppLayoutCommon>;
};

export const AppLayoutCommon = ({
  children,
  useRelativePaths = true,
}: {
  children: ReactNode;
  useRelativePaths?: boolean;
}) => {
  const mainEl = useRef(null);
  const { shouldRenderOnboarding } = useOnboarding();

  if (shouldRenderOnboarding) {
    return <Navigate to={appPaths.onboarding} />;
  }

  return (
    <>
      <div
        className={classNames(
          "cw-flex",
          "cw-h-screen cw-min-h-[640px] cw-overflow-hidden",
          "cw-relative",
        )}
      >
        <AppNav useRelativePaths={useRelativePaths} />
        <AppChrome>
          <main
            ref={mainEl}
            className={classNames("cw-grow cw-overflow-auto cw-h-full cw-bg-default")}
          >
            <div className="cw-w-full cw-h-full cw-flex cw-justify-center">{children}</div>
          </main>
        </AppChrome>
      </div>
    </>
  );
};

export const AppChrome = ({ children }: { children: ReactNode }) => {
  const ecosystem = useEcosystem();
  const { userProfile } = useUserProfile();
  const { showAppOverlay, navEducationCurrentState, setPopoverComplete } = useNuxPopoverStates();
  return (
    <div
      className={classNames(
        "cw-h-full cw-overflow-hidden cw-relative",
        "cw-p-2 cw-pl-[1px]",
        "cw-text-onEmphasis",
        "cw-flex cw-flex-col cw-grow",
        "cw-bg-brand-gradient",
      )}
    >
      {ecosystem === EcosystemEnum.Microsoft ? (
        <div className="cw-text-11 cw-text-onEmphasis cw-font-semibold cw-self-center cw-mb-2">
          Clockwise for Microsoft is in{" "}
          <span
            className="cw-underline cw-cursor-pointer"
            onClick={() => {
              window.open(articleUrls.m365, "_blank");
            }}
          >
            beta
          </span>
        </div>
      ) : (
        ""
      )}

      <div
        className={classNames(
          "cw-h-full cw-w-full",
          "cw-border cw-border-muted cw-border-solid",
          "cw-overflow-hidden",
          "cw-rounded-md",
          "cw-bg-default",
          "cw-text-default",
        )}
        style={{
          boxShadow: "0px 0px 0px 1px #B3CAC4",
        }}
      >
        {children}
        {showAppOverlay && <Overlay className="cw-my-[7px] cw-mr-[7px] cw-rounded-md" />}
        <NuxStartingModal
          givenName={userProfile?.givenName}
          isOpen={navEducationCurrentState === NavEducationStep.IntroModal}
          onClose={setPopoverComplete}
          srcImage={modalBackground}
        />
      </div>
    </div>
  );
};
