import * as React from "react";

import { ZoomAccountOrgSettings, ZoomSettings } from "./ZoomSettings";

import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { RefetchOrgAccount } from "../admin-organization-settings/OrganizationSettings";
import { OrgTimeZone } from "./OrgTimeZone";

type Persons = {
  email: string;
  displayName: string;
  externalImageUrl: string | null;
  familyName: string | null;
  givenName: string | null;
}[];

const MAX_RENDERED_AVATARS = 6;

export const OrgSettings = ({
  userId,
  persons,
  orgName,
  zoomAccount,
  refetchOrgAccount,
}: {
  userId: string;
  persons: Persons;
  orgName: string;
  zoomAccount: ZoomAccountOrgSettings | null;
  refetchOrgAccount: RefetchOrgAccount;
}) => {
  return (
    <div>
      <div className="cw-flex cw-items-center cw-flex-wrap cw-gap-10">
        <AttendeeAvatarStack overlap={false} size="xlarge" maxShown={MAX_RENDERED_AVATARS}>
          {persons.map((person) => (
            <AttendeeAvatar profile={person} size="xlarge" key={person.email} />
          ))}
        </AttendeeAvatarStack>
        <div className="cw-body-base cw-text-neutral">
          These settings apply to all {persons.length} {persons.length === 1 ? "person" : "people"}{" "}
          in the {orgName} organization
        </div>
      </div>
      <OrgTimeZone />
      <ZoomSettings
        userId={userId}
        zoomAccount={zoomAccount}
        refetchOrgAccount={refetchOrgAccount}
      />
    </div>
  );
};
