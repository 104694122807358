import { isValidEmailFormat } from "@clockwise/client-commons/src/util/email";
import { TextField } from "@clockwise/design-system";
import { ResponseStatusEnum } from "@clockwise/schema";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { Portal, Transition } from "@headlessui/react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePopper } from "react-popper";
import { useBoolean } from "usehooks-ts";
import { useSearchPersonsChat } from "../../../chat/hooks/useSearchPersons";
import { EventCardAttendee } from "../../types";

const ABOVE_EVERYTHING = 10000;

const SEARCH_PERSONS_LIMIT = 5;

export const ECAttendeeSelect = ({
  disabled,
  currentAttendees,
  onAddAttendee,
}: {
  disabled: boolean;
  currentAttendees: EventCardAttendee[];
  onAddAttendee: (attendee: EventCardAttendee) => void;
}) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { value: isFocused, setTrue: setFocused, setFalse: setUnfocused } = useBoolean(false);
  const isQueryEmpty = useMemo(() => isEmpty(query), [query]);

  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
  const hiddenCalendarIds = useMemo(
    () => currentAttendees.map((attendee) => attendee.primaryCalendar),
    [currentAttendees],
  );

  const { handlePersonSearch, searchPersons, setShowEmptyState } = useSearchPersonsChat({
    searchPersonLimit: SEARCH_PERSONS_LIMIT,
    skip: isQueryEmpty,
    showAllTopCollaborators: true,
  });

  const onSearch = useCallback(
    (searchQuery: string) => {
      handlePersonSearch(searchQuery, hiddenCalendarIds);
    },
    [hiddenCalendarIds],
  );

  const clearSearch = () => {
    setQuery("");
  };

  const updateSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    if (!isEmpty(e.target.value.trim()) && e.target.value !== query) {
      onSearch(e.target.value);
    }
    setQuery(e.target.value);
  };

  useEffect(() => {
    if (isQueryEmpty) {
      setShowEmptyState(true);
    } else if (!isQueryEmpty) {
      setShowEmptyState(false);
    }
  }, [query]);

  // On mount set so that stop tollaborators are shown by default
  useEffect(() => {
    setShowEmptyState(true);
  }, []);

  const onClickAndAddAttendee = (attendee: EventCardAttendee) => {
    onAddAttendee(attendee);
    clearSearch();
  };

  const filteredSearchPersons = useMemo(() => {
    return searchPersons
      .filter((person) => !hiddenCalendarIds.includes(person.primaryCalendar))
      .slice(0, SEARCH_PERSONS_LIMIT);
  }, [searchPersons, hiddenCalendarIds]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (searchPersons.length) {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        if (highlightedIndex === 0) {
          const bottomOfHighlighted =
            (filteredSearchPersons.length - 1) % filteredSearchPersons.length;
          setHighlightedIndex(bottomOfHighlighted);
        } else {
          const oneAboveHighlighted = (highlightedIndex - 1) % filteredSearchPersons.length;
          setHighlightedIndex(oneAboveHighlighted);
        }
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        const oneBelowHighlighted = (highlightedIndex + 1) % filteredSearchPersons.length;
        setHighlightedIndex(oneBelowHighlighted);
      } else if (e.key === "Enter") {
        onClickAndAddAttendee(filteredSearchPersons[highlightedIndex]);
        ref.current?.blur();
      }
    } else {
      if (e.key === "Enter") {
        const currentQuery = query.trim();
        if (isValidEmailFormat(currentQuery)) {
          const newAttendee: EventCardAttendee = {
            attendingStatus: ResponseStatusEnum.NeedsAction,
            id: "",
            isOptional: false,
            isOrganizer: false,
            isYou: false,
            primaryCalendar: currentQuery,
            primaryEmail: currentQuery,
            profile: null,
            userId: null,
          };
          onClickAndAddAttendee(newAttendee);
          ref.current?.blur();
        } else {
          setErrorMessage("Not a valid email");
        }
      }
    }
  };

  const updateHighlightedSuggestion = (index: number) => {
    setHighlightedIndex(index);
  };

  return (
    <div className="cw-mr-1 cw-w-full" cw-id="ec-attendee-select">
      <TextField
        type="text"
        fullWidth
        disabled={disabled}
        value={query}
        placeholder="Add attendees"
        fieldSize="small"
        onChange={updateSearchQuery}
        ref={ref}
        cw-id="ec-attendee-select-input"
        aria-label={"Add attendees"}
        onKeyDown={handleKeyDown}
        onBlur={setUnfocused}
        onFocus={setFocused}
        error={!!errorMessage}
        errorMessage={errorMessage}
      />
      <div tabIndex={0}>
        <AttendeeSearchOptions
          isOpen={!isEmpty(filteredSearchPersons) && isFocused}
          searchPersons={filteredSearchPersons}
          referenceElement={ref}
          onAddAttendee={onClickAndAddAttendee}
          setHighlightedSuggestion={updateHighlightedSuggestion}
          highlightedSuggestion={highlightedIndex}
        />
      </div>
    </div>
  );
};

const AttendeeSearchOptions = ({
  searchPersons,
  isOpen,
  referenceElement,
  onAddAttendee,
  highlightedSuggestion,
  setHighlightedSuggestion,
}: {
  searchPersons: EventCardAttendee[];
  isOpen: boolean;
  referenceElement: React.RefObject<HTMLDivElement>;
  onAddAttendee: (attendee: EventCardAttendee) => void;
  highlightedSuggestion: number | null;
  setHighlightedSuggestion: (index: number) => void;
}) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement.current, container, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [
      {
        name: "flip",
        enabled: true,
      },
      { name: "offset", options: { offset: [0, 2] } },
    ],
  });
  return (
    <Portal>
      <Transition
        show={isOpen}
        leave="cw-transition cw-ease-in cw-duration-100"
        leaveFrom="cw-opacity-100"
        leaveTo="cw-opacity-0"
        enter="cw-transition cw-ease-in cw-duration-75"
        enterFrom="cw-opacity-0"
        enterTo="cw-opacity-100"
      >
        <div
          role="combobox"
          ref={setContainer}
          style={{
            ...styles.popper,
            minWidth: referenceElement.current?.scrollWidth || 0,
            maxWidth: 500,
            zIndex: ABOVE_EVERYTHING,
          }}
          {...attributes.popper}
          className={classNames(
            "cw-p-2 cw-shadow-md cw-rounded-lg cw-absolute cw-body-base cw-bg-default",
            "cw-border cw-border-solid cw-border-default",
          )}
        >
          <Suggestions
            suggestions={searchPersons}
            onClick={onAddAttendee}
            highlightedSuggestion={highlightedSuggestion}
            setHighlightedSuggestion={setHighlightedSuggestion}
          />
        </div>
      </Transition>
    </Portal>
  );
};

const Suggestions = ({
  suggestions,
  onClick,
  highlightedSuggestion,
  setHighlightedSuggestion,
}: {
  suggestions: EventCardAttendee[];
  onClick: (suggestion: EventCardAttendee) => void;
  highlightedSuggestion: number | null;
  setHighlightedSuggestion: (index: number) => void;
}) => {
  return (
    <div>
      {suggestions.map((suggestion, index) => {
        const onAddSuggestion = () => {
          onClick(suggestion);
        };
        return (
          <div
            onMouseEnter={() => {
              setHighlightedSuggestion(index);
            }}
            role="button"
            tabIndex={0}
            className={classNames(
              "cw-p-1 cw-cursor-pointer cw-flex cw-items-center cw-rounded hover:cw-bg-default-hover",
              { "cw-bg-default-hover": highlightedSuggestion === index },
            )}
            onMouseDown={onAddSuggestion}
            key={`${suggestion.primaryCalendar}-${index}`}
            aria-label={suggestion.profile?.givenName || suggestion.primaryCalendar}
          >
            <AttendeeAvatar
              profile={
                suggestion?.profile || { familyName: "", givenName: "", externalImageUrl: "" }
              }
              size="medium"
            />
            <span className="cw-font-medium cw-ml-2 cw-mr-1">{`${
              suggestion.profile?.givenName || ""
            } ${suggestion.profile?.familyName || ""}`}</span>
            <span className="cw-text-neutral-muted cw-body-sm">{suggestion.primaryCalendar}</span>
          </div>
        );
      })}
    </div>
  );
};
