import {
  ApolloCache,
  ApolloError,
  InternalRefetchQueriesInclude,
  useMutation,
} from "@apollo/client";
import { useCallback } from "react";
import {
  CancelChatProposalDocument,
  CancelChatProposalMutation,
} from "../apollo/__generated__/CancelChatProposal.generated";
import { tempCancelAssistantMessage } from "../utils/getTempAssistantMessage";
import { useAIMessageContext } from "./AIMessageContext";

type UseCancelChatProposalResult = [
  () => void,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

type UseCancelChatProposalProps = {
  onCompleted?: (data: CancelChatProposalMutation) => void;
  onError?: (error: ApolloError) => void;
  update?: (cache: ApolloCache<any>, { data }: { data: CancelChatProposalMutation }) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
};

export const useCancelChatProposal = ({
  onCompleted,
  onError,
  update,
  refetchQueries,
}: UseCancelChatProposalProps): UseCancelChatProposalResult => {
  const [cancelChatProposal, { loading, error }] = useMutation(CancelChatProposalDocument);
  const { chatHistoryId, messages, shouldExcludeAi } = useAIMessageContext();
  const onCancelChatProposal = useCallback(() => {
    const newMessage = tempCancelAssistantMessage;
    const optimisticResponse: CancelChatProposalMutation | undefined = chatHistoryId
      ? {
          __typename: "Mutation",
          cancelChatProposal: {
            __typename: "CancelChatProposalPayload",
            updatedHistory: {
              __typename: "ChatHistory",
              id: chatHistoryId,
              shouldExcludeAi,
              isProcessing: false,
              messages: [...messages.slice(0, -1), newMessage],
            },
            success: true,
          },
        }
      : undefined;
    void cancelChatProposal({
      onCompleted,
      onError,
      update,
      refetchQueries,
      optimisticResponse,
    });
  }, [
    cancelChatProposal,
    onCompleted,
    onError,
    update,
    refetchQueries,
    chatHistoryId,
    messages,
    shouldExcludeAi,
  ]);

  return [onCancelChatProposal, { loading, error }];
};
