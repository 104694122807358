import { logger } from "#webapp/src/util/logger.util";
import { EventColorCategory } from "@clockwise/client-commons/src/util/event-category-coloring";
import { EventCategoryType } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useCalendarColors } from "@clockwise/web-commons/src/util/event-category-coloring";
import React from "react";
import { EventCategory } from "../chat-plus-calendar/CurrentProposalContext";
import { usePlannerContext } from "../web-app-calendar/Context";
import { CategoryOption } from "../web-app-calendar/hooks/useEventDetails";
import { eventToast } from "../web-app-calendar/notifiation-event/EventToast";
import { UpdateEventCategoryTypeDocument } from "./__generated__/UpdateEventCategoryType.v2.generated";
import { ECCategorySelector } from "./molecules/ECCategorySelect";

const toEventColorCategory = (category: EventCategoryType): EventColorCategory => {
  switch (category) {
    case EventCategoryType.AdHoc:
      return EventColorCategory.AdHoc;
    case EventCategoryType.External:
      return EventColorCategory.External;
    case EventCategoryType.FocusTime:
      return EventColorCategory.FocusTime;
    case EventCategoryType.Holiday:
      return EventColorCategory.Holiday;
    case EventCategoryType.IndividualHold:
      return EventColorCategory.IndividualHold;
    case EventCategoryType.OneOnOne:
      return EventColorCategory.OneOnOne;
    case EventCategoryType.Other:
      return EventColorCategory.Other;
    case EventCategoryType.OutOfOffice:
      return EventColorCategory.OutOfOffice;
    case EventCategoryType.PaceSetting:
      return EventColorCategory.PaceSetting;
    default:
      return EventColorCategory.Other;
  }
};

const toEventCategoryType = (category: EventColorCategory): EventCategoryType => {
  switch (category) {
    case EventColorCategory.AdHoc:
      return EventCategoryType.AdHoc;
    case EventColorCategory.External:
      return EventCategoryType.External;
    case EventColorCategory.FocusTime:
      return EventCategoryType.FocusTime;
    case EventColorCategory.Holiday:
      return EventCategoryType.Holiday;
    case EventColorCategory.IndividualHold:
      return EventCategoryType.IndividualHold;
    case EventColorCategory.OneOnOne:
      return EventCategoryType.OneOnOne;
    case EventColorCategory.Other:
      return EventCategoryType.Other;
    case EventColorCategory.OutOfOffice:
      return EventCategoryType.OutOfOffice;
    case EventColorCategory.PaceSetting:
      return EventCategoryType.PaceSetting;
    default:
      return EventCategoryType.Other;
  }
};

type Props = {
  category: EventCategory;
  eventTitle?: string;
  externalEventId?: string;
  readOnly?: boolean;
  onCategoryChange?: (category: EventCategory) => void;
};

export const EventCategorySelector = ({
  category,
  externalEventId,
  eventTitle = "Event",
  onCategoryChange,
  readOnly,
}: Props) => {
  const [updateEventCategoryType] = useGatewayMutation(UpdateEventCategoryTypeDocument);
  const { getCurrentSettings } = useCalendarColors();
  const { colorSettings } = usePlannerContext();

  const currentSettings = getCurrentSettings(colorSettings);
  const allColorSettings: CategoryOption[] = currentSettings.map((setting) => {
    return {
      category: setting.eventCategory,
      color: setting.renderColor,
      label: setting.labels.label,
    };
  });

  const handleCategoryChange = (cat: EventColorCategory) => {
    const updatedCategoryType = toEventCategoryType(cat);
    onCategoryChange?.({ type: updatedCategoryType });

    if (!externalEventId) {
      return;
    }

    void updateEventCategoryType({
      variables: { externalEventId, category: updatedCategoryType },
      onCompleted: (data) => {
        if (data.updateEventCategoryType?.category?.type === updatedCategoryType) {
          eventToast.success({
            title: eventTitle,
            operation: "EDIT_DETAILS",
          });
        }
      },
      onError: (err) => {
        eventToast.error({
          title: eventTitle,
          operation: "EDIT_DETAILS",
        });
        logger.error("unable to edit event category for event", {
          error: err || "unable to edit event category for event",
          externalEventId,
        });
      },
    });
  };

  return (
    <ECCategorySelector
      allColorSettings={allColorSettings}
      currentCategory={toEventColorCategory(category.type)}
      onCategoryChange={handleCategoryChange}
      readOnly={readOnly}
    />
  );
};
