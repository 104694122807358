import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SudoEnableSsoMutationVariables = Types.Exact<{
  input: Types.SudoEnableSsoMutationInput;
}>;


export type SudoEnableSsoMutation = { __typename: 'Mutation', sudoEnableSSO: { __typename: 'SudoEnableSSOMutationPayload', clientMutationId: string | null } | null };


export const SudoEnableSsoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SudoEnableSSO"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SudoEnableSSOMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sudoEnableSSO"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}}]}}]}}]} as unknown as DocumentNode<SudoEnableSsoMutation, SudoEnableSsoMutationVariables>;