import { Tooltip } from "@clockwise/design-system";
import { border_default, fg_brand } from "@clockwise/design-system/tokens";
import React from "react";
import { ColoredProfile } from ".";
import { Profile, getStringListOfProfiles } from "../../util/profile.util";
import { AttendeeAvatar, AvatarSize } from "../AttendeeAvatar";
import { OverflowAvatar } from "../AttendeeAvatarStack";

const positioningMap = {
  TR: "cw-top-0 cw-right-0",
  TL: "cw-top-0 cw-left-0",
  BR: "cw-bottom-0 cw-right-0",
  BL: "cw-bottom-0 cw-left-0",
};

const ClusterSegment = ({
  profile,
  position,
  size,
  hideTooltip,
  showBorder = false,
}: {
  profile: ColoredProfile | null;
  position: keyof typeof positioningMap;
  size: AvatarSize;
  hideTooltip?: boolean;
  showBorder?: boolean;
}) => {
  return (
    <div className={`cw-absolute cw-flex cw-items-start ${positioningMap[position]}`}>
      <AttendeeAvatar
        profile={profile?.profile}
        size={size}
        borderColor={showBorder ? profile?.color || border_default : undefined}
        hideTooltip={hideTooltip}
      />
    </div>
  );
};

const clusterContainerClass = "cw-relative cw-h-8 cw-w-8";

export const AvatarCluster: React.FC<
  | {
      profiles: Profile[];
      /**
       @deprecated coloredProfiles cannot be used with profiles
       */
      coloredProfiles?: never;
      overflowLimit?: 1 | 2 | 3 | 4;
    }
  | {
      /**
       @deprecated profiles cannot be used with coloredProfiles
       */
      profiles?: never;
      coloredProfiles: (ColoredProfile | null)[];
      overflowLimit?: 1 | 2 | 3 | 4;
    }
> = ({ coloredProfiles, profiles, overflowLimit = 4 }) => {
  const hasColors = !!coloredProfiles;
  const avatarProfiles = profiles
    ? profiles.map((profile) => {
        return { color: "", profile: profile };
      })
    : coloredProfiles;

  if (!avatarProfiles.length) {
    return <AttendeeAvatar profile={undefined} size="medium" borderColor={fg_brand} hideTooltip />;
  }

  const overflowTooltipContent = getStringListOfProfiles(avatarProfiles.map((p) => p?.profile));

  if (avatarProfiles.length > overflowLimit) {
    return (
      <OverflowAvatar
        count={avatarProfiles.length}
        size="medium"
        overflowTooltipContent={overflowTooltipContent}
      />
    );
  }

  if (avatarProfiles.length === 1) {
    return (
      <AttendeeAvatar
        profile={avatarProfiles?.[0]?.profile}
        size="medium"
        borderColor={hasColors ? avatarProfiles?.[0]?.color || border_default : undefined}
      />
    );
  }
  if (avatarProfiles.length === 2)
    return (
      <div className={clusterContainerClass}>
        <ClusterSegment
          showBorder={hasColors}
          profile={avatarProfiles?.[0]}
          position="TR"
          size="medium"
        />
        <ClusterSegment
          showBorder={hasColors}
          profile={avatarProfiles?.[1]}
          position="BL"
          size="small"
        />
      </div>
    );
  if (avatarProfiles.length === 3)
    return (
      <div className={clusterContainerClass}>
        <ClusterSegment
          showBorder={hasColors}
          profile={avatarProfiles?.[0]}
          position="TR"
          size="small"
        />
        <ClusterSegment
          showBorder={hasColors}
          profile={avatarProfiles?.[1]}
          position="BL"
          size="small"
        />
        <ClusterSegment
          showBorder={hasColors}
          profile={avatarProfiles?.[2]}
          position="BR"
          size="xsmall"
        />
      </div>
    );

  if (avatarProfiles.length === 4) {
    return (
      <Tooltip title={overflowTooltipContent}>
        <div className={clusterContainerClass}>
          <ClusterSegment
            showBorder={hasColors}
            profile={avatarProfiles?.[0]}
            position="TR"
            size="small"
            hideTooltip
          />
          <ClusterSegment
            showBorder={hasColors}
            profile={avatarProfiles?.[1]}
            position="BL"
            size="small"
            hideTooltip
          />
          <ClusterSegment
            showBorder={hasColors}
            profile={avatarProfiles?.[2]}
            position="BR"
            size="xsmall"
            hideTooltip
          />
          <ClusterSegment
            showBorder={hasColors}
            profile={avatarProfiles?.[3]}
            position="TL"
            size="xsmall"
            hideTooltip
          />
        </div>
      </Tooltip>
    );
  }

  return (
    <OverflowAvatar
      count={avatarProfiles.length}
      size="medium"
      overflowTooltipContent={overflowTooltipContent}
    />
  );
};
