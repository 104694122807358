import { graphql } from "react-relay";

export const sudoTriggerClientUpdateFragments = {
  sudo: graphql`
    fragment SudoTriggerClientUpdate_sudo on Sudo {
      id
      chromeAppUpdateTimes {
        idleTime
        forceTime
      }
    }
  `,
};
