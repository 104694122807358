import { Button } from "@clockwise/design-system";
import { InvitePolicyEnum } from "@clockwise/schema";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { CaretDown, Plus } from "phosphor-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFeatureFlag } from "../../../../../launch-darkly";
import { TrackingEvents, track } from "../../../../../util/analytics.util";
import { ToggleInvitePolicy } from "../PrimaryBillingCard/ToggleInvitePolicy";
import { APBillingGroup } from "../apBillingGroups.util";
import { SsoAdminPortalButton } from "../sso-admin-portal-button";
import { RowAdminList } from "./RowAdminList";

export const OtherBGRow = ({
  bg,
  orgId,
  isUserInNoBGs,
  orgDomain,
  onUserAddSelect,
}: {
  bg: APBillingGroup;
  orgId: string;
  isUserInNoBGs: boolean;
  orgDomain?: string;
  onUserAddSelect: (bgId: string, canUserAddSelf: boolean) => void;
}) => {
  const navigate = useNavigate();
  const [isBGRowOpen, setIsBGRowOpen] = useState(false);
  const [isRequested, setIsRequested] = useState(false);

  const [hasCustomRequestToJoinRequest] = useFeatureFlag("CustomJoinAPlanRequest");

  const renderBillingGroupRequestToJoin = () => {
    const canAddSelf = bg.invitePolicy === InvitePolicyEnum.AnyMember;
    const label = canAddSelf ? `Join plan` : isRequested ? `Request Sent` : `Request to Join`;
    return (
      <Button
        onClick={() => {
          if (canAddSelf) {
            onUserAddSelect(bg.id, canAddSelf);
          } else {
            if (!hasCustomRequestToJoinRequest) {
              setIsRequested(true);
            }

            onUserAddSelect(bg.id, canAddSelf);
          }
        }}
        disabled={isRequested}
        variant="outlined"
        size="small"
      >
        <Plus weight="bold" color="black" size="12" />
        {label}
      </Button>
    );
  };

  return (
    <div className="cw-w-full">
      <div
        className={`cw-pl-5 cw-grid cw-grid-cols-12 cw-w-full cw-body-base cw-py-4 ${
          isBGRowOpen ? "cw-border-none" : "cw-border-solid"
        } cw-border-b cw-border-neutral`}
      >
        <div
          className={`${
            isUserInNoBGs ? "cw-col-span-3" : "cw-col-span-5"
          } cw-font-semibold cw-flex cw-items-center`}
        >
          {bg.bgName}
        </div>
        <div className="cw-col-span-2 cw-flex cw-items-center">{bg.planType}</div>
        <div className="cw-col-span-4 cw-flex cw-items-center">
          <RowAdminList adminList={bg.adminList} />
        </div>
        {isUserInNoBGs && (
          <div className="cw-col-span-2 cw-flex cw-justify-end">
            {renderBillingGroupRequestToJoin()}
          </div>
        )}
        <div
          onClick={() => {
            setIsBGRowOpen(!isBGRowOpen);
          }}
          className="cw-col-span-1 cw-justify-center cw-flex cw-items-center "
        >
          <div className="cw-cursor-pointer hover:cw-bg-default-hover cw-flex cw-items-center cw-p-2 cw-rounded-md">
            <CaretDown
              size="15"
              weight="bold"
              color="black"
              className={`${isBGRowOpen ? "cw-rotate-180" : "cw-rotate-0"} cw-transition-all`}
            />
          </div>
        </div>
      </div>
      {isBGRowOpen && (
        <div className="cw-px-5 cw-w-full cw-border-b cw-border-solid cw-ease-out cw-py-4 cw-border-neutral cw-flex cw-items-center cw-gap-10">
          {bg.canToggleInvitePolicy ? (
            <div className="cw-flex cw-gap-4 cw-items-center">
              <ToggleInvitePolicy
                orgId={orgId}
                bgId={bg.id}
                invitePolicy={bg.invitePolicy}
                orgDomain={orgDomain}
              />
            </div>
          ) : bg.canSeeSCIM ? (
            <SsoAdminPortalButton
              orgId={orgId}
              billingGroupId={bg?.id || ""}
              isAdmin={!!bg?.isAdmin}
              isSSO={bg.canSeeSCIM}
            />
          ) : null}
          <div className="cw-flex cw-gap-3 cw-items-center cw-flex-end">
            <span className="cw-body-sm">
              <span className="cw-font-bold">{bg.userCount}</span> out of{" "}
              <span className="cw-font-bold">{bg.seatCount}</span> paid seats are being used
            </span>
            <Button
              sentiment="positive"
              variant="outlined"
              onClick={() => {
                track(TrackingEvents.ADMIN_PANEL.APBILLING_CLICK_OTHER_MEMBERS);
                navigate(`${appPaths.members}/${bg.id}`);
              }}
            >
              Manage Members
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
