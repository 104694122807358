//////////////////
// IMPORTS
//////////////////
// libraries
import * as React from "react";
import { useSelector } from "react-redux";
// login-default imports
import * as s from "../LoginWrapper.styles";
// other internals
import { LoginBtn } from "#webapp/src/components/login-btn";
import { MsftLoginButton } from "#webapp/src/components/msft-login-button";
import { CookieDialog } from "@clockwise/web-commons/src/components/cookie-dialog";
// img
// util
import { page, PageEvents, track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { PostMessageManager } from "#webapp/src/util/post-message.util";
// constants
import { paths } from "#webapp/src/constants/site.constant";
// state
import { hasSeenLoginPage, lastSignInDate } from "#webapp/src/state/local-storage";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
// material-ui
import { LinearProgress, Link } from "@clockwise/design-system";
import { LogoClock } from "@clockwise/web-commons/src/components/logo";
import { DateTime } from "luxon";
import Helmet from "react-helmet";

export interface IProps {
  userId: string | null;
  redirect?: string;
  onSuccess: () => void;
}

export const LoginDefault = ({ onSuccess, userId, redirect }: IProps) => {
  const isWebExtension = useSelector((state: IReduxState) => state.webExtension.isWebExtension);

  const [loading, setLoading] = React.useState(false);
  const [hideCookieErrorDialog, setHideCookieErrorDialog] = React.useState(true);

  React.useEffect(() => {
    page(PageEvents.LOGIN);
    hasSeenLoginPage.set(true);
    if (isWebExtension) {
      PostMessageManager.openSidebar(true);
    }
  }, []);

  React.useEffect(() => {
    setLoading(false);
  }, [userId]);

  const onLoginStart = () => {
    track(TrackingEvents.AUTH.BEGIN_LOGIN);
    setLoading(true);
  };

  const onLoginSuccess = () => {
    lastSignInDate.set(DateTime.now().toISO());
    onSuccess();
  };

  const onLoginFailure = (_e: any) => {
    track(TrackingEvents.AUTH.LOGIN_FAILED);
    setLoading(false);
  };

  const onGoogleFailure = (e: any) => {
    if (e.error === "idpiframe_initialization_failed") {
      setHideCookieErrorDialog(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Sign in to Clockwise · Clockwise</title>
      </Helmet>
      <CookieDialog isChromeExtension={isWebExtension} open={!hideCookieErrorDialog} />
      <div className="cw-p-2 cw-flex cw-items-center cw-justify-center cw-h-full cw-flex-1">
        <div className="cw-bg-default cw-rounded-lg cw-w-[480px] cw-p-[60px] cw-flex cw-flex-col cw-items-center cw-relative">
          <div className="cw-w-[95px] cw-h-[95px] cw-flex cw-items-center cw-justify-center cw-rounded-full cw-flex-shrink-0 cw-bg-accent-emphasis">
            <LogoClock fill="bright" className="cw-w-[60px] cw-h-[60px]" />
          </div>
          <div className="cw-flex cw-flex-col cw-items-center cw-p-2 cw-pt-4">
            <div className="cw-heading-base cw-mb-4 cw-text-center">
              Sign in to Clockwise with your work calendar
            </div>
            <div className="cw-w-[215px] cw-mb-4">
              <LoginBtn
                label="Sign in with Google"
                style={s.signupBtn.base}
                labelStyle={s.signupBtn.label}
                redirect={redirect}
                onStart={onLoginStart}
                onSuccess={onLoginSuccess}
                onFailure={onLoginFailure}
                onGoogleFailure={onGoogleFailure}
              />
            </div>
            <div className="cw-w-full cw-mb-4 cw-flex cw-items-center cw-w-5">
              <div className="cw-grow cw-border-b cw-border-solid cw-border-muted"></div>
              <div className="cw-px-2 cw-caption cw-text-muted cw-text-center">
                or try out our latest beta
              </div>
              <div className="cw-grow cw-border-b cw-border-solid cw-border-muted"></div>
            </div>
            <div className="cw-w-[215px] cw-mb-4">
              <MsftLoginButton redirect={redirect} />
            </div>
          </div>
          <div className="cw-caption cw-text-muted cw-text-center">
            By signing in, you agree to Clockwise's{" "}
            <Link target="_blank" href={paths.terms}>
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link target="_blank" href={paths.privacy}>
              Privacy Policy
            </Link>
            .
          </div>
          {loading && (
            <LinearProgress className="cw-absolute cw-bottom-0 cw-w-full cw-bg-transparent" />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
