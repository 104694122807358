import { Select, SelectOption } from "@clockwise/design-system";
import React, { ComponentProps } from "react";

export type AdminSelectOptionKey = string;
export type AdminSelectOptionValue = string;
export type AdminSelectOption = [AdminSelectOptionKey, AdminSelectOptionValue];
export type AdminSelectOptions = AdminSelectOption[];

export type AdminSelectProps = Omit<ComponentProps<typeof Select>, "onChange" | "value"> & {
  "cw-id"?: string;
  icon?: React.ElementType;
  onChange?: (selectedOptionKey: AdminSelectOptionKey) => void;
  options: AdminSelectOptions;
  value: AdminSelectOptionKey;
};

const AdminSelect = ({
  "cw-id": cwId = "admin-overview_select",
  icon: SelectIcon,
  onChange,
  options,
  value,
}: AdminSelectProps) => {
  const handleChange = (value: string) => {
    if (!onChange) {
      return;
    }

    if (!value) {
      return;
    }

    onChange(value);
  };

  return (
    <div className="cw-flex cw-items-center cw-text-right cw-text-neutral-muted cw-text-base">
      {!!SelectIcon && (
        <div className="cw-mr-2 cw-mt-1">
          <SelectIcon />
        </div>
      )}
      <Select size="large" cw-id={cwId} onChange={handleChange} value={value}>
        {options.map(([key, text]) => (
          <SelectOption key={key} value={key}>
            {text}
          </SelectOption>
        ))}
      </Select>
    </div>
  );
};

export default AdminSelect;
