import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateLinkNameMutationVariables = Types.Exact<{
  linkName: Types.Scalars['String'];
}>;


export type UpdateLinkNameMutation = { __typename: 'Mutation', updateLinkName: { __typename: 'User', id: string, linkName: string | null, schedulingLinks: Array<{ __typename: 'SchedulingLinkWithUrl', id: string, url: string, schedulingLink: { __typename: 'SchedulingLink', id: string, active: boolean } }> } | null };


export const UpdateLinkNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateLinkName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"linkName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateLinkName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"linkName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"linkName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"linkName"}},{"kind":"Field","name":{"kind":"Name","value":"schedulingLinks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"schedulingLink"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"active"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UpdateLinkNameMutation, UpdateLinkNameMutationVariables>;