import { Switch, Tooltip } from "@clockwise/design-system";
import { PauseCircleFilled, SvgIconComponent, WarningFilled } from "@clockwise/icons";
import classNames from "classnames";
import React from "react";
import { FlexOutlineIcon } from "../atoms/FlexOutlineIcon";
import { TimingSetMessage } from "../atoms/TimingSetMessage";
import { FlexibleStatusSimplified } from "../types";

const DEFAULT_TOOLTIP_TITLE =
  "Allow Clockwise to automatically reschedule this event to resolve conflicts and optimize your schedule.";
const PAUSED_TOOLTIP_TITLE =
  "This meeting is paused because it was manually rescheduled. Clockwise will not move a flexible meeting that has been manually rescheduled. Future meetings in this series will still move.";
const STUCK_TOOLTIP_TITLE =
  "Clockwise can't find any overlap in attendees' meeting hours. This meeting may need to be manually rescheduled.";
const PAYWALL_TOOLTIP_TITLE =
  "This meeting is paused because it was manually rescheduled. Clockwise will not move a flexible meeting that has been manually rescheduled. Future meetings in this series will still move.";

type Props = {
  disabled: boolean;
  flexible: boolean;
  onChange: (flexible: boolean) => void;
  pinned: boolean;
  status: FlexibleStatusSimplified;
  updating: boolean;
  noIcon?: boolean;
};

export const FlexibleControl = ({
  disabled,
  flexible,
  onChange,
  pinned,
  status,
  updating,
  noIcon,
}: Props) => {
  const handleChangeFlexible = () => {
    onChange(!flexible);
  };

  let tooltipTitle = DEFAULT_TOOLTIP_TITLE;
  let StatusIcon: SvgIconComponent | null = null;
  let sentiment: "neutral" | "warning" = "neutral";
  if (flexible) {
    switch (status) {
      case "stuck":
        tooltipTitle = STUCK_TOOLTIP_TITLE;
        StatusIcon = WarningFilled;
        sentiment = "warning";
        break;
      case "paused":
        tooltipTitle = PAUSED_TOOLTIP_TITLE;
        StatusIcon = PauseCircleFilled;
        sentiment = "warning";
        break;
      case "paywall":
        tooltipTitle = PAYWALL_TOOLTIP_TITLE;
        StatusIcon = PauseCircleFilled;
        sentiment = "warning";
        break;
    }
  }

  return (
    <Tooltip title={tooltipTitle} openDelay={1000}>
      <div className="cw-flex cw-flex-col cw-gap-1">
        <div className="cw-flex cw-flex-row cw-items-center cw-gap-2">
          <div
            className={classNames(
              "cw-w-4 cw-h-4 cw-flex cw-items-center",
              !flexible && "cw-opacity-50",
            )}
          >
            <FlexOutlineIcon />
          </div>
          <div className="cw-flex-grow">
            <div className="cw-flex cw-flex-row cw-items-center cw-justify-between">
              <div
                className={classNames(
                  "cw-cursor-pointer cw-flex cw-flex-row cw-items-center cw-gap-1 cw-text-13 cw-font-medium",
                  !flexible && "cw-opacity-50",
                )}
                onClick={handleChangeFlexible}
              >
                Flexible event
                {!noIcon && StatusIcon && (
                  <StatusIcon className="cw-w-3.5 cw-h-3.5 cw-text-subtle" />
                )}
              </div>
              <Switch
                checked={flexible}
                disabled={disabled || updating}
                onChange={handleChangeFlexible}
                sentiment={sentiment}
                size="small"
              />
            </div>
          </div>
        </div>
        {flexible && pinned && <TimingSetMessage />}
      </div>
    </Tooltip>
  );
};
