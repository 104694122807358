import {
  CALENDAR_HEIGHT_COMFORTABLE,
  CALENDAR_HEIGHT_COMPACT,
  CALENDAR_HEIGHT_DEFAULT,
  CalendarDensity,
} from "@clockwise/web-commons/src/components/calendar";
export const useCalendarDimensions = ({
  numColumns,
  ref,
  calendarDensity,
}: {
  numColumns: number;
  ref: React.RefObject<HTMLDivElement> | null;
  calendarDensity: CalendarDensity | null;
}) => {
  const numOfDaysShown = numColumns; // number of calendars === number of days
  const calendarWeekWidthInPx = ref?.current?.clientWidth || 0;
  const dayWidthInPx = calendarWeekWidthInPx / numOfDaysShown;

  let calHeight = CALENDAR_HEIGHT_DEFAULT;
  if (calendarDensity === "compact") {
    calHeight = CALENDAR_HEIGHT_COMPACT;
  } else if (calendarDensity === "comfortable") {
    calHeight = CALENDAR_HEIGHT_COMFORTABLE;
  }
  return {
    numOfDaysShown,
    calendarWeekWidthInPx,
    dayWidthInPx,
    calHeight,
  };
};
