import { Day, MaxSlot, MinSlot } from "@clockwise/schema";
import {
  convertTimeMaxSlotToHourMinutes,
  convertTimeMinSlotToHourMinutes,
} from "../../../../util/time-slot.util";

export type DaySetting = {
  __typename: "DaySetting";
  day: Day | null;
  setting: {
    __typename: "WorkingSetting";
    minSlot: MinSlot | null;
    maxSlot: MaxSlot | null;
  } | null;
};

export type DaySettings = (DaySetting | null)[] | null | undefined;

export const parseWorkingHourBounds = (daySettings: DaySettings): [number, number] | null => {
  const workingHours = daySettings;
  if (!workingHours || workingHours.length === 0) {
    return null;
  }

  const min = Math.min(
    ...workingHours.map(
      (wh) =>
        (wh?.setting?.minSlot
          ? convertTimeMinSlotToHourMinutes(wh?.setting?.minSlot)?.minutesIntoDay
          : 0) / 60,
    ),
  );

  const max = Math.max(
    ...workingHours.map(
      (wh) =>
        (wh?.setting?.maxSlot
          ? convertTimeMaxSlotToHourMinutes(wh.setting.maxSlot).minutesIntoDay
          : 0) / 60,
    ),
  );

  return [min, max];
};
