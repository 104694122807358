import { logoSparkleMalachite } from "#webapp/src/assets/img/logos";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
// import { track, TrackingEvents } from "#webapp/src/util/analytics.util";

export enum NotificationType {
  Cli = "Cli",
  NewOneOnOne = "newOneOnOne",
}

export interface PushNotificationCli {
  type: NotificationType;
}

export interface PushNotificationEventFlexibility {
  type: NotificationType;
  events: Array<{
    calendarId: string;
    parentEventId: string;
  }>;
}

export type PushNotificationData = PushNotificationCli | PushNotificationEventFlexibility;

export const requestNotificationPermission = async () => {
  const permission = await window.Notification.requestPermission();
  // value of permission can be 'granted', 'default', 'denied'
  // granted: user has accepted the request
  // default: user has dismissed the notification permission popup by clicking on x
  // denied: user has denied the request.
  return permission;
};

export const showLocalNotification = (
  options: NotificationOptions,
  swRegistration: ServiceWorkerRegistration,
  title = "Clockwise",
) => {
  const notificationOptions = {
    icon: logoSparkleMalachite,
    badge: logoSparkleMalachite,
    requireInteraction: true,
    ...options,
  };

  // track(TrackingEvents.BROWSER_NOTIFICATION.BROWSER_NOTIFICATION_CLICKED, {
  //   notificationType: null,
  // });

  return swRegistration.showNotification(title, notificationOptions);
};

export const getPushServiceVAPIDKey = () => {
  switch (getEnvironment()) {
    case "preprod":
    case "production":
      return "BIBiHEOKpCyjuvsJlEBig-ACOZPWNvsve0qh5_edkqIdmn7XEqMMg679GzreSb3aA55qpQ16E4G1if9sGmiiZpI";
    case "staging":
      return "BL9QSyKtbAYOnNR-Y1B38htbGhqjfJYy3tQIpZACHPpWvUnQEIfEwNzaC6UIkjIRmfVTC1THCf9bVMGK2cLKhGI";
    case "development":
    default:
      return "BGAO7BrUAVLH_yoRkva3wzzFDQGIrz0rBUE1MFkqxQPLTiJJl0y5MxTT40N4KqV6fHc9OfYuOO7CSulkUBbbRps";
  }
};
