import React from "react";
import { ToggleCalendarsLayersForEvents } from "../../../chat/ai-chat/components/multi-calendar/ToggleCalendarLayersForEvents";
import { ToggleCalendarsLayersForDiffs } from "../../../chat/ai-chat/components/multi-calendar/ToggleCalendarsLayersForDiffs";

export const MultiCalendarLayers = ({
  diffId,
  calendarId,
  externalEventId,
}: {
  diffId?: string;
  calendarId?: string;
  externalEventId?: string;
}) => {
  return (
    <div>
      {diffId && (
        <div className="cw-ml-auto">
          <ToggleCalendarsLayersForDiffs
            startingIsToggledOn={false}
            diffId={diffId}
            focusedToggle
          />
        </div>
      )}
      {externalEventId && calendarId && !diffId && (
        <div className="cw-ml-auto">
          <ToggleCalendarsLayersForEvents
            startingIsToggledOn={false}
            externalEventId={externalEventId}
            calendarId={calendarId}
            focusedToggle
          />
        </div>
      )}
    </div>
  );
};
