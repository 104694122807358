import { useQuery } from "@apollo/client";
import { OrgTimezoneDocument } from "../../graphql/__generated__/OrgTimezone.generated";
import { getCurrentOrg } from "../../util/org.util";

const useOrgTimeZone = () => {
  const { data, loading, error } = useQuery(OrgTimezoneDocument);

  const org = getCurrentOrg(data?.viewer);
  const orgTimeZone = org?.orgMeetingHours?.workingHours?.timeZone ?? null;

  return { orgTimeZone, loading, error };
};

export default useOrgTimeZone;
