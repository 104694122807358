import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { SvgIconComponent } from "@clockwise/icons";
import classNames from "classnames";
import { Interval } from "luxon";
import React, { PropsWithChildren, useCallback } from "react";
import { useBoolean } from "usehooks-ts";
import { ProposalRenderSurface } from "../chat/constants";
import { EventInfoWrapper } from "./EventInfo";
import { CardHeader } from "./atoms/CardHeader";
import { EventCardAttendee } from "./types";

type HeaderProps = {
  text: string;
  hasSchedulingOptions: boolean;
};

const MainContent = ({ children, text, hasSchedulingOptions }: PropsWithChildren<HeaderProps>) => {
  return (
    <div
      tabIndex={0}
      aria-label={text}
      className={classNames(
        "cw-py-2 cw-px-3 cw-border-none",
        "cw-body-sm cw-font-medium group-focus:cw-text-positive",
        "cw-w-full",
        "cw-flex cw-flex-col",
        {
          "cw-gap-1": hasSchedulingOptions,
        },
      )}
    >
      {children}
    </div>
  );
};

export type SubDrawerContent = { muted: boolean; content: React.ReactNode }[] | null;
const SubSections = ({ subDrawerContent }: { subDrawerContent: NonNullable<SubDrawerContent> }) => {
  return (
    <div>
      {subDrawerContent.map(({ muted, content }, index) => {
        if (!content) return null;
        return (
          <div
            key={index}
            className={classNames(
              `cw-w-full cw-border-t cw-border-solid cw-border-muted
            cw-py-1 cw-px-3
            cw-caption
            cw-flex-col cw-space-y-1
            `,
              {
                "cw-bg-neutral": muted,
                "cw-bg-default": !muted,
              },
            )}
          >
            {content}
          </div>
        );
      })}
    </div>
  );
};

type EventCardCollapsedProps = {
  attendeePeople?: EventCardAttendee[];
  disabled?: boolean;
  eventName: string;
  interval?: Interval;
  isLayersActive?: boolean;
  isLayersDisabled?: boolean;
  isRemoveCancel?: boolean;
  lineThrough?: boolean;
  onExpandCard?: () => void;
  onRemoveCard?: () => void;
  onToggleLayers?: () => void;
  recurrenceRule?: RecurrenceRule | null;
  schedulingOptions?: React.ReactNode;
  subDrawerContent?: SubDrawerContent;
  subText: string | null;
  subTextIcon?: SvgIconComponent;
  toggleLayersTooltip?: string;
  isAddDiff?: boolean;
  isFlexible?: boolean;
  variant?: ProposalRenderSurface;
  customSeeMoreIcon?: SvgIconComponent;
  diffId?: string;
  onMouseHover?: (hover: boolean) => void;
};

export const EventCardCollapsed = ({
  attendeePeople,
  disabled = false,
  eventName,
  interval,
  isLayersActive = false,
  isLayersDisabled = false,
  isRemoveCancel = false,
  lineThrough = false,
  onExpandCard,
  onRemoveCard,
  onToggleLayers,
  recurrenceRule,
  schedulingOptions,
  subDrawerContent,
  subText,
  subTextIcon,
  toggleLayersTooltip,
  isAddDiff = false,
  isFlexible,
  variant = "AI_CHAT",
  customSeeMoreIcon,
  diffId,
  onMouseHover,
}: EventCardCollapsedProps) => {
  const hasEventInfo = !!attendeePeople || !!subText || recurrenceRule !== undefined;
  const { value: hasFocus, setTrue: setFocused, setFalse: setUnfocused } = useBoolean();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const onCardMouseEnter = () => {
    setFocused();
    onMouseHover?.(true);
  };

  const onCardMouseLeave = () => {
    delayedSetUnfocused();
    onMouseHover?.(false);
  };

  const hasSchedulingOptions = Boolean(schedulingOptions);

  // prevent ui flicker on tabbing
  const delayedSetUnfocused = useCallback(() => {
    setTimeout(() => {
      const focusWithin = containerRef.current?.contains(document.activeElement);
      if (!focusWithin) {
        setUnfocused();
      }
    }, 10);
  }, [setUnfocused]);

  return (
    <div
      cw-id="event-detail-card-collapsed"
      ref={containerRef}
      onFocus={setFocused}
      onMouseEnter={onCardMouseEnter}
      onBlur={delayedSetUnfocused}
      onMouseLeave={onCardMouseLeave}
      className={classNames(
        `cw-w-full cw-rounded-xl 
        cw-border cw-border-solid cw-border-muted
        cw-group focus:cw-border-brand-emphasis-pressed
        cw-body-base
        cw-overflow-hidden
        cw-bg-default
      `,
        {
          "cw-flex-col": !!subDrawerContent,
          "hover:cw-border-brand-emphasis": !disabled,
          "cw-drop-shadow-sm": hasSchedulingOptions,
        },
      )}
    >
      <SchedulingOptionsContainer>{schedulingOptions}</SchedulingOptionsContainer>
      <MainContent text={eventName} hasSchedulingOptions={hasSchedulingOptions}>
        <CardHeader
          active={hasFocus}
          disabled={disabled}
          eventName={eventName}
          isLayersActive={isLayersActive}
          isLayersDisabled={isLayersDisabled}
          isRemoveCancel={isRemoveCancel}
          lineThrough={lineThrough}
          onExpandCard={onExpandCard}
          onRemoveCard={onRemoveCard}
          onToggleLayers={onToggleLayers}
          toggleLayersTooltip={toggleLayersTooltip}
          variant={variant}
          customSeeMoreIcon={customSeeMoreIcon}
        />
        {hasEventInfo && (
          <EventInfoWrapper
            attendeePeople={attendeePeople}
            interval={interval}
            subText={subText}
            subTextIcon={subTextIcon}
            recurrenceRule={recurrenceRule}
            isAddDiff={isAddDiff}
            isFlexible={isFlexible}
            diffId={diffId}
          />
        )}
      </MainContent>
      {subDrawerContent && <SubSections subDrawerContent={subDrawerContent} />}
    </div>
  );
};

const SchedulingOptionsContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <div
      className="cw-border-b cw-border-solid cw-border-b-muted cw-py-2 cw-px-3"
      cw-id="time-selector"
    >
      {children}
    </div>
  );
};
