import { ResponseStatusEnum } from "@clockwise/schema";
import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar/";
import { ICalPositionable } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import { DateTime, Duration } from "luxon";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../state/reducers/root.reducer";
import { useCurrentProposal } from "../../chat-plus-calendar/CurrentProposalContext";
import { usePlannerContext } from "../Context";
import { hasIntervalAllDayLong } from "../ooo-events/utils/hasIntervalAllDayLong";
import { PlannerEventCard } from "../types";
import { hasCategoryOutOfOffice } from "../util/hasCategoryOutOfOffice";
import { PlannerEvent } from "./PlannerEvent";

type PlannerEventsProps = {
  dateTimes: DateTime[];
};

export const MIN_DURATION = Duration.fromObject({ minutes: 15 });

export const PlannerEvents = ({ dateTimes }: PlannerEventsProps) => {
  const {
    hideDeclined,
    normalEvents: normalEventsNullable,
    workingLocationsByDay: workingLocationByDayNullable,
    multiCalendarIds,
  } = usePlannerContext();
  const { currentProposal } = useCurrentProposal();

  const highlightedSlotOnPlanner = useSelector(
    (state: IReduxState) => state.plannerHighlights.slot,
  );

  const eventIdsToFade = useMemo(
    () => (highlightedSlotOnPlanner?.additionalChanges || []).map((ac) => ac.event.externalEventId),
    [highlightedSlotOnPlanner?.additionalChanges],
  );

  const isHiddenBecauseDeclined = useMemo(() => {
    return getIsHiddenBecauseDeclined(hideDeclined, multiCalendarIds);
  }, [hideDeclined, multiCalendarIds]);

  const renderEvents = useMemo(
    () =>
      Object.values(normalEventsNullable ?? {})
        .flatMap((events) => events)
        .filter((event) => !hasCategoryOutOfOffice(event) || !hasIntervalAllDayLong(event))
        .filter((event) => !isHiddenBecauseDeclined(event))
        .filter(
          (event) =>
            !currentProposal.initialized || event.externalEventId !== currentProposal.eventId,
        ),
    [
      isHiddenBecauseDeclined,
      normalEventsNullable,
      currentProposal.initialized,
      currentProposal.eventId,
    ],
  );

  const renderWorkingLocations = useMemo(
    () =>
      Object.values(workingLocationByDayNullable ?? {})
        .flatMap((events) => events)
        .filter((event) => !hasIntervalAllDayLong(event) && !event.isAllDay),
    [workingLocationByDayNullable],
  );

  const positionables: ICalPositionable[] = useMemo(
    () =>
      [...renderEvents, ...renderWorkingLocations].map((evnt) => ({
        // Note that we must use `positioner?.keyAddendum` for Schedule by Moving `Before` and `After` views
        // because `positioner.key`s alone are not unique, which leads to UI bugs
        // TODO (jimmy): let's fix `key` Linear AI-1422 it should be unique
        // and we separate the conflation by forcing downstream consumers to use `externalEventId` instead of `key`
        key: `${evnt.key}${evnt.keyAddendum ?? ""}`,
        interval: evnt.interval,
        render: ({ position, columnIndex }) => {
          return (
            <PlannerEvent
              key={evnt.key}
              card={evnt}
              fadedWithAWandLabel={eventIdsToFade.includes(evnt?.externalEventId || "")}
              leftPad={hasCategoryOutOfOffice(evnt) && position.renderOrder > 0}
              position={position}
              columnIndex={columnIndex}
            />
          );
        },
      })),
    [eventIdsToFade, renderEvents, renderWorkingLocations],
  );

  return (
    <CalendarPositioner
      dateTimes={dateTimes}
      conflictResolution="columnOverlap"
      gutters
      minDuration={MIN_DURATION}
      positionables={positionables}
    />
  );
};

export const getIsHiddenBecauseDeclined = (
  hideDeclined = false,
  multiCalendarIds: string[] | null = [],
) => {
  return (event: PlannerEventCard) => {
    const isDeclinedByUser = event.responseState === ResponseStatusEnum.Declined;
    const eventHasNonDeclinedAttendeeDisplayedInMultiCal = event.attendees.some(
      (attendee) =>
        multiCalendarIds?.includes(attendee.email) &&
        attendee.responseStatus !== ResponseStatusEnum.Declined,
    );
    // always show declined events if at least one attendee on the multical is not declined
    if (eventHasNonDeclinedAttendeeDisplayedInMultiCal) {
      return false;
    }
    return hideDeclined && isDeclinedByUser;
  };
};
