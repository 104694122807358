import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import React from "react";
import { useReadCalendarColor } from "../../hooks/CalendarColorsContext";

export const Footer = ({
  canRSVP,
  showEventOwner,
  ownerDisplayName,
  loading,
  rsvpButtons,
  calendarId,
}: {
  canRSVP: boolean;
  showEventOwner: boolean;
  ownerDisplayName: string;
  loading: boolean;
  rsvpButtons?: React.ReactNode;
  calendarId: string;
}) => {
  const calendarColor = useReadCalendarColor(calendarId);
  return (
    <div
      className={classNames(
        "cw-flex cw-rounded-b-xl cw-p-2 cw-bg-neutral cw-w-full cw-border-t-[0.5px] cw-border-solid cw-border-subtle",
        {
          "cw-justify-between": canRSVP,
          "cw-justify-start": !canRSVP,
        },
      )}
    >
      {rsvpButtons || null}
      {showEventOwner &&
        !canRSVP &&
        (loading ? (
          <Skeleton variant="rect" width={150} height={18} />
        ) : (
          <div className="cw-flex cw-items-center cw-gap-x-1 cw-text-muted cw-body-small cw-text-12 cw-overflow-hidden cw-whitespace-nowrap cw-w-full">
            <div>Owner:</div>
            {calendarColor?.bg_Emphasis && (
              <div
                className="cw-w-4 cw-h-3 cw-rounded"
                style={{
                  backgroundColor: calendarColor.bg_Emphasis,
                }}
              />
            )}
            <div className="cw-font-medium cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis cw-w-full">
              {ownerDisplayName}
            </div>
          </div>
        ))}
    </div>
  );
};
