// schema
import * as ISchema from "#webapp/src/__schema__";

import { daysOfWeek, daysOfWeekMondayStart } from "#webapp/src/constants/date.constant";
import { logger } from "#webapp/src/util/logger.util";
import { minutesInRange } from "#webapp/src/util/time-slot.util";
import { WorkingHours } from "@clockwise/schema";

type TimeSlot = {
  hour: number;
  minute: number;
  minutesIntoDay: number;
};

// checks if the user has a single set of wokring hours for the whole week or not
export function workingHoursVaryDaily(workingHours: WorkingHours) {
  if (!workingHours || !workingHours.daySettings) {
    // arguably, we're in a bad state here if we don't have working hours
    // but seems like a sane default
    logger.error("working hours fragment incomplete");
    return true;
  }

  const weekdays = daysOfWeekMondayStart.filter((dow) => dow !== "Saturday" && dow !== "Sunday");
  const weekend = daysOfWeekMondayStart.filter((dow) => dow === "Saturday" || dow === "Sunday");

  const weekendSettingsOff = weekend.every((day) => {
    const daySetting = workingHours.daySettings?.find((ds) => ds?.day === day);
    return !daySetting;
  });

  const mondaySetting = workingHours.daySettings.find((ds) => ds?.day === "Monday");

  const weekdaySettingsSame = weekdays.every((day) => {
    const daySetting = workingHours.daySettings?.find((ds) => ds?.day === day);

    if (!mondaySetting && !daySetting) {
      return true;
    } else if (!mondaySetting || !daySetting) {
      return false;
    }

    const sameMin = mondaySetting.setting?.minSlot === daySetting.setting?.minSlot;
    const sameMax = mondaySetting.setting?.maxSlot === daySetting.setting?.maxSlot;
    return sameMin && sameMax;
  });

  // return false if weekend settings are not "OFF"
  // or if monday-friday settings are not the same
  return !weekendSettingsOff || !weekdaySettingsSame;
}

export function meetingAndWorkingHoursAreDifferent(
  meetingHours: WorkingHours,
  workingHours: WorkingHours,
) {
  if (!workingHours || !workingHours.daySettings || !meetingHours || !meetingHours.daySettings) {
    // arguably, we're in a bad state here if we don't have working hours
    // but seems like a sane default
    logger.error("working and meeting hours fragment incomplete");
    return true;
  }

  return daysOfWeek.some((day) => {
    const meetingDay = meetingHours.daySettings?.find((ds) => ds?.day === day);
    const workingDay = workingHours.daySettings?.find((ds) => ds?.day === day);

    if (!meetingDay && !workingDay) {
      return false;
    } else if (!meetingDay || !workingDay) {
      return true;
    }

    const notSameMin = workingDay.setting?.minSlot !== meetingDay.setting?.minSlot;
    const notSameMax = workingDay.setting?.maxSlot !== meetingDay.setting?.maxSlot;
    return notSameMin || notSameMax;
  });
}

export function getMeetingHoursOutsideWorkingHours(
  meetingHours: WorkingHours,
  workingHours: WorkingHours,
  isMeetingAndWorkingHoursSame: boolean,
) {
  if (!workingHours || !workingHours.daySettings || !meetingHours || !meetingHours.daySettings) {
    // arguably, we're in a bad state here if we don't have working hours
    // but seems like a sane default
    logger.error("working and meeting hours fragment incomplete");
    return [];
  }

  if (isMeetingAndWorkingHoursSame) {
    return [];
  }

  return daysOfWeek.filter((day) => {
    const meetingDay = meetingHours.daySettings?.find((ds) => ds?.day === day);
    const workingDay = workingHours.daySettings?.find((ds) => ds?.day === day);

    if (!meetingDay && !workingDay) {
      return false;
    } else if (!meetingDay || !workingDay) {
      // if meetingDay exists, workingDay must also exist because meetingHours are a subset of workingHours
      return !workingDay;
    }

    const isMeetingMinBeforeWorkingMin =
      (meetingDay.setting?.minSlot ?? 0) < (workingDay.setting?.minSlot ?? 0);
    const isMeetingMaxAfterWorkingMax =
      (meetingDay.setting?.maxSlot ?? 0) > (workingDay.setting?.maxSlot ?? 0);
    return isMeetingMinBeforeWorkingMin || isMeetingMaxAfterWorkingMax;
  });
}

/**
 * Determines if supplied working hours sets meets invalid conditions, such as start time > end time.
 *
 * @param workingHours existing working hours
 */
export function getInvalidWorkingHours(workingHours: ISchema.IWorkingHours) {
  if (!workingHours || !workingHours.daySettings) {
    // arguably, we're in a bad state here if we don't have working hours
    // but seems like a sane default
    logger.error("working hours fragment incomplete");
    return [];
  }

  return daysOfWeek.filter((day) => {
    const daySetting = workingHours.daySettings.find((ds) => ds.day === day);

    if (!daySetting) {
      return false;
    }

    return (
      Object.keys(ISchema.MinSlot).indexOf(daySetting.setting.minSlot) >
      Object.keys(ISchema.MaxSlot).indexOf(daySetting.setting.maxSlot)
    );
  });
}

/**
 * Mutates and augments existing working hours to assist components that allow a user to modify these settings.
 *
 * @param workingHours existing working hours
 * @param day day to modify
 * @param minOrMaxSlot whether to modify the min or max slot
 * @param newValue the new slot value
 * @param defaultMinSlot default min slot if we are adding a new day
 * @param defaultMaxSlot default max slot if we are adding a new day
 */
export function modifyWorkingHours(
  workingHours: ISchema.IWorkingHours,
  day: ISchema.Day,
  minOrMaxSlot: "minSlot" | "maxSlot",
  newValue: TimeSlot | false,
  defaultMinSlot = ISchema.MinSlot.T_09_00,
  defaultMaxSlot = ISchema.MaxSlot.T_17_00,
) {
  const dayIndex = workingHours.daySettings.findIndex((daySetting) => daySetting.day === day);

  if (newValue === false) {
    if (dayIndex !== -1) {
      workingHours.daySettings.splice(dayIndex, 1);
    }
  } else if (minOrMaxSlot === "minSlot") {
    if (dayIndex !== -1) {
      workingHours.daySettings[dayIndex].setting.minSlot = (newValue as any) as ISchema.MinSlot;
    } else {
      workingHours.daySettings.push({
        day,
        __typename: "DaySetting",
        setting: {
          __typename: "WorkingSetting",
          minSlot: (newValue as any) as ISchema.MinSlot,
          maxSlot: defaultMaxSlot,
          excludedSlots: [],
        },
      });
    }
  } else {
    if (dayIndex !== -1) {
      workingHours.daySettings[dayIndex].setting.maxSlot = (newValue as any) as ISchema.MaxSlot;
    } else {
      workingHours.daySettings.push({
        day,
        __typename: "DaySetting",
        setting: {
          __typename: "WorkingSetting",
          minSlot: defaultMinSlot,
          maxSlot: (newValue as any) as ISchema.MaxSlot,
          excludedSlots: [],
        },
      });
    }
  }
}

export function workingHoursAsInput(
  workingHours: ISchema.IWorkingHours,
): ISchema.IWorkingHoursInput {
  const { __typename: _u, ...workingHoursWithoutTypename } = workingHours;

  const daySettings =
    workingHoursWithoutTypename.daySettings &&
    workingHoursWithoutTypename.daySettings.map(({ __typename, ...daySetting }) => {
      const { __typename: _, ...setting } = daySetting.setting;

      return { ...daySetting, setting };
    });

  const dayOverrides =
    workingHoursWithoutTypename.dayOverrides &&
    workingHoursWithoutTypename.dayOverrides.map(({ __typename, ...dayOverride }) => dayOverride);

  return {
    daySettings,
    dayOverrides,
    id: workingHoursWithoutTypename.id,
    timeZone: workingHoursWithoutTypename.timeZone,
    type: workingHoursWithoutTypename.type,
  };
}

/**
 * Simple method that parses a set of day settings and counts up the total minutes available.
 *
 * @param daySettings IDaySetting set
 */
export function getDaySettingsTotalMinutes(daySettings: ISchema.IDaySetting[]) {
  return daySettings.reduce(
    (prev, curr) => prev + minutesInRange(curr.setting.minSlot, curr.setting.maxSlot),
    0,
  );
}
