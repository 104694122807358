import { Link } from "@clockwise/design-system";
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutStripe,
} from "@clockwise/web-commons/src/ui/page-layout/PageLayout";
import { useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import AccountPreferences from "../components/account-preferences/AccountPreferences";
import { FlexibleMeetingsBanner } from "../components/flexible-meetings-banner";
import { FlexibleMeetingsCallout } from "../components/flexible-meetings-callout";
import { useUnvisitedPageFlags } from "../hooks/useUnvisitedPageFlags";
import { PageEvents, TrackingEvents, page } from "../util/analytics.util";

const AccountPreferencesPage = () => {
  const track = useTracking();
  const {
    setHasVisitedIdealDayPage,
    shouldShowUnvisitedBadges,
    hasVisitedIdealDayPage,
  } = useUnvisitedPageFlags();

  useEffect(() => {
    page(PageEvents.ACCOUNT_PREFERENCES_PAGE);
  }, []);

  useEffect(() => {
    if (!hasVisitedIdealDayPage && shouldShowUnvisitedBadges) {
      setHasVisitedIdealDayPage();
      track(TrackingEvents.NUX_2025.UNVISITED_LINKS.IDEAL_DAY_VISITED);
    }
  }, [hasVisitedIdealDayPage, shouldShowUnvisitedBadges]);

  return (
    <>
      <Helmet>
        <title>Your ideal day · Clockwise</title>
      </Helmet>

      <PageLayout
        title="What's in your ideal day?"
        callout={<FlexibleMeetingsCallout />}
        description={
          <>
            Clockwise moves the events you mark as flexible to help you have the best possible day —
            whatever that means to you. The more preferences you set, the more we can help!{" "}
            <Link href="https://getclockwise.helpscoutdocs.com/article/80-accessing-clockwise">
              Learn more
            </Link>
          </>
        }
      >
        <PageLayoutContent>
          <AccountPreferences />
        </PageLayoutContent>
        <PageLayoutStripe sentiment="info">
          <PageLayoutContent>
            <FlexibleMeetingsBanner />
          </PageLayoutContent>
        </PageLayoutStripe>
      </PageLayout>
    </>
  );
};

export default AccountPreferencesPage;
