import { ConflictAction, ConflictEvent } from "@clockwise/schema";

import { useMutation, useQuery } from "@apollo/client";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { generateRescheduleCQL } from "@clockwise/web-commons/src/util/generateCQL";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime, Interval } from "luxon";
import toast from "react-hot-toast";
import { getCurrentOrg } from "../../../../util/org.util";
import { useSetAIError } from "../../../chat/ai-chat/hooks/AIErrorContext";
import { useProcessMessage } from "../../../chat/ai-chat/hooks/useProcessMessage";
import { RescheduleSuggestionsDocument } from "./__generated__/RescheduleSuggestions.generated";
import { ResolveConflictDocument } from "./__generated__/ResolveConflict.generated";

export const useConflictResolver = (
  event: ConflictEvent,
  _calendarId: string,
  onClose: () => void,
  clusterId: string,
  conversationId: string,
) => {
  const track = useTracking();
  const { processMessage } = useProcessMessage();
  const [resolveConflict, { loading: updatingEvent }] = useMutation(ResolveConflictDocument);
  const timeZone = getRenderTimeZone();
  const canUserReschedule = event.availableActions.includes(ConflictAction.RESCHEDULE);

  const { data, loading } = useQuery(RescheduleSuggestionsDocument, {
    variables: {
      externalEventId: event.eventId,
      clusterId: clusterId,
    },
    skip: !event.eventId || !canUserReschedule || !clusterId,
  });

  const setError = useSetAIError();

  const onSelectTime = (newTime: DateTime) => {
    track(TrackingEvents.CONFLICT_RESOLUTION.SUGGESTION_RESCHEDULE, {
      clusterId: clusterId,
      eventId: event.eventId,
      conversationId: conversationId,
    });
    void resolveConflict({
      variables: {
        input: {
          actionToResolve: ConflictAction.RESCHEDULE,
          newStartTime: newTime,
          eventId: event.eventId,
          clusterId: clusterId,
        },
      },
      onCompleted: () => {
        toast(`Rescheduling ${event.title} to ${newTime.toFormat("h:mma")}`);
        onClose();
      },
      onError: (error) => {
        setError({
          showUserMessage: true,
          message: `Error resolving conflict, failed to reschedule to ${newTime.toFormat("h:mma")}`,
          error: error,
          userMessage: `Error rescheduling ${event.title} to ${newTime.toFormat("h:mma")}`,
        });
      },
    });
  };

  const onDelete = () => {
    track(TrackingEvents.CONFLICT_RESOLUTION.DELETE_EVENT, {
      clusterId: clusterId,
      eventId: event.eventId,
      conversationId: conversationId,
    });
    void resolveConflict({
      variables: {
        input: {
          actionToResolve: ConflictAction.CANCEL,
          eventId: event.eventId,
          clusterId,
        },
      },
      onCompleted: () => {
        toast(`Deleted ${event.title}`);
        onClose();
      },
      onError: (error) => {
        setError({
          showUserMessage: true,
          message: `Error resolving conflict, failed to delete`,
          error: error,
          userMessage: `Error deleting ${event.title}`,
        });
      },
    });
  };

  const onRSVP = (rsvpResponse: ConflictAction.RSVP_MAYBE | ConflictAction.DECLINE) => {
    const trackingEvent =
      rsvpResponse === ConflictAction.RSVP_MAYBE
        ? TrackingEvents.CONFLICT_RESOLUTION.RSVP_MAYBE
        : TrackingEvents.CONFLICT_RESOLUTION.RSVP_DECLINED;

    track(trackingEvent, {
      clusterId: clusterId,
      eventId: event.eventId,
      conversationId: conversationId,
    });
    const responseMessage = rsvpResponse === ConflictAction.RSVP_MAYBE ? "Maybe" : "Not going";
    void resolveConflict({
      variables: {
        input: {
          actionToResolve: rsvpResponse,
          eventId: event.eventId,
          clusterId,
        },
      },
      onCompleted: () => {
        toast(`RSVPing "${responseMessage}" for ${event.title}`);
        onClose();
      },
      onError: (error) => {
        setError({
          showUserMessage: true,
          message: `Error resolving conflict, failed to RSVP`,
          error: error,
          userMessage: `Error RSVPing "${responseMessage}" for ${event.title}`,
        });
      },
    });
  };

  const onRescheduleWithChat = () => {
    track(TrackingEvents.CONFLICT_RESOLUTION.RESCHEDULE_WITH_CHAT, {
      clusterId: clusterId,
      eventId: event.eventId,
      conversationId: conversationId,
    });
    const cql = event.eventId ? generateRescheduleCQL(event.eventId) : undefined;

    void processMessage(
      `Reschedule ${event.title}`,
      {
        eventMentions: [],
        personMentions: [],
      },
      cql,
    );
    onClose();
  };

  const suggestedTimes =
    getCurrentOrg(data?.viewer)
      ?.findSchedulingSuggestionsV2.map((suggestion) => {
        return Interval.fromISO(suggestion.time, { zone: timeZone }).start;
      })
      .sort((a, b) => a.toMillis() - b.toMillis())
      .slice(0, 5) || [];

  return {
    loading,
    updatingEvent,
    suggestedTimes,
    onSelectTime,
    onRescheduleWithChat,
    onDelete,
    onRSVP,
  };
};
