import { PersonFilled } from "@clockwise/icons";
import {
  EventContainer,
  NamedEvent,
} from "@clockwise/web-commons/src/components/feature-illustration";
import React from "react";
import { TeamSettingsAdminTeamCalendarWrapper } from "../../../components/teams-settings/team-settings-admin-form/team-settings-admin-team-calendar";
import { TeamSettingsAdminSlack } from "../../teams-settings/team-settings-admin-form/team-settings-admin-slack";
import { TeamSections } from "../web-settings";
import { TeamSettingsSection } from "./TeamSettingsSection";

const TeamAvailabilityIllustration = () => {
  return (
    <EventContainer>
      <NamedEvent duration={15} Icon={PersonFilled} title={"Linda OOO"} />
      <NamedEvent duration={15} Icon={PersonFilled} title={"Andy OOO"} />
      <NamedEvent duration={15} Icon={PersonFilled} title={"Tatiana OOO"} />
    </EventContainer>
  );
};

type Props = {
  teamId: string;
};

export const TeamAvailabilityCalendar = ({ teamId }: Props) => {
  return (
    <TeamSettingsSection graphic={<TeamAvailabilityIllustration />}>
      <div cw-id={`web-settings-${TeamSections.TeamAvailabilityCalendar}`} id="team-calendar">
        <TeamSettingsAdminTeamCalendarWrapper teamId={teamId} />
        <TeamSettingsAdminSlack teamId={teamId} />
      </div>
    </TeamSettingsSection>
  );
};
