import { Day } from "@clockwise/schema";
import { DayEnum } from "@clockwise/schema/v2";
import { noop } from "lodash";
import React, { useMemo } from "react";
import {
  DayOnOffMap,
  MeetingOrWorkingHours,
  ORDERED_WEEKDAYS,
  SlotRangeWrapper,
  SlotRangesMap,
  WeekSetting,
  validateSlotChange,
} from "../../working-hours";
import { DaySetting, MeetingHours, TimeRange } from "./types";
import { convertSlotToTime, convertTimeToSlot } from "./utils";

const DEFAULT_TIME_RANGE: TimeRange = { startTime: "09:00", endTime: "17:00" };

interface Props {
  meetingHours: MeetingHours;
  onChange: (updatedMeetingHours: MeetingHours, isValid: boolean) => void;
}

export const CustomWeeklyHoursEditor = ({ meetingHours, onChange }: Props) => {
  const dayOnOffMap = useMemo(() => {
    const map: DayOnOffMap = {};
    // Initialize all days to off
    ORDERED_WEEKDAYS.forEach((day) => (map[day] = false));
    // Set days to on if they have at least one time range
    meetingHours.schedule.forEach(({ day, timeRanges }) => (map[day] = timeRanges.length > 0));
    return map;
  }, [meetingHours.schedule]);

  const slotRangesMap = useMemo(() => {
    const map: SlotRangesMap = {};
    meetingHours.schedule.forEach(({ day, timeRanges }) => {
      const slots = timeRanges.map(({ startTime, endTime }) => ({
        startSlot: convertTimeToSlot(startTime, false),
        endSlot: convertTimeToSlot(endTime, true),
        startError: false,
        endError: false,
      }));

      map[day] = validateSlotChange(slots);
    });
    return map;
  }, [meetingHours.schedule]);

  const handleSlotChange = (day: Day, updatedSlots: SlotRangeWrapper) => {
    const daySetting: DaySetting = {
      day: (day as string) as DayEnum,
      timeRanges: updatedSlots.slots.map(({ startSlot, endSlot }) => ({
        startTime: convertSlotToTime(startSlot, false),
        endTime: convertSlotToTime(endSlot, true),
      })),
    };

    const isValid = Object.values({ ...slotRangesMap, [day]: updatedSlots }).every(
      (slot) => slot.isValid,
    );

    onChange(
      {
        ...meetingHours,
        schedule: meetingHours.schedule
          // First, filter out the old day setting
          .filter((setting) => setting.day !== daySetting.day)
          // Then add the new day setting
          .concat(daySetting),
      },
      isValid,
    );
  };

  const handleToggleDay = (day: Day) => {
    const isOn = !dayOnOffMap[day];

    let updatedSchedule = meetingHours.schedule.filter(
      (setting) => setting.day !== (day as string),
    );

    if (isOn) {
      updatedSchedule = updatedSchedule.concat({
        day: (day as string) as DayEnum,
        timeRanges: [DEFAULT_TIME_RANGE],
      });
    }

    const isValid = Object.values({ ...slotRangesMap, [day]: { isValid: true } }).every(
      (slot) => slot.isValid,
    );

    onChange({ ...meetingHours, schedule: updatedSchedule }, isValid);
  };

  return (
    <WeekSetting
      slotRangesMap={slotRangesMap}
      currentDayOnOffMap={dayOnOffMap}
      onToggleDay={handleToggleDay}
      onSlotChange={handleSlotChange}
      // Always true since we support empty weekly hours
      isValidDayMap
      // Don't show copy button
      copyButtonStyle={null}
      onClickCopy={noop}
      // Used for determining hint text and other user-facing messages
      isMeetingOrWorkingHours={MeetingOrWorkingHours.MeetingHours}
    />
  );
};
