import { FlexStatus } from "@clockwise/schema/v2";
import { FlexibleStatusSimplified } from "../components/module-flex-settings/types";

export const getSimplifiedStatus = (
  status: FlexStatus,
  attendeeConflict: boolean,
): FlexibleStatusSimplified => {
  if (attendeeConflict) {
    return "stuck";
  }

  let simplifiedStatus: FlexibleStatusSimplified = "default";
  switch (status) {
    case FlexStatus.CanMove:
    case FlexStatus.EventExceedsDefragRange:
    case FlexStatus.EventOutsideTimeRange:
    case FlexStatus.External:
    case FlexStatus.NoDuration:
    case FlexStatus.PrivateEvent:
    case FlexStatus.SmartHold:
      simplifiedStatus = "default";
      break;
    case FlexStatus.OverQuota:
    case FlexStatus.TrialExpired:
      simplifiedStatus = "paywall";
      break;
    case FlexStatus.Paused:
      simplifiedStatus = "paused";
      break;
    case FlexStatus.Pinned:
      simplifiedStatus = "pinned";
      break;
  }

  return simplifiedStatus;
};
