import { ALL_WORK_DAYS_SELECTED } from "#webapp/src/components/event-card/types";
import { useQuery } from "@apollo/client";
import { Skeleton, Switch, Tooltip } from "@clockwise/design-system";
import { DayOfWeek, FlexRange, FlexStatus } from "@clockwise/schema/v2";
import { FlexOutlineIcon } from "@clockwise/web-commons/src/components/module-flex-settings/atoms/FlexOutlineIcon";
import { useAttendeeWorkingBounds } from "@clockwise/web-commons/src/hooks/useAttendeeWorkingHours";
import { useEventFlexibility } from "@clockwise/web-commons/src/hooks/useEventFlexibility";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { DayOnOffMap } from "@clockwise/web-commons/src/ui/working-hours";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import {
  convertArrayOfDayWeekToDayMap,
  convertDayMapToFlexRangeAndAllowedDays,
} from "@clockwise/web-commons/src/util/dayMap";
import { getSimplifiedStatus } from "@clockwise/web-commons/src/util/getFlexibleStatusSimplifed";
import classNames from "classnames";
import React from "react";
import toast from "react-hot-toast";
import useEventDetails from "../../hooks/useEventDetails";
import { useUnpauseEvent } from "../../hooks/useUnpauseEvent";
import { EventFlexibilityHoverCard, EventFlexibilityListItem } from "./EventFlexibilityHoverCard";

export const EventFlexibility = ({
  eventId,
  calendarId,
  loadingEventContextMenu,
}: {
  eventId: string;
  calendarId: string;
  loadingEventContextMenu: boolean;
}) => {
  const track = useTracking();
  const {
    canMarkFlexible,
    dayEnd: flexibleEnd,
    dayStart: flexibleStart,
    daysAllowed,
    flexible,
    range: flexibleRange,
    status,
    update: updateFlexibility,
    updating: updatingFlexible,
    refetchFlexibility,
  } = useEventFlexibility({
    id: eventId ?? "",
    calendarId: calendarId ?? "",
    useQuery: useGatewayQuery,
    useMutation: useGatewayMutation,
  });

  const {
    eventDetails: { attendeePeople },
  } = useEventDetails(eventId, calendarId);

  const {
    start: attendeeBoundStart,
    end: attendeeBoundEnd,
    conflict: attendeeBoundConflict,
  } = useAttendeeWorkingBounds({
    eventId,
    calendarId,
    calendarIds: attendeePeople.map((person) => person.primaryEmail.toLowerCase()),
    useQuery: useQuery,
  });

  const { update: unpause, updating } = useUnpauseEvent(eventId);

  const flexibleStatus = getSimplifiedStatus(status, attendeeBoundConflict);
  const flexibleDayOnOffMap = convertArrayOfDayWeekToDayMap(
    daysAllowed,
    flexibleRange,
    ALL_WORK_DAYS_SELECTED,
  );

  const onUpdateFlexibilitySuccess = () => {
    toast.success("Your event flexibility preferences have been saved.");
  };

  const onUpdateFlexibilityFailure = () => {
    toast.error("Failed to save your event flexibility preferences.");
  };

  const handleChangeFlexible = (isFlexible: boolean) => {
    track(TrackingEvents.EVENT_POPOVER.CHANGE_FLEXIBLE_SETTINGS, {
      eventId,
      settings: "flexible",
    });

    updateFlexibility({
      isFlexible: isFlexible,
      flexRange: flexibleRange,
      allowedDays: daysAllowed,
      start: flexibleStart,
      end: flexibleEnd,
      onSuccess: onUpdateFlexibilitySuccess,
      onFailure: onUpdateFlexibilityFailure,
    });
  };

  const handleChangeFlexibleDays = (dayOnOffMap: DayOnOffMap) => {
    track(TrackingEvents.EVENT_POPOVER.CHANGE_FLEXIBLE_SETTINGS, {
      eventId,
      settings: "flexible days",
    });

    const { flexRange, allowedDays } = convertDayMapToFlexRangeAndAllowedDays(dayOnOffMap);

    updateFlexibility({
      isFlexible: flexible,
      flexRange,
      allowedDays,
      start: flexibleStart,
      end: flexibleEnd,
      onSuccess: onUpdateFlexibilitySuccess,
      onFailure: onUpdateFlexibilityFailure,
    });
  };

  const handleChangeFlexibleRange = (range: FlexRange) => {
    track(TrackingEvents.EVENT_POPOVER.CHANGE_FLEXIBLE_SETTINGS, {
      eventId,
      settings: "flexible range",
    });

    let newAllowedDays: DayOfWeek[] | undefined;
    switch (range) {
      case FlexRange.Day:
        newAllowedDays = undefined;
        break;
      case FlexRange.Week:
        newAllowedDays = undefined;
        break;
      case FlexRange.SpecificDays:
        newAllowedDays = daysAllowed;
        break;
    }

    updateFlexibility({
      isFlexible: flexible,
      flexRange: range,
      allowedDays: newAllowedDays,
      start: flexibleStart,
      end: flexibleEnd,
      onSuccess: onUpdateFlexibilitySuccess,
      onFailure: onUpdateFlexibilityFailure,
    });
  };

  const handleChangeFlexibleTimeRange = (start: string, end: string) => {
    track(TrackingEvents.EVENT_POPOVER.CHANGE_FLEXIBLE_SETTINGS, {
      eventId,
      settings: "flexible time range",
    });

    updateFlexibility({
      isFlexible: flexible,
      flexRange: flexibleRange,
      allowedDays: daysAllowed,
      start,
      end,
      onSuccess: onUpdateFlexibilitySuccess,
      onFailure: onUpdateFlexibilityFailure,
    });
  };

  const handleChangeFlexibleResume = () => {
    track(TrackingEvents.EVENT_POPOVER.CHANGE_FLEXIBLE_SETTINGS, {
      eventId,
      settings: "resume",
    });

    unpause({
      onCompleted: () => {
        refetchFlexibility();
      },
    });
  };

  if (loadingEventContextMenu || updating) {
    return (
      <div className="cw-flex cw-items-center cw-gap-2 cw-mr-auto cw-my-2 cw-pl-1.5">
        <FlexOutlineIcon className="cw-w-4 cw-h-4" />
        <Skeleton variant="rect" height={19.5} className="cw-flex-grow cw-rounded-md" />
      </div>
    );
  }

  switch (status) {
    case FlexStatus.External:
      return (
        <EventFlexibilityDisabled
          tooltip={<div>Meetings with external attendees can't be marked as flexible.</div>}
        />
      );
    case FlexStatus.TrialExpired:
      return (
        <EventFlexibilityDisabled
          tooltip={
            <div>
              Flexible meeting aren't supported on the Free plan. Upgrade to let your flexible
              meetings move again.
            </div>
          }
        />
      );
    case FlexStatus.CanMove:
    case FlexStatus.EventExceedsDefragRange:
    case FlexStatus.EventOutsideTimeRange:
    case FlexStatus.NoDuration:
    case FlexStatus.OverQuota:
    case FlexStatus.Paused:
    case FlexStatus.Pinned:
    case FlexStatus.PrivateEvent:
    case FlexStatus.SmartHold:
    default:
      return (
        <EventFlexibilityHoverCard
          flexible={flexible}
          flexibleDayOnOffMap={flexibleDayOnOffMap}
          flexibleEditable={canMarkFlexible}
          flexibleEnd={flexibleEnd}
          flexibleRange={flexibleRange}
          flexibleStart={flexibleStart}
          flexibleStatus={flexibleStatus}
          onChangeFlexible={handleChangeFlexible}
          onChangeFlexibleDays={handleChangeFlexibleDays}
          onChangeFlexibleRange={handleChangeFlexibleRange}
          onChangeFlexibleResume={handleChangeFlexibleResume}
          onChangeFlexibleTimeRange={handleChangeFlexibleTimeRange}
          updatingFlexible={updatingFlexible}
          attendeeBoundStart={attendeeBoundStart}
          attendeeBoundEnd={attendeeBoundEnd}
        >
          <EventFlexibilityListItem
            flexible={flexible}
            updatingFlexible={updatingFlexible}
            flexibleStatus={flexibleStatus}
            flexibleEditable={canMarkFlexible}
            onChangeFlexible={handleChangeFlexible}
          />
        </EventFlexibilityHoverCard>
      );
  }
};

const EventFlexibilityDisabled = ({ tooltip }: { tooltip: NonNullable<React.ReactNode> }) => {
  return (
    <div className={classNames("cw-font-normal", "cw-opacity-50", "cw-cursor-not-allowed")}>
      <Tooltip title={tooltip}>
        <div className="cw-flex cw-gap-2 cw-items-center cw-my-1 cw-py-1 cw-px-1.5">
          <FlexOutlineIcon className="cw-w-4 cw-h-4" />
          <div className="cw-flex cw-items-center cw-justify-between cw-w-full">
            <div className="cw-text-start">Flexible event</div>
            <Switch disabled checked={false} size="xs" />
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
