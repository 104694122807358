import { InfoFilled } from "@clockwise/icons";
import classNames from "classnames";
import React from "react";

export const ChangesNotifier = ({ displayText }: { displayText: React.ReactNode }) => {
  return (
    <div
      className={classNames(
        "cw-mx-3 cw-bg-busy cw-flex cw-gap-2 cw-py-2 cw-px-3 cw-rounded-md cw-text-busy-pressed  cw-relative cw-z-10",
      )}
    >
      <InfoFilled className="cw-w-4 cw-h-4 cw-mt-[2px]" />
      <div className="cw-body-sm  cw-text-busy-pressed">{displayText}</div>
    </div>
  );
};
