import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useGetOrgId } from "../../hooks/useGetOrgId";
import { PageEvents, page } from "../../util/analytics.util";
import { WebSettingsCreateJoinTeamRelay } from "../web-settings/web-settings-create-join-team";

const TeamCreateOrJoinPage = () => {
  const { org, loading } = useGetOrgId();

  useEffect(() => {
    page(PageEvents.TEAM_MEMBERSHIP_PAGE);
  }, []);

  if (loading || !org) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Team Membership · Clockwise</title>
      </Helmet>
      <WebSettingsCreateJoinTeamRelay orgRelayId={org?.id} shouldReloadAfterCreateOrJoin />
    </>
  );
};

export default TeamCreateOrJoinPage;
