import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import React, { useState } from "react";
import { IOrg } from "../../../../__schema__";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { TeamCreateSlide } from "./TeamCreateSlide";
import { OrgNode } from "./TeamJoinCreateStep";
import { TeamJoinSlide } from "./TeamJoinSlide";
import { TeamJoinedSlide } from "./TeamJoinedSlide";

export const TeamJoinCreateSlide = ({
  org,
  userId,
  shouldShowSecondaryButton,
  goForward,
  goBack,
}: {
  org: OrgNode;
  userId: string | undefined;
  shouldShowSecondaryButton: boolean;
  goForward: () => void;
  goBack: () => void;
}) => {
  const { funnelType } = useWebOnboardingSteps();
  const [forceCreateMode, setForceCreateMode] = useState(false);

  const allOrgTeams = getValue(org?.teamsForOrg)?.list || [];
  const personId = getValue(org?.orgPersonForUserErrorable)?.personId || "";
  const primaryTeam = getValue(org?.primaryTeam);

  if (!allOrgTeams.length || forceCreateMode) {
    return (
      <TeamCreateSlide
        orgHasOtherTeams={!!allOrgTeams.length}
        showSecondaryButton={shouldShowSecondaryButton}
        onClickPrimaryButton={goForward}
        onClickSecondaryButton={goBack}
        currentPersonId={personId}
        org={org as IOrg}
        funnelType={funnelType}
      />
    );
  } else if (primaryTeam && userId) {
    return (
      <TeamJoinedSlide
        showSecondaryButton={shouldShowSecondaryButton}
        onClickPrimaryButton={goForward}
        onClickSecondaryButton={goBack}
        joinedTeam={primaryTeam}
        userId={userId}
        funnelType={funnelType}
      />
    );
  } else {
    return (
      <TeamJoinSlide
        showSecondaryButton={shouldShowSecondaryButton}
        onClickPrimaryButton={goForward}
        onClickSecondaryButton={goBack}
        onGoToTeamCreate={() => {
          setForceCreateMode(true);
        }}
        currentPersonId={personId}
        orgId={org.id}
        funnelType={funnelType}
      />
    );
  }
};
