import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { isNil } from "lodash";
import { useCallback } from "react";
import {
  useReadProposalOptionsOverlay,
  useUpdateProposalOptionsOverlay,
} from "../../chat-plus-calendar/util/ProposalOptionsOverlayContext";
import { useActiveProposal } from "../../chat/hooks/useActiveProposal";

export const useProposalOptionsOverlayToggle = () => {
  const track = useTracking();
  const { proposal } = useActiveProposal();
  const isProposalOptionsOverlayVisible = useReadProposalOptionsOverlay();
  const setProposalOptionsOverlayVisibility = useUpdateProposalOptionsOverlay();
  const [isOnProposalSuggestionsInCalendar] = useFeatureFlag("ProposalSuggestionsInCalendar");

  // Not all proposals have options, e.g. bulk proposals
  const proposalHasOptions =
    proposal?.options.__typename === "SchedulingOptions" &&
    proposal.options.optionDetails.length > 0;

  const showOverlayToggle =
    isOnProposalSuggestionsInCalendar && !isNil(proposal) && proposalHasOptions;

  const isOverlayVisible = showOverlayToggle && isProposalOptionsOverlayVisible;

  const disableOverlay = useCallback(() => {
    setProposalOptionsOverlayVisibility(false);
  }, [setProposalOptionsOverlayVisibility]);

  const toggleOverlay = useCallback(() => {
    if (showOverlayToggle) {
      if (isProposalOptionsOverlayVisible) {
        track(TrackingEvents.PROPOSAL_OPTIONS_OVERLAY.TOGGLED_OFF);
      } else {
        track(TrackingEvents.PROPOSAL_OPTIONS_OVERLAY.TOGGLED_ON);
      }
      setProposalOptionsOverlayVisibility(!isProposalOptionsOverlayVisible);
    }
  }, [showOverlayToggle, isProposalOptionsOverlayVisible, setProposalOptionsOverlayVisibility]);

  return {
    isOverlayVisible,
    showOverlayToggle,
    toggleOverlay,
    disableOverlay,
  };
};
