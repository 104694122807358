import { useUserSmartHoldSettings } from "#webapp/src/hooks/useUserSmartHoldSettings/useUserSmartHoldSettings";
import { Divider } from "@clockwise/design-system";
import { SmartHold, SmartHoldState, SmartHoldType } from "@clockwise/schema/v2";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import React, { useEffect, useState } from "react";
import { ChangesNotifier } from "../chat/ai-chat/components/ChangesNotifier";
import { CardWrapper } from "./CardWrapper";
import { EventVisibility } from "./atoms/SelectEventVisibility";
import { Transparency } from "./atoms/SelectTransparency";
import { WarningMessage } from "./atoms/WarningMessage";
import { ECCalendarInfo } from "./molecules/ECCalendarInfo";
import { ECTitle } from "./molecules/ECTitle";
import { ECVisibilitySettings } from "./molecules/ECVisibilitySettings";
import { Time } from "./molecules/Time";
import { CalendarInfoDocument } from "./molecules/__generated__/CalendarInfo.v2.generated";
import { FlexAndProtection } from "./smart-holds/FlexAndProtection";
import { SettingsWrapper } from "./smart-holds/SettingsWrapper";
import { SlackDNDBrief } from "./smart-holds/SlackDNDBrief";
import { SmartHoldFooter } from "./smart-holds/SmartHoldFooter";
import { EventPermissionsInfo } from "./types";
import { EventCardWarning } from "./utils/getEventCardWarning";

const getManualRescheduleText = (smartHoldType: SmartHoldType) => {
  switch (smartHoldType) {
    case SmartHoldType.Lunch:
      return "lunch";
    case SmartHoldType.FocusTime:
      return "Focus Time";
    case SmartHoldType.MeetingRelief:
      return "break";
    default:
      return "";
  }
};

const isAHoldWithPausableState = (smartHoldType: SmartHoldType) => {
  return smartHoldType !== SmartHoldType.TravelTime;
};

const stateToEmoji = (state: SmartHoldState) => {
  switch (state) {
    case SmartHoldState.Paused:
      return "⏸️";
    case SmartHoldState.Unpaused:
      return "❇️";
    case SmartHoldState.Protected:
      return "🛡️";
  }
};

const typeToName = (type: SmartHoldType) => {
  switch (type) {
    case SmartHoldType.Lunch:
      return "Lunch";
    case SmartHoldType.FocusTime:
      return "Focus Time";
    case SmartHoldType.MeetingRelief:
      return "Break";
    case SmartHoldType.TravelTime:
      return "Travel Time";
  }
};

const modifyTitleForView = (name: string, state: SmartHoldState, type: SmartHoldType) => {
  let modifiedTitle = stateToEmoji(state) + " " + typeToName(type);
  if (name) {
    modifiedTitle = `${stateToEmoji(state)} ${name}'s ${typeToName(type)}`;
  }
  return modifiedTitle;
};

export const SmartHoldCard = ({
  smartHold,
  permissions,
  calId,
  transparency,
  visibility,
}: {
  smartHold: SmartHold;
  permissions: EventPermissionsInfo;
  calId: string;
  transparency?: Transparency;
  visibility?: EventVisibility;
}) => {
  const isOtherUsersEvent = !permissions.canRemove;

  const { data, loading: calIdLoading } = useGatewayQuery(CalendarInfoDocument, {
    variables: { id: calId },
    fetchPolicy: "cache-first",
    skip: !isOtherUsersEvent, // Skip if you are the user, this is just to get the display name for the title
  });
  const calendarName = calIdLoading ? "" : data?.calendar?.displayName.split(" ")[0] ?? "";

  const [timeHasChangeNotifier, setTimeHasChangeNotifier] = useState(false);
  const updateActiveEvent = useUpdateActiveEvent();
  const { settingsReadableValues } = useUserSmartHoldSettings();
  const [holdState, setHoldState] = useState(smartHold.state);

  useEffect(() => {
    setHoldState(smartHold.state);
  }, [smartHold.state]);

  const showBanner = isAHoldWithPausableState(smartHold.holdType) && timeHasChangeNotifier;
  const showFlexAndProtection = isAHoldWithPausableState(smartHold.holdType) && !isOtherUsersEvent;

  const onOptionChange = (value: SmartHoldState) => {
    setTimeHasChangeNotifier(false);
    setHoldState(value);
  };

  const changeFromUnpausedDueToTimeMove = () => {
    if (holdState === SmartHoldState.Unpaused) {
      setHoldState(SmartHoldState.Paused);
      setTimeHasChangeNotifier(true);
    }
  };

  return (
    <CardWrapper
      header={<div className="cw-font-semibold">Hold</div>}
      onClose={() => {
        updateActiveEvent(null);
      }}
      footer={
        isOtherUsersEvent ? null : (
          <SmartHoldFooter
            holdState={holdState}
            hasHoldStateChanged={holdState !== smartHold.state}
            calendarId={calId}
          />
        )
      }
    >
      {isOtherUsersEvent && <WarningMessage type={EventCardWarning.NotYourEvent} />}
      <ECTitle
        readOnly={true}
        eventName={modifyTitleForView(calendarName, holdState, smartHold.holdType)}
      />
      {showBanner && (
        <ChangesNotifier
          displayText={`This ${getManualRescheduleText(
            smartHold.holdType,
          )} hold was manually rescheduled and will remain fixed unless updated.`}
        />
      )}
      <Time readOnly={isOtherUsersEvent} updateFlexToggle={changeFromUnpausedDueToTimeMove} />
      {showFlexAndProtection && (
        <>
          <Divider spacing="xs" inset />
          <FlexAndProtection smartHoldState={holdState} onOptionChange={onOptionChange} />
        </>
      )}
      <Divider spacing="xs" inset />
      <ECCalendarInfo calendarId={calId} />
      {(transparency || visibility) && (
        <ECVisibilitySettings
          // Dev note: existing events display this info as readonly as per A-498
          // When rolling out the new edit event experience, make these editable
          readonly
          visibility={visibility}
          transparency={transparency}
        />
      )}
      <SlackDNDBrief />
      <SettingsWrapper
        type={smartHold.holdType}
        allSettings={settingsReadableValues}
        isOtherUsersEvent={isOtherUsersEvent}
        calendarName={calendarName}
        smartHoldState={holdState}
      />
    </CardWrapper>
  );
};
