import { Select, SelectOption } from "@clockwise/design-system";
import {
  FeatureSetting,
  FeatureSettingAside,
  FeatureSettingBody,
  FeatureSettingHeading,
  FeatureSettingMain,
  FeatureSettingsHoverCard,
} from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import { TrackingEvents, track } from "../../util/analytics.util";

import { fg_muted } from "@clockwise/design-system/tokens";
import { WebappSurface } from "@clockwise/schema";
import { Duration } from "luxon";
import { CircleNotch } from "phosphor-react";
import React from "react";
import toast from "react-hot-toast";
import { ScrollIntoView } from "../ui-focus-wrappers/ScrollIntoView";
import MeetingBreakIllustration from "./MeetingBreakIllustration";
import Tooltip from "./Tooltip";
import useMeetingRelief from "./hooks/useMeetingRelief";

const notifySuccess = () => toast.success("Updated meeting break setting.");
const notifyError = () => toast.error("Failed to updated meeting break setting.");

const MeetingBreaks = () => {
  const { settings: state, update, loading, updating, toggle, toggling } = useMeetingRelief({
    onSuccess: notifySuccess,
    onFail: notifyError,
  });

  const handleEnabledChange = (value: boolean) => {
    void toggle(value);

    track(TrackingEvents.SETTINGS.UPDATE_MEETING_RELIEF, {
      enabled: value,
      thresholdMinutes: state.fatigueThreshold.shiftTo("minute").minutes,
      durationMinutes: state.breakDuration.shiftTo("minutes").minutes,
    });
  };

  const handleBreakDurationChange = (value: Duration) => {
    void update({ breakDuration: value });

    track(TrackingEvents.SETTINGS.UPDATE_MEETING_RELIEF_DURATION, {
      thresholdMinutes: state.fatigueThreshold.shiftTo("minute").minutes,
      durationMinutes: value.shiftTo("minutes").minutes,
    });
  };

  const handleFatigueThresholdChange = (value: Duration) => {
    void update({ fatigueThreshold: value });

    track(TrackingEvents.SETTINGS.UPDATE_MEETING_RELIEF_THRESHOLD, {
      enabled: value,
      thresholdMinutes: value.shiftTo("minute").minutes,
      durationMinutes: state.breakDuration.shiftTo("minutes").minutes,
    });
  };

  return (
    <ScrollIntoView focusSection={WebappSurface.Breaks} highlight>
      <FeatureSetting
        enabled={!loading && state.enabled}
        loading={loading}
        onEnabledChange={handleEnabledChange}
        saving={toggling || updating}
        title="Meeting Breaks Settings"
      >
        <FeatureSettingAside>
          <MeetingBreakIllustration />
        </FeatureSettingAside>
        <FeatureSettingMain>
          <FeatureSettingHeading>
            <h2
              className="cw-heading-2xl cw-m-0"
              aria-label="Want breaks between consecutive meetings?"
            >
              Want{" "}
              <FeatureSettingsHoverCard content={<Tooltip />}>
                <span className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1">
                  breaks
                </span>
              </FeatureSettingsHoverCard>{" "}
              between consecutive meetings?
            </h2>
          </FeatureSettingHeading>
          <FeatureSettingBody>
            <div className="cw-flex cw-flex-row cw-flex-wrap cw-space-x-2 cw-items-center">
              {loading ? (
                <CircleNotch
                  className="cw-animate-spin cw-h-9"
                  color={fg_muted}
                  data-chromatic="ignore"
                />
              ) : (
                <Select
                  disabled={!state.enabled}
                  onChange={(value) => handleBreakDurationChange(Duration.fromISO(value))}
                  value={state.breakDuration.toString()}
                >
                  <SelectOption value="PT5M">5 minutes</SelectOption>
                  <SelectOption value="PT15M">15 minutes</SelectOption>
                  <SelectOption value="PT30M">30 minutes</SelectOption>
                </Select>
              )}
              <span className="cw-whitespace-nowrap">breaks if there will be more than</span>
              {loading ? (
                <CircleNotch
                  className="cw-animate-spin cw-h-9"
                  color={fg_muted}
                  data-chromatic="ignore"
                />
              ) : (
                <Select
                  disabled={!state.enabled}
                  onChange={(value) => handleFatigueThresholdChange(Duration.fromISO(value))}
                  value={state.fatigueThreshold.toString()}
                >
                  <SelectOption value="PT30M">30 minutes</SelectOption>
                  <SelectOption value="PT1H">1 hour</SelectOption>
                  <SelectOption value="PT1H30M">1.5 hour</SelectOption>
                  <SelectOption value="PT2H">2 hour</SelectOption>
                  <SelectOption value="PT2H30M">2.5 hour</SelectOption>
                  <SelectOption value="PT3H">3 hour</SelectOption>
                  <SelectOption value="PT3H30M">3.5 hour</SelectOption>
                  <SelectOption value="PT4H">4 hour</SelectOption>
                </Select>
              )}
              <span className="cw-whitespace-nowrap">of consecutive meetings</span>
            </div>
          </FeatureSettingBody>
        </FeatureSettingMain>
      </FeatureSetting>
    </ScrollIntoView>
  );
};

export default MeetingBreaks;
