import { TradeoffTypeEnum } from "@clockwise/schema";
import { LegacyTooltip } from "@clockwise/web-commons/src/ui/tooltip";
import { first } from "lodash";
import pluralize from "pluralize";
import React from "react";
import { TradeoffAttendee } from "../../chat/ai-chat/utils/types";
import { getStringListOfAttendees } from "../utils/getStringListOfAttendees";

const getBodyTextFromAttendeesAndType = (attendees: TradeoffAttendee[], type: TradeoffTypeEnum) => {
  const peopleString = getStringListOfAttendees(attendees);
  const singleAttendee = attendees.length === 1;
  const singleAttendeeIsYou = singleAttendee ? !!first(attendees)?.isYou : false;
  const getHasOrHaveSingular = (isYou: boolean) => {
    return isYou ? "have" : "has";
  };
  switch (type) {
    case TradeoffTypeEnum.AVAILABILITY_ISSUE:
      return (
        peopleString +
        (singleAttendee
          ? ` ${getHasOrHaveSingular(singleAttendeeIsYou)} an availability issue`
          : " have availability issues") +
        " at this time."
      );
    case TradeoffTypeEnum.INCONVENIENCE:
      return (
        peopleString +
        (singleAttendee
          ? ` ${getHasOrHaveSingular(singleAttendeeIsYou)} an inconvenience`
          : " have inconveniences") +
        " at this time."
      );
    case TradeoffTypeEnum.FIXABLE_CONFLICT:
      return (
        peopleString +
        (singleAttendee
          ? ` ${getHasOrHaveSingular(singleAttendeeIsYou)} a fixable conflict`
          : " have fixable conflicts") +
        " at this time."
      );
    default:
      return "";
  }
};

export const TradeoffAttendeeTooltip = ({
  attendees,
  type,
  children,
}: {
  attendees: TradeoffAttendee[];
  type: TradeoffTypeEnum;
  children: React.ReactNode;
}) => {
  const header = `${attendees.length} ${pluralize("Attendee", attendees.length)} affected`;
  const body = getBodyTextFromAttendeesAndType(attendees, type);
  return (
    <LegacyTooltip
      header={header}
      body={body}
      showArrow
      placement="bottom"
      styles={{ maxWidth: "250px" }}
    >
      {children}
    </LegacyTooltip>
  );
};
