import { Assistant, FiberManualRecord, NotInterested } from "@clockwise/design-system/icons";
import {
  AccountCircleFilled,
  AvgPace,
  CheckCircleHistoryFilled,
  ClockwiseIntelligenceFilled,
  PauseCircleFilled,
  PersonFilled,
  PlayCircleFilled,
  WarningHistoryFilled,
} from "@clockwise/icons";
import { EventLogType } from "@clockwise/schema/v2";
import classNames from "classnames";
import React from "react";

export const getTimelineIcon = (action: EventLogType, showWarning: boolean) => {
  const Icon = TimelineIconByAction[action];
  return (
    <Icon
      className={classNames("cw-w-4 cw-h-4 cw-text-subtle", {
        "cw-text-warning": showWarning,
      })}
    />
  );
};

type TimelineIconByActionType = {
  [Property in EventLogType]: typeof Assistant; // This makes typing easier instead of trying to import type defined deep within mui
};

const TimelineIconByAction: TimelineIconByActionType = {
  NextFlexRun: AvgPace,
  EventCreated: FiberManualRecord,
  FlexTurnedOn: ClockwiseIntelligenceFilled,
  FlexTurnedOff: NotInterested,
  ClockwiseMeetingMove: CheckCircleHistoryFilled,
  EventRescheduled: PersonFilled,
  ClockwiseMovePaused: PauseCircleFilled,
  ClockwiseMoveUnpaused: PlayCircleFilled,
  ClockwiseCouldNotResolveConflict: WarningHistoryFilled,
  ClockwiseEventRescheduled: AccountCircleFilled,
  ClockwiseSecondaryReschedule: ClockwiseIntelligenceFilled,
};
