/**
 * @generated SignedSource<<8fe62f2ee087a035f4d5cb1a59ffbb94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChromeSlackWrapperQuery$variables = {};
export type ChromeSlackWrapperQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"ChromeSlackWrapper_viewer">;
  };
};
export type ChromeSlackWrapperQuery = {
  variables: ChromeSlackWrapperQuery$variables;
  response: ChromeSlackWrapperQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChromeSlackWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChromeSlackWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ChromeSlackWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emails",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000000
                  }
                ],
                "concreteType": "OrgConnection",
                "kind": "LinkedField",
                "name": "orgs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrgEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Org",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "orgs(first:1000000)"
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c89e57d81453305dabc381a3199e25a",
    "id": null,
    "metadata": {},
    "name": "ChromeSlackWrapperQuery",
    "operationKind": "query",
    "text": "query ChromeSlackWrapperQuery {\n  viewer {\n    ...ChromeSlackWrapper_viewer\n    id\n  }\n}\n\nfragment ChromeSlackVerifier_viewer on Viewer {\n  user {\n    id\n    emails\n    orgs(first: 1000000) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment ChromeSlackWrapper_viewer on Viewer {\n  ...ChromeSlackVerifier_viewer\n}\n"
  }
};
})();

(node as any).hash = "91a0a464eb70e0d3f54f8835a56ac883";

export default node;
