import { InfoFilled } from "@clockwise/icons";
import { LegacyTooltip } from "@clockwise/web-commons/src/ui/tooltip";
import React from "react";

export const LowVizInconvenienceTooltip = () => {
  const tooltipText =
    "Clockwise identifies booking over events such as Focus Time, lunch, meeting breaks, or on a no-meeting day as inconveniences.";
  return (
    <LegacyTooltip placement="top" showArrow={false} body={tooltipText}>
      <InfoFilled className="cw-w-3.5 cw-h-3.5 cw-mt-[5px] cw-cursor-pointer cw-text-info-disabled cw-ml-1" />
    </LegacyTooltip>
  );
};
