import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type LunchGoalSettingsFragment = { __typename: 'LunchGoals', id: string, enabled: boolean | null, preferredLunchTimes: { __typename: 'PreferredLunchTimes', earliestStart: Types.TimeSlot, latestEnd: Types.TimeSlot, minimumDuration: any, idealDuration: any } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null };

export type LunchGoalsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LunchGoalsQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', id: string, user: { __typename: 'User', id: string, orgs: { __typename: 'OrgConnection', edges: Array<{ __typename: 'OrgEdge', node: { __typename: 'Org', id: string, smartHoldGoals: { __typename: 'SmartHoldGoals', id: string, lunch: { __typename: 'LunchGoals', id: string, enabled: boolean | null, preferredLunchTimes: { __typename: 'PreferredLunchTimes', earliestStart: Types.TimeSlot, latestEnd: Types.TimeSlot, minimumDuration: any, idealDuration: any } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null } } } | null } | null> | null } } | null } };

export const LunchGoalSettingsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LunchGoalSettings"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LunchGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"preferredLunchTimes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"earliestStart"}},{"kind":"Field","name":{"kind":"Name","value":"latestEnd"}},{"kind":"Field","name":{"kind":"Name","value":"minimumDuration"}},{"kind":"Field","name":{"kind":"Name","value":"idealDuration"}}]}},{"kind":"Field","name":{"kind":"Name","value":"remoteHoldSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"scheduleAs"}},{"kind":"Field","name":{"kind":"Name","value":"notificationStrategy"}}]}}]}}]} as unknown as DocumentNode<LunchGoalSettingsFragment, unknown>;
export const LunchGoalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LunchGoals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"orgs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"smartHoldGoals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lunch"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LunchGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LunchGoalSettings"}}]}}]}}]}}]}}]}}]}}]}}]}}]}},...LunchGoalSettingsFragmentDoc.definitions]} as unknown as DocumentNode<LunchGoalsQuery, LunchGoalsQueryVariables>;