import { useLaunchConfetti } from "@clockwise/web-commons/src/hooks/useLaunchConfetti";
import { useReadActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import React from "react";
import { useReadActiveEventDiff } from "../chat-plus-calendar/util/ActiveDiffContext";
import { DefaultExpandedTradeoffProvider } from "../chat/ai-chat/hooks/ExpandedTradeoffContext";
import { GQLProposal } from "../chat/ai-chat/utils/types";
import ErrorBoundary from "../error-boundary/ErrorBoundary";
import { CardManager } from "../event-card/CardManager";
import { ProposalExpanded } from "./ProposalExpanded";
import { SharedProposalDiffEventCardExpanded } from "./SharedProposalDiffEventCardExpanded";

type ProposalProps = {
  sharedProposal: GQLProposal | null;
  ownerCalendarId: string | null;
  setUpdatedProposal: (proposal: GQLProposal | null) => void;
};

export const SharedProposal = ({
  sharedProposal,
  ownerCalendarId,
  setUpdatedProposal,
}: ProposalProps) => {
  const activeEvent = useReadActiveEvent();
  const activeDiff = useReadActiveEventDiff();
  const launchConfetti = useLaunchConfetti();

  if (!sharedProposal || !ownerCalendarId) {
    return null;
  }

  return (
    <>
      <DefaultExpandedTradeoffProvider initValue={null}>
        <div className="cw-relative cw-h-full cw-w-full cw-bg-neutral">
          {activeEvent && <CardManager activeEvent={activeEvent} />}
          {activeDiff?.id && (
            <SharedProposalDiffEventCardExpanded
              proposalId={sharedProposal.proposalId}
              id={activeDiff.id}
            />
          )}
          <div className="cw-w-full cw-body-base cw-flex-col cw-overflow-y-scroll cw-h-full cw-relative cw-pt-16">
            <div className="cw-w-full cw-min-h-full cw-flex-1 cw-flex cw-flex-col cw-align-center cw-pt-8">
              <div className="cw-flex-1 cw-flex cw-flex-col cw-pb-2 cw-pr-3.5 cw-w-full cw-h-full cw-z-[0] cw-overflow-x-hidden cw-justify-end">
                <ErrorBoundary>
                  <ProposalExpanded
                    sharedProposal={sharedProposal}
                    ownerCalendarId={ownerCalendarId}
                    setUpdatedProposal={setUpdatedProposal}
                    triggerConfetti={launchConfetti}
                  />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </div>
      </DefaultExpandedTradeoffProvider>
    </>
  );
};

export default SharedProposal;
