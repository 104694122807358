import { Edit, Link } from "@clockwise/design-system/icons";
import { TextField } from "@material-ui/core";
import { animated, config, useSpring } from "@react-spring/web";
import classNames from "classnames";
import React, { ChangeEvent, useState } from "react";

import { useKeyListener, useWindowSize } from "../../../util/react.util";

import { Popover } from "@clockwise/design-system";
import { pageMaxWidth, SetupSchedulingLinkProps, useSetupLinkStyles } from "../";
import { useResettingBoolean } from "../../scheduling-links/hooks/useResettingBoolean";

const AnimatedLink = animated(Link);

export interface ILinkEditor {
  linkName: SetupSchedulingLinkProps["linkName"];
  showLinkActiveText: boolean;
  slug: string;
  slugError: string;
  createUpdateError: SetupSchedulingLinkProps["createUpdateError"];
  setSlugError: React.Dispatch<React.SetStateAction<string>>;
  onChangeSlug: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const LinkEditor = ({
  linkName,
  showLinkActiveText,
  slug,
  slugError,
  createUpdateError,
  setSlugError,
  onChangeSlug,
}: ILinkEditor) => {
  const [editSlugOpen, setEditSlugOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useResettingBoolean(false);

  const { width: windowWidth } = useWindowSize();
  const classes = useSetupLinkStyles();
  const urlFadeStyle = useSpring({
    opacity: editSlugOpen ? 0.7 : 1,
    config: config.stiff,
  });

  useKeyListener(editSlugOpen ? ["Escape"] : [], () => setEditSlugOpen(false));

  const showSlugBar = windowWidth > 700;
  const showFullUrl = windowWidth > 890;
  const truncateUrl = windowWidth < pageMaxWidth;

  const copyLink = async () => {
    await window.navigator.clipboard.writeText(`getclockwise.com/c/${linkName}/${slug}`);
    setShowTooltip(true);
  };

  if (!showSlugBar || !linkName) {
    return null;
  }

  return (
    <div>
      <div
        className={classNames(
          classes.slug,
          "cw-flex cw-items-center cw-flex-shrink-0",
          "cw-text-muted cw-leading-tight",
        )}
      >
        <AnimatedLink
          className={classNames([
            "cw-bg-neutral-inset",
            "cw-box-content",
            "cw-p-[7px_5px_7px_9px]",
            "cw-rounded-tl cw-rounded-bl",
            { "cw-opacity-75": editSlugOpen },
          ])}
          style={urlFadeStyle}
        />
        <Popover opened={showTooltip}>
          <Popover.Target>
            <animated.span
              className={classNames([
                "cw-overflow-hidden cw-whitespace-nowrap cw-flex-shrink-0 cw-text-ellipsis cw-max-w-md hover:cw-cursor-copy cw-font-body",
                "cw-p-[9px_0px_9px]",
                "cw-bg-neutral-inset",
                "cw-box-content",
                { "cw-max-w-xs": truncateUrl },
                { "cw-max-w-xxs": !showLinkActiveText },
                { "cw-max-w-xxxs": !showFullUrl },
              ])}
              style={urlFadeStyle}
              cw-id="link-url"
              onClick={copyLink}
            >
              {`${showFullUrl ? `getclockwise.com/c/${linkName}/` : ""}${editSlugOpen ? "" : slug}`}
            </animated.span>
          </Popover.Target>
          <Popover.Dropdown>
            <div className="cw-body-base">Copied link to clipboard</div>
          </Popover.Dropdown>
        </Popover>

        {editSlugOpen ? (
          <TextField
            variant="filled"
            error={!!slugError || !!createUpdateError}
            className="cw-flex-shrink-0"
            autoFocus
            inputProps={{ ["aria-label"]: "Edit link url" }}
            InputProps={{
              className: "cw-rounded-r cw-pl-1",
            }}
            spellCheck={false}
            onBlur={() => {
              if (!slugError) {
                setEditSlugOpen(false);
              }
            }}
            value={slug}
            onChange={onChangeSlug}
            cw-id="link-name-slug-top-section"
          />
        ) : (
          <Edit
            aria-label="Edit link button"
            tabIndex={0}
            cw-id="edit-link-url-button"
            onFocus={() => setEditSlugOpen(true)}
            className={classNames(
              "cw-rounded-tr cw-rounded-br cw-cursor-pointer cw-flex-shrink-0",
              "cw-p-[7px_5px_7px_9px]",
              "cw-bg-neutral-inset",
              "cw-box-content",
            )}
            onClick={() => {
              setEditSlugOpen(!editSlugOpen);
              setSlugError("");
            }}
          />
        )}
      </div>
      <div className="cw-flex-shrink-0 cw-caption cw-py-1 cw-text-destructive cw-absolute">
        {slugError || createUpdateError}
      </div>
    </div>
  );
};
