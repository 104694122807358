import { Link } from "@clockwise/design-system";
import { ClockwiseIntelligenceFilled, Close } from "@clockwise/icons";
import React from "react";
import { useLocalStorage } from "usehooks-ts";
import { TrackingEvents, useTracking } from "../../util/analytics.util";

export const PrismBannerMessage = () => {
  const [_, setHasUserDismissedBanner] = useLocalStorage<boolean>(
    "ClockwiseAI:PrismBannerDismissed",
    false,
  );
  const track = useTracking();

  return (
    <div className="cw-prism-banner cw-h-full cw-w-full cw-flex cw-space-x-2 cw-items-center cw-justify-center cw-body-base cw-text-14 cw-text-accent cw-font-medium cw-relative">
      <ClockwiseIntelligenceFilled className="cw-w-4 cw-h-4 cw-text-accent" />
      <span>
        AI Scheduler is now Prism, your intelligent calendar. Read more{" "}
        <Link
          href="https://www.getclockwise.com/blog/introducing-prism"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            setHasUserDismissedBanner(true);
            track(TrackingEvents.AI_WEBAPP_CTA.PRISM_BANNER_CLICKED);
          }}
        >
          here
        </Link>
      </span>
      <Close
        className="cw-text-accent cw-w-4 cw-h-4 cw-absolute cw-top-2.5 cw-right-4 cw-stroke-2 cw-cursor-pointer"
        onClick={() => {
          setHasUserDismissedBanner(true);
          track(TrackingEvents.AI_WEBAPP_CTA.PRISM_BANNER_DISMISSED);
        }}
      />
    </div>
  );
};

export default PrismBannerMessage;
