import { graphql } from "react-relay";

import "../../goals-wrapper";
export const query = graphql`
  query WebSettingsHoursAndPreferencesRelayQuery($orgRelayId: ID!) {
    viewer: viewer {
      id
    }
    org: node(id: $orgRelayId) {
      ... on Org {
        ...WorkPreferenceStartEndGoal_org
        primaryOrgCalendar
        experiments
        primaryTeam {
          __typename
          ... on Team {
            teamId
            settings {
              __typename
              ... on TeamSettings {
                useNoMeetingDay
                noMeetingDayOfWeek
              }
              ... on GraphEntityError {
                statusCode
                message
              }
            }
            userSettings {
              __typename
              ... on TeamUserSettings {
                useNoMeetingDay
              }
              ... on GraphEntityError {
                statusCode
                message
              }
            }
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
        userMeetingHours(resolveOverrides: false) {
          id
          hasBeenSet
          workingHours {
            id
            type
            timeZone
            daySettings {
              day
              setting {
                minSlot
                maxSlot
                excludedSlots
              }
            }
            dayOverrides {
              date
              setting {
                minSlot
                maxSlot
                excludedSlots
              }
            }
          }
        }
        userWorkingHours(resolveOverrides: false) {
          id
          hasBeenSet
          workingHours {
            id
            type
            timeZone
            daySettings {
              day
              setting {
                minSlot
                maxSlot
                excludedSlots
              }
            }
            dayOverrides {
              date
              setting {
                minSlot
                maxSlot
                excludedSlots
              }
            }
          }
        }

        featureGrid {
          __typename
          ... on FeatureGrid {
            featureStates {
              meetingReliefSettingVisibility
            }
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
      }
    }
  }
`;
