import React from "react";

// components
import { LogoHorizontal } from "#webapp/src/components/logo";

// constants
import { paths } from "@clockwise/client-commons/src/constants/site";

// util
import { withRouterForClass } from "#webapp/src/routing/routes.util";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { windowLocation } from "#webapp/src/util/location.util";
import { Button } from "@clockwise/design-system";
import { Location } from "react-router-dom";

const promoPaths = [paths.nonUserDefragSurvey];

const promoEvents = {
  [paths.nonUserDefragSurvey]: TrackingEvents.NON_USER_DEFRAG_SURVEY.CLICKED_TRY_CLOCKWISE,
};

const HomeNavCmpt = ({ location }: { location: Location }) => {
  const onClickPromo = (path: keyof typeof promoEvents) => {
    const event = promoEvents[path];

    if (event) {
      track(event);
    }

    // TODO: https://app.asana.com/0/879916340247720/1157705287919017/f
    // give our track a bit of time to actually send, possibly flaky but it doesnt have a sync mechanism yet
    setTimeout(
      () => windowLocation.assign("HomeNavClickPromo", "https://www.getclockwise.com"),
      1500,
    );
  };

  const promoPath = promoPaths.find((path) => location.pathname.indexOf(path) !== -1);

  return (
    <div className="cw-fixed cw-top-0 cw-inset-x-0 cw-z-[10] cw-bg-default">
      <div className="cw-px-4 lg:cw-px-[124px] md:cw-px-[54px] cw-py-6 cw-flex cw-flex-1 cw-items-center">
        <a className="cw-h-full cw-flex cw-items-center" href="/">
          <LogoHorizontal style={{ width: 172 }} />
        </a>
        {promoPath ? (
          <div className="cw-flex-1 cw-flex cw-justify-end">
            <Button onClick={() => onClickPromo(promoPath)} sentiment="positive">
              Try Clockwise
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const HomeNav = withRouterForClass(HomeNavCmpt);
