import { CalendarTimeStats, TeamMemberStatus } from "@clockwise/schema/v2";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { compact, mean } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { SimpleMember, TeamStats } from "./TeamStats";
import { TeamHeaderDocument } from "./__generated__/TeamHeader.v2.generated";
import { FocusTimeChart } from "./dashboard/FocusTimeChart";

interface Props {
  teamId: string;
}

const calculateAverage = <T extends Partial<CalendarTimeStats>>(stats: T[], key: keyof T) =>
  stats.length > 0 ? mean(stats.map((m) => m?.[key] ?? 0)) : 0;

export const TeamHeader = ({ teamId }: Props) => {
  const { data } = useGatewayQuery(TeamHeaderDocument, {
    variables: {
      teamId,
      start: DateTime.local().startOf("week").toISODate(),
      end: DateTime.local().endOf("week").toISODate(),
      timeZone: getRenderTimeZone(),
    },
    errorPolicy: "all",
  });

  const teamName = data?.team?.name || "";
  const members = compact(data?.team?.members).map(
    (member): SimpleMember => ({
      id: member.id,
      invitePending: member.status === TeamMemberStatus.Invited,
    }),
  );
  const memberStats = compact(data?.team?.members.map((m) => m?.member?.calendarTimeStats) ?? []);

  const focusTime = calculateAverage(memberStats, "focusMinutes");
  const fragmentedTime = calculateAverage(memberStats, "fragmentedMinutes");
  const busyTime = calculateAverage(memberStats, "busyMinutes");
  const disruptionCount = data?.team?.disruptiveEvents.length ?? 0;

  return (
    <div className="cw-flex cw-justify-center cw-bg-brand">
      <div className="cw-flex cw-flex-col xl:cw-flex-row cw-max-w-[1040px] cw-justify-between cw-items-center cw-gap-3 cw-px-[60px] cw-py-8">
        <TeamStats
          id={teamId}
          name={teamName}
          focusTime={focusTime}
          fragmentedTime={fragmentedTime}
          busyTime={busyTime}
          disruptiveMeetingCount={disruptionCount}
          members={members}
          noMeetingDay={(data?.team?.noMeetingDay.enabled && data?.team?.noMeetingDay.day) || null}
          manageTeamCalendar={data?.team?.manageTeamCalendar ?? false}
        />
        <div>
          <div className="cw-heading-lg cw-text-center">This Week</div>
          <FocusTimeChart teamId={teamId} />
        </div>
      </div>
    </div>
  );
};
