import React from "react";
import { AreaSpline } from "../area-spline";
import { Chart } from "../chart";
import { Column } from "../column/column";
import { Donut } from "../donut";
import { IOrgOverview } from "../overview.types";
import getChartGroupBy from "./utils/getChartGroupBy";
import groupDataInDateRangeByStep from "./utils/groupDataInDateRangeByStep";
export interface ChartGridProps {
  maxDate: Date;
  minDate: Date;
  orgOverview: IOrgOverview;
  shouldAnimate?: boolean;
}

export const ChartGrid = ({
  maxDate,
  minDate,
  orgOverview,
  shouldAnimate = true,
}: ChartGridProps) => {
  const {
    userCount,
    scheduleAssistCount,
    focusTimeHoursCreatedCount,
    conflictsResolvedCount,
    numUsersByFunction,
    numUsersByRole,
  } = orgOverview;
  const groupBy = getChartGroupBy(maxDate, minDate);

  const renderCurrentUsers = () => (
    <div className="chromatic-ignore">
      <AreaSpline
        series={{
          name: "Users",
          data: groupDataInDateRangeByStep(userCount, maxDate, minDate, "day"),
          threshold: null,
        }}
        tooltipFormat={{ valueSuffix: " users" }}
        step={groupBy}
      />
    </div>
  );

  const renderScheduleAssists = () => (
    <div className="chromatic-ignore">
      <Column
        series={{
          name: "Schedule assists",
          data: groupDataInDateRangeByStep(scheduleAssistCount, maxDate, minDate, groupBy),
        }}
        step={groupBy}
        shouldAnimate={shouldAnimate}
      />
    </div>
  );

  const renderFocusTimeHoursCreated = () => (
    <div className="chromatic-ignore">
      <Column
        series={{
          name: "Focus Time hours created",
          data: groupDataInDateRangeByStep(focusTimeHoursCreatedCount, maxDate, minDate, groupBy),
          threshold: null,
        }}
        step={groupBy}
        tooltipFormat={{ valueSuffix: " hrs" }}
        shouldAnimate={shouldAnimate}
      />
    </div>
  );

  const renderConflictsResolved = () => (
    <div className="chromatic-ignore">
      <Column
        series={{
          name: "Conflicts resolved",
          data: groupDataInDateRangeByStep(conflictsResolvedCount, maxDate, minDate, groupBy),
          threshold: null,
        }}
        step={groupBy}
        shouldAnimate={shouldAnimate}
      />
    </div>
  );

  return (
    <div className="cw-grid cw-grid-cols-1 lg:cw-grid-cols-2 cw-gap-3 cw-pb-10">
      <Chart
        title="Users"
        subtitle="People in your organization who are using Clockwise."
        chart={renderCurrentUsers()}
      />
      <Chart
        title="Schedule assists"
        subtitle="Each time Clockwise schedules or reschedules an event for an attendee."
        chart={renderScheduleAssists()}
      />
      <Chart
        title="Focus Time hours created"
        subtitle="Uninterrupted work time blocks of two or more hours created by Clockwise."
        chart={renderFocusTimeHoursCreated()}
      />
      <Chart
        title="Conflicts resolved"
        subtitle="Each time Clockwise resolves a meeting conflict for an attendee."
        chart={renderConflictsResolved()}
      />
      <Chart
        title="Users by function"
        subtitle="Self-reported function when users sign up for Clockwise."
        chart={<Donut data={numUsersByFunction} />}
      />
      <Chart
        title="Users by role"
        subtitle="Self-reported role when users sign up for Clockwise."
        chart={<Donut data={numUsersByRole} />}
      />
    </div>
  );
};
