import { isEmpty } from "lodash";
import { Interval } from "luxon";
import React from "react";
import { formattedDateTime } from "../../../../web-app-calendar/calendar-popover/utils/formattedDateTime";
import { TradeoffBlock } from "../../utils/types";
import { CalDiffMini } from "./ShareSubMenu";

type CopyTextProps = {
  copyTitle: string;
  viewerGivenName: string | null;
  calDiffs: CalDiffMini[];
  conflictTradeoffs: TradeoffBlock[];
  sharedProposalLink: string;
  targetTimeZone: string;
  isProposeNewTime: boolean;
};

const CopyTimesRow = ({
  calDiff,
  targetTimeZone,
}: {
  calDiff: CalDiffMini;
  targetTimeZone: string;
}) => {
  const time = Interval.fromISO(calDiff.updatedTime || calDiff.currentTime).start;
  return (
    <li>
      <span className="cw-font-semibold">{calDiff.title}</span>
      {" -> "}
      {formattedDateTime(time, targetTimeZone, true)}
    </li>
  );
};

export const CopyText = ({
  copyTitle,
  viewerGivenName,
  calDiffs,
  conflictTradeoffs,
  sharedProposalLink,
  targetTimeZone,
  isProposeNewTime,
}: CopyTextProps) => {
  const hasConsequences = !isEmpty(calDiffs);
  const hasConflicts = !isEmpty(conflictTradeoffs);
  const title = `${copyTitle} ${
    isProposeNewTime ? "I would do it, but I don't have edit rights." : ""
  }`;

  return (
    <div>
      <div>
        <div dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      {(hasConsequences || hasConflicts) && (
        <div className="cw-mt-3">
          <div>There are a few issues with that time, which Clockwise will fix if it can:</div>
          <ul className="cw-list-inside cw-list-disc cw-ml-3 cw-mt-3">
            {calDiffs.map((calDiff) => (
              <CopyTimesRow key={calDiff.id} calDiff={calDiff} targetTimeZone={targetTimeZone} />
            ))}
            {conflictTradeoffs.map((tradeoffBlock) =>
              tradeoffBlock.schedulingTradeoffs.map((tradeOff) => (
                <li key={tradeOff.id}>
                  <span className="cw-font-semibold">{tradeOff.eventTitle}</span>
                  {" - Conflict"}
                </li>
              )),
            )}
          </ul>
        </div>
      )}
      <div className="cw-mt-3">Does that work?</div>
      <div className="cw-flex cw-flex-row cw-items-center cw-gap-2">
        🔗{" "}
        <a
          href={`${sharedProposalLink}?cw_link_source=copied_times`}
          target="_blank"
          rel="noreferrer"
          className="cw-text-[blue]"
        >
          {viewerGivenName ? `Respond to ${viewerGivenName}` : "Respond in Clockwise"}
        </a>
      </div>
    </div>
  );
};
