import { useUnvisitedPageFlags } from "#webapp/src/hooks/useUnvisitedPageFlags";
import { canAccessExperiments, canAccessSudo } from "#webapp/src/util/auth.util";
import { AddCircleOutline, Prism } from "@clockwise/design-system/icons";
import { border_positive_muted, fg_muted, fg_onEmphasis } from "@clockwise/design-system/tokens";
import { AccountCircle, TrendingUp } from "@clockwise/icons";
import {
  appPaths,
  clockwiseSupportUrl,
  pageSlugs,
  webappPathRoot,
} from "@clockwise/web-commons/src/constants/route.constants";
import { BusinessRounded, ExtensionOutlined, PeopleAltOutlined } from "@material-ui/icons";
import classNames from "classnames";
import {
  Bug,
  CreditCard,
  DotsNine,
  Flask,
  Gear,
  Graph,
  HandWaving,
  LinkSimple,
  Question,
  Sparkle,
  SunDim,
  TerminalWindow,
  UserPlus,
} from "phosphor-react";
import React from "react";
import { NavEducationStep } from "../superpowers-checklist/useNuxPopoverStates.util";
import { AppLogoNavLink } from "./AppLogoNavLink";
import { AppNavAvatar } from "./AppNavAvatar";
import { AppNavLink } from "./AppNavLink";
import { AppNavLinkMenu, AppNavLinkMenuGroup, AppNavLinkMenuItem } from "./AppNavLinkMenu";
import { AppNavTeamLinks, useTeamsList } from "./AppNavTeamLinks";
import { NavImagePreloader } from "./NavImagePreloader";

export const AppNav = ({ useRelativePaths = false }: { useRelativePaths?: boolean }) => {
  const [teamsList, isTeamListLoading] = useTeamsList();
  const paths = useRelativePaths ? pageSlugs : appPaths;

  const showExperiments = canAccessExperiments();
  const showSudo = canAccessSudo();
  const {
    shouldShowUnvisitedBadges,
    hasVisitedLinksPage,
    hasVisitedFlexibilityPage,
    hasVisitedIdealDayPage,
  } = useUnvisitedPageFlags();

  return (
    <div
      className={classNames(
        "cw-h-full cw-w-[72px]",
        "cw-body-base",
        "cw-pt-[18px] cw-pb-6 cw-px-3",
        "cw-bg-brand-gradient",
      )}
    >
      <NavImagePreloader />
      <div className={classNames("cw-h-full cw-w-full", "cw-flex cw-flex-col cw-justify-between")}>
        <div className={classNames("cw-flex cw-flex-col cw-space-y-6")}>
          <AppLogoNavLink />
          <div className={classNames("cw-flex cw-flex-col cw-space-y-4")}>
            <AppNavLink
              to={paths.ai}
              icon={({ isActive }) => (
                <Prism
                  fill={isActive ? border_positive_muted : fg_onEmphasis}
                  className="cw-w-5 cw-h-5"
                />
              )}
              educationPopoverType={NavEducationStep.CalendarPage}
              label="Calendar"
              end
            />
            <AppNavLink
              to={paths.schedulingLinks}
              icon={({ isActive }) => (
                <LinkSimple
                  size={20}
                  color={isActive ? border_positive_muted : fg_onEmphasis}
                  weight="bold"
                />
              )}
              educationPopoverType={NavEducationStep.SchedulingLinksPage}
              label="Links"
              emphasisBadge={shouldShowUnvisitedBadges && !hasVisitedLinksPage}
            />
            <AppNavLink
              to={paths.flexibleMeetings}
              icon={({ isActive }) => (
                <Sparkle
                  size={20}
                  color={isActive ? border_positive_muted : fg_onEmphasis}
                  weight="bold"
                />
              )}
              educationPopoverType={NavEducationStep.FlexMeetingsPage}
              label="Flexibility"
              emphasisBadge={shouldShowUnvisitedBadges && !hasVisitedFlexibilityPage}
            />
            <AppNavLink
              to={paths.accountPreferences}
              icon={({ isActive }) => (
                <SunDim
                  size={20}
                  color={isActive ? border_positive_muted : fg_onEmphasis}
                  weight="bold"
                />
              )}
              label="Ideal day"
              emphasisBadge={shouldShowUnvisitedBadges && !hasVisitedIdealDayPage}
            />
            <AppNavLinkMenu
              activePaths={[
                pageSlugs.analytics,
                pageSlugs.appSettings,
                pageSlugs.createOrJoinTeam,
                pageSlugs.landing,
                pageSlugs.members,
                pageSlugs.orgSettings,
                pageSlugs.plansAndBilling,
                pageSlugs.teamSettings,
                pageSlugs.teamsSettings,
              ]}
              icon={({ isActive }) => (
                <DotsNine
                  size={20}
                  color={isActive ? border_positive_muted : fg_onEmphasis}
                  weight="bold"
                />
              )}
              label="More"
            >
              <div className="cw-py-1.5">
                <AppNavLinkMenuGroup label="Teams">
                  <AppNavTeamLinks
                    icon={<PeopleAltOutlined className="cw-w-4 cw-h-4 cw-text-muted" />}
                    teams={teamsList}
                    loading={isTeamListLoading}
                  />
                  <AppNavLinkMenuItem
                    to={paths.createOrJoinTeam}
                    icon={
                      <AddCircleOutline
                        className={classNames(
                          "cw-w-4 cw-h-4",
                          teamsList.length > 0 ? "cw-text-muted" : "cw-text-positive",
                        )}
                      />
                    }
                    sentiment={teamsList.length > 0 ? "neutral" : "positive"}
                    label="Create or join"
                  />
                </AppNavLinkMenuGroup>
                <AppNavLinkMenuGroup label="Organization">
                  <AppNavLinkMenuItem
                    to={paths.analytics}
                    icon={<TrendingUp className="cw-w-4 cw-h-4 cw-text-muted" />}
                    label="Analytics"
                  />
                  <AppNavLinkMenuItem
                    to={paths.orgSettings}
                    icon={<BusinessRounded className="cw-w-4 cw-h-4 cw-text-muted" />}
                    label="Organization settings"
                  />
                  <AppNavLinkMenuItem
                    to={paths.plansAndBilling}
                    icon={<CreditCard size={16} color={fg_muted} fill="none" />}
                    label="Plans & billing"
                  />
                  <AppNavLinkMenuItem
                    to={paths.members}
                    icon={<AccountCircle className="cw-w-4 cw-h-4 cw-text-muted" />}
                    label="Users"
                  />
                </AppNavLinkMenuGroup>
                <AppNavLinkMenuGroup>
                  <AppNavLinkMenuItem
                    to={paths.appSettings}
                    icon={<ExtensionOutlined className="cw-w-4 cw-h-4 cw-text-muted" />}
                    label="Connected apps"
                  />
                </AppNavLinkMenuGroup>
                <AppNavLinkMenuGroup>
                  <AppNavLinkMenuItem
                    to={paths.landing}
                    icon={<HandWaving size={16} color={fg_muted} fill="none" />}
                    label="Welcome"
                  />
                </AppNavLinkMenuGroup>
              </div>
            </AppNavLinkMenu>
          </div>
        </div>

        <div className={classNames("cw-flex cw-flex-col cw-space-y-4")}>
          <div className={classNames("cw-flex cw-flex-col cw-space-y-1")}>
            {showExperiments && (
              <AppNavLinkMenu
                activePaths={[pageSlugs.experiments, pageSlugs.graphiql]}
                icon={({ isActive }) => (
                  <Bug
                    size={20}
                    color={isActive ? border_positive_muted : fg_onEmphasis}
                    weight="bold"
                  />
                )}
              >
                <div className="cw-py-1.5">
                  <AppNavLinkMenuGroup label="Internal">
                    <AppNavLinkMenuItem
                      to={paths.experiments}
                      icon={<Flask size={16} color={fg_muted} />}
                      label="Experiments"
                    />
                    <AppNavLinkMenuItem
                      to={paths.graphiql}
                      icon={<Graph size={16} color={fg_muted} />}
                      label="GraphiQL"
                    />
                    {showSudo && (
                      <AppNavLinkMenuItem
                        to={paths.sudo}
                        icon={<TerminalWindow size={16} color={fg_muted} />}
                        label="Sudo"
                      />
                    )}
                  </AppNavLinkMenuGroup>
                </div>
              </AppNavLinkMenu>
            )}
            <AppNavLink
              to={paths.preferences}
              icon={({ isActive }) => (
                <Gear
                  size={20}
                  color={isActive ? border_positive_muted : fg_onEmphasis}
                  weight="bold"
                />
              )}
              tooltip="Preferences"
            />
            <AppNavLink
              icon={({ isActive }) => (
                <UserPlus
                  size={20}
                  color={isActive ? border_positive_muted : fg_onEmphasis}
                  weight="bold"
                />
              )}
              to={`${webappPathRoot}/${pageSlugs.landing}?invite=true`}
              tooltip="Share Clockwise"
              preventActive
            />
            <AppNavLink
              to={clockwiseSupportUrl}
              icon={({ isActive }) => (
                <Question
                  size={20}
                  color={isActive ? border_positive_muted : fg_onEmphasis}
                  weight="bold"
                />
              )}
              tooltip="Get support"
            />
          </div>
          <AppNavAvatar />
        </div>
      </div>
    </div>
  );
};
