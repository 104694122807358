export const getStatDelta = (currentWeek: number, previousWeek: number) => {
  if (currentWeek === previousWeek) {
    return 0;
  }

  if (previousWeek === 0) {
    return 100;
  }

  return Math.round(((currentWeek - previousWeek) / previousWeek) * 100);
};
