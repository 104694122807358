import { sortBy } from "lodash";
import React from "react";
import { CliOption } from "./CliOption";
import { CliOptionValues } from "./CliOptionValues";
import { CliOptionDef } from "./types";

type OptionConfig = {
  active: boolean;
  values: string[];
};
export interface IProps {
  options: CliOptionDef[];
  optionConfigs: { [name: string]: OptionConfig };
  onConfigChange: (name: string, optionConfig: OptionConfig) => void;
  disabled?: boolean;
}

export const CliOptions = ({
  options,
  optionConfigs,
  onConfigChange,
  disabled = false,
}: IProps) => {
  const sortedOptions = sortBy(options, (o) => o.name.toLowerCase());

  const optionsList =
    options.length > 0 ? (
      sortedOptions.map(({ name, description, type }) => {
        const config = optionConfigs[name];
        if (!config) {
          return null;
        }
        return (
          <CliOption
            key={name}
            name={name}
            description={description}
            checked={config.active}
            onChange={(checked) => {
              onConfigChange(name, { ...config, active: checked });
            }}
            disabled={disabled}
            type={type}
          >
            {
              <CliOptionValues
                values={config.values}
                onChange={(values) => {
                  const isEmptyString = values.filter((value) => value === "").length > 0;
                  onConfigChange(name, { ...config, values, active: !isEmptyString });
                }}
                disabled={disabled}
              />
            }
          </CliOption>
        );
      })
    ) : (
      <em>No options</em>
    );
  return <div className="cw-my-1 cw-space-y-0.5">{optionsList}</div>;
};
