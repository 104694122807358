import { isEqual, sortBy } from "lodash";
import React, { PropsWithChildren, createContext, useContext, useState } from "react";
import invariant from "tiny-invariant";
import { EventCardAttendee } from "../types";

type EventAttendeesContextType = {
  attendees: EventCardAttendee[];
  initialAttendees: EventCardAttendee[];
  attendeesChanged: boolean;
  setAttendees: (attendees: EventCardAttendee[]) => void;
};

const attendeesHaveChanged = (
  initial: EventCardAttendee[],
  current: EventCardAttendee[],
): boolean => {
  // NB: id should be unique and consistent, may want to dedupe also but
  //  there shouldn't be dupe attendees in the first place
  const initialIds = initial.map((attendee) => {
    return { id: attendee.id, isOptional: attendee.isOptional };
  });
  const sortedInitial = sortBy(initialIds, ["id"]);

  const currentIds = current.map((attendee) => {
    return { id: attendee.id, isOptional: attendee.isOptional };
  });
  const sortedCurrent = sortBy(currentIds, ["id"]);

  return !isEqual(sortedInitial, sortedCurrent);
};

export type EventAttendeesProps = {
  attendees: EventCardAttendee[];
};

const EventAttendeesContext = createContext<EventAttendeesContextType>(undefined as never);

export const EventAttendeesProvider: React.FC<PropsWithChildren<EventAttendeesProps>> = ({
  children,
  attendees: initialAttendees,
}) => {
  const [attendees, setAttendees] = useState(initialAttendees);
  const attendeesChanged = attendeesHaveChanged(initialAttendees, attendees);

  return (
    <EventAttendeesContext.Provider
      value={{ attendees, initialAttendees, attendeesChanged, setAttendees }}
    >
      {children}
    </EventAttendeesContext.Provider>
  );
};

export const useEventAttendees = () => {
  const context = useContext(EventAttendeesContext);
  invariant(context, "useEventAttendees must be used within a EventAttendeesProvider");
  return context;
};
