import { Button } from "@clockwise/design-system";
import { LegacyTooltip } from "@clockwise/web-commons/src/ui/tooltip";
import { noop } from "lodash";
import { default as React } from "react";

export const ToggleCalendarLayers = ({
  onClick = noop,
  disabled,
  isToggledOn,
  reasonForDisabled,
}: {
  onClick: () => void;
  disabled: boolean;
  reasonForDisabled?: string | null;
  isToggledOn: boolean;
}) => {
  const tooltipBody =
    reasonForDisabled || (isToggledOn ? "Hide participants overlay" : "Show participants overlay");

  return (
    <LegacyTooltip
      placement="right"
      body={tooltipBody}
      showArrow={false}
      className="cw-flex cw-self-center cw-pl-3"
    >
      {isToggledOn ? (
        <Button
          onClick={onClick}
          aria-label="Hide participants overlay"
          disabled={disabled}
          variant="outlined"
          size="mini"
          cw-id="hide-calendar-layers-button"
        >
          Hide calendars
        </Button>
      ) : (
        <Button
          onClick={onClick}
          aria-label="Show participants overlay"
          disabled={disabled}
          variant="outlined"
          size="mini"
          cw-id="show-calendar-layers-button"
        >
          Show all calendars
        </Button>
      )}
    </LegacyTooltip>
  );
};
