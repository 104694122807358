import { ApolloCache, useMutation } from "@apollo/client";
import {
  UpdateNotificationStrategyInput,
  UpdatePreferredFocusTime,
  UpdateScheduleAs,
} from "@clockwise/schema";
import { isEqual, merge } from "lodash";
import { useCallback, useMemo } from "react";
import {
  UpdateFocusTimeDocument,
  UpdateFocusTimeMutation,
} from "../graphql/__generated__/UpdateFocusTime.generated";

import { logger } from "@clockwise/client-commons/src/util/logger";
import { FocusTimeSettings } from "../types";

export const useFocusTimeUpdate = (
  orgId: string,
  focusTimeSettings: FocusTimeSettings,
  {
    onCompleted,
    onError,
    onUpdate,
  }: {
    onCompleted?: () => void;
    onError?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUpdate?: (cache: ApolloCache<any>) => void;
  },
) => {
  const [updateFocusTime, { loading, error }] = useMutation(UpdateFocusTimeDocument);

  const onErrorHandler = useMemo(() => onError, [onError]);
  const onUpdateHandler = useMemo(() => onUpdate, [onUpdate]);

  const onCompleteHandler = useCallback(
    (expectedData: UpdateFocusTimeMutation) => (data: UpdateFocusTimeMutation) => {
      if (!isEqual(expectedData.UpdateFocusTime, data.UpdateFocusTime)) {
        // @scott - cleanup as part of AI-2044
        // logging to sentry to confrim error severtiy
        logger.error("Focus Time Update: Optimistic update does not match server response.", {
          expectedData,
          data,
        });

        onError?.();
      } else {
        onCompleted?.();
      }
    },
    [onCompleted, onError],
  );

  const update = useCallback(
    (updatedSettings: Partial<FocusTimeSettings>) => {
      const newSettings = merge(focusTimeSettings, updatedSettings);

      const variables = {
        orgId: String(orgId),
        scheduleAllFocusTime: newSettings.threshold === "all",
        scheduleAtMost:
          newSettings.threshold === "all"
            ? { hours: 1 }
            : { hours: newSettings.threshold.shiftTo("hours").hours },
        preferredFocusIn: UpdatePreferredFocusTime[newSettings.preferredFocusIn],
        scheduleAs: UpdateScheduleAs[newSettings.scheduleAs],
        notificationStrategy: UpdateNotificationStrategyInput[newSettings.notificationStrategy],
        autoDeclineEnabled: newSettings.autoDeclineSettings.enabled,
        autoDeclineThreshold: {
          hours: newSettings.autoDeclineSettings.threshold.shiftTo("hours").hours,
        },
        dailyMaxEnabled: newSettings.dailyMaxSettings.enabled,
        dailyMaxAmount: {
          hours: newSettings.dailyMaxSettings.maxAmount.shiftTo("hours").hours,
        },
      };

      const optimisticResponse: UpdateFocusTimeMutation = {
        UpdateFocusTime: {
          focusTimeGoal: {
            id: newSettings.id,
            enabled: newSettings.enabled,
            threshold:
              newSettings.threshold === "all"
                ? { __typename: "AllFocusTime" }
                : { __typename: "SomeFocusTime", idealFocus: newSettings.threshold.toISO() },
            remoteHoldSettings: {
              scheduleAs: newSettings.scheduleAs,
              notificationStrategy: newSettings.notificationStrategy,
              __typename: "RemoteSmartHoldSettings",
            },
            preferredFocusIn: newSettings.preferredFocusIn,
            autoDeclineSettings: {
              __typename: "AutoDeclineSettings",
              enabled: newSettings.autoDeclineSettings.enabled,
              threshold: newSettings.autoDeclineSettings.enabled
                ? newSettings.autoDeclineSettings.threshold.toISO()
                : "PT2H", // webserver changes threshold to 2 hours if auto decline is disabled
            },
            dailyMaxSettings: {
              __typename: "DailyMaxSettings",
              enabled: newSettings.dailyMaxSettings.enabled,
              maxAmount: newSettings.dailyMaxSettings.maxAmount.toISO(),
            },
            __typename: "FocusTimeGoals",
          },
          __typename: "UpdateFocusTimeGoalResponse",
        },
        __typename: "Mutation",
      };

      return updateFocusTime({
        variables,
        optimisticResponse,
        onCompleted: onCompleteHandler(optimisticResponse),
        onError: onErrorHandler,
        update: onUpdateHandler,
      });
    },
    [focusTimeSettings, orgId, updateFocusTime, onCompleteHandler, onErrorHandler, onUpdateHandler],
  );

  return { update, loading, error };
};

export default useFocusTimeUpdate;
