import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { RepeatingEventSaveOption } from "@clockwise/schema";

export const shouldUpdateThisAndFutureEvents = (
  saveOption: RepeatingEventSaveOption,
  oldRecurrence: RecurrenceRule | null,
  newRecurrence: RecurrenceRule | null,
) => {
  // Also return true if user is turning off recurrence
  if (!newRecurrence && !!oldRecurrence) {
    return RepeatingEventSaveOption.ThisAndFutureInstances;
  }
  return saveOption;
};
