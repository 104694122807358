import { graphql } from "react-relay";

export const eventCategoryColoringFragments = {
  org: graphql`
    fragment EventCategoryColoring_org on Org {
      id
      primaryOrgCalendar
    }
  `,
  eventCategoryColoring: graphql`
    fragment EventCategoryColoring_eventCategoryColoring on EventCategoryColoring {
      eventCategory
      googleColorKey
      active
      isUserSet
    }
  `,
};
