import { ApolloError } from "@apollo/client";
import { EditEventAppliesTo } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useCallback } from "react";
import { CalendarEventsDocument } from "../../web-app-calendar/apollo/__generated__/CalendarEvents.v2.generated";
import { DeleteEventDocument, DeleteEventMutation } from "./__generated__/DeleteEvent.v2.generated";

export const useDeleteEvent = () => {
  const [deleteEvent, { loading, error }] = useGatewayMutation(DeleteEventDocument);

  const onDeleteEvent = useCallback(
    async ({
      calendarId,
      email,
      externalEventId,
      appliesTo,
      callback,
      errorCallback,
      optimisticResponse,
    }: {
      calendarId: string;
      email: string;
      externalEventId: string;
      appliesTo: EditEventAppliesTo;
      callback?: (data: DeleteEventMutation) => void;
      errorCallback?: (error: ApolloError) => void;
      optimisticResponse?: DeleteEventMutation;
    }) => {
      await deleteEvent({
        variables: {
          calendarId,
          email,
          externalEventId,
          appliesTo,
        },
        onCompleted: (data) => {
          callback?.(data);
        },
        onError: (error) => {
          errorCallback?.(error);
        },
        optimisticResponse,
        refetchQueries: [CalendarEventsDocument],
        awaitRefetchQueries: true,
      });
    },
    [deleteEvent],
  );

  return { onDeleteEvent, loading, error };
};
