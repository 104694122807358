import { Button } from "@clockwise/design-system";
import { ArrowForward, Check, ChevronRight, LightbulbFilled } from "@clockwise/icons";
import { ModifyDiffSummary } from "@clockwise/schema";
import { ProposalAppliesTo } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useReadCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import { Interval } from "luxon";
import React from "react";
import { TrackingEvents, track } from "../../../../../util/analytics.util";
import calendarConflictSvg from "./assets/calendar-conflict.svg";

import { canAccessExperiments } from "#webapp/src/util/auth.util";
import classNames from "classnames";
import {
  OddProposal,
  useUpdateDefragProposal,
} from "../../../../chat-plus-calendar/DefragProposalContext";
import { useUpdateHoverEvent } from "../../../../chat-plus-calendar/util/HoverEventContext";
import {
  emptyTimeSuggestionsState,
  useUpdateTimeSuggestionPeek,
} from "../../../../chat-plus-calendar/util/TimeSuggestionPeekContext";
import { EventCardCollapsed } from "../../../../event-card/EventCardCollapsed";
import { EventCardAttendee } from "../../../../event-card/types";
import { ConfirmProposalDocument } from "../../../../reschedule-confirmation-modal/__generated__/ConfirmProposal.v2.generated";
import { RemoveTradeoffFromProposalDocument } from "../../../../tradeoffs/__generated__/RemoveTradeoffFromProposal.v2.generated";
import { eventToast } from "../../../../web-app-calendar/notifiation-event/EventToast";
import { useAIMessageContext } from "../../hooks/AIMessageContext";
import { SchedulingOptionsTradeoffs } from "../../utils/types";
import { AIMarkdown } from "../ai-markdown";
import { CalendarScores } from "./CalendarScores";
import { RawCalendarScores } from "./types";

export const OnDemandDefrag = ({ proposal }: { proposal: OddProposal["proposal"] }) => {
  const { setProcessingMessage } = useAIMessageContext();
  const [showConflicts, setShowConflicts] = React.useState(false);
  const [showNonConflicts, setShowNonConflicts] = React.useState(false);
  const { removeDiffInOnDemandDefrag, cancelOnDemandDefragRequest } = useUpdateDefragProposal();
  const { visibleDates } = useReadCalendar();
  const [removeTradeoffFromProposal] = useGatewayMutation(RemoveTradeoffFromProposalDocument);
  const { setHoveredTimeSuggestion } = useUpdateTimeSuggestionPeek();

  const updateHoverEvent = useUpdateHoverEvent();

  const [confirmProposal, { loading: confirmingProposal }] = useGatewayMutation(
    ConfirmProposalDocument,
    {
      refetchQueries: ["CalendarEventsQuery"],
      awaitRefetchQueries: true,
    },
  );

  const handleRemovalOfTradeoff = (diffId: string) => {
    if (!diffId) {
      return;
    }
    track(TrackingEvents.CHAT.ODD.ODD_DIFF_REMOVED, { diffId });
    void removeTradeoffFromProposal({
      variables: { eventDiffId: diffId },
      onCompleted: () => {
        removeDiffInOnDemandDefrag(diffId);
      },
      onError: () => {
        eventToast.error({ title: "Failed to remove tradeoff", operation: "CANCEL" });
      },
    });
  };

  const handleSaveAndFix = async () => {
    if (!proposal?.id) return;
    setProcessingMessage(false);
    try {
      track(TrackingEvents.CHAT.ODD.ODD_ACCEPTED, { proposalId: proposal.id });
      const result = await confirmProposal({
        variables: {
          proposalId: proposal.id,
          appliesTo: ProposalAppliesTo.Instance,
        },
      });
      if (result.data?.confirmProposal) {
        eventToast.success({ title: "Event moved succesfully", operation: "RESCHEDULE" });
        cancelOnDemandDefragRequest();
      } else {
        throw new Error("Failed to confirm proposal");
      }
    } catch (error) {
      console.log(`Failed to confirm proposal: ${error.message}`, error);
    }
  };

  const onMouseOver = (hover: boolean, diff: ModifyDiffSummary) => {
    const isDiffOnCurrentWeek = visibleDates.includes(
      Interval.fromISO(diff.currentTime).start.toFormat("yyyy-MM-dd"),
    );
    if (hover) {
      setTimeout(() => {
        updateHoverEvent(diff.id);
      }, 10);
      const interval = diff.updatedTime as string;
      const tradeoffBlocks = diff.tradeoffBlocks as SchedulingOptionsTradeoffs;
      if (!isDiffOnCurrentWeek) {
        setHoveredTimeSuggestion({
          interval,
          tradeoffs: tradeoffBlocks,
          attendeeCount: diff.attendees.proposalAttendees.length,
        });
      }
    } else {
      setHoveredTimeSuggestion(emptyTimeSuggestionsState);
      updateHoverEvent(null);
    }
  };

  const diffs = (proposal?.diffBlocks?.[0]?.diffs as unknown) as ModifyDiffSummary[];
  const conflictDiffs = diffs.filter((diff) => diff.resolvedAnyConflicts);
  const nonConflictDiffs = diffs.filter((diff) => !diff.resolvedAnyConflicts);
  const isInternal = canAccessExperiments();
  return (
    <div className="cw-flex cw-flex-col cw-gap-2">
      <AIMarkdown
        text={
          "Sure! I will move events to *resolve conflicts* and create more uninterrupted time in your day."
        }
      ></AIMarkdown>
      {/* TODO: Clean up the below code into seperate components, if prototype is approved */}
      {/* Conflict Portion */}
      {Boolean(conflictDiffs?.length) && (
        <div
          onClick={() => setShowConflicts(!showConflicts)}
          className="cw-px-2 cw-py-1.5 cw-rounded-xl cw-flex cw-items-center cw-justify-between cw-cursor-pointer cw-bg-neutral-inset hover:cw-bg-neutral-inset-hover cw-border cw-border-solid cw-border-muted"
        >
          <div className="cw-flex cw-items-center cw-gap-2">
            <img src={calendarConflictSvg} />
            <div>
              <span className="cw-font-semibold">{conflictDiffs?.length} events </span>moved to
              resolve conflicts
            </div>
          </div>
          <ChevronRight
            className={classNames("cw-text-positive cw-w-4 cw-h-4 cw-transition-transform", {
              "cw-rotate-90": showConflicts,
            })}
          />
        </div>
      )}
      {showConflicts &&
        conflictDiffs?.map((diff) => {
          const attendees = diff.attendees.proposalAttendees.map(
            (attendee) => attendee.attendeePerson,
          ) as EventCardAttendee[];
          const rawCalendarScores: RawCalendarScores = diff.rawCalendarScores;
          return (
            <div key={diff.id} className="cw-flex cw-flex-col cw-gap-2">
              <EventCardCollapsed
                key={diff.id}
                eventName={diff.title}
                subTextIcon={ArrowForward}
                subText={diff?.action?.label || ""}
                onRemoveCard={() => {
                  handleRemovalOfTradeoff(diff.id);
                }}
                attendeePeople={attendees}
                onMouseHover={(hover) => {
                  onMouseOver(hover, diff);
                }}
              />
              {rawCalendarScores && isInternal && (
                <CalendarScores scores={rawCalendarScores} eventId={diff.eventId} />
              )}
            </div>
          );
        })}
      {/* Non Conflict Portion (FT saved) */}
      {Boolean(nonConflictDiffs?.length) && (
        <div
          onClick={() => setShowNonConflicts(!showNonConflicts)}
          className="cw-px-2 cw-py-1.5 cw-rounded-xl cw-flex cw-items-center cw-justify-between cw-cursor-pointer cw-bg-neutral-inset hover:cw-bg-neutral-inset-hover cw-border cw-border-solid cw-border-muted"
        >
          <div className="cw-flex cw-items-center cw-gap-2">
            <LightbulbFilled className="cw-w-4 cw-h-4 cw-text-busy" />
            <div>
              <span className="cw-font-semibold">{nonConflictDiffs?.length} events </span>moved to
              create Focus Time
            </div>
          </div>
          <ChevronRight
            className={classNames("cw-text-positive cw-w-4 cw-h-4 cw-transition-transform", {
              "cw-rotate-90": showNonConflicts,
            })}
          />
        </div>
      )}
      {showNonConflicts &&
        nonConflictDiffs?.map((diff) => {
          const attendees = diff.attendees.proposalAttendees.map(
            (attendee) => attendee.attendeePerson,
          ) as EventCardAttendee[];
          const rawCalendarScores: RawCalendarScores = diff.rawCalendarScores;

          return (
            <div key={diff.id} className="cw-flex cw-flex-col cw-gap-2">
              <EventCardCollapsed
                key={diff.id}
                eventName={diff.title}
                subTextIcon={ArrowForward}
                subText={diff?.action?.label || ""}
                onRemoveCard={() => {
                  handleRemovalOfTradeoff(diff.id);
                }}
                attendeePeople={attendees}
                onMouseHover={(hover) => {
                  onMouseOver(hover, diff);
                }}
              />
              {rawCalendarScores && isInternal && (
                <CalendarScores scores={rawCalendarScores} eventId={diff.eventId} />
              )}
            </div>
          );
        })}

      <div className="cw-flex cw-gap-2">
        <Button
          disabled={confirmingProposal}
          size="xsmall"
          sentiment="positive"
          onClick={handleSaveAndFix}
          startIcon={Check}
        >
          Confirm
        </Button>
        <Button
          disabled={confirmingProposal}
          size="xsmall"
          variant="outlined"
          onClick={() => {
            track(TrackingEvents.CHAT.ODD.ODD_CANCELED, { proposalId: proposal?.id });
            setProcessingMessage(false);
            cancelOnDemandDefragRequest();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
