import React from "react";
import { TrackingEvents, track } from "../../../../../util/analytics.util";
import { InvitePanel } from "../../ap-members/bulk-invite/BulkInvite";
import { APBillingGroup, SimplePlan } from "../apBillingGroups.util";
import { SsoAdminPortalButton } from "../sso-admin-portal-button";
import { FirstButton, SecondButton } from "./CardButtons";
import { CardCopy } from "./CardCopy";
import { SeatCount } from "./SeatCount";
import { ToggleInvitePolicy } from "./ToggleInvitePolicy";

const boxShadowStyle = { boxShadow: "3px 3px 0px #003F2E" };

export type APPrimaryBillingCardProps = {
  orgDomain?: string;
  bg: APBillingGroup | null;
  orgId: string;
  onSaveSeatRequest: (bgId: string, newSeatCount: number) => void;
  refetchBillingsPage: () => void;
};

export const APPrimaryBillingCard = ({
  orgDomain,
  bg,
  orgId,
  onSaveSeatRequest,
  refetchBillingsPage,
}: APPrimaryBillingCardProps) => {
  const [inviteDialogOpen, setInviteDialogOpen] = React.useState(false);

  const savingSeatChangeRequest = (finalRequestedCount: number) => {
    if (bg) {
      track(TrackingEvents.ADMIN_PANEL.APBILLING_REQUEST_SEATCOUNT_CHANGE, {
        currentUserCount: bg.userCount,
        currentSeatCount: bg.seatCount,
        billingGroupId: bg.id,
        requestedSeatCount: finalRequestedCount,
      });
      onSaveSeatRequest(bg.id, finalRequestedCount);
    }
  };

  return (
    <div
      style={boxShadowStyle}
      className="cw-w-full cw-border cw-rounded-md cw-border-solid cw-border-brand-emphasis-pressed"
    >
      <div className="cw-px-7 cw-body-lg">
        <div className="cw-heading-xl cw-mt-5">
          {bg?.teamInfo?.teamName ||
            bg?.bgName ||
            "Upgrade now to unlock the full potential of Clockwise"}
        </div>
        <CardCopy
          planType={bg?.planType || SimplePlan.Free}
          teamInfo={bg?.teamInfo || null}
          userCount={bg?.userCount || 0}
          adminList={bg?.adminList || []}
        />
        {bg?.upcomingBillingDetails && (
          <SeatCount
            isAdmin={bg.isAdmin}
            isTeams={bg.planType === SimplePlan.Teams}
            upcomingBillingDetails={bg.upcomingBillingDetails}
            onSaveSeatRequest={savingSeatChangeRequest}
          />
        )}
        <div className="cw-flex cw-mb-5 cw-gap-3">
          <FirstButton
            planType={bg?.planType || SimplePlan.Free}
            canSeeSCIM={!!bg?.canSeeSCIM}
            onAddUsers={() => {
              track(TrackingEvents.ADMIN_PANEL.APBILLING_CLICK_ADD_USERS);
              setInviteDialogOpen(true);
            }}
            scimButton={
              <SsoAdminPortalButton
                orgId={orgId}
                billingGroupId={bg?.id || ""}
                isAdmin={!!bg?.isAdmin}
                isSSO={!!bg?.canSeeSCIM}
              />
            }
          />
          <SecondButton
            planType={bg?.planType || SimplePlan.Free}
            stripeURL={bg?.stripeUrl || null}
          />
        </div>
      </div>
      {!!bg?.canToggleInvitePolicy && (
        <div className="cw-flex cw-gap-4 cw-px-7 cw-items-center cw-py-5 cw-border-t cw-border-solid cw-border-brand-emphasis-pressed">
          <ToggleInvitePolicy
            orgId={orgId}
            bgId={bg.id}
            invitePolicy={bg.invitePolicy}
            orgDomain={orgDomain}
          />
        </div>
      )}
      <InvitePanel
        open={inviteDialogOpen}
        onClose={() => setInviteDialogOpen(false)}
        onCloseAfterInvite={() => {
          setInviteDialogOpen(false);
          refetchBillingsPage();
        }}
      />
    </div>
  );
};
