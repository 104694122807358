// schema
// import * as ISchema from 'src/__schema__';

// libraries
import * as React from "react";
import { createFragmentContainer } from "react-relay";

// sudo-switch-user imports
import { styles as sudoStyles } from "#webapp/src/components/sudo-wrapper/SudoWrapper.styles";
import { sudoDeactivateUserFragments } from "./SudoDeactivateUser.gql";
import { styles } from "./SudoDeactivateUser.styles";
import { IContainer, IProps, IState } from "./SudoDeactivateUserTypes";

// state

// util
import { sudoDeactivateUser } from "#webapp/src/mutations";

// material-ui
import { Button, LinearProgress, TextField } from "@clockwise/design-system";
import { Paper } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import toast from "react-hot-toast";

///////////////
// Component //
///////////////
class SudoDeactivateUserCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
      email: "",
    };
  }

  public handleEmailChange = (e: any) => {
    this.setState({
      email: e.target.value,
    });
  };

  private onClick = () => {
    const email = this.state.email.trim();
    if (!email) {
      return;
    }

    const CONFIRMATION_CODE = "DEACTIVATE";
    const confirmationText = window.prompt(`Please confirm by typing ${CONFIRMATION_CODE}`, "");
    if (confirmationText !== CONFIRMATION_CODE) {
      return;
    }

    this.setState({ loading: true });

    sudoDeactivateUser(
      this.props.relay.environment,
      { email },
      () => this.onDeactivateSuccess(),
      () => this.onDeactivateFailure(),
    );
  };

  private onDeactivateSuccess = () => {
    this.setState({ loading: false });
    toast.success(`Success, deactivated: ${this.state.email}`);
  };

  private onDeactivateFailure = () => {
    this.setState({ loading: false });
    toast.error(`Failed to deactivate user.`);
  };

  public render() {
    return (
      <Paper
        elevation={2}
        className="cw-font-body cw-p-5 cw-flex cw-flex-grow cw-flex-col cw-relative cw-gap-4"
      >
        <div style={sudoStyles.title}>Deactivate User</div>
        <div style={sudoStyles.row}>
          <TextField
            label="Email Address"
            onChange={this.handleEmailChange}
            value={this.state.email}
          />
        </div>
        <div style={sudoStyles.row}>
          <Button
            sentiment="destructive"
            onClick={() => this.onClick()}
            disabled={this.state.loading}
          >
            Deactivate user
          </Button>
        </div>
        {this.state.loading ? <LinearProgress /> : null}
      </Paper>
    );
  }
}

export const SudoDeactivateUserStyled = withStyles(styles)(SudoDeactivateUserCmpt);

export const SudoDeactivateUser = createFragmentContainer<IContainer>(
  SudoDeactivateUserStyled,
  sudoDeactivateUserFragments,
);
