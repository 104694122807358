import {
  addCalendarIds,
  removeCalendarIds,
} from "#webapp/src/state/actions/multi-calendar.actions";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { Visibility, VisibilityOff } from "@clockwise/icons";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListItemButton } from "../../calendar-popover/atoms/ListItemButton";
import { EventDetailsAttendees } from "../hooks/useEventPopoverDetails";

export const ToggleAttendeeCalendars = ({
  loading,
  attendees,
}: {
  loading: boolean;
  attendees: EventDetailsAttendees;
}) => {
  const dispatch = useDispatch();

  const attendeeCalendarIds = attendees.map((a) => a.person.id);
  const calendarIdsToFullyShow = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsToFullyShow,
  );
  const attendeeCalendarsToggledOn = attendeeCalendarIds.every((id) =>
    calendarIdsToFullyShow.includes(id),
  );

  useEffect(() => {
    return () => {
      // This toggle attendee calendars off
      // when the component unmounts
      dispatch(removeCalendarIds(attendeeCalendarIds));
    };
  }, []);

  const onToggle = () => {
    if (attendeeCalendarsToggledOn) {
      dispatch(removeCalendarIds(attendeeCalendarIds));
    } else {
      dispatch(addCalendarIds(attendeeCalendarIds));
    }
  };

  if (loading)
    return (
      <div className="cw-py-1 cw-my-1 cw-px-1 cw-flex cw-items-center cw-gap-2">
        <Skeleton variant="circle" height={16} width={16} className="cw-ml-[2px]" />
        <Skeleton variant="rect" height={24} className="cw-flex-grow cw-rounded-md" />
      </div>
    );

  return (
    <>
      <ListItemButton
        startIcon={attendeeCalendarsToggledOn ? VisibilityOff : Visibility}
        onClick={() => onToggle()}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onToggle();
          }
        }}
      >
        {attendeeCalendarsToggledOn ? "Hide attendees' calendars" : "Show attendees' calendars"}
      </ListItemButton>
    </>
  );
};
