import { setDeactivated, setUninstalled } from "#webapp/src/state/actions/auth.actions";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { PageEvents, page } from "#webapp/src/util/analytics.util";
import { useMutation } from "@apollo/client";
import { userDeactivated } from "@clockwise/web-commons/src/util/local-storage";
import React, { ReactNode, useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLogout } from "../hooks/useLogout";
import { DeactivationComplete } from "./DeactivationComplete";
import { DeactivationLogin } from "./DeactivationLogin";
import { DeactivationSurvey } from "./DeactivationSurvey";
import { RecordChromeUninstallDocument } from "./__generated__/HomeUninstall.generated";

export const HomeUninstall = () => {
  const authenticated = useSelector((state: IReduxState) => state.auth.authed);
  const deactivated = useSelector((state: IReduxState) => state.auth.deactivated);
  const uninstalled = useSelector((state: IReduxState) => state.auth.uninstalled);

  const [recordChromeUninstall] = useMutation(RecordChromeUninstallDocument, {
    variables: { input: {} },
  });

  useEffect(() => {
    if (!uninstalled) {
      page(PageEvents.HOME_UNINSTALL);
      void recordChromeUninstall();
      dispatch(setUninstalled(true));
    }
  }, []);

  const dispatch = useDispatch();
  const [logout] = useLogout();

  let content: ReactNode;
  if (deactivated || userDeactivated.get()) {
    content = <DeactivationComplete />;
  } else if (!authenticated) {
    content = <DeactivationLogin />;
  } else {
    content = (
      <DeactivationSurvey
        onDeactivate={() => {
          dispatch(setDeactivated(true));
          void logout();
        }}
      />
    );
  }

  const description = "Help with uninstalling Clockwise";

  return (
    <div>
      <Helmet>
        <title>Uninstall | Clockwise</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta itemProp="description" content={description} />
      </Helmet>
      {content}
    </div>
  );
};
