import { logger } from "@clockwise/client-commons/src/util/logger";
import { Button, Checkbox, Select, SelectOption, Tooltip } from "@clockwise/design-system";
import { BillingGroup } from "@clockwise/schema";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow,
} from "@material-ui/core";
import * as React from "react";
import {
  getBillingGroupText,
  getSignUpDateText,
  getStatusText,
  hasPermissionToRemove,
  isAdminOfBg,
} from "./member-row.util";

import {
  BillingGroupRoleEnum,
  NewMemberPerson,
  SearchPersonStatusResponse,
} from "../../APMembers.util";

import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { useSelectTable } from "../../../../hooks/useSelectTable";
import { CustomRequestToJoinDialog } from "../../../ap-billing-groups/CustomRequestToJoinDialog";
import { useAPMembers } from "../../useAPMembers";
import { getFullName } from "../members-content.util";
import { MemberRowMoreOptions } from "./member-row-more-options";

export interface MemberRowProps {
  person: NewMemberPerson;
  isChecked: boolean;
}

export const MemberRow = ({ person, isChecked }: MemberRowProps) => {
  const { isSelectTableDisabled, onSelectOneMember, isUserAdmin } = useSelectTable();

  const { onAdminSelectChange, onRemoveMember, currentUserBgIdList } = useAPMembers();

  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [dialogBillingGroup, setDialogBillingGroup] = React.useState<BillingGroup | null>(null);
  const [customRequestToJoinDialogOpen, setCustomRequestToJoinDialogOpen] = React.useState<boolean>(
    false,
  );

  const statusText = getStatusText(person);

  const name = getFullName({
    givenName: person.givenName,
    familyName: person.familyName,
  });

  // ~-~-~-~-~-~-~-
  // Callbacks
  // ~-~-~-~-~-~-~-
  const handleAdminSelectChange = (role: BillingGroupRoleEnum) => {
    const eMsg = `cannot select billing group role`;

    if (!person.billingGroups.length || !person.primaryBillingGroup) {
      logger.error(`${eMsg} because no billingGroup`);
      return;
    }

    if (!isAdminOfBg(person.primaryBillingGroup)) {
      logger.error(`${eMsg} because user is not an admin`);
      return;
    }

    if (!person.userId) {
      logger.error(`${eMsg} because no userId`);
      return;
    }

    const lastAdminError =
      role !== BillingGroupRoleEnum.Admin &&
      person.primaryBillingGroup?.adminOrgPersons.__typename === "OrgPersonList" &&
      person.primaryBillingGroup.adminOrgPersons.list.length <= 1
        ? `You're the only admin. Please assign another admin before you leave this group.`
        : ``;

    void onAdminSelectChange(
      person.userId,
      person?.primaryBillingGroup.id,
      role,
      person.personId,
      lastAdminError,
    );
  };

  const closeRemoveDialog = () => {
    setDialogOpen(false);
    setDialogBillingGroup(null);
  };

  const areYouLastAdmin = (bg: BillingGroup) => {
    if (bg.adminOrgPersons.__typename === "OrgPersonList") {
      if (isUserAdmin(bg) && bg.adminOrgPersons.list.length === 1) {
        return true;
      }
    }
    return false;
  };

  const handleRemoveMember = (p: NewMemberPerson, bg: BillingGroup | null) => {
    const eMsg = `cannot remove member from billing group`;

    if (!bg) {
      logger.error(`${eMsg} because no billingGroup`);
      return;
    }

    if (!hasPermissionToRemove(bg, currentUserBgIdList, [p.isYou])) {
      logger.error(`${eMsg} because user is not an admin`);
      return;
    }

    const lastAdminError =
      hasPermissionToRemove(bg, currentUserBgIdList, [p.isYou]) && p.isYou && areYouLastAdmin(bg)
        ? `You're the only admin. Please assign another admin before you leave this group.`
        : ``;
    void onRemoveMember([p.personId], bg.id, lastAdminError);
    closeRemoveDialog();
  };

  const handleCloseCustomRequestToJoinDialog = () => {
    setCustomRequestToJoinDialogOpen(false);
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-

  const maybeRenderAdminSelect = () => {
    if (!person.primaryBillingGroup || person.status !== SearchPersonStatusResponse.User) {
      return;
    }

    const showForm = isAdminOfBg(person.primaryBillingGroup);
    const hasRemoveAccess = hasPermissionToRemove(person.primaryBillingGroup, currentUserBgIdList, [
      person.isYou,
    ]);

    return showForm ? (
      <Tooltip
        title={
          !hasRemoveAccess
            ? `You don't have permission to manage this user. To request changes, please contact a plan admin.`
            : ``
        }
      >
        <div>
          <Select
            value={person.role}
            onChange={handleAdminSelectChange}
            disabled={!hasRemoveAccess}
          >
            <SelectOption value={BillingGroupRoleEnum.Admin}>Admin</SelectOption>
            <SelectOption value={BillingGroupRoleEnum.Member}>Member</SelectOption>
          </Select>
        </div>
      </Tooltip>
    ) : (
      <span> {person.role} </span>
    );
  };

  return (
    <>
      <TableRow className="cw-border-0 cw-border-b cw-border-solid cw-border-default">
        {!isSelectTableDisabled && (
          <TableCell>
            <Checkbox
              size="small"
              checked={isChecked}
              onChange={() => onSelectOneMember(person)}
              disabled={isSelectTableDisabled}
            />
          </TableCell>
        )}
        <TableCell className="cw-truncate cw-min-w-[150px]">
          <div className="cw-flex cw-items-center cw-border-none cw-gap-2">
            <AttendeeAvatar
              profile={person}
              size="xlarge"
              iconType={status === SearchPersonStatusResponse.User ? "logo" : undefined}
            />
            <div>
              <div className="cw-flex cw-items-center">
                <div className="cw-text-default">{`${name}${person.isYou ? " (You)" : ""}`}</div>
                {statusText ? (
                  <div className="cw-rounded cw-text-onEmphasis cw-bg-warning-emphasis cw-caption cw-text-11 cw-py-[2px] cw-px-1 cw-ml-1">
                    {statusText}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="cw-body-base cw-text-muted">{person.targetCalendarId}</div>
            </div>
          </div>
        </TableCell>
        <TableCell>{getSignUpDateText(person)}</TableCell>
        <TableCell className="cw-break-words">{getBillingGroupText(person)}</TableCell>
        <TableCell>{maybeRenderAdminSelect()}</TableCell>
        <TableCell>
          <MemberRowMoreOptions
            person={person}
            setDialogOpen={setDialogOpen}
            setDialogBillingGroup={setDialogBillingGroup}
            setCustomRequestToJoinDialogOpen={setCustomRequestToJoinDialogOpen}
          />
        </TableCell>
      </TableRow>
      <Dialog open={dialogOpen}>
        <DialogTitle>
          <h2 className="cw-heading-xl cw-font-bold">
            {person.isYou
              ? `Leave ${dialogBillingGroup?.name ?? "billing group"}?`
              : `Remove ${name} from ${dialogBillingGroup?.name ?? "billing group"}?`}
          </h2>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <span className="cw-body-base">
            {person.isYou
              ? `Are you sure you want to remove your own access? You will lose access to all existing
            paid features.`
              : `Are you sure you want to remove this person? They will lose access to all existing paid features.`}
          </span>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={closeRemoveDialog}>
            Cancel
          </Button>
          <Button
            variant="text"
            sentiment="destructive"
            onClick={() => handleRemoveMember(person, dialogBillingGroup)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <CustomRequestToJoinDialog
        open={customRequestToJoinDialogOpen}
        calendarId={person.targetCalendarId}
        handleClose={handleCloseCustomRequestToJoinDialog}
      />
    </>
  );
};
