import { Sentiment } from "@clockwise/design-system";
import * as colors from "@clockwise/design-system/tokens";
import { SvgIconComponent } from "@material-ui/icons";
import React, { CSSProperties } from "react";

export const Sticker = ({
  Icon,
  sentiment = "neutral",
  size = "inline",
  text,
  maxWidth,
}: {
  backgroundColor?: string;
  color?: string;
  Icon?: SvgIconComponent;
  sentiment?: Sentiment | "busy";
  size?: "inline" | "small";
  text: string;
  maxWidth?: number;
}) => {
  if (!text) return null;

  const styles: CSSProperties = {
    backgroundColor: colors.bg_neutral_inset,
    color: colors.fg_neutral,
    fontSize: "10px",
    maxWidth: maxWidth ? `${maxWidth}px` : "auto",
  };

  const iconStyles: CSSProperties = {
    color: colors.fg_neutral,
    fontSize: "14px",
  };

  switch (sentiment) {
    case "positive":
      styles.color = colors.fg_accent;
      iconStyles.color = colors.fg_accent;
      styles.backgroundColor = colors.bg_accent;
      break;
    case "info":
      styles.color = colors.fg_info;
      iconStyles.color = colors.fg_info;
      styles.backgroundColor = colors.bg_info_inset;
      break;
    case "busy":
      styles.color = colors.fg_busy;
      iconStyles.color = colors.fg_busy;
      styles.backgroundColor = colors.bg_busy_inset;
      break;
    case "warning":
      styles.color = colors.fg_warning;
      iconStyles.color = colors.fg_warning;
      styles.backgroundColor = colors.bg_warning_inset;
      break;
    case "destructive":
      styles.color = colors.fg_destructive;
      iconStyles.color = colors.fg_destructive;
      styles.backgroundColor = colors.bg_destructive_inset;
      break;
    case "accent":
      styles.color = colors.bg_accent_emphasis;
      iconStyles.color = colors.bg_accent_emphasis;
      styles.backgroundColor = colors.border_accent;
      break;
  }

  switch (size) {
    case "small":
      styles.fontSize = "14px";
      iconStyles.fontSize = "16px";
      break;
  }

  return (
    <div
      className="cw-body-sm cw-font-semibold cw-px-[5px] cw-rounded cw-w-fit cw-flex cw-items-center cw-space-x-0.5 cw-leading-[14px] cw-py-0.5"
      style={styles}
    >
      <div className="cw-overflow-hidden cw-text-ellipsis">{text}</div>
      {Icon && <Icon style={iconStyles} />}
    </div>
  );
};
