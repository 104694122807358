import { Divider } from "@clockwise/design-system";
import { useTrackPageview } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useRef } from "react";
import { QueryRenderer, ReadyState } from "react-relay";
import { IOrg } from "../../../__schema__";
import { WebSettingsContainer } from "../../../components/web-settings/web-settings-container";
import { getCurrentEnvironment } from "../../../state/relay-environment";
import { PageEvents } from "../../../util/analytics.util";
import { handleReadyStateError } from "../../../util/relay.util";
import { TeamAvailabilityCalendar } from "./TeamAvailabilityCalendar";
import { TeamDelete } from "./TeamDelete";
import { TeamMembers } from "./TeamMembers";
import { TeamNoMeetingDay } from "./TeamNoMeetingDay";
import { WebSettingsDivider } from "./WebSettingsDivider";
import { query } from "./WebSettingsTeamSettingsNewRelay.gql";

export interface Props {
  orgRelayId: string;
  teamId?: string;
}

export const WebSettingsTeamSettingsNewRelay = ({ teamId, orgRelayId }: Props) => {
  const shouldForce = useRef(true);
  useTrackPageview(PageEvents.WEB_SETTINGS_TEAM, { teamId });
  const environment = getCurrentEnvironment();

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{
        orgRelayId: orgRelayId,
      }}
      cacheConfig={{ force: shouldForce.current }}
      render={(readyState: ReadyState) => {
        // check for error or show a loader for first load
        if (readyState.error) {
          handleReadyStateError(readyState.error, "WebSettingsTeamSettingsRenderer");
          return null;
        }

        const org: IOrg = readyState.props && readyState.props.org;

        if (!org) {
          return null;
        }

        shouldForce.current = false;

        const team =
          (org.userTeams.__typename === "TeamList" &&
            org.userTeams.list.find((t) => t.teamId === teamId)) ||
          null;

        return (
          <WebSettingsContainer>
            {team ? (
              <>
                <WebSettingsDivider title="What you can do to help your team" />
                <div className="cw-space-y-28">
                  <TeamNoMeetingDay teamId={team.teamId} />
                  <TeamAvailabilityCalendar teamId={team.teamId} />
                </div>
                <WebSettingsDivider title="All your teammates" />
                <TeamMembers org={org} team={team} />
                <Divider spacing={64} />
                <TeamDelete org={org} team={team} />
              </>
            ) : (
              <div>Failed to render team</div>
            )}
          </WebSettingsContainer>
        );
      }}
    />
  );
};
