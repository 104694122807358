import { paths } from "#webapp/src/constants/site.constant";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import copyToClipboard from "#webapp/src/util/copyToClipboard";
import { Button, Menu, MenuGroup, MenuItem } from "@clockwise/design-system";
import { fg_muted } from "@clockwise/design-system/tokens";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { formatDuration } from "@clockwise/web-commons/src/util/duration.util";
import { getEditSchedulingLinkPath } from "@clockwise/web-commons/src/util/scheduling.util";
import classNames from "classnames";
import { LinkSimple } from "phosphor-react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "usehooks-ts";
import { ChromeDialog } from "../../chrome-dialog";
import { ShareBestTimesLinkModal } from "../../scheduling-link";
import { LinksDropdownListDocument } from "../apollo/__generated__/LinksDropdownList.v2.generated";

const Link = () => <LinkSimple color={fg_muted} height={16} width={16} />;

type DropdownLink = {
  durations: number[];
  defaultDuration: number;
  linkName: string;
  slug: string;
  name: string;
  url: string;
  hasMultipleUsers: boolean;
};

const LinksItem = ({
  link,
  onOpenShareModal,
}: {
  link: DropdownLink;
  onOpenShareModal: () => void;
}) => {
  const navigate = useNavigate();
  const { value: isHovered, setTrue: setHover, setFalse: setNoHover } = useBoolean(false);

  const formattedDuration =
    link.durations.length > 1
      ? `${link.durations.length} duration options`
      : formatDuration(link.defaultDuration);

  const onCopyLink = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e?.target as HTMLElement;
    const tagName = target?.tagName;
    if (tagName === "BUTTON") return;
    copyToClipboard(link.url);
    track(TrackingEvents.CHAT.LINKS_DROPDOWN.COPY_LINK);
    toast.success("Link copied to clipboard");
  };

  const openShareModal = () => {
    track(TrackingEvents.CHAT.LINKS_DROPDOWN.SHARE_TIMES);
    onOpenShareModal();
  };

  const onEditLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    track(TrackingEvents.CHAT.LINKS_DROPDOWN.EDIT_LINK);
    navigate(getEditSchedulingLinkPath(link.linkName, link.slug));
  };

  return (
    <div className="cw-mt-1" onClick={onCopyLink} onMouseEnter={setHover} onMouseLeave={setNoHover}>
      <MenuItem>
        <div className="cw-flex cw-items-center cw-justify-between cw-w-[265px] cw-mt-[1px]">
          <div>
            <div className="cw-body-sm">{link.name}</div>
            <div className="cw-text-11 cw-text-subtle cw-leading-4">{formattedDuration}</div>
          </div>
          <div
            className={classNames("cw-flex cw-gap-1", {
              "cw-opacity-0": !isHovered,
              "cw-opacity-1": isHovered,
            })}
          >
            <Button onClick={openShareModal} variant="outlined" size="mini">
              Share times
            </Button>
            <Button onClick={onEditLink} variant="outlined" size="mini">
              Edit
            </Button>
          </div>
        </div>
      </MenuItem>
    </div>
  );
};

export const LinksDropdown = () => {
  const [linkToOpenModal, setLinkToOpenModal] = useState<DropdownLink | null>(null);
  const navigate = useNavigate();
  const { data } = useGatewayQuery(LinksDropdownListDocument);

  const onCreateLink = () => {
    track(TrackingEvents.CHAT.LINKS_DROPDOWN.NEW_LINK);
    navigate(paths.createSchedulingLink);
  };

  const allLinks = data?.currentUser?.schedulingLinks;
  const allListableLinks = allLinks
    ?.filter((link) => !link.schedulingLink.singleUse && link.schedulingLink.active)
    .map((link) => {
      return {
        name: link.schedulingLink.name,
        id: link.id,
        url: link.url,
        linkName: link.linkName,
        slug: link.schedulingLink.slug,
        durations: link.schedulingLink.durations,
        defaultDuration: link.schedulingLink.defaultDuration,
        hasMultipleUsers: link.schedulingLink.linkMembers.length > 1,
      };
    });

  return (
    <>
      {linkToOpenModal && (
        <ChromeDialog open>
          <ShareBestTimesLinkModal
            url={linkToOpenModal.url}
            linkName={linkToOpenModal.linkName}
            name={linkToOpenModal.name}
            slug={linkToOpenModal.slug}
            durations={linkToOpenModal.durations || [linkToOpenModal.defaultDuration]}
            defaultDuration={linkToOpenModal.defaultDuration}
            multipleUser={linkToOpenModal.hasMultipleUsers}
            onClose={() => setLinkToOpenModal(null)}
            fullscreen={false}
            renderedFrom={"ai-chat-scheduling-link-card"}
          />
        </ChromeDialog>
      )}
      <Menu label="Links" variant="default" size="small" icon={Link}>
        <MenuGroup label="Click to copy link">
          <div className="cw-max-h-[220px] cw-overflow-auto">
            {allListableLinks && allListableLinks.length > 0 ? (
              allListableLinks.map((link) => (
                <LinksItem
                  onOpenShareModal={() => {
                    setLinkToOpenModal(link);
                  }}
                  link={link}
                  key={link.id}
                />
              ))
            ) : (
              <div className="cw-p-2 cw-text-12">No Active Links</div>
            )}
          </div>

          <div className="cw-mt-2">
            <Button onClick={onCreateLink} fullWidth variant="outlined" size="mini">
              New link
            </Button>
          </div>
        </MenuGroup>
      </Menu>
    </>
  );
};
