import { ExtensionURLs } from "@clockwise/web-commons/src/constants/stores.constant";
import React, { useEffect } from "react";
import { browserName } from "react-device-detect";
import { TrackingEvents, track } from "../../../../util/analytics.util";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { ButtonGroup, StepCounter, StepLayout } from "../../shared-components";
import { DownloadClockwiseSlide } from "./DownloadClockwiseSlide";

export const DownloadClockwiseStep = () => {
  const { goForward, goBack, shouldShowSecondaryButton, funnelType } = useWebOnboardingSteps();

  const isChrome = browserName === "Chrome";
  const isFirefox = browserName === "Firefox";

  useEffect(() => {
    track(TrackingEvents.WEB_ONBOARDING.DOWNLOAD_EXTENSION_VIEWED, { funnelType });
  }, []);

  const openExtensionLink = () => {
    let extensionLink;
    if (isChrome) {
      track(TrackingEvents.WEB_ONBOARDING.CHROME_EXTENSION, { funnelType });
      extensionLink = ExtensionURLs.chrome;
    } else if (isFirefox) {
      track(TrackingEvents.WEB_ONBOARDING.FIREFOX_EXTENSION, { funnelType });
      extensionLink = ExtensionURLs.firefox;
    }

    if (extensionLink) {
      window.open(extensionLink, "Download clockwise");
    }
    goForward();
  };

  const onSkip = () => {
    track(TrackingEvents.WEB_ONBOARDING.DOWNLOAD_EXTENSION_SKIPPED, { funnelType });
    goForward();
  };

  return (
    <StepLayout>
      <StepCounter />
      <DownloadClockwiseSlide browser={browserName} />
      <ButtonGroup
        shouldShowSkip
        shouldShowSecondary={shouldShowSecondaryButton}
        primaryLabel={"Install Extension"}
        onClickPrimary={openExtensionLink}
        onClickSecondary={goBack}
        onSkip={onSkip}
      />
    </StepLayout>
  );
};
