import {
  useReadCalendar,
  useUpdateCalendar,
} from "@clockwise/web-commons/src/util/CalendarContext";
import { includes } from "lodash";
import { useEffect, useMemo } from "react";
import { getEarliestDiffStart } from "../chat/ai-chat/utils/getDiffStart";
import { GQLGenericDiffSummary } from "../chat/ai-chat/utils/types";
import { useSharedProposalContext } from "./hooks/SharedProposalProvider";

export const SharedProposalCalendarDateSync = () => {
  const { visibleDates } = useReadCalendar();
  const calendarDispatch = useUpdateCalendar();

  const { proposal } = useSharedProposalContext();

  const activeDiffSummaries = useMemo(() => {
    const activeDiffSummaries: GQLGenericDiffSummary[] = [];

    if (proposal && proposal.diffBlocks) {
      proposal.diffBlocks.forEach((diffBlock) => {
        diffBlock.diffs.forEach((diffSummary) => {
          if (diffSummary?.active) {
            activeDiffSummaries.push(diffSummary);
          }
        });
      });
    }

    return activeDiffSummaries;
  }, [proposal]);

  /* eslint-disable react-hooks/exhaustive-deps */
  const visibleDatesOnDiffChange = useMemo(() => visibleDates, [
    JSON.stringify(activeDiffSummaries),
    // visibleDates -> IGNOIRE! -> prevent update on user date navigation
  ]);
  /* eslint-enable */

  useEffect(() => {
    const isDayView = visibleDates.length === 1;

    if (activeDiffSummaries.length > 0) {
      const diffDate = getEarliestDiffStart(activeDiffSummaries);

      if (diffDate.isValid) {
        const diffISODate = diffDate.toISODate();
        // visibleDatesOnDiffChange is a list of ISODates
        const isDiffDateVisible = includes(visibleDatesOnDiffChange, diffISODate);

        if (!isDiffDateVisible) {
          calendarDispatch({
            type: isDayView ? "jumpTo-date" : "jumpTo-weekOf",
            payload: diffISODate,
          });
        }
      }
    }
  }, [activeDiffSummaries, calendarDispatch, visibleDatesOnDiffChange]);

  return null;
};
