import { CalendarMode } from "@clockwise/web-commons/src/components/calendar";
import { WeekStartDays } from "@clockwise/web-commons/src/util/calendarReducer";
import { getPreviousWeekday } from "@clockwise/web-commons/src/util/getPreviousWeekday";
import { getSequentialDays } from "@clockwise/web-commons/src/util/getSequentialDays";
import { DateTime } from "luxon";

export const getDaysToRender = (
  renderCalendarMode: CalendarMode,
  selectedDateTime: DateTime,
  startofWeekday: WeekStartDays = "sunday",
  count = 7,
) => {
  if (renderCalendarMode === "day") {
    return [selectedDateTime];
  }
  const startOfWeek = getPreviousWeekday(selectedDateTime, startofWeekday);
  return getSequentialDays(startOfWeek, count);
};
