import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { calculateDayOffsetTimeSlotIndex } from "@clockwise/client-commons/src/util/time-slot";
import { compact } from "lodash";
import {
  EventFlexibilityConstraintsDocument,
  EventFlexibilityConstraintsQuery,
} from "./__generated__/EventFlexibilityConstraints.generated";

export type UseAttendeeWorkingBoundsInput = {
  eventId: string;
  calendarId: string;
  calendarIds: string[];
  useQuery: typeof useQuery;
};

type UseAttendeeWorkingBounds = (
  input: UseAttendeeWorkingBoundsInput,
) => {
  start: string | null;
  end: string | null;
  conflict: boolean;
};

export const useAttendeeWorkingBounds: UseAttendeeWorkingBounds = ({
  eventId,
  calendarId,
  calendarIds: _calendarIds,
  useQuery,
}) => {
  const { data: constraintsData, loading: constraintsLoading } = useQuery(
    EventFlexibilityConstraintsDocument,
    {
      variables: {
        externalEventId: eventId || "",
        calendarId: calendarId || "",
        typedCalendarId: calendarId || "",
      },
      skip: !calendarId || !eventId,
    },
  );

  const dataOrg = getValue(constraintsData?.viewer.user?.orgs.edges?.[0]?.node, "Org");
  const eventParent = getValue(dataOrg?.forceFetchEventParentErrorable);
  const suggestedAutopilotSettingsFetched = getValue(
    eventParent?.events[0]?.suggestedAutopilotSettingsResponseV3,
  );

  const usersWorkingHours = parseWorkingHoursFromEvent(constraintsData);
  const intersectionOfWorkingHours = getUnionOfAllWorkingHours(usersWorkingHours);

  let attendeeIntersectionHours;

  if (suggestedAutopilotSettingsFetched) {
    attendeeIntersectionHours =
      suggestedAutopilotSettingsFetched.intersectionOfAttendeesMeetingHours;
  } else {
    attendeeIntersectionHours = undefined;
  }

  const startTime = convertSlotToTime(intersectionOfWorkingHours?.start ?? null, false);
  const endTime = convertSlotToTime(intersectionOfWorkingHours?.end ?? null, true);

  const meetingHoursConflict =
    !!startTime &&
    !!endTime &&
    !attendeeIntersectionHours &&
    !intersectionOfWorkingHours &&
    !constraintsLoading;

  return {
    start: startTime,
    end: endTime,
    conflict: meetingHoursConflict,
  };
};

const parseWorkingHoursFromEvent = (data: EventFlexibilityConstraintsQuery | undefined) => {
  const dataOrg = getValue(data?.viewer.user?.orgs.edges?.[0]?.node, "Org");
  const eventParent = getValue(dataOrg?.forceFetchEventParentErrorable);
  const suggestedAutopilotSettingsFetched = getValue(
    eventParent?.events[0]?.suggestedAutopilotSettingsResponseV3,
  );
  const calendarIdToMeetingHours = suggestedAutopilotSettingsFetched?.calendarIdToMeetingHours;

  if (!calendarIdToMeetingHours) return [];

  const workingHoursToCalId = compact(
    calendarIdToMeetingHours?.map((range) => {
      const currentTimeSlotRange = range?.dayOffsetTimeSlotRanges?.[0] || undefined;
      return {
        calendarId: range.calendarId,
        workingHours: {
          start: calculateDayOffsetTimeSlotIndex(currentTimeSlotRange?.minSlot || undefined),
          end: calculateDayOffsetTimeSlotIndex(currentTimeSlotRange?.maxSlot || undefined),
        },
      };
    }),
  );

  return workingHoursToCalId;
};

const getUnionOfAllWorkingHours = (
  hours: {
    workingHours: {
      start: number | null;
      end: number | null;
    };
  }[],
) => {
  const allStart = compact(hours.map((hour) => hour.workingHours.start));
  const allEnds = compact(hours.map((hour) => hour.workingHours.end));

  // If anything was removed, we know that someone cannot work this day, so there is no union
  if (allStart.length !== hours.length || allEnds.length !== hours.length) return null;

  if (!allStart.length || !allEnds.length) return null;

  return {
    start: Math.max(...allStart) || null,
    end: Math.min(...allEnds) || null,
  };
};

const convertSlotToTime = (slotInput: number | null, isEnd: boolean): string | null => {
  if (slotInput === null) return null;
  const slot = slotInput + (isEnd ? 1 : 0);
  const hours = Math.floor(slot / 2);
  const minutes = (slot % 2) * 30;
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};
