import { fg_warning } from "@clockwise/design-system/tokens";
import { TradeoffTypeEnum } from "@clockwise/schema";
import { Skeleton } from "@material-ui/lab";
import { isEmpty } from "lodash";
import { CalendarCheck, Clock, Smiley } from "phosphor-react";
import React from "react";
import {
  DiffDetailsById,
  DiffDetailsTradeoffBlock,
  DiffDetailsTradeoffBlocks,
} from "../../chat/ai-chat/utils/types";
import { useEventTradeoffs } from "../hooks/EventTradeoffsContext";
import { ECSingleTradeoff } from "./ECSingleTradeoff";
import { ECTradeoffsNoDrawer } from "./ECTradeoffsNoDrawer";
import { ECTradeoffsWithDrawer } from "./ECTradeoffsWithDrawer";
export const NoConflicts = () => {
  return (
    <ECTradeoffsNoDrawer variant="neutral" text="No conflicts" icon={<CalendarCheck size={16} />} />
  );
};

export const RespectsPreferences = () => {
  return (
    <ECTradeoffsNoDrawer
      variant="neutral"
      text="Respects everyone's preferences"
      icon={<Smiley size={16} />}
    />
  );
};

export const OutOfRequestedTimeRange = () => {
  return (
    <ECTradeoffsNoDrawer
      variant="warning"
      text="Outside requested time range"
      icon={<Clock color={fg_warning} size={16} />}
      tooltipText="This time slot is later than you requested, but it may have better attendee availability."
    />
  );
};

const shouldShowNoConflicts = (tradeoffBlocks: DiffDetailsTradeoffBlocks) => {
  const hasConflict = tradeoffBlocks.some(
    (tradeoff) => tradeoff.tradeoffType === TradeoffTypeEnum.FIXABLE_CONFLICT,
  );
  const hasAvailabilityIssue = tradeoffBlocks.some(
    (tradeoff) => tradeoff.tradeoffType === TradeoffTypeEnum.AVAILABILITY_ISSUE,
  );
  return !hasConflict && !hasAvailabilityIssue;
};

const shouldShowRespectsEveryonesPreferences = (tradeoffBlocks: DiffDetailsTradeoffBlocks) => {
  const hasInconvenience = tradeoffBlocks.some(
    (tradeoff) =>
      tradeoff.tradeoffType === TradeoffTypeEnum.INCONVENIENCE ||
      tradeoff.tradeoffType === TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE,
  );
  const hasAvailabilityIssue = tradeoffBlocks.some(
    (tradeoff) => tradeoff.tradeoffType === TradeoffTypeEnum.AVAILABILITY_ISSUE,
  );
  return !hasInconvenience && !hasAvailabilityIssue;
};

const ECTradeoffWithDrawerFromTradeoffBlock = ({
  tradeoff,
  defaultExpanded,
}: {
  tradeoff: DiffDetailsTradeoffBlock;
  defaultExpanded: boolean;
}) => {
  const tradeoffSummary = { title: tradeoff.title, attendees: tradeoff.aggregateAttendees };
  return (
    <div cw-id={`tradeoff-${tradeoff.tradeoffType}`} className="cw-mt-1">
      <ECTradeoffsWithDrawer
        tradeoffSummary={tradeoffSummary}
        type={tradeoff.tradeoffType}
        tradeoffList={tradeoff.schedulingTradeoffs}
        defaultExpanded={defaultExpanded}
      />
    </div>
  );
};

export const DetailedTradeoffs = ({
  tradeoffs,
  defaultExpandedTradeoff,
}: {
  tradeoffs: DiffDetailsTradeoffBlocks;
  defaultExpandedTradeoff: TradeoffTypeEnum | null;
}) => {
  const isTradeOffExpanded = (type: TradeoffTypeEnum) =>
    !!defaultExpandedTradeoff && defaultExpandedTradeoff === type;

  return (
    <div>
      {tradeoffs.map((tradeoff, index) => {
        if (tradeoff.tradeoffType === TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE) {
          return (
            <ECSingleTradeoff
              tradeoff={null}
              type={tradeoff.tradeoffType}
              title={tradeoff.title}
              key={`${tradeoff.title}-${index}`}
            />
          );
        } else if (tradeoff.schedulingTradeoffs.length === 1) {
          return (
            <ECSingleTradeoff
              tradeoff={tradeoff.schedulingTradeoffs[0]}
              type={tradeoff.tradeoffType}
              title={tradeoff.title}
              key={`${tradeoff.title}-${index}`}
            />
          );
        } else {
          return (
            <ECTradeoffWithDrawerFromTradeoffBlock
              tradeoff={tradeoff}
              key={`${tradeoff.title}-${index}`}
              defaultExpanded={isTradeOffExpanded(tradeoff.tradeoffType)}
            />
          );
        }
      })}
    </div>
  );
};

export const ECTradeoffs: React.FC<
  React.ComponentProps<typeof Tradeoffs> & { tradeoffsNeedRecalculation: boolean }
> = ({
  defaultExpandedTradeoff,
  tradeoffBlocks: proposalTradeoffBlocks,
  tradeoffsNeedRecalculation,
}) => {
  const { tradeoffs: nonSBMTradeoffBlocks, loading, error } = useEventTradeoffs();

  if (loading) {
    return <Skeleton height="35.5px" width="100%" variant="text" />;
  }

  if (error) {
    return (
      <div className={"cw-body-sm cw-text-warning cw-py-1 cw-ml-1"}>
        Failed to calculate inconveniences
      </div>
    );
  }

  // we show tradeoff blocks from the proposal until the user makes edits, then we
  // refetch tradeoffs that don't include SBM. this is for security reasons.
  // (see comments on https://linear.app/getclockwise/issue/AI-6011/[fe]-fixable-conflict-is-shown-as-an-unfixable-conflict-in-the-event)
  const tradeoffBlocks = tradeoffsNeedRecalculation ? nonSBMTradeoffBlocks : proposalTradeoffBlocks;

  return (
    <Tradeoffs defaultExpandedTradeoff={defaultExpandedTradeoff} tradeoffBlocks={tradeoffBlocks} />
  );
};

export const Tradeoffs: React.FC<{
  defaultExpandedTradeoff: TradeoffTypeEnum | null;
  tradeoffBlocks: DiffDetailsById["tradeoffBlocks"];
}> = ({ defaultExpandedTradeoff, tradeoffBlocks }) => {
  const showNoConflicts = shouldShowNoConflicts(tradeoffBlocks);
  const showRespectsPreferences = shouldShowRespectsEveryonesPreferences(tradeoffBlocks);

  if (isEmpty(tradeoffBlocks)) {
    return (
      <div className="cw-w-full cw-flex-col">
        <div className="cw-mt-1" cw-id="tradeoff-no-conflicts">
          <NoConflicts />
        </div>
        <div className="cw-mt-1" cw-id="tradeoff-respects-preferences">
          <RespectsPreferences />
        </div>
      </div>
    );
  }

  const outOfTimeRangeTradeoff = tradeoffBlocks.find(
    (tradeoff) => tradeoff.tradeoffType === TradeoffTypeEnum.OUT_OF_TIME_RANGE,
  );
  const filteredTradeoffs = tradeoffBlocks.filter(
    (tradeoff) => tradeoff.tradeoffType !== TradeoffTypeEnum.OUT_OF_TIME_RANGE,
  );

  return (
    <div className="cw-w-full cw-flex-col">
      {!!outOfTimeRangeTradeoff && (
        <div cw-id="tradeoff-OUT_OF_TIME_RANGE">
          <OutOfRequestedTimeRange />
        </div>
      )}
      {showNoConflicts && (
        <div className="cw-mt-1" cw-id="tradeoff-no-conflicts">
          <NoConflicts />
        </div>
      )}
      <DetailedTradeoffs
        tradeoffs={filteredTradeoffs}
        defaultExpandedTradeoff={defaultExpandedTradeoff}
      />
      {showRespectsPreferences && (
        <div className="cw-mt-1" cw-id="tradeoff-respects-preferences">
          <RespectsPreferences />
        </div>
      )}
    </div>
  );
};
