import { SvgIconComponent } from "@clockwise/icons";
import {
  DatePickerType,
  DatePickerInput as MDatePickerInput,
  DatePickerInputProps as MDatePickerInputProps,
} from "@mantine/dates";
import classNames from "classnames";
import React, { useMemo } from "react";
import { Size } from "../../constants/types";
import {
  AllowedProps,
  fromInternalDate,
  fromInternalDateRange,
  toInternalDate,
  toInternalDateArray,
  toInternalDateRange,
  useInternalDate,
} from "./date.util";
import { getMantineSize, renderIcon } from "./mantine.util";

type MorePropKeys =
  | "clearButtonProps"
  | "clearable"
  | "closeOnChange"
  | "description"
  | "descriptionProps"
  | "disabled"
  | "label"
  | "placeholder"
  | "readOnly"
  | "required"
  | "sortDates";

type LocalDate = string;

type ValueByType = {
  default: LocalDate | null;
  multiple: LocalDate[];
  range: [LocalDate | null, LocalDate | null];
};

type DateValue<T extends DatePickerType> = ValueByType[T];

export type DateInputProps<T extends DatePickerType> = Pick<
  MDatePickerInputProps<T>,
  AllowedProps | MorePropKeys
> & {
  size?: Size;
  // Current selected value
  value?: DateValue<T>;
  defaultValue?: DateValue<T>;
  onChange?: (value: DateValue<T>) => void;
  // Controls for current calendar view
  date?: LocalDate;
  defaultDate?: LocalDate;
  minDate?: LocalDate;
  maxDate?: LocalDate;
  onDateChange?: (value: LocalDate) => void;
  excludeDate?: (date: LocalDate) => boolean;
  startIcon?: SvgIconComponent;
  error?: boolean;
  errorMessage?: string | null;
  fullWidth?: boolean;
  valueFormat?: string;
  showBorder?: boolean;
  ref?: React.RefObject<HTMLButtonElement>;
  returnFocus?: boolean;
};

/**
 * @experimental
 */
export const DateInput = ({
  size = "medium",
  value: _value,
  defaultValue: _defaultValue,
  onChange: _onChange,
  startIcon: StartIcon,
  error,
  errorMessage,
  fullWidth = false,
  valueFormat = "ddd MMM D, YYYY",
  showBorder = true,
  returnFocus = true,
  ...props
}: DateInputProps<"default">) => {
  const leftSection = StartIcon && renderIcon(StartIcon, size);
  const mantineSize = getMantineSize(size);
  const internalDateProps = useInternalDate(props);
  const value = useMemo(() => toInternalDate(_value), [_value]);
  const defaultValue = useMemo(() => toInternalDate(_defaultValue), [_defaultValue]);
  // Create optional callbacks with useMemo to allow for undefined
  const onChange = useMemo<MDatePickerInputProps<"default">["onChange"]>(
    () => _onChange && ((date) => _onChange(date && fromInternalDate(date))),
    [_onChange],
  );

  return (
    <MDatePickerInput
      {...props}
      {...internalDateProps}
      leftSection={leftSection}
      leftSectionPointerEvents="none"
      type="default"
      size={mantineSize}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      valueFormat={valueFormat}
      popoverProps={{ returnFocus }}
      classNames={{
        root: classNames({ "cw-w-full": fullWidth }),
        input: classNames("cw-truncate", {
          "cw-border-none focus-visible:cw-outline-override-none": !showBorder,
        }),
      }}
      error={(error && errorMessage) || error}
    />
  );
};

export const DateRangeInput = ({
  size = "medium",
  value: _value,
  defaultValue: _defaultValue,
  onChange: _onChange,
  startIcon: StartIcon,
  error,
  errorMessage,
  fullWidth = false,
  ...props
}: DateInputProps<"range">) => {
  const leftSection = StartIcon && renderIcon(StartIcon, size);
  const mantineSize = getMantineSize(size);
  const internalDateProps = useInternalDate(props);
  const value = useMemo(() => toInternalDateRange(_value), [_value]);
  const defaultValue = useMemo(() => toInternalDateRange(_defaultValue), [_defaultValue]);
  // Create optional callbacks with useMemo to allow for undefined
  const onChange = useMemo<MDatePickerInputProps<"range">["onChange"]>(
    () => _onChange && ((date) => _onChange(fromInternalDateRange(date))),
    [_onChange],
  );

  return (
    <MDatePickerInput
      {...props}
      {...internalDateProps}
      leftSection={leftSection}
      leftSectionPointerEvents="none"
      type="range"
      size={mantineSize}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      valueFormat="MMM D, YYYY"
      classNames={{
        root: classNames({ "cw-w-full": fullWidth }),
        input: "cw-truncate",
      }}
      error={(error && errorMessage) || error}
    />
  );
};

export const MultiDateInput = ({
  size = "medium",
  value: _value,
  defaultValue: _defaultValue,
  onChange: _onChange,
  startIcon: StartIcon,
  error,
  errorMessage,
  fullWidth = false,
  ...props
}: DateInputProps<"multiple">) => {
  const leftSection = StartIcon && renderIcon(StartIcon, size);
  const mantineSize = getMantineSize(size);
  const internalDateProps = useInternalDate(props);
  const value = useMemo(() => toInternalDateArray(_value), [_value]);
  const defaultValue = useMemo(() => toInternalDateArray(_defaultValue), [_defaultValue]);
  // Create optional callbacks with useMemo to allow for undefined
  const onChange = useMemo<MDatePickerInputProps<"multiple">["onChange"]>(
    () => _onChange && ((date) => _onChange((date ?? []).map((d) => fromInternalDate(d)))),
    [_onChange],
  );

  return (
    <MDatePickerInput
      {...props}
      {...internalDateProps}
      leftSection={leftSection}
      leftSectionPointerEvents="none"
      type="multiple"
      size={mantineSize}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      valueFormat="MMM D, YYYY"
      classNames={{
        root: classNames({ "cw-w-full": fullWidth }),
        input: "cw-truncate",
      }}
      error={(error && errorMessage) || error}
    />
  );
};
