import { NUXChecklistState } from "../../../hooks/useNUXChecklist";
import { OnboardingFlowState } from "../../../hooks/useOnboarding/useOnboarding";

export enum NUXChecklistVariant {
  ConnectCalendar = "ConnectCalendar",
  FlexMeeting = "FlexMeeting",
  IdealDay = "IdealDay",
  Holds = "Holds",
  NextOptimization = "NextOptimization",
  ScheduledMeeting = "ScheduledMeeting",
}

export const translateFlowStateToVariant = (step: OnboardingFlowState) => {
  switch (step) {
    case OnboardingFlowState.ChecklistFlexMeetings:
      return NUXChecklistVariant.FlexMeeting;
    case OnboardingFlowState.ChecklistHolds:
      return NUXChecklistVariant.Holds;
    case OnboardingFlowState.ChecklistPreferences:
      return NUXChecklistVariant.IdealDay;
    default:
      return null;
  }
};

const translateVariantToFlowState = (variant: NUXChecklistVariant) => {
  switch (variant) {
    case NUXChecklistVariant.FlexMeeting:
      return OnboardingFlowState.ChecklistFlexMeetings;
    case NUXChecklistVariant.IdealDay:
      return OnboardingFlowState.ChecklistPreferences;
    case NUXChecklistVariant.Holds:
      return OnboardingFlowState.ChecklistHolds;
    default:
      return null;
  }
};

export const isItemComplete = (state: NUXChecklistState) => {
  return state === NUXChecklistState.Finished;
};

export const isItemSkipped = (
  state: NUXChecklistState,
  activeStep: OnboardingFlowState | null,
  variant: NUXChecklistVariant,
) => {
  return state === NUXChecklistState.Skipped || activeStep === translateVariantToFlowState(variant);
};

export const isItemDisabled = (
  state: NUXChecklistState,
  activeStep: OnboardingFlowState | null,
  variant: NUXChecklistVariant,
) => {
  return (
    state === NUXChecklistState.Unstarted && activeStep !== translateVariantToFlowState(variant)
  );
};
