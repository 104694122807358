import { DateTime } from "luxon";
import { HashParams } from "../types";

export const getSelectedDateTime = (params: HashParams) => {
  let selectedDateTime = DateTime.now();

  if (params["year"] && params["month"] && params["day"]) {
    selectedDateTime = selectedDateTime.set({
      year: parseInt(params["year"]),
      month: parseInt(params["month"]),
      day: parseInt(params["day"]),
    });
  }

  return selectedDateTime;
};
