import copyToClipboard from "#webapp/src/util/copyToClipboard";
import { FileCopyOutlined } from "@clockwise/design-system/icons";
import { ExpandLess, ExpandMore } from "@clockwise/icons";
import classNames from "classnames";
import React, { useState } from "react";
import toast from "react-hot-toast";

interface JsonObject {
  [key: string]: any;
}

function fuzzySearchJSONObject(
  jsonObject: JsonObject,
  searchString: string,
): JsonObject | undefined {
  if (typeof jsonObject !== "object") {
    throw new Error("Input must be a JSON object");
  }

  // Function to perform a case-insensitive fuzzy match
  function fuzzyMatch(text: string, pattern: string): boolean {
    const textLower = text.toLowerCase();
    const patternLower = pattern.toLowerCase();
    let textIndex = 0;
    for (let i = 0; i < patternLower.length; i++) {
      const char = patternLower[i];
      while (textIndex < textLower.length && textLower[textIndex] !== char) {
        textIndex++;
      }
      if (textIndex === textLower.length) {
        return false;
      }
      textIndex++;
    }
    return true;
  }

  const filteredObject: JsonObject = Array.isArray(jsonObject) ? [] : {};

  for (const key in jsonObject) {
    if (jsonObject.hasOwnProperty(key)) {
      const value = jsonObject[key];
      if (Array.isArray(value)) {
        const filteredArray = value
          .map((item) => fuzzySearchJSONObject(item, searchString))
          .filter((item) => item !== undefined);

        if (filteredArray.length > 0) {
          filteredObject[key] = filteredArray;
        }
      } else if (typeof value === "object") {
        const filteredValue = fuzzySearchJSONObject(value, searchString);
        if (filteredValue !== undefined) {
          filteredObject[key] = filteredValue;
        }
      } else if (
        fuzzyMatch(key, searchString) ||
        (typeof value === "string" && fuzzyMatch(value, searchString))
      ) {
        filteredObject[key] = value;
      }
    }
  }

  return Object.keys(filteredObject).length > 0 ? filteredObject : undefined;
}

export const DebugBlock = ({ debugInfo }: { debugInfo: string }) => {
  const [expanded, setExpanded] = useState(false);
  const [debugInfoRepr, setDebugInfoRepr] = useState(JSON.parse(debugInfo));
  const [filter, setFilter] = useState("");

  const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setFilter(searchTerm);
    const fullDebugInfo = JSON.parse(debugInfo);
    if (searchTerm === "") {
      setDebugInfoRepr(fullDebugInfo);
      return;
    }
    setDebugInfoRepr(fuzzySearchJSONObject(fullDebugInfo, searchTerm));
  };

  const onClickExpand = () => {
    setExpanded(!expanded);
  };

  const copyText = () => {
    copyToClipboard(debugInfo);
    toast.success("Debug info copied to clipboard");
  };
  return (
    <div className="cw-w-full cw-max-w-xl cw-flex-col cw-mt-4 cw-opacity-10 hover:cw-opacity-100">
      <div
        className={classNames(
          "cw-bg-neutral-inset cw-px-2 cw-py-1.5 cw-rounded-t cw-cursor-pointer cw-flex cw-justify-between cw-items-center",
          {
            "cw-rounded-b": !expanded,
          },
        )}
      >
        <div onClick={onClickExpand} className="cw-flex cw-items-center cw-flex-1">
          <div className="cw-body-sm cw-mr-1 cw-font-bold"> Debug</div>
          {!expanded && <ExpandMore fontSize="small" />}
          {expanded && <ExpandLess fontSize="small" />}
        </div>
        <div className="cw-flex cw-items-center cw-grow">
          {expanded && (
            <input
              onChange={onChangeFilter}
              value={filter}
              placeholder="Filter by key"
              className="cw-border-0 cw-bg-transparent"
            ></input>
          )}
        </div>
        <div onClick={copyText} className="cw-flex cw-items-center">
          <FileCopyOutlined fontSize="small" />
        </div>
      </div>

      <div
        className={classNames(
          "cw-w-full cw-bg-neutral cw-rounded-b cw-transition-all cw-whitespace-normal cw-caption",
          {
            "cw-h-full cw-p-3": expanded,
            "cw-h-0 cw-p-0": !expanded,
          },
        )}
      >
        {expanded && (
          <pre className="cw-overflow-auto cw-h-[300px]">
            {JSON.stringify(debugInfoRepr, null, 2)}
          </pre>
        )}
      </div>
    </div>
  );
};
