import { getFormattedDuration } from "#webapp/src/components/web-onboarding/step-components/flex-meeting-step/utils/getFormattedDuration";
import { CountdownNextDefragTimeDocument } from "#webapp/src/graphql/__generated__/CountdownNextDefragTime.generated";
import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import Skeleton from "@material-ui/lab/Skeleton";
import { DateTime, Duration } from "luxon";
import React, { useEffect, useState } from "react";
import { NextOptimization } from "../assets";

export const DefragCountdownItem = ({ orgId, loading }: { orgId?: string; loading: boolean }) => {
  const { data, loading: diffLoading, error } = useQuery(CountdownNextDefragTimeDocument, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      orgId: orgId!,
    },
    skip: !orgId,
  });
  const [_, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (error) {
    return null;
  }

  const org = getValue(data?.org, "Org");
  const nextDefragTimeMillis: number | undefined = getValue(org?.nextDefragTime, "DefragTime")
    ?.millis;

  if (!nextDefragTimeMillis) {
    return null;
  }

  const now = DateTime.local();
  const nextDefrag = DateTime.fromMillis(nextDefragTimeMillis);
  const diff = nextDefrag.diff(now, ["days", "hours", "minutes", "seconds", "milliseconds"]); // Specifiying "milliseconds" forces "seconds" to be an integer

  if (now > nextDefrag) {
    // This is can happen if data is stale
    return null;
  }

  const formatDuration = (defragDiff: Duration) => {
    const timeDiff = getFormattedDuration(defragDiff);
    if (timeDiff.days) {
      return `${timeDiff.days}:${timeDiff.hours}:${timeDiff.minutes}:${timeDiff.seconds}`;
    }
    return `${timeDiff.hours}:${timeDiff.minutes}:${timeDiff.seconds}`;
  };

  const componentLoading = loading || diffLoading;

  return (
    <div className={"cw-flex cw-flex-col cw-px-6 pt-8"}>
      <div className="cw-flex cw-items-center cw-justify-center cw-h-20">
        <div className="cw-justify-self-center cw-flex cw-items-center cw-justify-center cw-p-2 cw-mr-4 cw-bg-[#B6FF80] cw-rounded-3xl">
          {!componentLoading && <img width={28} height={28} src={NextOptimization} />}
          {componentLoading && <Skeleton variant="circle" width={34} height={34} />}
        </div>
        <div className="cw-flex cw-items-center cw-flex-1">
          {!componentLoading && (
            <div className="cw-heading-lg">Your next optimization is in {formatDuration(diff)}</div>
          )}
          {componentLoading && <Skeleton variant="text" width="50%" />}
        </div>
      </div>
    </div>
  );
};
