import { greys, helpers } from "@clockwise/web-commons/src/styles/color.styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

// https://learn.microsoft.com/en-us/azure/active-directory/develop/howto-add-branding-in-apps
const button: CSSProperties = {
  color: greys.white,
  background: "#2F2F2F",
  padding: 4,
  justifyContent: "unset",
  "&:hover": {
    background: helpers.darken("#2F2F2F", 0.1),
  },
};

const startIcon: CSSProperties = {
  width: 38,
  height: 38,
  margin: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const label: CSSProperties = {
  padding: "0 12px",
  textTransform: "initial",
};

const logo: CSSProperties = {
  height: 22,
};

export const styles = {
  button,
  startIcon,
  label,
  logo,
};
