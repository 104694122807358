import { Select, SelectOption } from "@clockwise/design-system";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { range } from "lodash";
import { DateTime, IANAZone } from "luxon";
import React from "react";
import { OrgTimezoneDocument } from "./__generated__/OrgTimezone.v2.generated";

type Props = {
  selectedTimeSlot: string;
  onChange: (timeSlot: string) => void;
};

export const SlackDailyReminderSelect = ({ selectedTimeSlot, onChange }: Props) => {
  const { data } = useGatewayQuery(OrgTimezoneDocument);
  const timeZone = data?.currentOrg?.timeZone ? IANAZone.create(data.currentOrg.timeZone) : null;

  return (
    <Select
      label="Time to receive daily reminder"
      value={selectedTimeSlot}
      onChange={onChange}
      name="Daily Reminder Time"
      fullWidth
    >
      {getTimeSlotRange().map((slot) => (
        <SelectOption key={slot.toISOTime()} value={slot.toFormat("HH:mm")}>
          {slot.toLocaleString(DateTime.TIME_SIMPLE)}{" "}
          {timeZone?.offsetName(DateTime.local().toMillis(), { format: "short" })}
        </SelectOption>
      ))}
    </Select>
  );
};

export const getTimeSlotRange = () => {
  const start = DateTime.fromObject({ hour: 0, minute: 0 });
  return range(0, 48).map((i) => start.plus({ minutes: i * 30 }));
};
