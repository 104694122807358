import { useUpdateCurrentProposal } from "#webapp/src/components/chat-plus-calendar/CurrentProposalContext";
import { useUpdateActiveEventDiff } from "#webapp/src/components/chat-plus-calendar/util/ActiveDiffContext";
import { AI_EXCLUSION_TOOLTIP } from "#webapp/src/components/chat/constants";
import { Chip } from "#webapp/src/components/chip/Chip";
import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { ApolloError } from "@apollo/client";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { Button, Tooltip } from "@clockwise/design-system";
import { ClockwiseIntelligenceFilled } from "@clockwise/icons";
import { MessageBlock } from "@clockwise/web-commons/src/components/module-quick-reschedule/atoms/MessageBlock";
import { RescheduleAI } from "@clockwise/web-commons/src/components/svgs/RescheduleAI";
import {
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { Skeleton } from "@material-ui/lab";
import { isEmpty } from "lodash";
import { DateTime, Interval } from "luxon";
import React from "react";
import {
  emptyTimeSuggestionsState,
  useUpdateTimeSuggestionPeek,
} from "../../../chat-plus-calendar/util/TimeSuggestionPeekContext";
import { RescheduleOptionsQuery } from "../../../hooks/__generated__/RescheduleOptions.v2.generated";
import { useRescheduleOptions } from "../../../hooks/useRescheduleOptions";
import { getShortenedRelativeDateAndTime } from "../utils/getShortenedRelativeDateTimes";

export type RescheduleOptions = RescheduleOptionsQuery["rescheduleOptions"]["rescheduleOptions"];
export type RescheduleOption = RescheduleOptions[0];

export const QuickReschedule = ({
  externalEventId,
  shouldExcludeAi,
  submitRescheduleMessage,
  onSelectSchedulingOption,
  hasExternalAttendees,
  timeZone,
  duration,
  calendarId,
  canModify,
}: {
  externalEventId: string;
  shouldExcludeAi: boolean;
  submitRescheduleMessage: () => void;
  onSelectSchedulingOption: (startTime: string) => void;
  hasExternalAttendees: boolean;
  timeZone: string;
  duration: number;
  calendarId: string;
  canModify: boolean;
}) => {
  const [timeSuggestionsTabEnabled] = useFeatureFlag("TimeSuggestionsTab");
  const updateActiveEvent = useUpdateActiveEvent();
  const { selectExistingEvent } = useUpdateCurrentProposal();
  const updateActiveEventDiff = useUpdateActiveEventDiff();
  const track = useTracking();

  const onError = (error: ApolloError) => {
    logger.error("Failed to fetch reschedule options for event in context menu", error);
  };

  const { setHoveredTimeSuggestion } = useUpdateTimeSuggestionPeek();
  const { rescheduleOptions, loading: loadingRescheduleOptions, error } = useRescheduleOptions({
    externalEventId,
    calendarId,
    onError: onError,
    skip: !canModify,
  });

  const noRescheduleOptionsFound =
    ((rescheduleOptions && isEmpty(rescheduleOptions)) || error) && !loadingRescheduleOptions;

  const onClickOption = (startTime: string) => {
    onSelectSchedulingOption(startTime);
    setHoveredTimeSuggestion(emptyTimeSuggestionsState);
    track(TrackingEvents.QUICK_RESCHEDULE_AI_SCHEDULER.SELECTED);
  };

  const onMouseEnter = (startTime: DateTime) => {
    const durationMins = duration < 5 ? 5 : duration;
    const endTime = startTime.plus({ minutes: durationMins });
    const interval = Interval.fromDateTimes(startTime, endTime).toISO();
    setHoveredTimeSuggestion({ interval, tradeoffs: [], attendeeCount: 0 }); // attendee count doesn't matter if there are no tradeoffs
  };

  const onMouseLeave = () => {
    setHoveredTimeSuggestion(emptyTimeSuggestionsState);
  };

  const onClickFindMoreTimes = () => {
    if (timeSuggestionsTabEnabled) {
      selectExistingEvent(externalEventId, calendarId, { isFindATime: true });
      updateActiveEventDiff(null);
      updateActiveEvent({ externalEventId: "", calendarId: "", type: EventType.Proposal });
    } else {
      submitRescheduleMessage();
    }
    track(TrackingEvents.QUICK_RESCHEDULE_AI_SCHEDULER.FIND_MORE_TIMES);
  };

  return (
    <div className="cw-flex-col cw-mt-2">
      <div className="cw-flex cw-items-center cw-prism-text cw-text-12 cw-font-semibold cw-pl-1">
        <ClockwiseIntelligenceFilled className="cw-text-busy cw-w-3.5 cw-h-3.5 cw-pr-0.5" />
        Reschedule Suggestions
      </div>
      {noRescheduleOptionsFound && canModify && (
        <div className="cw-flex cw-ml-[2px] cw-pt-1 cw-text-subtle cw-body-sm cw-text-12">
          No available times within 2 weekdays
        </div>
      )}
      <div className="cw-flex cw-flex-wrap cw-gap-1 cw-mt-2">
        {rescheduleOptions?.map((option) => {
          const startTimeAsDateTime = DateTime.fromISO(option.start).setZone(timeZone);
          return (
            <div
              key={option.start}
              onMouseEnter={() => onMouseEnter(startTimeAsDateTime)}
              onMouseLeave={onMouseLeave}
            >
              <Chip sentiment="neutral" variant="solid" onClick={() => onClickOption(option.start)}>
                {getShortenedRelativeDateAndTime(startTimeAsDateTime, timeZone)}
              </Chip>
            </div>
          );
        })}
        {loadingRescheduleOptions && (
          <div className="cw-flex cw-flex-wrap cw-gap-1">
            <Skeleton variant="rect" width={90} height={20} className="cw-rounded-xl" />
            <Skeleton variant="rect" width={90} height={20} className="cw-rounded-xl" />
            <Skeleton variant="rect" width={90} height={20} className="cw-rounded-xl" />
            <Skeleton variant="rect" width={90} height={20} className="cw-rounded-xl" />
            <Skeleton variant="rect" width={90} height={20} className="cw-rounded-xl" />
          </div>
        )}
        {!loadingRescheduleOptions && (
          <FindMoreTimesButton
            shouldExcludeAi={shouldExcludeAi}
            canModify={canModify}
            submitRescheduleMessage={onClickFindMoreTimes}
            disabled={loadingRescheduleOptions}
          />
        )}

        {hasExternalAttendees ? (
          <div className="cw-mt-1">
            <MessageBlock message="External attendees may not be available at suggested times." />
          </div>
        ) : !canModify ? (
          <div className="cw-mt-1">
            <MessageBlock message="You don't have permission to change the event, but you can propose a new time to the event organizer" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const RescheduleButton = ({
  shouldExcludeAi,
  submitRescheduleMessage,
  disabled,
  componentType = "Chip",
  canModify,
}: {
  shouldExcludeAi: boolean;
  submitRescheduleMessage: () => void;
  disabled: boolean;
  componentType?: ComponentType;
  canModify: boolean;
}) => {
  switch (componentType) {
    case "Button": {
      return (
        <Button
          disabled={shouldExcludeAi || disabled}
          onClick={submitRescheduleMessage}
          startIcon={RescheduleAI}
          variant="outlined"
          sentiment="neutral"
          size="xsmall"
        >
          More times
        </Button>
      );
    }
    case "Chip":
    default: {
      return (
        <Chip
          sentiment="neutral"
          variant="solid"
          disabled={shouldExcludeAi || disabled}
          onClick={submitRescheduleMessage}
        >
          {canModify ? "Find more times" : "Propose new time"}
        </Chip>
      );
    }
  }
};

type ComponentType = "Chip" | "Button";
export const FindMoreTimesButton = ({
  shouldExcludeAi,
  submitRescheduleMessage,
  canModify,
  disabled,
  componentType = "Chip",
}: {
  shouldExcludeAi: boolean;
  submitRescheduleMessage: () => void;
  canModify: boolean;
  disabled: boolean;
  componentType?: ComponentType;
}) => {
  return shouldExcludeAi ? (
    <Tooltip title={AI_EXCLUSION_TOOLTIP} placement="top">
      <RescheduleButton
        shouldExcludeAi={shouldExcludeAi}
        submitRescheduleMessage={submitRescheduleMessage}
        disabled={disabled}
        componentType={componentType}
        canModify={canModify}
      />
    </Tooltip>
  ) : (
    <RescheduleButton
      shouldExcludeAi={shouldExcludeAi}
      submitRescheduleMessage={submitRescheduleMessage}
      disabled={disabled}
      componentType={componentType}
      canModify={canModify}
    />
  );
};
