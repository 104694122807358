import { InvitePolicyEnum } from "#webapp/src/__schema__";
import { inviteToBillingGroup, updateOrgInvite } from "#webapp/src/mutations";
import { compareBooleans, compareStrings } from "#webapp/src/util/sort.util";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { RelayModernEnvironment } from "relay-runtime/lib/store/RelayModernEnvironment";

export type QueryPerson = {
  personId: string;
  primaryCalendarId: string;
  userId: string | null;
  isYou: boolean;
  profile: {
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  } | null;
};

export type AugmentedPerson = {
  id: string;
  email: string;
  givenName: string;
  familyName: string;
  externalImageUrl: string;
  isSuggested: boolean;
  isUser: boolean;
  isYou: boolean;
  isPending: boolean;
  totalScore: number;
};
export type AugmentedPersonMap = {
  [email: string]: AugmentedPerson;
};

export const normalizePersons = (persons: QueryPerson[]): AugmentedPersonMap => {
  if (!persons) {
    return {};
  }

  const result: AugmentedPersonMap = persons
    .filter((person) => !person.isYou)
    .map(
      (person): AugmentedPerson => {
        // OrgPerson doesn't have isUser so we compute it here based on the presence of a userId
        const isUser = !!person.userId;
        const email = person.primaryCalendarId;
        const id = person.personId;

        const { profile, isYou } = person;
        return {
          id,
          isYou,
          isUser,
          isPending: false,
          email,
          givenName: profile?.givenName ?? email, // if no user profile, show primaryEmail
          familyName: profile?.familyName ?? "",
          externalImageUrl: profile?.externalImageUrl ?? "",
          isSuggested: false,
          totalScore: 0,
        };
      },
    )
    .reduce((accumulator: AugmentedPersonMap, augmentedPerson) => {
      accumulator[augmentedPerson.email] = augmentedPerson;

      return accumulator;
    }, {});

  return result;
};

export const sortPersons = (personMap: AugmentedPersonMap) => {
  return Object.keys(personMap).sort((a, b) => {
    const personA = personMap[a];
    const personB = personMap[b];

    return (
      compareBooleans(personA.isYou, personB.isYou) || // you go to the bottom
      compareBooleans(personA.isUser, personB.isUser) || // users go to the bottom
      (personA.isSuggested && personB.isSuggested && personB.totalScore - personA.totalScore) || // best suggestions to the top
      compareBooleans(personA.isSuggested, personB.isSuggested, false) || // suggestions to the top (note isAscending = false)
      compareBooleans(personA.isPending, personB.isPending) ||
      compareStrings(personA.givenName, personB.givenName) ||
      compareStrings(personA.familyName, personB.familyName)
    );
  });
};

interface Invitee {
  id: string;
  email: string;
  isSuggested: boolean;
}

interface SendInvitesOpts {
  relayEnv: RelayModernEnvironment;
  orgId: string;
  billingGroupId?: string | null;
  onSuccess?: () => void;
  onFailure?: () => void;
}

// This is a fairly generic operation. Tracking and followup actions can be performed after this function executes or as part of the optional callbacks.
export const sendInvites = (
  invitees: Invitee[],
  { relayEnv, orgId, billingGroupId, onSuccess = () => {}, onFailure = () => {} }: SendInvitesOpts,
) => {
  if (!orgId) {
    throw new Error("Cannot send invites with an org id");
  }

  if (billingGroupId) {
    // Use billing group invite mutation
    inviteToBillingGroup(
      relayEnv,
      {
        orgRelayId: orgId,
        billingGroupId,
        personIds: invitees.map(({ id }) => id),
      },
      onSuccess,
      onFailure,
    );
  } else {
    // Use normal invite mutation
    const selectedCalendarIds = invitees.map(({ email }) => email);

    if (selectedCalendarIds.length > 0) {
      updateOrgInvite(
        relayEnv,
        { calendarIds: selectedCalendarIds, orgRelayId: orgId },
        onSuccess,
        onFailure,
      );
    }
  }
};

export const canInviteToGroup = (group: {
  invitePolicy: InvitePolicyEnum;
  adminOrgPersons:
    | {
        __typename: "GraphEntityError";
        message: string | null;
        statusCode: number | null;
      }
    | {
        __typename: "OrgPersonList";
        list: Array<{
          __typename: "OrgPerson";
          isYou: boolean;
        }>;
      };
}) => {
  const admins = getValue(group.adminOrgPersons)?.list ?? [];
  return group.invitePolicy === InvitePolicyEnum.AnyMember || admins.some(({ isYou }) => isYou);
};
