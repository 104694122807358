import { Select, SelectOption, Switch } from "@clockwise/design-system";
import { fg_muted } from "@clockwise/design-system/tokens";
import { PreferredFocusTime, WebappSurface } from "@clockwise/schema";
import {
  FeatureSetting,
  FeatureSettingAside,
  FeatureSettingBody,
  FeatureSettingHeading,
  FeatureSettingMain,
  FeatureSettingOptions,
  FeatureSettingsHoverCard,
} from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import Options, {
  OptionsState,
} from "@clockwise/web-commons/src/components/feature-setting/Options";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { Duration } from "luxon";
import { CircleNotch } from "phosphor-react";
import React from "react";
import toast from "react-hot-toast";
import { useMonetization } from "../../hooks/useMonetization";
import { ScrollIntoView } from "../ui-focus-wrappers/ScrollIntoView";
import { FocusTimeHoursSelect } from "./FocusTimeHoursSelect";
import FocusTimeIllustration from "./FocusTimeIllustration";
import MonetizationBadge from "./MonetizationBadge";
import MonetizationBanner from "./MonetizationBanner";
import MonetizationWrapper from "./MonetizationWrapper";
import Tooltip from "./Tooltip";
import useFocusTime from "./hooks/useFocusTime";
import { FocusTimeGoalThreshold } from "./types";

const notifySuccess = () => toast.success("Updated Focus Time setting.");
const notifyError = () => toast.error("Failed to updated Focus Time setting.");

const FocusTime = () => {
  const trackEvent = useTracking();
  const { canUserHaveFocusTimeHolds } = useMonetization();
  const { settings: state, update, loading, updating, toggle, toggling } = useFocusTime({
    onSuccess: notifySuccess,
    onFail: notifyError,
  });

  const thresholdValue = state.threshold === "all" ? state.threshold : state.threshold?.toISO();
  const autoDeclineThresholdValue = state.autoDeclineSettings.threshold.toISO();
  const dailyMaxAmount = state.dailyMaxSettings.maxAmount.toISO();
  const handleEnabledChange = (value: boolean) => {
    void toggle(value);

    trackEvent(TrackingEvents.SETTINGS.UPDATE_SMART_HOLD_SYNC, {
      sync: value,
      type: "Focus Time",
    });
  };

  const handleThresholdChange = (value: string) => {
    const duration = Duration.fromISO(value);

    if (duration.isValid) {
      updateThreshold(duration);
    } else {
      notifyError();
    }
  };

  const updateThreshold = (value: FocusTimeGoalThreshold) => {
    if (value !== "all" && state.autoDeclineSettings.enabled) {
      if (value < state.autoDeclineSettings.threshold) {
        toast.error("Focus Time goal must be greater than or equal to auto-decline threshold");
        return;
      }
    }
    void update({ threshold: value });

    trackEvent(TrackingEvents.SETTINGS.UPDATE_SMART_HOLD_IDEAL_MINUTES_PER_WEEK, {
      idealMinutesPerWeek: value === "all" ? value : value.shiftTo("minutes").minutes,
      type: "Focus Time",
    });
  };

  const handlePreferenceChange = (value: PreferredFocusTime) => {
    void update({ preferredFocusIn: value });

    trackEvent(TrackingEvents.SETTINGS.UPDATE_PREFERRED_WORK, { workPreference: value });
  };

  const handleOptionsChange = (value: OptionsState) => {
    void update({
      scheduleAs: value.scheduleAs,
      notificationStrategy: value.notificationStrategy,
    });

    trackEvent(TrackingEvents.SETTINGS.UPDATE_OPTIONS, {
      scheduleAs: value.scheduleAs,
      notificationStrategy: value.notificationStrategy,
      type: "Focus Time",
    });
  };

  const handleDailyMaxEnabledChange = (value: boolean) => {
    void update({
      dailyMaxSettings: { enabled: value, maxAmount: state.dailyMaxSettings.maxAmount },
    });

    trackEvent(TrackingEvents.SETTINGS.UPDATE_FT_DAILY_MAX, { enabled: value });
  };

  const handleAutoDeclineEnabledChange = (value: boolean) => {
    trackEvent(TrackingEvents.SETTINGS.UPDATE_FT_AUTO_DECLINE, { enabled: value });
    void update({
      autoDeclineSettings: { enabled: value, threshold: state.autoDeclineSettings.threshold },
    });
  };

  const handleAutoDeclineThresholdChange = (value: string) => {
    const duration = Duration.fromISO(value);

    if (duration.isValid) {
      updateAutoDeclineThreshold(duration);
    } else {
      notifyError();
    }
  };

  const updateAutoDeclineThreshold = (value: Duration) => {
    if (state.threshold < value) {
      toast.error("Auto-decline threshold must be less than or equal to weekly focus time goal");
      return;
    }
    trackEvent(TrackingEvents.SETTINGS.UPDATE_FT_AUTO_DECLINE, { threshold: value });

    void update({
      autoDeclineSettings: { enabled: state.autoDeclineSettings.enabled, threshold: value },
    });
  };

  const handleDailyMaxAmountChange = (value: string) => {
    const duration = Duration.fromISO(value);
    if (duration.isValid) {
      updateDailyMaxAmount(duration);
    } else {
      notifyError();
    }
  };

  const updateDailyMaxAmount = (value: Duration) => {
    trackEvent(TrackingEvents.SETTINGS.UPDATE_FT_DAILY_MAX, { maxAmount: value });

    void update({
      dailyMaxSettings: { enabled: state.dailyMaxSettings.enabled, maxAmount: value },
    });
  };

  return (
    <ScrollIntoView focusSection={WebappSurface.FocusTime} highlight>
      <FeatureSetting
        enabled={!loading && state.enabled}
        loading={loading}
        locked={!canUserHaveFocusTimeHolds}
        onEnabledChange={handleEnabledChange}
        saving={toggling || updating}
        title="Focus Time Settings"
      >
        <FeatureSettingAside>
          <FocusTimeIllustration />
        </FeatureSettingAside>
        <FeatureSettingMain>
          <FeatureSettingHeading>
            <div className="cw-flex cw-flex-row cw-items-center">
              <h2
                className="cw-heading-2xl cw-m-0"
                aria-label="Do you need Focus Time to get things done?"
                id="FocusTime"
              >
                Do you need{" "}
                <FeatureSettingsHoverCard id="ft-desc-tooltip" content={<Tooltip />}>
                  <span
                    className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1"
                    aria-describedby="ft-desc-tooltip"
                    data-testid="ft-tooltipper"
                  >
                    Focus Time
                  </span>
                </FeatureSettingsHoverCard>{" "}
                to get things done?
              </h2>
              <MonetizationBadge />
            </div>
          </FeatureSettingHeading>
          <FeatureSettingBody>
            <MonetizationBanner />
            <MonetizationWrapper>
              <ul
                className={`
                cw-flex cw-flex-col cw-space-y-2
                cw-list-none cw-p-0 cw-my-1
              `}
              >
                <li>
                  <label className="cw-flex cw-flex-row cw-space-x-2 cw-items-center">
                    <span>How much Focus Time per week would you like?</span>
                    {loading ? (
                      <CircleNotch
                        className="cw-animate-spin cw-h-9"
                        color={fg_muted}
                        data-chromatic="ignore"
                      />
                    ) : (
                      <FocusTimeHoursSelect
                        value={thresholdValue}
                        onChange={handleThresholdChange}
                        disabled={!state.enabled}
                      />
                    )}
                  </label>
                </li>
                <li className="cw-pl-10">
                  <label className="cw-flex cw-flex-row cw-space-x-2 cw-items-center">
                    <Switch
                      checked={state.autoDeclineSettings.enabled}
                      onChange={handleAutoDeclineEnabledChange}
                      disabled={loading || !state.enabled}
                    />
                    <span>Decline incoming meetings once my weekly Focus Time drops below</span>
                    {loading ? (
                      <CircleNotch
                        className="cw-animate-spin cw-h-9"
                        color={fg_muted}
                        data-chromatic="ignore"
                      />
                    ) : (
                      <FocusTimeHoursSelect
                        value={autoDeclineThresholdValue}
                        onChange={handleAutoDeclineThresholdChange}
                        disabled={!state.autoDeclineSettings.enabled || !state.enabled}
                      />
                    )}
                  </label>
                </li>
                <li className="cw-pl-10">
                  <label className="cw-flex cw-flex-row cw-space-x-2 cw-items-center">
                    <Switch
                      checked={state.dailyMaxSettings.enabled}
                      onChange={handleDailyMaxEnabledChange}
                      disabled={loading || !state.enabled}
                    />
                    <span>The most Focus Time I want in a single day is</span>
                    {loading ? (
                      <CircleNotch
                        className="cw-animate-spin cw-h-9"
                        color={fg_muted}
                        data-chromatic="ignore"
                      />
                    ) : (
                      <FocusTimeHoursSelect
                        value={dailyMaxAmount}
                        onChange={handleDailyMaxAmountChange}
                        disabled={!state.dailyMaxSettings.enabled || !state.enabled}
                        maxHours={24}
                      />
                    )}
                  </label>
                </li>
                <li>
                  <label className="cw-flex cw-flex-row cw-space-x-2 cw-items-center">
                    <span>If there is a choice, I prefer to have Focus time</span>
                    {loading ? (
                      <CircleNotch
                        className="cw-animate-spin cw-h-9"
                        color={fg_muted}
                        data-chromatic="ignore"
                      />
                    ) : (
                      <Select
                        disabled={!state.enabled}
                        onChange={handlePreferenceChange}
                        value={state.preferredFocusIn}
                      >
                        <SelectOption value={PreferredFocusTime.Morning}>
                          in the mornings
                        </SelectOption>
                        <SelectOption value={PreferredFocusTime.Afternoon}>
                          in the afternoon
                        </SelectOption>
                        <SelectOption value={PreferredFocusTime.NoPreference}>
                          whenever possible
                        </SelectOption>
                      </Select>
                    )}
                  </label>
                </li>
              </ul>
            </MonetizationWrapper>
          </FeatureSettingBody>
          <FeatureSettingOptions>
            <MonetizationWrapper>
              <Options
                enabled={state.enabled && canUserHaveFocusTimeHolds}
                scheduleAs={state.scheduleAs}
                notificationStrategy={state.notificationStrategy}
                onChange={handleOptionsChange}
              />
            </MonetizationWrapper>
          </FeatureSettingOptions>
        </FeatureSettingMain>
      </FeatureSetting>
    </ScrollIntoView>
  );
};

export default FocusTime;
