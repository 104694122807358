import { Button } from "@clockwise/design-system";
import { Add } from "@clockwise/icons";
import { noop } from "lodash";
import React, { useMemo } from "react";
import {
  DaySetting as DaySettingInput,
  MeetingOrWorkingHours,
  SlotRangeWrapper,
  validateSlotChange,
} from "../../working-hours";
import { TimeRange } from "./types";
import { convertSlotToTime, convertTimeToSlot } from "./utils";

interface Props {
  timeRanges: TimeRange[];
  onChange(updatedTimeRanges: TimeRange[]): void;
}

export const HoursEditor = ({ timeRanges, onChange }: Props) => {
  const slots: SlotRangeWrapper = useMemo(() => {
    const slotRanges = timeRanges.map(({ startTime, endTime }) => ({
      startSlot: convertTimeToSlot(startTime, false),
      endSlot: convertTimeToSlot(endTime, true),
      // Initialize to false. These will be overwritten by validateSlotChange.
      startError: false,
      endError: false,
    }));

    // NB: validateSlotChange mutates the items in slotRanges. This is ok in our use current use
    // case since the slot range objects are created in this function; however, this could be an
    // issue if the slot ranges are made more persistent in the future.
    return validateSlotChange(slotRanges);
  }, [timeRanges]);

  const handleSlotChange = (updatedSlots: SlotRangeWrapper) => {
    onChange(
      updatedSlots.slots.map(({ startSlot, endSlot }) => ({
        startTime: convertSlotToTime(startSlot, false),
        endTime: convertSlotToTime(endSlot, true),
      })),
    );
  };
  const handleAddInitialHours = () => {
    onChange([
      {
        startTime: "09:00",
        endTime: "17:00",
      },
    ]);
  };

  return (
    <div>
      <div className="cw-heading-base cw-mb-2">Available hours</div>
      {timeRanges.length > 0 ? (
        <div className="cw-inline-block">
          <DaySettingInput
            isMeetingOrWorkingHours={MeetingOrWorkingHours.MeetingHours}
            onClickCopy={noop}
            onSlotChange={handleSlotChange}
            slotRanges={slots}
            allowEmpty
          />
        </div>
      ) : (
        <div className="cw-body-base cw-space-y-1">
          <span className="cw-text-muted">Unavailable</span>
          <Button variant="text" size="mini" startIcon={Add} onClick={handleAddInitialHours}>
            Add hours
          </Button>
        </div>
      )}
    </div>
  );
};
