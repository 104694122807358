import { Link } from "@clockwise/design-system";
import React from "react";

const Tooltip = () => (
  <>
    Clockwise will pre-emptively schedule breaks when it looks like you're going to have too many
    meetings in a row, and where possible break up long clusters of uninterrupted meeting time.{" "}
    <Link onEmphasis href="https://getclockwise.helpscoutdocs.com/article/174-smart-meeting-breaks">
      Learn more
    </Link>
  </>
);

export default Tooltip;
