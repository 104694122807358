import { CalendarState } from "@clockwise/web-commons/src/util/CalendarContext";
import { DateTime } from "luxon";

export const getCalendarHashFromState = (
  calendarState: Pick<CalendarState, "visibleDates" | "focusedDate">,
) => {
  const calendarMode = calendarState.visibleDates.length > 1 ? "week" : "day";

  const todayISO = DateTime.local().toISODate();
  const viewIncludesToday = calendarState.visibleDates.includes(todayISO);
  if (viewIncludesToday) {
    return calendarMode;
  }

  const calendarDate = calendarState.focusedDate.replace(/-/g, "/");
  const calendarHash = `/${calendarMode}/${calendarDate}`;
  return calendarHash;
};
