import { Button } from "@clockwise/design-system";
import { LockOutlined } from "@clockwise/design-system/icons";
import { CalloutBox } from "@clockwise/web-commons/src/components/callout-box/CalloutBox.tailwind";
import { openPricingPage } from "@clockwise/web-commons/src/util/upgradeLinks.util";
import React from "react";
import { useMonetization } from "../../hooks/useMonetization";

const MonetizationBanner = () => {
  const { canUserHaveFocusTimeHolds, isFiveDaysPastTrial } = useMonetization();

  const handleClick = () => {
    openPricingPage();
  };

  if (canUserHaveFocusTimeHolds) return null;

  return (
    <div className="cw-mb-6">
      <CalloutBox
        sentiment="warning"
        body={
          <div className="cw-flex cw-items-center">
            <LockOutlined className="cw-text-peach-darker cw-mr-2.5 cw-grow-0" />
            <div className="cw-grow-0">
              {isFiveDaysPastTrial ? "You are on the Free plan" : "Your Teams trial has expired"}.
              Please upgrade to keep your Focus Time holds.
            </div>
            <div className="cw-flex cw-grow cw-justify-end cw-ml-1">
              <Button variant="text" onClick={handleClick} sentiment="warning" size="medium">
                Upgrade
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default MonetizationBanner;
