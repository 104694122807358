import { isEmpty } from "lodash";
import { useUpdateCalendarColors } from "../../../web-app-calendar/hooks/CalendarColorsContext";
import { GQLDiffBlocks } from "../utils/types";

export const useSetAttendeeCalendarColors = () => {
  const dispatchCalendarColors = useUpdateCalendarColors();

  const updateProposalAttendeeCalendarColors = (diffs: GQLDiffBlocks) => {
    const aggregateAttendeeCalendarIds = new Set<string>();

    diffs.forEach((diff) => {
      const diffBlocks = diff.diffs;
      diffBlocks.forEach((diffBlock) => {
        const attendees = diffBlock.attendees;

        if (!!attendees) {
          if (!!attendees.ambiguities && !isEmpty(attendees.ambiguities)) {
            attendees.ambiguities.forEach((ambiguity) => {
              if (ambiguity.disambiguations.__typename === "PersonList") {
                ambiguity.disambiguations.list.forEach((attendee) => {
                  aggregateAttendeeCalendarIds.add(attendee.primaryCalendar);
                });
              }
            });
          }
          if (attendees.proposalAttendees.length > 0) {
            attendees.proposalAttendees.forEach((attendee) => {
              if (attendee.attendeePerson && !attendee.attendeePerson.isYou) {
                aggregateAttendeeCalendarIds.add(attendee.attendeePerson.primaryCalendar);
              }
            });
          }
        }
      });
    });
    if (aggregateAttendeeCalendarIds.size > 0) {
      dispatchCalendarColors({
        type: "add-calendars",
        payload: Array.from(aggregateAttendeeCalendarIds),
      });
    }
  };

  return { updateProposalAttendeeCalendarColors };
};
