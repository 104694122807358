import { DateTime } from "luxon";
import React from "react";
import { ProposalOptionsOverlayV2 } from "../proposal-options-overlay-v2/ProposalOptionsOverlay";

export const ProposalOptionsOverlayWrapperV2 = ({
  dateTime,
  onClick,
}: {
  dateTime: DateTime;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  return (
    <ProposalOptionsOverlayV2
      dateTimes={[dateTime]}
      popoverPosition="right"
      isDayView
      onClick={onClick}
    />
  );
};
