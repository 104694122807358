import {
  EventTable,
  EventTableRow,
  EventTableRowCollapser,
} from "@clockwise/web-commons/src/components/EventTable";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import React from "react";
import { MyFlexibleMeetingsListDocument } from "./__generated__/MyFlexibleMeetings.v2.generated";
import { Async, FlexEventRow } from "./flex-event-table/FlexEventRow";
import { useSetFlexibility } from "./useSetFlexibility";
import { parseAndFilterFlexibility } from "./util/parseFlexibility";

const trackingSourceId = "My flexible meetings control";

export const MyFlexibleMeetings = () => {
  const { data, loading, error } = useGatewayQuery(MyFlexibleMeetingsListDocument);
  const { handleFlexibilityChange } = useSetFlexibility(
    [
      ...(data?.flexibleMeetingsDashboardV2?.others || []),
      ...(data?.flexibleMeetingsDashboardV2?.teamSyncs || []),
      ...(data?.flexibleMeetingsDashboardV2?.upcomingAdHoc || []),
    ],
    trackingSourceId,
  );

  const otherMeetings = parseAndFilterFlexibility(data?.flexibleMeetingsDashboardV2.others || []);
  const teamSyncs = parseAndFilterFlexibility(data?.flexibleMeetingsDashboardV2.teamSyncs || []);
  const adHoc = parseAndFilterFlexibility(data?.flexibleMeetingsDashboardV2.upcomingAdHoc ?? []);
  const hasAnyMeetings = loading || !otherMeetings.length || teamSyncs.length || adHoc.length;

  const columnSizing = ["300", "180", "160", null];

  return (
    <EventTable
      heading="Other meetings"
      errorMessage={
        error ? "There was an error fetching your meetings. Please try again." : undefined
      }
      emptyMessage={!hasAnyMeetings ? "No meetings to display" : ""}
      columnSizing={columnSizing}
    >
      <EventTableRow isHeading={true}>
        <>Meeting</>
        <>Attendees</>
        <>Repeats</>
        <>Flexibility</>
      </EventTableRow>
      <Async loading={loading} loadingTypes={["text", "avatar", "text", "text"]}>
        <EventTableRowCollapser text="Upcoming ad hoc">
          {adHoc.map((event) => {
            return (
              <FlexEventRow
                key={event.id}
                showAttendees
                event={event}
                onFlexibilityChange={handleFlexibilityChange}
                showNotRecommededBadge={event.showNotRecommededBadge}
              />
            );
          })}
        </EventTableRowCollapser>
      </Async>
      <Async loading={loading} loadingTypes={["text", "avatar", "text", "text"]}>
        <EventTableRowCollapser text="Team syncs">
          {teamSyncs.map((event) => {
            return (
              <FlexEventRow
                event={event}
                key={event.id}
                showAttendees
                onFlexibilityChange={handleFlexibilityChange}
                showNotRecommededBadge={event.showNotRecommededBadge}
              />
            );
          })}
        </EventTableRowCollapser>
      </Async>
      <Async loading={loading} loadingTypes={["text", "avatar", "text", "text"]}>
        <EventTableRowCollapser text="Other">
          {otherMeetings.map((event) => {
            return (
              <FlexEventRow
                event={event}
                key={event.id}
                showAttendees
                onFlexibilityChange={handleFlexibilityChange}
                showNotRecommededBadge={event.showNotRecommededBadge}
              />
            );
          })}
        </EventTableRowCollapser>
      </Async>
    </EventTable>
  );
};
