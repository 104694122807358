import { EventTable, EventTableRow } from "@clockwise/web-commons/src/components/EventTable";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import React from "react";
import { FlexibleHoldsNewDocument } from "./__generated__/MyFlexibleMeetings.v2.generated";
import { Async, FlexEventRow } from "./flex-event-table/FlexEventRow";
import { useSetFlexibility } from "./useSetFlexibility";
import { parseAndFilterFlexibility } from "./util/parseFlexibility";

export function FlexibleHoldsSection() {
  const { data, loading, error } = useGatewayQuery(FlexibleHoldsNewDocument);

  const holds = data?.flexibleMeetingsDashboardV2.holds ?? [];

  const { handleFlexibilityChange } = useSetFlexibility(holds, "Flexible holds control");

  const recurringHolds = parseAndFilterFlexibility(holds.filter((hold) => !!hold.recurrenceRule));

  const oneOffHolds = parseAndFilterFlexibility(holds.filter((hold) => !hold.recurrenceRule));

  const eventTableError = error?.message && !loading ? "Unable to fetch data for holds" : undefined;
  const emptyMessageRecurring =
    !loading && !recurringHolds.length ? "No holds to display" : undefined;
  const emptyMessageOneOnOne = !loading && !oneOffHolds.length ? "No tasks to display" : undefined;

  return (
    <div className="cw-space-y-5" id="holds">
      <div className="cw-body-base">
        <span className="cw-p-3 cw-flex cw-rounded-lg cw-bg-neutral">
          <div className="cw-body-base cw-flex cw-flex-col cw-gap-1">
            <span className="cw-font-semibold">How does it work?</span>
            <div className="cw-text-13 cw-text-muted">
              Flexible holds are events where you are the only attendee. Exactly when they happen
              doesn't matter, just that they do. As your schedule changes throughout the week,
              Clockwise will automatically shuffle your flexible holds so your calendar reacts as
              your day evolves.
            </div>
          </div>
        </span>
      </div>
      <EventTable
        heading="Recurring routines"
        errorMessage={eventTableError}
        emptyMessage={emptyMessageRecurring}
      >
        <EventTableRow isHeading={true}>
          <>Meeting</>
          <>Repeats</>
          <>Flexibility</>
        </EventTableRow>
        <Async loading={loading} loadingTypes={["text", "text", "text"]}>
          {recurringHolds.map((event) => {
            return (
              <FlexEventRow
                event={event}
                key={event.id}
                onFlexibilityChange={handleFlexibilityChange}
              />
            );
          })}
        </Async>
      </EventTable>
      <EventTable
        heading="One-off tasks"
        errorMessage={eventTableError}
        emptyMessage={emptyMessageOneOnOne}
      >
        <EventTableRow isHeading={true}>
          <>Meeting</>
          <>Repeats</>
          <>Flexibility</>
        </EventTableRow>
        <Async loading={loading} loadingTypes={["text", "text", "text"]}>
          {oneOffHolds.map((event) => {
            return (
              <FlexEventRow
                event={event}
                key={event.id}
                onFlexibilityChange={handleFlexibilityChange}
              />
            );
          })}
        </Async>
      </EventTable>
    </div>
  );
}
