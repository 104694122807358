import { useMemo } from "react";
import { Proposal, useActiveProposal } from "./useActiveProposal";

type DiffBlock = Proposal["diffBlocks"];
export type DiffSummary = DiffBlock[0]["diffs"][0];

export const useActiveDiffSummaries = () => {
  const { proposal, loading } = useActiveProposal();

  const activeDiffSummaries = useMemo(() => {
    const activeDiffSummaries: DiffSummary[] = [];

    if (proposal && proposal.diffBlocks) {
      proposal.diffBlocks.forEach((diffBlock) => {
        diffBlock.diffs.forEach((diffSummary) => {
          if ("active" in diffSummary)
            if (diffSummary.active) {
              activeDiffSummaries.push(diffSummary);
            }
        });
      });
    }

    return activeDiffSummaries;
  }, [proposal]);

  return { activeDiffSummaries, loading };
};
