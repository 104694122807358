import { SelectOption } from "@clockwise/design-system";
import { ClockwiseIntelligence, EventAvailable, EventBusy } from "@clockwise/icons";
import React, { useEffect } from "react";
import { ProposalRenderSurface } from "../../../../constants";
import { SchedulingOptionsTradeoffs } from "../../../utils/types";
import {
  CustomSchedulingSelect,
  CustomSchedulingSelectOptionsGroup,
} from "./CustomSchedulingSelect";
import { Option, OptionWithIndex } from "./SchedulingPills";
import { parseSchedulingOptionsGQLByCategory } from "./parseSchedulingOptionsByCategory";

export type SchedulingSelectProps = {
  onClick: (index: number) => void;
  selectedIndex: number;
  disabled: boolean;
  options: OptionWithIndex[];
  onMouseEnter: (interval: string, tradeoffBlocks: SchedulingOptionsTradeoffs) => void;
  onMouseLeave: () => void;
  variant?: ProposalRenderSurface;
};

export const SchedulingSelect = ({
  options,
  selectedIndex,
  onClick,
  disabled,
  onMouseEnter,
  onMouseLeave,
  variant = "AI_CHAT",
}: SchedulingSelectProps) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = React.useState<number>();
  const stringifiedOptions = JSON.stringify(options);
  const {
    hasConflicts,
    hasFixableConflicts,
    worksForEveryone,
  } = parseSchedulingOptionsGQLByCategory(options);

  useEffect(() => {
    const parsedOptions = JSON.parse(stringifiedOptions) as Option[];
    const selectedOption = parsedOptions[selectedIndex];

    if (!selectedOption) {
      return;
    }

    setSelectedOptionIndex(selectedIndex);
  }, [selectedIndex, stringifiedOptions]);

  const handleChange = (selectedValue: string) => {
    const selectedIndex = parseInt(selectedValue);
    const updatedSelection = !isNaN(selectedIndex) ? options[selectedIndex] : null;
    if (!updatedSelection) {
      return;
    }
    onClick(selectedIndex);
  };

  return (
    <CustomSchedulingSelect
      disabled={disabled}
      onChange={handleChange}
      value={selectedOptionIndex?.toString() ?? ""}
      label={"More"}
      variant={variant}
    >
      {worksForEveryone.length > 0 && (
        <CustomSchedulingSelectOptionsGroup
          label={
            <>
              <EventAvailable fontSize="inherit" className="cw-text-positive-muted" />
              Works for everyone
            </>
          }
        >
          {worksForEveryone.map(({ label, value, interval, tradeoffBlocks }) => {
            return (
              <div
                key={`${label}-${value}`}
                onMouseEnter={() => onMouseEnter(interval, tradeoffBlocks)}
                onMouseLeave={onMouseLeave}
              >
                <SelectOption disabled={disabled} value={value.toString()}>
                  {label}
                </SelectOption>
              </div>
            );
          })}
        </CustomSchedulingSelectOptionsGroup>
      )}
      {hasFixableConflicts.length > 0 && (
        <CustomSchedulingSelectOptionsGroup
          label={
            <>
              <ClockwiseIntelligence className="cw-text-busy cw-w-3.5 cw-h-3.5" />
              Has fixable conflicts
            </>
          }
        >
          {hasFixableConflicts.map(({ label, value, interval, tradeoffBlocks }) => {
            return (
              <div
                key={`${label}-${value}`}
                onMouseEnter={() => onMouseEnter(interval, tradeoffBlocks)}
                onMouseLeave={onMouseLeave}
              >
                <SelectOption disabled={disabled} value={value.toString()}>
                  {label}
                </SelectOption>
              </div>
            );
          })}
        </CustomSchedulingSelectOptionsGroup>
      )}
      {hasConflicts.length > 0 && (
        <CustomSchedulingSelectOptionsGroup
          label={
            <>
              <EventBusy fontSize="inherit" className="cw-text-warning-muted" />
              Has conflicts
            </>
          }
        >
          {hasConflicts.map(({ label, value, interval, tradeoffBlocks }) => {
            return (
              <div
                key={`${label}-${value}`}
                onMouseEnter={() => onMouseEnter(interval, tradeoffBlocks)}
                onMouseLeave={onMouseLeave}
              >
                <SelectOption disabled={disabled} value={value.toString()}>
                  {label}
                </SelectOption>
              </div>
            );
          })}
        </CustomSchedulingSelectOptionsGroup>
      )}
    </CustomSchedulingSelect>
  );
};
