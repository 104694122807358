import React, { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  fill?: string;
  background?: string;
}

export const CategorySwatchIcon = ({
  size = 14,
  fill = "#039BE5",
  background = "none",
  ...props
}: Props) => {
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 14 14"
      fill={background}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="14" height="14" rx="4" fill={fill} />
    </svg>
  );
};
