import { Header } from "#webapp/src/components/web-app-calendar/calendar-popover/atoms/Header";
import { Divider } from "@clockwise/design-system";
import { fg_destructive } from "@clockwise/design-system/tokens";
import { Close, GroupFilled } from "@clockwise/icons";
import { writeRichTextToClipboard } from "@clockwise/web-commons/src/util/html.util";
import { LayersClearRounded, LayersRounded } from "@material-ui/icons";
import React from "react";
import toast from "react-hot-toast";
import { ListItemButton } from "../../../web-app-calendar/calendar-popover/atoms/ListItemButton";
import { PopoverWrapper } from "../../../web-app-calendar/calendar-popover/PopoverWrapper";
export const AttendeeActionsPopover = ({
  calendarId,
  anchorEl,
  onClose,
  onBlurCapture,
  onRemoveAttendee,
  onUpdateOptionality,
  onToggleCalendar,
  isCalendarVisible,
  isOptional,
}: {
  calendarId: string;
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
  onBlurCapture: () => void;
  onRemoveAttendee?: () => void;
  onUpdateOptionality?: (optional?: boolean) => void;
  onToggleCalendar?: () => void;
  isOptional: boolean;
  isCalendarVisible?: boolean;
}) => {
  async function copyEmailAddress() {
    const success = await writeRichTextToClipboard(calendarId, true);
    if (success) {
      toast.success("Copied attendee email to clipboard.");
    }
  }

  return (
    <PopoverWrapper
      id={calendarId}
      anchorEl={anchorEl}
      onClose={onClose}
      onBlurCapture={onBlurCapture}
      side="right"
      verticalPlacement="bottom"
    >
      <Header title={calendarId} actionType="COPY_EMAIL" onAction={copyEmailAddress} />
      <div className="cw-px-2">
        {onUpdateOptionality && (
          <ListItemButton startIcon={GroupFilled} onClick={() => onUpdateOptionality(!isOptional)}>
            Mark as {isOptional ? "required" : "optional"}
          </ListItemButton>
        )}
        {onToggleCalendar && (
          <ListItemButton
            startIcon={isCalendarVisible ? LayersClearRounded : LayersRounded}
            onClick={onToggleCalendar}
          >
            {isCalendarVisible ? "Hide Calendar" : " Show Calendar"}
          </ListItemButton>
        )}
        {(onToggleCalendar || onUpdateOptionality) && <Divider spacing="xs" />}
        {onRemoveAttendee && (
          <ListItemButton
            startIcon={Close}
            startIconColor={fg_destructive}
            onClick={onRemoveAttendee}
          >
            <span className="cw-text-destructive">Remove</span>
          </ListItemButton>
        )}
      </div>
    </PopoverWrapper>
  );
};
