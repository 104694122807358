import { FeatureSettingsContainer } from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import React from "react";
import CalendarSync from "../account-preferences-calendar-sync/CalendarSync";
import ColorCoding from "../account-preferences-color-coding/ColorCoding";
import FocusTime from "../account-preferences-focus-time/FocusTime";
import LunchHold from "../account-preferences-lunch-hold/LunchHold";
import MeetingBreaks from "../account-preferences-meeting-break/MeetingBreak";
import TravelTime from "../account-preferences-travel-time/TravelTime";
import WorkingMeetingHours from "../account-preferences-working-meeting-hours/WorkingMeetingHours";

const AccountPreferences = () => {
  return (
    <div>
      <FeatureSettingsContainer>
        <WorkingMeetingHours />
        <FocusTime />
        <MeetingBreaks />
        <LunchHold />
        <CalendarSync />
        <TravelTime />
        <ColorCoding />
      </FeatureSettingsContainer>
    </div>
  );
};

export default AccountPreferences;
