import { DateTime } from "luxon";

interface GetDateTimeFromPositionParams {
  /** The y-coordinate of the position */
  yPosition: number;
  /** The x-coordinate of the position */
  xPosition: number;
  /** Reference to the calendar container element */
  calendarContainerRef: React.RefObject<HTMLDivElement>;
  /** Array of dates to render - single item for single day view, multiple for week view */
  dateTimesToRender: DateTime[];
}

/**
 * Calculates the DateTime based on position in the calendar.
 */
export const getDateTimeFromPosition = ({
  yPosition,
  xPosition,
  calendarContainerRef,
  dateTimesToRender,
}: GetDateTimeFromPositionParams): DateTime => {
  const top = (calendarContainerRef.current?.getBoundingClientRect().top ?? 0) + window.scrollY;
  const height = calendarContainerRef.current?.offsetHeight ?? 0;
  const pxPerBlock = height / 96; // 15 minute blocks (24 hours × 4 blocks per hour = 96 blocks total)
  const yOffsetPosition = yPosition - top;

  // Calculate which time block they clicked on
  const timeBlockIndex = Math.floor(yOffsetPosition / pxPerBlock);

  if (dateTimesToRender.length === 1) {
    // Single day view - only consider vertical position
    return dateTimesToRender[0].startOf("day").plus({ minutes: timeBlockIndex * 15 });
  }

  // Multi-day view - consider both vertical and horizontal position
  const left = (calendarContainerRef.current?.getBoundingClientRect().left ?? 0) + window.scrollX;
  const width = calendarContainerRef.current?.offsetWidth ?? 0;
  const pxPerDay = width / dateTimesToRender.length;
  const xOffsetPosition = xPosition - left;
  const dateBlockIndex = Math.floor(xOffsetPosition / pxPerDay);

  return DateTime.fromISO(dateTimesToRender[dateBlockIndex].toISO(), {
    zone: dateTimesToRender[0].zone,
  })
    .startOf("day")
    .plus({ minutes: timeBlockIndex * 15 });
};
