import { FlexRange, FlexStatus } from "@clockwise/schema/v2";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { Interval } from "luxon";
import {
  EventForFlexibilityFromQuery,
  getTimeRangeFromFlex,
  isMeetingRecommendedForFlex,
  parseRecurrenceText,
  parseRecurrenceType,
} from "../../flexible-meetings/util/parseFlexibility";
import { FlexOnboardingEventsDocument } from "../step-components/flex-meeting-step/__generated__/FlexMeetingSlideNew.v2.generated";
import { getFlexMeetingMapFromSuggestionList } from "../step-components/flex-meeting-step/utils/suggestion-utils";
import { setError } from "../utils/setError";

const filterOutMeetingsToShow = (
  event: EventForFlexibilityFromQuery,
  backFromPreviousSlide?: boolean,
) => {
  if (backFromPreviousSlide) {
    return isMeetingRecommendedForFlex(event) && event.flexibility?.status !== FlexStatus.Paused;
  } else {
    return (
      isMeetingRecommendedForFlex(event) &&
      event.flexibility?.status !== FlexStatus.Paused &&
      !event.flexibility?.isFlexible
    );
  }
};

export const useOnboardingFetchEventFlexibility = ({
  onErrorCallback,
  backFromPreviousSlide,
}: {
  onErrorCallback: () => void;
  backFromPreviousSlide?: boolean;
}) => {
  const { data: events, loading, error } = useGatewayQuery(FlexOnboardingEventsDocument, {
    fetchPolicy: "no-cache",
    onError: (error) => {
      setError({
        error: error,
        message: "failed to fetch flex meeting suggestions",
        showUserMessage: false,
      });
      onErrorCallback();
    },
  });

  const filteredAndFormattedOneOnOnes =
    events?.flexibleMeetingsDashboardV2?.oneOnOnes
      ?.filter((event) => filterOutMeetingsToShow(event, backFromPreviousSlide))
      ?.map((event) => {
        const {
          id,
          externalEventId,
          flexibility,
          title,
          dateOrTimeRange,
          attendees,
          recurrenceRule,
        } = event;
        const startTime = Interval.fromISO(
          dateOrTimeRange.__typename === "DateRange"
            ? dateOrTimeRange.dateRange
            : dateOrTimeRange.timeRange,
        ).start;
        return {
          eventId: externalEventId,
          id: id, // this is the id of the event in the db, used for optimistic updates and cacheing
          title: title,
          status: flexibility?.status || FlexStatus.Pinned,
          attendees: attendees || [],
          startTime,
          isFlexible: !!flexibility?.isFlexible,
          flexRange: flexibility?.flexRange || FlexRange.Day,
          allowedDays: flexibility?.allowedDays || [],
          timeOfDayFlexRange: getTimeRangeFromFlex(flexibility?.timeOfDayFlexRange),
          recurrenceText: parseRecurrenceText({ recurrenceRule, startTime }),
          recurrenceType: parseRecurrenceType(recurrenceRule),
          showNotRecommededBadge: event.canMarkFlexible && !isMeetingRecommendedForFlex(event),
          canMarkFlexible: event.canMarkFlexible,
        };
      }) ?? [];

  const eventsConvertedToFlexIfFresh = backFromPreviousSlide
    ? filteredAndFormattedOneOnOnes
    : filteredAndFormattedOneOnOnes.map((event) => {
        return {
          ...event,
          isFlexible: true,
        };
      });

  const suggestedMap = eventsConvertedToFlexIfFresh?.length
    ? getFlexMeetingMapFromSuggestionList(eventsConvertedToFlexIfFresh)
    : null;

  return {
    flexOneOnOnes: suggestedMap,
    loading,
    error,
  };
};
