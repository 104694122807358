import { useMutation, useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { transform } from "@clockwise/web-commons/src/util/transform.util";
import { find } from "lodash";
import { compact, map } from "lodash/fp";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { UpdateUserFlowStateDocument } from "../../graphql/__generated__/UpdateUserFlowState.generated";
import { TrackingEvents, track } from "../../util/analytics.util";
import { isFlowStateFinished } from "../../util/flow-state.util";
import { getIsNewUser } from "../../util/getIsNewUser";
import { getCurrentOrg } from "../../util/org.util";
import { CurrentFlowStateDocument } from "../__generated__/CurrentFlowState.generated";
import { NuxChecklistDocument, NuxChecklistQuery } from "../__generated__/NUXChecklist.generated";
import { UserOnboardingChecklistDocument } from "../__generated__/UserOnboardingChecklist.v2.generated";
import { FlowStateType, OnboardingFlowState } from "../useOnboarding/useOnboarding";
import { NUXChecklistState, getCurrentActiveStep } from "./useNUXChecklist.util";

const getNUXChecklistState = (flowState?: FlowStateType) => {
  return (flowState?.current?.state as NUXChecklistState) ?? null;
};

const parseSuggestedFlexMeetingSugguestionsGQL = (data?: NuxChecklistQuery) => {
  const myOneOnOnes = getCurrentOrg(data?.viewer)?.flexibleMeetingsDashboard?.myOneOnOnes ?? [];
  const suggestedFlexMeetings =
    getCurrentOrg(data?.viewer)?.flexibleMeetingsDashboard?.suggestedFlexibleMeetings ?? [];

  const suggestions = [...myOneOnOnes, ...suggestedFlexMeetings].filter(
    (event) => event.flexibilityConstraints?.allowedToModify === true,
  );
  return suggestions.length > 0;
};

const parseFlexMeetingEnabledGQL = (data?: NuxChecklistQuery) => {
  const checklist = getCurrentOrg(data?.viewer)?.onboardingChecklist;
  return getValue(checklist?.flexMeetingEnabled)?.flexMeetingEnabled ?? false;
};

export const useNUXChecklist = () => {
  const { data: flowStateData, loading: loadingFlowStates } = useQuery(CurrentFlowStateDocument);
  const { data: checklistData, loading: loadingChecklist } = useQuery(NuxChecklistDocument, {
    variables: { timeZone: DateTime.local().zoneName },
    fetchPolicy: "no-cache",
  });

  const { data: checklistV2Data, loading: loadingChecklistV2 } = useGatewayQuery(
    UserOnboardingChecklistDocument,
    {
      fetchPolicy: "no-cache",
    },
  );

  const [updateFlowStateUser] = useMutation(UpdateUserFlowStateDocument);
  const [flexMeetingState, setFlexMeetingState] = useState<FlowStateType | null>(null);
  const [holdState, setHoldState] = useState<FlowStateType | null>(null);
  const [scheduleMeetingState, setScheduleMeetingState] = useState<FlowStateType | null>(null);
  const [aiChecklistState, setAiChecklistState] = useState<FlowStateType | null>(null);
  const [preferencesState, setPreferencesState] = useState<FlowStateType | null>(null);
  const [flexMeetingSuggestions, setFlexMeetingSuggestions] = useState(true);

  useEffect(() => {
    if (flowStateData) {
      const userFlowStates = flowStateData?.viewer.user?.flowStates?.edges ?? [];
      const getFlowState = (flowKey: OnboardingFlowState) =>
        transform(userFlowStates, compact, map("node"), (s) => find(s, { flowKey }) ?? null);

      const flexMeetingsFlowState = getFlowState(OnboardingFlowState.ChecklistFlexMeetings);
      const holdsFlowState = getFlowState(OnboardingFlowState.ChecklistHolds);
      const preferencesFlowState = getFlowState(OnboardingFlowState.ChecklistPreferences);
      const scheduleMeetingsState = getFlowState(OnboardingFlowState.ChecklistScheduleMeeting);
      const aiChecklistClosedCurrentState = getFlowState(OnboardingFlowState.AIChecklistClosed);

      setFlexMeetingState(flexMeetingsFlowState);
      setHoldState(holdsFlowState);
      setPreferencesState(preferencesFlowState);
      setScheduleMeetingState(scheduleMeetingsState);
      setAiChecklistState(aiChecklistClosedCurrentState);
    }
  }, [flowStateData]);

  useEffect(() => {
    if (checklistData && flexMeetingState) {
      const hasFlexMeetingSuggestions = parseSuggestedFlexMeetingSugguestionsGQL(checklistData);
      const flexMeetingEnabled = parseFlexMeetingEnabledGQL(checklistData);
      const hasCompletedProposal =
        checklistV2Data?.personalizedStatsRow?.onboardingChecklist?.hasCompletedProposal ?? false;
      const flexMeetingFinsihed = isFlowStateFinished(flexMeetingState);
      const scheduledMeetingFinished = isFlowStateFinished(scheduleMeetingState);
      // If Flex meeting enable d but mark ChecklistFlexMeetings as finished if not already finished
      if (flexMeetingEnabled && !flexMeetingFinsihed) {
        finishStep(OnboardingFlowState.ChecklistFlexMeetings);
      }
      if (hasCompletedProposal && !scheduledMeetingFinished) {
        finishStep(OnboardingFlowState.ChecklistScheduleMeeting);
      }
      // If no flex meeting suggestions, mark ChecklistFlexMeetings as finished
      if (!hasFlexMeetingSuggestions && !flexMeetingFinsihed) {
        finishStep(
          OnboardingFlowState.ChecklistFlexMeetings,
          undefined,
          !hasFlexMeetingSuggestions,
        );
      }
      setFlexMeetingSuggestions(hasFlexMeetingSuggestions);
    }
  }, [checklistData, flexMeetingState, checklistV2Data, scheduleMeetingState]);

  const skipStep = (flowKey: OnboardingFlowState, callback?: () => void) => {
    track(TrackingEvents.NUX_CHECKLIST.STEP_SKIPPED, { step: flowKey });
    updateFlowStateUser({
      variables: { input: { newState: NUXChecklistState.Skipped, flowKey: flowKey } },
      onCompleted: () => {
        if (!!callback) {
          callback();
        }
      },
    });
  };

  const finishStep = (
    flowKey: OnboardingFlowState,
    callback?: () => void,
    noSuggestions?: boolean,
  ) => {
    track(TrackingEvents.NUX_CHECKLIST.STEP_FINISHED, {
      step: flowKey,
      skippedDueToNoSuggestions: noSuggestions,
    });
    updateFlowStateUser({
      variables: { input: { newState: NUXChecklistState.Finished, flowKey: flowKey } },
      onCompleted: () => {
        if (!!callback) {
          callback();
        }
      },
    });
  };

  const isNewUser = getIsNewUser(flowStateData?.viewer.user);

  const flexMeetingCurrentState: NUXChecklistState | null = useMemo(
    () => getNUXChecklistState(flexMeetingState),
    [flexMeetingState],
  );
  const holdsCurrentState: NUXChecklistState | null = useMemo(
    () => getNUXChecklistState(holdState),
    [holdState],
  );
  const preferencesCurrentState: NUXChecklistState | null = useMemo(
    () => getNUXChecklistState(preferencesState),
    [preferencesState],
  );
  const scheduleMeetingCurrentState: NUXChecklistState | null = useMemo(
    () => getNUXChecklistState(scheduleMeetingState),
    [scheduleMeetingState],
  );
  const aiChecklistClosedCurrentState: NUXChecklistState | null = useMemo(
    () => getNUXChecklistState(aiChecklistState),
    [aiChecklistState],
  );

  const activeStep = useMemo(() => {
    if (
      flexMeetingCurrentState &&
      holdsCurrentState &&
      preferencesCurrentState &&
      scheduleMeetingCurrentState
    ) {
      const input = {
        [OnboardingFlowState.ChecklistFlexMeetings]: flexMeetingCurrentState,
        [OnboardingFlowState.ChecklistPreferences]: preferencesCurrentState,
        [OnboardingFlowState.ChecklistHolds]: holdsCurrentState,
        [OnboardingFlowState.ChecklistScheduleMeeting]: scheduleMeetingCurrentState,
      };
      return getCurrentActiveStep(input);
    }
    return null;
  }, [
    flexMeetingCurrentState,
    holdsCurrentState,
    preferencesCurrentState,
    scheduleMeetingCurrentState,
  ]);

  const loading = loadingFlowStates || loadingChecklist || loadingChecklistV2;

  const showFlexMeetingStep = useMemo(() => {
    return flexMeetingSuggestions;
  }, [flexMeetingSuggestions]);

  const totalSteps = useMemo(() => {
    return showFlexMeetingStep ? 4 : 3;
  }, [showFlexMeetingStep]);

  const completedSteps = useMemo(() => {
    const numFinished = [
      true, //by default we mark connect your calendar as finished
      showFlexMeetingStep ? isFlowStateFinished(flexMeetingState) : null,
      isFlowStateFinished(holdState),
      isFlowStateFinished(preferencesState),
    ].filter((finished: boolean) => finished === true);

    return numFinished.length;
  }, [flexMeetingState, holdState, preferencesState, showFlexMeetingStep]);

  return {
    loading,
    skipStep,
    finishStep,
    flexMeetingCurrentState,
    holdsCurrentState,
    preferencesCurrentState,
    scheduleMeetingCurrentState,
    aiChecklistClosedCurrentState,
    activeStep,
    showFlexMeetingStep,
    totalSteps,
    completedSteps,
    isNewUser,
  };
};
