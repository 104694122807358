import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import { nth } from "lodash";
import { IIntegerDataPoint, IOrgOverview } from "./overview.types";

/*
  NB: To display a more static snapshot of an
  org's Clockwise usage, we display data from
  the previous accounting period once there are
  two accounting periods in the orgs lifecycle,
  ie. the current and previous periods. Accounting
  periods currently exist in the context of schedule
  assists.

  scheduleAssistCount returns an array of cumulative
  schedule assists for each billing cycle for the org,
  sorted by oldest date first.

  getOrgOverviewTimeframe uses scheduleAssistCount to
  get the previous two billing cycle dates.
 */
export function getOrgOverviewTimeframe({ scheduleAssistCount }: IOrgOverview) {
  // ordered by ascending date, ie, oldest date first, latest date last
  const endDate = nth(scheduleAssistCount, -1)?.label;
  const startDate = nth(scheduleAssistCount, -2)?.label;

  if (startDate && endDate) {
    return [startDate, endDate];
  }

  // if there is no previous period
  if (endDate && !startDate) {
    return [endDate, new ZonedMoment(endDate).clone().add(2, "weeks").format("YYYY-MM-DD")];
  }

  // if there are no periods
  return ["", ""];
}

export function getOrgAccountPeriodLength(orgOverview: IOrgOverview) {
  const [startDate, endDate] = getOrgOverviewTimeframe(orgOverview);

  if (startDate && endDate) {
    return new ZonedMoment(endDate).diff(new ZonedMoment(startDate), "days");
  }

  return null;
}

// Highcharts time series charts want date in millis as a [number, number][]
export function formatOrgDataForTimeSeries(dataPoints: IIntegerDataPoint[]): [number, number][] {
  if (!dataPoints?.length) {
    return [];
  }

  return dataPoints.map((dataPoint) => [
    new ZonedMoment(dataPoint.label).valueOf(),
    dataPoint.value,
  ]);
}

export function formatDateAsShortMonthDate(date: string) {
  return new ZonedMoment(date).format("MMM D");
}

export function formatNumberAsAbbreviation(num: number) {
  const abbreviations = ["k", "M", "B", "T"];
  let formattedNum = "";

  for (let abbr = abbreviations.length - 1; abbr >= 0; abbr--) {
    const powerOfTen = Math.pow(10, (abbr + 1) * 3);
    // only abbreviate nums larger than or equal to 10k
    if (num >= powerOfTen && num >= 10000) {
      const abbrNum = num / powerOfTen;
      formattedNum =
        new Intl.NumberFormat("en-US", { maximumSignificantDigits: 3 }).format(abbrNum) +
        abbreviations[abbr];
      break;
    }

    formattedNum = new Intl.NumberFormat().format(num);
  }

  return formattedNum;
}
