import React, { useMemo } from "react";
import { useFlowState } from "../../hooks/useFlowState";
import { OnboardingFatalError } from "../onboarding-fatal-error";
import { FastCrawlRequired } from "./FastCrawlRequired";
import { StepComponentMapType, WebOnboardingStep } from "./WebOnboarding.util";
import { WebOnboardingLayout } from "./WebOnboardingLayout";
import { useWebOnboardingSteps } from "./hooks/useWebOnboardingSteps";
import { ErrorWrapper } from "./shared-components";
import {
  DownloadClockwiseStep,
  FinishedLoadingStep,
  FlexMeetingStep,
  GetToKnowYouStep,
  InviteStep,
  LinksStep,
  SlackStep,
} from "./step-components";
import { AISchedulerStep } from "./step-components/ai-scheduler-step/AISchedulerStep";
import { DefragStep } from "./step-components/defrag-step";
import { FocusTimeStep } from "./step-components/focus-time-step/FocusTimeStep";
import { InviteStepProvider } from "./step-components/invite-step";
import { LunchStep } from "./step-components/lunch";
import { ScheduleStep } from "./step-components/schedule-step";
import { TeamJoinCreateStep } from "./step-components/team-join-create-step/TeamJoinCreateStep";
import { setError } from "./utils/setError";

export const WebOnboardingSteps = ({
  orgId,
  canSkipOnboarding,
}: {
  orgId: string;
  canSkipOnboarding: boolean;
}) => {
  const { currentStep } = useWebOnboardingSteps();
  const { fastCrawlError } = useFlowState();
  const CurrentStep = () => {
    const stepComponentMap: StepComponentMapType = {
      [WebOnboardingStep.GetToKnowYou]: (
        <GetToKnowYouStep orgId={orgId} canSkipOnboarding={canSkipOnboarding} />
      ),
      [WebOnboardingStep.TeamJoinCreate]: (
        <FastCrawlRequired>
          <TeamJoinCreateStep />
        </FastCrawlRequired>
      ),
      [WebOnboardingStep.AIScheduler]: <AISchedulerStep />,
      [WebOnboardingStep.Links]: <LinksStep />,
      [WebOnboardingStep.Invite]: (
        <FastCrawlRequired>
          <InviteStep />
        </FastCrawlRequired>
      ),
      [WebOnboardingStep.FlexMeeting]: (
        <FastCrawlRequired>
          <FlexMeetingStep />
        </FastCrawlRequired>
      ),
      [WebOnboardingStep.FocusTime]: (
        <FastCrawlRequired>
          <FocusTimeStep orgId={orgId} />
        </FastCrawlRequired>
      ),
      [WebOnboardingStep.Lunch]: (
        <FastCrawlRequired>
          <LunchStep />
        </FastCrawlRequired>
      ),
      [WebOnboardingStep.Slack]: <SlackStep />,
      [WebOnboardingStep.DownloadClockwise]: <DownloadClockwiseStep />,
      [WebOnboardingStep.Finished]: <FinishedLoadingStep />,
      [WebOnboardingStep.Schedule]: <ScheduleStep />,
      [WebOnboardingStep.Defrag]: <DefragStep />,
    };
    return stepComponentMap[currentStep];
  };

  // dont re render when fastCrawl updates
  const CurrentStepComponent = useMemo(() => {
    return (
      <WebOnboardingLayout>
        <InviteStepProvider>
          <CurrentStep />
        </InviteStepProvider>
      </WebOnboardingLayout>
    );
  }, [currentStep]);

  if (fastCrawlError) {
    setError({ message: "fast crawl error", showUserMessage: false });
    return (
      <ErrorWrapper>
        <OnboardingFatalError onConfirm={() => null} />
      </ErrorWrapper>
    );
  }

  return CurrentStepComponent;
};
