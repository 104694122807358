import { graphql } from "react-relay";

export const smartHoldSyncFragments = {
  org: graphql`
    fragment SmartHoldSync_org on Org {
      id
      primaryOrgCalendar
      shadowCalendarSettingsErrorable {
        __typename
        ... on ShadowCalendarSettings {
          focusTimeSyncEnabled
          lunchSyncEnabled
          travelTimeSyncEnabled
          focusTimeExternalTransparency
          focusTimeIdealMinutesPerWeek
          focusTimeSyncAll
          focusTimeRemindersEnabled
          lunchRemindersEnabled
          travelTimeRemindersEnabled
        }
      }
      focusTimeStats {
        __typename
        ... on FocusTimeStats {
          minutesPerWeek
        }
      }
    }
  `,
};
