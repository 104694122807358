import { EventBusy } from "@material-ui/icons";
import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import { CalendarColorSet, getOwnCalendarColor } from "../../../util/calendar-coloring";
import { CalendarPositioner } from "../calendar-positioner/CalendarPositioner";
import { OutOfOfficeInterval } from "./types";

export const OutOfOfficeCards = ({
  dateTimes = [],
  outOfOfficeIntervals = [],
}: {
  dateTimes: DateTime[];
  outOfOfficeIntervals: OutOfOfficeInterval[];
}) => {
  const hasOutOfOfficeIntervals = outOfOfficeIntervals.length > 0;
  if (!hasOutOfOfficeIntervals) {
    return null;
  }

  return (
    <CalendarPositioner
      dateTimes={dateTimes}
      gutters
      conflictResolution="column"
      positionables={outOfOfficeIntervals.map(
        ({ interval, key, colorSet, text, subText, headless, onClick }) => ({
          key: `${interval.toISO()}_${key}`,
          interval,
          render: ({ position }) => (
            <OutOfOfficeCard
              colorSet={colorSet}
              fade={interval.end < DateTime.local()}
              headless={!!headless}
              leftPad={position.renderOrder > 0}
              onClick={onClick}
              subText={subText}
              text={text}
            />
          ),
        }),
      )}
    />
  );
};

export const OutOfOfficeCard = ({
  colorSet = defaultColorSet,
  fade = false,
  headless,
  leftPad = false,
  onClick,
  subText = "",
  text = "",
}: {
  colorSet: CalendarColorSet;
  fade: boolean;
  headless: boolean;
  leftPad?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  subText?: string;
  text?: string;
}) => {
  return (
    <div className="cw-h-full cw-w-full cw-bg-default cw-rounded ">
      <div
        className={classNames(
          "cw-h-full cw-w-full cw-overflow-hidden",
          leftPad ? "cw-pl-1" : "cw-pl-0",
          { "cw-opacity-50": fade },
        )}
      >
        <div
          className={classNames("cw-h-full cw-w-full cw-cursor-pointer", !headless && "cw-rounded")}
          style={{
            backgroundColor: headless ? `${colorSet.bg}b3` : colorSet.bg,
          }}
          onClick={onClick}
        >
          {!headless && (
            <div
              className="cw-p-1 cw-body-base backdrop:cw-leading-[13px] cw-text-[12px] cw-font-medium"
              style={{ color: colorSet.fg }}
            >
              <div>
                <EventBusy className="cw-inline-block cw-mr-1 cw-text-[16px] cw-align-middle" />
                {text}
              </div>
              <div className="cw-text-[11px] cw-font-normal">{subText}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const defaultColorSet = getOwnCalendarColor();
