import { PublicPerson } from "@clockwise/schema/v2";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import React from "react";
import { GQLPerson } from "../chat/ai-chat/utils/types";
import { useReadCalendarColors } from "../web-app-calendar/hooks/CalendarColorsContext";

export const ProposalOwnerAvatar = ({ owner }: { owner: GQLPerson | PublicPerson }) => {
  const calendarColors = useReadCalendarColors();
  const primaryCalendarId =
    owner.__typename === "PublicPerson" ? owner.primaryCalendarId : owner.primaryEmail;

  return (
    <>
      {owner.profile && (
        <span>
          <AttendeeAvatar
            profile={owner.profile}
            size="xlarge"
            key={primaryCalendarId}
            borderColor={calendarColors?.[primaryCalendarId]?.border ?? "#000000"}
          />
        </span>
      )}
    </>
  );
};
