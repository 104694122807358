import { ToggleButtonGroup } from "@clockwise/design-system";
import { FlexRange } from "@clockwise/schema/v2";
import React from "react";

type Props = {
  disabled: boolean;
  range: FlexRange;
  onChange: (range: FlexRange) => void;
};

export const RangeControl = ({ range: _range, disabled, onChange }: Props) => {
  // Present specific days as week
  const range = _range === FlexRange.SpecificDays ? FlexRange.Week : _range;

  return (
    <ToggleButtonGroup
      aria-label="Flexible settings"
      label="Allowed to move within:"
      disabled={disabled}
      fullWidth
      value={range}
      onChange={(value) => onChange(value as FlexRange)}
      options={[
        { value: FlexRange.Day, label: "Day" },
        {
          value: FlexRange.Week,
          label: "Week",
          disabled: false,
        },
      ]}
    />
  );
};
