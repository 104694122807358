import React from "react";
import { ClickableContainer, EventDetailsHeader } from "../atoms/EventDetails";
import { PrivateLock } from "../atoms/PrivateLock";
import { useEventPopoverDetails } from "../hooks/useEventPopoverDetails";
import { Footer } from "../molecules/Footer";
import { ExpandButton, Header } from "../molecules/Header";

export const LockedEventMenu = ({
  externalEventId,
  calendarId,
  title,
  onClose,
  onOpenCard,
  canExpand = true,
}: {
  externalEventId: string;
  calendarId: string;
  title: string;
  onClose: () => void;
  onOpenCard: () => void;
  canExpand?: boolean;
}) => {
  const { eventTimeString, loading: loadingDetails, ownerDisplayName } = useEventPopoverDetails({
    externalEventId,
    calendarId,
  });

  return (
    <>
      <Header onClose={onClose}>{canExpand && <ExpandButton onClick={onOpenCard} />}</Header>
      <div className="cw-px-2">
        <ClickableContainer onClick={onOpenCard}>
          <EventDetailsHeader
            attendees={[]}
            calendarId={calendarId}
            loading={loadingDetails}
            title={title}
            isLocked={true}
            eventTimeString={eventTimeString}
          />
          <div className="cw-flex-col cw-space-y-1">
            <PrivateLock />
          </div>
        </ClickableContainer>
      </div>
      <Footer
        canRSVP={false}
        showEventOwner={true}
        ownerDisplayName={ownerDisplayName}
        loading={loadingDetails}
        calendarId={calendarId}
      />
    </>
  );
};
