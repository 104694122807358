import { graphql } from "react-relay";

// gql

export const webAppCheckoutFragments = {
  viewer: graphql`
    fragment WebAppCheckout_viewer on Viewer {
      user {
        orgs(first: 1000000) {
          edges {
            node {
              id
              experiments
              primaryTeam {
                __typename
                ... on Team {
                  teamId
                }
                ... on GraphEntityError {
                  statusCode
                  message
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const webAppCheckoutQuery = graphql`
  query WebAppCheckoutQuery {
    viewer {
      ...WebAppCheckout_viewer
    }
  }
`;
