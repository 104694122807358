import { Button, ButtonSet, Tooltip } from "@clockwise/design-system";
import { RepeatingEventSaveOption } from "@clockwise/schema";
import { RescheduleAI } from "@clockwise/web-commons/src/components/svgs/RescheduleAI";
import { DeleteOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { isNull } from "lodash";
import React, { useState } from "react";
import { useAIMessageContext } from "../../chat/ai-chat/hooks/AIMessageContext";
import { AI_EXCLUSION_TOOLTIP } from "../../chat/constants";
import { SaveDropdown } from "../atoms/SaveDropdown";
import { getSubmitButtonCopy } from "../utils/getSubmitButtonCopy";
import { ECDeleteConfirm } from "./ECDeleteConfirm";

export const ECFooter = ({
  disabled = false,
  onDiscard,
  onSubmit,
  canModify,
  canRemove,
  canDelete,
  updatesPresent = false,
  onUpdateCancel,
  isCancelled,
  disabledRecurrenceSaveOptions = [],
  showDropdownOnSave = false,
  isProposal = false,
  shouldSendUpdatesAfterSave = false,
  onReschedule,
  showSaveAndFix = false,
  onSaveAndFix,
}: {
  disabled: boolean;
  onDiscard?: () => void;
  onSubmit: (options: RepeatingEventSaveOption) => void;
  canModify: boolean;
  canRemove: boolean;
  canDelete: boolean;
  updatesPresent?: boolean;
  onUpdateCancel?: (options?: RepeatingEventSaveOption) => void;
  isCancelled?: boolean;
  isProposal?: boolean;
  disabledRecurrenceSaveOptions?: RepeatingEventSaveOption[];
  showDropdownOnSave?: boolean;
  shouldSendUpdatesAfterSave?: boolean;
  onReschedule?: () => void;
  showSaveAndFix?: boolean;
  onSaveAndFix?: (option: RepeatingEventSaveOption) => void;
}) => {
  const { shouldExcludeAi } = useAIMessageContext();
  const [isConfirmDeleteShowing, setIsConfirmDeleteShowing] = useState(false);

  const hasDiscardButton = !!onDiscard;
  const hasRescheduleButton = canModify && onReschedule;
  const hasUpdateButton = onUpdateCancel && canRemove;
  const deleteRemoveCopy = canDelete ? "Delete this event" : "Remove from my calendar";
  const deletionButtonCopy = hasDiscardButton || hasRescheduleButton ? null : deleteRemoveCopy; // use icon button if we have discard or reschedule buttons
  const deletionTooltipCopy = isNull(deletionButtonCopy) ? deleteRemoveCopy : null; // provide tooltip if we dont have delete text

  const submissionCopy = getSubmitButtonCopy(isProposal, shouldSendUpdatesAfterSave);

  const saveAndFixButton = showSaveAndFix && onSaveAndFix && (
    <Button
      onClick={() => onSaveAndFix(RepeatingEventSaveOption.ThisInstanceOnly)}
      size="xsmall"
      variant="outlined"
      sentiment="neutral"
      disabled={disabled}
    >
      Save and fix conflicts
    </Button>
  );

  return (
    <div
      className={classNames("cw-flex cw-p-3 cw-justify-between cw-relative", {
        "cw-z-10": isCancelled,
      })}
    >
      {isConfirmDeleteShowing && (
        <ECDeleteConfirm
          onCancelEvent={(option: RepeatingEventSaveOption) => {
            onUpdateCancel?.(option);
          }}
          onHide={() => {
            setIsConfirmDeleteShowing(false);
          }}
          canDelete={canDelete}
          showDropdownOnSave={showDropdownOnSave}
        />
      )}
      {hasUpdateButton ? (
        <div className="cw-flex cw-gap-2">
          {isCancelled ? (
            <Button
              onClick={() => {
                onUpdateCancel?.();
              }}
              disabled={disabled}
              variant="outlined"
              sentiment="neutral"
              size="xsmall"
            >
              Undo event cancellation
            </Button>
          ) : (
            <Tooltip title={deletionTooltipCopy}>
              <Button
                onClick={() => {
                  setIsConfirmDeleteShowing(true);
                }}
                size="xsmall"
                disabled={disabled}
                aria-label={deleteRemoveCopy}
                startIcon={DeleteOutlined}
                variant="outlined"
                sentiment="destructive"
              >
                {saveAndFixButton ? undefined : deletionButtonCopy}
              </Button>
            </Tooltip>
          )}
          {hasRescheduleButton && (
            <Tooltip title={shouldExcludeAi && AI_EXCLUSION_TOOLTIP}>
              <span>
                <Button
                  aria-label="Reschedule with AI"
                  size="xsmall"
                  variant="outlined"
                  disabled={shouldExcludeAi}
                  sentiment="neutral"
                  startIcon={RescheduleAI}
                  onClick={onReschedule}
                >
                  Reschedule
                </Button>
              </span>
            </Tooltip>
          )}{" "}
        </div>
      ) : (
        <div /> // Empty div to keep the spacing
      )}
      <ButtonSet>
        {!isCancelled && (
          <>
            {!disabled && showDropdownOnSave ? (
              <>
                {saveAndFixButton}
                <SaveDropdown
                  disabled={disabled || !updatesPresent}
                  onSelect={onSubmit}
                  copy={submissionCopy}
                  disabledRecurrenceSaveOptions={disabledRecurrenceSaveOptions}
                />
              </>
            ) : (
              <>
                {hasDiscardButton && (
                  <Button
                    onClick={onDiscard}
                    size="xsmall"
                    variant="outlined"
                    sentiment="neutral"
                    disabled={disabled || !updatesPresent}
                  >
                    Discard updates
                  </Button>
                )}
                {saveAndFixButton}
                <Button
                  onClick={() => onSubmit(RepeatingEventSaveOption.ThisInstanceOnly)}
                  size="xsmall"
                  variant="solid"
                  sentiment="positive"
                  disabled={disabled || !updatesPresent}
                  aria-label={submissionCopy.default}
                >
                  {submissionCopy.default}
                </Button>
              </>
            )}
          </>
        )}
      </ButtonSet>
    </div>
  );
};
