import { SlackLogo } from "@clockwise/web-commons/src/components/svgs/SlackLogo";
import React from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";

export const SlackDNDBrief = () => {
  return (
    <EventElementsWrap name="doNotDisturb">
      <div className="cw-text-13 cw-items-center">
        Changes status to <span className="cw-font-medium">Do Not Disturb</span> on{" "}
        <SlackLogo size={14} className="cw-mb-[-2px]" />{" "}
        <span className="cw-font-medium">Slack</span>
      </div>
    </EventElementsWrap>
  );
};
