//////////////////
// IMPORTS
//////////////////
// libraries
import * as React from "react";
import { connect } from "react-redux";
import { createFragmentContainer } from "react-relay";

import { withRouterQuery } from "#webapp/src/routing/routes.util";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { page, PageEvents } from "#webapp/src/util/analytics.util";
import { isAuthed } from "#webapp/src/util/auth.util";
import { ChromeSlackVerifier } from "../chrome-slack-verifier";
import { chromeSlackWrapperFragments, chromeSlackWrapperQuery } from "./ChromeSlackWrapper.gql";
import IChromeSlackWrapper from "./ChromeSlackWrapperTypes";

//////////////////
// COMPONENT
//////////////////

class ChromeSlackWrapperCmpt extends React.Component<IChromeSlackWrapper.Props> {
  public render() {
    if (!isAuthed()) {
      window.location.replace("./slack");
      return null;
    } else {
      return <ChromeSlackVerifier viewer={this.props.viewer} />;
    }
  }

  public componentDidMount() {
    page(PageEvents.CHROME_SLACK_VERIFIER);
  }
}

//////////////////
// REDUX
//////////////////
function mapStateToProps(_state: IReduxState, _ownProps: IChromeSlackWrapper.Props) {
  return {};
}

const reduxContainer = connect(mapStateToProps, {})(ChromeSlackWrapperCmpt);

//////////////////
// RELAY
//////////////////

export const ChromeSlackWrapper = createFragmentContainer<IChromeSlackWrapper.Props>(
  reduxContainer,
  chromeSlackWrapperFragments,
);

export const ChromeSlackWrapperPage = withRouterQuery(ChromeSlackWrapper, chromeSlackWrapperQuery);
