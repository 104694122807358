import classNames from "classnames";
import { Duration, Interval } from "luxon";
import React, { useMemo } from "react";
import { ICalPosition, ICalPositionable, ICalPositioner } from "./types";
import { getColumnPlacement } from "./utils/getColumnPlacement";
import { getPosition } from "./utils/getPosition";
import { getRailPosition } from "./utils/getRailPosition";
import { byRenderOrderAndTop } from "./utils/sortByRenderOrderAndTop";

export const CalendarPositioner: React.FC<ICalPositioner> = ({
  dateTimes = [],
  conflictResolution = "column",
  gutters = false,
  minDuration = Duration.fromObject({ minutes: 1 }),
  positionables: allPositionables = [],
  columnIndexOverride,
  columnCountOverride,
  minWidth,
  fullWidth,
  nonInteractive = false,
}) => {
  const dateRange = useMemo(
    () =>
      dateTimes.length > 0
        ? Interval.fromDateTimes(
            dateTimes[0].startOf("day"),
            dateTimes[dateTimes.length - 1].endOf("day"),
          )
        : null,
    [dateTimes],
  );

  const positionables = useMemo(
    () =>
      allPositionables.filter((positionable) => dateRange?.contains(positionable.interval.start)),
    [allPositionables, dateRange],
  );

  const columnedItems = getColumnPlacement({
    allowOverlap: conflictResolution === "columnOverlap",
    doNotUseSubcolumns: conflictResolution === "none",
    dateTimes,
    positionables,
    minDuration,
    columnIndexOverride,
  });

  const positionedItems = columnedItems.map((columnedItem) => ({
    item: {
      ...columnedItem.item,
      columnIndex: columnedItem.placement.columnIndex,
    },
    position: columnedItem.item.rail
      ? getRailPosition({
          columnedItem,
          columnCount: columnCountOverride ?? dateTimes.length,
          minDuration,
        })
      : getPosition({
          columnedItem,
          columnCount: columnCountOverride ?? dateTimes.length,
          gutters,
          minDuration,
          minWidth,
          fullWidth,
        }),
  }));

  return (
    <>
      {positionedItems.sort(byRenderOrderAndTop).map(({ item, position }) => {
        return (
          <Item position={position} key={item.key} item={item} nonInteractive={nonInteractive} />
        );
      })}
    </>
  );
};

const Item = ({
  position,
  item,
  nonInteractive,
}: {
  position: ICalPosition;
  item: ICalPositionable & { columnIndex: number };
  nonInteractive: boolean;
}) => {
  const itemRef = React.useRef<HTMLDivElement>(null);

  return (
    <div
      key={item.key}
      style={posToCSS(position)}
      className={classNames(
        "cw-absolute",
        "cw-transition-top cw-transition-left cw-transition-width cw-transition-height cw-duration-300",
        { "cw-pointer-events-none": nonInteractive },
      )}
      ref={itemRef}
    >
      {item.render({
        position: position,
        columnIndex: item.columnIndex,
      })}
    </div>
  );
};

const posToCSS = ({ top, left, height, width, floatOffset, minWidth }: ICalPosition) => {
  return {
    top: `${top}%`,
    left: `${left + floatOffset}%`,
    height: `${height}%`,
    width: `${width - floatOffset}%`,
    minWidth: minWidth ?? undefined,
  };
};
