import classNames from "classnames";
import React, { forwardRef } from "react";

type Props = {
  eventName: string;
  onEventNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly: boolean;
};
export const ECTitle = forwardRef<HTMLInputElement, Props>(function ECTitle(
  { eventName, onEventNameChange, readOnly },
  ref,
) {
  return (
    <div className="cw-border-none">
      <div className="cw-py-2 cw-pr-3 cw-pl-2 cw-flex cw-border-solid cw-border-b cw-border-subtle cw-items-center cw-justify-between cw-gap-2 cw-mb-4">
        <input
          ref={ref}
          disabled={readOnly}
          className={classNames(
            "cw-p-1 cw-h-7 cw-body-base cw-flex-1 cw-rounded-lg cw-font-semibold disabled:cw-bg-default",
            { "cw-border-none": readOnly },
            {
              "cw-border-[#fff] cw-border-solid cw-border hover:cw-border-subtle focus:cw-border-brand-interactable focus:hover:cw-border-brand-interactable-hover focus-visible:cw-outline-brand-interactable focus:cw-outline-brand-interactable focus:hover:cw-outline-brand-interactable-hover": !readOnly,
            },
          )}
          value={eventName}
          placeholder="(No title)"
          onChange={(e) => {
            onEventNameChange?.(e);
          }}
          aria-label="Event title"
        />
      </div>
    </div>
  );
});
