import { ApolloCache, useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";
import {
  ToggleLunchDocument,
  ToggleLunchMutation,
} from "../graphql/__generated__/ToggleLunch.generated";

import { logger } from "@clockwise/client-commons/src/util/logger";
import { isEqual } from "lodash";
import { LunchSettings } from "../types";

export const useLunchToggle = (
  orgId: string,
  lunchSettings: LunchSettings,
  {
    onCompleted,
    onError,
    onUpdate,
  }: {
    onCompleted?: () => void;
    onError?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUpdate?: (cache: ApolloCache<any>) => void;
  },
) => {
  const [toggleLunch, { loading, error }] = useMutation(ToggleLunchDocument);

  const onErrorHandler = useMemo(() => onError, [onError]);
  const onUpdateHandler = useMemo(() => onUpdate, [onUpdate]);

  const onCompleteHandler = useCallback(
    (expectedData: ToggleLunchMutation) => (data: ToggleLunchMutation) => {
      if (!isEqual(expectedData.ToggleLunch, data.ToggleLunch)) {
        // @scott - cleanup as part of AI-2044
        // logging to sentry to confrim error severtiy
        logger.error("Lunch Hold Toggle: Optimistic update does not match server response.", {
          expectedData,
          data,
        });

        onError?.();
      } else {
        onCompleted?.();
      }
    },
    [onCompleted, onError],
  );

  const toggle = useCallback(
    (enabled: boolean) => {
      const variables = { orgId, enabled };

      const optimisticResponse: ToggleLunchMutation = {
        ToggleLunch: {
          lunchGoal: {
            id: lunchSettings.id,
            enabled,
            preferredLunchTimes: {
              earliestStart: lunchSettings.earliestStart,
              latestEnd: lunchSettings.latestEnd,
              minimumDuration: lunchSettings.minimumDuration.toISO(),
              idealDuration: lunchSettings.idealDuration.toISO(),
              __typename: "PreferredLunchTimes",
            },
            remoteHoldSettings: {
              scheduleAs: lunchSettings.scheduleAs,
              notificationStrategy: lunchSettings.notificationStrategy,
              __typename: "RemoteSmartHoldSettings",
            },
            __typename: "LunchGoals",
          },
          __typename: "ToggleGoalResponse",
        },
        __typename: "Mutation",
      };

      return toggleLunch({
        variables,
        optimisticResponse,
        onCompleted: onCompleteHandler(optimisticResponse),
        onError: onErrorHandler,
        update: onUpdateHandler,
      });
    },
    [
      lunchSettings.earliestStart,
      lunchSettings.id,
      lunchSettings.idealDuration,
      lunchSettings.latestEnd,
      lunchSettings.minimumDuration,
      lunchSettings.notificationStrategy,
      lunchSettings.scheduleAs,
      onCompleteHandler,
      onErrorHandler,
      onUpdateHandler,
      orgId,
      toggleLunch,
    ],
  );

  return { toggle, loading, error };
};

export default useLunchToggle;
