import { generateRescheduleCQL } from "@clockwise/web-commons/src/util/generateCQL";
import { ProcessMessage } from "../hooks/useProcessMessage";

type SearchPramKeys = "reschedule" | "vcb3lv" | "tu1qjm" | "1xfc2d" | "fvujps" | "civksh";
type SearchToProcessMessage = (search: URLSearchParams) => Parameters<ProcessMessage> | null;

const DeepLinkProcessMessages: Record<SearchPramKeys, SearchToProcessMessage> = {
  vcb3lv: () => [
    "Schedule an hour of heads-down time this week",
    { personMentions: [], eventMentions: [] },
    '(create_event (title "Heads-Down Time") (duration (delta (hour 1))) (when (date_merge (week))))',
  ],
  tu1qjm: () => [
    "Create a 15-minute Scheduling Link for a Quick Chat",
    { personMentions: [], eventMentions: [] },
    '(create_scheduling_link (title "Quick Chat") (duration (delta (minute 15))))',
  ],
  "1xfc2d": () => [
    "Create a 30-minute Scheduling Link for a Candidate Interview",
    { personMentions: [], eventMentions: [] },
    '(create_scheduling_link (title "Candidate Interview") (duration (delta (minute 30))))',
  ],
  fvujps: () => [
    "Add a 30-minute walk to my schedule this week",
    { personMentions: [], eventMentions: [] },
    '(create_event (title "30-Minute Walk") (duration (delta (minute 30))) (when (date_merge (week))))',
  ],
  civksh: () => [
    "Change all my 1:1s to bi-weekly",
    { personMentions: [], eventMentions: [] },
    "(update_events (find_events (find_event_type (one_on_one))) (event_mutation (recurrence (bi_weekly))))",
  ],
  reschedule: (search) => {
    const externalEventId = search.get("id");
    const startTime = search.get("start");
    let title = search.get("title");

    if (!externalEventId || !startTime || !title) {
      return null;
    }

    title = decodeURIComponent(title);

    return [
      `Reschedule ${title}`,
      { personMentions: [], eventMentions: [{ externalEventId, startTime, title }] },
      generateRescheduleCQL(externalEventId),
    ];
  },
};

export const findMessageToProcess = (
  search: URLSearchParams,
): Parameters<ProcessMessage> | null => {
  for (const key of Object.keys(DeepLinkProcessMessages) as SearchPramKeys[]) {
    if (search.has(key)) {
      return DeepLinkProcessMessages[key](search);
    }
  }

  return null;
};
