import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateEventCategoryTypeMutationVariables = Types.Exact<{
  externalEventId: Types.Scalars['ID'];
  category: Types.EventCategoryType;
}>;


export type UpdateEventCategoryTypeMutation = { __typename: 'Mutation', updateEventCategoryType: { __typename: 'Event', id: string, category: { __typename: 'EventCategory', type: Types.EventCategoryType } } | null };


export const UpdateEventCategoryTypeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateEventCategoryType"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"category"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EventCategoryType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateEventCategoryType"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"externalEventId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"category"},"value":{"kind":"Variable","name":{"kind":"Name","value":"category"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateEventCategoryTypeMutation, UpdateEventCategoryTypeMutationVariables>;