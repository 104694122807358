import { CalendarSpec, getRelativeFormat } from "@clockwise/web-commons/src/util/date.util";
import { DateTime } from "luxon";

const SHORTENED_DATE_CALENDAR_FORMAT_OPTIONS: CalendarSpec = {
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  yesterday: "'Yesterday'",
  next: "MMM d",
  this: "ccc",
  last: "MMM d",
  other: "MMM d",
};

export const getShortenedRelativeDate = (dateTime: DateTime) =>
  getRelativeFormat(dateTime, SHORTENED_DATE_CALENDAR_FORMAT_OPTIONS);

export const getShortenedRelativeDateAndTime = (dateTime: DateTime, timeZone: string) => {
  return `${getShortenedRelativeDate(dateTime)} ${dateTime
    .setZone(timeZone)
    .toFormat("h:mma")
    .toLowerCase()}`;
};
