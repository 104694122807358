import { ApolloError } from "@apollo/client";
import makeMutationUpdateRefetchBadCacheHandler from "../../../util/makeMutationUpdateRefetchBadCacheHandler";
import { LunchGoalsDocument } from "../graphql/__generated__/LunchGoals.generated";
import useLunchSettings from "./useLunchSettings";
import useLunchToggle from "./useLunchToggle";
import useLunchUpdate from "./useLunchUpdate";

type Options = {
  onSuccess?: () => void;
  onFail?: (error?: ApolloError) => void;
};

const useLunch = (options?: Options) => {
  const { settings, loading, error: loadError, orgId, refetch } = useLunchSettings();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const onUpdate = makeMutationUpdateRefetchBadCacheHandler("lunch", LunchGoalsDocument, refetch);

  const { update, loading: updating, error: updateError } = useLunchUpdate(
    String(orgId),
    settings,
    { onCompleted: options?.onSuccess, onError: options?.onFail, onUpdate },
  );

  const { toggle, loading: toggling, error: toggleError } = useLunchToggle(
    String(orgId),
    settings,
    { onCompleted: options?.onSuccess, onError: options?.onFail, onUpdate },
  );

  return {
    settings,
    loadError,
    loading,
    update,
    updating,
    updateError,
    toggle,
    toggling,
    toggleError,
  };
};

export default useLunch;
