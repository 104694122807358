import { Button } from "@clockwise/design-system";
import { RepeatingEventSaveOption } from "@clockwise/schema";
import { animated, useSpring } from "@react-spring/web";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { SaveDropdown } from "../atoms/SaveDropdown";
import { getDeleteEventCopy, getDeleteHoldCopy } from "../utils/getSubmitButtonCopy";

export const ECDeleteConfirm = ({
  onHide,
  onCancelEvent,
  canDelete = false,
  isHold = false,
  showDropdownOnSave = false,
}: {
  onHide: () => void;
  onCancelEvent: (options?: RepeatingEventSaveOption) => void;
  canDelete?: boolean;
  isHold?: boolean;
  showDropdownOnSave?: boolean;
}) => {
  const [animationState, setAnimationState] = useState<"in" | "out" | "none">("none");
  const deletionCopy = isHold ? getDeleteHoldCopy() : getDeleteEventCopy(canDelete);

  useEffect(() => {
    setAnimationState("in");
  }, []);

  const onNevermind = () => {
    setAnimationState("out");
    setTimeout(() => {
      // Allows for exit animation to play out
      onHide();
    }, 300);
  };

  const animationInConfig = {
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 300 },
  };

  const animationOutConfig = {
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: { duration: 300 },
  };

  const animationNoneConfig = {
    from: { opacity: 0 },
    to: { opacity: 0 },
  };

  let animationConfig = null;
  switch (animationState) {
    case "in":
      animationConfig = animationInConfig;
      break;
    case "out":
      animationConfig = animationOutConfig;
      break;
    default:
      animationConfig = animationNoneConfig;
      break;
  }

  const style = useSpring(animationConfig);

  return (
    <animated.div
      style={style}
      className={classNames(
        "cw-absolute cw-z-10 cw-px-3 cw-justify-between cw-flex cw-items-center cw-inset-0 cw-bg-destructive",
      )}
    >
      <>
        <div className="cw-body-sm cw-text-destructive">Are you sure?</div>
        <div className="cw-flex cw-gap-2">
          <Button onClick={onNevermind} variant="outlined" sentiment="neutral" size="xsmall">
            Never mind
          </Button>
          {showDropdownOnSave ? (
            <SaveDropdown
              disabled={false}
              onSelect={(option) => {
                onCancelEvent(option);
              }}
              copy={deletionCopy}
              disabledRecurrenceSaveOptions={[]}
            />
          ) : (
            <Button
              onClick={() => {
                onCancelEvent(RepeatingEventSaveOption.ThisInstanceOnly);
              }}
              variant="solid"
              sentiment="destructive"
              size="xsmall"
            >
              {deletionCopy.default}
            </Button>
          )}
        </div>
      </>
    </animated.div>
  );
};
