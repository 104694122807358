import { useQuery } from "@apollo/client";
import { GetOrgIdDocument } from "../components/team-settings/__generated__/GetOrgId.generated";
import { getCurrentOrg } from "../util/org.util";

export const useGetOrgId = () => {
  const { data, loading } = useQuery(GetOrgIdDocument);
  const org = getCurrentOrg(data?.viewer);

  return {
    org,
    loading,
  };
};
