import withStyles from "@material-ui/core/styles/withStyles";
import * as React from "react";
import { createFragmentContainer } from "react-relay";

// components
import { CheckoutFormRenderer } from "#webapp/src/components/checkout-form";

// util
import { page, PageEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { maybeGetPrimaryTeamFromOrg } from "#webapp/src/util/team.util";

// routing
import { withRouterQuery } from "#webapp/src/routing/routes.util";

// internal
import { webAppCheckoutFragments, webAppCheckoutQuery } from "./WebAppCheckout.gql";
import { styles } from "./WebAppCheckout.styles";
import { IProps, IState } from "./WebAppCheckoutTypes";

///////////////
// Component //
///////////////

class WebAppCheckoutComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-
  public componentDidMount() {
    page(PageEvents.CHECKOUT);
  }

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  public render() {
    const { classes, viewer, params, relay } = this.props;
    const searchParams = new URLSearchParams(window.location.search);

    const org = getCurrentOrg(viewer);
    const primaryTeam = org ? maybeGetPrimaryTeamFromOrg(org.primaryTeam) : undefined;

    if (!org) {
      logger.error("failed to load WebAppCheckout");
      return "";
    }

    const teamId = params.teamId
      ? atob(params.teamId)
      : primaryTeam
      ? primaryTeam.teamId
      : undefined;

    return (
      <div className={classes.wrappingContainer}>
        <CheckoutFormRenderer
          environment={relay.environment}
          orgRelayId={org.id}
          teamId={teamId}
          isDialog={false}
          startCheckoutWithTeamSelected={searchParams.has("teamSelect")}
        />
      </div>
    );
  }
}

/////////////////
// With Styles //
/////////////////

const WebAppCheckoutStyled = withStyles(styles)(WebAppCheckoutComponent);

///////////
// Relay //
///////////

export const WebAppCheckout = createFragmentContainer(
  WebAppCheckoutStyled,
  webAppCheckoutFragments,
);

export const WebAppCheckoutPage = withRouterQuery(WebAppCheckout, webAppCheckoutQuery);
