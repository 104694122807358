import { Loader } from "@clockwise/design-system/src/components/Loader";
import { ProposalStateEnum, PublicPerson } from "@clockwise/schema/v2";
import React from "react";
import { LoginBtn } from "../login-btn";
import { ProposalOwner } from "./ProposalOwner";
import { LoginInProgressViaType } from "./SharedProposalRendererGateway";

type PublicSharedProposalInactiveProps = {
  proposalId: string;
  owner: PublicPerson;
  eventTitle: string;
  state: ProposalStateEnum;
  hasFixableConflicts: boolean;
  loginInProgressVia: LoginInProgressViaType;
  onLoginStart: (button: string) => void;
  onLoginSuccess: () => void;
  onLoginFailure: () => void;
  onGoogleFailure: () => void;
};

const isCompletedState = (state: ProposalStateEnum) => {
  return state === ProposalStateEnum.Completed || state === ProposalStateEnum.SoftConfirm;
};

const descriptionComponent = (
  state: ProposalStateEnum,
  eventTitle: string,
  hasFixableConflicts: boolean,
) => {
  if (isCompletedState(state)) {
    return (
      <div className="cw-mb-2">
        <span className="cw-font-semibold">"{eventTitle}"</span> was scheduled
        {hasFixableConflicts ? ", and other meetings were modified to make this work." : "."}
      </div>
    );
  } else {
    return (
      <div className="cw-mb-2">
        <span className="cw-font-semibold">"{eventTitle}"</span> was not modified.
      </div>
    );
  }
};

const titleBody = (state: ProposalStateEnum, owner: PublicPerson) => {
  if (isCompletedState(state)) {
    return `${owner.profile.givenName}'s proposal was confirmed`;
  } else {
    return `${owner.profile.givenName}'s proposal was cancelled`;
  }
};

export const PublicSharedProposalInactive = ({
  proposalId,
  owner,
  eventTitle,
  state,
  hasFixableConflicts,
  loginInProgressVia,
  onLoginStart,
  onLoginSuccess,
  onLoginFailure,
  onGoogleFailure,
}: PublicSharedProposalInactiveProps) => {
  const description = descriptionComponent(state, eventTitle, hasFixableConflicts);

  return (
    <>
      <ProposalOwner owner={owner} titleBody={titleBody(state, owner)} />
      {description && description}
      <div className=" cw-flex cw-flex-col cw-w-full cw-pt-3 cw-border-t cw-border-solid cw-border-t-muted cw-font-semibold">
        {loginInProgressVia === "View in Clockwise" ? (
          <Loader size="md" sentiment="positive" className="cw-m-auto" />
        ) : (
          <LoginBtn
            label="View in Clockwise"
            variant={"default"}
            redirect={`app/proposal/${proposalId}`}
            onStart={() => onLoginStart("View in Clockwise")}
            onSuccess={onLoginSuccess}
            onFailure={onLoginFailure}
            onGoogleFailure={onGoogleFailure}
            textAlign="left"
            sentiment="positive"
          />
        )}
      </div>
    </>
  );
};
