import { Autocomplete } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

export interface IContainer {
  disabled?: boolean;
  label: string;
  onSelectAddress: (address: string) => void;
}

export interface IProps extends IContainer {
  initialQuery: string;
}

export interface IState {
  query: string;
  anchorEl: HTMLElement | null;
  googleLoaded: boolean;
  focused: boolean;
}

export class WorkingLocationAddressSearch extends React.Component<IProps, IState> {
  static defaultProps = {
    initialQuery: "",
  };

  private googleInterval?: ReturnType<typeof setInterval>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      query: props.initialQuery,
      anchorEl: null,
      googleLoaded: false,
      focused: false,
    };
  }

  public componentDidMount() {
    const googleLoaded = (window as any).google && (window as any).google.maps;
    if (googleLoaded) {
      this.setState({ googleLoaded });
    } else {
      this.googleInterval = setInterval(() => {
        const googleLoaded = (window as any).google && (window as any).google.maps;
        if (googleLoaded) {
          this.setState({ googleLoaded });
          this.googleInterval && clearInterval(this.googleInterval);
          delete this.googleInterval;
        }
      }, 5000);
    }
  }

  public componentWillUnmount() {
    if (this.googleInterval) {
      clearInterval(this.googleInterval);
    }
  }

  private onChange = (query: string) => {
    if (this.state.query === query) {
      return;
    }
    this.setState({ query });
    this.props.onSelectAddress(query);
  };

  public render() {
    if (!this.state.googleLoaded) {
      return <Loader size="sm" sentiment="positive" />;
    }

    return (
      <div className={`${this.state.focused ? " active" : ""}`}>
        <PlacesAutocomplete
          value={this.state.query}
          onSelect={() => null}
          onChange={() => null}
          searchOptions={{
            types: ["address"],
          }}
        >
          {({ suggestions, getInputProps }) => {
            return (
              <Autocomplete
                label="address"
                className="cw-pb-1.5"
                placeholder="Enter working location address"
                data={suggestions.map((suggestion) => suggestion.description)}
                onChange={(value) => {
                  this.onChange(value);
                  getInputProps().onChange({ target: { value } });
                }}
              />
            );
          }}
        </PlacesAutocomplete>
      </div>
    );
  }
}
