import { Button } from "@clockwise/design-system";
import React from "react";
import { useUpdateSuggestedPrompt } from "../../hooks/SuggestedPromptContext";

export const PromptButton = ({ text }: { text: string }) => {
  const updatePromptText = useUpdateSuggestedPrompt();

  const submitMessage = () => {
    updatePromptText(text);
  };

  return (
    <Button variant="outlined" onClick={submitMessage}>
      {text}
    </Button>
  );
};
