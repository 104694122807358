import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar";
import { ICalPositionable } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import classNames from "classnames";
import { DateTime, Interval } from "luxon";
import React, { useMemo, useRef } from "react";
import { useReadTimeSuggestionPeek } from "../../chat-plus-calendar/util/TimeSuggestionPeekContext";
import { useActiveProposal } from "../../chat/hooks/useActiveProposal";
import { TradeoffsPopover } from "../../tradeoffs-popover";
import { Tradeoff } from "../../tradeoffs-popover/types";

export const TimeSuggestionCard = ({
  interval,
  tradeoffs,
  popoverSide,
  totalAttendeeCount,
  widthPercent,
}: {
  interval: Interval;
  tradeoffs: Tradeoff[];
  popoverSide: "left" | "right";
  totalAttendeeCount: number;
  widthPercent: number;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div id="time-suggestion-card" className="cw-h-full cw-hidden lg:cw-flex cw-relative">
      <div
        className="cw-animate-slowPulse cw-h-full"
        ref={ref}
        style={{ width: `${widthPercent}%` }}
      >
        <div
          className={classNames(
            "cw-min-h-[10px] cw-h-full cw-w-full",
            "cw-border cw-border-solid cw-border-default",
            "cw-opacity-75 cw-caption cw-rounded",
            "cw-px-1 cw-bg-neutral-emphasis cw-shadow-timeSuggestionCard",
          )}
        >
          <div className="cw-text-onEmphasis cw-font-semibold cw-pl-1 [text-shadow:_1px_1px_1px_rgba(0,0,0,0.9)]">
            {interval.start.toFormat("h:mm") + interval.start.toFormat("a").toLowerCase()}
          </div>
        </div>
      </div>

      <TradeoffsPopover
        tradeoffs={tradeoffs}
        side={popoverSide}
        totalAttendeeCount={totalAttendeeCount}
        anchorEl={ref}
      />
    </div>
  );
};

export const TimeSuggestion = ({
  dateTimes,
  widthPercent,
  fullWidth = false,
  columnCountOverride,
  minWidthPercent,
  gutters = true,
}: {
  dateTimes: DateTime[];
  widthPercent?: number;
  fullWidth?: boolean;
  columnCountOverride?: number;
  minWidthPercent?: number;
  gutters?: boolean;
}) => {
  const { proposal } = useActiveProposal();
  const { interval, tradeoffs } = useReadTimeSuggestionPeek();
  const timeZone = getRenderTimeZone();
  // there will only be one diff block and one diff when there are scheduling suggestions
  const proposalAttendees =
    proposal?.diffBlocks[0]?.diffs[0]?.attendees?.proposalAttendees.length ?? 0;

  const positionables: ICalPositionable[] = useMemo(() => {
    if (interval) {
      const zonedStartDateTime = DateTime.fromISO(interval.split("/")[0]).setZone(timeZone);
      const zonedEndDateTime = DateTime.fromISO(interval.split("/")[1]).setZone(timeZone);
      const intervalAsLuxonInterval = Interval.fromDateTimes(zonedStartDateTime, zonedEndDateTime);
      return [
        {
          key: interval,
          interval: intervalAsLuxonInterval,
          render: ({ position }) => (
            <TimeSuggestionCard
              interval={intervalAsLuxonInterval}
              tradeoffs={tradeoffs}
              popoverSide={position.left < 60 ? "right" : "left"} // Open on the left for Thursday and Friday, because of space constraints
              totalAttendeeCount={proposalAttendees}
              widthPercent={widthPercent ?? 100}
            />
          ),
        },
      ];
    }
    return [];
  }, [tradeoffs, interval, timeZone, widthPercent]);

  return (
    <CalendarPositioner
      dateTimes={dateTimes}
      gutters={gutters}
      positionables={positionables}
      fullWidth={fullWidth}
      columnCountOverride={columnCountOverride}
      minWidth={minWidthPercent}
    />
  );
};
