import { GraphEntityError } from "@clockwise/schema";
import { compact } from "lodash";
import { createContext } from "react";

// ~-~-~-~-~-~-~-
// Context
// ~-~-~-~-~-~-~-
export const WebSettingsContext = createContext({
  onChangeSettingsView: (_settingsViewId: string) => {
    console.warn(
      "Your current context does not include the WebSettingsContext.  Please ensure your component is wrapped with an appropriate <WebSettingsContext.Provider>",
    );
  },
  webSettingsRefetch: () => {
    console.warn(
      "Your current context does not include the WebSettingsContext.  Please ensure your component is wrapped with an appropriate <WebSettingsContext.Provider>",
    );
  },
});

// ~-~-~-~-~-~-~-
// View
// ~-~-~-~-~-~-~-
export enum WebSettingsSections {
  Account = "Account",
  Teams = "Teams",
  Organization = "Organization",
  Internal = "Internal",
}

export enum AccountSections {
  HoursAndPreferences = "HoursAndPreferences",
  SmartHolds = "SmartHolds",
  Integrations = "Integrations",
  EventColorCoding = "EventColorCoding",
  TeamAvailabilityCalendar = "TeamAvailabilityCalendar",
  TeamNoMeetingDay = "TeamNoMeetingDay",
  EmailsAndNotifications = "EmailsAndNotifications",
}

export enum HoursAndPreferencesSections {
  WorkingHours = "WorkingHours",
  MeetingHours = "MeetingHours",
  TimeZone = "TimeZone",
  MeetingBreak = "MeetingBreak",
  TimeOfDayPreference = "TimeOfDayPreference",
  PreferredMeetingRooms = "PreferredMeetingRooms",
}

export enum SmartHoldsSections {
  FocusTime = "FocusTime",
  LunchHold = "LunchHold",
  TravelTime = "TravelTime",
  PersonalCalendarHold = "PersonalCalendarHold",
}

export enum IntegrationsSections {
  Slack = "Slack",
  Zoom = "Zoom",
}

export enum TeamsSections {
  Team = "Team",
  CreateOrJoinTeam = "CreateOrJoinTeam",
}

export enum TeamSections {
  TeamName = "TeamName",
  PlanDetails = "PlanDetails",
  Members = "Members",
  TeamAvailabilityCalendar = "TeamAvailabilityCalendar",
  Slack = "Slack",
  TeamNoMeetingDay = "TeamNoMeetingDay",
  Delete = "Delete",
  TeamMakeFlexibleMeetings = "TeamMakeFlexibleMeetings",
}

export enum OrganizationSections {
  Analytics = "Analytics",
  Members = "Members",
  Teams = "Teams",
  PlansAndBilling = "PlansAndBilling",
  OrganizationSettings = "OrganizationSettings",
}

export enum InternalSections {
  Experiments = "Experiments",
}

// ~-~-~-~-~-~-~-
// Routes
// ~-~-~-~-~-~-~-
const pathToEnum: Record<
  string,
  | WebSettingsSections
  | AccountSections
  | HoursAndPreferencesSections
  | SmartHoldsSections
  | IntegrationsSections
  | TeamsSections
  | TeamSections
  | OrganizationSections
  | InternalSections
> = {
  account: WebSettingsSections.Account,
  teams: WebSettingsSections.Teams, // Same as OrganizationSections.Teams.
  organization: WebSettingsSections.Organization,
  internal: WebSettingsSections.Internal,
  "hours-and-preferences": AccountSections.HoursAndPreferences,
  "smart-holds": AccountSections.SmartHolds,
  integrations: AccountSections.Integrations,
  "event-color-coding": AccountSections.EventColorCoding,
  "team-availability-calendar": AccountSections.TeamAvailabilityCalendar, // Same as TeamSections.TeamAvailabilityCalendar.
  "team-no-meeting-day": AccountSections.TeamNoMeetingDay, // Same as TeamSections.TeamNoMeetingDay.
  "emails-and-notifications": AccountSections.EmailsAndNotifications,
  // teams sections
  team: TeamsSections.Team,
  "create-or-join-team": TeamsSections.CreateOrJoinTeam,
  // team sections
  "team-name": TeamSections.TeamName,
  "plan-details": TeamSections.PlanDetails,
  members: TeamSections.Members, // Same as OrganizationSections.Members.
  slack: TeamSections.Slack, // Same as IntegrationsSections.Slack.
  delete: TeamSections.Delete,
  // organization sections
  analytics: OrganizationSections.Analytics,
  "plans-and-billing": OrganizationSections.PlansAndBilling,
  "organization-settings": OrganizationSections.OrganizationSettings,
  // internal sections
  experiments: InternalSections.Experiments,
  // hours and preferences sections
  "working-hours": HoursAndPreferencesSections.WorkingHours,
  "meeting-hours": HoursAndPreferencesSections.MeetingHours,
  "time-zone": HoursAndPreferencesSections.TimeZone,
  "meeting-break": HoursAndPreferencesSections.MeetingBreak,
  "time-of-day-preference": HoursAndPreferencesSections.TimeOfDayPreference,
  "preferred-meeting-rooms": HoursAndPreferencesSections.PreferredMeetingRooms,
  // smart holds sections
  "focus-time": SmartHoldsSections.FocusTime,
  "lunch-hold": SmartHoldsSections.LunchHold,
  "travel-time": SmartHoldsSections.TravelTime,
  "personal-calendar-hold": SmartHoldsSections.PersonalCalendarHold,
  // integrations sections
  zoom: IntegrationsSections.Zoom,
};

const enumToPath = (enumValue: string) => {
  const path = Object.keys(pathToEnum).find((key) => pathToEnum[key] === enumValue);
  return path;
};

export const getPathFromViewId = (viewId: string) => {
  const sections = viewId.split("-");
  return compact(sections.map((section) => enumToPath(section) || section)).join("/");
};

export const getSettingsViewIdForRoute = (windowLocation: Location) => {
  const paths = windowLocation.pathname.split("/web-settings")[1]?.split("/")?.filter(Boolean);
  return (paths || []).map((path) => pathToEnum?.[path] || path).join("-");
};

export const getRouteFromViewId = (viewId: string) => {
  const paths = viewId.split("-").filter(Boolean);
  return paths.join("-");
};

// ~-~-~-~-~-~-~-
// Settings View Id
// ~-~-~-~-~-~-~-
export const getExpandableTreeItemFromViewId = (settingsViewId: string) => {
  return settingsViewId.split("-").slice(0, 2).join("-");
};

export const maybeGetTeamIdFromViewId = (settingsViewId: string) => {
  const routeParts = settingsViewId.split("-");
  if (routeParts[1] !== TeamsSections.Team) {
    return null;
  }
  // Example: /teams/team/<teamId>
  return routeParts[2];
};

export const createSettingsViewId = (
  section: string,
  subSection?: string,
  subSubSection?: string,
  subSubSubSection?: string,
) => {
  return [section, subSection, subSubSection, subSubSubSection].filter(Boolean).join("-");
};

// ~-~-~-~-~-~-~-
// Scroll helpers
// ~-~-~-~-~-~-~-
const getScrollKeyFromViewId = (settingsViewId: string) => {
  return settingsViewId.split("-").slice(-1).join("-");
};

const getWebSettingsElement = (key: string) => {
  return document.querySelector(`[cw-id="web-settings-${key}"]`);
};

const getWebSettingsElementScrollOffset = (key: string) => {
  const el = getWebSettingsElement(key);
  return el ? (el as HTMLElement).offsetTop - 100 : 0;
};

const setWebSettingsContainerScrollTop = (scrollTop: number) => {
  const el = document.querySelector('[cw-id="web-settings-container"]') as Element;
  el.scrollTop = scrollTop;
};

export const scroll = (settingsViewId: string) => {
  const scrollTop = getWebSettingsElementScrollOffset(getScrollKeyFromViewId(settingsViewId));
  setWebSettingsContainerScrollTop(scrollTop);
};

// ~-~-~-~-~-~-~-
// Web Settings Team Helpers
// ~-~-~-~-~-~-~-
export interface TeamWithIdAndName {
  teamId: string;
  teamName: string;
  __typename: string;
}

export interface OrgWithPrimaryTeamAndUserTeams {
  primaryTeam: TeamWithIdAndName | GraphEntityError | null;
  userTeams?:
    | {
        list: Array<TeamWithIdAndName>;
        __typename: string;
      }
    | GraphEntityError;
}
