import { Interval } from "luxon";
import { Maybe } from "purify-ts";

type SummaryWithInterval =
  | { __typename: "AddDiffSummary"; time?: any }
  | { __typename: "ModifyDiffSummary"; updatedTime?: any; currentTime: any };

export const getIntervalFromDiffSummary = (diffSummary: SummaryWithInterval): Maybe<Interval> => {
  if (diffSummary.__typename === "AddDiffSummary") {
    return Maybe.fromNullable(diffSummary.time).map((isoInterval: string) =>
      Interval.fromISO(isoInterval),
    );
  }

  if (diffSummary.__typename === "ModifyDiffSummary") {
    return Maybe.fromNullable(diffSummary.updatedTime)
      .alt(Maybe.fromNullable(diffSummary.currentTime))
      .map((isoInterval: string) => Interval.fromISO(isoInterval));
  }

  return Maybe.empty();
};
