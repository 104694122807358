import classNames from "classnames";

export const pulsingBlackOverlay = classNames(
  "cw-relative",
  "cw-overflow-hidden",
  "before:cw-content-['']",
  "before:cw-bg-[#000000]",
  "before:cw-absolute",
  "before:cw-left-0",
  "before:cw-top-0",
  "before:cw-w-full",
  "before:cw-h-full",
  "before:cw-animate-pulse-opacity-low-slow",
  "before:cw-mix-blend-hard-light",
  "before:cw-pointer-events-none",
  "motion-reduce:before:cw-animate-none",
  "motion-reduce:before:cw-opacity-25",
);
