import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SudoUpdateBillingGroupNameMutationVariables = Types.Exact<{
  input: Types.SudoUpdateBillingGroupNameMutationInput;
}>;


export type SudoUpdateBillingGroupNameMutation = { __typename: 'Mutation', sudoUpdateBillingGroupName: { __typename: 'SudoUpdateBillingGroupNameMutationPayload', clientMutationId: string | null } | null };


export const SudoUpdateBillingGroupNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SudoUpdateBillingGroupName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SudoUpdateBillingGroupNameMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sudoUpdateBillingGroupName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}}]}}]}}]} as unknown as DocumentNode<SudoUpdateBillingGroupNameMutation, SudoUpdateBillingGroupNameMutationVariables>;