import { TravelTimeGoalsQuery } from "../graphql/__generated__/TravelTimeGoals.generated";
import { getCurrentOrg } from "../../../util/org.util";

export const parseTravelTimeGoalsQuery = (data: TravelTimeGoalsQuery | undefined) => {
  const org = getCurrentOrg(data?.viewer);
  const TravelTimeGoal = org?.smartHoldGoals.travelTime;
  return TravelTimeGoal;
};

export default parseTravelTimeGoalsQuery;
