import { useCalendarDimensions } from "#webapp/src/hooks/useCalendarDimensions";
import { useScrollTopOfScrollContainerBeforeDragging } from "#webapp/src/hooks/useScrollTopOfScrollContainerBeforeDragging";
import { useUserConfrencingTypes } from "#webapp/src/hooks/useUserConfrencingTypes";
import { FlexRange } from "@clockwise/schema/v2";
import { CalendarDensity, CalendarTimeDial } from "@clockwise/web-commons/src/components/calendar";
import { CalendarDimensionsContext } from "@clockwise/web-commons/src/components/calendar/calendar-event/CalendarDimensionsContext";
import {
  EventType,
  useReadActiveEvent,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { useReadCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { useDraggable } from "@dnd-kit/core";
import { animated, useSpring } from "@react-spring/web";
import classNames from "classnames";
import { isEmpty, isEqual } from "lodash";
import { DateTime, Interval } from "luxon";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useReadLocalStorage } from "usehooks-ts";
import { useFeatureFlag } from "../../../launch-darkly";
import {
  useCurrentProposal,
  useUpdateCurrentProposal,
} from "../../chat-plus-calendar/CurrentProposalContext";
import { useUpdateActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import { useAIMessageContext } from "../../chat/ai-chat/hooks/AIMessageContext";
import { RescheduleConfirmationWrapper } from "../../reschedule-confirmation-modal";
import { CalendarWeekDNDContext } from "../../root/DndContext";
import { usePlannerContext } from "../Context";
import { CalendarScrollSync } from "../calendar-week/CalendarScrollSync";
import { useAvailablities } from "../hooks/useAvailablities";
import DateTimeMarker from "../planner-datetime-marker/DateTimeMarker";
import { TimeZoneLink } from "../planner-head/PlannerHead";
import { ProposalOptionsOverlayToggleV2 } from "../proposal-options-overlay-v2/ProposalOptionsOverlayToggle";
import { useProposalOptionsOverlayToggleV2 } from "../proposal-options-overlay-v2/useProposalOptionsOverlayToggle";
import { ProposalOptionsOverlayToggle } from "../proposal-options-overlay/ProposalOptionsOverlayToggle";
import { useProposalOptionsOverlayToggle } from "../proposal-options-overlay/useProposalOptionsOverlayToggle";
import {
  ProposalSearchRangeOverlay,
  ProposalSearchRangeUnderlay,
} from "../search-range/ProposalSearchRangeLayers";
import { SingleDayHead } from "../single-day-head/SingleDayHead";
import { getDateTimeFromPosition } from "../utils/getDateTimeFromPosition";
import {
  useReadHorizontalScrollSync,
  useUpdateHorizontalScrollSync,
} from "./HorizonalScrollSyncContext";
import { ProposalOptionsOverlayWrapper } from "./ProposalOptionsOverlayWrapper";
import { ProposalOptionsSidebar } from "./ProposalOptionsSidebar";
import { ProposalOverlay } from "./ProposalOverlay";
import { SingleDayEventsColumn } from "./SingleDayEventsColumn";
import { TimeSuggestionOverlay } from "./TimeSuggestionOverlay";
import { sortCalendarIdsByProposalStatus } from "./utils/sortCalendarIdsByProposalStatus";
import { useActiveProposalTradeoffDetails } from "./utils/useActiveProposalTradeoffDetails";

type Next = (_: Record<string, string | number | boolean>) => Promise<unknown>;

const MIN_COLUMN_WIDTH_PERCENTAGE = 18;
const MAX_FULL_COLUMNS = Math.floor(100 / MIN_COLUMN_WIDTH_PERCENTAGE);
const DEFAULT_PROPOSAL_DURATION = 30;

export const CalendarSingleDay = () => {
  const track = useTracking();
  const {
    eventsByDayAndCalendar,
    primaryCalendarId,
    multiCalendarIds,
    popoverClosing,
    setIsDraggingEvent,
  } = usePlannerContext();
  const { proposalCalendarIds } = useActiveProposalTradeoffDetails();
  const { preferredConferencingType } = useUserConfrencingTypes();
  const { isDragging: eventIsDragging, data: eventDragData } = useContext(CalendarWeekDNDContext);
  const [clickToCreateEnabled] = useFeatureFlag("ClickToCreate");
  const { currentProposal } = useCurrentProposal();
  const { initOrRescheduleProposal, updateTime, updateProposalMeta } = useUpdateCurrentProposal();
  const activeEvent = useReadActiveEvent();
  const { containsActiveProposal: chatContainsActiveProposal } = useAIMessageContext();

  const sidebarContainsActiveEvent = activeEvent && activeEvent.type !== EventType.Proposal;
  const isViewingProposalOrEvent =
    sidebarContainsActiveEvent || chatContainsActiveProposal || popoverClosing;

  const canModifyDraggingEvent = eventDragData?.current?.canModify;

  const calendarState = useReadCalendar();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateActiveEventDiff = useUpdateActiveEventDiff();

  const [dateToRender, setDateToRender] = useState<string>(calendarState.visibleDates[0]);

  const {
    listeners: clickDragListeners,
    setNodeRef: setNodeRefClickDrag,
    transform: clickDragTransform,
    isDragging: isClickDraggingToCreate,
  } = useDraggable({
    id: `click-drag-to-create`,
    disabled: !clickToCreateEnabled,
    data: {
      isClickDragToCreate: true,
    },
  });

  const { isOverlayVisible: proposalOptionsOverlayVisible } = useProposalOptionsOverlayToggle();
  const {
    isToggleOverlayVisible: proposalOptionsOverlayVisibleV2,
    isSearchRangeOverlayVisible,
  } = useProposalOptionsOverlayToggleV2();

  const zone = getRenderTimeZone();
  const dateTimeToRender = useMemo(() => DateTime.fromISO(dateToRender, { zone }), [
    dateToRender,
    zone,
  ]);

  const multiCalLength = multiCalendarIds?.length ?? undefined;
  useEffect(() => {
    track(TrackingEvents.CALENDAR_SINGLE_DAY.VIEWED);
  }, []);

  useEffect(() => {
    if (multiCalLength && multiCalLength > 0) {
      track(TrackingEvents.CALENDAR_SINGLE_DAY.SPLIT_VIEW, {
        additionalCalendars: multiCalLength,
      });
    }
  }, [multiCalLength]);

  const {
    intervalsByCalendarId: availabilities,
    timeZoneByCalendarId: timeZones,
  } = useAvailablities({
    dateTimes: [dateTimeToRender],
    minCalendarCount: 2,
  });

  const [animateCalStyles, animateCal] = useSpring(() => ({}));

  const animateCalendar = useCallback(
    (goForward: boolean, goToNewDate: () => void) => {
      const distance = 16, // React spring seems to do best with an even number here.
        duration = 135;
      void animateCal.start(() => ({
        to: async (next: Next) => {
          await next({ translateX: -distance * (goForward ? 1 : -1), opacity: 0.25 });
          await next({
            translateX: distance * 1.8 * (goForward ? 1 : -1),
            opacity: 0,
            immediate: true,
          });
          await next({ immediate: true, opacity: 0.5 });
          goToNewDate();
          await next({ translateX: 0, opacity: 1 });
        },
        config: { duration },
      }));
    },
    [animateCal],
  );

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    scrollTopOfScrollContainerBeforeDragging,
    debouncedSetScrollTopOfScrollContainerBeforeDragging,
  } = useScrollTopOfScrollContainerBeforeDragging({
    scrollContainerRef,
  });

  const calendarContainerRef = useRef<HTMLDivElement | null>(null);
  const { calendarRef: calendarHorizonalScrollRef } = useReadHorizontalScrollSync();
  const { handleScrollCalendar: handleHorizontalScrollCalendar } = useUpdateHorizontalScrollSync();

  useEffect(() => {
    // if the render dates are the same, don't animate
    if (!isEqual(dateToRender, calendarState.visibleDates[0])) {
      // determine if we should animate forward or backward
      const newFirstRenderDateTime = DateTime.fromISO(calendarState.visibleDates[0]);
      const stepForward = newFirstRenderDateTime > dateTimeToRender;

      // animate the calendar, and update local state
      animateCalendar(stepForward, () => {
        setDateToRender(calendarState.visibleDates[0]);
      });
    }
  }, [calendarState.visibleDates]);

  const calendarDensity = useReadLocalStorage<CalendarDensity>("calendarDensity");

  const eventsByCalendarForDay = useMemo(() => {
    return eventsByDayAndCalendar ? eventsByDayAndCalendar[dateToRender] : null;
  }, [dateToRender, eventsByDayAndCalendar]);

  const allDayEventsByCalendar = eventsByCalendarForDay?.allDayEvents ?? {};

  const calendarIds = useMemo(() => {
    return primaryCalendarId ? [primaryCalendarId].concat(multiCalendarIds ?? []) : [];
  }, [primaryCalendarId, multiCalendarIds]);

  const handleDisplayProposalCard = () => {
    updateActiveEventDiff(null);
    updateActiveEvent({ calendarId: "", externalEventId: "", type: EventType.Proposal });
  };

  const showWorkingHoursHash = !isEmpty(availabilities);

  const { calendarWeekWidthInPx, dayWidthInPx, numOfDaysShown, calHeight } = useCalendarDimensions({
    numColumns: calendarIds.length,
    ref: calendarHorizonalScrollRef,
    calendarDensity,
  });

  // sort by 'you' first, then if there are any active proposals, then every other user
  const sortedCalendarIds = sortCalendarIdsByProposalStatus(
    calendarIds ?? [],
    proposalCalendarIds,
    primaryCalendarId ?? "",
  );

  const singleCalendar = sortedCalendarIds.length === 1;
  const timeZone = dateTimeToRender.toFormat("ZZZZ");

  const defaultTitle = useMemo(() => {
    return calendarIds.length > 1 ? "Sync" : "Hold";
  }, [calendarIds]);

  const defaultConferencingType = useMemo(() => {
    return calendarIds.length > 1 ? preferredConferencingType : null;
  }, [preferredConferencingType, calendarIds]);

  const getPxPer15MinuteBlock = () => {
    const height = calendarContainerRef.current?.offsetHeight ?? 0;
    const pxPerBlock = height / 96; // 15 minute blocks
    return pxPerBlock;
  };

  const isClickDraggingToCreateRef = useRef(false);

  const clickDragInitTimeRef = useRef<DateTime | null>(null);

  // Initialize a current proposal
  // Update its time as we click drag
  useEffect(() => {
    if (!isClickDraggingToCreate || !clickDragInitTimeRef.current) return;

    if (isViewingProposalOrEvent) return;

    const pxPerBlock = getPxPer15MinuteBlock();
    const initialClickTime = clickDragInitTimeRef.current;

    // On the first pass, the clickDragTransform?.y is 0
    const numBlocks = (clickDragTransform?.y ?? 0) / pxPerBlock;
    // We start out a min duration of 15 minutes, thus assuming we have at least 1 block
    // Depending on the direction of the drag, we set the default number of blocks to be positive (down) or negative (up)
    const defaultNumOfBlocks = Math.sign(numBlocks) === -1 ? -1 : 1;
    const deltaMinutes = (Math.ceil(numBlocks) || defaultNumOfBlocks) * 15;
    const atDragTime = initialClickTime.plus({ minutes: deltaMinutes });

    const startTime = deltaMinutes < 0 ? atDragTime : initialClickTime;
    const endTime = deltaMinutes < 0 ? initialClickTime : atDragTime;

    if (!isClickDraggingToCreateRef.current && !currentProposal.initialized) {
      isClickDraggingToCreateRef.current = true;
      setIsDraggingEvent?.(true);
      initOrRescheduleProposal({
        proposalId: null,
        title: defaultTitle,
        eventCalendarId: primaryCalendarId,
        startTime,
        endTime,
        timeZone: getRenderTimeZone(),
        initialAttendeeIds: calendarIds,
        attendees: [],
        allDay: false,
        description: "",
        recurrenceRule: null,
        location: null,
        conferenceType: defaultConferencingType,
        flexDetails: {
          isFlexible: calendarIds.length > 1,
          flexRange: FlexRange.Day,
        },
        meta: {
          isDragging: true,
          isFindATime: false,
        },
        transparency: "opaque",
        visibility: "default",
      });
    } else {
      updateTime(startTime, endTime);
      updateProposalMeta({ isDragging: true });
    }
  }, [clickDragTransform?.y, isClickDraggingToCreate]);

  // Display a proposal card on drag end
  useEffect(() => {
    const isOnDragEnd = isClickDraggingToCreateRef.current && !isClickDraggingToCreate;
    if (!isOnDragEnd) return;
    if (isViewingProposalOrEvent || !currentProposal.initialized) return;

    isClickDraggingToCreateRef.current = isClickDraggingToCreate;
    handleDisplayProposalCard();
    track(TrackingEvents.DIRECT_MANIPULATION.CALENDAR.CLICK_DRAG_TO_CREATE);
    updateProposalMeta({ isDragging: false });
    setIsDraggingEvent?.(false);
  }, [isClickDraggingToCreate]);

  const createProposal = (clickedTime: DateTime) => {
    // We don't want to create a new event if:
    // - there is an active event, e.g. the sidebar is open with an existing event
    // - there is an active proposal in the chat
    // - there is an event popover open
    if (isViewingProposalOrEvent) {
      return;
    }

    // We don't want to create a new event on click
    // if we are click dragging to create
    if (isClickDraggingToCreateRef.current) {
      return;
    }

    const chosenTimeRange = Interval.fromDateTimes(
      clickedTime,
      clickedTime.plus({ minutes: DEFAULT_PROPOSAL_DURATION }),
    );

    handleDisplayProposalCard();
    track(TrackingEvents.DIRECT_MANIPULATION.CALENDAR.CLICK_TO_CREATE);

    initOrRescheduleProposal({
      proposalId: null,
      title: defaultTitle,
      eventCalendarId: primaryCalendarId,
      startTime: chosenTimeRange.start,
      endTime: chosenTimeRange.end,
      timeZone: getRenderTimeZone(),
      initialAttendeeIds: calendarIds,
      attendees: [],
      allDay: false,
      description: "",
      recurrenceRule: null,
      location: null,
      conferenceType: defaultConferencingType,
      flexDetails: {
        isFlexible: calendarIds.length > 1,
        flexRange: FlexRange.Day,
      },
      meta: {
        isDragging: false,
        isFindATime: false,
      },
      transparency: "opaque",
      visibility: "default",
    });
  };

  const initializeClickDragToCreate = (clickedTime: DateTime) => {
    clickDragInitTimeRef.current = clickedTime;
    isClickDraggingToCreateRef.current = false;
  };

  const handleClickCalendarColumn = (event: React.MouseEvent<HTMLDivElement>) => {
    // We only want to act if the user clicks on the calendar itself, not on any of the children.
    if (event.target !== event.currentTarget) return;

    const clickedTime = getDateTimeFromPosition({
      yPosition: event.clientY,
      xPosition: event.clientX,
      calendarContainerRef: calendarContainerRef,
      dateTimesToRender: [dateTimeToRender],
    });
    createProposal(clickedTime);
  };

  const handleMouseDownCalendarColumn = (event: React.MouseEvent<HTMLDivElement>) => {
    // We only want to act if the user clicks on the calendar itself, not on any of the children.
    if (event.target !== event.currentTarget) return;

    const clickedTime = getDateTimeFromPosition({
      yPosition: event.clientY,
      xPosition: event.clientX,
      calendarContainerRef: calendarContainerRef,
      dateTimesToRender: [dateTimeToRender],
    });
    initializeClickDragToCreate(clickedTime);
  };

  const handleClickProposalOptionsOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) return;
    const clickedTime = getDateTimeFromPosition({
      yPosition: event.clientY,
      xPosition: event.clientX,
      calendarContainerRef: calendarContainerRef,
      dateTimesToRender: [dateTimeToRender],
    });
    updateProposalTime(clickedTime);
  };

  const updateProposalTime = (startTime: DateTime) => {
    const existingDurationMinutes = currentProposal.endTime
      .diff(currentProposal.startTime)
      .as("minutes");
    const endTime = startTime.plus({ minutes: existingDurationMinutes });
    updateTime(startTime, endTime);
  };

  return (
    <animated.div
      style={animateCalStyles}
      className="cw-flex cw-flex-1 cw-flex-col cw-mt-6 cw-w-[calc(100%-12px)] cw-h-full"
    >
      <div className="cw-relative cw-flex-1 cw-w-full cw-h-full cw-flex cw-flex-col cw-my-0 cw-mx-3 cw-pb-3">
        <div className="cw-border-b cw-border-subtle cw-border-solid cw-w-full">
          <SingleDayHead
            dateTime={dateTimeToRender}
            calendarIds={sortedCalendarIds}
            timeZones={timeZones}
            minWidthPercent={MIN_COLUMN_WIDTH_PERCENTAGE}
            allDayEventsByCalendar={allDayEventsByCalendar}
            showingProposalOptions={proposalOptionsOverlayVisibleV2}
          />
        </div>
        {!singleCalendar && (
          <div className="cw-relative cw-z-[60]">
            <div className="cw-top-[2px] cw-left-[8px] cw-absolute cw-body-base cw-text-[10px] cw-bg-default cw-p-1">
              <TimeZoneLink>{timeZone}</TimeZoneLink>
            </div>
          </div>
        )}
        <ProposalOptionsOverlayToggle overlayVisible={proposalOptionsOverlayVisible} />
        <ProposalOptionsOverlayToggleV2 overlayVisible={proposalOptionsOverlayVisibleV2} />
        <div
          className={classNames("cw-mb-[66px] cw-flex-grow", {
            "cw-overflow-auto": !eventIsDragging || canModifyDraggingEvent,
            // `cw-overflow-hidden` prevents vertical scrolling while
            // dragging the cursor to the end of screen
            // when user cannot modify the dragged event
            "cw-overflow-hidden": eventIsDragging && !canModifyDraggingEvent,
          })}
          ref={scrollContainerRef}
          onScroll={() => {
            if (eventIsDragging) return;

            // UX Note: if this isn't debounced it'll slow down scrolling!
            // And make the calendar scroll feel heavy!
            debouncedSetScrollTopOfScrollContainerBeforeDragging(
              scrollContainerRef?.current?.scrollTop || 0,
            );
          }}
          onWheel={(e) => {
            // Dev note: this handles a DnDKit UX bug
            // where the mouse scroll wheel doesn't work during a drag (before a drop)
            // We will manually scroll (whenever the mouse whell scrolls)

            if (!eventIsDragging || !scrollContainerRef?.current) return;

            scrollContainerRef.current.scrollTo({
              top: scrollContainerRef.current.scrollTop - e.deltaY,
              behavior: "smooth",
            });
          }}
        >
          <div
            style={{ minHeight: calHeight }}
            className="cw-relative cw-flex cw-flex-row cw-flex-1 cw-h-full cw-w-full"
            ref={(el) => {
              // Used by us to compute the start and end times of the proposal
              calendarContainerRef.current = el;
              // Used by DND kit for collision detection
              setNodeRefClickDrag(el);
            }}
            {...clickDragListeners}
          >
            {/* Non-rendering component that adjusts the scrollTop based on:
                1. On first mount, any previous scroll events initiated by the user
                2. The current time on routing to /app/ai-scheduler (via extension)
                3. The earliest start time in a conversation (proposal or viewEvents)
             */}
            <CalendarScrollSync
              scrollContainerRef={scrollContainerRef}
              calendarContainerRef={calendarContainerRef}
              scrollBehavior="instant"
            />
            <div className="cw-flex cw-sticky">
              <CalendarTimeDial />
              <ProposalOptionsSidebar
                isVisible={proposalOptionsOverlayVisibleV2}
                dateTime={dateTimeToRender}
                onClick={handleClickProposalOptionsOverlay}
              />
            </div>

            <DateTimeMarker daysRendered={[dateTimeToRender]} leftOverride={"55px"} />

            <CalendarDimensionsContext.Provider
              value={{
                calendarWeekWidthInPx,
                numOfDaysShown,
                calendarWeekHeightInPx: calHeight + 1, // This is based `cw-h-[calc(100%+1px)]` on `calendarWeekRef`
                dayWidthInPx,
                scrollContainerRef,
                scrollTopOfScrollContainerBeforeDragging,
              }}
            >
              <div
                className={classNames(
                  "cw-flex cw-flex-row cw-justify-center cw-flex-1 cw-flex-grow cw-relative cw-h-[calc(100%+1px)] cw-w-full",
                  { "cw-bg-subtle-striped": showWorkingHoursHash },
                  "cw-ml-5 cw-overflow-y-hidden cw-overflow-x-scroll",
                  {
                    "cw-overflow-auto": !eventIsDragging || canModifyDraggingEvent,
                    "cw-overflow-hidden": eventIsDragging && !canModifyDraggingEvent,
                  },
                )}
                ref={calendarHorizonalScrollRef}
                onScroll={(event) => {
                  handleHorizontalScrollCalendar(event);
                }}
              >
                {isSearchRangeOverlayVisible && (
                  <ProposalSearchRangeUnderlay dateTimes={[dateTimeToRender]} />
                )}
                {sortedCalendarIds.map((calendarId, index) => {
                  return (
                    <div
                      key={`${calendarId}-event-column`}
                      onClick={handleClickCalendarColumn}
                      onMouseDown={handleMouseDownCalendarColumn}
                      // Ensures that the clickable area of the column is the same width as the column
                      className="cw-w-full"
                    >
                      <SingleDayEventsColumn
                        calendarId={calendarId}
                        dateTime={dateTimeToRender}
                        columnIndex={index}
                        columnCount={calendarIds.length}
                        availability={availabilities[calendarId] ?? null}
                        minWidthPercent={MIN_COLUMN_WIDTH_PERCENTAGE}
                      />
                    </div>
                  );
                })}
                {isSearchRangeOverlayVisible && (
                  <ProposalSearchRangeOverlay dateTimes={[dateTimeToRender]} />
                )}

                {!proposalOptionsOverlayVisible && (
                  <TimeSuggestionOverlay
                    dateTime={dateTimeToRender}
                    proposalCalendarIds={proposalCalendarIds}
                    allCalendarIds={calendarIds}
                    minWidthPercent={MIN_COLUMN_WIDTH_PERCENTAGE}
                    maxFullColumns={MAX_FULL_COLUMNS}
                  />
                )}

                {!proposalOptionsOverlayVisible && (
                  <ProposalOverlay
                    dateTime={dateTimeToRender}
                    calendarIds={sortedCalendarIds}
                    primaryCalendarId={primaryCalendarId ?? ""}
                    minWidthPercent={MIN_COLUMN_WIDTH_PERCENTAGE}
                    maxFullColumns={MAX_FULL_COLUMNS}
                  />
                )}
                {proposalOptionsOverlayVisible && (
                  <ProposalOptionsOverlayWrapper
                    dateTime={dateTimeToRender}
                    calendarIds={sortedCalendarIds}
                    minWidthPercent={MIN_COLUMN_WIDTH_PERCENTAGE}
                    maxFullColumns={MAX_FULL_COLUMNS}
                  />
                )}
              </div>
            </CalendarDimensionsContext.Provider>

            <RescheduleConfirmationWrapper />
          </div>
        </div>
      </div>
    </animated.div>
  );
};
