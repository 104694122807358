import { Frequency } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { Select, SelectOption, TextField } from "@clockwise/design-system";
import pluralize from "pluralize";
import React, { ChangeEventHandler, useState } from "react";
import { SupportedFrequency } from "./utils";

type FrequencyFieldProps = {
  frequency: SupportedFrequency;
  interval: number;
  onChange: (v: { frequency: SupportedFrequency; interval: number }) => void;
};

export const FrequencyField = ({ frequency, interval, onChange }: FrequencyFieldProps) => {
  const [intervalInput, setIntervalInput] = useState(interval.toString(10));
  const handleIntervalChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const intervalString = e.target.value;
    setIntervalInput(intervalString);
    const interval = parseInt(intervalString, 10);
    if (interval > 0) {
      onChange({ frequency, interval });
    }
  };

  return (
    <div className="cw-inline-flex cw-align-middle cw-gap-2 cw-justify-stretch">
      <TextField
        size={2}
        defaultValue={intervalInput}
        fieldSize="small"
        onChange={handleIntervalChange}
      />
      <Select
        size="small"
        value={frequency}
        onChange={(freq) => onChange({ frequency: freq, interval })}
      >
        <SelectOption value={Frequency.DAILY}>{pluralize("day", interval)}</SelectOption>
        <SelectOption value={Frequency.WEEKLY}>{pluralize("week", interval)}</SelectOption>
        <SelectOption value={Frequency.MONTHLY}>{pluralize("month", interval)}</SelectOption>
        <SelectOption value={Frequency.YEARLY}>{pluralize("year", interval)}</SelectOption>
      </Select>
    </div>
  );
};
