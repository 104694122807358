// schema
import * as ISchema from "#webapp/src/__schema__";
// util
import { logger } from "#webapp/src/util/logger.util";
import { validateTeamErrorable } from "#webapp/src/util/team.util";

// ~-~-~-~-~-~-~-
// Team Settings
// ~-~-~-~-~-~-~-
export const validateTeamSettingsErrorable = (
  teamSettingsErrorable: ISchema.TeamSettingsErrorable,
) => {
  if (teamSettingsErrorable.__typename !== "TeamSettings") {
    logger.error("fragment incomplete for validateTeamSettingsErrorable!");
    return;
  }
  return teamSettingsErrorable;
};

export const maybeGetTeamSettingsFromOrg = (org: ISchema.IOrg) => {
  if (!org || !org.teamSettings) {
    logger.error("fragment incomplete for maybeGetTeamSettingsFromOrg!");
    return;
  }
  return validateTeamSettingsErrorable(org.teamSettings);
};

export const maybeGetTeamSettingsFromTeam = (teamErrorable: ISchema.TeamErrorable) => {
  const team = validateTeamErrorable(teamErrorable);
  if (!team || !team.settings) {
    logger.error("fragment incomplete for maybeGetTeamSettingsFromTeam!");
    return;
  }
  return validateTeamSettingsErrorable(team.settings);
};

// ~-~-~-~-~-~-~-
// Team User Settings
// ~-~-~-~-~-~-~-
export const validateTeamUserSettingsErrorable = (
  teamUserSettingsErrorable: ISchema.TeamUserSettingsErrorable,
) => {
  if (teamUserSettingsErrorable.__typename !== "TeamUserSettings") {
    logger.error("fragment incomplete for validateTeamUserSettingsErrorable!");
    return;
  }
  return teamUserSettingsErrorable;
};

export const maybeGetTeamUserSettingsFromTeam = (teamErrorable: ISchema.TeamErrorable) => {
  const team = validateTeamErrorable(teamErrorable);
  if (!team || !team.userSettings) {
    logger.error("fragment incomplete for maybeGetTeamUserSettingsFromTeam!");
    return;
  }
  return validateTeamUserSettingsErrorable(team.userSettings);
};

// ~-~-~-~-~-~-~-
// Team Calendar
// ~-~-~-~-~-~-~-

// ~-~-~-~-~-~-~-
// No Meeting Day
// ~-~-~-~-~-~-~-
export const isNoMeetingDayEnabledForUserFromTeam = (teamErrorable: ISchema.TeamErrorable) => {
  const teamSettings = maybeGetTeamSettingsFromTeam(teamErrorable);
  const teamUserSettings = maybeGetTeamUserSettingsFromTeam(teamErrorable);
  if (!teamSettings || !teamUserSettings) {
    return logger.error("fragment incomplete for isNoMeetingDayEnabledForUserFromTeam!");
  }

  return teamSettings.useNoMeetingDay && teamUserSettings.useNoMeetingDay;
};

export const getNoMeetingDayOfWeekFromTeam = (teamErrorable: ISchema.TeamErrorable) => {
  const teamSettings = maybeGetTeamSettingsFromTeam(teamErrorable);
  if (!teamSettings || !teamSettings.noMeetingDayOfWeek) {
    return logger.error("fragment incomplete for getNoMeetingDayOfWeek!");
  }

  return teamSettings.noMeetingDayOfWeek;
};
