import { Tooltip } from "@clockwise/design-system";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { getShortName } from "@clockwise/web-commons/src/util/profile.util";
import classNames from "classnames";
import React from "react";

type Props = {
  profile: {
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  } | null;
  onClick?: () => void;
  disabled?: boolean;
};

const PersonButton = ({
  displayName,
  profile,
  disabled,
  onClick,
}: Props & { displayName: string }) => {
  return (
    <button
      className={classNames(
        "cw-flex cw-gap-[2px] cw-items-center cw-pl-[2px] cw-pr-2",
        "cw-rounded-full cw-border-solid cw-body-sm",
        {
          "cw-cursor-pointer hover:cw-bg-positive-inset focus:cw-bg-positive-inset ": !disabled,
          "cw-bg-positive cw-text-positive-muted cw-border-positive-muted cw-outline-positive-emphasis": !disabled,
          "cw-bg-neutral cw-text-neutral-muted cw-border-neutral-muted cw-outline-neutral-emphasis": disabled,
        },
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {profile && <AttendeeAvatar hideTooltip={true} profile={profile} size="small" />}
      <span className="cw-max-w-[100px] cw-truncate"> {displayName}</span>
    </button>
  );
};

export const PersonTag = ({
  profile,
  onClick,
  calendarId,
  disabled = false,
}: Props & { calendarId: string }) => {
  const name = getShortName(profile);
  const displayName = name ? name : calendarId;

  if (name.length > 0) {
    return (
      <Tooltip title={calendarId}>
        <div>
          <PersonButton
            displayName={displayName}
            profile={profile}
            disabled={disabled}
            onClick={onClick}
          />
        </div>
      </Tooltip>
    );
  }

  return (
    <PersonButton
      displayName={displayName}
      profile={profile}
      disabled={disabled}
      onClick={onClick}
    />
  );
};
