import { useAIChatCollapseContext } from "@clockwise/web-commons/src/util/AIChatCollapseContext";
import { animated, config, useSpring } from "@react-spring/web";
import classNames from "classnames";
import { useReducedMotion } from "framer-motion";
import React from "react";
import { COLLAPSIBLE_SIDEBAR_WIDTH } from "./AnimatedAIChat";
import { Calendar } from "./Calendar";

export const AnimatedCalendar = () => {
  const { chatIsCollapsed } = useAIChatCollapseContext();
  const userPrefersReducedMotion = useReducedMotion();
  const sidebarWidth = COLLAPSIBLE_SIDEBAR_WIDTH;
  const animationStyles = useSpring({
    left: chatIsCollapsed ? 0 : sidebarWidth,
    config: {
      duration: userPrefersReducedMotion ? 0 : 150,
      ...config.stiff,
    },
  });
  return (
    <animated.div
      className={classNames(
        "cw-absolute cw-right-0 cw-top-0 cw-bottom-0 cw-overflow-hidden cw-min-w-[430px]",
      )}
      style={{ ...animationStyles, willChange: "left" }}
    >
      <div className="cw-flex cw-w-full cw-transition-all cw-h-full">
        <Calendar />
      </div>
    </animated.div>
  );
};
