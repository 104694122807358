export type TeamMember = {
  profile: {
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  } | null;
};

export const getNamesList = (teamMembers: TeamMember[]) => {
  const membersWithGivenNames = teamMembers.filter((member) => !!member?.profile?.givenName);
  const visibleMembers = membersWithGivenNames
    .slice(0, 3)
    .map((member) => member?.profile?.givenName);
  if (teamMembers.length > 3) {
    return visibleMembers.join(", ") + " and " + (teamMembers.length - 3) + " others";
  } else if (teamMembers.length > 2) {
    const lastMember = visibleMembers.pop();
    const appending = lastMember && " and " + lastMember;
    return visibleMembers.join(", ") + appending;
  } else {
    return visibleMembers[0] || null;
  }
};
