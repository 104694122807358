import { CHROME_MAX_WIDTH } from "#webapp/src/components/chrome-wrapper/ChromeWrapper.styles";

export const modernStyles = {
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
} as const;

// loader in the renderer
export const container = {
  display: "flex",
  flexDirection: "column",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
  height: 500,
} as const;

export const chromeContainer = {
  display: "flex",
  flexDirection: "column",
  top: 0,
  bottom: 0,
  right: 0,
  width: CHROME_MAX_WIDTH,
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
} as const;
