import { logger } from "#webapp/src/util/logger.util";
import { ApolloError } from "@apollo/client";
import toast from "react-hot-toast";
import { track, TrackingEvents } from "../../../util/analytics.util";

interface ErrorType {
  showUserMessage: boolean;
  message: string;
  error?: ApolloError;
}

export function setError(error: ErrorType | null) {
  if (error) {
    track(TrackingEvents.WEB_ONBOARDING.ERROR, { message: error.message, error: error });
    //  in the future we will selectively throw some toasts depending on the error
    logger.error("Onboarding Error: " + error.message, error.error);
    if (error.showUserMessage) {
      toast.error(`Oops something went wrong - ${error.message}`);
    }
  }
}
