import { Tab as HeadlessTab } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

export const ECHeadTab = ({ children }: { children: React.ReactNode }) => (
  <HeadlessTab
    className={({ selected }) =>
      classNames(
        "cw-w-fit",
        "cw-font-body cw-font-semibold cw-text-12",
        "cw-bg-none cw-bg-transparent",
        "cw-px-0 cw-pt-1.5 cw-pb-1.5",
        "cw-outline-none",
        "cw-cursor-pointer",
        "cw-border-t-0 cw-border-l-0 cw-border-r-0 cw-border-b-2 cw-border-solid",
        selected ? "cw-text-accent" : "cw-text-subtle",
        selected ? "" : "hover:cw-text-muted ",
        selected ? "cw-border-accent-emphasis" : "cw-border-transparent",
        selected ? "cw-border-accent-emphasis" : "",
      )
    }
  >
    {children}
  </HeadlessTab>
);

export const ECTabList = ({ children }: { children: React.ReactNode }) => (
  <HeadlessTab.List className="cw-flex cw-space-x-3">{children}</HeadlessTab.List>
);

export const ECTabPanels = HeadlessTab.Panels;
export const ECTabPanel = HeadlessTab.Panel;
export const ECTabGroup = HeadlessTab.Group;
