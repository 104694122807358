import * as ISchema from "#webapp/src/__schema__";
import { handleReadyStateError } from "#webapp/src/util/relay.util";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import React, { memo } from "react";
import { QueryRenderer, ReadyState } from "react-relay";
import { getCurrentEnvironment } from "../../state/relay-environment";
import { WebAppOnboardingComplete } from "./WebAppOnboardingComplete";
import { webAppOnboardingCompleteQuery } from "./WebAppOnboardingComplete.gql";

type Props = {
  orgRelayId: string;
};

export const WebAppOnboardingCompleteRenderer = memo(function WebAppOnboardingCompleteRenderer({
  orgRelayId,
}: Props) {
  const environment = getCurrentEnvironment();
  return (
    <QueryRenderer
      environment={environment}
      query={webAppOnboardingCompleteQuery}
      variables={{ orgRelayId }}
      cacheConfig={{ force: true }}
      render={(readyState: ReadyState) => {
        // check for error or show a loader for first load
        if (readyState.error) {
          handleReadyStateError(readyState.error, "WebAppOnboardingCompleteRenderer");

          return null;
        }

        const org = readyState && readyState.props && (readyState.props.org as ISchema.IOrg);

        if (!org) {
          return <Loader size="xl" sentiment="positive" />;
        }

        return <WebAppOnboardingComplete org={org} />;
      }}
    />
  );
});
