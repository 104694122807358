import { useMutation } from "@apollo/client";
import { QuestionType, ResponseType } from "@clockwise/schema";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { isEmpty } from "lodash";
import { useOrgId } from "../../hooks/useOrgId";
import { SavePmfSurveyResponsesDocument } from "../graphql/__generated__/SavePMFSurveyResponses.generated";
import {
  DisappointmentResponseType,
  DisappointmentResponseTypeToText,
  QuestionTypeToText,
  SurveyResponse,
} from "../types";

export const useSavePMFSurveyResponses = () => {
  const [savePMFSurveyResponses] = useMutation(SavePmfSurveyResponsesDocument);
  const { orgId } = useOrgId();
  const track = useTracking();

  const saveDissapointmentResponse = (responseType: DisappointmentResponseType) => {
    if (!orgId) return;

    void savePMFSurveyResponses({
      variables: {
        input: {
          userSurveyResponseWrapper: {
            id: orgId,
            userSurveyResponses: [
              {
                questionType: QuestionType.HowWouldYouFeelIfNoClockwise,
                responseType,
                questionText: QuestionTypeToText[QuestionType.HowWouldYouFeelIfNoClockwise],
                responseText: DisappointmentResponseTypeToText[responseType],
              },
            ],
          },
        },
      },
    });
  };

  const saveSurveyFreeformFields = (
    disappointmentResponseType: DisappointmentResponseType | null,
    whyText?: string,
    benefitText?: string,
    improvementText?: string,
    alternativeText?: string,
  ) => {
    if (!orgId) return;

    if (!whyText && !benefitText && !improvementText && !alternativeText) {
      // Track when the user clicks submit with no freeform text
      track(TrackingEvents.PMF_SURVEY.EMPTY_SUBMIT);
      return;
    }
    const userSurveyResponses: SurveyResponse[] = [];
    if (whyText && disappointmentResponseType && !isEmpty(whyText.trim())) {
      userSurveyResponses.push({
        questionType: QuestionType.HowWouldYouFeelIfNoClockwise,
        responseType: disappointmentResponseType,
        questionText: QuestionTypeToText[QuestionType.HowWouldYouFeelIfNoClockwise],
        responseText: DisappointmentResponseTypeToText[disappointmentResponseType],
        freeformResponse: whyText,
      });
    }
    if (benefitText && !isEmpty(benefitText.trim())) {
      userSurveyResponses.push({
        questionType: QuestionType.WhatIsMainBenefitOfClockwise,
        responseType: ResponseType.Freeform,
        questionText: QuestionTypeToText[QuestionType.WhatIsMainBenefitOfClockwise],
        freeformResponse: benefitText,
        responseText: benefitText,
      });
    }
    if (improvementText && !isEmpty(improvementText.trim())) {
      userSurveyResponses.push({
        questionType: QuestionType.HowCanWeMakeClockwiseMoreUseful,
        responseType: ResponseType.Freeform,
        questionText: QuestionTypeToText[QuestionType.HowCanWeMakeClockwiseMoreUseful],
        freeformResponse: improvementText,
        responseText: improvementText,
      });
    }
    if (alternativeText && !isEmpty(alternativeText.trim())) {
      userSurveyResponses.push({
        questionType: QuestionType.WhatToUseAsClockwiseAlternative,
        responseType: ResponseType.Freeform,
        questionText: QuestionTypeToText[QuestionType.WhatToUseAsClockwiseAlternative],
        freeformResponse: alternativeText,
        responseText: alternativeText,
      });
    }
    if (!isEmpty(userSurveyResponses)) {
      void savePMFSurveyResponses({
        variables: {
          input: {
            userSurveyResponseWrapper: {
              id: orgId,
              userSurveyResponses: userSurveyResponses,
            },
          },
        },
      });
    }
  };

  return { saveDissapointmentResponse, saveSurveyFreeformFields };
};
