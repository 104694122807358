import { windowLocation } from "#webapp/src/util/location.util";
import { useMutation } from "@apollo/client";
import { paths } from "@clockwise/client-commons/src/constants/site";
import {
  Button,
  ButtonSet,
  Link,
  RadioGroup,
  RadioOption,
  TextArea,
} from "@clockwise/design-system";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { ReactNode, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { HomeUninstallCard } from "./HomeUninstallCard";
import {
  DeactivateUserDocument,
  SaveUninstallResponseDocument,
} from "./__generated__/HomeUninstall.generated";

const SurveyField = ({ children, heading }: { children: ReactNode; heading: ReactNode }) => {
  return (
    <div className="cw-mb-10">
      <div className="cw-heading-base cw-mb-2">{heading}</div>
      <div>{children}</div>
    </div>
  );
};

const ConfirmationDialog = ({
  open,
  onSubmit,
  onClose,
}: {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}) => (
  <Dialog open={open}>
    <DialogTitle>
      <div className="cw-heading-2xl">
        Are you sure you want to deactivate your Clockwise account?
      </div>
    </DialogTitle>
    <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
      <div className="cw-body-base cw-mb-5">
        This action cannot be undone. It may take up to a few hours to process your deactivation
        request.
      </div>
    </DialogContent>
    <DialogActions>
      <ButtonSet>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          sentiment="destructive"
          onClick={() => {
            onSubmit();
            onClose();
          }}
        >
          Deactivate
        </Button>
      </ButtonSet>
    </DialogActions>
  </Dialog>
);

export const DeactivationSurvey = ({ onDeactivate }: { onDeactivate: () => void }) => {
  const [reason, setReason] = useState("");
  const [more, setMore] = useState("");
  const [considerClockwiseFuture, setConsiderClockwiseFuture] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [trackedSurveyResponse, setTrackedSurveyResponse] = useState(false);

  const [saveUninstallResponse] = useMutation(SaveUninstallResponseDocument, {
    onCompleted: () => {
      toast.success("Thanks for submitting your feedback.");
    },
  });
  const [deactivateUser] = useMutation(DeactivateUserDocument, {
    variables: { input: {} },
    onCompleted() {
      track(TrackingEvents.UNINSTALL.DEACTIVATE_SUCCESS);
      onDeactivate();
    },
    onError() {
      track(TrackingEvents.UNINSTALL.DEACTIVATE_FAIL);
      toast.error(
        "Sorry, there was a problem deactivating your account.  Please contact support so that we may resolve this.",
      );
    },
  });

  const track = useTracking();

  const trackFirstSurveyFieldChange = useCallback(() => {
    if (!trackedSurveyResponse) {
      track(TrackingEvents.UNINSTALL.SET_SURVEY_RESPONSE);
      setTrackedSurveyResponse(true);
    }
  }, [trackedSurveyResponse, track]);

  const handleCancel = useCallback(() => {
    track(TrackingEvents.UNINSTALL.CANCEL);
    void windowLocation.assign("HomeUninstallCancel", "/");
  }, [track]);

  const handleDeactivate = useCallback(async () => {
    track(TrackingEvents.UNINSTALL.DEACTIVATE_START);
    // Only submit survey if form is not completely empty
    if (reason || more || considerClockwiseFuture) {
      track(TrackingEvents.UNINSTALL.SUBMIT_SURVEY);
      await saveUninstallResponse({
        variables: { input: { reason, more, considerClockwiseFuture } },
      });
    }

    await deactivateUser();
  }, [deactivateUser, saveUninstallResponse, track, reason, more, considerClockwiseFuture]);

  return (
    <HomeUninstallCard
      title="You’ve uninstalled the Clockwise extension"
      description={
        <>
          You can still use Clockwise and adjust your settings by{" "}
          <Link href={paths.login} onClick={() => track(TrackingEvents.UNINSTALL.DEACTIVATE_ABORT)}>
            logging in.
          </Link>
        </>
      }
    >
      <div className="cw-body-lg cw-text-muted cw-italic cw-mb-6">
        Looking to deactivate your full Clockwise account? Complete the form below.
      </div>
      <div className="cw-flex cw-items-center cw-justify-center">
        <div className="cw-flex cw-flex-col cw-max-w-[650px]">
          <SurveyField heading="Which of the following best reflects the main reason for deactivating your Clockwise account?">
            <RadioGroup
              aria-label="Reason"
              name="reason"
              value={reason}
              onChange={(v) => {
                trackFirstSurveyFieldChange();
                setReason(v);
              }}
            >
              <RadioOption
                value="I couldn't successfully sign up or get started"
                label="I couldn't successfully sign up or get started"
              />
              <RadioOption
                value="I didn't see the value of Clockwise"
                label="I didn't see the value of Clockwise"
              />
              <RadioOption
                value="Clockwise is missing features I need"
                label="Clockwise is missing features I need"
              />
              <RadioOption
                value="Clockwise makes unwanted changes to my calendar"
                label="Clockwise makes unwanted changes to my calendar"
              />
              <RadioOption
                value="Clockwise is disruptive to others on my team"
                label="Clockwise is disruptive to others on my team"
              />
              <RadioOption
                value="I encountered performance issues or errors"
                label="I encountered performance issues or errors"
              />
              <RadioOption
                value="My company's policy does not allow me to use Clockwise"
                label="My company's policy does not allow me to use Clockwise"
              />
              <RadioOption
                value="Clockwise is too expensive for my organization"
                label="Clockwise is too expensive for my organization"
              />
              <RadioOption value="I am leaving my company" label="I am leaving my company" />
              <RadioOption value="Other" label="Other" />
            </RadioGroup>
          </SurveyField>

          <SurveyField heading="Tell us more! Your input is valuable.">
            <TextArea
              value={more}
              onChange={(e) => {
                trackFirstSurveyFieldChange();
                setMore(e.currentTarget.value);
              }}
              fullWidth
            />
          </SurveyField>

          <SurveyField heading="If Clockwise were to address your reason for deactivating your account, would you consider using Clockwise again in the future?">
            <RadioGroup
              aria-label="ConsiderClockwiseFuture"
              name="considerClockwiseFuture"
              value={considerClockwiseFuture}
              onChange={(v) => {
                trackFirstSurveyFieldChange();
                setConsiderClockwiseFuture(v);
              }}
            >
              <RadioOption value="Yes" label="Yes" />
              <RadioOption value="No" label="No" />
              <RadioOption value="I'm not sure" label="I'm not sure" />
            </RadioGroup>
          </SurveyField>
        </div>
      </div>
      <div className="cw-flex cw-justify-center">
        <ButtonSet>
          <Button sentiment="destructive" onClick={() => setDialogOpen(true)}>
            Deactivate your account
          </Button>
          <Button variant="text" onClick={handleCancel}>
            Cancel
          </Button>
        </ButtonSet>
      </div>
      <ConfirmationDialog
        open={dialogOpen}
        onSubmit={handleDeactivate}
        onClose={() => setDialogOpen(false)}
      />
    </HomeUninstallCard>
  );
};
