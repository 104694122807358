import {
  EventContainer,
  NamedEvent,
  UnnamedEvent,
} from "@clockwise/web-commons/src/components/feature-illustration/";
import { Lightbulb } from "phosphor-react";
import React from "react";

export const FocusTimeEvent = () => (
  <NamedEvent duration={90} Icon={Lightbulb} startTime="1:30pm" title="Focus Time" />
);

const FocusTimeIllustration = () => {
  return (
    <EventContainer>
      <UnnamedEvent duration={15} />
      <FocusTimeEvent />
      <UnnamedEvent duration={15} />
    </EventContainer>
  );
};

export default FocusTimeIllustration;
