import { DateTime, Interval } from "luxon";
import React from "react";
import { CalendarPositioner } from "../calendar-positioner/CalendarPositioner";

export const CalendarAvailability = ({
  availablities = [],
  dateTimes = [],
}: {
  availablities: Interval[];
  dateTimes: DateTime[];
}) => {
  const hasAvailability = availablities.length > 0;
  if (!hasAvailability) {
    return null;
  }

  return (
    <>
      <CalendarUnavailableStripes />
      <CalendarPositioner
        dateTimes={dateTimes}
        nonInteractive
        positionables={availablities.map((interval) => ({
          key: interval.toISO(),
          interval,
          render: CalendarAvailabilityBlock,
        }))}
      />
    </>
  );
};

export const CalendarUnavailableStripes = () => (
  <div className="cw-bg-subtle-striped cw-absolute cw-top-0 cw-right-0 cw-bottom-0 cw-left-0 cw-pointer-events-none" />
);

export const CalendarAvailabilityBlock = () => (
  <div className="cw-bg-default cw-h-full cw-w-full cw-pointer-events-none" />
);
