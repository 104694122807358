import { Tooltip } from "@clockwise/design-system";
import classNames from "classnames";
import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { NuxPopoverWrapper } from "../superpowers-checklist/NuxPopoverWrapper";
import { useNuxPopoverStates } from "../superpowers-checklist/useNuxPopoverStates";
import { NavEducationStep } from "../superpowers-checklist/useNuxPopoverStates.util";
import { EmphasisBadge } from "./EmphasisBadge";

export const AppNavLink = ({
  end = false,
  icon,
  label,
  educationPopoverType,
  preventActive = false,
  to,
  tooltip,
  emphasisBadge = false,
}: {
  end?: boolean;
  icon?: React.FC<{ isActive: boolean }>;
  label?: string;
  educationPopoverType?: NavEducationStep;
  preventActive?: boolean;
  to: NavLinkProps["to"];
  tooltip?: string;
  emphasisBadge?: boolean;
}) => {
  const { showAppOverlay } = useNuxPopoverStates();
  const content = (
    <NavLink
      onClick={(e) => {
        // This disables the nav items while NUX tooltips are shown
        if (showAppOverlay) {
          e.preventDefault();
        }
      }}
      aria-label={label || tooltip}
      className={classNames("cw-group", "cw-no-underline")}
      end={end}
      to={to}
    >
      {({ isActive }) => (
        <AppNavLinkContent
          icon={icon}
          isActive={isActive && !preventActive}
          label={label}
          emphasisBadge={emphasisBadge}
          educationPopoverType={educationPopoverType}
        />
      )}
    </NavLink>
  );
  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="right">
        {content}
      </Tooltip>
    );
  }
  return content;
};

export const AppNavLinkContent = ({
  icon: Icon,
  isActive = false,
  label,
  emphasisBadge = false,
  educationPopoverType,
}: {
  icon?: React.FC<{ isActive: boolean }>;
  isActive?: boolean;
  label?: string;
  emphasisBadge?: boolean;
  educationPopoverType?: NavEducationStep;
}) => {
  const { navEducationCurrentState, showAppOverlay } = useNuxPopoverStates();
  const isPopoverVisible = navEducationCurrentState === educationPopoverType;

  const showActiveState = showAppOverlay ? isPopoverVisible : isActive;

  const content = (
    <div
      className={classNames(
        "cw-overflow-hidden cw-rounded-lg cw-flex cw-flex-col cw-items-center cw-justify-center cw-h-8 cw-w-8",
        {
          "cw-bg-[#000] cw-bg-opacity-20": showActiveState,

          "cw-bg-none group-hover:cw-bg-[#000] group-hover:cw-bg-opacity-10":
            !showActiveState && !showAppOverlay,
        },
      )}
    >
      {Icon && <Icon isActive={showActiveState} />}
    </div>
  );

  return (
    <div
      className={classNames(
        "cw-body-sm cw-text-onEmphasis cw-flex cw-flex-col cw-items-center cw-space-y-0.5 cw-select-none cw-relative",
        { "cw-opacity-50": showAppOverlay },
      )}
    >
      {isPopoverVisible ? (
        <NuxPopoverWrapper type={educationPopoverType}>{content}</NuxPopoverWrapper>
      ) : (
        content
      )}
      {emphasisBadge && (
        <div className="cw-absolute cw-top-[25px] cw-left-[35px] cw-z-[1000] ">
          <EmphasisBadge />
        </div>
      )}
      {label && (
        <div className={classNames("cw-text-11 cw-text-center cw-font-medium")}>{label}</div>
      )}
    </div>
  );
};
