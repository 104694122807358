import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DisablePersonalSyncMutationVariables = Types.Exact<{
  externalEventId: Types.Scalars['ID'];
}>;


export type DisablePersonalSyncMutation = { __typename: 'Mutation', disablePersonalSync: { __typename: 'disablePersonalSyncResponse', success: boolean } | null };


export const DisablePersonalSyncDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DisablePersonalSync"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"disablePersonalSync"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"externalEventId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<DisablePersonalSyncMutation, DisablePersonalSyncMutationVariables>;