import { useFullStory } from "#webapp/src/util/fullstory";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { ProposalStateEnum } from "@clockwise/schema/v2";
import {
  hasInvalidError,
  hasNotFoundError,
} from "@clockwise/web-commons/src/network/apollo/errors";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { newUserSignedupViaSharedProposal } from "@clockwise/web-commons/src/util/local-storage";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { TrackingEvents, track } from "../../util/analytics.util";
import { LogoHorizontal } from "../logo";
import { RouteRenderer } from "../route-renderer";
import { PublicSharedProposalActive } from "./PublicSharedProposalActive";
import { PublicSharedProposalInactive } from "./PublicSharedProposalInactive";
import { PublicSharedProposalDocument } from "./apollo/__generated__/PublicSharedProposal.v2.generated";

export type LoginInProgressViaType =
  | "Google"
  | "Accept"
  | "Decline"
  | "View in Clockwise"
  | ""
  | "Microsoft"
  | "FixConflicts";

export const SharedProposalRenderGateway = () => {
  const { proposalId = "" } = useParams();
  const navigate = useNavigate();
  const { identify } = useFullStory();
  const [isValidProposal, setIsValidProposal] = useState(true);
  const [loginInProgressVia, setLoginInProgressVia] = useState<LoginInProgressViaType>("");
  const [pageTitle, setPageTitle] = useState("Shared Proposal by Clockwise");
  const redirect = `app/proposal/${proposalId}`;

  const { loading, data, error } = useGatewayQuery(PublicSharedProposalDocument, {
    variables: {
      proposalId,
    },
    onCompleted: (data) => {
      if (data?.publicSharedProposal) {
        setPageTitle(data.publicSharedProposal.eventSummary.title);
      }
    },
    onError: (error) => {
      if (hasNotFoundError(error) || hasInvalidError(error)) {
        setIsValidProposal(false);
      }
    },
  });

  useEffect(() => {
    track(TrackingEvents.SHAREABLE_PROPOSAL.PUBLIC_VIEW.VIEWED);
  }, []);

  useEffect(() => {
    identify();
  }, [identify]);

  const publicSharedProposal = data?.publicSharedProposal;

  if (loading) {
    return (
      <Loader
        size="xl"
        sentiment="positive"
        className="cw-w-full cw-h-screen cw-flex cw-flex-row cw-items-center cw-justify-center"
      />
    );
  }

  // If user is logged in, redirect to proposal page
  if (data?.currentUser) {
    navigate(`/${redirect}`, { replace: true });
  }

  const isActive = publicSharedProposal?.state === ProposalStateEnum.Active;
  const onLoginStart = (button: LoginInProgressViaType) => {
    track(TrackingEvents.SHAREABLE_PROPOSAL.PUBLIC_VIEW.GOOGLE_SIGNIN, { button });
    setLoginInProgressVia(button);
  };

  const onLoginSuccess = () => {
    setLoginInProgressVia("");
    newUserSignedupViaSharedProposal.set(true);
  };

  // TODO: Add error handling
  const onLoginFailure = () => {
    track(TrackingEvents.SHAREABLE_PROPOSAL.PUBLIC_VIEW.LOGIN_FAILURE);
    setLoginInProgressVia("");
  };

  // TODO: Add error handling
  const onGoogleFailure = () => {
    track(TrackingEvents.SHAREABLE_PROPOSAL.PUBLIC_VIEW.GOOGLE_FAILURE);
    setLoginInProgressVia("");
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={pageTitle} />
        {pageTitle && <title>{pageTitle}</title>}
      </Helmet>
      <RouteRenderer error={!!error && !loading} notFound={!isValidProposal} loading={loading}>
        {!error && !loading && (
          <div className="cw-bg-default cw-overflow-hidden cw-h-full cw-border cw-shadow-md cw-rounded-lg cw-divide-y cw-p-3 cw-text-sm">
            <div
              cw-id="publicProposalPage"
              className="cw-flex cw-flex-row cw-grow cw-items-center cw-justify-center cw-h-screen"
            >
              <div className="cw-flex cw-flex-col lg:cw-w-[420px] cw-w-full">
                <div className="cw-flex cw-text-right cw-justify-end">
                  Powered by{" "}
                  <span>
                    <LogoHorizontal className="cw-w-[100px] cw-ml-2 cw-flex-none" />
                  </span>
                </div>
                <div className="cw-relative cw-flex cw-flex-col cw-bg-default cw-border-r cw-border-subtle cw-border-solid cw-overflow-hidden cw-h-full cw-border cw-shadow-md cw-rounded-lg cw-divide-y cw-p-3 cw-mt-2">
                  {isActive && publicSharedProposal && (
                    <PublicSharedProposalActive
                      proposal={publicSharedProposal}
                      loginInProgressVia={loginInProgressVia}
                      onLoginStart={onLoginStart}
                      onLoginSuccess={onLoginSuccess}
                      onLoginFailure={onLoginFailure}
                      onGoogleFailure={onGoogleFailure}
                    />
                  )}
                  {!isActive && publicSharedProposal && (
                    <PublicSharedProposalInactive
                      proposalId={proposalId}
                      owner={publicSharedProposal.owner}
                      eventTitle={publicSharedProposal.eventSummary.title}
                      state={publicSharedProposal.state}
                      hasFixableConflicts={!isEmpty(publicSharedProposal.consequencesSummary)}
                      loginInProgressVia={loginInProgressVia}
                      onLoginStart={onLoginStart}
                      onLoginSuccess={onLoginSuccess}
                      onLoginFailure={onLoginFailure}
                      onGoogleFailure={onGoogleFailure}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </RouteRenderer>
    </>
  );
};
