import { useLazyQuery } from "@apollo/client";
import { SudoBillingGroupsForOrgDocument } from "./graphql/__generated__/SudoBillingGroupsForOrg.generated";

export const useBillingGroupLookup = () => {
  const [lookupBillingGroup, { data, loading, refetch }] = useLazyQuery(
    SudoBillingGroupsForOrgDocument,
  );

  const billingGroupLookup = (orgId: string, adminEmail: string) => {
    void lookupBillingGroup({
      variables: {
        orgId: orgId ?? "",
        adminEmail: adminEmail ?? "",
      },
    });
  };

  return { billingGroupLookup, data, loading, refetch };
};
