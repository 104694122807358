import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { logger } from "@clockwise/client-commons/src/util/logger";
import React from "react";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { Loading, StepCounter, StepLayout } from "../../shared-components";
import { setError } from "../../utils/setError";
import { TeamJoinCreateSlide } from "./TeamJoinCreateSlide";
import {
  TeamJoinCreateStepDocument,
  TeamJoinCreateStepQuery,
} from "./__generated__/TeamJoinCreateStep.generated";

export type OrgNode = Exclude<
  Exclude<
    Exclude<
      Exclude<Exclude<TeamJoinCreateStepQuery["viewer"]["user"], null>["orgs"], null>["edges"],
      null
    >[0],
    null
  >["node"],
  null
>;

export const TeamJoinCreateStep = () => {
  const { data, loading } = useQuery(TeamJoinCreateStepDocument, {
    onError: (error) => {
      setError({
        error: error,
        message: "failed to fetch Team Join Create information",
        showUserMessage: false,
      });
      goForward();
    },
    onCompleted: (res) => {
      const maybeOrg = getValue(res.viewer?.user?.orgs)?.edges?.[0]?.node;
      if (!maybeOrg) {
        logger.error("failed to fetch Team Join Create information");
        goForward();
      }
    },
  });

  const { goForward, goBack, shouldShowSecondaryButton } = useWebOnboardingSteps();

  const user = getValue(data?.viewer, "Viewer")?.user;
  const org = getValue(user?.orgs)?.edges?.[0]?.node;

  return (
    <StepLayout>
      <StepCounter />
      {loading || !org ? (
        <Loading />
      ) : (
        <TeamJoinCreateSlide
          userId={user?.id}
          org={org}
          goForward={goForward}
          goBack={goBack}
          shouldShowSecondaryButton={shouldShowSecondaryButton}
        />
      )}
    </StepLayout>
  );
};
