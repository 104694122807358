import React, { useEffect } from "react";
import {
  useReadCalendarColor,
  useUpdateCalendarColors,
} from "../../../../web-app-calendar/hooks/CalendarColorsContext";

export const ColoredTextWrapper = ({ text, calendarId }: { text: string; calendarId?: string }) => {
  const calendarColors = useReadCalendarColor(calendarId ?? "");
  const dispatchCalendarColors = useUpdateCalendarColors();

  useEffect(() => {
    if (!calendarColors && !!calendarId) {
      dispatchCalendarColors({
        type: "add-calendars",
        payload: [calendarId],
      });
    }
  }, [calendarColors, calendarId, dispatchCalendarColors]);

  return (
    <span style={{ color: calendarColors?.bg_Emphasis }} className="cw-inline-block">
      {text}
    </span>
  );
};
