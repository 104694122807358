import { SmartHoldState, SmartHoldType } from "@clockwise/schema/v2";
import React from "react";

const UnpausedBrief = ({ calendarName, hold }: { calendarName?: string; hold: string }) => {
  return (
    <div className="cw-text-13">
      This {hold} hold is automatically managed by Clockwise.{" "}
      <span className="cw-font-medium">
        The timing and duration of this event may update as {calendarName ?? "this user"}'s schedule
        changes.
      </span>
    </div>
  );
};

const PausedBrief = ({ hold }: { hold: string }) => {
  return (
    <div className="cw-text-13">
      This timing of this {hold} event is set, because it was edited.
    </div>
  );
};

const ProtectedBrief = ({ hold }: { hold: string }) => {
  return (
    <div className="cw-text-13 cw-flex cw-flex-col cw-gap-2">
      <PausedBrief hold={hold} />
      <div>
        <span className="cw-text-warning cw-font-medium">
          Meetings scheduled over this hold will automatically be declined{" "}
        </span>
        because it was marked as protected.
      </div>
    </div>
  );
};

export const enumHoldToString = (smartHold: SmartHoldType) => {
  switch (smartHold) {
    case SmartHoldType.FocusTime:
      return "Focus Time";
    case SmartHoldType.Lunch:
      return "lunch";
    case SmartHoldType.MeetingRelief:
      return "break";
    case SmartHoldType.TravelTime:
      return "travel time";
    default:
      return "";
  }
};

export const Description = ({
  type,
  calendarName,
  smartHoldState,
}: {
  type: SmartHoldType;
  calendarName?: string;
  smartHoldState: SmartHoldState;
}) => {
  if (type === SmartHoldType.TravelTime) {
    return <div className="cw-text-13">This travel time hold was created by Clockwise.</div>;
  }

  switch (smartHoldState) {
    case SmartHoldState.Unpaused:
      return <UnpausedBrief hold={enumHoldToString(type)} calendarName={calendarName} />;
    case SmartHoldState.Paused:
      return <PausedBrief hold={enumHoldToString(type)} />;
    case SmartHoldState.Protected:
      return <ProtectedBrief hold={enumHoldToString(type)} />;
    default:
      return null;
  }
};
