import { Location } from "react-router-dom";

export const utmParams = "?cwinstall";

export const isExtensionDownloadRedirect = (location: Location | undefined) => {
  const isRouteWelcome = location?.pathname === "/welcome";
  const doesQueryMatch = location?.search === utmParams;

  return isRouteWelcome && doesQueryMatch;
};
