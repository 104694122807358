import * as ISchema from "#webapp/src/__schema__";
import { EventCategoryColoring } from "#webapp/src/components/event-category-coloring";
import { updateToggleEventColoringSettings } from "#webapp/src/mutations";
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import {
  filterAndSortCategories,
  useCalendarColors,
} from "#webapp/src/util/event-category-coloring.util";
import { logger } from "#webapp/src/util/logger.util";
import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
import { Link, Switch } from "@clockwise/design-system";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { createFragmentContainer } from "react-relay";
import { eventColoringSettingsFragments } from "./EventColoringSettings.gql";
import { IContainer, IProps } from "./EventColoringSettingsTypes";

//////////////////
// COMPONENT
//////////////////
export const EventColoringSettingsCmpt = (props: IProps) => {
  const { resetEventColoringSettings } = useCalendarColors();

  // ~-~-~-~-~-~-~-
  // State
  // ~-~-~-~-~-~-~-
  const getToggleState = (org: ISchema.IOrg) => {
    const eventCategoryColorings = filterAndSortCategories(org.eventColoringSettingsErrorable);
    return eventCategoryColorings.some((category) => category.active);
  };

  const [toggled, setToggled] = useState(getToggleState(props.org));

  useEffect(() => {
    setToggled(getToggleState(props.org));
  }, [props]);

  // ~-~-~-~-~-~-~-
  // Callbacks
  // ~-~-~-~-~-~-~-
  const onLearnMore = () => {
    track(TrackingEvents.HELP.EVENT_COLORING);
  };

  const onError = (error?: Error) => {
    logger.error("updateEventColoringSettings failed", error);
    toast.error("Color selection failed to save. Please try again.");
  };

  const onSuccess = () => {
    toast.success("Saved. Your event colors will start updating shortly.");
  };

  const onToggleEventColoring = (toggle: boolean) => {
    const { org, relay } = props;
    const eventCategoryColorings = toggle
      ? resetEventColoringSettings(org.eventColoringSettingsErrorable)
      : filterAndSortCategories(org.eventColoringSettingsErrorable).map((ecc) => ({
          eventCategory: ecc.eventCategory,
          active: ecc.active,
          googleColorKey: ecc.googleColorKey ? ecc.googleColorKey : undefined,
          isUserSet: ecc.isUserSet,
        }));

    // input for mutation
    const input = {
      eventCategoryColorings,
      orgRelayId: org.id,
      enabled: toggle,
    };

    // fire off the mutation
    updateToggleEventColoringSettings(relay.environment, input, onSuccess, onError);
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  const { org, isWebSettings } = props;
  const eventCategoryColorings: ISchema.IEventCategoryColoring[] = filterAndSortCategories(
    org.eventColoringSettingsErrorable,
  );

  return (
    <div>
      <div className="cw-pb-2">
        {!isWebSettings && (
          <div className="cw-body-base cw-pb-4">
            See your priorities at a glance with automatic meeting categorization and color-coding
            from Clockwise. Use the default colors or configure them to your personal
            preferences.&nbsp;
            <Link href={articleUrls.colors} target="_blank" onClick={onLearnMore}>
              Learn more
            </Link>
            .
          </div>
        )}
        <div className="cw-mb-4">
          <Switch
            checked={toggled}
            onChange={onToggleEventColoring}
            label="Enable automatic meeting color-coding"
          />
        </div>
      </div>
      <div className={classNames({ "cw-cursor-not-allowed": !toggled })}>
        <div
          className={classNames({
            "cw-pointer-events-none cw-opacity-40 cw-grayscale-[45%]": !toggled,
          })}
        >
          {eventCategoryColorings.map((ecc, i) => (
            <EventCategoryColoring eventCategoryColoring={ecc} org={org} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const EventColoringSettings = createFragmentContainer<IContainer>(
  EventColoringSettingsCmpt,
  eventColoringSettingsFragments,
);
