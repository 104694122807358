import React from "react";
import { Header, SlideLayout, Text } from "../../shared-components";
import linksImage from "./assets/links.svg";

const Image = () => {
  return <img src={linksImage} className="cw-w-full md:cw-h-[475px] cw-mt-3" />;
};

export const LinksSlide = () => {
  return (
    <SlideLayout>
      <Header>Share your availability, instantly</Header>
      <Text>
        No more back-and-forth. Scheduling Links allow you to book internal and external meetings at
        the optimal time.
      </Text>
      <Image />
    </SlideLayout>
  );
};
