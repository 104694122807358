import { AIChatCollapseProvider } from "@clockwise/web-commons/src/util/AIChatCollapseContext";
import { ActiveEventProvider } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { CalendarProvider } from "@clockwise/web-commons/src/util/CalendarContext";
import { HighlightEventsProvider } from "@clockwise/web-commons/src/util/HighlightEventsContext";
import { TimeSuggestionProvider } from "@clockwise/web-commons/src/util/TimeSuggestionContext";
import { WeekStartDays } from "@clockwise/web-commons/src/util/calendarReducer";
import { getPreviousWeekday } from "@clockwise/web-commons/src/util/getPreviousWeekday";
import { getSequentialDays } from "@clockwise/web-commons/src/util/getSequentialDays";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import React, { ReactNode, useMemo } from "react";
import { HotkeysProvider } from "react-hotkeys-hook";
import { useReadLocalStorage } from "usehooks-ts";
import { AIMessageProvider } from "../chat/ai-chat/hooks/AIMessageContext";
import { CommandBarProvider } from "../chat/ai-chat/hooks/CommandBarContext";
import { SuggestedPromptProvider } from "../chat/ai-chat/hooks/SuggestedPromptContext";
import { CollaboratorsProvider } from "../chat/ai-chat/hooks/useCollaborators";
import { PlannerProvider } from "../web-app-calendar/Context";
import { CalendarColorsProvider } from "../web-app-calendar/hooks/CalendarColorsContext";
import usePlannerMetaData from "../web-app-calendar/hooks/usePlannerMetaData";
import { DialogProvider } from "../web-app-calendar/notification/DialogContext";
import { CurrentProposalProvider } from "./CurrentProposalContext";
import { DefragProposalProvider } from "./DefragProposalContext";
import { ActiveEventDiffProvider } from "./util/ActiveDiffContext";
import { HoverEventProvider } from "./util/HoverEventContext";
import { ProposalOptionsOverlayProvider } from "./util/ProposalOptionsOverlayContext";
import { ProposalOptionsOverlayProviderV2 } from "./util/ProposalOptionsOverlayContextV2";
import { TimeSuggestionPeekProvider } from "./util/TimeSuggestionPeekContext";

const Context = ({ children }: { children: ReactNode }) => {
  const zone = getRenderTimeZone();
  const showWeekendsLS = useReadLocalStorage("showWeekends");

  const initCalendarState = useMemo(() => {
    // setup inital state for calendar
    const dateTime = DateTime.now().setZone(zone).startOf("day");
    const isWeekend = dateTime.weekday === 6 || dateTime.weekday === 7;
    const showWeekends = isNil(showWeekendsLS) ? isWeekend : !!showWeekendsLS;
    const weekStartDay = showWeekends ? "sunday" : "monday";
    const weekDayCount = showWeekends ? 7 : 5;
    const startOfWeek = getPreviousWeekday(dateTime, weekStartDay);
    const visibleDates = getSequentialDays(startOfWeek, weekDayCount).map((date) =>
      date.toISODate(),
    );

    return {
      focusedDate: dateTime.toISODate(),
      visibleDates,
      weekendsShown: showWeekends,
      weekStartDay: weekStartDay as WeekStartDays,
      anchorDate: null,
    };
  }, [showWeekendsLS, zone]);

  const {
    userCalendarIds,
    colorSettings,
    workingHourBounds,
    workingHours,
    primaryCalendarId,
  } = usePlannerMetaData();
  const [isResizingAnyEvent, setIsResizingAnyEvent] = React.useState(false);

  return (
    <DialogProvider>
      <AIMessageProvider>
        <AIChatCollapseProvider>
          <HighlightEventsProvider>
            <CalendarColorsProvider>
              <HoverEventProvider>
                <CalendarProvider initValue={initCalendarState}>
                  <ActiveEventDiffProvider>
                    <ActiveEventProvider>
                      <CurrentProposalProvider>
                        <DefragProposalProvider>
                          <CollaboratorsProvider>
                            <TimeSuggestionProvider>
                              <TimeSuggestionPeekProvider>
                                <SuggestedPromptProvider initValue={null}>
                                  <PlannerProvider
                                    userCalendarIds={userCalendarIds}
                                    colorSettings={colorSettings}
                                    workingHourBounds={workingHourBounds}
                                    workingHours={workingHours}
                                    setIsResizingAnyEvent={setIsResizingAnyEvent}
                                    isResizingAnyEvent={isResizingAnyEvent}
                                    primaryCalendarId={primaryCalendarId}
                                  >
                                    <ProposalOptionsOverlayProvider initValue={true}>
                                      <ProposalOptionsOverlayProviderV2
                                        initValue={{
                                          isVisible: false,
                                          isEnabled: false,
                                          hoveredSuggestion: null,
                                        }}
                                      >
                                        <CommandBarProvider>
                                          <HotkeysProvider initiallyActiveScopes={["prism"]}>
                                            {children}
                                          </HotkeysProvider>
                                        </CommandBarProvider>
                                      </ProposalOptionsOverlayProviderV2>
                                    </ProposalOptionsOverlayProvider>
                                  </PlannerProvider>
                                </SuggestedPromptProvider>
                              </TimeSuggestionPeekProvider>
                            </TimeSuggestionProvider>
                          </CollaboratorsProvider>
                        </DefragProposalProvider>
                      </CurrentProposalProvider>
                    </ActiveEventProvider>
                  </ActiveEventDiffProvider>
                </CalendarProvider>
              </HoverEventProvider>
            </CalendarColorsProvider>
          </HighlightEventsProvider>
        </AIChatCollapseProvider>
      </AIMessageProvider>
    </DialogProvider>
  );
};

export default Context;
