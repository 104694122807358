export const parseLLMFromDebugMessage = (debugInfo: string) => {
  const fallbackLLMString = "Unknown LLM";
  try {
    const parsedDebug: { [key: string]: { [key: string]: string } } = JSON.parse(debugInfo);
    if (parsedDebug["FirstPartyLLMCall"]) {
      return parsedDebug["FirstPartyLLMCall"]["AICQLFirstPartyModel"] ?? fallbackLLMString;
    } else if (parsedDebug["OpenAICall"]) {
      return parsedDebug["OpenAICall"]["AICQLThirdPartyModel"] ?? fallbackLLMString;
    } else {
      return fallbackLLMString;
    }
  } catch (err) {
    return fallbackLLMString;
  }
};
