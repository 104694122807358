import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type NonUserDefragSurveyQueryQueryVariables = Types.Exact<{
  emailInstanceId: Types.Scalars['String'];
}>;


export type NonUserDefragSurveyQueryQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', nonUserDefragEmailResponse: { __typename: 'GraphEntityError' } | { __typename: 'NonUserDefragEmailResponse', responseType: Types.NonUserDefragEmailResponseType, additionalInformation: string | null } } };


export const NonUserDefragSurveyQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"NonUserDefragSurveyQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"emailInstanceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nonUserDefragEmailResponse"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"emailInstanceId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"emailInstanceId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"NonUserDefragEmailResponse"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"responseType"}},{"kind":"Field","name":{"kind":"Name","value":"additionalInformation"}}]}}]}}]}}]}}]} as unknown as DocumentNode<NonUserDefragSurveyQueryQuery, NonUserDefragSurveyQueryQueryVariables>;