import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { Skeleton } from "@material-ui/lab";
import { compact, orderBy } from "lodash";
import React from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";
import { AppNavLinkMenuItem } from "./AppNavLinkMenu";
import { HomeNavTeamsLinksDocument } from "./__generated__/HomeNavTeamsLinks.v2.generated";

type Team = {
  id: string;
  name: string;
};

type AppNavTeamLinksProps = {
  teams: Team[];
  loading?: boolean;
  icon: ReactElement;
};

export const useTeamsList = (): [Team[], boolean] => {
  const { data, loading } = useGatewayQuery(HomeNavTeamsLinksDocument);

  const teams = compact(data?.currentUser?.teams ?? []);

  const orderedTeams = orderBy(teams, ["isPrimary", "teamName"], ["desc", "asc"]);

  return [orderedTeams, loading];
};

export const AppNavTeamLinks = ({ icon, teams, loading = false }: AppNavTeamLinksProps) => {
  if (loading) {
    return (
      <div className="cw-flex cw-flex-col cw-space-y-1 cw-px-3">
        <Skeleton className="cw-rounded" variant="rect" height={20} />
        <Skeleton className="cw-rounded" variant="rect" height={20} />
      </div>
    );
  }

  return teams.map(({ id, name }) => {
    return <AppNavLinkMenuItem key={id} to={`/app/team/${id}`} icon={icon} label={name} />;
  });
};
