//////////////////
// IMPORTS
//////////////////
// schema
// material-ui
import { Typography } from "@clockwise/design-system";
// components
// libraries
import { WebSettingsContainer } from "#webapp/src/components/web-settings/web-settings-container";
import React from "react";
import { QueryRenderer, ReadyState } from "react-relay";
// state
import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
// util
import * as ISchema from "#webapp/src/__schema__";
import { page, PageEvents } from "#webapp/src/util/analytics.util";
import { handleReadyStateError } from "#webapp/src/util/relay.util";
// web-settings-hours-and-preferences-renderer imports
import { PreferencesSkeleton } from "../../account-settings/AccountSettingsSkeleton";
import { query } from "./WebSettingsHoursAndPreferencesRelay.gql";
import { WebSettingsTimeOfDayPreference } from "./WebSettingsTimeOfDayPreference";

export interface IRendererProps {
  orgRelayId: string;
  title?: string;
}

export interface IRendererState {
  key: number;
  loading: boolean;
}

//////////////////
// COMPONENT
//////////////////
export class WebSettingsHoursAndPreferencesRelay extends React.Component<
  IRendererProps,
  IRendererState
> {
  // ~-~-~-~-~-~-~-
  // Setup
  // ~-~-~-~-~-~-~-
  private shouldForce = true;

  private cachedViewer?: ISchema.IViewer;
  private cachedOrg?: ISchema.IOrg;

  constructor(props: IRendererProps) {
    super(props);
    this.state = {
      key: 0,
      loading: false,
    };
  }

  // ~-~-~-~-~-~-~-
  // Life-Cycle
  // ~-~-~-~-~-~-~-
  public componentDidMount() {
    page(PageEvents.WEB_SETTINGS_HOURS_AND_PREFERENCES);
  }

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  // private refetch = () => {
  //   this.shouldForce = true;
  //   this.setState({ key: this.state.key + 1 });
  // };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  public render() {
    const environment = getCurrentEnvironment();

    return (
      <QueryRenderer
        key={this.state.key}
        environment={environment}
        query={query}
        variables={{
          orgRelayId: this.props.orgRelayId,
        }}
        cacheConfig={{ force: this.shouldForce }}
        render={(readyState: ReadyState) => {
          // check for error or show a loader for first load
          if (readyState.error) {
            handleReadyStateError(readyState.error, "WebSettingsHoursAndPreferencesRelay");
            return null;
          }

          const freshViewer: ISchema.IViewer = readyState.props && readyState.props.viewer;
          const freshOrg: ISchema.IOrg = readyState.props && readyState.props.org;

          if (freshViewer) {
            this.cachedViewer = freshViewer;
          }

          if (freshOrg) {
            this.cachedOrg = freshOrg;
          }

          if (!this.cachedViewer || !this.cachedOrg || this.state.loading) {
            return (
              <div className="cw-min-w-[320px] cw-w-full cw-max-w-screen-lg">
                <PreferencesSkeleton />
              </div>
            );
          }

          this.shouldForce = false;

          return (
            <WebSettingsContainer>
              <Typography variant="h1" className="cw-mb-11">
                {this.props.title || "Hours and preferences"}
              </Typography>
              <WebSettingsTimeOfDayPreference org={this.cachedOrg} />
            </WebSettingsContainer>
          );
        }}
      />
    );
  }
}
