import { RadioOption } from "@clockwise/design-system";
import React from "react";
import { CWIDProps } from "../../util/cw-id.util";

export interface TwoRowRadioOptionProps extends CWIDProps {
  value: string;
  header: string;
  subHeader: string;
  disabled?: boolean;
}

export const TwoRowRadioOption = ({
  value,
  header,
  subHeader,
  "cw-id": cwId,
  disabled = false,
}: TwoRowRadioOptionProps) => {
  return (
    <RadioOption
      variant="outlined"
      value={value}
      disabled={disabled}
      label={
        <>
          <div>{header}</div>
          <div className="cw-body-base cw-text-slate-standard">{subHeader}</div>
        </>
      }
      cw-id={cwId}
    />
  );
};
