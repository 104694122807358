import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type LoginCredentialsUserMutationVariables = Types.Exact<{
  input: Types.LoginCredentialsUserMutationInput;
}>;


export type LoginCredentialsUserMutation = { __typename: 'Mutation', loginCredentialsUser: { __typename: 'LoginCredentialsUserMutationPayload', loginStatus: Types.LoginStatus, operation: Types.RefreshTokenResultOperationEnum | null, orgOperation: Types.OrganizationOperationResultEnum | null, clientMutationId: string | null, viewer: { __typename: 'Viewer', id: string } | null } | null };


export const LoginCredentialsUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LoginCredentialsUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginCredentialsUserMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginCredentialsUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"loginStatus"}},{"kind":"Field","name":{"kind":"Name","value":"operation"}},{"kind":"Field","name":{"kind":"Name","value":"orgOperation"}},{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}}]}}]}}]} as unknown as DocumentNode<LoginCredentialsUserMutation, LoginCredentialsUserMutationVariables>;