import Highcharts from "highcharts";
import { DateTime, Duration } from "luxon";
import { formatNumberAsAbbreviation } from "../overview.util";

export function constructDateRangeTooltip(
  accountingPeriodLength: number,
  x: number,
  y: number,
  name: string,
  color: Highcharts.ColorType,
  suffix = "",
) {
  const formattedY = formatNumberAsAbbreviation(y);
  const startDate = DateTime.fromMillis(x).toLocaleString({ month: "short", day: "2-digit" });

  const accountingPeriodDuration = Duration.fromDurationLike({ days: accountingPeriodLength - 1 });
  const endDate = DateTime.fromMillis(x)
    .plus(accountingPeriodDuration)
    .toLocaleString({ month: "short", day: "2-digit", year: "numeric" });

  const tooltipHeader = `<small>${startDate} - ${endDate}</small>`;
  const tooltipBody = `<span style="color: ${String(
    color,
  )};">● </span><span>${name}: <b>${formattedY} ${suffix}</b><span>`;

  const tooltipFormat = `
              <div>${tooltipHeader}<div>
              <div>${tooltipBody}</div>
              `;

  return tooltipFormat;
}

export function constructDatePointTooltip(
  x: number,
  y: number,
  name: string,
  color: Highcharts.ColorType,
  suffix = "",
) {
  const formattedY = formatNumberAsAbbreviation(y);
  const startDate = DateTime.fromMillis(x).toLocaleString({
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  const tooltipHeader = `<small>${startDate}</small>`;
  const tooltipBody = `<span style="color: ${String(
    color,
  )};">● </span><span>${name}: <b>${formattedY} ${suffix}</b><span>`;

  const tooltipFormat = `
              <div>${tooltipHeader}<div>
              <div>${tooltipBody}</div>
              `;

  return tooltipFormat;
}
