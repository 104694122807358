import { NextDefragRunTag } from "@clockwise/web-commons/src/ui/next-defrag-run-tag";
import {
  DefragRunFlowState,
  getDefragRunTag,
  getLocalDefragRunTime,
} from "@clockwise/web-commons/src/util/event-history.util";
import {
  getRenderTimeZone,
  getTimeZoneDisplayName,
} from "@clockwise/web-commons/src/util/time-zone.util";
import React from "react";
import { Card } from "./Card";

export const CanMove = ({
  canMoveAnytime,
  nextDefrag,
}: {
  canMoveAnytime: boolean;
  nextDefrag: string | null;
}) => {
  const title = "Clockwise will optimize this event's timing";
  if (canMoveAnytime || !nextDefrag) {
    return (
      <Card title={title}>
        Clockwise will search for a better time for this event throughout the day.
      </Card>
    );
  }

  const { flowState } = getDefragRunTag(nextDefrag);
  const searchTense = flowState === DefragRunFlowState.Running ? "searches" : "will search";
  const defragRunTimeWithZone = `${getLocalDefragRunTime(nextDefrag)} ${getTimeZoneDisplayName(
    getRenderTimeZone(),
  )}`;

  return (
    <Card
      title={
        <>
          {title} <NextDefragRunTag nextDefrag={nextDefrag} />
        </>
      }
    >
      Clockwise {searchTense} for a better time for this event every weekday at&nbsp;
      {defragRunTimeWithZone}.
    </Card>
  );
};
