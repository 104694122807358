import { ApolloCache, ApolloError, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  ConfirmSharedProposalDocument,
  ConfirmSharedProposalMutation,
} from "../apollo/__generated__/ConfimSharedProposal.generated";

type UseConfirmSharedProposalResult = [
  () => Promise<void>,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

type UseConfirmSharedProposalProps = {
  onCompleted?: (data: ConfirmSharedProposalMutation) => void;
  onError?: (error: ApolloError) => void;
  update?: (cache: ApolloCache<any>, { data }: { data: ConfirmSharedProposalMutation }) => void;
  proposalId: string;
};

export const useConfirmSharedProposal = ({
  onCompleted,
  onError,
  update,
  proposalId,
}: UseConfirmSharedProposalProps): UseConfirmSharedProposalResult => {
  const [confirmSharedProposal, { loading, error }] = useMutation(ConfirmSharedProposalDocument);

  const onConfirmChatProposal = useCallback(async () => {
    await confirmSharedProposal({
      variables: { input: { proposalId } },
      onCompleted,
      onError,
      update,
    });
  }, [confirmSharedProposal, onCompleted, onError, update, proposalId]);

  return [onConfirmChatProposal, { loading, error }];
};
