import { QuestionType, ResponseType } from "@clockwise/schema";

export const QuestionTypeToText = {
  [QuestionType.HowWouldYouFeelIfNoClockwise]:
    "How would you feel if you could no longer use Clockwise?",
  [QuestionType.WhatIsMainBenefitOfClockwise]:
    "What is the main benefit you get from using Clockwise??",
  [QuestionType.HowCanWeMakeClockwiseMoreUseful]: "How can we make Clockwise more useful for you?",
  [QuestionType.WhatToUseAsClockwiseAlternative]:
    "What would you use as an alternative if Clockwise were no longer available?",
};

export const DisappointmentResponseTypeToText: Record<DisappointmentResponseType, string> = {
  [ResponseType.VeryDisappointed]: "Very disappointed",
  [ResponseType.NotDisappointed]: "Not disappointed",
  [ResponseType.SomewhatDisappointed]: "Somewhat disappointed",
};

export type DisappointmentResponseType =
  | ResponseType.VeryDisappointed
  | ResponseType.NotDisappointed
  | ResponseType.SomewhatDisappointed;

export type SurveyResponse = {
  questionType: QuestionType;
  responseText: string;
  freeformResponse?: string;
  responseType?: ResponseType;
  questionText?: string;
};
