import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { Button, Divider, Tooltip } from "@clockwise/design-system";
import { Warning } from "@clockwise/design-system/icons";
import { LockFilled, SvgIconComponent } from "@clockwise/icons";
import { ToggleOffFlex } from "@clockwise/web-commons/src/components/svgs/ToggleOffFlex";
import { ToggleOnFlex } from "@clockwise/web-commons/src/components/svgs/ToggleOnFlex";
import { formatDuration } from "@clockwise/web-commons/src/util/duration.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import classNames from "classnames";
import { isEmpty, noop } from "lodash";
import { DateTime, Interval } from "luxon";
import React from "react";
import { useChangeFlexToggle } from "../chat/hooks/useChangeFlexToggle";
import { formattedDateTime } from "../web-app-calendar/calendar-popover/utils/formattedDateTime";
import { ECAttendeeStack } from "./atoms/ECAttendeeStack";
import { identifyStandardRecurrenceType } from "./molecules/ECRecurrence/recurrence.util";
import { EventCardAttendee } from "./types";

const getCompactRecurrenceText = (rrule: RecurrenceRule, dt?: DateTime) => {
  const type = identifyStandardRecurrenceType(rrule, dt);
  switch (type) {
    case "None":
      return null;
    case "Weekdays":
      return "Weekdays";
    case "Weekly":
      return "Weekly";
    case "Biweekly":
      return "Biweekly";
    case "MonthlyByDate":
    case "MonthlyByNthWeekday":
    case "MonthlyByLastWeekday":
      return "Monthly";
    default:
      return "Repeats";
  }
};

const RecurrenceInfo = ({
  recurrenceRule,
  startDate,
}: {
  recurrenceRule: RecurrenceRule;
  startDate?: DateTime;
}) => {
  const compactRecurrenceText = getCompactRecurrenceText(recurrenceRule);
  if (startDate) {
    const recurrenceDescription = recurrenceRule.toText({
      date: startDate.toISODate(),
      timezone: getRenderTimeZone(),
    });
    return (
      <Tooltip title={recurrenceDescription}>
        <span>{compactRecurrenceText}</span>
      </Tooltip>
    );
  } else {
    return <span>{compactRecurrenceText}</span>;
  }
};

const EventInfoNewEvent = ({
  attendeePeople,
  interval,
  recurrenceRule,
  isFlexible,
  diffId,
}: {
  attendeePeople?: EventCardAttendee[];
  interval?: Interval;
  recurrenceRule?: RecurrenceRule | null;
  isFlexible?: boolean;
  diffId?: string;
}) => {
  const [onChangeFlexToggle] = useChangeFlexToggle({
    onCompleted: noop,
    onError: noop,
  });
  const startTimeFormatted =
    interval && interval?.start
      ? formattedDateTime(interval.start, getRenderTimeZone())
      : "No times found";

  const hasAttendees = attendeePeople && !isEmpty(attendeePeople);
  const flexibilityFetched = isFlexible !== undefined;

  return (
    <div className="cw-flex cw-items-center cw-caption cw-text-muted">
      {flexibilityFetched && (
        <div className="cw-mt-1">
          <Button
            size="mini"
            sentiment="neutral"
            variant="outlined"
            onClick={() => {
              void onChangeFlexToggle(!isFlexible, diffId);
            }}
            startIcon={isFlexible ? ToggleOnFlex : ToggleOffFlex}
            // disabled if incomplete and no interval was returned as this will cause an error when saving flexibility
            disabled={!interval}
          >
            Flexible
          </Button>
        </div>
      )}
      {flexibilityFetched && <Divider orientation="vertical" spacing="sm" inset="sm" />}
      {hasAttendees && (
        <>
          <ECAttendeeStack attendees={attendeePeople} maxShown={3} />
          <Divider orientation="vertical" spacing="sm" inset="sm" />
        </>
      )}
      {startTimeFormatted}
      {recurrenceRule && (
        <>
          {startTimeFormatted && <span className="cw-mx-1"> ⸱ </span>}
          <RecurrenceInfo recurrenceRule={recurrenceRule} startDate={interval?.start} />
        </>
      )}
    </div>
  );
};

const EventInfoDefault = ({
  attendeePeople,
  interval,
  recurrenceRule,
  subText,
  subTextIcon: SubTextIcon,
}: {
  attendeePeople?: EventCardAttendee[];
  interval?: Interval;
  recurrenceRule?: RecurrenceRule | null;
  subText: string | null;
  subTextIcon?: SvgIconComponent;
}) => {
  const durationAsHoursMins = interval
    ? formatDuration(interval.length("minutes"), { unitDisplay: "narrow" })
    : null;

  const hasAttendees = attendeePeople && !isEmpty(attendeePeople);
  const showInlineDivider = subText && durationAsHoursMins;

  return (
    <div className="cw-flex cw-items-center cw-caption cw-leading-5 cw-text-muted cw-font-normal">
      {hasAttendees && <ECAttendeeStack attendees={attendeePeople} maxShown={5} />}
      {(subText || durationAsHoursMins) && (
        <div className="cw-flex cw-items-center">
          {hasAttendees && <Divider orientation="vertical" spacing="sm" inset="xs" />}
          {SubTextIcon && subText && (
            <div className="cw-flex cw-items-center cw-gap-1">
              {SubTextIcon && (
                <SubTextIcon
                  className={classNames("", {
                    "cw-text-warning": SubTextIcon === Warning || SubTextIcon === LockFilled,
                    "cw-text-subtle": SubTextIcon !== Warning && SubTextIcon !== LockFilled,
                  })}
                  style={{ height: 14, width: 14 }}
                />
              )}
              <div>{subText}</div>
            </div>
          )}
          {!SubTextIcon && subText && <span>{subText}</span>}
          {showInlineDivider && <span className="cw-mx-1"> ⸱ </span>}
          {durationAsHoursMins && <span>{durationAsHoursMins}</span>}
        </div>
      )}
      {recurrenceRule && (
        <>
          {(hasAttendees || subText || durationAsHoursMins) && (
            <Divider orientation="vertical" spacing="sm" inset="xs" />
          )}
          <RecurrenceInfo recurrenceRule={recurrenceRule} startDate={interval?.start} />
        </>
      )}
    </div>
  );
};

export const EventInfoWrapper = ({
  attendeePeople,
  interval,
  recurrenceRule,
  subText,
  subTextIcon: SubTextIcon,
  isAddDiff,
  isFlexible,
  diffId,
}: {
  attendeePeople?: EventCardAttendee[];
  interval?: Interval;
  recurrenceRule?: RecurrenceRule | null;
  subText: string | null;
  subTextIcon?: SvgIconComponent;
  isAddDiff: boolean;
  isFlexible?: boolean;
  diffId?: string;
}) => {
  if (isAddDiff) {
    return (
      <EventInfoNewEvent
        attendeePeople={attendeePeople}
        interval={interval}
        recurrenceRule={recurrenceRule}
        isFlexible={isFlexible}
        diffId={diffId}
      />
    );
  } else {
    return (
      <EventInfoDefault
        attendeePeople={attendeePeople}
        interval={interval}
        recurrenceRule={recurrenceRule}
        subText={subText}
        subTextIcon={SubTextIcon}
      />
    );
  }
};
