import React from "react";
import { AIMarkdown } from "../components/ai-markdown";

export const textSplitByNewLine = (text: string) => {
  const splitText = text.split("\n");

  return splitText.map((message, index) => {
    if (message === "") {
      return <div key={index}>&nbsp;</div>;
    } else {
      return (
        <div key={index}>
          <AIMarkdown text={message} />
        </div>
      );
    }
  });
};
