import { Divider } from "@clockwise/design-system";
import { fg_positive_muted } from "@clockwise/design-system/tokens";
import { DeleteFilled, EventAvailable } from "@clockwise/icons";
import { ConflictAction, ConflictEvent } from "@clockwise/schema";
import { LoadingSpinner } from "@clockwise/web-commons/src/ui/LoadingSpinner";
import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import { ResponseStatusEnum } from "../../../__schema__";
import { Header } from "./atoms/Header";
import { ListItemButton, listItemClass } from "./atoms/ListItemButton";
import { useConflictResolver } from "./hooks/useConflictResolver";
import { formattedDateTime } from "./utils/formattedDateTime";

const selectableRSVPs = [
  ResponseStatusEnum.Accepted,
  ResponseStatusEnum.Tentative,
  ResponseStatusEnum.Declined,
] as const;
type SelectableRSVP = typeof selectableRSVPs[number];

const Dot = ({ color }: { color: string }) => {
  return (
    <div className="cw-flex cw-items-center cw-justify-center cw-h-4 cw-w-4">
      <div className={classNames("cw-rounded-full cw-w-[7px] cw-h-[7px]", color)} />
    </div>
  );
};

export const getDot = (responseStatus: SelectableRSVP) => {
  switch (responseStatus) {
    case ResponseStatusEnum.Accepted:
      return <Dot color="cw-bg-positive-emphasis" />;
    case ResponseStatusEnum.Tentative:
      return <Dot color="cw-bg-warning-emphasis" />;
    case ResponseStatusEnum.Declined:
      return <Dot color="cw-bg-destructive-emphasis" />;
  }
};

export const ConflictEventActions = ({
  event,
  onGoBack,
  calendarId,
  onClose,
  clusterId,
  conversationId,
}: {
  event: ConflictEvent;
  onGoBack: () => void;
  calendarId: string;
  onClose: () => void;
  clusterId: string;
  conversationId: string;
}) => {
  const canUserDecline = event.availableActions.includes(ConflictAction.DECLINE);
  const canUserMaybe = event.availableActions.includes(ConflictAction.RSVP_MAYBE);
  const canUserCancel = event.availableActions.includes(ConflictAction.CANCEL);
  const canUserReschedule = event.availableActions.includes(ConflictAction.RESCHEDULE);
  const canUserRSVP = canUserDecline || canUserMaybe;

  const canUserDoAnything = event.availableActions.length;

  const onGoBackAndClose = () => {
    onGoBack();
    onClose();
  };
  const {
    suggestedTimes,
    loading,
    onSelectTime,
    onRescheduleWithChat,
    onDelete,
    onRSVP,
    updatingEvent,
  } = useConflictResolver(event, calendarId, onGoBackAndClose, clusterId, conversationId);

  return (
    <>
      <Header title={event.title} onAction={onGoBack} actionType="GO_BACK" />
      <div className="cw-px-2">
        {!canUserDoAnything && <ListItemButton>No available actions for this event</ListItemButton>}
        {canUserReschedule && (
          <>
            <ListItemButton
              disabled={updatingEvent}
              startIcon={EventAvailable}
              startIconColor={fg_positive_muted}
            >
              <span className="cw-text-subtle cw-font-semibold">Works for everyone</span>
            </ListItemButton>
            {loading ? (
              <div className={classNames(listItemClass, "cw-justify-center cw-w-full cw-pb-2")}>
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {suggestedTimes.length === 0 && (
                  <ListItemButton>No free times found</ListItemButton>
                )}
                {suggestedTimes.map((time: DateTime) => {
                  return (
                    <ListItemButton
                      disabled={updatingEvent}
                      key={time.toFormat("h:mma")}
                      onClick={() => {
                        onSelectTime(time);
                      }}
                    >
                      {formattedDateTime(time)}
                    </ListItemButton>
                  );
                })}
              </>
            )}
            <Divider spacing={0} />
            <ListItemButton disabled={updatingEvent} onClick={onRescheduleWithChat}>
              <div>Find more times with AI</div>
              <div className="cw-text-11 cw-text-muted">Will create a new proposal</div>
            </ListItemButton>
            <Divider spacing={0} />
          </>
        )}
        {canUserRSVP && (
          <>
            <ListItemButton>
              <span className="cw-text-subtle cw-font-semibold ">Change RSVP</span>
            </ListItemButton>
            {canUserDecline && (
              <ListItemButton
                disabled={updatingEvent}
                onClick={() => {
                  onRSVP(ConflictAction.DECLINE);
                }}
                startIcon={() => getDot(ResponseStatusEnum.Declined)}
              >
                Not going
              </ListItemButton>
            )}
            {canUserMaybe && (
              <ListItemButton
                disabled={updatingEvent}
                onClick={() => {
                  onRSVP(ConflictAction.RSVP_MAYBE);
                }}
                startIcon={() => getDot(ResponseStatusEnum.Tentative)}
              >
                Maybe
              </ListItemButton>
            )}
            <Divider spacing={0} />
          </>
        )}

        {canUserCancel && (
          <ListItemButton disabled={updatingEvent} startIcon={DeleteFilled} onClick={onDelete}>
            Delete Meeting
          </ListItemButton>
        )}
      </div>
    </>
  );
};
