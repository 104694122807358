import React, { useState } from "react";
// microsoft-auth-redirect
import { m365Nonce } from "#webapp/src/state/local-storage";
// util
import { logger } from "#webapp/src/util/logger.util";
import { OAUTH_COMPLETE_MESSAGE, sendMessageToParentWindow } from "./auth.util";

const onSuccess = (authCode: string) => {
  sendMessageToParentWindow({ message: OAUTH_COMPLETE_MESSAGE, success: true, authCode });
};

const onFailure = (error?: string) => {
  sendMessageToParentWindow({ message: OAUTH_COMPLETE_MESSAGE, success: false, error });
};

export const MicrosoftAuthRedirect = () => {
  const [error, setError] = useState("");

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const microsoftAuthCode = searchParams.get("code");
    const state = searchParams.get("state");

    if (!microsoftAuthCode || !state) {
      setError("Something went wrong with your authentication, missing a required field.");
      logger.error("microsoft auth error - missing required fields");

      onFailure();
      return;
    }

    let nonce: string | undefined;
    try {
      const parsedState: { nonce?: string } = JSON.parse(state) ?? {};
      nonce = parsedState.nonce;
    } catch (e) {
      setError("Something went wrong with your authentication, could not parse state parameter.");
      logger.error(`microsoft auth error - could not parse state parameter`);
      onFailure();
      return;
    }

    const expectedNonce = m365Nonce.get();

    if (!expectedNonce || expectedNonce !== nonce) {
      setError("Something went wrong with your authentication, state parameter is incorrect.");
      logger.error("microsoft auth error - bad state parameter");

      onFailure();
      return;
    }

    onSuccess(microsoftAuthCode);
    window.close();
  }, []);

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  if (error) {
    return (
      <div className="cw-p-3">
        There was a problem authenticating. &nbsp;
        {error}
      </div>
    );
  }

  return <div className="cw-p-3">verifying...</div>;
};
