import { Tooltip } from "@clockwise/design-system";
import * as React from "react";
import { HeroStat } from "../hero-stat";
import PercentIncreaseSubtitle from "./percent-increase-subtitle";

const HeroForPeriodStats = ({
  period,
  title,
  previousPeriodHelpText,
}: {
  period: {
    current: number;
    previous: number;
  };
  title: string;
  previousPeriodHelpText: string;
}) => {
  const hasCurrentData = period.current > 0;
  const hasPreviousData = period.previous > 0;
  let stat: number | null = null;
  let subtitle = <>None yet</>;

  if (hasCurrentData && hasPreviousData) {
    stat = period.current;
    subtitle = <PercentIncreaseSubtitle {...period} helpText={previousPeriodHelpText} />;
  } else if (hasCurrentData) {
    stat = period.current;
    subtitle = <>During current period</>;
  } else if (hasPreviousData) {
    stat = period.previous;
    subtitle = (
      <Tooltip title={previousPeriodHelpText} placement="bottom-start">
        <div>During previous period</div>
      </Tooltip>
    );
  }

  return <HeroStat hero={stat} title={title} subtitle={subtitle} />;
};

export default HeroForPeriodStats;
