import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
import { Link } from "@clockwise/design-system";
import React from "react";

const Tooltip = () => (
  <>
    See your priorities at a glance with automatic meeting categorization and color-coding from
    Clockwise. Use the default colors or configure them to your personal preferences.{" "}
    <Link onEmphasis href={articleUrls.colors} target="_blank">
      Learn more
    </Link>
  </>
);

export default Tooltip;
