import { Button, Tooltip } from "@clockwise/design-system";
import { Close } from "@clockwise/design-system/icons";
import { DeleteFilled, EditFilled, OpenInFullFilled } from "@clockwise/icons";
import classNames from "classnames";
import React from "react";

export const Header: React.FC<{
  onClose: () => void;
  PrimaryButton?: React.ReactNode;
  children: React.ReactNode;
}> = ({ children, onClose, PrimaryButton }) => {
  const hasPrimaryButton = !!PrimaryButton;

  return (
    <div
      className={classNames(
        "cw-text-muted cw-w-full cw-flex cw-items-center cw-py-2 cw-px-3 cw-h-10",
        "cw-bg-neutral cw-border-t-0 cw-border-x-0 cw-border-b-[0.5px] cw-border-solid cw-border-muted",
        "cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis",
        hasPrimaryButton ? "cw-justify-between" : "cw-justify-end",
      )}
    >
      {PrimaryButton}
      <div className="cw-flex cw-items-center cw-gap-1">
        {children}
        <div className="cw-h-3 cw-w-[1px] cw-bg-neutral-pressed first:cw-invisible"></div>
        <Tooltip title="Close menu" placement="top">
          <Button
            startIcon={Close}
            sentiment="accent"
            iconColorClassOverride="cw-text-neutral-muted"
            onClick={onClose}
            variant="text"
            size="mini"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export const DeleteButton = ({
  disabled = false,
  onClick,
  tooltip = "Delete event",
}: {
  disabled?: boolean;
  onClick: () => void;
  tooltip?: string;
}) => (
  <Tooltip title={tooltip} placement="top">
    <Button
      disabled={disabled}
      startIcon={DeleteFilled}
      sentiment="accent"
      iconColorClassOverride="cw-text-neutral-muted"
      onClick={onClick}
      variant="text"
      size="mini"
    />
  </Tooltip>
);

export const ExpandButton = ({
  onClick,
  tooltip = "View event",
  disabled = false,
}: {
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
}) => (
  <Tooltip title={tooltip} placement="top">
    <Button
      startIcon={OpenInFullFilled}
      sentiment="accent"
      iconColorClassOverride="cw-text-neutral-muted"
      onClick={onClick}
      variant="text"
      size="mini"
      disabled={disabled}
    />
  </Tooltip>
);

export const EditButton = ({
  onClick,
  tooltip = "Edit event",
  disabled = false,
}: {
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
}) => (
  <Tooltip title={tooltip} placement="top">
    <Button
      startIcon={EditFilled}
      sentiment="accent"
      iconColorClassOverride="cw-text-neutral-muted"
      onClick={onClick}
      variant="text"
      size="mini"
      disabled={disabled}
    />
  </Tooltip>
);
