import { SchedulingOptionTypeEnum } from "@clockwise/schema";
import { OptionWithIndex } from "./SchedulingPills";

export const parseSchedulingOptionsGQLByType = (options: OptionWithIndex[]) => ({
  initialOptions: options.filter((option) => option.type === SchedulingOptionTypeEnum.INITIAL),
  customOption: options.find((option) => option.type === SchedulingOptionTypeEnum.CUSTOM),
  additionalOptions: options.filter(
    (option) => option.type === SchedulingOptionTypeEnum.ADDITIONAL,
  ),
});
