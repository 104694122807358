import { ApolloCache, useMutation } from "@apollo/client";
import { UpdateNotificationStrategyInput, UpdateScheduleAs } from "@clockwise/schema";
import { isEqual, merge } from "lodash";
import { useCallback, useMemo } from "react";
import {
  UpdateMeetingReliefDocument,
  UpdateMeetingReliefMutation,
} from "../graphql/__generated__/UpdateMeetingRelief.generated";

import { logger } from "@clockwise/client-commons/src/util/logger";
import { MeetingReliefSettings } from "../types";

export const useMeetingReliefUpdate = (
  orgId: string,
  meetingReliefSettings: MeetingReliefSettings,
  {
    onCompleted,
    onError,
    onUpdate,
  }: {
    onCompleted?: () => void;
    onError?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUpdate?: (cache: ApolloCache<any>) => void;
  },
) => {
  const [updateMeetingRelief, { loading, error }] = useMutation(UpdateMeetingReliefDocument);

  const onErrorHandler = useMemo(() => onError, [onError]);
  const onUpdateHandler = useMemo(() => onUpdate, [onUpdate]);

  const onCompleteHandler = useCallback(
    (expectedData: UpdateMeetingReliefMutation) => (data: UpdateMeetingReliefMutation) => {
      if (!isEqual(expectedData.UpdateMeetingRelief, data.UpdateMeetingRelief)) {
        // @scott - cleanup as part of AI-2044
        // logging to sentry to confrim error severtiy
        logger.error("Meeting Relief Update: Optimistic update does not match server response.", {
          expectedData,
          data,
        });

        onError?.();
      } else {
        onCompleted?.();
      }
    },
    [onCompleted, onError],
  );

  const update = useCallback(
    (updatedSettings: Partial<MeetingReliefSettings>) => {
      const newSettings = merge(meetingReliefSettings, updatedSettings);

      const variables = {
        orgId: String(orgId),
        fatigueThreshold: newSettings.fatigueThreshold,
        breakDuration: newSettings.breakDuration,
        scheduleAs: UpdateScheduleAs[newSettings.scheduleAs],
        notificationStrategy: UpdateNotificationStrategyInput[newSettings.notificationStrategy],
      };

      const optimisticResponse: UpdateMeetingReliefMutation = {
        UpdateMeetingRelief: {
          meetingReliefGoal: {
            id: newSettings.id,
            enabled: newSettings.enabled,
            meetingReliefThreshold: {
              fatigueThreshold: newSettings.fatigueThreshold.toISO(),
              breakDuration: newSettings.breakDuration.toISO(),
              __typename: "MeetingReliefThreshold",
            },
            remoteHoldSettings: {
              scheduleAs: newSettings.scheduleAs,
              notificationStrategy: newSettings.notificationStrategy,
              __typename: "RemoteSmartHoldSettings",
            },
            __typename: "MeetingReliefGoals",
          },
          __typename: "UpdateMeetingReliefGoalResponse",
        },
        __typename: "Mutation",
      };

      return updateMeetingRelief({
        variables,
        optimisticResponse,
        onCompleted: onCompleteHandler(optimisticResponse),
        onError: onErrorHandler,
        update: onUpdateHandler,
      });
    },
    [
      meetingReliefSettings,
      onCompleteHandler,
      onErrorHandler,
      onUpdateHandler,
      orgId,
      updateMeetingRelief,
    ],
  );

  return { update, loading, error };
};

export default useMeetingReliefUpdate;
