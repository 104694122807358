import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FocusTimeGoalSettingsFragmentDoc } from './FocusTimeGoals.generated';
export type ToggleFocusTimeMutationVariables = Types.Exact<{
  orgId: Types.Scalars['ID'];
  enabled: Types.Scalars['Boolean'];
}>;


export type ToggleFocusTimeMutation = { __typename: 'Mutation', ToggleFocusTime: { __typename: 'ToggleFocusTimeGoalResponse', focusTimeGoal: { __typename: 'FocusTimeGoals', id: string, enabled: boolean | null, preferredFocusIn: Types.PreferredFocusTime | null, threshold: { __typename: 'AllFocusTime' } | { __typename: 'SomeFocusTime', idealFocus: any } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null, autoDeclineSettings: { __typename: 'AutoDeclineSettings', enabled: boolean, threshold: any } | null, dailyMaxSettings: { __typename: 'DailyMaxSettings', enabled: boolean, maxAmount: any } | null } } | null };


export const ToggleFocusTimeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ToggleFocusTime"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"ToggleFocusTime"},"name":{"kind":"Name","value":"toggleFocusTime"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"orgId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}},{"kind":"Argument","name":{"kind":"Name","value":"enabled"},"value":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"focusTimeGoal"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FocusTimeGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FocusTimeGoalSettings"}}]}}]}}]}}]}},...FocusTimeGoalSettingsFragmentDoc.definitions]} as unknown as DocumentNode<ToggleFocusTimeMutation, ToggleFocusTimeMutationVariables>;