import { IOrg } from "#webapp/src/__schema__";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TrackingEvents, track } from "../../../../util/analytics.util";
import { BillingGroupAction, SelectTableProvider } from "../../hooks/useSelectTable";
import { getBillingGroupName } from "./APMembers.util";
import { ConfirmBulkActionDialog, ReassignAdminDialog } from "./ap-members-dialogs";
import { InvitePanel } from "./bulk-invite/BulkInvite";
import { BulkActionSnackbar } from "./ui/bulk-action-snackbar";
import { MembersContent } from "./ui/members-content";
import { useAPMembers } from "./useAPMembers";

export const APMembers = ({ org }: { org: IOrg }) => {
  const {
    currentPersons,
    currentUserBgIdList,
    billingGroupsForOrg,
    reassignAdminDialogOpen,
    setReassignAdminDialogOpen,
    adminSelectDialogBillingGroup,
    onAddMember,
  } = useAPMembers();

  const navigate = useNavigate();
  const { billingGroupId } = useParams();

  const [inviteDialogOpen, setInviteDialogOpen] = useState<boolean>(false);
  const [bulkActionDialogOpen, setBulkActionDialogOpen] = useState<boolean>(false);
  const [selectedBulkAction, setSelectedBulkAction] = useState<BillingGroupAction>();

  const onSelectBulkAction = (billingGroupId: string, actionType: string) => {
    setSelectedBulkAction({ type: actionType, billingGroupId: billingGroupId });
    setBulkActionDialogOpen(true);
  };

  const handleInviteDialogOpen = (_open: boolean) => {
    track(TrackingEvents.ADMIN_PANEL.MEMBERS_OPEN_INVITE_DIALOG);
    setInviteDialogOpen(true);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has("invite") && billingGroupId) {
      const searchParamPersonId = searchParams.get("invite") as string;
      onAddMember([searchParamPersonId], billingGroupId).then(() => navigate(appPaths.members));
    }
  }, []);

  return (
    <SelectTableProvider
      members={currentPersons}
      currentUserBgIdList={currentUserBgIdList}
      billingGroups={billingGroupsForOrg}
    >
      <MembersContent onSetInviteDialogOpen={handleInviteDialogOpen} />
      <BulkActionSnackbar onSelect={onSelectBulkAction} />
      <ReassignAdminDialog
        open={reassignAdminDialogOpen}
        onClose={() => setReassignAdminDialogOpen(false)}
        orgId={org.id}
        billingGroupName={getBillingGroupName(billingGroupsForOrg, adminSelectDialogBillingGroup)}
        billingGroupId={adminSelectDialogBillingGroup}
      />
      <InvitePanel
        open={inviteDialogOpen}
        onClose={() => setInviteDialogOpen(false)}
        onCloseAfterInvite={() => setInviteDialogOpen(false)}
      />
      {bulkActionDialogOpen && !!selectedBulkAction && (
        <ConfirmBulkActionDialog
          open={bulkActionDialogOpen}
          onClose={() => {
            setBulkActionDialogOpen(false);
          }}
          action={selectedBulkAction}
        />
      )}
    </SelectTableProvider>
  );
};
