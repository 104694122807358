import { ResponseStatusEnum } from "@clockwise/schema/v2";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useCallback } from "react";
import { EventResponseInfoDocument } from "./__generated__/EventResponseInfo.v2.generated";
import {
  UpdateEventResponseDocument,
  UpdateEventResponseMutation,
} from "./__generated__/UpdateEventResponse.v2.generated";

export function useGatewayEventResponseStatus(externalEventId: string, calendarId: string) {
  const { data, loading, error } = useGatewayQuery(EventResponseInfoDocument, {
    variables: { externalEventId },
  });
  const [updateEventResponse, { loading: updating, error: updateError }] = useGatewayMutation(
    UpdateEventResponseDocument,
  );

  const getOptimisticResponse = useCallback(
    (responseStatus: ResponseStatusEnum): UpdateEventResponseMutation | undefined => {
      if (!data?.event) {
        return;
      }

      return {
        __typename: "Mutation",
        updateEventResponse: {
          __typename: "Event",
          id: data.event.id,
          responseStatus: responseStatus,
          attendees: data.event.attendees.map((attendee) => {
            if (attendee.email === calendarId) {
              return {
                ...attendee,
                responseStatus,
              };
            }
            return attendee;
          }),
        },
      };
    },
    [calendarId, data?.event],
  );

  const update = useCallback(
    async ({
      responseStatus,
      onError,
      onCompleted,
    }: {
      responseStatus: ResponseStatusEnum;
      onError?: () => void;
      onCompleted?: () => void;
    }) => {
      await updateEventResponse({
        variables: {
          externalEventId,
          responseStatus,
        },
        optimisticResponse: getOptimisticResponse(responseStatus),
        onError,
        onCompleted,
      });
    },
    [externalEventId, getOptimisticResponse, updateEventResponse],
  );

  return {
    responseStatus: data?.event?.responseStatus,
    loading,
    error,
    update,
    updating,
    updateError,
  };
}
