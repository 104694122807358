/**
 * @generated SignedSource<<5f0216b131d629cec8c75561d2a4005b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChromeZoomVerifierQuery$variables = {};
export type ChromeZoomVerifierQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"ChromeZoomVerifier_viewer">;
  };
};
export type ChromeZoomVerifierQuery = {
  variables: ChromeZoomVerifierQuery$variables;
  response: ChromeZoomVerifierQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChromeZoomVerifierQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChromeZoomVerifier_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ChromeZoomVerifierQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emails",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000000
                  }
                ],
                "concreteType": "OrgConnection",
                "kind": "LinkedField",
                "name": "orgs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrgEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Org",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "orgs(first:1000000)"
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aac4c9c33bbcf7ca897046ee757416f9",
    "id": null,
    "metadata": {},
    "name": "ChromeZoomVerifierQuery",
    "operationKind": "query",
    "text": "query ChromeZoomVerifierQuery {\n  viewer {\n    ...ChromeZoomVerifier_viewer\n    id\n  }\n}\n\nfragment ChromeZoomVerifier_viewer on Viewer {\n  user {\n    id\n    emails\n    orgs(first: 1000000) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c4ec74e0ecaa5b3aff570cdfed36813";

export default node;
