import { ResponseStatusEnum } from "@clockwise/schema";

type Attendee = {
  isYou: boolean;
  attendingStatus?: ResponseStatusEnum;
};

export const haveAllAttendeesButYouDeclined = (
  attendees: Attendee[],
  attendeesOmitted: boolean,
): boolean => {
  if (attendeesOmitted || !attendees.length) return false;
  const otherAttendees = attendees.filter((attendee) => !attendee.isYou);
  return (
    otherAttendees.length > 0 &&
    otherAttendees.every((attendee) => attendee.attendingStatus === ResponseStatusEnum.Declined)
  );
};
