import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { ProposalErrorProvider } from "../components/shareable-proposal/hooks/ProposalErrorContext";
import { SharedProposalProvider } from "../components/shareable-proposal/hooks/SharedProposalProvider";
import { ProposalLayout } from "../components/shareable-proposal/ProposalLayout";
import { page, PageEvents } from "../util/analytics.util";
import { useFullStory } from "../util/fullstory";

const ProposalPage = () => {
  const { identify } = useFullStory();
  const { proposalId = "" } = useParams();

  useEffect(() => {
    page(PageEvents.SHARED_PROPOSAL, { hasAccess: true });
  }, []);

  useEffect(() => {
    identify();
  }, [identify]);

  return (
    <>
      <SharedProposalProvider proposalId={proposalId}>
        <Helmet>
          <title>Proposal · Clockwise</title>
        </Helmet>
        <div className="cw-min-w-[280px] cw-bg-default cw-w-full cw-h-full cw-flex cw-flex-col cw-items-center">
          <ProposalErrorProvider>
            <ProposalLayout />
          </ProposalErrorProvider>
        </div>
      </SharedProposalProvider>
    </>
  );
};

export default ProposalPage;
