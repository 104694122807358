import { Tooltip } from "@clockwise/design-system";
import { ArrowUpward } from "@clockwise/design-system/icons";
import classNames from "classnames";
import * as React from "react";
import { formatNumberAsAbbreviation } from "../overview.util";

const PercentIncreaseSubtitle = ({
  current,
  previous,
  helpText,
}: {
  current: number;
  previous: number;
} & { helpText: string }) => {
  const percentChange = Math.round(((current - previous) * 100) / previous);
  const formattedPercentChange = formatNumberAsAbbreviation(percentChange);

  return (
    <Tooltip title={helpText} placement="bottom-start">
      <div className="cw-flex cw-items-center cw-flex-wrap">
        {percentChange > 0 && <ArrowUpward className="cw-h-3.5 cw-w-3.5 cw-text-positive" />}
        <span
          className={classNames({
            "cw-font-bold cw-text-positive cw-mr-1 cw-ml-2": percentChange > 0,
          })}
        >
          {formattedPercentChange}
          %&nbsp;
        </span>
        vs. previous period
      </div>
    </Tooltip>
  );
};

export default PercentIncreaseSubtitle;
