import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useUserProfile } from "../hooks/useUserProfile";

const SalutationSkeleton = () => (
  <div className="cw-flex cw-items-end">
    <Skeleton height={80} width={40} />
    <Skeleton height={80} width={115} className="cw-ml-4" />
  </div>
);

export const Salutation = () => {
  const { userProfile, loading } = useUserProfile();
  const greeting = userProfile?.givenName ? `Hi ${userProfile.givenName}!` : `Hi!`;

  if (loading) {
    return <SalutationSkeleton />;
  }

  return <h1 className="cw-heading-3xl cw-text-66">{greeting}</h1>;
};
