import makeMutationUpdateRefetchBadCacheHandler from "../../../util/makeMutationUpdateRefetchBadCacheHandler";
import { TravelTimeGoalsDocument } from "../graphql/__generated__/TravelTimeGoals.generated";
import useTravelTimeSettings from "./useTravelTimeSettings";
import useTravelTimeToggle from "./useTravelTimeToggle";
import useTravelTimeUpdate from "./useTravelTimeUpdate";

type Options = {
  onSuccess?: () => void;
  onFail?: () => void;
};

const useTravelTime = (options?: Options) => {
  const { settings, loading, error: loadError, orgId, refetch } = useTravelTimeSettings();

  const onUpdate = makeMutationUpdateRefetchBadCacheHandler(
    "travel time",
    TravelTimeGoalsDocument,
    refetch,
  );

  const { update, loading: updating, error: updateError } = useTravelTimeUpdate(
    String(orgId),
    settings,
    { onCompleted: options?.onSuccess, onError: options?.onFail, onUpdate },
  );

  const { toggle, loading: toggling, error: toggleError } = useTravelTimeToggle(
    String(orgId),
    settings,
    { onCompleted: options?.onSuccess, onError: options?.onFail, onUpdate },
  );

  return {
    settings,
    loadError,
    loading,
    update,
    updating,
    updateError,
    toggle,
    toggling,
    toggleError,
  };
};

export default useTravelTime;
