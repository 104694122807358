import { Menu, MenuGroup, MenuItem } from "@clockwise/design-system";
import { fg_muted } from "@clockwise/design-system/tokens";
import { DotsThree } from "phosphor-react";
import React from "react";
import { CalendarDensity } from "../";

type Props = {
  declinedEvents: boolean;
  density: CalendarDensity;
  onCalendarDensityChange: (density: CalendarDensity) => void;
  onShowDeclinedEventsChange: (showDeclinedEvents: boolean) => void;
  onShowWeekendsChange: (showWeekends: boolean) => void;
  onFadePastEventsChange: (fadePastEvents: boolean) => void;
  weekends: boolean;
  fadePastEvents: boolean;
  feedbackURL: string;
  changelogUrl: string;
};

export const CalendarMenu = ({
  declinedEvents,
  density,
  onCalendarDensityChange,
  onShowDeclinedEventsChange,
  onShowWeekendsChange,
  onFadePastEventsChange,
  weekends,
  fadePastEvents,
  feedbackURL,
  changelogUrl,
}: Props) => {
  return (
    <Menu
      label={<DotsThree color={fg_muted} aria-label="more options" />}
      variant="inline"
      size="small"
      aria-label="more options"
    >
      <MenuGroup label="View settings">
        <MenuItem onClick={() => onShowWeekendsChange(!weekends)} checked={weekends}>
          Show weekends
        </MenuItem>
        <MenuItem
          onClick={() => onShowDeclinedEventsChange(!declinedEvents)}
          checked={declinedEvents}
        >
          Show declined events
        </MenuItem>
        <MenuItem onClick={() => onFadePastEventsChange(!fadePastEvents)} checked={fadePastEvents}>
          Fade past events
        </MenuItem>
      </MenuGroup>
      <MenuGroup label="Density">
        <MenuItem
          onClick={() => onCalendarDensityChange("compact")}
          checked={density === "compact"}
        >
          Compact
        </MenuItem>
        <MenuItem
          onClick={() => onCalendarDensityChange("default")}
          checked={density === "default"}
        >
          Default
        </MenuItem>
        <MenuItem
          onClick={() => onCalendarDensityChange("comfortable")}
          checked={density === "comfortable"}
        >
          Comfortable
        </MenuItem>
      </MenuGroup>
      <MenuGroup>
        <MenuItem href={changelogUrl}>Changelog</MenuItem>
        <MenuItem href={feedbackURL}>Give feedback</MenuItem>
      </MenuGroup>
    </Menu>
  );
};
