import React, { createContext, PropsWithChildren, useContext } from "react";

// schema
import { EcosystemEnum } from "@clockwise/schema";

// util
import { useFeatureFlag } from "#webapp/src/launch-darkly";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
// import { track, TrackingEvents } from "#webapp/src/util/analytics.util";

export const NotificationContext = createContext({
  isOnBrowserNotifications: false,
  ecosystem: EcosystemEnum.NotApplicable,
  showBrowserNotificationPermissionDialog: false,
  setShowBrowserNotificationPermissionDialog: (_value: boolean) => {},
});

type ProviderProps = {};

export const NotificationProvider = ({ children }: PropsWithChildren<ProviderProps>) => {
  const [isOnBrowserNotifications] = useFeatureFlag("BrowserNotifications");
  const ecosystem = useEcosystem();

  const [
    showBrowserNotificationPermissionDialog,
    setShowBrowserNotificationPermissionDialog,
  ] = React.useState(false);

  return (
    <NotificationContext.Provider
      value={{
        isOnBrowserNotifications,
        ecosystem,
        showBrowserNotificationPermissionDialog,
        setShowBrowserNotificationPermissionDialog,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
