import * as ISchema from "@clockwise/schema";
import { Person } from "../../ui/person-selector";

type SuggestablePerson = Person & {
  isSuggested?: boolean;
  isPending?: boolean;
};

export const getPersonsFromEventAttendees = (
  people: Pick<ISchema.Person, "primaryEmail" | "profile">[],
) => {
  return people.map((p) => {
    const newPerson: SuggestablePerson = {
      email: p.primaryEmail,
      name: {
        givenName: p.profile ? p.profile.givenName : p.primaryEmail,
        familyName: p.profile ? p.profile.familyName : "",
      },
      externalImageUrl: p.profile ? p.profile.externalImageUrl : "",
      isSuggested: false,
    };
    return newPerson;
  });
};

export type PendingPersons =
  | ({
      __typename: "OrgInviteEdge";
      cursor: string;
      node: {
        __typename: "OrgInvite";
        id: string;
        targetCalendarId: string | null;
        lastSentTime: number | null;
        targetProfile: {
          __typename: "UserProfile";
          givenName: string | null;
          familyName: string | null;
          externalImageUrl: string | null;
        } | null;
      } | null;
    } | null)[]
  | null
  | undefined;

export type SuggestedPersons =
  | ({
      __typename: "SuggestedOrgInviteEdge";
      cursor: string;
      node: {
        __typename: "SuggestedOrgInvite";
        id: string;
        targetCalendarId: string | null;
        resolution: ISchema.SuggestedOrgInviteResolution | null;
        numberOfSharedMeetings: number | null;
        numberOfOrganizedMeetings: number | null;
        inviteReason: ISchema.SuggestedOrgInviteReason | null;
        totalScore: number | null;
        targetProfile: {
          __typename: "UserProfile";
          givenName: string | null;
          familyName: string | null;
          externalImageUrl: string | null;
        } | null;
      } | null;
    } | null)[]
  | null
  | undefined;

type PersonListPaginated = {
  __typename: "SearchPerson";
  status: ISchema.SearchPersonStatusResponse;
  person: {
    __typename: "OrgPerson";
    id: string;
    primaryCalendarId: string;
    userId: string | null;
    personId: string;
    isYou: boolean;
    profile: {
      __typename: "UserProfile";
      givenName: string | null;
      familyName: string | null;
      externalImageUrl: string | null;
    } | null;
  };
}[];

const isInPersonsList = (email: string, personArray: SuggestedPersons | PendingPersons) =>
  personArray?.some((person) => person?.node?.targetCalendarId === email);

export const getPersonsFromSearchPersons = (
  people: PersonListPaginated,
  suggestedPersons: SuggestedPersons,
  pendingPersons: PendingPersons,
  alreadySelected: Person[],
) => {
  const suggestions: SuggestablePerson[] = [];
  people?.forEach((p) => {
    const person = p.person;
    const isAlreadySelected = alreadySelected.some((p) => person.primaryCalendarId === p.email);
    if (!person.userId && !isAlreadySelected) {
      const newPerson: SuggestablePerson = {
        email: person.primaryCalendarId,
        name: {
          givenName: person.profile ? person.profile.givenName : person.primaryCalendarId,
          familyName: person.profile ? person.profile.familyName : "",
        },
        externalImageUrl: person.profile ? person.profile.externalImageUrl : "",
        isSuggested: isInPersonsList(person.primaryCalendarId, suggestedPersons),
        isPending: isInPersonsList(person.primaryCalendarId, pendingPersons),
      };
      suggestions.push(newPerson);
    }
  });
  return suggestions;
};

export const getPersonsFromSuggestions = (
  suggestedPersons: SuggestedPersons,
  alreadySelected: Person[],
) => {
  const suggestions: SuggestablePerson[] = [];
  suggestedPersons?.forEach((person) => {
    const isAlreadySelected = alreadySelected.some(
      (p) => person?.node?.targetCalendarId === p.email,
    );
    if (!isAlreadySelected) {
      const personNode = person?.node;
      const profile = personNode?.targetProfile;

      if (!personNode || !profile || !personNode.targetCalendarId) {
        return;
      }
      const newPerson: SuggestablePerson = {
        email: personNode.targetCalendarId,
        name: {
          givenName: profile ? profile.givenName : personNode.targetCalendarId,
          familyName: profile ? profile.familyName : "",
        },
        externalImageUrl: profile ? profile.externalImageUrl : "",
        isSuggested: true,
        isPending: false,
      };
      suggestions.push(newPerson);
    }
  });
  return suggestions;
};
