import { HighlightedNavButton } from "@clockwise/web-commons/src/components/calendar/calendar-step-navigation/CalendarStepNavigation";
import { getRelativeDateAndTime } from "@clockwise/web-commons/src/components/suggested-times-row/utils/date";
import { isEmpty } from "lodash";
import { DateTime, Interval } from "luxon";
import { useReadTimeSuggestionPeek } from "../../../chat-plus-calendar/util/TimeSuggestionPeekContext";

export const convertVisibleDatesToInterval = (
  visibleDates: string[],
  timeZone: string,
): Interval | null => {
  if (visibleDates.length === 0) {
    return null;
  }
  // Make assumption that visibleDates are sorted
  const start = visibleDates[0];
  const end = visibleDates[visibleDates.length - 1];

  const startDateTime = DateTime.fromFormat(start, "yyyy-MM-dd").setZone(timeZone).startOf("day");
  const endDateTime = DateTime.fromFormat(end, "yyyy-MM-dd").setZone(timeZone).endOf("day");

  return Interval.fromDateTimes(startDateTime, endDateTime);
};

export const useHighlightCalendarStepNavigation = (visibleDates: string[], timeZone: string) => {
  let navButtonToHighlight: HighlightedNavButton = null;
  let currentTimeSuggestionString: string | null = null;
  let showTradeoffs: boolean = false;
  let totalAttendeeCount: number = 0;
  const currentTimeSuggestion = useReadTimeSuggestionPeek();

  if (currentTimeSuggestion?.interval && !isEmpty(visibleDates)) {
    const visibleDatesInterval = convertVisibleDatesToInterval(visibleDates, timeZone);
    if (visibleDatesInterval) {
      const timeSuggestionInterval = Interval.fromISO(currentTimeSuggestion.interval).start;

      if (visibleDatesInterval.isBefore(timeSuggestionInterval)) {
        navButtonToHighlight = "next";
        currentTimeSuggestionString = getRelativeDateAndTime(timeSuggestionInterval, timeZone);
        showTradeoffs = true;
        totalAttendeeCount = currentTimeSuggestion.attendeeCount ?? 0;
      } else if (visibleDatesInterval.isAfter(timeSuggestionInterval)) {
        navButtonToHighlight = "previous";
        currentTimeSuggestionString = getRelativeDateAndTime(timeSuggestionInterval, timeZone);
        showTradeoffs = true;
        totalAttendeeCount = currentTimeSuggestion.attendeeCount ?? 0;
      }
    }
  }

  return {
    navButtonToHighlight,
    currentTimeSuggestionString,
    tradeoffs: currentTimeSuggestion.tradeoffs,
    showTradeoffs,
    totalAttendeeCount,
  };
};
