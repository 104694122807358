import { TrackingEvents } from "#webapp/src/util/analytics.util";
import { ApolloError } from "@apollo/client";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { Button } from "@clockwise/design-system";
import { SmartHoldState } from "@clockwise/schema/v2";
import {
  useReadActiveEvent,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { DeleteOutlined } from "@material-ui/icons";
import { Interval } from "luxon";
import React from "react";
import toast from "react-hot-toast";
import { useBoolean } from "usehooks-ts";
import { useReadEventTimeDetails } from "../hooks/EventCardContext";
import { useModifySmartHold } from "../hooks/useModifySmartHold";
import { useUnsyncSmartHold } from "../hooks/useUnsyncSmartHold";
import { ECDeleteConfirm } from "../molecules/ECDeleteConfirm";

export const SmartHoldFooter = ({
  holdState,
  hasHoldStateChanged,
  calendarId,
}: {
  holdState: SmartHoldState;
  hasHoldStateChanged: boolean;
  calendarId: string;
}) => {
  const track = useTracking();
  const event = useReadActiveEvent();
  const updateActiveEvent = useUpdateActiveEvent();
  const { timesHaveChanged, startTime, endTime, timeError } = useReadEventTimeDetails();
  const {
    value: isConfirmDeleteShowing,
    setTrue: setConfirmShowing,
    setFalse: setConfirmHiding,
  } = useBoolean(false);

  const { modifySmartHold, loading: saving } = useModifySmartHold(
    () => {
      toast.success("Hold updated");
      track(TrackingEvents.SMART_HOLD.STATE_UPDATED);
      updateActiveEvent(null);
    },
    (error: ApolloError) => {
      toast.error("Failed to update smarthold.");
      logger.error("Failed to update smarthold.", error);
    },
  );
  const { unsyncSmartHold, loading: unsyncing } = useUnsyncSmartHold(
    () => {
      toast.success("Hold removed");
      track(TrackingEvents.SMART_HOLD.UNSYNCED);
      updateActiveEvent(null);
    },
    (error: ApolloError) => {
      toast.error("Failed to remove smarthold.");
      logger.error("Failed to remove smarthold.", error);
    },
  );

  const onUnsyncSmartHold = () => {
    const externalEventId = event?.externalEventId;
    if (!externalEventId) {
      toast.error("Failed to remove smarthold.");
      logger.error("EventId not found, cannot remove smart hold");
      return;
    }
    unsyncSmartHold(externalEventId, calendarId, startTime);
  };

  const onModifySmartHold = () => {
    if (startTime && endTime) {
      const externalEventId = event?.externalEventId;
      if (!externalEventId) {
        toast.error("Failed to update smarthold.");
        logger.error("EventId not found, cannot save smart hold");
        return;
      }
      const timeInterval = Interval.fromDateTimes(startTime, endTime).toISO();
      modifySmartHold({
        externalEventId,
        timeRange: timesHaveChanged ? timeInterval : undefined,
        holdState: hasHoldStateChanged ? holdState : undefined,
      });
    }
  };

  const hasAnythingChanged = hasHoldStateChanged || timesHaveChanged;
  const hasError = !!timeError;

  return (
    <div className="cw-flex cw-justify-between cw-p-3 cw-relative">
      {isConfirmDeleteShowing && (
        <ECDeleteConfirm onCancelEvent={onUnsyncSmartHold} onHide={setConfirmHiding} isHold />
      )}
      <Button
        cw-id="ec-smarthold-remove"
        disabled={saving || unsyncing}
        size="xsmall"
        variant="outlined"
        onClick={setConfirmShowing}
        sentiment="destructive"
        startIcon={DeleteOutlined}
      >
        Delete hold
      </Button>
      <Button
        cw-id="ec-smarthold-save"
        disabled={hasError || !hasAnythingChanged || saving || unsyncing}
        size="xsmall"
        sentiment="positive"
        onClick={onModifySmartHold}
      >
        Save
      </Button>
    </div>
  );
};
