import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar";
import { ICalPositionable } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import { DateTime, Interval } from "luxon";
import React, { useMemo } from "react";
import { useCurrentProposal } from "../../chat-plus-calendar/CurrentProposalContext";
import { MIN_DURATION } from "../planner-event/PlannerEvents";
import { ProposalSlot } from "./ProposalSlot";

export const ProposalTimeOverlay = ({
  dateTimes,
  reserveRail = false,
}: {
  dateTimes: DateTime[];
  reserveRail?: boolean;
}) => {
  const { currentProposal } = useCurrentProposal();
  const primaryCalendarId = currentProposal.eventCalendarId;

  const widthPercent = 100;
  const fade = false;
  const interval = useMemo(
    () => Interval.fromDateTimes(currentProposal.startTime, currentProposal.endTime),
    [currentProposal.startTime, currentProposal.endTime],
  );

  const positionables: ICalPositionable[] = useMemo(
    () => [
      {
        key: interval.toISO(),
        interval,
        render: () => (
          <ProposalSlot calendarId={primaryCalendarId} widthPercent={widthPercent} fade={fade} />
        ),
      },
    ],
    [interval, primaryCalendarId, fade],
  );

  if (
    !currentProposal.initialized ||
    currentProposal.allDay ||
    !currentProposal.startTime.hasSame(currentProposal.endTime, "day")
  ) {
    return null;
  }

  return (
    <div>
      <CalendarPositioner
        dateTimes={dateTimes}
        positionables={positionables}
        gutters={reserveRail}
        minDuration={MIN_DURATION}
      />
    </div>
  );
};
