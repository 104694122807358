import { Interval } from "luxon";
import { Tradeoff } from "./RescheduleConfirmationModal";

export function toRenderableTradeoffs(tradeoffs: Tradeoff[]) {
  return tradeoffs.map(({ updatedTime, ...t }) => ({
    ...t,
    updatedStartTime:
      updatedTime?.__typename === "DateTimeRange"
        ? Interval.fromISO(updatedTime.dateTimeRange).start
        : updatedTime?.__typename === "DateRange"
        ? Interval.fromISO(updatedTime.dateRange).start
        : null,
  }));
}
