import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SudoRemoveMembersFromBillingGroupMutationVariables = Types.Exact<{
  input: Types.SudoRemoveMembersFromBillingGroupMutationInput;
}>;


export type SudoRemoveMembersFromBillingGroupMutation = { __typename: 'Mutation', sudoRemoveMembersFromBillingGroup: { __typename: 'SudoRemoveMembersFromBillingGroupMutationPayload', clientMutationId: string | null } | null };


export const SudoRemoveMembersFromBillingGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SudoRemoveMembersFromBillingGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SudoRemoveMembersFromBillingGroupMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sudoRemoveMembersFromBillingGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}}]}}]}}]} as unknown as DocumentNode<SudoRemoveMembersFromBillingGroupMutation, SudoRemoveMembersFromBillingGroupMutationVariables>;