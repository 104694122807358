import { SmartHoldSettingsReadable } from "#webapp/src/hooks/useUserSmartHoldSettings/useUserSmartHoldSettings";
import { Divider } from "@clockwise/design-system";
import { SmartHoldState, SmartHoldType } from "@clockwise/schema/v2";
import React from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";
import { Description, enumHoldToString } from "./Description";
import { SmartHoldSettingsBrief } from "./SmartHoldSettingsBrief";

export const SettingsWrapper = ({
  type,
  allSettings,
  isOtherUsersEvent,
  calendarName,
  smartHoldState,
}: {
  type: SmartHoldType;
  allSettings: SmartHoldSettingsReadable | undefined;
  isOtherUsersEvent: boolean;
  calendarName: string;
  smartHoldState: SmartHoldState;
}) => {
  if (!allSettings || isOtherUsersEvent) {
    return (
      <EventElementsWrap name="info">
        <Description calendarName={calendarName} type={type} smartHoldState={smartHoldState} />
      </EventElementsWrap>
    );
  } else {
    return (
      <>
        <EventElementsWrap name="settings">
          <SmartHoldSettingsBrief type={type} allSettings={allSettings} />
        </EventElementsWrap>
        {smartHoldState === SmartHoldState.Paused && (
          <>
            <Divider spacing="xs" />
            <EventElementsWrap name="info">
              <div className="cw-text-13">
                This timing of this {enumHoldToString(type)} is set, because it was edited.
              </div>
            </EventElementsWrap>
          </>
        )}
      </>
    );
  }
};
