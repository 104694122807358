import { Button } from "@clockwise/design-system";
import { Close } from "@clockwise/icons";
import { useWindowSize } from "@clockwise/web-commons/src/util/react.util";
import { Dialog } from "@material-ui/core";
import React from "react";
import { WorkingLocationAddressSettings } from "./WorkingLocationAddressSettings";

export type WorkingLocationAddressDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const WorkingLocationAddressDialog = ({
  open,
  onClose,
}: WorkingLocationAddressDialogProps) => {
  const { width: windowWidth } = useWindowSize();

  return (
    <Dialog
      fullScreen={windowWidth < 650}
      open={open}
      onClose={onClose}
      disableRestoreFocus
      disableScrollLock
      maxWidth="md"
    >
      <div className="cw-p-10 cw-relative">
        <div style={{ width: "600px" }}>
          <WorkingLocationAddressSettings />
          <div className="cw-flex cw-gap-5">
            <Button onClick={onClose} sentiment="positive">
              Done
            </Button>
          </div>
          <div onClick={onClose} className="cw-absolute cw-top-3 cw-right-3">
            <Close className="cw-w-6 cw-h-6 cw-text-muted cw-cursor-pointer" />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
