import { Menu, MenuItem } from "@clockwise/design-system";
import { ConferencingType } from "@clockwise/schema";
import { MeetLogo } from "@clockwise/web-commons/src/components/svgs/MeetLogo";
import { TeamsLogo } from "@clockwise/web-commons/src/components/svgs/TeamsLogo";
import { ZoomLogo } from "@clockwise/web-commons/src/components/svgs/ZoomLogo";
import { routeToFeature } from "@clockwise/web-commons/src/util/routes.util";
import { SvgIconComponent } from "@material-ui/icons";
import { includes } from "lodash";
import React from "react";

const routeToZoomSetup = () =>
  routeToFeature({
    featureName: "appSettings",
    routerState: { kind: "window", target: "_blank" },
    options: {
      paramsObj: { highlight: "true" },
      hash: "#zoom",
      relative: true,
    },
  });

type VideoConfOption = {
  type: ConferencingType;
  label: string;
  Icon: SvgIconComponent;
};

type Props = {
  disabled: boolean;
  enabledTypes: ConferencingType[];
  onSelect: (type?: ConferencingType) => void;
  selected?: ConferencingType;
};

const getLabelForSelectedVC = (type: ConferencingType | undefined, disabled: boolean) => {
  switch (type) {
    case ConferencingType.ZOOM:
      return "Zoom meeting";
    case ConferencingType.MEET:
      return "Google Meet meeting";
    case ConferencingType.TEAMS_FOR_BUSINESS:
      return "Teams meeting";
    case ConferencingType.AROUND:
      return "Around meeting";
    case ConferencingType.OTHER:
      return "Video meeting";
    default:
      return disabled ? "Add conferencing link" : "No video conferencing";
  }
};

export const SelectVideoConf = ({ disabled, onSelect, enabledTypes, selected }: Props) => {
  const options: VideoConfOption[] = [];

  if (includes(enabledTypes, "ZOOM" as ConferencingType)) {
    options.push(ZoomOption);
  } else if (selected !== ConferencingType.ZOOM) {
    options.push(ConnectZoomOption);
  }

  if (includes(enabledTypes, "MEET" as ConferencingType)) {
    options.push(MeetOption);
  }
  if (includes(enabledTypes, "TEAMS_FOR_BUSINESS" as ConferencingType)) {
    options.push(TeamsOption);
  }

  const handleChange = (option: ConferencingType | "None") => {
    if (disabled) return;

    if (option === "None") {
      onSelect(undefined);
      return;
    }

    if (!includes(enabledTypes, option) && option === ConnectZoomOption.type) {
      routeToZoomSetup();
      return;
    }
    onSelect(option);
  };

  return (
    <Menu
      disabled={disabled}
      fullWidth
      size="small"
      label={getLabelForSelectedVC(selected, disabled)}
    >
      {options.map((option) => {
        return (
          <MenuItem
            icon={option.Icon}
            key={option.label}
            onClick={() => {
              handleChange(option.type);
            }}
          >
            {option.label}
          </MenuItem>
        );
      })}
      <MenuItem
        onClick={() => {
          handleChange("None");
        }}
      >
        No video conferencing
      </MenuItem>
    </Menu>
  );
};

export const ZoomOption: VideoConfOption = {
  type: ConferencingType.ZOOM,
  label: "Add Zoom link",
  Icon: ZoomLogo,
};

export const ConnectZoomOption: VideoConfOption = {
  type: ConferencingType.ZOOM,
  label: "Connect Zoom account",
  Icon: ZoomLogo,
};

export const MeetOption: VideoConfOption = {
  type: ConferencingType.MEET,
  label: "Add Meet link",
  Icon: MeetLogo,
};

export const TeamsOption: VideoConfOption = {
  type: ConferencingType.TEAMS_FOR_BUSINESS,
  label: "Add Teams link",
  Icon: TeamsLogo,
};
