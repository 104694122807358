import { removeCalendarIds } from "#webapp/src/state/actions/multi-calendar.actions";
import { setConfirmProposalUIInFlight } from "#webapp/src/state/actions/ui-flow-on-screen.actions";
import { TrackingEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { ApolloError } from "@apollo/client";
import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { EcosystemEnum, RepeatingEventSaveOption } from "@clockwise/schema";
import { ProposalState, TradeoffType } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { Interval } from "luxon";
import React from "react";
import { useDispatch } from "react-redux";
import {
  useCurrentProposal,
  useUpdateCurrentProposal,
} from "../../chat-plus-calendar/CurrentProposalContext";
import {
  usePersistedProposal,
  useUpdatePersistedProposal,
} from "../../chat-plus-calendar/PersistedProposalContext";
import { ConfirmProposalDocument } from "../../reschedule-confirmation-modal/__generated__/ConfirmProposal.v2.generated";
import { CalendarEventsDocument } from "../../web-app-calendar/apollo/__generated__/CalendarEvents.generated";
import { getDisabledSaveOptions } from "../../web-app-calendar/hooks/useEditExistingEvent.util";
import { eventToast } from "../../web-app-calendar/notifiation-event/EventToast";
import { CreateEventMutation } from "../hooks/__generated__/CreateEvent.v2.generated";
import { DeleteEventMutation } from "../hooks/__generated__/DeleteEvent.v2.generated";
import { UpdateEventMutation } from "../hooks/__generated__/UpdateEvent.v2.generated";
import { useCreateEvent } from "../hooks/useCreateEvent";
import { useDeleteEvent } from "../hooks/useDeleteEvent";
import { useUpdateEvent } from "../hooks/useUpdateEvent";
import { ECFooter } from "../molecules/ECFooter";
import { toEditEventAppliesTo, toProposalAppliesTo } from "./utils/proposal";

type Permissions = {
  canModify: boolean;
  canRemove: boolean;
  canDelete: boolean;
  canModifyAll: boolean;
  canModifyFuture: boolean;
};

const DEFAULT_PERMISSIONS: Permissions = {
  canModify: false,
  canRemove: false,
  canDelete: false,
  canModifyAll: false,
  canModifyFuture: false,
};

export const ProposalFooter = ({
  disabled = false,
  permissions = DEFAULT_PERMISSIONS,
  isDescriptionOmitted = false,
  recurrenceRule,
}: {
  disabled?: boolean;
  permissions?: Permissions;
  isDescriptionOmitted?: boolean;
  recurrenceRule: RecurrenceRule | null;
}) => {
  const dispatch = useDispatch();
  const ecosystem = useEcosystem();
  const updateActiveEvent = useUpdateActiveEvent();
  const { clearProposal } = useUpdateCurrentProposal();
  const { clearPersistedProposal } = useUpdatePersistedProposal();

  const { onCreateEvent, loading: saving } = useCreateEvent();
  const { onUpdateEvent, loading: updating } = useUpdateEvent();
  const { onDeleteEvent, loading: deleting } = useDeleteEvent();
  const { currentProposal } = useCurrentProposal();
  const { id: proposalId, tradeoffBlocks, status } = usePersistedProposal();
  const track = useTracking();
  const [confirmProposal, { loading: confirming }] = useGatewayMutation(ConfirmProposalDocument, {
    refetchQueries: [CalendarEventsDocument],
    awaitRefetchQueries: true,
  });

  const clearCurrentAndPersistedProposal = () => {
    clearProposal();
    clearPersistedProposal();
    dispatch(removeCalendarIds(currentProposal.attendees.map((a) => a.person.email)));
  };

  const onEventCreatedSuccess = (data: CreateEventMutation) => {
    if (!data.createEvent) {
      clearCurrentAndPersistedProposal();
      return;
    }

    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.SAVE_BUTTON.CLICKED, {
      eventId: data?.createEvent?.id,
      proposalId,
      timeDescription: data?.createEvent?.timeDescription,
    });
    clearCurrentAndPersistedProposal();
    eventToast.success({
      title: data.createEvent.title,
      operation: "ADD",
    });
  };

  const onEventUpdatedSuccess = (data: UpdateEventMutation) => {
    if (!data.updateEvent) {
      clearCurrentAndPersistedProposal();
      return;
    }

    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.SAVE_BUTTON.CLICKED, {
      eventId: data?.updateEvent?.id,
      proposalId,
      timeDescription: data?.updateEvent?.timeDescription,
    });
    clearCurrentAndPersistedProposal();
    eventToast.success({
      title: data.updateEvent.title,
      operation: "EDIT_DETAILS",
    });
  };

  const onEventDeletedSuccess = (data: DeleteEventMutation) => {
    if (!data.deleteEvent) {
      return;
    }

    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.DELETE_BUTTON.CLICKED, {
      proposalId,
    });
    eventToast.success({
      title: "Event",
      operation: "CANCEL",
    });
  };

  const onEventCreatedError = (error: ApolloError, title: string) => {
    clearCurrentAndPersistedProposal();
    eventToast.error({
      title,
      operation: "ADD",
    });
    logger.error(`Failed to create event: ${error.message}`, error);
  };

  const onEventUpdatedError = (error: ApolloError, title: string) => {
    clearCurrentAndPersistedProposal();
    eventToast.error({
      title,
      operation: "EDIT_DETAILS",
    });
    logger.error(`Failed to update event: ${error.message}`, error);
  };

  const onEventDeletedError = (error: ApolloError, title: string) => {
    clearCurrentAndPersistedProposal();
    eventToast.error({
      title,
      operation: "CANCEL",
    });
    logger.error(`Failed to delete event: ${error.message}`, error);
  };

  const handleDeleteEvent = async (appliesTo?: RepeatingEventSaveOption) => {
    if (!currentProposal) return;
    const { eventId, eventCalendarId, title } = currentProposal;

    if (!eventId || !eventCalendarId) {
      return;
    }

    // Immediately close the event card
    updateActiveEvent(null);
    clearCurrentAndPersistedProposal();

    await onDeleteEvent({
      externalEventId: eventId,
      calendarId: eventCalendarId,
      email: eventCalendarId,
      appliesTo: toEditEventAppliesTo(appliesTo),
      callback: onEventDeletedSuccess,
      errorCallback: (error) => onEventDeletedError(error, title),
    });
  };

  const handleSave = async (appliesTo?: RepeatingEventSaveOption) => {
    if (!currentProposal) return;
    const {
      title,
      startTime,
      endTime,
      timeZone,
      description,
      attendees,
      recurrenceRule,
      location,
      conferenceType,
      flexDetails,
      eventId,
    } = currentProposal;

    // Immediately close the event card and show a loading toast.
    updateActiveEvent(null);
    dispatch(setConfirmProposalUIInFlight());

    if (eventId) {
      const { upsertedAttendees, removedAttendees } = currentProposal;

      const upsertAttendees = Object.values(upsertedAttendees).map((a) => ({
        email: a.person.email,
        optional: a.isOptional ?? false,
      }));
      const removeAttendees = Object.values(removedAttendees).map((a) => a.person.email);

      // Leverage the proposal confirmation to avoid needing a new mutation for existing events.
      await onUpdateEvent({
        externalEventId: eventId,
        appliesTo: toEditEventAppliesTo(appliesTo),
        timeRange: Interval.fromDateTimes(startTime, endTime).toISO(),
        timeZone,
        upsertAttendees,
        removeAttendees,
        title,
        description,
        recurrenceRule,
        location,
        conferenceType,
        callback: onEventUpdatedSuccess,
        errorCallback: (error) => onEventUpdatedError(error, title),
      });
    } else {
      await onCreateEvent({
        title,
        description,
        timeRange: Interval.fromDateTimes(startTime, endTime).toISO(),
        timeZone,
        attendees: attendees.map((a) => ({
          email: a.person.email,
          optional: a.isOptional ?? false,
        })),
        recurrenceRule,
        location,
        conferenceType,
        flexDetails,
        callback: onEventCreatedSuccess,
        errorCallback: (error) => onEventCreatedError(error, title),
      });
    }
  };

  const handleSaveAndFix = async (appliesTo?: RepeatingEventSaveOption) => {
    if (!currentProposal || !proposalId) return;

    try {
      dispatch(setConfirmProposalUIInFlight());
      const result = await confirmProposal({
        variables: {
          proposalId,
          appliesTo: toProposalAppliesTo(appliesTo),
        },
      });

      if (result.data?.confirmProposal) {
        const confirmedProposal = result.data.confirmProposal;
        const diff = confirmedProposal.diffBlocks[0].diffs[0];
        const addDiff = diff.__typename === "AddDiff" ? diff : null;
        const timeRange =
          addDiff?.time?.__typename === "DateRange"
            ? addDiff.time.dateRange
            : addDiff?.time?.timeRange;
        const clientTimeRange = Interval.fromDateTimes(
          currentProposal.startTime,
          currentProposal.endTime,
        );

        if (
          timeRange &&
          !Interval.fromISO(timeRange, { zone: currentProposal.timeZone }).equals(clientTimeRange)
        ) {
          // There shouldn't be any drift from the client tracked time range and the proposal time
          // range, so log an error if there is.
          logger.error("Proposal differs from current client time range", {
            proposalId,
            persistedTimeRange: Interval.fromISO(timeRange, {
              zone: currentProposal.timeZone,
            }).toISO(),
            currentTimeRange: clientTimeRange.toISO(),
          });
        }
        clearCurrentAndPersistedProposal();
        updateActiveEvent(null);
        eventToast.success({
          title: currentProposal.title,
          operation: "ADD",
        });
        track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.SAVE_AND_FIX_BUTTON.CLICKED, {
          proposalId,
        });
      } else {
        throw new Error("Failed to confirm proposal");
      }
    } catch (error) {
      eventToast.error({
        title: currentProposal.title,
        operation: "ADD",
      });
      logger.error(`Failed to confirm proposal: ${error.message}`, error);
    }
  };

  // Dev Note: only FixableConflict tradeoffs with diffId are considered active
  // And this number reduces if user removes a tradeoff.
  // The diffId is removed when user removes a tradeoff
  const numberOfActiveTradeoffs =
    tradeoffBlocks
      ?.filter((block) => block.tradeoffType === TradeoffType.FixableConflict)
      .flatMap((block) => block.tradeoffs)
      .filter((tradeoff) => tradeoff.diffId).length ?? 0;

  const waitingForProposal =
    !!currentProposal.eventId &&
    (!proposalId || (!!proposalId && status === ProposalState.LoadingSuggestions));

  const disabledRecurrenceSaveOptions = getDisabledSaveOptions({
    hasFlexBeenModified: false, // TODO Add change tracking
    hasRecurrenceBeenModified: false, // TODO Add change tracking
    isDescriptionOmitted,
    canModifyFuture: permissions.canModifyFuture,
    canModifyAll: permissions.canModifyAll,
  });

  return (
    <ECFooter
      canModify={permissions?.canModify ?? false}
      canRemove={permissions?.canRemove ?? false}
      canDelete={permissions?.canDelete ?? false}
      disabled={
        disabled ||
        saving ||
        confirming ||
        updating ||
        deleting ||
        (ecosystem === EcosystemEnum.Microsoft && currentProposal.attendees.length === 0)
      }
      onUpdateCancel={handleDeleteEvent}
      onSubmit={handleSave}
      updatesPresent
      isCancelled={false}
      disabledRecurrenceSaveOptions={disabledRecurrenceSaveOptions}
      showDropdownOnSave={
        !!recurrenceRule && (permissions.canModifyAll || permissions.canModifyFuture)
      }
      showSaveAndFix={numberOfActiveTradeoffs > 0 && !waitingForProposal}
      onSaveAndFix={handleSaveAndFix}
    />
  );
};
