import { Button } from "@clockwise/design-system";
import React from "react";
import { useProcessMessage } from "../hooks/useProcessMessage";

export const SendChatMessageButton = ({ text, disabled }: { text: string; disabled?: boolean }) => {
  const { processMessage } = useProcessMessage();

  const submitMessage = () => {
    void processMessage(text, { eventMentions: [], personMentions: [] });
  };

  return (
    <Button variant="outlined" onClick={submitMessage} disabled={disabled} size="small">
      {text}
    </Button>
  );
};
