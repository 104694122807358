import { useQuery } from "@apollo/client";
import { getCurrentOrg } from "../../../util/org.util";
import { LunchGoalsDocument } from "../graphql/__generated__/LunchGoals.generated";
import parseLunchGoalsQuery from "../utils/parseLunchGoalsQuery";
import transformLunchGoalsToSettings from "../utils/transformLunchGoalsToSettings";

export const useLunchSettings = () => {
  const { data, loading, error, refetch } = useQuery(LunchGoalsDocument);

  const orgId = getCurrentOrg(data?.viewer)?.id;

  const goal = parseLunchGoalsQuery(data);
  const settings = transformLunchGoalsToSettings(goal);

  return { settings, loading, error, orgId, refetch };
};

export default useLunchSettings;
