import { ExpandMore } from "@clockwise/design-system/icons";
import { Listbox, Transition } from "@headlessui/react";
import { Portal } from "@material-ui/core";
import classNames from "classnames";
import React, { PropsWithChildren, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { ProposalRenderSurface } from "../../../../constants";

// note: Current design system options group does only
// supports string labels
export const CustomSchedulingSelectOptionsGroup = ({
  label,
  children,
}: PropsWithChildren<{
  label: React.ReactNode;
}>) => {
  return (
    <>
      <div>
        {label && (
          <div
            className={classNames(
              "cw-text-subtle cw-flex cw-gap-1 cw-items-center",
              "cw-text-12 cw-font-semibold cw-select-none cw-relative cw-px-2 cw-py-[5px]",
            )}
          >
            {label}
          </div>
        )}
        {children}
      </div>
      <hr className="last:cw-hidden cw-border-solid cw-border-muted cw-border-b cw-border-t-0 cw-block cw-mx-2 cw-my-1" />
    </>
  );
};

// note: Custom Select is largely the same as Select;
// to be used until design system
// select allows for greater customization :(

type CustomSchedulingSelectProps = PropsWithChildren<{
  value: string;
  label: string;
  onChange: (value: string) => void;
  disabled: boolean;
  name?: string;
  variant?: ProposalRenderSurface;
}>;

export const CustomSchedulingSelect = ({
  value: selectedValue,
  label: buttonContent,
  onChange,
  disabled,
  children,
  name,
  variant = "AI_CHAT",
}: CustomSchedulingSelectProps) => {
  const wasOpen = useRef(false);
  const [trigger, setTrigger] = useState<HTMLButtonElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update: updatePopperInstance } = usePopper(trigger, container, {
    placement: variant === "SHARED_PROPOSAL" ? "bottom-start" : "top-start",
    strategy: "fixed",
    modifiers: [
      { name: "flip", enabled: true },
      { name: "offset", options: { offset: [0, 4] } },
    ],
  });

  return (
    <Listbox value={selectedValue} onChange={onChange} disabled={disabled} as="div" name={name}>
      {({ open }) => {
        /**
         * When the menu first transitions open, we need to tell Popper to update to ensure that the
         * menu is positioned correctly, because it won't be able to know how big the menu is until
         * it is open, and therefore won't know whether it can't fit into the current viewport in
         * the standard orientation.
         */
        if (open && !wasOpen.current) {
          wasOpen.current = true;
          void updatePopperInstance?.();
        } else if (!open && wasOpen) {
          wasOpen.current = false;
        }
        return (
          <div className="cw-font-body cw-max-w-full cw-relative">
            {name && <Listbox.Label className="cw-sr-only">{name}</Listbox.Label>}
            <Listbox.Button
              ref={setTrigger}
              className={classNames(
                "cw-caption cw-min-w-6 cw-rounded-full cw-px-2 cw-py-0.5 cw-h-6",
                "cw-max-w-full cw-flex cw-justify-center cw-items-center",
                "cw-border-none cw-bg-neutral-inset hover:cw-bg-neutral-inset-hover",
                {
                  "cw-cursor-pointer cw-text-brand hover:cw-text-brand-hover": !disabled,
                  "cw-pointer-events-none cw-text-default-disabled": disabled,
                },
              )}
            >
              <span className="cw-block cw-truncate">
                <div className="cw-text-12 cw-gap-1 cw-flex cw-items-center">
                  {buttonContent}
                  <ExpandMore className="cw-h-3 cw-w-3" aria-hidden="true" />
                </div>
              </span>
            </Listbox.Button>

            <Portal>
              <div
                ref={setContainer}
                style={{
                  ...styles.popper,
                  minWidth: 300,
                  zIndex: 10000,
                }}
                {...attributes.popper}
              >
                <Transition
                  leave="cw-transition cw-ease-in cw-duration-100"
                  leaveFrom="cw-opacity-100"
                  leaveTo="cw-opacity-0"
                  enter="cw-transition cw-ease-in cw-duration-100"
                  enterFrom="cw-opacity-0"
                  enterTo="cw-opacity-100"
                >
                  <Listbox.Options
                    // Update popper positioning after the content is rendered
                    ref={() => void updatePopperInstance?.()}
                    className={classNames(
                      "cw-z-50 cw-max-h-72 cw-py-2 cw-px-1 cw-overflow-auto cw-my-1",
                      "cw-font-body cw-font-normal cw-text-13 cw-bg-default cw-rounded-lg cw-shadow-selectPopup cw-min-w-[180px] cw-max-w-[220px]",
                    )}
                  >
                    {children}
                  </Listbox.Options>
                </Transition>
              </div>
            </Portal>
          </div>
        );
      }}
    </Listbox>
  );
};
