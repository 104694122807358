import { SchedulingOptionCategoryTypeEnum } from "@clockwise/schema";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useUpdateTimeSuggestion } from "@clockwise/web-commons/src/util/TimeSuggestionContext";
import React from "react";
import { useUnmount } from "usehooks-ts";
import {
  emptyTimeSuggestionsState,
  useReadTimeSuggestionPeek,
  useUpdateTimeSuggestionPeek,
} from "../../chat-plus-calendar/util/TimeSuggestionPeekContext";
import { SchedulingOptionsTradeoffs } from "../../chat/ai-chat/utils/types";
import { ProposalOptionCard } from "../proposal-option-card/ProposalOptionCard";
import { ProposalOptionRailCard } from "../proposal-option-card/ProposalOptionRailCard";
import { ProposalOptionCardVariant } from "../proposal-option-card/types";

const getVariantFromCategory = (
  category: SchedulingOptionCategoryTypeEnum | null,
): ProposalOptionCardVariant => {
  switch (category) {
    case SchedulingOptionCategoryTypeEnum.AVAILABILITY_ISSUE:
      return "conflict";
    case SchedulingOptionCategoryTypeEnum.FIXABLE_CONFLICT:
      return "sbm";
    case SchedulingOptionCategoryTypeEnum.INCONVENIENCE:
      return "no-conflict";
    default:
      return "no-conflict";
  }
};

export type ProposalOptionSpacing = "full" | "small" | "none";

export interface ProposalOptionProps {
  interval: string;
  tradeoffBlocks: SchedulingOptionsTradeoffs;
  label: string;
  popoverSide: "left" | "right" | "bottom";
  category: SchedulingOptionCategoryTypeEnum | null;
  totalAttendeeCount: number;
  index: number;
  spacing: ProposalOptionSpacing;
  widthPercent: number;
  selectedIndex: number | null;
  rail: boolean;
}

export const ProposalOption = ({
  interval,
  tradeoffBlocks,
  label,
  popoverSide,
  category,
  totalAttendeeCount,
  index,
  spacing,
  widthPercent,
  selectedIndex,
  rail,
}: ProposalOptionProps) => {
  const track = useTracking();
  const { setTimeSuggestionIndex } = useUpdateTimeSuggestion();
  const { setHoveredTimeSuggestion } = useUpdateTimeSuggestionPeek();
  const { interval: suggestedInterval, attendeeCount } = useReadTimeSuggestionPeek();

  const onMouseEnter = (interval: string, tradeoffBlocks: SchedulingOptionsTradeoffs) => {
    setHoveredTimeSuggestion({
      interval,
      tradeoffs: tradeoffBlocks,
      attendeeCount,
    });
  };

  const onMouseLeave = () => {
    setHoveredTimeSuggestion(emptyTimeSuggestionsState);
  };

  useUnmount(() => {
    // Remove the time suggestion overlay when the component unmounts.
    setHoveredTimeSuggestion(emptyTimeSuggestionsState);
  });

  const onClickOption = () => {
    if (selectedIndex !== index) {
      setTimeSuggestionIndex(index);
      setHoveredTimeSuggestion(emptyTimeSuggestionsState);
    }
    track(TrackingEvents.PROPOSAL_OPTIONS_OVERLAY.OPTION_CLICKED);
  };

  return (
    <div
      onMouseEnter={() => onMouseEnter(interval, tradeoffBlocks)}
      onMouseLeave={onMouseLeave}
      className="cw-h-full"
      style={{ width: `${widthPercent}%` }}
    >
      {rail ? (
        <ProposalOptionRailCard
          spacing={spacing}
          variant={getVariantFromCategory(category)}
          onClick={onClickOption}
          emphasized={suggestedInterval === interval}
          popoverSide={popoverSide}
          tradeoffs={tradeoffBlocks}
          attendeeCount={totalAttendeeCount}
          selected={selectedIndex === index}
          label={label}
        />
      ) : (
        <ProposalOptionCard
          text={label}
          spacing={spacing}
          variant={getVariantFromCategory(category)}
          onClick={onClickOption}
          emphasized={suggestedInterval === interval}
          popoverSide={popoverSide}
          tradeoffs={tradeoffBlocks}
          attendeeCount={totalAttendeeCount}
          selected={selectedIndex === index}
        />
      )}
    </div>
  );
};
