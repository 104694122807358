import { useModifySmartHold } from "#webapp/src/components/event-card/hooks/useModifySmartHold";
import { ApolloError } from "@apollo/client";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { Divider } from "@clockwise/design-system";
import { fg_muted, fg_positive } from "@clockwise/design-system/tokens";
import { CheckCircleFilled, PauseCircle, Security } from "@clockwise/icons";
import { SmartHoldState } from "@clockwise/schema/v2";
import { FlexOutlineIcon } from "@clockwise/web-commons/src/components/module-flex-settings/atoms/FlexOutlineIcon";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React from "react";
import toast from "react-hot-toast";
import { ListItemButton } from "../../calendar-popover/atoms/ListItemButton";

const FlexIconWithStyles = () => <FlexOutlineIcon className="cw-w-4 cw-h-4 cw-ml-px" />;

export const FlexAndProtection = ({
  externalEventId,
  smartHoldState,
  isOwnSmartHold,
  onClose,
}: {
  externalEventId: string;
  smartHoldState: SmartHoldState;
  isOwnSmartHold: boolean;
  onClose: () => void;
}) => {
  const track = useTracking();

  const isPaused = smartHoldState === SmartHoldState.Paused;
  const isUnpaused = smartHoldState === SmartHoldState.Unpaused;
  const isProtected = smartHoldState === SmartHoldState.Protected;
  const showUnpaused = isOwnSmartHold || isUnpaused;
  const showPaused = isOwnSmartHold || isPaused;
  const showProtected = isOwnSmartHold || isProtected;

  const { modifySmartHold, loading: saving } = useModifySmartHold(
    (holdState: SmartHoldState) => {
      switch (holdState) {
        case SmartHoldState.Unpaused:
          track(TrackingEvents.EVENT_POPOVER.SMART_HOLD_UNPAUSED);
          toast.success("Hold unpaused");
          break;
        case SmartHoldState.Paused:
          track(TrackingEvents.EVENT_POPOVER.SMART_HOLD_PAUSED);
          toast.success("Hold paused");
          break;
        case SmartHoldState.Protected:
          track(TrackingEvents.EVENT_POPOVER.SMART_HOLD_PROTECTED);
          toast.success("Hold protected");
          break;
      }
      track(TrackingEvents.SMART_HOLD.STATE_UPDATED);
      onClose();
    },
    (error: ApolloError) => {
      toast.error("Failed to update smarthold.");
      logger.error("Failed to update smarthold in popover", error);
    },
  );

  return (
    <>
      <Divider spacing="sm" />
      <div className="cw-body-sm cw-text-12 cw-font-semibold cw-text-subtle cw-pt-3px cw-pb-0.5 cw-px-1.5">
        Flexibility & Protection
      </div>
      {showUnpaused && (
        <ListItemButton
          disabled={saving}
          startIconColor={fg_muted}
          startIcon={FlexIconWithStyles}
          endIconColor={fg_positive}
          keepHoveredStyle={isUnpaused}
          onClick={() => {
            modifySmartHold({ externalEventId, holdState: SmartHoldState.Unpaused });
          }}
          endIcon={isUnpaused ? CheckCircleFilled : undefined}
        >
          <div>Flexible</div>
          <div className="cw-text-11 cw-text-muted">Update as my calendar changes</div>
        </ListItemButton>
      )}
      {showPaused && (
        <ListItemButton
          disabled={saving}
          startIconColor={fg_muted}
          startIcon={PauseCircle}
          endIconColor={fg_positive}
          keepHoveredStyle={isPaused}
          onClick={() => {
            modifySmartHold({ externalEventId, holdState: SmartHoldState.Paused });
          }}
          endIcon={isPaused ? CheckCircleFilled : undefined}
        >
          <div>Paused</div>
          <div className="cw-text-11 cw-text-muted">Do not move</div>
        </ListItemButton>
      )}
      {showProtected && (
        <ListItemButton
          disabled={saving}
          startIconColor={fg_muted}
          startIcon={Security}
          endIconColor={fg_positive}
          keepHoveredStyle={isProtected}
          onClick={() => {
            modifySmartHold({ externalEventId, holdState: SmartHoldState.Protected });
          }}
          endIcon={isProtected ? CheckCircleFilled : undefined}
        >
          <div>Protected</div>
          <div className="cw-text-11 cw-text-muted cw-max-w-[176px]">
            Pause & decline incoming meetings
          </div>
        </ListItemButton>
      )}
    </>
  );
};
