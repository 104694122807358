import { Loader } from "@clockwise/design-system/src/components/Loader";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { PageEvents, page } from "../../util/analytics.util";
import { useOrgId } from "../hooks/useOrgId";
import { NoRouteView } from "../no-route-view";
import { WebSettingsTeamSettingsNewRelay } from "../web-settings/web-settings-team-settings-new";
import { TeamHeader } from "./TeamHeader";
import { TeamScrollProvider } from "./TeamScrollContext";

export const NewTeamPage = () => {
  const { orgId, loading, error } = useOrgId();
  const { teamId } = useParams();

  useEffect(() => {
    page(PageEvents.TEAM_SETTINGS_PAGE, { teamId: teamId });
  }, [teamId]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  if (loading && !orgId) {
    return (
      <div className="cw-flex cw-justify-center cw-items-center cw-h-screen">
        <Loader size="xl" sentiment="positive" />
      </div>
    );
  }

  if (!orgId) {
    return (
      <div className="cw-flex cw-flex-col cw-items-center cw-justify-center cw-h-screen">
        <h2 className="cw-heading-2xl">Your session may have expired</h2>
        <p className="cw-body-lg">Please log in again to continue.</p>
      </div>
    );
  }

  if (!teamId) {
    return <NoRouteView />;
  }

  return (
    <TeamScrollProvider>
      <Helmet>
        <title>Team Dashboard · Clockwise</title>
      </Helmet>
      <div className="cw-w-full">
        <TeamHeader teamId={teamId} />
        <div>
          <WebSettingsTeamSettingsNewRelay orgRelayId={orgId} teamId={teamId} />
        </div>
      </div>
    </TeamScrollProvider>
  );
};
