import React from "react";
import { Header, SlideLayout } from "../../shared-components";
import defragvideo from "./assets/defrag.webm";

const Video = () => {
  return (
    <video
      className="cw-rounded-[24px] md:cw-w-[760px] cw-w-full cw-mt-11"
      autoPlay
      playsInline
      muted
      loop
      src={defragvideo}
    />
  );
};

export const DefragSlide = () => {
  return (
    <SlideLayout>
      <Header>As your assistant, Clockwise manages your calendar as the week unfolds</Header>
      <Video />
    </SlideLayout>
  );
};
