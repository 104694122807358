import { TimeSlot } from "@clockwise/schema";
import { Duration } from "luxon";

const timeSlotDurationAsMinutes = 30;

const timeSlotToMinutes = (ts: TimeSlot) => {
  const [h, m] = ts.substring(2).split("_");
  const hours = Number(h);
  const minutes = Number(m);

  return hours * 60 + minutes;
};

/**
 * @param isStartToEnd `true` for start to end comparision; `false` for start to start comparision
 */
export const diffTimeSlots = (ts1: TimeSlot, ts2: TimeSlot, isStartToEnd = false) => {
  const minutes1 = timeSlotToMinutes(ts1);
  let minutes2 = timeSlotToMinutes(ts2);

  if (isStartToEnd) {
    minutes2 = minutes2 + timeSlotDurationAsMinutes;
  }

  const minutesDiff = Math.abs(minutes1 - minutes2);

  return Duration.fromObject({ minutes: minutesDiff });
};

export default diffTimeSlots;
