import { UserFlags } from "@clockwise/client-commons/src/util/flags";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useFlags } from "@clockwise/web-commons/src/util/FlagsContext";
import { useEffect, useMemo } from "react";

export const useChecklistFlags = () => {
  const track = useTracking();
  const { isFlagSet, setFlag } = useFlags();

  const shouldShowSuperpowersChecklist = isFlagSet(
    UserFlags.NUX.ShouldShowSuperpowersChecklist.name,
  );

  const hasCompletedSchedulingChecklistItem = isFlagSet(
    UserFlags.NUX.HasCompletedSchedulingChecklistItem.name,
  );
  const hasCompletedReschedulingChecklistItem = isFlagSet(
    UserFlags.NUX.HasCompletedReschedulingChecklistItem.name,
  );
  const hasCompletedPreferencesChecklistItem = isFlagSet(
    UserFlags.NUX.HasCompletedPreferencesChecklistItem.name,
  );

  const isChecklistCompleted = useMemo(() => {
    return (
      hasCompletedSchedulingChecklistItem &&
      hasCompletedReschedulingChecklistItem &&
      hasCompletedPreferencesChecklistItem
    );
  }, [
    hasCompletedSchedulingChecklistItem,
    hasCompletedReschedulingChecklistItem,
    hasCompletedPreferencesChecklistItem,
  ]);

  useEffect(() => {
    if (isChecklistCompleted && shouldShowSuperpowersChecklist) {
      track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.ALL_COMPLETED);
      setTimeout(() => setShowSuperpowersChecklist(false), 3000);
    }
  }, [isChecklistCompleted, shouldShowSuperpowersChecklist]);

  const setCompletedSchedulingChecklistItem = () => {
    setFlag(UserFlags.NUX.HasCompletedSchedulingChecklistItem.name, true);
  };

  const setCompletedReschedulingChecklistItem = () => {
    setFlag(UserFlags.NUX.HasCompletedReschedulingChecklistItem.name, true);
  };

  const setCompletedPreferencesChecklistItem = () => {
    setFlag(UserFlags.NUX.HasCompletedPreferencesChecklistItem.name, true);
  };

  const setShowSuperpowersChecklist = (shouldShow: boolean) => {
    setFlag(UserFlags.NUX.ShouldShowSuperpowersChecklist.name, shouldShow);
  };

  // Only to be used in testing to reset the checklist items
  const resetAllChecklistItems = () => {
    setFlag(UserFlags.NUX.HasCompletedSchedulingChecklistItem.name, false);
    setFlag(UserFlags.NUX.HasCompletedReschedulingChecklistItem.name, false);
    setFlag(UserFlags.NUX.HasCompletedPreferencesChecklistItem.name, false);
    setFlag(UserFlags.NUX.ShouldShowUnvisitedBadges.name, false);
  };

  return {
    schedulingCompleted: hasCompletedSchedulingChecklistItem,
    reschedulingCompleted: hasCompletedReschedulingChecklistItem,
    preferencesCompleted: hasCompletedPreferencesChecklistItem,
    completeSchedulingChecklistItem: setCompletedSchedulingChecklistItem,
    completeReschedulingChecklistItem: setCompletedReschedulingChecklistItem,
    completePreferencesChecklistItem: setCompletedPreferencesChecklistItem,
    resetAllChecklistItems,
    shouldShowSuperpowersChecklist,
    setShowSuperpowersChecklist,
  };
};
