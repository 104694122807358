import { NotificationStrategy, ScheduleAs } from "@clockwise/schema";
import React from "react";
import NotificationStrategyOption from "./NotificationStrategyOption";
import ScheduleAsOption from "./ScheduleAsOption";

export type OptionsState = {
  scheduleAs?: ScheduleAs;
  notificationStrategy?: NotificationStrategy;
};

type Props = {
  scheduleAs?: ScheduleAs;
  enabled: boolean;
  notificationStrategy?: NotificationStrategy;
  onChange: (options: OptionsState) => void;
};

const Options = ({ scheduleAs, enabled, notificationStrategy, onChange }: Props) => {
  const handlescheduleAsChange = (value: ScheduleAs) =>
    onChange({
      scheduleAs: value,
      notificationStrategy,
    });

  const handleNotifyChange = (value: NotificationStrategy) =>
    onChange({
      scheduleAs,
      notificationStrategy: value,
    });

  return (
    <ul
      className={`
      cw-list-none cw-p-0 cw-m-0
      cw-flex cw-flex-row
      cw-space-x-6
`}
    >
      {scheduleAs !== undefined && (
        <li>
          <ScheduleAsOption
            enabled={enabled}
            onChange={handlescheduleAsChange}
            value={scheduleAs}
          />
        </li>
      )}
      {notificationStrategy !== undefined && (
        <li>
          <NotificationStrategyOption
            enabled={enabled}
            onChange={handleNotifyChange}
            value={notificationStrategy}
          />
        </li>
      )}
    </ul>
  );
};

export default Options;
