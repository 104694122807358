import { FlexRange } from "@clockwise/schema/v2";
import classNames from "classnames";
import React from "react";
import { DaysControl } from "./controls/DaysControl";
import { FlexibleControl } from "./controls/FlexibleControl";
import { PausedControl } from "./controls/PausedControl";
import { PaywallControll } from "./controls/PaywallControll";
import { RangeControl } from "./controls/RangeControl";
import { TimeRangeControl } from "./controls/TimeRangeControl";
import { DayOnOffMap, FlexibleStatusSimplified } from "./types";

type Props = {
  attendeeBoundEnd: string | null;
  attendeeBoundStart: string | null;
  flexible: boolean;
  flexibleEditable: boolean;
  flexibleDayOnOffMap: DayOnOffMap;
  flexibleEnd: string;
  flexibleRange: FlexRange;
  flexibleStart: string;
  flexibleStatus: FlexibleStatusSimplified;
  onChangeFlexible?: (flexible: boolean) => void;
  onChangeFlexibleDays: (dayOnOffMap: DayOnOffMap) => void;
  onChangeFlexibleRange: (flexibleRange: FlexRange) => void;
  onChangeFlexibleResume: () => void;
  onChangeFlexibleTimeRange: (start: string, end: string) => void;
  updating: boolean;
  suppressNoWorkHoursMessage?: boolean;
  noIcon?: boolean;
};

export const FlexSettingsModule = ({
  attendeeBoundEnd,
  attendeeBoundStart,
  flexible,
  flexibleDayOnOffMap,
  flexibleEditable,
  flexibleEnd,
  flexibleRange,
  flexibleStart,
  flexibleStatus,
  onChangeFlexible,
  onChangeFlexibleDays,
  onChangeFlexibleRange,
  onChangeFlexibleResume,
  onChangeFlexibleTimeRange,
  updating,
  noIcon,
  suppressNoWorkHoursMessage = false,
}: Props) => {
  const paused = flexibleStatus === "paused";
  const stuck = flexibleStatus === "stuck";
  const pinned = flexibleStatus === "pinned";
  const paywall = flexibleStatus === "paywall";

  const disabled = !!(!flexible || updating);

  const canUpdate = !paywall && !updating;
  const canResume = paused && !updating;

  const handleChangeFlexible = () => {
    if (canUpdate) {
      onChangeFlexible?.(!flexible);
    }
  };

  const handleChangeFlexibleRange = (newFlexibleRange: FlexRange) => {
    const rangeChanged = newFlexibleRange !== flexibleRange;
    if (canUpdate && rangeChanged) {
      onChangeFlexibleRange(newFlexibleRange);
    }
  };

  const handleChangeFlexibleTimeRange = (newStart: string, newEnd: string) => {
    const timeRangeChanged = newStart !== flexibleStart || newEnd !== flexibleEnd;
    if (canUpdate && timeRangeChanged) {
      onChangeFlexibleTimeRange(newStart, newEnd);
    }
  };

  const handleChangeFlexibleDays = (newDayOnOffMap: DayOnOffMap) => {
    const daysChanged = JSON.stringify(newDayOnOffMap) !== JSON.stringify(flexibleDayOnOffMap);
    if (canUpdate && daysChanged) {
      onChangeFlexibleDays(newDayOnOffMap);
    }
  };

  const handleResume = () => {
    if (canResume) {
      onChangeFlexibleResume();
    }
  };

  return (
    <div className={classNames("cw-body-base", "cw-flex cw-flex-col cw-gap-2")}>
      {onChangeFlexible && (
        <FlexibleControl
          flexible={flexible}
          disabled={!flexibleEditable}
          onChange={handleChangeFlexible}
          pinned={pinned}
          status={flexibleStatus}
          updating={updating}
          noIcon={noIcon}
        />
      )}
      {flexible && paused ? (
        <PausedControl onResume={handleResume} />
      ) : paywall ? (
        <PaywallControll />
      ) : flexibleEditable ? (
        <>
          <RangeControl
            disabled={disabled || !flexibleEditable}
            range={flexibleRange}
            onChange={handleChangeFlexibleRange}
          />
          {flexible && flexibleStart && flexibleEnd && (
            <TimeRangeControl
              disabled={disabled}
              end={flexibleEnd}
              endBound={attendeeBoundEnd}
              onChange={handleChangeFlexibleTimeRange}
              start={flexibleStart}
              startBound={attendeeBoundStart}
              stuck={stuck}
              suppressNoWorkHoursMessage={suppressNoWorkHoursMessage}
            />
          )}
          {flexible &&
            (flexibleRange === FlexRange.Week || flexibleRange === FlexRange.SpecificDays) && (
              <DaysControl
                dayOnOffMap={flexibleDayOnOffMap}
                disabled={disabled}
                onChange={handleChangeFlexibleDays}
              />
            )}
        </>
      ) : null}
    </div>
  );
};
