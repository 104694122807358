import { useQuery as _useQuery } from "@apollo/client";
import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { logger as _logger } from "@clockwise/client-commons/src/util/logger";
import { ConferencingType, EventPermissions, ResponseStatusEnum } from "@clockwise/schema";
import { apolloErrorLogger } from "@clockwise/web-commons/src/util/apolloErrorLogger";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import {
  EventColorCategory,
  useCalendarColors,
} from "@clockwise/web-commons/src/util/event-category-coloring";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { cloneDeep, find, isEmpty } from "lodash";
import { DateTime } from "luxon";
import { Tags, getUserTagValueOffEvent } from "../../../util/event-tag.util";
import { EventVisibility } from "../../event-card/atoms/SelectEventVisibility";
import { Transparency } from "../../event-card/atoms/SelectTransparency";
import {
  EMPTY_FLEX_DETAILS,
  EventCardAttendee,
  EventCardFlex,
  SelectedWorkdayOption,
} from "../../event-card/types";
import { toECTransparency } from "../../event-card/utils/toECTransparency";
import { toECVisibility } from "../../event-card/utils/toECVisibility";
import { useUserProfile } from "../../hooks/useUserProfile";
import { PlannerEventByIdDocument } from "../apollo/__generated__/PlannerEvent.generated";
import { getAttendeeResponseStatusFromEvent } from "../util/getAttendeeResponseStatusFromEvent";

export type CategoryOption = {
  category: string;
  color?: string;
  label: string;
};

export type EventDetails = {
  id?: string;
  calendarId: string | null;
  attendeePeople: EventCardAttendee[];
  description?: string;
  attendeesOmitted?: boolean;
  descriptionOmitted?: boolean;
  eventPermissions: EventPermissions;
  isCancelled?: boolean;
  flexDetails: EventCardFlex;
  isFlexible?: boolean;
  isValid?: boolean;
  location?: string;
  responseStatus?: ResponseStatusEnum;
  title?: string;
  videoLink?: string;
  videoType?: ConferencingType;
  startISO?: string;
  endISO?: string;
  organizerCalId?: string;
  recurrenceRule?: RecurrenceRule | null;
  category?: EventColorCategory;
  locked?: boolean;
  transparency?: Transparency;
  visibility?: EventVisibility;
};

export const useEventDetails = (
  externalEventId: string,
  calendarId: string | null,
  options?: {
    logger?: typeof _logger;
    useQuery?: typeof _useQuery;
  },
) => {
  const logger = options?.logger ?? _logger;
  const useQuery = options?.useQuery ?? _useQuery;

  const skip = !calendarId || !externalEventId;
  const { getCurrentSettings } = useCalendarColors();
  const { userProfile } = useUserProfile();
  const ecosystem = useEcosystem();

  const { data, loading, error } = useQuery(PlannerEventByIdDocument, {
    skip,
    fetchPolicy: "network-only",
    variables: {
      externalEventId,
      calendarId: calendarId || "",
      typedCalendarId: calendarId || "",
    },
  });

  const eventDetails: EventDetails = {
    id: undefined,
    calendarId,
    attendeePeople: [],
    startISO: undefined,
    endISO: undefined,
    description: undefined,
    flexDetails: cloneDeep(EMPTY_FLEX_DETAILS),
    isCancelled: undefined,
    isFlexible: undefined,
    isValid: undefined,
    eventPermissions: EventPermissions.NONE,
    responseStatus: undefined,
    recurrenceRule: undefined,
    title: undefined,
    organizerCalId: undefined,
    category: undefined,
    locked: undefined,
    transparency: undefined,
    visibility: undefined,
  };

  if (skip) {
    return { eventDetails, loading, error };
  }

  if (error) {
    apolloErrorLogger({
      error,
      errorContext: { externalEventId, calendarId },
      errorMessagePrefix: "Error fetching event details",
      logger,
    });
    return { eventDetails, loading, error };
  }

  const dataOrg = getValue(data?.viewer.user?.orgs.edges?.[0]?.node, "Org");
  const colorSettings: CategoryOption[] = getCurrentSettings(
    dataOrg?.eventColoringSettingsErrorable,
  ).map((setting) => {
    return {
      category: setting.eventCategory,
      color: setting.renderColor,
      label: setting.labels.label,
    };
  });
  const eventParent = getValue(dataOrg?.forceFetchEventParentErrorable);
  const events = eventParent?.events;
  const event = find(events, (event) => event?.eventKey?.externalEventId === externalEventId);

  if (!loading) {
    eventDetails.isValid = !isEmpty(event) && event.__typename === "Event";
  }

  if (event && event.__typename === "Event") {
    eventDetails.responseStatus = getAttendeeResponseStatusFromEvent(event, calendarId);

    if (eventParent?.id) {
      eventDetails.id = eventParent?.id;
    }

    if (eventParent?.flexibility) {
      eventDetails.flexDetails.isFlexible =
        eventParent?.flexibility.__typename === "EventFlexibility";
      if (eventParent?.flexibility.__typename === "EventFlexibility") {
        const timeOfDayFlexibility = eventParent.flexibility.timeOfDayFlexibility;
        eventDetails.flexDetails.timeOfDayFlexibility.startTime =
          timeOfDayFlexibility?.start || (null as string | null);
        eventDetails.flexDetails.timeOfDayFlexibility.endTime =
          timeOfDayFlexibility?.end || (null as string | null);

        const timeRangeFlexibility = eventParent.flexibility.timeRangeFlexibility;
        if (timeRangeFlexibility.__typename === "RangeFlexibility") {
          eventDetails.flexDetails.timeRangeFlexibility.range = timeRangeFlexibility.range;
        } else {
          eventDetails.flexDetails.timeRangeFlexibility.daysOfWeekFlexibility = timeRangeFlexibility.allowedDays as SelectedWorkdayOption[];
          eventDetails.flexDetails.timeRangeFlexibility.range = null;
        }
      }
    }

    eventDetails.eventPermissions = event.eventPermissions;

    if (event.attendees && event.organizerPerson) {
      const organizerCalendar = getValue(event.organizerPerson)?.primaryCalendar;
      const attendeePeople = getValue(event.attendeePeople)?.list || [];
      eventDetails.attendeePeople = attendeePeople.map((attendeePerson) => {
        const attendeeAlignedWithPerson = event.attendees.find((attendee) => {
          return attendee.urnValue === attendeePerson.primaryCalendar;
        });

        return {
          ...attendeePerson,
          isOrganizer: attendeePerson.primaryCalendar === organizerCalendar,
          attendingStatus: attendeeAlignedWithPerson?.responseStatus || undefined,
          isOptional: attendeeAlignedWithPerson?.optional ?? undefined,
        };
      });

      eventDetails.organizerCalId = organizerCalendar;
    }

    if (event.title) {
      eventDetails.title = event.title;
    }

    if (event.attendeesOmitted) {
      eventDetails.attendeesOmitted = event.attendeesOmitted;
    }

    if (event.descriptionOmitted) {
      eventDetails.descriptionOmitted = event.descriptionOmitted;
    }

    if (event.description) {
      eventDetails.description = event.description;
    }

    if (event.isCancelled) {
      eventDetails.isCancelled = event.isCancelled;
    }

    if (event.location) {
      eventDetails.location = event.location;
    }

    if (event.videoLink) {
      eventDetails.videoLink = event.videoLink.uri;
      eventDetails.videoType = event.videoLink.type;
    }

    if (event.startTime) {
      eventDetails.startISO = DateTime.fromISO(event.startTime.dateOrDateTime, {
        zone: getRenderTimeZone(),
      }).toISO();
    }

    if (event.endTime) {
      eventDetails.endISO = DateTime.fromISO(event.endTime.dateOrDateTime, {
        zone: getRenderTimeZone(),
      }).toISO();
    }

    if (event.annotatedEvent) {
      eventDetails.category = getUserTagValueOffEvent(
        event,
        Tags.EventColoringCategory,
        userProfile.userId,
      )?.value as EventColorCategory;
    }

    if (event.recurrenceRule !== undefined) {
      eventDetails.recurrenceRule = event.recurrenceRule
        ? new RecurrenceRule(event.recurrenceRule)
        : null;
    }

    if (event.locked !== null) {
      eventDetails.locked = event.locked;
    }

    // Set transparency and visibility, using defaults if the BE returns null
    eventDetails.transparency = toECTransparency(event.transparency, ecosystem);
    eventDetails.visibility = toECVisibility(event.visibility);
  }

  return {
    eventDetails,
    colorSettings,
    loading,
    error,
    orgId: dataOrg?.id,
  };
};

export default useEventDetails;
