import { EventLogType } from "@clockwise/schema/v2";
import React from "react";

export const DefragInfoCard = ({
  details,
  eventLogType,
  userName,
}: {
  details: string[];
  eventLogType: EventLogType;
  userName: string;
}) => {
  return (
    <div className="cw-bg-neutral cw-rounded-lg cw-px-2 cw-py-1 cw-my-2">
      {details.map((detail) => (
        <div className="cw-caption cw-text-default" key={detail}>
          {eventLogType === EventLogType.ClockwiseEventRescheduled
            ? `Moved to schedule "${detail}"`
            : eventLogType === EventLogType.ClockwiseSecondaryReschedule
            ? `Moved to schedule ${userName}’s meeting`
            : detail}
        </div>
      ))}
    </div>
  );
};
