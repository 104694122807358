import {
  AutomateTask,
  ConnectCalendar,
  FlexMeeting,
  FlexMeetingChecklist,
  HoldsExpanded,
  IdealDay,
  IdealDayExpanded,
  NextOptimization,
} from "../assets";

import { NUXChecklistVariant } from "./NUXChecklist.util";
interface Copy {
  title: string;
  text: string | null;
  icon: string;
  expandedImage?: string;
}

export const ChecklistCopy: Record<NUXChecklistVariant, Copy> = {
  ConnectCalendar: {
    title: "Connect your calendar",
    text: null,
    icon: ConnectCalendar,
  },
  FlexMeeting: {
    title: "Make your meetings flexible",
    text:
      "Set your meetings as flexible and enjoy the benefits of a calendar that adapts to your needs.",
    icon: FlexMeeting,
    expandedImage: FlexMeetingChecklist,
  },
  IdealDay: {
    title: "Customize your ideal day",
    text:
      "Clockwise will make sure you have time for focused work, lunch, personal appointments, and more.",
    icon: IdealDay,
    expandedImage: IdealDayExpanded,
  },
  Holds: {
    title: "Automate your tasks and routines",
    text:
      "Clockwise will automatically shift your calendar to help you make time for important tasks and routines.",
    icon: AutomateTask,
    expandedImage: HoldsExpanded,
  },
  NextOptimization: {
    title: "Your next optimization is in ",
    text: null,
    icon: NextOptimization,
  },
  ScheduledMeeting: {
    title: "Schedule your first event",
    text: null,
    icon: ConnectCalendar,
  },
};
