import { Loader } from "@clockwise/design-system/src/components/Loader";
import { isEmpty } from "lodash";
import { Maybe } from "purify-ts";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Calendar } from "../chat-plus-calendar/Calendar";
import { CurrentProposalProvider } from "../chat-plus-calendar/CurrentProposalContext";
import { NoRouteView } from "../no-route-view";
import { PlannerProvider } from "../web-app-calendar/Context";
import usePlannerMetaData from "../web-app-calendar/hooks/usePlannerMetaData";
import Context from "./Context";
import SharedProposal from "./SharedProposal";
import { SharedProposalCalendarDateSync } from "./SharedProposalCalendarDateSync";
import { useSetProposalError } from "./hooks/ProposalErrorContext";
import { useSharedProposalContext } from "./hooks/SharedProposalProvider";

export const ProposalLayout = () => {
  const [notFound, setNotFound] = React.useState(false);
  const {
    primaryCalendarId,
    loading: plannerMetaDataLoading,
    colorSettings,
    workingHourBounds,
    workingHours,
  } = usePlannerMetaData();
  const setError = useSetProposalError();
  const {
    ownerCalendarId,
    hasEditPermission,
    viewerIsProposalOwner,
    proposal,
    proposalLoading,
    errorMessage,
    setUpdatedProposal,
  } = useSharedProposalContext();
  const attendeePeople = Maybe.fromNullable(proposal)
    .map((proposal) => proposal.diffBlocks)
    .map((diffBlocks) => diffBlocks[0].diffs[0])
    .map((diff) => diff.attendees)
    .map((attendees) => attendees.proposalAttendees)
    .map((pas) => pas.map((pa) => pa.attendeePerson))
    .orDefault([]);
  const attendeesPrimaryCalendarIds = attendeePeople.map((person) => person.primaryCalendar);
  const userCanConfirmProposal = attendeesPrimaryCalendarIds.includes(primaryCalendarId);
  const render404 =
    notFound ||
    (proposal && !userCanConfirmProposal) ||
    (!hasEditPermission && !viewerIsProposalOwner);

  useEffect(() => {
    if (!isEmpty(errorMessage)) {
      if (errorMessage?.includes("404")) {
        setNotFound(true);
      } else {
        setError({
          error: new Error(errorMessage ?? "Error fetching proposal"),
          message:
            "Oops! Something went wrong while fetching the proposal. Please try again later.",
          proposalId: proposal?.proposalId || null,
        });
      }
    }
  }, [errorMessage]);

  if (render404) {
    const userErrorMessage = notFound
      ? "Sorry, proposal is not found."
      : "Sorry, you don't have access to this proposal.";

    toast.error(userErrorMessage, {
      position: "bottom-center",
    });

    return <NoRouteView />;
  }

  if (proposalLoading || plannerMetaDataLoading || !primaryCalendarId || !proposal) {
    return <Loader size="xl" sentiment="positive" className="cw-mt-auto cw-mb-auto" />;
  }

  return (
    <Context>
      <SharedProposalCalendarDateSync />
      <CurrentProposalProvider>
        <PlannerProvider
          userCalendarIds={attendeesPrimaryCalendarIds}
          colorSettings={colorSettings}
          workingHourBounds={workingHourBounds}
          workingHours={workingHours}
          primaryCalendarId={primaryCalendarId}
        >
          <div
            cw-id="proposalLayout"
            className="cw-flex cw-flex-row cw-grow cw-justify-items-end cw-w-full cw-h-full"
          >
            <div className="cw-self-end cw-h-full cw-flex lg:cw-w-[420px] cw-w-full  cw-border-r cw-border-subtle cw-border-solid">
              <SharedProposal
                setUpdatedProposal={setUpdatedProposal}
                sharedProposal={proposal}
                ownerCalendarId={ownerCalendarId}
              />
            </div>
            <div className="cw-grow cw-flex-1 cw-overflow-hidden">
              <div className="lg:cw-flex cw-hidden lg:cw-w-full cw-transition-all cw-h-full">
                <Calendar setInitialDateAndMode={false} />
              </div>
            </div>
          </div>
        </PlannerProvider>
      </CurrentProposalProvider>
    </Context>
  );
};
