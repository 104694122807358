import { TradeoffTypeEnum } from "@clockwise/schema";
import { Portal, Transition } from "@headlessui/react";
import classNames from "classnames";
import { isEmpty, uniqBy } from "lodash";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import {
  AvailabilityIssue,
  FixableConflict,
  Inconveniences,
  NoIssues,
  TradeoffHeader,
  WorksForEveryone,
} from "./Tradeoffs";
import { Tradeoff } from "./types";
const POPOVER_WIDTH = 250;

export const TradeoffsPopover = ({
  tradeoffs,
  side,
  totalAttendeeCount,
  anchorEl,
  headerText,
  showAttendeeCount = true,
}: {
  tradeoffs: Tradeoff[];
  side: "left" | "right" | "bottom";
  totalAttendeeCount: number;
  anchorEl: React.RefObject<HTMLDivElement>;
  headerText?: string;
  showAttendeeCount?: boolean;
}) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(anchorEl.current, container, {
    placement: side,
    strategy: "fixed",
    modifiers: [
      {
        name: "flip",
        enabled: true,
      },
      { name: "offset", options: { offset: [0, 2] } },
    ],
  });

  // Get all the tradeoffs that are of type "AVAILABILITY_ISSUE" (there can be several)
  const conflictTradeoffs = tradeoffs.filter(
    (tradeoff) => tradeoff.tradeoffType === TradeoffTypeEnum.AVAILABILITY_ISSUE,
  );

  // If there are multiple availability issues, merge them into one
  const mergedConflictTradeoffs = [];

  // We have to create a new tradeoffs object, especially to compute a new list of deduped attendees
  if (conflictTradeoffs.length > 0) {
    mergedConflictTradeoffs.push({
      __typename: "TradeoffBlock",
      id: conflictTradeoffs.flatMap((tradeoff) => tradeoff.id).join("-"), // Mock ID to satisfy Typescript, Apollo doesn't use this.
      title: "All availability issues grouped together",
      tradeoffType: TradeoffTypeEnum.AVAILABILITY_ISSUE,
      schedulingTradeoffs: conflictTradeoffs.flatMap((tradeoff) => tradeoff.schedulingTradeoffs),
      aggregateAttendees: uniqBy(
        conflictTradeoffs.flatMap((tradeoff) => tradeoff.aggregateAttendees),
        "primaryCalendarId",
      ),
    });
  }

  const filteredTradeoffs = [
    ...mergedConflictTradeoffs,
    ...tradeoffs.filter((tradeoff) => tradeoff.tradeoffType === TradeoffTypeEnum.FIXABLE_CONFLICT),
    ...tradeoffs.filter((tradeoff) => tradeoff.tradeoffType === TradeoffTypeEnum.INCONVENIENCE),
  ];

  const uniqueTradeoffAttendees = new Set(
    [...filteredTradeoffs].flatMap((tradeoff) =>
      tradeoff.aggregateAttendees.map((attendee) => attendee.primaryCalendarId),
    ),
  );

  const showWorksForEveryone = isEmpty(filteredTradeoffs);
  const showRemaningNoIssues =
    !showWorksForEveryone && totalAttendeeCount > uniqueTradeoffAttendees.size;
  const width = showWorksForEveryone && !headerText ? 160 : POPOVER_WIDTH;

  return (
    <Portal>
      <Transition
        show={true}
        leave="cw-transition cw-ease-in cw-duration-100"
        leaveFrom="cw-opacity-100"
        leaveTo="cw-opacity-0"
        enter="cw-transition cw-ease-in cw-duration-75"
        enterFrom="cw-opacity-0"
        enterTo="cw-opacity-100"
      >
        <div
          role="combobox"
          ref={setContainer}
          style={{
            ...styles.popper,
            width,
          }}
          {...attributes.popper}
          className={classNames(
            "cw-px-3 cw-py-0.5 cw-shadow-md cw-rounded-lg cw-absolute cw-body-sm cw-bg-emphasis cw-hidden lg:cw-flex cw-z-[42] cw-pointer-events-none",
          )}
        >
          <div className="cw-flex-col cw-w-full">
            {headerText && (
              <div className=" cw-w-full cw-flex cw-border-b-[0.5px] cw-border-solid cw-border-neutral-hover cw-py-2.5">
                <TradeoffHeader text={headerText} />
              </div>
            )}
            {showWorksForEveryone && <WorksForEveryone />}
            {filteredTradeoffs.map((tradeoff, index) => {
              const showBottomBorder = showRemaningNoIssues || index < filteredTradeoffs.length - 1;
              switch (tradeoff.tradeoffType) {
                case TradeoffTypeEnum.AVAILABILITY_ISSUE:
                  return (
                    <AvailabilityIssue
                      attendeeCount={tradeoff.aggregateAttendees.length}
                      schedulingTradeoffs={tradeoff.schedulingTradeoffs}
                      showBottomBorder={showBottomBorder}
                      key={tradeoff.id}
                      showAttendeeCount={showAttendeeCount}
                    />
                  );
                case TradeoffTypeEnum.FIXABLE_CONFLICT:
                  return (
                    <FixableConflict
                      attendeeCount={tradeoff.aggregateAttendees.length}
                      schedulingTradeoffs={tradeoff.schedulingTradeoffs}
                      showBottomBorder={showBottomBorder}
                      key={tradeoff.id}
                      showAttendeeCount={showAttendeeCount}
                    />
                  );
                case TradeoffTypeEnum.INCONVENIENCE:
                  return (
                    <Inconveniences
                      attendeeCount={tradeoff.aggregateAttendees.length}
                      schedulingTradeoffs={tradeoff.schedulingTradeoffs}
                      showBottomBorder={showBottomBorder}
                      showAttendeeCount={showAttendeeCount}
                      key={tradeoff.id}
                    />
                  );
                default:
                  return null;
              }
            })}
            {showRemaningNoIssues && (
              <NoIssues
                attendeeCount={totalAttendeeCount - uniqueTradeoffAttendees.size}
                showAttendeeCount={showAttendeeCount}
              />
            )}
          </div>
        </div>
      </Transition>
    </Portal>
  );
};
