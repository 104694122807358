import classNames from "classnames";
import React from "react";

export const MessageBlock = ({ message }: { message: string }) => {
  return (
    <div
      className={classNames(
        "cw-flex cw-items-center cw-gap-1",
        "cw-p-2",
        "cw-text-muted cw-body-sm cw-text-12",
        "cw-bg-neutral cw-rounded-lg",
        "cw-border cw-border-subtle cw-border-solid",
      )}
    >
      <div>{message}</div>
    </div>
  );
};
