import React from "react";
import { Header, SlideLayout, Text } from "../../shared-components";
import lunchImage from "./assets/lunch.svg";

const Image = () => {
  return <img src={lunchImage} className="cw-w-full md:cw-h-[475px] cw-mt-3" />;
};

export const LunchSlide = () => {
  return (
    <SlideLayout>
      <Header>And never miss lunch again</Header>
      <Text>
        Clockwise can dynamically block time for lunch, adjusting its time and duration as new
        events appear.
      </Text>
      <Image />
    </SlideLayout>
  );
};
