//////////////////
// IMPORTS
//////////////////
// libraries
import * as React from "react";
import { connect } from "react-redux";
// material-ui
import { Button, ThemeProvider, Typography } from "@clockwise/design-system";
import { withStyles } from "@material-ui/core/styles";
// error-page
import { styles } from "./error-page.styles";
import { IContainer, IProps, IState } from "./error-pageTypes";
// util
import { windowLocation } from "#webapp/src/util/location.util";
// state
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";

//////////////////
// COMPONENT
//////////////////
class ErrorPageComponent extends React.PureComponent<IProps, IState> {
  private reload = () => {
    windowLocation.reload("ErrorPage");
  };

  public render() {
    return (
      <ThemeProvider>
        <div>
          <div className={this.props.classes.header} />
          <div className={this.props.classes.content}>
            <Typography variant="h6" className={this.props.classes.upperMargin}>
              Oops! Something went wrong.
            </Typography>
            <Typography variant="body1" className={this.props.classes.upperMargin}>
              We are having some technical difficulties.
              <br />
              Please use the button below to reload Clockwise.
            </Typography>
          </div>
          <div className={this.props.classes.actions}>
            <Button variant="solid" sentiment="positive" onClick={() => this.reload()}>
              Reload
            </Button>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

//////////////////
// WITH STYLES
//////////////////

const ErrorPageStyled = withStyles(styles)(ErrorPageComponent);

//////////////////
// REDUX
//////////////////
function mapStateToProps(state: IReduxState, _ownProps: IContainer) {
  return {
    isChromeExtension: state.webExtension.isWebExtension,
  };
}

export const ErrorPage = connect(mapStateToProps, {})(ErrorPageStyled);
