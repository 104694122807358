//////////////////
// IMPORTS
//////////////////

// libraries
import React, { useCallback } from "react";

// queries, mutations, and types.

// components
import { RouteRenderer } from "#webapp/src/components/route-renderer";
import {
  ConfirmationType,
  SchedulingConfirmation,
} from "@clockwise/web-commons/src/ui/scheduling-confirmation";
import { BannerVariants } from "./BannerVariants";

// utils
import { SchedulingPriorityEnum } from "@clockwise/schema/v2";
import { hasNotFoundError } from "@clockwise/web-commons/src/network/apollo/errors";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { DateTime, Interval } from "luxon";
import { useParams } from "react-router-dom";
import {
  CancelLinkBookingDocument,
  LinkCancelDetailsDocument,
} from "./__generated__/CancelBooking.v2.generated";

export const CancelBookingRendererGateway = ({
  onClickMyLinks,
  onEditLink,
}: {
  onClickMyLinks: () => void;
  onEditLink: (linkName: string, slug: string) => void;
}) => {
  const { bookingId = "" } = useParams();

  const [bookingCancelled, setBookingCancelled] = React.useState(false);
  const [cancelReason, setCancelReason] = React.useState("");

  const { loading, data, error } = useGatewayQuery(LinkCancelDetailsDocument, {
    variables: {
      bookingId,
    },
  });

  const [cancelBooking] = useGatewayMutation(CancelLinkBookingDocument, {
    fetchPolicy: "no-cache", // No need to refetch.
    onCompleted: () => {
      setBookingCancelled(true);
    },
  });

  const handleCancelBooking = useCallback(
    async (reason?: string) => {
      await cancelBooking({
        variables: {
          bookingId,
          reason,
        },
      });
      if (reason) {
        setCancelReason(reason);
      }
    },
    [cancelBooking, bookingId, setCancelReason],
  );

  const bookingData = data?.linkBooking;
  const linkData = data?.linkBooking?.publicSchedulingLink;
  const bookingLinkName = linkData?.linkName;
  const slug = linkData?.slug;
  const meetingSettingsId = linkData?.id;

  const isLoaded = !!linkData && !!bookingData;

  const isOwner = !!linkData?.ownedByMe;
  const isMember = !!linkData?.managedByMe;
  const showOwnerBanner = isMember || isOwner;

  return (
    <RouteRenderer
      loading={loading}
      loadingMessage="Manage booking"
      notFound={!loading && error && hasNotFoundError(error)}
      error={!!error || (!loading && !isLoaded)}
    >
      {isLoaded && (
        <SchedulingConfirmation
          id={meetingSettingsId}
          meetingName={linkData.name}
          logoUrl={linkData.linkLogoUrl}
          duration={bookingData.duration}
          locationType={linkData.location}
          description={linkData.description}
          linkName={bookingLinkName}
          slug={slug}
          showOwnerBanner={showOwnerBanner}
          linkMembers={linkData.linkMembers.filter(
            (lm) => lm.schedulingPriority !== SchedulingPriorityEnum.DoNotSchedule,
          )}
          attendees={bookingData.attendees}
          bookedSlot={{
            bookedDateOrDateTime: Interval.fromISO(bookingData.timeSlot).start.toISO(),
            bookedTimeZone: DateTime.local().zoneName,
            bookedLinkMembersAsCalendarIds: [],
          }}
          onCancel={handleCancelBooking}
          completed={bookingCancelled}
          variant={ConfirmationType.Cancel}
          cancelRescheduleReason={cancelReason}
          banner={<BannerVariants isAuthenticatedUser={!!data.currentUser?.id} />}
          onClickMyLinks={onClickMyLinks}
          onEditLink={() => onEditLink(bookingLinkName ?? "", slug ?? "")}
        />
      )}
    </RouteRenderer>
  );
};
