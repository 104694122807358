import { TimeSlot } from "@clockwise/schema";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { Duration } from "luxon";
import React from "react";
import useLunch from "../../../account-preferences-lunch-hold/hooks/useLunch";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { ButtonGroup, StepCounter, StepLayout } from "../../shared-components";
import { setError } from "../../utils/setError";
import { LunchSlide } from "./LunchSlide";

const DEFAULT_LUNCH_SETTINGS = {
  enabled: true,
  latestEnd: TimeSlot.T_13_00,
  earliestStart: TimeSlot.T_12_00,
  minimumDuration: Duration.fromObject({ minute: 30 }),
  idealDuration: Duration.fromObject({ minute: 60 }),
};

export const LunchStep = () => {
  const track = useTracking();
  const { goForward, goBack, funnelType } = useWebOnboardingSteps();

  const onSkip = () => {
    track(TrackingEvents.WEB_ONBOARDING.LUNCH_STEP_SKIPPED, { funnelType });
    goForward();
  };

  const { update: updateLunch } = useLunch({
    onSuccess: () => {
      track(TrackingEvents.WEB_ONBOARDING.LUNCH_STEP_ENABLED, { funnelType });
    },
    onFail: (error) => {
      setError({
        error: error,
        message: "Failed to enable lunch",
        showUserMessage: true,
      });
    },
  });

  const enableLunch = () => {
    void updateLunch(DEFAULT_LUNCH_SETTINGS);
    goForward();
  };

  return (
    <StepLayout>
      <StepCounter />
      <LunchSlide />
      <ButtonGroup
        shouldShowSkip
        shouldShowSecondary={true}
        primaryLabel="Enable Lunch"
        skipLabel="Set up later"
        onClickPrimary={enableLunch}
        onClickSecondary={goBack}
        onSkip={onSkip}
      />
    </StepLayout>
  );
};
