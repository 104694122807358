import { useReadCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import classNames from "classnames";
import React from "react";
import { ChatPlusCalendarTimeSuggestionSync } from "../chat/ai-chat/ChatPlusCalendarTimeSuggestionSync";
import ErrorBoundary from "../error-boundary/ErrorBoundary";
import { SharedProposalTimeSuggestionSync } from "../shareable-proposal/SharedProposalTimeSuggestionSync";
import { useSharedProposalContext } from "../shareable-proposal/hooks/SharedProposalProvider";
import { usePlannerContext } from "../web-app-calendar/Context";
import { CalendarSingleDay } from "../web-app-calendar/calendar-single-day/CalendarSingleDay";
import { HorizontalScrollSyncProvider } from "../web-app-calendar/calendar-single-day/HorizonalScrollSyncContext";
import { CalendarWeek } from "../web-app-calendar/calendar-week/CalendarWeek";
import { CalendarColorsSync } from "../web-app-calendar/hooks/CalendarColorsSync";
import usePlannerMetaData from "../web-app-calendar/hooks/usePlannerMetaData";
import { usePersistCalendarHash } from "../web-app-calendar/planner-state-persistance/hooks/usePersistCalendarHash";
import { PlannerToolbar } from "../web-app-calendar/planner-toolbar/PlannerToolbar";
import { DismissedConflictClusterProvider } from "./util/DismissedConflictClusterContext";

type CalendarProps = {
  setInitialDateAndMode?: boolean;
};

export const Calendar = ({ setInitialDateAndMode = true }: CalendarProps) => {
  usePersistCalendarHash({ setInitialDateAndMode });

  const { loading, error } = usePlannerMetaData();
  const { isResizingAnyEvent } = usePlannerContext();
  const { proposal: sharedProposal } = useSharedProposalContext();

  // If on flag and single day, render new view
  const calendarState = useReadCalendar();

  const singleDay = calendarState?.visibleDates.length === 1;

  if (loading) {
    return null;
  }

  if (error) {
    return <div>error</div>;
  }

  return (
    <DismissedConflictClusterProvider initValue={[]}>
      <div
        className={classNames("cw-w-full cw-h-full cw-overflow-hidden cw-mr-[1px]", {
          "cw-cursor-ns-resize": isResizingAnyEvent,
        })}
      >
        <PlannerToolbar />
        <CalendarColorsSync />
        {sharedProposal ? (
          <SharedProposalTimeSuggestionSync />
        ) : (
          <ChatPlusCalendarTimeSuggestionSync />
        )}
        <ErrorBoundary>
          {singleDay ? (
            <HorizontalScrollSyncProvider>
              <CalendarSingleDay />
            </HorizontalScrollSyncProvider>
          ) : (
            <CalendarWeek />
          )}
        </ErrorBoundary>
      </div>
    </DismissedConflictClusterProvider>
  );
};
