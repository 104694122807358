//////////////////
// IMPORTS
//////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";
// libraries
import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
// slack-account imports
import * as s from "./SlackAccount.styles";
import {
  IProps,
  TAutopilotUpdates,
  TDailyDigest,
  TDoNotDisturb,
  TSlackSync,
} from "./SlackAccountTypes";
import { PatchSlackSettingsMutationDocument } from "./__generated__/PatchSlackSettings.generated";
import { UpdateSlackAccountMutationDocument } from "./__generated__/UpdateSlackAccount.generated";
// util
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
// ui
import { CalloutBox } from "#webapp/src/components/callout-box";
import { SlackButton } from "#webapp/src/components/slack-button";
// material-ui
import { Check, Delete, Warning } from "@clockwise/design-system/icons";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl } from "@material-ui/core";
// styles
import { H1, P } from "#webapp/src/components/ui";
import { TranslationContext } from "@clockwise/client-commons/src/providers/TranslationContext";
import { Button, ButtonSet, Select, SelectOption } from "@clockwise/design-system";
import { greys } from "@clockwise/web-commons/src/styles/color.styles";
import toast from "react-hot-toast";

//////////////////
// COMPONENT
//////////////////
export const SlackAccount = (props: IProps) => {
  const mode = props.mode || "standard";

  const t = useContext(TranslationContext);

  const getSyncFromProps = (props: IProps) => {
    let sync = "NoSync";
    if (props.slackSettings && props.slackSettings.calendarStatus) {
      if (props.slackSettings.calendarStatus.includeMeetingTitle) {
        sync = "SyncTitles";
      } else if (
        props.slackSettings.calendarStatus.includeMeetingCategory ||
        props.slackSettings.calendarStatus.calendarStatusEnabled
      ) {
        sync = "SyncCategories";
      }
    }
    return sync as TSlackSync;
  };

  const getDailyDigestFromProps = (props: IProps) => {
    const { slackAccount, slackSettings } = props;
    const { needsUpdate } = slackAccount;
    const { dailyDigestSettings } = slackSettings;
    if (needsUpdate || !dailyDigestSettings || !dailyDigestSettings.enabled) {
      return "Off";
    }

    return dailyDigestSettings.timeSlot as TDailyDigest;
  };

  const getDoNotDisturbFromProps = (props: IProps) => {
    const { slackAccount, slackSettings } = props;
    const { needsUpdate } = slackAccount;
    const { dndSettings } = slackSettings;
    if (needsUpdate || !dndSettings) {
      return "Off";
    }

    if (dndSettings.focusTime && dndSettings.meeting) {
      return "MeetingFocusTime" as TDoNotDisturb;
    } else if (dndSettings.focusTime) {
      return "FocusTime" as TDoNotDisturb;
    } else if (dndSettings.meeting) {
      return "Meeting" as TDoNotDisturb;
    }

    return "Off" as TDoNotDisturb;
  };

  const getAutopilotUpdatesFromProps = (props: IProps) => {
    const { slackAccount, slackSettings } = props;
    const { needsUpdate } = slackAccount;
    const { notificationSettings } = slackSettings;
    if (needsUpdate || !notificationSettings || !notificationSettings.defragSummary) {
      return "No" as TAutopilotUpdates;
    }

    return "Yes" as TAutopilotUpdates;
  };

  const [sync, setSync] = useState(getSyncFromProps(props));
  const [dailyDigest, setDailyDigest] = useState(getDailyDigestFromProps(props));
  const [dnd, setDnd] = useState(getDoNotDisturbFromProps(props));
  const [autopilotUpdates, setAutopilotUpdates] = useState(getAutopilotUpdatesFromProps(props));
  const [dialogOpen, setDialogOpen] = useState(false);

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-
  useEffect(() => {
    setSync(getSyncFromProps(props));
    setDailyDigest(getDailyDigestFromProps(props));
    setDnd(getDoNotDisturbFromProps(props));
    setAutopilotUpdates(getAutopilotUpdatesFromProps(props));
  }, [props]);

  // ~-~-~-~-~-~-~-
  // Mutations
  // ~-~-~-~-~-~-~-
  const [updateSlackAccount] = useMutation(UpdateSlackAccountMutationDocument, {
    onCompleted: () => {
      toast.success("Your Slack account was removed");
      props.onDelete();
    },
    onError: () => {
      toast.error("Sorry, there was a problem removing your Slack account");
    },
  });

  const [patchSlackSync] = useMutation(PatchSlackSettingsMutationDocument, {
    onCompleted: () => {
      onSettingChangeSuccess();
    },
    onError: (_error) => {
      toast.error("Sorry, there was a problem updating your Slack settings, please try again.");
      // setSync(oldValue);
    },
  });

  const [patchSlackDailyDigest] = useMutation(PatchSlackSettingsMutationDocument, {
    onCompleted: () => {
      onSettingChangeSuccess();
    },
    onError: (_error) => {
      toast.error("Sorry, there was a problem updating your Slack settings, please try again.");
      // setDailyDigest(oldValue);
    },
  });

  const [patchSlackDnd] = useMutation(PatchSlackSettingsMutationDocument, {
    onCompleted: () => {
      onSettingChangeSuccess();
    },
    onError: (_error) => {
      toast.error("Sorry, there was a problem updating your Slack settings, please try again.");
      // setDnd(oldValue);
    },
  });

  const [patchSlackNotification] = useMutation(PatchSlackSettingsMutationDocument, {
    onCompleted: () => {
      onSettingChangeSuccess();
    },
    onError: (_error) => {
      toast.error("Sorry, there was a problem updating your Slack settings, please try again.");
      // setAutopilotUpdates(oldValue);
    },
  });

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  const deleteAccount = () => {
    const slackAccountRelayId = props.slackAccount.id;
    const action = "Delete" as ISchema.SlackUpdateAction;

    void updateSlackAccount({
      variables: {
        input: { slackAccountRelayId, action },
      },
    });
  };

  const onSettingChangeSuccess = () => {
    // this causes a spastic refetch by our parent, lets not do that
    // this.props.onUpdate();
  };

  const onSyncChange = (value: TSlackSync) => {
    setSync(value);

    const oldValue = sync;
    track(TrackingEvents.SLACK.UPDATE_SYNC, {
      oldValue,
      newValue: value,
    });

    toast.success(
      "Updating your Slack preferences, it may take a few minutes for your status to update.",
    );

    const { slackSettings, org } = props;
    const newSlackSettings = { ...slackSettings };

    const calendarStatus = { ...slackSettings.calendarStatus };
    calendarStatus.calendarStatusEnabled = value !== "NoSync";
    calendarStatus.includeMeetingCategory = value === "SyncCategories" || value === "SyncTitles";
    calendarStatus.includeMeetingTitle = value === "SyncTitles";
    newSlackSettings.calendarStatus = calendarStatus;

    void patchSlackSync({
      variables: {
        input: { slackSettings: newSlackSettings, orgRelayId: org.id },
      },
    });
  };

  const onDailyDigestChange = (value: TDailyDigest) => {
    const oldValue = dailyDigest;
    track(TrackingEvents.SLACK.UPDATE_DAILY_DIGEST, {
      oldValue,
      newValue: value,
    });

    setDailyDigest(value);
    toast.success("Updating your Slack preferences for your daily forecast setting.");

    const { slackSettings, org } = props;
    const newSlackSettings = { ...slackSettings };

    const dailyDigestSettings = { ...slackSettings.dailyDigestSettings };
    dailyDigestSettings.enabled = value !== "Off";
    if (value !== "Off") {
      dailyDigestSettings.timeSlot = value as ISchema.DigestTimeSlot;
    }
    newSlackSettings.dailyDigestSettings = dailyDigestSettings;

    void patchSlackDailyDigest({
      variables: {
        input: { slackSettings: newSlackSettings, orgRelayId: org.id },
      },
    });
  };

  const onDoNotDisturbChange = (value: TDoNotDisturb) => {
    const oldValue = dnd;
    track(TrackingEvents.SLACK.UPDATE_DO_NOT_DISTURB, {
      oldValue,
      newValue: value,
    });

    setDnd(value);
    toast.success(
      "Updating your Slack preferences, it may take a few minutes for your Do Not Disturb settings to update.",
    );

    const { slackSettings, org } = props;
    const newSlackSettings = { ...slackSettings };

    const dndSettings = { ...slackSettings.dndSettings };
    dndSettings.focusTime = value === "FocusTime" || value === "MeetingFocusTime";
    dndSettings.meeting = value === "Meeting" || value === "MeetingFocusTime";
    newSlackSettings.dndSettings = dndSettings;

    void patchSlackDnd({
      variables: {
        input: { slackSettings: newSlackSettings, orgRelayId: org.id },
      },
    });
  };

  const onNotificationChange = (value: TAutopilotUpdates) => {
    const oldValue = autopilotUpdates;
    track(TrackingEvents.SLACK.UPDATE_NOTIFICATIONS, {
      oldValue,
      newValue: value,
    });

    setAutopilotUpdates(value);
    toast.success(t("settings:slack-autopilot-notification"));

    const { slackSettings, org } = props;
    const newSlackSettings = { ...slackSettings };

    const notificationSettings = { ...slackSettings.notificationSettings };
    notificationSettings.defragSummary = value === "Yes";
    newSlackSettings.notificationSettings = notificationSettings;

    void patchSlackNotification({
      variables: {
        input: { slackSettings: newSlackSettings, orgRelayId: org.id },
      },
    });
  };

  const onAuthPopupClosed = () => {
    toast.success("Your Slack account is updating...");
    props.onAuthPopupClosed();
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  const renderDeleteDialog = () => {
    return (
      <Dialog open={dialogOpen} PaperProps={{ style: { width: 450 } }}>
        <DialogTitle>
          <H1 style={{ fontSize: 22, marginBottom: 0, paddingBottom: 0 }}>
            Disconnect Slack Account?
          </H1>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <P
            style={{
              fontSize: 15,
              lineHeight: 1.8,
              color: greys.slate,
              marginBottom: 30,
            }}
          >
            This will end your Clockwise integration with Slack.
          </P>
        </DialogContent>
        <DialogActions>
          <ButtonSet>
            <Button variant="text" onClick={() => setDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteAccount();
                setDialogOpen(false);
              }}
              sentiment="destructive"
            >
              Disconnect
            </Button>
          </ButtonSet>
        </DialogActions>
      </Dialog>
    );
  };

  const renderSimple = () => {
    const { slackAccount } = props;

    return (
      <div className="cw-flex cw-items-center cw-mt-4 cw-gap-3">
        <div className="cw-text-positive cw-text-16 cw-leading-normal">
          {slackAccount.teamDisplayName}
        </div>
        <div className="cw-bg-positive-emphasis cw-rounded cw-text-12 cw-flex cw-items-center cw-p-1 cw-gap-1 cw-text-onEmphasis">
          <Check className="cw-h-3 cw-w-3" />
          Installed
        </div>
        <div className="cw-flex-grow cw-flex cw-justify-end">
          <Button variant="text" startIcon={Delete} onClick={() => setDialogOpen(true)} />
        </div>
        {renderDeleteDialog()}
      </div>
    );
  };

  if (mode === "simple") {
    return renderSimple();
  }

  return (
    <div className="cw-flex cw-flex-col cw-gap-3">
      {props.slackAccount.needsUpdate && (
        <div style={s.needsUpdate}>
          <CalloutBox warning style={s.calloutContainer}>
            <Warning style={s.calloutIcon} />
            <span style={s.calloutLabel}>
              Your Slack integration needs to be updated to get the latest features.
            </span>
            <SlackButton
              onAuthPopupClosed={() => onAuthPopupClosed()}
              target={
                <Button sentiment="neutral" variant="text">
                  Update now
                </Button>
              }
            />
          </CalloutBox>
        </div>
      )}
      <div style={s.scopeContainer}>
        <FormControl>
          <Select
            label="Auto sync calendar to Slack status"
            value={sync}
            onChange={onSyncChange}
            name="Auto sync calendar to Slack status"
            fullWidth
            size="large"
          >
            <SelectOption value={`NoSync`}>Off</SelectOption>
            <SelectOption value={`SyncCategories`}>Without event titles</SelectOption>
            <SelectOption value={`SyncTitles`}>With event titles</SelectOption>
          </Select>
        </FormControl>
        <FormControl>
          <Select
            label="Time to receive daily forecast"
            value={dailyDigest}
            onChange={onDailyDigestChange}
            name="Time to receive daily forecast"
            disabled={props.slackAccount.needsUpdate}
            fullWidth
            size="large"
          >
            <SelectOption value={`Off`}>Off</SelectOption>
            <SelectOption value={`T_06_00`}>6:00am</SelectOption>
            <SelectOption value={`T_07_00`}>7:00am</SelectOption>
            <SelectOption value={`T_08_00`}>8:00am</SelectOption>
            <SelectOption value={`T_09_00`}>9:00am</SelectOption>
            <SelectOption value={`T_10_00`}>10:00am</SelectOption>
            <SelectOption value={`T_17_00`}>5:00pm the night before</SelectOption>
            <SelectOption value={`T_18_00`}>6:00pm the night before</SelectOption>
            <SelectOption value={`T_19_00`}>7:00pm the night before</SelectOption>
            <SelectOption value={`T_20_00`}>8:00pm the night before</SelectOption>
            <SelectOption value={`T_21_00`}>9:00pm the night before</SelectOption>
            <SelectOption value={`T_22_00`}>10:00pm the night before</SelectOption>
          </Select>
        </FormControl>
        <FormControl>
          <Select
            label="Turn on Do Not Disturb"
            value={dnd}
            onChange={onDoNotDisturbChange}
            name="Turn on Do Not Disturb"
            disabled={props.slackAccount.needsUpdate}
            fullWidth
            size="large"
          >
            <SelectOption value={`Off`}>Off</SelectOption>
            <SelectOption value={`Meeting`}>In meetings</SelectOption>
            <SelectOption value={`FocusTime`}>In Focus Time</SelectOption>
            <SelectOption value={`MeetingFocusTime`}>In meetings and Focus Time</SelectOption>
          </Select>
        </FormControl>
        <FormControl>
          <Select
            label={t("settings:slack-autopilot-updates") as string}
            value={autopilotUpdates}
            onChange={onNotificationChange}
            name={t("settings:slack-autopilot-updates") as string}
            disabled={props.slackAccount.needsUpdate}
            fullWidth
            size="large"
          >
            <SelectOption value={`No`}>No</SelectOption>
            <SelectOption value={`Yes`}>Yes</SelectOption>
          </Select>
        </FormControl>
      </div>
      <div>
        <Button variant="outlined" startIcon={Delete} onClick={() => setDialogOpen(true)}>
          Disconnect Slack account
        </Button>
      </div>
      {renderDeleteDialog()}
    </div>
  );
};
