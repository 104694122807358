import { Button, ButtonSet } from "@clockwise/design-system";
import { Dialog, DialogActions, DialogContent, DialogTitle, ModalProps } from "@material-ui/core";
import * as React from "react";
export interface CwDialogProps {
  open: boolean;
  header: string;
  body: JSX.Element | string;
  onBackdropClick: () => void;
  children: React.ReactNode;
}

export interface CwDialogConfirmRedProps {
  open: boolean;
  header: string;
  body: JSX.Element | string;
  onCancel: () => void;
  onConfirm: () => void;
  cancelText?: string;
  confirmText?: string;
}

export const CwDialog = ({ open, header, body, onBackdropClick, children }: CwDialogProps) => {
  const onClose: ModalProps["onClose"] = (_e, reason) => {
    if (reason === "backdropClick") {
      onBackdropClick();
    }
  };

  return (
    <Dialog open={open} PaperProps={{ className: "cw-p-3" }} onClose={onClose} cw-id="cw-dialog">
      <DialogTitle>
        <div className="cw-text-h1">{header}</div>
      </DialogTitle>
      <DialogContent className="cw-py-3">{body}</DialogContent>
      <DialogActions className="cw-mt-4 cw-mr-4">{children}</DialogActions>
    </Dialog>
  );
};

export const CwDialogConfirmRed = ({
  open,
  header,
  body,
  onCancel,
  onConfirm,
  cancelText = "Cancel",
  confirmText = "Confirm",
}: CwDialogConfirmRedProps) => (
  <CwDialog open={open} header={header} body={body} onBackdropClick={onCancel}>
    <ButtonSet>
      <Button variant="text" onClick={onCancel}>
        {cancelText}
      </Button>
      <Button
        cw-id="confirm-delete-link-button"
        variant="text"
        sentiment="destructive"
        onClick={onConfirm}
      >
        {confirmText}
      </Button>
    </ButtonSet>
  </CwDialog>
);
