import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { UserProfileDocument } from "../../graphql/__generated__/UserProfile.generated";

export type UserProfile = {
  userId: string | null;
  externalImageUrl: string | null;
  givenName: string | null;
  familyName: string | null;
  primaryEmail: string | null;
  primaryCalendar: string | null;
};

export function useUserProfile() {
  const userProfile: UserProfile = {
    userId: null,
    externalImageUrl: null,
    givenName: null,
    familyName: null,
    primaryEmail: null,
    primaryCalendar: null,
  };

  const { data, loading, error } = useQuery(UserProfileDocument);

  const user = data?.viewer?.user;
  const userId = user?.id;
  const userPerson = data?.viewer?.user?.orgs?.edges?.[0]?.node?.userPerson;
  const userProfileGQL = (userPerson && getValue(userPerson)?.profile) ?? null;
  const primaryEmail = (userPerson && getValue(userPerson)?.primaryEmail) ?? null;
  const primaryCalendar = (userPerson && getValue(userPerson)?.primaryCalendar) ?? null;

  if (userProfileGQL) {
    userProfile.externalImageUrl = userProfileGQL.externalImageUrl;
    userProfile.givenName = userProfileGQL.givenName;
    userProfile.familyName = userProfileGQL.familyName;
  }

  if (userId) {
    userProfile.userId = userId;
  }

  if (primaryEmail) {
    userProfile.primaryEmail = primaryEmail;
  }

  if (primaryCalendar) {
    userProfile.primaryCalendar = primaryCalendar;
  }

  return { userProfile, loading, error };
}
