import classNames from "classnames";
import React from "react";

export const Header = () => (
  <div
    className={classNames(
      "cw-flex cw-items-center cw-justify-between",
      "cw-pt-1 cw-pb-2 cw-mb-2",
      "cw-border-solid cw-border-b cw-border-subtle",
      "cw-body-sm cw-font-medium cw-text-subtle",
    )}
  >
    30-day history
  </div>
);
