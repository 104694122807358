import classNames from "classnames";
import { DateTime, Interval } from "luxon";
import React from "react";
import { CalendarUnavailableStripes } from "../calendar-availability/CalendarAvailability";
import { CalendarPositioner } from "../calendar-positioner/CalendarPositioner";
import { sanitizeIntervals } from "./utils/sanitizeIntervals";
type Props =
  | {
      dateTimes: DateTime[];
      intervals: Interval[];
      isLoading?: boolean;
      onClick?: (interval: Interval) => void;
      type: "overlay";
    }
  | {
      dateTimes: DateTime[];
      intervals: Interval[];
      isLoading?: never;
      onClick?: never;
      type: "underlay";
    };
export const RangeHighlightCards = ({
  type,
  dateTimes = [],
  intervals = [],
  onClick,
  isLoading = false,
}: Props) => {
  const hasIntervals = intervals.length > 0;

  if (!hasIntervals) {
    return null;
  }

  const sanitizedIntervals = sanitizeIntervals(intervals);

  return (
    <>
      {type === "underlay" && <CalendarUnavailableStripes />}
      <CalendarPositioner
        dateTimes={dateTimes}
        gutters={false}
        conflictResolution="column"
        positionables={sanitizedIntervals.map((interval, index) => ({
          key: `range-highlight-${index}`, // DevNote: use index for semi-stable key allowing for CSS transitions on interval changes
          interval,
          render: () =>
            type === "overlay" ? (
              <RangeOverlayCard
                isLoading={isLoading}
                onClick={onClick ? () => onClick(interval) : undefined}
              />
            ) : (
              <RangeUnderlayCard />
            ),
        }))}
      />
    </>
  );
};
const RangeOverlayCard = ({
  isLoading,
  onClick,
}: {
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  return (
    <div
      className={classNames(
        onClick ? "cw-cursor-pointer" : "",
        "cw-h-full cw-w-full",
        "cw-rounded-md",
        "cw-bg-transparent",
        "cw-prism-outline cw-border-none",
        isLoading && "cw-prism-outline-animated",
      )}
      onClick={onClick}
    >
      <div
        className="cw-prism-mask cw-rounded-lg"
        style={
          {
            "--prism-mask-size": "1200px",
          } as React.CSSProperties
        }
      ></div>
    </div>
  );
};
const RangeUnderlayCard = () => {
  return (
    <div
      className={classNames(
        "cw-h-full cw-w-full",
        "cw-overflow-hidden",
        "cw-rounded-md",
        "cw-bg-default",
      )}
    ></div>
  );
};
