import { ClockwiseLogoFull } from "@clockwise/web-commons/src/components/logo/ClockwiseLogoFull";
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import { GraphiQL } from "graphiql";
import "graphiql/graphiql.css";
import React from "react";
import { GATEWAY_GRAPHQL_URL } from "../network/apollo/gateway-client";
import { jwt as jwtStorage, xsrf as xsrfStorage } from "../state/local-storage";

const jwt = jwtStorage.get();
const xsrf = xsrfStorage.get();

const fetcher = createGraphiQLFetcher({
  url: GATEWAY_GRAPHQL_URL,
  headers: {
    Authorization: jwt ? `Bearer ${jwt}` : "",
    Xsrf: xsrf ?? "",
  },
});

export function GatewayGraphiQLPage() {
  const [inLightMode, setInLightMode] = React.useState(false);

  React.useEffect(() => {
    // Check current state.
    const isCurrentLightMode = document.body.classList.contains("graphiql-light");
    if (isCurrentLightMode && !inLightMode) {
      setInLightMode(isCurrentLightMode);
    }

    // Mutation observer to look for whether the body tag has the `graphiql-light` class
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          const inLightMode = document.body.classList.contains("graphiql-light");
          setInLightMode(inLightMode);
        }
      });
    });
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["class"],
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="cw-h-screen cw-w-full">
      <GraphiQL fetcher={fetcher}>
        <GraphiQL.Logo>
          <div className="cw-flex cw-gap-1 cw-items-center">
            <ClockwiseLogoFull onEmphasis={!inLightMode} className="cw-h-4" />
            <span className="cw-text-lg">Gateway</span>
          </div>
        </GraphiQL.Logo>
      </GraphiQL>
    </div>
  );
}
