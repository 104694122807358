import { LoadingCard } from "#webapp/src/components/event-card/LoadingCard";
import { EventPermissionsInfoDocument } from "#webapp/src/components/event-card/__generated__/Permissions.v2.generated";
import { EMPTY_PERMISSIONS, FULL_PERMISSIONS } from "#webapp/src/components/event-card/types";
import { logger } from "@clockwise/client-commons/src/util/logger";
import {
  CancelEventDiffDetailsInput,
  SaveAddEventDiffDetailsInput,
  SaveModifyEventDiffDetailsInput,
} from "@clockwise/schema";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import React from "react";
import { useUpdateActiveEventDiff } from "../../../../../chat-plus-calendar/util/ActiveDiffContext";
import { useSetAIError } from "../../../hooks/AIErrorContext";
import { useCancelEventFromDiff } from "../../../hooks/useCancelEventFromDiff";
import { useDiffEventDetails } from "../../../hooks/useDiffEventDetails";
import { useSaveDiffEventDetails } from "../../../hooks/useSaveDiffEventDetails";
import { AddDiffEventCardExpanded } from "./AddDiffEventCardExpanded";
import { DiffContext } from "./DiffContext";
import { ModifyDiffEventCardExpanded } from "./ModifyDiffEventCardExpanded";

type DiffEventCardExpandedProps = {
  id: string;
};

export const DiffEventCardExpanded = ({ id }: DiffEventCardExpandedProps) => {
  const setActiveDiff = useUpdateActiveEventDiff();
  const { diffEventDetails, error } = useDiffEventDetails(id);
  const [onCancelEventDiffDetails] = useCancelEventFromDiff();
  const setError = useSetAIError();
  const onCloseCard = () => {
    setActiveDiff(null);
  };

  const eventId =
    diffEventDetails?.__typename === "ModifyDiffDetails" ? diffEventDetails.eventId : null;

  const { data: permissionsData } = useGatewayQuery(EventPermissionsInfoDocument, {
    variables: {
      id: eventId!,
    },
    skip: !eventId,
  });

  const permissions = eventId
    ? permissionsData?.event?.eventPermissions ?? EMPTY_PERMISSIONS
    : FULL_PERMISSIONS;

  const [
    onSaveEventDiffDetails,
    { saveAddDiffEventDetailsLoading, saveModifyDiffEventDetailsLoading },
  ] = useSaveDiffEventDetails({
    onError: (error) => {
      setError({
        error,
        message: "Error saving diff details",
        userMessage: "Failed to save proposal details",
        showUserMessage: true,
      });
    },
    onCompleted: () => {
      onCloseCard();
    },
  });

  if (error) {
    setError({ error: error, message: "Failed to fetch event details", showUserMessage: true });
    onCloseCard();

    return null;
  }

  const onSubmit = (input: SaveAddEventDiffDetailsInput | SaveModifyEventDiffDetailsInput) => {
    onSaveEventDiffDetails(input);
  };

  const onCancel = (input: CancelEventDiffDetailsInput, isPermissionAll: boolean) => {
    let failureMessage = isPermissionAll ? "Failed to deleted" : "Failed to remove";
    if (!input.shouldCancel) {
      failureMessage = "Failed to discard changes";
    }
    void onCancelEventDiffDetails(input, {
      onError: (error) => {
        setError({
          error,
          message: input.shouldCancel
            ? "Error cancelling diff details"
            : "Error undoing cancellation",
          showUserMessage: true,
          userMessage: failureMessage,
        });
      },
      onCompleted: () => {
        onCloseCard();
      },
    });
  };

  if (!diffEventDetails || saveAddDiffEventDetailsLoading || saveModifyDiffEventDetailsLoading) {
    return <LoadingCard onClose={onCloseCard} />;
  }

  switch (diffEventDetails.__typename) {
    case "AddDiffDetails":
      return (
        <DiffContext time={diffEventDetails.time} diffDetails={diffEventDetails}>
          <AddDiffEventCardExpanded
            diffEventDetails={diffEventDetails}
            onClose={onCloseCard}
            onSubmit={onSubmit}
          />
        </DiffContext>
      );
    case "ModifyDiffDetails":
      return (
        <DiffContext
          time={diffEventDetails.updatedTime ?? diffEventDetails.currentTime}
          diffDetails={diffEventDetails}
          eventId={diffEventDetails.eventId}
        >
          <ModifyDiffEventCardExpanded
            diffEventDetails={diffEventDetails}
            permissions={permissions}
            onClose={onCloseCard}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </DiffContext>
      );
    default:
      logger.warn("Unknown diff type", diffEventDetails);
      setActiveDiff(null);
      return null;
  }
};
