import {
  PageLayout,
  PageLayoutContent,
} from "@clockwise/web-commons/src/ui/page-layout/PageLayout";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import AppSettings from "../components/app-settings/AppSettings";
import { PageEvents, page } from "../util/analytics.util";

const AppSettingsPage = () => {
  useEffect(() => {
    page(PageEvents.CONNECTED_APPS_SETTINGS);
  }, []);

  return (
    <>
      <Helmet>
        <title>Connected apps · Clockwise</title>
      </Helmet>
      <PageLayout title="Connected apps">
        <PageLayoutContent>
          <AppSettings />
        </PageLayoutContent>
      </PageLayout>
    </>
  );
};

export default AppSettingsPage;
