import { TradeoffBlock } from "../../../../utils/types";

export type AttendeeThreadHeader = { names: string[]; hasCurrentUser: boolean };

export const removeTradeoffsEventsAlsoInConsequences = ({
  tradeoffBlocks,
  yourCalDiffs,
  otherCalDiffs,
}: {
  tradeoffBlocks: TradeoffBlock[];
  yourCalDiffs: {
    eventId: string;
  }[];
  otherCalDiffs: {
    eventId: string;
  }[];
}) => {
  const consequenceIds = [
    ...yourCalDiffs.map((diff) => diff.eventId),
    ...otherCalDiffs.map((diff) => diff.eventId),
  ];
  const newTradeoffBlocks: TradeoffBlock[] = [];
  for (const block of tradeoffBlocks) {
    const blockWithoutConsequenceItems = block.schedulingTradeoffs.filter(
      (tradeoff) => !consequenceIds.includes(tradeoff?.externalEventId || ""),
    );
    if (blockWithoutConsequenceItems.length) {
      newTradeoffBlocks.push({
        ...block,
        schedulingTradeoffs: blockWithoutConsequenceItems,
      });
    }
  }

  return newTradeoffBlocks;
};
