//////////////////
// libraries
import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";

import { ExportableAnalyticsButtonQueryDocument } from "./__generated__/ExportableAnalyticsButton.generated";
import { ExportableAnalyticsDialogQueryDocument } from "./__generated__/ExportableAnalyticsDialog.generated";

// components
import { Callout } from "@clockwise/web-commons/src/ui/callout";
import { ProBadge } from "@clockwise/web-commons/src/ui/pro-badge";

// util
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";

// constants
import { paths } from "@clockwise/client-commons/src/constants/site";

// material-ui
import { Button, Link, Modal } from "@clockwise/design-system";
import { GetApp, LockOutlined } from "@clockwise/design-system/icons";
import { ProductTypeEnum } from "@clockwise/schema";

// ~-~-~-~-~-~-~-
// Exportable analytics dialog
// ~-~-~-~-~-~-~-
const CSV_DOWNLOADER_ID = "cw-csv-downloader";
export const ExportableAnalyticsDialog = ({
  onClose,
  orgId,
  isPaywalled,
}: {
  onClose: () => void;
  orgId: string;
  isPaywalled: boolean;
}) => {
  const [downloadState, setDownloadedState] = React.useState<
    "NotDownloaded" | "Downloading" | "Downloaded"
  >("NotDownloaded");

  const { data, error, refetch } = useQuery(ExportableAnalyticsDialogQueryDocument, {
    variables: {
      fetchAnalytics: false,
      orgId,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const org = getValue(data?.org, "Org");
    const exportedCalendarAnalytics = getValue(org?.exportedCalendarAnalytics)?.list;

    if (exportedCalendarAnalytics) {
      const link: HTMLElement | null = document.querySelector(`[cw-id="${CSV_DOWNLOADER_ID}"]`);
      if (link) {
        link.click();
      } else {
        logger.error("failed to dispatch click on csv download link");
      }
    }

    setDownloadedState(exportedCalendarAnalytics ? "Downloaded" : "NotDownloaded");
  }, [data]);

  if (error) {
    logger.error(
      `failed to load ExportableAnalyticsDialog: ${error?.message || "no error message"}`,
    );
    return (
      <div>
        An error occurred while loading Clockwise:
        <br />
        {error.message}
      </div>
    );
  }

  const org = getValue(data?.org, "Org");
  const exportedCalendarAnalytics = getValue(org?.exportedCalendarAnalytics)?.list;

  const getFileName = () => {
    const currentDay = DateTime.local().toFormat("MM-dd-yyyy");
    return `${currentDay}-clockwise-calendar-analytics.csv`;
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  const rows = (exportedCalendarAnalytics || []).map((r) => {
    return {
      emailAddress: r.user.emailAddress,
      givenName: r.user.givenName,
      familyName: r.user.familyName,
      dateOfWeek: r.data.dateOfWeek,
      focusHours: r.data.focusMinutes / 60,
      meetingHours: r.data.meetingMinutes / 60,
      fragmentedHours: r.data.fragmentedMinutes / 60,
      job: r.data.job,
      role: r.data.role,
    };
  });

  return (
    <Modal
      opened={true}
      onClose={onClose}
      size="xl"
      title={
        <div className="cw-heading-xl cw-flex cw-items-center" aria-label="Calendar Analytics">
          Calendar Analytics {!isPaywalled && <ProBadge className="cw-ml-2" text="Business" />}
        </div>
      }
    >
      <div>
        {!isPaywalled && (
          <Callout level="warning" className="cw-mb-4">
            <div className="cw-text-sm cw-flex cw-items-center cw-leading-4 cw-w-full">
              <LockOutlined className="cw-text-warning-muted cw-mr-2.5 cw-grow-0 " />
              <div className="cw-grow-0">
                This feature is only available for users on Clockwise's Business or Enterprise
                plans.
              </div>
              <div className="cw-flex cw-grow cw-justify-end">
                <Button
                  variant="text"
                  onClick={() => {
                    window.open(`/app${paths.pricing}`, "_blank");
                    track(TrackingEvents.ADMIN_PANEL.OVERVIEW_CALENDAR_DATA_UPGRADE_CLICKED);
                  }}
                  sentiment="warning"
                  size="medium"
                >
                  Upgrade
                </Button>
              </div>
            </div>
          </Callout>
        )}
        <p className="cw-body-base cw-font-medium cw-mb-2">
          Export your organization's calendar data as a CSV file to better understand how your
          team's time is spent.
        </p>
        <p className="cw-body-base cw-font-medium">
          Only contains 8 weeks of data. For more, contact{" "}
          <Link href={`mailto:customersuccess@getclockwise.com`} target="_blank">
            customersuccess@getclockwise.com
          </Link>
          .
        </p>
      </div>
      <div className="cw-pt-2 cw-flex cw-gap-1 cw-justify-end">
        {!isPaywalled && (
          <Button
            variant="text"
            onClick={() => {
              track(TrackingEvents.ADMIN_PANEL.OVERVIEW_CALENDAR_DATA_OK_CLICKED);
              onClose();
            }}
            color="primary"
          >
            Ok
          </Button>
        )}
        {isPaywalled && downloadState !== "Downloaded" && (
          <>
            <Button
              variant="text"
              onClick={() => {
                track(TrackingEvents.ADMIN_PANEL.OVERVIEW_CALENDAR_DATA_CANCEL_CLICKED);
                onClose();
              }}
              color="primary"
            >
              Cancel
            </Button>
            <CSVLink
              cw-id={CSV_DOWNLOADER_ID}
              className="cw-display-none"
              filename={getFileName()}
              data={rows}
              headers={[
                { label: "Email address", key: "emailAddress" },
                { label: "Given name", key: "givenName" },
                { label: "Family name", key: "familyName" },
                { label: "Date of week", key: "dateOfWeek" },
                { label: "Hours of Focus Time", key: "focusHours" },
                { label: "Hours of meeting time", key: "meetingHours" },
                { label: "Hours of fragmented time", key: "fragmentedHours" },
                { label: "Job", key: "job" },
                { label: "Role", key: "role" },
              ]}
            />
            <Button
              disabled={downloadState === "Downloading"}
              variant="solid"
              color="primary"
              sentiment="positive"
              onClick={() => {
                setDownloadedState("Downloading");
                track(TrackingEvents.ADMIN_PANEL.OVERVIEW_CALENDAR_DATA_DOWNLOAD_CLICKED);
                void refetch({
                  fetchAnalytics: isPaywalled && true,
                  orgId,
                });
              }}
            >
              {downloadState === "Downloading" ? "Preparing..." : "Download"}
            </Button>
          </>
        )}
        {isPaywalled && downloadState === "Downloaded" && (
          <Button variant="solid" onClick={onClose} color="primary">
            Done
          </Button>
        )}
      </div>
    </Modal>
  );
};

// ~-~-~-~-~-~-~-
// Exportable analytics button
// ~-~-~-~-~-~-~-
export const ExportableAnalyticsButton = () => {
  const [fetchAnalytics, setFetchAnalytics] = React.useState(false);

  const { loading, data, error } = useQuery(ExportableAnalyticsButtonQueryDocument);

  if (!data || loading) {
    return null;
  }

  if (error) {
    logger.error(
      `failed to load ExportableAnalyticsButton: ${error?.message || "no error message"}`,
    );
    return (
      <div>
        An error occurred while loading Clockwise:
        <br />
        {error.message}
      </div>
    );
  }

  const viewer = data.viewer;
  const org = getCurrentOrg(viewer);

  if (!org) {
    return null;
  }

  const featureGrid = getValue(org.featureGrid);
  const subscription = featureGrid?.stateMetadata.primaryBillingGroupMetadata?.subscription;
  const isBusinessPlus =
    subscription?.productType === ProductTypeEnum.Enterprise_NonStandard ||
    subscription?.productType === ProductTypeEnum.Enterprise_Standard ||
    subscription?.productType === ProductTypeEnum.Business_NonStandard ||
    subscription?.productType === ProductTypeEnum.Business_Standard;

  // ~-~-~-~-~-~-~-
  // Helper
  // ~-~-~-~-~-~-~-
  const onClick = () => {
    track(TrackingEvents.ADMIN_PANEL.OVERVIEW_CALENDAR_DATA_MODAL_OPENED);
    setFetchAnalytics(true);
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  return (
    <div className="cw-ml-3">
      <Button
        variant="outlined"
        startIcon={GetApp}
        onClick={onClick}
        aria-label="Download calendar analytics"
      >
        Download
      </Button>
      {fetchAnalytics && (
        <ExportableAnalyticsDialog
          orgId={org.id}
          onClose={() => setFetchAnalytics(false)}
          isPaywalled={isBusinessPlus}
        />
      )}
    </div>
  );
};
