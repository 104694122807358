import { CSSProperties } from "@material-ui/core/styles/withStyles";

// styles
import { greys } from "@clockwise/web-commons/src/styles/color.styles";

const zoomAuthContainer: CSSProperties = {
  padding: "15px 0",
};

const zoomAccountContainer: CSSProperties = {
  padding: "15px 0",
};

const zoomAccountContent: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const zoomAccountNameInfo: CSSProperties = {};

const zoomAccountName: CSSProperties = {
  fontSize: 16,
  color: greys.standard,
};

const zoomAccountDomain: CSSProperties = {
  fontSize: 14,
  color: greys.slate,
  lineHeight: 1.5,
  fontWeight: "bold",
};

const deleteIcon: CSSProperties = {
  color: greys.slate,
  width: 20,
};

export const styles = {
  zoomAuthContainer,
  zoomAccountContainer,
  zoomAccountContent,
  zoomAccountNameInfo,
  zoomAccountName,
  zoomAccountDomain,
  deleteIcon,
};
