import { Link } from "@clockwise/design-system";
import { FeatureSettingsHoverCard } from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { Skeleton } from "@material-ui/lab";
import { compact, sortBy } from "lodash";
import React from "react";
import toast from "react-hot-toast";
import { TrackingEvents, track } from "../../../util/analytics.util";
import { logger } from "../../../util/logger.util";
import {
  PrimaryTeamSelectDocument,
  UpdatePrimaryTeamDocument,
} from "./__generated__/PrimaryTeamSelect.v2.generated";

export const PrimaryTeamSelect = ({ pageTeamId }: { pageTeamId?: string }) => {
  const { data, loading } = useGatewayQuery(PrimaryTeamSelectDocument);
  const [updatePrimaryTeam, { loading: saving }] = useGatewayMutation(UpdatePrimaryTeamDocument, {
    refetchQueries: [PrimaryTeamSelectDocument],
  });
  const userTeams = compact(sortBy(data?.currentUser?.teams ?? [], "name"));
  const primaryTeam = data?.currentUser?.teams.find((team) => team?.isPrimary);
  const primaryTeamId = primaryTeam?.id ?? "";

  const onMakePrimaryTeam = () => {
    if (!saving && pageTeamId) {
      void updatePrimaryTeam({
        variables: { teamId: pageTeamId },
        optimisticResponse: {
          __typename: "Mutation",
          updatePrimaryTeam: {
            __typename: "Team",
            id: pageTeamId,
            name: userTeams.find((team) => team.id === pageTeamId)?.name ?? "",
            isPrimary: true,
          },
        },
        refetchQueries: [PrimaryTeamSelectDocument],
        awaitRefetchQueries: true,
        onCompleted: () => {
          toast.success("Updated your primary team");
          track(TrackingEvents.TEAMS.CHANGED_PRIMARY_TEAM, {
            selectedPrimaryTeamId: pageTeamId,
            prevPrimaryTeamId: primaryTeamId,
          });
        },
        onError: (error) => {
          toast.error("Failed to update your primary team");
          logger.error("Failed to update your primary team", error);
        },
      });
    }
  };

  if (loading && !primaryTeamId) {
    return (
      <span>
        Your primary team is <Skeleton width={100} height={18} className="cw-inline-block" />
      </span>
    );
  }

  const isOnPrimaryTeamPage = primaryTeamId === pageTeamId;

  const primaryTeamWithTooltip = (
    <FeatureSettingsHoverCard
      id="primary-team-tooltip"
      content={
        <>
          Your primary team is your default team in Clockwise. You will receive proactive alerts
          about your primary teammates' availability and capacity.
        </>
      }
    >
      <span
        className="cw-underline cw-decoration-dashed cw-underline-offset-3 cw-decoration-1 cw-cursor-pointer"
        aria-describedby="primary-team-tooltip"
      >
        primary team
      </span>
    </FeatureSettingsHoverCard>
  );
  const currentPrimaryTeamMessage = primaryTeam ? (
    <span>
      Your {primaryTeamWithTooltip} is {primaryTeam.name}
    </span>
  ) : (
    <span>You don't have a {primaryTeamWithTooltip} yet</span>
  );

  return (
    <span>
      {isOnPrimaryTeamPage ? (
        <span>This is your {primaryTeamWithTooltip}.</span>
      ) : (
        <span>
          <div>{currentPrimaryTeamMessage}</div>
          <Link onClick={onMakePrimaryTeam}>Make this your primary team</Link>
        </span>
      )}
    </span>
  );
};
