import React from "react";
import { OrgPricingGrid } from "../pricing-grid/org-pricing-grid";

export const PricingPlansAndBilling = () => {
  return (
    <div className="cw-h-full cw-w-full cw-mt-10">
      <div className="cw-flex cw-flex-col cw-items-center cw-p-4">
        <OrgPricingGrid isDialog={true} isFullWidth={false} />
      </div>
    </div>
  );
};
