import { Link } from "@clockwise/design-system";
import React from "react";

const Tooltip = () => (
  <>
    Clockwise will schedule lunch every day and move flexible meetings to keep that time clear.{" "}
    <Link onEmphasis href="https://getclockwise.helpscoutdocs.com/article/171-lunch-events">
      Learn more
    </Link>
  </>
);

export default Tooltip;
