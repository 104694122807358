import {
  Conferencing,
  EventCategoryType,
  ResponseStatusEnum,
  SmartHoldType,
} from "@clockwise/schema/v2";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { EventType } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { getCWCalendarColors } from "@clockwise/web-commons/src/util/event-category-coloring";
import { useOnWindowFocusChange } from "@clockwise/web-commons/src/util/react.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { compact } from "lodash";
import { DateTime, Interval } from "luxon";
import React, { createContext, ReactNode, useCallback, useContext, useMemo } from "react";
import { CurrentOrNextEventDocument } from "./__generated__/CurrentOrNextEvent.v2.generated";

export type CurrentOrNextEventState = {
  event: {
    externalEventId: string;
    title: string;
    videoLink: Conferencing | null;
    time: Interval;
    color: string | null;
    type: EventType;
    isHold: boolean;
    isExternalEvent: boolean;
    isTravelTimeHold: boolean;
    canReschedule: boolean;
  } | null;
  loading: boolean;
};

const CurrentOrNextEventContext = createContext<CurrentOrNextEventState>({
  event: null,
  loading: false,
});

export const CurrentOrNextEventProvider = ({ children }: { children: ReactNode }) => {
  const ecosystem = useEcosystem();
  const colors = getCWCalendarColors(ecosystem);
  const currentDateTime = DateTime.now();
  const { data, loading, startPolling, stopPolling } = useGatewayQuery(CurrentOrNextEventDocument, {
    variables: {
      date: currentDateTime.toISODate(),
      timeZone: getRenderTimeZone(),
    },
    errorPolicy: "all", // This allows us to receive both error and data to able to load partial data.
  });

  const todaysEvents = compact(data?.currentCalendar?.eventsByDates ?? []).reduce((acc, event) => {
    if (
      event.isLocked ||
      event.dateOrTimeRange.__typename === "DateRange" ||
      !!event.smartHold?.id ||
      event.responseStatus === ResponseStatusEnum.Declined ||
      event.category.type === EventCategoryType.OutOfOffice ||
      event.isCanceled
    ) {
      return acc;
    }
    const timeInterval = Interval.fromISO(event.dateOrTimeRange.timeRange, {
      zone: getRenderTimeZone(),
    });
    const eventLength = timeInterval.end.diff(timeInterval.start, ["hours"]).hours;
    // Hide events over 8 hours long
    if (eventLength >= 8) {
      return acc;
    }
    return [
      ...acc,
      {
        externalEventId: event.externalEventId,
        title: event.title,
        videoLink: event.conferencing,
        time: timeInterval,
        color: event.category?.color ? colors[event.category?.color]?.foreground : null,
        type: event.isSyncedFromPersonalCalendar ? EventType.PersonalCalendarSync : EventType.Event,
        isHold: event.category.type === EventCategoryType.IndividualHold,
        isExternalEvent: event.category.type === EventCategoryType.External,
        isTravelTimeHold: event.smartHold?.holdType === SmartHoldType.TravelTime,
        canReschedule: event.canReschedule,
      },
    ];
  }, []);

  const timeIn10MinutesOrLess = currentDateTime.plus({ minutes: 10 });
  const eventStartingWithin10Minutes = todaysEvents?.find((event) => {
    return event.time.start <= timeIn10MinutesOrLess && event.time.start >= currentDateTime;
  });
  const eventCurrentlyOngoing = todaysEvents?.find((event) => {
    return event.time.contains(currentDateTime);
  });
  const nextFutureEvent = todaysEvents?.find((event) => {
    return event.time.start > currentDateTime;
  });

  const eventToShow = eventStartingWithin10Minutes || eventCurrentlyOngoing || nextFutureEvent;

  const value = useMemo(() => {
    return {
      event: eventToShow || null,
      loading,
    };
  }, [eventToShow?.externalEventId, loading]);

  // Start polling when the window is focused
  const startPollingCallback = useCallback(() => startPolling(30 * 1000), [startPolling]);
  useOnWindowFocusChange(startPollingCallback, stopPolling, stopPolling);

  return (
    <CurrentOrNextEventContext.Provider value={value}>
      {children}
    </CurrentOrNextEventContext.Provider>
  );
};

export const useCurrentOrNextEvent = () => useContext(CurrentOrNextEventContext);
