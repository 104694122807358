import { FocusTimeGoalsQuery } from "../graphql/__generated__/FocusTimeGoals.generated";
import { getCurrentOrg } from "../../../util/org.util";

export const parseFocusTimeGoalsQuery = (data: FocusTimeGoalsQuery | undefined) => {
  const org = getCurrentOrg(data?.viewer);
  const FocusTimeGoal = org?.smartHoldGoals.focusTime;
  return FocusTimeGoal;
};

export default parseFocusTimeGoalsQuery;
