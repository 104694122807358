import { isEmpty, isEqual } from "lodash";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDiffIdWithCalendarIds,
  addEventIds,
  removeCalendarIds,
  removeDiffIdWithCalendarIds,
  removeEventIds,
} from "../../../../../state/actions/multi-calendar.actions";
import { IReduxState } from "../../../../../state/reducers/root.reducer";
import useEventDetails from "../../../../web-app-calendar/hooks/useEventDetails";

import { DisableReason, useIsLayersDisabledWithReason } from "./calendar-layers.util";
type useEventLayersToggleProps = {
  startingIsToggledOn: boolean;
  externalEventId: string;
  calendarId: string;
  focusedToggle?: boolean;
};

type EventToggleReturnType = {
  active: boolean;
  calendarIds: string[];
  disabled: boolean;
  reasonForDisabled: DisableReason;
  toggle: () => void;
};

export const useEventLayersToggle = ({
  startingIsToggledOn,
  externalEventId,
  calendarId,
  // If `focusedToggle` is on, remove all the calendars on this event individually
  // regardless of all the other events and diffs that are toggled on
  focusedToggle,
}: useEventLayersToggleProps): EventToggleReturnType => {
  const dispatch = useDispatch();
  const { eventIdOrDiffIdMappedToCalendarIds, selectedDiffIds } = useSelector(
    (state: IReduxState) => state.multiCalendar,
  );

  const {
    eventDetails: { attendeePeople: fetchedAttendees },
    loading,
  } = useEventDetails(externalEventId, calendarId);
  const eventId = externalEventId;

  const calendarIds = useMemo(() => {
    return fetchedAttendees?.map((attendee) => attendee.primaryCalendar) || [];
  }, [fetchedAttendees]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (isEqual(eventIdOrDiffIdMappedToCalendarIds[eventId], calendarIds)) {
      return;
    }

    dispatch(addDiffIdWithCalendarIds(eventId, calendarIds));

    return () => {
      dispatch(removeEventIds([eventId])); // This removes the event selection to turn off the layers
      dispatch(removeDiffIdWithCalendarIds(eventId)); // This remove the eventId from mapping
    };
  }, [JSON.stringify(calendarIds), eventId, loading]);

  const isToggledOn = useMemo(() => selectedDiffIds.includes(eventId), [selectedDiffIds, eventId]);

  useEffect(() => {
    if (startingIsToggledOn) {
      dispatch(addEventIds([eventId]));
    }
  }, []);

  useEffect(() => {
    // Need the `loading` check because the calendarIds is empty before then
    if (isEmpty(calendarIds) && !loading) {
      // For diffs with no attendees, it's considered already shown in the calendar
      // Thus add it to the list of selected diff ids
      dispatch(addEventIds([eventId]));
    }
  }, [JSON.stringify(calendarIds)]);

  // We want to use the calendarIds that the user has edited because the toggle button
  // should turn on the calendars including the ones that the user has added or removed
  const calendarIdsPossiblyWithUserEdits = eventIdOrDiffIdMappedToCalendarIds[eventId];

  const { disabled, reason: reasonForDisabled } = useIsLayersDisabledWithReason(
    calendarIdsPossiblyWithUserEdits,
  );

  const toggle = () => {
    if (disabled) {
      return;
    }

    if (!isToggledOn) {
      dispatch(addEventIds([eventId]));
    }

    if (isToggledOn) {
      if (focusedToggle) {
        dispatch(removeCalendarIds([...eventIdOrDiffIdMappedToCalendarIds[eventId]]));
      } else {
        dispatch(removeEventIds([eventId]));
      }
    }
  };
  return {
    active: isToggledOn,
    calendarIds,
    disabled,
    reasonForDisabled,
    toggle,
  };
};
