import { SmartHoldState } from "@clockwise/schema/v2";
import classNames from "classnames";
import React from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";

const RadioSection = ({ onCheck, checked }: { onCheck: () => void; checked: boolean }) => {
  return (
    <div className="cw-relative">
      <input type="radio" className="cw-opacity-0 cw-absolute" />
      <label htmlFor="radioButton" className="cw-inline-flex cw-items-center cw-cursor-pointer ">
        <div
          className={classNames(
            "cw-w-4 cw-h-4 cw-flex cw-flex-shrink-0 cw-justify-center cw-items-center cw-border cw-border-solid cw-rounded-full",
            {
              "cw-border-[#027A52]": checked,
              "cw-border-default group-hover:cw-border-default-hover": !checked,
            },
          )}
        >
          <input
            type="radio"
            name="radioButton"
            checked={checked}
            className="cw-opacity-0 cw-absolute cw-cursor-pointer"
            onChange={onCheck}
          />
          <div
            className={classNames("cw-w-2 cw-h-2 cw-rounded-full", {
              "cw-bg-[#027A52]": checked,
              "cw-bg-transparent": !checked,
            })}
          />
        </div>
      </label>
    </div>
  );
};

export const FlexAndProtection = ({
  smartHoldState,
  onOptionChange,
}: {
  smartHoldState: SmartHoldState;
  onOptionChange: (value: SmartHoldState) => void;
}) => {
  return (
    <EventElementsWrap name="flexandprotection">
      <div className="cw-gap-3 cw-flex cw-flex-col">
        <div className="cw-h-7 cw-font-medium cw-text-subtle cw-text-13 cw-flex cw-items-center">
          Flexibility & Protection
        </div>
        <div
          className="cw-flex cw-gap-3 cw-items-center cw-group cw-cursor-pointer"
          onClick={() => {
            onOptionChange(SmartHoldState.Unpaused);
          }}
        >
          <RadioSection
            checked={smartHoldState === SmartHoldState.Unpaused}
            onCheck={() => {
              onOptionChange(SmartHoldState.Unpaused);
            }}
          />
          <div className="cw-flex cw-flex-col cw-flex-1 cw-text-13">
            <span className="cw-font-medium">❇️ Flexible</span>
            <span className="cw-text-muted">Move as my calendar changes</span>
          </div>
        </div>
        <div
          className="cw-flex cw-gap-3 cw-items-center cw-group cw-cursor-pointer"
          onClick={() => {
            onOptionChange(SmartHoldState.Paused);
          }}
        >
          <RadioSection
            checked={smartHoldState === SmartHoldState.Paused}
            onCheck={() => {
              onOptionChange(SmartHoldState.Paused);
            }}
          />
          <div className="cw-flex cw-flex-col cw-flex-1 cw-text-13">
            <span className="cw-font-medium">⏸️ Paused</span>
            <span className="cw-text-muted">Do not move this hold</span>
          </div>
        </div>
        <div
          className="cw-flex cw-gap-3 cw-items-center cw-group cw-cursor-pointer"
          onClick={() => {
            onOptionChange(SmartHoldState.Protected);
          }}
        >
          <RadioSection
            checked={smartHoldState === SmartHoldState.Protected}
            onCheck={() => {
              onOptionChange(SmartHoldState.Protected);
            }}
          />
          <div className="cw-flex cw-flex-col cw-flex-1 cw-text-13">
            <span className="cw-font-medium">🛡️ Protected</span>
            <span className="cw-text-muted">Do not move and decline incoming meetings</span>
          </div>
        </div>
      </div>
    </EventElementsWrap>
  );
};
