import React, { useEffect } from "react";
import { TrackingEvents, track } from "../../../../util/analytics.util";
import { WebOnboardingStep } from "../../WebOnboarding.util";
import { useOnboardingFetchEventFlexibility } from "../../hooks/useOnboardingFetchEventFlexibility";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { Loading, StepCounter, StepLayout } from "../../shared-components";
import { FlexMeetingSlide } from "./FlexMeetingSlide";

export const FlexMeetingStep = () => {
  const {
    goForward,
    goBack,
    shouldShowSecondaryButton,
    skipStep,
    backFromPreviousSlide,
    funnelType,
  } = useWebOnboardingSteps();

  const { flexOneOnOnes, loading } = useOnboardingFetchEventFlexibility({
    onErrorCallback: goForward,
    backFromPreviousSlide,
  });

  // if returning to step don't parse out already enabled
  useEffect(() => {
    if (!flexOneOnOnes && !loading) {
      track(TrackingEvents.WEB_ONBOARDING.FLEX_MEETING_NO_SUGGESTIONS);
      goForward();
      skipStep(WebOnboardingStep.FlexMeeting, true);
    } else if (flexOneOnOnes) {
      track(TrackingEvents.WEB_ONBOARDING.FLEX_MEETING_SUGGESTIONS, {
        suggestionCount: flexOneOnOnes.length,
      });
    }
  }, [flexOneOnOnes, loading]);

  return (
    <StepLayout>
      <StepCounter />
      {loading || !flexOneOnOnes ? (
        <Loading />
      ) : (
        <FlexMeetingSlide
          showSecondaryButton={shouldShowSecondaryButton}
          onClickPrimaryButton={goForward}
          onClickSecondaryButton={goBack}
          eventMap={flexOneOnOnes}
          funnelType={funnelType}
        />
      )}
    </StepLayout>
  );
};
