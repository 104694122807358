import { GoToSettings } from "#webapp/src/components/event-card/smart-holds/SmartHoldSettingsBrief";
import { Settings } from "@clockwise/design-system/icons";
import { webappDefaultWindowName } from "@clockwise/web-commons/src/constants/route.constants";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import classNames from "classnames";
import React from "react";

export const SettingsSmartHolds = ({ subText }: { subText?: string }) => {
  const onClickEditSettings = () => {
    window.open(getUrlForFeature("accountPreferences"), webappDefaultWindowName);
  };

  return (
    <div className="cw-pt-1.5 cw-mx-1.5">
      <div
        className={classNames(
          "cw-flex cw-items-start cw-gap-2",
          "cw-p-2",
          "cw-text-muted cw-body-sm cw-text-12",
          "cw-bg-neutral cw-rounded-lg",
          "cw-border cw-border-subtle cw-border-solid",
        )}
      >
        <Settings className="cw-w-3.5 cw-h-3.5 cw-text-muted cw-mt-0.5" />
        <div className="cw-flex cw-flex-col cw-gap-1">
          <div>{subText}</div>
          <GoToSettings label="Edit global settings" onClick={onClickEditSettings} />
        </div>
      </div>
    </div>
  );
};
