import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RescheduleSuggestionsQueryVariables = Types.Exact<{
  externalEventId: Types.Scalars['String'];
  clusterId: Types.Scalars['String'];
}>;


export type RescheduleSuggestionsQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', id: string, user: { __typename: 'User', id: string, orgs: { __typename: 'OrgConnection', edges: Array<{ __typename: 'OrgEdge', node: { __typename: 'Org', id: string, findSchedulingSuggestionsV2: Array<{ __typename: 'ConflictEventNewTimes', time: string }> } | null } | null> | null } } | null } };


export const RescheduleSuggestionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RescheduleSuggestions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"clusterId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"orgs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"findSchedulingSuggestionsV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"externalEventId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}}},{"kind":"Argument","name":{"kind":"Name","value":"clusterId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"clusterId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"time"}}]}}]}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<RescheduleSuggestionsQuery, RescheduleSuggestionsQueryVariables>;