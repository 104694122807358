import { Day } from "@clockwise/schema";
import classNames from "classnames";
import React from "react";

export interface DayCheckboxProps {
  checked: boolean;
  disabled?: boolean;
  restricted?: boolean;
  error?: boolean;
  onChange: (checked: boolean) => void;
  day: Day;
}

export const DayCheckbox = ({
  checked,
  disabled = false,
  restricted = false,
  error = false,
  day,
  onChange,
}: DayCheckboxProps) => {
  const dayInitial = day.substring(0, 1).toUpperCase();
  let bgColor = "cw-bg-default-disabled hover:cw-bg-default-hover";
  let textColor = "cw-text-muted";
  let outlineColor = "cw-outline-current";

  if (restricted) {
    bgColor = "cw-bg-info-pressed";
    textColor = "cw-text-info";
  } else if (disabled) {
    bgColor = checked ? "cw-bg-default-disabled" : "cw-bg-default-emphasis-disabled";
    textColor = "cw-text-muted";
  } else if (error) {
    bgColor = "cw-bg-destructive-emphasis hover:cw-bg-destructive-emphasis-hover";
    textColor = "cw-text-onEmphasis";
    outlineColor = "cw-outline-destructive-emphasis";
  } else if (checked) {
    bgColor = "cw-bg-positive-emphasis hover:cw-bg-positive-emphasis-hover";
    textColor = "cw-text-onEmphasis";
    outlineColor = "cw-outline-positive-emphasis";
  }

  const ariaLabel = `day of week: ${day}`;
  return (
    <label
      className={classNames({
        "cw-pointer-events-none": disabled || restricted,
      })}
    >
      <input
        aria-label={ariaLabel}
        className="cw-sr-only cw-peer"
        name={day}
        type="checkbox"
        checked={checked}
        disabled={disabled || restricted}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        role="checkbox"
      />
      <span
        title={day}
        className={classNames(
          "md:cw-h-6 md:cw-w-6 cw-w-8 cw-h-8",
          "cw-inline-flex  cw-items-center cw-justify-center",
          "cw-rounded-full",
          "cw-cursor-pointer",
          "cw-transition-colors cw-duration-200 cw-ease-in-out",
          bgColor,
          textColor,
          outlineColor,
          "peer-focus-visible:cw-outline peer-focus-visible:cw-outline-2 peer-focus-visible:cw-outline-offset-2",
        )}
      >
        <span className="cw-font-body cw-text-[10px]">{dayInitial}</span>
      </span>
    </label>
  );
};
