import { niceNameArrayFromPersonsWithCarryover } from "@clockwise/client-commons/src/util/event";
import { bg_default } from "@clockwise/design-system/tokens";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { compact } from "lodash";
import React, { useEffect } from "react";
import { fromGlobalId } from "../../../../util/graphql.util";
import { ButtonGroup, Header, SlideLayout } from "../../shared-components";
import { FunnelType } from "../../WebOnboarding.util";
import { TeamJoinCreateStepQuery } from "./__generated__/TeamJoinCreateStep.generated";

type OrgNode = Exclude<
  Exclude<
    Exclude<Exclude<TeamJoinCreateStepQuery["viewer"]["user"], null>["orgs"], null>["edges"],
    null
  >[0],
  null
>["node"];
type TeamInJoinedSlide = Extract<
  Extract<OrgNode, { __typename: "Org" }>["primaryTeam"],
  { __typename: "Team" }
>;

type TeamJoinedSlideProps = {
  showSecondaryButton: boolean;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton: () => void;
  joinedTeam: TeamInJoinedSlide;
  userId: string;
  funnelType: FunnelType;
};

export const TeamJoinedSlide = ({
  showSecondaryButton,
  onClickPrimaryButton,
  onClickSecondaryButton,
  joinedTeam,
  userId,
  funnelType,
}: TeamJoinedSlideProps) => {
  const track = useTracking();
  useEffect(() => {
    track(TrackingEvents.ONBOARDING.ONBOARDING_TEAM_CONFIRMATION_SHOWN, {
      teamId: joinedTeam.teamId,
      userWasCreator: youCreated,
      funnelType,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const teamName = joinedTeam.teamName;
  const youCreated = joinedTeam.createdBy?.value === fromGlobalId(userId).id;
  const allTeamMembers = [...(joinedTeam.invitedMembers || []), ...(joinedTeam.teamMembers || [])];

  const persons = compact(allTeamMembers.map((tm) => tm.person));
  const you = allTeamMembers.find((tm) => tm.person.isYou);
  const maybeInviter = you && allTeamMembers.find((tm) => tm.person.userId === you.addedBy);

  const niceNames = niceNameArrayFromPersonsWithCarryover(
    persons,
    undefined,
    40,
    true,
    undefined,
    false,
  ).niceNameArray.join("");

  return (
    <SlideLayout>
      <div className="cw-max-w-[750px] cw-mb-12">
        <Header>
          {youCreated
            ? `Welcome! It looks like you already created the team "${teamName}."`
            : maybeInviter?.person?.profile?.givenName
            ? ` Welcome! ${maybeInviter?.person.profile.givenName}
            ${
              maybeInviter?.person.profile?.familyName ?? ""
            } has added you to the team "${teamName}."`
            : `Welcome! You have been added to the team "${teamName}."`}
        </Header>
      </div>
      <div className="cw-bg-neutral-inset md:cw-w-[640px] cw-w-full md:cw-h-[400px] cw-flex cw-flex-col cw-rounded-[24px] cw-justify-center cw-items-center cw-pt-6 md:cw-pt-0">
        <AttendeeAvatarStack maxShown={6} size="xlarge" overlap overflowFillColor={bg_default}>
          {persons.map((person) => (
            <AttendeeAvatar
              isInOverlap
              key={person.primaryCalendarId}
              profile={person.profile || undefined}
              size="xlarge"
            />
          ))}
        </AttendeeAvatarStack>
        <div className="cw-body-sm cw-my-6 cw-text-center cw-text-muted md:cw-w-[434px]">
          {niceNames && `Your teammates include ${niceNames}.`}
          <div className="cw-mt-10">
            You can view or manage your team anytime later in settings.
          </div>
        </div>
      </div>

      <ButtonGroup
        shouldShowSecondary={showSecondaryButton}
        primaryLabel="Next"
        onClickPrimary={onClickPrimaryButton}
        onClickSecondary={onClickSecondaryButton}
      />
    </SlideLayout>
  );
};
