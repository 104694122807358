import {
  Day,
  UpdateWorkingLocationWeeklyScheduleMutation2Input,
  WorkingLocationWeeklyScheduleUpdateDeletionEnumInput,
} from "@clockwise/schema";
import {
  CopyButtonStyle,
  IDaySetting,
  IWorkingHours,
  MeetingOrWorkingHours,
  SlotRangeWrapper,
  WorkDay,
  getDayOnOffMap,
  getDaySettingFromSlotRanges,
  getSlotRangesMap,
  getWorkDaysFromWorkingHours,
  validateDayMap,
} from "@clockwise/web-commons/src/ui/working-hours";
import * as React from "react";
import { useFeatureFlag } from "../../../../launch-darkly";
import { logger } from "../../../../util/logger.util";
import { useWorkingLocation } from "../../../account-preferences-working-location";
import { WeekSetting } from "./week-setting";

export interface WeekSettingContainerProps {
  workingHours: IWorkingHours;
  copyButtonStyle: CopyButtonStyle | null;
  isMeetingOrWorkingHours: MeetingOrWorkingHours;
  onUpdateWorkingHours: (workingHours: IWorkingHours, updatedDayOfWeek: Day) => void;
}

export const WeekSettingContainer = ({
  workingHours,
  copyButtonStyle,
  isMeetingOrWorkingHours,
  onUpdateWorkingHours,
}: WeekSettingContainerProps) => {
  const [workDays, setWorkDays] = React.useState(getWorkDaysFromWorkingHours(workingHours));
  const [slotRangesMap, setSlotRangesMap] = React.useState(getSlotRangesMap(workDays));
  const [currentDayOnOffMap, setCurrentDayOnOffMap] = React.useState(getDayOnOffMap(workDays));
  const [isValidDayMap, setIsValidDayMap] = React.useState(validateDayMap(currentDayOnOffMap));

  const [isOnWorkingLocation] = useFeatureFlag("WorkingLocation");

  const {
    workingLocations,
    workingLocationWeeklySchedule,
    onUpdateWorkingLocationWeeklySchedule,
  } = useWorkingLocation();

  React.useEffect(() => {
    const updatedWorkDays = getWorkDaysFromWorkingHours(workingHours);
    const updatedDayOnOffMap = getDayOnOffMap(updatedWorkDays);

    setWorkDays(updatedWorkDays);
    setSlotRangesMap(getSlotRangesMap(updatedWorkDays));
    setCurrentDayOnOffMap(updatedDayOnOffMap);
    setIsValidDayMap(validateDayMap(updatedDayOnOffMap));
  }, [workingHours]);

  const updateWorkDays = (updatedWorkDays: WorkDay[], updatedDayOfWeek: Day) => {
    const updatedDaySettings: IDaySetting[] = [];

    updatedWorkDays.forEach(({ day, setting }) => {
      if (!setting.isOff) {
        updatedDaySettings.push({
          day: day,
          setting: {
            minSlot: setting.minSlot,
            maxSlot: setting.maxSlot,
            excludedSlots: setting.excludedSlots,
          },
        });
      }
    });

    const updatedWorkingHours = { ...workingHours, daySettings: updatedDaySettings };
    onUpdateWorkingHours(updatedWorkingHours, updatedDayOfWeek);
  };

  const handleToggleDay = (day: Day) => {
    const updatedMap = { ...currentDayOnOffMap };
    updatedMap[day] = !currentDayOnOffMap[day];
    setCurrentDayOnOffMap(updatedMap);

    const updatedWorkDays = [...workDays];
    const workDayIndex = workDays.findIndex((workDay) => workDay.day === day);
    updatedWorkDays.splice(workDayIndex, 1, {
      day,
      setting: {
        ...workDays[workDayIndex].setting,
        isOff: !updatedMap[day],
      },
    } as WorkDay);

    const isUpdatedDayMapValid = validateDayMap(updatedMap);
    setIsValidDayMap(isUpdatedDayMapValid);

    if (isUpdatedDayMapValid) {
      updateWorkDays(updatedWorkDays, day);
    }
  };

  const handleSlotChange = (day: Day, updatedSlots: SlotRangeWrapper) => {
    const updatedSlotRangeMap = { ...slotRangesMap };
    const updatedSetting = getDaySettingFromSlotRanges(updatedSlots.slots);
    const updatedWorkDays = [...workDays];
    updatedSlotRangeMap[day] = updatedSlots;
    setSlotRangesMap(updatedSlotRangeMap);
    updatedWorkDays.splice(
      workDays.findIndex((workDay) => workDay.day === day),
      1,
      { day, setting: updatedSetting } as WorkDay,
    );
    if (updatedSlots.isValid) {
      updateWorkDays(updatedWorkDays, day);
    }
  };

  const handleCopyDaySlotRanges = (copyDay: Day) => {
    // update settings for each day that is not off
    const copyDaySettings = workDays.find((workDay) => workDay.day === copyDay)?.setting;
    let updatedWorkDays;

    if (copyDaySettings) {
      updatedWorkDays = workDays.map(({ day, setting }) => {
        if (setting.isOff) {
          return { day, setting } as WorkDay;
        }
        return { day, setting: copyDaySettings } as WorkDay;
      });
    }

    // get updated slot ranges from the new updated work days
    if (updatedWorkDays) {
      const updatedSlotRangesMap = getSlotRangesMap(updatedWorkDays);
      setSlotRangesMap(updatedSlotRangesMap);
      updateWorkDays(updatedWorkDays, copyDay);
    }

    // update working location settings for each day
    const copyLocationId = workingLocationWeeklySchedule.find((w) => w.day === copyDay)
      ?.workingLocation.id;

    let input: UpdateWorkingLocationWeeklyScheduleMutation2Input = { updates: [], deletions: [] };
    const workingLocation = copyLocationId ? getWorkingLocationById(copyLocationId) : null;

    if (!copyLocationId) {
      input.deletions = workDays
        .filter((wd) => !wd.setting.isOff)
        .map(({ day }) => (day as string) as WorkingLocationWeeklyScheduleUpdateDeletionEnumInput);
    } else if (workingLocation) {
      input.updates = workDays
        .filter((wd) => !wd.setting.isOff)
        .map(({ day }) => ({ day, workingLocation }));
    } else {
      return logger.error("Failed to handleCopyDaySlotRanges because invalid working location id", {
        copyLocationId,
      });
    }

    if (isOnWorkingLocation) {
      onUpdateWorkingLocationWeeklySchedule(input);
    }
  };

  const handleLocationChange = (day: string, workingLocationId: string | null) => {
    let input: UpdateWorkingLocationWeeklyScheduleMutation2Input = { updates: [], deletions: [] };
    const workingLocation = workingLocationId ? getWorkingLocationById(workingLocationId) : null;

    if (!workingLocationId) {
      input.deletions = [day as WorkingLocationWeeklyScheduleUpdateDeletionEnumInput];
    } else if (workingLocation) {
      input.updates = [{ day, workingLocation }];
    } else {
      return logger.error(
        "Failed to updateWorkingLocationWeeklySchedule because invalid working location id",
        { workingLocationId },
      );
    }

    onUpdateWorkingLocationWeeklySchedule(input);
  };

  const getWorkingLocationById = (id: string) => {
    return (workingLocations || []).find((wl) => wl.id === id);
  };

  return (
    <WeekSetting
      slotRangesMap={slotRangesMap}
      currentDayOnOffMap={currentDayOnOffMap}
      copyButtonStyle={copyButtonStyle}
      isMeetingOrWorkingHours={isMeetingOrWorkingHours}
      isValidDayMap={isValidDayMap}
      onToggleDay={handleToggleDay}
      onClickCopy={handleCopyDaySlotRanges}
      onSlotChange={handleSlotChange}
      onLocationChange={handleLocationChange}
    />
  );
};
