import { graphql } from "react-relay";

export const fragments = {
  org: graphql`
    fragment WorkPreferenceStartEndGoal_org on Org {
      id
      userGoals(first: 1000000) {
        edges {
          node {
            id
            goalId
            name
            isDeleted
            options {
              syncToGoogle
              specificOptions {
                ... on GoalPreferredWorkOptions {
                  keepEndOfDayOpen
                  keepStartOfDayOpen
                }
              }
            }
          }
        }
      }
    }
  `,
};
