import { Button } from "@clockwise/design-system";
import { ExpandLess, ExpandMore } from "@clockwise/design-system/icons";
import { CalendarEvent } from "@clockwise/web-commons/src/components/calendar/calendar-event/CalendarEvent";
import { EventType } from "@clockwise/web-commons/src/util/ActiveEventContext";
import classNames from "classnames";
import { includes, map, max, noop, slice, some, uniqBy } from "lodash";
import { DateTime } from "luxon";
import React, { createContext, useContext } from "react";
import { useToggle } from "usehooks-ts";
import { useReadCalendarColorForCalendarId } from "../hooks/CalendarColorsContext";
import usePlannerMetaData from "../hooks/usePlannerMetaData";
import {
  Cell,
  ElementColumn,
  positionObjectsInDates,
} from "../planner-all-day-events/utils/PositionObjectsInDates";
import { PlannerEventCard } from "../types";
import { getCalendarEventStatus } from "../util/getCalendarEventStatus";
import { AddWorkingLocationCell } from "./AddWorkingLocationCell";

const MAX_ALL_DAY_EVENT_COUNT = 3;

type Props = {
  dateTimes: DateTime[];
  events?: PlannerEventCard[];
  onClick: (calendarId: string, externalEventId: string, type: EventType) => void;
};

type State = {
  dateToColumnMap: Map<string, ElementColumn<PlannerEventCard>>;
  isExpanded: boolean;
  objectToCellMap: Map<PlannerEventCard, Cell>;
  onClick: (calendarId: string, externalEventId: string, type: EventType) => void;
  toggleIsExpanded: () => void;
};

const defaultState = {
  dateToColumnMap: new Map(),
  isExpanded: false,
  objectToCellMap: new Map(),
  onClick: noop,
  toggleIsExpanded: noop,
};

const PlannerHeadWorkingLocationsContext = createContext<State>(defaultState);

export const PlannerHeadWorkingLocations = ({ dateTimes, events = [], onClick }: Props) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const uniqueEvents = uniqBy(events, ({ key }) => key);

  const { dateToColumnMap, objectToCellMap } = positionObjectsInDates(dateTimes, uniqueEvents);

  return (
    <PlannerHeadWorkingLocationsContext.Provider
      value={{ isExpanded, toggleIsExpanded, dateToColumnMap, objectToCellMap, onClick }}
    >
      <div className={classNames("cw-flex cw-flex-row cw-flex-1 cw-flex-grow-0")}>
        <ExpandToggle />
        <div
          className={classNames(
            "cw-max-h-[227px] cw-w-full cw-pb-[2px] [scrollbar-gutter:stable]",
            isExpanded ? "cw-overflow-y-scroll" : "cw-overflow-y-hidden",
          )}
        >
          <div className="cw-relative cw-w-full">
            <AllDayColumns />
            <AllDayCards />
          </div>
        </div>
      </div>
    </PlannerHeadWorkingLocationsContext.Provider>
  );
};

const ExpandToggle = () => {
  const { isExpanded, toggleIsExpanded, objectToCellMap } = useContext(
    PlannerHeadWorkingLocationsContext,
  );

  const expandable = some(
    [...objectToCellMap.values()],
    (cell) => cell.column + 1 > MAX_ALL_DAY_EVENT_COUNT,
  );

  return (
    <div className="cw-w-[40px] cw-max-h-[227px] cw-relative">
      {expandable ? (
        <div className="-cw-ml-2 -cw-mt-[7px] cw-absolute cw-bottom-1">
          <Button
            onClick={toggleIsExpanded}
            variant="text"
            size="small"
            aria-label="Show all all-day events"
          >
            <span>{isExpanded ? <ExpandLess /> : <ExpandMore />}</span>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const MoreEvents = ({ onClick, moreCount }: { onClick: () => void; moreCount: number }) => {
  return (
    <div className="cw-pt-1">
      <Button variant="text" size="mini" fullWidth onClick={onClick}>
        {`${moreCount} more`}
      </Button>
    </div>
  );
};

const AllDayColumns = () => {
  const { dateToColumnMap } = useContext(PlannerHeadWorkingLocationsContext);

  return (
    <div className="cw-flex cw-flex-row cw-flex-1 cw-overflow-auto">
      {map([...dateToColumnMap], ([date, elementColumn]) => (
        <AllDayColumn key={date} date={date} column={elementColumn} />
      ))}
    </div>
  );
};

const AllDayColumn = ({
  date,
  column,
}: {
  date: string;
  column: ElementColumn<PlannerEventCard>;
}) => {
  const { isExpanded, toggleIsExpanded } = useContext(PlannerHeadWorkingLocationsContext);

  const dateTime = DateTime.fromISO(date);
  const hasMoreInColumn = column.lastPosition + 1 > MAX_ALL_DAY_EVENT_COUNT;
  const showAllCells = isExpanded || !hasMoreInColumn;

  // get the correct number of cells to display;
  // if not expanded make sure to grab minimum number of cells to correctly place the expand button
  // see "Multiple Multi Day Events" storybook
  const displayCells = showAllCells
    ? slice(column.elements, 0, max([column.lastPosition + 1, MAX_ALL_DAY_EVENT_COUNT - 1]))
    : slice(column.elements, 0, MAX_ALL_DAY_EVENT_COUNT - 1);

  const nonEmptyDisplayCells = displayCells.filter((cell) => !!cell);

  const moreCount = column.elementCount - nonEmptyDisplayCells.length;

  return (
    <div className="cw-flex-1 cw-min-w-0">
      <section
        className="cw-w-full"
        aria-label={`All-day events on ${dateTime.toLocaleString(DateTime.DATETIME_FULL)}`}
      >
        {map(displayCells, (event, index) => {
          const key = event
            ? `${date}-${event?.key || "nokey"}`
            : `${date}-for-placement-only-${index}`;

          return <AllDayCell key={key} event={event || null} />;
        })}
        {!isExpanded && moreCount > 0 && (
          <MoreEvents onClick={toggleIsExpanded} moreCount={moreCount} />
        )}
      </section>
    </div>
  );
};

const AllDayCell = ({ event }: { event: PlannerEventCard | null }) =>
  event ? <AllDayCellTabable event={event} /> : <AllDayCellEmpty />;

const AllDayCellTabable = ({ event: { text, interval } }: { event: PlannerEventCard }) => (
  <div
    role="button"
    tabIndex={0}
    className="cw-text-muted cw-h-[26px]"
    aria-label={`${text} - ${interval.toLocaleString()}`}
  ></div>
);

const AllDayCellEmpty = () => <AddWorkingLocationCell />;

const AllDayCards = () => {
  const { objectToCellMap } = useContext(PlannerHeadWorkingLocationsContext);
  const events = map([...objectToCellMap.keys()], (event) => event);

  return (
    <div>
      {map(events, (event) => (
        <AllDayCard key={event.key} event={event} />
      ))}
    </div>
  );
};

const AllDayCard = ({ event }: { event: PlannerEventCard }) => {
  const { primaryCalendarId } = usePlannerMetaData();
  const { isExpanded, objectToCellMap, dateToColumnMap, onClick } = useContext(
    PlannerHeadWorkingLocationsContext,
  );

  const isOwnEvent = includes(event.calendarIds, primaryCalendarId);
  const calendarColorSet = useReadCalendarColorForCalendarId(event.calendarIds[0], isOwnEvent);

  const cell = objectToCellMap.get(event);
  const dayConst = (1 / dateToColumnMap.size) * 100;

  if (!cell) {
    return null;
  }

  const isMaxCellAndLastCell = cell?.column + 1 === MAX_ALL_DAY_EVENT_COUNT && cell?.isLast;
  const isPastMaxCell = cell?.column + 1 >= MAX_ALL_DAY_EVENT_COUNT;
  if (!isExpanded && !isMaxCellAndLastCell && isPastMaxCell) {
    return null;
  }

  const handleClick = () => {
    if (!event.externalEventId || !event.calendarIds[0]) {
      return;
    }

    onClick(event.calendarIds[0], event.externalEventId, EventType.WorkingLocation);
  };

  return (
    <div
      className="cw-p-[2px] cw-absolute"
      style={{
        width: `${cell.colSpan * dayConst}%`,
        left: `${cell.row * dayConst}%`,
        top: `${(cell.column || 0) * 26}px`,
      }}
      aria-hidden="true"
    >
      <CalendarEvent
        active={false}
        annotation={undefined}
        badge={undefined}
        calendarColorSet={calendarColorSet}
        deemphasis={event.deemphasis}
        eventColorSet={isOwnEvent ? event.eventCategoryColor : undefined}
        onClick={handleClick}
        status={getCalendarEventStatus(event)}
        spacing="Full"
        text={event.text}
        variant="SingleLine"
      />
    </div>
  );
};
