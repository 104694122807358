import { FlexibilityRange } from "@clockwise/schema";

export const getFlexibilityRangeFromString = (flexibilityAsString: "Week" | "Day" | null) => {
  switch (flexibilityAsString) {
    case "Week":
      return FlexibilityRange.Week;
    case "Day":
      return FlexibilityRange.Day;
    default:
      return null;
  }
};
