// schema
// import * as ISchema from 'src/__schema__';

// libraries
import * as React from "react";
import { createFragmentContainer } from "react-relay";

// sudo-switch-user imports
import { styles as sudoStyles } from "#webapp/src/components/sudo-wrapper/SudoWrapper.styles";
import { sudoSwitchUserFragments } from "./SudoSwitchUser.gql";
import { styles } from "./SudoSwitchUser.styles";
import { IContainer, IProps, IState } from "./SudoSwitchUserTypes";

// state

// util
import { sudoSwitchUser } from "#webapp/src/mutations";
import { doSudoLogin } from "#webapp/src/util/auth.util";
import { windowLocation } from "#webapp/src/util/location.util";

// material-ui
import { Button, LinearProgress, TextField } from "@clockwise/design-system";
import { Paper } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import toast from "react-hot-toast";

///////////////
// Component //
///////////////
class SudoSwitchUserCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
      email: "",
    };
  }

  public handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: e.target.value,
    });
  };

  private onClick = () => {
    const email = this.state.email.trim();
    if (!email) {
      return;
    }

    this.setState({ loading: true });

    sudoSwitchUser(
      this.props.relay.environment,
      { email, jwtType: "Full" },
      () => this.onSwitchSuccess(),
      () => this.onSwitchFailure(),
    );
  };

  private onSwitchSuccess = () => {
    this.setState({ loading: false });
    toast.success(`Success, switching to: ${this.state.email}`);
    doSudoLogin();
    setTimeout(() => {
      // hard reload the page to /
      windowLocation.assign("SudoSwitchUserSuccess", "/app");
    }, 500);
  };

  private onSwitchFailure = () => {
    this.setState({ loading: false });
    toast.error(`Failed to switch users.`);
  };

  public render() {
    return (
      <Paper
        elevation={2}
        className="cw-font-body cw-p-5 cw-flex cw-flex-grow cw-flex-col cw-relative cw-gap-4"
      >
        <div style={sudoStyles.title}>Switch User</div>
        <div style={sudoStyles.row}>
          <TextField
            label="Email Address"
            onChange={this.handleEmailChange}
            value={this.state.email}
          />
        </div>
        <div style={sudoStyles.row}>
          <Button sentiment="positive" onClick={() => this.onClick()} disabled={this.state.loading}>
            Switch user
          </Button>
        </div>
        {this.state.loading ? <LinearProgress /> : null}
      </Paper>
    );
  }
}

export const SudoSwitchUserStyled = withStyles(styles)(SudoSwitchUserCmpt);

export const SudoSwitchUser = createFragmentContainer<IContainer>(
  SudoSwitchUserStyled,
  sudoSwitchUserFragments,
);
