import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { Profile } from "@clockwise/web-commons/src/util/profile.util";
import React from "react";
import { useReadCalendarColors } from "../../web-app-calendar/hooks/CalendarColorsContext";
import { getStringListOfAttendees } from "../utils/getStringListOfAttendees";

type ScopedAttendee = {
  [k: string]: unknown;
} & (
  | ({
      isYou: boolean;
      profile?: Profile | null;
      person?: undefined;
    } & (
      | { primaryCalendarId: string; primaryCalendar?: undefined }
      | { primaryCalendarId?: undefined; primaryCalendar: string }
    ))
  | {
      person: {
        isMe: boolean;
        displayName?: string;
        givenName?: string | null;
        familyName?: string | null;
        externalImageUrl?: string | null;
        email: string;
      };
      profile?: undefined;
      isYou?: undefined;
      primaryCalendarId?: undefined;
      primaryCalendar?: undefined;
    }
);

export const ECAttendeeStack = ({
  attendees,
  maxShown = 3,
  borderColorOverride,
}: {
  attendees: ScopedAttendee[];
  maxShown?: number;
  borderColorOverride?: string;
}) => {
  const calendarColors = useReadCalendarColors();

  // avoid showing just a +1 when possible (e.g. 4 attendees, maxShown = 3)
  const attendeeLengthDependentMaxShown =
    attendees.length - 1 === maxShown ? maxShown - 1 : maxShown;

  const overflowAttendeeNames = getStringListOfAttendees(
    attendees.slice(attendeeLengthDependentMaxShown),
  );

  return (
    <div className="cw-flex cw-items-center cw-justify-center">
      <AttendeeAvatarStack
        maxShown={attendeeLengthDependentMaxShown}
        overlap
        size="medium"
        disableAnimation
        overflowToolTipContent={overflowAttendeeNames}
      >
        {attendees.map((attendee) => {
          const attendeeCalendarId = attendee.person
            ? attendee.person.email
            : attendee.primaryCalendarId ?? attendee.primaryCalendar;

          const attendeeProfile: Profile = attendee.profile ?? {
            givenName: attendee.person?.givenName,
            familyName: attendee.person?.familyName,
            externalImageUrl: attendee.person?.externalImageUrl,
          };

          const borderColor: string | undefined =
            borderColorOverride || (calendarColors?.[attendeeCalendarId]?.border ?? undefined);

          return (
            <AttendeeAvatar
              key={attendeeCalendarId}
              profile={attendeeProfile}
              calendarId={attendeeCalendarId}
              size="medium"
              isInOverlap
              borderColor={borderColor}
            />
          );
        })}
      </AttendeeAvatarStack>
    </div>
  );
};
