import { ApolloError, useMutation } from "@apollo/client";
import { useUpdateTimeSuggestion } from "@clockwise/web-commons/src/util/TimeSuggestionContext";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setSwitchingProposalUIInFlight } from "../../../../state/actions/ui-flow-on-screen.actions";
import { useActiveProposal } from "../../hooks/useActiveProposal";
import {
  SelectSchedulingOptionDocument,
  SelectSchedulingOptionMutation,
} from "../apollo/__generated__/SelectSchedulingOption.generated";
import { GQLProposal } from "../utils/types";

type UseSelectSchedulingOptionProps = {
  onCompleted?: (data: SelectSchedulingOptionMutation) => void;
  onError?: (error: ApolloError) => void;
};

type UseSelectSchedulingOptionResult = [
  (selectedIndex: number) => Promise<void>,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

const getOptimisticResponse = (
  msgId: string | undefined,
  conversationId: string | undefined,
  proposal: GQLProposal | undefined,
  selectedIndex: number,
): SelectSchedulingOptionMutation | undefined => {
  if (!msgId || !conversationId || !proposal) return undefined;

  const newOptionDetails =
    proposal.options.__typename === "SchedulingOptions" ? proposal.options.optionDetails : [];

  // Given that all proposals that have scheduling options to be selected will have only one diffBlock and one diff,
  // we are going to extract that diff and optimistically update the time to match the new selected option.
  const [diffBlock, ...otherDiffBlocks] = proposal.diffBlocks;
  const [diff, ...otherDiffs] = diffBlock.diffs;
  const newDiff = {
    ...diff,
    time: newOptionDetails[selectedIndex].interval,
  };

  return {
    __typename: "Mutation",
    selectSchedulingOption: {
      __typename: "SelectSchedulingOptionPayload",
      message: {
        __typename: "ProposalResponse",
        id: msgId,
        conversationId: conversationId,
        proposal: {
          ...proposal,
          diffBlocks: [
            {
              ...diffBlock,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TS doesn't understand how to merge these types with the mutation types.
              diffs: [newDiff, ...otherDiffs],
            },
            ...otherDiffBlocks,
          ],
          options: {
            __typename: "SchedulingOptions",
            selectedIndex,
            optionDetails:
              proposal.options.__typename === "SchedulingOptions"
                ? proposal.options.optionDetails
                : [],
          },
        },
      },
    },
  };
};

export const useSelectSchedulingOption = ({
  onCompleted,
  onError,
}: UseSelectSchedulingOptionProps): UseSelectSchedulingOptionResult => {
  const { msgId, conversationId, proposal } = useActiveProposal();
  const [selectSchedulingOption, { loading, error }] = useMutation(SelectSchedulingOptionDocument, {
    context: { useBatching: false },
  });
  const { setTimeSuggestionLoading } = useUpdateTimeSuggestion();
  const dispatch = useDispatch();

  const onSelectSchedulingOption = useCallback(
    async (selectedIndex: number) => {
      dispatch(setSwitchingProposalUIInFlight());

      setTimeSuggestionLoading(true);

      const optimisticResponse = getOptimisticResponse(
        msgId,
        conversationId,
        proposal,
        selectedIndex,
      );
      await selectSchedulingOption({
        onCompleted,
        onError,
        variables: { input: { selectedIndex } },
        optimisticResponse,
      });
    },
    [
      dispatch,
      setTimeSuggestionLoading,
      msgId,
      conversationId,
      proposal,
      selectSchedulingOption,
      onCompleted,
      onError,
    ],
  );

  return [onSelectSchedulingOption, { loading, error }];
};
