import { Tooltip } from "@clockwise/design-system";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { TeamAvatar } from "@clockwise/web-commons/src/ui/team-avatar/TeamAvatar";
import { Profile } from "@clockwise/web-commons/src/util/profile.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { motion } from "framer-motion";
import { clamp, compact } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { AvatarPerson } from "../../avatar";
import { FocusTimeChartDocument } from "./__generated__/FocusTimeChart.v2.generated";

type TimeStats = {
  focusMinutes: number;
  busyMinutes: number;
};

const CHART_SIZE = 470;
const BORDER_SIZE = 1;
const BUFFER_SIZE = 10;
const RENDERABLE_SIZE = CHART_SIZE - BORDER_SIZE - BUFFER_SIZE;
const MAX_FOCUS_TIME = 20 * 60; // 20 hours
const MAX_MEETING_TIME = 15 * 60; // 15 hours

function getPercentage(minutes: number, metric: "focus" | "meeting") {
  const max = metric === "focus" ? MAX_FOCUS_TIME : MAX_MEETING_TIME;
  return clamp(minutes / max, 0, max);
}

type Props = {
  teamId: string;
};

export const FocusTimeChart = ({ teamId }: Props) => {
  const startDate = DateTime.now().startOf("week");
  const { data } = useGatewayQuery(FocusTimeChartDocument, {
    variables: {
      teamId,
      startDate: startDate.toISODate(),
      endDate: startDate.endOf("week").toISODate(),
      timeZone: getRenderTimeZone(),
    },
    errorPolicy: "all",
  });

  const teamName = data?.team?.name ?? "Team";

  const users = compact(data?.team?.members ?? []);

  const teamStatsTotal = users.reduce(
    (acc, user) => {
      if (user.member) {
        acc.focusMinutes += user.member.calendarTimeStats?.focusMinutes ?? 0;
        acc.busyMinutes += user.member.calendarTimeStats?.busyMinutes ?? 0;
      }
      return acc;
    },
    { focusMinutes: 0, busyMinutes: 0 },
  );

  const teamStatsAverage = {
    focusMinutes: Math.round(teamStatsTotal.focusMinutes / users.length),
    busyMinutes: Math.round(teamStatsTotal.busyMinutes / users.length),
  } as TimeStats;

  return (
    <div className="cw-flex cw-gap-2 cw-items-center">
      <div
        className="cw-heading-sm cw-text-center cw-rotate-180"
        style={{ writingMode: "vertical-rl" }}
      >
        Low Focus Time
      </div>
      <div className="cw-inline-block">
        <div className="cw-heading-sm cw-text-center cw-mb-2">Fewer meetings</div>
        <div
          className="cw-bg-neutral cw-relative"
          style={{ width: `${CHART_SIZE}px`, height: `${CHART_SIZE}px` }}
        >
          <div className="charting-area cw-w-full cw-h-full cw-absolute cw-border cw-border-solid cw-border-default cw-rounded-md">
            <div className="red-zone cw-top-1/2 cw-left-0 cw-bottom-0 cw-right-1/2 cw-absolute cw-bg-warning" />
            <div className="divider cw-absolute cw-bg-[#D6D6D6] cw-w-full cw-h-px  cw-top-1/2 cw-left-0" />
            <div className="divider cw-absolute cw-bg-[#D6D6D6] cw-h-full cw-w-px  cw-left-1/2 cw-top-0" />
            {users.map(
              (user) =>
                user.member && (
                  <UserBadge
                    profile={user.member.person}
                    stats={user.member.calendarTimeStats}
                    key={user.id}
                  />
                ),
            )}
            {users.length > 0 && (
              <TeamAverageBadge stats={teamStatsAverage} teamName={teamName} teamId={teamId} />
            )}
          </div>
        </div>
        <div className="cw-heading-sm cw-text-center cw-mt-2">Lots of meetings</div>
      </div>
      <div className="cw-heading-sm cw-text-center" style={{ writingMode: "vertical-rl" }}>
        High Focus Time
      </div>
    </div>
  );
};

function useCalculatePosition(stats: TimeStats | null) {
  if (!stats) {
    return { top: 0, left: 0 };
  }
  const { busyMinutes, focusMinutes } = stats;
  // Calculate the position of the user badge based on the user's focus time and meeting minutes.
  const focusPercentage = getPercentage(focusMinutes, "focus");
  const meetingPercentage = getPercentage(busyMinutes, "meeting");
  const top = meetingPercentage * CHART_SIZE;
  const left = focusPercentage * CHART_SIZE;

  const avatarSize = 30;
  const halfAvatarSize = avatarSize / 2;
  const adjustedTop = Math.round(
    clamp(top - halfAvatarSize, BORDER_SIZE + BUFFER_SIZE, RENDERABLE_SIZE - avatarSize),
  );
  const adjustedLeft = Math.round(
    clamp(left - halfAvatarSize, BORDER_SIZE + BUFFER_SIZE, RENDERABLE_SIZE - avatarSize),
  );

  return { top: adjustedTop, left: adjustedLeft };
}

const TeamAverageBadge = ({
  stats,
  teamName,
  teamId,
}: {
  stats: TimeStats;
  teamName: string;
  teamId: string;
}) => {
  const { top, left } = useCalculatePosition(stats);

  if (!stats) {
    return null;
  }

  return (
    <div>
      <Tooltip
        title={
          <div>
            <div>
              <strong>TEAM AVERAGE</strong>
            </div>
            <div>
              <strong>Focus Time:</strong> {Math.floor(stats.focusMinutes / 60)} hours
            </div>
            <div>
              <strong>Meeting Time:</strong> {Math.floor(stats.busyMinutes / 60)} hours
            </div>
          </div>
        }
      >
        <motion.div
          className="cw-absolute"
          initial={{ top: "50%", left: "50%" }}
          animate={{ top, left }}
        >
          <TeamAvatar size="normal" id={teamId} teamName={teamName} />
        </motion.div>
      </Tooltip>
    </div>
  );
};

const UserBadge = ({ profile, stats }: { profile: Profile | null; stats: TimeStats | null }) => {
  const { top, left } = useCalculatePosition(stats);

  if (!stats) {
    return null;
  }

  return (
    <motion.div
      className="cw-absolute"
      initial={{ top: "50%", left: "50%" }}
      animate={{ top, left }}
    >
      <AvatarPerson
        size="medium"
        hasOverlap
        person={{ profile }}
        orgPerson={{ profile }}
        backupCalendarId=""
        toolTipContent={
          <div>
            <div>
              <strong>
                {profile?.givenName} {profile?.familyName}
              </strong>
            </div>
            <div>
              <strong>Focus Time:</strong> {Math.floor(stats.focusMinutes / 60)} hours
            </div>
            <div>
              <strong>Meeting Time:</strong> {Math.floor(stats.busyMinutes / 60)} hours
            </div>
          </div>
        }
      />
    </motion.div>
  );
};
