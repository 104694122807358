import { Button, Tooltip } from "@clockwise/design-system";
import { ExpandContent, SvgIconComponent } from "@clockwise/icons";
import { CloseRounded, LayersRounded, MoreVertRounded } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";
import { ProposalRenderSurface } from "../../chat/constants";

const LayersEnable = () => <LayersRounded fontSize="small" htmlColor="#0335ff" />;
const LayersDisable = () => <LayersRounded fontSize="small" htmlColor="#575757" />;

type CardHeaderProps = {
  active: boolean;
  disabled?: boolean;
  eventName: string;
  isLayersActive: boolean;
  isLayersDisabled: boolean;
  isRemoveCancel: boolean;
  lineThrough?: boolean;
  onExpandCard?: () => void;
  onRemoveCard?: () => void;
  onToggleLayers?: () => void;
  toggleLayersTooltip?: string;
  variant?: ProposalRenderSurface;
  customSeeMoreIcon?: SvgIconComponent;
};
export const CardHeader = ({
  active,
  disabled,
  eventName,
  isLayersActive,
  isLayersDisabled,
  isRemoveCancel,
  lineThrough,
  onExpandCard,
  onRemoveCard,
  onToggleLayers,
  toggleLayersTooltip,
  variant = "AI_CHAT",
  customSeeMoreIcon,
}: CardHeaderProps) => (
  <div className="cw-w-full cw-flex cw-justify-between cw-text-start cw-items-center cw-h-[24px]">
    <button
      aria-label={eventName}
      className={classNames(
        "cw-border-none cw-bg-transparent cw-text-start cw-p-0 cw-m-0",
        "cw-body-base cw-font-semibold cw-text-12 cw-truncate cw-flex-1 cw-mr-3",
        {
          "hover:cw-cursor-pointer": !disabled,
          "group-hover:cw-text-positive": !disabled,
          "cw-line-through": !!lineThrough,
        },
      )}
      style={active ? { maxWidth: 200 } : { width: 200 }} // DevNote: width must be set for truncation to work; 200px may not represent the final width
      onClick={onExpandCard}
      tabIndex={0}
    >
      {eventName}
    </button>
    {!disabled && (
      <CardActions
        active={active}
        isLayersActive={isLayersActive}
        isLayersDisabled={isLayersDisabled}
        isRemoveCancel={isRemoveCancel}
        onExpandCard={onExpandCard}
        onRemoveCard={variant === "SHARED_PROPOSAL" ? undefined : onRemoveCard}
        onToggleLayer={onToggleLayers}
        toggleLayersTooltip={toggleLayersTooltip}
        customSeeMoreIcon={customSeeMoreIcon}
      />
    )}
  </div>
);

const CardActions = ({
  active,
  isLayersActive,
  isLayersDisabled,
  isRemoveCancel,
  onExpandCard,
  onRemoveCard,
  onToggleLayer,
  toggleLayersTooltip,
  customSeeMoreIcon: CustomSeeMoreIcon,
}: {
  active: boolean;
  isLayersActive: boolean;
  isLayersDisabled: boolean;
  isRemoveCancel: boolean;
  onExpandCard?: () => void;
  onRemoveCard?: () => void;
  onToggleLayer?: () => void;
  toggleLayersTooltip?: string;
  customSeeMoreIcon?: SvgIconComponent;
}) => {
  const actionEls = [];

  const SeeMoreIcon = CustomSeeMoreIcon ? (
    <CustomSeeMoreIcon className="cw-w-3.5 cw-h-3.5 cw-text-subtle" />
  ) : (
    <MoreVertRounded className="cw-w-4 cw-h-4" />
  );

  if (onRemoveCard) {
    actionEls.push(
      <CardActionButton
        key="close"
        Icon={CloseRounded}
        onClick={onRemoveCard}
        tooltipText={isRemoveCancel ? "Cancel proposal" : "Remove from proposal"}
      />,
    );
  }

  if (onToggleLayer) {
    const tooltipText =
      toggleLayersTooltip ||
      (isLayersActive ? "Hide attendee calendars" : "Show attendee calendars");
    actionEls.push(
      <CardActionButton
        disabled={isLayersDisabled}
        key="layers"
        Icon={isLayersActive ? LayersEnable : LayersDisable}
        onClick={onToggleLayer}
        tooltipText={tooltipText}
      />,
    );
  }

  if (onExpandCard) {
    actionEls.push(
      <CardActionButton
        key="expand"
        Icon={ExpandContent}
        onClick={onExpandCard}
        tooltipText="Open event"
      />,
    );
  }

  if (actionEls.length === 0 && !CustomSeeMoreIcon) {
    return <></>;
  }

  if (actionEls.length === 1 && !CustomSeeMoreIcon) {
    return actionEls[0];
  }

  return !active ? SeeMoreIcon : <div className="cw-flex cw-flex-row cw-gap-1">{actionEls}</div>;
};

const CardActionButton = ({
  disabled = false,
  Icon,
  onClick,
  tooltipText,
}: {
  disabled?: boolean;
  Icon: SvgIconComponent;
  onClick: () => void;
  tooltipText: string;
}) => {
  return (
    <Tooltip title={tooltipText} placement="top">
      <Button
        title={tooltipText}
        disabled={disabled}
        onClick={onClick}
        size="mini"
        startIcon={Icon}
        variant="outlined"
      />
    </Tooltip>
  );
};
