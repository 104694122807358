import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";
import { AIMarkdown } from "../../chat/ai-chat/components/ai-markdown";
import { ECAttendeeStack } from "../atoms/ECAttendeeStack";
import { Tradeoff } from "./ECTradeoffsWithDrawer";

export const TradeoffItem = ({
  variant,
  tradeoff,
}: {
  variant: "warning" | "neutral";
  tradeoff: Tradeoff;
}) => {
  const hasAttendees = !isEmpty(tradeoff.attendees);
  return (
    <div className="cw-flex cw-items-center cw-my-1 cw-w-full">
      <div
        className={classNames(
          "cw-caption cw-flex cw-items-center cw-whitespace-nowrap cw-max-w-[153px]",
          {
            "cw-text-muted": variant === "neutral",
            "cw-text-warning": variant === "warning",
          },
        )}
      >
        <AIMarkdown text={tradeoff.title} />
      </div>
      <div
        className={classNames("cw-mx-2 cw-border-b cw-border-dotted cw-flex-grow", {
          "cw-border-default": variant === "neutral",
          "cw-border-warning": variant === "warning",
        })}
      />
      {hasAttendees && <ECAttendeeStack attendees={tradeoff.attendees} />}
    </div>
  );
};

export const ECTradeoffList = ({
  tradeoffList,
  variant,
}: {
  tradeoffList: Tradeoff[];
  variant: "warning" | "neutral";
}) => {
  return (
    <div className="cw-flex-col">
      {tradeoffList.map((tradeoff, index) => (
        <TradeoffItem variant={variant} tradeoff={tradeoff} key={`${tradeoff.title}-${index}`} />
      ))}
    </div>
  );
};
