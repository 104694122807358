import React from "react";
import { textSplitByNewLine } from "../../utils/textSplitByNewLine";
import { GQLAssistantMessageSchedulingLinksResponse } from "../../utils/types";
import { LinkCard } from "./LinkCard";

export interface SchdulingLinksResponseProps {
  message: GQLAssistantMessageSchedulingLinksResponse;
}

export const SchedulingLinksResponse = ({ message }: SchdulingLinksResponseProps) => {
  const { id, linksMetadata, responseText } = message;

  return (
    <div className="cw-flex-col cw-w-full">
      <div className="cw-body-sm cw-inline-block cw-mb-3">
        {textSplitByNewLine(responseText as string)}
      </div>
      {linksMetadata.map((linkMetadata, index) => {
        const { linkName, linkUrl, meetingSettings } = linkMetadata.link;
        return (
          <LinkCard
            key={index}
            messageId={id}
            linkName={linkName}
            linkUrl={linkUrl}
            meetingSettings={meetingSettings}
          />
        );
      })}
    </div>
  );
};
