import { DayOfWeek, EventFlexibilityInput, FlexRange, FlexStatus } from "@clockwise/schema/v2";
import { useCallback } from "react";
import { useGatewayMutation, useGatewayQuery } from "../network/apollo/gateway-provider";
import {
  EventFlexibilityDocument,
  UpdateEventFlexibilityDocument,
} from "./__generated__/EventFlexibility.v2.generated";
import { EventPermissionsDocument } from "./__generated__/EventPermissions.v2.generated";

export type UseEventFlexibilityInput = {
  id: string;
  calendarId: string;
  useQuery: typeof useGatewayQuery;
  useMutation: typeof useGatewayMutation;
};

export type UpdateFlexibilityParams = {
  isFlexible: boolean;
  flexRange: FlexRange;
  allowedDays: DayOfWeek[] | undefined;
  start: string;
  end: string;
  onSuccess?: () => void;
  onFailure?: () => void;
};

type UseEventFlexibility = (
  params: UseEventFlexibilityInput,
) => {
  canMarkFlexible: boolean;
  dayEnd: string;
  daysAllowed: DayOfWeek[];
  dayStart: string;
  flexible: boolean;
  loading: boolean;
  range: FlexRange;
  status: FlexStatus;
  update: (params: UpdateFlexibilityParams) => void;
  updating: boolean;
  refetchFlexibility: () => void;
};

export const useEventFlexibility: UseEventFlexibility = ({
  id,
  calendarId,
  useQuery,
  useMutation,
}) => {
  const [update, { loading: mutationLoading }] = useMutation(UpdateEventFlexibilityDocument);

  const { data, loading: queryLoading, refetch: refetchFlexibility } = useQuery(
    EventFlexibilityDocument,
    {
      variables: {
        id: id ?? "",
        calendarId: calendarId ?? "",
      },
      // HACK: for some reason without "no-cache", data comes back with the correct flexRange, but then mysteriously
      // comes back again with the information missing even though we see the information coming back
      // correctly on the network request 🤷🏽‍♂️
      // it's clearly a cache issue, but difficult to figure out the exact root cause
      fetchPolicy: "no-cache",
      skip: !id || !calendarId,
    },
  );

  const { data: permissionsData, loading: permissionsLoading } = useQuery(
    EventPermissionsDocument,
    {
      variables: {
        calendarId: calendarId ?? "",
        id: id ?? "",
      },
      skip: !id || !calendarId,
    },
  );

  const hasPermissions =
    !permissionsLoading && (permissionsData?.event?.eventPermissions?.canModify ?? false);
  const canMarkFlexible = hasPermissions && (data?.event?.canMarkFlexible ?? false);
  const dayEnd = data?.event?.flexibility?.timeOfDayFlexRange?.end ?? "17:00";
  const daysAllowed = data?.event?.flexibility?.allowedDays ?? [];
  const dayStart = data?.event?.flexibility?.timeOfDayFlexRange?.start ?? "09:00";
  const flexible = data?.event?.flexibility?.isFlexible ?? false;
  const range = data?.event?.flexibility?.flexRange ?? FlexRange.Day;
  const status = data?.event?.flexibility?.status ?? FlexStatus.CanMove;

  const handleUpdateFlexibility = useCallback(
    ({
      isFlexible,
      flexRange,
      allowedDays,
      start,
      end,
      onSuccess,
      onFailure,
    }: UpdateFlexibilityParams) => {
      const event: EventFlexibilityInput = {
        externalEventId: id,
        isFlexible,
        flexRange,
        timeRange: {
          start,
          end,
        },
      };

      if (flexRange === FlexRange.SpecificDays) {
        event.allowedDays = allowedDays;
      }

      void update({
        variables: {
          events: [event],
        },
        onCompleted: () => {
          void refetchFlexibility();
          onSuccess && onSuccess();
        },
        onError: () => {
          onFailure && onFailure();
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateEventsFlexibility: [
            {
              id: id,
              flexibility: {
                isFlexible: flexible,
                status: flexible ? FlexStatus.CanMove : FlexStatus.Pinned,
                timeOfDayFlexRange: {
                  start,
                  end,
                  __typename: "TimeRange",
                },
                flexRange,
                allowedDays: allowedDays ?? null,
                __typename: "FlexDetails",
              },
              __typename: "Event",
            },
          ],
        },
      });
    },
    [update, id, refetchFlexibility, flexible],
  );

  return {
    canMarkFlexible,
    dayEnd,
    daysAllowed,
    dayStart,
    flexible,
    loading: !!queryLoading,
    range,
    status,
    refetchFlexibility,
    update: handleUpdateFlexibility,
    updating: !!mutationLoading,
  };
};
