import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { EcosystemEnum } from "@clockwise/schema";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { EcosystemContext } from "@clockwise/web-commons/src/util/ecosystem";
import React, { useCallback, useContext } from "react";
import toast from "react-hot-toast";
import { M365TeamSettingsAdminTeamCalendar } from "./M365TeamSettingsAdminTeamCalendar";
import { TeamSettingsAdminTeamCalendar } from "./TeamSettingsAdminTeamCalendar";
import {
  SendTeamCalendarInvitesDocument,
  TeamCalendarSettingsDocument,
  UpdateTeamCalendarEnabledDocument,
  UpdateTeamCalendarSubscribedDocument,
  UpdateTeamWfhSyncEnabledDocument,
} from "./__generated__/TeamCalendarSettings.v2.generated";

type Props = {
  teamId: string;
};

export const TeamSettingsAdminTeamCalendarWrapper: React.FC<Props> = ({ teamId }) => {
  const ecosystem = useContext(EcosystemContext);
  const { data } = useGatewayQuery(TeamCalendarSettingsDocument, {
    variables: {
      teamId,
    },
  });
  const [updateTeamCalendarEnabled, { loading: updatingTeamCalendar }] = useGatewayMutation(
    UpdateTeamCalendarEnabledDocument,
  );
  const [updateTeamWFHSyncEnabled] = useGatewayMutation(UpdateTeamWfhSyncEnabledDocument);
  const [sendTeamCalendarInvites, { loading: sendingInvites }] = useGatewayMutation(
    SendTeamCalendarInvitesDocument,
  );
  const [updateTeamCalendarSubscribed] = useGatewayMutation(UpdateTeamCalendarSubscribedDocument);

  const team = data?.team;
  const manageTeamCalendar = team?.manageTeamCalendar ?? false;
  const syncWFHEvents = team?.syncWFHEvents ?? false;
  const useTeamCalendar = team?.useTeamCalendar ?? false;

  const handleResendM365TeamAvailabilityCalendarInvite = useCallback(() => {
    void sendTeamCalendarInvites({
      variables: {
        teamId: teamId,
      },
      onCompleted: () => toast.success("Invites sent!"),
      onError: () => toast.error("Failed to send invites"),
      optimisticResponse: {
        __typename: "Mutation",
        sendTeamCalendarInvites: {
          __typename: "Team",
          id: teamId,
          name: team?.name ?? "",
          manageTeamCalendar: manageTeamCalendar,
          useTeamCalendar,
          syncWFHEvents: syncWFHEvents,
          teamCalendar: team?.teamCalendar ?? null,
        },
      },
    });
  }, [
    manageTeamCalendar,
    sendTeamCalendarInvites,
    syncWFHEvents,
    team?.name,
    team?.teamCalendar,
    teamId,
    useTeamCalendar,
  ]);

  const handleSharedCalendarToggle = useCallback(async () => {
    const enabled = !manageTeamCalendar;
    await updateTeamCalendarEnabled({
      variables: {
        teamId: teamId,
        enabled,
      },
      optimisticResponse:
        ecosystem === EcosystemEnum.Microsoft
          ? undefined
          : {
              __typename: "Mutation",
              updateTeamCalendarEnabled: {
                __typename: "Team",
                id: teamId,
                manageTeamCalendar: enabled,
                syncWFHEvents: syncWFHEvents,
                teamCalendar: team?.teamCalendar ?? null,
              },
            },
      onCompleted: () => {
        toast.success(`${enabled ? "Created" : "Removed"} a shared calendar for your team.`);

        if (ecosystem === EcosystemEnum.Microsoft && enabled) {
          void sendTeamCalendarInvites({
            variables: {
              teamId: teamId,
            },
            optimisticResponse: {
              __typename: "Mutation",
              sendTeamCalendarInvites: {
                __typename: "Team",
                id: teamId,
                name: team?.name ?? "",
                manageTeamCalendar: enabled,
                useTeamCalendar,
                syncWFHEvents: syncWFHEvents,
                teamCalendar: team?.teamCalendar ?? null,
              },
            },
          });
        }
      },
      onError: () => {
        const msg = `Failed to ${enabled ? "create" : "remove"} your team's calendar!`;
        toast.error(msg);
        logger.error(msg);
      },
    });

    track(TrackingEvents.TEAMS.TEAM_SETTINGS_ADMIN_TOGGLED_OOO_CALENDAR, {
      manageTeamCalendar: enabled,
    });
  }, [
    manageTeamCalendar,
    updateTeamCalendarEnabled,
    teamId,
    ecosystem,
    syncWFHEvents,
    team?.teamCalendar,
    team?.name,
    sendTeamCalendarInvites,
    useTeamCalendar,
  ]);

  const handleTeamCalendarSubscribedToggle = useCallback(async () => {
    const subscribed = !team?.useTeamCalendar;
    await updateTeamCalendarSubscribed({
      variables: {
        teamId: teamId,
        subscribed,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateTeamCalendarSubscribed: {
          __typename: "Team",
          id: teamId,
          useTeamCalendar: subscribed,
        },
      },
      onCompleted: () => {
        toast.success(
          `${subscribed ? "Subscribed" : "Unsubscribed"} to the "Clockwise: ${
            team?.name
          }" calendar.`,
        );
      },
      onError: () => {
        toast.error(
          `Failed to ${subscribed ? "subscribe" : "unsubscribe"} to the "Clockwise: ${
            team?.name
          }" calendar!`,
        );
      },
    });
  }, [team?.useTeamCalendar, team?.name, updateTeamCalendarSubscribed, teamId]);

  const handleSyncWFHEventsCheck = useCallback(async () => {
    const enabled = !syncWFHEvents;

    await updateTeamWFHSyncEnabled({
      variables: {
        teamId: teamId,
        enabled,
      },
      onCompleted: () => {
        toast.success(
          `Syncing of WFH events to your calendar is now ${enabled ? "enabled" : "disabled"}.`,
        );
      },
      onError: () => {
        const msg = `Failed to ${
          enabled ? "enable" : "disable"
        } syncing of WFH events to your calendar!`;
        toast.error(msg);
        logger.error(msg);
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateTeamWFHSyncEnabled: {
          __typename: "Team",
          id: teamId,
          syncWFHEvents: enabled,
        },
      },
    });

    track(TrackingEvents.TEAMS.TEAM_SETTINGS_ADMIN_TOGGLED_WFH_SYNC, {
      syncWFHEvents: enabled,
    });
  }, [syncWFHEvents, teamId, updateTeamWFHSyncEnabled]);

  if (ecosystem === EcosystemEnum.Microsoft) {
    const loading = updatingTeamCalendar || sendingInvites;
    const teamCalendarDetails = data?.team?.teamCalendar ?? null;

    return (
      <M365TeamSettingsAdminTeamCalendar
        isTeamCalendarShareChecked={manageTeamCalendar}
        isSyncWFHEventsEnabled={syncWFHEvents}
        teamName={team?.name ?? ""}
        onHandleSyncWFHEventsToggle={handleSyncWFHEventsCheck}
        onSharedCalendarToggle={handleSharedCalendarToggle}
        onResendInvites={handleResendM365TeamAvailabilityCalendarInvite}
        teamCalendarDetails={teamCalendarDetails}
        loading={loading}
      />
    );
  } else {
    return (
      <TeamSettingsAdminTeamCalendar
        isTeamCalendarShareChecked={manageTeamCalendar}
        useTeamCalendar={useTeamCalendar}
        isSyncWFHEventsEnabled={syncWFHEvents}
        teamName={team?.name ?? ""}
        onHandleSyncWFHEventsToggle={handleSyncWFHEventsCheck}
        onSharedCalendarToggle={handleSharedCalendarToggle}
        onHandleTeamCalendarSubscribedToggle={handleTeamCalendarSubscribedToggle}
      />
    );
  }
};
