import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { ArrowDropDown, ArrowDropUp } from "@clockwise/design-system/icons";
import { BillingGroupTypeEnum, ProductTypeEnum, SubscriptionTypeEnum } from "@clockwise/schema";
import classNames from "classnames";
import { isEmpty, noop } from "lodash";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import React, { useState } from "react";
import { ClickToCopyIdSpan } from "../sudo-modify-user/SudoModifyUser";
import { FormWrapper } from "./actions/FormWrapper";
import { SudoBillingGroupsForOrgQuery } from "./graphql/__generated__/SudoBillingGroupsForOrg.generated";

export const BillingGroupList = ({
  data,
  loading,
}: {
  data?: SudoBillingGroupsForOrgQuery;
  loading: boolean;
}) => {
  const billingGroups = data?.viewer?.sudo?.billingGroupsForOrg;
  const noBillingGroups = billingGroups?.length === 0;

  return (
    <div className="cw-flex cw-flex-col cw-gap-2 cw-w-full">
      {noBillingGroups && <div>No billing groups found</div>}
      {billingGroups &&
        !loading &&
        billingGroups.map((bg) => {
          if (!bg) {
            return null;
          }
          const subscription =
            getValue(bg.subscription)?.__typename === "SubscriptionDetails"
              ? getValue(bg.subscription, "SubscriptionDetails")?.subscription
              : undefined;

          const memberCount =
            getValue(bg.manualMembers, "BillingGroupManualMemberList")?.list.length ?? 0;

          const admins =
            getValue(bg.adminOrgPersons, "OrgPersonList")?.list?.map(
              (admin) => admin.primaryCalendarId,
            ) ?? [];

          const upcomingBillingDetails =
            getValue(bg.upcomingBillingDetails)?.__typename === "UpcomingBillingDetails"
              ? getValue(bg.upcomingBillingDetails, "UpcomingBillingDetails")
              : null;

          const syncSources = bg.syncSources?.map((syncSource) => syncSource.type ?? "") ?? [];

          return (
            <FormWrapper key={bg.id}>
              <div className="cw-text-lg cw-text-[16px] cw-font-semibold cw-pb-2">{bg.name}</div>
              <BillingGroupTable
                key={bg.id}
                id={bg.id}
                type={bg.type}
                createdAtMillis={bg.createdAtMillis}
                invitePolicy={bg.invitePolicy}
                isAutoSyncEnabled={bg.isAutoSyncEnabled}
                memberCount={memberCount}
                admins={admins}
                syncSources={syncSources}
              />
              <SubscriptionTable subscription={subscription} />
              {upcomingBillingDetails && (
                <UpcomingBillingDetailsTable upcomingBillingDetails={upcomingBillingDetails} />
              )}
            </FormWrapper>
          );
        })}
    </div>
  );
};

const BillingGroupTable = ({
  id,
  type,
  createdAtMillis,
  invitePolicy,
  isAutoSyncEnabled,
  memberCount,
  admins,
  syncSources,
}: {
  id: string;
  type: BillingGroupTypeEnum | null;
  createdAtMillis: number;
  invitePolicy: string;
  isAutoSyncEnabled: boolean | null;
  memberCount: number;
  admins: string[];

  syncSources: string[];
}) => {
  const isAutoSyncEnabledStr = isAutoSyncEnabled ? "true" : "false";
  const [showRows, setShowRows] = useState(false);

  return (
    <div>
      <HeaderRow
        label={"Billing Group Details"}
        onClick={() => setShowRows(!showRows)}
        arrowUp={showRows}
      />
      {showRows && (
        <div>
          <Row label="Billing Group ID" value={id} canCopy />
          <Row label={pluralize("Admin", admins.length)} value={admins.join(", ")} canCopy />
          <Row label="Type" value={`${type}`} />
          <Row label="Created timestamp" value={DateTime.fromMillis(createdAtMillis).toISO()} />
          <Row label="Invite Policy" value={invitePolicy} />
          <Row label="Auto Sync Enabled" value={isAutoSyncEnabledStr} />
          <Row label="Member Count" value={memberCount.toString()} />
          <Row
            label="Sync Sources"
            value={isEmpty(syncSources) ? "None" : syncSources.join(", ")}
          />
        </div>
      )}
    </div>
  );
};

const SubscriptionTable = ({
  subscription,
}: {
  subscription?: {
    type: SubscriptionTypeEnum | null;
    productType: ProductTypeEnum | null;
    seatCount: number;
  };
}) => {
  const [showRows, setShowRows] = useState(false);
  if (!subscription) {
    return <HeaderRow label={"No subscription found"} onClick={noop} arrowUp={showRows} />;
  }
  const { type, productType, seatCount } = subscription;
  return (
    <div>
      <HeaderRow
        label={"Subscription Details"}
        onClick={() => setShowRows(!showRows)}
        arrowUp={showRows}
      />
      {showRows && (
        <div>
          <Row label="Type" value={`${type}`} />
          <Row label="Product Type" value={`${productType}`} />
          <Row label="Seat Count" value={`${seatCount}`} />
        </div>
      )}
    </div>
  );
};

export const Row = ({
  label,
  value,
  canCopy,
}: {
  label: string;
  value: string;
  canCopy?: boolean;
}) => {
  return (
    <div className="cw-flex cw-w-full cw-items-center cw-p-2.5 cw-border-b cw-border-solid cw-border-[#F1F1F1]">
      <div className="cw-text-13 cw-font-semibold cw-w-[30%]">{label}</div>
      <div className="cw-w-[70%]">
        {canCopy ? <ClickToCopyIdSpan>{value}</ClickToCopyIdSpan> : <div>{value}</div>}
      </div>
    </div>
  );
};

const HeaderRow = ({
  label,
  onClick,
  arrowUp,
}: {
  label: string;
  onClick: () => void;
  arrowUp: boolean;
}) => {
  return (
    <div
      className={classNames(
        "cw-flex cw-items-center cw-w-full cw-bg-neutral cw-rounded-t cw-px-3",
        {
          "cw-rounded-t cw-border-solid cw-border-b cw-border-subtle": arrowUp,
          "cw-rounded": !arrowUp,
        },
      )}
      onClick={onClick}
    >
      {!arrowUp ? (
        <ArrowDropDown className="cw-w-4 cw-h-4 cw-mr-1" />
      ) : (
        <ArrowDropUp className="cw-w-4 cw-h-4 cw-mr-1" />
      )}
      <div className="cw-cursor-pointer cw-py-2 cw-px-1 cw-flex cw-flex-col cw-justify-center cw-font-semibold cw-w-full ">
        {label}
      </div>
    </div>
  );
};

const UpcomingBillingDetailsTable = ({
  upcomingBillingDetails,
}: {
  upcomingBillingDetails: {
    nextBillingDate: string;
    nextTrueUpDate: string | null;
    billedQuantity: number;
    currentUsageQuantity: number;
    requestedUpdatedQuantity: number | null;
  };
}) => {
  const {
    nextBillingDate,
    nextTrueUpDate,
    billedQuantity,
    currentUsageQuantity,
    requestedUpdatedQuantity,
  } = upcomingBillingDetails;
  const [showRows, setShowRows] = useState(false);

  return (
    <div>
      <HeaderRow
        label={"Upcoming Billing Details"}
        onClick={() => setShowRows(!showRows)}
        arrowUp={showRows}
      />
      {showRows && (
        <div>
          <Row label="Next billing date" value={nextBillingDate} />
          {nextTrueUpDate && <Row label="Next true-up date" value={nextTrueUpDate} />}{" "}
          <Row label="Billed quantity" value={billedQuantity.toString()} />
          <Row label="Current usage quantity" value={currentUsageQuantity.toString()} />
          {requestedUpdatedQuantity && (
            <Row label="Requested updated quantity" value={requestedUpdatedQuantity.toString()} />
          )}
        </div>
      )}
    </div>
  );
};
