import { ExpandLess, ExpandMore } from "@clockwise/icons";
import React from "react";

export const CollapsibleSection = ({
  numberOfSections,
  defaultExpand = true,
  children,
}: {
  numberOfSections: number;
  defaultExpand?: boolean;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = React.useState(defaultExpand);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="cw-flex cw-flex-col cw-gap-2 cw-mb-10">
      <button
        className="cw-flex cw-flex-1 cw-justify-between cw-items-center cw-mx-3 cw-px-0 cw-min-h-6 cw-cursor-pointer cw-rounded-lg cw-group cw-bg-default cw-border-none"
        onClick={toggleOpen}
      >
        <div className="cw-text-muted cw-font-medium cw-text-13 cw-body-base cw-h-6 cw-flex cw-items-center">
          More settings
        </div>
        <div className="cw-h-6 cw-flex cw-items-center cw-gap-1">
          {!isOpen && (
            <div className="cw-rounded-full cw-h-5 cw-w-5 cw-flex cw-items-center cw-justify-center cw-font-semibold cw-bg-neutral-inset group-hover:cw-bg-positive-inset group-hover:cw-text-positive cw-text-12 cw-text-muted">
              {numberOfSections}
            </div>
          )}

          {isOpen ? (
            <>
              <ExpandLess className="cw-h-4 cw-w-4 cw-stroke-2 group-hover:cw-text-positive" />
            </>
          ) : (
            <>
              <ExpandMore className="cw-h-4 cw-w-4 cw-stroke-2 group-hover:cw-text-positive" />
            </>
          )}
        </div>
      </button>
      {isOpen ? <div className="cw-flex cw-flex-col cw-gap-2">{children}</div> : null}
    </div>
  );
};
