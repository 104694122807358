import React from "react";
import { Header, SlideLayout, Text } from "../../shared-components";
import sidecarImage from "./assets/sidecar.png";

export const DownloadClockwiseSlide = ({ browser }: { browser: string }) => {
  return (
    <SlideLayout>
      <Header> Download Clockwise for {browser}</Header>
      <Text>
        Access Clockwise scheduling intelligence right in the context of your everyday calendar.
      </Text>
      <img src={sidecarImage} className="cw-w-full md:cw-w-[640px] md:cw-h-[400px] cw-mt-3" />
    </SlideLayout>
  );
};
