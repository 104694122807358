//////////////////
// IMPORTS
//////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";
// libraries
import * as React from "react";
import { connect } from "react-redux";
import { createFragmentContainer } from "react-relay";
// chrome-zoom-verifier imports
import { chromeZoomVerifierFragments, chromeZoomVerifierQuery } from "./ChromeZoomVerifier.gql";
import * as s from "./ChromeZoomVerifier.styles";
import { IContainer, IProps, IState } from "./ChromeZoomVerifierTypes";
// state
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
// util
import { paths } from "#webapp/src/constants/site.constant";
import { addZoomAccount } from "#webapp/src/mutations";
import { withRouterQuery } from "#webapp/src/routing/routes.util";
import { zoomNonce, zoomUserLevelNonce } from "#webapp/src/state/local-storage";
import { windowLocation } from "#webapp/src/util/location.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { getServerUrl } from "@clockwise/web-commons/src/util/post-message-common.util";

//////////////////
// COMPONENT
//////////////////
// TODO: this component will need some love in the future
class ChromeZoomkVerifierCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      verifying: true,
      success: false,
      error: "",
    };
  }

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-
  public UNSAFE_componentWillMount() {
    const searchParams = new URLSearchParams(window.location.search);

    const isUserLevelAuth = window.location.pathname.includes(`${paths.chromeZoomVerifier}/user`);
    if (isUserLevelAuth) {
      return this.addUserLevelAuth(searchParams);
    } else {
      return this.addAccountLevelAuth(searchParams);
    }
  }

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  private onRelayFailure = (_error: string) => {
    this.setState({
      success: false,
      error: "Something went wrong with the verification of your Zoom account.",
      verifying: false,
    });
  };

  private onRelaySuccess = (_payload: ISchema.IAddZoomAccountMutationPayload) => {
    this.setState({ success: true, error: "", verifying: false });
    windowLocation.assign("ChromeZoomVerifierOnSuccess", "/app/settings");
    window.close();
  };

  private maybeGetZoomNonce = (
    state: string,
    _verificationCode: string,
    isUserLevelAuth: boolean,
  ) => {
    const nonce = isUserLevelAuth ? zoomUserLevelNonce.get() : zoomNonce.get();
    if (!nonce || nonce !== state) {
      this.setState({
        error:
          "Something went wrong with the verification of your Zoom account, state parameter is incorrect.",
        verifying: false,
      });
    }
  };

  private addUserLevelAuth = (searchParams: URLSearchParams) => {
    if (!searchParams.has("code") || !searchParams.has("state")) {
      return;
    }

    const verificationCode = searchParams.get("code") as string;
    const state = searchParams.get("state") as string;

    const org = getCurrentOrg(this.props.viewer);
    if (!org) {
      this.setState({ error: "There was a problem loading your data." });
      return;
    }
    const orgRelayId = org.id;
    const redirect = `${getServerUrl()}${paths.chromeZoomVerifier}/user`;

    addZoomAccount(
      this.props.relay.environment,
      { orgRelayId, verificationCode, redirect, isUserLevelZoomAccount: true },
      this.onRelaySuccess,
      this.onRelayFailure,
    );

    this.maybeGetZoomNonce(state, verificationCode, true);
  };

  private addAccountLevelAuth = (searchParams: URLSearchParams) => {
    if (!searchParams.has("code") || !searchParams.has("state")) {
      return;
    }

    const verificationCode = searchParams.get("code") as string;
    const state = searchParams.get("state") as string;

    const org = getCurrentOrg(this.props.viewer);
    if (!org) {
      this.setState({ error: "There was a problem loading your data." });
      return;
    }
    const orgRelayId = org.id;
    const redirect = `${getServerUrl()}${paths.chromeZoomVerifier}/account`;

    addZoomAccount(
      this.props.relay.environment,
      { orgRelayId, verificationCode, redirect, isUserLevelZoomAccount: false },
      this.onRelaySuccess,
      this.onRelayFailure,
    );

    this.maybeGetZoomNonce(state, verificationCode, false);
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  public render() {
    const { verifying, success, error } = this.state;

    if (verifying) {
      <div style={s.container}>Connecting...</div>;
    } else if (error) {
      return (
        <div style={s.container}>
          There was a problem connecting your Zoom. &nbsp;
          {this.state.error}
        </div>
      );
    } else if (success) {
      return <div style={s.container}>Zoom has been successfully connected.</div>;
    }

    return <div style={s.container}>Connecting...</div>;
  }
}

//////////////////
// REDUX
//////////////////
function mapStateToProps(_state: IReduxState, _ownProps: IContainer) {
  return {};
}

const ChromeZoomVerifierRedux = connect(mapStateToProps, {})(ChromeZoomkVerifierCmpt);

//////////////////
// RELAY
//////////////////
export const ChromeZoomVerifier = createFragmentContainer<IContainer>(
  ChromeZoomVerifierRedux,
  chromeZoomVerifierFragments,
);

export const ChromeZoomVerifierPage = withRouterQuery(ChromeZoomVerifier, chromeZoomVerifierQuery);
