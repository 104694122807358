import { Button, TextField } from "@clockwise/design-system";
import { OpenInNew } from "@clockwise/design-system/icons";
import { isValidUrl } from "@clockwise/web-commons/src/util/routes.util";
import classNames from "classnames";
import React, { useMemo } from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";

type Props = {
  location?: string;
  onLocationChange: (location?: string) => void;
  readonly?: boolean;
  showChanges?: boolean;
};

export const ECLocation = ({
  location = "",
  onLocationChange,
  readonly: disabled = false,
  showChanges,
}: Props) => {
  const routeAction = useMemo(() => {
    if (isValidUrl(location)) {
      return () => window.open(location, "_blank");
    } else {
      return null;
    }
  }, [location]);

  return (
    <EventElementsWrap name="location" showChanges={showChanges}>
      <div className="cw-flex cw-justify-between cw-items-center cw-flex-1">
        <div
          className={classNames("cw-flex cw-flex-1 cw-items-center", {
            "cw-max-w-[290px]": !!routeAction,
          })}
        >
          <TextField
            onChange={(e) => {
              onLocationChange(e.target.value);
            }}
            fieldSize="small"
            disabled={disabled}
            placeholder={disabled ? "No location" : "Add location"}
            value={location}
            cw-id="ec-location-input"
          />
        </div>
        {routeAction && (
          <Button
            cw-id="ec-location-route-button"
            size="mini"
            variant="text"
            sentiment="positive"
            aria-label="Open location in new tab"
            onClick={routeAction}
          >
            <OpenInNew className="cw-w-4 cw-h-4" />
          </Button>
        )}
      </div>
    </EventElementsWrap>
  );
};
