import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { OrgPersonForUserDocument } from "../../graphql/__generated__/OrgPersonForUser.generated";

export type OrgPersonForUser = {
  userId: string | null;
  primaryBillingGroupId: string | null;
};

export function useOrgPersonForUser() {
  const orgPersonForUser: OrgPersonForUser = {
    userId: null,
    primaryBillingGroupId: null,
  };
  const { data, loading, error } = useQuery(OrgPersonForUserDocument);

  const orgPersonErrorable = data?.viewer?.user?.orgs?.edges?.[0]?.node?.orgPersonForUserErrorable;
  const orgPerson = getValue(orgPersonErrorable);

  if (orgPerson) {
    const primaryBgId =
      getValue(orgPerson?.primaryBillingGroupIdErrorable)?.primaryBillingGroupId || null;

    orgPersonForUser.userId = orgPerson?.userId;
    orgPersonForUser.primaryBillingGroupId = primaryBgId;
  }

  return { orgPersonForUser, loading, error };
}
