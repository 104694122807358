import { isEmpty, isEqual } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCalendarIdsForCalendarPeek } from "../../../../../state/actions/multi-calendar.actions";
import { IReduxState } from "../../../../../state/reducers/root.reducer";
import { GQLConsequencesBlock } from "../../utils/types";

export const useSetCalendarIdsForCalendarPeek = (
  consequencesBlock: GQLConsequencesBlock | null | undefined,
) => {
  const dispatch = useDispatch();
  const calendarIdsForCalendarPeek = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsForCalendarPeek,
  );

  const otherCalDiffs = consequencesBlock?.otherCalDiffs || [];

  const allAffectedAttendeesCalendarIds = otherCalDiffs
    .map((diff) => diff.affectedAttendees || [])
    .map((attendees) => attendees.map((attendee) => attendee.calendarId))
    .flat()
    .sort((a, b) => a.localeCompare(b));

  useEffect(() => {
    if (isEqual(allAffectedAttendeesCalendarIds, calendarIdsForCalendarPeek)) {
      return;
    }

    if (isEmpty(allAffectedAttendeesCalendarIds)) {
      dispatch(setCalendarIdsForCalendarPeek([]));
    }

    dispatch(setCalendarIdsForCalendarPeek(allAffectedAttendeesCalendarIds));
  }, [allAffectedAttendeesCalendarIds]);

  useEffect(() => {
    return () => {
      dispatch(setCalendarIdsForCalendarPeek([]));
    };
  }, []);
};
