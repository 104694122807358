import { all } from "@clockwise/client-commons/src/constants/time-slot";
import { IDaySetting, IWorkingHours } from ".";

export const isValid = (meetingHourDaySetting: IDaySetting, workingHourDaySetting: IDaySetting) => {
  let isValid = true;
  const allWorkingHoursSlotsForDay: { [key: string]: boolean } = {};
  for (
    let i = all.indexOf(workingHourDaySetting.setting.minSlot);
    i < all.indexOf(workingHourDaySetting.setting.maxSlot);
    i++
  ) {
    if (workingHourDaySetting.setting.excludedSlots?.includes(all[i])) {
      continue;
    }

    allWorkingHoursSlotsForDay[all[i]] = true;
  }
  for (
    let i = all.indexOf(meetingHourDaySetting.setting.minSlot);
    i < all.indexOf(meetingHourDaySetting.setting.maxSlot);
    i++
  ) {
    if (
      !meetingHourDaySetting.setting.excludedSlots?.includes(all[i]) &&
      !allWorkingHoursSlotsForDay[all[i]]
    ) {
      // found an invalid meeting hours slot
      isValid = false;
      break;
    }
  }

  return isValid;
};

export const getValidMeetingHoursFromWorkingHours = (
  workingHours: IWorkingHours,
  meetingHours: IWorkingHours,
): IWorkingHours => {
  const newMeetingHours: IWorkingHours = { ...meetingHours, daySettings: [] };
  workingHours.daySettings.forEach(({ day, setting }) => {
    const matchingMeetingHoursDS = meetingHours.daySettings.find((ds) => ds.day === day);

    // if working hours doesn't have a day matching the
    // current meeting hours day, meeting hours are on
    // for an non - working day; mark as invalid
    // eg. if WH Sunday off, MH Sunday on
    if (matchingMeetingHoursDS && isValid(matchingMeetingHoursDS, { day, setting })) {
      newMeetingHours.daySettings.push(matchingMeetingHoursDS);
    } else if (matchingMeetingHoursDS && !isValid(matchingMeetingHoursDS, { day, setting })) {
      newMeetingHours.daySettings.push({ day, setting });
    }
  });
  return newMeetingHours;
};
