import React, { useEffect } from "react";
import { TrackingEvents, track } from "../../../../util/analytics.util";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { ButtonGroup, StepCounter, StepLayout } from "../../shared-components";
import { DefragSlide } from "./DefragSlide";

export const DefragStep = () => {
  const { goForward, goBack, shouldShowSecondaryButton, funnelType } = useWebOnboardingSteps();

  useEffect(() => {
    track(TrackingEvents.WEB_ONBOARDING.DEFRAG_STEP_VIEWED, { funnelType });
  }, []);

  return (
    <StepLayout>
      <StepCounter />
      <DefragSlide />
      <ButtonGroup
        shouldShowSecondary={shouldShowSecondaryButton}
        onClickPrimary={goForward}
        onClickSecondary={goBack}
      />
    </StepLayout>
  );
};
