import { CwIdProps } from "@clockwise/design-system/src/types/cw-id";
import { Close, SvgIconComponent } from "@clockwise/icons";
import classNames from "classnames";
import { noop } from "lodash";
import React, { forwardRef } from "react";

type Variant = "solid" | "outlined";
type Sentiment = "neutral" | "emphasis";

const COLOR_CLASSES_HASH: { [v in Variant]: { [s in Sentiment]: string } } = {
  solid: {
    neutral: "cw-bg-neutral-inset cw-text-default",
    emphasis:
      "cw-bg-accent-emphasis cw-text-onEmphasis cw-border cw-border-solid cw-border-emphasis",
  },
  outlined: {
    // empahsis outline currenlty not specced so it's the same as solid
    neutral: "cw-bg-neutral cw-text-default cw-border cw-border-solid cw-border-muted",
    emphasis:
      "cw-bg-accent-emphasis cw-text-onEmphasis cw-border cw-border-solid cw-border-emphasis",
  },
};

const HOVER_CLASSES_HASH: { [v in Variant]: { [s in Sentiment]: string } } = {
  solid: {
    neutral: "hover:cw-bg-neutral-inset-hover cw-cursor-pointer",
    emphasis: "hover:cw-bg-accent-emphasis-hover cw-cursor-pointer",
  },
  outlined: {
    neutral: "hover:cw-bg-neutral-hover cw-cursor-pointer",
    emphasis: "hover:cw-bg-accent-emphasis-hover cw-cursor-pointer",
  },
};

const DELETE_ICON_CLASSES_HASH: { [v in Variant]: { [s in Sentiment]: string } } = {
  solid: {
    neutral: "cw-text-subtle",
    emphasis: "cw-text-onEmphasis",
  },
  outlined: {
    neutral: "cw-text-subtle",
    emphasis: "cw-text-onEmphasis",
  },
};

const ICON_COLOR_CLASSES_HASH: { [v in Variant]: { [s in Sentiment]: string } } = {
  solid: {
    neutral: "cw-text-default",
    emphasis: "cw-text-onEmphasis",
  },
  outlined: {
    neutral: "cw-text-default",
    emphasis: "cw-text-onEmphasis",
  },
};

const DISABLED_CLASSES_HASH: { [v in Variant]: { [s in Sentiment]: string } } = {
  solid: {
    neutral: "cw-opacity-70 cw-pointer-events-none",
    emphasis: "cw-opacity-75 cw-pointer-events-none",
  },
  outlined: {
    neutral: "cw-opacity-70 cw-pointer-events-none",
    emphasis: "cw-opacity-75 cw-pointer-events-none",
  },
};

interface Props extends CwIdProps {
  children: React.ReactNode;
  sentiment: Sentiment;
  variant: Variant;
  disabled?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  startIcon?: SvgIconComponent;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const Chip = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      onClick,
      sentiment,
      variant,
      "cw-id": cwId,
      disabled = false,
      onDelete,
      startIcon: StartIconComponent,
      onMouseEnter,
      onMouseLeave,
    },
    ref,
  ) => {
    const showDelete = !!onDelete;
    const hasOnClickHander = !!onClick && !disabled;

    const hoverClasses = HOVER_CLASSES_HASH[variant][sentiment];
    const colorClasses = COLOR_CLASSES_HASH[variant][sentiment];

    const disabledClasses = disabled ? DISABLED_CLASSES_HASH[variant][sentiment] : "";
    const deleteIconColorClasses = DELETE_ICON_CLASSES_HASH[variant][sentiment];
    const iconColorClasses = ICON_COLOR_CLASSES_HASH[variant][sentiment];

    const startIcon = StartIconComponent ? (
      <StartIconComponent
        className={classNames(iconColorClasses, "cw-w-4 cw-h-4 cw-mr-0.5")}
        aria-hidden
      />
    ) : null;

    return (
      <div
        ref={ref}
        className={classNames(
          "cw-flex cw-rounded-full cw-caption cw-items-center cw-font-medium",
          colorClasses,
          disabledClasses,
        )}
        aria-disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          cw-id={cwId ?? "chip"}
          tabIndex={hasOnClickHander ? 0 : -1}
          onKeyDown={(e) => {
            if (e.key === "Enter" && hasOnClickHander) {
              onClick();
            }
          }}
          onClick={hasOnClickHander ? onClick : noop}
          className={classNames(
            "cw-z-20 cw-flex cw-rounded-l-full cw-items-center cw-pl-2 cw-py-0.5",
            {
              "cw-rounded-r-full cw-pr-2": !showDelete,
              "cw-pr-1": showDelete,
              [hoverClasses]: hasOnClickHander,
            },
          )}
        >
          {startIcon && <> {startIcon}</>}
          <div>{children}</div>
        </div>

        {!!showDelete && (
          <div
            tabIndex={disabled ? -1 : 0}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !disabled) {
                onDelete();
              }
            }}
            onClick={onDelete}
            className={classNames(
              "cw-flex cw-items-center cw-rounded-r-full cw-pr-2 cw-pl-1 cw-py-0.5 cw-h-full",
              hoverClasses,
            )}
          >
            <Close className={classNames("cw-w-[14px] cw-h-[14px]", deleteIconColorClasses)} />
          </div>
        )}
      </div>
    );
  },
);

Chip.displayName = "Chip";
