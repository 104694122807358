import { ZonedMoment } from "@clockwise/client-commons/src/util/ZonedMoment";
import { Options } from "highcharts";

export const PELLEGRINO = "#00A972";

export const highChartsConfig: Options = {
  credits: {
    enabled: false,
  },

  chart: {
    height: 200,
  },

  colors: [PELLEGRINO],

  title: {
    text: "",
  },

  xAxis: {
    type: "datetime",
    tickColor: "transparent",
    gridLineColor: "transparent",
    dateTimeLabelFormats: {
      day: "%b %e",
      week: "%b %e",
    },
    title: {
      text: "",
    },
  },

  yAxis: {
    min: 0,
    minRange: 10,
    startOnTick: true,
    title: {
      text: "",
    },
  },

  legend: {
    enabled: false,
  },

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
      },
    ],
  },
};

export const placeholderTickPositions = [0, 50, 100, 150, 200];
export const placeholderData = [[new ZonedMoment().valueOf(), 0]] as [number, number][];
