import { useMutation } from "@apollo/client";
import { Button, TextField } from "@clockwise/design-system";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { SudoUpdateBillingGroupNameDocument } from "../graphql/__generated__/SudoUpdateBillingGroupName.generated";
import { ButtonWrapper } from "./ButtonWrapper";
import { FormWrapper } from "./FormWrapper";
export const UpdateBillingGroupName = () => {
  const [orgId, setOrgId] = useState<string | undefined>();
  const [adminEmail, setAdminEmail] = useState<string | undefined>();
  const [billingGroupId, setBillingGroupId] = useState<string | undefined>();
  const [billingGroupName, setBillingGroupName] = useState<string | undefined>();

  const allFieldsPopulated = orgId && adminEmail && billingGroupId && billingGroupName;

  const [updateBillingGroupName, { loading }] = useMutation(SudoUpdateBillingGroupNameDocument);

  const resetFields = () => {
    setOrgId("");
    setAdminEmail("");
    setBillingGroupId("");
    setBillingGroupName("");
  };

  const onUpdateBillingGroupName = () => {
    if (allFieldsPopulated) {
      void updateBillingGroupName({
        variables: {
          input: {
            orgId,
            adminEmail,
            billingGroupId,
            name: billingGroupName,
          },
        },
        onCompleted: () => {
          toast.success("Billing group name updated successfully");
          resetFields();
        },
        onError: (error) => {
          toast.error("Error updating billing group name " + error.message);
        },
      });
    }
  };

  return (
    <FormWrapper>
      <div className="cw-flex cw-gap-x-2 cw-flex-wrap">
        <TextField
          label="Billing Group ID"
          value={billingGroupId}
          onChange={(e) => setBillingGroupId(e.target.value)}
        />
        <TextField
          label="Admin Email"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
        />
        <TextField label="Org ID" value={orgId} onChange={(e) => setOrgId(e.target.value)} />
      </div>
      <div className="cw-flex">
        <TextField
          label="New Name"
          value={billingGroupName}
          onChange={(e) => setBillingGroupName(e.target.value)}
        />
      </div>

      <ButtonWrapper>
        <Button
          size="medium"
          disabled={loading || !allFieldsPopulated}
          sentiment={"positive"}
          fullWidth={false}
          onClick={onUpdateBillingGroupName}
        >
          Update Name
        </Button>
      </ButtonWrapper>
    </FormWrapper>
  );
};
