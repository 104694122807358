import { getSiteUrl } from "@clockwise/client-commons/src/config/api";
import { Button, Modal } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { bg_neutral } from "@clockwise/design-system/tokens";
import { scheduleWithCSPath } from "@clockwise/web-commons/src/constants/route.constants";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@material-ui/icons";
import Player from "@vimeo/player";
import React, { useEffect, useRef, useState } from "react";
import { SectionHeader } from "./components/SectionHeader";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  header: string;
  subheader: string;
  mainContent: React.ReactNode;
  onClickPrimaryButton: () => void;
  videoLink: string;
  primaryButtonText: string;
  secondaryContent?: React.ReactNode;
  onClickSecondaryButton?: () => void;
};

export const EducationModal = ({
  isOpen,
  onClose,
  header,
  subheader,
  mainContent,
  secondaryContent,
  onClickPrimaryButton,
  onClickSecondaryButton,
  videoLink,
  primaryButtonText,
}: Props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loadingVideo, setLoadingVideo] = useState(true);
  const track = useTracking();
  const site = getSiteUrl();
  const hasSecondaryContent = !!secondaryContent;
  const [secondaryContentVisible, setSecondaryContentVisible] = useState(false);

  const toggleSecondaryContent = () => {
    setSecondaryContentVisible(!secondaryContentVisible);
  };

  const openCSMLink = () => {
    window.open(`${site}${scheduleWithCSPath}`, "_blank");
  };

  const onStartVideo = () => {
    track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.VIDEO_STARTED, {
      videoName: header,
      videoLink,
    });
  };

  const onEndVideo = () => {
    track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.VIDEO_COMPLETED, {
      videoName: header,
      videoLink,
    });
  };

  useEffect(() => {
    if (iframeRef.current) {
      const player = new Player(iframeRef.current);
      player.on("play", onStartVideo);
      player.on("ended", onEndVideo);
    }
  }, [iframeRef.current]);

  const onVideoLoaded = () => {
    setLoadingVideo(false);
  };
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      centered={true}
      radius={11}
      withCloseButton={false}
      size={830}
      styles={{
        content: {
          backgroundColor: bg_neutral,
        },
      }}
      trapFocus={false}
    >
      <div className="cw-flex cw-justify-between cw-font-body cw-h-[500px] cw-w-full cw-overflow-y-hidden">
        <div className="cw-flex cw-basis-[320px]">
          <div className="cw-flex cw-flex-col cw-rounded-lg cw-border cw-border-solid cw-border-muted cw-mr-3 cw-h-full cw-justify-between cw-w-full cw-shadow cw-shadow-subtle">
            <div className="cw-flex cw-flex-col cw-h-full cw-overflow-y-scroll cw-py-5 cw-pl-5 cw-pr-4 cw-bg-default cw-rounded-t-lg">
              <div className="cw-text-16 cw-font-bold cw-text-default cw-leading-5"> {header}</div>
              <div className="cw-text-13 cw-text-muted cw-leading-5 cw-my-1"> {subheader}</div>
              <div className="cw-flex cw-flex-col cw-h-full cw-mt-2 cw-mb-1">
                <SectionHeader>What to know</SectionHeader>
                {mainContent}
              </div>
              {hasSecondaryContent && (
                <div className="cw-flex cw-flex-col cw-h-full cw-my-1">
                  <div
                    className="cw-flex cw-justify-between cw-items-center cw-cursor-pointer cw-w-full"
                    onClick={toggleSecondaryContent}
                  >
                    <SectionHeader>How does it work?</SectionHeader>
                    {secondaryContentVisible ? (
                      <KeyboardArrowUpRounded className="cw-text-muted cw-w-5 cw-h-5" />
                    ) : (
                      <KeyboardArrowDownRounded className="cw-text-muted cw-w-5 cw-h-5" />
                    )}
                  </div>
                  {secondaryContentVisible && <>{secondaryContent}</>}
                </div>
              )}
              <div className="cw-leading-5 cw-text-12 cw-font-normal cw-text-muted cw-items-center">
                Want to learn more?{" "}
                <span
                  className="cw-font-medium cw-text-positive-muted cw-cursor-pointer hover:cw-underline"
                  onClick={openCSMLink}
                >
                  Talk to a Customer Success Manager
                </span>{" "}
                about best practices and using Clockwise with your team.
              </div>
            </div>

            <div className="cw-flex cw-justify-end cw-gap-x-2 cw-p-3 cw-bg-neutral cw-border-t cw-border-solid cw-border-subtle cw-rounded-b-lg">
              {!!onClickSecondaryButton && (
                <Button
                  size="xsmall"
                  variant="outlined"
                  sentiment="neutral"
                  onClick={onClickSecondaryButton}
                >
                  Done
                </Button>
              )}
              <Button size="xsmall" sentiment="positive" onClick={onClickPrimaryButton}>
                {primaryButtonText}
              </Button>
            </div>
          </div>
        </div>
        <div
          className="cw-flex cw-items-center cw-justify-center [&>iframe]:cw-rounded-lg cw-w-[500px]"
          data-chromatic="ignore"
        >
          <iframe
            onLoad={onVideoLoaded}
            src={videoLink}
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
            style={{ width: "500px", height: "500px", borderRadius: 10 }}
            title={header}
            data-chromatic="ignore"
            ref={iframeRef}
          ></iframe>
          {/* Script tag is apart of the vimeo embed block */}
          <script src="https://player.vimeo.com/api/player.js"></script>

          {loadingVideo && (
            <div className="cw-absolute">
              <Loader sentiment="positive" />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
