import React from "react";

export const AgendaOmittedIcon = () => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_3116_104218"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="4"
        width="16"
        height="16"
      >
        <rect y="4" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3116_104218)">
        <path
          d="M2.66667 12.6667C2.47778 12.6667 2.31956 12.6027 2.192 12.4747C2.064 12.3471 2 12.1889 2 12C2 11.8111 2.064 11.6527 2.192 11.5247C2.31956 11.3971 2.47778 11.3333 2.66667 11.3333H13.3333C13.5222 11.3333 13.6804 11.3971 13.808 11.5247C13.936 11.6527 14 11.8111 14 12C14 12.1889 13.936 12.3471 13.808 12.4747C13.6804 12.6027 13.5222 12.6667 13.3333 12.6667H2.66667ZM2.66667 16C2.47778 16 2.31956 15.936 2.192 15.808C2.064 15.6804 2 15.5222 2 15.3333C2 15.1444 2.064 14.9862 2.192 14.8587C2.31956 14.7307 2.47778 14.6667 2.66667 14.6667H9.33333C9.52222 14.6667 9.68067 14.7307 9.80867 14.8587C9.93622 14.9862 10 15.1444 10 15.3333C10 15.5222 9.93622 15.6804 9.80867 15.808C9.68067 15.936 9.52222 16 9.33333 16H2.66667ZM2.66667 9.33333C2.47778 9.33333 2.31956 9.26956 2.192 9.142C2.064 9.014 2 8.85556 2 8.66667C2 8.47778 2.064 8.31933 2.192 8.19133C2.31956 8.06378 2.47778 8 2.66667 8H13.3333C13.5222 8 13.6804 8.06378 13.808 8.19133C13.936 8.31933 14 8.47778 14 8.66667C14 8.85556 13.936 9.014 13.808 9.142C13.6804 9.26956 13.5222 9.33333 13.3333 9.33333H2.66667Z"
          fill="#A6A6A6"
        />
      </g>
      <rect x="6" y="10" width="14" height="14" rx="7" fill="#2B2B2B" />
      <g clipPath="url(#clip0_3116_104218)">
        <path
          d="M15.0002 15.6666H14.6668V14.9999C14.6668 14.0799 13.9202 13.3333 13.0002 13.3333C12.0802 13.3333 11.3335 14.0799 11.3335 14.9999V15.6666H11.0002C10.6335 15.6666 10.3335 15.9666 10.3335 16.3333V19.6666C10.3335 20.0333 10.6335 20.3333 11.0002 20.3333H15.0002C15.3668 20.3333 15.6668 20.0333 15.6668 19.6666V16.3333C15.6668 15.9666 15.3668 15.6666 15.0002 15.6666ZM13.0002 18.6666C12.6335 18.6666 12.3335 18.3666 12.3335 17.9999C12.3335 17.6333 12.6335 17.3333 13.0002 17.3333C13.3668 17.3333 13.6668 17.6333 13.6668 17.9999C13.6668 18.3666 13.3668 18.6666 13.0002 18.6666ZM12.0002 15.6666V14.9999C12.0002 14.4466 12.4468 13.9999 13.0002 13.9999C13.5535 13.9999 14.0002 14.4466 14.0002 14.9999V15.6666H12.0002Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3116_104218">
          <rect width="8" height="8" fill="white" transform="translate(9 13)" />
        </clipPath>
      </defs>
    </svg>
  );
};
