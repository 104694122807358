import { Skeleton } from "@material-ui/lab";
import React from "react";

const WorkingHoursSkeleton = () => {
  return (
    <div className="cw-flex cw-flex-col">
      <div className="cw-flex">
        <Skeleton variant="circle" width="26px" height="26px" style={{ marginRight: "3px" }} />
        <Skeleton variant="circle" width="26px" height="26px" style={{ margin: "0px 3px" }} />
        <Skeleton variant="circle" width="26px" height="26px" style={{ margin: "0px 3px" }} />
        <Skeleton variant="circle" width="26px" height="26px" style={{ margin: "0px 3px" }} />
        <Skeleton variant="circle" width="26px" height="26px" style={{ margin: "0px 3px" }} />
        <Skeleton variant="circle" width="26px" height="26px" style={{ margin: "0px 3px" }} />
        <Skeleton variant="circle" width="26px" height="26px" style={{ marginLeft: "3px" }} />
      </div>
      <Skeleton variant="text" width="20rem" height="3em" />
      <Skeleton variant="text" width="20rem" height="3em" />
      <Skeleton variant="text" width="20rem" height="3em" />
      <Skeleton variant="text" width="20rem" height="3em" />
      <Skeleton variant="text" width="20rem" height="3em" />
    </div>
  );
};

export default WorkingHoursSkeleton;
