import { Frequency } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { Select, SelectOption } from "@clockwise/design-system";
import { DateTime } from "luxon";
import React, { useMemo } from "react";

type RepetitionTypeFieldProps = {
  type: "date" | "nth" | "last";
  date: string;
  frequency: Frequency.MONTHLY | Frequency.YEARLY;
  onChange: (type: "date" | "nth" | "last") => void;
};
export const RepetitionTypeField = ({
  type,
  date,
  frequency,
  onChange,
}: RepetitionTypeFieldProps) => {
  const dt = useMemo(() => DateTime.fromISO(date), [date]);
  const { day, daysInMonth, weekdayLong, monthLong } = dt;
  const nthWeekday = Math.floor(day / 7);
  const isLastWeekday = daysInMonth - day < 7;

  const optionLabels: Record<typeof type, string> = useMemo(
    () =>
      frequency === Frequency.MONTHLY
        ? {
            date: `Monthly on day ${day}`,
            nth: `Monthly on the ${
              ["first", "second", "third", "fourth", "fifth"][nthWeekday]
            } ${weekdayLong}`,
            last: `Monthly on the last ${weekdayLong}`,
          }
        : {
            date: `Yearly on ${monthLong} ${day}`,
            nth: `Yearly on the ${
              ["first", "second", "third", "fourth", "fifth"][nthWeekday]
            } ${weekdayLong} of ${monthLong}`,
            last: `Yearly on the last ${weekdayLong} of ${monthLong}`,
          },
    [frequency, day, nthWeekday, weekdayLong, monthLong],
  );

  return (
    <Select value={type} onChange={onChange}>
      <SelectOption value="date">{optionLabels.date}</SelectOption>
      {nthWeekday < 4 && <SelectOption value="nth">{optionLabels.nth}</SelectOption>}
      {isLastWeekday && <SelectOption value="last">{optionLabels.last}</SelectOption>}
    </Select>
  );
};
