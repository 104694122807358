import { Assistant } from "@clockwise/design-system/icons";
import { CheckCircleFilled } from "@clockwise/icons";
import { ClockwiseLogo } from "@clockwise/web-commons/src/components/svgs/ClockwiseLogo";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";

export type AssuranceProps = {
  flexible?: boolean;
  clockwiseUsersFirstNames?: string[];
};

export const getUserFirstNamesTogether = (clockwiseUsersFirstNames: string[]): string => {
  if (clockwiseUsersFirstNames.length === 1) {
    return clockwiseUsersFirstNames[0];
  }

  return clockwiseUsersFirstNames.reduce((prevVal, firstName, index) => {
    if (index < clockwiseUsersFirstNames.length - 1) {
      return prevVal + firstName + ", ";
    }

    return prevVal + `and ${firstName}`;
  }, "");
};

export const ThereAreClockwiseUsersMessage = ({
  clockwiseUsersFirstNames,
}: {
  clockwiseUsersFirstNames: string[];
}) => {
  const firstUserName = clockwiseUsersFirstNames[0];

  switch (clockwiseUsersFirstNames.length) {
    case 0:
      // Shouldn't be reached, but just in case
      return <span>"Zero users are Clockwise users" </span>;
    case 1:
      return (
        <span className="cw-whitespace-nowrap">
          <span className="cw-text-brand">{firstUserName}</span> is a Clockwise user
        </span>
      );
    case 2:
      // non-oxford comma case!
      return (
        <span className="cw-whitespace-nowrap">
          <span className="cw-text-brand">{firstUserName}</span> and{" "}
          <span className="cw-text-brand">{clockwiseUsersFirstNames[1]}</span> are Clockwise users
        </span>
      );
    case 3:
      // oxford comma case!
      return (
        <span className="cw-whitespace-nowrap">
          <span className="cw-text-brand">
            {getUserFirstNamesTogether(clockwiseUsersFirstNames)}
          </span>{" "}
          are Clockwise users
        </span>
      );
    default:
      // group too many users together
      return (
        <span className="cw-whitespace-nowrap">
          <span className="cw-text-brand">{firstUserName}</span> and{" "}
          <span className="cw-text-brand">{clockwiseUsersFirstNames.length - 1} others</span> are
          Clockwise users
        </span>
      );
  }
};

const DotDotDot = () => (
  <span className="cw-flex cw-flex-1 cw-border-0 cw-border-t cw-border-dashed cw-border-grey-standard cw-border-opacity-20"></span>
);

export const Assurances = ({ flexible, clockwiseUsersFirstNames = [] }: AssuranceProps) => {
  const thereAreClockwiseUsers = !isEmpty(clockwiseUsersFirstNames);

  return (
    <div className="cw-w-full cw-flex cw-justify-center">
      <div className={classNames("cw-flex cw-flex-col cw-justify-between cw-w-full cw-gap-1")}>
        {flexible && (
          <div className="cw-flex cw-items-center cw-gap-2 cw-caption">
            <Assistant className="cw-text-busy cw-h-4 cw-w-4" />
            <span>
              This meeting is <span className="cw-text-black">flexible</span>
            </span>
            <DotDotDot />
            <CheckCircleFilled className="cw-text-busy cw-h-4 cw-w-4" />
          </div>
        )}
        {thereAreClockwiseUsers && (
          <div className="cw-flex cw-items-center cw-gap-2 cw-caption">
            <ClockwiseLogo width="16" height="16" viewBox="0 0 24 24" fill="rgb(68, 76, 231)" />

            <ThereAreClockwiseUsersMessage clockwiseUsersFirstNames={clockwiseUsersFirstNames} />
            <DotDotDot />
            <CheckCircleFilled className="cw-text-busy cw-h-4 cw-w-4" />
          </div>
        )}
      </div>
    </div>
  );
};
