import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UserDataSourcesFragment = { __typename: 'User', dataSources: { __typename: 'DataSourceConnection', edges: Array<{ __typename: 'DataSourceEdge', node: { __typename: 'DataSource', id: string, name: string | null, syncTargets: Array<{ __typename: 'CrawlTarget', calendarId: string | null } | null> | null } | null } | null> | null } | null };

export type PersonalDataSourcesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PersonalDataSourcesQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', id: string, user: { __typename: 'User', id: string, orgs: { __typename: 'OrgConnection', edges: Array<{ __typename: 'OrgEdge', node: { __typename: 'Org', id: string, domains: Array<string> } | null } | null> | null }, dataSources: { __typename: 'DataSourceConnection', edges: Array<{ __typename: 'DataSourceEdge', node: { __typename: 'DataSource', id: string, name: string | null, syncTargets: Array<{ __typename: 'CrawlTarget', calendarId: string | null } | null> | null } | null } | null> | null } | null } | null } };

export const UserDataSourcesFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserDataSources"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dataSources"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"1000000"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DataSource"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"syncTargets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"calendarId"}}]}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<UserDataSourcesFragment, unknown>;
export const PersonalDataSourcesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PersonalDataSources"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"orgs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"domains"}}]}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserDataSources"}}]}}]}}]}}]}},...UserDataSourcesFragmentDoc.definitions]} as unknown as DocumentNode<PersonalDataSourcesQuery, PersonalDataSourcesQueryVariables>;