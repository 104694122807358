import { Cancel, CheckCircle } from "@clockwise/design-system/icons";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { ClockwiseIntelligenceFilled } from "@clockwise/icons";
import { OwnerEcosystemEnum, PublicSharedProposal } from "@clockwise/schema/v2";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { normalizeDuration } from "@clockwise/web-commons/src/util/duration.util";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import { MagicWand, Warning } from "phosphor-react";
import React from "react";
import { googleGSVG } from "../../assets/img/google";
import { m365Logo } from "../../assets/img/microsoft";
import { LoginBtn } from "../login-btn";
import { MsftLoginButton } from "../msft-login-button";
import { formattedDateTime } from "../web-app-calendar/calendar-popover/utils/formattedDateTime";
import { ProposalOwner } from "./ProposalOwner";
import { LoginInProgressViaType } from "./SharedProposalRendererGateway";

type PublicSharedProposalActiveProps = {
  proposal: PublicSharedProposal;
  loginInProgressVia: LoginInProgressViaType;
  onLoginStart: (button: string) => void;
  onLoginSuccess: () => void;
  onLoginFailure: () => void;
  onGoogleFailure: () => void;
};

const getTimeText = (timeInterval: string | null | undefined, timezone: string) => {
  if (!timeInterval) return null;

  return formattedDateTime(DateTime.fromISO(timeInterval.split("/")[0]), timezone, true, true);
};

export const PublicSharedProposalActive = ({
  proposal,
  loginInProgressVia,
  onLoginStart,
  onLoginSuccess,
  onLoginFailure,
  onGoogleFailure,
}: PublicSharedProposalActiveProps) => {
  const redirect = `app/proposal/${proposal.proposalId}`;

  const eventSummary = proposal.eventSummary;
  // TODO: Ask BE to return a type for eventSummary
  const isReschedule = !isEmpty(eventSummary.oldTime);
  const title = eventSummary.title;
  const startTimeFormatted = getTimeText(eventSummary.time, proposal.ownerTimeZone);
  const oldTimeFormatted =
    isReschedule && getTimeText(eventSummary.oldTime, proposal.ownerTimeZone);
  const attendees = eventSummary.attendees;
  const hasFixableConflicts = !isEmpty(proposal.consequencesSummary);

  let titleBody = `${proposal.owner.profile.givenName} is `;
  titleBody += isReschedule ? " rescheduling a meeting" : " inviting you to a meeting";
  titleBody += ", and needs your input";
  titleBody += hasFixableConflicts ? " to fix a conflict." : ".";
  const ownerEcosystem = proposal.ownerEcosystem ?? OwnerEcosystemEnum.Google;

  return (
    <>
      <ProposalOwner owner={proposal.owner} titleBody={titleBody} />
      <div className="cw-flex-col cw-my-2">
        <div
          className="cw-w-full cw-rounded-xl cw-drop-shadow-sm cw-border cw-border-solid cw-border-muted
              cw-group focus:cw-border-brand-emphasis-pressed cw-body-base cw-overflow-hidden cw-bg-default
              hover:cw-border-brand-emphasis-hover cw-p-3"
        >
          <div className="cw-font-semibold">{title}</div>
          {startTimeFormatted && <div>{startTimeFormatted}</div>}
          {oldTimeFormatted && <div className="cw-line-through">{oldTimeFormatted}</div>}
          {!isEmpty(attendees) && (
            <>
              <AttendeeAvatarStack size="medium" overlap maxShown={5}>
                {attendees.map((attendee, index) => {
                  return (
                    <AttendeeAvatar
                      key={index}
                      isInOverlap
                      profile={attendee.profile}
                      size="medium"
                    />
                  );
                })}
              </AttendeeAvatarStack>
              <span>
                {" | "}
                {normalizeDuration(eventSummary.durationInMinutes).toHuman()}
              </span>
            </>
          )}
        </div>
      </div>
      {hasFixableConflicts && (
        <div className="cw-mt-2 cw-flex cw-flex-row cw-p-3">
          <div className="cw-flex cw-items-center">
            <MagicWand color="#4459dd" weight="fill" size="14px" />
          </div>
          <div className="cw-flex cw-items-center cw-ml-2">{proposal.consequencesSummary}</div>
        </div>
      )}
      {!isEmpty(proposal.tradeoffsSummary) && (
        <div className="cw-mt-2 cw-flex cw-flex-row cw-p-3">
          <div className="cw-flex cw-items-center">
            <Warning color="#DC6803" weight="fill" size="14px" />
          </div>
          <div className="cw-flex cw-items-center cw-ml-2">{proposal.tradeoffsSummary}</div>
        </div>
      )}
      <div className="cw-flex cw-flex-col cw-w-full cw-mt-3 cw-gap-2">
        <div className="cw-flex cw-border-t cw-border-solid cw-border-t-muted cw-justify-between">
          <div className="cw-text-16 cw-pt-3">Accept the new time?</div>
          <div className="cw-flex cw-justify-between">
            <div className="cw-pt-3 cw-border-solid cw-text-accent cw-border-t-muted cw-pr-2">
              Will need to sign-in
            </div>
            {ownerEcosystem === OwnerEcosystemEnum.Microsoft ? (
              <img width="18" height="18" className="cw-mt-3" src={m365Logo} alt="microsoft-logo" />
            ) : (
              <img width="20" height="20" className="cw-mt-3" src={googleGSVG} alt="google-logo" />
            )}
          </div>
        </div>
        {loginInProgressVia === "FixConflicts" ? (
          <Loader size="sm" sentiment="positive" />
        ) : ownerEcosystem === OwnerEcosystemEnum.Microsoft ? (
          <MsftLoginButton
            label={"Review the conflicts"}
            sentiment={"accent"}
            variant="default"
            icon={ClockwiseIntelligenceFilled}
            iconClassNames="cw-text-busy-muted-pressed"
          />
        ) : (
          <LoginBtn
            label={"Review the conflicts"}
            variant={"default"}
            redirect={redirect}
            onStart={() => onLoginStart("FixConflicts")}
            onSuccess={onLoginSuccess}
            onFailure={onLoginFailure}
            onGoogleFailure={onGoogleFailure}
            textAlign="left"
            icon={ClockwiseIntelligenceFilled}
            iconClassNames="cw-text-busy-muted-pressed"
            sentiment={"accent"}
          />
        )}
        {loginInProgressVia === "Accept" ? (
          <Loader size="sm" sentiment="positive" />
        ) : ownerEcosystem === OwnerEcosystemEnum.Microsoft ? (
          <MsftLoginButton
            label={"Yes"}
            sentiment={"accent"}
            variant="default"
            icon={CheckCircle}
            iconClassNames="cw-text-positive-muted"
          />
        ) : (
          <LoginBtn
            label={"Yes"}
            redirect={redirect}
            variant={"default"}
            onStart={() => onLoginStart("Accept")}
            onSuccess={onLoginSuccess}
            onFailure={onLoginFailure}
            onGoogleFailure={onGoogleFailure}
            textAlign="left"
            icon={CheckCircle}
            iconClassNames="cw-text-positive-muted"
            sentiment={"accent"}
          />
        )}
        {loginInProgressVia === "Decline" ? (
          <Loader size="sm" sentiment="positive" />
        ) : ownerEcosystem === OwnerEcosystemEnum.Microsoft ? (
          <MsftLoginButton
            label={"No"}
            sentiment={"accent"}
            variant="default"
            icon={Cancel}
            iconClassNames="cw-text-destructive-muted"
          />
        ) : (
          <LoginBtn
            label={"No"}
            variant={"default"}
            redirect={redirect}
            onStart={() => onLoginStart("Decline")}
            onSuccess={onLoginSuccess}
            onFailure={onLoginFailure}
            onGoogleFailure={onGoogleFailure}
            textAlign="left"
            icon={Cancel}
            iconClassNames="cw-text-destructive-muted"
            sentiment={"accent"}
          />
        )}
      </div>
    </>
  );
};
