import { OutOfOfficeTabs } from "@clockwise/web-commons/src/components/calendar";
import {
  EventType,
  useReadActiveEvent,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getOwnCalendarColor } from "@clockwise/web-commons/src/util/calendar-coloring";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { compact } from "lodash";
import { DateTime, Interval } from "luxon";
import React from "react";
import { useFeatureFlag } from "../../../launch-darkly";
import { useUpdateCurrentProposal } from "../../chat-plus-calendar/CurrentProposalContext";
import { useUpdateActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import { useAIMessageContext } from "../../chat/ai-chat/hooks/AIMessageContext";
import { usePlannerContext } from "../Context";
import { useReadCalendarColors } from "../hooks/CalendarColorsContext";
import { hasIntervalAllDayLong } from "./utils/hasIntervalAllDayLong";

type Props = {
  dateTimes: DateTime[];
  onClick: (calendarId: string, externalEventId: string, type: EventType) => void;
};

export const AllDayEventsUnderlay = ({ dateTimes, onClick }: Props) => {
  const underlayRef = React.useRef<HTMLDivElement>(null);
  const [isOnClickToCreate] = useFeatureFlag("ClickToCreate");
  const { containsActiveProposal: chatContainsActiveProposal } = useAIMessageContext();
  const {
    allDayOOOEvents: allDayOOOEventsNullable,
    primaryCalendarId,
    multiCalendarIds,
    popoverClosing,
  } = usePlannerContext();
  const calendarColorSets = useReadCalendarColors();
  const { initOrRescheduleProposal } = useUpdateCurrentProposal();
  const activeEvent = useReadActiveEvent();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateActiveEventDiff = useUpdateActiveEventDiff();

  const attendees = compact([primaryCalendarId, ...(multiCalendarIds ?? [])]);

  const defaultTitle = attendees.length > 1 ? "Sync" : "Hold";

  const allDayOOOEventsByDay = allDayOOOEventsNullable ?? {};
  const oooEventsFlat = Object.values(allDayOOOEventsByDay).flatMap((events) => events);
  const outOfOfficeEvents = oooEventsFlat.filter((event) =>
    dateTimes.some(
      (dateTime) =>
        event.interval.contains(dateTime) &&
        (event.interval.toDuration().as("days") >= 1 || hasIntervalAllDayLong(event)),
    ),
  );
  const activeEventIsProposal = activeEvent && activeEvent.type === EventType.Proposal;

  const onCreateProposal = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    // If there is an active event, e.g. the sidebar is open with an existing event, we don't want to create a new event.
    if (activeEventIsProposal) {
      return;
    }

    // If there is an active proposal in the chat, we don't want to create a new event.
    if (chatContainsActiveProposal) {
      return;
    }

    // If the popover is closing, we don't want to create a new event.
    if (popoverClosing) {
      return;
    }

    // Relative x position of the click
    const clickTarget = e.clientX - (underlayRef.current?.getBoundingClientRect().left ?? 0);

    // Width of a single day based on the number of dateTimes displayed
    const dayWidth = (underlayRef.current?.getBoundingClientRect().width ?? 0) / dateTimes.length;

    // Favor the previous day if the click is on the boundary between two days
    const targetDayIndex = Math.floor(clickTarget / dayWidth);
    const selectedDateTime = dateTimes[targetDayIndex].setZone(getRenderTimeZone());

    updateActiveEventDiff(null);
    updateActiveEvent({ calendarId: "", externalEventId: "", type: EventType.Proposal });
    initOrRescheduleProposal({
      proposalId: null,
      title: defaultTitle,
      eventCalendarId: primaryCalendarId,
      description: "",
      startTime: selectedDateTime.startOf("day"),
      endTime: selectedDateTime.plus({ days: 1 }).startOf("day"),
      timeZone: getRenderTimeZone(),
      initialAttendeeIds: attendees,
      attendees: [],
      allDay: true,
      recurrenceRule: null,
      location: null,
      conferenceType: null,
      flexDetails: {
        isFlexible: false,
      },
      meta: {
        isDragging: false,
        isFindATime: false,
      },
      transparency: "opaque",
      visibility: "default",
    });
  };

  if (!outOfOfficeEvents.length) {
    return null;
  }

  return (
    <div className="cw-h-6 cw-w-full cw-relative cw-flex cw-flex-row cw-flex-1">
      <div className="cw-w-10 cw-overflow-hidden" />
      <div
        className="cw-h-full cw-w-full cw-flex cw-flex-row cw-flex-1 cw-relative cw-overflow-y-hidden [scrollbar-gutter:stable]"
        ref={underlayRef}
        onClick={isOnClickToCreate ? onCreateProposal : undefined}
      >
        <OutOfOfficeTabs
          dateTimes={dateTimes}
          outOfOfficeIntervals={outOfOfficeEvents.map((event) => ({
            colorSet: calendarColorSets[event.calendarIds[0]] || getOwnCalendarColor(),
            // passing in full day is a bit of kludge, but will be required unless we update positioner logic
            interval: Interval.fromDateTimes(
              event.interval.start.startOf("day"),
              event.interval.start.endOf("day"),
            ),
            key: event.key,
            onClick: () => {
              if (!activeEventIsProposal) {
                onClick(event.calendarIds[0], event.externalEventId || "", EventType.Event);
              }
            },
            subText: event.subText,
            text: event.text,
          }))}
        />
      </div>
    </div>
  );
};
