import { Button, Checkbox, Divider, Link, Tooltip } from "@clockwise/design-system";
import { TeamCalendarMemberInviteStatus } from "@clockwise/schema/v2";
import { PaywallBannerUpgradeCta } from "@clockwise/web-commons/src/ui/paywall-banner-upgrade-cta";

import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { Delete, Email } from "@clockwise/design-system/icons";
import { FeatureSettingsHoverCard } from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import classNames from "classnames";
import * as React from "react";
import { useMonetization } from "../../../../hooks/useMonetization";

const EmptyState = ({
  onSharedCalendarToggle,
  loading,
}: {
  onSharedCalendarToggle: () => void;
  loading: boolean;
}) => {
  const { canTeamAvailabilityAndNMDBeEnabled } = useMonetization();

  const onCreateSharedCalendar = () => {
    onSharedCalendarToggle();
    track(TrackingEvents.TEAMS.M365_TEAM_SETTINGS_ADMIN_CREATE_TEAM_CALENDAR);
  };

  return (
    <div className="cw-border cw-border-subtle cw-border-solid cw-rounded cw-flex cw-flex-col cw-items-center cw-justify-center cw-p-9">
      <div className="cw-w-fit">
        <Button
          variant="outlined"
          sentiment="neutral"
          onClick={onCreateSharedCalendar}
          disabled={!canTeamAvailabilityAndNMDBeEnabled || loading}
        >
          Create shared calendar on Outlook
        </Button>
      </div>
      <div className="cw-mt-3 cw-text-14 cw-text-center cw-leading-5 cw-max-w-[560px]">
        Outlook will send an email inviting teammates to subscribe to the team calendar. Teammates{" "}
        <span className="cw-font-bold">must accept the invite</span> in order to see the shared
        calendar in Outlook.
      </div>
    </div>
  );
};

const EditState = ({
  isTeamCalendarShareChecked,
  onSharedCalendarToggle,
  onResendInvites,
  isSyncWFHEventsEnabled,
  onHandleSyncWFHEventsToggle,
  teamName,
  teamCalendarDetails,
}: {
  isTeamCalendarShareChecked: boolean;
  onSharedCalendarToggle: () => void;
  onResendInvites: () => void;
  isSyncWFHEventsEnabled: boolean;
  onHandleSyncWFHEventsToggle: () => void;
  teamName: string;
  teamCalendarDetails: {
    teamId: string;
    calendarName: string;
    members: Array<{
      personId: string;
      status: TeamCalendarMemberInviteStatus;
    }>;
    owner: {
      displayName: string;
      isMe: boolean;
    };
  };
}) => {
  const { canTeamAvailabilityAndNMDBeEnabled } = useMonetization();

  const owner = teamCalendarDetails.owner;
  const ownerDisplayName = owner.displayName;

  const tooltipText = !owner.isMe ? "You're not the calendar owner for this team" : "";

  const handleRemoveSharedCalendar = () => {
    if (!owner.isMe) {
      return;
    }

    onSharedCalendarToggle();
    track(TrackingEvents.TEAMS.M365_TEAM_SETTINGS_ADMIN_REMOVE_TEAM_CALENDAR);
  };

  const handleResendInvites = () => {
    if (!owner.isMe) {
      return;
    }

    onResendInvites();
    track(TrackingEvents.TEAMS.M365_TEAM_SETTINGS_ADMIN_RESEND_TEAM_CALENDAR_INVITES);
  };

  return (
    <>
      <div className="cw-mb-2">
        <span className="cw-font-bold">Shared calendar:</span> Clockwise: {teamName}
      </div>
      <div>
        <span className="cw-font-bold">Calendar owner:</span> {ownerDisplayName}
      </div>
      <Divider spacing="md" />
      <div>
        <Tooltip title={tooltipText} placement="top" arrow>
          <div>
            <div className={classNames({ "cw-opacity-50 cw-pointer-events-none": !owner.isMe })}>
              <Checkbox
                checked={isSyncWFHEventsEnabled}
                disabled={!isTeamCalendarShareChecked || !canTeamAvailabilityAndNMDBeEnabled}
                onChange={onHandleSyncWFHEventsToggle}
                label="Sync events that Clockwise categorizes as WFH (work from home)"
              />
            </div>
          </div>
        </Tooltip>
      </div>
      <div className="cw-mt-4 cw-mb-8 cw-flex cw-flex-row cw-gap-[8px]">
        <Tooltip title={tooltipText} placement="top" arrow>
          <div>
            <div className={classNames({ "cw-opacity-50 cw-pointer-events-none": !owner.isMe })}>
              <Button
                variant="outlined"
                sentiment="neutral"
                onClick={handleRemoveSharedCalendar}
                disabled={!canTeamAvailabilityAndNMDBeEnabled}
                startIcon={Delete}
              >
                Remove shared calendar from Outlook
              </Button>
            </div>
          </div>
        </Tooltip>
        <Tooltip title={tooltipText} placement="top" arrow>
          <div>
            <div className={classNames({ "cw-opacity-50 cw-pointer-events-none": !owner.isMe })}>
              <Button
                variant="outlined"
                sentiment="neutral"
                onClick={handleResendInvites}
                disabled={!canTeamAvailabilityAndNMDBeEnabled}
                startIcon={Email}
              >
                Resend invites for Outlook team calendar
              </Button>
            </div>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

type TeamCalendarDetails = {
  teamId: string;
  calendarName: string;
  members: Array<{
    personId: string;
    status: TeamCalendarMemberInviteStatus;
  }>;
  owner: {
    displayName: string;
    isMe: boolean;
  };
};

export const M365TeamSettingsAdminTeamCalendar = (props: {
  isTeamCalendarShareChecked: boolean;
  onSharedCalendarToggle: () => void;
  onResendInvites: () => void;
  isSyncWFHEventsEnabled: boolean;
  onHandleSyncWFHEventsToggle: () => void;
  teamName: string;
  teamCalendarDetails: TeamCalendarDetails | null;
  loading: boolean;
}) => {
  const { isTeamCalendarShareChecked, teamCalendarDetails } = props;
  const { canTeamAvailabilityAndNMDBeEnabled, shouldUserSeeJoinExistingPlan } = useMonetization();

  const isTeamCalendarCreated =
    teamCalendarDetails && isTeamCalendarShareChecked && canTeamAvailabilityAndNMDBeEnabled;

  return (
    <div>
      <div className="cw-heading-2xl cw-mb-4">
        Sync{" "}
        <FeatureSettingsHoverCard
          id="team-availability-desc-tooltip"
          content={
            <>
              Clockwise automatically syncs individual team member's “Out of office / unavailable”
              events to a shared team calendar to help the team stay informed about everyone's
              availability at a glance.{" "}
              <Link
                href="https://support.getclockwise.com/article/43-team-automatic-out-of-office-calendar"
                target="_blank"
                rel="noreferrer"
                onEmphasis
              >
                Learn more
              </Link>
            </>
          }
        >
          <span
            className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1"
            aria-describedby="team-availability-desc-tooltip"
          >
            everyone's availability
          </span>
        </FeatureSettingsHoverCard>
        ?
      </div>
      {!canTeamAvailabilityAndNMDBeEnabled && (
        <PaywallBannerUpgradeCta
          showJoinExistingPlan={shouldUserSeeJoinExistingPlan}
          bannerType="AvailabilityLocked"
          pricingTracking={() =>
            track(TrackingEvents.PAYWALLS.M2_ADMIN_AVAILABILTIY_PRICING_CLICKED)
          }
          billingTracking={() =>
            track(TrackingEvents.PAYWALLS.M2_ADMIN_AVAILABILTIY_JOIN_PLAN_CLICKED)
          }
        />
      )}
      {isTeamCalendarCreated ? (
        <EditState {...props} teamCalendarDetails={teamCalendarDetails} />
      ) : (
        <EmptyState onSharedCalendarToggle={props.onSharedCalendarToggle} loading={props.loading} />
      )}
    </div>
  );
};
