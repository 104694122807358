import { ApolloError } from "@apollo/client";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime } from "luxon";
import { CalendarEventsDocument } from "../../web-app-calendar/apollo/__generated__/CalendarEvents.v2.generated";
import {
  UnsyncSmartHoldDocument,
  UnsyncSmartHoldMutation,
} from "../../web-app-calendar/apollo/__generated__/UnsyncSmartHold.v2.generated";

export const useUnsyncSmartHold = (
  callback: () => void,
  errorCallback?: (error: ApolloError) => void,
) => {
  const [unsyncSmartHoldGateway, { loading }] = useGatewayMutation(UnsyncSmartHoldDocument);

  const unsyncSmartHold = (
    externalEventId: string,
    calendarId: string,
    holdStartTime?: DateTime,
    optimisticResponse?: UnsyncSmartHoldMutation,
  ) => {
    const startOfWeek =
      holdStartTime?.startOf("week").minus({ days: 1 }).toFormat("yyyy-MM-dd") || null;
    void unsyncSmartHoldGateway({
      variables: { externalEventId },
      onCompleted: () => {
        callback();
      },
      onError: (error) => {
        errorCallback?.(error);
      },
      optimisticResponse,
      refetchQueries: startOfWeek
        ? [
            {
              query: CalendarEventsDocument,
              variables: {
                calendarIds: [calendarId],
                weekStartDate: startOfWeek,
                timeZone: getRenderTimeZone(),
              },
            },
          ]
        : [],
    });
  };

  return { unsyncSmartHold, loading };
};
