import React from "react";
import { useCurrentProposal } from "../../chat-plus-calendar/CurrentProposalContext";
import { ProposalEventCard } from "./ProposalEventCard";
import { ProposalSchedulingCard } from "./ProposalSchedulingCard";

export const ProposalCardManager = () => {
  const { currentProposal } = useCurrentProposal();
  const { eventId, eventCalendarId } = currentProposal;

  const isEditingExistingEvent = !!eventId && !!eventCalendarId;

  if (isEditingExistingEvent) {
    return <ProposalEventCard />;
  } else {
    return <ProposalSchedulingCard />;
  }
};
