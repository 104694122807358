import { createLoginRedirect, isAuthed } from "#webapp/src/util/auth.util";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const AppAuthenticationLayout = () => {
  const location = useLocation();
  const isAuthenticated = isAuthed();

  if (!isAuthenticated) {
    return <Navigate to={createLoginRedirect(location)} replace />;
  }

  return <Outlet />;
};
