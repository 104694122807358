import React, { useEffect } from "react";
import Helmet from "react-helmet";
import AccountSettings from "../components/account-settings/AccountSettings";
import { PageEvents, page } from "../util/analytics.util";

const AccountSettingsPage = () => {
  useEffect(() => {
    page(PageEvents.ACCOUNT_SETTINGS_PAGE);
  }, []);

  return (
    <>
      <Helmet>
        <title>Preferences · Clockwise</title>
      </Helmet>
      <AccountSettings />
    </>
  );
};

export default AccountSettingsPage;
