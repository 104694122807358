import { Button, TextField } from "@clockwise/design-system";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLoginCredentialsUserMutation } from "./useLoginCredentialsUserMutation";

export const CredentialsLoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loginCredentialsUser, { loading }] = useLoginCredentialsUserMutation({
    onCompleted: () => {
      toast.success("Login successful");
    },
    onError: () => {
      toast.error("Error logging in");
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    void loginCredentialsUser({
      variables: {
        input: {
          email,
          // DevNote: This password is not hashed. It should not be used for anything other than testing with automation users in non-production environments.
          password,
        },
      },
    });
  };

  return (
    <>
      <form
        className="cw-flex cw-flex-col cw-gap-4 cw-w-80 cw-border cw-border-solid cw-border-muted cw-rounded-lg cw-shadow-md cw-p-4"
        onSubmit={handleSubmit}
        cw-id="cw-credentials-login-form"
      >
        <TextField
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          cw-id="cw-automation-email-input"
        />
        <TextField
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          cw-id="cw-automation-password-input"
        />
        <Button disabled={loading} type="submit" cw-id="cw-automation-login-button" size="small">
          {loading ? "Logging in..." : "Login"}
        </Button>
      </form>
    </>
  );
};
