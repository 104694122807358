import { Profile } from "@clockwise/web-commons/src/util/profile.util";

export const getAttendeeProfiles = (obj: {
  attendees: { person: { profile: Partial<Profile> | null } | null }[] | null;
}) => {
  if (!obj.attendees?.length) return [];

  const results: Profile[] = [];

  obj.attendees.map(({ person }) => {
    if (!person) return;
    if (!person.profile) return;

    results.push(person.profile);
  });

  return results;
};

export default getAttendeeProfiles;
