import { Frequency, RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { DayOfWeek, FlexDetails, FlexRange, FlexStatus, TimeRange } from "@clockwise/schema/v2";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime, Interval } from "luxon";

export const MAX_ATTENDEES_FOR_FLEX_LIMIT = 5;

export type EventForFlexibilityFromQuery = {
  id: string;
  externalEventId: string;
  flexibility: FlexDetails | null;
  isOutOfOffice?: boolean;
  dateOrTimeRange:
    | {
        __typename: "DateRange";
        dateRange: string;
      }
    | {
        __typename: "DateTimeRange";
        timeRange: string;
      };
  timeDescription?: string;
  title: string;
  attendees?: AttendeeForFlexEvent[];
  canMarkFlexible: boolean;
  recurrenceRule?: string | null;
};

export type AttendeeForFlexEvent = {
  person: {
    id: string;
    email: string;
    givenName?: string | null;
    familyName?: string | null;
    externalImageUrl?: string | null;
    isMe?: boolean;
  };
  isExternal?: boolean | null;
  isOrganizer: boolean | null;
  isOptional: boolean | null;
};

export type EventForFlexibility = {
  eventId: string;
  id: string; // this is the id of the event in the db, used for optimistic updates and cacheing
  title: string;
  status: FlexStatus;
  attendees: AttendeeForFlexEvent[];
  startTime: DateTime;
  isFlexible: boolean;
  flexRange: FlexRange;
  allowedDays: DayOfWeek[];
  timeOfDayFlexRange: {
    start: string;
    end: string;
  } | null;
  recurrenceText: string;
  recurrenceType?: Frequency;
  showNotRecommededBadge: boolean;
  canMarkFlexible: boolean;
};

export const getTimeRangeFromFlex = (timeRange?: TimeRange | null) => {
  if (!timeRange?.start || !timeRange?.end) return null;
  return {
    start: timeRange.start,
    end: timeRange.end,
  };
};

export const parseRecurrenceText = ({
  recurrenceRule,
  startTime,
  timeDescription,
}: {
  recurrenceRule?: string | null;
  startTime: DateTime;
  timeDescription?: string;
}) => {
  try {
    return recurrenceRule
      ? new RecurrenceRule(recurrenceRule).toText({
          date: startTime.toISODate(),
          timezone: getRenderTimeZone(),
          prefixString: "Repeats every",
        })
      : timeDescription ?? "";
  } catch (e) {
    return "Unknown";
  }
};

export const parseRecurrenceType = (recurrenceRule?: string | null) => {
  try {
    return recurrenceRule ? new RecurrenceRule(recurrenceRule).toFrequencyString() : undefined;
  } catch (e) {
    return undefined;
  }
};

export const isMeetingRecommendedForFlex = (event: EventForFlexibilityFromQuery) => {
  // We do not recommend:
  // - meetings over 5 attendees
  // - meeting with external attendees
  // - out of office events
  // - meetings that you cannot edit
  const hasExternalAttendees = event.attendees?.some((a) => a.isExternal);

  const isUnderAttendeeLimit = (event.attendees?.length || 0) < MAX_ATTENDEES_FOR_FLEX_LIMIT;

  return (
    !event.isOutOfOffice && !hasExternalAttendees && isUnderAttendeeLimit && event.canMarkFlexible
  );
};

const filterOutMeetingsForFlexPages = (event: EventForFlexibilityFromQuery) => {
  // filter out non recommended meetings (unless already flex)
  // IF the event is already flexible, we still want to show it, but we will add a "not recomended" badge in the UI
  return event.flexibility?.isFlexible || isMeetingRecommendedForFlex(event);
};

export const parseAndFilterFlexibility = (
  events: EventForFlexibilityFromQuery[],
): EventForFlexibility[] => {
  return events
    .filter((event) => filterOutMeetingsForFlexPages(event))
    .map((event) => {
      const {
        id,
        externalEventId,
        flexibility,
        title,
        timeDescription,
        dateOrTimeRange,
        attendees,
        recurrenceRule,
      } = event;
      const startTime = Interval.fromISO(
        dateOrTimeRange.__typename === "DateRange"
          ? dateOrTimeRange.dateRange
          : dateOrTimeRange.timeRange,
      ).start;
      return {
        eventId: externalEventId,
        id: id, // this is the id of the event in the db, used for optimistic updates and cacheing
        title: title,
        status: flexibility?.status || FlexStatus.Pinned,
        attendees: attendees || [],
        startTime,
        isFlexible: !!flexibility?.isFlexible,
        flexRange: flexibility?.flexRange || FlexRange.Day,
        allowedDays: flexibility?.allowedDays || [],
        timeOfDayFlexRange: getTimeRangeFromFlex(flexibility?.timeOfDayFlexRange),
        recurrenceText: parseRecurrenceText({ recurrenceRule, startTime, timeDescription }),
        recurrenceType: parseRecurrenceType(recurrenceRule),
        showNotRecommededBadge: event.canMarkFlexible && !isMeetingRecommendedForFlex(event),
        canMarkFlexible: event.canMarkFlexible,
      };
    });
};
