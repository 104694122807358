import { graphql } from "react-relay";
import "../chrome-slack-verifier";
import "./ChromeSlackWrapper";

export const chromeSlackWrapperFragments = {
  viewer: graphql`
    fragment ChromeSlackWrapper_viewer on Viewer {
      ...ChromeSlackVerifier_viewer
    }
  `,
};

export const chromeSlackWrapperQuery = graphql`
  query ChromeSlackWrapperQuery {
    viewer {
      ...ChromeSlackWrapper_viewer
    }
  }
`;
