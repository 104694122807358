import { FlexStatus } from "@clockwise/schema/v2";
import React from "react";
import { Flexibility } from "../types";
import { CanMove } from "./CanMove";
import { EventExceedsDefragRange } from "./EventExceedsDefragRange";
import { EventOutsideTimeRange } from "./EventOutsideTimeRange";
import { Paused } from "./Paused";
import { TrialExpired } from "./TrialExpired";

export const EventFlexibilityStatusCard = ({
  flexibility,
  nextDefrag,
  onGoToBilling,
  onUnpauseEvent,
  onViewPlans,
  showJoinExistingPlan,
}: {
  flexibility: Flexibility;
  nextDefrag: string | null;
  onGoToBilling: () => void;
  onUnpauseEvent: () => void;
  onViewPlans: () => void;
  showJoinExistingPlan: boolean;
}) => {
  switch (flexibility?.status) {
    case FlexStatus.EventOutsideTimeRange:
      return <EventOutsideTimeRange movableUntil={flexibility.moveableUntil} />;
    case FlexStatus.EventExceedsDefragRange:
      return (
        <EventExceedsDefragRange
          canMoveAnytime={!!flexibility.canMoveAnyTime}
          nextDefrag={nextDefrag}
        />
      );
    case FlexStatus.CanMove:
      return <CanMove canMoveAnytime={!!flexibility.canMoveAnyTime} nextDefrag={nextDefrag} />;
    case FlexStatus.Paused:
      return <Paused onUnpauseEvent={onUnpauseEvent} />;
    case FlexStatus.TrialExpired:
      return (
        <TrialExpired
          onViewPlans={onViewPlans}
          onGoToBilling={onGoToBilling}
          showJoinExistingPlan={showJoinExistingPlan}
        />
      );
    case FlexStatus.OverQuota: //enum still exists in BE
      return null;
    case FlexStatus.Pinned:
    case FlexStatus.NoDuration:
    case FlexStatus.External:
    case FlexStatus.PrivateEvent:
    case FlexStatus.SmartHold:
      return null;
  }
};
