import { fg_busy, fg_warning } from "@clockwise/design-system/tokens";
import { TradeoffTypeEnum } from "@clockwise/schema";
import { Clock, MagicWand, SmileyMeh, Warning } from "phosphor-react";
import React from "react";

export const getColorForTradeoffType = (type: TradeoffTypeEnum) => {
  switch (type) {
    case TradeoffTypeEnum.AVAILABILITY_ISSUE:
      return fg_warning;
    case TradeoffTypeEnum.OUT_OF_TIME_RANGE:
      return fg_warning;
    case TradeoffTypeEnum.FIXABLE_CONFLICT:
      return fg_busy;
    case TradeoffTypeEnum.INCONVENIENCE:
    case TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE:
      return fg_busy;
  }
};

export const getIconForTradeoffType = (type: TradeoffTypeEnum) => {
  const color = getColorForTradeoffType(type);
  switch (type) {
    case TradeoffTypeEnum.AVAILABILITY_ISSUE:
      return <Warning size={16} color={color} />;
    case TradeoffTypeEnum.FIXABLE_CONFLICT:
      return <MagicWand size={16} color={color} />;
    case TradeoffTypeEnum.INCONVENIENCE:
    case TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE:
      return <SmileyMeh size={16} color={color} />;
    case TradeoffTypeEnum.OUT_OF_TIME_RANGE:
      return <Clock color={color} size={16} />;
  }
};
