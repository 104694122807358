import { useEffect, useRef, useState } from "react";

/**
 * Given an image file or url, returns the original dimensions of the image
 */
export const useImageDimensions = (
  fileOrUrl: File | string | null | undefined,
): { width: number; height: number } | undefined => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const imageRef = useRef<string>();

  useEffect(() => {
    // If we have a file, create a url so we can load it via Image.src
    const url = fileOrUrl instanceof File ? URL.createObjectURL(fileOrUrl) : fileOrUrl;
    if (url) {
      const img = new Image();
      img.onload = () => {
        // Since onload is async, check that we are still considering the same image
        if (imageRef.current === url) {
          setWidth(img.width);
          setHeight(img.height);
        }
      };
      imageRef.current = url;
      img.src = url;
    }

    return () => {
      if (url) {
        // Prevent memory leaks by cleaning up urls created via createObjectURL. For other URLs, this
        // should be a no-op.
        URL.revokeObjectURL(url);
      }
      imageRef.current = undefined;
      setWidth(undefined);
      setHeight(undefined);
    };
  }, [fileOrUrl]);

  return width && height ? { width, height } : undefined;
};
