import { Tooltip } from "@clockwise/design-system";
import { ArrowDownward, ArrowUpward, HelpFilled } from "@clockwise/icons";
import classNames from "classnames";
import React from "react";
import { accentBorderClasses } from "./PersonalizedStatsRowGateway";
import { getDurationAsHours } from "./util/getDurationAsHours";
import { getStatDelta } from "./util/getStatDelta";

type MeetingTimeProps = {
  currentWeek: string;
  previousWeek: string;
};
export const MeetingTime = ({ currentWeek, previousWeek }: MeetingTimeProps) => {
  const currentMeetingTime = getDurationAsHours(currentWeek);
  const previousMeetingTime = getDurationAsHours(previousWeek);
  const delta = getStatDelta(currentMeetingTime, previousMeetingTime);

  const title = currentMeetingTime ? `${currentMeetingTime} hr` : "Zero";
  const tooltipText =
    "Calendar events with other attendees, or that our algorithm has identified as a meeting";
  const percentChange = delta ? `${Math.abs(delta)}%` : "No change";
  const ariaChange = delta
    ? delta > 0
      ? `Up ${percentChange}`
      : `Down ${percentChange}`
    : "No Change";

  return (
    <div
      className={classNames(accentBorderClasses, "cw-flex cw-flex-col cw-items-center", {
        "cw-border-t-[#4459DD] ": currentMeetingTime,
        "cw-border-t-[#A6A6A6]": !currentMeetingTime,
      })}
    >
      <p
        className={classNames("cw-heading-3xl cw-text-41", {
          "cw-text-[#4459DD] ": currentMeetingTime,
          "cw-text-[#A6A6A6]": !currentMeetingTime,
        })}
        aria-label={`${currentMeetingTime} hours of meetings. ${ariaChange} vs previous week.`}
      >
        {title}
      </p>
      <div
        className={classNames("cw-heading-base cw-flex cw-justify-center cw-items-center", {
          "cw-text-[#A6A6A6]": !currentMeetingTime,
        })}
        aria-hidden
      >
        Meetings
        <Tooltip title={tooltipText} placement="bottom">
          <HelpFilled fontSize="inherit" className="cw-ml-1 cw-text-subtle" />
        </Tooltip>
      </div>
      <div className="cw-flex cw-body-base" aria-hidden>
        <p
          className={classNames("cw-flex cw-items-center cw-justify-center cw-mr-1", {
            "cw-text-destructive": delta < 0,
            "cw-text-positive": delta > 0,
            "cw-text-[#A6A6A6]": delta === 0,
          })}
        >
          {delta < 0 && <ArrowDownward fontSize="inherit" />}
          {delta > 0 && <ArrowUpward fontSize="inherit" />}
          {percentChange}
        </p>
        vs previous week
      </div>
    </div>
  );
};
