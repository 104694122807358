import { ApolloError } from "@apollo/client";
import { SmartHoldState } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import {
  ModifySmartHoldDocument,
  ModifySmartHoldMutation,
} from "../../web-app-calendar/apollo/__generated__/ModifySmartHold.v2.generated";

export const useModifySmartHold = (
  callback: (newState?: SmartHoldState) => void,
  errorCallback?: (error: ApolloError) => void,
) => {
  const [modifySmartHoldGateway, { loading }] = useGatewayMutation(ModifySmartHoldDocument);

  const modifySmartHold = ({
    externalEventId,
    holdState,
    timeRange,
    optimisticResponse,
  }: {
    externalEventId: string;
    holdState?: SmartHoldState;
    timeRange?: string;
    optimisticResponse?: ModifySmartHoldMutation;
  }) => {
    const timeZone = getRenderTimeZone();
    void modifySmartHoldGateway({
      variables: { externalEventId, holdState, timeRange, timeZone },
      onCompleted: () => {
        callback(holdState);
      },
      onError: (error) => {
        errorCallback?.(error);
      },
      optimisticResponse,
    });
  };

  return { modifySmartHold, loading };
};
