import { useQuery } from "@apollo/client";
import { getCurrentOrg } from "@clockwise/client-commons/src/util/errorable.util";
import * as ISchema from "@clockwise/schema";
import { compact } from "lodash";
import React from "react";
import { OnboardingInviteDialog } from "./OnboardingInviteDialog";
import { OnboardingOrgInviteRendererDocument } from "./__generated__/OnboardingInviteDialog.generated";

interface IProps {
  onClose: () => void;
  attendeePersonsToInvite: Pick<ISchema.Person, "primaryEmail" | "profile">[];
  orgId: string;
  setInvitationMessage: (msg: string) => void;
  variant?: "flexible-meetings" | "group-link";
}

export const OnboardingInviteDialogRenderer = ({
  onClose,
  attendeePersonsToInvite,
  orgId,
  setInvitationMessage,
  variant = "flexible-meetings",
}: IProps) => {
  const { data, loading } = useQuery(OnboardingOrgInviteRendererDocument, {});

  const [pendingInvites, suggestedInvites] = React.useMemo(() => {
    const org = getCurrentOrg(data?.viewer, orgId);
    const pendingInvites = org?.pendingInvites?.edges;
    const suggestedInvites = org?.suggestedInvites?.edges;
    return [pendingInvites || [], suggestedInvites || []];
  }, [data]);

  return (
    <OnboardingInviteDialog
      setInvitationMessage={setInvitationMessage}
      isLoading={loading}
      onClose={onClose}
      attendeePersonsToInvite={attendeePersonsToInvite}
      orgId={orgId}
      suggestedPersons={compact(suggestedInvites)}
      pendingPersons={compact(pendingInvites)}
      variant={variant}
    />
  );
};
