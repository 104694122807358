import { ConferencingType } from "@clockwise/schema";
import { SupportedConferenceType } from "@clockwise/schema/v2";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { inferPreferredConferencingType } from "../util/video-conference.util";
import { useSupportedConferenceTypes } from "./useSupportedConferenceTypes";

export const useUserConfrencingTypes = () => {
  const {
    supportedConferenceTypes,
    preferredVideoConference,
    loading: typeLoading,
  } = useSupportedConferenceTypes();
  const ecosystem = useEcosystem();

  const conferencingTypes = new Set<ConferencingType>();

  if (!typeLoading) {
    supportedConferenceTypes.forEach((type) => {
      switch (type) {
        case SupportedConferenceType.Meet:
          conferencingTypes.add(ConferencingType.MEET);
          break;
        case SupportedConferenceType.Zoom:
          conferencingTypes.add(ConferencingType.ZOOM);
          break;
        case SupportedConferenceType.TeamsForBusiness:
          conferencingTypes.add(ConferencingType.TEAMS_FOR_BUSINESS);
          break;
      }
    });
  }

  const canUseZoom = conferencingTypes.has(ConferencingType.ZOOM);
  const canUseTeams = conferencingTypes.has(ConferencingType.TEAMS_FOR_BUSINESS);
  const canUseMeet = conferencingTypes.has(ConferencingType.MEET);
  const preferredConferencingType = inferPreferredConferencingType(
    preferredVideoConference,
    supportedConferenceTypes,
    ecosystem,
  );

  return {
    conferencingTypes: Array.from(conferencingTypes),
    canUseZoom,
    canUseTeams,
    canUseMeet,
    preferredConferencingType,
  };
};
