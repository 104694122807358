import { getSiteUrl } from "@clockwise/client-commons/src/config/api";
import {
  CategoryPicker,
  CategoryOption as DesignSystemCategoryOption,
} from "@clockwise/design-system";
import { Sell } from "@clockwise/icons";
import { EventCategoryType } from "@clockwise/schema/v2";
import React from "react";
import { webappDefaultWindowName } from "../../constants/route.constants";
import { getUrlForFeature } from "../../util/routes.util";

export type CategoryOption = {
  value: EventCategoryType;
  color: string | null;
  label: string;
};

type Props = {
  current: CategoryOption;
  disabled?: boolean;
  onChange: (category: CategoryOption) => void;
  options: CategoryOption[];
};

const _openLink = (url: string) => {
  window.open(url, webappDefaultWindowName);
};

export const CategoryPickerModule = ({ current, options, onChange, disabled }: Props) => {
  const routeToColorSettings = () => {
    const site = getSiteUrl();
    const path = getUrlForFeature("accountPreferences", { hash: "#colorCoding", relative: true });
    const url = new URL(path, site);
    _openLink(url.toString());
  };

  const handleChange = (category: DesignSystemCategoryOption) => {
    const newCategory = options.find((opt) => opt.value === category.value);
    if (newCategory) {
      onChange(newCategory);
    }
  };

  return (
    <div className="cw-flex cw-flex-row cw-items-center cw-gap-2">
      <div className="cw-w-4 cw-h-4 cw-flex cw-items-center">
        <Sell className="cw-w-4 cw-h-4" />
      </div>
      <CategoryPicker
        disabled={disabled}
        fullWidth
        size="small"
        value={current}
        options={options}
        onChange={handleChange}
        onEditCategories={routeToColorSettings}
        groupLabel="Meeting category"
        withinPortal={false}
      />
    </div>
  );
};

export const sortByLabel = <T extends { label: string }>(a: T, b: T) => {
  return a.label.localeCompare(b.label);
};
