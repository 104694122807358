import { ChevronRight } from "@clockwise/icons";
import { Users } from "phosphor-react";
import pluralize from "pluralize";
import React, { useState } from "react";
import { APUpcomingBillingDetails } from "../apBillingGroups.util";
import { PendingSeatCountBanner } from "./PendingSeatCountBanner";
import { SeatAdjustmentForm } from "./SeatAdjustmentForm";

export const SeatCount = ({
  isAdmin,
  isTeams,
  onSaveSeatRequest,
  upcomingBillingDetails,
}: {
  isAdmin: boolean;
  isTeams: boolean;
  onSaveSeatRequest: (newSeatCount: number) => void;
  upcomingBillingDetails: APUpcomingBillingDetails;
}) => {
  const [openSeatAdjustmentModal, setOpenSeatAdjustmentModal] = useState(false);

  const { currentUsageQuantity, billedQuantity } = upcomingBillingDetails;

  const getBarWidth = () => {
    return `${(currentUsageQuantity / billedQuantity) * 100}%`;
  };

  return (
    <>
      <div className="cw-w-full cw-bg-positive cw-mb-5 cw-rounded-md cw-text-accent cw-p-7 ">
        <div className="cw-flex cw-items-center cw-gap-2 cw-font-bold ">
          <Users color="#003F2E" weight="fill" height={24} width={24} /> Seat utilization
        </div>
        <div className="cw-flex cw-rounded-full cw-border cw-border-solid cw-border-accent-emphasis cw-my-3">
          <div
            style={{ width: getBarWidth() }}
            className={`cw-h-2 cw-bg-accent-emphasis cw-rounded-full `}
          />
          <div className="cw-flex-1" />
        </div>
        <div className="cw-flex cw-items-center">
          <span>
            {currentUsageQuantity} of {billedQuantity} {pluralize("seat", billedQuantity)} being
            used
          </span>
          {isAdmin && isTeams && (
            <div className="cw-flex cw-items-center">
              <span className="cw-text-subtle cw-mx-1">|</span>
              <button
                onClick={() => {
                  setOpenSeatAdjustmentModal(true);
                }}
                className="cw-border-none cw-flex cw-items-center cw-bg-positive cw-text-brand hover:cw-underline  hover:cw-text-brand-hover active:cw-text-brand-pressed cw-p-0 cw-cursor-pointer cw-body-lg"
              >
                Update seat count <ChevronRight className="cw-w-4 cw-h-4" />
              </button>
            </div>
          )}
        </div>
        <PendingSeatCountBanner upcomingBillingDetails={upcomingBillingDetails} />
      </div>
      <SeatAdjustmentForm
        billedQuantity={billedQuantity}
        currentUsageQuantity={currentUsageQuantity}
        isOpen={openSeatAdjustmentModal}
        closeSeatAdjustmentModal={() => {
          setOpenSeatAdjustmentModal(false);
        }}
        onSaveSeatRequest={onSaveSeatRequest}
      />
    </>
  );
};
