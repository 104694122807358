import { Popover } from "@clockwise/design-system";
import React, { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  title: string | JSX.Element;
  open?: boolean;
}>;

export function WarningPopover({ title, open = false, children }: Props) {
  if (!children) {
    return null;
  }

  return (
    <Popover opened={open}>
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown className="cw-bg-warning cw-body-base cw-text-warning cw-max-w-[300px]">
        {title}
      </Popover.Dropdown>
    </Popover>
  );
}
