import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import React, { useMemo } from "react";
import { track, TrackingEvents } from "../../../../../../util/analytics.util";
import { useAPMembers } from "../../useAPMembers";
import { MultiFilter } from "./multi-filter";

export const MultiFilterWrapper = () => {
  const {
    org,
    billingGroupsForOrg,
    bgAndTeamFilters,
    primaryBillingGroupId,
    handleFilterChange,
  } = useAPMembers();

  const teamsForOrg = useMemo(() => {
    return getValue(org?.teamsForOrg)?.list || [];
  }, [org?.teamsForOrg]);

  const teamOptions = useMemo(() => {
    return teamsForOrg?.map((t) => ({
      label: t.teamName,
      value: t.teamId,
    }));
  }, [teamsForOrg]);

  const getBillinGroupLabel = (billingGroupName: string, billingGroupId: string) => {
    if (billingGroupId === primaryBillingGroupId) {
      return `${billingGroupName} (Your Primary Plan)`;
    }
    return billingGroupName;
  };

  const billingGroupOptions = useMemo(() => {
    return billingGroupsForOrg?.map((bg) => ({
      label: getBillinGroupLabel(bg.name, bg.id),
      value: bg.id,
    }));
  }, [billingGroupsForOrg]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeFilters = (newTeamId: string, newBgId: string) => {
    if (newTeamId) {
      track(TrackingEvents.ADMIN_PANEL.MEMBERS_MULTIFILTER_FILTER_TEAM);
    }
    if (newBgId) {
      track(TrackingEvents.ADMIN_PANEL.MEMBERS_MULTIFILTER_FILTER_BILLING_GROUP);
    }
    handleFilterChange(newBgId, newTeamId);
  };

  return (
    <MultiFilter
      teamOptions={teamOptions}
      billingGroupOptions={billingGroupOptions}
      bgAndTeamFilters={bgAndTeamFilters}
      onSelect={changeFilters}
    />
  );
};
