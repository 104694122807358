import { useMutation } from "@apollo/client";
import React, { useState } from "react";

import { ClearUserDataDocument } from "./mutations/__generated__/ClearUserData.generated";

import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { Button } from "@clockwise/design-system";

import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { CwDialogConfirmRed } from "@clockwise/web-commons/src/ui/cw-dialog";
import toast from "react-hot-toast";
import { useLogout } from "../../../hooks/useLogout";

export const ClearUserDataButton = () => {
  const [clearing, setClearing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [logoutUser] = useLogout({
    onError: (error) => logger.error("An error occurred on logout", error),
  });
  const [clearUserDataMutation] = useMutation(ClearUserDataDocument, {
    onCompleted: () => onClearComplete(),
    onError: (error) => {
      logger.error(`failed to resetOnboarding: ${error}`);
      setClearing(false);
      setDialogOpen(false);
      toast.error("An error occured while trying to clear user");
    },
  });

  if (getEnvironment() === "production") {
    return null;
  }

  const onClearComplete = () => {
    logoutUser();
    localStorage.clear(); //clear all local storage data
    track(TrackingEvents.WEB_SETTINGS.EXPERIMENTS.CLEAR_USER_DATA_CLICKED);
    setDialogOpen(false);
  };

  const clearUserData = () => {
    const synchronousClearUserData = async () => {
      await clearUserDataMutation({});
    };

    setClearing(true);
    synchronousClearUserData();
  };

  return (
    <>
      <CwDialogConfirmRed
        header={"Are you sure that you want clear your data?"}
        body={
          "Clicking confirm will clear all user data, delete local storage, and log out the current user."
        }
        open={dialogOpen}
        onConfirm={() => {
          clearUserData();
        }}
        onCancel={() => setDialogOpen(false)}
        confirmText={clearing ? "Clearing" : "Clear user data"}
      />
      <Button
        size="large"
        sentiment="destructive"
        onClick={() => setDialogOpen(true)}
        cw-id="clear-user-data"
      >
        {clearing ? "Clearing" : "Clear user data"}
      </Button>
    </>
  );
};
