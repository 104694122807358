import { Button, Modal } from "@clockwise/design-system";
import { getCustomDomainRequestToJoinMessageFromUserEmail } from "@clockwise/web-commons/src/util/feature-grid.util";
import React from "react";
import { getSupportedLink, hasSupportedUrl } from "./CustomRequestToJoin.util";

interface CustomRequestToJoinDialogProps {
  calendarId: string | null;
  open: boolean;
  handleClose: () => void;
}

const ConvertLinksInContent = ({ content }: { content: string }) => {
  const tokens = content.split(" ");
  const removeEndingPeriod = (url: string) => {
    if (url.endsWith(".")) {
      return url.slice(0, -1);
    }
    return url;
  };
  return (
    <p>
      {tokens.map((word, index) => {
        return hasSupportedUrl(word) ? (
          <>
            <a
              key={index}
              target="_blank"
              rel="noreferrer"
              href={removeEndingPeriod(getSupportedLink(word).href)}
            >
              {getSupportedLink(word).linkText}
            </a>{" "}
          </>
        ) : (
          word + " "
        );
      })}
    </p>
  );
};

export const CustomRequestToJoinDialog = ({
  calendarId,
  open,
  handleClose,
}: CustomRequestToJoinDialogProps) => {
  const defaultCustomRequestToJoinMessage =
    "This organization uses a custom process for requesting a paid seat. Please follow the internal guidelines set to join this plan.";
  const customDomainRequestToJoinMessage =
    getCustomDomainRequestToJoinMessageFromUserEmail(calendarId) ||
    defaultCustomRequestToJoinMessage;

  return (
    <Modal opened={open} onClose={handleClose} title="Request to Join Plan">
      <div className="cw-body-base" id="custom-join-plan-dialog-description">
        <ConvertLinksInContent content={customDomainRequestToJoinMessage} />
      </div>
      <div className="cw-pt-4 cw-flex cw-justify-end cw-gap-1">
        <Button onClick={handleClose} sentiment="positive" autoFocus>
          OK
        </Button>
      </div>
    </Modal>
  );
};
