import { logger } from "@clockwise/client-commons/src/util/logger";
import { Button } from "@clockwise/design-system";
import { IosShare } from "@clockwise/icons";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { SidebarLink } from "../../../../scheduling-link/LinksSection";
import { useShareProposal } from "../../../../shareable-proposal/hooks/useShareProposal";
import { useSetAIError } from "../../hooks/AIErrorContext";
import {
  GQLAddDiffSummary,
  GQLConsequencesBlock,
  GQLModifyDiffSummary,
  GQLPerson,
  GQLProfile,
  TradeoffBlock,
} from "../../utils/types";
import { CreateLinkFromDraftEventDocument } from "../proposal/__generated__/CreateLinkFromDraftEvent.v2.generated";
import { CreateReschedulingLinkFromDraftEventDocument } from "../proposal/__generated__/CreateReschedulingLinkFromDraftEvent.v2.generated";
import { CalDiffMini, ShareSubMenu } from "../shared-proposal/ShareSubMenu";
import { ShareProposalModal } from "./ShareProposalModal";

export const ProposalAvailability = ({
  disabled,
  allAttendeesExceptViewer,
  viewerProfile,
  canShareProposal,
  baseEventDiffId,
  proposalId,
  sharedProposalDiff,
  consequencesBlock,
  tradeoffBlocks,
  messageId,
  selectedTime,
  isProposeNewTime,
  organizer,
}: {
  disabled: boolean;
  allAttendeesExceptViewer: GQLPerson[];
  viewerProfile: GQLProfile | null;
  canShareProposal: boolean;
  baseEventDiffId: string;
  proposalId: string;
  sharedProposalDiff: GQLAddDiffSummary | GQLModifyDiffSummary | null;
  consequencesBlock: GQLConsequencesBlock | null;
  tradeoffBlocks: TradeoffBlock[];
  messageId: string;
  selectedTime: DateTime | null;
  isProposeNewTime: boolean;
  organizer: GQLPerson | null;
}) => {
  const track = useTracking();
  const setError = useSetAIError();
  const [modalOpen, setModalOpen] = useState(false);
  const [sharedProposalLink, setSharedProposalLink] = useState<string | null>(null);
  const [schedulingLink, setSchedulingLink] = useState<SidebarLink | null>(null);
  const [reschedulingLinkUrl, setReschedulingLinkUrl] = useState<string | null>(null);
  const [schedulingLinkLoading, setSchedulingLinkLoading] = useState(false);
  const isForRescheduling = sharedProposalDiff?.__typename === "ModifyDiffSummary";

  const [onShareProposal, { loading }] = useShareProposal({
    proposalId: proposalId,
    onError: (error) => {
      setError({
        error: error,
        message: "Failed to confirm proposal",
        showUserMessage: true,
      });
      logger.error(`Mutation error sharing link with propoalId - ${proposalId}`, error);
    },
    onCompleted: (data) => {
      if (data.shareProposal) {
        setSharedProposalLink(data.shareProposal.sharedProposalLink);
      } else {
        const error = new Error("Data not returned from shareProposal mutation");
        setError({
          error: error,
          message: "Data not returned from shareProposal mutation",
          showUserMessage: true,
        });
        logger.error(`Mutation error sharing link with propoalId - ${proposalId}`, error);
      }
    },
  });

  const [getOrCreateLink] = useGatewayMutation(CreateLinkFromDraftEventDocument, {
    variables: { eventDiffId: baseEventDiffId },
    onError: (error) => {
      setError({
        error: error,
        message: "Oops. There was a problem creating the link. Please try again.",
        showUserMessage: true,
      });
      logger.error("Mutation error creating link via AI Scheduler", error);
      setSchedulingLink(null);
      setSchedulingLinkLoading(false);
    },
    onCompleted: (data) => {
      !modalOpen && setModalOpen(true);
      setSchedulingLink(data.createSchedulingLinkFromDraftEvent);
      setSchedulingLinkLoading(false);
    },
  });

  const [getOrCreateReschedulingLink] = useGatewayMutation(
    CreateReschedulingLinkFromDraftEventDocument,
    {
      variables: { eventDiffId: baseEventDiffId },
      onError: (error) => {
        setError({
          error: error,
          message: "Oops. There was a problem creating a rescheduling link. Please try again.",
          showUserMessage: true,
        });
        logger.error("Mutation error creating rescheduling link via AI Scheduler", error);
        setSchedulingLink(null);
        setReschedulingLinkUrl(null);
        setSchedulingLinkLoading(false);
      },
      onCompleted: (data) => {
        !modalOpen && setModalOpen(true);
        setSchedulingLink(data.createReschedulingLinkFromDraftEvent.schedulingLink);
        setReschedulingLinkUrl(data.createReschedulingLinkFromDraftEvent.reschedulingLinkUrl);
        setSchedulingLinkLoading(false);
      },
    },
  );

  const createLinkOrReschedulingLink = () => {
    if (isForRescheduling) {
      void getOrCreateReschedulingLink();
    } else {
      void getOrCreateLink();
    }
  };

  const handleShareProposal = () => {
    track(TrackingEvents.SHAREABLE_PROPOSAL.SHARE_TIMES.BUTTON_CLICKED, {
      canShareProposal,
      proposalId,
      messageId,
    });

    if (canShareProposal) {
      void onShareProposal();
    } else {
      void createLinkOrReschedulingLink();
    }
  };

  const onShareSchedulingLink = () => {
    setModalOpen(true);
    // When both are null, it means neither of getOrCreateLink or getOrCreateReschedulingLink has been called.
    // i.e., if any of them is set, then don't re-call the mutation.
    if (!schedulingLink && !reschedulingLinkUrl) {
      setSchedulingLinkLoading(true);
      void createLinkOrReschedulingLink();
    }
  };

  useEffect(() => {
    track(TrackingEvents.SHAREABLE_PROPOSAL.SHARE_TIMES.BUTTON_VIEWED, {
      canShareProposal,
      isProposeNewTime,
    });
  }, []);

  const calDiffMinis = useMemo(() => {
    const calDiffs: CalDiffMini[] = [];

    (consequencesBlock?.yourCalDiffs || []).forEach((calDiff) => {
      calDiffs.push({
        id: calDiff.id,
        updatedTime: calDiff.updatedTime,
        currentTime: calDiff.currentTime,
        title: calDiff.title,
      });
    });

    (consequencesBlock?.otherCalDiffs || []).forEach((calDiff) => {
      calDiffs.push({
        id: calDiff.id,
        updatedTime: calDiff.updatedTime,
        currentTime: calDiff.currentTime,
        title: calDiff.title,
      });
    });

    return calDiffs;
  }, [consequencesBlock]);

  return (
    <>
      {canShareProposal && selectedTime ? (
        <ShareSubMenu
          proposalId={proposalId}
          allAttendeesExceptViewer={allAttendeesExceptViewer}
          viewerProfile={viewerProfile}
          isProposeNewTime={isProposeNewTime}
          sharedProposalLink={sharedProposalLink}
          sharedProposalDiff={sharedProposalDiff}
          calDiffMinis={calDiffMinis}
          tradeoffBlocks={tradeoffBlocks}
          selectedTime={selectedTime}
          organizer={organizer}
          loading={loading}
          onShareProposal={handleShareProposal}
          onShareAllTimes={onShareSchedulingLink}
        />
      ) : (
        <Button
          size="xsmall"
          sentiment="neutral"
          variant="outlined"
          onClick={onShareSchedulingLink}
          startIcon={IosShare}
          disabled={disabled}
        >
          Share
        </Button>
      )}

      {modalOpen && (
        <ShareProposalModal
          schedulingLink={schedulingLink}
          reschedulingLinkUrl={reschedulingLinkUrl}
          schedulingLinkLoading={schedulingLinkLoading}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};
