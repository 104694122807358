import { useQuery } from "@apollo/client";
import { getCurrentOrg } from "../../../util/org.util";
import { MeetingReliefGoalsDocument } from "../graphql/__generated__/MeetingReliefGoals.generated";
import parseMeetingReliefGoalsQuery from "../utils/parseMeetingReliefGoalsQuery";
import transformMeetingReliefGoalsToSettings from "../utils/transformMeetingReliefGoalsToSettings";

export const useMeetingReliefSettings = () => {
  const { data, loading, error, refetch } = useQuery(MeetingReliefGoalsDocument);

  const orgId = getCurrentOrg(data?.viewer)?.id;

  const goal = parseMeetingReliefGoalsQuery(data);
  const settings = transformMeetingReliefGoalsToSettings(goal);

  return { settings, loading, error, orgId, refetch };
};

export default useMeetingReliefSettings;
