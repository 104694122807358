import { LunchGoals } from "@clockwise/schema";
import { LunchGoalsQuery } from "../graphql/__generated__/LunchGoals.generated";
import { getCurrentOrg } from "../../../util/org.util";

export const parseLunchGoalsQuery = (data: LunchGoalsQuery | undefined) => {
  const org = getCurrentOrg(data?.viewer);
  const LunchGoal = org?.smartHoldGoals.lunch as LunchGoals | undefined;
  return LunchGoal;
};

export default parseLunchGoalsQuery;
