import { useQuery } from "@apollo/client";
import { TranslationContext } from "@clockwise/client-commons/src/providers/TranslationContext";
import { Button, TextArea } from "@clockwise/design-system";
import { NavigateNext } from "@clockwise/design-system/icons";
import { webappPathRoot } from "@clockwise/web-commons/src/constants/route.constants";
import { extensionURLForBrowser } from "@clockwise/web-commons/src/util/browser.util";
import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams, useSearchParams } from "react-router-dom";
import * as ISchema from "../../__schema__";
import { upsertNonUserDefragEmailResponse } from "../../mutations";
import { getCurrentEnvironment } from "../../state/relay-environment";
import { track, TrackingEvents } from "../../util/analytics.util";
import { windowLocation } from "../../util/location.util";
import { NonUserDefragSurveyQueryDocument } from "./__generated__/NonUserDefragSurvey.generated";

export const NonUserDefragSurvey = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { data } = useQuery(NonUserDefragSurveyQueryDocument, {
    variables: {
      emailInstanceId: params["emailInstanceId"] || "",
    },
    skip: !params["emailInstanceId"],
  });

  const [responseType, setResponseType] = useState<ISchema.NonUserDefragEmailResponseType>();
  const [additionalInformation, setAdditionalInformation] = useState<string>();
  const [justSaved, setJustSaved] = useState(false);
  const t = useContext(TranslationContext);

  useEffect(() => {
    const nonUserDefragEmailResponse = data?.viewer?.nonUserDefragEmailResponse;

    const responseType = castResponseTypeQueryParam(searchParams.get("responseType") || "");

    if (
      nonUserDefragEmailResponse &&
      nonUserDefragEmailResponse.__typename !== "GraphEntityError"
    ) {
      setResponseType(nonUserDefragEmailResponse.responseType);
      setAdditionalInformation(nonUserDefragEmailResponse.additionalInformation || "");
      setJustSaved(true);
    } else if (responseType) {
      setResponseType(responseType);
    }
  }, [data?.viewer?.nonUserDefragEmailResponse]);

  const relayEnv = getCurrentEnvironment();
  const description = t("defrag-survey:description") as string;

  const updateResponseType = (responseType: ISchema.NonUserDefragEmailResponseType) => () => {
    setResponseType(responseType);
    setJustSaved(false);
  };

  const updateAdditionalInformation = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAdditionalInformation(event.target.value);
    setJustSaved(false);
  };

  const castResponseTypeQueryParam = (param: string) => {
    switch (param) {
      case "Yes":
        return ISchema.NonUserDefragEmailResponseType.Yes;
      case "No":
        return ISchema.NonUserDefragEmailResponseType.No;
      case "NotSure":
        return ISchema.NonUserDefragEmailResponseType.NotSure;
      default:
        return undefined;
    }
  };

  const goHome = () => {
    void windowLocation.assign("NonUserDefragSurveyGoHome", webappPathRoot);
  };

  const save = (responseType: ISchema.NonUserDefragEmailResponseType) => {
    if (!params["emailInstanceId"]) return;
    upsertNonUserDefragEmailResponse(
      relayEnv,
      {
        responseType,
        emailInstanceId: params["emailInstanceId"],
        additionalInformation: additionalInformation,
      },
      () => {
        setResponseType(responseType);
        setJustSaved(true);
      },
      goHome,
    );
  };

  const handleClickFromBanner = () => {
    track(TrackingEvents.NON_USER_DEFRAG_SURVEY.CLICKED_CTA);
    window.open(
      `${extensionURLForBrowser}?utm_medium=referral&utm_source=product&utm_campaign=meeting-move-cta`,
      "_blank",
    );
  };

  if (!responseType) {
    return null;
  }

  return (
    <div className="cw-flex cw-flex-col cw-items-center">
      <Helmet>
        <title>{t("defrag-survey:title")}</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta itemProp="description" content={description} />
      </Helmet>
      <div className="cw-relative cw-p-5 cw-mt-5 cw-flex cw-flex-col cw-items-center">
        <div className="cw-text-center">
          <h1 className="cw-heading-2xl cw-text-brand-pressed">
            Thank you for submitting your feedback!
          </h1>
          <h2 className="cw-text-subtle cw-body-base cw-max-w-[460px]">
            We take your feedback very seriously, any additional information you can provide would
            be very appreciated.
          </h2>
        </div>
      </div>

      <div className="cw-shadow-lg cw-p-8 cw-border cw-border-solid cw-border-subtle cw-w-full cw-rounded-lg cw-p-r cw-flex cw-flex-col cw-items-center">
        <h2 className="cw-heading-lg cw-text-brand-pressed cw-mb-3">
          Were these changes helpful to your schedule?
        </h2>
        <div className="cw-flex cw-items-center cw-gap-4 cw-mb-7">
          <Button
            onClick={updateResponseType(ISchema.NonUserDefragEmailResponseType.Yes)}
            variant={
              responseType === ISchema.NonUserDefragEmailResponseType.Yes ? "solid" : "outlined"
            }
          >
            Yes
          </Button>
          <Button
            onClick={updateResponseType(ISchema.NonUserDefragEmailResponseType.No)}
            variant={
              responseType === ISchema.NonUserDefragEmailResponseType.No ? "solid" : "outlined"
            }
          >
            No
          </Button>
          <Button
            onClick={updateResponseType(ISchema.NonUserDefragEmailResponseType.NotSure)}
            variant={
              responseType === ISchema.NonUserDefragEmailResponseType.NotSure ? "solid" : "outlined"
            }
          >
            Not Sure
          </Button>
        </div>
        <h2 className="cw-heading-lg cw-text-brand-pressed cw-mb-3">
          Tell us more! Your input is valuable.
        </h2>
        <div className="cw-w-10/12 cw-mb-3">
          <TextArea
            value={additionalInformation}
            onChange={updateAdditionalInformation}
            fullWidth
          />
        </div>

        <div className="cw-flex cw-flex-col cw-items-center">
          <Button sentiment="positive" size="large" onClick={() => save(responseType)}>
            Submit
          </Button>
          {justSaved ? (
            <span className="cw-body-base cw-mt-4">Your feedback has been saved!</span>
          ) : null}
        </div>
      </div>
      <div className="cw-relative cw-mt-5 cw-rounded cw-hidden md:cw-flex cw-items-center cw-gap-4 cw-p-4 cw-text-onEmphasis cw-bg-brand-emphasis">
        <div className="cw-heading-xl cw-text-onEmphasis">Make time for what matters today.</div>
        <Button
          rounded
          variant="outlined"
          sentiment="positive"
          onClick={handleClickFromBanner}
          endIcon={NavigateNext}
        >
          Get Started For Free
        </Button>
      </div>
    </div>
  );
};
