import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { LunchGoalSettingsFragmentDoc } from './LunchGoals.generated';
export type ToggleLunchMutationVariables = Types.Exact<{
  orgId: Types.Scalars['ID'];
  enabled: Types.Scalars['Boolean'];
}>;


export type ToggleLunchMutation = { __typename: 'Mutation', ToggleLunch: { __typename: 'ToggleGoalResponse', lunchGoal: { __typename: 'LunchGoals', id: string, enabled: boolean | null, preferredLunchTimes: { __typename: 'PreferredLunchTimes', earliestStart: Types.TimeSlot, latestEnd: Types.TimeSlot, minimumDuration: any, idealDuration: any } | null, remoteHoldSettings: { __typename: 'RemoteSmartHoldSettings', scheduleAs: Types.ScheduleAs, notificationStrategy: Types.NotificationStrategy } | null } } | null };


export const ToggleLunchDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ToggleLunch"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"ToggleLunch"},"name":{"kind":"Name","value":"toggleLunch"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"orgId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}},{"kind":"Argument","name":{"kind":"Name","value":"enabled"},"value":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lunchGoal"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LunchGoals"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LunchGoalSettings"}}]}}]}}]}}]}},...LunchGoalSettingsFragmentDoc.definitions]} as unknown as DocumentNode<ToggleLunchMutation, ToggleLunchMutationVariables>;