import { DateTime } from "luxon";
import dateComparator from "../../../../../../util/comparator.date";
import { TimeframeDuration, Timeframes } from "../consts";

const getTimeframeFromDates = (date1: Date, date2: Date) => {
  let result: Timeframes;

  const sortedDates = [date1, date2].sort(dateComparator);
  const maxLux = DateTime.fromJSDate(sortedDates[1]);
  const minLux = DateTime.fromJSDate(sortedDates[0]);

  const diff = maxLux.diff(minLux);

  if (diff >= TimeframeDuration.year) {
    result = "year";
  } else if (diff >= TimeframeDuration.month) {
    result = "month";
  } else {
    result = "week";
  }

  return result;
};

export default getTimeframeFromDates;
