/**
 * @generated SignedSource<<55176f7c6d09b5aa36b2fd2b28aa68c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventCategoryColoring_eventCategoryColoring$data = {
  readonly eventCategory: string;
  readonly googleColorKey: string | null;
  readonly active: boolean;
  readonly isUserSet: boolean;
  readonly " $fragmentType": "EventCategoryColoring_eventCategoryColoring";
};
export type EventCategoryColoring_eventCategoryColoring$key = {
  readonly " $data"?: EventCategoryColoring_eventCategoryColoring$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventCategoryColoring_eventCategoryColoring">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventCategoryColoring_eventCategoryColoring",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventCategory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "googleColorKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUserSet",
      "storageKey": null
    }
  ],
  "type": "EventCategoryColoring",
  "abstractKey": null
};

(node as any).hash = "f5b072bc5dc9f202588faddb5a6200d7";

export default node;
