import { ArrowDropDown } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";

const EventCell = ({ children }: React.PropsWithChildren) => {
  return <div className="cw-py-2 cw-px-1 cw-flex cw-flex-col cw-justify-center">{children}</div>;
};

export const EventTableRowCollapser = ({
  children,
  text,
  subtext,
  columnSizing,
}: React.PropsWithChildren<{
  text: string;
  subtext?: string;
  columnSizing?: (string | null)[];
}>) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as JSX.Element, { columnSizing: columnSizing });
    }
    return child;
  });

  const rowChildren = React.Children.toArray(childrenWithProps);
  const columnCount = rowChildren.length;

  if (columnCount === 0) return null;

  return (
    <div>
      <div
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        className="cw-flex cw-items-center cw-bg-neutral hover:cw-bg-neutral-hover cw-cursor-pointer cw-px-2 cw-py-1 cw-border-b cw-border-solid cw-border-muted"
      >
        <ArrowDropDown
          className={classNames(
            "cw-text-positive-muted cw-w-4 cw-h-4 cw-transition-transform cw-duration-75 cw-mr-2",
            {
              "-cw-rotate-90": collapsed,
            },
          )}
        />
        <div className="cw-text-13 cw-text-muted cw-font-semibold">{text}</div>
        {subtext && <div className="cw-text-xs cw-text-subtle cw-ml-4">{subtext}</div>}
      </div>
      {!collapsed && (
        <div className="cw-divide-solid cw-divide-muted cw-divide-y">
          {rowChildren.map((child) => child)}
        </div>
      )}
    </div>
  );
};

export const EventTableRow = ({
  children,
  isHeading,
  columnSizing,
}: React.PropsWithChildren<{ isHeading?: boolean; columnSizing?: (string | null)[] }>) => {
  const rowChildren = React.Children.toArray(children);
  const columnCount = rowChildren.length;

  if (columnCount === 0) return null;

  let columnSizes = `repeat(${columnCount}, minmax(0, 1fr))`;

  if (columnSizing?.length) {
    if (columnSizing.length === columnCount) {
      columnSizes = columnSizing
        .map((size) => (size ? `minmax(0, ${size}px)` : "minmax(0, 1fr)"))
        .join(" ");
    } else {
      throw new Error("EventTable: columnSizing length must match the number of columns");
    }
  }

  return (
    <div
      className={`cw-grid ${isHeading ? "cw-font-semibold cw-text-13 cw-text-subtle" : ""}`}
      style={{ gridTemplateColumns: columnSizes }}
    >
      {rowChildren.map((child, index) => (
        <EventCell key={index}>{child}</EventCell>
      ))}
    </div>
  );
};

export const EventTable = ({
  children,
  heading,
  errorMessage,
  emptyMessage,
  columnSizing,
}: React.PropsWithChildren<{
  heading?: string;
  errorMessage?: string;
  emptyMessage?: string;
  columnSizing?: (string | null)[];
}>) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as JSX.Element, { columnSizing: columnSizing });
    }
    return child;
  });

  return (
    <div>
      {heading && (
        <h3 className="cw-heading-xl cw-text-default cw-font-semibold cw-mb-3">{heading}</h3>
      )}
      {errorMessage ? (
        <div className="cw-border-0 cw-border-y cw-border-solid cw-border-muted">
          <div className="cw-py-2 cw-text-warning cw-pl-2 cw-font-semibold cw-body-base">
            {errorMessage}
          </div>
        </div>
      ) : emptyMessage ? (
        <div className="cw-border-0 cw-border-y cw-border-solid cw-border-muted">
          <div className="cw-py-2 cw-pl-2 cw-font-semibold cw-body-base">{emptyMessage}</div>
        </div>
      ) : (
        <div className="cw-w-full cw-body-base cw-divide-y cw-divide-solid cw-divide-muted">
          {childrenWithProps}
        </div>
      )}
    </div>
  );
};
