import { Button } from "@clockwise/design-system";
import { ArrowForward, ExpandMore } from "@clockwise/design-system/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";
import { motion } from "framer-motion";
import { CheckCircle, Circle } from "phosphor-react";
import React from "react";
import { NUXChecklistVariant } from "../utils/NUXChecklist.util";
import { ChecklistCopy } from "../utils/NUXChecklistCopy";

interface Props {
  variant: NUXChecklistVariant;
  canExpand: boolean;
  expanded: boolean;
  complete: boolean;
  disabled: boolean;
  loading: boolean;
  onExpand?: (itemVariant: NUXChecklistVariant) => void;
  onSkip?: () => void;
  onComplete?: () => void;
  last?: boolean;
}

const ExpandedItem = ({
  variant,
  onSkip,
  onComplete,
}: {
  variant: NUXChecklistVariant;
  onSkip?: () => void;
  onComplete?: () => void;
}) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      exit={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
    >
      <div className="cw-py-8 cw-px-6 cw-bg-brand cw-box-border cw-border cw-border-solid cw-border-brand-emphasis cw-rounded-xl">
        <div className="cw-flex cw-items-start">
          <div className="-cw-mt-[10px] cw-justify-self-start cw-flex cw-items-center cw-justify-center cw-p-2 cw-mr-4 cw-bg-[#B6FF80] cw-rounded-3xl">
            <img width={28} height={28} src={ChecklistCopy[variant].icon} />
          </div>
          <div className="cw-flex cw-items-start">
            <div className="cw-flex cw-flex-col cw-justify-between">
              <div className="cw-heading-lg">{ChecklistCopy[variant].title}</div>
              <div className="cw-body-lg cw-py-6 cw-text-subtle cw-pr-4">
                {ChecklistCopy[variant].text}
              </div>
              <div className="cw-flex">
                <div className="cw-mr-4">
                  <Button
                    sentiment="positive"
                    size="large"
                    endIcon={ArrowForward}
                    onClick={onComplete}
                  >
                    Get started
                  </Button>
                </div>
                <Button sentiment="neutral" size="large" variant="text" onClick={onSkip}>
                  Skip for now
                </Button>
              </div>
            </div>
            <img
              className="cw-mx-4 cw-self-center cw-w-[300px]"
              src={ChecklistCopy[variant].expandedImage}
            />
          </div>
          <div className="cw-justify-self-end">
            <Circle fill="none" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const NonExpandedItem = ({
  variant,
  complete,
  showBorder,
  disabled,
  canExpand,
  onExpand,
  loading,
}: {
  variant: NUXChecklistVariant;
  complete: boolean;
  showBorder: boolean;
  disabled: boolean;
  canExpand: boolean;
  loading: boolean;
  onExpand?: (itemVariant: NUXChecklistVariant) => void;
}) => {
  const onClick = () => {
    if (!!onExpand && canExpand) {
      onExpand(variant);
    }
  };
  return (
    <div
      className={classNames("cw-flex cw-flex-col cw-px-6 pt-8", {
        "cw-cursor-pointer": canExpand,
      })}
      onClick={onClick}
    >
      <div
        className={classNames("cw-flex cw-items-center cw-justify-center cw-h-20", {
          "cw-opacity-50": complete || disabled,
        })}
      >
        <div className="cw-justify-self-center cw-flex cw-items-center cw-justify-center cw-p-2 cw-mr-4 cw-bg-[#B6FF80] cw-rounded-3xl">
          {!loading && <img width={28} height={28} src={ChecklistCopy[variant].icon} />}
          {loading && <Skeleton variant="circle" width={34} height={34} />}
        </div>
        <div className="cw-flex cw-items-center cw-flex-1">
          {loading && <Skeleton variant="text" width="50%" />}
          {!loading && <div className="cw-heading-lg">{ChecklistCopy[variant].title}</div>}
          {canExpand && (
            <div className="cw-ml-3">
              <ExpandMore className="cw-heading-lg" />
            </div>
          )}
        </div>
        <div className="cw-justify-self-end">
          {complete ? <CheckCircle /> : <Circle fill="none" />}{" "}
        </div>
      </div>
      {showBorder && (
        <div className="cw-px-4 cw-border-0 cw-border-b cw-border-solid cw-border-default" />
      )}
    </div>
  );
};

export const NUXChecklistItem = ({
  variant,
  expanded,
  complete,
  loading,
  disabled,
  canExpand,
  onExpand,
  onSkip,
  onComplete,
  last = false,
}: Props) => {
  return expanded && !loading ? (
    <ExpandedItem variant={variant} onSkip={onSkip} onComplete={onComplete} />
  ) : (
    <NonExpandedItem
      variant={variant}
      complete={complete}
      showBorder={!last}
      disabled={disabled}
      canExpand={canExpand}
      onExpand={onExpand}
      loading={loading}
    />
  );
};
