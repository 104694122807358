import { TradeoffType } from "@clockwise/schema/v2";
import { Portal, Transition } from "@headlessui/react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import { useCurrentProposal } from "../chat-plus-calendar/CurrentProposalContext";
import { TradeoffBlock } from "../chat-plus-calendar/PersistedProposalContext";
import { getTradeoffBlocksToDisplay } from "../tradeoffs/tradeoffs.utils";
import {
  AvailabilityIssue,
  FixableConflict,
  Inconveniences,
  NoIssues,
  TradeoffHeader,
  WorksForEveryone,
} from "./Tradeoffs";
const POPOVER_WIDTH = 250;

export const TradeoffsPopover = ({
  id,
  tradeoffBlocks,
  side,
  anchorEl,
  headerText,
  showAttendeeCount = true,
}: {
  /**
   * Must be a unique ID and *must* match the trigger's `aria-describedby`
   * property. Will be applied to the `role="tooltip"` element.
   */
  id: string;
  tradeoffBlocks: TradeoffBlock[];
  side: "left" | "right" | "bottom";
  anchorEl: React.RefObject<HTMLButtonElement>;
  headerText?: string;
  showAttendeeCount?: boolean;
}) => {
  const { currentProposal } = useCurrentProposal();
  const totalAttendeeCount = currentProposal?.attendees.length ?? 0;

  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(anchorEl.current, container, {
    placement: side,
    strategy: "fixed",
    modifiers: [
      {
        name: "flip",
        enabled: true,
      },
      { name: "offset", options: { offset: [0, 2] } },
    ],
  });

  const filteredTradeoffBlocks = getTradeoffBlocksToDisplay(tradeoffBlocks);

  const uniqueTradeoffAttendees = new Set(
    [...filteredTradeoffBlocks].flatMap((tradeoff) =>
      tradeoff.affectedAttendees.map((attendee) => attendee.person.email),
    ),
  );

  const showWorksForEveryone = isEmpty(filteredTradeoffBlocks);
  const showRemaningNoIssues =
    !showWorksForEveryone && totalAttendeeCount > uniqueTradeoffAttendees.size;
  const width = showWorksForEveryone && !headerText ? 160 : POPOVER_WIDTH;

  return (
    <Portal>
      <Transition
        show={true}
        leave="cw-transition cw-ease-in cw-duration-100"
        leaveFrom="cw-opacity-100"
        leaveTo="cw-opacity-0"
        enter="cw-transition cw-ease-in cw-duration-75"
        enterFrom="cw-opacity-0"
        enterTo="cw-opacity-100"
      >
        <div
          id={id}
          role="tooltip"
          ref={setContainer}
          style={{
            ...styles.popper,
            width,
          }}
          {...attributes.popper}
          className={classNames(
            "cw-px-3 cw-py-0.5 cw-shadow-md cw-rounded-lg cw-absolute cw-body-sm cw-bg-emphasis cw-hidden lg:cw-flex cw-z-[42] cw-pointer-events-none",
          )}
        >
          <div className="cw-flex cw-flex-col cw-w-full">
            {headerText && (
              <div className="cw-w-full cw-flex cw-border-b-[0.5px] cw-border-solid cw-border-neutral-hover cw-py-2.5">
                <TradeoffHeader text={headerText} />
              </div>
            )}
            {showWorksForEveryone && <WorksForEveryone />}
            {filteredTradeoffBlocks.map((tradeoffBlock, index) => {
              const showBottomBorder =
                showRemaningNoIssues || index < filteredTradeoffBlocks.length - 1;
              switch (tradeoffBlock.tradeoffType) {
                case TradeoffType.AvailabilityIssue:
                  return (
                    <AvailabilityIssue
                      key={index}
                      tradeoffBlock={tradeoffBlock}
                      showAttendeeCount={showAttendeeCount}
                      showBottomBorder={showBottomBorder}
                    />
                  );
                case TradeoffType.FixableConflict:
                  return (
                    <FixableConflict
                      key={index}
                      tradeoffBlock={tradeoffBlock}
                      showAttendeeCount={showAttendeeCount}
                      showBottomBorder={showBottomBorder}
                    />
                  );
                case TradeoffType.Inconvenience:
                  return (
                    <Inconveniences
                      key={index}
                      tradeoffBlock={tradeoffBlock}
                      showAttendeeCount={showAttendeeCount}
                      showBottomBorder={showBottomBorder}
                    />
                  );
                default:
                  return null;
              }
            })}
            {showRemaningNoIssues && (
              <NoIssues
                attendeeCount={totalAttendeeCount - uniqueTradeoffAttendees.size}
                showAttendeeCount={showAttendeeCount}
              />
            )}
          </div>
        </div>
      </Transition>
    </Portal>
  );
};
