import { isImpersonated } from "@clockwise/client-commons/src/util/jwt";
import { jwt } from "@clockwise/web-commons/src/util/local-storage";

export type SubmitDeleteSelectCopy = {
  default: string;
  singleEventOnly: string;
  thisAndFutureEvents: string;
  allEvents: string;
};

const getProposalSubmitCopy = (): SubmitDeleteSelectCopy => {
  const isImpersonating = isImpersonated(jwt.get());
  return {
    default: isImpersonating ? "Can't edit in sudo" : "Update proposal",
    singleEventOnly: "Add updates to this event only",
    thisAndFutureEvents: "Add updates to this and future events",
    allEvents: "Add updates to all events",
  };
};
const getCalendarEventSubmitCopy = (alsoSendingUpdates?: boolean): SubmitDeleteSelectCopy => {
  const isImpersonating = isImpersonated(jwt.get());
  return {
    default: isImpersonating ? "Can't edit in sudo" : alsoSendingUpdates ? "Save & send" : "Save",
    singleEventOnly: "Apply changes to this event only",
    thisAndFutureEvents: "Apply changes to this and future events",
    allEvents: "Apply changes to all events",
  };
};
export const getDeleteEventCopy = (hasDeletePermissions: boolean): SubmitDeleteSelectCopy => {
  const isImpersonating = isImpersonated(jwt.get());
  return {
    default: isImpersonating
      ? "Can't delete in sudo"
      : hasDeletePermissions
      ? "Delete event"
      : "Remove event",
    singleEventOnly: "This event",
    thisAndFutureEvents: "This and future events",
    allEvents: "All events",
  };
};

export const getDeleteHoldCopy = (): SubmitDeleteSelectCopy => {
  return {
    default: "Remove",
    singleEventOnly: "Remove",
    thisAndFutureEvents: "Remove",
    allEvents: "Remove",
  };
};

export const getSubmitButtonCopy = (
  isProposal: boolean,
  alsoSendingUpdates?: boolean,
): SubmitDeleteSelectCopy => {
  if (isProposal) {
    return getProposalSubmitCopy();
  } else {
    return getCalendarEventSubmitCopy(alsoSendingUpdates);
  }
};
