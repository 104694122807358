import { useQuery } from "@apollo/client";
import { getCurrentOrg } from "../../../util/org.util";
import { TravelTimeGoalsDocument } from "../graphql/__generated__/TravelTimeGoals.generated";
import parseTravelTimeGoalsQuery from "../utils/parseTravelTimeGoalsQuery";
import transformTravelTimeGoalsToSettings from "../utils/transformTravelTimeGoalsToSettings";

export const useTravelTimeSettings = () => {
  const { data, loading, error, refetch } = useQuery(TravelTimeGoalsDocument);

  const orgId = getCurrentOrg(data?.viewer)?.id;

  const goal = parseTravelTimeGoalsQuery(data);
  const settings = transformTravelTimeGoalsToSettings(goal);

  return { settings, loading, error, orgId, refetch };
};

export default useTravelTimeSettings;
