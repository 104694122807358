import { useMutation } from "@apollo/client";
import { EventAvailable } from "@clockwise/design-system/icons";
import { DataSourceAuthError } from "@clockwise/schema";
import React from "react";
import toast from "react-hot-toast";
import { logger } from "../../util/logger.util";
import GoogleBtn from "../google-btn";
import { useOrgId } from "../hooks/useOrgId";
import { CreatePersonalDataSourceMutationDocument } from "./graphql/__generated__/CreatePersonalDataSourceMutation.generated";

type Props = {
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  onSaveEnd?: (saveType: "added") => void;
  onSaveError?: (error?: DataSourceAuthError | Error) => void;
  onSaveStart?: () => void;
  variant?: "flat" | "default";
};

const AddButton = ({
  disabled = false,
  fullWidth = false,
  label = "Connect new calendar",
  onSaveEnd,
  onSaveError,
  onSaveStart,
  variant = "default",
}: Props) => {
  const [addDataSource] = useMutation(CreatePersonalDataSourceMutationDocument);
  const { orgId } = useOrgId();

  const handleGoogleAuthSuccess = (response: { code: string }) => {
    onSaveStart?.();

    const googleOfflineCode = response.code;
    if (!orgId || !googleOfflineCode) return handleSaveError();

    void addDataSource({
      variables: {
        input: {
          orgRelayId: orgId,
          googleOfflineCode,
          isPersonal: true,
        },
      },
      onCompleted: (data) => {
        const authError = data.createDataSource?.dataSourceAuthError;
        authError ? handleSaveError(authError) : handleSaveSuccess();
      },
      onError: handleSaveError,
    });
  };

  const handleGoogleAuthFailure = () => {
    onSaveError?.();

    logger.debug("OAuth failure");
    toast.error("Failed to authenticate.");
  };

  const handleSaveSuccess = () => {
    onSaveEnd?.("added");

    toast.success("Your personal calendar is now connected and syncing.");
  };

  const handleSaveError = (error?: DataSourceAuthError | Error) => {
    onSaveError?.(error);

    toast.error("There was a problem connecting this calendar as a personal calendar.");
    logger.debug("Unable to connect a personal calendar");
  };

  return (
    <GoogleBtn
      label={label}
      onSuccess={handleGoogleAuthSuccess}
      onFailure={handleGoogleAuthFailure}
      variant={variant}
      fullWidth={fullWidth}
      labelStyle={{ paddingLeft: 8, marginBottom: -3 }}
      icon={EventAvailable}
      disabled={disabled}
      excludeAdminPermissions
      excludeContactPermission
    />
  );
};

export default AddButton;
