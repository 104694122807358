import { bg_fragmented_inset, fg_fragmented_muted } from "@clockwise/design-system/tokens";
import React from "react";
import { EventDuration } from "./types";
import { durationToHeight } from "./utils/durationToHeight";
type UnnamedEventProps = {
  duration?: EventDuration;
  variant: "morning" | "evening";
};

export const OutsideHours = ({ duration = 15, variant }: UnnamedEventProps) => {
  const height = durationToHeight(duration);
  const bgColor = variant === "morning" ? bg_fragmented_inset : "rgba(238, 244, 255, 1)"; //doesn't exist in tokens
  const lineColor = variant === "morning" ? fg_fragmented_muted : "rgba(97, 114, 243, 1)"; //doesn't exist in tokens
  return (
    <div
      style={{
        height: `${height}px`,
        background: `repeating-linear-gradient(-45deg, ${bgColor}, ${bgColor} 7px, ${lineColor} 3px, ${lineColor} 8px)`,
        opacity: 0.6,
      }}
      className={`
      cw-rounded
  `}
    ></div>
  );
};

export default OutsideHours;
