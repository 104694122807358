import { TradeoffAttendee } from "#webapp/src/components/chat/ai-chat/utils/types.js";
import { TradeoffTypeEnum } from "@clockwise/schema";

export enum TradeoffUIVariant {
  AVAILABILITY_ISSUE = "AVAILABILITY_ISSUE",
  FIXABLE_CONFLICT = "FIXABLE_CONFLICT",
  INCONVENIENCE = "INCONVENIENCE",
  NO_TRADEOFFS = "NO_TRADEOFFS",
}

const tradeoffRanking: Record<TradeoffUIVariant, number> = {
  AVAILABILITY_ISSUE: 0,
  FIXABLE_CONFLICT: 1,
  INCONVENIENCE: 2,
  NO_TRADEOFFS: 3,
};

const getTradeoffUIVariantFromTradeoffType = (
  tradeoffType: TradeoffTypeEnum,
): TradeoffUIVariant => {
  switch (tradeoffType) {
    case TradeoffTypeEnum.AVAILABILITY_ISSUE:
      return TradeoffUIVariant.AVAILABILITY_ISSUE;
    case TradeoffTypeEnum.FIXABLE_CONFLICT:
      return TradeoffUIVariant.FIXABLE_CONFLICT;
    case TradeoffTypeEnum.INCONVENIENCE:
      return TradeoffUIVariant.INCONVENIENCE;
    case TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE:
      return TradeoffUIVariant.INCONVENIENCE;
    case TradeoffTypeEnum.OUT_OF_TIME_RANGE:
      return TradeoffUIVariant.AVAILABILITY_ISSUE;
    default:
      return TradeoffUIVariant.NO_TRADEOFFS;
  }
};

type TradeoffAttendeesAndType = {
  aggregateAttendees: TradeoffAttendee[];
  tradeoffType: TradeoffTypeEnum;
};

export const mapCalendarIdToTradeoffUIVariant = (
  tradeoffBlocks: TradeoffAttendeesAndType[] | null,
  allCalendarIds: string[],
) => {
  if (!tradeoffBlocks) {
    return {};
  }

  const calendarIdsToHighestTradeoffType: Record<string, TradeoffUIVariant> = {};

  // initialize as NO_TRADEOFFS
  allCalendarIds.forEach((calendarId) => {
    calendarIdsToHighestTradeoffType[calendarId] = TradeoffUIVariant.NO_TRADEOFFS;
  });

  tradeoffBlocks?.forEach((tradeoffBlock) => {
    const tradeoffType = tradeoffBlock.tradeoffType;
    const aggregateAttendeeIds = tradeoffBlock.aggregateAttendees.map(
      (attendee) => attendee.primaryCalendarId,
    );
    aggregateAttendeeIds.forEach((calendarId) => {
      // if there is already a tradeoff, only replace if it's a higher ranking
      if (calendarIdsToHighestTradeoffType[calendarId] !== TradeoffUIVariant.NO_TRADEOFFS) {
        if (
          tradeoffRanking[calendarIdsToHighestTradeoffType[calendarId]] >
          tradeoffRanking[getTradeoffUIVariantFromTradeoffType(tradeoffType)]
        ) {
          calendarIdsToHighestTradeoffType[calendarId] = getTradeoffUIVariantFromTradeoffType(
            tradeoffType,
          );
        }
      } else {
        calendarIdsToHighestTradeoffType[calendarId] = getTradeoffUIVariantFromTradeoffType(
          tradeoffType,
        );
      }
    });
  });

  return calendarIdsToHighestTradeoffType;
};
