import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Button, ButtonSet, Modal } from "@clockwise/design-system";
import React from "react";
import { toast } from "react-hot-toast";
import { TrackingEvents, track } from "../../../../../util/analytics.util";
import { getAdminsFromBillingGroup } from "../../../../../util/billing-group.util";
import {
  BillingGroupAction,
  BillingGroupActionType,
  useSelectTable,
} from "../../../hooks/useSelectTable";
import {
  BillingGroupRoleEnum,
  NewMemberPerson,
  formatListofPersonsAsEmailString,
  getBillingGroupName,
} from "../APMembers.util";
import { useAPMembers } from "../useAPMembers";

type ConformBulkActionDialogProps = {
  open: boolean;
  onClose: () => void;
  action: BillingGroupAction;
};

export const ConfirmBulkActionDialog = ({
  open,
  onClose,
  action,
}: ConformBulkActionDialogProps) => {
  const { org, onAddMember, onRemoveMember, billingGroupsForOrg } = useAPMembers();

  const { selectedMembers, clearSelectedMembers } = useSelectTable();

  if (!action) return null;

  const bgName = getBillingGroupName(billingGroupsForOrg, action?.billingGroupId);

  const personsEmailsAsString = formatListofPersonsAsEmailString(selectedMembers);

  const message =
    action.type === BillingGroupActionType.remove
      ? `Are you sure you want to remove ${personsEmailsAsString}? They will loose access to all existing paid features.`
      : `Do you want to add ${personsEmailsAsString} to ${bgName}?`;

  const upperCaseFirstLetter = (s: string) => {
    const str = s.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getPersonIdList = (persons: NewMemberPerson[]) => {
    return persons.map((p: NewMemberPerson) => p.personId);
  };

  const onCloseAndCancel = () => {
    clearSelectedMembers();
    onClose();
  };

  const getBillingGroupForId = (billingGroupId: string) => {
    const billingGroupsInWholeOrg = getValue(org?.billingGroups)?.list || [];
    return billingGroupsInWholeOrg.find((bg) => bg.id === billingGroupId);
  };

  const onConfirmBulkAction = async () => {
    const billingGroupId = action.billingGroupId;
    const actionType = action.type;
    track(TrackingEvents.ADMIN_PANEL.MEMBERS_USE_BULK_MEMBER_ACTION, { billingGroupId });
    const personList = getPersonIdList(selectedMembers);
    const containsAdmin = selectedMembers.some(
      (member: NewMemberPerson) => member.role === BillingGroupRoleEnum.Admin,
    );
    switch (actionType) {
      case BillingGroupActionType.add:
        await onAddMember(personList, billingGroupId);
        break;
      case BillingGroupActionType.remove:
        if (containsAdmin) {
          const adminsToRemove = selectedMembers.filter(
            (member: NewMemberPerson) => member.role === BillingGroupRoleEnum.Admin,
          );
          const bg = getBillingGroupForId(billingGroupId);
          const BgAdmins = bg ? getAdminsFromBillingGroup(bg) : [];

          if (!!BgAdmins && adminsToRemove.length >= BgAdmins.length) {
            toast.error(`Cannot remove all admins. Please assign another admin first.`);
            clearSelectedMembers();
          } else {
            await onRemoveMember(personList, billingGroupId);
          }
        } else {
          await onRemoveMember(personList, billingGroupId);
        }
        break;
      default:
        toast.error("Could not complete that action");
        break;
    }
    onCloseAndCancel();
  };

  return (
    <Modal
      opened={open}
      onClose={onClose}
      title={`${upperCaseFirstLetter(action.type)} users from ${bgName}?`}
      size="lg"
    >
      <div className="cw-body-base">{message}</div>
      <div className="cw-pt-4 cw-flex cw-justify-end">
        <ButtonSet>
          <Button variant="text" onClick={onCloseAndCancel}>
            Cancel
          </Button>
          <Button
            variant="text"
            sentiment={action.type === BillingGroupActionType.add ? "positive" : "destructive"}
            onClick={() => {
              void onConfirmBulkAction();
            }}
          >
            Confirm
          </Button>
        </ButtonSet>
      </div>
    </Modal>
  );
};
