import { Info } from "@clockwise/icons";
import React from "react";

export const TimingSetMessage = () => {
  return (
    <div>
      <span className="cw-inline-flex cw-flex-row cw-items-center cw-gap-1 cw-bg-neutral-inset cw-rounded-md cw-px-1 cw-py-0.5 cw-text-[10px]">
        <div>Timing is set</div>
        <Info className="cw-w-3.5 cw-h-3.5" />
      </span>
    </div>
  );
};
