import { useFlags } from "@clockwise/web-commons/src/util/FlagsContext";
import { useCallback, useEffect } from "react";
import { UserFlags } from "../util/flags.util";

export const useUnvisitedPageFlags = () => {
  const { isFlagSet, setFlag } = useFlags();

  const shouldShowUnvisitedBadges = isFlagSet(UserFlags.NUX.ShouldShowUnvisitedBadges.name);

  const hasVisitedLinksPage = isFlagSet(UserFlags.NUX.HasVisitedLinksPage.name);

  const hasVisitedFlexibilityPage = isFlagSet(UserFlags.NUX.HasVisitedFlexibilityPage.name);

  const hasVisitedIdealDayPage = isFlagSet(UserFlags.NUX.HasVisitedIdealDayPage.name);

  const setHasVisitedLinksPage = useCallback(() => {
    if (!hasVisitedLinksPage) {
      setFlag(UserFlags.NUX.HasVisitedLinksPage.name, true);
    }
  }, [hasVisitedLinksPage]);

  const setHasVisitedFlexibilityPage = useCallback(() => {
    if (!hasVisitedFlexibilityPage) {
      setFlag(UserFlags.NUX.HasVisitedFlexibilityPage.name, true);
    }
  }, [hasVisitedFlexibilityPage]);

  const setHasVisitedIdealDayPage = useCallback(() => {
    if (!hasVisitedIdealDayPage) {
      setFlag(UserFlags.NUX.HasVisitedIdealDayPage.name, true);
    }
  }, [hasVisitedIdealDayPage]);

  useEffect(() => {
    if (hasVisitedLinksPage && hasVisitedFlexibilityPage && hasVisitedIdealDayPage) {
      setFlag(UserFlags.NUX.ShouldShowUnvisitedBadges.name, false);
    }
  }, [hasVisitedLinksPage, hasVisitedFlexibilityPage, hasVisitedIdealDayPage]);

  return {
    shouldShowUnvisitedBadges,
    setHasVisitedLinksPage,
    setHasVisitedFlexibilityPage,
    setHasVisitedIdealDayPage,
    hasVisitedLinksPage,
    hasVisitedFlexibilityPage,
    hasVisitedIdealDayPage,
  };
};
