import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { Divider } from "@clockwise/design-system";
import { ExpandCircleDownFilled, Height, Repeat } from "@clockwise/icons";
import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import { useBoolean } from "usehooks-ts";
import { identifyStandardRecurrenceType } from "../molecules/ECRecurrence/recurrence.util";

const standardRecurrenceLabels = {
  Weekdays: "Every weekday",
  Weekly: "Every week",
  Biweekly: "Every 2 weeks",
  MonthlyByNthWeekday: "Monthly",
  MonthlyByLastWeekday: "Monthly",
  MonthlyByDate: "Monthly",
  None: "Does not repeat",
};

export const MoreEventTimeOptions = ({
  recurrenceRule,
  isAllDay,
  children,
}: {
  recurrenceRule: RecurrenceRule | null;
  isAllDay: boolean;
  children: ReactNode;
}) => {
  const { value: expanded, setTrue: setExpand } = useBoolean(false);
  const recurrenceType = identifyStandardRecurrenceType(recurrenceRule) || "None";
  const recurrenceLabel = standardRecurrenceLabels[recurrenceType];

  if (expanded) {
    return <div>{children}</div>;
  }
  return (
    <div
      className="cw-flex cw-ml-[38px] cw-cursor-pointer cw-gap-4 cw-text-subtle cw-font-medium cw-body-base cw-text-12"
      onClick={setExpand}
    >
      <div className="cw-flex cw-items-center cw-gap-0.5">
        <Height className="cw-h-3.5 cw-w-3.5" />
        {isAllDay ? "All day" : "All day?"}
      </div>
      <div className="cw-flex cw-items-center cw-gap-0.5">
        <Repeat className="cw-h-3.5 cw-w-3.5" />
        {recurrenceLabel}
      </div>
    </div>
  );
};

export const MoreSettings = ({ children }: { children: ReactNode }) => {
  const [isExpanded, setExpanded] = useState(false);

  const onToggleSettings = () => {
    setExpanded(!isExpanded);
  };

  return (
    <>
      <div className="cw-flex cw-gap-2 cw-items-center cw-mr-4 cw-ml-3" onClick={onToggleSettings}>
        <span className="cw-text-subtle cw-body-sm cw-text-12 cw-font-semibold">More settings</span>
        <div className="cw-flex-1">
          <Divider />
        </div>
        <ExpandCircleDownFilled
          className={classNames(
            "cw-w-4 cw-h-4 cw-text-subtle cw-transition-transform cw-duration-200",
            {
              "-cw-rotate-90": !isExpanded,
            },
          )}
        />
      </div>
      {isExpanded && <>{children}</>}
    </>
  );
};
