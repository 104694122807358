import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  RemoveDiffFromProposalDocument,
  RemoveDiffFromProposalMutation,
  RemoveDiffFromProposalMutationVariables,
} from "../apollo/__generated__/RemoveDiffFromProposal.generated";

type RemoveDiffFromProposalProps = {
  onError?: (error: Error) => void;
  onCompleted?: (data: RemoveDiffFromProposalMutation) => void;
};

type RemoveDiffFromProposal = [
  (variables: RemoveDiffFromProposalMutationVariables) => Promise<void>,
  {
    loading: boolean;
    error?: Error;
  },
];

export const useRemoveDiffFromProposal = ({
  onError,
  onCompleted,
}: RemoveDiffFromProposalProps): RemoveDiffFromProposal => {
  const [removeDiff, { loading, error }] = useMutation(RemoveDiffFromProposalDocument, {
    onCompleted,
    onError,
  });

  const onRemoveDiff = useCallback(
    async (variables: RemoveDiffFromProposalMutationVariables) => {
      await removeDiff({
        variables,
      });
    },
    [removeDiff],
  );

  return [onRemoveDiff, { loading, error }];
};
