import { Button, Tabs } from "@clockwise/design-system";
import { Close } from "@clockwise/icons";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { LoadingSpinner } from "@clockwise/web-commons/src/ui/LoadingSpinner";
import UsernameEditor from "@clockwise/web-commons/src/ui/scheduling-links/UsernameEditor";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { openPricingPage } from "@clockwise/web-commons/src/util/upgradeLinks.util";
import { Dialog } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { SchedulingLinksQueryDocument } from "../__generated__/SchedulingLinksQuery.v2.generated";
import { useUsernameValidator } from "../hooks/useUsernameValidator";
import { BillingGroupLogoUpload, PersonalLogoUpload } from "./LogoUpload";
import { ReadonlyLogo } from "./ReadonlyLogo";
import { LogoSettingsQueryDocument } from "./__generated__/LinkSettings.v2.generated";
import { UpdateLinkNameDocument } from "./__generated__/UpdateLinkName.v2.generated";

interface Props {
  open: boolean;
  onClose: () => void;
  username: string;
}

export const SchedulingLinkSettingsModal = ({ open, onClose, username }: Props) => {
  const { data, loading } = useGatewayQuery(LogoSettingsQueryDocument);

  const billingGroupBrandingEnabled =
    data?.paidFeatures?.brandedLinksForBillingGroupsAllowed ?? false;
  const billingGroup = data?.paidFeatures?.primaryBillingGroup;
  const userId = data?.currentUser?.id;
  const isAdmin =
    billingGroup?.admins.some((admin) => userId && admin.member.user?.id === userId) ?? false;
  const admins =
    billingGroup?.admins.map((admin) => ({
      name: admin.member.person.displayName,
      email: admin.member.person.email,
    })) ?? [];
  const userLogoUrl = data?.currentUser?.linkLogoUrl ?? null;
  const groupLogoUrl = billingGroup?.linkLogoUrl ?? null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <div className="cw-flex cw-flex-nowrap cw-items-center cw-border-b cw-border-solid cw-border-b-default">
        <div className="cw-heading-sm cw-flex-1 cw-flex cw-flex-nowrap cw-px-5 cw-py-3">
          Link Settings
        </div>
        {onClose && (
          <div className="cw-justify-self-end cw-px-2">
            <Button variant="text" size="mini" startIcon={Close} onClick={onClose} rounded />
          </div>
        )}
      </div>
      <div className="cw-w-[600px]">
        <Tabs
          tabs={[
            {
              key: "general",
              title: "General",
              content: (
                <div className="cw-px-5 cw-py-3">
                  <GeneralSettings
                    loading={loading}
                    username={username}
                    logoUrl={userLogoUrl}
                    hasGroupLogoOverride={billingGroupBrandingEnabled && !!groupLogoUrl}
                  />
                </div>
              ),
            },
            {
              key: "shared",
              title: "Shared",
              content: (
                <div className="cw-px-5 cw-py-3">
                  <SharedSettings
                    logoUrl={groupLogoUrl}
                    loading={loading}
                    isAdmin={isAdmin}
                    admins={admins}
                    billingGroup={billingGroup}
                    billingGroupBrandingEnabled={billingGroupBrandingEnabled}
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
    </Dialog>
  );
};

interface GeneralSettingsProps {
  username: string;
  logoUrl?: string | null;
  hasGroupLogoOverride?: boolean;
  loading?: boolean;
}

const GeneralSettings = ({
  username: initialUsername,
  logoUrl: initialLogoUrl,
  hasGroupLogoOverride = false,
  loading = false,
}: GeneralSettingsProps) => {
  const { validate, error: usernameError, validating } = useUsernameValidator(initialUsername);

  const [updateLinkName, { loading: isUpdatingLinkName }] = useGatewayMutation(
    UpdateLinkNameDocument,
    {
      refetchQueries: [{ query: SchedulingLinksQueryDocument }],
    },
  );

  if (loading) {
    return <LoadingContent />;
  }

  return (
    <div className="cw-flex cw-flex-col cw-gap-6">
      <div className="cw-space-y-1">
        <div className="cw-body-base cw-font-medium">Username</div>
        <div>
          <UsernameEditor
            username={initialUsername}
            loading={!initialUsername || isUpdatingLinkName}
            onSaveUsername={async (name) => {
              await updateLinkName({ variables: { linkName: name } });
            }}
            onValidateUsername={validate}
            validating={validating}
            error={usernameError}
            alwaysEditing
          />
        </div>
      </div>
      <div>
        <div className="cw-body-base cw-font-medium cw-mb-1">Booking logo</div>
        <div
          className={classNames("cw-body-base cw-mb-4", {
            "cw-text-subtle": !hasGroupLogoOverride,
            "cw-text-warning-muted": hasGroupLogoOverride,
          })}
        >
          {hasGroupLogoOverride
            ? "This logo will not be used because the admin of your plan has already selected a logo. View the admin-selected logo on the Shared tab."
            : "Logo will be displayed on the booking page."}
        </div>
        <PersonalLogoUpload url={initialLogoUrl} />
      </div>
    </div>
  );
};

interface SharedSettingsProps {
  logoUrl?: string | null;
  loading: boolean;
  isAdmin: boolean;
  admins: {
    name: string;
    email: string;
  }[];
  billingGroup?: {
    id: string;
    name: string;
  } | null;
  billingGroupBrandingEnabled?: boolean;
}

const SharedSettings = ({
  logoUrl: initialLogoUrl,
  loading,
  isAdmin,
  admins,
  billingGroup,
  billingGroupBrandingEnabled = false,
}: SharedSettingsProps) => {
  if (loading) {
    return <LoadingContent />;
  }

  if (!billingGroup || !billingGroupBrandingEnabled) {
    return <UpgradeToAccessPremiumFeatures billingGroupName={billingGroup?.name} />;
  }
  return (
    <div className="cw-flex cw-flex-col cw-gap-6">
      <div>
        <div className="cw-body-base cw-font-medium cw-mb-1">
          Shared booking logo for members of {billingGroup.name}
        </div>
        <div className="cw-body-base cw-text-subtle cw-mb-4">
          Logo will be displayed on the booking page for links created by all members of the{" "}
          <span className="cw-font-medium">{billingGroup.name}</span> plan.
          <br />
          <span className="cw-text-warning-muted">
            Note: This will override custom logos of all members on this plan.
          </span>
        </div>
        {isAdmin ? (
          <BillingGroupLogoUpload billingGroupId={billingGroup.id} url={initialLogoUrl} />
        ) : (
          <ReadonlyLogo logoUrl={initialLogoUrl} admins={admins} />
        )}
      </div>
    </div>
  );
};

interface UpgradeProps {
  billingGroupName?: string;
}

const UpgradeToAccessPremiumFeatures = ({ billingGroupName }: UpgradeProps) => {
  const track = useTracking();
  const eligibilityMessage = billingGroupName ? (
    <>
      Your current plan, <span className="cw-font-medium">{billingGroupName}</span>, does not
      include this feature.
    </>
  ) : (
    "Your current plan does not include this feature."
  );

  return (
    <div className="cw-flex cw-flex-col cw-gap-6">
      <div>
        <div className="cw-body-base cw-font-medium cw-mb-1">
          Upgrade to access premium features
        </div>
        <div className="cw-body-base cw-text-subtle cw-mb-4">
          {eligibilityMessage} Upgrade your plan to access premium features such as administrative
          logo control, meeting & calendar data export, and more.
        </div>
      </div>
      <Button
        sentiment="positive"
        onClick={() => {
          track(TrackingEvents.PAYWALLS.LINKS_SHARED_SETTINGS_DIALOG_UPGRADE_CLICKED);
          openPricingPage();
        }}
      >
        Upgrade now
      </Button>
    </div>
  );
};

const LoadingContent = () => {
  return (
    <div className="cw-flex cw-flex-col cw-items-center cw-justify-center">
      <div className="cw-w-10 cw-h-10">
        <LoadingSpinner />
      </div>
    </div>
  );
};
