import { RadioGroup, RadioOption } from "@clockwise/design-system";
import React from "react";
import { ThresholdLabel } from "./ThresholdLabel";

type Value = "some" | "none";

type Props = {
  selected: Value;
  threshold?: number | null;
  disabled?: boolean;
  onChange: (value: Value) => void;
  onChangeThreshold: (value: number) => void;
};

export function FocusTimeSlideRadioGroup({
  selected,
  threshold,
  disabled = false,
  onChange,
  onChangeThreshold,
}: Props) {
  return (
    <RadioGroup
      fullWidth
      value={selected}
      onChange={onChange}
      variant="outlined"
      disabled={disabled}
    >
      <RadioOption
        value="some"
        label={
          <ThresholdLabel
            threshold={threshold ?? undefined}
            disabled={disabled}
            onChange={onChangeThreshold}
          />
        }
      />
      <RadioOption value="none" label="Don't schedule any Focus Time for me" />
    </RadioGroup>
  );
}
