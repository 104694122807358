import makeMutationUpdateRefetchBadCacheHandler from "../../../util/makeMutationUpdateRefetchBadCacheHandler";
import { MeetingReliefGoalsDocument } from "../graphql/__generated__/MeetingReliefGoals.generated";
import useMeetingReliefSettings from "./useMeetingReliefSettings";
import useMeetingReliefToggle from "./useMeetingReliefToggle";
import useMeetingReliefUpdate from "./useMeetingReliefUpdate";

type Options = {
  onSuccess?: () => void;
  onFail?: () => void;
};

export const useMeetingRelief = (options?: Options) => {
  const { settings, loading, error: loadError, orgId, refetch } = useMeetingReliefSettings();

  const onUpdate = makeMutationUpdateRefetchBadCacheHandler(
    "meeting breaks",
    MeetingReliefGoalsDocument,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    refetch,
  );

  const { update, loading: updating, error: updateError } = useMeetingReliefUpdate(
    String(orgId),
    settings,
    { onCompleted: options?.onSuccess, onError: options?.onFail, onUpdate },
  );

  const { toggle, loading: toggling, error: toggleError } = useMeetingReliefToggle(
    String(orgId),
    settings,
    { onCompleted: options?.onSuccess, onError: options?.onFail, onUpdate },
  );

  return {
    settings,
    loadError,
    loading,
    update,
    updating,
    updateError,
    toggle,
    toggling,
    toggleError,
  };
};

export default useMeetingRelief;
