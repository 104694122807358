import * as React from "react";
// import * as Relay from 'react-relay';

import { setSudoTarget } from "#webapp/src/state/actions/sudo.actions";
import { getReduxStore } from "#webapp/src/state/redux-store";

// Adapted from https://facebook.github.io/react/docs/higher-order-components.html
function withSudo(WrappedComponent: React.ComponentClass<any>): any {
  class WithSudo extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
    }

    public refetch = (email: string, variables: Object, callback: Function) => {
      getReduxStore().dispatch(setSudoTarget(email));
      this.props.relay.refetch(variables, undefined, () => callback && callback(), { force: true });
    };

    public commitMutation = (
      email: string,
      mutation: Function,
      variables: Object,
      onCompleted: Function,
      onError: Function,
    ) => {
      getReduxStore().dispatch(setSudoTarget(email));
      mutation(this.props.relay.environment, variables, onCompleted, onError);
    };

    public render() {
      return (
        <WrappedComponent
          sudoRefetch={this.refetch}
          sudoCommitMutation={this.commitMutation}
          {...this.props}
        />
      );
    }
  }

  (WithSudo as any).displayName = `WithSudo(${getDisplayName(WrappedComponent)})`;
  return WithSudo;
}

function getDisplayName(WrappedComponent: React.ComponentClass<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withSudo;
