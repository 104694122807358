import { slackAppStoreUrl } from "@clockwise/client-commons/src/constants/site";
import React, { useEffect } from "react";
import { TrackingEvents, track } from "../../../../util/analytics.util";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { ButtonGroup, StepCounter, StepLayout } from "../../shared-components";
import { SlackSlide } from "./SlackSlide";

export const SlackStep = () => {
  const { goForward, goBack, shouldShowSecondaryButton, funnelType } = useWebOnboardingSteps();

  useEffect(() => {
    track(TrackingEvents.WEB_ONBOARDING.SLACK_STEP_VIEWED, { funnelType });
  }, []);

  const openSlackAndGoForward = () => {
    track(TrackingEvents.WEB_ONBOARDING.SLACK_STEP_CLICK_INSTALL, { funnelType });
    goForward();
    // This is a workaround to open the slack app store in a new tab while also navigating to the next step.
    // We had very few instances of users having to click next on the slack slide more than once, so this seems to fix the issue.
    setTimeout(() => window.open(slackAppStoreUrl, "_blank"), 100);
  };

  const skipStep = () => {
    track(TrackingEvents.WEB_ONBOARDING.SLACK_STEP_SKIPPED, { funnelType });
    goForward();
  };

  return (
    <StepLayout>
      <StepCounter />
      <SlackSlide />
      <ButtonGroup
        shouldShowSkip
        shouldShowSecondary={shouldShowSecondaryButton}
        primaryLabel={"Install app for Slack"}
        onClickPrimary={openSlackAndGoForward}
        onClickSecondary={goBack}
        onSkip={skipStep}
      />
    </StepLayout>
  );
};
