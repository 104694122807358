import { track, TrackingEvents } from "#clockwise/web-commons/src/util/analytics.util";
import { getServerUrl } from "#clockwise/web-commons/src/util/post-message-common.util";
import { Button } from "@clockwise/design-system";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { useWindowSize } from "../../util/react.util";
import { openBillingPage, openPricingPage } from "../../util/upgradeLinks.util";
export type TrialDialogOpenStates = "Group" | "RoundRobin" | null;

export type TrialUpgradeDialogProps = {
  openState: TrialDialogOpenStates;
  onClose: () => void;
  shouldshowJoinExistingPlan: boolean;
};

const DialogCopy: {
  [k in Exclude<TrialDialogOpenStates, null>]: { title: string; description: string };
} = {
  Group: {
    title: "Upgrade to the Teams plan for group links, and more.",
    description: "You're currently on the Free plan, which includes one-on-one scheduling links.",
  },
  RoundRobin: {
    title: "Upgrade to access premium features",
    description:
      "Round Robin scheduling is only available on the Business and Enterprise plans. Please upgrade your plan to access this feature.",
  },
};

export const TrialUpgradeDialog = ({
  openState,
  onClose,
  shouldshowJoinExistingPlan,
}: TrialUpgradeDialogProps) => {
  const { width: windowWidth } = useWindowSize();

  const goToPricing = () => {
    track(TrackingEvents.PAYWALLS.LINKS_UPGRADE_DIALOG_COMPARE_PLANS_CLICKED);
    openPricingPage();
    onClose();
  };

  const goToBillingPage = () => {
    track(TrackingEvents.PAYWALLS.LINKS_UPGRADE_DIALOG_JOIN_PLAN_CLICKED);
    openBillingPage();
    onClose();
  };

  const goToCheckout = () => {
    track(TrackingEvents.PAYWALLS.LINKS_UPGRADE_DIALOG_UPGRADE_CLICKED);
    window.open(`${getServerUrl()}/app/checkout?teamSelect=true`, "_blank");
    onClose();
  };

  return (
    <Dialog
      fullScreen={windowWidth < 500}
      open={Boolean(openState)}
      onClose={onClose}
      disableRestoreFocus
      disableScrollLock
    >
      <div className="cw-p-10 cw-relative">
        <div style={{ width: "445px" }}>
          <h2 className="cw-font-brand cw-font-bold cw-text-xl">
            {openState && DialogCopy[openState].title}
          </h2>
          <div className="cw-leading-6 cw-mt-2 cw-mb-5">
            {openState && DialogCopy[openState].description}
          </div>
          <div className="cw-flex cw-gap-5">
            {shouldshowJoinExistingPlan ? (
              <Button onClick={goToBillingPage} variant="outlined">
                Join a Plan
              </Button>
            ) : (
              <Button onClick={goToPricing} variant="outlined">
                Compare all Plans
              </Button>
            )}

            <Button onClick={goToCheckout} sentiment="positive">
              {openState === "RoundRobin" ? "Upgrade now" : "Upgrade to Teams"}
            </Button>
          </div>
          <div onClick={onClose} className="cw-absolute cw-top-3 cw-right-3">
            <Close className="cw-w-6 cw-h-6 cw-text-subtle cw-cursor-pointer" />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
