import classNames from "classnames";
import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6
import "../molecules/additionalStyles.css";

type QuillToolBar =
  | (
      | string[]
      | {
          list: string;
        }[]
    )[]
  | false;

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    ["clean"],
  ] as QuillToolBar,
  clipboard: {
    matchVisual: false,
    matchers: [
      [
        Node.TEXT_NODE,
        (node: { data: string }, delta: any) => {
          const regex = /https?:\/\/[^\s]+/g;
          if (node.data) {
            const matches = node.data.matchAll(regex);
            const newOps = [];
            let lastIndex = 0;
            for (const match of matches) {
              const start = match.index;
              const end = start + match[0].length;

              // Text before the URL
              if (start > lastIndex) {
                newOps.push({ insert: node.data.slice(lastIndex, start) });
              }

              // The URL itself
              newOps.push({
                insert: match[0],
                attributes: { link: match[0] },
              });

              lastIndex = end;
            }

            // Remaining text after the last URL
            if (lastIndex < node.data.length) {
              newOps.push({ insert: node.data.slice(lastIndex) });
            }

            delta.ops = newOps;
          }

          return delta;
        },
      ],
    ],
  },
};

const listOfToolbarClasses = [
  "ql-bold",
  "ql-italic",
  "ql-underline",
  "ql-list",
  "ql-link",
  "ql-clean",
  "ql-editor",
  "ql-clipboard",
];

const formats = ["bold", "italic", "underline", "list", "bullet", "link"];

export const QuillMarkdownEditor = ({
  showToolbar = false,
  readOnly,
  text,
  handleChange,
  additionalClasses,
}: {
  showToolbar?: boolean;
  readOnly: boolean;
  text: string;
  handleChange: (value: string, userModified?: boolean) => void;
  additionalClasses?: string;
}) => {
  const quillRef = useRef<ReactQuill>(null);
  const [isFocused, setIsFocused] = useState(false);

  const onQuillFocus = () => {
    setIsFocused(true);
  };

  const onQuillBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    // We are putting this on the div's blur instead of quills because FOR SOME REASON quill does not pass the event in their onblur
    const qlEditingTooltip = document.querySelector(".ql-editing");
    const isLinkEditingOpen = qlEditingTooltip && !qlEditingTooltip.classList.contains("ql-hidden");
    const target = event.relatedTarget;
    if (target && target instanceof HTMLElement) {
      // We want to stay in focus if they just clicked one of the toolbar buttons
      // This is just a way of making sure all the buttons are covered here
      const dataLinkForLinkButton = target.getAttribute("data-link");
      const clickedOnToolbar =
        Array.from(target.classList).some((className) =>
          listOfToolbarClasses.includes(className),
        ) || dataLinkForLinkButton === "https://quilljs.com";
      if (!clickedOnToolbar) {
        setIsFocused(false);
      }
      // We want to stay in focus if they are adding a link
    } else if (!isLinkEditingOpen) {
      setIsFocused(false);
    }
  };

  const handleClick = () => {
    if (!isFocused) {
      setIsFocused(true);
    }
  };

  return (
    <div className="cw-w-full" onBlur={onQuillBlur} onClick={handleClick}>
      <ReactQuill
        ref={quillRef}
        className={classNames("fs-exclude cw-body-base cw-font-medium", additionalClasses, {
          "hide-quill-toolbar": !isFocused || !showToolbar,
          "focus-quill-border": isFocused && showToolbar,
          "cw-border cw-border-solid cw-border-subtle": !isFocused || !showToolbar,
          "cw-text-default-disabled cw-bg-default-disabled": !showToolbar,
        })}
        readOnly={readOnly}
        preserveWhitespace={true}
        value={text}
        theme="snow"
        onFocus={onQuillFocus}
        onChange={(value) => {
          handleChange(value, isFocused);
        }}
        modules={modules}
        placeholder={readOnly ? "No description" : "Add description..."}
        formats={formats}
      />
    </div>
  );
};
