import { disabled_fg_muted, fg_muted } from "@clockwise/design-system/tokens";
import { SvgIconComponent } from "@clockwise/icons";
import classNames from "classnames";
import React, { KeyboardEventHandler, MouseEvent } from "react";

export const listItemClass = classNames(
  "cw-bg-default cw-border-none cw-w-full cw-flex cw-items-center cw-py-1 cw-my-1 cw-body-sm cw-rounded-md",
);

export const buttonClassForPopover = classNames(
  listItemClass,
  "cw-cursor-pointer hover:cw-bg-default-hover ",
);

export const ListItemButton = ({
  onClick,
  onKeyDown,
  disabled = false,
  startIcon: StartIconComponent,
  startIconColor,
  endIconColor,
  keepHoveredStyle = false,
  endIcon: EndIconComponent,
  children,
}: {
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  startIcon?: SvgIconComponent;
  startIconColor?: string;
  endIconColor?: string;
  keepHoveredStyle?: boolean;
  endIcon?: SvgIconComponent;
  children: React.ReactNode;
}) => {
  const iconColorStart = startIconColor ? startIconColor : disabled ? disabled_fg_muted : fg_muted;
  const iconColorEnd = endIconColor ? endIconColor : disabled ? disabled_fg_muted : fg_muted;

  const Content = (
    <>
      <div className="cw-flex cw-gap-2 cw-items-center cw-w-full">
        {StartIconComponent && (
          <StartIconComponent
            fill={iconColorStart}
            style={{ color: iconColorStart }}
            className="cw-h-4 cw-w-4"
          />
        )}
        <div className="cw-text-start cw-w-full">{children}</div>
      </div>
      {EndIconComponent && (
        <EndIconComponent
          fill={iconColorEnd}
          style={{ color: iconColorEnd }}
          className="cw-h-4 cw-w-4"
        />
      )}
    </>
  );

  if (!onClick) {
    return (
      <div className="cw-bg-default cw-ml-1 cw-border-none cw-w-full cw-flex cw-py-1 cw-my-1 cw-body-sm cw-rounded-md cw-justify-between cw-items-center">
        {Content}
      </div>
    );
  } else {
    return (
      <button
        disabled={disabled}
        className={classNames(
          "cw-items-center cw-border-none cw-w-full cw-flex cw-py-1 cw-my-1 cw-body-sm cw-rounded-md cw-justify-between cw-cursor-pointer hover:cw-bg-default-hover disabled:cw-cursor-not-allowed disabled:cw-text-default-disabled",
          {
            "cw-bg-default-hover": keepHoveredStyle,
            "cw-bg-default": !keepHoveredStyle,
          },
        )}
        onMouseDown={onClick}
        onKeyDown={onKeyDown}
      >
        {Content}
      </button>
    );
  }
};
