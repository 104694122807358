import { getRenderTimeZone } from "#clockwise/web-commons/src/util/time-zone.util";
import { TimeInput, Tooltip } from "@clockwise/design-system";
import { Undo } from "@clockwise/icons";
import { DateTime } from "luxon";
import React from "react";
import { MeetingHoursMessage } from "../atoms/MeetingHoursMessage";
import { StuckMessage } from "../atoms/StuckMessage";
import { UnknownBoundsMessage } from "../atoms/UnknownBoundsMessage";

type Props = {
  disabled?: boolean;
  end: string;
  endBound: string | null;
  minDuration?: number;
  onChange: (start: string, end: string) => void;
  start: string;
  startBound: string | null;
  stuck: boolean;
  suppressNoWorkHoursMessage?: boolean;
};

export const TimeRangeControl = ({
  disabled,
  end,
  endBound,
  minDuration = 30,
  onChange,
  start,
  startBound,
  stuck,
  suppressNoWorkHoursMessage = false,
}: Props) => {
  const onChangeStart = (newStart: string | null) => {
    if (!newStart) return;

    const startDt = DateTime.fromFormat(newStart, "HH:mm");
    const endDt = DateTime.fromFormat(end, "HH:mm");

    if (endDt.diff(startDt, "minutes").minutes < minDuration) {
      const newEnd = startDt.plus({ minutes: minDuration }).toFormat("HH:mm");
      onChange(newStart, newEnd);
    } else {
      onChange(newStart, end);
    }
  };

  const onChangeEnd = (newEnd: string | null) => {
    if (!newEnd) return;

    const startDt = DateTime.fromFormat(start, "HH:mm");
    const endDt = DateTime.fromFormat(newEnd, "HH:mm");

    if (endDt.diff(startDt, "minutes").minutes < minDuration) {
      const newStart = endDt.minus({ minutes: minDuration }).toFormat("HH:mm");
      onChange(newStart, newEnd);
    } else {
      onChange(start, newEnd);
    }
  };

  const onChangeReset = () => {
    if (!startBound || !endBound) return;
    onChange(startBound, endBound);
  };

  const matchesBounds = start === startBound && end === endBound;
  const unknownBounds = !startBound || !endBound;

  return (
    <div className="cw-flex cw-flex-col cw-gap-1">
      <div className="cw-text-muted cw-text-[12px] cw-flex cw-flex-row cw-items-center cw-justify-between">
        <div className="cw-font-medium">Between:</div>
        {!matchesBounds && startBound && endBound && (
          <Tooltip
            maxWidth={200}
            title={`Revert to overlapping attendee meeting hours (${formatTimeRange(
              startBound,
              endBound,
            )})`}
          >
            <Undo
              className="cw-w-3.5 cw-h-3.5 cw-cursor-pointer cw-text-brand"
              onClick={onChangeReset}
            />
          </Tooltip>
        )}
      </div>
      <div className="cw-flex cw-flex-row cw-items-center cw-gap-1">
        <div>
          <TimeInput
            disabled={disabled}
            maxTime="23:30"
            minTime="01:00"
            onChange={onChangeStart}
            selectOnly
            size="small"
            value={start}
            withinPortal={false}
          />
        </div>
        <div className="cw-text-muted cw-text-[12px]">&</div>
        <div>
          <TimeInput
            disabled={disabled}
            maxTime="24:00"
            minTime="01:30"
            onChange={onChangeEnd}
            selectOnly
            size="small"
            value={end}
            withinPortal={false}
          />
        </div>
      </div>
      <MeetingHoursMessage />
      {stuck ? (
        <StuckMessage />
      ) : unknownBounds && !suppressNoWorkHoursMessage ? (
        <UnknownBoundsMessage />
      ) : null}
    </div>
  );
};

const formatTimeRange = (start: string, end: string) => {
  const zone = getRenderTimeZone();
  const startDt = DateTime.fromFormat(start, "HH:mm", { zone });
  const endDt = DateTime.fromFormat(end, "HH:mm", { zone });
  const timeFormat = "h:mma ZZZZ";
  const formatted = `${startDt.toFormat(timeFormat)} - ${endDt.toFormat(timeFormat)}`;
  const reFormatted = formatted.replace(/AM|PM/g, (match) => match.toLowerCase());

  return reFormatted;
};
