import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime } from "luxon";

export const calculateIsAllDay = (startDateTime: DateTime, endDateTime: DateTime) => {
  return (
    startDateTime.millisecond == 0 &&
    startDateTime.second == 0 &&
    startDateTime.minute == 0 &&
    startDateTime.hour == 0 &&
    endDateTime.millisecond == 0 &&
    endDateTime.second == 0 &&
    endDateTime.minute == 0 &&
    endDateTime.hour == 0
  );
};

export const isValidTimeRange = (_startISO?: DateTime, _endISO?: DateTime) => {
  if (!_startISO || !_endISO) {
    return false;
  }
  return _startISO < _endISO;
};

export const isValidTimeInput = (input: string) => {
  // validate HH:MM AM/PM format
  const regex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm])$)/;
  const valid = input.match(regex);
  return Boolean(valid);
};

export const dateFromString = (userSelectedTime: string) => {
  return DateTime.fromFormat(userSelectedTime.replace(/\s/g, ""), "h:mma", {
    zone: getRenderTimeZone(),
  });
};

const getDurationMins = (startISO?: DateTime, endISO?: DateTime) => {
  if (!startISO || !endISO) {
    return 30;
  }

  if (startISO < endISO) {
    return endISO.diff(startISO).as("minutes");
  }

  // If the start time is after the end time,
  // just calculate the duration between the times, ignoring the date
  const startTime = {
    hour: startISO.hour,
    minute: startISO.minute,
  };
  const endTime = {
    hour: endISO.hour,
    minute: endISO.minute,
  };
  return DateTime.fromObject(endTime).diff(DateTime.fromObject(startTime)).as("minutes");
};

export const evaluateTimesFromStartTimeChange = (
  startTime: string,
  existingStartISO?: DateTime,
  existingEndISO?: DateTime,
) => {
  const newStartTimeAsDate = dateFromString(startTime);

  // Adjust end time if the change makes it invalid
  const newTimeValues = {
    hour: dateFromString(startTime).hour,
    minute: dateFromString(startTime).minute,
  };

  const existingDurationMins = getDurationMins(existingStartISO, existingEndISO);

  const newStartDate = existingStartISO
    ? existingStartISO.set(newTimeValues)
    : DateTime.fromObject(newTimeValues);
  const newStartISO = newStartDate;

  const adjustableEndISO = newStartISO.set({
    minute: newStartTimeAsDate.minute + existingDurationMins,
  });

  return {
    start: newStartISO,
    end: adjustableEndISO,
  };
};

export const evaluateTimesFromEndTimeChange = (
  endTime: string,
  existingStartISO?: DateTime,
  existingEndISO?: DateTime,
) => {
  const newEndTimeAsDate = dateFromString(endTime);
  const isAtMidnight = endTime === "12:00AM";

  // Adjust end time if the change makes it invalid
  const newTimeValues = {
    hour: dateFromString(endTime).hour,
    minute: dateFromString(endTime).minute,
    day: existingEndISO
      ? isAtMidnight
        ? existingEndISO.day + 1
        : existingEndISO.day
      : existingStartISO
      ? existingStartISO.day
      : newEndTimeAsDate.day,
  };

  const newEndDate = existingEndISO
    ? existingEndISO.set(newTimeValues)
    : DateTime.fromObject(newTimeValues);
  const newEndISO = newEndDate;
  let adjustableStartISO = existingStartISO;

  if (!isValidTimeRange(existingStartISO, newEndISO)) {
    adjustableStartISO = newEndISO.set({
      minute: newEndTimeAsDate.minute - 30,
    });
  }

  return {
    start: adjustableStartISO,
    end: newEndISO,
  };
};
