// schema
import * as ISchema from "#webapp/src/__schema__";

// easy-to-reference constants
export const standardGoals = {
  Lunch: "Lunch",
  FocusTime: "FocusTime",
  PreferredWork: "PreferredWork",
  MeetingRelief: "MeetingRelief",
  TravelTime: "TravelTime",
};

export function findGoal(userGoals: ISchema.IGoalConnection, goalId: string): ISchema.IGoal | null {
  const goals = userGoals.edges.map((edge) => edge.node);
  return goals.find((goal) => !goal.isDeleted && goal.goalId === goalId) || null;
}

export function findGoalByName(
  userGoals: ISchema.IGoalConnection,
  name: keyof typeof standardGoals,
): ISchema.IGoal | null {
  const goals = userGoals.edges.map((edge) => edge.node);
  return goals.find((goal) => !goal.isDeleted && goal.name === name) || null;
}
