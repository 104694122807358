import { Tooltip } from "@clockwise/design-system";
import React from "react";

export const RowAdminList = ({ adminList }: { adminList: string[] }) => {
  if (!adminList.length) return <div>None</div>;
  const adminDupe = [...adminList];
  const firstAdmin = adminDupe.shift();

  const remainingAdminsList = adminDupe.map((admin) => {
    return <div key={admin}>{admin}</div>;
  });

  return (
    <div>
      {firstAdmin}{" "}
      {adminDupe.length > 0 && (
        <span>
          and{" "}
          <Tooltip placement="top" title={remainingAdminsList}>
            <span className="cw-underline cw-pointer">{adminDupe.length} others</span>
          </Tooltip>
        </span>
      )}
    </div>
  );
};
