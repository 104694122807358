import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useFeatureFlag } from "../../launch-darkly/useLaunchDarkly";

const signUpPages = ["/signup", "/login", "/login/app", "/welcome", "/welcome-sign-in", "/m365"];
const welcomePageHref = "https://www.getclockwise.com/welcome";

export const WelcomePageCanonicalTag = () => {
  const location = useLocation();

  const [shouldEnable] = useFeatureFlag("EnableWelcomePageCanonicalTag");
  const shouldCheckForWelcomePageCanonicalLink = signUpPages.some((signupPathItem) => {
    const currentPathTokens = location.pathname.split("/");
    const signupPathItemTokens = signupPathItem.split("/");
    return (
      currentPathTokens.length === signupPathItemTokens.length &&
      currentPathTokens.every((token, index) => token === signupPathItemTokens[index])
    );
  });

  useEffect(() => {
    if (shouldEnable && shouldCheckForWelcomePageCanonicalLink) {
      const welcomePageCanonicalLink = document.querySelector("link[rel='canonical']");
      const canonicalLinkHref = welcomePageCanonicalLink?.getAttribute("href");
      const isWelcomePageCanonicalLinkPresent = canonicalLinkHref === welcomePageHref;
      // if the canonical link is not present, add it to the head
      if (!isWelcomePageCanonicalLinkPresent) {
        const head = document.querySelector("head");
        const canonicalLink = document.createElement("link");
        canonicalLink.setAttribute("rel", "canonical");
        canonicalLink.setAttribute("href", welcomePageHref);
        head?.appendChild(canonicalLink);
      }
    }
  }, [location.pathname, shouldCheckForWelcomePageCanonicalLink, shouldEnable]);

  return null;
};
