import React from "react";
import { NUXChecklist } from "./NUXChecklist";
import { ChecklistProgress } from "./components/ChecklistProgress";

export const NUXChecklistWrapper = () => {
  return (
    <div className="cw-flex cw-flex-col cw-w-full">
      <ChecklistProgress />
      <NUXChecklist />
    </div>
  );
};
