import { bg_busy_emphasis, bg_warning_emphasis } from "@clockwise/design-system/tokens";
import { ConflictCluster } from "@clockwise/schema";
import { isEmpty, isNil } from "lodash";
import { DateTime, Interval } from "luxon";
import React from "react";
import { getRenderTimeZone } from "../../../util/time-zone.util";
import { CalendarPositioner } from "../calendar-positioner/CalendarPositioner";

export const CalendarConflicts = ({
  conflictClusters = [],
  activeConflictId = "",
  dateTimes,
  onNodeClick,
}: {
  conflictClusters: ConflictCluster[];
  activeConflictId?: string;
  dateTimes: DateTime[];
  onNodeClick: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
}) => {
  const hasConflictClusters = !isEmpty(conflictClusters) && !isNil(conflictClusters);
  const zone = getRenderTimeZone();

  if (!hasConflictClusters) {
    return null;
  }

  return (
    <CalendarPositioner
      dateTimes={dateTimes}
      gutters={true}
      positionables={conflictClusters.map((cluster) => {
        const interval = Interval.fromISO(cluster.timeBound, { zone });
        return {
          key: cluster.timeBound,
          interval,
          render: () => (
            <CalendarAvailabilityBlock
              onClick={(e) => {
                e.stopPropagation();
                onNodeClick(e, cluster.id);
              }}
              isActive={activeConflictId === cluster.id}
            />
          ),
        };
      })}
    />
  );
};

const CalendarAvailabilityBlock = ({
  onClick,
  isActive,
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  isActive?: boolean;
}) => {
  const color = isActive ? bg_busy_emphasis : bg_warning_emphasis;

  return (
    <div
      onClick={(e) => {
        onClick(e);
      }}
      style={{
        boxShadow: `0px 0px 7px -2px ${color}`,
        borderColor: color,
      }}
      className={`cw-border-2 cw-border-solid cw-h-full cw-cursor-pointer cw-rounded-md`}
    />
  );
};
