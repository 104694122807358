import {
  FocusTimeGoals,
  NotificationStrategy,
  PreferredFocusTime,
  ScheduleAs,
} from "@clockwise/schema";

import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Duration } from "luxon";
import { FocusTimeSettings } from "../types";

export type AutoDeclineSettings = {
  enabled: boolean;
  threshold: Duration;
};

export type DailyMaxSettings = {
  enabled: boolean;
  maxAmount: Duration;
};

export const defaultSettings: FocusTimeSettings = {
  id: "",
  enabled: false,
  notificationStrategy: NotificationStrategy.NotifyInGCal,
  preferredFocusIn: PreferredFocusTime.NoPreference,
  scheduleAs: ScheduleAs.Busy,
  threshold: Duration.fromObject({ hours: 15 }),
  autoDeclineSettings: {
    enabled: false,
    threshold: Duration.fromObject({ hours: 15 }),
  },
  dailyMaxSettings: {
    enabled: false,
    maxAmount: Duration.fromObject({ hours: 3 }),
  },
};

export const transformFocusTimeGoalsToSettings = (focusTimeGoal: FocusTimeGoals | undefined) => {
  const settings = { ...defaultSettings };

  if (focusTimeGoal) {
    const {
      id,
      enabled,
      remoteHoldSettings,
      preferredFocusIn,
      threshold,
      autoDeclineSettings,
      dailyMaxSettings,
    } = focusTimeGoal;
    const allFocusTime = getValue(threshold, "AllFocusTime");
    const someFocusTime = getValue(threshold, "SomeFocusTime");

    if (id) settings.id = id;
    settings.enabled = !!enabled;
    if (preferredFocusIn) settings.preferredFocusIn = preferredFocusIn;

    if (allFocusTime) {
      settings.threshold = "all";
    } else if (someFocusTime && typeof someFocusTime.idealFocus === "string") {
      settings.threshold = Duration.fromISO(someFocusTime.idealFocus);
    }

    if (remoteHoldSettings) {
      const { notificationStrategy, scheduleAs } = remoteHoldSettings;
      if (notificationStrategy) settings.notificationStrategy = notificationStrategy;
      if (scheduleAs) settings.scheduleAs = scheduleAs;
    }

    if (autoDeclineSettings) {
      const { enabled, threshold } = autoDeclineSettings;
      if (enabled) settings.autoDeclineSettings.enabled = enabled;
      if (threshold && typeof threshold === "string") {
        settings.autoDeclineSettings.threshold = Duration.fromISO(threshold);
      }
    }

    if (dailyMaxSettings) {
      const { enabled, maxAmount } = dailyMaxSettings;
      if (enabled) settings.dailyMaxSettings.enabled = enabled;
      if (maxAmount && typeof maxAmount === "string") {
        settings.dailyMaxSettings.maxAmount = Duration.fromISO(maxAmount);
      }
    }
  }

  return settings;
};

export default transformFocusTimeGoalsToSettings;
