import { ApolloError, InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  ResetConversationDocument,
  ResetConversationMutation,
} from "../apollo/__generated__/ResetConversation.generated";

type UseResetConversationProps = {
  onCompleted?: (data: ResetConversationMutation) => void;
  onError?: (error: ApolloError) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
};

type UseResetConversationResult = [
  () => Promise<void>,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

export const useResetConversation = ({
  onCompleted,
  onError,
  refetchQueries = ["ChatHistoryQuery"],
}: UseResetConversationProps): UseResetConversationResult => {
  const [resetConversation, { loading, error }] = useMutation(ResetConversationDocument);

  const onResetConversation = useCallback(async () => {
    await resetConversation({
      onCompleted,
      onError,
      refetchQueries,
    });
  }, [resetConversation, refetchQueries, onCompleted, onError]);

  return [onResetConversation, { loading, error }];
};
