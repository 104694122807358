import { bg_emphasis } from "@clockwise/design-system/tokens";
import {
  CheckCircleFilled,
  ClockwiseIntelligenceFilled,
  EmergencyHomeFilled,
  SentimentNeutralFilled,
} from "@clockwise/icons";
import classNames from "classnames";
import pluralize from "pluralize";
import React from "react";
import { TradeoffBlock } from "../chat-plus-calendar/PersistedProposalContext";
import { AIMarkdown } from "../chat/ai-chat/components/ai-markdown";
import { ECAttendeeStack } from "../event-card/atoms/ECAttendeeStack";

const MAX_TRADEOFFS = 4;

export const TradeoffHeader = ({ text, subtext }: { text: string; subtext?: string }) => {
  return (
    <div className="cw-text-onEmphasis cw-body-sm cw-text-12">
      <span className="cw-font-semibold">{text}</span> {subtext && <span>{subtext}</span>}
    </div>
  );
};

const TradeoffContainer = ({
  children,
  showBottomBorder,
}: {
  children: React.ReactNode;
  showBottomBorder: boolean;
}) => {
  return (
    <div
      className={classNames("cw-flex-col cw-items-center cw-py-2.5", {
        "cw-border-b-[0.5px] cw-border-solid cw-border-neutral-hover": showBottomBorder,
      })}
    >
      {children}
    </div>
  );
};

const TradeoffHeaderRow = ({ children }: { children: React.ReactNode }) => {
  return <div className="cw-flex cw-items-center cw-mb-1"> {children} </div>;
};

const MoreTradeoffsHidden = ({ additional }: { additional: number }) => {
  return (
    <div className="cw-body-sm cw-text-onEmphasis cw-text-12 cw-font-medium cw-items-center">
      {`+ ${additional} more`}
    </div>
  );
};

type TradeoffProps = {
  tradeoffBlock: TradeoffBlock;
  showBottomBorder: boolean;
  showAttendeeCount: boolean;
};

export const AvailabilityIssue = ({
  tradeoffBlock,
  showBottomBorder,
  showAttendeeCount,
}: TradeoffProps) => {
  const tradeoffs = tradeoffBlock.tradeoffs;
  const attendeeCount = tradeoffBlock.affectedAttendees.length;
  let maxLen = MAX_TRADEOFFS;

  // If one over max, show so that we never show "+1 more"
  if (tradeoffs.length === MAX_TRADEOFFS + 1) {
    maxLen = MAX_TRADEOFFS + 1;
  }

  const moreThanMaxTradeoffs = tradeoffs.length > maxLen;
  const subtext = showAttendeeCount
    ? `⸱ ${attendeeCount} ${pluralize("attendee", attendeeCount)}`
    : undefined;

  return (
    <TradeoffContainer showBottomBorder={showBottomBorder}>
      <TradeoffHeaderRow>
        <EmergencyHomeFilled className="cw-text-[#fec84b] cw-mr-1 cw-w-4 cw-h-4" />
        <TradeoffHeader text={"Conflicts"} subtext={subtext} />
      </TradeoffHeaderRow>
      <div>
        <SchedulingTradeoffsList tradeoffBlock={tradeoffBlock} maxLength={maxLen} />
        {moreThanMaxTradeoffs && (
          <MoreTradeoffsHidden additional={tradeoffs.length - MAX_TRADEOFFS} />
        )}
      </div>
    </TradeoffContainer>
  );
};

export const FixableConflict = ({
  tradeoffBlock,
  showBottomBorder,
  showAttendeeCount,
}: TradeoffProps) => {
  const tradeoffs = tradeoffBlock.tradeoffs;
  const attendeeCount = tradeoffBlock.affectedAttendees.length;
  let maxLen = MAX_TRADEOFFS;

  // If one over max, show so that we never show "+1 more"
  if (tradeoffs.length === MAX_TRADEOFFS + 1) {
    maxLen = MAX_TRADEOFFS + 1;
  }

  const moreThanMaxTradeoffs = tradeoffs.length > maxLen;
  const subtext = showAttendeeCount
    ? `⸱ ${attendeeCount} ${pluralize("attendee", attendeeCount)}`
    : undefined;

  return (
    <TradeoffContainer showBottomBorder={showBottomBorder}>
      <TradeoffHeaderRow>
        <ClockwiseIntelligenceFilled className="cw-text-[#a4bcfd] cw-mr-1 cw-w-4 cw-h-4" />
        <TradeoffHeader text={"Fixable Conflicts"} subtext={subtext} />
      </TradeoffHeaderRow>
      <div>
        <SchedulingTradeoffsList tradeoffBlock={tradeoffBlock} maxLength={maxLen} />
        <div>
          {moreThanMaxTradeoffs && (
            <MoreTradeoffsHidden additional={tradeoffs.length - MAX_TRADEOFFS} />
          )}
        </div>
      </div>
    </TradeoffContainer>
  );
};

export const Inconveniences = ({
  tradeoffBlock,
  showAttendeeCount,
  showBottomBorder,
}: TradeoffProps) => {
  const schedulingTradeoffs = tradeoffBlock.tradeoffs;
  const attendeeCount = tradeoffBlock.affectedAttendees.length;
  let maxLen = MAX_TRADEOFFS;

  // If one over max, show so that we never show "+1 more"
  if (schedulingTradeoffs.length === MAX_TRADEOFFS + 1) {
    maxLen = MAX_TRADEOFFS + 1;
  }

  const moreThanMaxTradeoffs = schedulingTradeoffs.length > maxLen;
  const subtext = showAttendeeCount
    ? `⸱ ${attendeeCount} ${pluralize("attendee", attendeeCount)}`
    : undefined;

  return (
    <TradeoffContainer showBottomBorder={showBottomBorder}>
      <TradeoffHeaderRow>
        <SentimentNeutralFilled className="cw-text-[#f1f1f1] cw-mr-1 cw-w-4 cw-h-4" />
        <TradeoffHeader text={"Inconveniences"} subtext={subtext} />
      </TradeoffHeaderRow>
      <div>
        <SchedulingTradeoffsList tradeoffBlock={tradeoffBlock} maxLength={maxLen} />
        <div>
          {moreThanMaxTradeoffs && (
            <MoreTradeoffsHidden additional={schedulingTradeoffs.length - MAX_TRADEOFFS} />
          )}
        </div>
      </div>
    </TradeoffContainer>
  );
};

export const WorksForEveryone = () => {
  return (
    <div className="cw-flex cw-items-center cw-my-3">
      <CheckCircleFilled className="cw-text-[#b6ff80] cw-mr-1 cw-w-4 cw-h-4" />
      <TradeoffHeader text={"No conflicts"} />
    </div>
  );
};

export const NoIssues = ({
  attendeeCount,
  showAttendeeCount,
}: {
  attendeeCount: number;
  showAttendeeCount: boolean;
}) => {
  const text = showAttendeeCount
    ? `No issues ⸱ ${attendeeCount} ${pluralize("attendee", attendeeCount)}`
    : "No issues";
  return (
    <div className="cw-flex cw-items-center cw-my-3 cw-opacity-50">
      <CheckCircleFilled className="cw-text-[#b6ff80] cw-mr-1 cw-w-4 cw-h-4" />
      <TradeoffHeader text={text} />
    </div>
  );
};

const SchedulingTradeoffsList = ({
  tradeoffBlock,
  maxLength,
}: {
  tradeoffBlock: TradeoffBlock;
  maxLength: number;
}) => {
  return (
    <div className="cw-flex-col">
      {tradeoffBlock.tradeoffs.slice(0, maxLength).map((tradeoff, index) => {
        return (
          <div key={index} className="cw-flex cw-items-center cw-py-1">
            <div className="cw-mr-1.5">
              <ECAttendeeStack
                attendees={tradeoff.attendees}
                maxShown={3}
                borderColorOverride={bg_emphasis}
              />
            </div>
            <div className="cw-flex cw-body-sm cw-text-onEmphasis cw-text-11 cw-font-medium cw-items-center">
              <AIMarkdown text={tradeoff.title.replace("Conflict: ", "")} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
