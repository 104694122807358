import { Duration } from "luxon";
import pluralize from "pluralize";
import React from "react";
import { Card } from "./Card";

export const EventOutsideTimeRange = ({ movableUntil }: { movableUntil: string | null }) => {
  let soonText = "soon";

  if (movableUntil) {
    const eventMovementInHours = Number(Duration.fromISO(movableUntil).toFormat("h"));
    if (eventMovementInHours > 0) {
      const eventMovementSuffix = pluralize("hours", eventMovementInHours);
      soonText = `in the next ${eventMovementInHours} ${eventMovementSuffix}`;
    }
  }

  return (
    <Card title="This event's timing is set">
      Clockwise will not move this event because it begins {soonText} or is in the past.
    </Card>
  );
};
