import { ChromeDialog } from "#webapp/src/components/chrome-dialog";
import { Button } from "@clockwise/design-system";
import { track, TrackingEvents } from "@clockwise/web-commons/src/util/analytics.util";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { fadeDownVariants, fadeInContainerVariants, fadeScaleVariants } from "./animations";

export interface TutorialDialogProps {
  onConfirm: () => void;
  imagePath: string;
  header: string;
  trackingEventType: string;
  visible: boolean;
  confirmText: string;
  onDismiss?: () => void;
  subtitle?: string;
}

export const TutorialDialog = ({
  onConfirm,
  imagePath,
  header,
  trackingEventType,
  onDismiss,
  visible,
  subtitle,
  confirmText,
}: TutorialDialogProps) => {
  const [showTutorial, setShowTutorial] = React.useState(false);

  useEffect(() => {
    if (visible) {
      track(TrackingEvents.TUTORIAL_DIALOG.VIEW, { dialogType: trackingEventType });
    }
    setShowTutorial(visible);
  }, [visible]);

  const onDismissClick = () => {
    setShowTutorial(false);
    track(TrackingEvents.TUTORIAL_DIALOG.DISMISS, { dialogType: trackingEventType });
    if (!!onDismiss) {
      onDismiss();
    }
  };

  const onConfirmClick = () => {
    onConfirm();
    track(TrackingEvents.TUTORIAL_DIALOG.CONFIRM, { dialogType: trackingEventType });
    setShowTutorial(false);
  };

  return (
    <ChromeDialog
      key="settings"
      open={showTutorial}
      scroll="paper"
      maxWidth="md"
      disableBackdropClick
      disableEscapeKeyDown
      PaperProps={{
        classes: {
          rounded: "cw-rounded-large",
          root:
            "cw-border cw-border-solid cw-border-positive-emphasis cw-shadow-none cw-drop-shadow-[2px_2px_0px_rgb(0,63,46)]",
        },
      }}
    >
      <motion.div
        variants={fadeInContainerVariants}
        initial="hidden"
        animate="show"
        className="cw-p-8"
      >
        <img src={imagePath} className="cw-w-full cw-rounded-lg" />
        <div className="cw-pt-6 cw-flex cw-items-center cw-justify-center">
          <div className="cw-flex cw-flex-col cw-items-center cw-w-full">
            <motion.div variants={fadeScaleVariants} className="cw-heading-3xl cw-text-center">
              {header}
            </motion.div>
            {!!subtitle && (
              <motion.div variants={fadeScaleVariants} className="cw-body-lg cw-text-center">
                {subtitle}
              </motion.div>
            )}
            <motion.div
              variants={fadeDownVariants}
              className="cw-flex cw-pt-6 cw-justify-end cw-w-full"
            >
              <div className="cw-px-2">
                <Button
                  sentiment="positive"
                  variant="outlined"
                  onClick={onDismissClick}
                  size="large"
                >
                  Maybe later
                </Button>
              </div>
              <div className="cw-pl-2">
                <Button sentiment="positive" size="large" color="primary" onClick={onConfirmClick}>
                  {confirmText}
                </Button>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </ChromeDialog>
  );
};
