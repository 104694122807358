import { SchedulingProposalStateEnum } from "@clockwise/schema";
import { TrackingEvents } from "@clockwise/web-commons/src/util/analytics.util";
import React from "react";
import { track } from "../../util/analytics.util";
import { GQLProposal } from "../chat/ai-chat/utils/types";
import { ProposalExpandedActive } from "./ProposalExpandedActive";
import { ProposalExpandedInactive } from "./ProposalExpandedInactive";
import { useSetProposalError } from "./hooks/ProposalErrorContext";
import { useSharedProposalContext } from "./hooks/SharedProposalProvider";

type ProposalExpandedProps = {
  sharedProposal: GQLProposal | null;
  ownerCalendarId: string;
  setUpdatedProposal: (proposal: GQLProposal | null) => void;
  triggerConfetti: () => void;
};

export const ProposalExpanded = ({
  sharedProposal,
  ownerCalendarId,
  setUpdatedProposal,
  triggerConfetti,
}: ProposalExpandedProps) => {
  const setError = useSetProposalError();
  const { viewerIsProposalOwner } = useSharedProposalContext();
  const proposalId = sharedProposal?.proposalId || null;

  if (!sharedProposal) {
    return null;
  }

  const proposalIsActive = sharedProposal.state === SchedulingProposalStateEnum.ACTIVE;
  // HACK: For MVP, we are only supporting sharing proposal with only one recipient, and thus only one diff block
  const diff = sharedProposal.diffBlocks[0]?.diffs[0];
  if (diff === null) {
    setError({
      error: new Error("Proposal event not found or not an AddDiffSummary"),
      message: "Proposal event not found or not an AddDiffSummary",
      proposalId: proposalId,
    });
    return null;
  }

  const attendees = diff.attendees?.proposalAttendees.map((attendee) => attendee.attendeePerson);
  const proposalOwner =
    attendees.find((attendee) => attendee.primaryCalendar === ownerCalendarId) || null;
  if (proposalOwner) {
    track(TrackingEvents.SHAREABLE_PROPOSAL.VIEW.RENDERED, { viewerIsProposalOwner });
  } else {
    setError({
      error: new Error("Owner of proposal not found"),
      message: "Owner of proposal not found",
      proposalId: proposalId,
    });
    return null;
  }

  const yourCalDiffs = sharedProposal.consequencesBlock?.yourCalDiffs || [];
  const otherCaldiff = sharedProposal.consequencesBlock?.otherCalDiffs || [];

  return (
    <div
      className="cw-z-40 cw-absolute cw-w-full cw-p-2 cw-pt-5 cw-h-full"
      aria-label="Event Details"
    >
      {proposalIsActive && (
        <ProposalExpandedActive
          proposal={sharedProposal}
          proposalOwner={proposalOwner}
          triggerConfetti={triggerConfetti}
          setUpdatedProposal={setUpdatedProposal}
        />
      )}
      {!proposalIsActive && (
        <ProposalExpandedInactive
          proposalOwner={proposalOwner}
          viewerIsProposalOwner={viewerIsProposalOwner}
          yourCalDiffs={yourCalDiffs}
          otherCalDiffs={otherCaldiff}
          proposalState={sharedProposal.state}
          diff={diff}
        />
      )}
    </div>
  );
};
