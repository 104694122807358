import { Select, SelectOption } from "@clockwise/design-system";
import { NotificationStrategy } from "@clockwise/schema";
import { Notifications } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";
import { useEcosystem } from "../../util/ecosystem";

type Props = {
  enabled: boolean;
  value: NotificationStrategy;
  onChange: (newValue: NotificationStrategy) => void;
};

const NotificationStrategyOption = ({ enabled, value, onChange }: Props) => {
  const ecosystem = useEcosystem();

  return (
    <div className="cw-flex cw-flex-row cw-items-center">
      <Notifications
        fontSize="small"
        className={classNames(enabled ? "" : "cw-text-default-disabled")}
      />
      <Select
        showExpandIcon
        onChange={onChange}
        size="small"
        value={value}
        variant="inline"
        disabled={!enabled}
      >
        <SelectOption value={NotificationStrategy.NotifyInGCal}>
          Notify using {ecosystem === "Google" ? "Google Calendar" : "Outlook"} defaults
        </SelectOption>
        <SelectOption value={NotificationStrategy.DontNotify}>Don't notify</SelectOption>
      </Select>
    </div>
  );
};

export default NotificationStrategyOption;
