import { graphql } from "react-relay";

export const adminPanelOverviewQuery = graphql`
  query AdminPanelOverviewQuery($orgRelayId: ID!, $maxDate: String, $minDate: String) {
    org: node(id: $orgRelayId) {
      ... on Org {
        id
        orgOverview(maxDate: $maxDate, minDate: $minDate) {
          __typename
          ... on GraphEntityError {
            statusCode
            message
          }

          ... on OrgOverview {
            userCount {
              label
              value
            }
            scheduleAssistCount {
              label
              value
            }
            scheduleAssistLimit
            focusTimeHoursCreatedCount {
              label
              value
            }
            conflictsResolvedCount {
              label
              value
            }
            numUsersByRole {
              label
              value
            }
            numUsersByFunction {
              label
              value
            }
          }
        }
      }
    }
  }
`;
