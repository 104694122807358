import { logger } from "@clockwise/client-commons/src/util/logger";
import { BillingGroup, PaymentSubscription, ProductTypeEnum } from "@clockwise/schema";

export function getFullName({ givenName, familyName }: { givenName: string; familyName: string }) {
  if (givenName && familyName) {
    return `${givenName} ${familyName}`;
  }

  return givenName || familyName;
}

export function getSubscriptionFromBillingGroup(billingGroup: BillingGroup) {
  if (!billingGroup.subscription || billingGroup.subscription.__typename === "GraphEntityError") {
    logger.error("failed to getSubscriptionFromBillingGroup");
    return;
  }

  return billingGroup.subscription;
}

export function isSubscriptionProductTypeEnterprise(subscription: PaymentSubscription) {
  return (
    subscription.productType === ProductTypeEnum.Enterprise_NonStandard ||
    subscription.productType === ProductTypeEnum.Enterprise_Standard
  );
}

export function isSubscriptionProductTypeBusiness(subscription: PaymentSubscription) {
  return (
    subscription.productType === ProductTypeEnum.Business_NonStandard ||
    subscription.productType === ProductTypeEnum.Business_Standard
  );
}

export function isSubscriptionProductTypePro(subscription: PaymentSubscription) {
  return (
    subscription.productType === ProductTypeEnum.Pro_NonStandard ||
    subscription.productType === ProductTypeEnum.Pro_Standard
  );
}
export function isSubscriptionNonStandard(subscription: PaymentSubscription) {
  return (
    subscription.productType === ProductTypeEnum.Business_NonStandard ||
    subscription.productType === ProductTypeEnum.Pro_NonStandard ||
    subscription.productType === ProductTypeEnum.Enterprise_NonStandard
  );
}

export function getPersonListCountFromBillingGroup(billingGroup: BillingGroup) {
  if (
    !billingGroup.personListPaginatedErrorable ||
    billingGroup.personListPaginatedErrorable.__typename === "GraphEntityError"
  ) {
    logger.error("failed to get personListPaginatedErrorable");
    return;
  }

  return billingGroup.personListPaginatedErrorable.count;
}
