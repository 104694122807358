//////////////////
// IMPORTS
//////////////////
import { getClient } from "#webapp/src/network/apollo/client";
import { getGatewayClient } from "#webapp/src/network/apollo/gateway-client";
import { AppRouter } from "#webapp/src/routing/AppRouter";
import { ApolloProvider } from "@apollo/client";
import { ClockwiseToaster } from "@clockwise/web-commons/src/components/ClockwiseToaster";
import { GatewayApolloProvider } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { track, TrackingContext } from "@clockwise/web-commons/src/util/analytics.util";
import * as React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Store } from "redux";
import { ModernWrapperPage } from "../modern-wrapper/ModernWrapper";
import DNDWrapperContext from "./DndContext";

export interface Props {
  reduxStore: Store<unknown>;
}

//////////////////
// COMPONENT
//////////////////
// This gives us a single component that our entire app is contained under,
// allowing us to wrap the whole app very easily; e.g. for hot-loading
export const RootModern = ({ reduxStore }: Props) => {
  return (
    <TrackingContext.Provider value={track}>
      <Provider store={reduxStore}>
        <GatewayApolloProvider client={getGatewayClient()}>
          <DNDWrapperContext>
            <ApolloProvider client={getClient()}>
              <BrowserRouter>
                <ModernWrapperPage>
                  <AppRouter />
                </ModernWrapperPage>
                <ClockwiseToaster />
              </BrowserRouter>
            </ApolloProvider>
          </DNDWrapperContext>
        </GatewayApolloProvider>
      </Provider>
    </TrackingContext.Provider>
  );
};
