import { Checkbox, Select, SelectOption } from "@clockwise/design-system";
import { BookingLimitPeriodEnum } from "@clockwise/schema/v2";
import { range } from "lodash";
import pluralize from "pluralize";
import React from "react";

interface Props {
  active: boolean;
  count: number;
  period: BookingLimitPeriodEnum;
  onChange(active: boolean, count: number, period: BookingLimitPeriodEnum): void;
}

export const MaxBookingsRow = ({ active, count, period, onChange }: Props) => {
  const ariaLabel = `Only allow ${count} ${pluralize("booking", count)} per ${
    period === BookingLimitPeriodEnum.Daily ? "day" : "week"
  }`;
  return (
    <div className="cw-flex cw-gap-2 cw-items-center cw-body-lg">
      <Checkbox
        cw-id="max-bookings-checkbox"
        checked={active}
        onChange={(checked) => onChange(checked, count, period)}
        aria-label={ariaLabel}
      />
      Only allow
      <Select
        cw-id="max-bookings-count-dropdown"
        value={count}
        onChange={(value) => onChange(true, value, period)}
      >
        {range(1, 51).map((n) => (
          <SelectOption value={n} key={n} />
        ))}
      </Select>{" "}
      {pluralize("booking", count)} per{" "}
      <Select
        cw-id="max-bookings-period-dropdown"
        value={period}
        onChange={(value) => onChange(true, count, value)}
      >
        <SelectOption value={BookingLimitPeriodEnum.Daily}>day</SelectOption>
        <SelectOption value={BookingLimitPeriodEnum.Weekly}>week</SelectOption>
      </Select>
    </div>
  );
};
