import { UpdateUserFlowStateDocument } from "#webapp/src/graphql/__generated__/UpdateUserFlowState.generated";
import { CurrentFlowStateDocument } from "#webapp/src/hooks/__generated__/CurrentFlowState.generated";
import { FlowStateType, OnboardingFlowState } from "#webapp/src/hooks/useOnboarding/useOnboarding";
import { UserFlags } from "#webapp/src/util/flags.util";
import { useMutation, useQuery } from "@apollo/client";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useFlags } from "@clockwise/web-commons/src/util/FlagsContext";
import { transform } from "@clockwise/web-commons/src/util/transform.util";
import { find } from "lodash";
import { compact, map } from "lodash/fp";
import { useFeatureFlag } from "../../launch-darkly";
import {
  getNextEducationStep,
  mapOfStatesWithUIOverlay,
  NavEducationStep,
} from "./useNuxPopoverStates.util";

const getNavEducationState = (flowState?: FlowStateType) => {
  return (flowState?.current?.state as NavEducationStep) ?? null;
};

export const useNuxPopoverStates = () => {
  const track = useTracking();
  const { setFlag } = useFlags();

  const [onNux2025] = useFeatureFlag("NUX-Q1-2025");
  const { data: flowStateData, loading: loadingFlowStates } = useQuery(CurrentFlowStateDocument);
  const [updateFlowStateUser] = useMutation(UpdateUserFlowStateDocument);

  const userFlowStates = flowStateData?.viewer.user?.flowStates?.edges ?? [];
  const getFlowState = (flowKey: OnboardingFlowState) =>
    transform(userFlowStates, compact, map("node"), (s) => find(s, { flowKey }) ?? null);
  const navEducationCurrentState =
    !loadingFlowStates && onNux2025
      ? getNavEducationState(getFlowState(OnboardingFlowState.NavigationEducation))
      : NavEducationStep.Unstarted;

  const setPopoverComplete = () => {
    const nextStep = getNextEducationStep(navEducationCurrentState);
    track(TrackingEvents.NUX_2025.TOOLTIP_FLOW.TOOLTIP_STEP, {
      oldState: navEducationCurrentState,
      newState: nextStep,
      isOnNux2025: true,
    });
    void updateFlowStateUser({
      variables: {
        input: { newState: nextStep, flowKey: OnboardingFlowState.NavigationEducation },
      },
      onCompleted: () => {
        if (nextStep === NavEducationStep.SuperpowerChecklist) {
          setFlag(UserFlags.NUX.ShouldShowUnvisitedBadges.name, true);
          track(TrackingEvents.NUX_2025.UNVISITED_LINKS.UNVISITED_BADGES_SHOWN);
        }
        if (nextStep === NavEducationStep.Completed) {
          track(TrackingEvents.NUX_2025.TOOLTIP_FLOW.COMPLETED);
        }
      },
    });
  };

  const showAppOverlay = mapOfStatesWithUIOverlay[navEducationCurrentState];

  return {
    navEducationCurrentState,
    setPopoverComplete,
    showAppOverlay,
  };
};
