// schema
// import * as ISchema from 'src/__schema__';

// libraries
import * as React from "react";
import { connect } from "react-redux";

// zoom-button imports
import { styles } from "./ZoomButton.styles";
import { IContainer, IProps, IState } from "./ZoomButtonTypes";

// state
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";

// util
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import {
  generateZoomAccountLevelAuthUrl,
  generateZoomUserLevelAuthUrl,
} from "#webapp/src/util/third-party-oauth.util";

// material-ui
// import { Button } from '@material-ui/Button';
import withStyles from "@material-ui/core/styles/withStyles";

///////////////
// Component //
///////////////
class ZoomButtonCmpt extends React.Component<IProps, IState> {
  private popup: any;
  private popupInterval: ReturnType<typeof setInterval>;

  constructor(props: IProps) {
    super(props);
  }

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-
  public componentWillUnmount() {
    if (this.popupInterval) {
      clearInterval(this.popupInterval);
    }
  }

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  private addZoomUserLevel = () => {
    const url = generateZoomUserLevelAuthUrl();
    this.popup = window.open(url, "_blank", "width=650,height=780");
    track(TrackingEvents.ZOOM.PERSONAL_BEGIN_ADD);

    // detect when the popup is closed
    clearInterval(this.popupInterval);
    this.popupInterval = setInterval(() => {
      if (this.popup && this.popup.closed) {
        track(TrackingEvents.ZOOM.PERSONAL_OAUTH_WINDOW_CLOSED);

        // it was closed, refetch
        clearInterval(this.popupInterval);
        this.props.onAuthPopupClosed();
      }
    }, 500);
  };

  private addZoomAccountLevel = () => {
    const url = generateZoomAccountLevelAuthUrl();
    this.popup = window.open(url, "_blank", "width=650,height=780");
    track(TrackingEvents.ZOOM.BEGIN_ADD);

    // detect when the popup is closed
    clearInterval(this.popupInterval);
    this.popupInterval = setInterval(() => {
      if (this.popup && this.popup.closed) {
        track(TrackingEvents.ZOOM.OAUTH_WINDOW_CLOSED);

        // it was closed, refetch
        clearInterval(this.popupInterval);
        this.props.onAuthPopupClosed();
      }
    }, 500);
  };

  private addZoom(event: any) {
    event.preventDefault();

    if (this.props.isZoomUserLevelAuth) {
      this.addZoomUserLevel();
    } else {
      this.addZoomAccountLevel();
    }
  }

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  private renderDefault = () => {
    return (
      <div>
        <a
          href=""
          onClick={(event: any) => {
            this.addZoom(event);
            if (this.props.onClick) {
              this.props.onClick();
            }
          }}
        >
          <img
            src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
            height="32"
            alt="Add to ZOOM"
          />
        </a>
      </div>
    );
  };

  private renderCustom = (ButtonCmpt: JSX.Element) => {
    return React.cloneElement(ButtonCmpt, {
      onClick: (e: any) => {
        this.addZoom(e);
        if (this.props.onClick) {
          this.props.onClick();
        }
      },
      key: "zoomButtonCustom",
    });
  };

  public render() {
    const { target } = this.props;

    if (target) {
      return this.renderCustom(target);
    } else {
      return this.renderDefault();
    }
  }
}

/////////////////
// With Styles //
/////////////////
export const ZoomButtonStyled = withStyles(styles)(ZoomButtonCmpt);

///////////
// Redux //
///////////
function mapStateToProps(_state: IReduxState, _ownProps: IContainer) {
  return {};
}

export const ZoomButton = connect(mapStateToProps, {})(ZoomButtonStyled);
