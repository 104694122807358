import { ALL_WORK_DAYS_SELECTED } from "#webapp/src/components/event-card/types";
import { MENU_LABELS } from "#webapp/src/components/flexible-meetings/flex-event-table/FlexEventMenu";
import { EventForFlexibility } from "#webapp/src/components/flexible-meetings/util/parseFlexibility";
import { useUserProfile } from "#webapp/src/components/hooks/useUserProfile";
import { PopperWrapper } from "#webapp/src/components/web-app-calendar/calendar-popover/PopperWrapper";
import { useQuery } from "@apollo/client";
import { frequencyToRecurrenceTextMap } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { TranslationContext } from "@clockwise/client-commons/src/providers/TranslationContext";
import { Switch, Tooltip } from "@clockwise/design-system";
import { Info } from "@clockwise/icons";
import { FlexRange } from "@clockwise/schema/v2";
import { EventTableRow } from "@clockwise/web-commons/src/components/EventTable";
import { FlexSettingsModule } from "@clockwise/web-commons/src/components/module-flex-settings/FlexSettingsModule";
import { useAttendeeWorkingBounds } from "@clockwise/web-commons/src/hooks/useAttendeeWorkingHours";
import { DayOnOffMap } from "@clockwise/web-commons/src/ui/working-hours";
import {
  convertArrayOfDayWeekToDayMap,
  convertDayMapToFlexRangeAndAllowedDays,
} from "@clockwise/web-commons/src/util/dayMap";
import { getSimplifiedStatus } from "@clockwise/web-commons/src/util/getFlexibleStatusSimplifed";
import { ExpandMore } from "@material-ui/icons";
import classNames from "classnames";
import { noop } from "lodash";
import { DateTime } from "luxon";
import React, { useState } from "react";

export const OnboardingFlexEventRow = ({
  onFlexibilityChange = noop,
  showNotRecommededBadge = false,
  columnSizing,
  event,
  onFlexEventChange,
}: {
  onFlexibilityChange?: (eventId: string, isFlexible: boolean) => void;
  showNotRecommededBadge?: boolean;
  columnSizing?: (string | null)[];
  event: EventForFlexibility;
  onFlexEventChange: (event: EventForFlexibility) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { userProfile } = useUserProfile();

  const {
    start: attendeeBoundStart,
    end: attendeeBoundEnd,
    conflict: attendeeBoundConflict,
  } = useAttendeeWorkingBounds({
    eventId: event.eventId,
    calendarId: userProfile.primaryCalendar || "",
    calendarIds:
      event.attendees.map((attendee) => attendee?.person?.email?.toLocaleLowerCase()) || "",
    useQuery: useQuery,
  });

  const { recurrenceType, eventId, isFlexible, recurrenceText, title } = event;
  const recurrenceTypeText = recurrenceType
    ? frequencyToRecurrenceTextMap[recurrenceType]
    : "Never";

  const translation = React.useContext(TranslationContext);
  const tooltipText = translation("autopilot-form:toggle-tooltip", { on: !!isFlexible });

  const handleFlexibilityChange = (isFlexible: boolean) => {
    onFlexibilityChange(eventId, isFlexible);
  };

  const handleChangeFlexibleRange = (newRange: FlexRange) => {
    const eventToUpdate = { ...event };
    eventToUpdate.flexRange = newRange;
    eventToUpdate.allowedDays = [];
    onFlexEventChange(eventToUpdate);
  };

  const handleChangeFlexibleDays = (dayOnOffMap: DayOnOffMap) => {
    const eventToUpdate = { ...event };
    const { flexRange, allowedDays } = convertDayMapToFlexRangeAndAllowedDays(dayOnOffMap);
    eventToUpdate.flexRange = flexRange;
    eventToUpdate.allowedDays = flexRange === FlexRange.SpecificDays ? allowedDays : [];
    onFlexEventChange(eventToUpdate);
  };

  const handleChangeFlexibleTimeRange = (start: string, end: string) => {
    const eventToUpdate = { ...event };
    eventToUpdate.timeOfDayFlexRange = { start, end };
    onFlexEventChange(eventToUpdate);
  };

  const onOpenCard = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const onCloseCard = () => {
    setAnchorEl(null);
  };

  const dayStart = event.timeOfDayFlexRange?.start ?? "09:00"; //?? attendeeBoundStart
  const dayEnd = event.timeOfDayFlexRange?.end ?? "17:00"; // ?? attendeeBoundEnd
  const timeStartReadable = DateTime.fromFormat(dayStart, "HH:mm")
    .toFormat("h:mma")
    .toLocaleLowerCase();
  const timeEndReadable = DateTime.fromFormat(dayEnd, "HH:mm")
    .toFormat("h:mma")
    .toLocaleLowerCase();
  const timeRange = ` ⸱ ${timeStartReadable}-${timeEndReadable}`;
  const flexibleStatus = getSimplifiedStatus(event.status, attendeeBoundConflict);
  const flexibleDayOnOffMap = convertArrayOfDayWeekToDayMap(
    event.allowedDays,
    event.flexRange,
    ALL_WORK_DAYS_SELECTED,
  );

  return (
    <EventTableRow columnSizing={columnSizing}>
      <div className="cw-flex cw-gap-2 cw-items-center">
        <div className="cw-font-semibold cw-truncate cw-body-sm">{title}</div>
        {showNotRecommededBadge && (
          <Tooltip title={"Flexible meetings work best for meetings with 5 attendees or less"}>
            <div className="cw-text-[10px] cw-font-semibold cw-px-1 cw-text-nowrap cw-rounded-md cw-text-onEmphasis cw-bg-warning-emphasis cw-min-w-fit">
              Not recommended
            </div>
          </Tooltip>
        )}
      </div>
      <div className="cw-truncate cw-body-sm cw-flex cw-gap-1 cw-items-center">
        {recurrenceTypeText}
        {recurrenceText && (
          <Tooltip title={recurrenceText}>
            <Info className="cw-w-4 cw-h-4 cw-text-muted" />
          </Tooltip>
        )}
      </div>

      <div className="cw-flex cw-gap-3">
        <Tooltip title={tooltipText}>
          <Switch checked={isFlexible} onChange={handleFlexibilityChange} size="small" />
        </Tooltip>
        {isFlexible ? (
          <>
            <button
              onClick={onOpenCard}
              disabled={!event.isFlexible}
              className="cw-body-sm cw-text-11 cw-flex-1 cw-max-w-[200px] cw-cursor-pointer cw-font-medium cw-border-none cw-rounded-full cw-pl-2 cw-pr-1 cw-h-5 cw-bg-neutral-inset pressed:cw-bg-neutral-inset-pressed hover:cw-bg-neutral-inset-pressed"
            >
              <div className="cw-block cw-truncate cw-w-full">
                <div className="cw-flex cw-items-center cw-justify-between cw-gap-0.5">
                  <div className="cw-flex cw-items-center cw-gap-1">{`${
                    MENU_LABELS[event.flexRange]
                  }${timeRange}`}</div>
                  <ExpandMore className="cw-h-[14px] cw-w-[14px]" aria-hidden="true" />
                </div>
              </div>
            </button>
            {anchorEl && (
              <PopperWrapper
                anchorEl={anchorEl}
                placement="bottom-end"
                customWidth={210}
                onClose={onCloseCard}
              >
                <div
                  cw-id="event-flexibility-page-popover"
                  className={classNames("cw-overflow-auto cw-body-sm cw-text-12 cw-py-4 cw-px-3")}
                >
                  <FlexSettingsModule
                    flexible={event.isFlexible}
                    flexibleDayOnOffMap={flexibleDayOnOffMap}
                    flexibleEditable={true}
                    flexibleEnd={dayEnd}
                    flexibleRange={event.flexRange}
                    flexibleStart={dayStart}
                    updating={false}
                    flexibleStatus={flexibleStatus}
                    onChangeFlexibleDays={handleChangeFlexibleDays}
                    onChangeFlexibleRange={handleChangeFlexibleRange}
                    onChangeFlexibleResume={noop} // Should never be called, we dont show paused events
                    onChangeFlexibleTimeRange={handleChangeFlexibleTimeRange}
                    attendeeBoundStart={attendeeBoundStart}
                    attendeeBoundEnd={attendeeBoundEnd}
                  />
                </div>
              </PopperWrapper>
            )}
          </>
        ) : (
          <span className="cw-text-muted cw-font-medium cw-body-sm">Mark as Flexible</span>
        )}
      </div>
    </EventTableRow>
  );
};
