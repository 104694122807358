import { Button } from "@clockwise/design-system";
import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar";
import { CalendarEvent } from "@clockwise/web-commons/src/components/calendar/calendar-event/CalendarEvent";
import { ICalPositionable } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import {
  EventType,
  useReadActiveEvent,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import classNames from "classnames";
import { includes } from "lodash";
import { DateTime, Interval } from "luxon";
import React, { useMemo } from "react";
import { useUpdateCurrentProposal } from "../../chat-plus-calendar/CurrentProposalContext";
import { useUpdateActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import { useReadCalendarColorForCalendarId } from "../hooks/CalendarColorsContext";
import { usePrefetchEventDetails } from "../hooks/useGatewayEventDetails";
import { ALLDAY_MAX_HEIGHT } from "../single-day-head/SingleDayHead";
import { PlannerEventCard } from "../types";
import { getCalendarEventStatus } from "../util/getCalendarEventStatus";
import { hasCategoryOutOfOffice } from "../util/hasCategoryOutOfOffice";

export const AllDayEventColumn = ({
  events,
  minWidth,
  columnCount,
  calendarId,
  dateTime,
  index,
  toggleExpanded,
  showEvents,
}: {
  events: PlannerEventCard[];
  minWidth: number;
  columnCount: number;
  calendarId: string;
  dateTime: DateTime;
  index: number;
  toggleExpanded: () => void;
  showEvents: boolean;
}) => {
  const positionables: ICalPositionable[] = useMemo(
    () => [
      {
        key: `${calendarId}-single-day-head`,
        interval: Interval.fromDateTimes(
          dateTime.startOf("day"),
          dateTime.startOf("day").plus({ hours: 2 }),
        ),
        render: () => (
          <div
            className={classNames(
              "cw-flex cw-flex-col cw-flex-1 cw-min-w-0 cw-items-center cw-flex-wrap cw-relative",
              "cw-w-full cw-overflow-y-scroll",
              "cw-scrollbar-none",
            )}
            key={calendarId}
            style={{ maxHeight: ALLDAY_MAX_HEIGHT }}
          >
            <div className="cw-flex cw-items-center cw-justify-center cw-flex-wrap cw-w-full cw-pt-1">
              {showEvents ? (
                <AllDayEventStack events={events} primaryCalendarId={calendarId} />
              ) : (
                <div className="cw-flex cw-items-center cw-px-1 cw-pt-0.5 cw-pl-[2.5%] cw-w-full">
                  {events.length > 0 && (
                    <Button
                      size="xsmall"
                      fullWidth
                      variant="text"
                      onClick={toggleExpanded}
                    >{`${events.length} more`}</Button>
                  )}
                </div>
              )}
            </div>
          </div>
        ),
      },
    ],
    [showEvents, events],
  );

  return (
    <CalendarPositioner
      dateTimes={[dateTime]}
      positionables={positionables}
      columnIndexOverride={index}
      columnCountOverride={columnCount}
      minWidth={minWidth}
      gutters
    />
  );
};

const AllDayEventStack = ({
  events,
  primaryCalendarId,
}: {
  events: PlannerEventCard[];
  primaryCalendarId: string;
}) => {
  return (
    <div className="cw-flex cw-flex-col cw-w-full cw-gap-y-1 cw-py-1">
      {events.map((event) => (
        <AllDayEvent event={event} primaryCalendarId={primaryCalendarId} key={event.key} />
      ))}
    </div>
  );
};

const AllDayEvent = ({
  event,
  primaryCalendarId,
}: {
  event: PlannerEventCard;
  primaryCalendarId: string;
}) => {
  const track = useTracking();
  const isOwnEvent = includes(event.calendarIds, primaryCalendarId);
  const calendarColorSet = useReadCalendarColorForCalendarId(event.calendarIds[0], isOwnEvent);

  const [prefetchEventDetails] = usePrefetchEventDetails();
  const { selectExistingEvent } = useUpdateCurrentProposal();

  const activeEvent = useReadActiveEvent();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateActiveEventDiff = useUpdateActiveEventDiff();

  const isOOOevent = hasCategoryOutOfOffice({ eventCategory: event.eventCategory });
  const canDisplayProposalForEvent =
    !event.locked &&
    !isOOOevent &&
    !event.smartHold &&
    event.type !== EventType.WorkingLocation &&
    event.type !== EventType.PersonalCalendarSync;

  const onClick = () => {
    if (!event.externalEventId || activeEvent?.type === EventType.Proposal) {
      return;
    }

    const calendarId = event.calendarIds[0] ?? primaryCalendarId;

    if (canDisplayProposalForEvent) {
      track(TrackingEvents.DIRECT_MANIPULATION.CALENDAR.CLICK_TO_EXPAND, {
        externalEventId: event.externalEventId,
        calendarId,
      });

      selectExistingEvent(event.externalEventId, calendarId);
      updateActiveEventDiff(null);
      updateActiveEvent({ externalEventId: "", calendarId: "", type: EventType.Proposal });
    } else {
      updateActiveEvent({
        calendarId,
        externalEventId: event.externalEventId,
        type: EventType.Event,
      });
    }
  };

  return (
    <div
      aria-hidden
      onMouseOver={() => {
        if (event.externalEventId && event.calendarIds[0]) {
          void prefetchEventDetails({
            variables: { externalEventId: event.externalEventId, calendarId: event.calendarIds[0] },
          });
        }
      }}
    >
      <CalendarEvent
        active={false}
        annotation={undefined}
        badge={undefined}
        calendarColorSet={calendarColorSet}
        deemphasis={event.deemphasis}
        eventColorSet={isOwnEvent ? event.eventCategoryColor : undefined}
        onClick={onClick}
        status={getCalendarEventStatus(event)}
        pulse={event.pulse}
        spacing="Full"
        text={event.text}
        variant="SingleLine"
      />
    </div>
  );
};
