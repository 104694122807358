import React from "react";
import { Header, SlideLayout, Text } from "../../shared-components";
import SlackAI from "./assets/slackai.png";

export const SlackSlide = () => {
  return (
    <SlideLayout>
      <Header> Download Clockwise for Slack </Header>
      <Text>
        Harness the power of an intelligent calendar assistant, delivered right where you and your
        teammates are.
      </Text>
      <div className="cw-my-6 cw-w-full cw-flex cw-items-center cw-justify-center">
        <img src={SlackAI} className="cw-w-full md:cw-w-[640px] md:cw-h-[400px]" />
      </div>
    </SlideLayout>
  );
};
