import { ApolloCache, ApolloError, NormalizedCacheObject } from "@apollo/client";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { Button, Link } from "@clockwise/design-system";
import { Close, Delete, Edit } from "@clockwise/icons";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import classNames from "classnames";
import { filesize } from "filesize";
import React, { ReactNode, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { SchedulingLinksQueryDocument } from "../__generated__/SchedulingLinksQuery.v2.generated";
import {
  DeleteBillingGroupLogoDocument,
  DeleteLogoDocument,
  UploadBillingGroupLogoDocument,
  UploadLogoDocument,
} from "./__generated__/LogoUpload.v2.generated";
import { useImageDimensions } from "./useImageDimensions";

/**
 * Returns a human-readable error message for a failed logo upload.
 */
const getUploadErrorMessage = (error?: ApolloError) => {
  if (!error) {
    return;
  }
  if (error.networkError) {
    return "Upload failed due to a network error. Make sure your internet connection is stable and try again.";
  } else {
    logger.error("Logo upload failure:", error);
    return `Error uploading logo: ${error.message}`;
  }
};

/**
 * Returns a human-readable error message for a failed logo upload.
 */
const getDeleteErrorMessage = (error?: ApolloError) => {
  if (!error) {
    return;
  }
  if (error.networkError) {
    return "Failed to remove logo due to a network error. Make sure your internet connection is stable and try again.";
  } else {
    logger.error("Logo delete failure:", error);
    return `Error removing logo: ${error.message}`;
  }
};

const invalidateCachedLogos = (cache: ApolloCache<NormalizedCacheObject>) => {
  // Since the logo url is a field on PublicSchedulingLink, we need to evict
  // publicSchedulingLink and linkBooking. This ensures the latest logo url will be fetched if
  // the current user navigates to the booking page after uploading a new logo.
  cache.evict({ fieldName: "publicSchedulingLink" });
  cache.evict({ fieldName: "linkBooking" });
  cache.gc();
};

interface PersonalLogoUploadProps {
  url?: string | null;
}

/**
 * Uploads and preview a custom company logo for Scheduling links using react-dropzone.
 */
export const PersonalLogoUpload = ({ url: currentLogoUrl }: PersonalLogoUploadProps) => {
  const [
    deleteLogo,
    { loading: deleting, error: deleteError },
  ] = useGatewayMutation(DeleteLogoDocument, { update: invalidateCachedLogos });
  const [
    uploadLogo,
    { loading: uploading, error: uploadError },
  ] = useGatewayMutation(UploadLogoDocument, { update: invalidateCachedLogos });

  const onUploadLogo = async (file: File) => {
    await uploadLogo({ variables: { logoImage: file } });
  };

  const onDeleteLogo = async () => {
    await deleteLogo();
  };

  return (
    <LogoUploadWithHandlers
      logoUrl={currentLogoUrl}
      onUpload={onUploadLogo}
      uploading={uploading}
      uploadError={uploadError}
      onDelete={onDeleteLogo}
      deleting={deleting}
      deleteError={deleteError}
    />
  );
};
interface BillingGroupLogoUploadProps {
  billingGroupId: string;
  url?: string | null;
}

export const BillingGroupLogoUpload = ({
  billingGroupId,
  url: currentLogoUrl,
}: BillingGroupLogoUploadProps) => {
  const [deleteLogo, { loading: deleting, error: deleteError }] = useGatewayMutation(
    DeleteBillingGroupLogoDocument,
    {
      update: invalidateCachedLogos,
      refetchQueries: [{ query: SchedulingLinksQueryDocument }],
    },
  );
  const [uploadLogo, { loading: uploading, error: uploadError }] = useGatewayMutation(
    UploadBillingGroupLogoDocument,
    {
      update: invalidateCachedLogos,
      refetchQueries: [{ query: SchedulingLinksQueryDocument }],
    },
  );

  const onUploadLogo = async (file: File) => {
    await uploadLogo({ variables: { logoImage: file, billingGroupId } });
  };

  const onDeleteLogo = async () => {
    await deleteLogo({ variables: { billingGroupId } });
  };

  return (
    <LogoUploadWithHandlers
      logoUrl={currentLogoUrl}
      onUpload={onUploadLogo}
      uploading={uploading}
      uploadError={uploadError}
      onDelete={onDeleteLogo}
      deleting={deleting}
      deleteError={deleteError}
    />
  );
};

interface LogoUploadWithHandlersProps {
  logoUrl?: string | null;

  onUpload: (file: File) => Promise<void>;
  uploading?: boolean;
  uploadError?: ApolloError;

  onDelete: () => Promise<void>;
  deleting?: boolean;
  deleteError?: ApolloError;
}
const LogoUploadWithHandlers = ({
  logoUrl: currentLogoUrl,
  onUpload,
  uploading = false,
  uploadError,
  onDelete,
  deleting = false,
  deleteError,
}: LogoUploadWithHandlersProps) => {
  // If we don't have a logo url yet, start in edit mode
  // When true, this shows the file upload instructions instead of a preivew of the existing logo
  const [editing, setEditing] = useState(!currentLogoUrl);

  const [newLogoFile, setNewLogoFile] = useState<File>();
  const [fileDropError, setFileDropError] = useState<string | undefined>();

  const newLogoUrl = useMemo(() => {
    return newLogoFile && URL.createObjectURL(newLogoFile);
  }, [newLogoFile]);

  const newLogoDimensions = useImageDimensions(newLogoFile);
  const currentLogoDimensions = useImageDimensions(currentLogoUrl);

  const stopEditing = () => {
    setEditing(false);
    setNewLogoFile(undefined);
    setFileDropError(undefined);
  };

  const handleFileChange = (file?: File) => {
    setEditing(true);
    setNewLogoFile(file);
    setFileDropError(undefined);
  };
  const handleFileReject = ({ errors }: FileRejection) => {
    setNewLogoFile(undefined);
    setFileDropError(errors.map(({ message }) => `${message}`).join(", ") || "File error");
  };

  const handleDeleteLogo = async () => {
    await onDelete();
    toast.success("Logo removed!");
  };

  const handleUploadLogo = async () => {
    if (newLogoFile) {
      await onUpload(newLogoFile);
    }
    stopEditing();
    toast.success("Logo uploaded successfully!");
  };

  const errorMessage =
    fileDropError || getUploadErrorMessage(uploadError) || getDeleteErrorMessage(deleteError);

  let imageDetails: ReactNode;
  if (editing) {
    imageDetails = newLogoFile && (
      <p className="cw-caption cw-text-subtle cw-text-center">
        {newLogoFile.name} ({filesize(newLogoFile.size, { standard: "jedec" })}){" "}
        {newLogoDimensions && `${newLogoDimensions.width}px x ${newLogoDimensions.height}px`}
      </p>
    );
  } else {
    imageDetails = currentLogoUrl && (
      <p className="cw-caption cw-text-subtle cw-text-center">
        Current logo{" "}
        {currentLogoDimensions &&
          `${currentLogoDimensions.width}px x ${currentLogoDimensions.height}px`}
      </p>
    );
  }

  const activeUrl = editing ? newLogoUrl : currentLogoUrl;
  const busy = uploading || deleting;

  return (
    <div className="cw-flex cw-flex-col cw-gap-2 cw-items-stretch">
      <LogoDropZone
        onChange={handleFileChange}
        onReject={handleFileReject}
        showPreview={!!activeUrl}
      >
        <div className="cw-absolute cw-right-2 cw-top-2">
          {editing ? (
            <Button
              aria-label="Cancel editing logo"
              variant="text"
              size="mini"
              startIcon={Close}
              onClick={() => setNewLogoFile(undefined)}
              rounded
              disabled={busy}
            />
          ) : (
            <div className="cw-flex cw-flex-nowrap cw-items-center cw-gap-2">
              <Button
                aria-label="Change logo"
                variant="text"
                size="mini"
                startIcon={Edit}
                onClick={() => setEditing(true)}
                disabled={busy}
                rounded
              />
              <Button
                aria-label="Remove logo"
                variant="text"
                sentiment="destructive"
                size="mini"
                startIcon={Delete}
                onClick={handleDeleteLogo}
                disabled={busy}
                rounded
              />
            </div>
          )}
        </div>
        <div className="cw-flex cw-flex-col cw-items-center cw-gap-2">
          {activeUrl && (
            <img
              className="cw-w-[300px] cw-h-[150px] cw-object-contain"
              src={activeUrl}
              alt={editing ? "Uploaded logo" : "Current booking logo"}
            />
          )}
        </div>
      </LogoDropZone>

      {/* Image details */}
      <div className="">
        {imageDetails}
        {errorMessage && <p className="cw-body-sm cw-text-destructive">{errorMessage}</p>}
      </div>

      {editing && (
        <div className="cw-self-end cw-flex cw-gap-2">
          <Button
            variant="text"
            sentiment="positive"
            onClick={handleUploadLogo}
            disabled={!newLogoFile || busy}
          >
            Save logo
          </Button>
          {currentLogoUrl && (
            <Button variant="text" sentiment="neutral" onClick={stopEditing} disabled={busy}>
              Cancel
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

const MAX_LOGO_SIZE = 3 * 2 ** 20; // 3MB

interface LogoDropProps {
  // If true, show children instead of the instructions
  showPreview: boolean;
  onChange: (file?: File) => void;
  onReject: (fileRejection: FileRejection) => void;
  children: ReactNode;
}
/**
 * File drag and drop component for uploading a logo
 */
const LogoDropZone = ({ showPreview, onChange, onReject, children }: LogoDropProps) => {
  const { open: openFileDialog, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    maxSize: MAX_LOGO_SIZE,
    noClick: true,
    onDropAccepted: (acceptedFiles) => {
      onChange(acceptedFiles[0]);
    },
    onDropRejected: (rejectedFiles) => {
      onReject(rejectedFiles[0]);
    },
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className={classNames(
          "cw-relative cw-flex cw-flex-col cw-items-center cw-justify-center",
          "cw-p-8",
          "cw-border-default cw-border cw-border-dashed cw-rounded-lg",
          {
            "cw-bg-default": !isDragActive,
            "cw-bg-default-hover hover:cw-bg-default-pressed": isDragActive,
          },
        )}
      >
        {showPreview ? (
          children
        ) : (
          <div className="cw-max-w-[250px] cw-text-center cw-flex cw-flex-col cw-gap-5">
            <p className="cw-body-sm cw-text-subtle">
              Drag and drop or <Link onClick={openFileDialog}>click here</Link> to upload your
              company logo
            </p>
            <p className="cw-body-sm cw-text-subtle">
              The file should be .png or .jpg, and have dimensions of at least 250 x 150
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
