import { DiffActionTypeEnum } from "@clockwise/schema";

export const BULK_PROPOSAL_ON_SHOW_CALENDAR_LIMIT = 5;
export const START_TOGGLE_ON_CASES = [DiffActionTypeEnum.ADD, DiffActionTypeEnum.RESCHEDULE];

export const calculateStartingToggleIsOn = (
  isSBM: boolean,
  isOnSplitViewDefault: boolean,
  actionType: DiffActionTypeEnum,
  totalNumberOfAttendeesOnAllDiffs: number,
  isConsequenceBlock: boolean,
) => {
  if (isConsequenceBlock) {
    return false;
  }
  return isOnSplitViewDefault
    ? true
    : !isSBM &&
        START_TOGGLE_ON_CASES.includes(actionType) &&
        totalNumberOfAttendeesOnAllDiffs <= BULK_PROPOSAL_ON_SHOW_CALENDAR_LIMIT;
};
