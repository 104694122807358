import { Tooltip } from "@clockwise/design-system";
import { Info } from "@clockwise/icons";
import React from "react";

export const UnknownBoundsMessage = () => {
  return (
    <div className="cw-text-muted cw-text-[12px] cw-flex cw-flex-row cw-items-center cw-gap-1">
      <div>No working hours set</div>
      <Tooltip title="One or more attendees' have not set their working hours. Clockwise is unable to find the overlap between the overlapping working hours.">
        <Info className="cw-w-3.5 cw-h-3.5" />
      </Tooltip>
    </div>
  );
};
