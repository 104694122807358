import classNames from "classnames";
import React from "react";
import { useFeatureFlag } from "../../../../launch-darkly";
import { DebugBlock } from "../../web/DebugBlock";
import { useAIMessageContext } from "../hooks/AIMessageContext";
import { parseLLMFromDebugMessage } from "../utils/parseLLMFromDebugMessage";
import { AssistantMessageType } from "../utils/types";
import { ChatDone } from "./ChatDone";
import { TextResponse } from "./TextResponse";
import { ViewEventsResponse } from "./ViewEventsResponse";
import { ProposalResponse } from "./proposal";
import { SchedulingLinksResponse } from "./scheduling-link/SchedulingLinksResponse";

export const AssistantMessage = ({
  debugInfo,
  isLastMessage,
  message,
}: {
  debugInfo?: string;
  isLastMessage: boolean;
  message: AssistantMessageType;
}) => {
  const { processingMessage, confirmingMessage } = useAIMessageContext();
  const [isOnShowChatDebugInfo] = useFeatureFlag("ShowChatDebugInfo");

  const messageTypeNeedsDone =
    (message.__typename === "TextResponse" && message.text !== "Confirming...") ||
    message.__typename === "ViewEventsResponse" ||
    message.__typename === "SchedulingLinksResponse";

  const returnMessageByType = (assistantMessage: AssistantMessageType) => {
    const messageType = assistantMessage.__typename;
    const currentLLMUsed: string = debugInfo ? parseLLMFromDebugMessage(debugInfo) : "";

    switch (messageType) {
      case "TextResponse":
        return <TextResponse message={assistantMessage} />;
      case "ProposalResponse":
        return <ProposalResponse message={assistantMessage} currentLLMUsed={currentLLMUsed} />;
      case "ViewEventsResponse":
        return <ViewEventsResponse message={assistantMessage} />;
      case "SchedulingLinksResponse":
        return <SchedulingLinksResponse message={assistantMessage} />;
    }
  };
  const loading =
    (processingMessage || confirmingMessage) && message.__typename === "ProposalResponse";

  return (
    <div cw-id="assisstant-message" className="cw-flex-col">
      <div className={classNames("cw-w-full cw-body-sm cw-text-13", { "cw-opacity-60": loading })}>
        {returnMessageByType(message)}
      </div>
      {isLastMessage && messageTypeNeedsDone && !loading && !confirmingMessage && (
        <ChatDone
          conversationId={message.conversationId}
          currentLLMUsed={debugInfo ? parseLLMFromDebugMessage(debugInfo) : "unknown"}
          msgId={message.id}
          proposalId=""
        />
      )}
      {debugInfo && isOnShowChatDebugInfo && <DebugBlock debugInfo={debugInfo} />}
    </div>
  );
};
