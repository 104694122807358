import { Menu, MenuItem } from "@clockwise/design-system";
import React from "react";

export type Transparency = "opaque" | "transparent" | "tentative";

type Props = {
  disabled?: boolean;
  selected?: Transparency;
  onSelect?: (transparency: Transparency) => void;
};

const getLabel = (transparency: Transparency) => {
  switch (transparency) {
    case "transparent":
      return "Free";
    case "tentative":
      return "Tentative";
    default:
      return "Busy";
  }
};

export const SelectTransparency = ({ disabled = false, selected = "opaque", onSelect }: Props) => {
  return (
    <Menu disabled={disabled} fullWidth size="small" label={getLabel(selected)}>
      <MenuItem onClick={() => onSelect?.("opaque")}>Busy</MenuItem>
      <MenuItem onClick={() => onSelect?.("transparent")}>Free</MenuItem>
      <MenuItem onClick={() => onSelect?.("tentative")}>Tentative</MenuItem>
    </Menu>
  );
};
