import { Loader } from "@clockwise/design-system/src/components/Loader";
import {
  CheckCircleFilled,
  ClockwiseIntelligenceFilled,
  EmergencyHomeFilled,
  SentimentNeutralFilled,
} from "@clockwise/icons";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { SecondaryCard } from ".";
import { Item } from "./Item";
import { ProposalRenderSurface } from "./types";

export const Break: React.FC<{
  type: SecondaryCard | "worksForEveryone";
  variant?: ProposalRenderSurface;
  loading?: boolean;
  children: ReactNode;
  spacing?: "Default" | "Compact";
}> = ({ children, type, variant = "AI_CHAT", loading = false, spacing = "Default" }) => {
  if (!children) {
    return null;
  }

  let Icon = null;
  switch (type) {
    case "sbm":
      Icon = <ClockwiseIntelligenceFilled className="cw-h-4 cw-w-4 cw-text-busy" />;
      break;
    case "unavailable":
      Icon = <EmergencyHomeFilled className="cw-text-warning-muted cw-h-4 cw-w-4" />;
      break;
    case "inconvenience":
      Icon = <SentimentNeutralFilled className="cw-text-muted cw-h-4 cw-w-4" />;
      break;
    case "worksForEveryone":
      Icon = <CheckCircleFilled className="cw-h-4 cw-w-4 cw-text-positive" />;
      break;
  }

  if (loading) {
    Icon = <Loader size={16} sentiment="busy" className="cw-h-4 cw-w-4 cw-text-busy" />;
  }

  return (
    <>
      <Item
        spacing={spacing}
        alignTop
        left={
          <div
            className={classNames(
              "cw-h-6 cw-rounded-full cw-flex cw-items-center cw-justify-center",
              {
                "cw-bg-neutral": variant === "AI_CHAT",
                "cw-bg-default": variant !== "AI_CHAT",
                "cw-w-8": spacing === "Default",
                "cw-w-7": spacing === "Compact",
              },
            )}
          >
            {Icon}
          </div>
        }
        right={
          <div className="cw-body-sm cw-font-medium cw-flex cw-flex-col cw-gap-0.5">{children}</div>
        }
      />
    </>
  );
};
