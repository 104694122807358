import { getFullName, Profile } from "@clockwise/web-commons/src/util/profile.util";

type ScopedAttendee = {
  [k: string]: unknown;
} & (
  | ({
      isYou: boolean;
      profile?: Profile | null;
      person?: undefined;
    } & (
      | { primaryCalendarId: string; primaryCalendar?: undefined }
      | { primaryCalendarId?: undefined; primaryCalendar: string }
    ))
  | {
      person: {
        isMe: boolean;
        displayName?: string;
        givenName?: string | null;
        familyName?: string | null;
        externalImageUrl?: string | null;
        email: string;
      };
      profile?: undefined;
      isYou?: undefined;
      primaryCalendarId?: undefined;
      primaryCalendar?: undefined;
    }
);

const fullName = (attendee: ScopedAttendee): string => {
  const fullName = attendee.person
    ? attendee.person.displayName ??
      getFullName({
        givenName: attendee.person?.givenName,
        familyName: attendee.person?.familyName,
      })
    : attendee.profile
    ? getFullName(attendee.profile)
    : undefined;
  const defaultAttendeeName = attendee.person
    ? attendee.person.email
    : attendee.primaryCalendarId ?? attendee.primaryCalendar;

  return fullName || defaultAttendeeName;
};

export const getStringListOfAttendees = (attendees: ScopedAttendee[]): string => {
  const numAttendees = attendees.length;

  const attendeeNameList = attendees
    .map((attendee, index) => {
      const baseName = attendee.isYou || attendee.person?.isMe ? "You" : fullName(attendee);
      if (index === numAttendees - 2) {
        return baseName + " and";
      }
      if (index === numAttendees - 1) {
        return baseName;
      }
      return baseName + ",";
    })
    .join(" ");

  return attendeeNameList;
};
