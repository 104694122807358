import { CalendarDensity, CalendarMode } from "@clockwise/web-commons/src/components/calendar";
import useIsNarrow from "@clockwise/web-commons/src/hooks/useIsNarrow";
import { useAIChatCollapseContext } from "@clockwise/web-commons/src/util/AIChatCollapseContext";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import {
  useReadCalendar,
  useUpdateCalendar,
} from "@clockwise/web-commons/src/util/CalendarContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime } from "luxon";
import React, { useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useFeatureFlag } from "../../../launch-darkly";
import { useUpdateActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import { getFeedbackUrls } from "../../chat/ai-chat/components/proposal/utils/feedbackUrl";
import { useAIMessageContext } from "../../chat/ai-chat/hooks/AIMessageContext";
import { useCommandBarContext } from "../../chat/ai-chat/hooks/CommandBarContext";
import { useUpdateSuggestedPrompt } from "../../chat/ai-chat/hooks/SuggestedPromptContext";
import { useFocusChatTextInput } from "../../chat/ai-chat/hooks/useFocusChatTextInput";
import { useActiveProposal } from "../../chat/hooks/useActiveProposal";
import { useUserProfile } from "../../hooks/useUserProfile";
import { TradeoffsPopover } from "../../tradeoffs-popover";
import { usePlannerContext } from "../Context";
import PlannerToolbarRender from "./PlannerToolbarRender";
import { useHighlightCalendarStepNavigation } from "./hooks/useHighlightCalendarStepNavigation";

export const plannerToolbarSettingKeys = {
  unFadePastEvents: "unFadePastEvents",
};

export const PlannerToolbar = () => {
  const { shouldExcludeAi } = useAIMessageContext();
  const updatePromptText = useUpdateSuggestedPrompt();
  const setActiveEvent = useUpdateActiveEvent();
  const setActiveDiff = useUpdateActiveEventDiff();
  const [enableCommandBar] = useFeatureFlag("CommandBar");
  const { setIsCommandBarVisible } = useCommandBarContext();

  const { userProfile } = useUserProfile();
  const activeProposal = useActiveProposal();

  const [, , neutralFeedbackUrl] = getFeedbackUrls(
    activeProposal?.conversationId || "",
    activeProposal.proposal?.proposalId || "",
    userProfile,
    "",
  );

  const { calendarDataLoading: loading, hideDeclined, setHideDeclined } = usePlannerContext();

  const { focusedDate, visibleDates, weekendsShown, anchorDate } = useReadCalendar();
  const calendarDispatch = useUpdateCalendar();
  const track = useTracking();
  const compact = useIsNarrow();
  const [density, setCalendarDensity] = useLocalStorage<CalendarDensity>(
    "calendarDensity",
    "default",
  );
  const zone = getRenderTimeZone();
  const {
    navButtonToHighlight,
    tradeoffs,
    showTradeoffs,
    currentTimeSuggestionString,
    totalAttendeeCount,
  } = useHighlightCalendarStepNavigation(visibleDates, zone);
  const { setChatIsCollapsed, chatIsCollapsed } = useAIChatCollapseContext();
  const focusChatTextInput = useFocusChatTextInput();

  // LocalStorage settings are only created on user interaction
  const [, setWeekendsShownLS] = useLocalStorage<boolean>("showWeekends", weekendsShown);
  // Set the defaulted state such that past events are faded for those who have not
  // interacted with the toggle
  const [unFadePastEvents, setUnFadePastEvents] = useLocalStorage<boolean>(
    plannerToolbarSettingKeys.unFadePastEvents,
    false,
  );

  const mode = visibleDates.length > 1 ? "week" : "day";

  const currentDateTime = DateTime.fromISO(focusedDate, { zone: getRenderTimeZone() });

  const handleTodayClick = () =>
    calendarDispatch({
      type: mode === "day" ? "jumpTo-date" : "jumpTo-weekOf",
      payload: DateTime.now().toISODate(),
    });

  const handleCalendarModeChange = useCallback(
    (newMode: CalendarMode) => {
      calendarDispatch({
        type: "set-view",
        payload: { view: newMode, date: anchorDate ?? focusedDate },
      });
    },
    [anchorDate, focusedDate, calendarDispatch],
  );

  const handleNavigateToDate = (date: string) => {
    calendarDispatch({
      type: mode === "day" ? "jumpTo-date" : "jumpTo-weekOf",
      payload: date,
    });
  };

  const handleShiftNext = () => calendarDispatch({ type: "step-forward" });

  const handleShiftPrevious = () => calendarDispatch({ type: "step-backward" });

  const handleShowDeclinedEventsChange = (checked: boolean) => {
    const hideDeclined = !checked;
    track(TrackingEvents.PLANNER.SETTINGS.HIDE_DECLINED, { hidden: hideDeclined });
    setHideDeclined?.(hideDeclined);
  };

  const handleShowWeekendsChange = (checked: boolean) => {
    track(TrackingEvents.PLANNER.SETTINGS.HIDE_WEEKENDS, { hidden: !checked });
    calendarDispatch({ type: "set-weekendsShown", payload: checked });
    setWeekendsShownLS(checked);
  };

  const handleFadePastEventsChange = (faded: boolean) => {
    track(TrackingEvents.PLANNER.SETTINGS.FADE_PAST_EVENTS, { faded });
    setUnFadePastEvents(!faded);
  };

  const handleCalendarDensityChange = (density: CalendarDensity) => {
    // TODO: this does not belong in calendar reducer
    track(TrackingEvents.PLANNER.SETTINGS.DENSITY, { density });
    setCalendarDensity(density);
  };

  const handleExpandChat = () => {
    track(TrackingEvents.CHAT.SIDEBAR_EXPANDED);
    setChatIsCollapsed(false);
    focusChatTextInput();
  };

  const handleFindTime = () => {
    const text = "Schedule @";
    track(TrackingEvents.CHAT.NUX.CHAT_RECOMMENDATION_CLICKED, { text });
    if (enableCommandBar) {
      setIsCommandBarVisible(true);
    } else {
      updatePromptText(text);
      setActiveEvent(null);
      setActiveDiff(null);
      handleExpandChat();
    }
  };

  const getDateFormat = () => {
    return compact ? "MMM YYYY" : "MMMM YYYY";
  };

  const HighlightedNavButtonTooltip = ({
    anchorEl,
  }: {
    anchorEl: React.RefObject<HTMLDivElement>;
  }) => {
    return (
      <TradeoffsPopover
        tradeoffs={tradeoffs}
        side="bottom"
        totalAttendeeCount={totalAttendeeCount}
        anchorEl={anchorEl}
        headerText={currentTimeSuggestionString ?? undefined}
      />
    );
  };

  return (
    <PlannerToolbarRender
      currentDate={currentDateTime.toISODate()}
      dateFormat={getDateFormat()}
      declinedEventsEnabled={!hideDeclined}
      density={density || "default"}
      fadePastEvents={!unFadePastEvents}
      feedbackURL={neutralFeedbackUrl}
      highlightedNavButtonTooltip={showTradeoffs ? HighlightedNavButtonTooltip : null}
      loading={!!loading}
      mode={mode}
      navButtonToHighlight={navButtonToHighlight}
      onCalendarDensityChange={handleCalendarDensityChange}
      onCalendarModeChange={handleCalendarModeChange}
      onFadePastEventsChange={handleFadePastEventsChange}
      onFindTime={shouldExcludeAi ? undefined : handleFindTime}
      onNavigateToDate={handleNavigateToDate}
      onShiftNext={handleShiftNext}
      onShiftPrevious={handleShiftPrevious}
      onShowDeclinedEventsChange={handleShowDeclinedEventsChange}
      onShowWeekendsChange={handleShowWeekendsChange}
      onTodayClick={handleTodayClick}
      onExpandChat={handleExpandChat}
      chatIsCollapsed={chatIsCollapsed}
      weekendsEnabled={weekendsShown}
    />
  );
};

export default PlannerToolbar;
