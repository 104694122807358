import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { Button, Divider } from "@clockwise/design-system";
import { EventPermissions, RepeatingEventSaveOption } from "@clockwise/schema";
import { EditEventAppliesTo, WorkingLocationType } from "@clockwise/schema/v2";
import { webappDefaultWindowName } from "@clockwise/web-commons/src/constants/route.constants";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { LinkOff } from "@material-ui/icons";
import classNames from "classnames";
import { Interval } from "luxon";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useBoolean } from "usehooks-ts";
import { useWorkingLocation } from "../account-preferences-working-location/hooks/useWorkingLocation";
import { EventDetails } from "../web-app-calendar/hooks/useEventDetails";
import usePlannerMetaData from "../web-app-calendar/hooks/usePlannerMetaData";
import useUpdateWorkingLocationEvent from "../web-app-calendar/hooks/useUpdateWorkingLocationEvent";
import { CardWrapper } from "./CardWrapper";
import { EventElementsWrap } from "./atoms/EventElementsWrap";
import { ExternalLinkIcon } from "./atoms/ExternalLinkIcon";
import { InlineAction } from "./atoms/InlineAction";
import { SaveDropdown } from "./atoms/SaveDropdown";
import { WarningMessage } from "./atoms/WarningMessage";
import { LoadingProgress } from "./atoms/history/LoadingProgress";
import { useReadEventTimeDetails } from "./hooks/EventCardContext";
import { AllDay } from "./molecules/ECAllDay";
import { ECDeleteConfirm } from "./molecules/ECDeleteConfirm";
import { ECRecurrence } from "./molecules/ECRecurrence";
import { ECTitle as Title } from "./molecules/ECTitle";
import {
  convertLocationTypeFromWebserverToGateway,
  ECWorkingLocation,
} from "./molecules/ECWorkingLocation";
import { Time } from "./molecules/Time";
import { EventCardWarning } from "./utils/getEventCardWarning";
import { getSubmitButtonCopy } from "./utils/getSubmitButtonCopy";

export const convertRepeatingEventSaveOptionToEditEventEnum = (type: RepeatingEventSaveOption) => {
  switch (type) {
    case RepeatingEventSaveOption.AllInstances:
      return EditEventAppliesTo.AllInstances;
    case RepeatingEventSaveOption.ThisInstanceOnly:
      return EditEventAppliesTo.Instance;
    case RepeatingEventSaveOption.ThisAndFutureInstances:
      return EditEventAppliesTo.ThisAndFuture;
  }
};

type Props = Pick<EventDetails, "title" | "eventPermissions"> & {
  eventCalendarId: string;
  eventId: string;
  workingLocation: {
    id: string;
    type: WorkingLocationType;
    name: string | null;
    timeZone: string | null;
    address: string | null;
  };
  recurrenceRule: RecurrenceRule | null;
};
export const WorkingLocationCard = ({
  eventCalendarId,
  eventPermissions,
  eventId = "",
  title = "Working location",
  workingLocation: fetchedWorkingLocation,
  recurrenceRule: fetchedRecurrenceRule,
}: Props) => {
  const { startTime, endTime, isAllDay, timesHaveChanged } = useReadEventTimeDetails();
  const updateActiveEvent = useUpdateActiveEvent();
  const { primaryCalendarId } = usePlannerMetaData();
  const { workingLocations } = useWorkingLocation();
  const {
    value: isConfirmDeleteShowing,
    setTrue: setConfirmShowing,
    setFalse: setConfirmHiding,
  } = useBoolean(false);

  const [workingLocation, setWorkingLocation] = useState(fetchedWorkingLocation);
  const options = (workingLocations || []).map((wl) => ({
    id: wl.id,
    type: convertLocationTypeFromWebserverToGateway(wl.locationType),
    name: wl.name,
    timeZone: wl.timeZone,
    address: wl.address,
  }));
  // if event's working location is not in the user's defined options list from settings, add it.
  if (fetchedWorkingLocation && !options.find((o) => o.id === fetchedWorkingLocation.id)) {
    options.unshift(fetchedWorkingLocation);
  }
  const [recurrenceRule, setRecurrenceRule] = useState(fetchedRecurrenceRule);

  const {
    onSaveWorkingLocationEventChanges,
    savingChanges,
    onDeleteWorkingLocationEvent,
  } = useUpdateWorkingLocationEvent(eventId, eventCalendarId, {
    onEditCompleted: () => {
      toast.success("Event saved");
      updateActiveEvent(null);
    },
    onEditError: () => toast.error("Event failed to save"),
    onDeleteCompleted: () => {
      toast.success("Event deleted");
      updateActiveEvent(null);
    },
    onDeleteError: () => toast.error("Event failed to delete"),
  });

  const changesMap = {
    workingLocation: workingLocation.id !== fetchedWorkingLocation.id,
    eventRecurrence: fetchedRecurrenceRule?.toString() !== recurrenceRule?.toString(),
    time: timesHaveChanged,
  };

  const changesCount = getChangesCount(changesMap);

  const handleClose = () => {
    updateActiveEvent(null);
  };

  const handleSave = (repeatingEventSaveOption?: RepeatingEventSaveOption) => {
    if (!startTime || !endTime) {
      toast.error("Unable to save changes. Please try again later.");
      return;
    }

    void onSaveWorkingLocationEventChanges({
      calendarId: eventCalendarId,
      externalEventId: eventId,
      patchAppliesTo: repeatingEventSaveOption
        ? convertRepeatingEventSaveOptionToEditEventEnum(repeatingEventSaveOption)
        : EditEventAppliesTo.Instance,
      workingLocationEvent: {
        isAllDay,
        location: {
          id: workingLocation.id,
          type: workingLocation.type,
          name: workingLocation.name,
          timeZone: workingLocation.timeZone,
          address: workingLocation.address,
        },
        range: Interval.fromDateTimes(startTime, endTime).toISO(),
        recurrenceRule: recurrenceRule ? recurrenceRule.toString() : null,
        timeZone: getRenderTimeZone(),
        title: title,
      },
    });
  };

  const readOnly =
    eventPermissions !== EventPermissions.ALL && eventCalendarId !== primaryCalendarId;
  const showDropdownOnSave = !readOnly && !!recurrenceRule;

  return (
    <CardWrapper
      changesCount={changesCount}
      footer={
        <div className={classNames("cw-flex cw-justify-between", "cw-relative", "cw-p-3")}>
          {isConfirmDeleteShowing && (
            <ECDeleteConfirm
              onCancelEvent={(repeatingEventSaveOption) => {
                void onDeleteWorkingLocationEvent({
                  externalEventId: eventId,
                  calendarId: eventCalendarId,
                  patchAppliesTo: repeatingEventSaveOption
                    ? convertRepeatingEventSaveOptionToEditEventEnum(repeatingEventSaveOption)
                    : EditEventAppliesTo.Instance,
                });
              }}
              onHide={setConfirmHiding}
              isHold
            />
          )}
          <Button
            aria-label="Remove from calendar"
            onClick={setConfirmShowing}
            sentiment="destructive"
            size="xsmall"
            startIcon={LinkOff}
            variant="outlined"
          >
            Remove from calendar
          </Button>
          {showDropdownOnSave ? (
            <SaveDropdown
              disabled={changesCount <= 0 || savingChanges}
              onSelect={(option) => handleSave(option)}
              copy={getSubmitButtonCopy(false, false)}
              disabledRecurrenceSaveOptions={[RepeatingEventSaveOption.AllInstances]}
            />
          ) : (
            <Button
              aria-label="Save"
              disabled={changesCount <= 0 || savingChanges}
              onClick={() => handleSave(RepeatingEventSaveOption.ThisInstanceOnly)}
              sentiment="positive"
              size="xsmall"
              variant="solid"
            >
              Save
            </Button>
          )}
        </div>
      }
      header={<div className="cw-font-semibold">Working location</div>}
      onClose={handleClose}
    >
      {readOnly && <WarningMessage type={EventCardWarning.NotYourEvent} />}
      {savingChanges && <LoadingProgress />}
      <Title eventName={title} readOnly />
      <Time readOnly={readOnly} />
      <AllDay readOnly />
      <ECRecurrence
        readOnly={readOnly || isAllDay}
        recurrenceRule={recurrenceRule}
        date={startTime?.toISODate()}
        onSelect={(v) => setRecurrenceRule(v)}
        showChanges={changesMap.eventRecurrence}
        originalRecurrence={fetchedRecurrenceRule}
      />
      <Divider spacing="xs" inset />
      <ECWorkingLocation
        onSelect={(v) => setWorkingLocation(v)}
        readOnly={readOnly && !isAllDay}
        selected={workingLocation.id}
        showChanges={changesMap.workingLocation}
        options={options}
      />
      <Divider spacing="xs" inset />
      <EventElementsWrap name="settings">
        <div className="cw-body-sm">
          This is a working location.{" "}
          <InlineAction
            ariaLabel="Edit Working location Settings"
            fontSize="" // empty to inherit from container
            icon={ExternalLinkIcon}
            label="Edit Settings"
            onClick={openWorkingLocationSettings}
          />
        </div>
      </EventElementsWrap>
    </CardWrapper>
  );
};

const getChangesCount = (obj: { [key: string]: boolean }) => {
  let count = 0;
  for (const k in obj) {
    if (obj[k]) {
      count++;
    }
  }
  return count;
};

const openWorkingLocationSettings = () =>
  window.open(getUrlForFeature("accountPreferences"), webappDefaultWindowName);
