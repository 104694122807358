import { SchedulingProposalStateEnum } from "@clockwise/schema";
import { find } from "lodash";
import { ProposalResponseDetailsFragment } from "../ai-chat/apollo/__generated__/ChatHistory.generated";
import { useAIMessageContext } from "../ai-chat/hooks/AIMessageContext";

export type Proposal = ProposalResponseDetailsFragment["proposal"];

export const useActiveProposal = () => {
  const { messages, processingMessage, confirmingMessage, loadingHistory } = useAIMessageContext();
  const proposalResponse = find(
    messages,
    (message) =>
      message.__typename === "ProposalResponse" &&
      (message.proposal.state === SchedulingProposalStateEnum.ACTIVE ||
        message.proposal.state === SchedulingProposalStateEnum.INCOMPLETE),
  );
  const msgId =
    proposalResponse?.__typename === "ProposalResponse" ? proposalResponse.id : undefined;
  const conversationId =
    proposalResponse?.__typename === "ProposalResponse"
      ? proposalResponse.conversationId
      : undefined;
  const proposal =
    proposalResponse?.__typename === "ProposalResponse" ? proposalResponse.proposal : undefined;
  const loading = loadingHistory || processingMessage || confirmingMessage;

  const proposalOptionsIndex =
    proposal?.options.__typename === "SchedulingOptions" ? proposal.options.selectedIndex : null;

  // Set of all calendar ids that are attendees for all diffs of the proposal
  const attendeeCalendarIds =
    proposal?.diffBlocks?.reduce((acc, diff) => {
      const attendeesForDiff = diff?.diffs?.reduce((attendees, diffBlock) => {
        const attendeesForDiff = diffBlock.attendees.proposalAttendees.map(
          (attendee) => attendee.attendeePerson.primaryCalendar,
        );
        return new Set([...attendees, ...attendeesForDiff]);
      }, new Set<string>());

      return new Set([...acc, ...attendeesForDiff]);
    }, new Set<string>()) ?? new Set<string>();

  return {
    msgId,
    conversationId,
    proposal,
    loading,
    totalAttendeeCount: attendeeCalendarIds.size,
    proposalOptionsIndex,
    attendeeCalendarIds: Array.from(attendeeCalendarIds),
  };
};
