//////////////////
// IMPORTS
//////////////////
// schema
import * as ISchema from "#webapp/src/__schema__";
// libraries
import React from "react";
import { QueryRenderer, ReadyState } from "react-relay";
// web-settings-event-color-coding-renderer imports
import { query } from "./WebSettingsEventColorCodingRelay.gql";
// components
import { EventColoringSettings } from "#webapp/src/components/event-coloring-settings";

// util
import { page, PageEvents } from "#webapp/src/util/analytics.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";
import { handleReadyStateError } from "#webapp/src/util/relay.util";
// constants

// state
import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
import { Loader } from "@clockwise/design-system/src/components/Loader";
// material-ui

export interface IRendererProps {}

export interface IRendererState {
  key: number;
  loading: boolean;
}

//////////////////
// COMPONENT
//////////////////
export class WebSettingsEventColorCodingRelay extends React.Component<
  IRendererProps,
  IRendererState
> {
  // ~-~-~-~-~-~-~-
  // Setup
  // ~-~-~-~-~-~-~-
  private shouldForce = true;

  private cachedOrg?: ISchema.IOrg;

  constructor(props: IRendererProps) {
    super(props);
    this.state = {
      key: 0,
      loading: false,
    };
  }

  // ~-~-~-~-~-~-~-
  // Life-Cycle
  // ~-~-~-~-~-~-~-
  public componentDidMount() {
    page(PageEvents.WEB_SETTINGS_EVENT_COLOR_CODING);
  }

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  // private refetch = () => {
  //   this.shouldForce = true;
  //   this.setState({ key: this.state.key + 1 });
  // };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  public render() {
    const environment = getCurrentEnvironment();

    return (
      <QueryRenderer
        key={this.state.key}
        environment={environment}
        query={query}
        variables={{}}
        cacheConfig={{ force: this.shouldForce }}
        render={(readyState: ReadyState) => {
          // check for error or show a loader for first load
          if (readyState.error) {
            handleReadyStateError(readyState.error, "WebSettingsEventColorCodingRenderer");
            return null;
          }

          const viewer = readyState.props && readyState.props.viewer;
          const org = getCurrentOrg(viewer);

          if (org) {
            this.cachedOrg = org;
          }

          if (!this.cachedOrg || this.state.loading) {
            return <Loader size="lg" sentiment="positive" className="cw-ml-auto cw-mr-auto" />;
          }

          this.shouldForce = false;

          return <EventColoringSettings org={org} isWebSettings />;
        }}
      />
    );
  }
}
