import { DateTime } from "luxon";
import React from "react";
import { MAX_WINDOW_SIZE_IN_DAYS, MIN_WINDOW_SIZE_IN_DAYS } from "../consts";
import { DateTimeString } from "../setup-scheduling-link";
import SetupLinkDatePicker from "../SetupLinkDatePicker";

const getDateTimeInRange = (givenDate: DateTime, minDate: DateTime, maxDate: DateTime) => {
  const isDateBeforeMin = givenDate < minDate;
  const isDateAfterMax = givenDate > maxDate;

  if (isDateBeforeMin) {
    return minDate;
  }

  if (isDateAfterMax) {
    return maxDate;
  }

  return givenDate;
};

export type DateRangePickerProps = {
  endDate: DateTimeString;
  onChange: (startDate: string, endDate: string) => void;
  startDate: DateTimeString;
};

const DateRangePicker = ({ endDate, onChange, startDate }: DateRangePickerProps) => {
  const handleStartDateChange = (newStartDateString: DateTimeString) => {
    const newStartDate = DateTime.fromISO(newStartDateString).startOf("day");
    let newEndDate = DateTime.fromISO(endDate).endOf("day");
    if (newEndDate < newStartDate) {
      newEndDate = newStartDate.plus({ days: MIN_WINDOW_SIZE_IN_DAYS });
    }

    onChange(newStartDate.toISO(), newEndDate.toISO());
  };

  const handleEndDateChange = (newEndDateString: DateTimeString) => {
    const newEndDate = DateTime.fromISO(newEndDateString).endOf("day");
    let newStartDate = DateTime.fromISO(startDate).startOf("day");
    if (newStartDate > newEndDate) {
      newStartDate = newEndDate.minus({ days: MIN_WINDOW_SIZE_IN_DAYS });
    }

    onChange(newStartDate.toISO(), newEndDate.toISO());
  };

  const dateMin = DateTime.now();
  const dateMax = dateMin.plus({ days: MAX_WINDOW_SIZE_IN_DAYS });

  const dateStart = getDateTimeInRange(DateTime.fromISO(startDate), dateMin, dateMax);
  const dateEnd = getDateTimeInRange(DateTime.fromISO(endDate), dateMin, dateMax);

  return (
    <>
      <SetupLinkDatePicker
        cw-id="links_start-date-picker"
        maxDate={dateMax.toJSDate()}
        minDate={dateMin.toJSDate()}
        onChange={handleStartDateChange}
        value={dateStart.toJSDate()}
      />
      <span className="-cw-mx-1">to</span>
      <SetupLinkDatePicker
        cw-id="links_end-date-picker"
        maxDate={dateMax.toJSDate()}
        minDate={dateMin.toJSDate()}
        onChange={handleEndDateChange}
        value={dateEnd.toJSDate()}
      />
    </>
  );
};

export default DateRangePicker;
