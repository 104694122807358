import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  CalendarIds,
  addDiffIdWithCalendarIdsBeforeEdit,
  addEventIdWithCalendarIdsBeforeEdit,
} from "../../../../../../state/actions/multi-calendar.actions";

export const useSetOriginalAttendeesBeforeEdit = ({
  diffId,
  calendarIds,
  eventId,
}: {
  diffId?: string;
  calendarIds: CalendarIds;
  eventId?: string;
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!calendarIds) {
      return;
    }

    if (diffId) {
      dispatch(addDiffIdWithCalendarIdsBeforeEdit(diffId, calendarIds));
    } else if (eventId) {
      dispatch(addEventIdWithCalendarIdsBeforeEdit(eventId, calendarIds));
    }
  }, []);
};
