import { AttendeeAvatar, AvatarSize } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { getStringListOfAttendees } from "@clockwise/web-commons/src/util/profile.util";
import React from "react";
import { useReadCalendarColors } from "../web-app-calendar/hooks/CalendarColorsContext";

export type Attendee = {
  person: {
    isMe: boolean;
    displayName: string;
    externalImageUrl: string | null;
    email: string;
  };
};

export type Props = {
  attendees: Attendee[];
  maxShown?: number;
  borderColorOverride?: string;
  size?: AvatarSize;
};
export const AttendeeStack = ({
  attendees,
  maxShown = 3,
  size = "medium",
  borderColorOverride,
}: Props) => {
  const calendarColors = useReadCalendarColors();

  // avoid showing just a +1 when possible (e.g. 4 attendees, maxShown = 3)
  const attendeeLengthDependentMaxShown =
    attendees.length - 1 === maxShown ? maxShown - 1 : maxShown;

  const overflowAttendeeNames = getStringListOfAttendees(
    attendees.slice(attendeeLengthDependentMaxShown),
  );

  return (
    <AttendeeAvatarStack
      maxShown={attendeeLengthDependentMaxShown}
      overlap
      size={size}
      disableAnimation
      overflowToolTipContent={overflowAttendeeNames}
    >
      {attendees.map(({ person }) => {
        const borderColor =
          borderColorOverride || (calendarColors?.[person.email]?.border ?? undefined);

        return (
          <AttendeeAvatar
            key={person.email}
            profile={{ externalImageUrl: person.externalImageUrl }}
            displayName={person.displayName}
            toolTipContent={person.isMe ? "You" : person.displayName}
            aria-label={person.isMe ? "Your avatar" : `${person.displayName} avatar`}
            size="medium"
            isInOverlap
            borderColor={borderColor}
          />
        );
      })}
    </AttendeeAvatarStack>
  );
};
