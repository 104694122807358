import { SvgIconComponent } from "@clockwise/icons";
import { MantineSize } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { INPUT_ICON_SIZES } from "../../constants/classes";
import { Size } from "../../constants/types";

const SIZE_CONVERSION: Record<Size, MantineSize> = {
  mini: "sm",
  small: "md",
  medium: "lg",
  large: "xl",
};
export const getMantineSize = (size: Size): MantineSize => {
  return SIZE_CONVERSION[size];
};

const ICON_NUDGE: Record<Size, string> = {
  mini: "cw-left-[1px]",
  small: "cw-left-[2px]",
  medium: "cw-left-[2px]",
  large: "cw-left-[2px]",
};

/**
 * Render an icon with correct size and alignment adjustments to fit in the Mantine Input component
 */
export const renderIcon = (Icon: SvgIconComponent, size: Size) => (
  <div
    className={classNames(
      "cw-flex cw-items-center cw-h-full cw-text-default cw-relative",
      ICON_NUDGE[size],
    )}
  >
    <Icon className={classNames("cw-font-medium", INPUT_ICON_SIZES[size])} />
  </div>
);
