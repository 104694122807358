import { useMutation } from "@apollo/client";
import { CancelEventDiffDetailsInput } from "@clockwise/schema";
import { useCallback } from "react";
import {
  CancelEventDiffDocument,
  CancelEventDiffMutation,
} from "../apollo/__generated__/CancelEventDiff.generated";

type CancelEventFromDiffResult = [
  (
    input: CancelEventDiffDetailsInput,
    {
      onError,
      onCompleted,
    }: {
      onError?: (error: Error) => void;
      onCompleted?: (data: CancelEventDiffMutation) => void;
    },
  ) => Promise<void>,
  {
    cancelEventDiffLoading: boolean;
    cancelEventDiffError: Error | undefined;
  },
];

export const useCancelEventFromDiff = (): CancelEventFromDiffResult => {
  const [
    cancelEventFromDiff,
    { loading: cancelEventDiffLoading, error: cancelEventDiffError },
  ] = useMutation(CancelEventDiffDocument);

  const onCancelEventFromDiff = useCallback(
    async (
      input: CancelEventDiffDetailsInput,
      {
        onError,
        onCompleted,
      }: {
        onError?: (error: Error) => void;
        onCompleted?: (data: CancelEventDiffMutation) => void;
      },
    ) => {
      await cancelEventFromDiff({
        variables: {
          input,
        },
        onError,
        onCompleted,
      });
    },
    [cancelEventFromDiff],
  );

  return [
    onCancelEventFromDiff,
    {
      cancelEventDiffLoading,
      cancelEventDiffError,
    },
  ];
};
