import { DataSourceAuthError } from "@clockwise/schema";
import React, { useReducer } from "react";
import AddButton from "./AddButton";
import List from "./List";

type State = {
  saving: boolean;
  error: string | null;
};

type Action =
  | { type: "save-start" }
  | { type: "save-end" }
  | { type: "save-error"; payload?: Error | DataSourceAuthError };

const reducer = (state: State, action: Action) => {
  let error: string | null = null;

  switch (action.type) {
    case "save-start":
      return { ...state, saving: true, error: null };
    case "save-end":
      return { ...state, saving: false, error: null };
    case "save-error":
      switch (action.payload) {
        case DataSourceAuthError.SameDomainError:
          error = "Personal calendars cannot be on the same domain as your work calendar.";
          break;
        case DataSourceAuthError.InsufficientScopes:
          error =
            "Clockwise needs your permission to access this calendar. Please try again and select all data access requests.";
          break;
        case DataSourceAuthError.GenericError:
          error = "Something went wrong updating your settings.";
          break;
        default:
          error = "Unable to update your settings.";
          break;
      }
      return { ...state, saving: false, error };
  }
};

type Props = {
  disabled?: boolean;
  onAdded?: () => void;
  onRemoved?: () => void;
};

const Control = ({ disabled, onAdded, onRemoved }: Props) => {
  const [state, dispatch] = useReducer(reducer, { saving: false, error: null });

  const handleSaveStart = () => {
    dispatch({ type: "save-start" });
  };

  const handleSaveEnd = (saveAction?: "added" | "removed") => {
    dispatch({ type: "save-end" });

    if (saveAction === "added") onAdded?.();
    if (saveAction === "removed") onRemoved?.();
  };

  const handleSaveError = (error?: DataSourceAuthError | Error) =>
    dispatch({ type: "save-error", payload: error });

  return (
    <div className="cw-flex cw-flex-col cw-space-y-2">
      <div className="cw-flex cw-flex-col cw-space-y-0">
        <List
          disabled={disabled || state.saving}
          onSaveEnd={handleSaveEnd}
          onSaveError={handleSaveError}
          onSaveStart={handleSaveStart}
        />
      </div>
      <div className="cw-grow-0">
        <AddButton
          disabled={disabled || state.saving}
          label={state.saving ? "Updating" : "Connect new calendar"}
          onSaveEnd={handleSaveEnd}
          onSaveError={handleSaveError}
          onSaveStart={handleSaveStart}
        />
      </div>
      {state.error && <div className="cw-text-warning cw-mb-1 cw-body-sm">{state.error}</div>}
    </div>
  );
};

export default Control;
