import { useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { ThumbDownOutlined, ThumbUpOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";
import { TrackingEvents } from "../../../util/analytics.util";
import { useUserProfile } from "../../hooks/useUserProfile";
import { feedbackURL } from "../constants";
import { getFormattedIdWithDashes } from "../util/getFormattedIdWithDashes";

type FeedbackThumbsProps = {
  msgId: string;
  conversationId: string;
  proposalId?: string;
  orientation: "horizontal" | "vertical";
  currentLLMUsed: string;
};

export const FeedbackThumbs = ({
  msgId,
  conversationId,
  proposalId,
  orientation,
  currentLLMUsed,
}: FeedbackThumbsProps) => {
  const { userProfile } = useUserProfile();
  const track = useTracking();

  const params = new URLSearchParams();
  if (!isEmpty(conversationId)) {
    params.set("conversationId", getFormattedIdWithDashes(conversationId));
  }
  if (proposalId) {
    params.set("proposalId", getFormattedIdWithDashes(proposalId));
  }
  if (userProfile.primaryEmail) {
    params.set("email", userProfile.primaryEmail);
  }
  if (!isEmpty(currentLLMUsed)) {
    params.set("llmName", currentLLMUsed);
  }

  const positiveSentimentURL = `${feedbackURL}/prefill/645179906231300a18dd6fe6dd96?${params.toString()}`;
  const negativeSentimentURL = `${feedbackURL}/prefill/64507ed663343305c5fca14459ad?${params.toString()}`;

  return (
    <div
      className={classNames("cw-flex cw-gap-2 cw-items-center", {
        "cw-flex-col-reverse cw-ml-3": orientation === "vertical",
        "cw-mt-1": orientation === "horizontal",
      })}
    >
      <a
        href={positiveSentimentURL}
        target="_blank"
        rel="noreferrer"
        title="Link to feedback form"
        onClick={() => {
          track(TrackingEvents.CHAT.PROPOSAL.FEEDBACK_POSITIVE, {
            msgId: msgId,
            conversationId: conversationId,
            proposalId: proposalId,
            llmName: currentLLMUsed,
          });
        }}
      >
        <ThumbUpOutlined className="cw-text-neutral-muted hover:cw-text-neutral-hover cw-w-4 cw-h-4" />
      </a>
      <a
        href={negativeSentimentURL}
        target="_blank"
        rel="noreferrer"
        title="Link to feedback form"
        onClick={() => {
          track(TrackingEvents.CHAT.PROPOSAL.FEEDBACK_NEGATIVE, {
            msgId: msgId,
            conversationId: conversationId,
            proposalId: proposalId,
            llmName: currentLLMUsed,
          });
        }}
      >
        <ThumbDownOutlined className="cw-text-neutral-muted hover:cw-text-neutral-hover cw-w-4 cw-h-4" />
      </a>
    </div>
  );
};
