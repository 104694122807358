import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const wrappingContainer: CSSProperties = {
  height: "100%",
  width: "100%",
};

export const styles = {
  wrappingContainer,
};
