import { CalendarDates } from "@clockwise/web-commons/src/components/calendar/calendar-dates/CalendarDates";
import { EventType } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { useUpdateCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useFeatureFlag } from "../../../launch-darkly";
import { AllDayEventsUnderlay } from "../ooo-events/AllDayEventsUnderlay";
import { AllDayEvents } from "../planner-all-day-events/AllDayEvents";
import { PlannerHeadWorkingLocations } from "../planner-head-working-locations/PlannerHeadWorkingLocations";
import { PlannerEventCard } from "../types";

type Props = {
  dateTimes: DateTime[];
  events?: PlannerEventCard[];
  workingLocations?: PlannerEventCard[];
  onEventClick: (calendarId: string, externalEventId: string, type: EventType) => void;
};

export const PlannerHead = ({ dateTimes, events, workingLocations, onEventClick }: Props) => {
  const [isOnWorkingLocation] = useFeatureFlag("WorkingLocation");
  const calendarDispatch = useUpdateCalendar();

  const handleClick = (dateTime: DateTime) =>
    calendarDispatch({
      type: "set-view",
      payload: { view: "day", date: dateTime.toISODate() },
    });

  return (
    <div className="cw-z-20">
      <div className="cw-ml-5 cw-mr-2">
        <CalendarDates dateTimes={dateTimes} onClick={handleClick} TimeZoneWrap={TimeZoneLink} />
      </div>
      {isOnWorkingLocation && (
        <div className="cw-ml-5 cw-pb-1">
          <PlannerHeadWorkingLocations
            dateTimes={dateTimes}
            events={workingLocations}
            onClick={onEventClick}
          />
        </div>
      )}
      <div className="cw-ml-5 cw-pb-1">
        <AllDayEvents dateTimes={dateTimes} events={events} onClick={onEventClick} />
      </div>
      <div className="cw-ml-5">
        <AllDayEventsUnderlay dateTimes={dateTimes} onClick={onEventClick} />
      </div>
    </div>
  );
};

export const TimeZoneLink: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const linkTo = getUrlForFeature("accountPreferences", { relative: true });

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    navigate(linkTo);
  };
  return (
    <a
      className={classNames(
        "cw-no-underline",
        "cw-bg-transparent hover:cw-bg-neutral-hover",
        "cw-px-2 cw-py-1",
        "cw-rounded",
        "cw-text-subtle visited:cw-text-subtle active:cw-text-subtle",
        "cw-relative cw-top-[2px]",
      )}
      aria-label="Current time zone"
      href={linkTo}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};
