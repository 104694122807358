import { TradeoffType } from "@clockwise/schema/v2";
import { CalendarDensity } from "@clockwise/web-commons/src/components/calendar";
import { Interval } from "luxon";
import { TradeoffBlock } from "../../chat-plus-calendar/PersistedProposalContext";
import { ProposalOptionCardVariant } from "./types";

export function calcCardVariantFromTradeoffBlocks(
  blocks: TradeoffBlock[],
): ProposalOptionCardVariant {
  let foundSbm = false;
  let foundConflict = false;
  for (let i = 0; i < blocks.length; i++) {
    const tradeoffType = blocks[i].tradeoffType;
    if (tradeoffType === TradeoffType.FixableConflict) {
      foundSbm = true;
    } else if (
      tradeoffType !== TradeoffType.Inconvenience &&
      tradeoffType !== TradeoffType.LowVisibilityInconvenience
    ) {
      foundConflict = true;
    }
  }
  return foundConflict ? "conflict" : foundSbm ? "sbm" : "no-conflict";
}

export function getSpacing(interval: Interval, calendarDensity: CalendarDensity | null) {
  const lessThan30 = interval.toDuration().as("minutes") < 30;
  if (lessThan30) {
    switch (calendarDensity) {
      case "compact":
        return "none";
      default:
        return "small";
    }
  }
  return "full";
}
