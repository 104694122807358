//////////////////
// IMPORTS
//////////////////
// libraries
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// login-welcome imports
import * as s from "../LoginWrapper.styles";
// other internals
import { LoginBtn } from "#webapp/src/components/login-btn";
import { CookieDialog } from "@clockwise/web-commons/src/components/cookie-dialog";

// util
import { page, PageEvents, track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { PostMessageManager } from "#webapp/src/util/post-message.util";
// constants
import { paths } from "#webapp/src/constants/site.constant";
// state
import { setOnboardingMeta } from "#webapp/src/state/actions/onboarding.actions";
import { hasSeenLoginPage } from "#webapp/src/state/local-storage";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";

// material-ui
import {
  OnboardingType,
  OnboardingTypeSource,
} from "#webapp/src/components/onboarding-nux/OnboardingNUXTypes";
import { LinearProgress } from "@clockwise/design-system";

// schema

import { Link } from "@clockwise/design-system";
import { getAnonymousId } from "@clockwise/web-commons/src/util/analytics.util";
import { signupExperimentInitialAnonymousId } from "@clockwise/web-commons/src/util/local-storage";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { MsftLoginButton } from "../../msft-login-button";
import { isExtensionDownloadRedirect } from "./isExtensionDownloadRedirect";
import { isWelcomeSignupExperiment } from "./isWelcomeSignupExperiment";

interface IProps {
  userId: string | null;
  redirect?: string;
  isNewUser?: boolean;
  onSuccess: () => void;
}

//////////////////
// COMPONENT
//////////////////

export const LoginWelcome = ({ redirect, isNewUser, onSuccess, userId }: IProps) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const isWebExtension = useSelector((state: IReduxState) => state.webExtension.isWebExtension);

  const routeMatchesWelcomeExperiment = isWelcomeSignupExperiment(location);
  const routeMatchesChromeStoreDownload = isExtensionDownloadRedirect(location);

  const [loading, setLoading] = React.useState(false);
  const [hideCookieErrorDialog, setHideCookieErrorDialog] = React.useState(true);

  React.useEffect(() => {
    page(PageEvents.WELCOME, {
      redirect: redirect,
      isNewUser: isNewUser ? isNewUser : false,
    });
    hasSeenLoginPage.set(true);
    if (isWebExtension) {
      PostMessageManager.openSidebar(true);
    }
    if (routeMatchesWelcomeExperiment) {
      signupExperimentInitialAnonymousId.set(getAnonymousId());
      track(TrackingEvents.Q4_2022_SIGNUP_EXPERIMENT.SIGNUP_EXPERIMENT_WELCOME_VISITED);
    }
    if (routeMatchesChromeStoreDownload) {
      track(TrackingEvents.CHROME_EXTENSION.INSTALL_REDIRECT_WELCOME);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setLoading(false);
  }, [userId]);

  const enableWelcomeLoginOnboardingType = () => {
    dispatch(setOnboardingMeta(OnboardingType.Default, OnboardingTypeSource.WelcomeLoginPage));
  };

  const onLoginStart = () => {
    track(TrackingEvents.AUTH.BEGIN_LOGIN);
    setLoading(true);
  };

  const onLoginSuccess = () => {
    enableWelcomeLoginOnboardingType();
    if (routeMatchesWelcomeExperiment) {
      const initialAnonymousId = signupExperimentInitialAnonymousId.get();
      track(TrackingEvents.Q4_2022_SIGNUP_EXPERIMENT.SIGNUP_EXPERIMENT_WELCOME_LOGIN, {
        initialAnonymousId: initialAnonymousId,
      });
    }
    onSuccess();
  };

  const onLoginFailure = () => {
    track(TrackingEvents.AUTH.LOGIN_FAILED);
    setLoading(false);
  };

  const renderCookieDialog = () => {
    return <CookieDialog isChromeExtension={isWebExtension} open={!hideCookieErrorDialog} />;
  };

  const onGoogleFailure = (e: any) => {
    if (e.error === "idpiframe_initialization_failed") {
      setHideCookieErrorDialog(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Welcome to Clockwise · Clockwise</title>
      </Helmet>
      {renderCookieDialog()}
      <div className="cw-w-full cw-h-full cw-bg-default cw-flex cw-items-center cw-justify-center">
        <div className="cw-flex cw-flex-col cw-items-center cw-mt-[-50px]">
          <div className="cw-text-center cw-heading-3xl cw-mb-5">Welcome to Clockwise!</div>
          <div className="cw-w-[215px] cw-mb-4">
            <LoginBtn
              label={"Sign in with Google"}
              style={s.signupBtn.base}
              labelStyle={s.signupBtn.label}
              redirect={redirect}
              onStart={onLoginStart}
              onSuccess={onLoginSuccess}
              onFailure={onLoginFailure}
              onGoogleFailure={onGoogleFailure}
            />
          </div>
          <div className="cw-w-[300px] cw-mb-4 cw-flex cw-items-center cw-w-5">
            <div className="cw-grow cw-border-b cw-border-solid cw-border-muted"></div>
            <div className="cw-px-2 cw-caption cw-text-muted cw-text-center">
              or try out our latest beta
            </div>
            <div className="cw-grow cw-border-b cw-border-solid cw-border-muted"></div>
          </div>
          <div className="cw-w-[215px] cw-mb-4">
            <MsftLoginButton redirect={redirect} />
          </div>
          <div className="cw-caption cw-text-center cw-text-muted cw-w-[250px]">
            By signing in, you agree to Clockwise's{" "}
            <Link target="_blank" href={paths.terms}>
              {" "}
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link target="_blank" href={paths.privacy}>
              Privacy Policy
            </Link>
            .
          </div>

          {loading && (
            <LinearProgress className="cw-bg-transparent cw-absolute cw-bottom-0 cw-w-full" />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
