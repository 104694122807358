import { ApolloError } from "@apollo/client";
import { EventLogEntry, EventLogStatus as SchemaEventLogEnum } from "@clockwise/schema/v2";
import React from "react";
import { Error } from "./Error";
import { HistoryOff } from "./HistoryOff";
import { HistoryOn } from "./HistoryOn";
import { NoResults } from "./NoResults";
import { EventLogStatus, Flexibility } from "./types";

export type AugmentedEventLogEntry = EventLogEntry & {
  displayName: string;
  externalImageUrl: string | null;
};
export const History = ({
  entries,
  error,
  flexibility,
  nextDefrag,
  onGoToBilling,
  onTimelineExpanded,
  onUnpauseEvent,
  onViewPlans,
  showJoinExistingPlan,
  status,
}: {
  entries: AugmentedEventLogEntry[];
  error: ApolloError | undefined;
  flexibility: Flexibility | null;
  nextDefrag: string | null;
  onGoToBilling: () => void;
  onTimelineExpanded: () => void;
  onUnpauseEvent: () => void;
  onViewPlans: () => void;
  showJoinExistingPlan: boolean;
  status: EventLogStatus | null;
}) => {
  if (error) {
    return <Error />;
  }

  switch (status) {
    case null:
    case SchemaEventLogEnum.NotVisible:
      return (
        <div className="cw-m-3">
          <NoResults />
        </div>
      );
    case SchemaEventLogEnum.Off:
      return (
        <div className="cw-m-3">
          <HistoryOff />
        </div>
      );
    case SchemaEventLogEnum.On:
    case SchemaEventLogEnum.Incomplete:
      return (
        <div className="cw-m-3">
          <HistoryOn
            entries={entries}
            flexibility={flexibility}
            nextDefrag={nextDefrag}
            onGoToBilling={onGoToBilling}
            onTimelineExpanded={onTimelineExpanded}
            onUnpauseEvent={onUnpauseEvent}
            onViewPlans={onViewPlans}
            showJoinExistingPlan={showJoinExistingPlan}
          />
        </div>
      );
  }
};
