import { EcosystemEnum, TransparencyEnum } from "@clockwise/schema";
import { Transparency } from "../atoms/SelectTransparency";

export const toECTransparency = (
  transparency: TransparencyEnum | null,
  ecosystem: EcosystemEnum,
): Transparency => {
  switch (transparency) {
    case TransparencyEnum.Opaque:
      return "opaque";
    case TransparencyEnum.Transparent:
      return "transparent";
    case TransparencyEnum.Tentative:
      // Google doesn't support tentative transparency, so we use opaque instead
      return ecosystem === EcosystemEnum.Microsoft ? "tentative" : "opaque";
    default:
      return "opaque";
  }
};
