//////////////////
// IMPORTS
//////////////////
// libraries
import * as React from "react";
import { connect } from "react-redux";
// slack-settings imports
import { SlackAccount } from "#webapp/src/components/slack-account";
import { SlackButton } from "#webapp/src/components/slack-button";
import * as s from "./SlackSettings.styles";
import { IContainer, IProps, IState } from "./SlackSettingsTypes";
// state
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
// util
import { A } from "#webapp/src/components/ui";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";
// material-ui
import {
  IOnboardingChecklistSyncType,
  setSavedOnboardingChecklistSyncType,
} from "#webapp/src/state/actions/settings.actions";
import { cleanUpSlackNonce } from "#webapp/src/util/third-party-oauth.util";
import { Warning } from "@clockwise/design-system/icons";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import toast from "react-hot-toast";

//////////////////
// COMPONENT
//////////////////
class SlackSettingsCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  private onDeleteSlackAccount = () => {
    track(TrackingEvents.SLACK.DELETE);
    this.props.refetch();
  };

  private onUpdateSlackSettings = () => {
    // slack settings should instead be a relay connection so the mutation updates the record
    // and ui just works, but instead going with a refetch
    this.props.refetch();
  };

  private onAuthPopupClosed = () => {
    cleanUpSlackNonce();
    this.props.refetch();
  };

  private onLearnMore = () => {
    track(TrackingEvents.HELP.SLACK);
  };

  private handleTabChange = (_event: React.ChangeEvent, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (
      this.props.org.slackAccountsErrorable.__typename !== "GraphEntityError" &&
      nextProps.org.slackAccountsErrorable.__typename !== "GraphEntityError"
    ) {
      const accountCount = this.props.org.slackAccountsErrorable.list.length || 0;
      const nextAccountCount = nextProps.org.slackAccountsErrorable.list.length || 0;
      if (nextAccountCount > accountCount) {
        track(TrackingEvents.SLACK.ADDED);
        toast.success("Your Slack account was added!");
        this.props.setSavedOnboardingChecklistSyncType(IOnboardingChecklistSyncType.slackEnabled);
        this.setState({ selectedTab: nextAccountCount - 1 });
      }
      if (nextAccountCount >= this.state.selectedTab) {
        this.setState({ selectedTab: 0 });
      }
    }
  }

  public render() {
    const { org, hideDescription } = this.props;
    const { selectedTab } = this.state;
    const accounts =
      org.slackAccountsErrorable.__typename !== "GraphEntityError" &&
      org.slackAccountsErrorable.list;
    const settings =
      (org.slackSettingsErrorable.__typename !== "GraphEntityError" &&
        org.slackSettingsErrorable.list) ||
      undefined;

    return (
      <div>
        {!hideDescription ? (
          <>
            <div className="cw-heading-xl cw-mb-3">Slack</div>
            <div style={s.intro}>
              Let Clockwise update your Slack status automatically so your team can tell at a glance
              if you're in a Focus Time block or out of office.&nbsp;
              <A target="_blank" href={articleUrls.slack} onClick={this.onLearnMore}>
                Learn more.
              </A>
            </div>
          </>
        ) : (
          ""
        )}

        {accounts && accounts.length ? (
          <div style={s.slackAccounts}>
            <Tabs
              value={selectedTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              style={s.tabs}
              variant="scrollable"
              scrollButtons={accounts.length > 3 ? "on" : "off"}
            >
              {accounts.map((a) => {
                return (
                  <Tab
                    key={a.slackTeamId}
                    label={
                      <span style={s.tabLabel}>
                        {a.needsUpdate ? <Warning style={s.needsUpdate} /> : null}
                        {a.teamDisplayName}
                      </span>
                    }
                  />
                );
              })}
            </Tabs>
            {accounts.map((a, index) => {
              if (index === selectedTab) {
                const sl =
                  (settings &&
                    settings.find(
                      (ss) => ss.slackUserId === a.slackUserId && ss.slackTeamId === a.slackTeamId,
                    )) ||
                  null;
                if (!sl) {
                  return null;
                }
                return (
                  <div key={a.id} style={s.tabContent}>
                    <SlackAccount
                      org={org}
                      slackAccount={a}
                      slackSettings={sl}
                      onAuthPopupClosed={() => this.onAuthPopupClosed()}
                      onDelete={() => this.onDeleteSlackAccount()}
                      onUpdate={() => this.onUpdateSlackSettings()}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        ) : (
          ""
        )}
        <div style={s.addSlack}>
          <SlackButton onAuthPopupClosed={() => this.onAuthPopupClosed()} />
        </div>
      </div>
    );
  }
}

//////////////////
// REDUX
//////////////////
function mapStateToProps(_state: IReduxState, _ownProps: IContainer) {
  return {};
}

export const SlackSettings = connect(mapStateToProps, {
  setSavedOnboardingChecklistSyncType,
})(SlackSettingsCmpt);
