import { DateInput, RadioGroup, RadioOption, TextField } from "@clockwise/design-system";
import { DateTime } from "luxon";
import React, { ChangeEventHandler } from "react";

type EndFieldProps = {
  type: "count" | "until" | "none";
  count: number;
  until: DateTime;
  onChange: (v: { type: "count" | "until" | "none"; count: number; until: DateTime }) => void;
};
export const EndField = ({ type, count, until, onChange }: EndFieldProps) => {
  const handleCountChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const countString = e.target.value;
    const count = parseInt(countString, 10);
    if (count > 0) {
      onChange({ type, count, until });
    }
  };

  return (
    <RadioGroup value={type} onChange={(type) => onChange({ type, count, until })} size="small">
      <RadioOption value="none" label="Never" />
      <RadioOption
        value="until"
        label={
          <span className="cw-flex cw-items-center cw-gap-1">
            On{" "}
            <DateInput
              size="small"
              value={until.toISODate()}
              readOnly={type !== "until"}
              onChange={(date) => {
                date && onChange({ type, count, until: DateTime.fromISO(date).endOf("day") });
              }}
            />
          </span>
        }
      />
      <RadioOption
        value="count"
        label={
          <span className="cw-flex cw-items-center cw-gap-1">
            After{" "}
            <span className={type !== "count" ? "cw-pointer-events-none" : ""}>
              <TextField
                size={2}
                fieldSize="small"
                tabIndex={0}
                disabled={type !== "count"}
                defaultValue={count.toString(10)}
                onChange={handleCountChange}
              />{" "}
            </span>
            times
          </span>
        }
      />
    </RadioGroup>
  );
};
