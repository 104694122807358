import { graphql } from "react-relay";

export const query = graphql`
  query WebSettingsTeamSettingsNewRelayQuery($orgRelayId: ID!) {
    org: node(id: $orgRelayId) {
      ... on Org {
        id
        primaryOrgCalendar
        primaryOrgEmail
        workingGroups(first: 1000000) {
          edges {
            node {
              calendarIds
            }
          }
        }
        primaryTeam {
          __typename
          ... on Team {
            teamId
            teamName
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
        userTeams {
          __typename
          ... on TeamList {
            list {
              teamId
              id
              teamId
              teamName
              createdBy {
                type
                value
              }
              teamCalendarId
              settings {
                __typename
                ... on TeamSettings {
                  m365TeamCalendarDetails {
                    __typename
                    ... on TeamCalendarDetails {
                      calendarName
                      owner {
                        personId
                        primaryCalendarId
                      }
                    }
                    ... on GraphEntityError {
                      statusCode
                      message
                    }
                  }
                }
                ... on GraphEntityError {
                  message
                  statusCode
                }
              }
              userSettings {
                __typename
                ... on TeamUserSettings {
                  useTeamCalendar
                }
              }
              teamMembers {
                role
                person {
                  __typename
                  id
                  userId
                  personId
                  primaryCalendarId
                  calendarAliases
                  isYou
                  redirectPersonId
                  profile {
                    givenName
                    familyName
                    externalImageUrl
                  }
                }
              }
              invitedMembers {
                role
                person {
                  userId
                  personId
                  primaryCalendarId
                  calendarAliases
                  isYou
                  redirectPersonId
                  profile {
                    givenName
                    familyName
                    externalImageUrl
                  }
                }
              }
            }
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
        billingGroups {
          ... on BillingGroupList {
            list {
              id
              name
              syncSources {
                type
                value
              }
            }
          }
          ... on GraphEntityError {
            message
            statusCode
          }
        }
      }
    }
  }
`;
