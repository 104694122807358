import { Switch as HeadlessSwitch } from "@headlessui/react";
import classNames from "classnames";
import React, { AriaAttributes, MouseEventHandler, useRef } from "react";
import { CwIdProps } from "../types/cw-id";

type Sentiment = "neutral" | "warning";
export type Size = "xs" | "small" | "medium";
export interface Props extends CwIdProps {
  size?: Size;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  onClick?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  onEmphasis?: boolean;
  name?: string;
  tabIndex?: number;
  sentiment?: Sentiment;
  "aria-label"?: AriaAttributes["aria-label"];
  "aria-labelledby"?: AriaAttributes["aria-labelledby"];
  label?: React.ReactNode;
  labelPlacement?: "start" | "end";
}

const SIZE_CLASSES: {
  [S in Size]: {
    switch: string;
    track: string;
    thumb: string;
    outline: string;
    translate: { off: string; on: string };
  };
} = {
  xs: {
    switch: "cw-h-[14px]",
    track: "cw-h-[14px] cw-w-[24px]",
    thumb: "cw-h-[10px] cw-w-[10px]",
    outline: "cw-outline-[6px]",
    translate: {
      off: "cw-translate-x-[2px]",
      on: "cw-translate-x-[10px]",
    },
  },
  small: {
    switch: "cw-h-[18px]",
    track: "cw-h-[18px] cw-w-[30px]",
    thumb: "cw-h-[14px] cw-w-[14px]",
    outline: "cw-outline-[6px]",
    translate: {
      off: "cw-translate-x-[2px]",
      on: "cw-translate-x-[14px]",
    },
  },
  medium: {
    switch: "cw-h-6",
    track: "cw-h-6 cw-w-10",
    thumb: "cw-h-[18px] cw-w-[18px]",
    outline: "cw-outline-8",
    translate: {
      off: "cw-translate-x-[3px]",
      on: "cw-translate-x-[19px]",
    },
  },
};

const colorClassesBySentiment: {
  [S in Sentiment]: { track: string; hover: string; focus: string; active: string };
} = {
  neutral: {
    track: "cw-bg-brand-emphasis",
    hover: "group-hover:cw-outline-brand-emphasis/5",
    focus: "group-focus-visible:cw-outline-brand-emphasis/30",
    active: "group-active:cw-outline-brand-emphasis/30",
  },
  warning: {
    track: "cw-bg-warning-emphasis",
    hover: "group-hover:cw-outline-warning-emphasis/5",
    focus: "group-focus-visible:cw-outline-warning-emphasis/30",
    active: "group-active:cw-outline-warning-emphasis/30",
  },
};

// In lieu of preflight, reset some native button style props so this looks consistent
const buttonResetClasses =
  "cw-bg-none cw-bg-transparent cw-text-inherit cw-border-0 cw-p-0 cw-align-left";

export const Switch = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      size = "medium",
      checked,
      onChange,
      onClick,
      disabled = false,
      onEmphasis = false,
      sentiment = "neutral",
      label,
      labelPlacement = "end",
      ...switchProps
    },
    ref,
  ) => {
    const userInteractedRef = useRef(false);

    const handleChange = React.useCallback(
      (_checked: boolean) => {
        userInteractedRef.current = true;
        if (onChange) {
          onChange(_checked);
        }
        setTimeout(() => {
          userInteractedRef.current = false;
        }, 200);
      },
      [onChange],
    );

    const sizeClasses = SIZE_CLASSES[size];

    const onDefaultTrackColor = checked
      ? colorClassesBySentiment[sentiment].track
      : "cw-bg-neutral-emphasis/60";
    const onEmphasisTrackColor = "cw-bg-default";
    const onEmphasisThumbColor = onDefaultTrackColor;

    const trackColor = disabled
      ? "cw-bg-neutral-emphasis-disabled"
      : onEmphasis
      ? onEmphasisTrackColor
      : onDefaultTrackColor;

    const hoverColor =
      (checked && colorClassesBySentiment[sentiment].hover) ||
      "group-hover:cw-outline-default-pressed/5";
    const activeColor =
      (checked && colorClassesBySentiment[sentiment].active) ||
      "group-focus-visible:cw-outline-default-pressed/30";
    const focusColor =
      (checked && colorClassesBySentiment[sentiment].focus) || "group-active:cw-outline-default/30";

    const isReversed = labelPlacement === "start";

    return (
      <HeadlessSwitch.Group
        as="span"
        className={classNames("cw-group", "cw-inline-flex cw-items-center cw-gap-2", {
          "cw-flex-row": !isReversed,
          "cw-flex-row-reverse cw-space-x-reverse": isReversed,
        })}
      >
        <HeadlessSwitch
          {...switchProps}
          ref={ref}
          checked={checked}
          onChange={handleChange}
          onClick={onClick}
          disabled={disabled}
          className={classNames(
            buttonResetClasses,
            "focus:cw-outline-none",
            "cw-group",
            sizeClasses.switch,
            {
              "cw-cursor-pointer": !disabled,
              "cw-cursor-not-allowed": disabled,
            },
          )}
        >
          {/* The Switch */}
          <div
            className={classNames(
              "cw-inline-flex cw-shrink-0 cw-items-center",
              sizeClasses.track,
              "cw-relative",
              "focus:cw-outline-none",
              {
                "cw-cursor-not-allowed": disabled,
                "cw-cursor-pointer": !disabled,
              },
            )}
          >
            {/* Switch track */}
            <span
              aria-hidden="true"
              className={classNames(
                "cw-inline-block",
                "cw-h-full cw-w-full",
                "cw-rounded-full",
                trackColor,
                {
                  "cw-transition-colors cw-duration-200 cw-ease-in-out": userInteractedRef.current,
                },
                "cw-pointer-events-none",
              )}
            />
            {/* Switch thumb slider */}
            <span
              aria-hidden="true"
              className={classNames(
                "cw-inline-block",
                sizeClasses.thumb,
                "cw-absolute",
                "cw-rounded-full",
                sizeClasses.outline,
                "cw-outline cw-outline-transparent",
                onEmphasis ? onEmphasisThumbColor : "cw-bg-default",
                hoverColor,
                activeColor,
                focusColor,
                "cw-shadow-sm cw-shadow-neutral-emphasis/20",
                {
                  "cw-transition cw-duration-200 cw-ease-in-out": userInteractedRef.current,
                },
                "cw-pointer-events-none",
                {
                  [sizeClasses.translate.off]: !checked,
                  [sizeClasses.translate.on]: checked,
                },
              )}
            />
          </div>
        </HeadlessSwitch>
        {/* Label */}
        {label && (
          <HeadlessSwitch.Label
            className={classNames("cw-body-lg cw-text-left ", {
              "cw-text-default-disabled cw-cursor-not-allowed": disabled,
              "cw-cursor-pointer": !disabled,
            })}
            onClick={onClick}
          >
            {label}
          </HeadlessSwitch.Label>
        )}
      </HeadlessSwitch.Group>
    );
  },
);
Switch.displayName = "Switch";
