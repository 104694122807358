import { InviteModalMode } from "#webapp/src/components/invite-modal";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMonetization } from "../../hooks/useMonetization";
import { InviteModal } from "../invite-modal";

export const InviteModalWrapper = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isModalOpen, setIsOpenModal] = useState(false);
  const [mode, setMode] = useState(InviteModalMode.Invite);

  const { canUserSeeTrialExtension } = useMonetization();

  useEffect(() => {
    if (searchParams) {
      if (searchParams.get("invite") === "true") {
        setIsOpenModal(true);
        setMode(InviteModalMode.Invite);
      }
      if (searchParams.get("trialExtension") === "true" && canUserSeeTrialExtension) {
        setIsOpenModal(true);
        setMode(InviteModalMode.TrialExtension);
      }
    }
  }, [searchParams, canUserSeeTrialExtension]);

  const closeModal = () => {
    setIsOpenModal(false);
    navigate(appPaths.landing);
  };

  return <InviteModal isOpen={isModalOpen} onClose={closeModal} mode={mode} />;
};
