import { graphql } from "react-relay";
import "../sudo-delete-everything";
import "../sudo-modify-user";
import "../sudo-switch-user";
import "../sudo-trigger-client-update";

export const sudoWrapperFragments = {
  viewer: graphql`
    fragment SudoWrapper_viewer on Viewer {
      id
      user {
        id
        emails
      }
      ...SudoSwitchUser_viewer
      ...SudoModifyUser_viewer
      ...SudoDeleteEverything_viewer
      sudo {
        ...SudoTriggerClientUpdate_sudo
      }
    }
  `,
};

export const sudoWrapperQuery = graphql`
  query SudoWrapperQuery {
    viewer {
      ...SudoWrapper_viewer
    }
  }
`;
