//////////////////
// IMPORTS
//////////////////
// schema
import { EcosystemEnum, SettingInput } from "@clockwise/schema";
// libraries
import { useMutation, useQuery } from "@apollo/client";
import React from "react";

import toast from "react-hot-toast";

import { UpdateNotificationSettingMutationDocument } from "./__generated__/UpdateNotificationSetting.generated";
import { WebSettingsEmailsAndNotificationsQueryDocument } from "./__generated__/WebSettingsEmailsAndNotifications.generated";
import { BrowserNotificationIllustration } from "./BrowserNotificationIllustration";
import { NotificationProvider, useNotification } from "./useNotification";
import {
  browserNotificationPermissionGranted,
  receiveDefragMoveValueSummaryEmailSetting,
  receiveInviteSuggestionsEmailSetting,
  receiveManageOneOnOnesEmailSetting,
  receiveMeetingFlexibilitySuggestionNotification,
  receiveNowOnAutopilotEmailSetting,
  receiveWeekInReviewEmailSetting,
  receiveYourWeekAheadEmailSetting,
  sendNowOnAutopilotEmailSetting,
} from "./web-settings-emails-and-notifications";

// components
import { WebSettingsContainer } from "../web-settings-container";
import { BrowserNotificationPermissionDialog } from "./BrowserNotificationPermissionDialog";

import { ServiceWorkerPostMessagePayload } from "#webapp/src/util/service-worker.util";

import { ScrollIntoView } from "../../ui-focus-wrappers/ScrollIntoView";

// constants
// import { paths } from "@clockwise/client-commons/src/constants/site";

// util
import { page, PageEvents, track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { getCurrentOrg } from "#webapp/src/util/org.util";

// material-ui
import { Button, Checkbox, Link } from "@clockwise/design-system";
import { DisabledWrapper } from "@clockwise/web-commons/src/ui/disabled-wrapper";
import { makeStyles } from "@material-ui/styles";
import { WebSettingsEmailsAndNotificationsSkeleton } from "../../account-settings/AccountSettingsSkeleton";

import {
  requestNotificationPermission,
  showLocalNotification,
} from "#webapp/src/util/notification.util";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import { useNavigate } from "react-router-dom";

// ~-~-~-~-~-~-~-
// Web Settings Emails and notifications
// ~-~-~-~-~-~-~-
const useStyles = makeStyles({
  section: {
    marginBottom: 69,
  },
});

const WebSettingsEmailsAndNotifications = () => {
  const {
    isOnBrowserNotifications,
    ecosystem,
    showBrowserNotificationPermissionDialog,
    setShowBrowserNotificationPermissionDialog,
  } = useNotification();

  const classes = useStyles();

  const [updateSetting] = useMutation(UpdateNotificationSettingMutationDocument, {
    onError: (error) => {
      logger.error(`failed to updateSetting: ${error.message}`, error);
    },
  });

  const navigate = useNavigate();

  const updateEmailAndNotificationSetting = (checked: boolean, setting: SettingInput) => {
    const synchronousUpdateSetting = async () => {
      await updateSetting({
        variables: {
          input: { setting, value: [checked ? "true" : "false"] },
        },
      });
    };

    synchronousUpdateSetting();
  };

  React.useEffect(() => {
    page(PageEvents.WEB_SETTINGS_EMAILS_AND_NOTIFICATIONS);
  }, []);

  const { loading, data, error } = useQuery(WebSettingsEmailsAndNotificationsQueryDocument);

  if (error) {
    logger.error(`failed to load WebSettingsEmailsAndNotifications: ${error}`);
    return (
      <WebSettingsContainer>
        An error occurred while loading Clockwise:
        <br />
        {error.message}
      </WebSettingsContainer>
    );
  }

  if (!data || loading) {
    return <WebSettingsEmailsAndNotificationsSkeleton />;
  }

  const viewer = data.viewer;
  const org = getCurrentOrg(viewer);

  if (!org) {
    return <WebSettingsEmailsAndNotificationsSkeleton />;
  }

  // ~-~-~-~-~-~-~-
  // Helper
  // ~-~-~-~-~-~-~-
  const linkToSlack = `${getUrlForFeature("appSettings", { relative: true })}?highlight=true#slack`;
  const handleClickSlack = (event: React.SyntheticEvent) => {
    event.preventDefault();
    track(TrackingEvents.WEB_SETTINGS.EMAILS_AND_NOTIFICATIONS.SLACK_NOTIFICATIONS_LINK_CLICKED);
    const urlForFeature = getUrlForFeature("appSettings", {
      paramsObj: { highlight: "true" },
      hash: "#slack",
      relative: true,
    });
    navigate(urlForFeature);
  };

  const onChange = (setting: SettingInput, event: string) => (checked: boolean) => {
    track(event, { isOn: checked });
    updateEmailAndNotificationSetting(checked, setting);
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  const renderBrowserNotificationSettings = () => {
    return (
      <>
        <h3 className="cw-heading-xl cw-mb-4">Push notifications</h3>
        <div>
          {!browserNotificationPermissionGranted.isOn(org) ||
          Notification.permission !== "granted" ? (
            <Button
              sentiment="positive"
              onClick={async () => {
                // if permission is already denied in browser, show dialog
                if (Notification.permission === "denied") {
                  return setShowBrowserNotificationPermissionDialog(true);
                }

                // request notification
                const permission = await requestNotificationPermission();
                const isNotificationPermissionGranted = permission === "granted";

                // save response
                updateEmailAndNotificationSetting(
                  isNotificationPermissionGranted,
                  browserNotificationPermissionGranted.get(org)!,
                );

                track(
                  TrackingEvents.WEB_SETTINGS.EMAILS_AND_NOTIFICATIONS
                    .TOGGLE_BROWSER_NOTIFICATION_PERMISSION_GRANTED_SETTING,
                  { isOn: isNotificationPermissionGranted },
                );

                // handle not granted
                if (!isNotificationPermissionGranted) {
                  return toast.error("Failed to grant notification permission in your browser");
                }

                // handle granted
                navigator.serviceWorker.ready.then((registration) => {
                  const payload: ServiceWorkerPostMessagePayload = {
                    message: "cw-notification-permission-granted",
                    data: {},
                  };
                  registration.active?.postMessage(payload);
                });

                toast.success("Success! Push notifications have been enabled.");
              }}
            >
              Enable browser notifications
            </Button>
          ) : (
            ""
          )}
        </div>
        <div className="cw-w-full cw-p-8 cw-my-4 cw-flex cw-items-center cw-justify-center cw-bg-neutral cw-relative">
          <BrowserNotificationIllustration />
          {browserNotificationPermissionGranted.isOn(org) && Notification.permission === "granted" && (
            <div
              className="cw-absolute cw-cursor-pointer cw-text-positive cw-underline cw-top-2 cw-right-2 cw-text-sm"
              onClick={() => {
                navigator.serviceWorker.ready.then((registration) => {
                  showLocalNotification({ body: `Test notification.` }, registration);
                });
              }}
            >
              Send test notification
            </div>
          )}
        </div>
        <h5 className="cw-heading-lg">Notify me when</h5>
        <DisabledWrapper
          isDisabled={
            !browserNotificationPermissionGranted.isOn(org) || Notification.permission !== "granted"
          }
          tooltipBody={"Please enable push notifications to edit these settings."}
          width={280}
        >
          <Checkbox
            checked={receiveMeetingFlexibilitySuggestionNotification.isOn(org)}
            onChange={onChange(
              receiveMeetingFlexibilitySuggestionNotification.get(org)!,
              TrackingEvents.WEB_SETTINGS.EMAILS_AND_NOTIFICATIONS
                .TOGGLE_RECEIVE_FLEXIBLE_MEETING_NOTIFICATION_NOTIFICATION_SETTING,
            )}
            label={"Enable meeting flexibility push notifications"}
            disabled={
              !browserNotificationPermissionGranted.isOn(org) ||
              Notification.permission !== "granted"
            }
          />
        </DisabledWrapper>
        {showBrowserNotificationPermissionDialog && (
          <BrowserNotificationPermissionDialog
            onClose={() => setShowBrowserNotificationPermissionDialog(false)}
          />
        )}
      </>
    );
  };

  return (
    <ScrollIntoView focusSection="email" highlight>
      <WebSettingsContainer>
        <h1 className="cw-heading-3xl">Emails and notifications</h1>
        <div className="cw-heading-caption cw-pb-6">
          There are certain high-priority emails related to account functionality that you will
          receive regardless of subscription status.
        </div>
        <div className={classes.section}>
          <h3 className="cw-heading-xl cw-mb-4">Flexible meeting emails</h3>
          <div>
            <Checkbox
              checked={sendNowOnAutopilotEmailSetting.isOn(org)}
              onChange={onChange(
                sendNowOnAutopilotEmailSetting.get(org)!,
                TrackingEvents.WEB_SETTINGS.EMAILS_AND_NOTIFICATIONS
                  .TOGGLE_SEND_NOW_ON_AUTOPILOT_EMAIL_SETTING,
              )}
              label={"Notify non-Clockwise attendees when you mark a meeting as flexible"}
            />
          </div>
          <div>
            <Checkbox
              checked={receiveNowOnAutopilotEmailSetting.isOn(org)}
              onChange={onChange(
                receiveNowOnAutopilotEmailSetting.get(org)!,
                TrackingEvents.WEB_SETTINGS.EMAILS_AND_NOTIFICATIONS
                  .TOGGLE_RECEIVE_NOW_ON_AUTOPILOT_EMAIL_SETTING,
              )}
              label={"Get notified when others mark a meeting you're attending as flexible"}
            />
          </div>
          <div>
            <Checkbox
              checked={receiveDefragMoveValueSummaryEmailSetting.isOn(org)}
              onChange={onChange(
                receiveDefragMoveValueSummaryEmailSetting.get(org)!,
                TrackingEvents.WEB_SETTINGS.EMAILS_AND_NOTIFICATIONS
                  .TOGGLE_RECEIVE_DEFRAG_MOVE_VALUE_SUMMARY_EMAIL_SETTING,
              )}
              label={"Summary of your flexible meeting updates"}
            />
          </div>
          {ecosystem === EcosystemEnum.Microsoft && (
            <div>
              <Checkbox
                checked={receiveManageOneOnOnesEmailSetting.isOn(org)}
                onChange={onChange(
                  receiveManageOneOnOnesEmailSetting.get(org)!,
                  TrackingEvents.WEB_SETTINGS.EMAILS_AND_NOTIFICATIONS
                    .TOGGLE_RECEIVE_MANAGE_ONE_ON_ONES_EMAIL_SETTING,
                )}
                label={
                  "Receive periodic suggestions for which meetings we think you should make flexible"
                }
              />
            </div>
          )}
        </div>
        <div className={classes.section}>
          <h3 className="cw-heading-xl cw-mb-4">Other emails</h3>
          <div>
            <Checkbox
              checked={receiveWeekInReviewEmailSetting.isOn(org)}
              onChange={onChange(
                receiveWeekInReviewEmailSetting.get(org)!,
                TrackingEvents.WEB_SETTINGS.EMAILS_AND_NOTIFICATIONS
                  .TOGGLE_RECEIVE_WEEK_IN_REVIEW_EMAIL_SETTING,
              )}
              label={"Your week in review"}
            />
          </div>
          <div>
            <Checkbox
              checked={receiveYourWeekAheadEmailSetting.isOn(org)}
              onChange={onChange(
                receiveYourWeekAheadEmailSetting.get(org)!,
                TrackingEvents.WEB_SETTINGS.EMAILS_AND_NOTIFICATIONS
                  .TOGGLE_RECEIVE_YOUR_WEEK_AHEAD_EMAIL_SETTING,
              )}
              label={"Your week ahead"}
            />
          </div>
          <div>
            <Checkbox
              checked={receiveInviteSuggestionsEmailSetting.isOn(org)}
              onChange={onChange(
                receiveInviteSuggestionsEmailSetting.get(org)!,
                TrackingEvents.WEB_SETTINGS.EMAILS_AND_NOTIFICATIONS
                  .TOGGLE_RECEIVE_INVITE_SUGGESTIONS_EMAIL_SETTING,
              )}
              label={"Suggestions for inviting coworkers"}
            />
          </div>
        </div>
        <div className={classes.section}>
          <h3 className="cw-heading-xl cw-mb-4">Slack notifications</h3>
          <div className="cw-body-lg">
            <Link href={linkToSlack} onClick={handleClickSlack}>
              Go to Clockwise in Slack
            </Link>{" "}
            to manage or turn off your Slack notifications.
          </div>
        </div>
        {isOnBrowserNotifications && renderBrowserNotificationSettings()}
      </WebSettingsContainer>
    </ScrollIntoView>
  );
};

export const WebSettingsEmailsAndNotificationsWrapper = () => {
  return (
    <NotificationProvider>
      <WebSettingsEmailsAndNotifications />
    </NotificationProvider>
  );
};
