import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CalendarWeekDNDContext } from "../components/root/DndContext";

export const useScrollTopOfScrollContainerBeforeDragging = ({
  scrollContainerRef,
}: {
  scrollContainerRef: React.RefObject<HTMLDivElement> | null;
}) => {
  const { isDragging: eventIsDragging } = useContext(CalendarWeekDNDContext);

  const [
    scrollTopOfScrollContainerBeforeDragging,
    setScrollTopOfScrollContainerBeforeDragging,
  ] = useState<number>(scrollContainerRef?.current?.scrollTop || 0);

  const debouncedSetScrollTopOfScrollContainerBeforeDragging = useCallback(
    debounce(setScrollTopOfScrollContainerBeforeDragging, 100, { trailing: true }),
    [setScrollTopOfScrollContainerBeforeDragging],
  );

  useEffect(() => {
    if (!scrollContainerRef) return;

    // Initial `scrollContainerScrollTopBeforeDragging` when `scrollContainerRef` is ready
    setScrollTopOfScrollContainerBeforeDragging(scrollContainerRef?.current?.scrollTop || 0);
  }, [scrollContainerRef]);

  useEffect(() => {
    if (eventIsDragging) return;

    // stop the race condition by `cancel` the debounced
    debouncedSetScrollTopOfScrollContainerBeforeDragging.cancel();
    // set `scrollContainerScrollTopBeforeDragging` when `eventIsDragging` is complete
    setScrollTopOfScrollContainerBeforeDragging(scrollContainerRef?.current?.scrollTop || 0);
  }, [eventIsDragging]);

  return useMemo(() => {
    return {
      scrollTopOfScrollContainerBeforeDragging,
      debouncedSetScrollTopOfScrollContainerBeforeDragging,
    };
  }, [
    scrollTopOfScrollContainerBeforeDragging,
    debouncedSetScrollTopOfScrollContainerBeforeDragging,
  ]);
};
