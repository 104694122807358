import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CurrentProposalMembersQueryVariables = Types.Exact<{
  calendarIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type CurrentProposalMembersQuery = { __typename: 'Query', members: Array<{ __typename: 'Member', id: string, user: { __typename: 'User', id: string } | null, person: { __typename: 'Person', id: string, email: string, displayName: string, givenName: string | null, familyName: string | null, externalImageUrl: string | null, isMe: boolean } } | null> };


export const CurrentProposalMembersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentProposalMembers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"calendarIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"members"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"calendarIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"calendarIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"person"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"givenName"}},{"kind":"Field","name":{"kind":"Name","value":"familyName"}},{"kind":"Field","name":{"kind":"Name","value":"externalImageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"isMe"}}]}}]}}]}}]} as unknown as DocumentNode<CurrentProposalMembersQuery, CurrentProposalMembersQueryVariables>;