import { AIChatCollapseProvider } from "@clockwise/web-commons/src/util/AIChatCollapseContext";
import { ActiveEventProvider } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { CalendarProvider } from "@clockwise/web-commons/src/util/CalendarContext";
import { HighlightEventsProvider } from "@clockwise/web-commons/src/util/HighlightEventsContext";
import { TimeSuggestionProvider } from "@clockwise/web-commons/src/util/TimeSuggestionContext";
import { WeekStartDays } from "@clockwise/web-commons/src/util/calendarReducer";
import { getPreviousWeekday } from "@clockwise/web-commons/src/util/getPreviousWeekday";
import { getSequentialDays } from "@clockwise/web-commons/src/util/getSequentialDays";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime } from "luxon";
import React, { ReactNode, useMemo } from "react";
import { ActiveEventDiffProvider } from "../chat-plus-calendar/util/ActiveDiffContext";
import { ProposalOptionsOverlayProvider } from "../chat-plus-calendar/util/ProposalOptionsOverlayContext";
import { ProposalOptionsOverlayProviderV2 } from "../chat-plus-calendar/util/ProposalOptionsOverlayContextV2";
import { TimeSuggestionPeekProvider } from "../chat-plus-calendar/util/TimeSuggestionPeekContext";
import { CommandBarProvider } from "../chat/ai-chat/hooks/CommandBarContext";
import { CalendarColorsProvider } from "../web-app-calendar/hooks/CalendarColorsContext";
import { DialogProvider } from "../web-app-calendar/notification/DialogContext";

const Context = ({ children }: { children: ReactNode }) => {
  const zone = getRenderTimeZone();

  // setup inital state for calendar
  const dateTime = DateTime.now().setZone(zone).startOf("day");
  const startOfWeek = getPreviousWeekday(dateTime, "monday");
  const visibleDates = getSequentialDays(startOfWeek, 5).map((date) => date.toISODate());

  const initCalendarState = useMemo(
    () => ({
      focusedDate: dateTime.toISODate(),
      visibleDates,
      weekendsShown: false,
      weekStartDay: "monday" as WeekStartDays,
      anchorDate: null,
    }),
    [dateTime, visibleDates],
  );

  return (
    <DialogProvider>
      <HighlightEventsProvider>
        <CalendarColorsProvider>
          <CalendarProvider initValue={initCalendarState}>
            <AIChatCollapseProvider>
              <ActiveEventDiffProvider>
                <ActiveEventProvider>
                  <ProposalOptionsOverlayProvider initValue={false}>
                    <ProposalOptionsOverlayProviderV2
                      initValue={{
                        isVisible: false,
                        isEnabled: false,
                        hoveredSuggestion: null,
                      }}
                    >
                      <TimeSuggestionProvider>
                        <TimeSuggestionPeekProvider>
                          <CommandBarProvider>{children}</CommandBarProvider>
                        </TimeSuggestionPeekProvider>
                      </TimeSuggestionProvider>
                    </ProposalOptionsOverlayProviderV2>
                  </ProposalOptionsOverlayProvider>
                </ActiveEventProvider>
              </ActiveEventDiffProvider>
            </AIChatCollapseProvider>
          </CalendarProvider>
        </CalendarColorsProvider>
      </HighlightEventsProvider>
    </DialogProvider>
  );
};

export default Context;
