import {
  EventContainer,
  NamedEvent,
  UnnamedEvent,
} from "@clockwise/web-commons/src/components/feature-illustration/";
import { Drop } from "phosphor-react";
import React from "react";

export const MeetingBreakEvent = () => (
  <NamedEvent duration={15} Icon={Drop} title="Meeting Break" />
);

const MeetingBreakIllustration = () => {
  return (
    <EventContainer>
      <UnnamedEvent duration={10} />
      <UnnamedEvent duration={20} />
      <MeetingBreakEvent />
      <UnnamedEvent duration={20} />
      <UnnamedEvent duration={20} />
    </EventContainer>
  );
};

export default MeetingBreakIllustration;
