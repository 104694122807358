import { Select, SelectOption, SelectOptionsGroup } from "@clockwise/design-system";
import { Business } from "@clockwise/design-system/icons";
import { makeStyles } from "@material-ui/core";
import * as React from "react";

import { Home, LocationOff, LocationOn } from "@clockwise/icons";
import { LocationTypeEnum } from "@clockwise/schema";
import { colors, greys, helpers } from "@clockwise/web-commons/src/styles/color.styles";
import { fontFamilySans } from "@clockwise/web-commons/src/styles/type.styles";
import { WorkingLocationSelectValueEnum } from "../WorkingLocation.util";
import { useWorkingLocation } from "../hooks/useWorkingLocation";
import { WorkingLocationAddressDialog } from "./WorkingLocationAddressDialog";

export interface DaySettingProps {
  day?: string;
  onSelectLocation: (workingLocationId: string | null) => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",

    "& .MuiIconButton-root": {
      padding: 0,
      margin: "0px 5px 0px 10px",
    },
  },
  picker: {
    display: "flex",
    alignItems: "center",
    maxWidth: 180,
    width: "100%",
    height: 36,
    color: greys.standard,
    fontFamily: fontFamilySans,
    fontSize: 14,
    margin: "4px 0px",
  },
  invalid: {
    background: helpers.opacity(colors.red.standard, 0.3),
  },
});

const workingLocationTypeIcon = (type: LocationTypeEnum) => {
  switch (type) {
    case LocationTypeEnum.HOME:
      return Home;
    case LocationTypeEnum.OFFICE:
      return Business;
    case LocationTypeEnum.OTHER:
      return LocationOff;
  }
};

export const WorkingLocationDaySetting = ({ day, onSelectLocation }: DaySettingProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const { workingLocations, workingLocationWeeklySchedule } = useWorkingLocation();
  const setting = workingLocationWeeklySchedule.find((w) => w.day === day);
  let options = (workingLocations || []).map((wl) => ({
    id: wl.id,
    locationType: wl.locationType,
    name: wl.name,
    timeZone: wl.timeZone,
    address: wl.address,
  }));

  // if setting's working location is not in the user's defined options list from settings, add it.
  if (setting?.workingLocation && !options.find((o) => o.id === setting.workingLocation.id)) {
    options.unshift(setting.workingLocation);
  }

  return (
    <>
      <div className="cw-flex cw-flex-col">
        <div className={classes.container}>
          <div className={classes.picker}>
            <Select
              size="medium"
              variant="default"
              value={setting?.workingLocation.id || WorkingLocationSelectValueEnum.None}
              onChange={(value) => {
                if (value === WorkingLocationSelectValueEnum.Manage) {
                  setDialogOpen(true);
                } else if (value === WorkingLocationSelectValueEnum.None) {
                  onSelectLocation(null);
                } else {
                  onSelectLocation(value);
                }
              }}
              hideIcon
              label={`Working location for ${day}`}
              hideLabel={true}
            >
              <SelectOptionsGroup>
                {(options || []).map((workingLocation) => (
                  <SelectOption
                    key={`working-locatino-option-${workingLocation.id}`}
                    value={workingLocation.id}
                    icon={workingLocationTypeIcon(workingLocation.locationType)}
                  >
                    {workingLocation.name}
                  </SelectOption>
                ))}
              </SelectOptionsGroup>
              <SelectOptionsGroup>
                <SelectOption
                  key={WorkingLocationSelectValueEnum.None}
                  value={WorkingLocationSelectValueEnum.None}
                  icon={LocationOff}
                >
                  None
                </SelectOption>
                <SelectOption
                  key={WorkingLocationSelectValueEnum.Manage}
                  value={WorkingLocationSelectValueEnum.Manage}
                  icon={LocationOn}
                >
                  Manage working locations
                </SelectOption>
              </SelectOptionsGroup>
            </Select>
          </div>
        </div>
      </div>
      {dialogOpen && (
        <WorkingLocationAddressDialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
        />
      )}
    </>
  );
};
