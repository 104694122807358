import { fg_muted } from "@clockwise/design-system/tokens";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { noop } from "lodash";
import { X } from "phosphor-react";
import React, { createContext, ReactNode, useContext, useRef, useState } from "react";
import { useBoolean } from "usehooks-ts";
import { FeedbackThumbs } from "../FeedbackThumbs";

const ABOVE_EVERYTHING = 10000;

type ProviderContext = readonly [(content: ReactNode, option?: DialogOption) => void, () => void];

type DialogParams = {
  actions?: ReactNode;
  children: ReactNode;
  feedbackUrlNegative?: string;
  feedbackUrlPositive?: string;
  onClose?: () => void;
  onExited?: () => void;
  onFeedback?: (isPositive: boolean) => void;
  open: boolean;
  title?: string;
};

type DialogOption = Omit<DialogParams, "open" | "children">;

type DialogContainerProps = DialogParams & {
  onClose: () => void;
};

const DialogContext = createContext<ProviderContext>([noop, noop]);

const DialogContainer = ({
  actions,
  children,
  feedbackUrlNegative,
  feedbackUrlPositive,
  onClose,
  onFeedback,
  title,
}: DialogContainerProps) => {
  const hasFeedback = feedbackUrlNegative && feedbackUrlPositive;
  const hasFooter = actions || hasFeedback;

  return (
    <Dialog open onClose={onClose} data-testid="dialog-dialog">
      <Transition.Child
        className="cw-fixed cw-inset-0"
        enter="cw-transition cw-duration-100 cw-ease-out"
        enterFrom="cw-opacity-0 cw-bg-[#000]"
        enterTo="cw-opacity-30 cw-bg-[#000]"
        leave="cw-transition cw-duration-100 cw-ease-out"
        leaveFrom="cw-opacity-30 cw-bg-[#000]"
        leaveTo="cw-opacity-0 cw-bg-[#000]"
        onClick={onClose}
        style={{ zIndex: ABOVE_EVERYTHING }}
      >
        <Dialog.Overlay
          className="cw-fixed cw-inset-0 cw-opacity-30 cw-bg-[#000]"
          onClick={onClose}
          style={{ zIndex: ABOVE_EVERYTHING }}
        />
      </Transition.Child>
      <div
        className="cw-fixed cw-inset-0 cw-w-screen cw-overflow-y-auto cw-p-4"
        style={{ zIndex: ABOVE_EVERYTHING }}
      >
        <div className="cw-flex cw-min-h-full cw-items-center cw-justify-center">
          <Transition.Child
            enter="cw-transition cw-duration-300 cw-ease-out"
            enterFrom="cw-transform cw-scale-90 cw-opacity-0"
            enterTo="cw-transform cw-scale-100 cw-opacity-100"
            leave="cw-transition cw-duration-300 cw-ease-out"
            leaveFrom="cw-transform cw-scale-100 cw-opacity-100"
            leaveTo="cw-transform cw-scale-90 cw-opacity-0"
          >
            <Dialog.Panel
              className={classNames(
                "cw-rounded-lg",
                "cw-overflow-hidden",
                "cw-shadow-2xl",
                "focus:cw-ring-0 focus:cw-ring-offset-0 focus:cw-outline-none",
                "cw-bg-default",
                "cw-flex cw-flex-col cw-space-y-1",
                "cw-body-base cw-font-body cw-font-normal cw-text-13",
              )}
            >
              <Dialog.Title
                className={classNames(
                  "cw-h-8",
                  "cw-flex cw-justify-between cw-items-center cw-w-full",
                  "cw-py-0.5 cw-px-3",
                  "cw-bg-neutral cw-border-b cw-border-solid cw-border-subtle",
                  "cw-font-semibold cw-text-default",
                )}
              >
                <div>{title}</div>
                <X
                  className={classNames("cw-cursor-pointer")}
                  onClick={onClose}
                  size={16}
                  color={fg_muted}
                  weight="fill"
                />
              </Dialog.Title>
              <div>
                <div
                  className={classNames(
                    "cw-min-w-[400px] cw-max-w-[600px] cw-min-h-[60px] cw-max-h-[550px] cw-overflow-auto",
                  )}
                >
                  <div className={classNames("cw-p-4 cw-pb-6")}>{children}</div>
                </div>
              </div>
              {hasFooter && (
                <div
                  className={classNames(
                    "cw-flex cw-flex-row-reverse cw-justify-between cw-items-center cw-w-full",
                    "cw-py-2 cw-px-3",
                    "cw-border-t cw-border-solid cw-border-subtle",
                  )}
                >
                  <div>{actions}</div>
                  {hasFeedback && (
                    <FeedbackThumbs
                      feedbackUrlNegative={feedbackUrlNegative}
                      onClick={onFeedback}
                      feedbackUrlPositive={feedbackUrlPositive}
                      size={16}
                    />
                  )}
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  );
};

export const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [dialog, setDialog] = useState<DialogParams | null>(null);
  const { value: show, setValue: setShow } = useBoolean(false);

  const openDialog = (content: ReactNode, option: DialogOption) => {
    const dialog = { ...option, open: true, children: content };
    setDialog(dialog);
    setShow(true);
  };

  const closeDialog = () => {
    setShow(false);
  };

  const handleAnimationEnd = () => {
    setDialog(null);
  };

  const contextValue = useRef([openDialog, closeDialog] as const);

  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      <Transition show={show} afterLeave={handleAnimationEnd}>
        {dialog && <DialogContainer onClose={closeDialog} {...dialog} />}
      </Transition>
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
