import { TradeoffTypeEnum } from "@clockwise/schema";
import { EventThread } from "@clockwise/web-commons/src/ui/event-thread/";
import { isEmpty, partition } from "lodash";
import React, { useEffect } from "react";
import { useBoolean } from "usehooks-ts";
import { ProposalRenderSurface } from "../../../../constants";
import { proposalIsScheduleByMoving } from "../../../../util/proposalIsScheduleByMoving";
import { GQLAssistantMessageProposalResponse } from "../../../utils/types";
import { ProposalDiffBlocks } from "../../diffs/ProposalDiffBlocks";
import { ConsequencesWrapper } from "./ConsequencesWrapper";
import { TradeoffsWrapper } from "./TradeoffsWrapper";
import { removeTradeoffsEventsAlsoInConsequences } from "./util/proposal-thread.util";

export const ProposalThread = ({
  proposal,
  variant = "AI_CHAT",
  viewerIsProposalOwner = true,
}: {
  proposal: GQLAssistantMessageProposalResponse["proposal"];
  variant?: ProposalRenderSurface;
  viewerIsProposalOwner?: boolean;
}) => {
  const { value: isSBMCollapsed, setTrue: setSBMCollapsed, setFalse: setSBMExpanded } = useBoolean(
    false,
  );
  const {
    value: isUnavailableCollapsed,
    setTrue: setUnavailableCollapsed,
    setFalse: setUnavailableExpanded,
  } = useBoolean(true);
  const {
    value: isInconvenienceCollapsed,
    setTrue: setInconvenienceCollapsed,
    setFalse: setInconvenienceExpanded,
  } = useBoolean(true);
  const renderedInSharedProposal = variant === "SHARED_PROPOSAL";
  const { consequencesBlock, diffBlocks } = proposal;
  const yourCalDiffs = consequencesBlock?.yourCalDiffs || [];
  const otherCalDiffs = consequencesBlock?.otherCalDiffs || [];
  const activeDiff = diffBlocks.find((diffBlock) => diffBlock.diffs.find((diff) => diff.active));
  const hasConsequences = !isEmpty(yourCalDiffs) || !isEmpty(otherCalDiffs);
  const isScheduleByMoving = proposalIsScheduleByMoving(proposal);

  const tradeoffBlocksWithoutOutofTimeRange =
    activeDiff?.diffs
      .map((diff) => diff.tradeoffBlocks)
      .flat()
      .filter(
        (tradeoffBlock) =>
          ![TradeoffTypeEnum.OUT_OF_TIME_RANGE].includes(tradeoffBlock.tradeoffType),
      ) || [];

  const [tradeoffBlocks, inconveniences] = partition(
    tradeoffBlocksWithoutOutofTimeRange,
    (tradeoffBlock) =>
      ![TradeoffTypeEnum.INCONVENIENCE, TradeoffTypeEnum.LOW_VIZ_INCONVENIENCE].includes(
        tradeoffBlock.tradeoffType,
      ),
  );

  const unavailableTradeoffs = removeTradeoffsEventsAlsoInConsequences({
    tradeoffBlocks,
    yourCalDiffs,
    otherCalDiffs,
  });

  const hasTradeoffThreadItems = unavailableTradeoffs.length > 0;
  const hasInconveniences = inconveniences.length > 0;

  const allSchedulingTradeoffs = unavailableTradeoffs
    .map((tradeoffBlock) => tradeoffBlock.schedulingTradeoffs)
    .flat();

  const allInconviniences = inconveniences
    .map((tradeoffBlock) => tradeoffBlock.schedulingTradeoffs)
    .flat();

  useEffect(() => {
    if (allSchedulingTradeoffs.length === 1 && isUnavailableCollapsed) {
      setUnavailableExpanded();
    }
    if (allInconviniences.length === 1 && isInconvenienceCollapsed) {
      setInconvenienceExpanded();
    }
  }, [allSchedulingTradeoffs.length, allInconviniences.length]);

  return (
    <div cw-id="proposal-response" className="cw-flex-col cw-w-full">
      <EventThread>
        <EventThread.Source>
          <ProposalDiffBlocks
            diffBlocks={diffBlocks}
            isScheduleByMoving={isScheduleByMoving}
            tradeoffTypes={[TradeoffTypeEnum.OUT_OF_TIME_RANGE]}
            options={proposal.options.__typename === "SchedulingOptions" ? proposal.options : null}
            variant={variant}
            viewerIsProposalOwner={viewerIsProposalOwner}
          />
        </EventThread.Source>
        {hasTradeoffThreadItems && (
          <TradeoffsWrapper
            isCollapsed={isUnavailableCollapsed}
            onCollapse={setUnavailableCollapsed}
            onExpand={setUnavailableExpanded}
            tradeoffs={unavailableTradeoffs}
            tradeoffGroupType="unavailable"
            variant={variant}
          />
        )}
        {hasConsequences && (
          <ConsequencesWrapper
            isCollapsed={isSBMCollapsed}
            onCollapse={setSBMCollapsed}
            onExpand={setSBMExpanded}
            showHeader={
              !renderedInSharedProposal || (renderedInSharedProposal && !isEmpty(yourCalDiffs))
            }
            isScheduleByMoving={isScheduleByMoving}
            diffs={[...yourCalDiffs, ...otherCalDiffs]}
            variant={variant}
            viewerIsProposalOwner={viewerIsProposalOwner}
          />
        )}
        {hasInconveniences && (
          <TradeoffsWrapper
            isCollapsed={isInconvenienceCollapsed}
            onCollapse={setInconvenienceCollapsed}
            onExpand={setInconvenienceExpanded}
            tradeoffs={inconveniences}
            tradeoffGroupType="inconvenience"
          />
        )}
      </EventThread>
    </div>
  );
};
