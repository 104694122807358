import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EventFlexibilityQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  calendarId: Types.Scalars['ID'];
}>;


export type EventFlexibilityQuery = { __typename: 'Query', event: { __typename: 'Event', id: string, canMarkFlexible: boolean, flexibility: { __typename: 'FlexDetails', isFlexible: boolean, status: Types.FlexStatus, flexRange: Types.FlexRange | null, allowedDays: Array<Types.DayOfWeek> | null, timeOfDayFlexRange: { __typename: 'TimeRange', start: string | null, end: string | null } | null } | null } | null };

export type UpdateEventFlexibilityMutationVariables = Types.Exact<{
  events: Array<Types.EventFlexibilityInput> | Types.EventFlexibilityInput;
}>;


export type UpdateEventFlexibilityMutation = { __typename: 'Mutation', updateEventsFlexibility: Array<{ __typename: 'Event', id: string, flexibility: { __typename: 'FlexDetails', isFlexible: boolean, status: Types.FlexStatus, flexRange: Types.FlexRange | null, allowedDays: Array<Types.DayOfWeek> | null, timeOfDayFlexRange: { __typename: 'TimeRange', start: string | null, end: string | null } | null } | null } | null> };


export const EventFlexibilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EventFlexibility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"calendarId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"event"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"calendarId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"calendarId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"flexibility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isFlexible"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"timeOfDayFlexRange"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"start"}},{"kind":"Field","name":{"kind":"Name","value":"end"}}]}},{"kind":"Field","name":{"kind":"Name","value":"flexRange"}},{"kind":"Field","name":{"kind":"Name","value":"allowedDays"}}]}},{"kind":"Field","name":{"kind":"Name","value":"canMarkFlexible"}}]}}]}}]} as unknown as DocumentNode<EventFlexibilityQuery, EventFlexibilityQueryVariables>;
export const UpdateEventFlexibilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateEventFlexibility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"events"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EventFlexibilityInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateEventsFlexibility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"events"},"value":{"kind":"Variable","name":{"kind":"Name","value":"events"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"flexibility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isFlexible"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"timeOfDayFlexRange"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"start"}},{"kind":"Field","name":{"kind":"Name","value":"end"}}]}},{"kind":"Field","name":{"kind":"Name","value":"flexRange"}},{"kind":"Field","name":{"kind":"Name","value":"allowedDays"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateEventFlexibilityMutation, UpdateEventFlexibilityMutationVariables>;