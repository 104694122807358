/**
 * @generated SignedSource<<41c471cded83ec18438d5772472ca04d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChromeSlackWrapper_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ChromeSlackVerifier_viewer">;
  readonly " $fragmentType": "ChromeSlackWrapper_viewer";
};
export type ChromeSlackWrapper_viewer$key = {
  readonly " $data"?: ChromeSlackWrapper_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChromeSlackWrapper_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChromeSlackWrapper_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChromeSlackVerifier_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "4b91a2cd5602cd8ba903671c70c33a41";

export default node;
