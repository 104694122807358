/////////////////
// IMPORTS
//////////////////
// libraries
import * as React from "react";
import { createFragmentContainer } from "react-relay";
// sudo-delete-everything imports
import { styles as sudoStyles } from "#webapp/src/components/sudo-wrapper/SudoWrapper.styles";
import { sudoDeleteEverythingFragments } from "./SudoDeleteEverything.gql";
import { styles } from "./SudoDeleteEverything.styles";
import { IContainer, IProps, IState } from "./SudoDeleteEverythingTypes";
// state
// util
import { windowLocation } from "#webapp/src/util/location.util";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
// mutations
import { sudoDeleteEverything } from "#webapp/src/mutations";
// material-ui
import { Button, LinearProgress } from "@clockwise/design-system";
import { Paper } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import toast from "react-hot-toast";

//////////////////
// COMPONENT
//////////////////
class SudoDeleteEverythingCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  private onSuccess = () => {
    toast.success("Success! Deleted everything!");
    setTimeout(() => {
      windowLocation.assign("SudoDeleteEverythingSuccess", "/");
      this.setState({ loading: false });
    }, 1000);
  };

  private onFailure = () => {
    toast.error("Failed to delete. :(");
    this.setState({ loading: false });
  };

  private onClick = () => {
    const confirm = window.confirm(
      "Are you sure you want to delete all data from this environment?",
    );
    if (!confirm) {
      return;
    }

    this.setState({ loading: true });
    sudoDeleteEverything(
      this.props.relay.environment,
      {},
      () => this.onSuccess(),
      () => this.onFailure(),
    );
  };

  public render() {
    const env = getEnvironment();
    return (
      <Paper
        elevation={2}
        className="cw-font-body cw-p-5 cw-flex cw-flex-grow cw-flex-col cw-relative cw-gap-4"
      >
        <div style={sudoStyles.title}>Delete All Data</div>
        <div style={sudoStyles.instructions}>
          This allows you to reset all data on a particular environment. It will be permanently
          deleted, use with discretion. This is not available on production!
        </div>
        <div style={sudoStyles.row}>
          <Button
            sentiment="destructive"
            onClick={() => this.onClick()}
            disabled={this.state.loading}
          >
            Delete all data on {env}
          </Button>
        </div>
        {this.state.loading ? <LinearProgress /> : null}
      </Paper>
    );
  }
}

export const SudoDeleteEverythingStyled = withStyles(styles)(SudoDeleteEverythingCmpt);

export const SudoDeleteEverything = createFragmentContainer<IContainer>(
  SudoDeleteEverythingStyled,
  sudoDeleteEverythingFragments,
);
