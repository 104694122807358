import { ResponseStatusEnum } from "@clockwise/schema/v2";
import { isNil } from "lodash";
import { compareBooleans } from "../../../../util/sort.util";

interface Attendee {
  responseStatus: ResponseStatusEnum | null;
  isOrganizer: boolean | null;
  person: {
    email: string;
  };
}

export const sortedAttendeesByOrganizerYouAndStatus = <A extends Attendee>(
  attendees: A[],
  calendarId: string,
): A[] => {
  return [...attendees].sort((a, b) => {
    return (
      compareBooleans(isNil(a.responseStatus), isNil(b.responseStatus)) ||
      compareBooleans(
        a.responseStatus === ResponseStatusEnum.NotApplicable,
        b.responseStatus === ResponseStatusEnum.NotApplicable,
      ) ||
      compareBooleans(
        a.responseStatus === ResponseStatusEnum.NeedsAction,
        b.responseStatus === ResponseStatusEnum.NeedsAction,
      ) ||
      compareBooleans(
        a.responseStatus === ResponseStatusEnum.Tentative,
        b.responseStatus === ResponseStatusEnum.Tentative,
      ) ||
      compareBooleans(
        a.responseStatus === ResponseStatusEnum.Declined,
        b.responseStatus === ResponseStatusEnum.Declined,
      ) ||
      compareBooleans(
        a.responseStatus === ResponseStatusEnum.Accepted,
        b.responseStatus === ResponseStatusEnum.Accepted,
      ) ||
      compareBooleans(!!b.isOrganizer, !!a.isOrganizer) ||
      compareBooleans(b.person.email === calendarId, a.person.email === calendarId)
    );
  });
};
