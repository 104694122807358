import * as ISchema from "#webapp/src/__schema__";
import { WorkPreferenceStartEndGoal } from "#webapp/src/components/goals-wrapper";
import React from "react";
import { HoursAndPreferencesSections } from "../web-settings";

interface WebSettingsTimeOfDayPreferenceProps {
  org: ISchema.IOrg;
}

export const WebSettingsTimeOfDayPreference = (props: WebSettingsTimeOfDayPreferenceProps) => {
  return (
    <div cw-id={`web-settings-${HoursAndPreferencesSections.TimeOfDayPreference}`}>
      <h3 className="cw-heading-xl cw-mb-4">
        Do you prefer to have open time at the start or end of your day?
      </h3>
      <WorkPreferenceStartEndGoal org={props.org} />
    </div>
  );
};
