import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { animated, config, useTransition } from "@react-spring/web";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useMemo, useRef } from "react";
import { useDefragProposal } from "../../chat-plus-calendar/DefragProposalContext";
import { AssistantMessage } from "./components/AssistantMessage";
import { OnDemandDefrag } from "./components/on-demand-defrag/OnDemandDefrag";
import { useAIMessageContext } from "./hooks/AIMessageContext";
import { OptimizeWeek } from "./Sidebar/optimize-week/OptimizeWeek";
import { SidebarQuickFeed } from "./Sidebar/SidebarQuickFeed";

export const AIChatFeed = () => {
  const { messages, debugInfoMap } = useAIMessageContext();
  const { onDemandDefrag } = useDefragProposal();
  const [onDemandDefragAvailable] = useFeatureFlag("onDemandDefrag");

  const feedRef = useRef(null);

  const renderedMessages = useMemo(() => {
    // filter out optimistic response AssistantMessage and UserMessage
    const filteredMessages = messages
      .filter((message) => !(message.__typename === "TextResponse" && isEmpty(message.text)))
      .filter((message) => message.__typename !== "UserMessage")
      .filter((message, _, newMessages) => newMessages[newMessages.length - 1].id === message.id);

    return filteredMessages.map((message) => {
      const getDebugInfoForMessage = (messageId: string) => {
        const debugInfoForId = debugInfoMap[messageId];
        return debugInfoForId ?? undefined;
      };
      const debugInfo = getDebugInfoForMessage(message.id);

      return (
        <div key={message.id} className={classNames("cw-my-2 cw-max-w-full cw-self-stretch", {})}>
          <AssistantMessage debugInfo={debugInfo} isLastMessage message={message} />
        </div>
      );
    });
  }, [messages, debugInfoMap]);

  const animatedMessages = useTransition(renderedMessages, {
    from: { translateY: 5 },
    enter: { translateY: 0 },
    config: config.stiff,
    trail: feedRef.current ? 50 : 0,
    immediate: !feedRef.current,
    key: (message: JSX.Element) => message.key ?? JSON.stringify(message.props).substring(0, 10),
  });

  const showEmptyState = renderedMessages.length === 0;

  const showOnDemandDefrag = useMemo(() => onDemandDefrag.visible && onDemandDefrag?.proposal?.id, [
    onDemandDefrag,
  ]);

  return (
    <>
      {onDemandDefragAvailable && (showEmptyState || showOnDemandDefrag) && <OptimizeWeek />}
      {showEmptyState && !showOnDemandDefrag && <SidebarQuickFeed />}
      {!showEmptyState && !showOnDemandDefrag && (
        <>
          {animatedMessages((style, message) => (
            <animated.div style={style} className="cw-mb-4 cw-flex cw-flex-col" ref={feedRef}>
              {message}
            </animated.div>
          ))}
        </>
      )}
      {showOnDemandDefrag && <OnDemandDefrag proposal={onDemandDefrag.proposal} />}
    </>
  );
};
