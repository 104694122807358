import { fg_brand } from "@clockwise/design-system/tokens";
import { OpenInNew } from "@clockwise/icons";
import React from "react";

export const ExternalLinkIcon = (
  <OpenInNew
    style={{
      fontSize: "16px",
      color: fg_brand,
      bottom: "-3px",
      position: "relative",

      marginLeft: "3px",
    }}
  />
);
