import { useQuery } from "@apollo/client";
import { isEmpty, isEqual, noop, without } from "lodash";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDiffIdWithCalendarIds,
  addDiffIds,
  clearCalendarIds,
  clearDiffIdsAndEventIds,
  removeDiffIdWithCalendarIds,
  removeDiffIds,
  replaceDiffIdsOrEventIds,
} from "../../../../../state/actions/multi-calendar.actions";
import { setLoadingProposalUIDone } from "../../../../../state/actions/ui-flow-on-screen.actions";
import { IReduxState } from "../../../../../state/reducers/root.reducer";
import usePlannerMetaData from "../../../../web-app-calendar/hooks/usePlannerMetaData";
import { DiffAttendeesByIdDocument } from "../../apollo/__generated__/DiffAttendeesById.generated";
import {
  DisableReason,
  calculateIfTooManyCalendarsToShow,
  useIsLayersDisabledWithReason,
} from "./calendar-layers.util";

type useDiffLayersToggleProps = {
  diffId: string;
  focusedToggle?: boolean;
  startingIsToggledOn: boolean;
};

type DiffToggleReturnType = {
  active: boolean;
  calendarIds: string[];
  disabled: boolean;
  reasonForDisabled: DisableReason;
  toggle: (() => void) | undefined;
};

export const useDiffLayersToggle = ({
  diffId,
  // If `focusedToggle` is on, remove all the calendars on this event individually
  // regardless of all the other events and diffs that are toggled on
  focusedToggle,
  startingIsToggledOn,
}: useDiffLayersToggleProps): DiffToggleReturnType => {
  const { userCalendarIds } = usePlannerMetaData();
  const dispatch = useDispatch();
  const {
    eventIdOrDiffIdMappedToCalendarIds,
    selectedDiffIds,
    calendarIdsToFullyShow,
  } = useSelector((state: IReduxState) => state.multiCalendar);

  const hasAttemptedToSetStartingIsToggledOn = useRef(false);

  const { data, loading } = useQuery(DiffAttendeesByIdDocument, {
    variables: {
      diffId: diffId || "",
    },
    skip: !diffId,
    // NB: reflects changes in the diff details card
    // after a diff is updated by fetching the latest
    // diff details from the server
    fetchPolicy: "network-only",
  });

  const calendarIds = useMemo(() => {
    return (
      data?.viewer?.user?.diffDetailsById?.attendees?.proposalAttendees.map(
        (attendee) => attendee.attendeePerson.primaryCalendar,
      ) || []
    );
  }, [data]);

  const othersCalendars = without(calendarIds, ...userCalendarIds);
  const hasOthersCalendars = othersCalendars.length > 0;

  const prevCalendarIdRef = useRef<string[]>(calendarIds);

  const updateCalendarIdRef = useCallback(() => {
    prevCalendarIdRef.current = calendarIds;
  }, [calendarIds]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (isEqual(eventIdOrDiffIdMappedToCalendarIds[diffId], calendarIds)) {
      return;
    }

    dispatch(addDiffIdWithCalendarIds(diffId, calendarIds));

    return () => {
      dispatch(removeDiffIds([diffId])); // This removes the event selection to turn off the layers
      dispatch(removeDiffIdWithCalendarIds(diffId)); // This remove the eventId from mapping
    };
  }, [calendarIds, diffId, loading]);

  useEffect(() => {
    const isDisambiguationChange =
      prevCalendarIdRef?.current.length == calendarIds.length &&
      !isEqual(prevCalendarIdRef?.current, calendarIds);

    if (isDisambiguationChange) {
      // If it's not a disambiguation of attendee change, we want to respect the `startingIsToggledOn`
      dispatch(addDiffIds([diffId]));
    }

    updateCalendarIdRef();
  }, [prevCalendarIdRef, calendarIds, diffId]);

  const isToggledOn = useMemo(() => selectedDiffIds.includes(diffId), [selectedDiffIds, diffId]);

  useEffect(() => {
    if (loading || hasAttemptedToSetStartingIsToggledOn.current) {
      return;
    }

    const tooManyCalendarsToShow = calculateIfTooManyCalendarsToShow(
      calendarIds,
      calendarIdsToFullyShow,
    );

    if (startingIsToggledOn && !tooManyCalendarsToShow) {
      dispatch(addDiffIds([diffId]));
    }

    dispatch(setLoadingProposalUIDone()); // Now the calendarIds are set, we can stop processing
    hasAttemptedToSetStartingIsToggledOn.current = true;
  }, [loading]);

  useEffect(() => {
    // Need the `loading` check because the calendarIds is empty before then
    if (isEmpty(calendarIds) && !loading) {
      // For diffs with no attendees, it's considered already shown in the calendar
      // Thus add it to the list of selected diff ids
      dispatch(addDiffIds([diffId]));
    }
  }, [calendarIds]);

  // We want to use the calendarIds that the user has edited because the toggle button
  // should turn on the calendars including the ones that the user has added or removed
  const calendarIdsPossiblyWithUserEdits = eventIdOrDiffIdMappedToCalendarIds[diffId];

  const { disabled, reason: reasonForDisabled } = useIsLayersDisabledWithReason(
    calendarIdsPossiblyWithUserEdits,
  );

  if (disabled || !hasOthersCalendars) {
    return {
      active: false,
      calendarIds: [],
      disabled: true,
      reasonForDisabled: reasonForDisabled,
      toggle: noop,
    };
  }

  const toggle = () => {
    if (disabled) {
      return;
    }

    if (!isToggledOn) {
      dispatch(replaceDiffIdsOrEventIds([diffId]));
    }

    if (isToggledOn) {
      if (focusedToggle) {
        dispatch(clearCalendarIds());
      } else {
        dispatch(clearDiffIdsAndEventIds());
      }
    }
  };

  return {
    active: isToggledOn,
    calendarIds,
    disabled,
    reasonForDisabled,
    toggle,
  };
};
