import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  DisambiguateProposalAttendeeDocument,
  DisambiguateProposalAttendeeMutation,
  DisambiguateProposalAttendeeMutationVariables,
} from "../apollo/__generated__/DisambiguateProposalAttendee.generated";

type UseDisambiguateAttendeeProps = {
  onCompleted?: (data: DisambiguateProposalAttendeeMutation) => void;
  onError?: (error: Error) => void;
};

type UseDisambiguateAttendeeResult = [
  (variables: DisambiguateProposalAttendeeMutationVariables) => Promise<void>,
  { loading: boolean; error: Error | undefined },
];

export const useDisambiguateProposalAttendee = ({
  onCompleted,
  onError,
}: UseDisambiguateAttendeeProps): UseDisambiguateAttendeeResult => {
  const [disambiguateProposalAttendee, { loading, error }] = useMutation(
    DisambiguateProposalAttendeeDocument,
    {
      refetchQueries: ["DiffAttendeesById"],
    },
  );

  const onDisambiguateProposalAAttendee = useCallback(
    async (variables: DisambiguateProposalAttendeeMutationVariables) => {
      await disambiguateProposalAttendee({
        variables,
        onCompleted,
        onError,
      });
    },
    [disambiguateProposalAttendee, onCompleted, onError],
  );

  return [onDisambiguateProposalAAttendee, { loading, error }];
};
