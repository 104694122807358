import { Select, SelectOption } from "@clockwise/design-system";
import React from "react";

export const FocusTimeHoursSelect = ({
  disabled,
  onChange,
  value,
  maxHours = 40,
}: {
  disabled: boolean;
  onChange: (value: string) => void;
  value: string;
  maxHours?: number;
}) => {
  const listOfNums = () => {
    const numArray = [];
    for (let i = 2; i <= maxHours; i++) {
      numArray.push(i);
    }
    return numArray;
  };
  return (
    <Select disabled={disabled} onChange={onChange} value={value}>
      {listOfNums().map((num) => (
        <SelectOption key={`PT${num}H`} value={`PT${num}H`}>
          {num} hours
        </SelectOption>
      ))}

      {/* legacy option conditionally rendered */}
      {value === "all" && <SelectOption value="all">All my available Focus Time</SelectOption>}
    </Select>
  );
};
