import { ClockwiseLogoCircle } from "@clockwise/web-commons/src/components/svgs/ClockwiseLogoCircle";
import { webappPathRoot } from "@clockwise/web-commons/src/constants/route.constants";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

const label = "Clockwise";

export const AppLogoNavLink = () => {
  return (
    <Link
      aria-label={label}
      className={classNames("cw-group", "cw-no-underline")}
      to={webappPathRoot}
    >
      <div
        className={classNames(
          "cw-body-sm cw-text-onEmphasis",
          "cw-flex cw-flex-col cw-items-center cw-space-y-0.5",
          "cw-select-none",
        )}
      >
        <ClockwiseLogoCircle />
      </div>
    </Link>
  );
};
