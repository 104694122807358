import { bg_busy, bg_neutral_inset, fg_busy, fg_default } from "@clockwise/design-system/tokens";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { useState } from "react";

// util
import {
  IEventCategoryColoring,
  IEventColoringSettings,
} from "#clockwise/web-commons/src/util/event-category-coloring";
import { EventPositioner } from "#clockwise/web-commons/src/util/event-position";
import { ResponseStatus } from "@clockwise/client-commons/src/util/attendees";
import {
  AnnotatedEvent,
  isClockwiseUnsyncedEvent,
  isFocusTimeSmartHold,
  isLunchSmartHold,
} from "@clockwise/client-commons/src/util/event";
import { useCalendarColors } from "../../../util/event-category-coloring";

// internal
import { colors as oldColorPalette } from "../../../styles";
import { styles } from "./CalendarDayEvent.styles";

// components
import { TutorialTip } from "../../tutorial-tip";
import { TipPosition } from "../../tutorial-tip/TutorialTip";

// styles
import { defaultCWCalFocusTimeColor } from "@clockwise/client-commons/src/util/event-category-coloring";
import classNames from "classnames";
import { DateTime, Interval } from "luxon";
import { greys } from "../../../styles";
import { DateString } from "../../../ui/scheduling-link";
import { getRenderTimeZone } from "../../../util/time-zone.util";
import { formatEventTitle } from "../calendar-event/utils/formatEventTitle";

export interface CalendarDayEventTooltipOptions {
  header: string;
  body?: string;
  dismissText?: string;
  moreText?: string;
  onDismiss?: () => void;
  onMore?: () => void;
  tipPosition?: TipPosition;
  tipColor?: "white" | "brandEmphasis";
}

export interface CalendarDayIAttendee {
  responseStatus?: ResponseStatus | null;
  urnValue: string;
}

export type CalendarMode = "week" | "day";

export const isCalendarMode = (value: string): value is CalendarMode => {
  return value === "day" || value === "week";
};

const EVENT_SHADOW = "cw-drop-shadow-[0.5px_1px_5px_rgb(89,99,119,.7)]";
const EVENT_SHADOW_HOVER = `hover:cw-drop-shadow-[0.5px_1px_5px_rgb(89,99,119,.7)]`;

export interface CalendarDayIEvent {
  startTime: {
    dateOrDateTime: string;
    millis: number;
  };
  endTime: {
    dateOrDateTime: string;
    millis: number;
  };
  calendarIds: string[];
  location?: string | null;
  title?: string | null;
  isAllDay: boolean | null;
  organizer?: CalendarDayIAttendee | null;
  attendees: CalendarDayIAttendee[];
  annotatedEvent?: AnnotatedEvent | null;
  annotatedRecurringEvent?: AnnotatedEvent | null;
  isClockwiseEvent: boolean;
  eventKey: {
    externalEventId: string;
  };
}

interface CalendarDayEventContainer {
  onClick?: (calendarId: string, externalEventId: string) => void;
  eventPositioner: EventPositioner;
  userId: string;
  calendarIds: string[];
  defaultColor?: string;
  colorSettings?: IEventColoringSettings<IEventCategoryColoring>;
  containerClassName?: string;
  zIndexOverride?: number;
  tooltipOptions?: CalendarDayEventTooltipOptions;
}

export interface CalendarDayEventProps
  extends WithStyles<typeof styles>,
    CalendarDayEventContainer {
  showFocusTimeHolds: boolean;
  showLunchHolds: boolean;
  showNiceSmartHolds: boolean;
  withoutRelativeStyle?: boolean;
  loading: boolean;
  day?: DateString;
  calendarMode?: CalendarMode;
}

export interface CalendarDayDataMapperGeneratorOptions {
  onClick?: (calendarId: string, externalEventId: string) => void;
  userId: string;
  calendarIds: string[];
  showFocusTimeHolds?: boolean;
  showLunchHolds?: boolean;
  showNiceSmartHolds?: boolean;
  defaultColor?: string;
  colorSettings?: IEventColoringSettings<IEventCategoryColoring>;
  withoutRelativeStyle?: boolean;
  containerClassName?: string;
  zIndexOverride?: number;
  tooltipOptions?: CalendarDayEventTooltipOptions;
}

export interface CalendarDayEventDisplayData {
  eventPositioner: EventPositioner;
}

export const BASE_HEIGHT = 60; // used for computing the height of events, hour distance, etc

export const CalendarDayEventComponent = (props: CalendarDayEventProps) => {
  const { getEventColors } = useCalendarColors();
  const {
    event,
    top,
    left,
    height,
    floatOffset,
    width,
    isFocused,
    displayNone,
  } = props.eventPositioner;

  const [isHovered, setIsHovered] = useState(false);

  const calcLeft = floatOffset ? left + floatOffset : left;
  const calcWidth = floatOffset ? width - floatOffset : width;

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  const onClick = () => {
    const { event } = props.eventPositioner;
    const matchedCalendarId = props.calendarIds?.find(
      (calId) => event.calendarIds.indexOf(calId) !== -1,
    );
    const calendarId = matchedCalendarId ? matchedCalendarId : null;

    if (calendarId && props.onClick) {
      const externalEventId = event.eventKey.externalEventId;

      props.onClick(calendarId, externalEventId);
    }
  };

  const onHover = (h: boolean) => {
    setIsHovered(h);
  };

  const {
    userId,
    colorSettings,
    classes,
    tooltipOptions,
    showNiceSmartHolds,
    withoutRelativeStyle,
    showLunchHolds,
    defaultColor,
  } = props;

  const isLunch = isLunchSmartHold(event);
  const isFocusTime =
    isFocusTimeSmartHold(event) || (event.title || "").indexOf("FocusTime") !== -1;
  const clockwiseUnsyncedEvent = !displayNone && isClockwiseUnsyncedEvent(event);

  let colors = getEventColors(event, colorSettings, userId, false, true);

  if (defaultColor === fg_busy) {
    colors = {
      background: bg_busy,
      foreground: fg_busy,
      twForeground: `cw-fill-[#444CE7]`,
      name: "busy", // This name doesnt matter, but it's ts required.
    };
  }

  const title = formatEventTitle(event.title, false);
  let borderStyle = "solid";
  let shouldShow = true;

  if (clockwiseUnsyncedEvent && !showNiceSmartHolds) {
    // No longer displaying unsynced events other than when
    // showNiceSmartHolds is true during onboarding.
    return null;
  } else if (isFocusTime) {
    if (showNiceSmartHolds) {
      colors = colorSettings
        ? getEventColors(event, colorSettings, userId, isFocusTime)
        : defaultCWCalFocusTimeColor;
    }
    borderStyle = "none";
    shouldShow = props.showFocusTimeHolds;
  } else if (isLunch) {
    if (showNiceSmartHolds) {
      colors = colorSettings
        ? {
            background: oldColorPalette.googleLavendar,
            foreground: greys.white,
            twForeground: `cw-fill-white`,
            name: "niceSmartHold",
          }
        : {
            background: bg_neutral_inset,
            foreground: fg_default,
            twForeground: `cw-fill-[#2B2B2B]`,
            name: "niceSmartHold",
          };
    }
    borderStyle = "none";
    shouldShow = showLunchHolds;
  }

  const transform = shouldShow ? "scale(1, 1)" : "scale(1, 0)";

  const colorStyle = clockwiseUnsyncedEvent
    ? {
        background: colors.background,
        border: `1px ${borderStyle} ${colors.foreground}`,
        color: colors.foreground,
      }
    : {
        background: colors.background,
        color: colors.foreground,
        border: event.isAllDay ? undefined : "1px solid rgba(0, 0, 0, 0.05)",
        borderLeft: `2px solid ${colors.foreground}`,
        boxShadow: event.isAllDay ? undefined : "0px 2px 2px rgba(89, 99, 119, 0.07)",
      };

  const displayedTitle = `${title.replace(" (via Clockwise)", "")}`;

  let tipTransform = "",
    tipTop = "",
    tipLeft = "",
    tipRight = "";

  if (tooltipOptions?.tipPosition === "left") {
    tipTransform = "translate(calc(100% + 5px), -40px)";
    tipRight = `${left}%`;
    tipTop = `${top + height / 2}%`;
  } else {
    tipLeft = `${left}%`;
    tipTop = `${top + height / 2}%`;
  }

  const eventDuration = Interval.fromISO(
    `${event.startTime.dateOrDateTime}/${event.endTime.dateOrDateTime}`,
  ).length("minutes");

  const isFirstDayOfAllDayEvent =
    event.isAllDay &&
    DateTime.fromISO(event.startTime.dateOrDateTime, { zone: getRenderTimeZone() }).toISODate() ===
      props.day;

  const isLastDayOfAllDayEvent =
    event.isAllDay &&
    DateTime.fromISO(event.endTime.dateOrDateTime, { zone: getRenderTimeZone() })
      .minus({ seconds: 1 })
      .toISODate() === props.day;

  const dayOfWeek = props.day && DateTime.fromISO(props.day, { zone: getRenderTimeZone() }).weekday; // 1-7, with 7 being Sunday.

  return (
    <>
      <div
        key={event.eventKey.externalEventId}
        id={event.eventKey.externalEventId}
        role="button"
        tabIndex={0}
        cw-id="calendar-day-event"
        className={classNames(
          displayNone && "cw-hidden",
          classes.simpleEvent,
          props.containerClassName,
          "cw-ease-in cw-leading-4",
          event.isAllDay && "cw-h-6 cw-text-ellipsis cw-whitespace-nowrap cw-overflow-hidden",
          !event.isAllDay && `cw-rounded ${EVENT_SHADOW_HOVER}`,
          isFocused && EVENT_SHADOW,
          isFirstDayOfAllDayEvent && "cw-rounded-l",
          isLastDayOfAllDayEvent && "cw-rounded-r cw-w-[94%]",
          event.isAllDay && !isFirstDayOfAllDayEvent && "cw-border-none",
          eventDuration <= 30 && "cw-pt-0.5",
          eventDuration === 45 && "cw-pt-[1px]",
          eventDuration > 45 && "cw-pt-1",
        )}
        style={{
          display: shouldShow ? "unset" : "none",
          transform,
          cursor: props.onClick ? "pointer" : undefined,
          ...(withoutRelativeStyle
            ? {}
            : {
                top: `${top}%`,
                height: `calc(${height}% - 4px)`,
                left: `${calcLeft}%`,
                width: `calc(${calcWidth}% - 4px)`,
                scale: isHovered ? 1.1 : 1,
              }),
          ...colorStyle,
        }}
        onMouseOver={() => onHover(true)}
        onMouseOut={() => onHover(false)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onClick();
          }
        }}
        onClick={displayNone ? undefined : onClick}
      >
        {props.calendarMode === "week" &&
        event.isAllDay && // For all day events dont show the title except for on the first day of the event or on Sunday the first day of the week.
        // Show on Sunday first day of the week for all day events that span weekends. E.g. Friday-Tuesday.
        // TODO make that title width span all days that the event spans rather than just the first.
        DateTime.fromISO(event.startTime.dateOrDateTime, {
          zone: getRenderTimeZone(),
        }).toISODate() !== props.day &&
        dayOfWeek !== 7 ? (
          ""
        ) : (
          <span>{displayedTitle}</span>
        )}
        {!event.isAllDay && (
          <>
            {eventDuration <= 30 ? ", " : <br />}
            <span style={{ opacity: 0.7 } /* Drops the opacity of the start-end hour text */}>
              {DateTime.fromISO(event.startTime.dateOrDateTime, { zone: getRenderTimeZone() })
                .toFormat("h:m")
                .replace(":0", "")}
              -{/* startTime-endTime with trailing zeros and spaces removed */}
              {DateTime.fromISO(event.endTime.dateOrDateTime, { zone: getRenderTimeZone() })
                .toLocaleString(DateTime.TIME_SIMPLE)
                .replace(/\s+/g, "")
                .toLowerCase()}
            </span>
          </>
        )}
      </div>
      {tooltipOptions && ( // A few onboarding calendars display tooltips.
        <TutorialTip
          containerStyle={{
            top: tipTop,
            left: tipLeft,
            right: tipRight,
            transform: tipTransform,
            zIndex: 4,
          }}
          hide={false}
          onDismiss={tooltipOptions.onDismiss}
          onMore={tooltipOptions.onMore}
          moreText={tooltipOptions.moreText}
          dismissText={tooltipOptions.dismissText}
          tipPosition={tooltipOptions.tipPosition}
          tipColor={tooltipOptions.tipColor}
          tooltipContents={
            <>
              <div className={classes.tipHeader}>{tooltipOptions.header}</div>
              {tooltipOptions.body ? (
                <div className={classes.tipBody}>{tooltipOptions.body}</div>
              ) : null}
            </>
          }
        />
      )}
    </>
  );
};

CalendarDayEventComponent.defaultProps = {
  showFocusTimeHolds: true,
  showLunchHolds: true,
  showNiceSmartHolds: false,
  withoutRelativeStyle: false,
  loading: false,
};

export const CalendarDayEvent = withStyles(styles)(CalendarDayEventComponent);

export const calendarDayEventDataMapperFactory = (
  options: CalendarDayDataMapperGeneratorOptions,
) => (displayData: CalendarDayEventDisplayData) => (
  <CalendarDayEvent {...options} {...displayData} />
);
