import { Button } from "@clockwise/design-system";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import pluralize from "pluralize";
import React from "react";
import { useNavigate } from "react-router-dom";
import useFlexibleMeetings from "../hooks/useFlexibleMeetings";

const FlexibleMeetingsBanner = () => {
  const { flexibleMeetings, loading } = useFlexibleMeetings();
  const flexibleMeetingCount = flexibleMeetings.length;
  const navigate = useNavigate();

  const linkTo = getUrlForFeature("flexibleMeetings", { relative: true });

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    navigate(linkTo);
  };

  const displayText = `You have ${flexibleMeetingCount} flexible ${pluralize(
    "meetings",
    flexibleMeetingCount,
  )}.`;

  return (
    <a className="cw-no-underline" href={linkTo} onClick={handleClick}>
      <div
        className={`
          cw-flex 
          cw-flex-col lg:cw-flex-row
          cw-items-start lg:cw-items-center 
          cw-justify-between 
          cw-space-x-0 lg:cw-space-x-12
          cw-space-y-8 lg:cw-space-y-0
        `}
      >
        <div className="cw-body-xl cw-max-w-screen-sm">
          <span className="cw-font-bold">
            Now, mark meetings as flexible so Clockwise can try to create this ideal day.
          </span>{" "}
          {!loading && !!flexibleMeetingCount && displayText}
        </div>
        <Button sentiment="info" size="jumbo">
          See recommendations
        </Button>
      </div>
    </a>
  );
};

export default FlexibleMeetingsBanner;
