import { useUnsyncSmartHold } from "#webapp/src/components/event-card/hooks/useUnsyncSmartHold";
import { CalendarInfoDocument } from "#webapp/src/components/event-card/molecules/__generated__/CalendarInfo.v2.generated";
import { Description } from "#webapp/src/components/event-card/smart-holds/Description";
import {
  SmartHoldSettingsReadable,
  useUserSmartHoldSettings,
} from "#webapp/src/hooks/useUserSmartHoldSettings/useUserSmartHoldSettings";
import { ApolloError } from "@apollo/client";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { Divider } from "@clockwise/design-system";
import { SmartHoldState, SmartHoldType } from "@clockwise/schema/v2";
import { useEventCategory } from "@clockwise/web-commons/src/hooks/useEventCategory";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { Interval } from "luxon";
import React from "react";
import toast from "react-hot-toast";
import { usePlannerContext } from "../../Context";
import { ClickableContainer, EventDetailsHeader } from "../atoms/EventDetails";
import { formatIntervalContextMenu } from "../hooks/useEventPopoverDetails";
import { Footer } from "../molecules/Footer";
import { DeleteButton, EditButton, ExpandButton, Header } from "../molecules/Header";
import { SettingsSmartHolds } from "./EditSettingsLink";
import { FlexAndProtection } from "./FlexAndProtection";

type SmartHoldForMenu = {
  state: SmartHoldState;
  holdType: SmartHoldType;
};

const getSubtextForSettings = (
  settings: SmartHoldSettingsReadable | undefined,
  type: SmartHoldType,
) => {
  if (!settings) return;
  if (type === SmartHoldType.Lunch) {
    const setting = settings.lunchHoldSettings;
    return `Blocking ${setting.lunchDurationMinMinutes}-${
      setting.lunchDurationIdealMinutes
    }mins for lunch between ${setting.minStartTime?.toLocaleLowerCase()}-${setting.maxStartTime?.toLocaleLowerCase()}`;
  }
  if (type === SmartHoldType.FocusTime) {
    const setting = settings.focusTimeSettings;
    return `Blocking up to ${setting.maxPerWeekHours}hrs of Focus Time per week`;
  }
  if (type === SmartHoldType.MeetingRelief) {
    const setting = settings.meetingReliefSettings;
    return `Blocking ${setting.breakDurationMinutes}mins to prevent ${setting.consecutiveMeetingDurationHours}hrs of consecutive meetings`;
  }
  if (type === SmartHoldType.TravelTime) {
    return "Blocking travel time for external meetings with a location set";
  }
  return; // Linting is vexed if this isnt here
};

export const SmartHoldMenu = ({
  smartHold,
  title,
  interval,
  onOpenCard,
  onClose,
  calendarId,
  externalEventId,
  isOwnSmartHold,
  canExpand = true,
}: {
  smartHold: SmartHoldForMenu;
  title: string;
  interval: Interval;
  onClose: () => void;
  onOpenCard: () => void;
  calendarId: string;
  externalEventId: string;
  isOwnSmartHold: boolean;
  canExpand?: boolean;
}) => {
  const { data, loading: calIdLoading } = useGatewayQuery(CalendarInfoDocument, {
    variables: { id: calendarId },
    fetchPolicy: "cache-first",
    skip: isOwnSmartHold, // Skip if you are the user, this is just to get the display name for the title
  });

  const { category } = useEventCategory({
    calendarId: calendarId ?? "",
    eventId: externalEventId ?? "",
    useQuery: useGatewayQuery,
    useMutation: useGatewayMutation,
  });

  const { settingsReadableValues } = useUserSmartHoldSettings();

  const ownerDisplayName = calIdLoading ? "" : data?.calendar?.displayName;
  const firstName = ownerDisplayName?.split(" ")?.[0];
  const titleFormatted = firstName ? title.replace(" ", ` ${firstName}'s `) : title;

  const showFlexibilityStatus = smartHold.holdType !== SmartHoldType.TravelTime;

  const subText = isOwnSmartHold
    ? getSubtextForSettings(settingsReadableValues, smartHold.holdType)
    : undefined;

  return (
    <>
      <Header onClose={onClose}>
        {canExpand && isOwnSmartHold && <EditButton tooltip="Edit hold" onClick={onOpenCard} />}
        {canExpand && !isOwnSmartHold && <ExpandButton tooltip="View hold" onClick={onOpenCard} />}
        {isOwnSmartHold && (
          <RemoveSmartHold
            calendarId={calendarId}
            externalEventId={externalEventId}
            onClose={onClose}
          />
        )}
      </Header>
      <div className="cw-pt-1.5 cw-pb-3 cw-px-2">
        <ClickableContainer onClick={onOpenCard}>
          <EventDetailsHeader
            attendees={[]}
            calendarId={calendarId}
            categoryColor={category.color || undefined}
            loading={false}
            title={titleFormatted}
            isLocked={false}
            eventTimeString={formatIntervalContextMenu(interval)}
          />
        </ClickableContainer>
        {isOwnSmartHold ? (
          showFlexibilityStatus && (
            <FlexAndProtection
              onClose={onClose}
              isOwnSmartHold={isOwnSmartHold}
              externalEventId={externalEventId}
              smartHoldState={smartHold.state}
            />
          )
        ) : (
          <>
            <Divider spacing="xs" />
            <div className="cw-px-2 cw-py-1.5">
              <Description
                type={smartHold.holdType}
                smartHoldState={smartHold.state}
                calendarName={firstName || ""}
              />
            </div>
          </>
        )}
        {isOwnSmartHold && (
          <>
            <Divider spacing="xs" />
            <SettingsSmartHolds subText={subText} />
          </>
        )}
      </div>
      {ownerDisplayName && !calIdLoading && (
        <Footer
          loading={calIdLoading}
          canRSVP={false}
          showEventOwner={true}
          ownerDisplayName={ownerDisplayName || ""}
          calendarId={calendarId}
        />
      )}
    </>
  );
};

const RemoveSmartHold = ({
  externalEventId,
  calendarId,
  onClose,
}: {
  externalEventId: string;
  calendarId: string;
  onClose: () => void;
}) => {
  const { refetchEvents } = usePlannerContext();
  const track = useTracking();

  const { unsyncSmartHold, loading: unsyncing } = useUnsyncSmartHold(
    () => {
      toast.success("Hold removed");
      track(TrackingEvents.SMART_HOLD.UNSYNCED);
      onClose();
      void refetchEvents?.();
    },
    (error: ApolloError) => {
      toast.error("Failed to remove smarthold.");
      logger.error("Failed to remove smarthold in popover", error);
    },
  );

  const onUnsyncSmartHold = () => {
    if (!externalEventId) {
      toast.error("Failed to remove smarthold.");
      logger.error("EventId not found, cannot remove smart hold in popover");
      return;
    }
    unsyncSmartHold(externalEventId, calendarId);
  };

  return <DeleteButton tooltip="Delete hold" onClick={onUnsyncSmartHold} disabled={unsyncing} />;
};
