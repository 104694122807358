import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import React, { useMemo } from "react";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { StepCounter, StepLayout } from "../../shared-components";
import { setError } from "../../utils/setError";
import { FocusTimeSlide } from "./FocusTimeSlide";
import { FocusTimeSlideDocument } from "./__generated__/FocusTimeSlide.generated";

export const FocusTimeStep = ({ orgId }: { orgId: string }) => {
  const {
    goForward,
    goBack,
    shouldShowSecondaryButton,
    backFromPreviousSlide,
    funnelType,
  } = useWebOnboardingSteps();

  const { data, loading } = useQuery(FocusTimeSlideDocument, {
    fetchPolicy: "no-cache",
    onError: (error) => {
      setError({
        error: error,
        message: "failed to fetch Focus Time meeting suggestions",
        showUserMessage: false,
      });
    },
  });

  const usersFocusTime = useMemo(() => {
    const user = getValue(data?.viewer, "Viewer")?.user;
    const org = getValue(user?.orgs)?.edges?.[0]?.node;
    const focusTimeSyncEnabled = getValue(org?.shadowCalendarSettingsErrorable)
      ?.focusTimeSyncEnabled;
    const focusTimeMinutes = getValue(org?.shadowCalendarSettingsErrorable)
      ?.focusTimeIdealMinutesPerWeek;
    return { focusTimeSyncEnabled, focusTimeMinutes };
  }, [data]);

  return (
    <StepLayout>
      <StepCounter />
      <FocusTimeSlide
        loading={loading}
        orgId={orgId}
        backFromPreviousSlide={backFromPreviousSlide}
        showSecondaryButton={shouldShowSecondaryButton}
        onClickPrimaryButton={goForward}
        onClickSecondaryButton={goBack}
        funnelType={funnelType}
        initialFocusTimeSyncEnabled={usersFocusTime.focusTimeSyncEnabled}
        initialFocusTimeMinutes={usersFocusTime.focusTimeMinutes}
      />
    </StepLayout>
  );
};
