import { noop } from "lodash";
import React, { ReactNode, createContext, useContext, useMemo } from "react";
import invariant from "tiny-invariant";
import { SchedulingOptionsTradeoffs } from "../../chat/ai-chat/utils/types";

type TimeSuggestionState = {
  interval: string | null;
  tradeoffs: SchedulingOptionsTradeoffs;
  attendeeCount: number | null;
};

export type TimeSuggestionActions = {
  setHoveredTimeSuggestion: (suggestion: TimeSuggestionState) => void;
};

export const emptyTimeSuggestionsState: TimeSuggestionState = {
  interval: null,
  tradeoffs: [],
  attendeeCount: null,
};

const ReadContext = createContext<TimeSuggestionState>(emptyTimeSuggestionsState);
const WriteContext = createContext<TimeSuggestionActions>({ setHoveredTimeSuggestion: noop });

type ProviderProps = {
  children: ReactNode;
  initValue?: TimeSuggestionState;
};

export const TimeSuggestionPeekProvider = ({
  children,
  initValue = emptyTimeSuggestionsState,
}: ProviderProps) => {
  const [hoveredTimeSuggestion, setHoveredTimeSuggestion] = React.useState<TimeSuggestionState>(
    initValue,
  );
  const writeContext = useMemo(
    () => ({
      setHoveredTimeSuggestion: setHoveredTimeSuggestion,
    }),
    [],
  );

  const readContext = useMemo(
    () => ({
      interval: hoveredTimeSuggestion?.interval,
      tradeoffs: hoveredTimeSuggestion?.tradeoffs,
      attendeeCount: hoveredTimeSuggestion?.attendeeCount,
    }),
    [hoveredTimeSuggestion],
  );
  return (
    <WriteContext.Provider value={writeContext}>
      <ReadContext.Provider value={readContext}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadTimeSuggestionPeek = () => useContext(ReadContext);

export const useUpdateTimeSuggestionPeek = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdateTimeSuggestionPeek must be within TimeSuggestionPeekProvider");
  return context;
};
