import {
  Frequency,
  RecurrenceRule,
  RRuleFrequency,
} from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { Button } from "@clockwise/design-system";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { DayOfWeekField } from "./DayOfWeekField";
import { Dialog } from "./Dialog";
import { EndField } from "./EndField";
import { FrequencyField } from "./FrequencyField";
import { RepetitionTypeField } from "./RepetitionTypeField";
import {
  buildNewRRule,
  getInitialByDay,
  getInitialEndDate,
  getRepetitionType,
  isSupportedFrequency,
} from "./utils";

const Label = ({ children }: { children: ReactNode }) => (
  <div className="cw-body-sm cw-font-medium">{children}</div>
);

type Props = {
  recurrence: RecurrenceRule;
  date: string;
  onChange: (recurrence: RecurrenceRule) => void;
};

/**
 * Renders a form to configure a recurrence rule.
 *
 * This form only supports creating a limited subset of possible recurrence rule patterns. These
 * cover most common use cases and should be compatible with both Google and Microsoft's recurrence
 * models. Unsupported recurrence patterns will be adapted to fit the UI or replaced entirley with a
 * default recurrence rule.
 */
export const CustomRecurrence = ({ recurrence: initialRRule, date, onChange }: Props) => {
  const [frequency, setFrequency] = useState<Frequency>(() =>
    isSupportedFrequency(initialRRule.freq) ? initialRRule.freq : RRuleFrequency.WEEKLY,
  );
  const [interval, setInterval] = useState(initialRRule.interval);

  // Only relevant in our limited config UI for weekly. Ignore for other frequencies.
  const [byDay, setByDay] = useState<number[]>(() => {
    return initialRRule.byDay.length > 0 ? initialRRule.byDay : getInitialByDay(date);
  });

  // Only relevant for monthly and yearly. Ignore for other frequencies.
  const [repetitionType, setRepetitionType] = useState<"date" | "nth" | "last">(() =>
    getRepetitionType(initialRRule, date),
  );

  const [endType, setEndType] = useState<"none" | "count" | "until">(() => {
    return initialRRule.count ? "count" : initialRRule.until ? "until" : "none";
  });
  const [count, setCount] = useState(() => initialRRule.count || 5);
  const [until, setUntil] = useState(() => initialRRule.until || getInitialEndDate(date));

  const newRRule = useMemo(() => {
    return buildNewRRule(date, {
      frequency: frequency as RRuleFrequency,
      byDay,
      end: { type: endType, count, until },
      interval,
      relativeRepetitionType: repetitionType,
    });
  }, [frequency, interval, byDay, repetitionType, endType, count, until, date]);

  useEffect(() => {
    onChange(new RecurrenceRule(newRRule.toString()));
  }, [onChange, newRRule]);

  return (
    <div className="cw-body-base cw-space-y-4">
      <div className="cw-space-y-2">
        <Label>Repeat every</Label>
        <FrequencyField
          frequency={frequency}
          interval={interval}
          onChange={({ frequency, interval }) => {
            setFrequency(frequency);
            setInterval(interval);
          }}
        />
        {frequency === Frequency.MONTHLY && (
          <RepetitionTypeField
            date={date}
            frequency={Frequency.MONTHLY}
            type={repetitionType}
            onChange={setRepetitionType}
          />
        )}
        {frequency === Frequency.YEARLY && (
          <RepetitionTypeField
            date={date}
            frequency={Frequency.YEARLY}
            type={repetitionType}
            onChange={setRepetitionType}
          />
        )}
      </div>
      {frequency === Frequency.WEEKLY && (
        <div className="cw-space-y-2">
          <Label>On</Label>
          <DayOfWeekField
            selected={byDay}
            onChange={(days) => setByDay(days.length ? days : getInitialByDay(date))}
          />
        </div>
      )}
      <div>
        <Label>Ends</Label>
        <EndField
          type={endType}
          count={count}
          until={until}
          onChange={({ type, count, until }) => {
            setEndType(type);
            setCount(count);
            setUntil(until);
          }}
        />
      </div>
    </div>
  );
};

type ModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (recurrence: RecurrenceRule) => void;
};

export const CustomRecurrenceModal = ({
  open,
  onClose,
  onSubmit,
  ...props
}: Omit<Props, "onChange"> & ModalProps) => {
  const [updatedRecurrence, setUpdatedRecurrence] = useState<RecurrenceRule>(props.recurrence);
  const handleConfirm = useCallback(() => {
    onSubmit(updatedRecurrence);
  }, [onSubmit, updatedRecurrence]);
  return (
    <Dialog
      title="Custom repeat"
      onClose={onClose}
      open={open}
      footer={
        <>
          <Button variant="outlined" sentiment="neutral" size="xsmall" onClick={onClose}>
            Cancel
          </Button>
          <Button sentiment="positive" size="xsmall" onClick={handleConfirm}>
            Done
          </Button>
        </>
      }
    >
      <CustomRecurrence
        // Remount when the initial `recurrence` or `date` change to ensure the form resets correctly
        key={`${props.recurrence.toString()}-${props.date}`}
        {...props}
        onChange={setUpdatedRecurrence}
      />
    </Dialog>
  );
};
