import { Videocam } from "@clockwise/icons";
import { ConferencingType } from "@clockwise/schema/v2";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import classNames from "classnames";
import React from "react";
import { VideoLink } from "../types";
const openVideoLink = (url: string) => {
  window.open(url, "openVideoLink");
};

type Sentiment = "purple" | "blue" | "default";
type VideoLinkVariant = "text" | "solid";

const getSentimentFromVideoType = (type: ConferencingType) => {
  switch (type) {
    case ConferencingType.Zoom:
    case ConferencingType.Meet:
      return "blue";
    case ConferencingType.TeamsForBusiness:
    case ConferencingType.Gather:
      return "purple";
    default:
      return "default";
  }
};

const ICON_COLOR_CLASSES_HASH: { [v in VideoLinkVariant]: { [s in Sentiment]: string } } = {
  text: {
    purple: "cw-text-[#5C5FC1]",
    blue: "cw-text-[#3170E3]",
    default: "cw-text-busy",
  },
  solid: {
    purple: "cw-text-[#fff]",
    blue: "cw-text-[#fff]]",
    default: "cw-text-[#fff]]",
  },
};

const COLOR_CLASSES_HASH: { [v in VideoLinkVariant]: { [s in Sentiment]: string } } = {
  text: {
    purple: "cw-text-[#5C5FC1] cw-bg-neutral",
    blue: "cw-text-[#3170E3] cw-bg-neutral",
    default: "cw-text-busy cw-bg-neutral",
  },
  solid: {
    purple: "cw-text-[#fff] cw-bg-[#5C5FC1]",
    blue: "cw-text-[#fff] cw-bg-[#3170E3]",
    default: "cw-text-[#fff] cw-bg-busy-emphasis",
  },
};

const HOVER_CLASSES_HASH: { [v in VideoLinkVariant]: { [s in Sentiment]: string } } = {
  text: {
    purple: "hover:cw-bg-default-pressed",
    blue: "hover:cw-bg-default-pressed",
    default: "hover:cw-bg-default-pressed",
  },
  solid: {
    purple: "hover:cw-bg-opacity-80",
    blue: "hover:cw-bg-opacity-80",
    default: "hover:cw-bg-opacity-80",
  },
};

const getLabelFromVideoType = (type: ConferencingType) => {
  switch (type) {
    case ConferencingType.Zoom:
      return "Join Zoom";
    case ConferencingType.Meet:
      return "Join Google Meet";
    case ConferencingType.TeamsForBusiness:
      return "Join Microsoft Teams";
    case ConferencingType.Gather:
      return "Join Gather";
    default:
      return "Join virtual call";
  }
};

export const JoinVideoLink = ({
  videoLink,
  onClose,
  isUpcomingMeeting,
  hideIcon,
}: {
  videoLink: VideoLink;
  onClose: () => void;
  isUpcomingMeeting?: boolean;
  hideIcon?: boolean;
}) => {
  const track = useTracking();

  const onClick = () => {
    track(TrackingEvents.EVENT_POPOVER.MEETING_JOINED);
    openVideoLink(videoLink.uri);
    onClose();
  };
  const variant: VideoLinkVariant = isUpcomingMeeting ? "solid" : "text";
  const sentiment = getSentimentFromVideoType(videoLink.type);

  const colorClasses = COLOR_CLASSES_HASH[variant][sentiment];
  const hoverClasses = HOVER_CLASSES_HASH[variant][sentiment];
  const iconColor = ICON_COLOR_CLASSES_HASH[variant][sentiment];
  return (
    <button
      className={classNames(
        "cw-h-6 cw-text-12 cw-px-1 cw-gap-1 cw-font-medium cw-flex cw-items-center cw-py-1 cw-my-1 cw-rounded-md",
        "focus-visible:cw-outline-2 focus-visible:cw-outline-offset-2 cw-cursor-pointer",
        "cw-border-none cw-transition-colors cw-ease-out cw-duration-200",
        "cw-leading-none cw-font-body cw-whitespace-nowrap",
        "cw-flex cw-items-center ",
        colorClasses,

        hoverClasses,
      )}
      onClick={onClick}
    >
      {!hideIcon ? <Videocam className={classNames("cw-w-h cw-h-4 cw-mr-1", iconColor)} /> : ""}

      <span className="cw-px-1">{getLabelFromVideoType(videoLink.type)}</span>
    </button>
  );
};
