import { Dialog as MUIDialog } from "@material-ui/core";
import React, { ReactNode } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  title: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
};

export const Dialog = ({ title, children, footer, open, onClose }: Props) => {
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: "cw-w-[350px] cw-rounded-xl" }}
      BackdropProps={{
        classes: { root: "cw-bg-[#000000] cw-bg-opacity-30" },
      }}
    >
      <div className="cw-flex cw-flex-col cw-body-sm">
        {/* HEADER */}
        {title && (
          <div className="cw-body-sm cw-font-semibold cw-py-2 cw-px-4 cw-border-b cw-border-solid cw-border-subtle">
            {title}
          </div>
        )}
        {/* CONTENT */}
        <div className="cw-body-base cw-p-4 cw-pb-8">{children}</div>
        {/* FOOTER */}
        {footer && (
          <div className="cw-flex cw-justify-between cw-border-t cw-border-solid cw-border-subtle cw-py-2 cw-px-3">
            {footer}
          </div>
        )}
      </div>
    </MUIDialog>
  );
};
