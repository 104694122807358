import { isEmpty } from "lodash";
import { GQLAssistantMessageProposalResponse } from "../ai-chat/utils/types";

export const proposalIsScheduleByMoving = (
  proposal: GQLAssistantMessageProposalResponse["proposal"],
): boolean => {
  const { consequencesBlock } = proposal;
  if (isEmpty(consequencesBlock)) {
    return false;
  }

  const yourCalDiffs = consequencesBlock?.yourCalDiffs || [];
  const otherCalDiffs = consequencesBlock?.otherCalDiffs || [];

  const hasConsequences = !isEmpty(yourCalDiffs) || !isEmpty(otherCalDiffs);

  return hasConsequences;
};
