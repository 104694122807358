import useOrgDomains from "#webapp/src/components/hooks/useOrgDomains";
import { isEmailInDomains } from "@clockwise/client-commons/src/util/email";
import { ExpandLess, ExpandMore } from "@clockwise/icons";
import { ResponseStatusEnum } from "@clockwise/schema";
import { countBy } from "lodash";
import pluralize from "pluralize";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../../state/reducers/root.reducer";
import { calculateIfIsAtOrBeyondCalendarLimit } from "../../../chat/ai-chat/components/multi-calendar/calendar-layers.util";
import { useReadCalendarColors } from "../../../web-app-calendar/hooks/CalendarColorsContext";
import { EventCardAttendee } from "../../types";
import { ECAttendee } from "./ECAttendee";
import { sortedAttendeesByOrganizerYouAndStatus } from "./utils/sortAttendees";

export type Props = {
  attendees: EventCardAttendee[];
  nonEditableAttendeeIds: Set<string>;
  readOnly?: boolean;
  removeAttendee: (primaryCalendar: string) => void;
  changeOptionalityOfAttendee: (attendee: EventCardAttendee, isOptional: boolean) => void;
  attendeeDisplayLimit?: number;
};
export const ECAttendeeList = ({
  attendees,
  nonEditableAttendeeIds,
  readOnly,
  removeAttendee,
  changeOptionalityOfAttendee,
  attendeeDisplayLimit = 6,
}: Props) => {
  const calendarIdsToFullyShow = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsToFullyShow,
  );
  const { domains: orgDomains, loading: domainsLoading } = useOrgDomains();

  const calendarColorMap = useReadCalendarColors();

  const [showOverflow, setShowOverflow] = useState(false);
  const hasOverFlowAttendees = attendees.length > attendeeDisplayLimit;

  const visibleAttendees: EventCardAttendee[] = useMemo(() => {
    const sortedAttendees: EventCardAttendee[] = sortedAttendeesByOrganizerYouAndStatus(attendees);
    if (showOverflow) {
      return sortedAttendees;
    }

    const firstThreeAttendees = sortedAttendees.slice(0, attendeeDisplayLimit);
    return firstThreeAttendees;
  }, [showOverflow, attendees, calendarColorMap, calendarIdsToFullyShow]);

  const hiddenAttendeeCount = attendees.length - attendeeDisplayLimit;

  const getStatusString = () => {
    const countsOfRSVPs = countBy(attendees, (attendee) => attendee.attendingStatus);

    const statusArray: string[] = [];
    if (countsOfRSVPs[ResponseStatusEnum.Accepted]) {
      statusArray.push(`${countsOfRSVPs[ResponseStatusEnum.Accepted]} going`);
    }
    if (countsOfRSVPs[ResponseStatusEnum.Tentative]) {
      statusArray.push(`${countsOfRSVPs[ResponseStatusEnum.Tentative]} maybe`);
    }
    if (countsOfRSVPs[ResponseStatusEnum.Declined]) {
      statusArray.push(`${countsOfRSVPs[ResponseStatusEnum.Declined]} not going`);
    }
    if (countsOfRSVPs[ResponseStatusEnum.NeedsAction]) {
      statusArray.push(`${countsOfRSVPs[ResponseStatusEnum.NeedsAction]} awaiting`);
    }
    return statusArray.join(", ");
  };

  const toggleShowOverflow = () => {
    setShowOverflow(!showOverflow);
  };

  return (
    <div cw-id="ec-attendee-list" role="list" aria-label="Attendee list" className="cw-w-full">
      {visibleAttendees.map((attendee) => {
        const calendarColors = calendarColorMap[attendee.primaryCalendar];
        const isIncludedInMultiCalendar = !!calendarIdsToFullyShow.includes(
          attendee.primaryCalendar,
        );
        const isExternal =
          Boolean(orgDomains.length) &&
          !domainsLoading &&
          !isEmailInDomains(attendee.primaryCalendar, orgDomains);
        const isToggleable =
          !isExternal &&
          (isIncludedInMultiCalendar || // Always allow toggling off the calendar
            !calculateIfIsAtOrBeyondCalendarLimit(calendarIdsToFullyShow)); // Allow toggling on if not at limit)

        return (
          <div key={attendee.id} className="cw-my-0.5" cw-id="ec-attendee-list-attendee">
            <ECAttendee
              attendee={attendee}
              isEditable={!nonEditableAttendeeIds.has(attendee.primaryCalendar) && !readOnly}
              removeAttendee={removeAttendee}
              multiCalConfig={{
                calendarColors,
                isIncluded: isIncludedInMultiCalendar,
                isToggleable,
              }}
              isExternal={isExternal}
              updateOptionality={changeOptionalityOfAttendee}
            />
          </div>
        );
      })}
      {hasOverFlowAttendees && (
        <div className="cw-flex cw-flex-col cw-mt-2" cw-id="ec-attendee-list-overflow-attendees">
          <div
            className="cw-text-13 cw-font-medium cw-cursor-pointer hover:cw-underline cw-text-positive cw-flex cw-items-center"
            onClick={toggleShowOverflow}
            cw-id="ec-attendee-list-overflow-attendees-toggle"
          >
            {showOverflow
              ? "See less attendees"
              : `See ${hiddenAttendeeCount} more ${pluralize("attendee", hiddenAttendeeCount)}`}
            {showOverflow ? (
              <ExpandLess className="cw-w-4 cw-h-4" />
            ) : (
              <ExpandMore className="cw-w-4 cw-h-4" />
            )}
          </div>
          <div className="cw-text-12 cw-text-muted" cw-id="ec-attendee-list-status">
            {getStatusString()}
          </div>
        </div>
      )}
    </div>
  );
};
