import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar";
import { OutOfOfficeCard } from "@clockwise/web-commons/src/components/calendar/calendar-out-of-office/OutOfOfficeCards";
import {
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getOwnCalendarColor } from "@clockwise/web-commons/src/util/calendar-coloring";
import { uniqBy } from "lodash";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useUpdateActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import { usePlannerContext } from "../Context";
import { getDateStringKeyFromDateTime } from "../calendar-single-day/utils/getDateStringKeyFromDateTime";
import { useReadCalendarColors } from "../hooks/CalendarColorsContext";
import { hasIntervalAllDayLong } from "./utils/hasIntervalAllDayLong";

export const SingleColumnOOOEventsUnderlay = ({
  calendarId,
  dateTime,
  columnIndex,
  columnCount,
  minWidth,
}: {
  calendarId: string;
  dateTime: DateTime;
  columnIndex: number;
  columnCount: number;
  minWidth: number;
}) => {
  const { eventsByDayAndCalendar } = usePlannerContext();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateActiveEventDiff = useUpdateActiveEventDiff();
  const calendarColorSets = useReadCalendarColors();

  const dateToRender = getDateStringKeyFromDateTime(dateTime);

  const eventsByCalendarForDay = useMemo(() => {
    return eventsByDayAndCalendar ? eventsByDayAndCalendar[dateToRender] : null;
  }, [dateToRender, eventsByDayAndCalendar]);

  const allDayOOOEvents = uniqBy(
    eventsByCalendarForDay?.allDayOOOEvents[calendarId] ?? [],
    "externalEventId",
  );

  const handleSelectEvent = (calendarId: string, externalEventId: string) => {
    updateActiveEventDiff(null);
    updateActiveEvent({ calendarId, externalEventId, type: EventType.Event });
  };

  if (allDayOOOEvents.length < 1) {
    return null;
  }

  const outOfOfficeIntervals = allDayOOOEvents.map((event) => ({
    colorSet: calendarColorSets[event.calendarIds[0]] || getOwnCalendarColor(),
    headless: hasIntervalAllDayLong(event) || event.interval.toDuration().as("days") >= 1,
    interval: event.interval,
    key: event.key,
    onClick: () => handleSelectEvent(event.calendarIds[0], event.externalEventId || ""),
    subText: event.subText,
    text: event.text,
  }));

  return (
    <CalendarPositioner
      columnCountOverride={columnCount}
      columnIndexOverride={columnIndex}
      dateTimes={[dateTime]}
      gutters
      conflictResolution="column"
      minWidth={minWidth}
      positionables={outOfOfficeIntervals.map(
        ({ interval, key, colorSet, text, subText, headless, onClick }) => ({
          key: `${interval.toISO()}_${key}`,
          interval,
          render: ({ position }) => (
            <OutOfOfficeCard
              colorSet={colorSet}
              fade={interval.end < DateTime.local()}
              headless={!!headless}
              leftPad={position.renderOrder > 0}
              onClick={onClick}
              subText={subText}
              text={text}
            />
          ),
        }),
      )}
    />
  );
};
