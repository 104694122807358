import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const ToggleOnFlex = (args: SvgIconProps) => (
  <SvgIcon {...args}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1188_25433)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 5C3.13401 5 0 8.13401 0 12C0 15.866 3.13401 19 7 19H17C20.866 19 24 15.866 24 12C24 8.13401 20.866 5 17 5H7ZM17 17C19.7614 17 22 14.7614 22 12C22 9.23858 19.7614 7 17 7C14.2386 7 12 9.23858 12 12C12 14.7614 14.2386 17 17 17Z"
          fill="#039861"
        />
      </g>
      <defs>
        <clipPath id="clip0_1188_25433">
          <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
