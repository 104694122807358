import { noop } from "lodash";
import React, { ReactNode, createContext, useContext, useMemo, useState } from "react";
import invariant from "tiny-invariant";

export type TimeSuggestionState = {
  index: number | null;
  loading: boolean;
};

export type TimeSuggestionActions = {
  setTimeSuggestionIndex: (index: number | null) => void;
  setTimeSuggestionLoading: (loading: boolean) => void;
};

const emptyTimeSuggestionsState: TimeSuggestionState = {
  index: null,
  loading: false,
};

const ReadContext = createContext<TimeSuggestionState>(emptyTimeSuggestionsState);
const WriteContext = createContext<TimeSuggestionActions>({
  setTimeSuggestionIndex: noop,
  setTimeSuggestionLoading: noop,
});

export const TimeSuggestionProvider = ({
  children,
  initValue = emptyTimeSuggestionsState,
}: {
  children: ReactNode;
  initValue?: TimeSuggestionState;
}) => {
  const [timeSuggestionSelectionIndex, setTimeSuggestionSelectionIndex] = useState<
    TimeSuggestionState["index"]
  >(initValue.index);
  const [timeSuggestionSelectionLoading, setTimeSuggestionSelectionLoading] = useState<
    TimeSuggestionState["loading"]
  >(initValue.loading);

  const writeContext = useMemo(
    () => ({
      setTimeSuggestionIndex: setTimeSuggestionSelectionIndex,
      setTimeSuggestionLoading: setTimeSuggestionSelectionLoading,
    }),
    [],
  );

  const readContext = useMemo(
    () => ({ index: timeSuggestionSelectionIndex, loading: timeSuggestionSelectionLoading }),
    [timeSuggestionSelectionIndex, timeSuggestionSelectionLoading],
  );

  return (
    <WriteContext.Provider value={writeContext}>
      <ReadContext.Provider value={readContext}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadTimeSuggestion = () => useContext(ReadContext);

export const useUpdateTimeSuggestion = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdateTimeSuggestion must be within TimeSuggestionProvider");
  return context;
};
