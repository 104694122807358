import { Select, SelectOption, SelectOptionsGroup } from "@clockwise/design-system";
import { SvgIconComponent } from "@clockwise/design-system/icons";
import React from "react";
import { LegacyTooltip } from "../../tooltip";

type Props = {
  selected: MeetingType;
  options: MeetingTypeOption[];
  onChange: (value: MeetingType) => void;
};

export enum MeetingType {
  SingleTeammate = "SingleTeammate",
  Group = "Group",
  RoundRobin = "RoundRobin",
}

export type MeetingTypeOption = {
  type: MeetingType;
  name: string;
  icon: SvgIconComponent;
  available: boolean;
};

const unavailableTypeReason: { [key: string]: { title: string; body: string } } = {
  Group: {
    title: "Group scheduling links",
    body: "This feature is only available on the Teams plans. Upgrade to access this feature.",
  },
  RoundRobin: {
    title: "Round Robin",
    body:
      "This feature is only available on the Business and Enterprise plans. Upgrade to access this feature.",
  },
};

export const MeetingTypePicker = ({ selected, options, onChange }: Props) => {
  const availableOptions = options.filter((option) => option.available);
  const unavailableOptions = options.filter((option) => !option.available);

  return (
    <Select
      value={selected}
      onChange={(value) => {
        onChange(value);
      }}
    >
      {(availableOptions.length && (
        <SelectOptionsGroup>
          {availableOptions.map((option, index) => {
            return (
              <SelectOption icon={option.icon} key={`${option.name}-${index}`} value={option.type}>
                <div className="cw-pr-6">{option.name}</div>
              </SelectOption>
            );
          })}
        </SelectOptionsGroup>
      )) || <></>}
      {(unavailableOptions.length && (
        <SelectOptionsGroup label="Upgrade needed">
          {unavailableOptions.map((option, index) => {
            const unavailableReason = unavailableTypeReason[option.type];
            return (
              <LegacyTooltip
                showArrow={true}
                placement={"right"}
                header={unavailableReason?.title}
                body={unavailableReason?.body}
                styles={{ left: "10px" }}
              >
                <SelectOption
                  icon={option.icon}
                  key={`${option.name}-${index}`}
                  value={option.type}
                >
                  <div className="cw-pr-6">{option.name}</div>
                </SelectOption>
              </LegacyTooltip>
            );
          })}
        </SelectOptionsGroup>
      )) || <></>}
    </Select>
  );
};
