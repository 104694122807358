import React from "react";
import { formatNumberAsAbbreviation } from "../overview.util";

export interface HeroStatProps {
  hero: number | null;
  title: string;
  subtitle: React.ReactNode;
}

export const HeroStat = ({ hero, title, subtitle }: HeroStatProps) => {
  const renderHero = () => {
    if (!hero) {
      return "-";
    }

    return formatNumberAsAbbreviation(hero);
  };

  return (
    <div className="cw-space-y-1 cw-w-1/2 lg:cw-w-1/4">
      <div
        className="cw-heading-3xl"
        style={{ fontVariantNumeric: "proportional-nums lining-nums" }}
      >
        {renderHero()}
      </div>
      <div className="cw-heading-caption cw-uppercase">{title}</div>
      <div className="cw-body-base cw-text-subtle">{subtitle}</div>
    </div>
  );
};
