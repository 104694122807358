import classNames from "classnames";
import React from "react";

import {
  PageLayout,
  PageLayoutContent,
} from "@clockwise/web-commons/src/ui/page-layout/PageLayout";

import { ExtensionTutorialDialog } from "#webapp/src/components/extension-tutorial-dialog";
import { LoaderClock } from "@clockwise/web-commons/src/components/loader-clock";
import { useOnboarding } from "../../hooks/useOnboarding/useOnboarding";
import { FeatureCardGrid } from "../feature-card-grid";
import { GetStarted } from "../get-started-page/GetStarted";
import { PersonalizedStats } from "../personalized-stats-row/PersonalizedStats";
import { shouldHighlightHomeStatGrid } from "../ui-focus-wrappers/ui-focus.utils";
import WebExtensionDownLoadButton from "../web-app/web-app-ext-download-button/WebExtensionDownloadButton";
import { InviteModalWrapper } from "./InviteModalWrapper";
import { Salutation } from "./Salutation";

const WebLanding = () => {
  const { shouldShowNUXChecklist: shouldShowGetStartedLandingPage, loading } = useOnboarding();

  const GetStartedContent = () => {
    return (
      <PageLayout title="Get Started">
        <PageLayoutContent>
          <InviteModalWrapper />
          <GetStarted />
        </PageLayoutContent>
      </PageLayout>
    );
  };

  const WebLandingContent = () => {
    return (
      <PageLayout>
        <PageLayoutContent>
          <div
            className={classNames("cw-flex cw-flex-col cw-items-center cw-justify-between", {
              "cw-animate-serene-spotlight": shouldHighlightHomeStatGrid(),
            })}
          >
            <InviteModalWrapper />
            <ExtensionTutorialDialog />
            <div className="cw-w-full cw-max-w-screen-lg">
              <div className="cw-flex cw-justify-between cw-mb-4 cw-mt-2">
                <Salutation />
                <WebExtensionDownLoadButton />
              </div>
              <PersonalizedStats />
              <FeatureCardGrid />
            </div>
          </div>
        </PageLayoutContent>
      </PageLayout>
    );
  };

  if (loading) {
    return (
      <div className="cw-w-full cw-h-screen cw-flex cw-items-center cw-justify-center">
        <LoaderClock />
      </div>
    );
  }

  return shouldShowGetStartedLandingPage ? <GetStartedContent /> : <WebLandingContent />;
};

export default WebLanding;
