import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { Checkbox, Link, Switch } from "@clockwise/design-system";
import { EcosystemEnum } from "@clockwise/schema";
import { FeatureSettingsHoverCard } from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";
import { helpDefaultWindowName } from "@clockwise/web-commons/src/constants/route.constants";
import { PaywallBannerUpgradeCta } from "@clockwise/web-commons/src/ui/paywall-banner-upgrade-cta";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import classNames from "classnames";
import * as React from "react";
import { useMemo } from "react";
import { useMonetization } from "../../../../hooks/useMonetization";

type TeamSettingsAdminTeamCalendarProps = {
  isTeamCalendarShareChecked: boolean;
  useTeamCalendar: boolean;
  onSharedCalendarToggle: () => void;
  isSyncWFHEventsEnabled: boolean;
  onHandleSyncWFHEventsToggle: () => void;
  onHandleTeamCalendarSubscribedToggle: (subscribed: boolean) => void;
  teamName: string;
};

export const TeamSettingsAdminTeamCalendar = ({
  isTeamCalendarShareChecked,
  useTeamCalendar,
  onSharedCalendarToggle,
  isSyncWFHEventsEnabled,
  onHandleSyncWFHEventsToggle,
  onHandleTeamCalendarSubscribedToggle,
  teamName,
}: TeamSettingsAdminTeamCalendarProps) => {
  const { canTeamAvailabilityAndNMDBeEnabled, shouldUserSeeJoinExistingPlan } = useMonetization();

  const ecosystem = useEcosystem();
  const canSubscribeToTeamCalendar = useMemo(() => {
    return isTeamCalendarShareChecked && canTeamAvailabilityAndNMDBeEnabled;
  }, [isTeamCalendarShareChecked, canTeamAvailabilityAndNMDBeEnabled]);

  return (
    <div className="cw-space-y-4 ">
      <div className="cw-flex cw-gap-2">
        <div className="cw-heading-2xl">
          Sync{" "}
          <FeatureSettingsHoverCard
            id="team-availability-desc-tooltip"
            content={
              <>
                Clockwise automatically syncs individual team member's “Out of office / unavailable”
                events to a shared team calendar to help the team stay informed about everyone's
                availability at a glance.{" "}
                <Link
                  href="https://support.getclockwise.com/article/43-team-automatic-out-of-office-calendar"
                  target={helpDefaultWindowName}
                  rel="noreferrer"
                  onEmphasis
                >
                  Learn more
                </Link>
              </>
            }
          >
            <span
              className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1"
              aria-describedby="team-availability-desc-tooltip"
            >
              everyone's availability
            </span>
          </FeatureSettingsHoverCard>
          ?
        </div>
        <Switch
          checked={isTeamCalendarShareChecked && canTeamAvailabilityAndNMDBeEnabled}
          onChange={onSharedCalendarToggle}
          disabled={!canTeamAvailabilityAndNMDBeEnabled}
          label={
            <div
              className={classNames({
                "cw-text-positive":
                  isTeamCalendarShareChecked && canTeamAvailabilityAndNMDBeEnabled,
              })}
            >
              {isTeamCalendarShareChecked && canTeamAvailabilityAndNMDBeEnabled ? "Yes" : "No"}
            </div>
          }
        />
      </div>

      {!canTeamAvailabilityAndNMDBeEnabled && (
        <PaywallBannerUpgradeCta
          showJoinExistingPlan={shouldUserSeeJoinExistingPlan}
          bannerType="AvailabilityLocked"
          pricingTracking={() =>
            track(TrackingEvents.PAYWALLS.M2_ADMIN_AVAILABILTIY_PRICING_CLICKED)
          }
          billingTracking={() =>
            track(TrackingEvents.PAYWALLS.M2_ADMIN_AVAILABILTIY_JOIN_PLAN_CLICKED)
          }
        />
      )}
      <Checkbox
        checked={isTeamCalendarShareChecked && canTeamAvailabilityAndNMDBeEnabled}
        disabled
        label={
          <>
            <div
              className={classNames({
                "cw-text-default-disabled": !canTeamAvailabilityAndNMDBeEnabled,
              })}
            >
              Create a shared “Clockwise: {teamName}" calendar and sync everyone's events
            </div>
            <div
              className={classNames("cw-body-base", {
                "cw-text-default-disabled": !canTeamAvailabilityAndNMDBeEnabled,
                "cw-text-muted": canTeamAvailabilityAndNMDBeEnabled,
              })}
            >
              When enabled, visible in everyone's calendars list in{" "}
              {ecosystem === EcosystemEnum.Google ? "Google Calendar" : "Outlook"}.
            </div>
          </>
        }
      />
      <Checkbox
        checked={isSyncWFHEventsEnabled}
        disabled={!isTeamCalendarShareChecked || !canTeamAvailabilityAndNMDBeEnabled}
        onChange={onHandleSyncWFHEventsToggle}
        label="Also sync events that Clockwise categorizes as WFH (work from home)"
      />
      <Checkbox
        checked={useTeamCalendar}
        onChange={onHandleTeamCalendarSubscribedToggle}
        disabled={!canSubscribeToTeamCalendar}
        label={
          <>
            <div
              className={classNames({
                "cw-text-default-disabled": !canSubscribeToTeamCalendar,
              })}
            >
              Subscribe to the "Clockwise: {teamName}" calendar
            </div>
            <div
              className={classNames("cw-body-base", {
                "cw-text-default-disabled": !canSubscribeToTeamCalendar,
                "cw-text-muted": canSubscribeToTeamCalendar,
              })}
            >
              When enabled, visible in your calendars list in{" "}
              {ecosystem === EcosystemEnum.Google ? "Google Calendar" : "Outlook"}.
            </div>
          </>
        }
      />
    </div>
  );
};
