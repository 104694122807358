import {
  DisabledWrapper,
  focusTimeExpiredTooltipCopy,
} from "@clockwise/web-commons/src/ui/disabled-wrapper";
import React from "react";
import { useMonetization } from "../../hooks/useMonetization";

const MonetizationWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { canUserHaveFocusTimeHolds } = useMonetization();

  return (
    <DisabledWrapper
      isDisabled={!canUserHaveFocusTimeHolds}
      tooltipBody={focusTimeExpiredTooltipCopy.body}
      tooltipHeader={focusTimeExpiredTooltipCopy.header}
    >
      {children}
    </DisabledWrapper>
  );
};

export default MonetizationWrapper;
