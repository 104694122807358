import { CliOptionType } from "@clockwise/schema";
import React from "react";

import { Button, Select, SelectOption, Tooltip } from "@clockwise/design-system";
import { Edit, Replay } from "@clockwise/design-system/icons";

import { CliOptions } from "./CliOptions";

import { sortBy } from "lodash";
import { CliCommandDef, OptionConfigMap, SelectedOption } from "./types";
export interface IProps {
  commands: CliCommandDef[];
  disabled?: boolean;
  onChange: (commandConfig: { command: string; options: SelectedOption[] } | null) => void;
  onReset: () => void;
}

export const CliCommandConfig = ({ commands, disabled = false, onChange, onReset }: IProps) => {
  const [command, setCommand] = React.useState<CliCommandDef>();

  const [optionConfigMap, setOptionConfigMap] = React.useState<OptionConfigMap>({});

  const handleEdit = () => {
    onReset();
  };

  const handleReset = () => {
    updateCommandConfig(undefined, {});
  };

  const updateCommandConfig = (
    updatedCommand: typeof command,
    updatedOptionConfigMap: typeof optionConfigMap,
  ) => {
    setCommand(updatedCommand);
    setOptionConfigMap(updatedOptionConfigMap);
    if (updatedCommand) {
      const options = getSelectedOptions(updatedCommand, updatedOptionConfigMap);
      onChange({ command: updatedCommand.name, options });
    } else {
      onChange(null);
    }
  };

  const handleChange = (value: string) => {
    const selectedCommand = commands.find(({ name }) => name === value);
    if (selectedCommand === command) {
      return;
    }

    const initialOptionConfigMap = selectedCommand
      ? makeInitialOptionConfigMap(selectedCommand)
      : {};
    updateCommandConfig(selectedCommand, initialOptionConfigMap);
  };

  const sortedCommands = sortBy(commands, (c) => c.name.toLowerCase());

  return (
    <div>
      <div className="cw-flex cw-justify-between cw-items-center cw-gap-1">
        <Select
          value={command?.name ?? ""}
          onChange={handleChange}
          disabled={disabled}
          size="large"
        >
          <SelectOption value="" disabled>
            <em>Select command</em>
          </SelectOption>
          {sortedCommands.map(({ id, name }) => (
            <SelectOption key={id} value={name}>
              {name}
            </SelectOption>
          ))}
        </Select>
        <div className="cw-flex">
          <Tooltip title="Edit">
            <Button
              aria-label="Edit"
              disabled={!disabled}
              onClick={handleEdit}
              startIcon={Edit}
              variant="text"
            />
          </Tooltip>
          <Tooltip title="Reset">
            <Button
              aria-label="Reset"
              disabled={!disabled}
              onClick={handleReset}
              startIcon={Replay}
              variant="text"
            />
          </Tooltip>
        </div>
      </div>
      {command && (
        <div>
          <div className="cw-body-base cw-text-muted cw-mt-1">{command.description}</div>
          <CliOptions
            options={command.options}
            optionConfigs={optionConfigMap}
            onConfigChange={(optionName, optionConfig) => {
              updateCommandConfig(command, { ...optionConfigMap, [optionName]: optionConfig });
            }}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

/**
 * Create a config object for a given command's options
 */
const makeInitialOptionConfigMap = (command: CliCommandDef) => {
  const optionConfigMap: OptionConfigMap = {};
  command.options.forEach(({ name, type }) => {
    let values: string[];
    switch (type) {
      case CliOptionType.String:
        values = [""];
        break;
      case CliOptionType.Boolean:
      default:
        values = [];
    }
    optionConfigMap[name] = { active: false, values };
  });

  return optionConfigMap;
};

const getSelectedOptions = (command: CliCommandDef, optionConfigMap: OptionConfigMap) => {
  const options: SelectedOption[] = [];
  command.options.forEach(({ name }) => {
    const config = optionConfigMap[name];
    if (config?.active) {
      options.push({ name, values: config.values });
    }
  });
  return options;
};
