import { Info } from "@clockwise/design-system/icons";
import { DateTime } from "luxon";
import React from "react";
import {
  APUpcomingBillingDetails,
  BannerVersions,
  determineDateToDisplayForUpcomingPayment,
  getUpcomingPaymentBannerType,
} from "../apBillingGroups.util";

const Banner = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="cw-bg-busy-inset cw-flex cw-gap-2 cw-py-2 cw-body-base cw-mt-4 cw-pr-4 cw-pl-3 cw-text-busy cw-border cw-border-solid cw-rounded-md cw-border-busy-emphasis">
      <Info className="cw-w-[20px] cw-h-[20px] cw-mt-[1px]" />
      <span>{children}</span>
    </div>
  );
};

export const PendingSeatCountBanner = ({
  upcomingBillingDetails,
}: {
  upcomingBillingDetails: APUpcomingBillingDetails;
}) => {
  const appropriateDateToShow = determineDateToDisplayForUpcomingPayment(upcomingBillingDetails);

  const nextVisibleDate =
    appropriateDateToShow &&
    DateTime.fromFormat(appropriateDateToShow, "yyyy-MM-dd").toFormat("LLL d, yyyy");

  const {
    currentUsageQuantity,
    billedQuantity,
    requestedUpdatedQuantity,
    nextTrueUpDate,
  } = upcomingBillingDetails;

  const bannerType = getUpcomingPaymentBannerType(
    billedQuantity,
    currentUsageQuantity,
    requestedUpdatedQuantity,
    Boolean(nextTrueUpDate),
  );

  switch (bannerType) {
    case BannerVersions.RequestConfirm:
      return (
        <Banner>
          Pending adjustment: An admin has requested to update your seat count to{" "}
          {requestedUpdatedQuantity} seat(s), which will take effect at the start of your next
          billing cycle on {nextVisibleDate}.
        </Banner>
      );
    case BannerVersions.TrueUp:
      return (
        <Banner>
          Your plan's seat count has increased to {currentUsageQuantity}, since new users have
          joined since your last bill. You have not yet been charged for this increase, which will
          take effect at the start of your next billing cycle on {nextVisibleDate}.
        </Banner>
      );
    default:
      return null;
  }
};
