import { useQuery } from "@apollo/client";
import classNames from "classnames";
import React from "react";
import { logger } from "../../util/logger.util";
import { ConflictsResolved } from "./ConflictsResolved";
import { FocusTimeHeld } from "./FocusTimeHeld";
import { MeetingTime } from "./MeetingTime";
import { PersonalizedStatsRowSkeleton } from "./PersonalizedStatsRowSkeleton";
import { PersonalizedStatsRowDocument } from "./graphql/__generated__/PersonalizedStatsRow.generated";
import { getCurrentWeek } from "./util/getCurrentWeek";
import { parseFeatureUsageGQL } from "./util/parseFeatureUsageGQL";
import { parsePersonalizedStatsRowGQL } from "./util/parsePersonalizedStatsRowGQL";

export const accentBorderClasses = " cw-border-t cw-border-solid cw-pt-3";

export const PersonalizedStatsRow = () => {
  const { data, loading, error } = useQuery(PersonalizedStatsRowDocument);

  const analytics = parsePersonalizedStatsRowGQL(
    data?.viewer.user?.orgs.edges?.[0]?.node?.homeAnalytics,
  );

  if (loading) {
    return <PersonalizedStatsRowSkeleton />;
  }

  if (error) {
    logger.error("Unable to fetch home analytics", error);
  }

  if (!analytics) {
    return null;
  }

  const { focusTimeHeld, meetingTime, conflictsResolved } = analytics;
  const { flexMeetingEnabled, focusTimeEnabled } = parseFeatureUsageGQL(data);
  const currentWeek = getCurrentWeek();

  return (
    <section
      className={classNames(
        "cw-grid cw-gap-4 cw-mb-16",
        "cw-grid-cols-1 md:cw-grid-cols-3 lg:cw-grid-cols-4",
      )}
      aria-labelledby="cw-personalized-stats-heading"
    >
      <h2
        className={classNames(
          accentBorderClasses,
          "cw-border-t-[#003f2e] cw-flex cw-flex-col cw-items-center",

          "md:cw-col-span-3 lg:cw-col-span-1 lg:cw-items-start",
        )}
        id="cw-personalized-stats-heading"
      >
        <p className="cw-heading-lg" aria-hidden>
          This week
        </p>
        <p className="cw-heading-lg cw-font-light" aria-hidden>
          {currentWeek}
        </p>
      </h2>
      <MeetingTime
        currentWeek={meetingTime.currentWeek ?? "0"}
        previousWeek={meetingTime.previousWeek ?? "0"}
      />
      <FocusTimeHeld
        currentWeek={focusTimeHeld.currentWeek ?? "0"}
        previousWeek={focusTimeHeld.previousWeek ?? "0"}
        focusTimeEnabled={focusTimeEnabled}
      />
      <ConflictsResolved
        currentWeek={conflictsResolved.currentWeek}
        previousWeek={conflictsResolved.previousWeek}
        flexMeetingEnabled={flexMeetingEnabled}
      />
    </section>
  );
};
