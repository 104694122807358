import { CheckCircleFilled, ChevronRight, Close, RocketLaunchFilled } from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateSuggestedPrompt } from "../chat/ai-chat/hooks/SuggestedPromptContext";
import { NUXPreferencesModal } from "../nux-education-modals/NUXPreferencesModal";
import { NUXReschedulingModal } from "../nux-education-modals/NUXReschedulingModal";
import { NUXSchedulingModal } from "../nux-education-modals/NUXSchedulingModal";
import { NuxPopoverWrapper } from "./NuxPopoverWrapper";
import { useChecklistFlags } from "./useChecklistFlags";
import { useNuxPopoverStates } from "./useNuxPopoverStates";
import { NavEducationStep } from "./useNuxPopoverStates.util";

const CompletionStatus = ({
  percentageCompleted,
  onClose,
}: {
  percentageCompleted: number;
  onClose: () => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const getPercentageCompleteEmoji = (percent: number) => {
    if (percent === 33) {
      return " 🙂";
    } else if (percent === 67) {
      return "☺️";
    } else if (percent === 100) {
      return "🥳";
    } else {
      return "😢";
    }
  };

  const onMouseEnter = () => {
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      onClick={isHovering ? onClose : undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classNames(
        "cw-cursor-pointer cw-flex cw-rounded-xl cw-items-center cw-transition-colors cw-duration-300 cw-font-medium cw-text-[11px] cw-h-[22px] cw-w-[55px] cw-justify-center",
        {
          "cw-bg-neutral-inset": !isHovering,
        },
      )}
    >
      <div
        className={classNames(
          "cw-absolute cw-transition-opacity cw-duration-300 cw-flex cw-items-center",
          {
            "cw-opacity-0": !isHovering,
            "cw-opacity-100": isHovering,
          },
        )}
      >
        <Close className="cw-text-default cw-w-[14px] cw-h-[14px]" />
      </div>
      <div
        className={classNames(
          "cw-absolute cw-transition-opacity cw-duration-300 cw-flex cw-items-center",
          {
            "cw-opacity-0": isHovering,
            "cw-opacity-100": !isHovering,
          },
        )}
      >{`${percentageCompleted}%  ${getPercentageCompleteEmoji(percentageCompleted)}`}</div>
    </div>
  );
};

export const ChecklistItem = ({
  number,
  onClick,
  title,
  subtext,
  disabled,
}: {
  number: number;
  onClick: () => void;
  title: string;
  subtext: string;
  disabled?: boolean;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className={"cw-flex cw-gap-x-3 cw-items-center cw-cursor-pointer"}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {disabled ? (
        <div className="cw-flex cw-items-center cw-justify-center cw-rounded-[100%] cw-h-4 cw-w-4 cw-basis-[16px] cw-border cw-border-solid cw-border-muted cw-cursor-pointer">
          <CheckCircleFilled className="cw-text-subtle cw-w-4 cw-h-4" />
        </div>
      ) : (
        <div className="cw-cursor-pointer cw-px-[4px] cw-flex cw-items-center cw-justify-center cw-text-[8px] cw-font-semibold cw-text-onEmphasis cw-bg-positive-emphasis cw-rounded-[100%] cw-h-4 cw-w-[14px] cw-basis-[16px] cw-border cw-border-solid cw-border-brand-interactable">
          {number}
        </div>
      )}
      <div
        className={classNames("cw-flex cw-flex-col cw-w-full cw-gap-y-0.5 cw-cursor-pointer", {
          "cw-opacity-60": disabled,
        })}
      >
        <div
          className={classNames(
            "cw-text-13 cw-font-medium cw-text-default cw-flex cw-items-center cw-h-4",
            {
              "cw-text-positive": isHovering && !disabled,
            },
          )}
        >
          {title}
          {isHovering && (
            <ChevronRight
              className={classNames("cw-ml-1 cw-w-4 cw-h-4 cw-mt-0.5", {
                "cw-text-subtle": disabled,
                "cw-text-positive": !disabled,
              })}
            />
          )}
        </div>
        <div className="cw-text-13 cw-text-subtle"> {subtext}</div>
      </div>
    </div>
  );
};

enum ModalType {
  Scheduling = "Scheduling",
  Rescheduling = "Rescheduling",
  Preferences = "Preferences",
}
export const SuperpowersChecklist = () => {
  const track = useTracking();
  const [openModal, setOpenModal] = useState<ModalType | null>(null);
  const {
    schedulingCompleted,
    reschedulingCompleted,
    preferencesCompleted,
    completeSchedulingChecklistItem,
    completeReschedulingChecklistItem,
    completePreferencesChecklistItem,
    shouldShowSuperpowersChecklist,
    setShowSuperpowersChecklist,
  } = useChecklistFlags();
  const { navEducationCurrentState } = useNuxPopoverStates();
  const navigate = useNavigate();
  const updatePromptText = useUpdateSuggestedPrompt();

  const onClickScheduling = () => {
    setOpenModal(ModalType.Scheduling);
    track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.SCHEDULING_CLICKED);
  };

  const onClickQuickRescheduling = () => {
    setOpenModal(ModalType.Rescheduling);
    track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.RESCHEDULING_CLICKED);
  };

  const onClickCalendarPreferences = () => {
    setOpenModal(ModalType.Preferences);
    track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.CALENDAR_PREFERENCES_CLICKED);
  };

  const percentageCompleted = Math.round(
    ([schedulingCompleted, reschedulingCompleted, preferencesCompleted].filter(
      (completed) => completed === true,
    ).length /
      3) *
      100,
  );

  const isSuperPowerPopoverVisible =
    navEducationCurrentState === NavEducationStep.SuperpowerChecklist;

  const onCloseChecklist = () => {
    setShowSuperpowersChecklist(false);
    track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.DISMISSED, {
      schedulingCompleted,
      reschedulingCompleted,
      preferencesCompleted,
    });
  };

  if (!shouldShowSuperpowersChecklist) {
    return null;
  }

  const onClickSchedulingModalAction = () => {
    setOpenModal(null);
    completeSchedulingChecklistItem();
    updatePromptText("Schedule @");
    track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.SCHEDULING_MODAL_ACTION_CLICKED);
  };

  const onClickReschedulingModalAction = () => {
    setOpenModal(null);
    completeReschedulingChecklistItem();
    updatePromptText("Reschedule #");
    track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.RESCHEDULING_MODAL_ACTION_CLICKED);
  };

  const onClickCalendarPreferencesModalAction = () => {
    setOpenModal(null);
    completePreferencesChecklistItem();
    navigate(appPaths.accountPreferences);
    track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.CALENDAR_PREFERENCES_MODAL_ACTION_CLICKED);
  };

  return (
    <div className="cw-flex cw-flex-col cw-gap-y-2 cw-transition-discrete">
      <div className="cw-flex cw-justify-between cw-items-center cw-gap-y-3">
        <RocketLaunchFilled className="cw-text-subtle cw-w-4 cw-h-4 cw-mr-2" />
        <div className="cw-text-subtle cw-font-medium cw-text-13">Clockwise superpowers</div>
        <div className="cw-border-t cw-border-solid cw-border-subtle cw-mx-2 cw-grow" />
        {isSuperPowerPopoverVisible ? (
          <NuxPopoverWrapper type={NavEducationStep.SuperpowerChecklist}>
            <div className="cw-flex">
              <CompletionStatus
                percentageCompleted={percentageCompleted}
                onClose={onCloseChecklist}
              />
            </div>
          </NuxPopoverWrapper>
        ) : (
          <div className="cw-flex">
            <CompletionStatus
              percentageCompleted={percentageCompleted}
              onClose={onCloseChecklist}
            />
          </div>
        )}
      </div>
      <div className="cw-flex cw-flex-col cw-gap-1.5">
        <ChecklistItem
          number={1}
          onClick={onClickScheduling}
          title="Powerful scheduling"
          subtext="Find availability in an instant"
          disabled={schedulingCompleted}
        />
        <ChecklistItem
          number={2}
          onClick={onClickQuickRescheduling}
          title="Quick rescheduling"
          subtext="Get smart suggestions in one click"
          disabled={reschedulingCompleted}
        />
        <ChecklistItem
          number={3}
          onClick={onClickCalendarPreferences}
          title="Intelligent automation"
          subtext="Craft a schedule that works for you"
          disabled={preferencesCompleted}
        />
      </div>
      {openModal === ModalType.Scheduling && (
        <NUXSchedulingModal
          onClose={() => {
            setOpenModal(null);
            completeSchedulingChecklistItem();
            track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.SCHEDULING_MODAL_DISMISSED);
          }}
          onClickAction={onClickSchedulingModalAction}
        />
      )}
      {openModal === ModalType.Rescheduling && (
        <NUXReschedulingModal
          onClose={() => {
            setOpenModal(null);
            completeReschedulingChecklistItem();
            track(TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.RESCHEDULING_MODAL_DISMISSED);
          }}
          onClickAction={onClickReschedulingModalAction}
        />
      )}
      {openModal === ModalType.Preferences && (
        <NUXPreferencesModal
          onClose={() => {
            setOpenModal(null);
            completePreferencesChecklistItem();
            track(
              TrackingEvents.NUX_2025.SUPERPOWERS_CHECKLIST.CALENDAR_PREFERENCES_MODAL_DISMISSED,
            );
          }}
          onClickAction={onClickCalendarPreferencesModalAction}
        />
      )}
    </div>
  );
};
