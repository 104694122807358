import { Button } from "@clockwise/design-system";
import { InfoFilled, WarningFilled } from "@clockwise/icons";
import classNames from "classnames";
import React from "react";

export type IBannerMessage = {
  text: string;
  type: "warn" | "info" | "prism" | "loading";
};

export const BannerMessage: React.FC<{
  message: IBannerMessage["text"];
  type: IBannerMessage["type"];
}> = ({ message, type }) => {
  const loadingMessage = (
    <>
      <WarningFilled className="cw-w-3.5 cw-h-3.5" />
      <div>{message}</div>
      <Button
        variant="outlined"
        sentiment="warning"
        size="mini"
        onClick={() => window.location.reload()}
      >
        Reload
      </Button>
    </>
  );
  return (
    <div
      className={classNames(
        "cw-h-full cw-w-full",
        "cw-flex cw-space-x-2 cw-items-center cw-justify-center",
        "cw-body-base cw-text-12 cw-font-medium",
        {
          "cw-bg-warning-inset cw-border-warning cw-text-warning-muted":
            type === "warn" || type === "loading",
          "cw-bg-neutral-inset cw-border-neutral cw-text-muted": type === "info",
        },
      )}
    >
      {type === "info" && <InfoFilled className="cw-w-3.5 cw-h-3.5" />}
      {type === "warn" && <WarningFilled className="cw-w-3.5 cw-h-3.5" />}
      <div className="cw-p-1 cw-flex cw-justify-between cw-items-center cw-gap-2">
        {type === "loading" ? loadingMessage : message}
      </div>
    </div>
  );
};
