import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SudoAddMembersToBillingGroupMutationVariables = Types.Exact<{
  input: Types.SudoAddMembersToBillingGroupMutationInput;
}>;


export type SudoAddMembersToBillingGroupMutation = { __typename: 'Mutation', sudoAddMembersToBillingGroup: { __typename: 'SudoAddMembersToBillingGroupMutationPayload', clientMutationId: string | null } | null };


export const SudoAddMembersToBillingGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SudoAddMembersToBillingGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SudoAddMembersToBillingGroupMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sudoAddMembersToBillingGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}}]}}]}}]} as unknown as DocumentNode<SudoAddMembersToBillingGroupMutation, SudoAddMembersToBillingGroupMutationVariables>;