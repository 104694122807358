import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const disabled: CSSProperties = {
  opacity: 0.4,
  pointerEvents: "none",
};

export const toggleContainer: CSSProperties = {
  display: "flex",
  alignItems: "center",
  marginBottom: 15,
};

export const toggleDisabledContainer: CSSProperties = {
  ...toggleContainer,
  ...disabled,
};

export const toggleControl: CSSProperties = {
  marginRight: 10,
};

export const freeBusyContainer: CSSProperties = {
  width: 310,
  paddingBottom: 16,
};

export const freeBusyDisabledContainer: CSSProperties = {
  ...freeBusyContainer,
  ...disabled,
};

export const remindersContainer: CSSProperties = {
  width: 310,
};

export const remindersDisabledContainer: CSSProperties = {
  ...remindersContainer,
  ...disabled,
};

export const styles = {
  toggleContainer,
  toggleDisabledContainer,
  toggleControl,
  freeBusyContainer,
  freeBusyDisabledContainer,
  remindersContainer,
  remindersDisabledContainer,
};
