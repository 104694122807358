import { useMutation } from "@apollo/client";
import { Button, TextField } from "@clockwise/design-system";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { SudoDeleteBillingGroupDocument } from "../graphql/__generated__/SudoDeleteBillingGroup.generated";
import { ButtonWrapper } from "./ButtonWrapper";
import { FormWrapper } from "./FormWrapper";

export const DeleteBillingGroup = () => {
  const [orgId, setOrgId] = useState<string | undefined>();
  const [adminEmail, setAdminEmail] = useState<string | undefined>();
  const [billingGroupId, setBillingGroupId] = useState<string | undefined>();

  const [deleteBillingGroup, { loading }] = useMutation(SudoDeleteBillingGroupDocument);

  const resetFields = () => {
    setOrgId("");
    setAdminEmail("");
    setBillingGroupId("");
  };

  const onDeleteBillingGroup = () => {
    if (!orgId || !adminEmail || !billingGroupId) {
      return;
    }
    void deleteBillingGroup({
      variables: {
        input: {
          orgId,
          adminEmail,
          billingGroupId,
        },
      },
      onCompleted: () => {
        toast.success("Successfully deleted billing group");
        resetFields();
      },
      onError: (error) => {
        toast.error("Error deleting billing group " + error.message);
      },
    });
  };
  const allFieldsPopulated = orgId && adminEmail && billingGroupId;

  return (
    <FormWrapper>
      <div className="cw-flex cw-gap-x-2 cw-flex-wrap">
        <TextField
          label="Billing Group ID"
          value={billingGroupId}
          onChange={(e) => setBillingGroupId(e.target.value)}
        />
        <TextField
          label="Admin Email"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
        />
        <TextField label="Org ID" value={orgId} onChange={(e) => setOrgId(e.target.value)} />
      </div>

      <div className="cw-flex"></div>

      <ButtonWrapper>
        <Button
          size="medium"
          disabled={loading || !allFieldsPopulated}
          sentiment="destructive"
          fullWidth={false}
          onClick={onDeleteBillingGroup}
        >
          Delete Billing Group
        </Button>
      </ButtonWrapper>
    </FormWrapper>
  );
};
