import { filter, flatten, flow, some, uniqBy, values } from "lodash";
import { DateTime } from "luxon";
import { PlannerEventCard, PlannerEventCardsByDay } from "../../types";

export const getCardsFromCardsByDay: (
  cardsByDay: PlannerEventCardsByDay | undefined,
  renderDates: DateTime[],
) => PlannerEventCard[] = (cardsByDay, renderDates) =>
  flow(
    // handle undefined case
    (nullableObj: PlannerEventCardsByDay | undefined) => nullableObj || {},
    // get PlannerEventCards
    values,
    flatten,
    // remove duplicates
    (cards: PlannerEventCard[]) => uniqBy(cards, (card) => `${card.key}`),
    // filter out cards that don't intersect with the dates to render
    (cards: PlannerEventCard[]) =>
      filter(cards, (card) =>
        some(
          renderDates,
          (dateTime) => dateTime.equals(card.interval.start) || card.interval.contains(dateTime),
        ),
      ),
  )(cardsByDay);
