import { RecommendedSlotRange } from "@clockwise/client-commons/src/util/time-slot";
import { Button, Switch } from "@clockwise/design-system";
import { OpenInNew, SvgIconComponent } from "@clockwise/design-system/icons";
import { fg_brand } from "@clockwise/design-system/tokens";
import { InfoFilled } from "@clockwise/icons";
import { AutopilotEventStatusEnum, DayOfWeek } from "@clockwise/schema";
import { FlexDetailsInput, FlexRange } from "@clockwise/schema/v2";
import { Sticker } from "@clockwise/web-commons/src/components/sticker/Sticker";
import { LegacyTooltip } from "@clockwise/web-commons/src/ui/tooltip";
import { useReadActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import { InfoOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useMonetization } from "../../../hooks/useMonetization";
import { useUnpauseEvent } from "../../web-app-calendar/hooks/useUnpauseEvent";
import { Async } from "../atoms/Async";
import { EventElementsWrap } from "../atoms/EventElementsWrap";
import { InlineAction } from "../atoms/InlineAction";
import {
  WorkingHoursByAttendee,
  useEventFlexConstraints,
} from "../hooks/EventFlexConstraintsContext";
import { ECFlexSettings } from "./ECFlexSettings";

export type Flexibility = AutopilotEventStatusEnum | "Stuck";

export const isFlexibilityDisabled = (flexibility: Flexibility) => {
  switch (flexibility) {
    case AutopilotEventStatusEnum.External:
    case AutopilotEventStatusEnum.OverQuota:
    case AutopilotEventStatusEnum.TrialExpired:
    case AutopilotEventStatusEnum.NoDuration:
    case AutopilotEventStatusEnum.PrivateEvent:
      return true;
    default:
      return false;
  }
};

export const ECFlexibility = (
  props: Omit<
    React.ComponentProps<typeof FlexibilityPanel>,
    | "flexStatus"
    | "meetingHoursConflict"
    | "getFlexDescription"
    | "loading"
    | "eventId"
    | "setFlexModalOpen"
    | "availableWorkDays"
    | "minMaxRecommendedValues"
    | "usersWorkingHours"
  >,
) => {
  const {
    flexStatus,
    meetingHoursConflict,
    getFlexDescription,
    loading,
    availableWorkDays,
    minMaxRecommendedValues,
    usersWorkingHours,
  } = useEventFlexConstraints();
  const activeEvent = useReadActiveEvent();
  const eventId = activeEvent?.externalEventId ?? null;

  return (
    <>
      <FlexibilityPanel
        {...props}
        flexStatus={flexStatus}
        meetingHoursConflict={meetingHoursConflict}
        getFlexDescription={getFlexDescription}
        loading={loading}
        eventId={eventId}
        availableWorkDays={availableWorkDays}
        minMaxRecommendedValues={minMaxRecommendedValues}
        usersWorkingHours={usersWorkingHours}
      />
    </>
  );
};

export const FlexibilityPanel = ({
  onFlexibilityChange,
  flexDetails,
  flexStatus,
  getFlexDescription,
  readOnly = false,
  showChanges = false,
  meetingHoursConflict = false,
  loading = false,
  eventId = null,
  onFlexDetailsChange,
  availableWorkDays,
  minMaxRecommendedValues,
  usersWorkingHours,
}: {
  onFlexibilityChange: (flexible: boolean) => void;
  onFlexDetailsChange: (flexDetails: FlexDetailsInput) => void;
  readOnly?: boolean;
  showChanges?: boolean;
  flexDetails: FlexDetailsInput;
  flexStatus?: AutopilotEventStatusEnum | null | undefined;
  meetingHoursConflict?: boolean;
  getFlexDescription: (
    start?: string | null | undefined,
    end?: string | null | undefined,
    flexRange?: FlexRange | null | undefined,
    allowedDays?: DayOfWeek[] | null | undefined,
  ) => string;
  loading?: boolean;
  eventId?: string | null;
  availableWorkDays: DayOfWeek[];
  minMaxRecommendedValues: RecommendedSlotRange;
  usersWorkingHours: WorkingHoursByAttendee[];
}) => {
  const [isFlexModalOpen, setIsFlexModalOpen] = useState(false);
  const { update: unpauseEvent } = useUnpauseEvent(eventId);
  const handleUnpauseEvent = () => {
    void unpauseEvent({
      onCompleted: () => {
        toast.success("Event unpaused");
      },
    });
  };
  const flexibilityStatus = meetingHoursConflict
    ? "Stuck"
    : flexStatus ?? AutopilotEventStatusEnum.CanMove;

  const { isFlexible, flexRange, allowedDays, timeOfDayRange } = flexDetails;
  const isDisabled = readOnly || isFlexibilityDisabled(flexibilityStatus);
  const showUnpauseButton = flexibilityStatus === AutopilotEventStatusEnum.Paused;
  const flexDescription = getFlexDescription(
    timeOfDayRange?.start,
    timeOfDayRange?.end,
    flexRange,
    allowedDays,
  );

  return (
    <>
      <EventElementsWrap showChanges={showChanges} name="flex">
        <Async loading={loading} loadingHeight={20}>
          <div className="cw-flex cw-flex-col cw-flex-1">
            <div className="cw-body-base cw-flex cw-justify-between cw-flex-1 cw-items-center">
              <div className="cw-flex-1 cw-flex cw-space-x-2 cw-body-sm cw-leading-4 cw-h-5 cw-items-center">
                <FlexibilityStatus flexible={isFlexible} flexibility={flexibilityStatus} />
              </div>
              <FlexibilityControl
                disabled={isDisabled}
                flexible={isFlexible}
                flexibility={flexibilityStatus}
                onFlexibilityChange={(flexible) => onFlexibilityChange?.(flexible)}
              />
            </div>
            {isFlexible && (
              <FlexibilityDescription
                description={flexDescription}
                flexibility={flexibilityStatus}
              />
            )}
            {isFlexible && !isDisabled && (
              <div className="cw-mt-2 cw-flex cw-flex-col cw-flex-1 cw-gap-2">
                {showUnpauseButton && eventId && (
                  <Button fullWidth size="mini" variant="outlined" onClick={handleUnpauseEvent}>
                    Let it move again
                  </Button>
                )}
                <Button
                  fullWidth
                  size="mini"
                  variant="outlined"
                  onClick={() => setIsFlexModalOpen(true)}
                >
                  Update move range
                </Button>
              </div>
            )}
          </div>
        </Async>
      </EventElementsWrap>
      <ECFlexSettings
        isOpen={isFlexModalOpen}
        goBack={() => setIsFlexModalOpen(false)}
        flexDetails={flexDetails}
        onFlexDetailsChange={onFlexDetailsChange}
        availableWorkDays={availableWorkDays}
        meetingHoursConflict={meetingHoursConflict}
        minMaxRecommendedValues={minMaxRecommendedValues}
        usersWorkingHours={usersWorkingHours}
        flexStatus={flexStatus ?? AutopilotEventStatusEnum.CanMove}
      />
    </>
  );
};

const FlexibilityStatus = ({
  flexible,
  flexibility,
}: {
  flexible: boolean;
  flexibility: Flexibility;
}) => {
  const { userIsOnActiveTrial } = useMonetization();
  const text = flexible ? "Flexible meeting" : "Mark meeting as flexible";

  let stickerText = "";
  let stickerSentiment: "neutral" | "warning" | "busy" = "neutral";
  let tooltipText =
    "Flexible meetings indicate that the meeting can move to the best time for you and attendees within the preferences you've set.";
  let stickerIcon: SvgIconComponent | undefined = undefined;

  const userIsOnTrialAndCanMove =
    userIsOnActiveTrial && flexibility === AutopilotEventStatusEnum.CanMove;

  switch (flexibility) {
    case AutopilotEventStatusEnum.Pinned:
    case AutopilotEventStatusEnum.EventOutsideTimeRange:
      if (flexible) {
        // We only want the badge to show up when a meeting is marked flex
        stickerText = "Timing is set";
        tooltipText =
          "Clockwise will not change events on your calendar that occur in the next 24 hours, or events that have already taken place. Future meetings in this series will still move.";
        stickerIcon = InfoOutlined;
      }
      break;
    case AutopilotEventStatusEnum.Paused:
      stickerText = "Paused";
      stickerSentiment = "warning";
      stickerIcon = InfoOutlined;
      tooltipText =
        "This meeting is paused because it was manually rescheduled. Clockwise will not move a flexible meeting that has been manually rescheduled. Future meetings in this series will still move.";
      break;
    case "Stuck":
      if (flexible) {
        // We only want the badge to show up when a meeting is marked flex
        stickerText = "Cannot move";
        stickerSentiment = "warning";
        tooltipText =
          "Clockwise can't find any overlap in attendees' meeting hours. This meeting may need to be manually rescheduled.";
        stickerIcon = InfoOutlined;
      }
      break;
    case AutopilotEventStatusEnum.TrialExpired:
    case AutopilotEventStatusEnum.OverQuota:
      stickerText = "TEAMS";
      stickerSentiment = "busy";
      break;
  }
  if (userIsOnTrialAndCanMove) {
    stickerText = "TEAMS";
    stickerSentiment = "busy";
  }

  return (
    <>
      <div
        className={classNames({
          "cw-text-default cw-font-medium": flexible,
          "cw-text-muted cw-font-medium": !flexible,
        })}
      >
        {text}
      </div>
      <LegacyTooltip showArrow={false} placement="top" body={tooltipText}>
        {!stickerText ? (
          <InfoFilled className="cw-w-4 cw-h-4 cw-mb-[-2px] cw-cursor-pointer cw-text-info-disabled" />
        ) : (
          <Sticker sentiment={stickerSentiment} Icon={stickerIcon} text={stickerText} />
        )}
      </LegacyTooltip>
    </>
  );
};

const onClickUpgradeRoute = () => {
  const updatePlanUrl = getUrlForFeature("plansAndBilling", { relative: true });
  window.open(updatePlanUrl, "_blank");
};

const FlexibilityDescription = ({
  flexibility,
  description,
}: {
  flexibility: Flexibility;
  description: string;
}) => {
  let Description = <>{description}</>;

  if (
    flexibility === AutopilotEventStatusEnum.OverQuota ||
    flexibility === AutopilotEventStatusEnum.TrialExpired
  ) {
    Description = (
      <>
        Flexible meeting moves aren't supported on the Free plan.{" "}
        <InlineAction
          onClick={onClickUpgradeRoute}
          ariaLabel="Upgrade Plan"
          label="Upgrade"
          icon={
            <OpenInNew
              style={{
                fontSize: "13px",
                color: fg_brand,
                bottom: "-3px",
                position: "relative",
                marginBottom: "1px",
              }}
            />
          }
        />{" "}
        to let your flexible meetings move again.
      </>
    );
  }
  return (
    <div
      className={classNames("cw-body-sm cw-text-12 cw-leading-[18px] -cw-mt-0.5 cw-pr-3", {
        "cw-text-muted": flexibility !== "Stuck",
        "cw-text-warning": flexibility === "Stuck",
      })}
    >
      {Description}
    </div>
  );
};

const FlexibilityControl = ({
  disabled,
  flexible,
  flexibility,
  onFlexibilityChange,
}: {
  disabled: boolean;
  flexible: boolean;
  flexibility: Flexibility;
  onFlexibilityChange: (flexible: boolean) => void;
}) => {
  const dontShowToggle =
    flexibility === AutopilotEventStatusEnum.TrialExpired ||
    flexibility === AutopilotEventStatusEnum.OverQuota;

  if (!dontShowToggle) {
    return (
      <FlexibilitySwitch flexible={flexible} disabled={disabled} onChange={onFlexibilityChange} />
    );
  } else {
    return null;
  }
};

const FlexibilitySwitch = ({
  flexible,
  disabled,
  onChange,
}: {
  disabled: boolean;
  flexible: boolean;
  onChange: (flexible: boolean) => void;
}) => {
  return (
    <Switch
      size="small"
      checked={flexible}
      disabled={disabled}
      onChange={onChange}
      aria-label="Toggle flexibility"
    />
  );
};
