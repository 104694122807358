import { ButtonSet } from "@clockwise/design-system";
import { Edit, FileCopyOutlined } from "@clockwise/design-system/icons";
import { Button } from "@clockwise/design-system/src/components/Button";
import { CalendarClock } from "@clockwise/icons";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getEditSchedulingLinkPath } from "@clockwise/web-commons/src/util/scheduling.util";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ChromeDialog } from "../../../../chrome-dialog";
import { ShareBestTimesLinkModal } from "../../../../scheduling-link";
import { useReadCalendarColors } from "../../../../web-app-calendar/hooks/CalendarColorsContext";
import { useSetAIError } from "../../hooks/AIErrorContext";
import { MeetingSettings } from "../../utils/types";

type LinkCardCollapsedProps = {
  messageId: string;
  linkName: string;
  linkUrl: string;
  meetingSettings: MeetingSettings;
};

type EditComponentProps = {
  expandOnHover?: boolean;
  onClick: () => void;
};

const EditComponent = ({ expandOnHover = true, onClick }: EditComponentProps) => {
  const displayClasses = "group-hover:cw-block group-focus:cw-block";
  const hideClasses = "group-hover:cw-hidden group-focus:cw-hidden";

  return (
    <>
      <div
        className={classNames("cw-hidden", {
          [displayClasses]: expandOnHover,
        })}
      >
        <div
          onClick={onClick}
          className="cw-flex cw-items-center cw-h-4 cw-body-sm cw-text-positive cw-gap-1 hover:cw-cursor-pointer"
        >
          <Edit className="cw-h-[15px] cw-w-[15px]" />
          <span className="cw-pt-[3px]">Edit link</span>
        </div>
      </div>
      <div
        className={classNames("cw-block cw-text-muted cw-h-4", {
          [hideClasses]: expandOnHover,
        })}
      >
        <Edit className="cw-h-[15px] cw-w-[15px]" />
      </div>
    </>
  );
};

export const LinkCard = ({
  messageId,
  linkName,
  linkUrl,
  meetingSettings,
}: LinkCardCollapsedProps) => {
  const trackEvent = useTracking();
  const setError = useSetAIError();
  const calendarColors = useReadCalendarColors();

  const { name, slug, description, linkMembers, timingSettings, isActive } = meetingSettings;
  const { durations, defaultDuration, timeRange } = timingSettings;

  const [modalOpen, setModalOpen] = useState(false);

  const notifyLinkCopySuccess = () => toast.success("Copied the link to the clipboard!");

  const onShareTimes = () => {
    trackEvent(TrackingEvents.CHAT.SCHEDULING_LINK.SHARE_TIMES);
    setModalOpen(true);
  };

  const onCopyLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    navigator.clipboard
      .writeText(linkUrl)
      .then(() => {
        notifyLinkCopySuccess();
        trackEvent(TrackingEvents.CHAT.SCHEDULING_LINK.COPY_LINK);
      })
      .catch((error: Error) => {
        setError({
          error: error,
          message: "Oops. There was a problem copying the link. Please try again.",
          showUserMessage: true,
        });
      });
  };

  const onEditLink = () => {
    trackEvent(TrackingEvents.CHAT.SCHEDULING_LINK.EDIT_LINK);
    window.open(getEditSchedulingLinkPath(linkName, slug), "_blank");
  };

  useEffect(() => {
    trackEvent(TrackingEvents.CHAT.SCHEDULING_LINK.VIEWED, {
      meeting_settings_id: meetingSettings.id,
      message_id: messageId,
    });
  }, []);

  return (
    <div className="cw-flex-col cw-my-2">
      <div className="cw-grow cw-min-w-0">
        <div
          className={`
          cw-w-full cw-rounded-xl cw-drop-shadow-sm 
          cw-py-2 cw-px-3
          cw-border cw-border-solid cw-border-default
          cw-group focus:cw-border-brand-emphasis-pressed
          cw-flex-col hover:cw-border-brand-emphasis-hover`}
        >
          <div className="cw-w-full cw-flex cw-justify-between cw-text-start cw-items-center cw-pb-2">
            <span className="cw-font-semibold cw-truncate cw-flex-1 cw-mr-3">{name}</span>
            <span className="cw-max-w-16">
              <EditComponent expandOnHover={isActive} onClick={() => onEditLink()} />
            </span>
          </div>
          {description && (
            <div className="cw-w-full cw-flex cw-justify-between cw-text-start cw-items-center cw-pb-2">
              {description}
            </div>
          )}
          <div
            className={`      
            cw-flex cw-gap-2 cw-items-center
            cw-caption cw-text-muted cw-pb-2
            `}
          >
            {linkMembers && linkMembers.length > 1 && (
              <span>
                <AttendeeAvatarStack maxShown={8} overlap size="small">
                  {linkMembers.map((lm) => {
                    const borderColor = calendarColors?.[lm.calendarId]?.border;
                    return (
                      <AttendeeAvatar
                        key={lm.calendarId}
                        profile={lm.userProfile}
                        isInOverlap
                        size="small"
                        cw-id="scheduling-link-attendee-avatar"
                        borderColor={borderColor}
                      />
                    );
                  })}
                </AttendeeAvatarStack>
                {" | "}
              </span>
            )}
            <span>{timingSettings.defaultDuration} minutes</span>
          </div>
          <div className="cw-flex cw-flex-wrap cw-items-center">
            <ButtonSet>
              <Button
                onClick={(event) => onCopyLink(event)}
                startIcon={FileCopyOutlined}
                size="small"
                sentiment={"neutral"}
                variant={"outlined"}
              >
                Copy link
              </Button>
              <Button
                onClick={onShareTimes}
                startIcon={CalendarClock}
                size="xsmall"
                sentiment="neutral"
                variant="text"
              >
                Share times
              </Button>
            </ButtonSet>
          </div>
        </div>
      </div>
      {modalOpen && (
        <ChromeDialog open>
          <ShareBestTimesLinkModal
            url={linkUrl}
            linkName={linkName}
            name={name}
            slug={slug}
            durations={durations || [defaultDuration]}
            defaultDuration={defaultDuration}
            windowSizeDays={(timeRange.windowSizeDays && timeRange.windowSizeDays) || null}
            multipleUser={!!(linkMembers && linkMembers.length > 1)}
            onClose={() => setModalOpen(false)}
            fullscreen={false}
            renderedFrom={"ai-chat-scheduling-link-card"}
          />
        </ChromeDialog>
      )}
    </div>
  );
};
