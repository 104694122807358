import { IWorkingHours } from "#webapp/src/__schema__";
import { Checkbox } from "@clockwise/design-system";
import { CopyButtonStyle } from "@clockwise/web-commons/src/ui/working-hours";
import { default as React } from "react";
import WorkingHoursSkeleton from "./components/WorkingHoursSkeleton";
import { SplitMeetingHours } from "./components/split-meeting-hours";

const MeetingHours = ({
  workingHours,
  meetingHours,
  showMeetingHours,
  onCheckShowMeetingHours,
  onUpdateMeetingHours,
  loading,
}: {
  workingHours: IWorkingHours | null;
  meetingHours: IWorkingHours | null;
  showMeetingHours: boolean;
  onCheckShowMeetingHours: (checked: boolean) => void;
  onUpdateMeetingHours: (updatedMeetingHours: IWorkingHours) => void;
  loading: boolean;
}) => {
  return (
    <div className="-cw-mb-4">
      <Checkbox
        checked={!showMeetingHours}
        onChange={onCheckShowMeetingHours}
        label="My meeting hours are the same as my working hours"
        disabled={loading}
      />

      {showMeetingHours &&
        (loading ? (
          <WorkingHoursSkeleton />
        ) : (
          <div className="cw-pt-4">
            <SplitMeetingHours
              copyButtonStyle={CopyButtonStyle.Standard}
              workingHours={workingHours as any} // TODO: https://linear.app/getclockwise/issue/GM-1603/once-onboardingnux-is-deleted-clean-up-types-for-splitworkinghours-and
              meetingHours={meetingHours as any}
              onUpdateMeetingHours={onUpdateMeetingHours as any}
            />
          </div>
        ))}
    </div>
  );
};

export default MeetingHours;
