import { ConflictCluster } from "@clockwise/schema";
import { DateTime, Interval } from "luxon";

export const getEarliestConflictClusterStartTime = (conflictClusters: ConflictCluster[]) => {
  let firstStart = DateTime.invalid("No time found");

  conflictClusters.forEach((conflictCluster) => {
    const start = getClusterStartTime(conflictCluster);
    if (start.isValid) {
      if (!firstStart.isValid || start < firstStart) {
        firstStart = start;
      }
    }
  });
  return firstStart;
};

export const getClusterStartTime = (conflictCluster: ConflictCluster) => {
  const startTime = Interval.fromISO(conflictCluster.timeBound).start;

  return startTime;
};
