import { graphql } from "react-relay";

export const webAppOnboardingCompleteFragments = {
  org: graphql`
    fragment WebAppOnboardingComplete_org on Org {
      id
      slackSettingsErrorable {
        ... on SlackSettingsList {
          list {
            id
            slackTeamId
          }
        }
      }
    }
  `,
};

export const webAppOnboardingCompleteQuery = graphql`
  query WebAppOnboardingCompleteQuery($orgRelayId: ID!) {
    org: node(id: $orgRelayId) {
      ... on Org {
        ...WebAppOnboardingComplete_org
      }
    }
  }
`;
