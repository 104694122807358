import { SmartHoldType } from "./types";

// Note: If you change this, remember to also change it in services repo!
export const FOCUS_TIME_IDEAL_MINUTES_DEFAULT = 900;

export const FOCUS_TIME_RANGE_MIN = 2;

export const FOCUS_TIME_RANGE_MAX = 41;

export const SMART_HOLD_LABEL: { [K in SmartHoldType]: string } = {
  FocusTime: "Focus Time",
  Lunch: "lunch",
  TravelTime: "travel time",
};
