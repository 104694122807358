import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useMemo } from "react";
import { sortedAttendeesByOrganizerYouAndStatus } from "../../../../../../web-app-calendar/event-context-menu/utils/sortedAttendeesByOrganizerYouAndStatus";
import {
  ProposalThreadEventDetailsDocument,
  ProposalThreadEventDetailsQuery,
} from "./__generated__/ProposalThreadEventDetails.v2.generated";

type ProposalThreadEventDetails = {
  loading: boolean;
  description: string | undefined;
  attendees: ProposalThreadAttendees;
  eventId: string | undefined;
  title: string;
};

type EventDetails = ProposalThreadEventDetailsQuery["event"];
export type ProposalThreadAttendees = Extract<EventDetails, { __typename: "Event" }>["attendees"];

export const useProposalThreadEventDetails = ({
  externalEventId,
  calendarId,
}: {
  externalEventId: string;
  calendarId: string;
}): ProposalThreadEventDetails => {
  const { data, loading: loadingData } = useGatewayQuery(ProposalThreadEventDetailsDocument, {
    variables: {
      id: externalEventId,
      calendarId,
    },
    skip: !externalEventId || !calendarId,
  });

  const loading = loadingData && !data;
  const description = data?.event?.description || undefined;
  const title = data?.event?.title || "";
  const eventId = data?.event?.id || undefined;

  const attendees = useMemo(
    () =>
      data?.event?.attendees
        ? sortedAttendeesByOrganizerYouAndStatus(data?.event?.attendees, calendarId)
        : [],
    [calendarId, data?.event?.attendees],
  );

  return useMemo(() => {
    return {
      eventId,
      loading,
      description,
      attendees,
      title,
    };
  }, [loading, description, attendees, eventId, title]);
};
