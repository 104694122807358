import React from "react";
import { Header, SlideLayout, Text } from "../../shared-components";
import scheduleImage from "./assets/schedule.svg";

const Image = () => {
  return <img src={scheduleImage} className="cw-w-full md:cw-h-[475px] cw-mt-3" />;
};

export const ScheduleSlide = () => {
  return (
    <SlideLayout>
      <Header>Find the best time, every time</Header>
      <Text>
        Quickly find a time for any meeting. Even when everybody's busy, Clockwise can rearrange
        schedules to make it happen.
      </Text>
      <Image />
    </SlideLayout>
  );
};
