import { Button, Modal, TextArea } from "@clockwise/design-system";
import { Close } from "@clockwise/icons";
import React, { useState } from "react";

export const PMFSurveyQuestionsModal = ({
  isOpen,
  onClose,
  satisfactionResponse,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    why: string | null,
    benefit: string | null,
    improvement: string | null,
    alternative: string | null,
  ) => void;
  satisfactionResponse: string;
}) => {
  const [whyText, setWhyText] = useState<string | null>(null);
  const [benefitText, setBenefitText] = useState<string | null>(null);
  const [improvementText, setImprovementText] = useState<string | null>(null);
  const [alternativeText, setAlternativeText] = useState<string | null>(null);

  const handleSubmit = () => {
    onSubmit(whyText, benefitText, improvementText, alternativeText);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size={400}
      centered={true}
      radius={11}
      padding="lg"
      withCloseButton={false}
      transitionProps={{ duration: 300, transition: "fade" }}
    >
      <div className="cw-flex cw-flex-col cw-gap-y-4 cw-font-body">
        <div className="cw-flex cw-items-center cw-justify-between ">
          <div className="cw-text-[10px]">Thank you—this goes directly to our product team!</div>
          <Close
            onClick={onClose}
            className="cw-text-18 cw-cursor-pointer cw-text-muted hover:cw-text-muted-hover"
          />
        </div>
        <div className="cw-flex cw-flex-col">
          <div className="cw-text-13 cw-font-semibold cw-mb-2 cw-leading-5">
            Why did you choose <span className="cw-text-brand">{satisfactionResponse}</span>?
          </div>
          <TextArea
            minRows={4}
            placeholder="Tell us a little more."
            onChange={(e) => setWhyText(e.target.value)}
          />
        </div>
        <div className="cw-flex cw-flex-col">
          <div className="cw-text-12 cw-mb-2 cw-leading-5">
            What is the main benefit you get from using Clockwise?
          </div>
          <TextArea onChange={(e) => setBenefitText(e.target.value)} />
        </div>
        <div className="cw-flex cw-flex-col">
          <div className="cw-text-12 cw-mb-2 cw-leading-5">
            How can we make Clockwise more useful for you?
          </div>
          <TextArea onChange={(e) => setImprovementText(e.target.value)} />
        </div>
        <div className="cw-flex cw-flex-col">
          <div className="cw-text-12 cw-mb-2 cw-leading-5">
            What would you use as an alternative if Clockwise were no longer available?
          </div>
          <TextArea onChange={(e) => setAlternativeText(e.target.value)} />
        </div>
        <div className="cw-flex cw-justify-end cw-mt-1">
          <Button onClick={handleSubmit} size="small" sentiment="positive">
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};
