import { fg_muted } from "@clockwise/design-system/tokens";
import { GroupFilled } from "@clockwise/icons";
import { TradeoffTypeEnum } from "@clockwise/schema";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { CaretDown, CaretUp } from "phosphor-react";
import pluralize from "pluralize";
import React from "react";
import { useToggle } from "usehooks-ts";
import { TradeoffAttendee } from "../../chat/ai-chat/utils/types";
import { FixableConflictTooltip } from "../atoms/FixableConflictTootlip";
import { TradeoffAttendeeTooltip } from "../atoms/TradeoffAttendeeTooltip";
import { getColorForTradeoffType, getIconForTradeoffType } from "../utils/getStylesForTradeoffType";
import { ECTradeoffList } from "./ECTradeoffList";
export type Tradeoff = {
  title: string;
  attendees: TradeoffAttendee[];
};

const TradeoffSummary = ({
  title,
  type,
  expanded,
  attendees,
}: {
  title: string;
  type: TradeoffTypeEnum;
  expanded: boolean;
  attendees: TradeoffAttendee[];
}) => {
  const iconColor = getColorForTradeoffType(type);
  const showTooltip = type === TradeoffTypeEnum.FIXABLE_CONFLICT;
  return (
    <div
      className={classNames(
        "cw-flex cw-items-center cw-w-full cw-h-7 cw-px-2 cw-text-12 cw-font-medium",
        {
          "cw-rounded-t-md cw-shadow-[0_1px_3px_0_rgba(0,0,0,0.12)] cw-bg-neutral-inset": expanded,
          "cw-rounded-md cw-bg-neutral hover:cw-bg-neutral-inset": !expanded,
        },
      )}
    >
      <div className="cw-mr-1 cw-flex cw-items-center"> {getIconForTradeoffType(type)}</div>
      <div className="cw-flex-1 cw-flex cw-items-center">
        <div>{title}</div>
        {showTooltip && <FixableConflictTooltip />}
      </div>
      <div>
        {!!attendees && !isEmpty(attendees) && (
          <TradeoffAttendeeTooltip attendees={attendees} type={type}>
            <AttendeesAffected attendees={attendees} />
          </TradeoffAttendeeTooltip>
        )}
      </div>

      <div className="cw-flex cw-items-center cw-justify-center">
        {expanded ? (
          <CaretUp size={16} color={iconColor} weight="fill" fillOpacity={1} />
        ) : (
          <CaretDown size={16} color={iconColor} weight="fill" fillOpacity={1} />
        )}
      </div>
    </div>
  );
};

const AttendeesAffected = ({ attendees }: { attendees: TradeoffAttendee[] }) => {
  const attendeeCount = attendees.length;
  return (
    <div className="cw-body-sm cw-text-muted cw-flex cw-items-center cw-mx-2 cw-text-12 cw-font-normal">
      <div> {attendeeCount} </div>
      <GroupFilled
        fill={fg_muted}
        className="cw-mx-1 cw-h-3 cw-w-3"
        aria-label={pluralize("person", attendeeCount)}
      />
      <div> affected</div>
    </div>
  );
};

export const ECTradeoffsWithDrawer = ({
  tradeoffSummary,
  tradeoffList,
  type,
  defaultExpanded,
}: {
  tradeoffSummary: Tradeoff;
  tradeoffList: Tradeoff[];
  type: TradeoffTypeEnum;
  defaultExpanded: boolean;
}) => {
  const [expanded, setExpanded] = useToggle(defaultExpanded);

  return (
    <div
      tabIndex={1}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setExpanded();
        }
      }}
      onClick={setExpanded}
      className={classNames(
        "cw-rounded-md cw-cursor-pointer",
        "cw-border-solid cw-bg-neutral",
        {
          "cw-text-warning cw-body-sm": type === TradeoffTypeEnum.AVAILABILITY_ISSUE,
          "cw-text-busy cw-body-sm":
            type === TradeoffTypeEnum.FIXABLE_CONFLICT || type === TradeoffTypeEnum.INCONVENIENCE,
        },
        {
          "cw-border cw-border-subtle": !expanded,
          "cw-border-[1.5px] cw-border-warning":
            expanded && type === TradeoffTypeEnum.AVAILABILITY_ISSUE,
          "cw-border-[1.5px] cw-border-busy-emphasis":
            expanded &&
            (type === TradeoffTypeEnum.FIXABLE_CONFLICT || type === TradeoffTypeEnum.INCONVENIENCE),
        },
      )}
    >
      <TradeoffSummary
        {...tradeoffSummary}
        type={type}
        expanded={expanded}
        attendees={tradeoffSummary.attendees ? tradeoffSummary.attendees : []}
      />
      {expanded && (
        <div className="cw-px-2 cw-py-0.5 cw-my-1" cw-id={`tradeoff-expanded-${type}`}>
          <ECTradeoffList variant="neutral" tradeoffList={tradeoffList} />{" "}
        </div>
      )}
    </div>
  );
};
