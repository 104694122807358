import { ApolloError, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { formatSparkleInTitle } from "../../web-app-calendar/hooks/useEditExistingEvent.util";
import {
  SaveFlexStatusDocument,
  SaveFlexStatusMutation,
} from "../ai-chat/apollo/__generated__/SaveFlexStatus.generated";
import { GQLDiffBlock, GQLProposal } from "../ai-chat/utils/types";
import { useActiveProposal } from "./useActiveProposal";

type UseChangeFlexToggleProps = {
  onCompleted?: (data: SaveFlexStatusMutation) => void;
  onError?: (error: ApolloError) => void;
};

type UseChangeFlexToggleResult = [
  (isFlexible: boolean, diffId?: string) => Promise<void>,
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

const getOptimisticResponse = (
  msgId: string | undefined,
  conversationId: string | undefined,
  proposal: GQLProposal | undefined,
  isFlexible: boolean,
  diffId: string,
): SaveFlexStatusMutation | undefined => {
  if (!msgId || !conversationId || !proposal) return undefined;

  const updatedDiffBlockIndex = proposal.diffBlocks.findIndex((diffBlock) =>
    diffBlock.diffs.some((diff) => diff.id === diffId),
  );

  const updatedDiffBlock = proposal.diffBlocks[updatedDiffBlockIndex];

  const updatedDiffWithinDiffBlockIndex = updatedDiffBlock.diffs.findIndex(
    (diff) => diff.id === diffId,
  );
  const updatedDiffWithinDiffBlock = updatedDiffBlock.diffs[updatedDiffWithinDiffBlockIndex];

  const newDiffsForDiffBlock = [...updatedDiffBlock.diffs];
  const newEventTitleWithFlex = formatSparkleInTitle(updatedDiffWithinDiffBlock.title, isFlexible);

  // Order for the diffBlock and diffs within the diff block matter to prevent wierd UI jumps
  newDiffsForDiffBlock[updatedDiffWithinDiffBlockIndex] = {
    ...updatedDiffWithinDiffBlock,
    title: newEventTitleWithFlex,
    isFlexible: isFlexible,
  };

  const newDiffBlocks = [...proposal.diffBlocks];
  newDiffBlocks[updatedDiffBlockIndex] = {
    ...updatedDiffBlock,
    diffs: newDiffsForDiffBlock,
  } as GQLDiffBlock;

  return {
    __typename: "Mutation",
    saveFlexStatus: {
      __typename: "SaveFlexStatusPayload",
      message: {
        __typename: "ProposalResponse",
        id: msgId,
        conversationId: conversationId,
        proposal: {
          ...proposal,
          diffBlocks: [...newDiffBlocks],
        },
      },
    },
  };
};

export const useChangeFlexToggle = ({
  onCompleted,
  onError,
}: UseChangeFlexToggleProps): UseChangeFlexToggleResult => {
  const { msgId, conversationId, proposal } = useActiveProposal();
  const [saveFlexStatus, { loading, error }] = useMutation(SaveFlexStatusDocument);

  const onChangeFlexToggle = useCallback(
    async (isFlexible: boolean, diffId?: string) => {
      if (!diffId) return;

      const optimisticResponse = getOptimisticResponse(
        msgId,
        conversationId,
        proposal,
        isFlexible,
        diffId,
      );
      await saveFlexStatus({
        onCompleted,
        onError,
        variables: { input: { isFlexible: isFlexible, diffId } },
        optimisticResponse,
      });
    },
    [saveFlexStatus, onCompleted, onError, msgId, conversationId, proposal],
  );

  return [onChangeFlexToggle, { loading, error }];
};
