import { Tooltip } from "@clockwise/design-system";
import classNames from "classnames";
import React from "react";

export const OverflowAvatar = ({
  numberAdditionalAttendees,
  toolTipContent,
}: {
  numberAdditionalAttendees: number;
  toolTipContent: string;
}) => {
  return (
    <Tooltip title={toolTipContent}>
      <div className="cw-p-0 cw-inline-flex cw-relative cw-shrink-0 cw-align-middle cw-box-border cw-w-6 cw-h-6">
        <div
          className={classNames(
            "cw-text-muted cw-bg-neutral-inset-hover cw-flex cw-w-full cw-h-full cw-overflow-hidden cw-box-border cw-relative cw-items-center cw-leading-none cw-select-none cw-rounded-[50%] cw-justify-center cw-font-body",
            "cw-font-semibold cw-border cw-border-solid cw-border-default cw-text-12",
          )}
        >
          <>{`+${numberAdditionalAttendees.toString()}`}</>
        </div>
      </div>
    </Tooltip>
  );
};
