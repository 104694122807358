import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { SlackChannelSuggestionsDocument } from "./__generated__/SlackChannelSuggestions.v2.generated";

type Props = {
  teamId: string;
  disabled: boolean;
  value: SlackChannel | undefined;
  error?: boolean;
  onChange: (channel: SlackChannel) => void;
};

export type SlackChannel = {
  name: string;
  channelId: string;
};

export function SlackChannelPicker({ teamId, value, disabled, onChange, error }: Props) {
  const { data, loading } = useGatewayQuery(SlackChannelSuggestionsDocument, {
    variables: { teamId },
    context: { useBatching: false },
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<SlackChannel | undefined>(value);

  const suggestions =
    data?.team?.slackAccount?.publicChannels
      .map(({ name, id }) => ({ name, channelId: id }))
      .sort((a, b) => a.name.localeCompare(b.name)) || [];

  if (searchQuery && suggestions.findIndex((c) => c.name === searchQuery) === -1 && !loading) {
    // Add the manual entry to the list of options
    suggestions.push({ name: searchQuery, channelId: `CustomSlackEntry-${searchQuery}` });
  }

  return (
    <Autocomplete
      fullWidth
      loading={loading}
      loadingText="Loading channels..."
      disabled={disabled}
      options={suggestions}
      getOptionLabel={(option) => option.name}
      value={selectedValue}
      disableClearable
      autoHighlight
      renderInput={({ inputProps, ...params }) => (
        <TextField
          {...params}
          error={!selectedValue || error}
          label="Which Slack channel should it post to?"
          inputProps={inputProps}
          fullWidth
        />
      )}
      onChange={(_e, option) => {
        if (option) {
          setSelectedValue(option);
          onChange(option);
        }
      }}
      onInputChange={(_e, v) => {
        setSearchQuery(v);
      }}
    />
  );
}
