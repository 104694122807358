import { sortBy } from "lodash";
import { DateTime } from "luxon";

type GraphEntityError = { __typename: "GraphEntityError" };

export type CurrentOrgPersonsResponse = {
  userId: string | null;
  primaryCalendarId: string;
  calendarAliases: string[];
  signUpMillis: number | null;
  profile: {
    givenName: string | null;
    familyName: string | null;
  } | null;
  primaryBillingGroupIdErrorable: PrimaryBillingGroupIdErrorable;
}[];

type AdminList = {
  list: { userId: string | null }[];
};

type AdminListErrorable = GraphEntityError | AdminList;

type PrimaryBillingGroupId = { primaryBillingGroupId: string | null };

type PrimaryBillingGroupIdErrorable = GraphEntityError | PrimaryBillingGroupId | null;

export type BillingGroupsResponse = {
  id: string;
  name: string;
  adminOrgPersons: AdminListErrorable;
}[];

export type OrgResponse = {
  billingGroups?: BillingGroupsResponse;
  currentOrgPersons?: CurrentOrgPersonsResponse;
};

type billingGroupHash = {
  [key: string]: {
    name: string;
    adminSet: Set<string>;
  };
};

type CSVOrgPerson = {
  primaryCalendarId: string;
  firstName: string;
  lastName: string;
  signupDate: string;
  plan: string;
  role: "Member" | "Admin";
};

const getAdminList = (maybeAdminList: AdminListErrorable) => {
  if ((maybeAdminList as AdminList).list !== undefined) {
    return (maybeAdminList as AdminList).list;
  } else {
    return;
  }
};

const getPrimaryBillingGroupId = (maybePBGId: PrimaryBillingGroupIdErrorable) => {
  if ((maybePBGId as PrimaryBillingGroupId).primaryBillingGroupId !== undefined) {
    return (maybePBGId as PrimaryBillingGroupId).primaryBillingGroupId;
  } else {
    return;
  }
};

// Without this function, we would need to loop through all the billing groups for every orgperson we loop through,
// and loop through all admins for every one of the billing groups. This has us do this crappy loop only once
const formatBillingGroupsToSearchableHash = (allBillingGroups: BillingGroupsResponse) => {
  const billingGroupHash: billingGroupHash = {};
  for (const billingGroup of allBillingGroups) {
    const setOfAdmins: Set<string> = new Set();
    const adminList = getAdminList(billingGroup.adminOrgPersons);
    if (adminList) {
      for (const admin of adminList) {
        if (admin.userId) {
          setOfAdmins.add(admin.userId);
        }
      }
    }
    billingGroupHash[billingGroup.id] = {
      name: billingGroup.name,
      adminSet: setOfAdmins,
    };
  }
  return billingGroupHash;
};

export const addCorrectBillingGroupToUser = (
  allOrgPersons: CurrentOrgPersonsResponse,
  allBillingGroups: BillingGroupsResponse,
) => {
  const billingGroupsById = formatBillingGroupsToSearchableHash(allBillingGroups);
  const newStructuredPersonsForCSV: CSVOrgPerson[] = [];
  for (const person of allOrgPersons) {
    const signupDate =
      person.signUpMillis && DateTime.fromMillis(person.signUpMillis).toFormat("LLL dd yyyy");
    const primaryBillingGroupId = getPrimaryBillingGroupId(person.primaryBillingGroupIdErrorable);
    const maybeBillingGroup = primaryBillingGroupId && billingGroupsById[primaryBillingGroupId];
    let plan = "Free";
    let role: "Member" | "Admin" = "Member";
    if (maybeBillingGroup) {
      plan = maybeBillingGroup.name;
      role = maybeBillingGroup.adminSet.has(person.userId || "") ? "Admin" : "Member";
    }
    const finalLayoutOfPerson = {
      primaryCalendarId: person.primaryCalendarId,
      firstName: person.profile?.givenName || "",
      lastName: person.profile?.familyName || "",
      signupDate: signupDate || "",
      plan,
      role,
    };
    newStructuredPersonsForCSV.push(finalLayoutOfPerson);
  }
  return sortBy(newStructuredPersonsForCSV, "primaryCalendarId");
};
