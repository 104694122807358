import React from "react";

const WorkingHoursTooltip = () => {
  return (
    <>
      We'll use this information to set your preferred schedule and calculate your Focus Time each
      week.
    </>
  );
};

export default WorkingHoursTooltip;
