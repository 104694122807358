import { ResponseStatusEnum } from "@clockwise/schema/v2";
import { EventCardAttendee } from "../../types";

export type Person = {
  email: string;
  displayName: string;
  givenName: string | null;
  familyName: string | null;
  externalImageUrl: string | null;
  isMe: boolean;
  id: string;
};

export type Attendee = {
  id: string;
  isOptional: boolean | null;
  isOrganizer: boolean | null;
  responseStatus: ResponseStatusEnum | null;
  person: Person;
  user: {
    id: string;
  } | null;
};

export const toEventCardAttendee = (attendee: Attendee): EventCardAttendee => ({
  id: attendee.id,
  primaryEmail: attendee.person.email,
  primaryCalendar: attendee.person.email,
  userId: attendee?.user?.id ?? null,
  isYou: attendee.person.isMe,
  profile: {
    givenName: attendee.person.givenName,
    familyName: attendee.person.familyName,
    externalImageUrl: attendee.person.externalImageUrl,
  },
  isOptional: attendee.isOptional ?? undefined,
  isOrganizer: attendee.isOrganizer ?? undefined,
  attendingStatus: attendee.responseStatus ?? undefined,
});
