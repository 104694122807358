import React from "react";
import { AvatarRow } from "../../../avatar-row";
import { TeamMember, getNamesList } from "./TeamJoinCreateStep.util";

export const TeamCard = ({
  teamName,
  teamMembers,
}: {
  teamName: string;
  teamMembers: TeamMember[];
}) => {
  const visibleTeamNames = getNamesList(teamMembers);

  return (
    <div className="cw-flex cw-w-full cw-items-center cw-justify-between cw-bg-default cw-rounded-md">
      <div>
        <div className="cw-body-base cw-font-bold cw-text-accent">{teamName}</div>
        <span className="cw-body-sm cw-text-subtle">{visibleTeamNames}</span>
      </div>
      <AvatarRow orgPersons={teamMembers} size="medium" overlap maxAvatarCount={3} />
    </div>
  );
};
