import { Select, SelectOption } from "@clockwise/design-system";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import React from "react";
import { useReadCalendarColor } from "../../web-app-calendar/hooks/CalendarColorsContext";
import { EventElementsWrap } from "../atoms/EventElementsWrap";
import { CalendarInfoDocument } from "./__generated__/CalendarInfo.v2.generated";

export function ECCalendarInfo({ calendarId }: { calendarId: string }) {
  const calendarColor = useReadCalendarColor(calendarId);

  const { data, loading } = useGatewayQuery(CalendarInfoDocument, {
    variables: { id: calendarId },
    fetchPolicy: "cache-first",
  });
  const calendarName = loading
    ? ""
    : data?.calendar?.displayName ?? calendarId ?? "No Calendar Name Found";

  return (
    <EventElementsWrap name="calendar">
      {/* not really a select, but we want the same styling */}
      <Select showExpandIcon={false} disabled value={calendarId} onChange={() => {}} fullWidth>
        [
        <SelectOption key={calendarId} value={calendarId}>
          <div
            className="cw-w-3 cw-h-3 cw-rounded"
            style={{
              backgroundColor: calendarColor?.bg_Emphasis,
            }}
          />
          <span className="cw-body-sm cw-font-medium cw-text-default-disabled">{calendarName}</span>
        </SelectOption>
        ]
      </Select>
    </EventElementsWrap>
  );
}
