import classNames from "classnames";
import React from "react";
export type PowerStep = 1 | 2 | 3 | 4;

export const PowerMeter = ({ strength }: { strength: PowerStep }) => {
  return (
    <div className="cw-flex cw-gap-2 cw-items-center md:cw-w-[300px] cw-w-10/12 cw-px-2 cw-pb-5">
      <div className="cw-text-positive-pressed cw-font-semibold cw-text-14">Scheduling Power</div>
      <div className="cw-flex-1 cw-h-2 cw-rounded-full cw-bg-[#FF7000]" />
      <div
        className={classNames("cw-flex-1 cw-h-2 cw-rounded-full", {
          "cw-bg-neutral-inset": strength < 2,
          "cw-bg-[#FFE800]": strength >= 2,
        })}
      />
      <div
        className={classNames("cw-flex-1 cw-h-2 cw-rounded-full", {
          "cw-bg-neutral-inset": strength < 3,
          "cw-bg-[#B6FF80]": strength >= 3,
        })}
      />
      <div
        className={classNames("cw-flex-1 cw-h-2 cw-rounded-full", {
          "cw-bg-neutral-inset": strength < 4,
          "cw-bg-[#00A972]": strength >= 4,
        })}
      />
    </div>
  );
};
