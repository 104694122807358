import { Button } from "@clockwise/design-system";
import React from "react";

export const ErrorBlanket = ({
  hasError,
  onClose,
  message,
}: {
  hasError: boolean;
  onClose: () => void;
  message: string;
}) => {
  if (!hasError) return null;
  return (
    <div className="cw-absolute cw-bg-default cw-inset-0 cw-z-20 cw-rounded-lg cw-flex cw-flex-col cw-items-center cw-justify-center">
      <span className="cw-body-lg cw-mb-4">{message}</span>
      <div className="cw-flex cw-justify-center cw-items-center cw-gap-2">
        <Button onClick={onClose} size="small" variant="text" sentiment="neutral">
          Close
        </Button>
      </div>
    </div>
  );
};
