import { EventBusy } from "@material-ui/icons";
import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import { CalendarColorSet, getOwnCalendarColor } from "../../../util/calendar-coloring";
import { CalendarPositioner } from "../calendar-positioner/CalendarPositioner";
import { OutOfOfficeInterval } from "./types";

export const OutOfOfficeTabs = ({
  dateTimes = [],
  outOfOfficeIntervals = [],
}: {
  dateTimes: DateTime[];
  outOfOfficeIntervals: OutOfOfficeInterval[];
}) => {
  return (
    <CalendarPositioner
      dateTimes={dateTimes}
      gutters
      conflictResolution="column"
      positionables={outOfOfficeIntervals.map(({ interval, key, colorSet, text, onClick }) => ({
        key: `${interval.toISO()}_${key}`,
        interval,
        render: ({ position }) => (
          <OutOfOfficeTab
            colorSet={colorSet}
            text={text}
            onClick={onClick}
            leftPad={position.renderOrder > 0}
          />
        ),
      }))}
    />
  );
};

export const OutOfOfficeTab = ({
  colorSet = defaultColorSet,
  leftPad = false,
  onClick,
  text = "",
}: {
  colorSet: CalendarColorSet;
  leftPad?: boolean;
  onClick: () => void;
  text?: string;
}) => {
  return (
    <div className={classNames("cw-h-full cw-w-full", leftPad ? "cw-pl-1" : "cw-pl-0")}>
      <div
        className="cw-h-full cw-w-full cw-cursor-pointer cw-rounded-t"
        style={{
          backgroundColor: `${colorSet.bg}b3`,
        }}
        onClick={onClick}
      >
        <div
          className="cw-p-1 cw-body-base backdrop:cw-leading-[13px] cw-text-[12px] cw-font-medium"
          style={{ color: colorSet.fg }}
        >
          <div className="cw-flex cw-flex-row cw-items-center">
            <EventBusy className="cw-inline-block cw-mr-1 cw-text-[16px] cw-align-middle" />
            <div className="cw-truncate">{text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const defaultColorSet = getOwnCalendarColor();
