import { fg_fragmented_muted } from "@clockwise/design-system/tokens";
import {
  EventContainer,
  OutsideHours,
  UnnamedEvent,
} from "@clockwise/web-commons/src/components/feature-illustration/";
import { MoonStars, Sun } from "phosphor-react";
import React from "react";

const WorkingHoursIllustration = () => {
  return (
    <EventContainer>
      <OutsideHours duration={30} variant="morning" />
      <div className="cw-relative">
        <div className="cw-absolute cw-inset-x-0 cw-top-0 ">
          <Sun
            size={30}
            color={fg_fragmented_muted}
            className="cw-flex cw-items-center cw-w-full cw-mt-2"
          />
        </div>
        <UnnamedEvent duration={135} />
        <div className="cw-absolute cw-inset-x-0 cw-bottom-0 ">
          <MoonStars
            size={30}
            color={"rgba(97, 114, 243, 1)"} //doesn't exist in tokens
            className="cw-flex cw-items-center cw-w-full cw-mb-2"
          />
        </div>
      </div>
      <OutsideHours duration={30} variant="evening" />
    </EventContainer>
  );
};

export default WorkingHoursIllustration;
