import { Button, Select, SelectOption, SelectOptionsGroup } from "@clockwise/design-system";
import {
  Close,
  Diversity2,
  Group,
  NotInterested,
  Person,
  PersonOutline,
} from "@clockwise/design-system/icons";
import { Flag, FlagFilled, HelpFilled } from "@clockwise/icons";
import { SchedulingPriorityEnum } from "@clockwise/schema/v2";
import { animated, useTransition } from "@react-spring/web";
import { compact } from "lodash";
import React from "react";
import { appPaths } from "../../../constants/route.constants";
import { TrackingEvents, useTracking } from "../../../util/analytics.util";
import { getFullName } from "../../../util/profile.util";
import { AttendeeAvatar } from "../../AttendeeAvatar";
import { LegacyTooltip } from "../../tooltip";
import {
  MeetingType,
  MeetingTypeOption,
  MeetingTypePicker,
} from "../meeting-type-picker/MeetingTypePicker";

export type Person = {
  memberId: string;
  email: string;
  name: {
    familyName: string | null;
    givenName: string | null;
  };
  externalImageUrl: string | null;
  isRequired: boolean;
  organizer: boolean;
  schedulingPriority: SchedulingPriorityEnum | null;
  roundRobinEnabled: boolean;
};

type Props = {
  people: Person[];
  onRemove: (email: string) => void;
  onChangeOptionality: (email: string, newOptionality: boolean) => void;
  onChangeMeetingType: (meetingType: MeetingType) => void;
  meetingType: MeetingType;
  onChangePriority: (email: string, priority: SchedulingPriorityEnum) => void;
  canUserMakeGroupLinks: boolean;
};

type PersonRowProps = {
  person: Person;
  onRemove: (email: string) => void;
  onChangeOptionality: (email: string, newOptionality: boolean) => void;
  showActions: boolean;
  onChangePriority: (email: string, priority: SchedulingPriorityEnum) => void;
  isRoundRobinEnabled: boolean;
};

const enumToStringMap: { [k in SchedulingPriorityEnum]: string | null } = {
  DoNotSchedule: "Do not schedule",
  Low: "Low",
  Medium: "Medium",
  High: "High",
};

const PersonRow = ({
  person,
  onRemove,
  onChangeOptionality,
  showActions,
  onChangePriority,
  isRoundRobinEnabled,
}: PersonRowProps) => {
  const trackEvent = useTracking();

  const { email, isRequired, organizer, externalImageUrl } = person;
  const fullName = getFullName(person.name);

  const personHasRoundRobinEnabled = person.roundRobinEnabled;

  return (
    <div className="cw-flex cw-flex-row cw-gap-4 cw-items-center cw-justify-start">
      <div className="cw-flex-grow-0">
        <AttendeeAvatar size="xlarge" profile={{ ...person.name, externalImageUrl }} />
      </div>
      <div className="cw-flex cw-flex-col cw-flex-grow">
        <div className="cw-body-lg cw-leading-snug cw-break-all xxs:cw-break-normal">
          {fullName}
        </div>
        <div className="cw-body-base cw-text-muted cw-leading-snug cw-break-all cw-flex cw-flex-col xxs:cw-flex-row cw-gap-2 cw-flex-wrap">
          {organizer && (
            <div>
              Organizer
              <span className="cw-hidden xxs:cw-inline"> {email.length < 20 ? "•" : " "} </span>
            </div>
          )}
          {email}
        </div>
      </div>
      {showActions && (
        <div>
          <div className="cw-flex cw-flex-row cw-gap-1">
            {!personHasRoundRobinEnabled && isRoundRobinEnabled && (
              <div className="cw-mr-1">
                <Button
                  size="xsmall"
                  onClick={() => {
                    window.open(appPaths.plansAndBilling);
                    trackEvent(TrackingEvents.LINKS.ROUND_ROBIN.UPGRADE_BUTTON_CLICKED);
                  }}
                  sentiment="positive"
                  variant="solid"
                >
                  Upgrade
                </Button>
              </div>
            )}
            {isRoundRobinEnabled ? (
              <LegacyTooltip
                placement="right"
                styles={{ left: "10px" }}
                body={
                  !personHasRoundRobinEnabled
                    ? "This user is not on a plan that has the Round Robin feature enabled. Please contact your admin or add this user to a Business or Enterprise plan."
                    : ""
                }
                showArrow
              >
                <div>
                  <Select
                    size="small"
                    variant="default"
                    disabled={!personHasRoundRobinEnabled}
                    value={person.schedulingPriority || SchedulingPriorityEnum.Medium}
                    onChange={(value) => {
                      onChangePriority(email, value);
                      trackEvent(TrackingEvents.LINKS.ROUND_ROBIN.PRIORITY_CHANGED, {
                        value,
                      });
                    }}
                    hideIcon
                  >
                    <SelectOptionsGroup
                      label={
                        <div className="cw-flex cw-items-center">
                          <div>User priority when scheduling</div>
                          <div>
                            <LegacyTooltip
                              placement="right"
                              styles={{ left: "20px" }}
                              body="Priority can be high, medium, or low. When multiple people are available, priority breaks the tie. If priorities are the same, the least recently scheduled person is booked."
                              showArrow
                            >
                              <HelpFilled fontSize="inherit" className="cw-ml-1 cw-text-gray-400" />
                            </LegacyTooltip>
                          </div>
                        </div>
                      }
                    >
                      <SelectOption
                        key={SchedulingPriorityEnum.High}
                        value={SchedulingPriorityEnum.High}
                        icon={FlagFilled}
                        iconProps={{ className: "cw-text-destructive-muted" }}
                      >
                        {enumToStringMap[SchedulingPriorityEnum.High]}
                      </SelectOption>
                      <SelectOption
                        key={SchedulingPriorityEnum.Medium}
                        value={SchedulingPriorityEnum.Medium}
                        icon={FlagFilled}
                        iconProps={{ className: "cw-text-warning-muted" }}
                      >
                        {enumToStringMap[SchedulingPriorityEnum.Medium]}
                      </SelectOption>
                      <SelectOption
                        key={SchedulingPriorityEnum.Low}
                        value={SchedulingPriorityEnum.Low}
                        icon={Flag}
                      >
                        {enumToStringMap[SchedulingPriorityEnum.Low]}
                      </SelectOption>
                    </SelectOptionsGroup>
                    <SelectOptionsGroup>
                      <SelectOption
                        key={SchedulingPriorityEnum.DoNotSchedule}
                        value={SchedulingPriorityEnum.DoNotSchedule}
                        icon={NotInterested}
                      >
                        {enumToStringMap[SchedulingPriorityEnum.DoNotSchedule]}
                      </SelectOption>
                    </SelectOptionsGroup>
                  </Select>
                </div>
              </LegacyTooltip>
            ) : (
              <LegacyTooltip
                placement="right"
                styles={{ left: "10px" }}
                body={isRequired ? "Mark optional" : "Mark required"}
                showArrow
              >
                <Button
                  variant="text"
                  size="small"
                  startIcon={isRequired ? Person : PersonOutline}
                  onClick={() => onChangeOptionality(email, !isRequired)}
                />
              </LegacyTooltip>
            )}
            {!organizer ? (
              <LegacyTooltip placement="right" styles={{ left: "10px" }} body="Remove" showArrow>
                <Button
                  variant="text"
                  size="small"
                  startIcon={Close}
                  cw-id="remove-attendee"
                  onClick={() => onRemove(email)}
                />
              </LegacyTooltip>
            ) : (
              <div className="cw-w-8"></div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export function SetupLinkPersonPicker({
  people: unsortedPeople,
  onChangeOptionality,
  onRemove,
  meetingType,
  onChangeMeetingType,
  onChangePriority,
  canUserMakeGroupLinks,
}: Props) {
  const organizer = unsortedPeople.find((person) => person.organizer);
  const attendees = unsortedPeople.filter((person) => !person.organizer);
  const people = compact([organizer, ...attendees]);
  const organizerHasRoundRobinEnabled = organizer && organizer.roundRobinEnabled;
  const personRowTransition = useTransition(people, {
    from: { opacity: 0, transform: "translateY(-10px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    trail: 100,
    keys: (person) => person.email,
  });

  const atLeastOneRequired = people.some((person) => person.isRequired);
  const isRoundRobinEnabled = meetingType === MeetingType.RoundRobin;

  const meetingTypePickerOptions = [
    meetingType === MeetingType.SingleTeammate || // show option if meetingType is selected
    people.length <= 1 || // show option if only one person is selected
    !canUserMakeGroupLinks // show option if user cannot use group links
      ? {
          type: MeetingType.SingleTeammate,
          name: "Single Teammate",
          icon: Person,
          available: true,
        }
      : null,
    meetingType === MeetingType.Group || // show option if meetingType is selected
    people.length > 1 || // show option if more than one person is selected
    !canUserMakeGroupLinks // show option if user cannot use group links (this option will be unavailable)
      ? {
          type: MeetingType.Group,
          name: "Group meeting",
          icon: Group,
          available: canUserMakeGroupLinks,
        }
      : null,
    {
      type: MeetingType.RoundRobin,
      name: "Round robin",
      icon: Diversity2,
      available: !!organizerHasRoundRobinEnabled,
    },
  ].filter((option) => option !== null) as MeetingTypeOption[];

  return (
    <div>
      <div className="cw-mb-6 cw-mt-0">
        <div className="cw-flex cw-justify-between">
          <h2 className="cw-heading-xl">Teammates</h2>
          <div className="cw-flex cw-items-center cw-gap-1">
            <span className="cw-text-13 cw-font-bold">Meeting type:</span>
            <MeetingTypePicker
              selected={meetingType}
              onChange={onChangeMeetingType}
              options={meetingTypePickerOptions}
            />
          </div>
        </div>

        {!atLeastOneRequired && (
          <div className="cw-body-base cw-text-destructive cw-mt-3 cw-max-w-full">
            At least one attendee must be required
          </div>
        )}
      </div>
      <div className="cw-flex cw-flex-col cw-gap-3">
        {personRowTransition((style, person) => {
          return (
            <animated.div style={style}>
              <PersonRow
                key={person.email}
                person={person}
                onChangeOptionality={onChangeOptionality}
                onRemove={onRemove}
                showActions={people.length > 1}
                onChangePriority={onChangePriority}
                isRoundRobinEnabled={isRoundRobinEnabled}
              />
            </animated.div>
          );
        })}
      </div>
    </div>
  );
}
