import { IWorkingHours } from "#webapp/src/__schema__";
import { meetingAndWorkingHoursAreDifferent } from "#webapp/src/util/working-hours.util";
import { useMutation, useQuery } from "@apollo/client";
import {
  setCurrentTimeZone,
  setRenderTimeZone,
} from "@clockwise/web-commons/src/util/time-zone.util";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { throttle } from "throttle-debounce";
import { logger } from "../../../util/logger.util";
import {
  UpdateUserWorkingMeetingHoursDocument,
  UserWorkingMeetingHoursDocument,
} from "../graphql/__generated__/UserWorkingMeetingHours.generated";

const notifySuccess = (hoursType: string) => toast.success(`Your ${hoursType} hours were updated`);
const notifyError = (error: Error, hoursType: string) => {
  toast.error(`Failed to update ${hoursType} hours`);
  logger.error(`Failed to update ${hoursType} hours`, error);
};

export const useWorkingHours = () => {
  const { data, loading: workingHoursLoading } = useQuery(UserWorkingMeetingHoursDocument, {
    // Do NOT use the cache!
    // If we read from the cache, we may conflate generic and week-specific meetingHours because their graphql ids are the same
    // Combined with useEffect, this can lead to week-specific meetingHours overriding the generic setting
    // See: hooks/useAvailablities.tsx
    fetchPolicy: "network-only",
  });

  const [workingHours, setWorkingHours] = useState<IWorkingHours | null>(null);
  const [meetingHours, setMeetingHours] = useState<IWorkingHours | null>(null);
  const [showMeetingHours, setShowMeetingHours] = useState(false);

  const [updateWorkingHours] = useMutation(UpdateUserWorkingMeetingHoursDocument);

  const throttledUpdateWorkingHours = useRef(throttle(250, updateWorkingHours)).current;

  useEffect(() => {
    if (data) {
      const workingHours =
        data.viewer?.user?.orgs?.edges?.[0]?.node?.userWorkingHours?.workingHours;
      const meetingHours =
        data.viewer?.user?.orgs?.edges?.[0]?.node?.userMeetingHours?.workingHours;
      if (workingHours) {
        setWorkingHours(workingHours as IWorkingHours); // This is not ideal, TODO: https://linear.app/getclockwise/issue/GM-1603/once-onboardingnux-is-deleted-clean-up-types-for-splitworkinghours-and
      }
      if (meetingHours) {
        setMeetingHours(meetingHours as IWorkingHours); // This is not ideal, TODO: https://linear.app/getclockwise/issue/GM-1603/once-onboardingnux-is-deleted-clean-up-types-for-splitworkinghours-and
      }

      if (!!workingHours && !!meetingHours) {
        const shouldShowMeetingHoursOnLoad = meetingAndWorkingHoursAreDifferent(
          workingHours,
          meetingHours,
        );
        setShowMeetingHours(shouldShowMeetingHoursOnLoad);
      }
    }
  }, [data]);

  const onUpdateWorkingHours = (updatedWorkingHours: IWorkingHours) => {
    if (!showMeetingHours) {
      // Update meeting hours in UI if they are the same
      setMeetingHours({ ...updatedWorkingHours });
    }
    setWorkingHours({ ...updatedWorkingHours });
    void throttledUpdateWorkingHours({
      variables: {
        input: {
          workingHours: updatedWorkingHours,
          saveMeetingAndWorkingHours: !showMeetingHours,
        },
      },
      onCompleted: () => {
        notifySuccess("working");
      },
      onError: (error) => notifyError(error, "working"),
      refetchQueries: ["UserWorkingMeetingHours"],
    });
  };

  const onUpdateTimeZone = (timeZone: string) => {
    if (workingHours) {
      const newWorkingHoursWithTimeZone = { ...workingHours, timeZone: timeZone };
      setWorkingHours({ ...newWorkingHoursWithTimeZone });
      void throttledUpdateWorkingHours({
        variables: {
          input: {
            workingHours: newWorkingHoursWithTimeZone,
            saveMeetingAndWorkingHours: !showMeetingHours,
          },
        },
        onCompleted: () => {
          notifySuccess("working");
          setRenderTimeZone(newWorkingHoursWithTimeZone.timeZone);
          setCurrentTimeZone(newWorkingHoursWithTimeZone.timeZone);
        },
        onError: (error) => notifyError(error, "working"),
        refetchQueries: ["UserWorkingMeetingHours"],
      });
    }
  };

  const onUpdateMeetingHours = (updatedMeetingHours: IWorkingHours) => {
    setMeetingHours({ ...updatedMeetingHours });
    void throttledUpdateWorkingHours({
      variables: {
        input: {
          workingHours: updatedMeetingHours,
          saveMeetingAndWorkingHours: !showMeetingHours,
        },
      },
      onCompleted: () => notifySuccess("meeting"),
      onError: (error) => notifyError(error, "meeting"),
      refetchQueries: ["UserWorkingMeetingHours"],
    });
  };

  const onCheckShowMeetingHours = (checked: boolean) => {
    setShowMeetingHours(!checked);
    if (checked && workingHours) {
      setMeetingHours({ ...workingHours });
      void throttledUpdateWorkingHours({
        variables: {
          input: {
            workingHours: workingHours,
            saveMeetingAndWorkingHours: checked,
          },
        },
        onCompleted: () => notifySuccess("meeting"),
        onError: (error) => notifyError(error, "meeting"),
        refetchQueries: ["UserWorkingMeetingHours"],
      });
    }
  };

  const loading = !workingHours || workingHoursLoading;

  return {
    loading,
    workingHours,
    onUpdateWorkingHours,
    onUpdateTimeZone,
    meetingHours,
    onUpdateMeetingHours,
    showMeetingHours,
    onCheckShowMeetingHours,
  };
};
