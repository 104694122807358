import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gcalUrl, paths } from "../constants/site.constant";
import { isAuthed as getIsAuthed } from "../util/auth.util";
import { windowLocation } from "../util/location.util";

export function InstallExtensionPage() {
  const isAuthed = getIsAuthed();
  const track = useTracking();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthed) {
      track(
        TrackingEvents.CHROME_EXTENSION.EXTENSION_INSTALL_GCAL_REDIRECT,
        undefined,
        undefined,
        true,
      );
      void windowLocation.assign("calendar", `${gcalUrl}?cwinstall`);
    } else {
      track(
        TrackingEvents.CHROME_EXTENSION.EXTENSION_INSTALL_WEBAPP_REDIRECT,
        undefined,
        undefined,
        true,
      );
      navigate(`${paths.welcome}?cwinstall`, { replace: true });
    }
  }, [navigate, track, isAuthed]);

  return (
    <div className="cw-flex cw-flex-row cw-justify-center cw-body-base">
      <div className="cw-p-2">
        Return to <a href={appPaths.landing}>Clockwise</a>.
      </div>
    </div>
  );
}
