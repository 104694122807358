import { graphql } from "react-relay";

export const sudoModifyUserFragments = {
  viewer: graphql`
    fragment SudoModifyUser_viewer on Viewer {
      id
      sudo {
        modifyUser {
          ecosystem
          user {
            id
            givenName
            familyName
            emails
            status
            externalImageUrl
            flowStates(first: 1000000) {
              edges {
                node {
                  id
                  flowKey
                  current {
                    id
                    state
                    percentComplete
                    errorMessage
                  }
                  pending {
                    id
                    state
                    percentComplete
                    errorMessage
                  }
                }
              }
            }
            orgs(first: 1000000) {
              edges {
                node {
                  id
                  name
                  myBillingGroups {
                    primaryBillingGroup {
                      id
                    }

                    allGroups {
                      id
                      name
                    }
                  }
                  flowStates(first: 1000000) {
                    edges {
                      node {
                        id
                        flowKey
                        current {
                          id
                          state
                          percentComplete
                          errorMessage
                        }
                        pending {
                          id
                          state
                          percentComplete
                          errorMessage
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          userTeams {
            __typename
            ... on TeamList {
              list {
                teamId
                teamName
              }
            }
          }
          featureStatesJson
        }
      }
    }
  `,
};

export const sudoModifyUserQuery = graphql`
  query SudoModifyUserQuery {
    viewer {
      ...SudoModifyUser_viewer
    }
  }
`;
