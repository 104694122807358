import { DateTime, Duration } from "luxon";
import dateComparator from "../../../../../util/comparator.date";
import { IIntegerDataPoint } from "../../overview.types";

const groupDataInDateRangeByStep = (
  data: IIntegerDataPoint[],
  date1: Date,
  date2: Date,
  step: "day" | "week" | "month",
  accumulate = true,
) => {
  const typeName = data[0]?.__typename;
  const dateFormat = "yyyy-MM-dd";
  const map = new Map<string, number>();
  const stepDuration = Duration.fromDurationLike({ [step]: 1 });

  if (!date1 || !date2) {
    throw new Error("Invalid dates");
  }

  const sortedDates = [date1, date2].sort(dateComparator);

  const maxLux = DateTime.fromJSDate(sortedDates[1]);
  const minLux = DateTime.fromJSDate(sortedDates[0]);
  const stepOffset = minLux.diff(minLux.startOf(step));
  let currentLux = DateTime.fromJSDate(minLux.toJSDate());

  if (step === "month") {
    // start at first full month of data for consistency
    currentLux = currentLux.startOf("month");
  }

  // init map with 0s for every step in sorted order
  while (currentLux < maxLux) {
    map.set(currentLux.toFormat(dateFormat), 0);
    currentLux = currentLux.plus(stepDuration);
  }

  // accumulate values per step from data; if in date range
  data.forEach((datum: IIntegerDataPoint) => {
    let groupLux: DateTime;

    if (step === "day" || step === "week") {
      groupLux = getGroupLuxForDayWeek(datum.label, step, stepOffset, dateFormat);
    } else {
      groupLux = getGroupLuxForMonth(datum.label, dateFormat);
    }
    const groupDateString = groupLux.toFormat(dateFormat);
    const currentValue = map.get(groupDateString);
    if (currentValue === undefined) return;
    const newValue = accumulate ? currentValue + datum.value : datum.value;

    map.set(groupDateString, newValue);
  });

  // return
  const result: IIntegerDataPoint[] = [];
  map.forEach((value, label) => {
    result.push({ __typename: typeName, label, value });
  });

  return result;
};

const getGroupLuxForMonth = (label: string, dateFormat: string) => {
  return DateTime.fromFormat(label, dateFormat).startOf("month");
};

const getGroupLuxForDayWeek = (
  label: string,
  step: "day" | "week",
  stepOffset: Duration,
  dateFormat: string,
) => {
  return DateTime.fromFormat(label, dateFormat)
    .minus({ days: Math.ceil(stepOffset.as("days")) })
    .startOf(step)
    .plus({ days: Math.ceil(stepOffset.as("days")) });
};

export default groupDataInDateRangeByStep;
