import { useMutation } from "@apollo/client";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { FOCUS_TIME_IDEAL_MINUTES_DEFAULT } from "../../../smart-hold-sync";
import { FunnelType } from "../../WebOnboarding.util";
import { ButtonGroup, Header, SlideLayout, Text } from "../../shared-components";
import { setError } from "../../utils/setError";
import { FocusTimeSlideRadioGroup } from "./FocusTimeSlideRadioGroup";
import { UpdateFocusTimeSyncAndThresholdNewDocument } from "./__generated__/FocusTimeSlide.generated";
import offUrl from "./assets/off.svg";
import onUrl from "./assets/on.svg";

type FocusTimeSlideProps = {
  showSecondaryButton: boolean;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton: () => void;
  loading: boolean;
  initialFocusTimeSyncEnabled: boolean | undefined;
  initialFocusTimeMinutes: number | undefined;
  orgId: string;
  backFromPreviousSlide: boolean;
  funnelType: FunnelType;
};

export const FocusTimeSlide = ({
  showSecondaryButton,
  onClickPrimaryButton,
  onClickSecondaryButton,
  loading,
  initialFocusTimeSyncEnabled,
  initialFocusTimeMinutes,
  orgId,
  backFromPreviousSlide,
  funnelType,
}: FocusTimeSlideProps) => {
  const [isFocusTimeEnabled, setIsFocusTimeEnabled] = useState(true);
  const [idealMinutesPerWeek, setIdealMinutesPerWeek] = useState(FOCUS_TIME_IDEAL_MINUTES_DEFAULT);
  const track = useTracking();

  useEffect(() => {
    if (initialFocusTimeSyncEnabled !== undefined) {
      if (!backFromPreviousSlide) {
        // User's first time on this slide, set FT to true
        setIsFocusTimeEnabled(true);
      } else {
        setIsFocusTimeEnabled(initialFocusTimeSyncEnabled);
      }
    }
    if (initialFocusTimeMinutes !== undefined) {
      setIdealMinutesPerWeek(initialFocusTimeMinutes);
    }
  }, [initialFocusTimeMinutes, initialFocusTimeSyncEnabled, backFromPreviousSlide]);

  const [updateSettings, { loading: updating }] = useMutation(
    UpdateFocusTimeSyncAndThresholdNewDocument,
    {
      onError: (error) => {
        setError({
          error: error,
          message: "Failed to update Focus Time settings",
          showUserMessage: true,
        });
      },
      onCompleted: () => {
        track(TrackingEvents.WEB_ONBOARDING.FOCUSTIME_STEP_ENABLED, {
          isFocusTimeEnabled,
          idealMinutesPerWeek,
          funnelType,
        });
      },
    },
  );

  const onSaveAndMoveForward = async () => {
    await updateSettings({
      variables: {
        orgId,
        sync: isFocusTimeEnabled,
        idealMinutesPerWeek,
      },
    });
    onClickPrimaryButton();
  };

  const onChangeFocusTimeSetting = (value: "none" | "some") => {
    setIsFocusTimeEnabled(value === "some");
  };

  const onChangeThreshold = (value: number) => {
    track(TrackingEvents.SETTINGS.UPDATE_SMART_HOLD_IDEAL_MINUTES_PER_WEEK, {
      isOnboarding: true, // This component is only used in onboarding
      idealMinutesPerWeek: value,
      type: "FocusTime",
      funnelType,
    });
    setIdealMinutesPerWeek(value);
  };

  return (
    <SlideLayout>
      <Header>Protect your focus time</Header>
      <Text>
        Clockwise can hold time on your calendar for your most important work, and automatically
        shift it as your schedule changes.
      </Text>
      <div className="md:cw-w-[500px] cw-w-full cw-relative">
        <FocusTimeSlideRadioGroup
          selected={isFocusTimeEnabled ? "some" : "none"}
          threshold={idealMinutesPerWeek}
          disabled={loading || updating}
          onChange={onChangeFocusTimeSetting}
          onChangeThreshold={onChangeThreshold}
        />
        <div className="cw-relative cw-w-full cw-h-[205px] ">
          <img
            src={onUrl}
            className={classNames(
              "cw-absolute cw-top-0 cw-w-full cw-transition-all cw-duration-500",
              {
                "cw-opacity-0": !isFocusTimeEnabled,
                "cw-opacity-100": isFocusTimeEnabled,
              },
            )}
          />
          <img
            src={offUrl}
            className={classNames(
              "cw-absolute cw-top-0 cw-w-full cw-transition-all cw-duration-500",
              {
                "cw-opacity-0": isFocusTimeEnabled,
                "cw-opacity-100": !isFocusTimeEnabled,
              },
            )}
          />
        </div>
      </div>
      <ButtonGroup
        shouldShowSecondary={showSecondaryButton}
        primaryLabel={"Confirm"}
        onClickPrimary={onSaveAndMoveForward}
        onClickSecondary={onClickSecondaryButton}
        disabled={loading || updating}
      />
    </SlideLayout>
  );
};
