import { GroupAdd, LockOutlined } from "@clockwise/design-system/icons";
import React, { useEffect, useState } from "react";

import { PersonSelector } from "@clockwise/web-commons/src/ui/person-selector";

import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
import { compareBooleans } from "#webapp/src/util/sort.util";
import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Button, Modal, Select, SelectOption } from "@clockwise/design-system";
import { useDebounceValue } from "usehooks-ts";
import { BulkInviteInfoDocument } from "./__generated__/BulkInviteInfo.generated";
import {
  canInviteToGroup,
  normalizePersons,
  QueryPerson,
  sendInvites,
  sortPersons,
} from "./BulkInvite.util";

// Copied from web-commons/ui/person-selector because types cannot be imported between packages
interface Person {
  email: string;
  name: { givenName: string; familyName: string };
  externalImageUrl?: string;
  isSuggested: boolean;
}

interface PersonWithId extends Person {
  id: string;
}

export interface IProps {
  open: boolean;
  onClose: () => void;
  onCloseAfterInvite: () => void;
}

const FREE_PLAN = "free";
export const InvitePanel = ({ open, onClose, onCloseAfterInvite }: IProps) => {
  const [rawQuery, setRawQuery] = useState("");
  const [query, setQuery] = useDebounceValue(rawQuery, 200);
  const environment = getCurrentEnvironment();

  useEffect(() => {
    // Set the debounced value for fetching purposes.
    setQuery(rawQuery);
  }, [rawQuery, setQuery]);

  const { data } = useQuery(BulkInviteInfoDocument, {
    variables: {
      search: query,
    },
  });

  const org = getValue(data?.viewer.user?.orgs.edges?.[0]?.node);
  const persons = getValue(org?.orgPersonListPaginatedErrorable)?.persons;

  const billingGroupOptions = React.useMemo(
    () =>
      (getValue(org?.billingGroups)?.list ?? [])
        .map((group) => {
          return { id: group.id, name: group.name, needsAdminApproval: !canInviteToGroup(group) };
        })
        .concat({
          id: FREE_PLAN,
          name: "Free plan",
          needsAdminApproval: false,
        })
        .sort((a, b) => compareBooleans(a.needsAdminApproval, b.needsAdminApproval)),
    [org?.billingGroups],
  );

  const [billingGroupId, setBillingGroupId] = React.useState(
    () =>
      billingGroupOptions.find(({ needsAdminApproval }) => !needsAdminApproval)?.id ?? FREE_PLAN,
  );

  const selectedBillingGroup = billingGroupOptions.find(({ id }) => id === billingGroupId);

  const [selected, setSelected] = React.useState<PersonWithId[]>([]);

  const suggested = useSuggestions({
    persons: persons ?? [],
    selections: selected.map(({ email }) => email),
  });

  return (
    <Modal opened={open} onClose={onClose} title="Invite members" size="lg">
      <div className="cw-min-h-[500px]">
        <div className="cw-body-base cw-my-3">
          {selectedBillingGroup?.needsAdminApproval
            ? "New users will be invited as free users immediately. Admins will receive a request to accept new additions to the subscription."
            : "After you invite new members, they will receive an email invitation with signup instructions. "}
        </div>
        <div className="cw-mb-3">
          <PersonSelector<PersonWithId>
            dataAutoFocus={open}
            hasChips
            maxDropdownHeight="300px"
            onRemove={(person) =>
              setSelected((prev) => prev.filter((p) => p.email !== person.email))
            }
            onSearch={setRawQuery}
            onSelect={(person) =>
              setSelected((prev) => [...prev.filter((p) => p.email !== person.email), person])
            }
            people={suggested}
            placeholder="Search by name or email"
            selectedPeople={selected}
          />
        </div>
      </div>
      <div className="cw-pt-4 cw-flex cw-items-center cw-justify-end cw-gap-2">
        <Select
          value={selectedBillingGroup?.id}
          onChange={(bgId) => bgId && setBillingGroupId(bgId)}
        >
          {billingGroupOptions.map(({ id, name, needsAdminApproval }) => (
            <SelectOption icon={GroupAdd} key={id} value={id}>
              <div>
                Add to {name}
                {needsAdminApproval && (
                  <div className="cw-text-warning cw-body-base  cw-flex cw-items-center cw-mt-2">
                    <LockOutlined fontSize="small" className="cw-mr-2" />
                    <span>Admin approval needed</span>
                  </div>
                )}
              </div>
            </SelectOption>
          ))}
        </Select>
        <Button
          disabled={selected.length === 0}
          sentiment="positive"
          variant="text"
          size="large"
          onClick={() => {
            if (!org?.id) return;
            sendInvites(selected, {
              relayEnv: environment,
              orgId: org.id,
              billingGroupId: billingGroupId !== FREE_PLAN ? billingGroupId : null,
            });
            onCloseAfterInvite();
          }}
        >
          {selectedBillingGroup?.needsAdminApproval ? "Send invite request to admin" : "Invite"}
        </Button>
      </div>
    </Modal>
  );
};

interface IUseSuggestions {
  persons: QueryPerson[];
  selections: string[];
  limit?: number;
}
const useSuggestions = ({ persons, selections, limit = 10 }: IUseSuggestions): PersonWithId[] => {
  const normalizedPersons = React.useMemo(() => normalizePersons(persons ?? []), [persons]);

  const suggestions = React.useMemo(
    () =>
      sortPersons(normalizedPersons)
        .map((id) => normalizedPersons[id])
        .map((p) => {
          return {
            id: p.id,
            name: { givenName: p.givenName, familyName: p.familyName },
            email: p.email,
            externalImageUrl: p.externalImageUrl,
            isSuggested: p.isSuggested,
          };
        }),
    [normalizedPersons],
  );

  // Remove selected values from the suggestion and enforce a max number of suggestions
  return suggestions.filter(({ email }) => !selections.includes(email)).slice(0, limit);
};
