import { useUserProfile } from "#webapp/src/components/hooks/useUserProfile";
import { usePlannerContext } from "#webapp/src/components/web-app-calendar/Context";
import { BatchSummary } from "#webapp/src/components/web-app-calendar/notifiation-event/BatchSummary";
import { eventToast } from "#webapp/src/components/web-app-calendar/notifiation-event/EventToast";
import { useDialog } from "#webapp/src/components/web-app-calendar/notification/DialogContext";
import { Button } from "@clockwise/design-system";
import { Check } from "@clockwise/icons";
import { DiffActionTypeEnum, OperationEnum, SchedulingProposalStateEnum } from "@clockwise/schema";
import {
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { Interval } from "luxon";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { clearAllSelected as clearAllSelectedCalendarsInMultiCalendar } from "../../../../../state/actions/multi-calendar.actions";
import {
  setCancelProposalUIInFlight,
  setConfirmProposalUIInFlight,
} from "../../../../../state/actions/ui-flow-on-screen.actions";
import { useUpdateDismissedConflictClusters } from "../../../../chat-plus-calendar/util/DismissedConflictClusterContext";
import usePlannerMetaData from "../../../../web-app-calendar/hooks/usePlannerMetaData";
import { useResetConversation } from "../../../hooks/useResetConversation";
import { proposalIsScheduleByMoving } from "../../../util/proposalIsScheduleByMoving";
import { FeedbackThumbs } from "../../../web/FeedbackThumbs";
import { useSetAIError } from "../../hooks/AIErrorContext";
import { useAIMessageContext } from "../../hooks/AIMessageContext";
import { useConfirmChatProposal } from "../../hooks/useConfirmChatProposal";
import { getHasMultipleDiffs } from "../../utils/getHasMultipleDiffs";
import { textSplitByNewLine } from "../../utils/textSplitByNewLine";
import { GQLAssistantMessageProposalResponse, GQLSchedulingOptions } from "../../utils/types";
import { ProposalAvailability } from "../availability/ProposalAvailability";
import { ConsequencesBlock } from "../diffs/ConsequencesBlock";
import { ProposalDiffBlocks } from "../diffs/ProposalDiffBlocks";
import { ProposalResolvedRow } from "./ProposalResolvedRow";
import { ProposalThread } from "./proposal-thread/ProposalThread";
import { getFeedbackUrls } from "./utils/feedbackUrl";

export interface ProposalResponseProps {
  message: GQLAssistantMessageProposalResponse;
  currentLLMUsed: string;
}

export const ProposalResponse = ({ message, currentLLMUsed }: ProposalResponseProps) => {
  const { primaryCalendarId } = usePlannerMetaData();
  const track = useTracking();
  const setError = useSetAIError();
  const dispatch = useDispatch();
  const { refetchEvents } = usePlannerContext();
  const [openDialog, closeDialog] = useDialog();
  const [isUpdating, setIsUpdating] = React.useState(false); // DevNote: this is a crutch to prevent this view from briefly re-enabling between reset and the parent re-render causing a flicker

  const { processingMessage, setConfirmingMessage } = useAIMessageContext();
  const setDismissedConflicts = useUpdateDismissedConflictClusters();

  const updateActiveEvent = useUpdateActiveEvent();
  const { userProfile } = useUserProfile();

  const [onResetConversation, { loading: resetLoading }] = useResetConversation({
    refetchQueries: ["ChatHistoryQuery", "MentionsSearchEvents"],
    onError: (error) => {
      setIsUpdating(false);
      setError({ error: error, message: "Failed to cancel proposal", showUserMessage: true });
    },
    onCompleted: () => {
      if (isAConflictProposal) {
        setDismissedConflicts([]);
      }
      void refetchEvents?.();
    },
  });

  const { proposal, conversationId, id: msgId } = message;
  const { state } = proposal;

  const isBulkSchedulingProposal = getHasMultipleDiffs(proposal.diffBlocks);

  const [onConfirmChatProposal, { loading: confirmLoading }] = useConfirmChatProposal({
    clearHistory: true,
    onError: (error) => {
      setError({ error: error, message: "Failed to confirm proposal", showUserMessage: true });
      setIsUpdating(false);
      setConfirmingMessage(false);
    },
    onCompleted: (data) => {
      setConfirmingMessage(false);
      void refetchEvents?.();
      const events = data?.confirmChatProposal?.updatedEvents;
      triggerNotification(events);
    },
    refetchQueries: ["ChatHistoryQuery", "MentionsSearchEvents"],
  });

  const triggerNotification = (
    events?: {
      externalEventId: string;
      title: string;
      success: boolean;
      operation: OperationEnum;
    }[],
  ) => {
    if (!events || events.length === 0) {
      toast.success("Updated calendar", {
        position: "bottom-right",
      });
      return;
    }

    const [feedbackUrlPositive, feedbackUrlNegative] = getFeedbackUrls(
      conversationId,
      proposal.proposalId,
      userProfile,
      currentLLMUsed,
    );

    const handleFeedback = (isPositive: boolean) => {
      const trackingEvent = isPositive
        ? TrackingEvents.CHAT.PROPOSAL.FEEDBACK_POSITIVE
        : TrackingEvents.CHAT.PROPOSAL.FEEDBACK_NEGATIVE;
      track(trackingEvent, {
        msgId: msgId,
        conversationId: conversationId,
        proposalId: proposal.proposalId,
        llmName: currentLLMUsed,
      });
    };

    const handleViewEvent = (externalEventId: string) => {
      const { primaryCalendar } = userProfile;
      if (!primaryCalendar) {
        return;
      }
      updateActiveEvent({
        calendarId: primaryCalendar,
        externalEventId,
        type: EventType.Event,
      });
    };

    const isLargeBatch = events.length > 4;

    const isMixedSuccess =
      events.some((event) => event.success === false) &&
      events.some((event) => event.success === true);

    if (isLargeBatch || isMixedSuccess) {
      openDialog(<BatchSummary events={events} onViewEvent={handleViewEvent} />, {
        actions: (
          <Button sentiment="positive" onClick={closeDialog} size="mini">
            Close
          </Button>
        ),
        title: "Calendar updates",
        feedbackUrlNegative,
        feedbackUrlPositive,
        onFeedback: handleFeedback,
      });
    } else {
      events.forEach(({ externalEventId, title, operation, success }) => {
        const isViewable = !(
          (operation === OperationEnum.CANCEL && success) ||
          (operation === OperationEnum.ADD && !success)
        );

        if (success) {
          eventToast.success({
            operation,
            title,
            feedbackUrlNegative,
            feedbackUrlPositive,
            onFeedback: handleFeedback,
            onViewEvent: isViewable ? () => handleViewEvent(externalEventId) : undefined,
          });
        } else {
          eventToast.error({
            operation,
            title,
            feedbackUrlNegative,
            feedbackUrlPositive,
            onFeedback: handleFeedback,
            onViewEvent: isViewable ? () => handleViewEvent(externalEventId) : undefined,
          });
        }
      });
    }
  };

  const handleConfirm = () => {
    const sessionId = new URLSearchParams(window.location.search).get("sessionId");
    track(TrackingEvents.CHAT.PROPOSAL.CONFIRM, {
      sessionId,
    });

    setConfirmingMessage(true);

    dispatch(setConfirmProposalUIInFlight());
    dispatch(clearAllSelectedCalendarsInMultiCalendar());
    void onConfirmChatProposal();
    setIsUpdating(true);
  };

  const handleCancel = () => {
    track(TrackingEvents.CHAT.PROPOSAL.CANCEL);
    dispatch(setCancelProposalUIInFlight());
    dispatch(clearAllSelectedCalendarsInMultiCalendar());
    void onResetConversation();
    setIsUpdating(true);
  };

  const resetDismissedConflictsAndConfirm = () => {
    const conflictClusterIds = proposal?.conflictClusters?.map((cluster) => cluster.id) || [];
    // Separate function so that the dismissed conflicts reset after the proposal fully cancels
    track(TrackingEvents.CONFLICT_RESOLUTION.FINISH_RESOLUTION, {
      conversationId: conversationId,
      clusterIds: conflictClusterIds,
    });
    dispatch(clearAllSelectedCalendarsInMultiCalendar());
    setConfirmingMessage(true);
    void onConfirmChatProposal();
    setIsUpdating(true);
  };

  const startTimeFromInterval = (interval: string | null) => {
    return interval ? Interval.fromISO(interval).start : null;
  };

  const selectedTimeFromOptions = (options: GQLSchedulingOptions | null) => {
    if (!options) {
      return null;
    }

    const optionsWithIndex =
      options.optionDetails.map((option, index) => ({ ...option, index })) || [];
    const selectedOption = optionsWithIndex.find(
      (option) => option.index === options.selectedIndex,
    );
    return startTimeFromInterval(selectedOption?.interval as string | null);
  };

  const isActiveOrIncomplete =
    state === SchedulingProposalStateEnum.ACTIVE ||
    state === SchedulingProposalStateEnum.INCOMPLETE;

  const loading = confirmLoading || resetLoading || processingMessage || isUpdating;
  const disableConfirm = state !== SchedulingProposalStateEnum.ACTIVE || loading;
  const disableCancel =
    state === SchedulingProposalStateEnum.CANCELLED ||
    state === SchedulingProposalStateEnum.COMPLETED ||
    loading;

  const isAConflictProposal = proposal?.conflictClusters?.length || proposal?.conflictReceipt;
  const firstDiff = proposal.diffBlocks[0]?.diffs[0] || null;
  const firstDiffTradeoffBlocks = firstDiff?.tradeoffBlocks || null;
  const firstDiffProposalAttendees = firstDiff?.attendees?.proposalAttendees || [];
  const firstDiffAttendees = firstDiffProposalAttendees.map((a) => a.attendeePerson);
  const viewerAttendee = firstDiffAttendees.find(
    (attendee) => attendee.primaryCalendar === primaryCalendarId,
  );
  const firstDiffAttendeesExceptViewer = firstDiffAttendees.filter(
    (attendee) => attendee !== viewerAttendee,
  );
  const viewerProfile = viewerAttendee?.profile || null;
  const emailDomainsSet = new Set(
    firstDiffAttendees.map((attendee) => attendee.primaryEmail.split("@")[1]),
  );
  const availabilityBaseEventDiffId = proposal.availability?.baseEventDiffId || null;
  const options = proposal.options.__typename === "SchedulingOptions" ? proposal.options : null;
  const addDiffTime = (firstDiff?.__typename === "AddDiffSummary" && firstDiff?.time) || null;
  const modifyDiffTime =
    (firstDiff?.__typename === "ModifyDiffSummary" && firstDiff?.updatedTime) || null;
  const selectedTime =
    selectedTimeFromOptions(options) || startTimeFromInterval(addDiffTime || modifyDiffTime);
  const isProposeNewTime = firstDiff?.action?.type === DiffActionTypeEnum.PROPOSE_NEW_TIME;
  const organizer = firstDiffProposalAttendees.find((a) => a.isOrganizer)?.attendeePerson || null;

  // TODO: Get this from the backend
  // TODO: Find an efficient way to get `omittedAttendees` value and use it in place for `isProposeNewTime`
  const canShareProposal =
    (isProposeNewTime || firstDiffAttendees.length > 1) &&
    availabilityBaseEventDiffId !== null &&
    emailDomainsSet.size === 1;

  useEffect(() => {
    // Handles a new proposal being sent which would cancel the previous proposal
    // Thus we'll clear the selected calendars for the new proposal
    if (proposal.state === SchedulingProposalStateEnum.CANCELLED) {
      dispatch(clearAllSelectedCalendarsInMultiCalendar());
    }
  }, [proposal]);

  useEffect(() => {
    // Handles any case where the proposal closes (switch tabs) or
    // the `Clear` button being clicked or
    // a new proosal is initiated into the chat
    return () => {
      dispatch(clearAllSelectedCalendarsInMultiCalendar());
    };
  }, []);

  if (!isActiveOrIncomplete) {
    return (
      <div className="cw-flex-col cw-items-start cw-body-sm">
        <ProposalResolvedRow state={state} />
        <div className="cw-mt-3">
          <FeedbackThumbs
            msgId={msgId}
            conversationId={conversationId}
            orientation="horizontal"
            currentLLMUsed={currentLLMUsed}
          />
        </div>
      </div>
    );
  }

  const hasActiveDiff = proposal.diffBlocks.some((diffBlock) =>
    diffBlock.diffs.some((diff) => diff.active),
  );
  const isThreadedProposal = !isBulkSchedulingProposal && hasActiveDiff;

  const isScheduleByMoving = proposalIsScheduleByMoving(proposal);

  return (
    <div cw-id="proposal-response" className="cw-flex-col cw-w-full">
      {isThreadedProposal && <ProposalThread proposal={proposal} />}
      {!isThreadedProposal && (
        <>
          <ProposalDiffBlocks
            diffBlocks={proposal.diffBlocks}
            isScheduleByMoving={isScheduleByMoving}
            options={options}
          />
          {proposal?.consequencesBlock && (
            <ConsequencesBlock consequencesBlock={proposal.consequencesBlock} />
          )}
          <div className="-cw-mt-2">
            {!!proposal?.conflictReceipt?.receipt &&
              textSplitByNewLine(proposal?.conflictReceipt?.receipt)}
          </div>
        </>
      )}
      <div className="cw-pt-3">
        <div className="cw-flex cw-flex-wrap cw-items-center">
          {isAConflictProposal ? (
            <Button
              size="xsmall"
              sentiment="neutral"
              variant="outlined"
              onClick={resetDismissedConflictsAndConfirm}
              disabled={disableConfirm || !!confirmLoading}
            >
              I'm done fixing conflicts
            </Button>
          ) : (
            <div className="cw-flex cw-flex-row cw-gap-2">
              {!isProposeNewTime && (
                <Button
                  size="xsmall"
                  sentiment="positive"
                  onClick={handleConfirm}
                  disabled={disableConfirm || !!confirmLoading}
                  startIcon={Check}
                >
                  Confirm
                </Button>
              )}
              {availabilityBaseEventDiffId && !disableConfirm && (
                <ProposalAvailability
                  disabled={!!resetLoading}
                  allAttendeesExceptViewer={firstDiffAttendeesExceptViewer}
                  viewerProfile={viewerProfile}
                  canShareProposal={canShareProposal}
                  baseEventDiffId={availabilityBaseEventDiffId}
                  proposalId={proposal.proposalId}
                  sharedProposalDiff={firstDiff}
                  consequencesBlock={proposal.consequencesBlock}
                  tradeoffBlocks={firstDiffTradeoffBlocks}
                  messageId={msgId}
                  selectedTime={selectedTime}
                  isProposeNewTime={isProposeNewTime}
                  organizer={organizer}
                />
              )}
              <Button
                size="xsmall"
                sentiment="neutral"
                variant="outlined"
                onClick={handleCancel}
                disabled={disableCancel || !!resetLoading}
              >
                Cancel
              </Button>
            </div>
          )}

          <div className="cw-ml-4">
            <FeedbackThumbs
              msgId={msgId}
              conversationId={conversationId}
              orientation="horizontal"
              currentLLMUsed={currentLLMUsed}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
