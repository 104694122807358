import { paths } from "@clockwise/client-commons/src/constants/site";
import { Link } from "@clockwise/design-system";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React from "react";
import { LoginBtn } from "../login-btn";
import { HomeUninstallCard } from "./HomeUninstallCard";

export const DeactivationLogin = () => {
  const track = useTracking();

  return (
    <HomeUninstallCard
      title="You’ve uninstalled the Clockwise extension"
      description={
        <>
          You can still use Clockwise and adjust your settings by{" "}
          <Link href={paths.login} onClick={() => track(TrackingEvents.UNINSTALL.DEACTIVATE_ABORT)}>
            logging in.
          </Link>
        </>
      }
    >
      <div className="cw-body-base md:cw-body-lg cw-text-muted cw-italic cw-mb-6">
        Looking to deactivate your full Clockwise account? Sign in below to continue.
      </div>
      <div className="cw-mb-8 cw-font-normal cw-body-base cw-w-56">
        <LoginBtn
          label="Sign in to deactivate"
          labelStyle={{
            paddingLeft: 15,
            paddingRight: 20,
            textTransform: "none",
          }}
          preventLoginRedirect
          onStart={() => track(TrackingEvents.AUTH.BEGIN_LOGIN)}
          onSuccess={() => track(TrackingEvents.UNINSTALL.LOGIN)}
          onFailure={() => track(TrackingEvents.AUTH.LOGIN_FAILED)}
        />
      </div>
    </HomeUninstallCard>
  );
};
