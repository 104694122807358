import ErrorBoundary from "#clockwise/web-commons/src/ui/error-boundary/ErrorBoundary";
import classNames from "classnames";
import React from "react";

export const PageLayout = ({
  callout,
  children,
  description,
  title,
}: {
  callout?: React.ReactChild | React.ReactChild[];
  children: React.ReactChild | React.ReactChild[];
  description?: React.ReactChild | React.ReactChild[];
  title?: React.ReactChild | React.ReactChild[];
}) => {
  return (
    <div className="cw-w-full cw-h-full cw-body-base cw-pt-10">
      <PageLayoutContent>
        <>{title && <h1 className="cw-heading-3xl cw-p-0 cw-m-0">{title}</h1>}</>
        <>{description && <div className="cw-max-w-2xl cw-mt-4 cw-mb-1">{description}</div>}</>
      </PageLayoutContent>
      {callout && (
        <PageLayoutContent>
          <div className="cw-mb-2">{callout}</div>
        </PageLayoutContent>
      )}
      <div>{children}</div>
    </div>
  );
};

export const PageLayoutContent = ({
  children,
}: {
  children: React.ReactChild | React.ReactChild[];
}) => {
  return (
    <section className="cw-w-full cw-flex cw-flex-row cw-justify-center">
      <div className="cw-max-w-screen-lg cw-pb-4 cw-px-8 cw-w-full">
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </section>
  );
};

export const PageLayoutStripe = ({
  children,
  sentiment,
}: {
  children: React.ReactChild | React.ReactChild[];
  sentiment: "info" | "warning" | "off-brand";
}) => {
  return (
    <section
      className={classNames(
        "cw-relative cw-min-h-20 cw-pt-12 cw-pb-16 cw-w-full",
        sentiment === "info" ? "cw-bg-info" : "",
        sentiment === "warning" ? "cw-bg-destructive" : "",
        sentiment === "off-brand" ? "cw-bg-[#ECFDF3]" : "",
      )}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </section>
  );
};
