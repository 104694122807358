import { IOrg } from "#webapp/src/__schema__";
import { logger } from "@clockwise/client-commons/src/util/logger";

export function getOrgOverview(org: IOrg | null) {
  if (!org) {
    return null;
  }
  if (!org.orgOverview || org.orgOverview.__typename === "GraphEntityError") {
    logger.error("failed to get orgOverview from org");
    return null;
  }

  return org.orgOverview;
}
