import { salesContactFormUrl } from "@clockwise/client-commons/src/constants/site";
import { Button } from "@clockwise/design-system";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { ArrowSquareOut, UserPlus } from "phosphor-react";
import React from "react";
import { TrackingEvents, track } from "../../../../../util/analytics.util";
import { SimplePlan } from "../apBillingGroups.util";

export const FirstButton = ({
  planType,
  canSeeSCIM,
  scimButton,
  onAddUsers,
}: {
  planType: SimplePlan;
  canSeeSCIM: boolean;
  scimButton: JSX.Element;
  onAddUsers: () => void;
}) => {
  const onPricingPageClick = () => {
    track(TrackingEvents.ADMIN_PANEL.APBILLING_CLICK_PRICING);
    window.open(appPaths.pricingPage, "_blank");
  };

  if (planType === SimplePlan.Free) {
    return (
      <Button onClick={onPricingPageClick} variant="solid" sentiment="positive">
        <ArrowSquareOut size="15" color="white" />
        View Plans
      </Button>
    );
  } else if (canSeeSCIM) {
    return scimButton;
  } else {
    return (
      <Button onClick={onAddUsers} variant="solid" sentiment="positive">
        <UserPlus size="15" color="white" weight="fill" />
        Add users to your plan
      </Button>
    );
  }
};

export const SecondButton = ({
  planType,
  stripeURL,
}: {
  planType: SimplePlan;
  stripeURL: string | null;
}) => {
  const onContactUsClick = () => {
    track(TrackingEvents.ADMIN_PANEL.APBILLING_CLICK_CONTACT_US);
    window.open(salesContactFormUrl, "_blank");
  };

  const onBillingDetailsClick = () => {
    if (!stripeURL) return;
    track(TrackingEvents.ADMIN_PANEL.APBILLING_CLICK_MANAGE_BILLING);
    window.open(stripeURL, "_blank");
  };

  if (planType === SimplePlan.Free) {
    return (
      <Button onClick={onContactUsClick} variant="solid">
        Contact us
      </Button>
    );
  } else if (stripeURL) {
    return (
      <Button onClick={onBillingDetailsClick} variant="solid">
        <ArrowSquareOut size="15" color="white" />
        Manage billing details
      </Button>
    );
  } else {
    return null;
  }
};
