import { useState } from "react";

import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { CheckLinkNameDocument } from "./__generated__/CheckLinkName.v2.generated";

// This regex checks for special characters outside of '.', '@', and '-' and a single space.
const allowedChars = /[a-zA-Z0-9.@ -]+$/;
// Checks for two or more spaces in a row.
const sequentialSpaces = /\s{2,}/;

export const useUsernameValidator = (linkName: string) => {
  const [error, setError] = useState<string | undefined>();
  const [validating, setValidating] = useState(false);

  const [checkLinkNameAvailability] = useGatewayMutation(CheckLinkNameDocument, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const validate = async (input: string) => {
    if (linkName === input) {
      setError(undefined);
      return;
    }

    if (!input.length) {
      setError("A username is required.");
      return;
    }

    if (input.match(sequentialSpaces)) {
      setError("Usernames cannot contain sequential spaces.");
      return;
    }

    if (!input.match(allowedChars)) {
      setError("Usernames can only contain letters, numbers, dashes, periods, and '@'.");
      return;
    }

    setValidating(true);
    const { data } = await checkLinkNameAvailability({ variables: { linkName: input } });
    if (!data?.checkLinkNameAvailable) {
      setError("That username is not available.");
      setValidating(false);
      return;
    }

    setError(undefined);
    setValidating(false);
  };

  return {
    error,
    validate,
    validating,
    valid: !error,
  };
};
