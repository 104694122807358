import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { BillingGroupTypeEnum, UrnType } from "@clockwise/schema";
import { getSimplifiedUserProductType } from "../../../../hooks/useMonetization/monetization.util";
import { BillingGroupsPageQuery } from "./__generated__/APBillingGroups.generated";

type NonNullable<T> = Exclude<T, null | undefined>;

type OrgNode = Exclude<BillingGroupsPageQuery["node"], null>;

type GQLBillingGroupErrorable = NonNullable<
  Extract<OrgNode, { __typename: "Org" }>
>["billingGroups"];
type GQLBillingGroupList = NonNullable<
  Extract<GQLBillingGroupErrorable, { __typename: "BillingGroupList" }>
>["list"];
export type GQLBillingGroup = GQLBillingGroupList[number];
type GQLTeamsErrorable = NonNullable<Extract<OrgNode, { __typename: "Org" }>>["userTeams"];
export type GQLTeamsList = NonNullable<
  Extract<GQLTeamsErrorable, { __typename: "TeamList" }>
>["list"];

export type APBillingGroup = ReturnType<typeof formatBillingGroupForAP>;

export type APUpcomingBillingDetails = Extract<
  GQLBillingGroup["upcomingBillingDetails"],
  { __typename: "UpcomingBillingDetails" }
>;

export enum BannerVersions {
  TrueUp = "TrueUp",
  RequestConfirm = "RequestConfirm",
}

export enum SimplePlan {
  Free = "Free",
  Teams = "Teams",
  Enterprise = "Enterprise",
  Business = "Business",
}

export type TeamInfo = { teamName: string; teamId: string };

export const getUpcomingPaymentBannerType = (
  billedQuantity: number,
  currentUsageQuantity: number,
  requestedSeatCount: number | null,
  usesTrueUp: boolean,
): BannerVersions | null => {
  const showTrueUp = usesTrueUp && billedQuantity < currentUsageQuantity;
  const userUpgrading = requestedSeatCount && requestedSeatCount > currentUsageQuantity;
  // if requested is higher or equal than currentUsageQuantity, then show requested
  if (userUpgrading) {
    return BannerVersions.RequestConfirm;
  } else if (showTrueUp) {
    return BannerVersions.TrueUp;
  } else if (requestedSeatCount) {
    return BannerVersions.RequestConfirm;
  }
  return null;
};

export const determineDateToDisplayForUpcomingPayment = (
  upcomingBillingDetails: APUpcomingBillingDetails,
) => {
  const {
    requestedUpdatedQuantity,
    currentUsageQuantity,
    billedQuantity,
    nextTrueUpDate,
    nextBillingDate,
  } = upcomingBillingDetails;
  //the upcoming date we show can either be the next true up date or the next billing date depending on circumstances
  const requestIsHigher = requestedUpdatedQuantity && requestedUpdatedQuantity > billedQuantity;
  const currentUsageIsHigher = currentUsageQuantity > billedQuantity;
  if (!nextTrueUpDate) {
    return nextBillingDate;
  } else if (requestIsHigher) {
    return nextTrueUpDate;
  } else if (currentUsageIsHigher) {
    return nextTrueUpDate;
  } else {
    return nextBillingDate;
  }
};

export const maybeGetTeamIdAndNameForPrimaryBG = (
  syncSources: GQLBillingGroup["syncSources"],
  teamsList: GQLTeamsList,
) => {
  // With current logic there will only ever be one or zero sync sources (not sure why this is an array)
  const maybeTeamId = syncSources?.[0]?.value;
  const isSourceTypeTeam = syncSources?.[0]?.type === UrnType.Team;
  if (maybeTeamId && isSourceTypeTeam) {
    const team = teamsList.find((team) => team.teamId === maybeTeamId);
    if (team) {
      return {
        teamId: team.teamId,
        teamName: team.teamName,
      };
    }
  }
  return null;
};

export const canUserViewSCIM = (isAdmin: boolean, bgType: BillingGroupTypeEnum | null) => {
  return isAdmin && bgType === BillingGroupTypeEnum.SSO;
};

export const canUserViewInvitePolicy = (
  isAdmin: boolean,
  bgType: BillingGroupTypeEnum | null,
  syncSources: {
    type: UrnType | null;
  }[],
) => {
  const isSCIMVisible = canUserViewSCIM(isAdmin, bgType);
  const isDivisionPlan = !syncSources.length; // Divisions syncSources are always an empty array
  return isAdmin && isDivisionPlan && !isSCIMVisible;
};

export const formatBillingGroupForAP = (
  currentUserCalendarId: string,
  billingGroup: GQLBillingGroup,
  teamsList: GQLTeamsList,
) => {
  const adminList = getValue(billingGroup.adminOrgPersons)?.list || [];
  const subscription = getValue(billingGroup.subscription)?.subscription;
  const productType = subscription?.productType || null;
  const seatCount = getValue(billingGroup.subscription)?.subscription?.seatCount || 0;
  const userCount = getValue(billingGroup.personListPaginatedErrorable)?.count || 0;
  const upcomingBillingDetails = getValue(billingGroup.upcomingBillingDetails);
  const adminNameList = adminList.map((admin) => {
    return admin.primaryCalendarId;
  });
  const isAdmin = adminNameList.includes(currentUserCalendarId);
  const teamInfo = maybeGetTeamIdAndNameForPrimaryBG(billingGroup.syncSources, teamsList);
  const productTypeSimple = SimplePlan[getSimplifiedUserProductType(productType)];
  const stripeUrl = getValue(billingGroup.stripeCustomerPortalSession)?.sessionUrl || null;

  return {
    id: billingGroup.id,
    adminList: adminNameList,
    stripeUrl,
    isAdmin,
    bgName: billingGroup.name,
    invitePolicy: billingGroup.invitePolicy,
    ssoType: billingGroup.type,
    planType: productTypeSimple,
    upcomingBillingDetails,
    seatCount,
    userCount,
    teamInfo,
    canSeeSCIM: canUserViewSCIM(isAdmin, billingGroup.type),
    canToggleInvitePolicy: canUserViewInvitePolicy(
      isAdmin,
      billingGroup.type,
      billingGroup.syncSources,
    ),
  };
};
