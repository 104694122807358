import { OnboardingInviteDialogRenderer } from "#clockwise/web-commons/src/components/onboarding-invite-dialog/OnboardingInviteDialogRenderer";
import {
  PersonSelector,
  Person as SelectablePerson,
} from "#clockwise/web-commons/src/ui/person-selector";
import { Button, Link } from "@clockwise/design-system";
import { SchedulingPriorityEnum } from "@clockwise/schema/v2";
import { debounce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useGatewayLazyQuery } from "../../../network/apollo/gateway-provider";
import { useResettingBoolean } from "../../scheduling-links/hooks/useResettingBoolean";
import { MeetingType } from "../meeting-type-picker/MeetingTypePicker";
import { Person, SetupLinkPersonPicker } from "../setup-link-person-picker/SetupLinkPersonPicker";
import { AttendeePickerQueryDocument } from "./__generated__/AttendeePicker.v2.generated";

const MAX_NUM_ATTENDEES = 14;

type Props = {
  people: Person[];
  onChange: (people: Person[]) => void;
  orgId: string | undefined;
  openPickerOnLoad?: boolean;
  canUserMakeGroupLinks: boolean;
  onOpenMonetizationModal: () => void;
  meetingType: MeetingType;
  handleChangeMeetingType: (value: MeetingType) => void;
};

type NonUser = {
  email: string;
  givenName: string | null;
  familyName: string | null;
  externalImageUrl: string | null;
};

export function AttendeePicker({
  people,
  onChange,
  orgId,
  openPickerOnLoad,
  canUserMakeGroupLinks,
  onOpenMonetizationModal,
  handleChangeMeetingType,
  meetingType,
}: Props) {
  const [pickerOpen, setPickerOpen] = useState(openPickerOnLoad);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<Person[]>([]);
  const [nonUsers, setNonUsers] = useState<NonUser[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [invitationMessage, setInvitationMessage] = useState("");
  const [showInvitationMessage, setShowInvitationMessage] = useResettingBoolean(false, 3000);
  const [searchPersons] = useGatewayLazyQuery(AttendeePickerQueryDocument);

  const handleSearch = useMemo(() => {
    const debouncedSearch = debounce(async (query: string) => {
      setSearchLoading(true);
      const { data } = await searchPersons({ variables: { query } });
      setSearchLoading(false);
      const members = data?.searchMembers.edges.map(({ node: member }) => member);

      if (members) {
        setNonUsers(members.filter((member) => !member.user).map(({ person }) => person));
        setSearchResults(
          members
            .filter((member) => member.user)
            .map(
              ({
                id,
                person: { email, givenName, familyName, externalImageUrl },
                roundRobinEnabled,
              }) => ({
                memberId: id,
                email,
                name: {
                  givenName,
                  familyName,
                },
                externalImageUrl,
                isRequired: true,
                organizer: false,
                schedulingPriority:
                  meetingType === MeetingType.RoundRobin && roundRobinEnabled
                    ? SchedulingPriorityEnum.Medium
                    : meetingType === MeetingType.RoundRobin
                    ? SchedulingPriorityEnum.DoNotSchedule
                    : null,
                roundRobinEnabled,
              }),
            ),
        );
      }
    }, 250);

    return (query: string) => {
      if (!query) {
        setNonUsers([]);
        setSearchResults([]);
        return;
      }
      setSearchLoading(true);
      void debouncedSearch(query);
    };
  }, [searchPersons, meetingType]);

  const handleRemove = useCallback(
    (email: string) => onChange(people.filter((p: Person) => p.email !== email)),
    [onChange, people],
  );

  const handleAdd = useCallback(
    (newPerson: Person) => {
      if (
        newPerson.email &&
        !people.some((p: Person) => p.email === newPerson.email) &&
        people.length < MAX_NUM_ATTENDEES
      ) {
        onChange([...people, newPerson]);
      }
    },
    [onChange, people],
  );

  const handlePriorityChange = useCallback(
    (email: string, newPriority: SchedulingPriorityEnum) =>
      onChange(
        people.map((p: Person) => {
          if (p.email === email) {
            p.schedulingPriority = newPriority;
          }
          return p;
        }),
      ),
    [onChange, people],
  );

  const handleChangeOptionality = useCallback(
    (email: string, newOptionality: boolean) =>
      onChange(
        people.map((p: Person) => {
          if (p.email === email) {
            p.isRequired = newOptionality;
          }
          return p;
        }),
      ),
    [onChange, people],
  );

  const searchProfiles = useMemo(
    () =>
      searchResults.filter(({ memberId }) => !people.some((p: Person) => memberId === p.memberId)),
    [searchResults, people],
  );

  return (
    <div className="cw-my-8 cw-body-base">
      <SetupLinkPersonPicker
        people={people}
        onRemove={handleRemove}
        onChangeOptionality={handleChangeOptionality}
        onChangePriority={handlePriorityChange}
        onChangeMeetingType={handleChangeMeetingType}
        meetingType={meetingType}
        canUserMakeGroupLinks={canUserMakeGroupLinks}
      />
      {pickerOpen ? (
        <div className="cw-mt-6">
          {people.length >= MAX_NUM_ATTENDEES ? (
            <div className="cw-text-muted cw-my-8">
              You've added the maximum number of teammates we support
            </div>
          ) : (
            <PersonSelector
              autoFocus={pickerOpen}
              onSearch={handleSearch}
              onSelect={(profile: SelectablePerson) => {
                const person = searchResults.find((p: Person) => p.email === profile.email);
                if (person) {
                  handleAdd(person);
                }
              }}
              onRemove={(profile: SelectablePerson) => {
                const person = people.find((p: Person) => p.email === profile.email);
                if (person) {
                  handleRemove(person.email);
                }
              }}
              people={searchProfiles}
              placeholder="Search teammates by email"
              searchLoading={searchLoading}
              selectedPeople={people}
            />
          )}
          <div className="cw-text-muted cw-mt-5 cw-mb-2">Only Clockwise users can be added</div>
          <Link onClick={() => setInviteOpen(true)}>Invite teammates to Clockwise</Link>
          <div className="cw-text-muted cw-mt-2">{showInvitationMessage && invitationMessage}</div>
        </div>
      ) : (
        <div className="cw-mt-7">
          <Button
            onClick={() => {
              if (canUserMakeGroupLinks) {
                setPickerOpen(true);
              } else {
                onOpenMonetizationModal();
              }
            }}
            sentiment="positive"
          >
            Add a teammate
          </Button>
        </div>
      )}
      {orgId && inviteOpen && (
        <InviteNonUsersDialog
          nonUsers={nonUsers}
          orgId={orgId}
          onClose={() => setInviteOpen(false)}
          onInviteSent={(message) => {
            setInvitationMessage(message);
            setShowInvitationMessage(true);
          }}
        />
      )}
    </div>
  );
}

const InviteNonUsersDialog = ({
  nonUsers,
  orgId,
  onClose,
  onInviteSent,
}: {
  nonUsers: NonUser[];
  orgId: string;
  onClose: () => void;
  onInviteSent: (message: string) => void;
}) => {
  // TODO: OnboardingInviteDialogRenderer uses old GQL schema types, so we need to convert our data.
  // Eventually, we should update OnboardingInviteDialogRenderer to use better types.
  const membersToInvite = nonUsers.map((nonUser) => ({
    primaryEmail: nonUser.email,
    profile: {
      givenName: nonUser.givenName,
      familyName: nonUser.familyName,
      externalImageUrl: nonUser.externalImageUrl,
      __typename: "UserProfile" as const,
    },
  }));

  return (
    <OnboardingInviteDialogRenderer
      onClose={onClose}
      // Only prepopulate if there's one email match
      attendeePersonsToInvite={membersToInvite.length === 1 ? membersToInvite : []}
      orgId={orgId}
      setInvitationMessage={onInviteSent}
      variant="group-link"
    />
  );
};
