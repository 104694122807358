import { useMutation } from "@apollo/client";
import { Button, Select, SelectOption, TextField } from "@clockwise/design-system";
import { BillingGroupTypeEnum, InvitePolicyEnum } from "@clockwise/schema";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { SudoCreateBillingGroupDocument } from "../graphql/__generated__/SudoCreateBillingGroup.generated";
import { ButtonWrapper } from "./ButtonWrapper";
import { FormWrapper } from "./FormWrapper";

const BILLING_GROUP_TYPE_OPTIONS = [
  { label: "Manual", value: BillingGroupTypeEnum.Manual },
  { label: "SSO", value: BillingGroupTypeEnum.SSO },
];

const INVITE_POLICY_OPTIONS = [
  { label: "Any member", value: InvitePolicyEnum.AnyMember },
  { label: "Admin only", value: InvitePolicyEnum.AdminOnly },
];

export const CreateBillingGroup = () => {
  const [createBillingGroup, { loading }] = useMutation(SudoCreateBillingGroupDocument);
  const [orgId, setOrgId] = useState<string | undefined>();
  const [adminEmail, setAdminEmail] = useState<string | undefined>();
  const [billingGroupName, setBillingGroupName] = useState<string | undefined>();
  const [billingGroupType, setBillingGroupType] = useState<BillingGroupTypeEnum | undefined>(
    BILLING_GROUP_TYPE_OPTIONS[0].value,
  );
  const [invitePolicy, setInvitePolicy] = useState<InvitePolicyEnum | undefined>(
    INVITE_POLICY_OPTIONS[0].value,
  );

  const allFieldsPopulated =
    orgId && adminEmail && billingGroupName && billingGroupType && invitePolicy;

  const resetFields = () => {
    setOrgId("");
    setAdminEmail("");
    setBillingGroupName("");
    setBillingGroupType(undefined);
    setInvitePolicy(undefined);
  };
  const onCreateBillingGroup = () => {
    if (!orgId || !adminEmail || !billingGroupName || !billingGroupType || !invitePolicy) {
      return;
    }
    void createBillingGroup({
      variables: {
        input: {
          orgId,
          adminEmail,
          name: billingGroupName,
          type: billingGroupType,
          invitePolicy: invitePolicy,
        },
      },
      onCompleted: () => {
        toast.success("Billing group created successfully");
        resetFields();
      },
      onError: (error) => {
        toast.error("Error creating billing group " + error.message);
      },
    });
  };
  return (
    <FormWrapper>
      <div className="cw-flex cw-gap-x-2 cw-flex-wrap">
        <TextField
          label="Billing Group Name"
          value={billingGroupName}
          onChange={(e) => setBillingGroupName(e.target.value)}
        />
        <TextField
          label="Admin Email"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
        />
        <TextField label="Org ID" value={orgId} onChange={(e) => setOrgId(e.target.value)} />
      </div>
      <div className="cw-flex cw-w-full cw-gap-x-8 ">
        <div className="cw-flex cw-items-center">
          <div className="cw-mr-2"> Billing group type: </div>
          <Select value={billingGroupType} onChange={(value) => setBillingGroupType(value)}>
            {BILLING_GROUP_TYPE_OPTIONS.map((option) => (
              <SelectOption key={option.value} value={option.value}>
                {option.label}
              </SelectOption>
            ))}
          </Select>
        </div>
        <div className="cw-flex cw-items-center">
          <div className="cw-mr-2"> Invite Policy: </div>
          <Select value={invitePolicy} onChange={(value) => setInvitePolicy(value)}>
            {INVITE_POLICY_OPTIONS.map((option) => (
              <SelectOption key={option.value} value={option.value}>
                {option.label}
              </SelectOption>
            ))}
          </Select>
        </div>
      </div>

      <ButtonWrapper>
        <Button
          size="medium"
          disabled={loading || !allFieldsPopulated}
          sentiment={"positive"}
          fullWidth={false}
          onClick={onCreateBillingGroup}
        >
          Create Billing Group
        </Button>
      </ButtonWrapper>
    </FormWrapper>
  );
};
