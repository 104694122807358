import React from "react";
import { IIntegerDataPoint } from "../overview.types";

export interface ChartProps {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  chart: React.ReactNode;
}

export interface TimeSeriesProps {
  name: string;
  data: IIntegerDataPoint[];
  threshold?: number | null;
}

export interface ChartTooltipProps {
  valueSuffix?: string;
  xDataFormat?: string;
}

export const Chart = ({ title, subtitle, chart }: ChartProps) => {
  return (
    <div className="cw-mt-10 cw-flex cw-flex-col cw-justify-between">
      <div className="cw-space-y-2 cw-mb-10">
        <div className="cw-heading-xl">{title}</div>
        <div className="cw-body-base cw-text-subtle">{subtitle}</div>
      </div>
      <div className="chromatic-ignore">{chart}</div>
    </div>
  );
};
