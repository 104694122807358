import { TextField } from "@clockwise/design-system";
import React from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";

export const ECPrivateEventCallout = () => {
  return (
    <div className="cw-pb-2">
      <EventElementsWrap name="locked">
        <TextField value="Private" disabled />
      </EventElementsWrap>
    </div>
  );
};
