import { RouteRenderer } from "#webapp/src/components/route-renderer";
import { EcosystemEnum } from "@clockwise/schema";
import { CustomMeetingHoursInput } from "@clockwise/schema/v2";
import { hasNotFoundError } from "@clockwise/web-commons/src/network/apollo/errors";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { SetupSchedulingLink } from "@clockwise/web-commons/src/ui/setup-scheduling-link";
import {
  DEFAULT_MAX_BOOKINGS,
  SchedulingLinkMeetingDetails,
} from "@clockwise/web-commons/src/ui/setup-scheduling-link/utils";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { omit } from "lodash";
import { nanoid } from "nanoid";
import React, { useCallback, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import { useMonetization } from "../../hooks/useMonetization";
import { logger } from "../../util/logger.util";
import { SchedulingLinksQueryDocument } from "../scheduling-link-dashboard/__generated__/SchedulingLinksQuery.v2.generated";
import {
  DeleteSchedulingLinkMutationDocument,
  EditSchedulingLinkQueryDocument,
  EditSchedulingLinkQueryQuery,
  UpdateSchedulingLinkMutationDocument,
} from "./__generated__/EditSchedulingLink.v2.generated";
import { buildSchedulingLinkTrackingData } from "./tracking.util";
import { buildAvailabilityRange } from "./utils";

export const EditSchedulingLinkPageGateway = ({
  onReturnToDashboard,
  onViewLink,
}: {
  onReturnToDashboard: () => void;
  onViewLink: (linkName: string, slug: string, shouldPushNotReplace?: boolean) => void;
}) => {
  // Context hooks
  const { linkName = "", slug = "" } = useParams();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const isGroupLink = queryString.get("group_link") === "true";

  const trackEvent = useTracking();

  const {
    canUserMarkLinkAsActive,
    canUserMakeGroupLinks,
    shouldUserSeeJoinExistingPlan,
  } = useMonetization();

  // Local state

  const [createUpdateError, setCreateUpdateError] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);

  // GQL queries and mutations

  // Fetch the existing link data some user specific values

  const { loading, data, error } = useGatewayQuery(EditSchedulingLinkQueryDocument, {
    // On mount, this component should always fetch to ensure we're editing the most up to date link
    fetchPolicy: "network-only",
    // Since we only use this query to set up initial link settings, we don't need to fetch it again
    nextFetchPolicy: "cache-only",
    variables: {
      slug,
      linkName,
    },
  });

  const isInvalidLink = error && hasNotFoundError(error);

  const linkSettingsData = data?.currentUser?.schedulingLink;

  const [updateLink] = useGatewayMutation(UpdateSchedulingLinkMutationDocument, {
    refetchQueries: [{ query: SchedulingLinksQueryDocument }],
    update(cache, { data }, { variables }) {
      // The PublicSchedulingLink version of the link is now out of date, so we need to evict it from
      // the cache.
      if (data?.updateSchedulingLink && variables?.input.id) {
        cache.evict({
          id: cache.identify({ __typename: "PublicSchedulingLink", id: variables.input.id }),
        });
        cache.gc();
      }
    },
  });

  const [deleteLink, { loading: deletingLink }] = useGatewayMutation(
    DeleteSchedulingLinkMutationDocument,
    {
      refetchQueries: [{ query: SchedulingLinksQueryDocument }],
      update(cache, { data }, { variables }) {
        if (data?.deleteSchedulingLink && variables?.id) {
          cache.evict({ id: cache.identify({ __typename: "SchedulingLink", id: variables.id }) });
          cache.gc();
        }
      },
    },
  );

  // Callbacks

  // Convert the newLinkSettings to the mutation input type, CreateMeetingSettingsVariables.
  const handleSave = useCallback(
    async ({
      active,
      allowBookingOnNoMeetingDay,
      allowQuarterHourBooking,
      attendeeQuestions,
      availabilityRange,
      availabilityRestriction,
      bookableHours,
      bufferHours,
      customMeetingHours,
      durations,
      defaultDuration,
      highlightBestTimes,
      linkMembers,
      location,
      name,
      singleUse,
      slug,
      description,
      id,
      allowAdditionalAttendees,
      titleTemplate,
      textOnlyTitle,
      maxBookings,
      groupSchedulingStrategy,
    }: SchedulingLinkMeetingDetails) => {
      if (!id) {
        throw new Error("Cannot update a link that does not have an id!");
      }

      if (!defaultDuration) {
        throw new Error("Cannot create a link without a default duration!");
      }

      setSaving(true);

      let customMeetingHoursInput: CustomMeetingHoursInput | null = null;
      if (customMeetingHours) {
        customMeetingHoursInput = {
          schedule: customMeetingHours.schedule.map(({ day, timeRanges }) => ({
            day,
            timeRanges: timeRanges.map(({ startTime, endTime }) => ({ startTime, endTime })),
          })),
          dateOverrides: customMeetingHours.dateOverrides.map(({ date, timeRanges }) => ({
            date,
            timeRanges: timeRanges.map(({ startTime, endTime }) => ({ startTime, endTime })),
          })),
        };
      }

      try {
        const { data } = await updateLink({
          variables: {
            input: {
              id,
              schedulingLink: {
                active,
                allowBookingOnNoMeetingDay,
                allowQuarterHourBooking,
                attendeeQuestions: attendeeQuestions.map((q) => omit(q, ["renderId"])),
                availabilityRange: buildAvailabilityRange(availabilityRange),
                availabilityRestriction,
                bookableHours,
                bufferHours,
                customMeetingHours: customMeetingHoursInput,
                durations,
                defaultDuration,
                highlightBestTimes,
                linkMembers: linkMembers.map(({ member, owner, required, schedulingPriority }) => ({
                  owner,
                  required,
                  memberId: member.id,
                  schedulingPriority: schedulingPriority,
                })),
                name,
                singleUse,
                slug,
                description,
                location,
                allowAdditionalAttendees,
                titleTemplate,
                textOnlyTitle,
                groupSchedulingStrategy,
                maxBookings: maxBookings.active
                  ? { count: maxBookings.count, period: maxBookings.period }
                  : undefined,
              },
            },
          },
        });

        const updatedLink = data?.updateSchedulingLink;
        // Check for success first (why would we ever have null data?)
        if (updatedLink) {
          trackEvent(
            TrackingEvents.LINKS.EDIT_PAGE.SAVE_CHANGES,
            buildSchedulingLinkTrackingData({
              ...updatedLink,
              availabilityRange: buildAvailabilityRange(updatedLink.availabilityRange),
            }),
          );
          if (updatedLink.active && updatedLink.slug) {
            onViewLink(linkName, updatedLink.slug, true);
          } else {
            onReturnToDashboard();
          }
        }
      } catch (error) {
        setCreateUpdateError((error as Error)?.message ?? "Something went wrong.");
        logger.error("Mutation error updating link via EditSchedulingLinkPage", error);
      } finally {
        setSaving(false);
      }
    },
    [updateLink, trackEvent, linkName, onViewLink, onReturnToDashboard],
  );

  const handleDelete = useCallback(
    async (meetingSettingsId: string) => {
      await deleteLink({
        variables: {
          id: meetingSettingsId,
        },
      });
      trackEvent(TrackingEvents.LINKS.EDIT_PAGE.DELETE, {
        meetingSettingsId,
        singleUse: linkSettingsData?.singleUse,
      });
      onReturnToDashboard();
    },
    [deleteLink, trackEvent, linkSettingsData?.singleUse, onReturnToDashboard],
  );

  const onBack = useCallback(() => {
    if (history.length > 1) {
      history.back();
    } else {
      // We arrived here in a new tab from sidebar create button or directly via url.
      onReturnToDashboard();
    }
  }, [onReturnToDashboard]);

  const onViewThisLink = useCallback(() => {
    onViewLink(linkName, slug, true);
  }, [linkName, slug, onViewLink]);

  const linkType =
    linkSettingsData?.linkMembers && linkSettingsData?.linkMembers?.length > 1 ? "Group" : "1:1";
  const canMarkMeetingAsActive = canUserMarkLinkAsActive(linkType);

  const org = data?.currentOrg;

  const canUseZoom = !!org?.zoomAccount?.valid || !!data?.currentUser?.zoomAccount?.valid;
  const canUseTeams = useEcosystem() === EcosystemEnum.Microsoft;

  const meetingName = linkSettingsData?.name;

  const initialLinkSettings = useMemo(
    () => (linkSettingsData ? initializeLinkSettings(linkSettingsData) : null),
    [linkSettingsData],
  );

  const showErrorScreen = !!error || (loading && !data);

  return (
    <>
      <Helmet>
        <title>{meetingName ? `Edit ${meetingName}` : "Clockwise Links"}</title>
      </Helmet>
      <RouteRenderer loading={loading} error={showErrorScreen} notFound={isInvalidLink}>
        {initialLinkSettings && (
          <SetupSchedulingLink
            initialLinkSettings={initialLinkSettings}
            canUseZoom={canUseZoom}
            canUseTeams={canUseTeams}
            canMarkMeetingAsActive={canMarkMeetingAsActive}
            shouldUserSeeJoinExistingPlan={shouldUserSeeJoinExistingPlan}
            canUserMakeGroupLinks={canUserMakeGroupLinks}
            onBack={onBack}
            onSave={handleSave}
            onDelete={handleDelete}
            createUpdateError={createUpdateError}
            saving={loading || saving}
            linkName={linkName}
            deleting={deletingLink}
            clearError={() => setCreateUpdateError(null)}
            onPreview={onViewThisLink}
            orgId={org?.id}
            isGroupLink={isGroupLink}
          />
        )}
      </RouteRenderer>
    </>
  );
};

// Convert GQL schema from gateway to match SchedulingLinkMeetingDetails
const initializeLinkSettings = ({
  active,
  allowBookingOnNoMeetingDay,
  allowQuarterHourBooking,
  attendeeQuestions,
  availabilityRange,
  customMeetingHours,
  availabilityRestriction,
  bookableHours,
  bufferHours,
  durations,
  defaultDuration,
  highlightBestTimes,
  linkMembers,
  location,
  name,
  singleUse,
  slug,
  description,
  id,
  allowAdditionalAttendees,
  titleTemplate,
  textOnlyTitle,
  maxBookings,
  groupSchedulingStrategy,
}: NonNullable<
  NonNullable<EditSchedulingLinkQueryQuery["currentUser"]>["schedulingLink"]
>): SchedulingLinkMeetingDetails => {
  const settings: SchedulingLinkMeetingDetails = {
    id,
    name,
    slug,
    description,
    durations,
    defaultDuration,
    availabilityRange: {
      windowSize:
        availabilityRange.__typename === "RollingWindow" ? availabilityRange.windowSize : null,
      endDate: availabilityRange.__typename === "FixedWindow" ? availabilityRange.endDate : null,
      startDate:
        availabilityRange.__typename === "FixedWindow" ? availabilityRange.startDate : null,
    },
    customMeetingHours,
    allowQuarterHourBooking,
    attendeeQuestions: attendeeQuestions.map(({ type, required, question }) => ({
      type,
      required,
      question,
      renderId: nanoid(),
    })),
    availabilityRestriction,
    bookableHours,
    singleUse,
    bufferHours,
    allowBookingOnNoMeetingDay,
    highlightBestTimes,
    active,
    linkMembers,
    location,
    allowAdditionalAttendees,
    titleTemplate,
    textOnlyTitle,
    groupSchedulingStrategy,
    maxBookings: maxBookings
      ? {
          active: true,
          count: maxBookings.count,
          period: maxBookings.period,
        }
      : DEFAULT_MAX_BOOKINGS,
  };
  return settings;
};
