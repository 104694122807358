import { AccessTimeFilled } from "@clockwise/design-system/icons";
import {
  AssistantRounded,
  CalendarTodayRounded,
  GroupRounded,
  HeightRounded,
  InfoRounded,
  LocalOfferRounded,
  LockRounded,
  NotesRounded,
  NotificationsOffRounded,
  PersonRounded,
  PlaceRounded,
  RepeatRounded,
  SecurityRounded,
  SettingsRounded,
  SvgIconComponent,
  VideocamRounded,
  WorkRounded,
} from "@material-ui/icons";

import classNames from "classnames";
import React from "react";

export const eventSelections = [
  "agenda",
  "allDay",
  "attendee",
  "calendar",
  "calendarSync",
  "doNotDisturb",
  "flex",
  "flexandprotection",
  "info",
  "location",
  "locked",
  "recurrence",
  "settings",
  "tags",
  "time",
  "timeAndFlex",
  "video",
  "workingLocation",
  "visibility",
] as const;
export type EventSection = typeof eventSelections[number];

const iconWithStylesMap: {
  [key in EventSection]: {
    icon: SvgIconComponent;
    heightOfTopRow?: string;
  };
} = {
  flex: {
    icon: AssistantRounded,
    heightOfTopRow: "20px",
  },
  attendee: {
    icon: PersonRounded,
    heightOfTopRow: "24px",
  },
  time: {
    icon: AccessTimeFilled,
    heightOfTopRow: "28px",
  },
  timeAndFlex: {
    icon: AccessTimeFilled,
    heightOfTopRow: "32px",
  },
  recurrence: {
    icon: RepeatRounded,
    heightOfTopRow: "28px",
  },
  video: {
    icon: VideocamRounded,
    heightOfTopRow: "28px",
  },
  agenda: {
    icon: NotesRounded,
    heightOfTopRow: "28px",
  },
  location: {
    icon: PlaceRounded,
  },
  locked: {
    icon: LockRounded,
  },
  calendar: {
    icon: CalendarTodayRounded,
    heightOfTopRow: "auto",
  },
  flexandprotection: {
    icon: SecurityRounded,
    heightOfTopRow: "28px",
  },
  doNotDisturb: {
    icon: NotificationsOffRounded,
    heightOfTopRow: "auto",
  },
  settings: {
    icon: SettingsRounded,
    heightOfTopRow: "20px",
  },
  info: {
    icon: InfoRounded,
    heightOfTopRow: "20px",
  },
  allDay: {
    icon: HeightRounded,
    heightOfTopRow: "28px",
  },
  tags: {
    icon: LocalOfferRounded,
    heightOfTopRow: "28px",
  },
  calendarSync: {
    icon: GroupRounded,
    heightOfTopRow: "20px",
  },
  workingLocation: {
    icon: PlaceRounded,
  },
  visibility: {
    icon: WorkRounded,
  },
};

export const EventElementsWrap = ({
  children,
  showChanges = false,
  name,
  icon: Icon,
}: {
  children: React.ReactNode;
  showChanges?: boolean;
  name: EventSection;
  icon?: SvgIconComponent;
}) => {
  const currentSection = iconWithStylesMap[name];
  const SectionIcon = Icon ? Icon : currentSection.icon;
  const icon = SectionIcon ? (
    <SectionIcon
      className={classNames("cw-w-4 cw-h-4", {
        "cw-text-subtle": !showChanges,
        "cw-text-busy": showChanges,
      })}
      aria-hidden
    />
  ) : null;

  return (
    <div className="cw-flex cw-body-base cw-gap-3 cw-ml-3 cw-mr-4">
      <div
        className="cw-flex cw-items-center cw-relative"
        style={{ height: iconWithStylesMap[name].heightOfTopRow || "32px" }}
      >
        {icon}
      </div>
      {/* 28px is the width of the icon plus the gap between the icon and the child */}
      <div className="cw-flex cw-items-center cw-flex-1 cw-max-w-[calc(100%-28px)]">{children}</div>
    </div>
  );
};
