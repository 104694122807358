import { ActionIcon } from "@clockwise/design-system";
import { fg_subtle } from "@clockwise/design-system/tokens";
import { X } from "phosphor-react";
import React from "react";
import { AttendeeAvatar } from "../AttendeeAvatar";
import { Person } from "./types";

interface PersonChipProps<T extends Person> {
  person: T;
  onClose: () => void;
}

export const PersonProfileChip = <T extends Person>({ person, onClose }: PersonChipProps<T>) => {
  const { name, email, externalImageUrl } = person;
  const fullName = name.givenName || name.familyName ? `${name.givenName} ${name.familyName}` : "";
  const profile = {
    givenName: name.givenName,
    familyName: name.familyName,
    externalImageUrl,
  };

  return (
    <div
      className="cw-inline-flex cw-items-center cw-bg-neutral-inset cw-rounded-full cw-py-0.5 cw-pl-2 cw-pr-1 cw-shrink-0"
      cw-id="cw-person-chip"
    >
      <div className="cw-flex cw-items-center cw-gap-2">
        <AttendeeAvatar calendarId={email} profile={profile} size="small" />
        <span className="cw-body-sm cw-font-medium">{fullName || email}</span>
      </div>
      <ActionIcon
        size="xs"
        onClick={onClose}
        className="cw-ml-1 cw-bg-transparent cw-rounded-full hover:cw-bg-neutral-inset-hover"
      >
        <X size={12} weight="bold" color={fg_subtle} />
      </ActionIcon>
    </div>
  );
};
