import { isAuthedSudo } from "#webapp/src/util/auth.util";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const AppRestrictedSudoLayout = () => {
  const isSudo = isAuthedSudo();

  if (!isSudo) {
    return <Navigate to={appPaths.landing} replace />;
  }

  return <Outlet />;
};
