import {
  timeSlotsCeiling,
  timeSlotsFloor,
} from "@clockwise/client-commons/src/constants/time-slot";
import { Button, Select, SelectOption } from "@clockwise/design-system";
import {
  CalendarToday,
  DateRange,
  InfoFilled,
  SvgIconComponent,
  WarningFilled,
} from "@clockwise/icons";
import { AutopilotEventStatusEnum } from "@clockwise/schema";
import { DayOfWeek, FlexDetailsInput, FlexRange } from "@clockwise/schema/v2";
import { DayCheckboxes } from "@clockwise/web-commons/src/ui/working-hours";
import { Dialog } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect } from "react";
import "react-quill/dist/quill.snow.css"; // ES6
import { WorkingHoursByAttendee } from "../hooks/EventFlexConstraintsContext";
import { SelectedWorkdayOption } from "../types";
import { toDayOfWeek } from "../utils/flexibility";
import { FlexTimeRangeSelector } from "./FlexTimeRangeSelector";
import "./additionalStyles.css";

export type FlexType = "withinDay" | "withinWeek" | "specificDays";

const convertArrayOfDayWeekToDayMap = (
  days: SelectedWorkdayOption[],
  possibleWorkDays: SelectedWorkdayOption[],
) => {
  const dayMap: { [keyof: string]: boolean } = {};
  for (const day of possibleWorkDays) {
    if (days.includes(day)) {
      dayMap[day] = true;
    } else {
      dayMap[day] = false;
    }
  }
  return dayMap;
};

const getAllowedStartAndEndTimes = (
  flexDetails: FlexDetailsInput,
  minMaxDefaults: [number, number],
): [string, string] => {
  const startTime = flexDetails.timeOfDayRange?.start;
  const endTime = flexDetails.timeOfDayRange?.end;
  if (startTime && endTime) {
    return [startTime, endTime];
  }
  const minDefault = timeSlotsFloor[minMaxDefaults[0]];
  const maxDefault = timeSlotsCeiling[minMaxDefaults[1]];
  return [startTime || minDefault, endTime || maxDefault];
};

const FlexStatusBanner = ({
  Icon,
  sentiment,
  title,
  description,
}: {
  Icon: SvgIconComponent;
  sentiment: "info" | "warning";
  title: string;
  description: string;
}) => {
  return (
    <div
      className={classNames(
        "cw-px-3 cw-py-2 cw-rounded-md cw-flex cw-gap-[6px] cw-text-12 cw-font-medium",
        {
          "cw-bg-busy": sentiment === "info",
          "cw-bg-warning": sentiment === "warning",
        },
      )}
    >
      <Icon
        className={classNames("cw-w-4 cw-h-4", {
          "cw-text-busy-pressed": sentiment === "info",
          "cw-text-warning": sentiment === "warning",
        })}
      />
      <div
        className={classNames({
          "cw-text-busy-pressed": sentiment === "info",
          "cw-text-warning": sentiment === "warning",
        })}
      >
        <span className="cw-font-semibold">{title}</span>
        {" - "}
        <span>{description}</span>
      </div>
    </div>
  );
};

const FlexStatusNotification = ({ status }: { status: AutopilotEventStatusEnum | "Stuck" }) => {
  switch (status) {
    case AutopilotEventStatusEnum.Pinned:
      return (
        <FlexStatusBanner
          title="This meeting will not move"
          description="Clockwise will not change events on your calendar that occur in the next 24 hours, or events that have already taken place. Future meetings in this series will still move."
          Icon={InfoFilled}
          sentiment="info"
        />
      );
    case AutopilotEventStatusEnum.Paused:
      return (
        <FlexStatusBanner
          title="This meeting is paused"
          description="Clockwise won't move this meeting because it was manually rescheduled to its current time. Future meetings in this series will still move."
          Icon={InfoFilled}
          sentiment="info"
        />
      );
    case "Stuck":
      return (
        <FlexStatusBanner
          title="This meeting is stuck"
          description="Clockwise won't move this meeting because there is no overlap in attendees' meeting hours."
          Icon={WarningFilled}
          sentiment="warning"
        />
      );
    default:
      return null;
  }
};

export const ECFlexSettings = ({
  goBack,
  isOpen,
  flexDetails: inputFlexDetails,
  onFlexDetailsChange,
  availableWorkDays,
  meetingHoursConflict,
  minMaxRecommendedValues,
  usersWorkingHours,
  flexStatus,
}: {
  availableWorkDays: DayOfWeek[];
  meetingHoursConflict: boolean;
  minMaxRecommendedValues: [number, number];
  usersWorkingHours: WorkingHoursByAttendee[];
  flexStatus: AutopilotEventStatusEnum;
  goBack: () => void;
  isOpen: boolean;
  flexDetails: FlexDetailsInput;
  onFlexDetailsChange: (details: FlexDetailsInput) => void;
}) => {
  const [flexDetails, setFlexDetails] = React.useState<FlexDetailsInput>(inputFlexDetails);
  const selectedFlexType = flexDetails.flexRange ?? FlexRange.Day;

  useEffect(() => {
    if (isOpen) {
      setFlexDetails(inputFlexDetails);
    }
  }, [isOpen]);

  const onFlexTypeChange = (type: FlexRange) => {
    setFlexDetails({
      ...flexDetails,
      flexRange: type,
      allowedDays: type === FlexRange.SpecificDays ? availableWorkDays.map(toDayOfWeek) : null,
    });
  };

  const onToggleSpecificDay = (day: DayOfWeek) => {
    const currentDays = [...(flexDetails?.allowedDays ?? [])];
    if (currentDays.includes(day)) {
      currentDays.splice(currentDays.indexOf(day), 1);
    } else {
      currentDays.push(day);
    }
    setFlexDetails({
      ...flexDetails,
      allowedDays: currentDays,
      flexRange: FlexRange.SpecificDays,
    });
  };

  const onTimeRangeChange = (allowedStartTime: string, allowedEndTime: string) => {
    setFlexDetails({
      ...flexDetails,
      timeOfDayRange: {
        start: allowedStartTime,
        end: allowedEndTime,
      },
    });
  };

  const [allowedStart, allowedEnd] = getAllowedStartAndEndTimes(
    flexDetails,
    minMaxRecommendedValues,
  );

  return (
    <Dialog
      open={isOpen}
      onClose={goBack}
      PaperProps={{ className: "cw-w-[350px] cw-rounded-xl" }}
      BackdropProps={{
        classes: { root: "cw-bg-[#000000] cw-bg-opacity-30" },
      }}
    >
      <div className="cw-flex-col cw-body-sm">
        <div className="cw-py-2 cw-px-4 cw-border-b cw-border-solid cw-border-muted cw-font-semibold cw-text-default">
          Flexible meeting settings
        </div>
        <div className="cw-body-base cw-p-4 cw-flex cw-flex-col cw-gap-4">
          {/* Notification */}
          {flexStatus && (
            <FlexStatusNotification status={meetingHoursConflict ? "Stuck" : flexStatus} />
          )}
          <div className="cw-flex cw-flex-col cw-gap-2">
            <div className="cw-body-sm cw-font-medium">
              Allow this meeting to automatically move:
            </div>
            <Select size="small" onChange={onFlexTypeChange} value={selectedFlexType}>
              <SelectOption icon={CalendarToday} value={FlexRange.Day}>
                Within the day
              </SelectOption>
              <SelectOption icon={DateRange} value={FlexRange.Week}>
                Within the week
              </SelectOption>
              <SelectOption icon={CalendarToday} value={FlexRange.SpecificDays}>
                Within specific workdays
              </SelectOption>
            </Select>
            {selectedFlexType === FlexRange.SpecificDays && (
              <div className="cw-flex cw-h-6 cw-justify-start">
                <DayCheckboxes
                  dayOnOffMap={convertArrayOfDayWeekToDayMap(
                    flexDetails.allowedDays ?? [],
                    availableWorkDays,
                  )}
                  isValid
                  onToggleDay={(day) => onToggleSpecificDay((day as unknown) as DayOfWeek)}
                />
              </div>
            )}
          </div>
          <div className="cw-flex cw-flex-col cw-gap-2">
            <div className="cw-body-sm cw-font-medium">Between these hours:</div>
            <FlexTimeRangeSelector
              allowedStart={allowedStart || ""}
              onChange={onTimeRangeChange}
              allowedEnd={allowedEnd || ""}
              minMaxRecommendedValues={minMaxRecommendedValues}
              showMeetingHoursConflict={meetingHoursConflict}
              allUsersWorkingHours={usersWorkingHours}
            />
          </div>
        </div>
        <div className="cw-flex cw-justify-between cw-border-t cw-border-solid cw-border-muted cw-py-2 cw-px-3">
          <Button variant="outlined" sentiment="neutral" size="xsmall" onClick={goBack}>
            Cancel
          </Button>
          <Button
            sentiment="positive"
            size="xsmall"
            onClick={() => {
              onFlexDetailsChange(flexDetails);
              goBack();
            }}
          >
            Done
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
