import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import { getRenderTimeZone } from "../../../util/time-zone.util";

export const CalendarDate = ({
  dateTime,
  onClick,
}: {
  dateTime: DateTime;
  onClick: (date: DateTime) => void;
}) => {
  const zone = getRenderTimeZone();
  const isToday = dateTime.hasSame(DateTime.local({ zone }), "day");
  const isPast = dateTime.startOf("day") < DateTime.now().startOf("day");

  const renderDay = dateTime.toLocaleString({ weekday: "short" });
  const renderDate = dateTime.toLocaleString({ day: "numeric" });
  const isRenderDateShort = renderDate.length === 1;

  const handleClick = () => onClick(dateTime);

  return (
    <div role="button" tabIndex={0} onClick={handleClick}>
      <div
        className={classNames(
          "cw-flex cw-items-center",
          "cw-w-full cw-rounded",
          "cw-h-[36px]",
          "hover:cw-bg-default-hover",
          "cw-text-center cw-body-base",
          isPast ? "cw-text-subtle" : "cw-text-default",
          isToday ? "cw-font-bold" : "",
          "cw-cursor-pointer ",
        )}
      >
        <div className="cw-grow">
          {renderDay}{" "}
          <span
            className={classNames(
              "cw-rounded-md",
              "cw-py-[2px]",
              isToday ? "cw-bg-destructive-emphasis cw-text-onEmphasis" : "",
              isToday ? (isRenderDateShort ? "cw-px-[7px]" : "cw-px-1") : "",
            )}
          >
            {renderDate}
          </span>
        </div>
      </div>
    </div>
  );
};
