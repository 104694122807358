import { ResponseStatusEnum } from "@clockwise/schema";
import React from "react";

import { Button, ButtonSet } from "@clockwise/design-system";
import { CheckCircleOutline, HelpOutline } from "@clockwise/design-system/icons";
import { CancelOutlined } from "@material-ui/icons";
import classNames from "classnames";

export const ECResponseStatus = ({
  onRSVPChange,
  rsvpStatus,
  disabled = false,
}: {
  onRSVPChange: (value: ResponseStatusEnum) => void;
  rsvpStatus: ResponseStatusEnum;
  disabled?: boolean;
}) => {
  const onRSVPYes = () => {
    onRSVPChange(ResponseStatusEnum.Accepted);
  };
  const onRSVPMaybe = () => {
    onRSVPChange(ResponseStatusEnum.Tentative);
  };
  const onRSVPNo = () => {
    onRSVPChange(ResponseStatusEnum.Declined);
  };

  const acceptedSelected = rsvpStatus === ResponseStatusEnum.Accepted;
  const maybeSelected = rsvpStatus === ResponseStatusEnum.Tentative;
  const declinedSelected = rsvpStatus === ResponseStatusEnum.Declined;
  const nothingSelected = !acceptedSelected && !maybeSelected && !declinedSelected;

  return (
    <div className="cw-flex cw-justify-between cw-items-center">
      <span className="cw-body-sm cw-font-medium">Going?</span>
      <ButtonSet variant="default">
        <span
          className={classNames(
            acceptedSelected || nothingSelected ? "cw-opacity-100" : "cw-opacity-40",
            "hover:cw-opacity-100",
          )}
        >
          <Button
            size="xsmall"
            aria-label={`RSVP Yes, ${acceptedSelected ? "selected" : "not selected"}`}
            variant="outlined"
            sentiment={acceptedSelected ? "positive" : "neutral"}
            onClick={onRSVPYes}
            startIcon={CheckCircleOutline}
            disabled={disabled}
          >
            Yes
          </Button>
        </span>
        <span
          className={classNames(
            declinedSelected || nothingSelected ? "cw-opacity-100" : "cw-opacity-40",
            "hover:cw-opacity-100",
          )}
        >
          <Button
            size="xsmall"
            aria-label={`RSVP No, ${declinedSelected ? "selected" : "not selected"}`}
            variant="outlined"
            sentiment={declinedSelected ? "positive" : "neutral"}
            onClick={onRSVPNo}
            startIcon={CancelOutlined}
            disabled={disabled}
          >
            No
          </Button>
        </span>
        <span
          className={classNames(
            maybeSelected || nothingSelected ? "cw-opacity-100" : "cw-opacity-40",
            "hover:cw-opacity-100",
          )}
        >
          <Button
            size="xsmall"
            aria-label={`RSVP Maybe, ${maybeSelected ? "selected" : "not selected"}`}
            variant="outlined"
            sentiment={maybeSelected ? "positive" : "neutral"}
            onClick={onRSVPMaybe}
            startIcon={HelpOutline}
            disabled={disabled}
          >
            Maybe
          </Button>
        </span>
      </ButtonSet>
    </div>
  );
};
