import { Button, ButtonGroup } from "@clockwise/design-system";
import { ChevronLeft } from "@clockwise/design-system/icons";
import { ChevronRight } from "@clockwise/icons";
import classNames from "classnames";
import React, { useRef } from "react";
import { CalendarMode } from "../calendar-day-event";

export type HighlightedNavButton = "previous" | "next" | null;

type Props = {
  mode: CalendarMode;
  onNext: () => void;
  onPrevious: () => void;
  onToday: () => void;
  highlightedButton: HighlightedNavButton;
  highlightedTooltip:
    | (({ anchorEl }: { anchorEl: React.RefObject<HTMLDivElement> }) => React.JSX.Element)
    | null;
};

export const CalendarStepNavigation = ({
  mode,
  onPrevious,
  onToday,
  onNext,
  highlightedButton,
  highlightedTooltip: HighlightedTooltip,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const overlayStyles = classNames(
    "cw-absolute cw-top-0 cw-w-[30px] cw-h-[29px] cw-bg-neutral-emphasis cw-opacity-75",
    "cw-border cw-border-solid cw-border-default cw-shadow-timeSuggestionCard",
  );

  const TooltipComponenet = () =>
    HighlightedTooltip ? <HighlightedTooltip anchorEl={ref} /> : null;

  return (
    <div className="cw-relative" ref={ref}>
      <ButtonGroup
        aria-label="calendar step navigation"
        sentiment="neutral"
        size="xsmall"
        variant="outlined"
      >
        <Button
          startIcon={ChevronLeft}
          aria-label={`Go to previous ${mode}`}
          onClick={onPrevious}
          variant="text"
        ></Button>

        <Button aria-label="Go to today" onClick={onToday} variant="text">
          Today
        </Button>

        <Button
          startIcon={ChevronRight}
          aria-label={`Go to next ${mode}`}
          onClick={onNext}
          variant="text"
        ></Button>
      </ButtonGroup>
      {highlightedButton === "previous" && (
        <div className="cw-animate-slowPulse">
          <div className={classNames(overlayStyles, "cw-rounded-l-md")} />
        </div>
      )}
      {highlightedButton === "next" && (
        <div className="cw-animate-slowPulse">
          <div className={classNames(overlayStyles, "cw-rounded-r-md cw-left-[82px]")} />
        </div>
      )}
      {HighlightedTooltip && <TooltipComponenet />}
    </div>
  );
};
