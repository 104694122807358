import { EventForFlexibility } from "#webapp/src/components/flexible-meetings/util/parseFlexibility.js";

export type FlexMeetingEventMap = Record<string, EventForFlexibility>;

export const getFlexMeetingMapFromSuggestionList = (suggestions: EventForFlexibility[]) => {
  const flexMeetingMap: FlexMeetingEventMap = {};

  suggestions.forEach((suggestion) => {
    flexMeetingMap[suggestion.id] = suggestion;
  });

  return flexMeetingMap;
};

export const getEventListFromFlexMeetingMap = (eventMap: FlexMeetingEventMap) => {
  return Object.keys(eventMap).map((key) => eventMap[key]);
};
