import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const LoggedNavigate = ({ to }: { to: string }) => {
  const location = useLocation();
  const track = useTracking();

  useEffect(() => {
    track(TrackingEvents.APP_NAV.REDIRECT, { from: location.pathname, to });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Navigate to={to} replace />;
};
