import { EventCategory, EventCategoryType } from "@clockwise/schema/v2";
import { useGatewayMutation, useGatewayQuery } from "../network/apollo/gateway-provider";
import { useEcosystem } from "../util/ecosystem";
import { getCalendarColors, getCategoryLabels } from "../util/event-category-coloring";
import {
  EventCategoryDocument,
  EventUpdateCategoryDocument,
} from "./__generated__/EventCategory.v2.generated";

export type UseEventCategoryInput = {
  calendarId: string;
  eventId: string;
  useQuery: typeof useGatewayQuery;
  useMutation: typeof useGatewayMutation;
};

export type Category = {
  value: EventCategoryType;
  color: string | null;
  label: string;
};

type UseEventCategory = ({
  calendarId,
  eventId,
  useQuery,
  useMutation,
}: UseEventCategoryInput) => {
  category: Category;
  categoryOptions: Category[];
  update: (value: Category["value"]) => void;
  updating: boolean;
  loading: boolean;
};

export const useEventCategory: UseEventCategory = ({
  calendarId,
  eventId,
  useQuery,
  useMutation,
}) => {
  const [updateEventCategory, { loading: mutationLoading }] = useMutation(
    EventUpdateCategoryDocument,
  );

  const { data, refetch: refetchCategory, loading } = useQuery(EventCategoryDocument, {
    variables: {
      id: eventId,
      calendarId,
    },
    skip: !calendarId || !eventId,
  });

  const ecosystem = useEcosystem();
  const calendarColors = getCalendarColors(ecosystem);

  const mapEventCategoryToCategory = (category: EventCategory): Category => ({
    value: category.type,
    color: category.color ? calendarColors[category.color]?.foreground : null,
    label: getCategoryLabels(category.type).label,
  });

  const dataCategory = data?.event?.category ?? {
    __typename: "EventCategory",
    type: EventCategoryType.Other,
  };

  const category = mapEventCategoryToCategory(dataCategory);
  const categoryOptions = data?.currentUser?.eventCategories.map(mapEventCategoryToCategory) ?? [];

  const update = (value: Category["value"]) => {
    updateEventCategory({
      variables: {
        input: {
          externalEventId: eventId,
          category: value,
        },
      },
      onCompleted: () => {
        void refetchCategory();
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateEventCategoryType: {
          __typename: "Event",
          id: eventId,
          externalEventId: eventId,
          category: { type: value, color: null, __typename: "EventCategory" },
        },
      },
    });
  };

  return { category, categoryOptions, update, updating: !!mutationLoading, loading: !!loading };
};
