import {
  EventContainer,
  NamedEvent,
  UnnamedEvent,
} from "@clockwise/web-commons/src/components/feature-illustration/";
import { ForkKnife } from "phosphor-react";
import React from "react";

export const LunchEvent = () => (
  <NamedEvent duration={60} Icon={ForkKnife} startTime="11:15am" title="Lunch" />
);

const LunchHoldIllustration = () => {
  return (
    <EventContainer>
      <UnnamedEvent duration={20} />
      <LunchEvent />
      <UnnamedEvent duration={20} />
    </EventContainer>
  );
};

export default LunchHoldIllustration;
