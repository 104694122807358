import { HoverCard, Switch } from "@clockwise/design-system";
import { bg_positive_emphasis, fg_muted, fg_warning_muted } from "@clockwise/design-system/tokens";
import {
  CheckCircleFilled,
  ChevronRight,
  PauseCircleFilled,
  WarningFilled,
} from "@clockwise/icons";
import { FlexRange } from "@clockwise/schema/v2";
import { FlexOutlineIcon } from "@clockwise/web-commons/src/components/module-flex-settings/atoms/FlexOutlineIcon";
import { FlexSettingsModule } from "@clockwise/web-commons/src/components/module-flex-settings/FlexSettingsModule";
import { FlexibleStatusSimplified } from "@clockwise/web-commons/src/components/module-flex-settings/types";
import { DayOnOffMap } from "@clockwise/web-commons/src/ui/working-hours";
import classNames from "classnames";
import { noop } from "lodash";
import { default as React } from "react";
import { ListItemButton } from "../../calendar-popover/atoms/ListItemButton";

export const EventFlexibilityStatusIcon = ({
  flexibleStatus,
}: {
  flexibleStatus: FlexibleStatusSimplified;
}) => {
  switch (flexibleStatus) {
    case "paused":
    case "paywall":
      return (
        <PauseCircleFilled
          fill={fg_warning_muted}
          style={{ color: fg_warning_muted }}
          className="cw-h-4 cw-w-4"
        />
      );
    case "stuck":
      return (
        <WarningFilled
          fill={fg_warning_muted}
          style={{ color: fg_warning_muted }}
          className="cw-h-4 cw-w-4"
        />
      );
    case "pinned":
    case "default":
      return (
        <CheckCircleFilled
          fill={bg_positive_emphasis}
          style={{ color: bg_positive_emphasis }}
          className="cw-h-4 cw-w-4"
        />
      );
  }
};

export const EventFlexibilityListItem = ({
  flexibleEditable,
  flexible,
  onChangeFlexible,
  updatingFlexible,
  flexibleStatus,
}: {
  flexibleEditable: boolean;
  flexible: boolean;
  onChangeFlexible: (flexible: boolean) => void;
  updatingFlexible: boolean;
  flexibleStatus: FlexibleStatusSimplified;
}) => {
  const onEnable = () => {
    if (flexible) {
      return;
    }
    const paywall = flexibleStatus === "paywall";
    const canUpdate = !paywall && !updatingFlexible && flexibleEditable;
    if (canUpdate) {
      onChangeFlexible(!flexible);
    }
  };

  return (
    <ListItemButton startIcon={FlexOutlineIcon} onClick={noop} disabled={!flexibleEditable}>
      <div
        className="cw-flex cw-flex-row cw-items-center cw-justify-between cw-w-full"
        onClick={() => onEnable()}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnable();
          }
        }}
      >
        <div>{flexible ? "Flexibility settings" : "Flexible event"}</div>
        <div className="cw-flex cw-items-center cw-gap-2">
          {flexible ? (
            <>
              <EventFlexibilityStatusIcon flexibleStatus={flexibleStatus} />
              <ChevronRight fill={fg_muted} style={{ color: fg_muted }} className="cw-h-4 cw-w-4" />
            </>
          ) : (
            <Switch
              checked={flexible}
              disabled={updatingFlexible || !flexibleEditable}
              sentiment="neutral"
              size="xs"
            />
          )}
        </div>
      </div>
    </ListItemButton>
  );
};

export const EventFlexibilityHoverCard = ({
  flexible,
  flexibleEditable,
  flexibleDayOnOffMap,
  flexibleEnd,
  flexibleRange,
  flexibleStart,
  flexibleStatus,
  onChangeFlexible,
  onChangeFlexibleDays,
  onChangeFlexibleRange,
  onChangeFlexibleResume,
  onChangeFlexibleTimeRange,
  updatingFlexible,
  attendeeBoundStart,
  attendeeBoundEnd,
  children,
}: {
  flexible: boolean;
  flexibleEditable: boolean;
  flexibleDayOnOffMap: DayOnOffMap;
  flexibleEnd: string;
  flexibleRange: FlexRange;
  flexibleStart: string;
  flexibleStatus: FlexibleStatusSimplified;
  onChangeFlexible: (flexible: boolean) => void;
  onChangeFlexibleDays: (dayOnOffMap: DayOnOffMap) => void;
  onChangeFlexibleRange: (flexibleRange: FlexRange) => void;
  onChangeFlexibleResume: () => void;
  onChangeFlexibleTimeRange: (start: string, end: string) => void;
  updatingFlexible: boolean;
  attendeeBoundStart: string | null;
  attendeeBoundEnd: string | null;
  children: React.ReactNode;
}) => {
  // NOTE: The HoverCard itself is invisible. It's providing a mouse-navigatable buffer between
  // the button and the (actual visible) menu container. Without it, there's a risk the HoverCard
  // disappears while the user is mousing over to it.
  return (
    <HoverCard
      position="right"
      classNames={{
        dropdown: "cw-pr-4 cw-bg-none cw-bg-transparent cw-border-0 cw-shadow-none",
      }}
      offset={0}
    >
      <HoverCard.Target>
        <div>{children}</div>
      </HoverCard.Target>
      {flexible && flexibleEditable ? (
        <HoverCard.Dropdown>
          <div
            cw-id="event-flexibility-portal"
            className={classNames(
              "cw-rounded-xl cw-w-[210px] cw-overflow-auto cw-body-sm cw-text-12 cw-bg-default cw-shadow-selectPopup",
              "cw-py-4 cw-px-3",
            )}
          >
            <FlexSettingsModule
              attendeeBoundEnd={attendeeBoundEnd}
              attendeeBoundStart={attendeeBoundStart}
              flexible={flexible}
              flexibleDayOnOffMap={flexibleDayOnOffMap}
              flexibleEditable={flexibleEditable}
              flexibleEnd={flexibleEnd}
              flexibleRange={flexibleRange}
              flexibleStart={flexibleStart}
              flexibleStatus={flexibleStatus}
              onChangeFlexible={onChangeFlexible}
              onChangeFlexibleDays={onChangeFlexibleDays}
              onChangeFlexibleRange={onChangeFlexibleRange}
              onChangeFlexibleResume={onChangeFlexibleResume}
              onChangeFlexibleTimeRange={onChangeFlexibleTimeRange}
              updating={updatingFlexible}
              noIcon
            />
          </div>
        </HoverCard.Dropdown>
      ) : null}
    </HoverCard>
  );
};
