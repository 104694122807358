import { Button, Checkbox, Select, SelectOption, TextField } from "@clockwise/design-system";
import { EditCalendar } from "@clockwise/icons";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

interface TemplateButton {
  template: string;
  label: string;
  example: string;
}

export interface LinkTitleTemplateProps {
  includeSparkleEmoji: boolean;
  currentTemplate: string;
  onChange: (value: string) => void;
  onChangeIncludeEmoji: (value: boolean) => void;
  linkName: string;
  organizerName: string;
  organizerEmail: string;
  attendees: string;
  groupOrSingleUse: boolean;
}

// These are the templates that the BE changed links to when we turned on the feature via a one time migration.
export const DEFAULT_TEMPLATE = "{{scheduler.display_name}} and {{organizer.display_name}}";
const DEFAULT_REVERSED_TEMPLATE = "{{organizer.display_name}} and {{scheduler.display_name}}";
export const GROUP_OR_SINGLE_USE_TEMPLATE = "{{link.name}} - {{scheduler.display_name}}";

// These are the templates available to the user in the Select.
// We can add more here that we want to offer to the user.
const premadeTemplates: Omit<TemplateButton, "example">[] = [
  {
    template: DEFAULT_TEMPLATE, // Default for non-group links.
    label: "[Scheduler Name] and [Organizer Name]",
  },
  {
    template: DEFAULT_REVERSED_TEMPLATE,
    label: "[Organizer Name] and [Scheduler Name]",
  },
  {
    template: GROUP_OR_SINGLE_USE_TEMPLATE, // Default for group links.
    label: "[Link Name] - [Scheduler Name]",
  },
  { template: "{{link.name}}", label: "[Link Name]" },
];

// Replace both [] and {{}} matching template variables with their example values.
// The editor adds things with [], but the user can still enter {{}} if for some reason they want to.
const generateExample = (template: string, templateVariables: TemplateButton[]) => {
  let example = template;
  templateVariables.forEach((templateVariable) => {
    example = example.replaceAll(templateVariable.label, templateVariable.example);
  });
  templateVariables.forEach((templateVariable) => {
    example = example.replaceAll(templateVariable.template, templateVariable.example);
  });
  return example;
};

// Editor uses [] for template variables.
const customTemplateCurlyToSquare = (template: string, templateVariables: TemplateButton[]) => {
  let toLabels = template;
  templateVariables.forEach((templateVariable) => {
    toLabels = toLabels.replaceAll(templateVariable.template, templateVariable.label);
  });
  return toLabels;
};

export const LinkTitleTemplate = ({
  currentTemplate,
  onChange,
  includeSparkleEmoji,
  onChangeIncludeEmoji,
  linkName,
  organizerName,
  organizerEmail,
  attendees,
  groupOrSingleUse,
}: LinkTitleTemplateProps) => {
  // Create and save in a variable so that we ca use linkName, organizerName, etc. in the useMemo below.
  // The example fields are used to store
  const templateVariables: TemplateButton[] = useMemo(
    () => [
      { template: "{{link.name}}", label: "[Link Name]", example: linkName },
      {
        template: "{{organizer.display_name}}",
        label: "[Link Owner Name]",
        example: organizerName,
      },
      { template: "{{organizer.email}}", label: "[Link Owner Email]", example: organizerEmail },
      {
        template: "{{scheduler.display_name}}",
        label: "[Scheduler Name]",
        example: "Scheduler Name",
      },
      {
        template: "{{scheduler.email}}",
        label: "[Scheduler Email]",
        example: "example@example.com",
      },
      {
        template: "{{attendees}}",
        label: "[Attendees]",
        example: attendees,
      },
    ],
    [attendees, linkName, organizerEmail, organizerName],
  );

  const [previousDefaultNotReversed, setPreviousDefaultNotReversed] = useState(true);

  // Important, only run this on change of groupOrSingleUse. Keep the dependency array to just that variable.
  // Using useUpdateEffect to avoid resetting title template to default when component first mounts.
  useUpdateEffect(() => {
    if (
      groupOrSingleUse &&
      (currentTemplate === DEFAULT_TEMPLATE || currentTemplate === DEFAULT_REVERSED_TEMPLATE)
    ) {
      onChange(GROUP_OR_SINGLE_USE_TEMPLATE);
      setSelectedTemplate(GROUP_OR_SINGLE_USE_TEMPLATE);
    } else if (!groupOrSingleUse && currentTemplate === GROUP_OR_SINGLE_USE_TEMPLATE) {
      const newTemplate = previousDefaultNotReversed ? DEFAULT_TEMPLATE : DEFAULT_REVERSED_TEMPLATE;
      onChange(newTemplate);
      setSelectedTemplate(newTemplate);
    }
  }, [groupOrSingleUse]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentTemplateSquares = useMemo(
    () => customTemplateCurlyToSquare(currentTemplate, templateVariables),
    [currentTemplate, templateVariables],
  );

  // Save to local storage to persist across sessions in case user changes back to custom later on.
  // This is just a convenience for the user.
  const [customTitleLS, setCustomTitleLS] = useLocalStorage(
    "customTitleTemplate",
    customTemplateCurlyToSquare("{{link.name}}", templateVariables), // Could just use [Link Name] here,
    // but this is to show how the conversion function gets used.
  );

  // BE stores variables as {{}}, so convert all [] variables back to {{}} variables before sending to BE.
  const onChangeSaveCustomConvertSquareBackToCurly = useCallback(
    (template: string) => {
      let toTemplate = template;
      templateVariables.forEach((templateVariable) => {
        toTemplate = toTemplate.replaceAll(templateVariable.label, templateVariable.template);
      });
      onChange(toTemplate);
    },
    [onChange, templateVariables],
  );

  const [selectedTemplate, setSelectedTemplate] = useState(
    premadeTemplates.find((t) => t.template === currentTemplate)?.template || "custom",
  );

  const textFieldRef = React.useRef<HTMLInputElement>(null);

  const handleSelectChange = (value: string) => {
    setSelectedTemplate(value);
    if (value === DEFAULT_TEMPLATE) {
      setPreviousDefaultNotReversed(true);
    }
    if (value === DEFAULT_REVERSED_TEMPLATE) {
      setPreviousDefaultNotReversed(false);
    }
    if (value === "custom") {
      onChangeSaveCustomConvertSquareBackToCurly(customTitleLS);
    } else {
      onChange(value);
    }
  };

  const textFieldCursorPosition = useRef<number | null>(null);

  // If handleCustomChange has set the cursor position, use that position to move the cursor
  // when currentTemplateSquares changes. This has to happen in a useEffect to give the text field time to render first.
  useEffect(() => {
    if (textFieldRef.current && textFieldCursorPosition.current) {
      textFieldRef.current.selectionStart = textFieldCursorPosition.current;
      textFieldRef.current.selectionEnd = textFieldCursorPosition.current; // Must set both start and end.
      textFieldCursorPosition.current = null;
    }
  }, [currentTemplateSquares]);

  const handleCustomChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCustom = event.target.value;
      onChangeSaveCustomConvertSquareBackToCurly(newCustom);
      setCustomTitleLS(newCustom);
    },
    [onChangeSaveCustomConvertSquareBackToCurly, setCustomTitleLS],
  );

  const handleClickVariable = useCallback(() => {
    if (!textFieldRef.current || textFieldRef.current !== document.activeElement) {
      return;
    }

    const cursorPosition = textFieldRef.current.selectionStart;

    templateVariables.forEach((templateVariable) => {
      let templateVariableIndex = -1;
      while (
        // Iterate through all instances of the variable in the string.
        (templateVariableIndex = currentTemplateSquares.indexOf(
          templateVariable.label,
          templateVariableIndex + 1,
        )) !== -1
      ) {
        if (
          cursorPosition &&
          cursorPosition > templateVariableIndex &&
          cursorPosition < templateVariableIndex + templateVariable.label.length
        ) {
          if (textFieldRef.current) {
            textFieldRef.current.selectionStart = templateVariableIndex;
            textFieldRef.current.selectionEnd =
              templateVariableIndex + templateVariable.label.length;
          }
        }
      }
    });
  }, [currentTemplateSquares, templateVariables]);

  const handleButtonClick = (value: string) => {
    let newCustom = currentTemplateSquares;
    if (textFieldRef.current) {
      let cursorPosition = textFieldRef.current.selectionStart;

      if (cursorPosition === null) {
        return; // Probably shouldn't happen.
      }

      // Check if cursor is inside a template variable. If so, move cursor to after the variable.
      templateVariables.forEach((templateVariable) => {
        const templateVariableIndex = newCustom.indexOf(templateVariable.label);
        if (templateVariableIndex === -1) {
          return;
        }
        if (
          cursorPosition &&
          cursorPosition > templateVariableIndex &&
          cursorPosition < templateVariableIndex + templateVariable.label.length
        ) {
          // Move cursor to position after current variable.
          cursorPosition = templateVariableIndex + templateVariable.label.length;
        }
      });

      const hasSpaceBefore =
        newCustom[cursorPosition - 1] === " " || !newCustom[cursorPosition - 1];
      const hasSpaceAfter = newCustom[cursorPosition + 1] === " " || !newCustom[cursorPosition + 1];

      // Splice in the template variable at the cursor position, adding spaces if needed.
      newCustom =
        newCustom.slice(0, cursorPosition) +
        (hasSpaceBefore ? "" : " ") +
        value +
        (hasSpaceAfter ? "" : " ") +
        newCustom.slice(cursorPosition);

      // Set text field cursor to new position, which is after the inserted value
      textFieldRef.current.focus();
      // Put cursor to the end of the inserted variable.
      textFieldCursorPosition.current = cursorPosition + value.length + 1;
    }
    onChangeSaveCustomConvertSquareBackToCurly(newCustom);
    setCustomTitleLS(newCustom);
  };

  const titlePreview = generateExample(currentTemplate, templateVariables);

  return (
    <div className="cw-flex cw-flex-col cw-items-start cw-gap-3">
      <div className="cw-w-80 xxs:cw-w-full cw-body-lg cw-flex cw-flex-wrap cw-items-center cw-justify-start cw-gap-1">
        {/* h-36px keeps height same as Select when Select wraps down a line */}
        <div className="cw-flex cw-h-[36px] cw-flex-col cw-justify-center cw-mr-2">
          <EditCalendar
            className={classNames("cw-w-5 cw-h-5 cw-text-muted cw-mr-1 cw-self-start")}
          />
        </div>
        <span className="cw-mr-2">Book meetings with title</span>
        <Select value={selectedTemplate} onChange={handleSelectChange}>
          {premadeTemplates.map((value) => (
            <SelectOption key={value.template} value={value.template}>
              {value.label}
            </SelectOption>
          ))}
          <SelectOption value="custom">Custom</SelectOption>
        </Select>
      </div>

      {selectedTemplate === "custom" && (
        <>
          <div className="cw-flex cw-flex-col cw-items-start cw-mt-2 cw-w-full cw-gap-4 cw-max-w-lg">
            <TextField
              value={currentTemplateSquares}
              fullWidth
              onChange={handleCustomChange}
              label="Custom Title"
              ref={textFieldRef}
              onMouseUp={handleClickVariable}
            />
            <div className="">
              <div className="cw-body-base cw-mb-0.5">Available variables, click to insert</div>
              <div className="cw-flex cw-flex-wrap">
                {templateVariables.map((templateButton) => (
                  <div className="cw-m-0.5" key={templateButton.label}>
                    <Button
                      key={templateButton.label}
                      variant="outlined"
                      color="primary"
                      size="xsmall"
                      onClick={() => handleButtonClick(templateButton.label)}
                    >
                      {templateButton.label}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="cw-mt-1 cw-bg-info cw-rounded cw-p-3 cw-font-body">
        <div>Event will be scheduled as:</div>
        <div className="cw-text-muted cw-mt-2 cw-break-words">
          {includeSparkleEmoji ? "✨" : ""} {titlePreview}
        </div>
      </div>
      <Checkbox
        checked={includeSparkleEmoji}
        onChange={onChangeIncludeEmoji}
        label="Include sparkle emoji"
      />
    </div>
  );
};

// This hook was deprecated in usehooks-ts v2 and removed in v3
// persisting here to allow for update of usehooks-ts
// however we should consider updating this logic
/** @deprecated Unnecessary abstraction, prefer built-in React hooks. see: https://usehooks-ts.com/migrate-to-v3 */
function useUpdateEffect(effect: any, deps: any) {
  const isFirst = useIsFirstRender();
  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
  }, deps);
}

/** @deprecated Not comply with the React functional mindset. see: https://usehooks-ts.com/migrate-to-v3 */
function useIsFirstRender() {
  const isFirst = useRef(true);
  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }
  return isFirst.current;
}
