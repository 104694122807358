import { Day } from "@clockwise/schema";
import * as React from "react";

import {
  CopyButtonStyle,
  DayCheckboxes,
  DayOnOffMap,
  DaySetting,
  MeetingOrWorkingHours,
  SlotRangesMap,
  SlotRangeWrapper,
} from "../../working-hours";

export interface WeekSettingProps {
  slotRangesMap: SlotRangesMap;
  currentDayOnOffMap: DayOnOffMap;
  copyButtonStyle: CopyButtonStyle | null;
  isValidDayMap: boolean;
  isMeetingOrWorkingHours: MeetingOrWorkingHours;
  onSlotChange: (day: Day, updatedSlots: SlotRangeWrapper) => void;
  onClickCopy: (day: Day) => void;
  onToggleDay: (day: Day) => void;
}

export const WeekSetting = ({
  slotRangesMap,
  currentDayOnOffMap,
  copyButtonStyle,
  isMeetingOrWorkingHours,
  isValidDayMap,
  onToggleDay,
  onClickCopy,
  onSlotChange,
}: WeekSettingProps) => {
  const renderDaySetting = () => {
    const firstDayOn = Object.keys(currentDayOnOffMap).find(
      (day) => currentDayOnOffMap[day as Day],
    );

    return Object.keys(currentDayOnOffMap).map((day) => {
      if (!currentDayOnOffMap[day as Day]) {
        return null;
      }

      return (
        <DaySetting
          key={day}
          label={day}
          copyButtonStyle={day === firstDayOn ? copyButtonStyle : null}
          isMeetingOrWorkingHours={isMeetingOrWorkingHours}
          slotRanges={slotRangesMap[day]}
          onSlotChange={(updatedSlots) => onSlotChange(day as Day, updatedSlots)}
          onClickCopy={() => onClickCopy(day as Day)}
        />
      );
    });
  };

  return (
    <div>
      <DayCheckboxes
        dayOnOffMap={currentDayOnOffMap}
        onToggleDay={onToggleDay}
        isValid={isValidDayMap}
      />
      <div className="cw-mt-4">{renderDaySetting()}</div>
    </div>
  );
};
