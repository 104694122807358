import { useLastTabFocusDate } from "#webapp/src/hooks/useLastTabFocusDate";
import { hashHistory } from "#webapp/src/util/history.util";
import {
  useReadCalendar,
  useUpdateCalendar,
} from "@clockwise/web-commons/src/util/CalendarContext";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CALENDAR_HASH } from "../../constants";
import { getCalendarDatesFromDefault } from "../util/getCalendarDatesFromDefault";
import { getCalendarDatesFromRoute } from "../util/getCalendarDatesFromRoute";
import { getCalendarDatesFromState } from "../util/getCalendarDatesFromState";
import { getCalendarDatesFromStorage } from "../util/getCalendarDatesFromStorage";
import { getCalendarHashFromState } from "../util/getCalendarHashFromState";

/**
 * Custom hook that
 *   1) persists the calendar state to the route hash & localstorage and
 *   2) reads the calendar state from the route hash or localstorage.
 *
 * example: #/week/2024/06/14
 */
type UsePersistCalendarHashProps = {
  setInitialDateAndMode?: boolean;
};

export const usePersistCalendarHash = ({
  setInitialDateAndMode = true,
}: UsePersistCalendarHashProps) => {
  const location = useLocation();
  const calendarState = useReadCalendar();
  const calendarDispatch = useUpdateCalendar();

  const setCalendarStateFromPersistedStores = useCallback(() => {
    const [mode, focusDate] =
      getCalendarDatesFromRoute(location) ||
      getCalendarDatesFromStorage(sessionStorage) ||
      getCalendarDatesFromState(calendarState) ||
      getCalendarDatesFromDefault();

    calendarDispatch({
      type: mode === "day" ? "jumpTo-date" : "jumpTo-weekOf",
      payload: focusDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDispatch, location]);

  // On Component Mount: Update calendar state
  // so that or hash is correct on load
  useEffect(() => {
    if (setInitialDateAndMode) {
      setCalendarStateFromPersistedStores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On last focused date !== current date: Update calendar state
  // so that mode only hash ("#/week and #/day") stay current with current date
  useLastTabFocusDate({ onChange: setCalendarStateFromPersistedStores });

  // On Calendar Change: Update Route Hash
  useEffect(() => {
    const calendarHash = getCalendarHashFromState(calendarState);
    hashHistory.replace(calendarHash);
  }, [calendarState]);

  // On Calendar Change: Update Storage
  useEffect(() => {
    const calendarHash = getCalendarHashFromState(calendarState);
    sessionStorage.setItem(CALENDAR_HASH, calendarHash);
  }, [calendarState]);
};
