import { ControlPoint } from "@clockwise/design-system/icons";
import classNames from "classnames";
import React from "react";

type Props = {
  onClick: (isGroupLink: boolean) => void;
  variant?: "single-member" | "group" | "single-use";
};

export default function CreateLinkCard({ onClick, variant = "single-member" }: Props) {
  return (
    <button
      className={classNames(
        "cw-flex cw-items-center cw-justify-center cw-cursor-pointer cw-bg-neutral hover:cw-bg-neutral-hover cw-rounded-md cw-border-solid cw-border-2 cw-border-neutral-interactable hover:cw-border-neutral-interactable-hover cw-w-full",
        variant === "group" ? "cw-min-h-[228px]" : "cw-min-h-[178px]",
      )}
      onClick={() => onClick(variant === "group")}
      cw-id="create-link-card"
    >
      <div className="cw-flex cw-items-center cw-gap-2 cw-heading-base">
        <ControlPoint />
        <span>Create a {["single-use", "group"].includes(variant) ? `${variant} ` : ""}link</span>
      </div>
    </button>
  );
}
