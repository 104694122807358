import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";
import { ECTime } from "../molecules/ECTime";
import { isValidTimeRange } from "../utils/eventCardTime.util";
import { EventFlexibility } from "./EventFlexibility";
import { SchedulingEventFlexibility } from "./SchedulingEventFlexibility";

export const ERROR_MSG = {
  ALL_DAY: "All day events must have different start and end dates",
  TIME_RANGE: "Start time must be before end time.",
};

export const TimeIntervalAndFlexSelector = ({
  startTime,
  endTime,
  isAllDay,
  readOnly,
  onChangeTime,
  onError,
  eventCalendarId,
  eventId,
}: {
  startTime?: DateTime;
  endTime?: DateTime;
  isAllDay: boolean;
  readOnly: boolean;
  onChangeTime: (newStart?: DateTime, newEnd?: DateTime) => void;
  onError?: (error: string | null) => void;
  eventCalendarId?: string;
  eventId?: string;
}) => {
  const [timeError, setTimeError] = useState<string | null>(null);

  const showExistingFlexDetails = !!eventCalendarId && !!eventId;

  const handleError = useCallback(
    (error: string | null) => {
      setTimeError(error);
      onError?.(error);
    },
    [onError],
  );

  useEffect(() => {
    if (isAllDay && startTime && endTime) {
      const isSameDay = startTime.hasSame(endTime, "day");
      if (isSameDay) {
        handleError(ERROR_MSG.ALL_DAY);
      }
    }
  }, [endTime, handleError, isAllDay, startTime]);

  const validateAndCallback = (newStart?: DateTime, newEnd?: DateTime) => {
    if (!newStart || !newEnd) {
      return;
    }

    if (isValidTimeRange(newStart, newEnd)) {
      handleError(null);
      onChangeTime(newStart, newEnd);
    } else {
      handleError(ERROR_MSG.TIME_RANGE);
    }
  };

  return (
    <>
      <EventElementsWrap name="timeAndFlex">
        <div className="cw-flex cw-flex-col cw-gap-2 cw-w-full">
          {showExistingFlexDetails ? (
            <EventFlexibility eventId={eventId} calendarId={eventCalendarId} />
          ) : (
            <SchedulingEventFlexibility />
          )}
          <ECTime
            startTime={startTime}
            endTime={endTime}
            readOnly={readOnly}
            onChange={validateAndCallback}
            isAllDay={isAllDay}
            timeError={timeError}
          />
        </div>
      </EventElementsWrap>
    </>
  );
};
