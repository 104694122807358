import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ShareProposalMutationVariables = Types.Exact<{
  input: Types.SharedProposalInput;
}>;


export type ShareProposalMutation = { __typename: 'Mutation', shareProposal: { __typename: 'ShareProposalPayload', sharedProposalLink: string } | null };


export const ShareProposalDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ShareProposal"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SharedProposalInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shareProposal"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sharedProposalLink"}}]}}]}}]} as unknown as DocumentNode<ShareProposalMutation, ShareProposalMutationVariables>;