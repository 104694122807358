import React from "react";

export const HistoryOff = () => (
  <div>
    <div className="cw-space-y-1 cw-border cw-border-solid cw-border-default cw-rounded cw-p-2">
      <div className="cw-body-sm cw-font-bold">
        History isn’t available for this event because it has never been managed by Clockwise.
      </div>
      <div className="cw-caption">
        When an event is made flexible, event modifications will display here.
      </div>
    </div>
  </div>
);
