//////////////////
// IMPORTS
//////////////////
// schema
// material-ui
import { Button, ButtonSet, Typography } from "@clockwise/design-system";
import { Add } from "@clockwise/design-system/icons";
// components
// styles
import { greys } from "@clockwise/web-commons/src/styles/color.styles";
// libraries
import { TeamJoinCreateDialog } from "#webapp/src/components/team-join-create-dialog";
import { WebSettingsContainer } from "#webapp/src/components/web-settings/web-settings-container";
import React from "react";
import { QueryRenderer, ReadyState } from "react-relay";
// state
import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
// util
import * as ISchema from "#webapp/src/__schema__";
import { page, PageEvents, track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { handleReadyStateError } from "#webapp/src/util/relay.util";
import {
  createSettingsViewId,
  TeamsSections,
  WebSettingsContext,
  WebSettingsSections,
} from "../web-settings";
// web-settings-create-join-team-renderer imports
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { query } from "./WebSettingsCreateJoinTeamRelay.gql";

export interface IRendererProps {
  orgRelayId: string;
  shouldReloadAfterCreateOrJoin?: boolean;
}

export interface IRendererState {
  key: number;
  loading: boolean;
  joinCreateDialogOpen: boolean;
  joinCreateInitialView: "join" | "create";
}

//////////////////
// COMPONENT
//////////////////
export class WebSettingsCreateJoinTeamRelay extends React.Component<
  IRendererProps,
  IRendererState
> {
  // ~-~-~-~-~-~-~-
  // Setup
  // ~-~-~-~-~-~-~-
  static contextType = WebSettingsContext;
  declare context: React.ContextType<typeof WebSettingsContext>;

  private shouldForce = true;
  private cachedViewer?: ISchema.IViewer;
  private cachedOrg?: ISchema.IOrg;

  constructor(props: IRendererProps) {
    super(props);
    this.state = {
      key: 0,
      loading: false,
      joinCreateDialogOpen: false,
      joinCreateInitialView: "create",
    };
  }

  // ~-~-~-~-~-~-~-
  // Life-Cycle
  // ~-~-~-~-~-~-~-
  public componentDidMount() {
    page(PageEvents.WEB_SETTINGS_CREATE_JOIN_TEAM);
  }

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  // private refetch = () => {
  //   this.shouldForce = true;
  //   this.setState({ key: this.state.key + 1 });
  // };

  // ~-~-~-~-~-~-~-
  // Handlers
  // ~-~-~-~-~-~-~-
  private onClickEmptyStateCreate = () => {
    this.setState({ joinCreateDialogOpen: true, joinCreateInitialView: "create" });
    track(TrackingEvents.TEAMS.EMPTY_STATE_CLICKED_CREATE_TEAM_BUTTON);
  };

  private onClickEmptyStateJoin = () => {
    this.setState({ joinCreateDialogOpen: true, joinCreateInitialView: "join" });
    track(TrackingEvents.TEAMS.EMPTY_STATE_CLICKED_JOIN_TEAM_BUTTON);
  };

  private onCloseCreateJoinTeam = (teamId?: string) => {
    this.setState({ joinCreateDialogOpen: false });
    this.context.webSettingsRefetch();
    this.context.onChangeSettingsView(
      createSettingsViewId(WebSettingsSections.Teams, TeamsSections.Team, teamId),
    );
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  public render() {
    const environment = getCurrentEnvironment();

    return (
      <QueryRenderer
        key={this.state.key}
        environment={environment}
        query={query}
        variables={{
          orgRelayId: this.props.orgRelayId,
        }}
        cacheConfig={{ force: this.shouldForce }}
        render={(readyState: ReadyState) => {
          // check for error or show a loader for first load
          if (readyState.error) {
            handleReadyStateError(readyState.error, "WebSettingsCreateJoinTeamRenderer");
            return null;
          }

          const freshViewer: ISchema.IViewer = readyState.props && readyState.props.viewer;
          const freshOrg: ISchema.IOrg = readyState.props && readyState.props.org;

          if (freshViewer) {
            this.cachedViewer = freshViewer;
          }

          if (freshOrg) {
            this.cachedOrg = freshOrg;
          }

          if (!this.cachedViewer || !this.cachedOrg || this.state.loading) {
            return <Loader size="xl" sentiment="positive" className="cw-mt-auto cw-mb-auto" />;
          }

          this.shouldForce = false;

          const orgHasTeams =
            this.cachedViewer.teamQueryResultErrorable.__typename === "TeamQueryResult" &&
            !!this.cachedViewer.teamQueryResultErrorable.teams.length;

          const userHasTeams =
            this.cachedOrg.userTeams.__typename === "TeamList" &&
            this.cachedOrg.userTeams.list.length > 0;

          return (
            <WebSettingsContainer>
              <Typography variant="h1" className="cw-mb-11">
                Create or join team
              </Typography>
              <div className="cw-w-full">
                <div
                  style={{
                    padding: "20px 25px",
                    border: `1px solid ${greys.borderGrey}`,
                  }}
                >
                  <Typography variant="subtitle1" className="cw-mb-6">
                    {userHasTeams
                      ? "Clockwise can be used solo, but it's much better when you create a team and invite teammates you meet with most."
                      : "You're not a member of any teams yet. Clockwise can be used solo, but it's much better when you create a team and invite teammates you meet with most."}
                  </Typography>
                  <ButtonSet>
                    <Button
                      sentiment={orgHasTeams ? "neutral" : "positive"}
                      variant={orgHasTeams ? "outlined" : "solid"}
                      startIcon={Add}
                      onClick={this.onClickEmptyStateCreate}
                    >
                      Create team
                    </Button>
                    <Button
                      sentiment={orgHasTeams ? "positive" : "neutral"}
                      variant={orgHasTeams ? "solid" : "outlined"}
                      startIcon={Add}
                      onClick={this.onClickEmptyStateJoin}
                    >
                      Join team
                    </Button>
                  </ButtonSet>
                </div>
                <div
                  style={{
                    padding: "20px 25px",
                    border: `1px solid ${greys.borderGrey}`,
                    borderTop: "none",
                  }}
                >
                  <Typography variant="h3" className="cw-mb-4">
                    Benefits of teams
                  </Typography>
                  <Typography variant="subtitle1" className="cw-mb-2">
                    👀 Get visibility into your team's capacity with a team availability calendar
                  </Typography>
                  <Typography variant="subtitle1" className="cw-mb-2">
                    📊 Forecast your team's bandwidth with real-time team analytics
                  </Typography>
                  <Typography variant="subtitle1" className="cw-mb-2">
                    💡 Automatically protect team focus days with no meeting days
                  </Typography>
                </div>
              </div>
              {this.state.joinCreateDialogOpen && (
                <TeamJoinCreateDialog
                  open={this.state.joinCreateDialogOpen}
                  onClose={this.onCloseCreateJoinTeam}
                  org={this.cachedOrg}
                  forcedInitialView={this.state.joinCreateInitialView}
                  mode="settings"
                  shouldReloadAfterCreateOrJoin={this.props.shouldReloadAfterCreateOrJoin}
                />
              )}
            </WebSettingsContainer>
          );
        }}
      />
    );
  }
}
