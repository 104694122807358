import { Switch } from "@clockwise/design-system";
import { webappDefaultWindowName } from "@clockwise/web-commons/src/constants/route.constants";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import classNames from "classnames";
import React from "react";
import { EventElementsWrap } from "../atoms/EventElementsWrap";
import { ExternalLinkIcon } from "../atoms/ExternalLinkIcon";
import { InlineAction } from "../atoms/InlineAction";

export const ECTeamSyncSection = ({
  onChange,
  readOnly = false,
  syncing,
  teams,
}: {
  onChange: () => void;
  readOnly?: boolean;
  syncing: boolean;
  teams: { id: string; name: string }[];
}) => {
  const track = useTracking();

  const handleClickTeamSettings = () => {
    track(TrackingEvents.EVENT.TEAM_MANAGE_SETTINGS);
    openTeamSyncSettings();
  };

  return (
    <EventElementsWrap name="calendarSync">
      <div className={classNames("cw-flex cw-flex-col cw-space-y-3", "cw-w-full", "cw-body-base")}>
        <div className="cw-flex cw-flex-row cw-justify-between">
          <div className="cw-body-sm cw-font-medium">Sync to team calendars</div>
          <div>
            <Switch
              aria-label="Sync to team calendars"
              checked={syncing}
              disabled={readOnly}
              onChange={onChange}
              size="small"
            />
          </div>
        </div>
        <div className="cw-flex cw-flex-col cw-space-y-1">
          {teams.map(({ id = "unkownId", name = "Unnamed team" }) => (
            <div
              key={id}
              className={classNames(
                "cw-py-1 cw-px-2",
                "cw-rounded-lg",
                "cw-bg-neutral",
                "cw-body-sm cw-font-medium cw-text-subtle",
              )}
            >
              {name}
            </div>
          ))}
          {teams.length === 0 && (
            <div className="cw-body-sm cw-font-medium cw-text-subtle">No teams</div>
          )}
        </div>
        <div className="cw-body-sm">
          You can manage your{" "}
          <InlineAction
            ariaLabel="team calendar settings"
            fontSize="" // empty to inherit from container
            icon={ExternalLinkIcon}
            label="team calendar settings"
            onClick={handleClickTeamSettings}
          />{" "}
          in Clockwise settings.
        </div>
      </div>
    </EventElementsWrap>
  );
};

const openTeamSyncSettings = () =>
  window.open(getUrlForFeature("teamsSettings"), webappDefaultWindowName);
