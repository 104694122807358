import { useUpdateHoverEvent } from "#webapp/src/components/chat-plus-calendar/util/HoverEventContext";
import { SchedulingOptionCategoryTypeEnum, SchedulingOptionTypeEnum } from "@clockwise/schema";
import { useUpdateTimeSuggestion } from "@clockwise/web-commons/src/util/TimeSuggestionContext";
import React, { useCallback } from "react";
import { useUnmount } from "usehooks-ts";
import {
  emptyTimeSuggestionsState,
  useUpdateTimeSuggestionPeek,
} from "../../../../../chat-plus-calendar/util/TimeSuggestionPeekContext";
import { Chip } from "../../../../../chip";
import { ProposalRenderSurface } from "../../../../constants";
import { SchedulingOptionsTradeoffs } from "../../../utils/types";
import { SchedulingSelect } from "./SchedulingSelect";
import { getClickableSelectedOptions } from "./getClickableSelectedOptions";
import { parseSchedulingOptionsGQLByType } from "./parseSchedulingOptionsByType";

export type Option = {
  label: string;
  type: SchedulingOptionTypeEnum;
  category: SchedulingOptionCategoryTypeEnum | null;
  interval: string;
  tradeoffBlocks: SchedulingOptionsTradeoffs;
};

export type OptionWithIndex = Option & { index: number };

export type SchedulingPillsProps = {
  selectedIndex: number;
  disabled: boolean;
  options: Option[];
  variant?: ProposalRenderSurface;
  totalAttendeeCount?: number;
};

export const SchedulingPills = ({
  selectedIndex,
  disabled,
  options,
  variant = "AI_CHAT",
  totalAttendeeCount,
}: SchedulingPillsProps) => {
  const updateHoverEvent = useUpdateHoverEvent();

  const { setHoveredTimeSuggestion } = useUpdateTimeSuggestionPeek();

  const { setTimeSuggestionIndex } = useUpdateTimeSuggestion();
  const optionsWithIndex = options.map((option, index) => ({ ...option, index }));
  const { additionalOptions } = parseSchedulingOptionsGQLByType(optionsWithIndex);

  const showSelect = additionalOptions.length > 0;
  const clickableSchedulingOptions = getClickableSelectedOptions(optionsWithIndex, selectedIndex);

  const onMouseEnter = (interval: string, tradeoffBlocks: SchedulingOptionsTradeoffs) => {
    setHoveredTimeSuggestion({
      interval,
      tradeoffs: tradeoffBlocks,
      attendeeCount: totalAttendeeCount ?? null,
    });
  };

  const onMouseLeave = () => {
    setHoveredTimeSuggestion(emptyTimeSuggestionsState);
  };

  useUnmount(() => {
    // Remove the time suggestion overlay when the component unmounts.
    setHoveredTimeSuggestion(emptyTimeSuggestionsState);
  });

  const onClickOption = useCallback(
    (index: number) => {
      if (disabled || index === selectedIndex) {
        return;
      }
      setTimeSuggestionIndex(index);
      setHoveredTimeSuggestion(emptyTimeSuggestionsState);
      // only if need to be set

      updateHoverEvent(null);
    },
    [disabled, selectedIndex, setHoveredTimeSuggestion, setTimeSuggestionIndex, updateHoverEvent],
  );

  return (
    <div className="cw-flex cw-flex-wrap cw-gap-1">
      {clickableSchedulingOptions.map(({ label, interval, index, tradeoffBlocks }) => (
        <div
          key={`${label}-${index}`}
          onMouseEnter={() => onMouseEnter(interval, tradeoffBlocks)}
          onMouseLeave={onMouseLeave}
        >
          <Chip
            sentiment={index === selectedIndex ? "emphasis" : "neutral"}
            onClick={() => onClickOption(index)}
            cw-id="scheduling-pill"
            disabled={disabled}
            variant="solid"
          >
            {label}
          </Chip>
        </div>
      ))}

      {showSelect && (
        <SchedulingSelect
          options={optionsWithIndex}
          selectedIndex={selectedIndex}
          onClick={(index) => onClickOption(index)}
          disabled={disabled}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          variant={variant}
        />
      )}
    </div>
  );
};
