import { useReadCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import {
  CalendarLayer,
  getCalendarPosition,
  getCalendarZIndex,
} from "@clockwise/web-commons/src/util/event-position";
import { DateTime } from "luxon";
import { getDaysToRender } from "../../calendar-week/utils/getDaysToRender";

// TODO: still may want to remove the need for this layer
export const usePlannerPosition = (
  start: DateTime,
  end: DateTime,
  calendarLayer: CalendarLayer = "default",
) => {
  const { weekendsShown } = useReadCalendar();
  const daysRendered = getDaysToRender(
    "week",
    start,
    weekendsShown ? "sunday" : "monday",
    weekendsShown ? 7 : 5,
  );

  const calPos = getCalendarPosition({
    startTime: start,
    endTime: end,
    daysRendered: daysRendered,
    minHeightMinutes: 15,
  });

  const zIndex = getCalendarZIndex(calendarLayer);
  const top = calPos?.top || 0;
  const height = calPos?.height || 0;
  const left = calPos?.left || 0;
  const width = calPos?.width || 0;

  const style = {
    top: `${top}%`,
    height: `calc(${height}% - 4px)`,
    left: `${left}%`,
    width: `calc(${width}% - 4px)`,
    zIndex: zIndex,
  };

  return {
    top,
    left,
    height,
    width,
    zIndex,
    style,
  };
};

export default usePlannerPosition;
