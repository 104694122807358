import { makeStyles } from "@material-ui/styles";

import { colors } from "../../styles/index";

export const pageMaxWidth = 1150;

export const useSetupLinkStyles = makeStyles({
  slug: {
    "& .MuiFilledInput-root": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,

      "& .MuiFilledInput-input": {
        padding: "8px 10px 8px 4px",
      },
    },
  },
  datePicker: {
    "& .MuiInputAdornment-root": {
      marginLeft: "0",
    },

    "& .MuiFilledInput-root": {
      borderRadius: "4px",
      paddingRight: "0px",
    },

    "& .MuiFilledInput-input": {
      padding: "9px 0px 9px 9px",
      fontSize: "14px",
      minWidth: "4.5rem",
    },
  },
  datePickerInput: {
    "& .MuiTouchRipple-root": {
      display: "none",
    },

    "& .MuiIconButton-root": {
      paddingLeft: "0px",
      paddingRight: "4px",

      "&:focus": {
        color: colors.green.standard,
      },
    },
  },
});
