import { TradeoffType } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { EventThread, SecondaryCard } from "@clockwise/web-commons/src/ui/event-thread";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { Skeleton } from "@material-ui/lab";
import { isEmpty } from "lodash";
import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { TradeoffBlock } from "../reschedule-confirmation-modal/RescheduleConfirmationModal";
import { toRenderableTradeoffs } from "../reschedule-confirmation-modal/toRenderableTradeoffs";
import { TradeoffBlock as TradeoffBlockComponent } from "./TradeoffBlock";
import { RemoveTradeoffFromProposalDocument } from "./__generated__/RemoveTradeoffFromProposal.v2.generated";
import { getTradeoffBlocksToDisplay } from "./tradeoffs.utils";

const getTradeoffRenderType = (tradeoffType: TradeoffType): SecondaryCard => {
  switch (tradeoffType) {
    case TradeoffType.AvailabilityIssue:
      return "unavailable";
    case TradeoffType.Inconvenience:
      return "inconvenience";
    case TradeoffType.LowVisibilityInconvenience:
      return "inconvenience";
    case TradeoffType.FixableConflict:
      return "sbm";
    default:
      return "unavailable";
  }
};

export const TradeoffBlocks = ({
  blocks,
  loading,
}: {
  blocks: TradeoffBlock[];
  loading?: boolean;
}) => {
  const track = useTracking();
  const [removeTradeoffFromProposal] = useGatewayMutation(RemoveTradeoffFromProposalDocument);

  const handleRemovalOfTradeoff = useCallback(
    (diffId: string) => {
      void removeTradeoffFromProposal({
        variables: { eventDiffId: diffId },
        onCompleted: (data) => {
          toast.success("Will not fix conflict");

          const proposalId = data?.removeTradeoffFromProposal?.id;
          track(TrackingEvents.DIRECT_MANIPULATION.RESCHEDULE_MODAL.TRADEOFF.REMOVED, {
            proposalId,
          });
        },
        onError: () => {
          toast.error("Failed to remove tradeoff");
        },
      });
    },
    [removeTradeoffFromProposal, track],
  );

  if (loading && isEmpty(blocks)) return <TradeoffBlocksSkeleton />;

  if (!loading && isEmpty(blocks)) {
    return (
      <EventThread.Break
        type={"worksForEveryone"}
        variant="MODAL_PROPOSAL"
        loading={false}
        spacing={"Compact"}
      >
        <div className="cw-flex cw-flex-row cw-gap-3">Works for everyone</div>
      </EventThread.Break>
    );
  }

  return (
    <>
      {getTradeoffBlocksToDisplay(blocks).map(
        ({ tradeoffType, affectedAttendees, tradeoffs }, index) => {
          const isFixableConflict = tradeoffType === TradeoffType.FixableConflict;
          const isAvailabilityIssue = tradeoffType === TradeoffType.AvailabilityIssue;
          const isLoadingAndAvailabilityIssue = loading && isAvailabilityIssue;

          const shouldCollapse = !isFixableConflict && !isAvailabilityIssue;

          return (
            <TradeoffBlockComponent
              key={`${tradeoffType}-${index}`}
              tradeoffs={toRenderableTradeoffs(tradeoffs)}
              affectedAttendees={affectedAttendees}
              type={getTradeoffRenderType(tradeoffType)}
              defaultCollapsed={shouldCollapse}
              loading={isLoadingAndAvailabilityIssue}
              onClickX={handleRemovalOfTradeoff}
            />
          );
        },
      )}
    </>
  );
};

export const TradeoffBlocksSkeleton = () => {
  return (
    <div className="cw-flex cw-flex-col cw-gap-2">
      <Skeleton variant="rect" width="50%" height={14} className="cw-rounded-full" />
      <Skeleton variant="rect" width="75%" height={14} className="cw-rounded-full" />
      <Skeleton variant="rect" width="60%" height={14} className="cw-rounded-full" />
    </div>
  );
};
