import { Location } from "react-router-dom";
import { getParamsFromHash } from "../../util/getParamsFromHash";
import { getSelectedDateTime } from "../../util/getSelectedDateTime";

export const getCalendarDatesFromRoute = (location: Location) => {
  if (!location.hash) return null;

  const params = getParamsFromHash(location.hash);

  if (!params) return null;

  return [params.mode, getSelectedDateTime(params).toISODate()];
};
