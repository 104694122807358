import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { QuestionType, UserSurveyResponse } from "@clockwise/schema";
import { useCallback, useEffect, useMemo, useState } from "react";
import { setError } from "../../utils/setError";
import {
  CURRENT_POSITION_RESPONSES,
  OnboardingGetToKnowYouStepQuestion,
  TIME_MANAGEMENT_GOAL_RESPONSES,
  TYPE_OF_WORK_RESPONSES,
  getDefaultCurrentPosition,
  getDefaultTeamType,
  getDefaultTimeManagementGoal,
} from "./GetToKnowYouStep.util";
import {
  OnboardingGetUserSurveyResponseDocument,
  OnboardingGetUserSurveyResponseQuery,
} from "./__generated__/OnboardingGetUserSurveyResponse.generated";

export const useUserSurvey = (orgRelayId: string) => {
  const [currentPosition, setCurrentPosition] = useState<OnboardingGetToKnowYouStepQuestion>(
    getDefaultCurrentPosition(),
  );
  const [teamType, setTeamType] = useState<OnboardingGetToKnowYouStepQuestion>(
    getDefaultTeamType(),
  );
  const [timeManagementGoal, setTimeManagementGoal] = useState<OnboardingGetToKnowYouStepQuestion>(
    getDefaultTimeManagementGoal(),
  );

  const { data } = useQuery(OnboardingGetUserSurveyResponseDocument, {
    variables: { orgRelayId: orgRelayId },
    onError: (error) =>
      setError({
        message: "could not get user survey response",
        error: error,
        showUserMessage: false,
      }),
  });

  const getSurveyResponsesFromData = (queryData: OnboardingGetUserSurveyResponseQuery) => {
    return getValue(queryData.node, "Org")?.userSurveyResponseWrapperErrorable;
  };

  useEffect(() => {
    if (data) {
      const responseErrorable = getSurveyResponsesFromData(data);
      const list: UserSurveyResponse[] | undefined = getValue(
        responseErrorable,
        "UserSurveyResponseWrapper",
      )?.userSurveyResponses;
      if (list) {
        updateTeamType(list);
        updateCurrentPosition(list);
        updateTimeManagementGoal(list);
      }
    }
  }, [data]);

  const updateTeamType = (list: UserSurveyResponse[]) => {
    const defaultTeamType = getDefaultTeamType();
    const teamTypeNew =
      list.find((usr) => usr.questionType === QuestionType.WhatTypeOfWorkDoYouDo) ||
      defaultTeamType;
    setTeamType(teamTypeNew);
  };

  const updateCurrentPosition = (list: UserSurveyResponse[]) => {
    const defaultCurrentPosition = getDefaultCurrentPosition();
    const currentPositionNew =
      list.find((usr) => usr.questionType === QuestionType.WhatIsYourCurrentPosition) ||
      defaultCurrentPosition;
    setCurrentPosition(currentPositionNew);
  };

  const updateTimeManagementGoal = (list: UserSurveyResponse[]) => {
    const defaultTimeManagementGoal = getDefaultTimeManagementGoal();
    const timeManagementGoalNew =
      list.find((usr) => usr.questionType === QuestionType.WhatsYourTimeManagementGoal) ||
      defaultTimeManagementGoal;
    setTimeManagementGoal(timeManagementGoalNew);
  };

  const onTeamTypeChange = useCallback(
    (responseType: string) => {
      const teamTypeCopy = { ...teamType };
      const newResponse = TYPE_OF_WORK_RESPONSES.find((r) => r.responseType === responseType);
      if (newResponse) {
        teamTypeCopy.responseText = newResponse.responseText;
        teamTypeCopy.responseType = newResponse.responseType;
      }
      setTeamType(teamTypeCopy);
    },
    [teamType],
  );

  const onCurrentPositionChange = useCallback(
    (responseType: string) => {
      const currentPositionCopy = { ...currentPosition };
      const newResponse = CURRENT_POSITION_RESPONSES.find((r) => r.responseType === responseType);
      if (newResponse) {
        currentPositionCopy.responseText = newResponse.responseText;
        currentPositionCopy.responseType = newResponse.responseType;
      }
      setCurrentPosition(currentPositionCopy);
    },
    [currentPosition],
  );

  const onTimeManagementGoalChange = useCallback(
    (responseType: string) => {
      const timeManagementCopy = { ...timeManagementGoal };
      const newResponse = TIME_MANAGEMENT_GOAL_RESPONSES.find(
        (r) => r.responseType === responseType,
      );
      if (newResponse) {
        timeManagementCopy.responseText = newResponse.responseText;
        timeManagementCopy.responseType = newResponse.responseType;
      }
      setTimeManagementGoal(timeManagementCopy);
    },
    [timeManagementGoal],
  );

  return useMemo(() => {
    return {
      currentPosition,
      teamType,
      onCurrentPositionChange,
      onTeamTypeChange,
      timeManagementGoal,
      onTimeManagementGoalChange,
    };
  }, [
    currentPosition,
    teamType,
    onCurrentPositionChange,
    onTeamTypeChange,
    timeManagementGoal,
    onTimeManagementGoalChange,
  ]);
};
