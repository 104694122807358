import { Tooltip } from "@clockwise/design-system";
import { SecondaryCard } from "@clockwise/web-commons/src/ui/event-thread";
import { getShortStringFromListOfAttendees } from "@clockwise/web-commons/src/util/profile.util";
import pluralize from "pluralize";
import React from "react";

type Attendee = {
  person: {
    isMe: boolean;
    displayName: string;
  };
};

const UnfixableConflicts = ({ attendees }: { attendees: Attendee[] }) => {
  const attendeeDisplay = getShortStringFromListOfAttendees(attendees);

  return (
    <span className="cw-font-medium">
      {pluralize("Conflicts", attendees.length)} ·{" "}
      <span className="cw-font-normal">{attendeeDisplay}</span>
    </span>
  );
};

const FixableConflicts = ({ attendees }: { attendees: Attendee[] }) => {
  const attendeeDisplay = getShortStringFromListOfAttendees(attendees);

  const tooltipText = "Clockwise will only move other users' events if they are Flexible.";
  return (
    <span className="cw-font-normal">
      <Tooltip title={tooltipText} placement="top">
        <span className="cw-underline cw-decoration-dashed cw-decoration-[#D9D9D9] cw-underline-offset-4 cw-font-medium cw-whitespace-nowrap">
          Fixable {pluralize("conflicts", attendees.length)}{" "}
        </span>
      </Tooltip>
      · {attendeeDisplay}
    </span>
  );
};

const Inconveniences = ({ attendees }: { attendees: Attendee[] }) => {
  const attendeeDisplay = getShortStringFromListOfAttendees(attendees);

  const tooltipText =
    "Clockwise identifies booking over events such as Focus Time, lunch, meeting breaks, or on a no-meeting day as inconveniences.";

  return (
    <span className="cw-font-normal">
      <Tooltip
        title={<div className="cw-body-sm cw-text-onEmphasis">{tooltipText}</div>}
        placement="top"
      >
        <span className="cw-underline cw-decoration-dashed cw-decoration-[#D9D9D9] cw-underline-offset-4 cw-font-medium cw-whitespace-nowrap">
          {pluralize("Inconveniences", attendees.length)}{" "}
        </span>
      </Tooltip>
      · {attendeeDisplay}
    </span>
  );
};

export const TradeoffEffectByType = ({
  type,
  attendees,
}: {
  type: SecondaryCard;
  attendees: Attendee[];
}) => {
  switch (type) {
    case "sbm":
      return <FixableConflicts attendees={attendees} />;
    case "unavailable":
      return <UnfixableConflicts attendees={attendees} />;
    case "inconvenience":
      return <Inconveniences attendees={attendees} />;
  }
};
