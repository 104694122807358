/**
 * @generated SignedSource<<064ec9f41139196b6a8a26c2860dea28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventColoringSettings_org$data = {
  readonly id: string;
  readonly experiments: ReadonlyArray<string | null> | null;
  readonly primaryOrgCalendar: string;
  readonly eventColoringSettingsErrorable: {
    readonly __typename: "GraphEntityError";
    readonly statusCode: number | null;
    readonly message: string | null;
  } | {
    readonly __typename: "EventColoringSettings";
    readonly eventCategoryColorings: ReadonlyArray<{
      readonly googleColorKey: string | null;
      readonly eventCategory: string;
      readonly active: boolean;
      readonly isUserSet: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"EventCategoryColoring_eventCategoryColoring">;
    }> | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly " $fragmentSpreads": FragmentRefs<"EventCategoryColoring_org">;
  readonly " $fragmentType": "EventColoringSettings_org";
};
export type EventColoringSettings_org$key = {
  readonly " $data"?: EventColoringSettings_org$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventColoringSettings_org">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventColoringSettings_org",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventCategoryColoring_org"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "experiments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryOrgCalendar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "eventColoringSettingsErrorable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "statusCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            }
          ],
          "type": "GraphEntityError",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EventCategoryColoring",
              "kind": "LinkedField",
              "name": "eventCategoryColorings",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EventCategoryColoring_eventCategoryColoring"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "googleColorKey",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "eventCategory",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "active",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isUserSet",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "EventColoringSettings",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Org",
  "abstractKey": null
};

(node as any).hash = "4693c13da44f357aa6a0446149caaf53";

export default node;
