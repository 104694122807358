import { Placement } from "@clockwise/design-system/src/components/Menu";
import classNames from "classnames";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import { useEventListener } from "usehooks-ts";

const ABOVE_EVERYTHING = 10000;

export const PopperWrapper = ({
  anchorEl,
  children,
  boundary,
  customWidth,
  onClose,
  placement = "right-start",
}: {
  anchorEl: HTMLDivElement | HTMLButtonElement | null;
  children: React.ReactNode;
  boundary?: Element;
  customWidth?: number;
  onClose: () => void;
  placement?: Placement;
}) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(anchorEl, container, {
    placement,
    strategy: "fixed",
    modifiers: [
      {
        name: "flip",
        enabled: true,
      },
      { name: "offset", options: { offset: [0, 2] } },
      { name: "preventOverflow", options: { padding: 8, boundary: boundary } },
    ],
  });

  useEventListener("mousedown", (event) => {
    const eventCategoryPortal = document.querySelector('[cw-id="event-category-portal"]');
    const eventFlexibilityPortal = document.querySelector('[cw-id="event-flexibility-portal"]');

    const popperClicked = container?.contains(event.target as Node);
    const eventCategoryPortalClicked = eventCategoryPortal?.contains(event.target as Node);
    const eventFlexibilityPortalClicked = eventFlexibilityPortal?.contains(event.target as Node);

    if (!popperClicked && !eventCategoryPortalClicked && !eventFlexibilityPortalClicked) {
      onClose();
    }
  });

  return (
    <div
      ref={setContainer}
      className={classNames(
        `cw-rounded-xl cw-z-50 cw-overflow-auto cw-body-sm cw-bg-default cw-shadow-selectPopup`,
      )}
      style={{
        ...styles.popper,
        width: customWidth ?? 250,
        zIndex: ABOVE_EVERYTHING,
      }}
      {...attributes.popper}
    >
      {children}
    </div>
  );
};
