// schema
// import * as ISchema from 'src/__schema__';

// libraries
import * as React from "react";
import { connect } from "react-redux";
import { createFragmentContainer } from "react-relay";

// sudo-wrapper imports
import { SudoCli } from "#webapp/src/components/sudo-cli";
import { SudoDeactivateUser } from "#webapp/src/components/sudo-deactivate-user";
import { SudoDeleteEverything } from "#webapp/src/components/sudo-delete-everything";
import { SudoModifyUser } from "#webapp/src/components/sudo-modify-user";
import { SudoSwitchUser } from "#webapp/src/components/sudo-switch-user";
import { SudoTriggerClientUpdate } from "#webapp/src/components/sudo-trigger-client-update";
import { sudoWrapperFragments, sudoWrapperQuery } from "./SudoWrapper.gql";
import { styles } from "./SudoWrapper.styles";
import { IContainer, IProps, IState } from "./SudoWrapperTypes";

// state
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";

// routing
import { withRouterQuery } from "#webapp/src/routing/routes.util";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Helmet from "react-helmet";

import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { Button } from "@clockwise/design-system";
import { OpenInNew } from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
///////////////
// Component //
///////////////
class SudoWrapperCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const env = getEnvironment();
    const userEmail = this.props.viewer?.user?.emails[0];
    return (
      <div className={this.props.classes.container}>
        <Helmet>
          <title>Sudo · Clockwise</title>
        </Helmet>
        <div className="cw-heading-base">Logged in User: {userEmail}</div>
        <div className="cw-grid cw-grid-cols-1 sm:cw-grid-cols-3 cw-gap-4">
          <div className="cw-flex cw-flex-col cw-gap-4">
            <SudoSwitchUser viewer={this.props.viewer} />
            <SudoModifyUser viewer={this.props.viewer} />
            <SudoDeactivateUser viewer={this.props.viewer} />
            <SudoTriggerClientUpdate sudo={this.props.viewer.sudo} />
            {env && env !== "production" && <SudoDeleteEverything viewer={this.props.viewer} />}
          </div>
          <div className="cw-col-span-2">
            <SudoCli />
            <div className="cw-my-4">
              <Button
                endIcon={OpenInNew}
                sentiment="positive"
                onClick={() => window.open(appPaths.provisioning, "Provisioning")}
              >
                Go to Provisioning
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/////////////////
// With Styles //
/////////////////
export const SudoWrapperStyled = withStyles(styles)(SudoWrapperCmpt);

///////////
// Redux //
///////////
function mapStateToProps(_state: IReduxState, _ownProps: IContainer) {
  return {};
}

const SudoWrapperRedux = connect(mapStateToProps, {})(SudoWrapperStyled);

///////////
// Relay //
///////////
export const SudoWrapper = createFragmentContainer<IContainer>(
  SudoWrapperRedux,
  sudoWrapperFragments,
);

export const SudoWrapperPage = withRouterQuery(SudoWrapper, sudoWrapperQuery);
