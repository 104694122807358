import React from "react";
import { CardWrapper } from "./CardWrapper";
import { Async } from "./atoms/Async";

const SkeletonRow = ({ width }: { width?: number }) => {
  return (
    <div className="cw-mt-2">
      <Async loading={true} loadingWidth={width ? width : undefined} loadingHeight="28px">
        <div />
      </Async>
    </div>
  );
};

export const LoadingCard = ({ onClose }: { onClose: () => void }) => {
  return (
    <CardWrapper onClose={onClose} header={<div />} footer={<div />}>
      <div className="cw-border-none">
        <div className="cw-py-2 cw-mx-4 cw-flex-1 cw-border-solid cw-border-b cw-border-subtle">
          <Async loading={true} loadingHeight="28px">
            <div />
          </Async>
        </div>
      </div>
      <div className="cw-mx-4">
        <SkeletonRow />
        <SkeletonRow width={250} />
        <SkeletonRow width={200} />
        <SkeletonRow width={200} />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow width={100} />
      </div>
    </CardWrapper>
  );
};
