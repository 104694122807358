import { EventForFlexibility } from "#webapp/src/components/flexible-meetings/util/parseFlexibility";
import { Frequency } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { BlockFilled, GroupFilled, SunnyFilled } from "@clockwise/icons";
import { EventTable } from "@clockwise/web-commons/src/components/EventTable";
import React, { useEffect, useState } from "react";
import { TrackingEvents, track } from "../../../../util/analytics.util";
import { useOnboardingUpdateEventFlexibility } from "../../hooks/useOnboardingUpdateEventFlexibility";
import { ButtonGroup, Header, SlideLayout, Text } from "../../shared-components";
import { setError } from "../../utils/setError";
import { FunnelType } from "../../WebOnboarding.util";
import { OnboardingFlexEventRow } from "./OnboardingFlexEventRow";
import { FlexMeetingEventMap, getEventListFromFlexMeetingMap } from "./utils/suggestion-utils";

export const FlexMeetingSlide = ({
  eventMap,
  showSecondaryButton,
  onClickPrimaryButton,
  onClickSecondaryButton,
  funnelType,
}: {
  showSecondaryButton: boolean;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton: () => void;
  eventMap: FlexMeetingEventMap;
  funnelType: FunnelType;
}) => {
  const [flexMeetingEventMap, setFlexMeetingEventMap] = useState<FlexMeetingEventMap>({});
  const [disableButtons, setDisableButtons] = useState(false);
  const flexMeetingList = getEventListFromFlexMeetingMap(flexMeetingEventMap);
  const sortedMeetingsByRecurrence = sortFlexMeetings(flexMeetingList);
  const { update } = useOnboardingUpdateEventFlexibility();

  useEffect(() => {
    setFlexMeetingEventMap({ ...eventMap });
  }, [eventMap]);

  const onFlexToggle = (id: string, isFlex: boolean) => {
    const updatedMap = { ...flexMeetingEventMap };
    const updatedEvent = updatedMap[id];
    if (!updatedEvent) return;
    updatedEvent.isFlexible = isFlex;
    setFlexMeetingEventMap(updatedMap);
  };

  const onFlexEventChange = (id: string, updatedEvent: EventForFlexibility) => {
    const updatedMap = { ...flexMeetingEventMap };
    updatedMap[id] = updatedEvent;
    setFlexMeetingEventMap(updatedMap);
  };

  const onSaveAndMoveForward = () => {
    setDisableButtons(true);
    void update({
      variables: { events: flexMeetingList },
      onCompleted: () => {
        onClickPrimaryButton();
        setDisableButtons(false);
      },
      onError: (error) => {
        setDisableButtons(false);
        setError({
          message: "failed to make meetings flexible",
          error: error,
          showUserMessage: true,
        });
        onClickPrimaryButton();
      },
    });
  };

  const onSkip = () => {
    track(TrackingEvents.WEB_ONBOARDING.FLEX_MEETING_SKIPPED, { funnelType: funnelType });
    onClickPrimaryButton();
  };

  return (
    <SlideLayout>
      <Header>A flexible calendar, on your terms.</Header>
      <Text>
        Marking events as flexible allows Clockwise to resolve conflicts automatically and maximize
        your focus time, within the rules you set.
      </Text>
      <div className=" cw-w-full md:cw-w-[600px] cw-text-sm cw-text-muted">
        <div className="cw-flex cw-gap-5 cw-text-center cw-mb-12 cw-text-12">
          <div className="cw-flex-1">
            <div>
              <SunnyFilled className="cw-w-5 cw-h-5 cw-text-positive-muted" />
            </div>
            We'll optimize your flexible meetings once a day
          </div>
          <div className="cw-flex-1">
            <div>
              <GroupFilled className="cw-w-5 cw-h-5 cw-text-positive-muted" />
            </div>
            We'll optimize around your coworkers' schedules, even if they're not signed up yet
          </div>
          <div className="cw-flex-1">
            <div>
              <BlockFilled className="cw-w-5 cw-h-5 cw-text-positive-muted" />
            </div>
            Clockwise will not move events occurring in the next 24 hours
          </div>
        </div>
      </div>
      <ButtonGroup
        shouldShowSecondary={showSecondaryButton}
        primaryLabel={"Confirm"}
        onClickPrimary={onSaveAndMoveForward}
        onClickSecondary={onClickSecondaryButton}
        onSkip={onSkip}
        disabled={disableButtons}
        powerMeeterStrength={getStrengthOfMeeter(flexMeetingList)}
      />
      <EventTable heading="Flex Meetings">
        {sortedMeetingsByRecurrence.map((event) => {
          return (
            <OnboardingFlexEventRow
              event={event}
              key={event.id}
              onFlexibilityChange={(_, isFlex) => {
                onFlexToggle(event.id, isFlex);
              }}
              showNotRecommededBadge={event.showNotRecommededBadge}
              onFlexEventChange={(updatedEvent) => {
                onFlexEventChange(event.id, updatedEvent);
              }}
            />
          );
        })}
      </EventTable>
    </SlideLayout>
  );
};

const recurrenceSorted = [
  Frequency.DAILY,
  Frequency.WEEKLY,
  Frequency.FORTNIGHTLY,
  Frequency.MONTHLY,
  Frequency.YEARLY,
];

const sortFlexMeetings = (flexMeetings: EventForFlexibility[]) => {
  return flexMeetings.sort(
    (a, b) =>
      recurrenceSorted.indexOf(a.recurrenceType ?? Frequency.YEARLY) -
      recurrenceSorted.indexOf(b.recurrenceType ?? Frequency.YEARLY),
  );
};

const getStrengthOfMeeter = (flexMeetings: EventForFlexibility[]) => {
  if (!flexMeetings.length) return 4;
  const totalMeetings = flexMeetings.length;
  const flexibleMeetings = flexMeetings.filter((meeting) => meeting.isFlexible).length;
  const percentage = flexibleMeetings / totalMeetings;
  if (percentage < 0.25) return 1;
  if (percentage < 0.5) return 2;
  if (percentage < 0.75) return 3;
  return 4;
};
