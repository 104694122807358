import { InfoFilled } from "@clockwise/icons";
import { LegacyTooltip } from "@clockwise/web-commons/src/ui/tooltip";
import classNames from "classnames";
import React from "react";
export const ECTradeoffsNoDrawer = ({
  text,
  icon,
  variant,
  tooltipText,
}: {
  text: string;
  icon: React.ReactNode;
  variant: "neutral" | "warning";
  tooltipText?: string;
}) => {
  return (
    <div className="cw-flex cw-items-center cw-text-12 cw-font-medium cw-px-2 cw-py-1 cw-rounded-md cw-bg-neutral cw-border cw-border-solid cw-border-subtle">
      <div className="cw-mr-1 cw-flex cw-justify-center ">{icon}</div>
      <div
        className={classNames("cw-body-sm", {
          "cw-text-muted": variant === "neutral",
          "cw-text-warning": variant === "warning",
        })}
      >
        {text}
      </div>

      {!!tooltipText && (
        <LegacyTooltip placement="top" showArrow={false} body={tooltipText}>
          <InfoFilled className="cw-w-3.5 cw-h-3.5 cw-mt-[5px] cw-cursor-pointer cw-text-info-disabled cw-ml-1" />
        </LegacyTooltip>
      )}
    </div>
  );
};
