import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

const ONE_SECOND = 1000;

export const useCurrentDateTime = (updateInterval = ONE_SECOND) => {
  const zone = getRenderTimeZone();
  const [currentTime, setCurrentTime] = useState(DateTime.now().setZone(zone));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(DateTime.now().setZone(zone));
    }, updateInterval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return currentTime;
};

export default useCurrentDateTime;
