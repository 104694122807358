// styles
import { text, theme } from "@clockwise/web-commons/src/styles/color.styles";
import spacing from "@clockwise/web-commons/src/styles/spacing.styles";

export const container = {
  margin: `${spacing.xlg}px auto`,
  padding: "10px",
  maxWidth: "1440px",
  display: "flex",
  flexGrow: 1,
  justifyContent: "left",
  flexDirection: "column",
} as const;

export const title = {
  fontWeight: 600,
  fontSize: 18,
  flexShrink: 0,
  flexGrow: 1,
  textAlign: "left",
} as const;

export const section = {
  marginTop: spacing.lg,
} as const;

export const row = {
  display: "flex",
  marginBottom: spacing.std,
} as const;

export const label = {
  textAlign: "right",
  marginRight: spacing.std,
  width: 120,
  fontWeight: 600,
} as const;

export const labelContent = {
  flexGrow: 1,
} as const;

export const labelContentSection = {
  paddingBottom: spacing.std,
} as const;

export const list = {
  marginBottom: spacing.xxs,
  display: "flex",
  flexDirection: "column",
} as const;

export const listItem = {
  marginBottom: spacing.xxs,
  display: "flex",
  justifyContent: "space-between",
  height: spacing.xlg,
  maxWidth: 500,
  alignItems: "center",
  ":hover": {},
} as const;

export const listItemLabel = {
  color: text.secondary,
} as const;

export const miniHeading = {
  marginBottom: spacing.xs,
} as const;

export const clickToCopyCwId = {
  fontSize: 14,
  color: text.secondary,
  padding: "2px 0 2px 2px",
  marginLeft: "4px",
  marginRight: "4px",
  border: "1px dashed transparent",
} as const;

export const cwId = {
  fontSize: 14,
} as const;

export const vline = {
  margin: "0 0 8px 0",
} as const;

export const preJson = {
  margin: "0 0 8px 0",
  fontFamily: "Monaco, monospace",
  fontSize: 13,
  lineHeight: "1.5em",
} as const;

export const instructions = {
  paddingTop: 20,
  paddingBottom: 20,
} as const;

export const select = {
  fontSize: 14,
} as const;

export const btn = {
  marginTop: spacing.sm,
} as const;

export const miniLink = {
  color: theme.accentColor,
  cursor: "pointer",
  ":hover": {
    textDecoration: "underline",
  },
  ":active": {
    color: theme.primary,
  },
} as const;

export const smallIconContainer = {
  width: 35,
  height: 35,
  padding: 5,
  opacity: 0.25,
} as const;

export const smallIconContainerOpaque = {
  width: 35,
  height: 35,
  padding: 5,
  opacity: 1,
} as const;

export const smallIcon = {
  width: 20,
  height: 20,
} as const;

export const profilePhoto = {
  paddingLeft: spacing.std,
} as const;

export const styles = {
  container,
  title,
  section,
  row,
  label,
  labelContent,
  labelContentSection,
  list,
  listItem,
  listItemLabel,
  miniHeading,
  clickToCopyCwId,
  cwId,
  vline,
  preJson,
  instructions,
  select,
  btn,
  miniLink,
  smallIcon,
  smallIconContainer,
  smallIconContainerOpaque,
  profilePhoto,
} as const;
