import { getLocalDefragRunTime } from "@clockwise/web-commons/src/util/event-history.util";
import {
  getRenderTimeZone,
  getTimeZoneDisplayName,
} from "@clockwise/web-commons/src/util/time-zone.util";
import React from "react";
import { Card } from "./Card";

export const EventExceedsDefragRange = ({
  canMoveAnytime,
  nextDefrag,
}: {
  canMoveAnytime: boolean;
  nextDefrag: string | null;
}) => {
  const eventMovement =
    canMoveAnytime || !nextDefrag
      ? "as your schedule changes"
      : `every weekday at ${getLocalDefragRunTime(nextDefrag)} ${getTimeZoneDisplayName(
          getRenderTimeZone(),
        )}`;

  return (
    <Card title="Clockwise may update this event's timing">
      Clockwise will search for a better time for this event {eventMovement}.
    </Card>
  );
};
