import { Button } from "@clockwise/design-system";
import { DayEnum } from "@clockwise/schema/v2";
import MomentUtils from "@date-io/moment";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTime } from "luxon";
import moment, { Moment } from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { HoursEditor } from "./HoursEditor";
import { DateSetting, DateString, MeetingHours, TimeRange } from "./types";

interface Props {
  open: boolean;
  meetingHours: MeetingHours;
  onSubmit: (updatedDateOverrides: DateSetting[]) => void;
  onClose: () => void;
}

export const DateOverrideModal = ({ meetingHours, open, onClose, onSubmit }: Props) => {
  const [selectedDates, setSelectedDates] = useState<DateString[]>([]);
  const [lastSelectedDate, setLastSelectedDate] = useState<Moment>();
  const [timeRanges, setTimeRanges] = useState<TimeRange[]>([]);

  const handleSubmit = () => {
    onSubmit(selectedDates.map((d) => ({ date: d, timeRanges })));
    onClose();
  };

  const handleDateChange = (newDate: MaterialUiPickersDate) => {
    if (newDate) {
      setLastSelectedDate(newDate);
      // Convert moment to ISO 8601 Date string
      const date = newDate.format("YYYY-MM-DD");
      if (selectedDates.length === 0) {
        setTimeRanges(getTimeRangesForDate(date, meetingHours));
      }

      setSelectedDates((prevDates) => {
        if (prevDates.includes(date)) {
          return prevDates.filter((d) => d !== date);
        } else {
          // Maintain the order of selection for consistent default timeRanges
          return [...prevDates, date];
        }
      });
    }
  };

  // Memoize selected dates as moments for comparison in renderDay
  const selectedMoments = useMemo(() => {
    return selectedDates.map((date) => moment(date));
  }, [selectedDates]);
  const renderDay = useCallback(
    (
      date: MaterialUiPickersDate,
      _selectedDate: MaterialUiPickersDate,
      _isInCurrentMonth: boolean,
      dayComponent: JSX.Element,
    ) => {
      const selected =
        !!date && selectedMoments.some((selectedMoment) => selectedMoment.isSame(date, "day"));
      return React.cloneElement(dayComponent, { selected });
    },
    [selectedMoments],
  );

  const hasChanges = selectedDates.length > 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="cw-flex cw-flex-nowrap cw-items-center cw-border-b cw-border-solid cw-border-b-default">
        <div className="cw-heading-sm cw-flex-1 cw-flex cw-flex-nowrap cw-px-5 cw-py-3">
          Pick a date and time range
        </div>
        <div className="cw-justify-self-end cw-px-2">
          <Button variant="text" size="mini" startIcon={Close} onClick={onClose} rounded />
        </div>
      </div>

      <div className="cw-px-5 cw-py-3">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            variant="static"
            value={lastSelectedDate}
            onChange={handleDateChange}
            renderDay={renderDay}
            disablePast
            disableToolbar
            fullWidth
            id="scheduling-link-date-picker"
          />
        </MuiPickersUtilsProvider>
        {selectedDates.length > 0 && (
          <HoursEditor timeRanges={timeRanges} onChange={setTimeRanges} />
        )}
        <div className="cw-heading-sm cw-flex-nowrap cw-flex cw-justify-around cw-px-5 cw-py-3">
          <Button sentiment="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button sentiment="positive" disabled={!hasChanges} onClick={handleSubmit}>
            Apply
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

/// Utils

const DAYS_OF_WEEK = [
  DayEnum.Monday,
  DayEnum.Tuesday,
  DayEnum.Wednesday,
  DayEnum.Thursday,
  DayEnum.Friday,
  DayEnum.Saturday,
  DayEnum.Sunday,
] as const;
const getDayOfWeek = (date: string): DayEnum => {
  // weekday is 1-indexed and starts with Monday=1
  const dayIndex = DateTime.fromISO(date).weekday - 1;
  return DAYS_OF_WEEK[dayIndex];
};

const getTimeRangesForDate = (date: DateString, meetingHours: MeetingHours): TimeRange[] => {
  const { dateOverrides, schedule } = meetingHours;
  const day = getDayOfWeek(date);
  return (
    dateOverrides.find((setting) => setting.date === date)?.timeRanges ??
    schedule.find((setting) => setting.day === day)?.timeRanges ?? [
      { startTime: "09:00", endTime: "17:00" },
    ]
  );
};
