import { EventColorCategory } from "@clockwise/client-commons/src/util/event-category-coloring";
import { Menu, MenuGroup, MenuItem } from "@clockwise/design-system";
import { bg_info_emphasis } from "@clockwise/design-system/tokens";
import { CropSquareFilled, Settings } from "@clockwise/icons";
import { getUrlForFeature } from "@clockwise/web-commons/src/util/routes.util";
import { Launch } from "@material-ui/icons";
import React from "react";
import { CategoryOption } from "../../web-app-calendar/hooks/useEventDetails";
import { EventElementsWrap } from "../atoms/EventElementsWrap";

const _openColorSettingsLink = (url: string) => {
  window.open(url, "_blank");
};

export const ECCategorySelector = ({
  allColorSettings = [],
  currentCategory,
  onCategoryChange,
  readOnly,
  showChanges = false,
}: {
  allColorSettings?: CategoryOption[];
  currentCategory?: EventColorCategory;
  onCategoryChange?: (value: EventColorCategory) => void;
  readOnly?: boolean;
  showChanges?: boolean;
}) => {
  const showCategories = Boolean(allColorSettings?.length);

  const routeToColorSettings = () => {
    const url = getUrlForFeature("preferences", { hash: "#colorCoding", relative: true });
    _openColorSettingsLink(url);
  };

  const currentCategoryOption = allColorSettings.find((setting) => {
    return setting.category === (currentCategory as string);
  });

  if (!showCategories) {
    return null;
  }

  return (
    <EventElementsWrap name="tags" showChanges={showChanges}>
      <Menu
        disabled={readOnly}
        fullWidth
        size="small"
        label={
          <div className="cw-flex cw-gap-2 cw-items-center">
            <CropSquareFilled
              className="cw-w-4 cw-h-4"
              style={{ color: currentCategoryOption?.color || bg_info_emphasis }}
            />
            {currentCategoryOption?.label || "Other"}
          </div>
        }
      >
        <MenuGroup label="Meeting category">
          {allColorSettings.map((setting) => {
            return (
              <MenuItem
                icon={CropSquareFilled}
                iconProps={{
                  style: { color: setting.color || bg_info_emphasis },
                }}
                onClick={() => {
                  onCategoryChange?.(setting.category as EventColorCategory);
                }}
                key={setting.label}
              >
                {setting.label}
              </MenuItem>
            );
          })}
        </MenuGroup>
        <MenuItem icon={Settings} onClick={routeToColorSettings}>
          Edit category colors
          <Launch className="cw-w-4 cw-h-4 cw-text-muted" />
        </MenuItem>
      </Menu>
    </EventElementsWrap>
  );
};
