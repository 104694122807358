import { Info, Notes } from "@clockwise/design-system/icons";
import { LegacyTooltip } from "@clockwise/web-commons/src/ui/tooltip";
import React from "react";
import { AgendaOmittedIcon } from "../assets/AgendaOmittedIcon";
import { EventElementsWrap } from "../atoms/EventElementsWrap";
import { QuillMarkdownEditor } from "../atoms/QuillMarkdownEditor";

export const ECAgenda = ({
  agenda,
  onChangeAgenda,
  readOnly,
  descriptionOmitted = false,
  showChanges,
}: {
  agenda: string;
  onChangeAgenda: (newAgenda: string, userModified?: boolean) => void;
  readOnly: boolean;
  descriptionOmitted?: boolean;
  showChanges?: boolean;
}) => {
  return (
    <EventElementsWrap
      icon={descriptionOmitted ? AgendaOmittedIcon : Notes}
      name="agenda"
      showChanges={showChanges}
    >
      {descriptionOmitted ? (
        <div className="cw-flex cw-items-center cw-text-subtle cw-body-sm cw-gap-1">
          We do not have access to your org's description field
          <LegacyTooltip
            showArrow={false}
            placement="bottom"
            body="Your organization has locked the description field and we are unable to read it. Please talk to your admin."
          >
            <Info className="cw-h-4 cw-w-4 cw-text-default-disabled cw-flex cw-items-center" />
          </LegacyTooltip>
        </div>
      ) : (
        <QuillMarkdownEditor
          readOnly={readOnly}
          showToolbar={!readOnly}
          handleChange={onChangeAgenda}
          text={agenda}
          additionalClasses="cw-w-full cw-overflow-auto"
        />
      )}
    </EventElementsWrap>
  );
};
