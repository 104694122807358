import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { DateTime, Duration } from "luxon";
import {
  UserSmartHoldSettingsDocument,
  UserSmartHoldSettingsQuery,
} from "./__generated__/UserSmartHoldSettings.v2.generated";

export type UserFocusTimeSettings = NonNullable<
  UserSmartHoldSettingsQuery["currentUser"]
>["focusTimeSettings"];

export type UserTravelTimeSettings = NonNullable<
  UserSmartHoldSettingsQuery["currentUser"]
>["travelTimeSettings"];

export type UserLunchHoldSettings = NonNullable<
  UserSmartHoldSettingsQuery["currentUser"]
>["lunchHoldSettings"];

export type UserMeetingReliefSettings = NonNullable<
  UserSmartHoldSettingsQuery["currentUser"]
>["meetingReliefSettings"];

export type UserSmartHoldSettings = {
  focusTimeSettings: UserFocusTimeSettings;
  lunchHoldSettings: UserLunchHoldSettings;
  meetingReliefSettings: UserMeetingReliefSettings;
  travelTimeSettings: UserTravelTimeSettings;
};

export type SmartHoldSettingsReadable = {
  focusTimeSettings: {
    maxPerWeekHours: number;
    minPerWeekHours: number;
    isProtected: boolean;
  };
  lunchHoldSettings: {
    lunchDurationMinMinutes: number;
    lunchDurationIdealMinutes: number;
    minStartTime: string | null;
    maxStartTime: string | null;
  };
  meetingReliefSettings: {
    consecutiveMeetingDurationHours: number;
    breakDurationMinutes: number;
  };
};

export const useUserSmartHoldSettings = () => {
  const { data: smartHoldSettingsData, loading } = useGatewayQuery(UserSmartHoldSettingsDocument);

  let allSettings: UserSmartHoldSettings | undefined;

  if (smartHoldSettingsData?.currentUser) {
    allSettings = {
      focusTimeSettings: smartHoldSettingsData.currentUser.focusTimeSettings,
      lunchHoldSettings: smartHoldSettingsData.currentUser.lunchHoldSettings,
      meetingReliefSettings: smartHoldSettingsData.currentUser.meetingReliefSettings,
      travelTimeSettings: smartHoldSettingsData.currentUser.travelTimeSettings,
    };
  }

  const settingsReadableValues = getSettingsFormattedForDescriptions(allSettings);

  return {
    loading,
    allSettings,
    settingsReadableValues,
  };
};

const getSettingsFormattedForDescriptions = (
  settings: UserSmartHoldSettings | undefined,
): SmartHoldSettingsReadable | undefined => {
  // This function brings all the duration => number conversion to one place
  if (!settings) return;
  // Meeting Break
  const consecutiveMeetingDurationHours = Duration.fromISO(
    settings.meetingReliefSettings.consecutiveMeetingDuration as string,
  ).as("hours");
  const breakDurationMinutes = Duration.fromISO(
    settings.meetingReliefSettings.breakDuration as string,
  ).minutes;
  // Focus Time
  const maxPerWeekHours = Duration.fromISO(settings.focusTimeSettings.maxPerWeek).as("hours");
  const minPerWeekHours = Duration.fromISO(settings.focusTimeSettings.autoDeclineThreshold).as(
    "hours",
  );
  const isProtected = settings.focusTimeSettings.autoDeclineEnabled;
  // Lunch
  const lunchDurationMinMinutes = Duration.fromISO(settings.lunchHoldSettings.minDuration as string)
    .minutes;
  const lunchDurationIdeal = Duration.fromISO(settings.lunchHoldSettings.idealDuration as string);
  const lunchDurationIdealMinutes = lunchDurationIdeal.minutes;
  const minStartTime = settings.lunchHoldSettings.timeRange.start
    ? DateTime.fromISO(settings.lunchHoldSettings.timeRange.start).toFormat("h:mm a")
    : null;
  const maxStartTime = settings.lunchHoldSettings.timeRange.end
    ? DateTime.fromISO(settings.lunchHoldSettings.timeRange.end).toFormat("h:mm a")
    : null;

  return {
    focusTimeSettings: {
      maxPerWeekHours,
      minPerWeekHours,
      isProtected,
    },
    lunchHoldSettings: {
      lunchDurationMinMinutes,
      lunchDurationIdealMinutes,
      minStartTime,
      maxStartTime,
    },
    meetingReliefSettings: {
      consecutiveMeetingDurationHours,
      breakDurationMinutes,
    },
  };
};
