import { Tooltip } from "@clockwise/design-system";
import { CollapseContent, ExpandLess, ExpandMore } from "@clockwise/icons";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { EventThread, SecondaryCard } from "@clockwise/web-commons/src/ui/event-thread";
import { Profile } from "@clockwise/web-commons/src/util/profile.util";
import { ChevronRight } from "@material-ui/icons";
import React from "react";
import { ProposalRenderSurface } from "../../../../constants";

const TextWithFurtherExplanation = ({
  explanation,
  text,
}: {
  explanation: string;
  text: string;
}) => {
  return (
    <Tooltip title={explanation} placement="top">
      <span className="cw-underline cw-decoration-dashed cw-decoration-[#D9D9D9] cw-underline-offset-4 cw-font-medium cw-whitespace-nowrap">
        {text}
      </span>
    </Tooltip>
  );
};

const EndText = ({ type }: { type: SecondaryCard }) => {
  switch (type) {
    case "unavailable":
      return <span>conflicts that I can't fix:</span>;
    case "inconvenience":
      return (
        <TextWithFurtherExplanation
          text="inconveniences:"
          explanation="Clockwise identifies booking over events such as Focus Time, lunch, meeting breaks, or on a no-meeting day as inconveniences."
        />
      );
    case "sbm":
      return (
        <span>
          conflicts that{" "}
          <TextWithFurtherExplanation
            text="can move:"
            explanation="Clockwise will only move other users' events if they are Flexible."
          />
        </span>
      );
  }
};

export const ThreadItemHeader = ({
  profiles,
  type,
  isCollapsed,
  onCollapse,
  variant = "AI_CHAT",
  onExpand,
}: {
  profiles: (Profile & { isYou?: boolean; calendarId: string })[];
  type: SecondaryCard;
  isCollapsed: boolean;
  variant?: ProposalRenderSurface;
  onCollapse?: () => void;
  onExpand?: () => void;
}) => {
  const attendeesCount = profiles.length;
  if (attendeesCount === 0) return null;

  let affectedNoun = `${attendeesCount} attendees`;
  let affectedVerb = "have";
  if (attendeesCount === 1) {
    // I specifically don't just check if the name is "You", because the name could be "You" and the user is not the current user
    // If you think this case is too crazy you can simplify it
    if (profiles[0].isYou) {
      affectedNoun = "You";
      affectedVerb = "have";
    } else {
      affectedNoun = profiles[0]?.givenName || profiles[0]?.calendarId || "Someone";
      affectedVerb = "has";
    }
  }

  const onRowClick = () => {
    if (isCollapsed) {
      onExpand?.();
    } else {
      onCollapse?.();
    }
  };

  return (
    <>
      <EventThread.Break type={type} variant={variant}>
        <div
          cw-id={isCollapsed ? "expand-thread-button" : "collapse-thread-button"}
          onClick={onRowClick}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onRowClick();
            }
          }}
          className="cw-flex cw-items-center cw-justify-between cw-rounded-md hover:cw-cursor-pointer cw-group"
        >
          <span className="cw-font-normal">
            <span className="cw-font-medium">{affectedNoun} </span>
            {affectedVerb} <EndText type={type} />
          </span>
          {isCollapsed
            ? onExpand && (
                <>
                  <ChevronRight className="cw-text-positive-muted cw-h-4 cw-w-4 cw-stroke-2 group-hover:cw-hidden" />
                  <ExpandMore className="cw-text-positive-muted cw-h-4 cw-w-4 cw-stroke-2 cw-hidden group-hover:cw-block" />
                </>
              )
            : onCollapse && (
                <>
                  <CollapseContent className="cw-text-positive-muted cw-h-4 cw-w-4 cw-stroke-2 group-hover:cw-hidden" />
                  <ExpandLess className="cw-text-positive-muted cw-h-4 cw-w-4 cw-stroke-2 cw-hidden group-hover:cw-block" />
                </>
              )}
        </div>
        {isCollapsed && (
          <AttendeeAvatarStack maxShown={11} overlap size="medium" disableAnimation>
            {profiles.map((profile) => {
              return (
                <AttendeeAvatar
                  isInOverlap
                  key={profile.calendarId}
                  size="medium"
                  profile={profile}
                />
              );
            })}
          </AttendeeAvatarStack>
        )}
      </EventThread.Break>
    </>
  );
};
