import { colors } from "@clockwise/web-commons/src/styles/color.styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const container: CSSProperties = {
  display: "flex",
};

export const myOtherThing: CSSProperties = {
  color: colors.green.standard,
};

export const styles = {
  container,
  myOtherThing,
};
