import React from "react";
import { EventDuration } from "./types";
import { durationToHeight } from "./utils/durationToHeight";

type UnnamedEventProps = {
  duration?: EventDuration;
};

export const UnnamedEvent = ({ duration = 15 }: UnnamedEventProps) => {
  const height = durationToHeight(duration);

  return (
    <div
      style={{ height: `${height}px` }}
      className={`
      cw-bg-neutral-inset
      cw-rounded
  `}
    ></div>
  );
};

export default UnnamedEvent;
