import { graphql } from "react-relay";
import "../event-category-coloring";

export const eventColoringSettingsFragments = {
  org: graphql`
    fragment EventColoringSettings_org on Org {
      ...EventCategoryColoring_org
      id
      experiments
      primaryOrgCalendar
      eventColoringSettingsErrorable {
        __typename
        ... on GraphEntityError {
          statusCode
          message
        }
        ... on EventColoringSettings {
          eventCategoryColorings {
            ...EventCategoryColoring_eventCategoryColoring
            googleColorKey
            eventCategory
            active
            isUserSet
          }
        }
      }
    }
  `,
};
