import { ALL_WORK_DAYS_SELECTED } from "#webapp/src/components/event-card/types";
import { DayOfWeek, FlexRange, FlexStatus } from "@clockwise/schema/v2";
import { DayOnOffMap } from "@clockwise/web-commons/src/ui/working-hours";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import {
  convertArrayOfDayWeekToDayMap,
  convertDayMapToFlexRangeAndAllowedDays,
} from "@clockwise/web-commons/src/util/dayMap";
import { getSimplifiedStatus } from "@clockwise/web-commons/src/util/getFlexibleStatusSimplifed";
import { noop } from "lodash";
import React from "react";
import {
  useCurrentProposal,
  useUpdateCurrentProposal,
} from "../../chat-plus-calendar/CurrentProposalContext";
import { EventFlexibilityHoverCard } from "../../web-app-calendar/event-context-menu/atoms/EventFlexibilityHoverCard";
import { EventFlexibilityTarget } from "./EventFlexibilityTarget";

export const SchedulingEventFlexibility = () => {
  const track = useTracking();
  const { currentProposal } = useCurrentProposal();
  const { updateFlexDetails } = useUpdateCurrentProposal();

  const { flexDetails } = currentProposal;

  const attendeeBoundStart = "09:00";
  const attendeeBoundEnd = "17:00";
  const attendeeBoundConflict = false;

  const flexibleStatus = getSimplifiedStatus(FlexStatus.CanMove, attendeeBoundConflict);
  const flexibleDayOnOffMap = convertArrayOfDayWeekToDayMap(
    flexDetails.allowedDays ?? ALL_WORK_DAYS_SELECTED,
    flexDetails.flexRange ?? FlexRange.Day,
    ALL_WORK_DAYS_SELECTED,
  );

  const handleChangeFlexible = (isFlexible: boolean) => {
    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_FLEXIBILITY, {
      settings: "flexible",
    });

    updateFlexDetails({
      ...flexDetails,
      isFlexible,
    });
  };

  const handleChangeFlexibleDays = (dayOnOffMap: DayOnOffMap) => {
    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_FLEXIBILITY, {
      settings: "flexible days",
    });

    const updatedFlexDetails = convertDayMapToFlexRangeAndAllowedDays(dayOnOffMap);

    updateFlexDetails({
      ...flexDetails,
      ...updatedFlexDetails,
    });
  };

  const handleChangeFlexibleRange = (range: FlexRange) => {
    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_FLEXIBILITY, {
      settings: "flexible range",
    });

    let newAllowedDays: DayOfWeek[] | undefined;
    switch (range) {
      case FlexRange.Day:
        newAllowedDays = undefined;
        break;
      case FlexRange.Week:
        newAllowedDays = undefined;
        break;
      case FlexRange.SpecificDays:
        newAllowedDays = flexDetails.allowedDays ?? ALL_WORK_DAYS_SELECTED;
        break;
    }

    updateFlexDetails({
      ...flexDetails,
      flexRange: range,
      allowedDays: newAllowedDays,
    });
  };

  const handleChangeFlexibleTimeRange = (start: string, end: string) => {
    track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_FLEXIBILITY, {
      settings: "flexible time range",
    });

    updateFlexDetails({
      ...flexDetails,
      timeOfDayRange: { start, end },
    });
  };

  return (
    <EventFlexibilityHoverCard
      flexible={flexDetails.isFlexible}
      flexibleDayOnOffMap={flexibleDayOnOffMap}
      flexibleEditable={true}
      flexibleEnd={flexDetails.timeOfDayRange?.end ?? attendeeBoundEnd}
      flexibleRange={flexDetails.flexRange ?? FlexRange.Day}
      flexibleStart={flexDetails.timeOfDayRange?.start ?? attendeeBoundStart}
      flexibleStatus={flexibleStatus}
      onChangeFlexible={handleChangeFlexible}
      onChangeFlexibleDays={handleChangeFlexibleDays}
      onChangeFlexibleRange={handleChangeFlexibleRange}
      onChangeFlexibleTimeRange={handleChangeFlexibleTimeRange}
      updatingFlexible={false}
      attendeeBoundStart={attendeeBoundStart}
      attendeeBoundEnd={attendeeBoundEnd}
      onChangeFlexibleResume={noop}
    >
      <EventFlexibilityTarget
        flexibleEditable={true}
        flexibleStatus={flexibleStatus}
        flexible={flexDetails.isFlexible}
        onChangeFlexible={handleChangeFlexible}
        updatingFlexible={false}
      />
    </EventFlexibilityHoverCard>
  );
};
