import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { UserDataSourcesFragmentDoc } from './PersonalDataSources.generated';
export type CreatePersonalDataSourceMutationMutationVariables = Types.Exact<{
  input: Types.CreateDataSourceMutationInput;
}>;


export type CreatePersonalDataSourceMutationMutation = { __typename: 'Mutation', createDataSource: { __typename: 'CreateDataSourceMutationPayload', dataSourceAuthError: Types.DataSourceAuthError | null, user: { __typename: 'User', id: string, dataSources: { __typename: 'DataSourceConnection', edges: Array<{ __typename: 'DataSourceEdge', node: { __typename: 'DataSource', id: string, name: string | null, syncTargets: Array<{ __typename: 'CrawlTarget', calendarId: string | null } | null> | null } | null } | null> | null } | null } | null } | null };


export const CreatePersonalDataSourceMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePersonalDataSourceMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateDataSourceMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createDataSource"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dataSourceAuthError"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserDataSources"}}]}}]}}]}},...UserDataSourcesFragmentDoc.definitions]} as unknown as DocumentNode<CreatePersonalDataSourceMutationMutation, CreatePersonalDataSourceMutationMutationVariables>;