import { DayCheckboxes } from "#clockwise/web-commons/src/ui/working-hours/day-checkboxes/day-checkboxes";
import { DayOnOffMap } from "#clockwise/web-commons/src/ui/working-hours/working-hours.types";
import { Day } from "@clockwise/schema";
import React from "react";

// only valid if at least one day is selected
const getIsValid = (dayOnOffMap: DayOnOffMap) => Object.values(dayOnOffMap).some((value) => value);

type Props = {
  disabled?: boolean;
  dayOnOffMap: DayOnOffMap;
  onChange: (dayOnOffMap: DayOnOffMap) => void;
};

export const DaysControl = ({ disabled, dayOnOffMap: onOffMap, onChange }: Props) => {
  const handleChange = (day: Day) => {
    const newOnOffMap = { ...onOffMap, [day]: !onOffMap[day] };
    const isValid = getIsValid(newOnOffMap);
    if (isValid && !disabled) {
      onChange(newOnOffMap);
    }
  };

  const isValid = getIsValid(onOffMap);

  return (
    <div className="cw-flex cw-flex-col cw-gap-1">
      <div className="cw-text-muted cw-text-[12px] cw-font-medium">On the days:</div>
      <DayCheckboxes dayOnOffMap={onOffMap} isValid={isValid} onToggleDay={handleChange} />
    </div>
  );
};
