import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import {
  BillingGroup,
  BillingGroupTypeEnum,
  PaymentSubscription,
  ProductTypeEnum,
} from "@clockwise/schema";
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { NewMemberPerson } from "../admin-panel-view/ap-members/APMembers.util";
import { hasPermissionToRemove } from "../admin-panel-view/ap-members/ui/member-row/member-row.util";
export const BillingGroupActionType = {
  add: "ADD",
  remove: "REMOVE",
  invite: "INVITE",
};
export type BillingGroupAction = {
  actionLabel?: string;
  type: string;
  billingGroupId: string;
};

export type BillingGroupActionMap = {
  [k: string]: BillingGroupAction;
};

export type ContextOfSelectTable = ReturnType<typeof useSelectTableForProvider>;

const Context = createContext<ContextOfSelectTable | null>(null);

export const useSelectTable = (): ContextOfSelectTable => {
  const context = useContext(Context);

  if (!context) {
    console.error(
      "Tried to access Select Table context but it is not available. Please ensure you are inside a SelectTableProvider.",
    );
  }

  return context as ContextOfSelectTable;
};

const useSelectTableForProvider = ({
  members,
  billingGroups,
  currentUserBgIdList,
}: {
  members: NewMemberPerson[];
  billingGroups: BillingGroup[];
  currentUserBgIdList: string[];
}) => {
  // ~-~-~-~-~-~-~-
  // State
  // ~-~-~-~-~-~-~-
  const [selectedMembers, setSelectedMembers] = useState<NewMemberPerson[]>([]);
  const [isSelectDialogShowing, setIsSelectDialogShowing] = useState(false);
  const [availableActions, setAvailableActions] = useState<BillingGroupActionMap>({});
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [isSelectTableDisabled, setIsSelectTableDisabled] = useState(true);
  // ~-~-~-~-~-~-~-
  // LifeCycle
  // ~-~-~-~-~-~-~-
  useEffect(() => {
    updateAvailableActions(selectedMembers);
    if (isSelectDialogShowing && selectedMembers.length === 0) {
      setIsSelectDialogShowing(false);
    } else if (!isSelectDialogShowing && selectedMembers.length > 0) {
      setIsSelectDialogShowing(true);
    }
  }, [selectedMembers]);

  // check to see if user is admin of business or member of pro plan
  useEffect(() => {
    const isUserAllowedBulkAction = billingGroups.some((bg: BillingGroup) => {
      const bgSubscription = getValue(bg.subscription);
      // const bgSubscription = getSubscriptionFromBillingGroup(bg);
      if (bgSubscription) {
        if (isSubscriptionProductTypeEnterprise(bgSubscription.subscription)) {
          return isUserAdmin(bg);
        }
        if (isSubscriptionProductTypeBusiness(bgSubscription.subscription)) {
          return isUserAdmin(bg);
        }
        if (isSubscriptionProductTypePro(bgSubscription.subscription)) {
          return isUserAMember(bg);
        }
      }
      return false;
    });
    isUserAllowedBulkAction && setIsSelectTableDisabled(false);
  }, [billingGroups]);

  // ~-~-~-~-~-~-~-
  // Checkbox functions
  // ~-~-~-~-~-~-~-

  const onSelectOneMember = (person: NewMemberPerson) => {
    const isPersonAlreadySelected = selectedMembers.some(
      (member) => member.targetCalendarId === person.targetCalendarId,
    );

    if (isPersonAlreadySelected) {
      //remove from list
      const newSelectedMemberList = selectedMembers.filter(
        (member) => member.targetCalendarId !== person.targetCalendarId,
      );
      setSelectedMembers(newSelectedMemberList);
    } else {
      //add to list
      setSelectedMembers((selectedMembers) => [...selectedMembers, person]);
    }
  };

  const onSelectAllMembers = () => {
    if (isSelectAllChecked) {
      setSelectedMembers([]);
    } else {
      setSelectedMembers(members);
    }
    setIsSelectAllChecked(!isSelectAllChecked);
  };

  const clearSelectedMembers = () => {
    setSelectedMembers([]);
    setIsSelectAllChecked(false);
  };

  // ~-~-~-~-~-~-~-
  // Billing Group and Subscription
  // ~-~-~-~-~-~-~-

  const isSubscriptionProductTypeBusiness = (subscription: PaymentSubscription) => {
    return (
      subscription.productType === ProductTypeEnum.Business_NonStandard ||
      subscription.productType === ProductTypeEnum.Business_Standard
    );
  };

  const isSubscriptionProductTypePro = (subscription: PaymentSubscription) => {
    return (
      subscription.productType === ProductTypeEnum.Pro_Standard ||
      subscription.productType === ProductTypeEnum.Pro_NonStandard
    );
  };

  const isSubscriptionProductTypeEnterprise = (subscription: PaymentSubscription) => {
    return (
      subscription.productType === ProductTypeEnum.Enterprise_Standard ||
      subscription.productType === ProductTypeEnum.Enterprise_NonStandard
    );
  };

  const isUserAdmin = (bg: BillingGroup) => {
    return (
      bg.adminOrgPersons.__typename === "OrgPersonList" &&
      bg.adminOrgPersons.list.findIndex((p) => p.isYou) >= 0
    );
  };

  const getAdminCalendarIdFromBg = (bg: BillingGroup) => {
    const adminOrgPersons = getValue(bg.adminOrgPersons)?.list || [];
    const adminEmail = adminOrgPersons?.[0]?.primaryCalendarId;
    if (adminEmail) {
      return adminEmail;
    } else {
      return null;
    }
  };

  const isUserAMember = (bg: BillingGroup) => {
    return Boolean(currentUserBgIdList.find((bgId) => bgId === bg.id));
  };

  const hasPermissionToAdd = (bg: BillingGroup) => {
    const billingGroupSubscription = getValue(bg.subscription);

    const isProductTypeSso = bg?.type === BillingGroupTypeEnum.SSO;

    const isProductTypeEnterprise =
      billingGroupSubscription &&
      isSubscriptionProductTypeEnterprise(billingGroupSubscription.subscription);

    const isProductTypeBusiness =
      billingGroupSubscription &&
      isSubscriptionProductTypeBusiness(billingGroupSubscription.subscription);

    const isProductTypePro =
      billingGroupSubscription &&
      isSubscriptionProductTypePro(billingGroupSubscription.subscription);

    const isAdmin = isUserAdmin(bg);

    const isOnlyMember = isUserAMember(bg);

    if (isProductTypeSso && !isAdmin) return false;

    return (
      (isProductTypeEnterprise && isAdmin) ||
      (isProductTypeBusiness && isAdmin) ||
      (isProductTypePro && isOnlyMember)
    );
  };

  //only update the actions if the user is in a business plan and a member
  const updateAvailableActions = (newMembers: NewMemberPerson[]) => {
    if (newMembers.length > 0) {
      const newMemberIsYouStatuses = newMembers.map((member) => member.isYou);
      const isEveryoneUser = newMembers.every((member) => !!member.userId);

      const isEveryoneInBillingGroup = (billingGroupId: string) =>
        newMembers.every(
          (member) => member.billingGroups && member?.primaryBillingGroup?.id === billingGroupId,
        );
      const isNoOneInBillingGroup = (billingGroupId: string) =>
        newMembers.every(
          (member) =>
            !member.billingGroups?.length || member?.primaryBillingGroup?.id !== billingGroupId,
        );

      const newActions = billingGroups.reduce(
        (accumulator: BillingGroupActionMap, billingGroup) => {
          const everyoneInBillingGroup = isEveryoneInBillingGroup(billingGroup.id);
          const noOneInBillingGroup = isNoOneInBillingGroup(billingGroup.id);
          const billingGroupId = billingGroup.id;
          const showRemoveIfValid = isEveryoneUser && everyoneInBillingGroup;
          const showAddIfValid = noOneInBillingGroup && isEveryoneUser;
          if (showAddIfValid && hasPermissionToAdd(billingGroup)) {
            accumulator[billingGroupId] = {
              type: BillingGroupActionType.add,
              billingGroupId: billingGroup.id,
              actionLabel: `Add to ${billingGroup.name} `,
            };
          } else if (
            showRemoveIfValid &&
            hasPermissionToRemove(billingGroup, currentUserBgIdList, newMemberIsYouStatuses)
          ) {
            accumulator[billingGroupId] = {
              type: BillingGroupActionType.remove,
              billingGroupId: billingGroup.id,
              actionLabel: `Remove from ${billingGroup.name}`,
            };
          } else if (billingGroup?.type === BillingGroupTypeEnum.SSO) {
            const adminToContact = getAdminCalendarIdFromBg(billingGroup);
            if (showRemoveIfValid) {
              const label = `Please contact ${
                adminToContact || "an admin"
              } to remove these users from ${billingGroup.name}`;
              accumulator[billingGroupId] = {
                type: "RemoveRequest",
                billingGroupId: billingGroup.id,
                actionLabel: label,
              };
            } else if (showAddIfValid) {
              const label = `Please contact ${adminToContact || "an admin"} to add these users to ${
                billingGroup.name
              }`;
              accumulator[billingGroupId] = {
                type: "AddRequest",
                billingGroupId: billingGroup.id,
                actionLabel: label,
              };
            }
          }
          return accumulator;
        },
        {},
      );
      setAvailableActions(newActions);
    } else {
      setAvailableActions({});
    }
  };

  return {
    selectedMembers,
    setSelectedMembers,
    onSelectOneMember,
    onSelectAllMembers,
    isSelectDialogShowing,
    setIsSelectDialogShowing,
    availableActions,
    isSelectAllChecked,
    isSelectTableDisabled,
    clearSelectedMembers,
    isUserAdmin,
  };
};

type PropsForProvider = {
  members: NewMemberPerson[];
  billingGroups: BillingGroup[];
  currentUserBgIdList: string[];
};

export const SelectTableProvider: FC<PropsWithChildren<PropsForProvider>> = ({
  children,
  members,
  billingGroups,
  currentUserBgIdList,
}) => {
  const value = useSelectTableForProvider({
    members,
    billingGroups,
    currentUserBgIdList,
  });

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const _SelectTableProvider = Context.Provider;
