import { graphql } from "react-relay";

export const query = graphql`
  query WebSettingsCreateJoinTeamRelayQuery($orgRelayId: ID!) {
    viewer: viewer {
      __typename
      teamQueryResultErrorable(query: "", orgRelayId: $orgRelayId, excludeMyTeams: false) {
        ... on TeamQueryResult {
          __typename
          query
          teams {
            teamId
          }
        }
      }
    }
    org: node(id: $orgRelayId) {
      ... on Org {
        id
        ...TeamJoinCreateDialogRenderer_org
        userTeams {
          __typename
          ... on TeamList {
            list {
              teamId
              teamName
            }
          }
          ... on GraphEntityError {
            statusCode
            message
          }
        }
      }
    }
  }
`;
