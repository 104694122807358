import { TimeSlot } from "@clockwise/schema";
import { findIndex } from "lodash";

// [TimeSlot, start text, end text]
export const TimeSlotTextTuples = [
  [TimeSlot.T_00_00, "12:00am", "12:30am"],
  [TimeSlot.T_00_30, "12:30am", "1:00am"],
  [TimeSlot.T_01_00, "1:00am", "1:30am"],
  [TimeSlot.T_01_30, "1:30am", "2:00am"],
  [TimeSlot.T_02_00, "2:00am", "2:30am"],
  [TimeSlot.T_02_30, "2:30am", "3:00am"],
  [TimeSlot.T_03_00, "3:00am", "3:30am"],
  [TimeSlot.T_03_30, "3:30am", "4:00am"],
  [TimeSlot.T_04_00, "4:00am", "4:30am"],
  [TimeSlot.T_04_30, "4:30am", "5:00am"],
  [TimeSlot.T_05_00, "5:00am", "5:30am"],
  [TimeSlot.T_05_30, "5:30am", "6:00am"],
  [TimeSlot.T_06_00, "6:00am", "6:30am"],
  [TimeSlot.T_06_30, "6:30am", "7:00am"],
  [TimeSlot.T_07_00, "7:00am", "7:30am"],
  [TimeSlot.T_07_30, "7:30am", "8:00am"],
  [TimeSlot.T_08_00, "8:00am", "8:30am"],
  [TimeSlot.T_08_30, "8:30am", "9:00am"],
  [TimeSlot.T_09_00, "9:00am", "9:30am"],
  [TimeSlot.T_09_30, "9:30am", "10:00am"],
  [TimeSlot.T_10_00, "10:00am", "10:30am"],
  [TimeSlot.T_10_30, "10:30am", "11:00am"],
  [TimeSlot.T_11_00, "11:00am", "11:30am"],
  [TimeSlot.T_11_30, "11:30am", "12:00pm"],
  [TimeSlot.T_12_00, "12:00pm", "12:30pm"],
  [TimeSlot.T_12_30, "12:30pm", "1:00pm"],
  [TimeSlot.T_13_00, "1:00pm", "1:30pm"],
  [TimeSlot.T_13_30, "1:30pm", "2:00pm"],
  [TimeSlot.T_14_00, "2:00pm", "2:30pm"],
  [TimeSlot.T_14_30, "2:30pm", "3:00pm"],
  [TimeSlot.T_15_00, "3:00pm", "3:30pm"],
  [TimeSlot.T_15_30, "3:30pm", "4:00pm"],
  [TimeSlot.T_16_00, "4:00pm", "4:30pm"],
  [TimeSlot.T_16_30, "4:30pm", "5:00pm"],
  [TimeSlot.T_17_00, "5:00pm", "5:30pm"],
  [TimeSlot.T_17_30, "5:30pm", "6:00pm"],
  [TimeSlot.T_18_00, "6:00pm", "6:30pm"],
  [TimeSlot.T_18_30, "6:30pm", "7:00pm"],
  [TimeSlot.T_19_00, "7:00pm", "7:30pm"],
  [TimeSlot.T_19_30, "7:30pm", "8:00pm"],
  [TimeSlot.T_20_00, "8:00pm", "8:30pm"],
  [TimeSlot.T_20_30, "8:30pm", "9:00pm"],
  [TimeSlot.T_21_00, "9:00pm", "9:30pm"],
  [TimeSlot.T_21_30, "9:30pm", "10:00pm"],
  [TimeSlot.T_22_00, "10:00pm", "10:30pm"],
  [TimeSlot.T_22_30, "10:30pm", "11:00pm"],
  [TimeSlot.T_23_00, "11:00pm", "11:30pm"],
  [TimeSlot.T_23_30, "11:30pm", "12:00am"],
] as const;

export const getTimeSlotsBefore = (timeSlot: TimeSlot, inclusive = true) => {
  let index = findIndex(TimeSlotTextTuples, ([ts]) => ts === timeSlot);

  if (index) {
    if (inclusive) {
      index = index + 1;
    }
  } else {
    index = TimeSlotTextTuples.length;
  }

  return TimeSlotTextTuples.slice(0, index);
};

export const getTimeSlotsAfter = (timeSlot: TimeSlot, inclusive = true) => {
  let index = findIndex(TimeSlotTextTuples, ([ts]) => ts === timeSlot);

  if (index) {
    if (!inclusive) {
      index = index + 1;
    }
  } else {
    index = 0;
  }

  return TimeSlotTextTuples.slice(index, TimeSlotTextTuples.length);
};
