/**
 * @generated SignedSource<<904b184a15d9c0151e7ed0e33340ab38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventCategoryColoring_org$data = {
  readonly id: string;
  readonly primaryOrgCalendar: string;
  readonly " $fragmentType": "EventCategoryColoring_org";
};
export type EventCategoryColoring_org$key = {
  readonly " $data"?: EventCategoryColoring_org$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventCategoryColoring_org">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventCategoryColoring_org",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryOrgCalendar",
      "storageKey": null
    }
  ],
  "type": "Org",
  "abstractKey": null
};

(node as any).hash = "ac3b7e78e5ef05539abf4c1769f9e516";

export default node;
