import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import {
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import usePlannerMetaData from "../../web-app-calendar/hooks/usePlannerMetaData";
import { useProcessMessage } from "./hooks/useProcessMessage";
import { findMessageToProcess } from "./utils/findMessageToProcess";

export const AIDeepLinkSync = () => {
  // This hook handles deep links to proposals
  useProcessMessageSync();

  // This hook handles deep links to event details
  useEventDeeplinkSync();

  return null;
};

const useProcessMessageSync = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { processMessage } = useProcessMessage();

  useEffect(() => {
    if (searchParams) {
      const message = findMessageToProcess(searchParams);
      if (message) {
        console.log("processing message...", message);
        void processMessage(...message);
        navigate(appPaths.ai);
      }
    }
  }, []);
};

const useEventDeeplinkSync = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { primaryCalendarId } = usePlannerMetaData();
  const updateActiveEvent = useUpdateActiveEvent();

  useEffect(() => {
    if (primaryCalendarId) {
      const eventId = searchParams.get("eid");

      if (eventId) {
        // update the active event; assuming own calendar
        updateActiveEvent({
          externalEventId: eventId,
          calendarId: primaryCalendarId,
          type: EventType.Event,
        });

        // Remove the eventid parameter from the URL
        searchParams.delete("eid");
        const newLocation = {
          ...location,
          search: searchParams.toString(),
        };
        navigate(
          `${newLocation.pathname}${isEmpty(newLocation.search) ? "" : "?"}${newLocation.search}${
            newLocation.hash
          }`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryCalendarId]);
};
