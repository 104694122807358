import { round } from "lodash";
import { DateTime } from "luxon";
import React, { CSSProperties } from "react";
import TimeMarker from "./TimeMarker";
import _useCurrentDateTime from "./hooks/useCurrentDateTime";

const TEN_MINUTES = 10 * 60 * 1000;

type Props = {
  daysRendered: DateTime[];
  useDateTime?: typeof _useCurrentDateTime;
  leftOverride?: string;
};

export const DateTimeMarker = ({
  daysRendered = [],
  useDateTime = _useCurrentDateTime,
  leftOverride,
}: Props) => {
  const dateTime = useDateTime(TEN_MINUTES);
  const renderDay = daysRendered.find((day) => day.hasSame(dateTime, "day"));
  const renderDayIndex = daysRendered.findIndex((day) => day.hasSame(dateTime, "day"));
  const widthPercent = 100 / daysRendered.length;
  const width = round(widthPercent, 3);
  const left = round(widthPercent * renderDayIndex, 3);
  const styles: CSSProperties = {
    width: leftOverride ? `calc(${width}% - ${leftOverride})` : `${width}%`,
    left: leftOverride ? leftOverride : `${left}%`,
  };

  if (!renderDay) {
    return null;
  }

  return (
    <div className="cw-pointer-events-none cw-absolute cw-h-full" style={styles}>
      <TimeMarker />
    </div>
  );
};

export default DateTimeMarker;
