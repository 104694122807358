import { clone, every, forEach, intersection, map } from "lodash";
import { DateTime, Interval } from "luxon";

type ISODate = string;

export type Cell = {
  column: number;
  row: number;
  colSpan: number;
  isLast: boolean;
};

interface IObjectWithInterval {
  interval: Interval;
}

export type Nullable<T> = T | null;

export type ElementColumn<T> = {
  elements: Nullable<T>[];
  lastPosition: number;
  elementCount: number;
};

export const positionObjectsInDates = <T extends IObjectWithInterval>(
  dates: DateTime[],
  objects: T[],
  hasPriority?: (obj: T) => boolean,
) => {
  const dateToColumnMap = new Map<ISODate, ElementColumn<T>>();
  const objectToCellMap = new Map<T, Cell>();
  const sortedObjects = clone(objects)
    .sort(ObjectWithIntervalComparator)
    .sort(byPriority(hasPriority));

  forEach(dates, (date) =>
    dateToColumnMap.set(date.toISODate(), {
      elements: [],
      lastPosition: -1,
      elementCount: 0,
    }),
  );
  forEach(sortedObjects, (object) =>
    objectToCellMap.set(object, {
      column: -1,
      row: -1,
      colSpan: -1,
      isLast: true,
    }),
  );

  forEach(sortedObjects, (object) => {
    const objectDates = object.interval.splitBy({ day: 1 }).map((d) => d.start);
    findSlots(object, objectDates, dateToColumnMap, objectToCellMap, 0);
  });

  return { dateToColumnMap, objectToCellMap };
};

// sort by duration, then start date, then end date
const ObjectWithIntervalComparator = (
  { interval: a }: IObjectWithInterval,
  { interval: b }: IObjectWithInterval,
) => {
  if (a.length("day") !== b.length("day")) {
    return a.length("day") < b.length("day") ? 1 : -1;
  } else if (a.start.toISODate() !== b.start.toISODate()) {
    return a.start > b.start ? 1 : -1;
  } else if (a.end.toISODate() !== b.end.toISODate()) {
    return a.end > b.end ? 1 : -1;
  }

  return 0;
};

const byPriority = <T extends IObjectWithInterval>(hasPriority?: (obj: T) => boolean) => (
  a: T,
  b: T,
) => {
  if (!hasPriority) {
    return 0;
  }

  const aHasPriority = hasPriority(a);
  const bHasPriority = hasPriority(b);
  if (aHasPriority && !bHasPriority) {
    return -1;
  } else if (!aHasPriority && bHasPriority) {
    return 1;
  }

  return 0;
};

const findSlots = (
  object: IObjectWithInterval,
  objectDates: DateTime[],
  dateToColumnMap: Map<ISODate, ElementColumn<IObjectWithInterval>>,
  objectToCellMap: Map<IObjectWithInterval, Cell>,
  column: number,
): void => {
  const firstColumn = [...dateToColumnMap.values()][0];

  // if we have new depth; increase all columns by 1
  if (firstColumn.elements.length <= column) {
    dateToColumnMap.forEach((eventColumn) => {
      eventColumn.elements.push(null);
    });
  }

  // determine if we can slot the current event in the current column
  const canSlot = every(
    objectDates,
    (objectDate) => !dateToColumnMap.get(objectDate.toISODate())?.elements[column],
  );

  // update maps or recurse until we can
  if (canSlot) {
    const slottedDates = [...dateToColumnMap.keys()];
    const intersectionDates = intersection(
      slottedDates,
      map(objectDates, (d) => d.toISODate()),
    );
    const row = slottedDates.indexOf(intersectionDates[0]);
    const colSpan = intersectionDates.length;

    objectToCellMap.set(object, {
      column,
      row,
      colSpan,
      isLast: true,
    });

    forEach(objectDates, (objectDate) => {
      const currentColumn = dateToColumnMap.get(objectDate.toISODate()) || [];
      if ("elements" in currentColumn) {
        // TODO: cleanup
        forEach(currentColumn.elements, (element) => {
          if (element) {
            const previousElement = objectToCellMap.get(element);
            if (previousElement) {
              objectToCellMap.set(element, { ...previousElement, isLast: false });
            }
          }
        });

        currentColumn.elements.splice(column, 1, object);
        currentColumn.lastPosition = column;
        currentColumn.elementCount += 1;
      }
    });
  } else if (column < 50) {
    // bail out if we have tried too many columns; otherwise recurse
    return findSlots(object, objectDates, dateToColumnMap, objectToCellMap, column + 1);
  }

  return;
};
