import * as ISchema from "#webapp/src/__schema__";
import { CalendarColorPicker } from "#webapp/src/components/calendar-color-picker";
import { updateEventColoringSettings } from "#webapp/src/mutations";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { getCategoryLabels, isValidCategory } from "#webapp/src/util/event-category-coloring.util";
import { logger } from "#webapp/src/util/logger.util";
import { Tooltip } from "@clockwise/design-system";
import { HelpFilled } from "@clockwise/icons";
import * as React from "react";
import toast from "react-hot-toast";
import { createFragmentContainer, RelayProp } from "react-relay";
import { eventCategoryColoringFragments } from "./EventCategoryColoring.gql";

//////////////////
// TYPES
//////////////////
type EventColoringSettingsCmptProps = {
  relay: RelayProp;
  org: ISchema.IOrg;
  eventCategoryColoring: ISchema.IEventCategoryColoring;
};

//////////////////
// COMPONENT
//////////////////
const EventCategoryColoringCmpt = ({
  relay,
  org,
  eventCategoryColoring,
}: EventColoringSettingsCmptProps) => {
  const onError = (error: string) => {
    logger.error("updateEventColoringSettings failed", error);
    toast.error("Color selection failed to save. Please try again.");
  };

  const onSuccess = () => {
    toast.success("Saved. Your event colors will update shortly.");
  };

  const onSelectColor = (key: string | null) => {
    const active = !!key;
    const googleColorKey = key ? key : eventCategoryColoring.googleColorKey;

    // new coloring settings
    const updatedColoring: ISchema.IEventCategoryColoring = {
      ...eventCategoryColoring,
      googleColorKey,
      active,
    };

    if (active) {
      track(TrackingEvents.COLOR_CATEGORIES.SETTINGS_ENABLED, {
        ...updatedColoring,
      });
    } else {
      track(TrackingEvents.COLOR_CATEGORIES.SETTINGS_DISABLED, {
        ...updatedColoring,
      });
    }

    // input for mutation
    const input = {
      orgRelayId: org.id,
      eventCategoryColorings: [updatedColoring],
    };

    // fire off the mutation
    updateEventColoringSettings(relay.environment, input, onSuccess, onError);
  };

  const { eventCategory, googleColorKey, active } = eventCategoryColoring;

  if (!isValidCategory(eventCategory)) {
    logger.warn(`front-end cannot translate eventCategory ${eventCategory}`);
    return <span />;
  }

  const labels = getCategoryLabels(eventCategory);

  return (
    <div className="cw-flex cw-items-center cw-justify-between cw-w-full cw-mb-1">
      <div className="cw-flex cw-items-center cw-mr-6">
        <div className="cw-text-default">{labels.label}</div>
        <Tooltip title={labels.description} placement="bottom">
          <HelpFilled fontSize="inherit" className="cw-ml-1 cw-text-subtle" />
        </Tooltip>
      </div>
      <CalendarColorPicker
        active={active}
        selectedColorIndex={googleColorKey}
        onSelect={onSelectColor}
      />
    </div>
  );
};

//////////////////
// RELAY
//////////////////
export const EventCategoryColoring = createFragmentContainer(
  EventCategoryColoringCmpt,
  eventCategoryColoringFragments,
);
