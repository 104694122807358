import * as React from "react";
import { createFragmentContainer } from "react-relay";

// web-app-onboarding-complete imports
import { webAppOnboardingCompleteFragments } from "./WebAppOnboardingComplete.gql";
import { styles } from "./WebAppOnboardingComplete.styles";
import { IContainer, IProps, IState } from "./WebAppOnboardingCompleteTypes";

// assets
import webExtensionImage from "./assets/web-extension.png";

// util
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";

// material-ui
import { Button, Typography } from "@clockwise/design-system";
import { ChevronRight, NavigateNext } from "@clockwise/design-system/icons";
import { browserName, extensionURLForBrowser } from "@clockwise/web-commons/src/util/browser.util";
import withStyles from "@material-ui/core/styles/withStyles";

///////////////
// Component //
///////////////
class WebAppOnboardingCompleteCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  private onClickInstall = () => {
    if (!extensionURLForBrowser) {
      return;
    }

    track(TrackingEvents.ONBOARDING.ONBOARDING_LANDING_CHROME_EXTENSION_INSTALL);
    window.open(
      `${extensionURLForBrowser}?utm_medium=referral&utm_source=product&utm_campaign=web-callout`,
      "_blank",
    );
  };

  private openSlack = () => {
    const { org } = this.props;

    const firstTeamId =
      org &&
      org.slackSettingsErrorable.__typename !== "GraphEntityError" &&
      org.slackSettingsErrorable.list.length &&
      org.slackSettingsErrorable.list[0].slackTeamId;

    track(TrackingEvents.ONBOARDING.ONBOARDING_LANDING_OPEN_SLACK, { slackTeamId: firstTeamId });
    window.open(`slack://open?team=${firstTeamId}`, "_blank");
  };

  public render() {
    const { org } = this.props;

    const isSlackConnected =
      org &&
      org.slackSettingsErrorable.__typename !== "GraphEntityError" &&
      org.slackSettingsErrorable.list.length;

    return (
      <div className={this.props.classes.container}>
        <div>
          <div className={this.props.classes.successIcon}>🎉</div>
          <Typography variant="h5">You're all set!</Typography>
        </div>
        {isSlackConnected ? (
          <div className={this.props.classes.slackContainer}>
            <Typography variant="body1" className={this.props.classes.slackText}>
              You have successfully set up Clockwise. Check Slack for a direct message sent from
              Clockwise to learn more about how we'll help you make time for what matters!
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }} className="cw-mt-9">
              <Button variant="solid" sentiment="positive" onClick={this.openSlack}>
                Open Slack
                <NavigateNext className="cw-ml-1" />
              </Button>
            </div>
          </div>
        ) : null}
        <div className={this.props.classes.textWithDivider}>
          <hr />
          <div>
            {isSlackConnected
              ? "Or, get even more from Clockwise:"
              : "Get even more from Clockwise:"}
          </div>
          <hr />
        </div>
        {extensionURLForBrowser && (
          <div className={this.props.classes.cards}>
            <div>
              <div onClick={this.onClickInstall}>
                <Typography className={this.props.classes.cardH6} variant="h6">
                  {`Install Clockwise for ${browserName}`}
                  <ChevronRight />
                </Typography>
                <Typography className={this.props.classes.cardBody} variant="body1">
                  {`Clockwise works best right next to your calendar. Schedule meetings at the least
                disruptive times, instantly forecast your team’s bandwidth, stay a step ahead with
                smart calendar suggestions, and more with Clockwise’s ${browserName} extension.`}
                </Typography>
              </div>
              <img src={webExtensionImage} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

/////////////////
// With Styles //
/////////////////
const WebAppOnboardingCompleteStyled = withStyles(styles)(WebAppOnboardingCompleteCmpt);

// Relay //
///////////
export const WebAppOnboardingComplete = createFragmentContainer<IContainer>(
  WebAppOnboardingCompleteStyled,
  webAppOnboardingCompleteFragments,
);
