import { Button } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import * as React from "react";

// internal
import { styles } from "./TutorialTip.styles";

// components
import { ClockwiseLogoFull } from "../logo/ClockwiseLogoFull";

export type TipPosition = "top" | "left" | "right" | "bottom" | "leftBottom";

export interface IProps extends WithStyles<typeof styles> {
  hide: boolean;
  dismissText?: string;
  moreText?: string;
  onDismiss?: () => void;
  onMore?: () => void;
  tooltipContents: JSX.Element | null;
  containerStyle?: React.CSSProperties;
  tipPosition?: TipPosition;
  tipColor?: "white" | "brandEmphasis";
}

export interface IState {}

// REMINDER: Tracking TutorialTip clicks is often desired.
class TutorialTipComponent extends React.Component<IProps, IState> {
  static defaultProps = {
    tipPosition: "top" as TipPosition,
  };

  ///////////////
  // Rendering //
  ///////////////
  public render() {
    const {
      hide,
      dismissText,
      tooltipContents,
      onDismiss,
      onMore,
      moreText,
      classes,
      containerStyle,
      tipPosition,
      tipColor = "brandEmphasis",
    } = this.props;

    return (
      <div
        className={`${classes.container} ${tipColor || ""}`}
        style={{
          ...(hide
            ? {
                visibility: "hidden",
                opacity: 0,
                paddingTop: 0,
                paddingBottom: 0,
                height: 0,
                overflow: "hidden",
                transition: "all .35s",
              }
            : {}),
          ...containerStyle,
        }}
      >
        <div className={`${classes.arrow} ${tipPosition} ${tipColor || ""}`}></div>
        <ClockwiseLogoFull onEmphasis={tipColor !== "white"} className="cw-w-[86px] cw-pb-2" />
        {tooltipContents}
        <div>
          {onDismiss && dismissText ? (
            <Button
              className={`${classes.button} ${tipColor || ""}`}
              onClick={onDismiss}
              variant="outlined"
            >
              {dismissText}
            </Button>
          ) : null}
          {onMore && moreText ? (
            <Button
              style={{ marginLeft: 8 }}
              className={`${classes.button} ${tipColor}`}
              onClick={onMore}
              variant="text"
            >
              {moreText}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}

/////////////////
// With Styles //
/////////////////

export const TutorialTip = withStyles(styles)(TutorialTipComponent);
