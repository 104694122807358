import { InviteSearchPerson } from "#webapp/src/components/invite-search";
import { some, uniqBy } from "lodash";
import React, { ReactNode, createContext, useMemo, useState } from "react";

interface InviteStepState {
  invitees: InviteSearchPerson[];
  addInvitees: (invitees: InviteSearchPerson[]) => void;
  removeInvitees: (invitees: InviteSearchPerson[]) => void;
}
/**
 * Context for persisting invitee selections made during this step of the onboarding flow
 */
const InviteStepContext = createContext<InviteStepState | null>(null);

export const InviteStepProvider = ({ children }: { children: ReactNode }) => {
  const [invitees, setInvitees] = useState<InviteSearchPerson[]>([]);
  const value = useMemo(
    () => ({
      invitees,
      addInvitees: (toAdd: InviteSearchPerson[]) => {
        setInvitees((invitees) => uniqBy([...invitees, ...toAdd], "primaryCalendarId"));
      },
      removeInvitees: (toRemove: InviteSearchPerson[]) => {
        setInvitees((invitees) =>
          invitees.filter(({ primaryCalendarId }) => !some(toRemove, { primaryCalendarId })),
        );
      },
    }),
    [invitees],
  );
  return <InviteStepContext.Provider value={value}>{children}</InviteStepContext.Provider>;
};

/**
 * A hook that returns a non-nullabled {@link InviteStepContext}.
 * If invoked without an {@link InviteStepProvider} in the component tree, an error will be thrown.
 */
export const useInviteStepContext = () => {
  const context = React.useContext(InviteStepContext);
  if (!context) {
    throw new Error("useInviteStepContext must be used within an InviteStepProvider");
  }
  return context;
};
