import { useFullStory } from "#webapp/src/util/fullstory";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import AIChatPlusCalendar from "../components/chat-plus-calendar/AIChatPlusCalendar";
import { page, PageEvents } from "../util/analytics.util";

const AIPage = () => {
  const { identify } = useFullStory();

  useEffect(() => {
    page(PageEvents.AI_PAGE);
  }, []);

  useEffect(() => {
    identify();
  }, [identify]);

  return (
    <>
      <Helmet>
        <title>Clockwise</title>
      </Helmet>
      <div className="cw-w-full">
        <AIChatPlusCalendar />
      </div>
    </>
  );
};

export default AIPage;
