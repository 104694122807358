import { logger } from "@clockwise/client-commons/src/util/logger";
import { Button, Select, SelectOption, TextField } from "@clockwise/design-system";
import { Business } from "@clockwise/design-system/icons";
import { Home, LocationOff } from "@clockwise/icons";
import { LocationTypeEnum, WorkingLocation } from "@clockwise/schema";
import React from "react";
import { useWorkingLocation } from "../hooks/useWorkingLocation";
import { WorkingLocationAddressSearch } from "./WorkingLocationAddressSearch";

const WorkingLocationTypeIcon = ({ type }: { type: LocationTypeEnum }) => {
  switch (type) {
    case LocationTypeEnum.HOME:
      return <Home />;
    case LocationTypeEnum.OFFICE:
      return <Business />;
    case LocationTypeEnum.OTHER:
      return <LocationOff />;
  }
};

const WorkingLocationTypePicker = ({
  value,
  onSelect,
}: {
  value: LocationTypeEnum;
  onSelect: (type: LocationTypeEnum) => void;
}) => {
  return (
    <Select
      size="small"
      variant="default"
      value={value}
      onChange={(value) => {
        onSelect(value);
      }}
      hideIcon
    >
      <SelectOption key={LocationTypeEnum.OFFICE} value={LocationTypeEnum.OFFICE} icon={Business}>
        Office
      </SelectOption>
      <SelectOption key={LocationTypeEnum.HOME} value={LocationTypeEnum.HOME} icon={Home}>
        Home
      </SelectOption>
      <SelectOption key={LocationTypeEnum.OTHER} value={LocationTypeEnum.OTHER} icon={LocationOff}>
        Unspecified
      </SelectOption>
    </Select>
  );
};

const WorkingLocationEditForm = ({
  workingLocation,
  onCancel,
}: {
  workingLocation?: WorkingLocation;
  onCancel?: () => void;
}) => {
  const {
    onUpdateWorkingLocation,
    onDeleteWorkingLocation,
    onCreateWorkingLocation,
  } = useWorkingLocation();

  const [locationType, setLocationType] = React.useState(
    (workingLocation && workingLocation.locationType) || LocationTypeEnum.HOME,
  );
  const [name, setName] = React.useState((workingLocation && workingLocation.name) || "");
  const [address, setAddress] = React.useState((workingLocation && workingLocation.address) || "");
  const [timeZone, _setTimeZone] = React.useState(
    (workingLocation && workingLocation.timeZone) || "",
  );

  const onSave = () => {
    if (workingLocation) {
      onUpdateWorkingLocation({
        workingLocation: {
          id: workingLocation.id,
          address,
          locationType,
          name,
          timeZone,
        },
        workingLocationId: workingLocation.id,
      });
    } else {
      onCreateWorkingLocation({
        address: address,
        locationType: locationType,
        name: name,
        timeZone: timeZone,
      });
    }
  };

  const onDelete = () => {
    if (!workingLocation) {
      return logger.error("Failed to delete working location");
    }

    onDeleteWorkingLocation({
      workingLocationId: workingLocation.id,
    });
  };

  return (
    <div className="cw-flex cw-flex-row cw-items-center cw-w-full cw-mb-4">
      <div className="cw-flex cw-flex-row cw-items-end cw-gap-2 cw-w-full cw-mr-2">
        <div className="cw-flex-none">
          <WorkingLocationTypePicker value={locationType} onSelect={(v) => setLocationType(v)} />
        </div>
        <div className="cw-flex-none">
          <TextField label="name" value={name || ""} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="cw-flex-1">
          <WorkingLocationAddressSearch
            label="address"
            onSelectAddress={(address) => setAddress(address)}
          />
        </div>
      </div>
      <div className="cw-flex cw-flex-row cw-items-center cw-ml-auto cw-self-end">
        <Button
          sentiment="positive"
          variant="solid"
          size="small"
          onClick={() => {
            onSave();
            onCancel && onCancel();
          }}
        >
          Save
        </Button>
        {workingLocation && (
          <>
            <Button
              sentiment="destructive"
              variant="solid"
              size="small"
              onClick={() => {
                onDelete();
              }}
            >
              Delete
            </Button>
            <Button
              sentiment="neutral"
              variant="text"
              size="small"
              onClick={() => onCancel && onCancel()}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const WorkingLocationAddressSetting = ({
  workingLocation,
}: {
  workingLocation: WorkingLocation;
}) => {
  const [editMode, setEditMode] = React.useState(false);
  const { locationType, address, name } = workingLocation;

  if (editMode) {
    return (
      <WorkingLocationEditForm
        workingLocation={workingLocation}
        onCancel={() => setEditMode(false)}
      />
    );
  }

  return (
    <div className="cw-flex cw-flex-row cw-items-center cw-w-full cw-mb-4">
      <div className="cw-mr-2">
        <WorkingLocationTypeIcon type={locationType} />
      </div>
      <div>
        <div className="cw-mb-1">{name}</div>
        <div className="cw-text-subtle cw-text-11">{address}</div>
      </div>
      <div className="cw-flex cw-flex-row cw-items-center cw-ml-auto">
        <Button sentiment="neutral" variant="text" size="small" onClick={() => setEditMode(true)}>
          Edit
        </Button>
      </div>
    </div>
  );
};

export const WorkingLocationAddressSettings = () => {
  const { workingLocations } = useWorkingLocation();

  return (
    <>
      <h2 className="cw-font-brand cw-font-bold cw-text-xl cw-mb-4">
        Manage your working locations
      </h2>
      <div>
        {(workingLocations || []).map((wl) => (
          <WorkingLocationAddressSetting
            key={`working-location-setting-item-${wl.id}`}
            workingLocation={wl}
          />
        ))}
      </div>
      <h2 className="cw-font-brand cw-font-bold cw-text-xl cw-mb-4">
        Or create a new working location
      </h2>
      <div className="cw-leading-6 cw-mt-2 cw-mb-5">
        <WorkingLocationEditForm />
      </div>
    </>
  );
};
