import { Select, SelectOption } from "@clockwise/design-system";
import { Timelapse } from "@clockwise/icons";
import React, { useMemo } from "react";
import { formatDuration } from "../../util/duration.util";
import { MultiSelect } from "../multi-select/MultiSelect";
import { DURATIONS } from "./consts";

interface DurationPickerRowProps {
  durations: number[];
  defaultDuration: number | null;
  onChange: (durations: number[], defaultDuration: number | null) => void;
  errorMessage?: string;
}

const buildDurationOptions = (durations: readonly number[]) => {
  return durations.map((duration) => (
    <SelectOption key={duration} value={duration.toString(10)}>
      {formatDuration(duration)}
    </SelectOption>
  ));
};

export const DurationPickerRow = ({
  durations,
  defaultDuration,
  onChange,
  errorMessage,
}: DurationPickerRowProps) => {
  const handleDurationChange = (newSelections: string[]) => {
    const newDurations = newSelections.map((d) => parseInt(d, 10)).sort((a, b) => a - b);
    // If defaultDuration is null or we removed the default duration from the selected durations,
    // set the first duration in the selection array as the default.
    // It's still possible defaultDuration could be null if no durations are selected.
    const newDefaultDuration =
      !defaultDuration || !newDurations.includes(defaultDuration)
        ? newDurations[0] ?? null
        : defaultDuration;

    onChange(newDurations, newDefaultDuration);
  };
  const handleDefaultDurationChange = (newDefaultDuration: string) => {
    onChange(durations, parseInt(newDefaultDuration));
  };

  const selectedDurations = useMemo(() => durations.map((d) => d.toString(10)), [durations]);
  const selectedDefaultDuration = useMemo(
    () => (defaultDuration ? defaultDuration.toString(10) : ""),
    [defaultDuration],
  );

  return (
    <div className="cw-body-base cw-flex cw-flex-col cw-gap-1">
      <div className="cw-flex cw-gap-3 cw-items-start cw-relative">
        <div className="cw-flex cw-gap-3 cw-items-center cw-h-8">
          <Timelapse className="cw-w-5 cw-h-5 cw-text-muted cw-mr-1" />
          <span className="cw-body-base cw-whitespace-nowrap">Let people schedule</span>
        </div>
        <MultiSelect
          cw-id="duration-multi-select"
          value={selectedDurations}
          onChange={handleDurationChange}
          placeholder="Select durations"
          error={!!errorMessage}
          errorMessage={errorMessage}
        >
          {buildDurationOptions(DURATIONS)}
        </MultiSelect>
      </div>
      {selectedDurations.length > 1 && (
        <div className="cw-flex cw-gap-3 cw-items-center cw-pl-9">
          <div className="cw-flex cw-items-center cw-gap-1 cw-caption">
            <span className="cw-text-muted">Default to</span>
            <Select
              value={selectedDefaultDuration}
              onChange={handleDefaultDurationChange}
              size="mini"
              variant="inline"
              showExpandIcon
            >
              <SelectOption value="">no durations selected</SelectOption>
              {buildDurationOptions(selectedDurations.map((s) => parseInt(s, 10)))}
            </Select>
          </div>
        </div>
      )}
    </div>
  );
};
