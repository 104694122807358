import { DateTime, Interval } from "luxon";

/**
 * Breaks up any intervals that span across day boundaries into separate intervals
 * @param intervals Array of Luxon Intervals
 * @returns Array of Intervals where none cross day boundaries
 */
export const breakDayBoundaryIntervals = (intervals: Interval[]): Interval[] => {
  if (!intervals || intervals.length === 0) {
    return [];
  }

  const result: Interval[] = [];

  intervals.forEach((interval) => {
    if (!interval.isValid) {
      return;
    }

    const startDate = interval.start;
    const endDate = interval.end;

    // If the interval is within the same day, just add it to the result
    if (startDate.hasSame(endDate, "day")) {
      result.push(interval);
      return;
    }

    // Handle intervals that span across days
    let currentDate = startDate.startOf("day");

    // Create an interval for each day that the original interval spans
    while (currentDate < endDate) {
      const nextDay = currentDate.plus({ days: 1 }).startOf("day");

      // For the first day, use the original start time to the end of the day
      if (currentDate.hasSame(startDate, "day")) {
        result.push(Interval.fromDateTimes(startDate, currentDate.endOf("day")));
      }
      // For the last day, use the start of the day to the original end time
      else if (
        nextDay.minus({ seconds: 1 }).hasSame(endDate, "day") ||
        nextDay.minus({ seconds: 1 }) > endDate
      ) {
        result.push(Interval.fromDateTimes(currentDate.startOf("day"), endDate));
        break; // Exit the loop as we've reached the end date
      }
      // For days in between, use the full day
      else {
        result.push(Interval.fromDateTimes(currentDate.startOf("day"), currentDate.endOf("day")));
      }

      currentDate = nextDay;
    }
  });

  return result;
};

/**
 * Merges overlapping intervals into a single interval
 * @param intervals Array of Luxon Intervals
 * @returns Array of non-overlapping Intervals
 */
export const mergeOverlappingIntervals = (intervals: Interval[]): Interval[] => {
  if (!intervals || intervals.length === 0) {
    return [];
  }

  // Sort intervals by start time
  const sortedIntervals = [...intervals].sort((a, b) => {
    return a.start.toMillis() - b.start.toMillis();
  });

  const result: Interval[] = [];
  let currentInterval = sortedIntervals[0];

  for (let i = 1; i < sortedIntervals.length; i++) {
    const nextInterval = sortedIntervals[i];

    // If intervals overlap or are adjacent, merge them
    if (
      currentInterval.overlaps(nextInterval) ||
      currentInterval.abutsStart(nextInterval) ||
      currentInterval.abutsEnd(nextInterval)
    ) {
      // Create a new interval that spans both
      const mergedStart = DateTime.min(currentInterval.start, nextInterval.start);
      const mergedEnd = DateTime.max(currentInterval.end, nextInterval.end);
      currentInterval = Interval.fromDateTimes(mergedStart, mergedEnd);
    } else {
      // No overlap, add the current interval to results and move to the next
      result.push(currentInterval);
      currentInterval = nextInterval;
    }
  }

  // Add the last interval
  result.push(currentInterval);

  return result;
};

/**
 * Processes intervals by:
 * 1. Breaking up intervals that cross day boundaries
 * 2. Merging overlapping intervals
 * @param intervals Array of Luxon Intervals to process
 * @returns Processed array of Intervals
 */
export const sanitizeIntervals = (intervals: Interval[]): Interval[] => {
  if (!intervals || intervals.length === 0) {
    return [];
  }

  // First break up intervals at day boundaries
  const brokenIntervals = breakDayBoundaryIntervals(intervals);

  // Then merge any overlapping intervals
  return mergeOverlappingIntervals(brokenIntervals);
};
