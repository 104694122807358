import React, { ReactNode } from "react";

export const TeamSettingsSection = ({
  graphic,
  children,
}: {
  graphic: ReactNode;
  children: ReactNode;
}) => {
  return (
    <div className="cw-flex cw-gap-8">
      <div className="cw-w-[180px]">{graphic}</div>
      <div className="cw-flex-1">{children}</div>
    </div>
  );
};
