import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SendInvitesMutationVariables = Types.Exact<{
  calendarIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type SendInvitesMutation = { __typename: 'Mutation', sendInvites: Array<{ __typename: 'Invitee', id: string, invited: boolean } | null> };


export const SendInvitesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendInvites"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"calendarIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendInvites"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"calendarIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"calendarIds"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"invited"}}]}}]}}]} as unknown as DocumentNode<SendInvitesMutation, SendInvitesMutationVariables>;