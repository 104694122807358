import { ApolloError } from "@apollo/client";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import {
  RescheduleEventGatewayDocument,
  RescheduleEventGatewayMutation,
} from "./__generated__/RescheduleEventGateway.v2.generated";
import { RescheduleOptionsDocument } from "./__generated__/RescheduleOptions.v2.generated";

export const useRescheduleEvent = (
  callback: () => void,
  errorCallback?: (error: ApolloError) => void,
) => {
  const [rescheduleEventMutation, { loading, error }] = useGatewayMutation(
    RescheduleEventGatewayDocument,
  );

  const rescheduleEvent = (
    externalEventId: string,
    startTime: string,
    timeZone: string,
    optimisticResponse?: RescheduleEventGatewayMutation,
  ) => {
    void rescheduleEventMutation({
      variables: {
        externalEventId,
        startTime,
        timeZone,
      },
      onCompleted: () => {
        callback();
      },
      onError: (error) => {
        errorCallback?.(error);
      },
      optimisticResponse,
      refetchQueries: [{ query: RescheduleOptionsDocument, variables: { externalEventId } }],
    });
  };

  return { rescheduleEvent, loading, error };
};
