import { Tooltip } from "@clockwise/design-system";
import { ChevronRight } from "@clockwise/icons";
import classNames from "classnames";
import React from "react";
import { useAIMessageContext } from "../../../chat/ai-chat/hooks/AIMessageContext";
import { AI_EXCLUSION_TOOLTIP } from "../../../chat/constants";
import { InlineAction } from "../../atoms/InlineAction";

export const RescheduleWithAIButton = ({ onReschedule }: { onReschedule: () => void }) => {
  const { shouldExcludeAi } = useAIMessageContext();
  return (
    <div
      cw-id="ec-attendee-reschedule-button"
      className="cw-body-sm cw-text-12 cw-text-muted cw-flex cw-items-center cw-my-2"
    >
      <span className="cw-mr-1">Most attendees have declined.</span>
      <Tooltip title={shouldExcludeAi ? AI_EXCLUSION_TOOLTIP : ""}>
        <span>
          <InlineAction
            onClick={onReschedule}
            disabled={shouldExcludeAi}
            label="Reschedule with AI"
            ariaLabel="Reschedule this event with AI"
            cw-id="ec-attendee-reschedule-button"
          />
        </span>
      </Tooltip>
      <ChevronRight
        className={classNames("cw-h-4 cw-w-4", {
          "cw-text-positive": !shouldExcludeAi,
          "cw-text-positive-disabled": shouldExcludeAi,
        })}
      />
    </div>
  );
};
