import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { WebSettingsTeamAvailabilityCalendarRelay } from "../components/web-settings/web-settings-team-availability-calendar";
import { WebSettingsTeamNoMeetingDayRelay } from "../components/web-settings/web-settings-team-no-meeting-day";
import { useGetOrgId } from "../hooks/useGetOrgId";
import { useMonetization } from "../hooks/useMonetization";
import { page, PageEvents } from "../util/analytics.util";

const TeamsSettingsPage = () => {
  // DevNote: this is not a "page" concern. move into components
  const { org, loading } = useGetOrgId();

  // DevNote: this is not a "page" concern. move into components
  const {
    getCopyForMonetizationBadges,
    canTeamAvailabilityAndNMDBeEnabled,
    daysLeftInTrial,
    shouldUserSeeJoinExistingPlan,
  } = useMonetization();

  useEffect(() => {
    page(PageEvents.TEAMS_SETTINGS_PAGE);
  }, []);

  if (loading || !org) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Teams settings · Clockwise</title>
      </Helmet>
      <div>
        <h1 className="cw-heading-3xl cw-my-10 cw-mx-[60px]"> Team settings</h1>
        <div className="cw-border-0 cw-border-t cw-border-solid cw-border-default">
          <WebSettingsTeamAvailabilityCalendarRelay
            orgRelayId={org?.id}
            tooltipType={getCopyForMonetizationBadges("Team Availability")}
            canTeamAvailabilityBeEnabled={canTeamAvailabilityAndNMDBeEnabled}
            daysLeftInTrial={daysLeftInTrial}
            showJoinExistingPlan={shouldUserSeeJoinExistingPlan}
          />
          <WebSettingsTeamNoMeetingDayRelay
            tooltipType={getCopyForMonetizationBadges("NMD")}
            canNMDBeEnabled={canTeamAvailabilityAndNMDBeEnabled}
            daysLeftInTrial={daysLeftInTrial}
            orgRelayId={org.id}
            showJoinExistingPlan={shouldUserSeeJoinExistingPlan}
          />
        </div>
      </div>
    </>
  );
};

export default TeamsSettingsPage;
