import { useGetOrgId } from "#webapp/src/hooks/useGetOrgId";
import { setPricingDialogOpen } from "#webapp/src/state/actions/payments.actions";
import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { isNotNull } from "@clockwise/client-commons/src/util/null";
import { EventLogEntry } from "@clockwise/schema/v2";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { track, TrackingEvents } from "@clockwise/web-commons/src/util/analytics.util";
import { shouldShowJoinExistingPlanWithRedux } from "@clockwise/web-commons/src/util/feature-grid.util";
import { openBillingPage } from "@clockwise/web-commons/src/util/upgradeLinks.util";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import invariant from "tiny-invariant";
import { IReduxState } from "../../../state/reducers/root.reducer";
import { OrgPersonsByCalendarIdDocument } from "../../web-app-calendar/multi-calendar-select/graphql/__generated__/OrgPersonsByCalendarId.generated";
import { History } from "../atoms/history/History";
import { LoadingProgress } from "../atoms/history/LoadingProgress";
import { EventHistoryDocument } from "./__generated__/EventHistory.v2.generated";
import { UnpauseEventDocument } from "./__generated__/UnpauseEvent.v2.generated";

const DEFAULT_ATTENDEE = "An attendee";

export const ECHistory = ({
  eventId,
  calendarId,
}: {
  eventId: string | null;
  calendarId: string | null;
}) => {
  const dispatch = useDispatch();
  const featureGrid = useSelector((state: IReduxState) => state.featureGrid);
  const orgBillingGroupIds = useSelector((state: IReduxState) => state.payments.orgBillingGroupIds);
  const shouldShowJoinExistingPlan = shouldShowJoinExistingPlanWithRedux(
    orgBillingGroupIds ?? [],
    featureGrid.hasPaidPlan,
  );

  const handleViewPlans = () => {
    dispatch(setPricingDialogOpen(true, undefined));
  };

  const handleUnpauseEvent = () => {
    if (!eventId) {
      return;
    }

    void unpauseEvent({
      variables: {
        externalEventId: eventId,
      },
    });

    track(TrackingEvents.DEFRAG.UNPAUSE_AUTOPILOT, {
      externalEventId: eventId,
    });
  };

  const handleGoToBilling = () => {
    openBillingPage();
  };

  const trackTimelineExpanded = useCallback(() => {
    track(TrackingEvents.AUTOPILOT_HISTORY.SIDEPANEL_FLEXIBLE_MEETING_HISTORY_SHOW_MORE, {
      externalEventId: eventId,
    });
  }, [eventId]);

  const [unpauseEvent] = useGatewayMutation(UnpauseEventDocument, {
    refetchQueries: [{ query: EventHistoryDocument }],
  });

  const { data, loading: loadingHistoryData, error } = useGatewayQuery(EventHistoryDocument, {
    variables: {
      id: eventId!,
      calendarId: calendarId!,
    },
    skip: !eventId || !calendarId,
  });

  const nextDefrag = data?.currentOrg?.nextDefragTime ?? null;
  const entries = data?.event?.history.entries ?? [];
  const status = data?.event?.history.status ?? null;
  const flexibility = data?.event?.flexibility ?? null;

  const { org, loading: loadingOrgData } = useGetOrgId();
  invariant(org && org.id);
  const { data: personData, loading: loadingPersonData } = useQuery(
    OrgPersonsByCalendarIdDocument,
    {
      variables: {
        orgId: org.id!,
        calendarIds: entries.map((entry) => entry.sourceCalendarId).filter(isNotNull),
      },
    },
  );

  const personOrg = getValue(personData?.org, "Org");
  const orgPersonList = getValue(personOrg?.orgPersonsByCalendarIdsErrorable, "OrgPersonList");

  if (loadingHistoryData || loadingOrgData || loadingPersonData) {
    return <LoadingProgress />;
  }

  const augmentedEntries = entries.map((entry) => {
    const { displayName, externalImageUrl } = ((log: EventLogEntry) => {
      if (log.sourceCalendarId === null) {
        return {
          displayName: DEFAULT_ATTENDEE,
          externalImageUrl: null,
        };
      }

      const person = orgPersonList?.list.find(
        (person: { primaryCalendarId: string | null }) =>
          person.primaryCalendarId === entry.sourceCalendarId,
      );

      if (!person) {
        return {
          displayName: DEFAULT_ATTENDEE,
          externalImageUrl: null,
        };
      }

      return {
        displayName: person.profile?.givenName ?? DEFAULT_ATTENDEE,
        externalImageUrl: person.profile?.externalImageUrl ?? null,
      };
    })(entry);

    return {
      ...entry,
      displayName,
      externalImageUrl,
    };
  });

  return (
    <History
      entries={augmentedEntries}
      error={error}
      flexibility={flexibility}
      nextDefrag={nextDefrag}
      onGoToBilling={handleGoToBilling}
      onTimelineExpanded={trackTimelineExpanded}
      onUnpauseEvent={handleUnpauseEvent}
      onViewPlans={handleViewPlans}
      showJoinExistingPlan={shouldShowJoinExistingPlan}
      status={status}
    />
  );
};
