import React, { ReactNode } from "react";
import { BannerMessage, IBannerMessage } from "./BannerMessage";
import PrismBannerMessage from "./PrismBannerMessage";

export type IBannerMessages = {
  messages: IBannerMessage[];
  children: ReactNode;
};

export const BannerMessages: React.FC<IBannerMessages> = ({ children, messages }) => {
  const messageHeight = 36;
  const messagesHeight = messages.length * messageHeight;

  return (
    <>
      <div
        className="cw-divide-y cw-divide-solid cw-divide-neutral-emphasis"
        style={{ height: messagesHeight }}
      >
        {messages.map((message) => {
          if (message.type === "prism") {
            return (
              <div key="prism" style={{ height: messageHeight }}>
                <PrismBannerMessage />
              </div>
            );
          }
          if (message.type === "loading") {
            return (
              <div key={message.text} style={{ height: messageHeight }}>
                <BannerMessage message={message.text} type="loading" />
              </div>
            );
          }

          return (
            <div key={message.text} style={{ height: messageHeight }}>
              <BannerMessage message={message.text} type={message.type} />
            </div>
          );
        })}
      </div>
      <div style={{ height: `calc(100% - ${messagesHeight}px)` }}>{children}</div>
    </>
  );
};
