import {
  Props as ButtonProps,
  Grouped,
  isButton,
} from "#clockwise/design-system/src/components/Button";
import React from "react";

type Props = Omit<ButtonProps, "startIcon" | "endIcon">;

export const ButtonGroup: React.FC<Props> = ({
  children,
  "aria-label": ariaLabel,
  ...groupProps
}) => {
  const buttonChildren = React.Children.toArray(children).filter(isButton);

  const childButtons = buttonChildren.map((child, index, { length }) => {
    let grouped: Grouped = "none";
    if (length > 1) {
      if (index === 0) {
        grouped = "first";
      } else if (index === React.Children.count(children) - 1) {
        grouped = "last";
      } else {
        grouped = "middle";
      }
    }

    return React.cloneElement(child as JSX.Element, {
      ...groupProps,
      ...child.props,
      grouped,
    });
  });

  return (
    <div className="cw-flex cw-flex-row" aria-label={ariaLabel} role="group">
      {childButtons}
    </div>
  );
};
