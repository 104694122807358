import { CalendarDate } from "@clockwise/web-commons/src/components/calendar/calendar-dates/CalendarDate";
import { MiniCalendarDate } from "@clockwise/web-commons/src/components/calendar/calendar-dates/MiniCalendarDate";
import {
  useReadCalendar,
  useUpdateCalendar,
} from "@clockwise/web-commons/src/util/CalendarContext";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import classNames from "classnames";
import { sum } from "lodash";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useOrgId } from "../../hooks/useOrgId";
import {
  useReadHorizontalScrollSync,
  useUpdateHorizontalScrollSync,
} from "../calendar-single-day/HorizonalScrollSyncContext";
import { getDateStringKeyFromDateTime } from "../calendar-single-day/utils/getDateStringKeyFromDateTime";
import { SingleDayAllDayEventsUnderlay } from "../ooo-events/SingleDayAllDayEventsUnderlay";
import { SingleDayAllDayEvents } from "../planner-all-day-events/SingleDayAllDayEvents";
import { TimeZoneLink } from "../planner-head/PlannerHead";
import { PlannerEventCard } from "../types";
import { UserColumnHeader } from "./UserColumnHeader";

export const TOP_ROW_HEIGHT = "50px";
export const ALLDAY_MAX_HEIGHT = "200px";
export const SingleDayHead = ({
  dateTime,
  calendarIds,
  timeZones,
  minWidthPercent,
  allDayEventsByCalendar,
  showingProposalOptions = false,
}: {
  dateTime: DateTime;
  calendarIds: string[];
  timeZones: Record<string, string | null>;
  minWidthPercent: number;
  allDayEventsByCalendar: {
    [calendarId: string]: PlannerEventCard[];
  };
  showingProposalOptions?: boolean;
}) => {
  const singleCalendar = calendarIds.length === 1;
  const { orgId } = useOrgId();
  const dateToRender = getDateStringKeyFromDateTime(dateTime);
  const calendarDispatch = useUpdateCalendar();
  const { focusedDate } = useReadCalendar();

  const showAllDayEvents =
    sum(
      Object.keys(allDayEventsByCalendar).map(
        (calendarId) => allDayEventsByCalendar[calendarId].length,
      ),
    ) > 0;

  const timeZone = dateTime.toFormat("ZZZZ");

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const { headerRef } = useReadHorizontalScrollSync();
  const { handleScrollHeader } = useUpdateHorizontalScrollSync();

  const switchToWeekView = () => {
    calendarDispatch({
      type: "set-view",
      payload: { view: "week", date: focusedDate },
    });
  };
  return (
    <div className="cw-flex cw-px-1 cw-z-80">
      <div
        className={classNames(
          "cw-flex-col cw-sticky cw-justify-end cw-flex-shrink-0 cw-transition-[width] cw-duration-300 motion-reduce:cw-duration-0 cw-origin-left",
          {
            "cw-w-[60px] cw-ease-in": !showingProposalOptions,
            "cw-w-[108px] cw-ease-out": showingProposalOptions,
          },
        )}
      >
        <div
          style={{ height: TOP_ROW_HEIGHT }}
          className={classNames(
            "cw-body-base cw-text-[10px] cw-text-subtle cw-text-end cw-pr-1 cw-flex cw-flex-col cw-items-center cw-justify-center",
            { "cw-border-b cw-border-subtle cw-border-solid": showAllDayEvents },
          )}
        >
          {singleCalendar ? (
            <TimeZoneLink>{timeZone}</TimeZoneLink>
          ) : (
            <MiniCalendarDate dateTime={dateTime} onClick={switchToWeekView} />
          )}
        </div>
        {showAllDayEvents && (
          <div
            className="cw-flex cw-flex-row cw-items-center cw-sticky cw-justify-center cw-cursor-pointer cw-pt-3"
            onClick={toggleExpanded}
          >
            <div className="cw-body-base cw-text-[10px] cw-text-subtle">All day</div>
            {expanded && <KeyboardArrowUp className="cw-w-3.5 cw-h-3.5 cw-text-subtle" />}
            {!expanded && <KeyboardArrowDown className="cw-w-3.5 cw-h-3.5 cw-text-subtle" />}
          </div>
        )}
      </div>

      <div
        className={classNames(
          "cw-flex-col cw-w-full cw-overflow-x-scroll cw-overflow-y-hidden cw-pl-4 cw-pr-1",
          "cw-scrollbar-none",
        )}
        ref={headerRef}
        onScroll={handleScrollHeader}
      >
        <div style={{ height: TOP_ROW_HEIGHT }}>
          {singleCalendar ? (
            <div
              style={{ height: TOP_ROW_HEIGHT }}
              className={classNames("cw-pr-1 cw-pt-2", {
                "cw-border-b cw-border-subtle cw-border-solid": showAllDayEvents,
              })}
            >
              <CalendarDate dateTime={dateTime} onClick={switchToWeekView} />
            </div>
          ) : (
            <UserColumnHeader
              dateTime={dateTime}
              calendarIds={calendarIds}
              orgId={orgId ?? ""}
              timeZones={timeZones}
              minWidthPercent={minWidthPercent}
              showBorder={showAllDayEvents}
            />
          )}
        </div>
        {showAllDayEvents && (
          <SingleDayAllDayEvents
            calendarIds={calendarIds}
            minWidth={minWidthPercent}
            allDayEventsByCalendar={allDayEventsByCalendar}
            dateTime={dateTime}
            expanded={expanded}
            toggleExpanded={toggleExpanded}
          />
        )}

        <SingleDayAllDayEventsUnderlay
          calendarIds={calendarIds}
          dateTime={dateTime}
          columnCount={calendarIds.length}
          dateToRender={dateToRender}
          minWidth={minWidthPercent}
        />
      </div>
    </div>
  );
};
