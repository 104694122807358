import { DateTime } from "luxon";
import React from "react";
import { useActiveProposal } from "../../chat/hooks/useActiveProposal";
import { ProposalOptionsOverlay } from "../proposal-options-overlay/ProposalOptionsOverlay";
import { getFullWidthPositionerData } from "./utils/getFullWidthPositionerData";

export const ProposalOptionsOverlayWrapper = ({
  dateTime,
  calendarIds,
  minWidthPercent,
  maxFullColumns,
}: {
  dateTime: DateTime;
  calendarIds: string[];
  minWidthPercent: number;
  maxFullColumns: number;
}) => {
  const { attendeeCalendarIds } = useActiveProposal();
  const { widthPercent, maxColumnsExceeded, columnCountOverride } = getFullWidthPositionerData(
    attendeeCalendarIds,
    calendarIds,
    maxFullColumns,
    minWidthPercent,
  );

  return (
    <ProposalOptionsOverlay
      dateTimes={[dateTime]}
      minWidth={minWidthPercent}
      columnCountOverride={columnCountOverride}
      positionerFullWidth={maxColumnsExceeded}
      popoverPosition="bottom"
      widthPercent={widthPercent}
      includeRail={false} // rail is options only used for week view
    />
  );
};
