import { useQuery } from "@apollo/client";
import { FocusTimeStatsDocument } from "../graphql/__generated__/FocusTimeStats.generated";

const useFocusTimeStats = () => {
  const { data, error, loading } = useQuery(FocusTimeStatsDocument);
  const focusTimeStats = data?.viewer.user?.orgs.edges?.[0]?.node?.focusTimeStats;
  let minutesPerWeek = 0;

  if (focusTimeStats?.__typename === "FocusTimeStats") {
    if (focusTimeStats.minutesPerWeek) {
      minutesPerWeek = focusTimeStats.minutesPerWeek;
    }
  }

  return { focusTimeStats: { minutesPerWeek }, error, loading };
};

export default useFocusTimeStats;
