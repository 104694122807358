import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { isNil } from "lodash";
import { useCallback } from "react";
import { usePersistedProposal } from "../../chat-plus-calendar/PersistedProposalContext";
import {
  useReadProposalOptionsOverlayV2,
  useUpdateProposalOptionsOverlayV2,
} from "../../chat-plus-calendar/util/ProposalOptionsOverlayContextV2";

export const useProposalOptionsOverlayToggleV2 = () => {
  const track = useTracking();
  const { isVisible, isEnabled } = useReadProposalOptionsOverlayV2();
  const { setIsVisible } = useUpdateProposalOptionsOverlayV2();
  const [isOnTimeSuggestionsTab] = useFeatureFlag("TimeSuggestionsTab");
  const persistedProposal = usePersistedProposal();

  const proposalHasOptions = !isNil(persistedProposal.suggestedTimeOptions);

  const showOverlayToggle = isEnabled && proposalHasOptions && isOnTimeSuggestionsTab;
  const isToggleOverlayVisible = showOverlayToggle && isVisible;
  const isSearchRangeOverlayVisible = isEnabled && isOnTimeSuggestionsTab;

  const disableOverlay = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const toggleOverlay = useCallback(() => {
    if (showOverlayToggle) {
      if (isToggleOverlayVisible) {
        track(TrackingEvents.PROPOSAL_OPTIONS_OVERLAY.TOGGLED_OFF);
      } else {
        track(TrackingEvents.PROPOSAL_OPTIONS_OVERLAY.TOGGLED_ON);
      }
      setIsVisible(!isToggleOverlayVisible);
    }
  }, [showOverlayToggle, isToggleOverlayVisible, setIsVisible]);

  return {
    isToggleOverlayVisible,
    showOverlayToggle,
    isSearchRangeOverlayVisible,
    toggleOverlay,
    disableOverlay,
  };
};
