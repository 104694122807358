import { Switch } from "@clockwise/design-system";
import classNames from "classnames";
import React from "react";
import { useProposalOptionsOverlayToggleV2 } from "./useProposalOptionsOverlayToggle";

export const ProposalOptionsOverlayToggleV2 = ({ overlayVisible }: { overlayVisible: boolean }) => {
  const { showOverlayToggle, toggleOverlay } = useProposalOptionsOverlayToggleV2();

  if (!showOverlayToggle) return null;

  const Label = () => (
    <div className="cw-flex cw-w-[115px] cw-body-sm">{`${
      overlayVisible ? "Hide" : "Show"
    } suggestions`}</div>
  );

  return (
    <div className="cw-relative cw-z-[40]">
      <div
        className={classNames(
          "cw-mt-2 cw-px-3 cw-py-1.5 cw-absolute cw-bg-default cw-rounded-xl cw-left-[calc(50%-62px)] cw-drop-shadow-md cw-flex cw-items-center",
          "cw-border-[0.5px] cw-border-solid cw-border-muted cw-drop-shadow-md",
        )}
      >
        <Switch
          checked={overlayVisible}
          onChange={toggleOverlay}
          label={<Label />}
          labelPlacement="end"
          size="small"
        />
      </div>
    </div>
  );
};
