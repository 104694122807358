import { EventThread } from "@clockwise/web-commons/src/ui/event-thread/";
import { Profile } from "@clockwise/web-commons/src/util/profile.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { isEmpty, uniqBy } from "lodash";
import { Interval } from "luxon";
import React from "react";
import { useUserProfile } from "../../../../../hooks/useUserProfile";
import { formattedInterval } from "../../../../../web-app-calendar/calendar-popover/utils/formattedDateTime";
import { ProposalRenderSurface } from "../../../../constants";
import {
  GQLOthersConsequenceDiffSummary,
  GQLYourConsequenceDiffSummary,
} from "../../../utils/types";
import { DiffEventCardCollapsed } from "../../diffs/DiffEventCardCollapsed";
import { ThreadItemHeader } from "./ThreadItemHeader";
import { ThreadEventTooltip } from "./thread-event-tooltip/ThreadEventTooltip";
import { useProposalThreadEventDetails } from "./thread-event-tooltip/useProposalThreadEventDetails";

type ListOfDiffsWithProfiles = {
  diff: GQLYourConsequenceDiffSummary | GQLOthersConsequenceDiffSummary;
  profiles: (Profile & { calendarId: string })[];
}[];

export const ConsequencesWrapper = ({
  diffs,
  isScheduleByMoving,
  viewerIsProposalOwner = true,
  variant = "AI_CHAT",
  showHeader,
  isCollapsed,
  onCollapse,
  onExpand,
}: {
  diffs: (GQLYourConsequenceDiffSummary | GQLOthersConsequenceDiffSummary)[];
  isScheduleByMoving: boolean;
  viewerIsProposalOwner?: boolean;
  variant?: ProposalRenderSurface;
  showHeader: boolean;
  isCollapsed: boolean;
  onCollapse: () => void;
  onExpand: () => void;
}) => {
  const { userProfile: currentUserProfile, loading: userProfileLoading } = useUserProfile();

  const allProfiles: (Profile & { isYou?: boolean; calendarId: string })[] = [];
  const listofOthersDiffswithProfiles: ListOfDiffsWithProfiles = [];
  const listOfYourDiffs: ListOfDiffsWithProfiles = [];

  for (const diff of diffs) {
    if (diff.__typename === "OthersConsequenceDiffSummary") {
      const diffObjectForOthers: ListOfDiffsWithProfiles[0] = { diff, profiles: [] };
      diff.affectedAttendees.map((attendee) => {
        const profile = {
          givenName: attendee.person?.profile?.givenName || null,
          familyName: attendee.person?.profile?.familyName || null,
          externalImageUrl: attendee.person?.profile?.externalImageUrl || null,
        };
        diffObjectForOthers.profiles.push({
          ...profile,
          calendarId: attendee.calendarId,
        });
        allProfiles.push({ ...profile, calendarId: attendee.calendarId });
      });
      listofOthersDiffswithProfiles.push(diffObjectForOthers);
    } else if (diff.__typename === "YourConsequenceDiffSummary") {
      const diffObjectForYou: ListOfDiffsWithProfiles[0] = { diff, profiles: [] };
      if (!userProfileLoading) {
        diffObjectForYou.profiles.push({
          ...currentUserProfile,
          calendarId: currentUserProfile?.primaryEmail || "",
        });
        allProfiles.push({
          ...currentUserProfile,
          isYou: true,
          calendarId: currentUserProfile?.primaryEmail || "",
        });
      }
      listOfYourDiffs.push(diffObjectForYou);
    }
  }

  return (
    <div className="cw-flex cw-flex-col cw-gap-1.5">
      {showHeader && (
        <ThreadItemHeader
          isCollapsed={isCollapsed}
          onCollapse={onCollapse}
          onExpand={onExpand}
          profiles={uniqBy(allProfiles, "calendarId")}
          type="sbm"
          variant={variant}
        />
      )}
      {!isCollapsed && (
        <>
          <ConsequencesEvents
            diffs={listOfYourDiffs}
            isScheduleByMoving={isScheduleByMoving}
            viewerIsProposalOwner={viewerIsProposalOwner}
            variant={variant}
          />
          <ConsequencesEvents
            diffs={listofOthersDiffswithProfiles}
            isScheduleByMoving={isScheduleByMoving}
            variant={variant}
          />
        </>
      )}
    </div>
  );
};

export const ConsequencesEvents = ({
  diffs,
  isScheduleByMoving,
  viewerIsProposalOwner = true,
  variant = "AI_CHAT",
}: {
  diffs: ListOfDiffsWithProfiles;
  isScheduleByMoving: boolean;
  viewerIsProposalOwner?: boolean;
  variant?: ProposalRenderSurface;
}) => {
  if (isEmpty(diffs)) {
    return null;
  }

  return (
    <>
      {diffs.map((diff) => (
        <ConsequencesEvent
          key={diff.diff.id}
          diff={diff.diff}
          profiles={diff.profiles}
          isScheduleByMoving={isScheduleByMoving}
          viewerIsProposalOwner={viewerIsProposalOwner}
          variant={variant}
        />
      ))}
    </>
  );
};

const ConsequencesEvent = ({
  diff,
  isScheduleByMoving,
  viewerIsProposalOwner,
  variant,
  profiles,
}: {
  diff: GQLYourConsequenceDiffSummary | GQLOthersConsequenceDiffSummary;
  isScheduleByMoving: boolean;
  viewerIsProposalOwner: boolean;
  variant: ProposalRenderSurface;
  profiles: (Profile & { calendarId: string })[];
}) => {
  const { attendees, description, loading } = useProposalThreadEventDetails({
    externalEventId: diff.eventId,
    calendarId: profiles[0]?.calendarId || "",
  });
  const zone = getRenderTimeZone();

  const currentAndUpdatedTime = diff.updatedTime
    ? {
        currentTime: formattedInterval(Interval.fromISO(diff.currentTime, { zone }), zone),
        updatedTime: formattedInterval(Interval.fromISO(diff.updatedTime, { zone }), zone),
      }
    : undefined;

  return (
    <EventThread.Event profiles={profiles} variant={variant}>
      <ThreadEventTooltip
        title={diff.title}
        attendees={attendees}
        description={description}
        loadingDetails={loading}
        timeIntervals={currentAndUpdatedTime}
      >
        <DiffEventCardCollapsed
          action={diff.action}
          ambiguities={[]}
          id={diff.id}
          isSBM={isScheduleByMoving}
          title={diff.title}
          variant={variant}
          viewerIsProposalOwner={viewerIsProposalOwner}
          isConsequenceBlock
        />
      </ThreadEventTooltip>
    </EventThread.Event>
  );
};
