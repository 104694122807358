import { SelectedOption } from "./types";

export const getCommandText = (command: string, options: SelectedOption[]) => {
  const commandParts = [command];
  options.forEach(({ name, values }) => {
    commandParts.push(normalizeOptionName(name));
    commandParts.push(...values);
  });
  return commandParts.join(" ");
};

export const normalizeOptionName = (name: string) => {
  const nameOnly = name.replace(/^-+/, "");
  return nameOnly.length > 1 ? `--${nameOnly}` : `-${nameOnly}`;
};
