import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { useMutation } from "@apollo/client";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import {
  setCurrentTimeZone,
  setRenderTimeZone,
} from "@clockwise/web-commons/src/util/time-zone.util";
import { noop } from "lodash";
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { browserName } from "react-device-detect";
import { useMonetization } from "../../../hooks/useMonetization";
import { useOnboarding } from "../../../hooks/useOnboarding/useOnboarding";
import { TrackingEvents, track } from "../../../util/analytics.util";
import { useChecklistFlags } from "../../superpowers-checklist/useChecklistFlags";
import {
  FunnelType,
  WebOnboardingStep,
  getFlowStateEnumFromString,
  getFunnelType,
  getOrderingOfOnboarding,
  skipableWebOnboardingStepMap,
} from "../WebOnboarding.util";
import { useUserSurvey } from "../step-components/get-to-know-you-step/useUserSurvey";
import { getDefaultWorkingHoursInput } from "../utils/getDefaultWorkingHoursInput";
import { setError } from "../utils/setError";
import { OnboardingUpdateFlowStateUserDocument } from "./__generated__/OnboardingUpdateFlowStateUser.generated";
import { UpdateUserWorkingHoursOnboardingDocument } from "./__generated__/UpdateUserWorkingHours.v2.generated";

const INITIAL_STATE = {
  currentStep: WebOnboardingStep.GetToKnowYou,
  goForward: () => noop,
  goBack: () => noop,
  shouldShowSecondaryButton: false,
  skipStep: () => noop,
  loading: false,
  skipOnboarding: () => noop,
  backFromPreviousSlide: false,
  funnelType: FunnelType.None,
  numNonSkippedSteps: 0,
  currentStepIndex: 0,
};

interface WebOnboardingStepContextType {
  currentStep: WebOnboardingStep;
  goForward: () => void;
  goBack: () => void;
  shouldShowSecondaryButton: boolean;
  skipStep: (step: WebOnboardingStep, shouldSkip: boolean) => void;
  loading: boolean;
  skipOnboarding: () => void;
  backFromPreviousSlide: boolean;
  funnelType: FunnelType;
  numNonSkippedSteps: number;
  currentStepIndex: number;
}

const FLOW_KEY = "WebOnboarding";
const OLD_FLOW_KEY = "InitialOnboarding";
export const WebOnboardingStepContext = createContext<WebOnboardingStepContextType>(INITIAL_STATE);

const getInitialStepFromFlowState = (flowState: string | null) => {
  return flowState ? getFlowStateEnumFromString(flowState) : WebOnboardingStep.GetToKnowYou;
};

const useWebOnboardingStepsForProvider = () => {
  const {
    refetchOnboardingFlowStates,
    currentWebOnboardingFlowState,
    loading: onboardingLoading,
    orgId,
  } = useOnboarding();
  const { setShowSuperpowersChecklist } = useChecklistFlags();
  const { timeManagementGoal } = useUserSurvey(orgId || "");
  const { refetchFeatureGrid } = useMonetization();
  const [updateFlowStateUser] = useMutation(OnboardingUpdateFlowStateUserDocument, {
    fetchPolicy: "no-cache",
  });
  const [updateWorkingHoursNew] = useGatewayMutation(UpdateUserWorkingHoursOnboardingDocument, {
    onError: (error) => {
      setError({
        error: error,
        message: "failed to set default working hours",
        showUserMessage: false,
      });
    },
  });
  const [isOnNux2025] = useFeatureFlag("NUX-Q1-2025");

  const ecosystem = useEcosystem();
  const isChromeOrFirefox =
    (browserName === "Chrome" || browserName === "Firefox") && ecosystem === "Google";

  const [skippedStepMap, setSkippedStepMap] = useState<Record<WebOnboardingStep, boolean>>({
    ...skipableWebOnboardingStepMap,
  });
  const [currentStep, setCurrentStep] = useState<WebOnboardingStep>(
    getInitialStepFromFlowState(currentWebOnboardingFlowState),
  );
  const [haveWorkingHoursBeenSaved, setHaveWorkingHoursBeenSaved] = useState(false);
  const [returningFromPreviousSlide, setReturningFromPreviousSlide] = useState(false);

  const onboardingSteps = useMemo(() => {
    return getOrderingOfOnboarding(timeManagementGoal);
  }, [timeManagementGoal.responseType]);

  const funnelType = getFunnelType(timeManagementGoal);

  useEffect(() => {
    track(TrackingEvents.WEB_ONBOARDING.STARTING_STEP, {
      step: currentStep,
      funnelType,
      isOnNux2025,
    });
  }, []);

  useEffect(() => {
    let skippedStepMapCopy = { ...skippedStepMap };
    if (isChromeOrFirefox) {
      //unskip extension download slide
      track(TrackingEvents.WEB_ONBOARDING.CHROME_FIREFOX, { isOnNux2025 });
      skippedStepMapCopy = {
        ...skippedStepMapCopy,
        [WebOnboardingStep.DownloadClockwise]: false,
      };
    }
    if (isOnNux2025) {
      // skip deprecated steps if on new NUX flag
      skippedStepMapCopy = {
        ...skippedStepMapCopy,
        [WebOnboardingStep.AIScheduler]: true,
        [WebOnboardingStep.Links]: true,
        [WebOnboardingStep.Schedule]: true,
      };
      track(TrackingEvents.NUX_2025.HAS_SEEN_NUX_2025);
    }
    setSkippedStepMap(skippedStepMapCopy);
  }, [isChromeOrFirefox, isOnNux2025]);

  useEffect(() => {
    void updateFlowStateUser({
      variables: { input: { newState: currentStep, flowKey: FLOW_KEY } },
      onCompleted: () => {
        if (currentStep === WebOnboardingStep.Finished) {
          onOnboardingFinished();
        }
      },
      onError: (error) => {
        if (currentStep === WebOnboardingStep.Finished) {
          // Covers case where we recieve a timeout from BE but the request actually succeeded
          // We still want to ensure InitialOnboarding is set to finished
          void finishInitialOnboarding();
        }
        setError({
          message: "failed to update flow state for user " + `${currentStep} ${FLOW_KEY}`,
          error: error,
          showUserMessage: false,
        });
      },
    });
  }, [currentStep]);

  useEffect(() => {
    if (!haveWorkingHoursBeenSaved) {
      void updateDefaultWorkingHours();
      setHaveWorkingHoursBeenSaved(true);
    }
  }, []);

  const onOnboardingFinished = () => {
    track(TrackingEvents.WEB_ONBOARDING.FINSIHED, { funnelType, isOnNux2025 });
    void updateDefaultWorkingHours();
    void finishInitialOnboarding();
    void refetchFeatureGrid();
    void refetchOnboardingFlowStates();
    // If on NUX 2025, show the superpowers checklist
    if (isOnNux2025) {
      setShowSuperpowersChecklist(true);
    }
  };

  const updateDefaultWorkingHours = async () => {
    const input = getDefaultWorkingHoursInput();
    if (input?.timeZone) {
      // Will set initial default to Datetime.local
      setRenderTimeZone(input.timeZone);
      setCurrentTimeZone(input.timeZone);
    }
    await updateWorkingHoursNew({
      variables: { input: input },
    });
  };

  const finishInitialOnboarding = async () => {
    await updateFlowStateUser({
      variables: { input: { newState: WebOnboardingStep.Finished, flowKey: OLD_FLOW_KEY } },
      onError: (error) =>
        setError({
          message:
            "failed to update flow state for user " +
            `${WebOnboardingStep.Finished} ${OLD_FLOW_KEY}`,
          error: error,
          showUserMessage: false,
        }),
    });
  };

  const getStepIndex = (step: WebOnboardingStep) => onboardingSteps.indexOf(step);

  const getOffsetStep = (step: WebOnboardingStep, offset: number): WebOnboardingStep => {
    return onboardingSteps[
      Math.min(Math.max(onboardingSteps.indexOf(step) + offset, 0), onboardingSteps.length - 1)
    ];
  };

  const getNonSkippedSequentialStep = useCallback(
    (step: WebOnboardingStep, direction: number) => {
      let nextStep = getOffsetStep(step, direction);

      while (
        skippedStepMap[nextStep] &&
        getStepIndex(nextStep) !== 0 &&
        getStepIndex(nextStep) !== onboardingSteps.length - 1
      ) {
        nextStep = getOffsetStep(nextStep, direction);
      }

      track(TrackingEvents.WEB_ONBOARDING.ONBOARDING_STEP, {
        newState: `${nextStep}`,
        oldState: `${currentStep}`,
        funnelType,
        isOnNux2025,
      });

      return nextStep;
    },
    [currentStep, skippedStepMap, onboardingSteps],
  );

  const skipStep = useCallback(
    (step: WebOnboardingStep, shouldSkip: boolean) => {
      setSkippedStepMap({ ...skippedStepMap, [step]: shouldSkip });
    },
    [currentStep, skippedStepMap, setSkippedStepMap],
  );

  const goForward = useCallback(() => {
    const nextStep = getNonSkippedSequentialStep(currentStep, 1);
    setCurrentStep(nextStep);
    if (returningFromPreviousSlide) {
      setReturningFromPreviousSlide(false);
    }
  }, [currentStep, setCurrentStep, onboardingSteps]);

  const goBack = useCallback(() => {
    const nextStep = getNonSkippedSequentialStep(currentStep, -1);
    setCurrentStep(nextStep);
    setReturningFromPreviousSlide(true);
  }, [currentStep, setCurrentStep, onboardingSteps]);

  const shouldShowSecondaryButton = useMemo(() => {
    if (currentStep === WebOnboardingStep.GetToKnowYou) {
      return false;
    }
    return true;
  }, [currentStep]);

  const skipOnboarding = useCallback(() => {
    setCurrentStep(WebOnboardingStep.Finished);
  }, []);

  const backFromPreviousSlide = returningFromPreviousSlide;

  const loading = onboardingLoading;

  const nonSkippedSequentialSteps = useMemo(() => {
    const sequentialNonSkippedSteps: WebOnboardingStep[] = [];
    for (const step of onboardingSteps) {
      if (!skippedStepMap[step]) {
        sequentialNonSkippedSteps.push(step);
      }
    }
    return sequentialNonSkippedSteps;
  }, [onboardingSteps, skippedStepMap]);

  const numNonSkippedSteps = useMemo(
    () => nonSkippedSequentialSteps.length - 1, // subtract 1 for the finished step
    [nonSkippedSequentialSteps],
  );

  const currentStepIndex = useMemo(() => nonSkippedSequentialSteps.indexOf(currentStep), [
    nonSkippedSequentialSteps,
    currentStep,
  ]);

  return useMemo(() => {
    return {
      currentStep,
      goForward,
      goBack,
      shouldShowSecondaryButton,
      skipStep,
      loading,
      skipOnboarding,
      backFromPreviousSlide,
      funnelType,
      numNonSkippedSteps,
      currentStepIndex,
    };
  }, [
    currentStep,
    goForward,
    goBack,
    shouldShowSecondaryButton,
    skipStep,
    loading,
    skipOnboarding,
    backFromPreviousSlide,
    funnelType,
    numNonSkippedSteps,
    currentStepIndex,
  ]);
};

export function WebOnboardingStepProvider({ children }: PropsWithChildren) {
  const value = useWebOnboardingStepsForProvider();

  return (
    <WebOnboardingStepContext.Provider value={value}>{children}</WebOnboardingStepContext.Provider>
  );
}

export function useWebOnboardingSteps() {
  const context = useContext(WebOnboardingStepContext);
  return context;
}
