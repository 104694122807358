import {
  DARK_ICON_COLOR_CLASS,
  ICON_COLOR_CLASS,
  ICON_ONLY_SIZE_CLASSES,
  ICON_WITH_TEXT_SIZE_CLASSES,
  SIZE_CLASSES,
} from "@clockwise/design-system/src/components/Button";
import { CwIdProps } from "@clockwise/design-system/src/types/cw-id";
import { SvgIconComponent } from "@clockwise/icons";
import classNames from "classnames";
import React, { ButtonHTMLAttributes, PropsWithChildren, useEffect, useRef, useState } from "react";

interface PrismButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className">,
    CwIdProps {
  /**
   * An icon to be displayed either alone or before a label.  Should render well at 18px size.
   */
  startIcon?: SvgIconComponent;
  /**
   * An icon to be displayed after a label.  Should render well at 18px size. Does not display without a label.
   */
  endIcon?: SvgIconComponent;
  size?: Size;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: Variant;
}

export type Variant = "solid" | "outlined";
type Size = "mini" | "xsmall" | "small";

/**
 * `PrismButton` adds a unique iridescence to the background or outline of the standard `Button`, which is used specifically for <blank> use cases.
 **/
export const PrismButton = ({
  children,
  startIcon: StartIconComponent,
  endIcon: EndIconComponent,
  size = "small",
  fullWidth,
  disabled,
  variant = "outlined",
  ...props
}: PropsWithChildren<PrismButtonProps>) => {
  const [entry, setEntry] = useState<Partial<IntersectionObserverEntry>>({});
  const observer = useRef<IntersectionObserver | null>(null);
  const heroRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      return; // Only valid in a browser environment
    }

    observer.current = new IntersectionObserver(
      ([entry]) => {
        setEntry(entry);
      },
      { root: null, rootMargin: "0px", threshold: 0.75 },
    );

    const { current: currentObserver } = observer;

    if (heroRef.current) {
      currentObserver.observe(heroRef.current);
    }

    return () => currentObserver.disconnect?.();
  }, [heroRef.current]);

  const isIconOnly = !children && !!StartIconComponent;
  const iconColorClass = isIconOnly
    ? DARK_ICON_COLOR_CLASS["neutral"]
    : ICON_COLOR_CLASS["neutral"];

  const startIcon = StartIconComponent ? (
    <StartIconComponent
      className={`${ICON_WITH_TEXT_SIZE_CLASSES[size]} ${iconColorClass}`}
      aria-hidden
    />
  ) : null;
  const endIcon = EndIconComponent ? (
    <EndIconComponent
      className={`${ICON_WITH_TEXT_SIZE_CLASSES[size]} ${iconColorClass}`}
      aria-hidden
    />
  ) : null;

  const sync = ({ clientX, clientY }: React.MouseEvent) => {
    if (!heroRef.current) return;
    const rect = entry.boundingClientRect;
    if (!rect) return;
    const x = clientX - rect.left;
    const y = clientY - rect.top;
    heroRef.current.style.setProperty("--x", `${x}px`);
    heroRef.current.style.setProperty("--y", `${y}px`);
  };

  if (variant === "outlined") {
    return (
      <button
        {...props}
        className={classNames(
          "cw-prism-outline hover:cw-prism-outline-animated", // class differences from regular button
          "cw-border-none cw-rounded-lg cw-cursor-pointer",
          "cw-leading-none cw-font-body cw-whitespace-nowrap cw-text-neutral",
          "active:cw-bg-neutral-hover hover:cw-bg-neutral cw-bg-default ",
          "cw-flex cw-items-center",
          {
            "cw-w-full": fullWidth,
            "cw-opacity-50 cw-cursor-not-allowed": disabled,
            [ICON_ONLY_SIZE_CLASSES[size]]: isIconOnly,
            [SIZE_CLASSES[size]]: !isIconOnly,
          },
        )}
        disabled={disabled}
      >
        <div className="cw-prism-mask cw-prism-mask-opaque cw-rounded-lg" />
        {children ? (
          <>
            {startIcon}
            {children}
            {endIcon}
          </>
        ) : (
          startIcon
        )}
      </button>
    );
  }

  return (
    <button
      {...props}
      ref={heroRef}
      onMouseMove={sync}
      style={
        {
          "--x": "50%",
          "--y": "50%",
        } as React.CSSProperties
      }
      className={classNames(
        "cw-prism-solid", // class differences from regular button
        "cw-border-muted cw-border cw-border-solid cw-rounded-lg cw-cursor-pointer",
        "cw-leading-none cw-font-body cw-whitespace-nowrap",
        "active:cw-bg-neutral-hover cw-bg-default",
        "cw-flex cw-items-center",
        {
          "cw-w-full": fullWidth,
          "cw-opacity-50 cw-cursor-not-allowed": disabled,
          [ICON_ONLY_SIZE_CLASSES[size]]: isIconOnly,
          [SIZE_CLASSES[size]]: !isIconOnly,
        },
      )}
      disabled={disabled}
    >
      {children ? (
        <>
          {startIcon}
          {children}
          {endIcon}
        </>
      ) : (
        startIcon
      )}
    </button>
  );
};
