// schema

import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { RelayRefetchProp } from "react-relay";

import { useLazyQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { Button } from "@clockwise/design-system";
import { BillingGroup, Org } from "@clockwise/schema";
import { TrackingEvents, track } from "../../../../../util/analytics.util";
import { SsoAdminPortalLinkQueryDocument } from "./__generated__/SsoAdminPortalButton.generated";

export interface IAdminPanelBillingGroupsPlansAndBillingCmpt {
  billingGroup: BillingGroup;
  org: Org;
  disabled?: boolean;
  relay: RelayRefetchProp;
}

export const SsoAdminPortalButton = ({
  orgId,
  billingGroupId,
  isAdmin,
  isSSO,
}: {
  orgId: string;
  billingGroupId: string;
  isAdmin: boolean;
  isSSO: boolean;
}) => {
  const [fetchSsoLink, { loading }] = useLazyQuery(SsoAdminPortalLinkQueryDocument, {
    fetchPolicy: "cache-and-network", // quick results from cache, true data back from network.
    nextFetchPolicy: "cache-only", // don't fetch from network again. Needed to prevent duplicate fetches.
    notifyOnNetworkStatusChange: true, // This is needed to make the loading variable work according to Apollo docs.
  });

  const onClickConfigureSso = useCallback(() => {
    // Adding these checks for sanity in case the component is reused and the check is not in the parent
    if (!isSSO || !isAdmin) {
      toast.error("You do not have permission to configure SCIM. Contact your admin.");
      return;
    }

    fetchSsoLink({ variables: { orgId, billingGroupId } }).then((res) => {
      const billingGroup = getValue(res.data?.node, "Org")?.billingGroupForId;
      const ssoAdminPortalLink = getValue(billingGroup)?.ssoAdminPortalLink;
      const link = getValue(ssoAdminPortalLink)?.link;
      if (link) {
        track(TrackingEvents.ADMIN_PANEL.PLANS_AND_BILLING_CLICK_CONFIGURE_SCIM);
        window.open(link, "_blank");
      } else {
        toast.error("failed to get ssoAdminPortalLink from your billing group");
        logger.error("failed to get ssoAdminPortalLink from billing group");
      }
    });
  }, [billingGroupId, fetchSsoLink, isAdmin, isSSO, orgId]);

  return (
    <Button sentiment="positive" disabled={loading} onClick={onClickConfigureSso}>
      {loading ? "Opening portal" : "Configure SCIM"}
    </Button>
  );
};
