import { Button } from "@clockwise/design-system";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
};

const RemoveDialog = ({ onCancel: handleCancel, onConfirm: handleConfirm, open }: Props) => {
  return (
    <Dialog open={open} PaperProps={{ style: { width: 380 } }} className="cw-body-base">
      <DialogTitle>Disconnect Personal Calendar?</DialogTitle>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <p>This will disconnect this calendar from Clockwise.</p>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>
          Cancel
        </Button>
        <Button sentiment="destructive" onClick={handleConfirm}>
          Disconnect
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
