import { useReadTimeSuggestionPeek } from "#webapp/src/components/chat-plus-calendar/util/TimeSuggestionPeekContext";
import { TradeoffBlock } from "#webapp/src/components/chat/ai-chat/utils/types";
import { Proposal, useActiveProposal } from "#webapp/src/components/chat/hooks/useActiveProposal";
import { useReadTimeSuggestion } from "@clockwise/web-commons/src/util/TimeSuggestionContext";
import { useEffect, useMemo, useState } from "react";
import { mapCalendarIdToTradeoffUIVariant } from "./mapCalendarIdToTradeoffUIVariant";

const getTradeoffBlocksFromProposal = (proposal?: Proposal) => {
  let tradeoffBlocks: TradeoffBlock[] = [];
  proposal?.diffBlocks.forEach((diffBlock) => {
    diffBlock.diffs.forEach((diff) => {
      if (diff.tradeoffBlocks) {
        tradeoffBlocks = tradeoffBlocks.concat(diff.tradeoffBlocks);
      }
    });
  });

  return tradeoffBlocks;
};

const getAttendeesFromProposal = (proposal?: Proposal) => {
  let attendees: string[] = [];
  proposal?.diffBlocks.forEach((diffBlock) => {
    diffBlock.diffs.forEach((diff) => {
      if (diff.attendees) {
        const attendeeIdListForDiff =
          diff.attendees?.proposalAttendees.map(
            (attendee) => attendee.attendeePerson.primaryEmail,
          ) ?? [];
        attendees = attendees.concat(attendeeIdListForDiff);
      }
    });
  });

  return attendees;
};
export const useActiveProposalTradeoffDetails = () => {
  const { proposal } = useActiveProposal();
  const { interval, tradeoffs: hoverTradeoffs } = useReadTimeSuggestionPeek();
  const { loading } = useReadTimeSuggestion();
  const [activeTradeoffs, setActiveTradeoffs] = useState<TradeoffBlock[]>([]);
  const attendeeCalendarIds = getAttendeesFromProposal(proposal);

  useEffect(() => {
    // Only set interval if it's not null
    if (interval) {
      setActiveTradeoffs(hoverTradeoffs);
    }
  }, [interval, hoverTradeoffs]);

  useEffect(() => {
    setActiveTradeoffs(getTradeoffBlocksFromProposal(proposal)); // fix typing
  }, [proposal]);

  const tradeoffBlockToCalendarIdMap = useMemo(
    () => mapCalendarIdToTradeoffUIVariant(activeTradeoffs, attendeeCalendarIds),
    [activeTradeoffs, attendeeCalendarIds],
  );

  return {
    tradeoffBlockToCalendarIdMap,
    proposalCalendarIds: attendeeCalendarIds,
    activeTradeoffs,
    loading,
  };
};
