import { Button, TextField } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { Cached, Search } from "@clockwise/icons";
import React, { useState } from "react";
import { BillingGroupList } from "./BillingGroupList";
import { useBillingGroupLookup } from "./useBillingGroupLookup";

type SearchInput = {
  orgId: string;
  email: string;
};

export const BillingGroupLookup = () => {
  const [orgIdValue, setOrgIdValue] = useState<string | undefined>();
  const [emailValue, setEmailValue] = useState<string | undefined>();

  const [searchInput, setSearchInput] = useState<SearchInput | undefined>();
  const { billingGroupLookup, data, loading, refetch } = useBillingGroupLookup();

  const hasInputChanged = orgIdValue !== searchInput?.orgId || emailValue !== searchInput?.email;

  const onClickSearch = () => {
    if (orgIdValue && emailValue && (!searchInput || hasInputChanged)) {
      billingGroupLookup(orgIdValue, emailValue);
      setSearchInput({ orgId: orgIdValue, email: emailValue });
    } else if (!hasInputChanged) {
      // if input hasn't change still refresh to show any changes
      void refetch();
    }
  };

  const isButtonSearch = hasInputChanged || !searchInput;

  return (
    <div className="cw-flex cw-flex-col cw-gap-2">
      <div className="cw-heading-lg"> Billing Group Lookup </div>
      <div className="cw-flex cw-gap-x-2 cw-items-center cw-mb-2 cw-flex-wrap">
        <TextField
          label="Org ID"
          value={orgIdValue}
          onChange={(e) => setOrgIdValue(e.target.value)}
          placeholder="Org Id (e.g. 1234567890)"
        />
        <TextField
          label="Email"
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
          placeholder="Admin email or user email"
        />
      </div>
      <div>
        <Button
          sentiment={isButtonSearch ? "positive" : "neutral"}
          onClick={onClickSearch}
          size="small"
          fullWidth={false}
          disabled={!orgIdValue || !emailValue}
          startIcon={isButtonSearch ? Search : Cached}
          variant={isButtonSearch ? "solid" : "outlined"}
        >
          {isButtonSearch ? "Search" : "Refetch"}
        </Button>
      </div>
      <div className="cw-flex cw-justify-center cw-py-4">
        {loading ? (
          <Loader size="md" sentiment="positive" />
        ) : (
          <BillingGroupList data={data} loading={loading} />
        )}
      </div>
    </div>
  );
};
