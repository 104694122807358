/**
 * @generated SignedSource<<fbbbe0716c68498e0329ee556bc05521>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnsubscribeWrapperQuery$variables = {};
export type UnsubscribeWrapperQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"UnsubscribeWrapper_viewer">;
  };
};
export type UnsubscribeWrapperQuery = {
  variables: UnsubscribeWrapperQuery$variables;
  response: UnsubscribeWrapperQuery$data;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnsubscribeWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnsubscribeWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UnsubscribeWrapperQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5a1bf38c11d5d329ba47a4f5c4074b10",
    "id": null,
    "metadata": {},
    "name": "UnsubscribeWrapperQuery",
    "operationKind": "query",
    "text": "query UnsubscribeWrapperQuery {\n  viewer {\n    ...UnsubscribeWrapper_viewer\n    id\n  }\n}\n\nfragment UnsubscribeWrapper_viewer on Viewer {\n  id\n}\n"
  }
};

(node as any).hash = "94322080d6b1bed0cd84e11a8bc3f469";

export default node;
