import { Interval } from "luxon";
import { useMemo } from "react";
import { EventDetailsByIdQuery } from "../../hooks/__generated__/EventDetailsById.v2.generated";
import { useGatewayEventDetails } from "../../hooks/useGatewayEventDetails";
import { sortedAttendeesByOrganizerYouAndStatus } from "../utils/sortedAttendeesByOrganizerYouAndStatus";

export const formatIntervalContextMenu = (eventInterval?: Interval) => {
  const startDate = eventInterval?.start;
  const endDate = eventInterval?.end;
  if (!startDate || !endDate) return "";
  if (!startDate.hasSame(endDate, "day")) {
    return `${startDate.toFormat("EEE, MMM d")} - ${endDate.toFormat("EEE, MMM d")}`;
  } else {
    return `${startDate.toFormat("EEE, MMM d")} ⸱ ${startDate
      .toFormat("h:mm a")
      .toLocaleLowerCase()} - ${endDate.toFormat("h:mm a").toLocaleLowerCase()}`;
  }
};

type EventPopoverDetails = {
  attendees: EventDetailsAttendees;
  eventTimeString: string | undefined;
  hasExternalAttendees: boolean;
  id: string | undefined;
  loading: boolean;
  ownerDisplayName: string;
  isHold: boolean;
  isMyEvent: boolean;
};

type EventDetails = EventDetailsByIdQuery["event"];
export type EventDetailsAttendees = Extract<EventDetails, { __typename: "Event" }>["attendees"];

export const useEventPopoverDetails = ({
  externalEventId,
  calendarId,
}: {
  externalEventId: string;
  calendarId: string;
}): EventPopoverDetails => {
  const { data, loading: loadingData } = useGatewayEventDetails(externalEventId, calendarId);

  // `loadingData` will still be true if we hit the cache, so only show the loading spinner if we don't have any data.
  const loading = loadingData && !data;

  const event = data?.event;
  const eventId = event?.id;
  const isHold = event?.isHold ?? false;
  const isMyEvent = event?.isMyEvent ?? false;
  const dateOrTimeRange =
    data?.event?.dateOrTimeRange.__typename === "DateTimeRange"
      ? data.event.dateOrTimeRange.timeRange
      : data?.event?.dateOrTimeRange.dateRange;

  const ownerDisplayName = event?.calendar.displayName ?? calendarId;

  // Attendee sorting matches event card attendee sorting
  const attendees = useMemo(
    () =>
      data?.event?.attendees
        ? sortedAttendeesByOrganizerYouAndStatus(data?.event?.attendees, calendarId)
        : [],
    [calendarId, data?.event?.attendees],
  );

  const hasExternalAttendees = attendees.some((attendee) => attendee.isExternal);

  const interval = dateOrTimeRange ? Interval.fromISO(dateOrTimeRange) : undefined;
  const eventTimeString = formatIntervalContextMenu(interval);

  return useMemo(() => {
    return {
      attendees,
      eventTimeString,
      hasExternalAttendees,
      id: eventId,
      loading,
      ownerDisplayName,
      isHold,
      isMyEvent,
    };
  }, [
    attendees, //
    eventTimeString, //
    eventId, //
    hasExternalAttendees, //
    loading, //
    ownerDisplayName, //
    isHold, //
    isMyEvent, //
  ]);
};
