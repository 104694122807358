import {
  DatePickerType,
  DatePickerProps as MDatePickerProps,
  DatePickerValue as MDatePickerValue,
} from "@mantine/dates";
import { compact } from "lodash";
import { DateTime } from "luxon";
import { useMemo } from "react";

/**
 * A ISO 8601 formatted date string, YYYY-MM-DD
 * Eventually we should make a class or use opaque types for better type safety */
export type LocalDate = string;

/** Allowlist of Mantine DatePicker props to include in interface for DS date picker components */
export type AllowedProps =
  | "allowDeselect"
  | "allowSingleDateInRange"
  | "ariaLabels"
  | "hideOutsideDates"
  | "hideWeekdays"
  | "highlightToday"
  | "getDayAriaLabel"
  | "getDayProps"
  | "getMonthControlProps"
  | "getYearControlProps";

/** Generic value for various types of date picker `value` props */
export type DateValue<T extends DatePickerType> = {
  default: LocalDate | null;
  multiple: LocalDate[];
  range: [LocalDate | null, LocalDate | null];
}[T];

/**
 * Helper hook for converting `date`-related props from our components' public interfaces to the
 * internal Mantine types
 */
export const useInternalDate = ({
  date: _date,
  defaultDate: _defaultDate,
  minDate: _minDate,
  maxDate: _maxDate,
  onDateChange: _onDateChange,
  excludeDate: _excludeDate,
}: {
  date?: LocalDate;
  defaultDate?: LocalDate;
  minDate?: LocalDate;
  maxDate?: LocalDate;
  onDateChange?: (date: LocalDate) => void;
  excludeDate?: (date: LocalDate) => boolean;
}) => {
  const date = useMemo(() => toInternalDate(_date), [_date]);
  const defaultDate = useMemo(() => toInternalDate(_defaultDate), [_defaultDate]);
  const minDate = useMemo(() => toInternalDate(_minDate), [_minDate]);
  const maxDate = useMemo(() => toInternalDate(_maxDate), [_maxDate]);
  const onDateChange = useMemo<MDatePickerProps<"multiple">["onDateChange"]>(
    () => _onDateChange && ((date) => _onDateChange(fromInternalDate(date))),
    [_onDateChange],
  );
  const excludeDate = useMemo<MDatePickerProps<"multiple">["excludeDate"]>(
    () => _excludeDate && ((date) => _excludeDate(fromInternalDate(date))),
    [_excludeDate],
  );
  return {
    date,
    defaultDate,
    minDate,
    maxDate,
    onDateChange,
    excludeDate,
  };
};

export const toInternalDate = <T extends null | undefined>(date: LocalDate | T): Date | T =>
  typeof date === "string" ? DateTime.fromISO(date, { zone: "local" }).toJSDate() : date;

export const toInternalDateRange = (
  date?: DateValue<"range">,
): MDatePickerValue<"range"> | undefined =>
  date ? [toInternalDate(date[0]), toInternalDate(date[1])] : date;

export const toInternalDateArray = (
  date?: DateValue<"multiple">,
): MDatePickerValue<"multiple"> | undefined =>
  date ? compact(date.map((d) => toInternalDate(d) ?? null)) : date;

export const fromInternalDate = (date: Date) =>
  DateTime.fromJSDate(date, { zone: "local" }).toISODate();

export const fromInternalDateRange = ([start, end]: [Date | null, Date | null]): [
  string | null,
  string | null,
] => [start && fromInternalDate(start), end && fromInternalDate(end)];
