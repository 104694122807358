import { compareBooleans } from "#webapp/src/util/sort.util";

export const sortCalendarIdsByProposalStatus = (
  allCalendarIds: string[],
  proposalCalendarIds: string[],
  primaryCalendarId: string,
) => {
  const sorted = allCalendarIds.sort((a, b) => {
    return (
      compareBooleans(b === primaryCalendarId, a === primaryCalendarId) ||
      compareBooleans(proposalCalendarIds.includes(b), proposalCalendarIds.includes(a))
    );
  });

  return sorted;
};
