import { isBrave } from "@clockwise/web-commons/src/util/browser.util";
import classNames from "classnames";
import { PuzzlePiece } from "phosphor-react";
import React from "react";
import { browserName, CustomView } from "react-device-detect";
import { ExtensionDownloadButton, sendTrackingWebApp } from "../extension-download-button";
import { useUserProfile } from "../hooks/useUserProfile";

const BrowserIntegration = () => {
  const isChrome = browserName === "Chrome" && !isBrave;
  const isFF = browserName === "Firefox";
  const displayedBrowserName = isBrave ? "Brave" : browserName;

  const {
    userProfile: { userId },
  } = useUserProfile();

  return (
    <div className="cw-w-full">
      <CustomView condition={isChrome || isFF}>
        <div
          className={classNames(
            "cw-flex cw-items-center cw-justify-evenly",
            "cw-px-2 cw-py-3",
            "cw-rounded-lg cw-border-transparent",
            "cw-bg-positive",
          )}
        >
          <div className="cw-body-xl cw-font-bold cw-text-accent">
            Get the most of out Clockwise
          </div>
          <ExtensionDownloadButton
            browserName={isChrome ? "Chrome" : "Firefox"}
            onClick={() => {
              sendTrackingWebApp("CONNECT_APPS", userId);
            }}
          />
        </div>
      </CustomView>
      <CustomView condition={!isChrome && !isFF}>
        <div>
          <h2 className="cw-heading-2xl">
            <PuzzlePiece size={32} className="cw-align-bottom cw-mr-2" />
            <span>Browser</span>
          </h2>
          <div>
            Sorry, we do not have a browser extension for {displayedBrowserName}. Try Firefox or
            Chrome.
          </div>
        </div>
      </CustomView>
    </div>
  );
};

export default BrowserIntegration;
