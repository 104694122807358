import { Divider, HoverCard } from "@clockwise/design-system";
import { CategorySwatchIcon } from "@clockwise/design-system/src/components/Icons/CategorySwatch";
import { bg_info_emphasis } from "@clockwise/design-system/tokens";
import { ChevronRight, OpenInNew, Sell, Settings } from "@clockwise/icons";
import {
  CategoryOption,
  sortByLabel,
} from "@clockwise/web-commons/src/components/module-category-picker/CategoryPickerModule";
import {
  appPaths,
  webappDefaultWindowName,
} from "@clockwise/web-commons/src/constants/route.constants";
import { Category } from "@clockwise/web-commons/src/hooks/useEventCategory";
import { Skeleton } from "@material-ui/lab";
import { noop } from "lodash";
import { default as React } from "react";
import { ListItemButton } from "../../calendar-popover/atoms/ListItemButton";
export const EventCategory = ({
  loading,
  category,
  categoryOptions,
  onChange,
}: {
  category: Category;
  categoryOptions: Category[];
  loading: boolean;
  onChange: (category: CategoryOption) => void;
}) => {
  const handleEditCategories = () => {
    window.open(appPaths.accountPreferences, webappDefaultWindowName);
  };

  if (loading)
    return (
      <div className="cw-py-1 cw-my-1 cw-px-1 cw-flex cw-items-center cw-gap-2">
        <Skeleton variant="circle" height={16} width={16} className="cw-ml-[2px]" />
        <Skeleton variant="rect" height={24} className="cw-flex-grow cw-rounded-md" />
      </div>
    );

  // NOTE: The HoverCard itself is invisible. It's providing a mouse-navigatable buffer between
  // the button and the (actual visible) menu container. Without it, there's a risk the HoverCard
  // disappears while the user is mousing over to it.
  return (
    <HoverCard
      position="right"
      classNames={{
        dropdown: "cw-pr-4 cw-bg-none cw-bg-transparent cw-border-0 cw-shadow-none",
      }}
      offset={0}
    >
      <HoverCard.Target>
        <div>
          <ListItemButton startIcon={Sell} endIcon={ChevronRight} onClick={noop}>
            Event category
          </ListItemButton>
        </div>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <div
          cw-id="event-category-portal"
          className="cw-rounded-xl cw-overflow-auto cw-body-sm cw-text-12 cw-bg-default cw-shadow-selectPopup cw-px-1 cw-py-2"
        >
          <div className="cw-mx-1.5">
            <Divider spacing="xs" size="xs" labelPosition="left" label="Meeting Category" />
          </div>
          {categoryOptions.sort(sortByLabel).map((option) => {
            return (
              <ListItemButton
                key={option.label}
                onClick={() => {
                  onChange(option);
                }}
                keepHoveredStyle={category.value === option.value}
              >
                <div className="cw-flex cw-flex-row cw-items-center">
                  <CategorySwatchIcon
                    className="cw-w-4 cw-h-4 cw-mr-2"
                    fill={option.color || bg_info_emphasis}
                  />
                  {option.label}
                </div>
              </ListItemButton>
            );
          })}
          <div className="cw-mx-1.5">
            <Divider spacing="xs" size="xs" labelPosition="left" label="Settings" />
          </div>
          <ListItemButton startIcon={Settings} endIcon={OpenInNew} onClick={handleEditCategories}>
            Edit Categories
          </ListItemButton>
        </div>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
