import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { UserCreatedTimeDocument } from "./__generated__/UserCreatedTime.generated";

export const useUserCreatedTime = () => {
  const { data } = useQuery(UserCreatedTimeDocument);

  const createdTime: number | null = useMemo(() => data?.viewer?.user?.createdTime ?? null, [data]);

  return { createdTime };
};
