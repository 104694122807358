import { Select, SelectOption, SelectOptionsGroup } from "@clockwise/design-system";
import React, { useState } from "react";
import { AddAdmin } from "./actions/AddAdmin";
import { AddMembersToBillingGroup } from "./actions/AddMembersToBillingGroup";
import { CreateBillingGroup } from "./actions/CreateBillingGroup";
import { DeleteBillingGroup } from "./actions/DeleteBillingGroup";
import { EnableSSO } from "./actions/EnableSSO";
import { LinkSubscriptionToBillingGroup } from "./actions/LinkSubscriptionToBillingGroup";
import { RemoveMembersFromBillingGroup } from "./actions/RemoveMembersFromBillingGroup";
import { ToggleAutoSync } from "./actions/ToggleAutoSync";
import { UnlinkSubscription } from "./actions/UnlinkSubscription";
import { UpdateBillingGroupName } from "./actions/UpdateBillingGroupName";
import { UpdateInvitePolicy } from "./actions/UpdateInvitePolicy";
import { BillingGroupLookup } from "./BillingGroupLookup";
import { OrgLookup } from "./OrgLookup";
type ActionList = {
  name: string;
  value: string;
  component: React.ReactNode;
}[];
const SUDO_BG_CREATE_ACTIONS: ActionList = [
  {
    name: "Create a billing group",
    value: "createBillingGroup",
    component: <CreateBillingGroup />,
  },
  {
    name: "Link subscription to billing group",
    value: "linkSubscriptionToBillingGroup",
    component: <LinkSubscriptionToBillingGroup />,
  },
  {
    name: "Enable SSO",
    value: "enableSSO",
    component: <EnableSSO />,
  },
  {
    name: "Add admin to billing group",
    value: "addAdminToBillingGroup",
    component: <AddAdmin />,
  },
  {
    name: "Add users to billing group",
    value: "addUsersToBillingGroup",
    component: <AddMembersToBillingGroup />,
  },
];

const SUDO_BG_UPDATE_ACTIONS: ActionList = [
  {
    name: "Update billing group name",
    value: "updateBillingGroupName",
    component: <UpdateBillingGroupName />,
  },
  {
    name: "Update auto sync for org plan",
    value: "toggleAutoSyncForOrgPlan",
    component: <ToggleAutoSync />,
  },
  {
    name: "Update invite policy",
    value: "updateInvitePolicy",
    component: <UpdateInvitePolicy />,
  },
];

const SUDO_BG_DELETE_ACTIONS: ActionList = [
  {
    name: "Remove users from billing group",
    value: "removeUsersFromBillingGroup",
    component: <RemoveMembersFromBillingGroup />,
  },
  {
    name: "Unlink subscription",
    value: "unlinkSubscription",
    component: <UnlinkSubscription />,
  },

  {
    name: "Delete a billing group",
    value: "deleteBillingGroup",
    component: <DeleteBillingGroup />,
  },
];
const allActions = SUDO_BG_CREATE_ACTIONS.concat(SUDO_BG_UPDATE_ACTIONS).concat(
  SUDO_BG_DELETE_ACTIONS,
);

export const SudoProvisioning = () => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(allActions[0].value);

  const handleSelectChange = (value: string | undefined) => {
    if (value) {
      setSelectedOption(value);
    }
  };

  const renderComponentForSelectedOption = (actionList: ActionList) => {
    const selectedAction = actionList.find((action) => action.value === selectedOption);
    return selectedAction?.component;
  };

  type ActionList = {
    name: string;
    value: string;
    component: React.ReactNode;
  }[];

  return (
    <div className="cw-flex cw-flex-col cw-px-8 cw-w-full">
      <div className="cw-flex cw-items-center cw-w-full cw-justify-start cw-pb-4">
        <div className="cw-heading-3xl">Sudo Provisioning</div>
      </div>
      <div className="cw-flex cw-w-full ">
        <div className="cw-flex cw-flex-col cw-gap-6 cw-w-[40%] cw-bg-neutral cw-rounded-lg cw-p-4 cw-mr-4 cw-h-full">
          <OrgLookup />
          <BillingGroupLookup />
        </div>
        <div className="cw-flex cw-flex-col cw-gap-6 cw-w-[60%] cw-bg-neutral cw-rounded-lg cw-p-4 cw-h-full ">
          <div className="cw-heading-lg"> Actions </div>
          <Select value={selectedOption} onChange={(value) => handleSelectChange(value)}>
            <SelectOptionsGroup label="Create">
              {SUDO_BG_CREATE_ACTIONS.map((action) => (
                <SelectOption key={action.value} value={action.value}>
                  {action.name}
                </SelectOption>
              ))}
            </SelectOptionsGroup>
            <SelectOptionsGroup label="Update">
              {SUDO_BG_UPDATE_ACTIONS.map((action) => (
                <SelectOption key={action.value} value={action.value}>
                  {action.name}
                </SelectOption>
              ))}
            </SelectOptionsGroup>
            <SelectOptionsGroup label="Delete">
              {SUDO_BG_DELETE_ACTIONS.map((action) => (
                <SelectOption key={action.value} value={action.value}>
                  {action.name}
                </SelectOption>
              ))}
            </SelectOptionsGroup>
          </Select>
          <div className="cw-pb-2">{renderComponentForSelectedOption(allActions)}</div>
        </div>
      </div>
    </div>
  );
};
