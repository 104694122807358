import {
  EventContainer,
  NamedEvent,
} from "@clockwise/web-commons/src/components/feature-illustration/";
import React from "react";

const MeetingBreakIllustration = () => {
  return (
    <EventContainer>
      <NamedEvent duration={15} title="One On One" />
      <NamedEvent duration={45} sentiment="info" title="Focus Time" />
      <NamedEvent duration={15} sentiment="warning" title="Intro Call" />
    </EventContainer>
  );
};

export default MeetingBreakIllustration;
