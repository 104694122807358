import { intersection } from "lodash";

export const getFullWidthPositionerData = (
  partialCalendarIds: string[],
  allCalendarIds: string[],
  maxColumns: number,
  minWidthPercent: number,
) => {
  const partialInAllInCalendarIds = intersection(partialCalendarIds, allCalendarIds).length;
  const widthPercentFromCalendarIds = (partialInAllInCalendarIds / allCalendarIds.length) * 100;
  const maxColumnsExceeded = maxColumns < partialInAllInCalendarIds;

  const minWidthForCalendarIds = minWidthPercent * partialInAllInCalendarIds;

  const widthPercent =
    widthPercentFromCalendarIds < minWidthForCalendarIds
      ? minWidthForCalendarIds
      : widthPercentFromCalendarIds;

  const columnCountOverride = maxColumnsExceeded ? partialInAllInCalendarIds : undefined;

  return { widthPercent, maxColumnsExceeded, columnCountOverride };
};
