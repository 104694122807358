import { Button } from "@clockwise/design-system";
import { ConferencingType } from "@clockwise/schema";
import classNames from "classnames";
import { isEmpty, isUndefined } from "lodash";
import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { EventElementsWrap } from "../atoms/EventElementsWrap";
import { SelectVideoConf } from "../atoms/SelectVideoConf";

const _openVideoLink = (url: string) => {
  window.open(url, "_blank");
};

const notifyJoinError = () => toast.error("Unable to open video link");

type Props = {
  enabledTypes: ConferencingType[];
  onSelect: (type?: ConferencingType) => void;
  openVideoLink?: (url: string) => void;
  readonly?: boolean;
  selected?: ConferencingType;
  videoLink?: string;
  showChanges?: boolean;
};

export const ECVideoConf = ({
  enabledTypes,
  readonly = false,
  onSelect,
  openVideoLink = _openVideoLink,
  selected,
  videoLink,
  showChanges,
}: Props) => {
  const handleJoin = useCallback(
    () =>
      isUndefined(videoLink) || isEmpty(videoLink) ? notifyJoinError() : openVideoLink(videoLink),
    [openVideoLink, videoLink],
  );

  const showJoinButton = selected && videoLink;

  return (
    <EventElementsWrap name="video" showChanges={showChanges}>
      <div className="cw-flex cw-flex-1 cw-justify-between cw-items-center">
        <div
          className={classNames("cw-flex cw-flex-1 cw-items-center", {
            "cw-max-w-[290px]": showJoinButton,
          })}
        >
          <SelectVideoConf
            disabled={readonly}
            enabledTypes={enabledTypes}
            onSelect={onSelect}
            selected={selected}
          />
        </div>
        {showJoinButton && (
          <Button
            cw-id="ec-location-route-button"
            size="mini"
            variant="text"
            sentiment="positive"
            onClick={handleJoin}
          >
            Join
          </Button>
        )}
      </div>
    </EventElementsWrap>
  );
};
