import { Button, Tooltip } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { WebappSurface } from "@clockwise/schema";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { every } from "lodash";
import React from "react";
import { useMonetization } from "../../hooks/useMonetization";
import { useSetFlexibility } from "../flexible-meetings/useSetFlexibility";
import { ScrollIntoView } from "../ui-focus-wrappers/ScrollIntoView";
import { OneOnOnesListDocument } from "./__generated__/OneOnOnes.v2.generated";
import { FlexEventCardColumns } from "./flex-event-card/FlexEventCardColumns";
import { parseAndFilterFlexibility } from "./util/parseFlexibility";

export const OneOnOnes = () => {
  const { error, loading, data } = useGatewayQuery(OneOnOnesListDocument);

  const {
    handleFlexibilityChange,
    handleAllFlexibilityChange,
    handleFlexibilityUpdating,
  } = useSetFlexibility(data?.flexibleMeetingsDashboardV2.oneOnOnes ?? [], "One-on-ones control");
  const { canUserAccessFlexMeetings } = useMonetization();

  const oneOnOnes = parseAndFilterFlexibility(data?.flexibleMeetingsDashboardV2.oneOnOnes ?? []);

  const allFlexible = every(oneOnOnes, "isFlexible");
  const fetchError = error?.message ? "Unable to fetch One-on-ones" : undefined;

  const switchTooltipText = canUserAccessFlexMeetings ? "" : "Upgrade to mark meetings as flexible";

  return (
    <ScrollIntoView focusSection={WebappSurface.FlexibleMeetings}>
      <div className="cw-mb-3">
        <div className="cw-flex cw-flex-wrap cw-items-center cw-mb-1">
          <h2 className="cw-text-20 cw-grow cw-mb-0 cw-font-semibold">One-on-ones</h2>
          <div className="cw-relative">
            <Tooltip title={switchTooltipText}>
              <div className="cw-pb-[2px]">
                {!allFlexible && (
                  <div className="cw-flex cw-items-center cw-gap-2">
                    {handleFlexibilityUpdating && <Loader size="xs" sentiment="positive" />}
                    <Button
                      size="xsmall"
                      variant="outlined"
                      disabled={
                        loading ||
                        handleFlexibilityUpdating ||
                        !oneOnOnes.length ||
                        !canUserAccessFlexMeetings
                      }
                      cw-id="mark-all-flexible-button"
                      onClick={() => {
                        handleAllFlexibilityChange(true);
                      }}
                    >
                      Mark all 1-1s as flexible
                    </Button>
                  </div>
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="cw-grid cw-grid-cols-1 md:cw-grid-cols-3 cw-gap-2">
        <FlexEventCardColumns
          oneOnOnes={oneOnOnes}
          handleFlexibilityChange={handleFlexibilityChange}
          loading={loading}
          fetchError={fetchError}
        />
      </div>
    </ScrollIntoView>
  );
};
