import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { map } from "lodash";
import pluralize from "pluralize";
import React, { useState } from "react";
import { TrackingEvents, track } from "../../../../util/analytics.util";
import { InviteSearchPerson } from "../../../invite-search";
import { useInviteSuggestions } from "../../../invite-search/hooks/useInviteSuggestions";
import { useWebOnboardingSteps } from "../../hooks/useWebOnboardingSteps";
import { ButtonGroup, StepCounter, StepLayout } from "../../shared-components";
import { PowerStep } from "../../shared-components/PowerMeter";
import { setError } from "../../utils/setError";
import { useInviteStepContext } from "./InviteContext";
import { InviteSlide } from "./InviteSlide";
import { SendInvitesDocument } from "./__generated__/InviteStep.v2.generated";

export const InviteStep = () => {
  const { goForward, goBack, shouldShowSecondaryButton, funnelType } = useWebOnboardingSteps();
  const { invitees, addInvitees, removeInvitees } = useInviteStepContext();
  const { suggestions, loading } = useInviteSuggestions((error) =>
    setError({
      message: "failed to gather invite suggestions",
      error: error,
      showUserMessage: false,
    }),
  );

  const [sendInvites] = useGatewayMutation(SendInvitesDocument);

  const [disableButtons, setDisableButtons] = useState(false);

  const updateSelectedUsers = (selectedInvitees: InviteSearchPerson[], checked: boolean) => {
    if (checked) {
      addInvitees(selectedInvitees);
    } else {
      removeInvitees(selectedInvitees);
    }
  };

  const onSaveAndGoForward = async () => {
    if (invitees.length > 0) {
      const calendarIds = map(invitees, "primaryCalendarId");
      const trackedProperties = {
        inviteCount: invitees.length,
        invitedCalendarIds: calendarIds,
        bulkInvites: [],
        numSelectedBulkInvites: 0,
        funnelType,
      };
      track(TrackingEvents.WEB_ONBOARDING.INVITE_SENT, trackedProperties);
      setDisableButtons(true);

      try {
        await sendInvites({ variables: { calendarIds } });
        track(TrackingEvents.WEB_ONBOARDING.INVITE_SENT_ON_COMPLETE, trackedProperties);
      } catch (error) {
        setError({ error, message: "failed to send invites", showUserMessage: true });
      } finally {
        // Clear selected invitee state
        removeInvitees(invitees);
        setDisableButtons(false);
        goForward();
      }
    } else {
      track(TrackingEvents.WEB_ONBOARDING.INVITE_SKIPPED, {
        funnelType,
      });
      goForward();
    }
  };

  const primaryButtonText =
    invitees.length > 0 ? `Invite ${pluralize("person", invitees.length, true)}` : "Continue";

  const shouldShowPowerMeeter = lengthOfSelectableSuggestions(suggestions) >= 3;

  return (
    <StepLayout>
      <StepCounter />
      <InviteSlide
        selectedInvitees={invitees}
        suggestions={suggestions}
        loading={loading}
        onCheck={updateSelectedUsers}
      />
      <ButtonGroup
        shouldShowSecondary={shouldShowSecondaryButton}
        primaryLabel={primaryButtonText}
        onClickPrimary={onSaveAndGoForward}
        onClickSecondary={goBack}
        disabled={disableButtons}
        powerMeeterStrength={
          shouldShowPowerMeeter ? getStrengthBasedOnInvitees(invitees.length) : null
        }
      />
    </StepLayout>
  );
};

const lengthOfSelectableSuggestions = (suggestions: InviteSearchPerson[]) => {
  return suggestions.filter((s) => !s.pending && !s.isUser).length;
};

const getStrengthBasedOnInvitees = (inviteesLength: number): PowerStep => {
  switch (inviteesLength) {
    case 0:
      return 1;
    case 1:
      return 2;
    case 2:
      return 3;
    default:
      return 4;
  }
};
