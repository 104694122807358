import { WorkingLocationDetailsDocument } from "#webapp/src/components/event-card/__generated__/WorkingLocationDetails.v2.generated";
import { Divider } from "@clockwise/design-system";
import { Business } from "@clockwise/design-system/icons";
import { Home, LocationOff } from "@clockwise/icons";
import { WorkingLocationType } from "@clockwise/schema/v2";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import React from "react";
import { Title } from "../atoms/Title";
import { EditButton, Header } from "../molecules/Header";
import { EditSettingsLink } from "./EditSettingsLink";

const WorkingLocationTypeIcon = ({ type }: { type: WorkingLocationType }) => {
  switch (type) {
    case WorkingLocationType.Home:
      return <Home />;
    case WorkingLocationType.Office:
      return <Business />;
    case WorkingLocationType.Other:
      return <LocationOff />;
  }
};

export const WorkingLocationMenu = ({
  calendarId,
  eventId,
  onClose,
  onOpenCard,
  canExpand = true,
}: {
  calendarId: string;
  eventId: string;
  onClose: () => void;
  onOpenCard: () => void;
  canExpand?: boolean;
}) => {
  const { data, loading } = useGatewayQuery(WorkingLocationDetailsDocument, {
    variables: {
      externalEventId: eventId,
      calendarId,
    },
  });

  const workingLocationEvent = data?.calendars[0]?.workingLocationEvent;
  if (!workingLocationEvent) {
    return <></>;
  }

  const title = workingLocationEvent.title ?? "(No title)";
  const { type, name } = workingLocationEvent.location;

  return (
    <div className="cw-flex-col cw-space-y-1">
      <Header onClose={onClose}>{canExpand && <EditButton onClick={onOpenCard} />}</Header>
      <div className="cw-px-2">
        <Title loading={loading} title={title} subTitle="Working location" onClick={onOpenCard} />
        <Divider spacing="sm" />
        <div className="cw-px-2">
          <div className="cw-flex cw-items-center">
            <WorkingLocationTypeIcon type={type} />
            <div>{name}</div>
          </div>
        </div>
        <Divider spacing="sm" />
        <EditSettingsLink />
      </div>
    </div>
  );
};
