import { Tooltip } from "@clockwise/design-system";
import { InfoFilled, LockFilled, SvgIconComponent } from "@clockwise/icons";
import classNames from "classnames";
import React from "react";
import { EventCardWarning } from "../utils/getEventCardWarning";

export const WarningMessage = ({ type }: { type: EventCardWarning }) => {
  if (type === EventCardWarning.ExternalUser) {
    return (
      <WarningMessageBase
        tooltipContent="Meetings with external attendees can't be marked as flexible and their calendars cannot be shown."
        message="This meeting has external attendees."
      />
    );
  } else if (type === EventCardWarning.NotYourEvent) {
    return <WarningMessageBase icon={LockFilled} message="You may have limited edit access." />;
  } else {
    return null;
  }
};

export const WarningMessageBase = ({
  tooltipContent,
  message,
  icon: MessageIcon,
  sentiment = "info",
}: {
  tooltipContent?: string;
  message: string;
  icon?: SvgIconComponent;
  sentiment?: "info"; // Add more sentiments as needed
}) => {
  return (
    <div
      className={classNames(
        "cw-flex cw-gap-1 cw-border-solid cw-border-b cw-justify-center cw-items-center cw-py-1 cw-body-sm cw-text-12 cw-font-medium cw-border-subtle",
        {
          "cw-text-busy cw-bg-neutral": sentiment === "info",
        },
      )}
    >
      {MessageIcon && <MessageIcon className="cw-w-[14px] cw-h-[14px]" />}
      {message}
      {tooltipContent && (
        <Tooltip title={tooltipContent}>
          <InfoFilled className="cw-w-[14px] cw-h-[14px]" />
        </Tooltip>
      )}
    </div>
  );
};
