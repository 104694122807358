import * as Types from '@clockwise/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateFlagsForUserMutationVariables = Types.Exact<{
  input: Types.UpdateFlagsForUserMutationInput;
}>;


export type UpdateFlagsForUserMutation = { __typename: 'Mutation', updateFlagsForUser: { __typename: 'UpdateFlagsForUserMutationPayload', flags: { __typename: 'Flags', id: string, flags: Array<string | null> | null } | null } | null };


export const UpdateFlagsForUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFlagsForUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateFlagsForUserMutationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateFlagsForUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"flags"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateFlagsForUserMutation, UpdateFlagsForUserMutationVariables>;