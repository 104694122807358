import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDiffIdWithCalendarIds,
  addEventIdWithCalendarIds,
} from "../../../../../../state/actions/multi-calendar.actions";
import { IReduxState } from "../../../../../../state/reducers/root.reducer";

// The purpose of this hook is to support UX for the following scenario:
// The user opens an event card and clicks on the "Edit attendees" button
// And instead of saving, the user closes the event card without saving
// in such a case, we want to reset the attendees to the original attendees
// So that the multi-calendar layers are not affected by the user's uncommitted edits
export const useResetEditsToAttendees = ({
  diffId,
  externalEventId,
}: {
  diffId?: string;
  externalEventId?: string;
}) => {
  const originalCalendarIdsBeforeEditing =
    useSelector(
      (state: IReduxState) => state.multiCalendar.eventIdOrDiffIdMappedToCalendarIdsBeforeEdit,
    )[diffId || externalEventId || ""] || [];

  const dispatch = useDispatch();

  const resetCallback = useCallback(() => {
    if (diffId) {
      dispatch(addDiffIdWithCalendarIds(diffId, originalCalendarIdsBeforeEditing));
    } else if (externalEventId) {
      dispatch(addEventIdWithCalendarIds(externalEventId, originalCalendarIdsBeforeEditing));
    }
  }, [diffId, externalEventId, originalCalendarIdsBeforeEditing]);

  return [resetCallback];
};
