import { every, isEmpty } from "lodash";
import { compact, map } from "lodash/fp";

import { useQuery } from "@apollo/client";
import { Profile } from "@clockwise/web-commons/src/util/profile.util";
import { transform } from "@clockwise/web-commons/src/util/transform.util";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { ExcludedMeetingType } from "../../__schema__";
import { MyFlexibleMeetingsDocument } from "../../graphql/__generated__/MyFlexibleMeetings.generated";
import { removeAutopilotEmoji } from "../../util/event.util";
import getAttendeeProfiles from "../../util/getAttendeeProfiles";
import getFlexibilityIsEditable from "../../util/getFlexibilityIsEditable";
import getStartDateTime from "../../util/getStartDateTime";
import getTimeDescription from "../../util/getTimeDescription";
import isTimeRangeFlexible from "../../util/isTimeRangeFlexible";
import { logger } from "../../util/logger.util";

type FlexibleMeeting = {
  attendees: Profile[];
  flexible: boolean;
  flexibilityEditable: boolean;
  id: string;
  startTime: DateTime;
  recurrenceText: string;
  title: string;
};

export const useFlexibleMeetings = (excludedMeetingTypes: ExcludedMeetingType[] = []) => {
  const { data, loading, error } = useQuery(MyFlexibleMeetingsDocument, {
    variables: { input: { exclude: excludedMeetingTypes } },
    errorPolicy: "all",
  });

  const flexibleMeetings: FlexibleMeeting[] = useMemo(
    () =>
      transform(
        data?.viewer?.user?.orgs?.edges?.[0]?.node?.flexibleMeetingsDashboard?.myFlexibleMeetings ??
          [],
        compact,
        map(({ event }) => ({
          attendees: getAttendeeProfiles(event),
          flexible: isTimeRangeFlexible(event),
          flexibilityEditable: getFlexibilityIsEditable(event),
          id: event.id,
          startTime: getStartDateTime(event),
          recurrenceText: getTimeDescription(event),
          title: removeAutopilotEmoji(event.title) || "Untitled Event",
        })),
      ),
    [data],
  );

  /**
   * External attendees being unable to be loaded does not break the UI for flexible meetings, so
   * we don't want to report errors in that situation.
   */
  const ignorableErrors =
    !!error?.graphQLErrors &&
    !isEmpty(error.graphQLErrors) &&
    every(error.graphQLErrors, ({ message }) =>
      message.startsWith("Unable to find external org member"),
    );

  if (ignorableErrors) {
    const message = `Ignoring ${error.graphQLErrors.length} errors in flexible meeting request.`;
    console.warn(message);
    logger.info(message, error);
  }

  return { flexibleMeetings, loading, error: ignorableErrors ? undefined : error };
};

export default useFlexibleMeetings;
