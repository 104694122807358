import { Link, Tooltip } from "@clockwise/design-system";
import { HelpFilled } from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { track, TrackingEvents } from "../../util/analytics.util";
import { accentBorderClasses } from "./PersonalizedStatsRowGateway";

const StatChange = ({
  delta,
  flexMeetingEnabled,
}: {
  delta: number;
  flexMeetingEnabled: boolean;
}) => {
  const deltaString = delta ? (delta > 0 ? `+${delta}` : delta) : "No change";
  const navigate = useNavigate();

  if (!flexMeetingEnabled) {
    return (
      <div className="cw-body-base">
        <Link
          href={appPaths.flexibleMeetings}
          onClick={(event) => {
            event.preventDefault();
            navigate(appPaths.flexibleMeetings);
            track(TrackingEvents.WEB_LANDING_PAGE.PERSONALIZED_STATS.ZERO_STATE_CTA_CLICKED, {
              type: "ConflictsResolved",
            });
          }}
        >
          Make events Flexible
        </Link>
      </div>
    );
  }

  return (
    <div className="cw-flex cw-body-base" aria-hidden>
      <p
        className={classNames("cw-flex cw-items-center cw-justify-center cw-mr-1", {
          "cw-text-destructive": delta < 0,
          "cw-text-positive": delta > 0,
          "cw-text-[#A6A6A6]": delta === 0,
        })}
      >
        {deltaString}
      </p>
      vs previous week
    </div>
  );
};

type ConflictsResolvedProps = {
  currentWeek?: number;
  previousWeek?: number;
  flexMeetingEnabled: boolean;
};

export const ConflictsResolved = ({
  currentWeek = 0,
  previousWeek = 0,
  flexMeetingEnabled,
}: ConflictsResolvedProps) => {
  const title = currentWeek ? currentWeek : "Zero";
  const delta = currentWeek - previousWeek;
  const tooltipText =
    "Clockwise automatically tries to resolve conflicts with events marked as Flexible";
  const ariaChange = delta ? delta : "No change";

  return (
    <div
      className={classNames(accentBorderClasses, "cw-flex cw-flex-col cw-items-center", {
        "cw-border-t-[#FF7000] ": currentWeek,
        "cw-border-t-[#A6A6A6]": !currentWeek,
      })}
    >
      <p
        className={classNames("cw-heading-3xl cw-text-41", {
          "cw-text-[#FF7000] ": currentWeek,
          "cw-text-[#A6A6A6]": !currentWeek,
        })}
        aria-label={`${title} conflicts resolved. ${ariaChange} vs previous week.`}
      >
        {title}
      </p>
      <div
        className={classNames("cw-heading-base cw-flex cw-justify-center cw-items-center", {
          "cw-text-[#A6A6A6]": !currentWeek,
        })}
        aria-hidden
      >
        Conflicts resolved
        <Tooltip title={tooltipText} placement="bottom">
          <HelpFilled fontSize="inherit" className="cw-ml-1 cw-text-subtle" />
        </Tooltip>
      </div>
      <StatChange delta={delta} flexMeetingEnabled={flexMeetingEnabled} />
    </div>
  );
};
