// schema
import * as ISchema from "#webapp/src/__schema__";

// libraries
import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";

// zoom-personal-settings imports
import { styles } from "./ZoomPersonalSettings.styles";
import { DeleteUserLevelZoomAccountMutationDocument } from "./__generated__/DeleteUserLevelZoomAccount.generated";

// other imports
import { CalloutBox } from "#webapp/src/components/callout-box";
import { A, H2 } from "#webapp/src/components/ui";
import { ZoomButton } from "#webapp/src/components/zoom-button";

// state

// util
import { TrackingEvents, track } from "#webapp/src/util/analytics.util";
import { logger } from "#webapp/src/util/logger.util";
import { articleUrls } from "@clockwise/client-commons/src/constants/help-center";

// material-ui
import { Button } from "@clockwise/design-system";
import { Delete } from "@clockwise/design-system/icons";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import toast from "react-hot-toast";

///////////////
// Component //
///////////////

interface IProps {
  org: ISchema.IOrg;
  refetch: () => void;
  hideDescription?: boolean;
}

const useStyles = makeStyles(styles);

export const ZoomPersonalSettings = (props: IProps) => {
  const classes = useStyles();

  // ~-~-~-~-~-~-~-
  // Helpers
  // ~-~-~-~-~-~-~-
  const maybeGetZoomUserLevelAccountFromOrg = (org: ISchema.IOrg) => {
    if (!org.zoomUserLevelAccount) {
      return undefined;
    } else if (org.zoomUserLevelAccount.__typename !== "ZoomAccount") {
      logger.error("Failed to fetch user level zoom account");
      return undefined;
    }

    return org.zoomUserLevelAccount;
  };

  const maybeGetZoomAccountFromOrg = (org: ISchema.IOrg) => {
    if (!org.zoomAccount) {
      return undefined;
    } else if (org.zoomAccount.__typename !== "ZoomAccount") {
      logger.error("Failed to fetch zoom account");
      return undefined;
    }

    return org.zoomAccount;
  };

  // ~-~-~-~-~-~-~-
  // State
  // ~-~-~-~-~-~-~-
  const zoomUserLevelAccount = maybeGetZoomUserLevelAccountFromOrg(props.org);
  const zoomAccount = maybeGetZoomAccountFromOrg(props.org);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showAuth, setShowAuth] = useState(!zoomAccount || !zoomAccount.hasValidZoomAccount);

  // ~-~-~-~-~-~-~-
  // Lifecycle
  // ~-~-~-~-~-~-~-
  useEffect(() => {
    if (!!zoomAccount && !zoomUserLevelAccount) {
      track(TrackingEvents.ZOOM.PERSONAL_ADDED);
    }

    setShowAuth(!zoomAccount || !zoomAccount.hasValidZoomAccount);
  }, [zoomAccount, zoomUserLevelAccount]);

  // ~-~-~-~-~-~-~-
  // Mutations
  // ~-~-~-~-~-~-~-
  const [deleteUserLevelZoomAccount] = useMutation(DeleteUserLevelZoomAccountMutationDocument, {
    onCompleted: () => {
      toast.success("Deleted your Zoom integration!");
      onDeleteConfirmationModalClose();
      props.refetch();
      setShowAuth(true);
    },
    onError: () => {
      const msg = `Failed to delete Zoom integration`;
      logger.error(msg);
      toast.error(msg);
    },
  });

  // ~-~-~-~-~-~-~-
  // Callbacks
  // ~-~-~-~-~-~-~-
  const onAuthPopupClosed = () => {
    props.refetch();
  };

  const onLearnMore = () => {
    track(TrackingEvents.HELP.ZOOM);
  };

  const onDeleteConfirmationModalOpen = () => {
    setShowDeleteConfirmationModal(true);
  };

  const onDeleteConfirmationModalClose = () => {
    setShowDeleteConfirmationModal(false);
  };

  const onClickConfirmDelete = (_zoomUserLevelAccount: ISchema.IZoomAccount) => {
    track(TrackingEvents.ZOOM.PERSONAL_DELETE);

    void deleteUserLevelZoomAccount({
      variables: {
        orgId: props.org.id,
      },
    });
  };

  // ~-~-~-~-~-~-~-
  // Render
  // ~-~-~-~-~-~-~-
  const maybeRenderDeleteConfirmationModal = (zoomUserLevelAccount: ISchema.IZoomAccount) => {
    return (
      <Dialog
        open={showDeleteConfirmationModal}
        onClose={onDeleteConfirmationModalClose}
        PaperProps={{ style: { maxWidth: 700, width: 700, textAlign: "center" } }}
      >
        <DialogTitle>
          <H2 style={{ fontSize: 22, marginBottom: 0, paddingBottom: 0 }}>
            Are you sure you want to remove your Zoom account?
          </H2>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          {/* <P
            style={{
              fontSize: 15,
              lineHeight: 1.8,
              color: greys.slate,
            }}
          >
            If you delete this integration, you will no longer be able to schedule with Zoom with Clockwise.
          </P> */}
        </DialogContent>
        <DialogActions>
          <Button
            // keyboardFocused={true}
            variant="text"
            onClick={onDeleteConfirmationModalClose}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            onClick={() => onClickConfirmDelete(zoomUserLevelAccount)}
            sentiment="destructive"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderZoomAuthFlow = () => {
    return (
      <div className={classes.zoomAuthContainer}>
        <ZoomButton onAuthPopupClosed={onAuthPopupClosed} isZoomUserLevelAuth />
      </div>
    );
  };

  const maybeRenderOrgLevelZoomIntegration = (zoomAccount?: ISchema.IZoomAccount) => {
    if (!zoomAccount || !zoomAccount.hasValidZoomAccount) {
      return "";
    }

    return (
      <div className={classes.zoomAccountContainer}>
        <div className={classes.zoomAccountContent}>
          <div className={classes.zoomAccountNameInfo}>
            <div className={classes.zoomAccountName}>Zoom account connected</div>
            <div className={classes.zoomAccountDomain}>{props.org.primaryOrgCalendar}</div>
            {showAuth ? (
              ""
            ) : (
              <A
                style={{ display: "block", marginTop: 15, fontSize: 14 }}
                onClick={() => setShowAuth(true)}
              >
                Connect a different Zoom account
              </A>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderZoomPersonalSettings = (zoomUserLevelAccount: ISchema.IZoomAccount) => {
    const ownerPerson =
      zoomUserLevelAccount.ownerPersons.__typename === "PersonList"
        ? zoomUserLevelAccount.ownerPersons.list[0]
        : null;
    const zoomAccountOwnerName =
      ownerPerson && ownerPerson.isYou
        ? "You"
        : ownerPerson && ownerPerson.profile
        ? `${ownerPerson.profile.givenName} ${ownerPerson.profile.familyName}`
        : ownerPerson
        ? ownerPerson.primaryEmail
        : "";

    if (!zoomAccountOwnerName) {
      logger.error("failed to render user level zoom account owner name");
    }

    return (
      <div className={classes.zoomAccountContainer}>
        <div className={classes.zoomAccountContent}>
          <div className={classes.zoomAccountNameInfo}>
            <div className={classes.zoomAccountName}>Zoom account connected</div>
            <div className={classes.zoomAccountDomain}>
              {ownerPerson ? ownerPerson.primaryEmail : ""}
            </div>
          </div>
          <Button
            variant="text"
            sentiment="neutral"
            startIcon={Delete}
            onClick={onDeleteConfirmationModalOpen}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {!props.hideDescription && (
        <>
          <div className="cw-heading-xl cw-mb-3">Zoom</div>
          <div className="cw-body-base">
            Connect your Zoom account to automatically add Zoom video conferencing to meetings you
            schedule with Clockwise.{" "}
            <A target="_blank" href={articleUrls.zoom} onClick={onLearnMore}>
              Learn more.
            </A>
          </div>
        </>
      )}

      {!zoomUserLevelAccount && zoomAccount && zoomAccount.hasValidZoomAccount ? (
        <CalloutBox style={{ marginTop: 15 }} info>
          Your Zoom admin has set up Zoom for your organization. Your Zoom account (
          {props.org.primaryOrgCalendar}) is connected.
        </CalloutBox>
      ) : !zoomUserLevelAccount && zoomAccount && !zoomAccount.hasValidZoomAccount ? (
        <CalloutBox style={{ marginTop: 15 }} warning>
          Your Zoom admin has set up Zoom for your organization but we can't find a Zoom account
          matching {props.org.primaryOrgCalendar}. Contact your Zoom admin or connect a different
          Zoom account.
        </CalloutBox>
      ) : (
        ""
      )}
      {!zoomUserLevelAccount ? maybeRenderOrgLevelZoomIntegration(zoomAccount) : ""}
      {zoomUserLevelAccount
        ? renderZoomPersonalSettings(zoomUserLevelAccount)
        : showAuth
        ? renderZoomAuthFlow()
        : ""}
      {zoomUserLevelAccount ? maybeRenderDeleteConfirmationModal(zoomUserLevelAccount) : ""}
    </div>
  );
};
