import {
  toDetailsInput,
  toEventCardFlex,
} from "#webapp/src/components/event-card/utils/flexibility";
import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { Divider } from "@clockwise/design-system";
import { ConferencingType, SaveAddEventDiffDetailsInput } from "@clockwise/schema";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { cloneDeep, isEqual } from "lodash";
import { Interval } from "luxon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useBoolean } from "usehooks-ts";
import { useUserConfrencingTypes } from "../../../../../../hooks/useUserConfrencingTypes";
import { AllDay } from "../../../../../event-card//molecules/ECAllDay";
import { CardWrapper } from "../../../../../event-card/CardWrapper";
import { CollapsibleSection } from "../../../../../event-card/atoms/CollapsibleSection";
import { TimeTradeoffs } from "../../../../../event-card/atoms/TimeTradeoffs";
import { WarningMessage } from "../../../../../event-card/atoms/WarningMessage";
import { useEventAttendees } from "../../../../../event-card/hooks/EventAttendeesContext";
import { useReadEventTimeDetails } from "../../../../../event-card/hooks/EventCardContext";
import { ECAgenda } from "../../../../../event-card/molecules/ECAgenda";
import { ECAttendeeWrapper } from "../../../../../event-card/molecules/ECAttendeesV2/ECAttendeeWrapper";
import { ECFlexibility } from "../../../../../event-card/molecules/ECFlexibility";
import { ECFooter } from "../../../../../event-card/molecules/ECFooter";
import { ECLocation } from "../../../../../event-card/molecules/ECLocation";
import { ECRecurrence } from "../../../../../event-card/molecules/ECRecurrence";
import { ECTitle } from "../../../../../event-card/molecules/ECTitle";
import { ECVideoConf } from "../../../../../event-card/molecules/ECVideoConf";
import { EMPTY_FLEX_DETAILS, EventCardFlex } from "../../../../../event-card/types";
import { getChangesCount } from "../../../../../event-card/utils/getChangesCount";
import { getEventCardWarning } from "../../../../../event-card/utils/getEventCardWarning";
import useOrgDomains from "../../../../../hooks/useOrgDomains";
import {
  convertStringToDayOfWeek,
  formatSparkleInTitle,
} from "../../../../../web-app-calendar/hooks/useEditExistingEvent.util";
import { useReadDefaultExpandedTradeoff } from "../../../hooks/ExpandedTradeoffContext";
import { useResetEditsToAttendees as useResetEditsToAttendeesForMulticalendar } from "../../../hooks/multicalendar/event-card-edit/useResetEditsToAttendees";
import { useSetOriginalAttendeesBeforeEdit as useSetOriginalAttendeesBeforeEditForMultiCalendar } from "../../../hooks/multicalendar/event-card-edit/useSetOriginalAttendeesBeforeEdit";
import { getFlexibilityRangeFromString } from "../../../utils/getFlexibilityRangeFromString";
import { AddDiffDetails } from "../../../utils/types";

export type AddDiffEventCardExpandedProps = {
  diffEventDetails: AddDiffDetails;
  viewerIsProposalOwner?: boolean;
  onClose: () => void;
  onSubmit: (input: SaveAddEventDiffDetailsInput) => void;
};

export const AddDiffEventCardExpanded = ({
  diffEventDetails,
  viewerIsProposalOwner = true,
  onClose,
  onSubmit,
}: AddDiffEventCardExpandedProps) => {
  const {
    title: fetchedTitle,
    description: fetchedDescription,
    descriptionOmitted: fetchedDescriptionOmitted,
    attendeesOmitted: fetchedAttendeesOmitted,
    recurrenceRule: _fetchedRecurrenceRule,
    id: diffId,
    videoConferencingType: fetchedVideoType,
    flexDetails: fetchedFlexDetails,
    location: fetchedLocation,
    tradeoffBlocks: fetchedTradeoffBlocks,
  } = diffEventDetails;

  const fetchedRecurrenceRule = useMemo(
    () => (_fetchedRecurrenceRule ? new RecurrenceRule(_fetchedRecurrenceRule) : null),
    [_fetchedRecurrenceRule],
  );

  const readOnly = !viewerIsProposalOwner;
  const zone = getRenderTimeZone();
  const defaultExpandedTradeoff = useReadDefaultExpandedTradeoff();
  const { conferencingTypes } = useUserConfrencingTypes();
  const { domains: orgDomains } = useOrgDomains();
  const { startTime, endTime, timesHaveChanged, isAllDay, timeError } = useReadEventTimeDetails();
  const { attendees: attendeePeople, attendeesChanged } = useEventAttendees();
  const [videoType, setVideoType] = useState<ConferencingType | undefined>(
    fetchedVideoType ?? undefined,
  );
  const [title, setTitle] = useState<string>(fetchedTitle);
  const [description, setDescription] = useState<string>(fetchedDescription ?? "");
  const [location, setLocation] = useState<string>(fetchedLocation ?? "");
  const [recurrenceRule, setRecurrenceRule] = useState<RecurrenceRule | null>(
    fetchedRecurrenceRule,
  );
  const [flexDetails, setFlexDetails] = useState<EventCardFlex>(
    fetchedFlexDetails || cloneDeep(EMPTY_FLEX_DETAILS),
  );
  const { value: agendaHasChanges, setTrue: setAgendaChanges } = useBoolean(false);
  const isAllDayRef = useRef(isAllDay);

  useEffect(() => {
    if (fetchedDescription) {
      setDescription(fetchedDescription);
    }
    if (fetchedVideoType) {
      setVideoType(fetchedVideoType);
    }
    if (fetchedLocation) {
      setLocation(fetchedLocation);
    }
    setTitle(fetchedTitle);
    setFlexDetails(fetchedFlexDetails);
  }, [diffId]);

  const onFlexChange = (flexible: boolean) => {
    setFlexDetails({
      ...flexDetails,
      isFlexible: flexible,
    });
    setTitle(formatSparkleInTitle(title, flexible));
  };

  const attendees = attendeePeople.map((attendee) => attendee.primaryCalendar);
  const attendeesList = attendeePeople.map((attendee) => {
    return { calendarId: attendee.primaryCalendar, optional: !!attendee.isOptional };
  });

  const handleSubmit = () => {
    if (!!startTime && !!endTime) {
      const input: SaveAddEventDiffDetailsInput = {
        title: title.trim(),
        description: description.trim(),
        time: Interval.fromISO(`${startTime}/${endTime}`, { zone }).toISO(),
        isFlexible: flexDetails.isFlexible,
        flexDetails: {
          ...flexDetails,
          timeRangeFlexibility: {
            range: getFlexibilityRangeFromString(flexDetails.timeRangeFlexibility.range),
            allowedDays: flexDetails.timeRangeFlexibility.daysOfWeekFlexibility.map(
              convertStringToDayOfWeek,
            ),
          },
        },
        isAllDay,
        conferenceType: videoType,
        attendees,
        diffId: diffEventDetails.id,
        recurrence: { recurrenceRule: recurrenceRule ? recurrenceRule.toString() : null },
        location: location.trim(),
        attendeesList,
      };
      onSubmit(input);
    }
  };

  const changesMap = {
    agenda: agendaHasChanges,
    attendees: attendeesChanged,
    eventName: title !== fetchedTitle,
    eventRecurrence: fetchedRecurrenceRule?.toString() !== recurrenceRule?.toString(),
    flexDetails: !isEqual(flexDetails, fetchedFlexDetails),
    isAllDay: isAllDayRef.current !== isAllDay,
    location: (location || "") !== (fetchedLocation || ""),
    time: timesHaveChanged,
    videoType: videoType !== fetchedVideoType,
  };

  const anyPropertyChanged = Object.values(changesMap).some((change) => change);
  const changesCount = getChangesCount(changesMap);

  useSetOriginalAttendeesBeforeEditForMultiCalendar({
    diffId,
    calendarIds: attendees,
  });

  const [resetEditsToAttendeesForMultiCalendar] = useResetEditsToAttendeesForMulticalendar({
    diffId,
  });

  const wrappedOnClose = () => {
    resetEditsToAttendeesForMultiCalendar();
    onClose();
  };
  const warningMessage = getEventCardWarning(orgDomains, attendeePeople, true);
  const footerDisabled = !startTime || !endTime || !!timeError;

  return (
    <CardWrapper
      alsoSendUpdates={false}
      onClose={wrappedOnClose}
      changesCount={changesCount}
      header={<div className="cw-font-semibold">New event</div>}
      footer={
        <ECFooter
          disabled={footerDisabled}
          onDiscard={wrappedOnClose}
          onSubmit={handleSubmit}
          updatesPresent={anyPropertyChanged}
          canModify
          canRemove
          canDelete
          showDropdownOnSave={false}
          isProposal
        />
      }
    >
      {warningMessage && <WarningMessage type={warningMessage} />}
      <ECTitle
        readOnly={readOnly}
        eventName={title}
        onEventNameChange={(e) => setTitle(() => e.target.value)}
      />
      <div className="cw-flex cw-flex-col cw-gap-3">
        <ECAttendeeWrapper
          canInviteOthers={!readOnly}
          canModify={!readOnly}
          attendeesOmitted={fetchedAttendeesOmitted}
          diffId={diffId}
        />
        <Divider spacing="xs" inset />
        <ECFlexibility
          flexDetails={toDetailsInput(flexDetails)}
          onFlexibilityChange={onFlexChange}
          readOnly={readOnly}
          onFlexDetailsChange={(details) => setFlexDetails(toEventCardFlex(details))}
          showChanges={changesMap.flexDetails}
        />
        <Divider spacing="xs" inset />
        <TimeTradeoffs
          readOnly={readOnly}
          onFlexChange={() => {
            onFlexChange(false); // We turn off flex if user swaps to custom time
          }}
          defaultExpandedTradeoff={defaultExpandedTradeoff}
          tradeoffBlocks={fetchedTradeoffBlocks}
          tradeoffsNeedRecalculation={changesMap.time}
          timesHaveChanged={timesHaveChanged}
        />
        <AllDay readOnly={readOnly} showChanges={changesMap.isAllDay} />
        <ECRecurrence
          readOnly={readOnly}
          recurrenceRule={recurrenceRule}
          date={startTime?.toISODate()}
          onSelect={setRecurrenceRule}
          showChanges={changesMap.eventRecurrence}
          originalRecurrence={fetchedRecurrenceRule}
        />
        <Divider spacing="xs" inset />
        <CollapsibleSection numberOfSections={3}>
          <ECVideoConf
            onSelect={setVideoType}
            readonly={readOnly}
            selected={videoType}
            enabledTypes={conferencingTypes}
            showChanges={changesMap.videoType}
          />
          <ECLocation
            location={location}
            onLocationChange={(location) => setLocation(location || "")}
            readonly={readOnly}
            showChanges={changesMap.location}
          />
          <ECAgenda
            readOnly={readOnly}
            agenda={description ?? ""}
            descriptionOmitted={fetchedDescriptionOmitted}
            onChangeAgenda={(agenda, userModified) => {
              setDescription(agenda);
              if (userModified) {
                setAgendaChanges();
              }
            }}
            showChanges={changesMap.agenda}
          />
        </CollapsibleSection>
      </div>
    </CardWrapper>
  );
};
