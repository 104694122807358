import { constructionBlockSvg } from "#webapp/src/assets/img/illustrations";
import { colors } from "@clockwise/web-commons/src/styles/color.styles";
import spacing from "@clockwise/web-commons/src/styles/spacing.styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const header: CSSProperties = {
  background: `${colors.green.standard} url(${constructionBlockSvg}) center / 100px no-repeat`,
  width: "100%",
  height: 120,
  padding: spacing.sm,
};

const content: CSSProperties = {
  textAlign: "center",
  paddingTop: spacing.sm,
  paddingLeft: spacing.sm,
  paddingRight: spacing.sm,
};

const actions: CSSProperties = {
  justifyContent: "center",
  display: "flex",
  flexDirection: "row",
  paddingBottom: spacing.sm,
  paddingLeft: spacing.sm,
  paddingRight: spacing.sm,
  marginTop: spacing.xlg,
};

const upperMargin: CSSProperties = {
  marginTop: spacing.sm,
};

export const styles = {
  header,
  content,
  actions,
  upperMargin,
};
