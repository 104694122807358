import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PrimaryTeamSelectQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PrimaryTeamSelectQuery = { __typename: 'Query', currentUser: { __typename: 'User', id: string, teams: Array<{ __typename: 'Team', id: string, name: string, isPrimary: boolean } | null> } | null };

export type UpdatePrimaryTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
}>;


export type UpdatePrimaryTeamMutation = { __typename: 'Mutation', updatePrimaryTeam: { __typename: 'Team', id: string, name: string, isPrimary: boolean } | null };


export const PrimaryTeamSelectDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PrimaryTeamSelect"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"teams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"isPrimary"}}]}}]}}]}}]} as unknown as DocumentNode<PrimaryTeamSelectQuery, PrimaryTeamSelectQueryVariables>;
export const UpdatePrimaryTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updatePrimaryTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePrimaryTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"teamId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"teamId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"isPrimary"}}]}}]}}]} as unknown as DocumentNode<UpdatePrimaryTeamMutation, UpdatePrimaryTeamMutationVariables>;