import { fg_subtle } from "@clockwise/design-system/tokens";
import classNames from "classnames";
import { CircleNotch, MagnifyingGlass } from "phosphor-react";
import React, { useCallback, useEffect } from "react";
import { useBoolean, useEventListener } from "usehooks-ts";
import { useKeyboardSelect } from "./hooks/useKeyboardSelect";
import { PersonProfileChip } from "./person-chip";
import { PersonProfileOption } from "./person-option";
import { Person } from "./types";

type Size = "sm" | "md";

interface PersonSelectorProps<T extends Person> {
  autoFocus?: boolean;
  dataAutoFocus?: boolean;
  hasChips?: boolean;
  maxDropdownHeight?: string;
  onRemove?: (person: T) => void;
  onSearch: (query: string) => void;
  onSelect: (person: T) => void;
  people: T[];
  placeholder?: string;
  searchLoading?: boolean;
  selectedPeople?: T[];
  size?: Size;
}

export const PersonSelector = <T extends Person>({
  autoFocus = false,
  dataAutoFocus = false,
  hasChips: useChips = false,
  maxDropdownHeight = "400px",
  onRemove,
  onSearch,
  onSelect,
  people,
  placeholder = "Find people",
  searchLoading = false,
  selectedPeople = [],
  size = "md",
}: PersonSelectorProps<T>) => {
  const { value: isFocused, setTrue: setFocus, setFalse: setBlur } = useBoolean(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [query, setQuery] = React.useState("");

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
      setFocus();
    }
  }, [autoFocus, setFocus]);

  const handleBackspaceRemove = useCallback(
    (event: KeyboardEvent) => {
      if (
        (event.key === "Backspace" || event.key === "Delete") &&
        useChips &&
        selectedPeople.length > 0 &&
        query === ""
      ) {
        event.preventDefault();
        const lastPerson = selectedPeople[selectedPeople.length - 1];
        onRemove?.(lastPerson);
      }
    },
    [useChips, selectedPeople, query, onRemove],
  );

  useEventListener("keydown", handleBackspaceRemove, inputRef);

  const { activeIndex, setActiveIndex } = useKeyboardSelect({
    active: isFocused,
    inputRef,
    onBlur: setBlur,
    onSelect: (index) => {
      const person = people[index];

      if (!person) {
        return;
      }

      if (selectedPeople.some((p) => p.email === person.email)) {
        onRemove?.(person);
      } else {
        handleSelect(person);
      }
      onBlurDelay();
    },
    optionCount: people?.length,
  });

  useEffect(() => {
    // Reset index to top on search change
    setActiveIndex(0);
  }, [query, setActiveIndex]);

  const noResults = people.length === 0 && !searchLoading;
  const hasUserInput = query.length > 0;
  const shouldShowDropdown = isFocused && (people.length > 0 || (noResults && hasUserInput));

  const onBlurDelay = () => {
    setTimeout(() => {
      setBlur();
    }, 200);
  };

  const handleSelect = (person: T) => {
    onSelect(person);
    setQuery("");
  };

  const handleQueryChange = (value: string) => {
    setQuery(value);
    onSearch(value);
  };

  return (
    <div className="cw-relative">
      <div
        className={classNames(
          "cw-flex cw-items-center cw-gap-2 cw-w-full cw-bg-default cw-border cw-border-solid cw-border-muted cw-rounded-lg",
          {
            "cw-border-focus": isFocused,
            "cw-px-2 cw-py-1": size === "md",
            "cw-px-1.5 cw-py-0.5": size === "sm",
          },
          "cw-cursor-text",
        )}
        onClick={(e) => {
          // Only focus if we didn't click on a chip
          if (!(e.target as HTMLElement).closest('[cw-id^="cw-person-chip"]')) {
            inputRef.current?.focus();
          }
        }}
      >
        <MagnifyingGlass
          fill="none"
          color={fg_subtle}
          size={size === "md" ? 16 : 14}
          className="cw-shrink-0"
        />
        <div
          className={classNames(
            "cw-flex cw-flex-wrap cw-items-center cw-gap-1 cw-flex-1",
            "cw-overflow-x-auto",
            {
              "cw-min-h-[24px]": size === "md",
              "cw-min-h-[22px]": size === "sm",
            },
          )}
        >
          {useChips &&
            selectedPeople.map((person) => (
              <React.Fragment key={person.email}>
                <PersonProfileChip person={person} onClose={() => onRemove?.(person)} />
              </React.Fragment>
            ))}
          <input
            data-autofocus={dataAutoFocus}
            ref={inputRef}
            type="text"
            value={query}
            onChange={(e) => {
              handleQueryChange(e.target.value);
              setActiveIndex(0);
              setFocus();
            }}
            onFocus={setFocus}
            onBlur={onBlurDelay}
            placeholder={useChips && selectedPeople.length > 0 ? undefined : placeholder}
            className={classNames(
              "cw-flex-1 cw-min-w-[120px] cw-bg-transparent cw-border-0 cw-outline-none cw-font-normal placeholder:cw-text-muted",
              {
                "cw-text-14": size === "md",
                "cw-text-12": size === "sm",
              },
            )}
          />
          {searchLoading && (
            <div
              className={classNames(
                "cw-shrink-0",
                "cw-overflow-hidden",
                size === "md" ? "cw-h-4 cw-w-4" : "cw-h-3.5 cw-w-3.5",
              )}
            >
              <CircleNotch
                size={size === "md" ? 16 : 14}
                weight="bold"
                color={fg_subtle}
                className={"cw-animate-spin"}
              />
            </div>
          )}
        </div>
      </div>
      {shouldShowDropdown && (
        <div
          className="cw-absolute cw-w-full cw-bg-default cw-z-10 cw-mt-1 cw-border cw-border-solid cw-border-muted cw-rounded-lg cw-shadow-selectPopup"
          style={{
            maxHeight: maxDropdownHeight || "none",
            overflowY: maxDropdownHeight ? "auto" : "visible",
          }}
        >
          {noResults ? (
            <div className="cw-flex cw-group cw-justify-between cw-items-center cw-rounded-lg cw-p-1 cw-m-1">
              <div className="cw-flex cw-gap-3 cw-items-center cw-truncate">
                <div className="cw-body-sm cw-font-medium">No results</div>
              </div>
            </div>
          ) : (
            people.map((person, index) => (
              <React.Fragment key={person.email}>
                <PersonProfileOption
                  person={person}
                  isSelected={selectedPeople.some((p) => p.email === person.email)}
                  isActive={index === activeIndex}
                  onSelect={handleSelect}
                  onRemove={onRemove}
                />
              </React.Fragment>
            ))
          )}
        </div>
      )}
    </div>
  );
};
