import { paths } from "@clockwise/client-commons/src/constants/site";
import { TrackingEvents, track } from "@clockwise/web-commons/src/util/analytics.util";
import { inviteLink } from "@clockwise/web-commons/src/util/local-storage";
import React from "react";
import { Navigate, useParams } from "react-router-dom";

export const InviteAttributionAndRedirect = ({ redirect }: { redirect?: string }) => {
  const { inviteToken } = useParams<{ inviteToken?: string }>();

  const hasAlreadySignedUpViaInviteLink = !!inviteLink && !!inviteLink.getAll()?.hasSignedUp;

  if (!!inviteToken && !hasAlreadySignedUpViaInviteLink) {
    inviteLink.set("referrer", inviteToken);
    inviteLink.set("hasSignedUp", false);
    // Non debounced tracking event
    track(
      TrackingEvents.INVITE_ATTRIBUTION.INVITE_LINK_ACTIVATED,
      { referrer: inviteToken },
      undefined,
      true,
    );
  }
  const whereToGoNext = redirect ? redirect : paths.welcome;

  return <Navigate to={whereToGoNext} replace />;
};
