import classNames from "classnames";
import React from "react";
import { Account } from "./Account";
import { ConnectedApps } from "./ConnectedApps";
import { FlexibleHolds } from "./FlexibleHolds";
import { FlexibleMeetings } from "./FlexibleMeetings";
import { Preferences } from "./Preferences";
import { SchedulingLinks } from "./SchedulingLinks";

export const MAX_H_TALL = "cw-max-h-44";
export const MAX_H_SHORT = "cw-max-h-20";

export const FeatureCardGrid = () => {
  return (
    <section>
      <ul
        className={classNames("cw-grid cw-gap-4 xl:cw-grid-cols-3 cw-mb-16", "md:cw-grid-cols-2")}
        aria-label="Key Clockwise features"
      >
        <li>
          <Preferences />
        </li>
        <li>
          <FlexibleMeetings />
        </li>
        <li>
          <FlexibleHolds />
        </li>
        <li>
          <SchedulingLinks />
        </li>
        <li>
          <ConnectedApps />
        </li>
        <li>
          <Account />
        </li>
      </ul>
    </section>
  );
};
