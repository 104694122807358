import {
  TrackingEvents,
  useTrackPageview,
  useTracking,
} from "#clockwise/web-commons/src/util/analytics.util";
import { logger } from "@clockwise/client-commons/src/util/logger";
import {
  Button,
  Checkbox,
  RadioGroup,
  Select,
  SelectOption,
  Switch,
  TextArea,
  TextField,
  Tooltip,
} from "@clockwise/design-system";
import {
  ArrowBackIos,
  CalendarToday,
  Check,
  Close,
  DeleteOutline,
  DragIndicator,
  Notes,
  RemoveRedEye,
  SvgIconComponent,
  Videocam,
} from "@clockwise/design-system/icons";
import {
  AttendeeQuestionTypeEnum,
  AvailabilityRestrictionEnum,
  BookableHoursEnum,
  DayEnum,
  GroupSchedulingStrategyEnum,
  LocationTypeEnum,
  SchedulingPriorityEnum,
} from "@clockwise/schema/v2";
import { animated, config, useSpring, useTransition } from "@react-spring/web";
import classNames from "classnames";
import { Reorder } from "framer-motion";
import { cloneDeep, isEmpty, isEqualWith, range } from "lodash";
import { DateTime, Interval } from "luxon";
import pluralize from "pluralize";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  CONFIRM_UNSAVED_ONUNLOAD_TEXT,
  useConfirmBeforeUnloadEvent,
  useIsTouchScreen,
  useWindowSize,
} from "../../util/react.util";
import { routeToFeature } from "../../util/routes.util";
import { getSchedulingLinkUrl, sanitizeSlug, validateSlug } from "../../util/scheduling.util";
import { CenteredCard } from "../card";
import { CwDialogConfirmRed } from "../cw-dialog";
import { LinkTitleTemplate } from "../link-title-template/link-title-template";
import { MEETING_LOCATION_DETAILS } from "../scheduling-confirmation/event-summary";
import { WarningPopover } from "../scheduling-links/WarningPopover";
import { AVAILABILITY_TEXT } from "../scheduling-links/constants";
import { Person } from "../setup-scheduling-link/setup-link-person-picker/SetupLinkPersonPicker";
import { TrialDialogOpenStates, TrialUpgradeDialog } from "../trial-upgrade-dialog";
import { TwoRowRadioOption } from "../two-row-radio-option";
import { LinkEditor } from "./";
import { BookableHoursRow } from "./BookableHoursRow";
import { MeetingHours } from "./CustomMeetingHours/types";
import { DurationPickerRow } from "./DurationPicker";
import { MaxBookingsRow } from "./MaxBookingsRow";
import TimeRangePicker, { PeriodType, TimeRange } from "./TimeRangePicker";
import { AttendeePicker } from "./attendee-picker/AttendeePickerGateway";
import { MeetingType } from "./meeting-type-picker/MeetingTypePicker";
import { AttendeeQuestion, SchedulingLinkMeetingDetails, getDefaulAttendeeQuestion } from "./utils";

/**
 * Date time in YYYY-MM-DDTHH:mm:ss.sssZ format.
 */
export type DateTimeString = string;

export interface SetupSchedulingLinkProps {
  initialLinkSettings: SchedulingLinkMeetingDetails;
  canUseZoom: boolean;
  canUseTeams: boolean;
  canMarkMeetingAsActive: boolean;
  shouldUserSeeJoinExistingPlan: boolean;
  canUserMakeGroupLinks: boolean;
  onBack: () => void;
  onSave: (linkSettings: SchedulingLinkMeetingDetails) => void;
  onDelete: (meetingSettingsId: string) => void;
  createUpdateError?: string | null;
  saving: boolean;
  linkName?: string;
  deleting: boolean;
  clearError: () => void;
  onPreview: () => void;
  orgId: string | undefined;
  isGroupLink: boolean;
}

interface NewLinkTopSectionProps {
  onCreateNew: () => void;
  enableCreateBtn: boolean;
  creating: boolean;
  onBack: () => void;
}

const QUESTION_TYPES = Object.values(AttendeeQuestionTypeEnum);

const DEFAULT_BOOKING_TIME_BUFFER = 4; // In hours.
const MAXIMIZE_MESSAGE =
  "We automatically maximize availability when there are two or more attendees on a link, to ensure availability.";

const deleteHeader = "Are you sure you want to delete this Scheduling Link?";
const deleteBody = "Doing so will make this link inoperable for those you have shared it with.";
const MAX_DESC_LENGTH = 5000; // 5,000 characters

const ATTENDEE_QUESTION_TYPE_LABEL: Record<AttendeeQuestionTypeEnum, string> = {
  LongText: "Long text",
  ShortText: "Short text",
  PhoneNumber: "Phone number",
};

const DEFAULT_CUSTOM_HOURS: MeetingHours = {
  schedule: [
    {
      day: DayEnum.Monday,
      timeRanges: [{ startTime: "09:00", endTime: "17:00" }],
    },
    {
      day: DayEnum.Tuesday,
      timeRanges: [{ startTime: "09:00", endTime: "17:00" }],
    },
    {
      day: DayEnum.Wednesday,
      timeRanges: [{ startTime: "09:00", endTime: "17:00" }],
    },
    {
      day: DayEnum.Thursday,
      timeRanges: [{ startTime: "09:00", endTime: "17:00" }],
    },
    {
      day: DayEnum.Friday,
      timeRanges: [{ startTime: "09:00", endTime: "17:00" }],
    },
  ],
  dateOverrides: [],
};

const transitionSpring = {
  from: { opacity: 0, translateY: -8 },
  enter: { opacity: 1, translateY: 0 },
  delay: 50,
  trail: 180,
  config: config.stiff,
};

const NewLinkTopSection = ({
  onCreateNew,
  enableCreateBtn,
  creating,
  onBack,
}: NewLinkTopSectionProps) => {
  return (
    <>
      <div className="cw-flex cw-gap-2 cw-items-center">
        <Button variant="text" onClick={onBack} startIcon={ArrowBackIos} cw-id="back-button">
          {window.history.length > 1 ? "Back" : "My Links"}
        </Button>
      </div>
      <span className="cw-heading-base cw-text-muted cw-hidden sm:cw-block" cw-id="new-link-header">
        New Scheduling Link
      </span>
      <Button
        onClick={onCreateNew}
        sentiment="positive"
        disabled={!enableCreateBtn || creating}
        startIcon={Check}
        cw-id="create-link-button"
      >
        Create Link
      </Button>
    </>
  );
};

const SlugRenameWarning = ({
  linkName,
  oldSlug,
  newSlug,
}: {
  linkName: string;
  oldSlug: string;
  newSlug: string;
}) => (
  <div>
    <span className="cw-font-bold">Saving a new link URL inactivates the old URL.</span> If you've
    shared this link, share the new URL with your link recipients.
    <br />
    <br />
    {linkName && oldSlug && (
      <>
        <div className="cw-text-destructive cw-bold cw-flex cw-items-center cw-gap-1">
          <Close className="cw-text-20" />
          <span className="cw-line-through">{getSchedulingLinkUrl(linkName, oldSlug, true)}</span>
        </div>
        <div className="cw-bold cw-flex cw-items-center cw-gap-1">
          <Check className="cw-text-20 cw-text-positive" />
          <span>{getSchedulingLinkUrl(linkName, newSlug ?? "", true)}</span>
        </div>
      </>
    )}
  </div>
);

function LocationMenuItem({ locationType }: { locationType: LocationTypeEnum | "ConnectZoom" }) {
  const locationDetails = MEETING_LOCATION_DETAILS[locationType];
  const icon = locationDetails?.locationIcon;
  return (
    <div className="cw-flex cw-items-center cw-gap-2">
      {icon}
      <span>{locationDetails?.locationName}</span>
    </div>
  );
}

const TimingRow = ({
  children,
  icon: IconClass,
  align = "center",
}: {
  children: React.ReactNode;
  icon: SvgIconComponent;
  align?: "start" | "center";
}) => (
  <div className="cw-body-base cw-flex cw-whitespace-nowrap cw-gap-3 cw-items-center cw-justify-start">
    <IconClass
      className={classNames("cw-w-5 cw-h-5 cw-text-muted cw-mr-1", {
        "cw-self-start": align === "start",
      })}
    />
    {children}
  </div>
);

const getMeetingTypeLinkSingleOrGroup = (linkSettings: SchedulingLinkMeetingDetails) => {
  return linkSettings.linkMembers.length === 1 ? MeetingType.SingleTeammate : MeetingType.Group;
};

export const SetupSchedulingLink = ({
  initialLinkSettings,
  canUseZoom,
  canUseTeams,
  canMarkMeetingAsActive,
  shouldUserSeeJoinExistingPlan,
  canUserMakeGroupLinks,
  onBack,
  onSave,
  onDelete,
  saving,
  linkName,
  deleting,
  createUpdateError,
  clearError,
  onPreview,
  orgId,
  isGroupLink,
}: SetupSchedulingLinkProps) => {
  const isNew = !initialLinkSettings.id;

  const trackEvent = useTracking();

  ////////////////// State //////////////////

  const { width: windowWidth } = useWindowSize();

  const [newLinkSettings, setNewLinkSettings] = useState(initialLinkSettings);

  // Set have changes if the new link settings are different from the current link settings.
  const hasChanges = useMemo(
    () =>
      !isNew &&
      // Do a deep equality check on the link settings. Ignore "renderId" on attendeeQuestions since
      // it's a client-only field that cannot be edited by the user
      !isEqualWith(initialLinkSettings, newLinkSettings, (_value, _other, key) =>
        key === "renderId" ? true : undefined,
      ),
    [initialLinkSettings, newLinkSettings, isNew],
  );

  // Register listener to warn user if they try to leave with unsaved changes
  useConfirmBeforeUnloadEvent(hasChanges);

  const [enableBookingTimeBuffer, setEnableBookingTimeBuffer] = useState(true);
  const [monetizationModalOpen, setMonetizationModalOpen] = useState<TrialDialogOpenStates>(null);
  // This stores the last value of the booking time buffer, for the case where the user toggles off the feature and we need
  // to set newLinkSettings.bufferHours to zero while maintaining a non-zero value in the picker. Will be able to
  // remove this when we add a boolean to the LinkSettings to set the bookingTimeBuffer feature on and off rather than just
  // setting the value to zero.
  const [prevTimeBuffer, setPrevTimeBuffer] = useState(
    newLinkSettings.bufferHours || DEFAULT_BOOKING_TIME_BUFFER, // Default to 4 hours if the feature is disabled via bufferHours set to 0.
  );

  const [expandDescription, setExpandDescription] = useState<boolean>(
    !!newLinkSettings.description,
  );

  const [showAttendeeQuestionErrors, setShowAttendeeQuestionErrors] = React.useState(false);

  const [deleteLinkDialogOpen, setDeleteLinkDialogOpen] = useState(false);
  const [slugError, setSlugError] = useState("");
  const [saveHovered, setWarningTooltipOpen] = useState(false);

  const [customMeetingHoursError, setCustomMeetingHoursError] = useState("");

  const initialMeetingType =
    initialLinkSettings.groupSchedulingStrategy ===
    GroupSchedulingStrategyEnum.RoundRobinMaxAvailability
      ? MeetingType.RoundRobin
      : getMeetingTypeLinkSingleOrGroup(initialLinkSettings);

  const [meetingType, setMeetingType] = useState(initialMeetingType);

  const availabilityRangeType =
    newLinkSettings.availabilityRange.windowSize == null
      ? PeriodType.Range
      : PeriodType.RollingWindow;

  ////////////////// Effects //////////////////

  useTrackPageview(TrackingEvents.LINKS.EDIT_PAGE.VIEW, {
    meetingSettingsId: newLinkSettings.id,
  });

  // Keep enableBookingTimeBuffer in sync with newLinkSettings.bufferHours.
  useEffect(() => {
    // Will be able to remove once we have this boolean saved to the LinkSettings.
    setEnableBookingTimeBuffer(newLinkSettings.bufferHours === 0 ? false : true);
  }, [newLinkSettings.bufferHours]);

  useEffect(() => {
    if (meetingType === MeetingType.SingleTeammate) {
      setLinkSetting(
        "linkMembers",
        initialLinkSettings.linkMembers.filter((lm) => lm.owner),
      );
    }

    if (meetingType === MeetingType.Group && !canUserMakeGroupLinks) {
      setMonetizationModalOpen("Group");
    }

    if (meetingType === MeetingType.RoundRobin) {
      setLinkSetting("singleUse", false);
    }

    if (meetingType === MeetingType.RoundRobin && !organizer?.roundRobinEnabled) {
      setMonetizationModalOpen("RoundRobin");
    }

    setLinkSetting(
      "groupSchedulingStrategy",
      meetingType === MeetingType.RoundRobin
        ? GroupSchedulingStrategyEnum.RoundRobinMaxAvailability
        : null,
    );

    setLinkSetting(
      "linkMembers",
      newLinkSettings.linkMembers.map((lm) => ({
        ...lm,
        schedulingPriority:
          meetingType !== MeetingType.RoundRobin
            ? null
            : lm.roundRobinEnabled
            ? lm.schedulingPriority || SchedulingPriorityEnum.Medium
            : SchedulingPriorityEnum.DoNotSchedule,
      })),
    );
  }, [meetingType]);

  // This setter function ensures that setNewLinkSettings is used to update state, rather than
  // directly settings fields on newLinkSettings. Ensures that the proper keys and their respective
  // values types are used.
  const setLinkSetting = useCallback(
    <KEY extends keyof SchedulingLinkMeetingDetails>(
      key: KEY,
      value: SchedulingLinkMeetingDetails[KEY],
    ) => {
      setNewLinkSettings((prevNewLinkSettings) => ({
        ...prevNewLinkSettings,
        [key]: value,
      }));
      clearError();
    },
    [clearError],
  );

  ////////////////// Animations //////////////////

  const leftTransition = useTransition(
    ["name and description", "timing", "calendar event", "attendees"],
    transitionSpring,
  );
  const rightTransition = useTransition(
    ["focus-mode", "limits", "best", "conflicts", "attendee-questions", "delete"],
    transitionSpring,
  );
  const leftToRightTransition = useTransition(["left", "divider", "right"], transitionSpring);

  const [saveOrViewLink, setSaveOrViewLink] = useSpring(() => ({ opacity: 0 }));
  useEffect(() => {
    setSaveOrViewLink({ opacity: 1, from: { opacity: 0 }, config: config.slow });
  }, [hasChanges, setSaveOrViewLink]);

  const bestIconStyle = useSpring({
    opacity: newLinkSettings.highlightBestTimes ? 1 : 0,
    config: config.stiff,
  });

  const [{ opacity: fadeIn }, setFifteenMinFade] = useSpring(() => ({ opacity: 0 }));
  useEffect(() => {
    setFifteenMinFade({ opacity: 1, from: { opacity: 0 }, config: config.slow });
  }, [newLinkSettings.allowQuarterHourBooking, setFifteenMinFade]);

  // Description
  const descriptionInfo = useSpring({ opacity: expandDescription ? 1 : 0, delay: 50 });

  const [durationError, setDurationError] = useState("");

  const handleDurationsChange = (durations: number[], defaultDuration: number | null) => {
    setLinkSetting("durations", durations);
    setLinkSetting("defaultDuration", defaultDuration);
    if (durations.length === 0) {
      setDurationError("Select at least 1 duration");
    } else if (!defaultDuration) {
      setDurationError("Select a default duration");
    } else {
      setDurationError("");
    }
  };

  ////////////////// Misc //////////////////

  const enableSave =
    !!newLinkSettings.name &&
    !newLinkSettings.attendeeQuestions.some((q) => !q.question) &&
    newLinkSettings.linkMembers.some((lm) => lm.required) &&
    !customMeetingHoursError &&
    !durationError &&
    !(meetingType === MeetingType.Group && !canUserMakeGroupLinks);
  const bookingTimeBufferHoursToDisplay = newLinkSettings.bufferHours || prevTimeBuffer;

  // Responsive styling
  const removeDivider = windowWidth < 980;
  const showLinkActiveText = windowWidth > 1000;
  const alwaysShowWarningTooltip = windowWidth > 1200;

  const onChangeSlug = (e: ChangeEvent<HTMLInputElement>) => {
    const newSlug = sanitizeSlug(e.target.value);

    const { slugError, blockChange } = validateSlug(newSlug);

    setSlugError(slugError);
    if (blockChange) {
      return; // Short circuit and don't allow the slug to change.
    }

    setLinkSetting("slug", newSlug);
  };

  const onChangeIsActive = (isActive: boolean) => {
    if (isActive && !canMarkMeetingAsActive && newLinkSettings.linkMembers.length > 1) {
      setMonetizationModalOpen("Group");
      return;
    }
    setLinkSetting("active", isActive);
    trackEvent(TrackingEvents.LINKS.EDIT_PAGE.CHANGE_ACTIVE, {
      meetingSettingsId: newLinkSettings.id,
      isActive,
      singleUse: newLinkSettings.singleUse,
    });
  };

  const handleOnBack = () => {
    if (!hasChanges || window.confirm(CONFIRM_UNSAVED_ONUNLOAD_TEXT)) {
      onBack();
    }
  };

  const handleTimeRangeChange = ({ endDate, startDate, windowSizeDays }: TimeRange) => {
    const newAvailabilityRange = {
      windowSize: windowSizeDays,
      startDate,
      endDate,
    };

    setLinkSetting("availabilityRange", newAvailabilityRange);
    validateAvailabilityRangeAndBookableHours(
      newLinkSettings.bookableHours,
      newLinkSettings.customMeetingHours,
      newAvailabilityRange,
      true,
    );
  };

  const handleAttendeeQuestionChange = (updatedQuestion: AttendeeQuestion, i: number) => {
    const newAttendeeQuestions = [...newLinkSettings.attendeeQuestions];
    newAttendeeQuestions[i] = updatedQuestion;
    setLinkSetting("attendeeQuestions", newAttendeeQuestions);
  };

  const handleAttendeeQuestionDeletion = (i: number) => {
    const newAttendeeQuestions = cloneDeep(newLinkSettings.attendeeQuestions);
    newAttendeeQuestions.splice(i, 1);

    setLinkSetting("attendeeQuestions", newAttendeeQuestions);
  };

  const validateAvailabilityRangeAndBookableHours = (
    bookableHours: BookableHoursEnum,
    customMeetingHours: MeetingHours | null,
    availabilityRange: {
      windowSize: number | null;
      startDate: string | null;
      endDate: string | null;
    },
    isCustomHoursValid: boolean,
  ) => {
    if (bookableHours === BookableHoursEnum.CustomHours) {
      // Links using custom hours must allow booking on no-meeting days
      setLinkSetting("allowBookingOnNoMeetingDay", true);

      const updatedCustomMeetingHours = customMeetingHours ?? DEFAULT_CUSTOM_HOURS;
      setLinkSetting("customMeetingHours", updatedCustomMeetingHours);
      const { schedule, dateOverrides } = updatedCustomMeetingHours;
      const isEmpty = [...dateOverrides, ...schedule].every(
        ({ timeRanges }) => timeRanges.length === 0,
      );

      const { startDate, endDate, windowSize } = availabilityRange;
      const luxonStartDate = startDate ? DateTime.fromISO(startDate) : null;
      const luxonEndDate = endDate ? DateTime.fromISO(endDate) : null;
      const hasDateOverrideOutOfBounds = dateOverrides.some((dateOverride) => {
        const date = DateTime.fromISO(dateOverride.date);
        // check if each date is either:
        // 1. within the windowSize
        // 2. on/after the startDate AND on/before the endDate
        if (luxonStartDate && luxonEndDate) {
          return date < luxonStartDate || date > luxonEndDate;
        } else if (windowSize) {
          const interval = Interval.fromDateTimes(
            DateTime.now(),
            DateTime.now().plus({ days: windowSize }),
          );
          return !interval.contains(date);
        } else {
          logger.error("availabilityRange doesn't have windowSize nor start/end date");
          return false;
        }
      });

      // Update error
      if (isEmpty) {
        setCustomMeetingHoursError("Custom hours cannot be blank");
      } else if (!isCustomHoursValid) {
        setCustomMeetingHoursError("Custom hours have invalid time ranges");
      } else if (hasDateOverrideOutOfBounds) {
        if (luxonStartDate && luxonEndDate) {
          setCustomMeetingHoursError(
            `Some of these days are outside the range of ${luxonStartDate.toFormat(
              "ccc, d LLL",
            )} - ${luxonEndDate.toFormat("ccc, d LLL")}`,
          );
        } else if (windowSize) {
          setCustomMeetingHoursError(
            `Some of these days are outside the range of within the next ${windowSize} days`,
          );
        }
      } else {
        setCustomMeetingHoursError("");
      }
    } else if (!isCustomHoursValid) {
      // Reset custom meeting hours to initial state and clear error
      setLinkSetting("customMeetingHours", initialLinkSettings.customMeetingHours);
      setCustomMeetingHoursError("");
    }
  };

  const handleBookableHoursChange = (
    bookableHours: BookableHoursEnum,
    customMeetingHours: MeetingHours | null,
    valid: boolean,
  ) => {
    // Update bookableHours
    setLinkSetting("bookableHours", bookableHours);

    // Update customMeetingHours state
    validateAvailabilityRangeAndBookableHours(
      bookableHours,
      customMeetingHours,
      newLinkSettings.availabilityRange,
      valid,
    );
  };

  const hasSlugChanged = newLinkSettings.slug && newLinkSettings.slug !== initialLinkSettings.slug;
  const warningTooltip = hasSlugChanged ? (
    <SlugRenameWarning
      linkName={linkName ?? ""}
      oldSlug={initialLinkSettings.slug}
      newSlug={newLinkSettings.slug}
    />
  ) : (
    ""
  );

  const handleSave = () => {
    const attendeeQuestions = newLinkSettings.attendeeQuestions.filter(
      ({ question }) => question.length > 0,
    );
    onSave({ ...newLinkSettings, attendeeQuestions });
  };

  const isUserOnMobile = useIsTouchScreen();
  const organizer = newLinkSettings.linkMembers.find((lm) => lm.owner);
  const organizerName = (
    (organizer?.member?.person?.givenName || "") +
    " " +
    (organizer?.member?.person?.familyName || "")
  ).trim();

  const onTitleTemplateChange = useCallback(
    (titleTemplate: string) => {
      setLinkSetting("titleTemplate", titleTemplate);
    },
    [setLinkSetting],
  );

  const MultiMemberTooltip = ({
    children,
    show,
    windowWidth,
  }: {
    children: React.ReactNode;
    show: boolean;
    windowWidth: number;
  }) => {
    if (!show) {
      return <>{children}</>;
    }

    return (
      <Tooltip
        key="maximize-ft-tooltip"
        title={MAXIMIZE_MESSAGE}
        placement={windowWidth > 910 ? "left-start" : "top"}
      >
        {children}
      </Tooltip>
    );
  };

  return (
    <div>
      <TrialUpgradeDialog
        openState={monetizationModalOpen}
        onClose={() => {
          setMonetizationModalOpen(null);
          setMeetingType(getMeetingTypeLinkSingleOrGroup(newLinkSettings));
        }}
        shouldshowJoinExistingPlan={shouldUserSeeJoinExistingPlan}
      />
      <div
        className={classNames(
          "cw-flex cw-flex-row cw-gap-2 cw-justify-between cw-items-center",
          "cw-border-0 cw-border-b-2 cw-border-solid cw-border-muted cw-px-2 cw-py-4",
          "cw-sticky cw-top-0 cw-bg-default cw-z-20",
          {
            "cw-pb-6": createUpdateError || slugError,
          },
        )}
      >
        {isNew ? (
          <>
            <NewLinkTopSection
              onCreateNew={handleSave}
              enableCreateBtn={enableSave && !saving}
              creating={saving}
              onBack={handleOnBack}
            />
            <div className="cw-flex-shrink-0 cw-caption cw-py-1 cw-text-destructive cw-absolute cw-right-3 cw-mt-[59px]">
              {createUpdateError}
            </div>
          </>
        ) : (
          <>
            <animated.div
              style={{
                ...saveOrViewLink,
              }}
            >
              <div className="cw-flex cw-gap-2">
                <Button
                  variant="text"
                  onClick={handleOnBack}
                  startIcon={ArrowBackIos}
                  cw-id="back-button"
                >
                  {hasChanges ? "Cancel" : window.history.length > 1 ? "Back" : "My Links"}
                </Button>
              </div>
            </animated.div>
            <div className="cw-flex cw-gap-4 cw-justify-end cw-items-center cw-ml-4">
              <div className="xxs:cw-flex cw-hidden cw-items-center">
                <Switch
                  checked={newLinkSettings.active}
                  onChange={onChangeIsActive}
                  // Use aria-label to in case showLinkActiveText is false
                  aria-label="Link is Active"
                  label={showLinkActiveText ? "Link is Active" : undefined}
                  labelPlacement="start"
                  cw-id="link-active-checkbox"
                />
              </div>
              <LinkEditor
                linkName={linkName}
                showLinkActiveText={showLinkActiveText}
                slug={newLinkSettings.slug}
                slugError={slugError}
                createUpdateError={createUpdateError}
                onChangeSlug={onChangeSlug}
                setSlugError={setSlugError}
              />
              {hasChanges ? (
                <WarningPopover
                  title={warningTooltip}
                  open={(alwaysShowWarningTooltip || saveHovered) && enableSave && !saving}
                >
                  <Button
                    sentiment="positive"
                    startIcon={Check}
                    onClick={handleSave}
                    disabled={!enableSave || saving}
                    onMouseEnter={() => setWarningTooltipOpen(true)}
                    onMouseLeave={() => setWarningTooltipOpen(false)}
                    cw-id="save-link-button"
                  >
                    {newLinkSettings.active ? "Save and view" : "Save and view all"}
                  </Button>
                </WarningPopover>
              ) : (
                <Button
                  variant="text"
                  sentiment="positive"
                  startIcon={RemoveRedEye}
                  onClick={onPreview}
                  disabled={!newLinkSettings.active}
                  cw-id="view-live-page-button"
                >
                  View live page
                </Button>
              )}
            </div>
          </>
        )}
      </div>
      <div
        className={classNames([
          "cw-flex cw-mt-4 cw-justify-center cw-max-w-[1580px]",
          { "cw-flex-wrap": removeDivider, "xxs:cw-ml-5": removeDivider },
        ])}
      >
        {leftToRightTransition(
          (style, key, _, i) =>
            [
              <animated.div
                className="cw-flex cw-flex-1 cw-flex-col cw-items-start cw-gap-5 cw-my-4 cw-mx-4 xxs:cw-ml-12 xxs:cw-mr-3 mdlrg:cw-max-w-[550px]"
                style={style}
                key={key}
              >
                {leftTransition(
                  (style, key, _, i) =>
                    [
                      <animated.div style={style} key={key} className="cw-mb-0.5 cw-w-full">
                        <h2
                          className="cw-heading-xl cw-mb-3"
                          style={{ marginBottom: 24 }}
                          id="define-event-label"
                        >
                          Link info
                        </h2>
                        <TextArea
                          fieldSize="xlarge"
                          placeholder="Link name"
                          value={newLinkSettings.name}
                          maxRows={4}
                          fullWidth
                          autoFocus={isNew}
                          autoCorrect="off"
                          spellCheck={false}
                          aria-label="edit link name text field"
                          onBlur={() => {
                            setLinkSetting("name", newLinkSettings.name.trim());
                          }}
                          onChange={({ currentTarget: { value: newName } }) => {
                            setLinkSetting("name", newName.slice(0, 50)); // Max 50 char name;
                          }}
                          cw-id="link-name"
                        />
                      </animated.div>,
                      <animated.div
                        style={style}
                        key={key}
                        className="cw-flex cw-flex-col cw-gap-5"
                      >
                        <DurationPickerRow
                          durations={newLinkSettings.durations}
                          defaultDuration={newLinkSettings.defaultDuration}
                          onChange={handleDurationsChange}
                          errorMessage={durationError}
                        />
                        <TimingRow icon={CalendarToday}>
                          <TimeRangePicker
                            endDate={newLinkSettings.availabilityRange.endDate}
                            onChange={handleTimeRangeChange}
                            periodType={availabilityRangeType}
                            startDate={newLinkSettings.availabilityRange.startDate}
                            windowSizeDays={newLinkSettings.availabilityRange.windowSize}
                          />
                        </TimingRow>
                        <BookableHoursRow
                          customMeetingHoursError={customMeetingHoursError}
                          bookableHours={newLinkSettings.bookableHours}
                          customMeetingHours={newLinkSettings.customMeetingHours}
                          onChange={handleBookableHoursChange}
                          groupLink={newLinkSettings.linkMembers.length > 1}
                        />
                        <TimingRow icon={Videocam}>
                          <span>Using</span>
                          <Select
                            cw-id="location-type-dropdown"
                            value={newLinkSettings.location}
                            onChange={(locationType: LocationTypeEnum | "ConnectZoom") => {
                              if (locationType === "ConnectZoom") {
                                routeToFeature({
                                  featureName: "appSettings",
                                  routerState: { kind: "window", target: "_blank" }, // use _blank target to avoid loss of Link setup state
                                  options: {
                                    paramsObj: { highlight: "true" },
                                    hash: "#zoom",
                                    relative: true,
                                  },
                                });
                              } else {
                                setLinkSetting("location", locationType);
                              }
                            }}
                          >
                            {canUseZoom ? (
                              <SelectOption value={LocationTypeEnum.Zoom}>
                                <LocationMenuItem locationType={LocationTypeEnum.Zoom} />
                              </SelectOption>
                            ) : (
                              <SelectOption value="ConnectZoom">
                                <LocationMenuItem locationType="ConnectZoom" />
                              </SelectOption>
                            )}
                            {canUseTeams && (
                              <SelectOption value={LocationTypeEnum.Teams}>
                                <LocationMenuItem locationType={LocationTypeEnum.Teams} />
                              </SelectOption>
                            )}
                            <SelectOption value={LocationTypeEnum.Meet}>
                              <LocationMenuItem locationType={LocationTypeEnum.Meet} />
                            </SelectOption>
                            <SelectOption value={LocationTypeEnum.InDescription}>
                              <LocationMenuItem locationType={LocationTypeEnum.InDescription} />
                            </SelectOption>
                          </Select>
                        </TimingRow>
                        <TimingRow icon={Notes} align="start">
                          {!expandDescription ? (
                            <div className="-cw-ml-3 -cw-mt-[7px]">
                              <Button
                                variant="text"
                                onClick={() => setExpandDescription(true)}
                                aria-label="add description"
                              >
                                Add description
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <TextArea
                                autoFocus={!newLinkSettings.description}
                                placeholder="Add description"
                                minRows={2}
                                maxRows={20}
                                fullWidth
                                value={newLinkSettings.description || ""}
                                onBlur={() => {
                                  setLinkSetting(
                                    "description",
                                    newLinkSettings.description?.trim() ?? null,
                                  );
                                }}
                                onChange={(e) => {
                                  if (e.currentTarget.value.length > MAX_DESC_LENGTH) {
                                    setLinkSetting(
                                      "description",
                                      e.currentTarget.value.slice(0, MAX_DESC_LENGTH),
                                    );
                                    return;
                                  }
                                  setLinkSetting("description", e.currentTarget.value);
                                }}
                                cw-id="link-description"
                                aria-label="link description"
                              />
                              <animated.div
                                className="cw-body-base cw-text-muted cw-whitespace-normal cw-mt-1"
                                style={descriptionInfo}
                              >
                                This description{" "}
                                {!isNew &&
                                initialLinkSettings.description &&
                                initialLinkSettings.active
                                  ? "is"
                                  : "will be"}{" "}
                                visible to those you share this link with and will be included on
                                any bookings scheduled
                              </animated.div>
                            </div>
                          )}
                        </TimingRow>
                      </animated.div>,
                      <animated.div key={key} className="cw-mt-10 cw-mb-4">
                        <h2 className="cw-heading-xl cw-mb-4" id="calendar-event-settings">
                          Booked Event Title
                        </h2>
                        <LinkTitleTemplate
                          includeSparkleEmoji={!newLinkSettings.textOnlyTitle}
                          onChange={onTitleTemplateChange}
                          onChangeIncludeEmoji={(include: boolean) => {
                            setLinkSetting("textOnlyTitle", !include);
                          }}
                          currentTemplate={newLinkSettings.titleTemplate}
                          linkName={newLinkSettings.name}
                          organizerName={organizerName}
                          organizerEmail={
                            newLinkSettings.linkMembers.find((p) => p.owner)?.member?.person
                              ?.email ?? ""
                          }
                          attendees={newLinkSettings.linkMembers
                            .map(
                              (m) =>
                                `${m.member.person.givenName ?? ""} ${
                                  m.member.person.familyName ?? ""
                                }`,
                            )
                            .join(", ")}
                          groupOrSingleUse={
                            newLinkSettings.singleUse || newLinkSettings.linkMembers.length > 1
                          }
                        />
                      </animated.div>,
                      <animated.div key={key} className="cw-w-full">
                        <AttendeePicker
                          canUserMakeGroupLinks={canUserMakeGroupLinks}
                          // Hack: if meetingType is RoundRobin and user is paywalled, dont change ui to gated ui behind modal bc it looks weird
                          meetingType={
                            monetizationModalOpen !== "RoundRobin"
                              ? meetingType
                              : getMeetingTypeLinkSingleOrGroup(newLinkSettings)
                          }
                          handleChangeMeetingType={(meetingType) => {
                            setMeetingType(meetingType);
                            trackEvent(TrackingEvents.LINKS.ROUND_ROBIN.MEETING_TYPE_SELECTED, {
                              value: meetingType,
                            });
                          }}
                          openPickerOnLoad={isGroupLink}
                          people={newLinkSettings.linkMembers.map(
                            ({
                              owner,
                              required,
                              member,
                              schedulingPriority,
                              roundRobinEnabled,
                            }) => {
                              let priority = null;
                              if (meetingType !== MeetingType.RoundRobin) {
                                priority = null;
                              } else if (!roundRobinEnabled) {
                                priority = SchedulingPriorityEnum.DoNotSchedule;
                              } else {
                                priority = schedulingPriority ?? SchedulingPriorityEnum.Medium;
                              }
                              return {
                                memberId: member.id,
                                email: member.person.email,
                                name: {
                                  givenName: member.person.givenName,
                                  familyName: member.person.familyName,
                                },
                                externalImageUrl: member.person.externalImageUrl,
                                isRequired: required,
                                organizer: owner,
                                schedulingPriority: priority,
                                roundRobinEnabled: roundRobinEnabled,
                              };
                            },
                          )}
                          onOpenMonetizationModal={() => {
                            setMonetizationModalOpen("Group");
                          }}
                          onChange={(people: Person[]) => {
                            setLinkSetting(
                              "linkMembers",
                              people.map(
                                ({
                                  memberId,
                                  email,
                                  isRequired,
                                  organizer,
                                  externalImageUrl,
                                  name: { givenName, familyName },
                                  schedulingPriority,
                                  roundRobinEnabled,
                                }) => ({
                                  required: isRequired || people.length === 1, // If just organizer, mark required.
                                  owner: organizer,
                                  member: {
                                    id: memberId,
                                    person: {
                                      email,
                                      givenName,
                                      familyName,
                                      externalImageUrl,
                                    },
                                  },
                                  schedulingPriority,
                                  roundRobinEnabled,
                                }),
                              ),
                            );
                            if (people.length >= 2) {
                              setLinkSetting(
                                "availabilityRestriction",
                                AvailabilityRestrictionEnum.MaximizeAvailability,
                              );
                              setLinkSetting("allowBookingOnNoMeetingDay", true);
                              setLinkSetting("bookableHours", BookableHoursEnum.MeetingHours);
                            }
                            if (people.length < 2) {
                              setMeetingType(MeetingType.SingleTeammate);
                            } else if (meetingType === MeetingType.SingleTeammate) {
                              setMeetingType(MeetingType.Group);
                            }
                          }}
                          orgId={orgId}
                        />
                      </animated.div>,
                    ]?.[i],
                )}
              </animated.div>,
              !removeDivider && (
                <animated.div
                  className="cw-border-solid cw-border-0 cw-border-l-2 cw-border-muted cw-flex-initial cw-mx-11 -cw-mt-4 cw-b-0"
                  style={style}
                  key={key}
                />
              ),
              <animated.div
                className="cw-flex cw-flex-1 cw-flex-col cw-items-start cw-gap-12 cw-mx-4 xxs:cw-mx-12 cw-mt-4 cw-mb-10 cw-min-w-[330px] cw-max-w-[800px]"
                style={style}
                key={key}
              >
                {rightTransition((style, key) => {
                  return {
                    // rightTransition iterates over the keys passed into its useTransition hook. this map
                    // maps each key to its corresponding content.
                    "focus-mode": (
                      <animated.div style={style} key={key}>
                        <h2 className="cw-heading-xl cw-mb-3" id="protect-focus-label">
                          Protect your focus
                        </h2>
                        <MultiMemberTooltip
                          show={newLinkSettings.linkMembers.length > 1}
                          windowWidth={windowWidth}
                        >
                          <div>
                            <RadioGroup
                              fullWidth
                              value={newLinkSettings.availabilityRestriction}
                              onChange={(mode) => {
                                setLinkSetting("availabilityRestriction", mode);
                              }}
                              aria-label="Focus Mode Radios"
                            >
                              <div>
                                <TwoRowRadioOption
                                  value={AvailabilityRestrictionEnum.MaximizeAvailability}
                                  header={
                                    AVAILABILITY_TEXT[
                                      AvailabilityRestrictionEnum.MaximizeAvailability
                                    ]
                                  }
                                  subHeader="Make all your Focus Time bookable"
                                  cw-id="maximize-availability"
                                />
                              </div>
                              <div>
                                <TwoRowRadioOption
                                  value={AvailabilityRestrictionEnum.BalanceAvailabilityAndFocus}
                                  header={
                                    AVAILABILITY_TEXT[
                                      AvailabilityRestrictionEnum.BalanceAvailabilityAndFocus
                                    ]
                                  }
                                  subHeader="Emphasize slots at the start and end of Focus Time"
                                  cw-id="balance-availability-and-focus"
                                  disabled={newLinkSettings.linkMembers.length > 1}
                                />
                              </div>
                              <div>
                                <TwoRowRadioOption
                                  value={AvailabilityRestrictionEnum.MaximizeFocusTime}
                                  header={
                                    AVAILABILITY_TEXT[AvailabilityRestrictionEnum.MaximizeFocusTime]
                                  }
                                  subHeader="Focus Time will not be bookable"
                                  cw-id="maximize-focus-time"
                                  disabled={newLinkSettings.linkMembers.length > 1}
                                />
                              </div>
                            </RadioGroup>
                          </div>
                        </MultiMemberTooltip>
                      </animated.div>
                    ),
                    limits: (
                      <animated.div
                        style={{ ...style, marginBottom: 6 }}
                        key={key}
                        id="meeting-limits"
                      >
                        <h2 className="cw-heading-xl cw-mb-3">Booking limits</h2>
                        <div>
                          <Checkbox
                            aria-label="Make this a single-use link"
                            checked={newLinkSettings.singleUse}
                            disabled={meetingType === MeetingType.RoundRobin}
                            onChange={(checked) => setLinkSetting("singleUse", checked)}
                            label={
                              <Tooltip
                                title="The link will become inactive after the first booking."
                                placement="right"
                              >
                                <span>Make this a single-use link</span>
                              </Tooltip>
                            }
                          />
                        </div>
                        <div>
                          <Checkbox
                            aria-label="Allow guests to add additional attendees"
                            checked={newLinkSettings.allowAdditionalAttendees}
                            onChange={(checked) =>
                              setLinkSetting("allowAdditionalAttendees", checked)
                            }
                            label="Allow guests to add additional attendees"
                          />
                        </div>
                        <div className="cw-flex cw-gap-2 cw-items-center">
                          <Checkbox
                            cw-id="time-buffer-checkbox"
                            checked={enableBookingTimeBuffer}
                            onChange={(checked) => {
                              setEnableBookingTimeBuffer(checked);
                              // This will get removed once we have a true boolean to turn this feature on and off.
                              if (!checked) {
                                setPrevTimeBuffer(bookingTimeBufferHoursToDisplay);
                                setLinkSetting("bufferHours", 0);
                              } else {
                                setLinkSetting("bufferHours", bookingTimeBufferHoursToDisplay);
                              }
                            }}
                            label="Don't let guests book in the next"
                          />
                          <Select
                            cw-id="time-buffer-dropdown"
                            value={bookingTimeBufferHoursToDisplay}
                            onChange={(value) => setLinkSetting("bufferHours", value)}
                          >
                            {range(24)
                              .map((h) => ++h)
                              .concat([48, 72, 96, 120, 144, 168])
                              .map((h) => (
                                <SelectOption value={h} key={h}>
                                  {h >= 24
                                    ? pluralize("days", h / 24, true)
                                    : pluralize("hour", h, true)}
                                </SelectOption>
                              ))}
                          </Select>
                        </div>
                        <Checkbox
                          cw-id="fifteen-minute-mark-checkbox"
                          checked={newLinkSettings.allowQuarterHourBooking}
                          onChange={(checked) => setLinkSetting("allowQuarterHourBooking", checked)}
                          label="Allow booking on the 15 minute mark "
                        />
                        <Tooltip
                          title={
                            newLinkSettings.linkMembers.length > 1
                              ? "Required when two or more attendees are included on a link to ensure that the link has availability"
                              : newLinkSettings.bookableHours === BookableHoursEnum.CustomHours
                              ? `Required when the "Custom hours" option is selected`
                              : ""
                          }
                        >
                          <div>
                            <Checkbox
                              disabled={
                                newLinkSettings.linkMembers.length > 1 ||
                                newLinkSettings.bookableHours === BookableHoursEnum.CustomHours
                              }
                              cw-id="no-meeting-day-checkbox"
                              checked={newLinkSettings.allowBookingOnNoMeetingDay}
                              onChange={(checked) =>
                                setLinkSetting("allowBookingOnNoMeetingDay", checked)
                              }
                              label="Let guests book on your no-meeting day"
                            />
                          </div>
                        </Tooltip>
                        <MaxBookingsRow
                          active={newLinkSettings.maxBookings.active}
                          count={newLinkSettings.maxBookings.count}
                          period={newLinkSettings.maxBookings.period}
                          onChange={(active, count, period) => {
                            setLinkSetting("maxBookings", { active, count, period });
                          }}
                        />
                      </animated.div>
                    ),
                    best: (
                      <animated.div
                        style={{ ...style, display: "flex", gap: 20 }}
                        id="highlight-best-times"
                        key={key}
                        cw-id="highlight-best-times-checkbox"
                        className="cw-max-w-[640px]"
                      >
                        <div>
                          <h2 className="cw-heading-xl cw-mb-3">Highlight your best times</h2>
                          <Checkbox
                            checked={newLinkSettings.highlightBestTimes}
                            onChange={(checked) => setLinkSetting("highlightBestTimes", checked)}
                            label='Highlight your "Best" times'
                          />
                          <div
                            className={classNames([
                              "cw-flex cw-items-center cw-gap-4 cw-flex-wrap cw-max-w-[550px]",
                              { "cw-flex-wrap": !showLinkActiveText },
                            ])}
                          >
                            <div className="cw-bg-neutral cw-p-8 cw-pt-4 cw-m-2 cw-ml-0 cw-min-w-[250px] cw-max-h-[215px] cw-rounded-md">
                              <CenteredCard
                                content={
                                  <p className="cw-body-lg cw-text-accent cw-font-semibold cw-m-0">
                                    1:00pm
                                  </p>
                                }
                              />
                              <CenteredCard
                                content={
                                  <p className="cw-body-lg cw-text-accent cw-font-semibold cw-m-0">
                                    2:00pm
                                    {newLinkSettings.highlightBestTimes && (
                                      <animated.span
                                        className="cw-bg-positive-inset cw-body-sm cw-text-positive cw-ml-2 cw-px-2 cw-py-1 cw-rounded"
                                        style={bestIconStyle}
                                      >
                                        BEST
                                      </animated.span>
                                    )}
                                  </p>
                                }
                              />
                              <CenteredCard
                                content={
                                  <p className="cw-body-lg cw-text-accent cw-font-semibold cw-m-0">
                                    2:
                                    <animated.span style={{ opacity: fadeIn }}>
                                      {newLinkSettings.allowQuarterHourBooking ? "15" : "30"}
                                    </animated.span>
                                    pm
                                  </p>
                                }
                              />
                            </div>
                            <div className="cw-body-base cw-text-muted cw-mr-3 cw-mb-5 cw-min-w-[205px] cw-max-w-[254px]">
                              Best times according to the Clockwise scheduler. These are highlighted
                              to help guide guests towards times that are more in-line with your
                              preferences
                            </div>
                          </div>
                        </div>
                      </animated.div>
                    ),
                    "attendee-questions": (
                      <animated.div
                        style={style}
                        key={key}
                        cw-id="attendee-questions"
                        className="cw-w-full"
                        onMouseLeave={() => setShowAttendeeQuestionErrors(true)}
                      >
                        <h2 className="cw-heading-xl cw-mb-3">Attendee questions</h2>
                        <div className="cw-body-lg cw-mr-3 cw-mb-5 cw-py-1 cw-min-w-[205px] cw-max-w-[470px]">
                          These are questions that you can include on the booking page. By default,
                          your link will gather the attendee's name and email address.
                        </div>
                        <div className={newLinkSettings.attendeeQuestions.length ? "cw-mb-8" : ""}>
                          <Reorder.Group
                            axis="y"
                            values={newLinkSettings.attendeeQuestions}
                            onReorder={(newQuestionsOrder: AttendeeQuestion[]) =>
                              setLinkSetting("attendeeQuestions", newQuestionsOrder)
                            }
                          >
                            {newLinkSettings.attendeeQuestions.map((aq, i) => {
                              const qEquivalentToOtherQ = newLinkSettings.attendeeQuestions.some(
                                (aq2, j) => i !== j && aq.question === aq2.question,
                              );
                              // renderId is needed as question is empty string at creation
                              const key = aq.renderId;
                              return (
                                <Reorder.Item key={key} value={aq}>
                                  <div
                                    className="cw-flex cw-items-center cw-gap-5 cw-mb-4 cw-flex-wrap"
                                    cw-id={`attendee-question-${i}`}
                                  >
                                    <div className="cw-flex cw-items-center cw-gap-1">
                                      <DragIndicator className="cw-cursor-grab active:cw-cursor-grabbing cw-text-default" />
                                      <TextField
                                        aria-label={`Attendee question ${i + 1} question text`}
                                        autoFocus={
                                          initialLinkSettings.attendeeQuestions.length !==
                                          newLinkSettings.attendeeQuestions.length
                                        }
                                        size={34}
                                        error={
                                          // If on mobile, we dont have mouseleave event to trigger validation so just always show
                                          ((showAttendeeQuestionErrors || isUserOnMobile) &&
                                            isEmpty(aq.question.trim())) ||
                                          qEquivalentToOtherQ
                                        }
                                        errorMessage={
                                          qEquivalentToOtherQ
                                            ? "Questions must be unique"
                                            : "Oops, a question cannot be blank"
                                        }
                                        value={aq.question}
                                        onChange={({ currentTarget: { value: updatedQuestion } }) =>
                                          handleAttendeeQuestionChange(
                                            { ...aq, question: updatedQuestion },
                                            i,
                                          )
                                        }
                                        fullWidth
                                        maxLength={200}
                                        required={aq.required}
                                      />
                                    </div>
                                    <div className="cw-flex cw-items-center cw-gap-4">
                                      <div className="cw-min-w-[112px]">
                                        <Select
                                          fullWidth
                                          value={aq.type}
                                          onChange={(updatedType) => {
                                            let updatedQuestion = aq.question;
                                            if (
                                              updatedType ===
                                                AttendeeQuestionTypeEnum.PhoneNumber &&
                                              !aq.question
                                            ) {
                                              updatedQuestion = "Phone Number";
                                            } else if (
                                              updatedType !==
                                                AttendeeQuestionTypeEnum.PhoneNumber &&
                                              aq.question === "Phone Number"
                                            ) {
                                              updatedQuestion = "";
                                              setShowAttendeeQuestionErrors(false);
                                            }

                                            handleAttendeeQuestionChange(
                                              {
                                                ...aq,
                                                question: updatedQuestion,
                                                type: updatedType,
                                              },
                                              i,
                                            );
                                          }}
                                        >
                                          {QUESTION_TYPES.map((type) => (
                                            <SelectOption key={type} value={type}>
                                              {ATTENDEE_QUESTION_TYPE_LABEL[type]}
                                            </SelectOption>
                                          ))}
                                        </Select>
                                      </div>
                                      <div className="cw-w-[105px]">
                                        <Select
                                          fullWidth
                                          value={aq.required ? "Required" : "Optional"}
                                          onChange={(value) => {
                                            handleAttendeeQuestionChange(
                                              { ...aq, required: value === "Required" },
                                              i,
                                            );
                                          }}
                                        >
                                          <SelectOption value="Required">Required</SelectOption>
                                          <SelectOption value="Optional">Optional</SelectOption>
                                        </Select>
                                      </div>
                                      <Button
                                        aria-label={`Delete attendee question ${i + 1}`}
                                        variant="text"
                                        sentiment="destructive"
                                        startIcon={DeleteOutline}
                                        onClick={() => handleAttendeeQuestionDeletion(i)}
                                      />
                                    </div>
                                  </div>
                                </Reorder.Item>
                              );
                            })}
                          </Reorder.Group>
                        </div>
                        <div className="cw-mt-6">
                          <Button
                            sentiment="positive"
                            disabled={newLinkSettings.attendeeQuestions.length >= 20}
                            onClick={() => {
                              const newAttendeeQuestions = cloneDeep(
                                newLinkSettings.attendeeQuestions,
                              );

                              setShowAttendeeQuestionErrors(false);
                              setLinkSetting("attendeeQuestions", [
                                ...newAttendeeQuestions,
                                getDefaulAttendeeQuestion(),
                              ]);
                            }}
                          >
                            Add a question
                          </Button>
                        </div>
                      </animated.div>
                    ),
                    delete: initialLinkSettings.id && (
                      <animated.div
                        style={style}
                        key={key}
                        className="cw-mt-6 cw-mb-2"
                        cw-id="delete-link-btn"
                      >
                        <Button
                          sentiment="destructive"
                          disabled={deleting}
                          onClick={() => setDeleteLinkDialogOpen(true)}
                          cw-id="delete-link-btn"
                        >
                          Delete link
                        </Button>
                      </animated.div>
                    ),
                    conflicts: null,
                  }[key];
                })}
              </animated.div>,
            ]?.[i],
        )}
      </div>
      <CwDialogConfirmRed
        header={deleteHeader}
        body={deleteBody}
        open={deleteLinkDialogOpen}
        onConfirm={() => {
          setDeleteLinkDialogOpen(false);
          if (initialLinkSettings.id) {
            onDelete(initialLinkSettings.id);
          }
        }}
        onCancel={() => setDeleteLinkDialogOpen(false)}
        confirmText="Delete"
      />
    </div>
  );
};
