import {
  FeatureSetting,
  FeatureSettingAside,
  FeatureSettingBody,
  FeatureSettingHeading,
  FeatureSettingMain,
  FeatureSettingsHoverCard,
} from "@clockwise/web-commons/src/components/feature-setting/FeatureSetting";

import React from "react";
import { ScrollIntoView } from "../ui-focus-wrappers/ScrollIntoView";
import { WebSettingsEventColorCodingRelay } from "../web-settings/web-settings-event-color-coding";
import ColorCodingIllustration from "./ColorCodingIllustration";
import Tooltip from "./Tooltip";

const ColorCoding = () => {
  return (
    <ScrollIntoView focusSection="colorCoding" highlight>
      <FeatureSetting title="Color Coding Settings">
        <FeatureSettingAside>
          <ColorCodingIllustration />
        </FeatureSettingAside>
        <FeatureSettingMain>
          <FeatureSettingHeading>
            <h2
              className="cw-heading-2xl cw-m-0"
              aria-label="Should we automatically color code your meetings?"
            >
              Should we automatically{" "}
              <FeatureSettingsHoverCard content={<Tooltip />}>
                <span className="cw-underline cw-decoration-dashed cw-underline-offset-4 cw-decoration-1">
                  color code
                </span>
              </FeatureSettingsHoverCard>{" "}
              your meetings?
            </h2>
          </FeatureSettingHeading>
          <FeatureSettingBody>
            <div className="cw-flex cw-flex-row cw-flex-wrap cw-space-x-2 cw-items-center">
              <WebSettingsEventColorCodingRelay />
            </div>
          </FeatureSettingBody>
        </FeatureSettingMain>
      </FeatureSetting>
    </ScrollIntoView>
  );
};

export default ColorCoding;
