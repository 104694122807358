import { bg_neutral_inset, bg_positive_emphasis } from "@clockwise/design-system/tokens";
import classNames from "classnames";
import React from "react";
import { useWebOnboardingSteps } from "../hooks/useWebOnboardingSteps";

export const StepCounter = () => {
  const { numNonSkippedSteps, currentStepIndex } = useWebOnboardingSteps();

  return (
    <div className="cw-flex cw-gap-x-2 cw-justify-center cw-w-full cw-text-subtle cw-pb-16">
      {[...Array(numNonSkippedSteps).keys()].map((step, index) => {
        const isHighlighted = index <= currentStepIndex;
        return (
          <div
            key={`step-${step}`}
            style={{ borderColor: isHighlighted ? bg_positive_emphasis : bg_neutral_inset }}
            className={classNames("cw-h-1 cw-w-[50px] cw-border cw-border-solid cw-rounded", {
              "cw-bg-neutral-inset": !isHighlighted,
              "cw-bg-positive-emphasis": isHighlighted,
            })}
          />
        );
      })}
    </div>
  );
};
