import { Button, Tooltip } from "@clockwise/design-system";
import {
  AddCircleOutline,
  FileCopyOutlined,
  RemoveCircleOutline,
} from "@clockwise/design-system/icons";
import { makeStyles } from "@material-ui/core";
import pluralize from "pluralize";
import * as React from "react";

import { all, keys, Slot } from "@clockwise/client-commons/src/constants/time-slot";
import { colors, greys } from "@clockwise/web-commons/src/styles/color.styles";
import { fontFamilySans } from "@clockwise/web-commons/src/styles/type.styles";
import {
  AutocompleteTimeslot,
  CopyButtonStyle,
  MeetingOrWorkingHours,
  SlotRangeWrapper,
  StartOrEndSlot,
  validateSlotChange,
} from "@clockwise/web-commons/src/ui/working-hours";
import { useFeatureFlag } from "../../../../launch-darkly";
import { WorkingLocationDaySetting } from "../../../account-preferences-working-location";

export interface DaySettingProps {
  label?: string;
  copyButtonStyle?: CopyButtonStyle | null;
  isMeetingOrWorkingHours: MeetingOrWorkingHours;
  slotRanges: SlotRangeWrapper;
  onSlotChange: (updatedSlots: SlotRangeWrapper) => void;
  onClickCopy: () => void;
  onLocationChange: (workingLocationId: string | null) => void;
  allowEmpty?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  slotRangeWrapper: {
    display: "flex",
    alignItems: "center",

    "& .MuiIconButton-root": {
      padding: 0,
      margin: "0px 5px 0px 10px",
    },
  },
  slotRange: {
    display: "flex",
    alignItems: "center",
    maxWidth: 220,
    width: "100%",
    height: 36,
    color: greys.standard,
    fontFamily: fontFamilySans,
    fontSize: 14,
    margin: "4px 0px",
  },
  slotRange__actions: {
    minWidth: 40,
    marginLeft: 8,
  },
  label: {
    maxWidth: 100,
    width: "100%",
    fontFamily: fontFamilySans,
    color: greys.standard,
    fontSize: 14,
  },
  endSlotWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > div:first-child": {
      color: greys.standard,
      fontFamily: fontFamilySans,
      fontSize: 14,
      lineHeight: "21px",
      height: 35,
      display: "flex",
      alignItems: "center",
    },
  },
  autocompleteWrapper: {
    height: 35,
    fontFamily: fontFamilySans,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: greys.standard,
    minWidth: 90,
  },
  startSlot: {
    borderTopLeftRadius: "4px 4px",
    borderBottomLeftRadius: "4px 4px",
  },
  endSlot: {
    borderTopRightRadius: "4px 4px",
    borderBottomRightRadius: "4px 4px",
  },
  copyButton: {
    color: colors.green.standard,
    textTransform: "initial",
    fontWeight: 500,
    marginLeft: 10,
  },
});

export const DaySetting = ({
  label,
  slotRanges,
  copyButtonStyle,
  isMeetingOrWorkingHours,
  onSlotChange,
  onClickCopy,
  onLocationChange,
  allowEmpty = false,
}: DaySettingProps) => {
  const classes = useStyles();
  const [isOnWorkingLocation] = useFeatureFlag("WorkingLocation");

  const handleAddHours = (endRangeEndSlot: Slot) => {
    const currEndSlotIndex = all.indexOf(endRangeEndSlot);
    const newSlots = [...slotRanges.slots];

    // add the next hour after the current end slot
    // when the endSlot is 1030p, the next hour
    // we add is at a half hours offset: 11pm-12am
    if (endRangeEndSlot === "T_22_00") {
      newSlots.push({
        startSlot: all[currEndSlotIndex + 2],
        endSlot: all[currEndSlotIndex + 3],
        startError: false,
        endError: false,
      });
    } else {
      // the add button is disabled for times greater
      // than 1030p, so the indexes below
      // are always valid
      newSlots.push({
        startSlot: all[currEndSlotIndex + 3],
        endSlot: all[currEndSlotIndex + 4],
        startError: false,
        endError: false,
      });
    }

    onSlotChange(validateSlotChange(newSlots));
  };

  const handleRemoveHours = (index: number) => {
    const newSlots = [...slotRanges.slots];
    newSlots.splice(index, 1);

    onSlotChange(validateSlotChange(newSlots));
  };

  const onEndSlotChange = (newEndSlot: Slot, index: number) => {
    const newSlots = [...slotRanges.slots];
    // Unless the user selects the very beginning slot as an option,
    // we preemptively correct the start slot when they make an end slot
    // selection that would otherwise lead to an invalid state.
    if (newEndSlot < newSlots[index].startSlot && newEndSlot >= all[2]) {
      newSlots[index].startSlot = all[all.indexOf(newEndSlot) - 1];
    }
    newSlots[index].endSlot = newEndSlot;
    onSlotChange(validateSlotChange(newSlots, StartOrEndSlot.EndSlot));
  };

  const onStartSlotChange = (newStartSlot: Slot, index: number) => {
    const newSlots = [...slotRanges.slots];
    // Unless the user selects the very end slot as an option,
    // we preemptively correct the end slot when they make a start slot
    // selection that would otherwise lead to an invalid state.
    if (newStartSlot > newSlots[index].endSlot && newStartSlot <= all[all.length - 3]) {
      newSlots[index].endSlot = all[all.indexOf(newStartSlot) + 1];
    }
    newSlots[index].startSlot = newStartSlot;
    onSlotChange(validateSlotChange(newSlots, StartOrEndSlot.StartSlot));
  };

  const renderCopyButton = (isFirstSlot: boolean) => {
    if (isFirstSlot && copyButtonStyle === CopyButtonStyle.Standard) {
      return (
        <Button
          variant="text"
          sentiment="positive"
          onClick={onClickCopy}
          startIcon={FileCopyOutlined}
          disabled={!slotRanges.isValid}
        >
          Copy {pluralize("time", slotRanges.slots.length)} to all
        </Button>
      );
    }

    if (isFirstSlot && copyButtonStyle === CopyButtonStyle.Compact) {
      return (
        <div className="cw-hidden md:cw-flex">
          <Tooltip
            title={`Copy ${pluralize("time", slotRanges.slots.length)} to all`}
            openDelay={400}
          >
            <Button
              variant="text"
              sentiment="positive"
              onClick={onClickCopy}
              startIcon={FileCopyOutlined}
              disabled={!slotRanges.isValid}
              aria-label={`Copy ${pluralize("time", slotRanges.slots.length)} to all`}
            >
              Copy
            </Button>
          </Tooltip>
        </div>
      );
    }

    return <></>;
  };

  const renderSlotRanges = () => {
    return slotRanges.slots.map(({ startSlot, endSlot, startError, endError }, index) => {
      const isFirstSlot = index === 0;
      const isLastSlot = index === slotRanges.slots.length - 1;

      return (
        <React.Fragment key={`${startSlot}-${endSlot}`}>
          <div className={classes.slotRangeWrapper}>
            <div className={classes.label}>{isFirstSlot && label}</div>
            <div className={classes.slotRange}>
              <div className={classes.autocompleteWrapper}>
                <AutocompleteTimeslot
                  type={StartOrEndSlot.StartSlot}
                  defaultValue={startSlot}
                  onTimeSlotChange={(option) => onStartSlotChange(option, index)}
                  error={startError}
                  aria-label={`Start time for ${isMeetingOrWorkingHours} period #${
                    index + 1
                  } on ${label}`}
                />
              </div>
              <div className="cw-px-2">to</div>
              <div className={classes.endSlotWrapper}>
                <div className={classes.autocompleteWrapper}>
                  <AutocompleteTimeslot
                    type={StartOrEndSlot.EndSlot}
                    defaultValue={endSlot}
                    onTimeSlotChange={(option) => onEndSlotChange(option, index)}
                    error={endError}
                    aria-label={`End time for ${isMeetingOrWorkingHours} period #${
                      index + 1
                    } on ${label}`}
                  />
                </div>
              </div>
            </div>
            <div className={classes.slotRange__actions}>
              {isLastSlot && (
                <Tooltip
                  title={`Add another ${isMeetingOrWorkingHours} period to this day`}
                  openDelay={400}
                >
                  <Button
                    variant="text"
                    startIcon={AddCircleOutline}
                    onClick={() => handleAddHours(endSlot)}
                    disabled={endSlot >= keys["T_22_30"]}
                    aria-label={`Add another ${isMeetingOrWorkingHours} period to ${label}`}
                  />
                </Tooltip>
              )}
            </div>
            <div className={classes.slotRange__actions}>
              {(!isFirstSlot || allowEmpty) && (
                <Tooltip title={`Remove this ${isMeetingOrWorkingHours} period`} openDelay={400}>
                  <Button
                    variant="text"
                    startIcon={RemoveCircleOutline}
                    onClick={() => handleRemoveHours(index)}
                    aria-label={`Remove ${isMeetingOrWorkingHours} period #${
                      index + 1
                    } from ${label}`}
                  />
                </Tooltip>
              )}
            </div>
            {isOnWorkingLocation &&
              isFirstSlot &&
              isMeetingOrWorkingHours === MeetingOrWorkingHours.WorkingHours && (
                <WorkingLocationDaySetting
                  day={label}
                  onSelectLocation={(workingLocationId) => onLocationChange(workingLocationId)}
                />
              )}
            {renderCopyButton(isFirstSlot)}
          </div>
        </React.Fragment>
      );
    });
  };

  return <div className={classes.container}>{renderSlotRanges()}</div>;
};
