import { DateTime } from "luxon";
import React from "react";
import { TimeSuggestion } from "../time-suggestion";
import { getFullWidthPositionerData } from "./utils/getFullWidthPositionerData";

export const TimeSuggestionOverlay = ({
  dateTime,
  proposalCalendarIds,
  allCalendarIds,
  minWidthPercent,
  maxFullColumns,
}: {
  dateTime: DateTime;
  proposalCalendarIds: string[];
  allCalendarIds: string[];
  minWidthPercent: number;
  maxFullColumns: number;
}) => {
  const { widthPercent, maxColumnsExceeded, columnCountOverride } = getFullWidthPositionerData(
    proposalCalendarIds,
    allCalendarIds,
    maxFullColumns,
    minWidthPercent,
  );

  return (
    <TimeSuggestion
      dateTimes={[dateTime]}
      widthPercent={widthPercent}
      fullWidth={maxColumnsExceeded}
      columnCountOverride={columnCountOverride}
      minWidthPercent={minWidthPercent}
      gutters={false}
    />
  );
};
