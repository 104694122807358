import {
  DayOffsetTimeSlotInput,
  DayOffsetTimeSlotRange,
  DaySetting,
  Maybe,
  Scalars,
  TimeSlot,
  WorkingHours,
} from "@clockwise/schema";
import { DateTime } from "luxon";
import { all, max, maxSlotMaxIndex, min } from "../constants/time-slot";
import { ZonedMoment } from "./ZonedMoment";

export type { DayOffsetTimeSlot } from "@clockwise/schema";

export const getSlotIndex = (slot: TimeSlot) => {
  return all.indexOf(slot);
};

export function convertTimeSlotToHourMinutes(
  slot: TimeSlot,
  workingHoursTimeZone?: string,
  isMinSlot = true,
  nowZonedMomentForTests?: ZonedMoment,
) {
  let hour = Number(slot.substring(2, 4));
  let minute = Number(slot.substring(5, 7));
  let minutesIntoDay = hour * 60 + minute;

  if (!isMinSlot) {
    hour = minute < 30 ? hour : hour + 1;
    minute = minute < 30 ? minute : 0;
    minutesIntoDay += 30;
  }

  if (workingHoursTimeZone) {
    // apply difference of timezones
    const now = nowZonedMomentForTests || new ZonedMoment().utc();
    const workingHoursTimeZoneOffset = new ZonedMoment(now, workingHoursTimeZone).utcOffset();
    const renderTimeZoneOffset = new ZonedMoment(now, ZonedMoment.getRenderTimeZone()).utcOffset();
    const tzOffset = renderTimeZoneOffset - workingHoursTimeZoneOffset;
    hour += tzOffset / 60;
    minute += tzOffset % 60;
    minutesIntoDay += tzOffset;
  }

  return { hour, minute, minutesIntoDay };
}

// take a MinSlotEnum and convert to { hour, minute }
export function convertTimeMinSlotToHourMinutes(
  slot: TimeSlot,
  workingHoursTimeZone?: string,
  nowZonedMomentForTests?: ZonedMoment,
) {
  return convertTimeSlotToHourMinutes(slot, workingHoursTimeZone, true, nowZonedMomentForTests);
}

// take a MaxSlotEnum and convert to { hour, minute }
export function convertTimeMaxSlotToHourMinutes(
  slot: TimeSlot,
  workingHoursTimeZone?: string,
  nowZonedMomentForTests?: ZonedMoment,
) {
  return convertTimeSlotToHourMinutes(slot, workingHoursTimeZone, false, nowZonedMomentForTests);
}

export function getAutopilotTimeOfDayFlexibilitySummaryText(
  minSlot: TimeSlot,
  maxSlot: TimeSlot,
  minAtAttendeesHours: boolean,
  maxAtAttendeesHours: boolean,
) {
  if (minAtAttendeesHours && maxAtAttendeesHours) {
    return `Within attendees' meeting hours`;
  } else if (minAtAttendeesHours) {
    return `Before ${max[maxSlot]}`;
  } else if (maxAtAttendeesHours) {
    return `After ${min[minSlot]}`;
  }

  return `Between ${min[minSlot]} - ${max[maxSlot]}`;
}

export function calculateDayOffsetTimeSlotIndex(targetSlot?: DayOffsetTimeSlotInput | null) {
  if (!targetSlot) {
    return null;
  }

  return all.indexOf(targetSlot.timeSlot) + targetSlot.dayOffset * all.length;
}

export function calculateDayOffsetTimeSlotFromIndex(index: number): DayOffsetTimeSlotInput {
  const dayDiff = (index < 0 ? -1 : 0) + Math.floor(index / all.length);
  const timeSlotIndex = (index < 0 ? index + all.length : index) % all.length;

  return {
    dayOffset: dayDiff,
    timeSlot: all[timeSlotIndex],
  };
}

const isDayOffsetTimeSlotRange = (x: unknown): x is DayOffsetTimeSlotRange => {
  return Boolean(
    x && typeof x === "object" && "__typename" in x && x.__typename === "DayOffsetTimeSlotRange",
  );
};

/**
 * Based on a set of timeSlots, where 0 represents T_00_00 (midnight) and 47 represents T_23_30 (11:30 PM),
 * the recommended slot range is the earliest and latest time slot that the event can be moved to.
 */
export type RecommendedSlotRange = [minRecommendedSlot: number, maxRecommendedSlot: number];

export function getEventMoveRangeMinMaxRange(
  meetingHourIntersection:
    | Maybe<DayOffsetTimeSlotRange>
    | undefined
    | { start: number; end: number },
  workingHours: WorkingHours | undefined,
  date?: string,
): RecommendedSlotRange {
  // Get the min and max bounds of the move range. Either comes from suggestedAutopilotSettingsResponseV3 if present,
  // else the user's working hours for that day.

  let constraintMin = 0, // The earlist and latest time the range can be set to.
    constraintMax = maxSlotMaxIndex; // These are bad defaults. Something better.
  if (meetingHourIntersection) {
    if (isDayOffsetTimeSlotRange(meetingHourIntersection)) {
      constraintMin =
        calculateDayOffsetTimeSlotIndex(meetingHourIntersection.minSlot) ?? constraintMin;
      constraintMax =
        calculateDayOffsetTimeSlotIndex(meetingHourIntersection.maxSlot) ?? constraintMax;
    } else {
      meetingHourIntersection;
      constraintMin = meetingHourIntersection.start;
      constraintMax = meetingHourIntersection.end;
    }
  } else if (workingHours && date) {
    const selectedDay = new ZonedMoment(date).format("dddd");

    const dayWorkingHours = workingHours?.daySettings?.find(
      (ds: Maybe<DaySetting>): ds is DaySetting => ds?.day === selectedDay,
    );

    if (dayWorkingHours) {
      // TODO (lsanwick) Remove MinSlot and MaxSlot from the schema. This type casting is because
      // MinSlot, MaxSlot, and TimeSlot are all identical enums, but Typescript doesn't know that.
      constraintMin = dayWorkingHours.setting?.minSlot
        ? all.indexOf((dayWorkingHours.setting.minSlot as unknown) as TimeSlot)
        : 0;
      constraintMax = dayWorkingHours.setting?.maxSlot
        ? all.indexOf((dayWorkingHours.setting.maxSlot as unknown) as TimeSlot)
        : maxSlotMaxIndex;
    }
  }

  return [constraintMin, constraintMax];
}

/**
 * @param timeString Format is 'h:mm' and needs be on the hour or half hour, e.g. "18:30"
 */
export function convertTimeHoursMinutesToTimeSlot(
  timeString: string,
  boundary: "start" | "end",
): {
  dayOffset: Scalars["Int"];
  timeSlot: TimeSlot;
} {
  const hour = Number(timeString?.split(":")[0]);
  const minutes = Number(timeString?.split(":")[1]);

  const hourMinutesTimeOfDay = DateTime.fromObject({ hour: hour, minute: minutes })
    .toFormat("h:mma")
    .toLowerCase();

  const timeSlotMap = boundary === "start" ? min : max;
  const timeSlot = Object.keys(timeSlotMap).find(
    (timeSlot) => timeSlotMap[timeSlot] === hourMinutesTimeOfDay,
  ) as TimeSlot;

  return {
    dayOffset: 0,
    timeSlot: timeSlot,
  };
}
