// styles
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { greys } from "../../../styles/color.styles";
import { fontFamilySans } from "../../../styles/type.styles";

// components
import { body as tipBody, header as tipHeader } from "../../tutorial-tip/TutorialTip.styles";

const event: CSSProperties = {
  flexDirection: "column",
  overflow: "hidden",
  borderRadius: 4,
  cursor: "pointer",
  width: "100%",
  height: "100%",
  color: greys.white,
  transition: "background-color 250ms, opacity 250ms",
  border: `1px solid ${greys.white}`,
  padding: "6px 8px",
};

const eventTitle: CSSProperties = {
  fontFamily: fontFamilySans,
  fontSize: 12,
  lineHeight: 1.2,
  overflow: "hidden",
};

const eventLocationTime: CSSProperties = {
  fontFamily: fontFamilySans,
  marginTop: 4,
  fontSize: 12,
  width: 209,
  overflow: "hidden",
};

export const simpleEvent: CSSProperties = {
  position: "absolute",
  width: "100%",
  fontFamily: fontFamilySans,
  fontSize: 12,
  padding: "0 4px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  color: greys.white,
  zIndex: 11,
  transformOrigin: "center top",
  transition: "all 200ms",
  "@media (min-width: 768px)": {
    padding: "0 9px",
  },
};

export const styles = {
  event,
  eventTitle,
  eventLocationTime,
  simpleEvent,
  tipHeader,
  tipBody,
  container: {
    transition: "height 250ms, width 250ms, left 250ms, top 250ms, z-index 250ms",
    position: "absolute",
    paddingBottom: 4,
    paddingLeft: 2,
    paddingRight: 2,
    zIndex: 1,
    "&:hover": {
      zIndex: 2,
    },
  } as CSSProperties,
  eventMask: {
    borderRadius: 8,
    height: "100%",
    width: "100%",
    backgroundColor: greys.white,
  } as CSSProperties,
};
