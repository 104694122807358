class EnhancedHistory {
  public stack: string[];
  public currentIndex = 0;

  private ignoreNextChange = false;

  constructor(private mode: "hash" | "pathname") {
    this.subscribe();
    this.stack = [mode === "pathname" ? window.location.pathname : window.location.hash];
  }

  private onChange = () => {
    if (this.ignoreNextChange) {
      this.ignoreNextChange = false;

      return;
    }

    const next = this.mode === "pathname" ? window.location.pathname : window.location.hash;

    // either we are going back, forward, or there are no changes
    if (this.currentIndex - 1 >= 0 && next === this.stack[this.currentIndex - 1]) {
      this.stack.pop();
      this.currentIndex--;
    } else if (next !== this.stack[this.currentIndex]) {
      this.stack = this.stack.slice(0, this.currentIndex + 1);
      this.stack.push(next);
      this.currentIndex += 1;
    }
  };

  private updateLocation() {
    const pathname =
      this.mode === "pathname" ? this.stack[this.currentIndex] : window.location.pathname;
    const hash = this.mode === "hash" ? this.stack[this.currentIndex] : window.location.hash;

    const newState = `${window.location.origin}${pathname}${window.location.search}${
      hash ? hash : "#"
    }`;

    if (this.mode === "pathname") {
      window.history.pushState({}, "", newState);
    } else {
      window.location.replace(newState);

      this.ignoreNextChange = true;
    }
  }

  public push(next: string) {
    this.stack = this.stack.slice(0, this.currentIndex + 1);
    this.stack.push(`#${next}`);
    this.currentIndex += 1;
    this.updateLocation();
  }

  public replace(next: string) {
    this.stack[this.currentIndex] = `#${next}`;

    this.updateLocation();
  }

  public go(delta: number) {
    this.currentIndex = Math.max(0, Math.min(this.currentIndex + delta, this.stack.length - 1));

    this.updateLocation();
  }

  public goBack() {
    this.go(-1);
  }

  public goForward() {
    this.go(1);
  }

  public subscribe() {
    window.addEventListener(this.mode === "pathname" ? "popstate" : "hashchange", this.onChange);
  }

  public dispose() {
    window.removeEventListener(this.mode === "pathname" ? "popstate" : "hashchange", this.onChange);
  }
}

export const pathnameHistory = new EnhancedHistory("pathname");
export const hashHistory = new EnhancedHistory("hash");
