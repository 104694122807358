import { useActiveProposal } from "./useActiveProposal";

export const useActiveSchedulingOptions = () => {
  const { proposal, loading } = useActiveProposal();

  if (loading) {
    return [];
  } else {
    const options = proposal?.options;
    if (options?.__typename === "SchedulingOptions") {
      return options.optionDetails;
    }
  }
  return [];
};
