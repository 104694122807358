import { Select, SelectOption } from "@clockwise/design-system";
import { range } from "lodash";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import React from "react";
import RangeSelect from "../DateRangePicker";
import {
  DEFAULT_WINDOW_SIZE_IN_DAYS,
  MAX_WINDOW_SIZE_IN_DAYS,
  MIN_WINDOW_SIZE_IN_DAYS,
} from "../consts";
import { DateTimeString } from "../setup-scheduling-link";

export enum PeriodType {
  Range = "Range",
  RollingWindow = "RollingWindow",
}

export type TimeRange =
  | {
      endDate: DateTimeString;
      periodType: PeriodType.Range;
      startDate: DateTimeString;
      windowSizeDays: null;
    }
  | {
      endDate: null;
      periodType: PeriodType.RollingWindow;
      startDate: null;
      windowSizeDays: number;
    };

export type TimeRangePickerProps = {
  endDate: DateTimeString | null;
  onChange: (timeRangeUpdate: TimeRange) => void;
  periodType: PeriodType;
  startDate: DateTimeString | null;
  windowSizeDays: number | null;
};

export type TimeRangeAction =
  | {
      type: "change-periodType";
      delta: {
        periodType: PeriodType;
      };
    }
  | {
      type: "change-dateRange";
      delta: {
        startDate: DateTimeString;
        endDate: DateTimeString;
      };
    }
  | {
      type: "change-windowSizeDays";
      delta: {
        windowSizeDays: number;
      };
    };

/** return time range with null set according to period type; populates related fields with defaults if required */
const getTimeRange = ({
  endDate,
  periodType,
  startDate,
  windowSizeDays,
}: {
  endDate: DateTimeString | null;
  periodType: PeriodType;
  startDate: DateTimeString | null;
  windowSizeDays: number | null;
}) => {
  if (periodType === PeriodType.Range) {
    return {
      endDate:
        endDate || DateTime.now().plus({ days: DEFAULT_WINDOW_SIZE_IN_DAYS }).endOf("day").toISO(),
      periodType: PeriodType.Range,
      startDate: startDate || DateTime.now().startOf("day").toISO(),
      windowSizeDays: null,
    } as TimeRange;
  } else {
    return {
      endDate: null,
      periodType: PeriodType.RollingWindow,
      startDate: null,
      windowSizeDays: windowSizeDays || DEFAULT_WINDOW_SIZE_IN_DAYS,
    } as TimeRange;
  }
};

const TimeRangePicker = ({
  endDate,
  onChange,
  periodType,
  startDate,
  windowSizeDays,
}: TimeRangePickerProps) => {
  const currentTimeRange = getTimeRange({ endDate, periodType, startDate, windowSizeDays });

  const onChangePeriodType = (periodType: PeriodType) =>
    onChange(
      getTimeRange({
        ...currentTimeRange,
        periodType,
      }),
    );

  const onChangeWindowSize = (windowSizeDays: number) =>
    onChange(
      getTimeRange({
        ...currentTimeRange,
        periodType: PeriodType.RollingWindow,
        windowSizeDays,
      }),
    );

  const onDateRangeChange = (startDate: string, endDate: string) =>
    onChange(
      getTimeRange({
        ...currentTimeRange,
        endDate,
        periodType: PeriodType.Range,
        startDate,
      }),
    );

  return (
    <div className="cw-flex cw-flex-row cw-gap-3 md:cw-flex-nowrap cw-flex-wrap cw-items-center">
      <Select cw-id="links-time-window-select" value={periodType} onChange={onChangePeriodType}>
        <SelectOption value={PeriodType.RollingWindow}>Within next</SelectOption>
        <SelectOption value={PeriodType.Range}>From</SelectOption>
      </Select>
      {currentTimeRange.periodType === PeriodType.RollingWindow && (
        <>
          <Select
            cw-id="link-window-size-days"
            value={currentTimeRange.windowSizeDays}
            onChange={onChangeWindowSize}
          >
            {range(MAX_WINDOW_SIZE_IN_DAYS - MIN_WINDOW_SIZE_IN_DAYS + 1).map((i) => {
              const day = i + MIN_WINDOW_SIZE_IN_DAYS;
              return (
                <SelectOption value={day} key={day}>
                  {pluralize("day", day, true)}
                </SelectOption>
              );
            })}
          </Select>
        </>
      )}
      {currentTimeRange.periodType === PeriodType.Range && (
        <RangeSelect
          endDate={currentTimeRange.endDate}
          startDate={currentTimeRange.startDate}
          onChange={onDateRangeChange}
        />
      )}
    </div>
  );
};

export default TimeRangePicker;
