import { graphql } from "react-relay";
import "../../event-coloring-settings";

export const query = graphql`
  query WebSettingsEventColorCodingRelayQuery {
    viewer {
      user {
        orgs(first: 1000000) {
          edges {
            node {
              id
              ...EventColoringSettings_org
            }
          }
        }
      }
    }
  }
`;
