import { DayOfWeek, ResponseStatusEnum } from "@clockwise/schema";

export const ALL_WORK_DAYS_SELECTED = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
];
export type SelectedWorkdayOption = typeof ALL_WORK_DAYS_SELECTED[number];

export const EMPTY_FLEX_DETAILS: EventCardFlex = {
  isFlexible: false,
  timeRangeFlexibility: {
    range: "Day",
    daysOfWeekFlexibility: [],
  },
  timeOfDayFlexibility: {
    startTime: null,
    endTime: null,
  },
};

export type EventCardFlexConstraints =
  | {
      availableMeetingHours: {
        start: string | null;
        end: string | null;
      } | null;
      availableMeetingDays: SelectedWorkdayOption[];
    }
  | undefined;

export type EventCardFlex = {
  isFlexible: boolean;
  timeRangeFlexibility: {
    range: "Day" | "Week" | null;
    daysOfWeekFlexibility: SelectedWorkdayOption[];
  };
  timeOfDayFlexibility: {
    startTime: string | null;
    endTime: string | null;
  };
};

export type EventCardAttendee = {
  id: string;
  primaryEmail: string;
  primaryCalendar: string;
  userId: string | null;
  attendingStatus?: ResponseStatusEnum;
  isYou: boolean;
  isOrganizer?: boolean;
  profile: {
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  } | null;
  isOptional?: boolean;
};

export type EventPermissionsInfo = {
  canDelete: boolean;
  canInviteOthers: boolean;
  canModify: boolean;
  canRemove: boolean;
  canRSVP: boolean;
  canSeeOtherGuests: boolean;
  canModifyAll: boolean;
  canModifyFuture: boolean;
};

export const EMPTY_PERMISSIONS: EventPermissionsInfo = {
  canDelete: false,
  canInviteOthers: false,
  canModify: false,
  canRemove: false,
  canRSVP: false,
  canSeeOtherGuests: false,
  canModifyAll: false,
  canModifyFuture: false,
};

export const FULL_PERMISSIONS: EventPermissionsInfo = {
  canDelete: true,
  canInviteOthers: true,
  canModify: true,
  canRemove: true,
  canRSVP: true,
  canSeeOtherGuests: true,
  canModifyAll: true,
  canModifyFuture: true,
};
