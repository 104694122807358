import * as Types from '@clockwise/schema/v2';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EnableOrDisableTeamSyncMutationVariables = Types.Exact<{
  externalEventId: Types.Scalars['ID'];
  enabled: Types.Scalars['Boolean'];
}>;


export type EnableOrDisableTeamSyncMutation = { __typename: 'Mutation', enableOrDisableTeamSync: { __typename: 'Event', id: string, isSyncedToTeamCalendar: boolean } | null };


export const EnableOrDisableTeamSyncDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EnableOrDisableTeamSync"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enableOrDisableTeamSync"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"externalEventId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"externalEventId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"enabled"},"value":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isSyncedToTeamCalendar"}}]}}]}}]} as unknown as DocumentNode<EnableOrDisableTeamSyncMutation, EnableOrDisableTeamSyncMutationVariables>;