import { logger } from "@clockwise/client-commons/src/util/logger";
import { Button } from "@clockwise/design-system";
import { Close } from "@clockwise/design-system/icons";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { narrowMediaQuery } from "@clockwise/web-commons/src/ui/scheduling-link/scheduling-link.styles";
import { track, TrackingEvents } from "@clockwise/web-commons/src/util/analytics.util";
import { Dialog, makeStyles, Paper, PaperProps } from "@material-ui/core";
import { animated, config, useSpring } from "@react-spring/web";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { SendProposeNewTimeEmailDocument } from "../../apollo/__generated__/SendProposeNewTimeEmail.v2.generated";

const useStyles = makeStyles({
  paper: {
    minWidth: 380,
    maxWidth: 380,
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    borderRadius: 8,

    [narrowMediaQuery]: {
      height: "100%",
    },
  },
});

interface IProps {
  proposalId: string;
  dialogOpen: boolean;
  recipientEmail: string;
  fullscreen?: boolean;
  onClose: () => void;
}

export const ProposeNewTimeModal = ({
  proposalId,
  dialogOpen,
  recipientEmail,
  fullscreen = false,
  onClose,
}: IProps) => {
  const classes = useStyles();
  const [sendingPreviewEmailTimer, setSendingPreviewEmailTimer] = useState(false);
  const [previewEmailSent, setPreviewEmailSent] = useState(false);
  const [optionalReason, setOptionalReason] = useState<null | string>(null);
  const [sendProposeNewTimeEmail] = useGatewayMutation(SendProposeNewTimeEmailDocument);

  const modalStyles = useSpring({
    from: {
      scale: 0.83,
      opacity: 0,
    },
    to: {
      scale: 1,
      opacity: 1,
    },
    config: config.stiff,
  });

  const AnimatedPaper = animated(Paper);
  const setOptionalReasonFromUser = (value: string) => {
    value.length === 0 ? setOptionalReason(null) : setOptionalReason(value);
  };

  useEffect(() => {
    if (!sendingPreviewEmailTimer && previewEmailSent) {
      toast.success("Preview email sent!");
      setSendingPreviewEmailTimer(false);
      setPreviewEmailSent(false);
    }
  }, [sendingPreviewEmailTimer, previewEmailSent]);

  const sendEmail = ({ isPreview = false }) => {
    if (isPreview) {
      setSendingPreviewEmailTimer(true);
      setTimeout(() => {
        setSendingPreviewEmailTimer(false);
      }, 2000);
      track(TrackingEvents.SHAREABLE_PROPOSAL.SHARE_SUB_MENU.SEND_PREVIEW_PROPOSE_NEW_TIME_EMAIL, {
        withReason: !!optionalReason,
      });
    } else {
      track(TrackingEvents.SHAREABLE_PROPOSAL.SHARE_SUB_MENU.SEND_PROPOSE_NEW_TIME_EMAIL, {
        withReason: !!optionalReason,
      });
    }

    void sendProposeNewTimeEmail({
      variables: { proposalId, isPreview, reasonForProposing: optionalReason },
      onError: (error) => {
        logger.error("Error sending propose new time email", error);
        toast.error("Oops. There was a problem sending the email. Please try again.");
      },
      onCompleted: (_) => {
        if (isPreview) {
          setPreviewEmailSent(true);
        } else {
          setOptionalReason(null);
          onClose();
          toast.success(`Sent proposal to ${recipientEmail}`);
        }
      },
    });
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => onClose()}
      fullScreen={fullscreen}
      disableRestoreFocus
      disableScrollLock
      PaperComponent={AnimatedPaper as React.ComponentType<PaperProps>}
      PaperProps={{
        style: {
          // PaperProps wants CSS properties so must cast the animated values to number
          scale: (modalStyles.scale as unknown) as number,
          opacity: (modalStyles.opacity as unknown) as number,
          visibility: dialogOpen ? "visible" : "hidden",
        },
        className: classes.paper,
      }}
      cw-id="propose-new-time-modal"
    >
      <div className="cw-flex cw-flex-col cw-h-full cw-text-12">
        <div className="cw-flex cw-justify-between cw-items-center cw-pl-3 cw-border-b cw-border-solid cw-border-b-muted cw-bg-neutral">
          <h2 className="cw-font-semibold">Propose a new time via email</h2>
          <div className="">
            <Button variant="text" size="xsmall" startIcon={Close} onClick={onClose} />
          </div>
        </div>
        <div className="cw-flex cw-items-center cw-pt-4 cw-px-4">
          <span className="cw-font-semibold">To:</span>&nbsp;{recipientEmail} (organizer)
        </div>
        <div className="cw-flex cw-flex-col cw-py-4 cw-px-4 cw-text-13">
          <div className="cw-font-semibold">Optional Reason</div>
          <input
            className="cw-w-full cw-text-13 cw-mt-2 cw-px-3 cw-py-2 cw-rounded cw-border cw-border-t-muted"
            placeholder="Why this time?"
            onChange={(e) => setOptionalReasonFromUser(e.target.value)}
          />
        </div>
        <div className="cw-flex cw-justify-between cw-items-center cw-py-[10px] cw-px-3 cw-border-t cw-border-solid cw-border-t-muted">
          <div className="cw-text-positive">
            {sendingPreviewEmailTimer ? (
              <div>Sending preview email...</div>
            ) : (
              <div
                className="cw-underline cw-cursor-pointer"
                onClick={() => sendEmail({ isPreview: true })}
              >
                Send yourself a preview
              </div>
            )}
          </div>
          <Button
            sentiment="positive"
            size="medium"
            disabled={sendingPreviewEmailTimer}
            onClick={() => sendEmail({ isPreview: false })}
          >
            Send Proposal
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
