import { useUserProfile } from "#webapp/src/components/hooks/useUserProfile";
import { FeedbackThumbs } from "#webapp/src/components/web-app-calendar/FeedbackThumbs";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { Button } from "@clockwise/design-system";
import classNames from "classnames";
import React from "react";
import { useResetConversation } from "../../hooks/useResetConversation";
import { useSetAIError } from "../hooks/AIErrorContext";
import { getFeedbackUrls } from "./proposal/utils/feedbackUrl";

export const ChatDone = ({
  conversationId,
  currentLLMUsed,
  msgId,
  proposalId,
}: {
  conversationId: string;
  currentLLMUsed: string;
  msgId: string;
  proposalId: string;
}) => {
  const { userProfile } = useUserProfile();

  const setError = useSetAIError();

  const [onResetConversation, { loading }] = useResetConversation({
    onError: (error) => {
      setError({
        error,
        message: "Error resetting chat conversation",
        userMessage: "Failed to reset the conversation",
        showUserMessage: true,
      });
    },
  });

  const handleResetConversation = () => {
    track(TrackingEvents.CHAT.RESET_CONVERSATION);
    void onResetConversation();
  };

  // Got a confirmation message
  const [feedbackUrlPositive, feedbackUrlNegative] = getFeedbackUrls(
    conversationId,
    proposalId,
    userProfile,
    currentLLMUsed,
  );

  const handleFeedback = (isPositive: boolean) => {
    const trackingEvent = isPositive
      ? TrackingEvents.CHAT.PROPOSAL.FEEDBACK_POSITIVE
      : TrackingEvents.CHAT.PROPOSAL.FEEDBACK_NEGATIVE;
    track(trackingEvent, {
      msgId: msgId,
      conversationId: conversationId,
      proposalId: proposalId,
      llmName: currentLLMUsed,
    });
  };

  return (
    <div className={classNames("cw-flex cw-flex-row cw-items-center cw-space-x-3", "cw-pt-2")}>
      <div>
        <Button
          disabled={!!loading}
          onClick={handleResetConversation}
          sentiment="positive"
          size="xsmall"
          variant="solid"
        >
          Done
        </Button>
      </div>
      <FeedbackThumbs
        feedbackUrlNegative={feedbackUrlNegative}
        onClick={handleFeedback}
        feedbackUrlPositive={feedbackUrlPositive}
        size="16"
      />
    </div>
  );
};
