import { RecurrenceRule } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { EventPermissions } from "@clockwise/schema";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import {
  EventId,
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime, Interval } from "luxon";
import React, { useMemo } from "react";
import { useUserProfile } from "../hooks/useUserProfile";
import { isAllDayBasedOnTimes } from "../web-app-calendar/hooks/useEditExistingEvent.util";
import useEventDetails from "../web-app-calendar/hooks/useEventDetails";
import { EventCard } from "./EventCard";
import { LoadingCard } from "./LoadingCard";
import { LockedEventCard } from "./LockedEventCard";
import { OutOfOfficeCard } from "./OutOfOfficeCard";
import { PersonalSyncCard } from "./PersonalSyncCard";
import { SmartHoldCard } from "./SmartHoldCard";
import { WorkingLocationCard } from "./WorkingLocationCard";
import { EventDetailsDocument } from "./__generated__/EventDetails.v2.generated";
import { EventPermissionsInfoDocument } from "./__generated__/Permissions.v2.generated";
import { WorkingLocationDetailsDocument } from "./__generated__/WorkingLocationDetails.v2.generated";
import { EventCardContextProvider } from "./hooks/EventCardContext";
import { ProposalCardManager } from "./proposals";
import { EMPTY_PERMISSIONS, EventCardAttendee } from "./types";

const DEFAULT_START_ISO = DateTime.now().setZone(getRenderTimeZone());
const DEFAULT_END_ISO = DateTime.now().setZone(getRenderTimeZone()).plus({ hours: 1 });

const toDateTime = (iso?: string): DateTime | undefined =>
  iso ? DateTime.fromISO(iso, { zone: getRenderTimeZone() }) : undefined;

export const EventCardManager = ({ activeEvent }: { activeEvent: EventId }) => {
  const updateActiveEvent = useUpdateActiveEvent();

  const { eventDetails, colorSettings, loading, error: fetchedDetailsError } = useEventDetails(
    activeEvent.externalEventId,
    activeEvent.calendarId,
  );

  const { data: permissionsData, loading: loadingPermissions } = useGatewayQuery(
    EventPermissionsInfoDocument,
    {
      variables: {
        id: activeEvent.externalEventId,
        calendarId: activeEvent.calendarId,
      },
      skip: eventDetails.isValid === false,
    },
  );
  // Will merge with the permissions query in the PR that will remove the LD flag
  const { data: eventDetailsV2Data, loading: eventDetailsV2Loading } = useGatewayQuery(
    EventDetailsDocument,
    {
      variables: {
        id: activeEvent.externalEventId,
        calendarId: activeEvent.calendarId,
      },
      skip: eventDetails.isValid === false,
    },
  );

  if (eventDetails.isValid === false) {
    updateActiveEvent(null);
    return null;
  }

  const permissions = permissionsData?.event?.eventPermissions ?? EMPTY_PERMISSIONS;

  const isAllDayFromTimes = isAllDayBasedOnTimes(
    toDateTime(eventDetails.startISO),
    toDateTime(eventDetails.endISO),
  );

  const startTime = toDateTime(eventDetails.startISO) ?? DEFAULT_START_ISO;
  const endTime = toDateTime(eventDetails.endISO) ?? DEFAULT_END_ISO;

  const attendeesAsEventCardAttendees: EventCardAttendee[] =
    eventDetailsV2Data?.event?.attendees?.map((attendee) => ({
      id: attendee.id,
      primaryEmail: attendee.email,
      primaryCalendar: attendee.email,
      userId: null,
      attendingStatus: attendee.responseStatus ?? undefined,
      isYou: attendee.person.isMe,
      isOrganizer: attendee.isOrganizer ?? undefined,
      profile: {
        givenName: attendee.person.givenName,
        familyName: attendee.person.familyName,
        externalImageUrl: attendee.person.externalImageUrl,
      },
      isOptional: attendee.isOptional ?? undefined,
    })) ?? [];

  const contextValue = {
    startTime,
    endTime,
    isAllDay: isAllDayFromTimes,
    timeError: null,
    attendees: attendeesAsEventCardAttendees,
    eventId: activeEvent.externalEventId,
    calendarId: activeEvent.calendarId,
  };

  if (loading || loadingPermissions || eventDetailsV2Loading) {
    return <LoadingCard onClose={() => updateActiveEvent(null)} />;
  }

  const isOwnEvent = permissions.canRemove;
  const isRecurring = !!eventDetails.recurrenceRule;

  return (
    // give a key to the context provider, so we ensure the provider is recreated once we have data
    //  this is a bit of a hack because we need to provide some initial data before information is fetched,
    //  because `EventCard` needs data at all times
    <EventCardContextProvider key={eventDetails.id} initValue={contextValue}>
      {eventDetails.locked ? (
        <LockedEventCard
          calendarId={activeEvent.calendarId}
          title={eventDetailsV2Data?.event?.title}
        />
      ) : eventDetailsV2Data?.event?.smartHold ? (
        <SmartHoldCard
          smartHold={eventDetailsV2Data?.event?.smartHold}
          permissions={permissions}
          calId={activeEvent.calendarId}
          transparency={eventDetails.transparency}
          visibility={eventDetails.visibility}
        />
      ) : eventDetailsV2Data?.event?.isOutOfOffice ? (
        <OutOfOfficeCard
          category={eventDetails.category}
          colorSettings={colorSettings}
          eventCalendarId={eventDetails.calendarId ?? ""}
          eventPermissions={eventDetails.eventPermissions}
          id={activeEvent.externalEventId}
          isOwnEvent={isOwnEvent}
          isRecurring={isRecurring}
          isSynced={eventDetailsV2Data?.event?.isSyncedToTeamCalendar}
          permissions={permissions}
          recurrenceRule={eventDetails.recurrenceRule ?? null}
          title={eventDetails.title}
          transparency={eventDetails.transparency}
          visibility={eventDetails.visibility}
        />
      ) : eventDetailsV2Data?.event?.isSyncedFromPersonalCalendar ? (
        <PersonalSyncCard
          category={eventDetails.category}
          colorSettings={colorSettings}
          eventCalendarId={eventDetails.calendarId ?? ""}
          eventPermissions={eventDetails.eventPermissions}
          id={activeEvent.externalEventId}
          isOwnEvent={isOwnEvent}
          isRecurring={isRecurring}
          title={eventDetailsV2Data.event?.syncedFromEvent?.title ?? eventDetails.title}
        />
      ) : (
        <EventCard
          eventDetails={eventDetails}
          permissions={permissions}
          eventId={activeEvent}
          error={Boolean(fetchedDetailsError)}
          colorSettings={colorSettings}
        />
      )}
    </EventCardContextProvider>
  );
};

export const WorkingLocationCardManager = ({ activeEvent }: { activeEvent: EventId }) => {
  const updateActiveEvent = useUpdateActiveEvent();
  const {
    userProfile: { primaryCalendar },
  } = useUserProfile();

  // Will merge with the permissions query in the PR that will remove the LD flag
  const { data, loading } = useGatewayQuery(WorkingLocationDetailsDocument, {
    variables: {
      externalEventId: activeEvent.externalEventId,
      calendarId: activeEvent.calendarId,
    },
  });

  const workingLocationEvent = data?.calendars[0]?.workingLocationEvent;

  const recurrenceRule = useMemo(
    () =>
      workingLocationEvent?.recurrenceRule
        ? new RecurrenceRule(workingLocationEvent.recurrenceRule)
        : null,
    [workingLocationEvent?.recurrenceRule],
  );

  if (!workingLocationEvent) {
    return null;
  }

  const range =
    workingLocationEvent.range.__typename === "DateTimeRange"
      ? workingLocationEvent.range.timeRange
      : workingLocationEvent.range.dateRange;
  const timeInterval = Interval.fromISO(range, {
    zone: getRenderTimeZone(),
  });

  const startTime = toDateTime(timeInterval.start.toISO()) ?? DEFAULT_START_ISO;
  const endTime = toDateTime(timeInterval.end.toISO()) ?? DEFAULT_END_ISO;

  const contextValue = {
    startTime,
    endTime,
    isAllDay: workingLocationEvent.range.__typename === "DateRange",
    timeError: null,
    attendees: [],
    eventId: activeEvent.externalEventId,
    calendarId: activeEvent.calendarId,
  };

  if (loading) {
    return <LoadingCard onClose={() => updateActiveEvent(null)} />;
  }

  return (
    // give a key to the context provider, so we ensure the provider is recreated once we have data
    //  this is a bit of a hack because we need to provide some initial data before information is fetched,
    //  because `EventCard` needs data at all times
    <EventCardContextProvider key={workingLocationEvent.id} initValue={contextValue}>
      <WorkingLocationCard
        eventCalendarId={activeEvent.calendarId}
        eventPermissions={
          primaryCalendar === activeEvent.calendarId ? EventPermissions.ALL : EventPermissions.NONE
        }
        eventId={activeEvent.externalEventId}
        title={workingLocationEvent.title ?? ""}
        workingLocation={workingLocationEvent.location}
        recurrenceRule={recurrenceRule}
      />
    </EventCardContextProvider>
  );
};

export const CardManager = ({ activeEvent }: { activeEvent: EventId }) => {
  switch (activeEvent.type) {
    case EventType.Event:
    case EventType.PersonalCalendarSync:
      return <EventCardManager activeEvent={activeEvent} />;
    case EventType.WorkingLocation:
      return <WorkingLocationCardManager activeEvent={activeEvent} />;
    case EventType.Proposal:
      return <ProposalCardManager />;
    default:
      return <></>;
  }
};
