import React from "react";

export const NoResults = () => (
  <div>
    <div className="cw-space-y-1 cw-border cw-border-solid cw-border-default cw-rounded cw-p-2">
      <div className="cw-body-sm cw-font-bold">
        History isn’t available or can’t be shown for this event.
      </div>
      <div className="cw-caption">
        When an event history is available, event modifications will display here.
      </div>
    </div>
  </div>
);
