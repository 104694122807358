import { SchedulingOptionCategoryTypeEnum } from "@clockwise/schema";
import {
  CalendarDensity,
  CalendarPositioner,
} from "@clockwise/web-commons/src/components/calendar";
import { ICalPositionable } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import { DateTime, Interval } from "luxon";
import React, { useMemo } from "react";
import { SchedulingOptionsTradeoffs } from "../../chat/ai-chat/utils/types";

import { useReadLocalStorage } from "usehooks-ts";
import { useActiveProposal } from "../../chat/hooks/useActiveProposal";
import { useActiveSchedulingOptions } from "../../chat/hooks/useActiveSchedulingOptions";
import { ProposalOption } from "./ProposalOption";

export const ProposalOptionsOverlay = ({
  dateTimes,
  minWidth,
  columnCountOverride,
  positionerFullWidth,
  popoverPosition,
  widthPercent,
  includeRail,
}: {
  dateTimes: DateTime[];
  columnCountOverride?: number;
  minWidth?: number;
  positionerFullWidth?: boolean;
  popoverPosition?: "left" | "right" | "bottom";
  widthPercent?: number;
  includeRail: boolean;
}) => {
  const schedulingOptions = useActiveSchedulingOptions();

  if (schedulingOptions.length === 0) {
    return null;
  } else {
    return (
      <div className="cw-w-full cw-h-full">
        <div className="cw-absolute cw-top-0 cw-right-0 cw-bottom-0 cw-left-0 cw-pointer-events-none" />
        <PositionedCards
          options={schedulingOptions}
          dateTimes={dateTimes}
          minWidth={minWidth}
          columnCountOverride={columnCountOverride}
          positionerFullWidth={positionerFullWidth}
          popoverPosition={popoverPosition}
          widthPercent={widthPercent}
          includeRail={includeRail}
        />
      </div>
    );
  }
};

type Option = {
  id: string;
  label: string;
  interval: string;
  category: SchedulingOptionCategoryTypeEnum | null;
  tradeoffBlocks: SchedulingOptionsTradeoffs;
};

const PositionedCards = ({
  options,
  dateTimes,
  minWidth,
  columnCountOverride,
  positionerFullWidth,
  popoverPosition,
  widthPercent,
  includeRail,
}: {
  options: Option[];
  dateTimes: DateTime[];
  columnCountOverride?: number;
  minWidth?: number;
  positionerFullWidth?: boolean;
  popoverPosition?: "left" | "right" | "bottom";
  widthPercent?: number;
  includeRail: boolean;
}) => {
  const { totalAttendeeCount, proposalOptionsIndex } = useActiveProposal();
  const calendarDensity = useReadLocalStorage<CalendarDensity>("calendarDensity");

  const positionables: ICalPositionable[] = useMemo(
    () =>
      options.map((option, index) => {
        const interval = Interval.fromISO(option.interval);

        const getSpacing = () => {
          const lessThan15 = interval.toDuration().as("minutes") <= 15;
          if (lessThan15) {
            switch (calendarDensity) {
              case "compact":
                return "none";
              default:
                return "small";
            }
          }
          return "full";
        };

        return {
          interval,
          key: option.id,
          rail: includeRail,
          render: ({ position }) => (
            <ProposalOption
              label={option.label}
              popoverSide={
                popoverPosition ? popoverPosition : position.left < 60 ? "right" : "left"
              } // Open on the left for Thursday and Friday, because of space constraints
              tradeoffBlocks={option.tradeoffBlocks}
              totalAttendeeCount={totalAttendeeCount}
              index={index}
              interval={option.interval}
              category={option.category}
              spacing={getSpacing()}
              widthPercent={widthPercent ?? 100}
              selectedIndex={proposalOptionsIndex}
              rail={includeRail}
            />
          ),
        };
      }),
    [
      options,
      includeRail,
      popoverPosition,
      widthPercent,
      proposalOptionsIndex,
      totalAttendeeCount,
      calendarDensity,
    ],
  );
  return (
    <CalendarPositioner
      positionables={positionables}
      dateTimes={dateTimes}
      minWidth={minWidth}
      columnCountOverride={columnCountOverride}
      fullWidth={positionerFullWidth}
    />
  );
};
