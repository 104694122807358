// schema
import * as ISchema from "#webapp/src/__schema__";

// util
import {
  EXPERIMENT_FLAGS,
  ExperimentNameType,
  isOn as isExperimentOn,
} from "#webapp/src/util/experiment.util";

export const getExperiments = (org: ISchema.IOrg) => {
  const localExperiments = Object.values(EXPERIMENT_FLAGS) as ExperimentNameType[];
  const allExperiments = [
    ...new Set(localExperiments.concat(org.experiments as ExperimentNameType[])),
  ];
  return allExperiments.map((flag) => {
    const isOn = isExperimentOn(flag);
    return {
      flag,
      isOn,
    };
  });
};
