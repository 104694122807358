import { Checkbox } from "@clockwise/design-system";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { getFullName } from "@clockwise/web-commons/src/util/profile.util";
import classNames from "classnames";
import React from "react";
import { Pill } from "./Pill";
import { InviteSearchPerson } from "./types";

export const InviteSearchResultRow = ({
  searchPerson,
  onCheck,
  isChecked,
}: {
  searchPerson: InviteSearchPerson;
  onCheck: (selectedPerson: InviteSearchPerson, checked: boolean) => void;
  isChecked: boolean;
}) => {
  const { profile, primaryCalendarId, suggested, isUser, pending } = searchPerson;
  const fullName = getFullName(profile);
  const isSuggested = Boolean(suggested);
  const isPending = Boolean(pending);

  const disabled = isPending || isUser;

  const onChange = (checked: boolean) => {
    if (!disabled) {
      onCheck(searchPerson, checked);
    }
  };

  return (
    <div
      className={classNames(
        "cw-group cw-p-2 cw-items-center cw-flex cw-w-full hover:cw-bg-default-hover cw-rounded-md",
        {
          "cw-cursor-pointer": !disabled,
          "cw-pointer-events-none": disabled,
        },
      )}
      onClick={() => onChange(!isChecked)}
    >
      <AttendeeAvatar profile={profile} size="xlarge" />
      <div className="cw-flex cw-flex-col cw-flex-1 cw-ml-3 cw-w-full cw-text-ellipsis cw-overflow-hidden cw-whitespace-nowrap">
        <div className="md:cw-heading-base cw-heading-sm cw-text-ellipsis cw-overflow-hidden cw-whitespace-nowrap">
          {fullName}
        </div>
        <div className="md:cw-text-sm cw-text-xs cw-text-muted cw-text-ellipsis cw-overflow-hidden cw-whitespace-nowrap">
          {primaryCalendarId}
        </div>
      </div>
      {isSuggested && <Pill>Suggested</Pill>}
      {isUser && <Pill>Joined</Pill>}
      {isPending && <Pill>Invited</Pill>}
      <div className="cw-flex cw-items-center cw-gap-3 cw-mr-2">
        <Checkbox checked={isChecked || disabled} disabled={disabled} />
      </div>
    </div>
  );
};
