import { ArrowDropDown, ArrowDropUp } from "@clockwise/design-system/icons";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import classNames from "classnames";
import moment from "moment";
import React, { FocusEvent } from "react";
import { DATE_FORMAT_DISPLAY } from "../consts";
import { DateTimeString } from "../setup-scheduling-link";
import { useSetupLinkStyles } from "../setup-scheduling-link.styles";

const manualInputFormat = "MM/DD/YYYY";

type SetupLinkDatePickerProps = Omit<KeyboardDatePickerProps, "onChange"> & {
  onChange: (date: DateTimeString) => void;
};

const SetupLinkDatePicker = ({ ...props }: SetupLinkDatePickerProps) => {
  const classes = useSetupLinkStyles();
  const [isOpen, setIsOpen] = React.useState(false); //               Required to update icon, up or down arrow
  const [isManualEntry, setIsManualEntry] = React.useState(false); // Required to udpate change date input format for manual date entry

  const handleManualEntryBegin = () => {
    setIsManualEntry(true);
  };

  const handleManualEntryEnd = (event: FocusEvent<HTMLInputElement>) => {
    setIsManualEntry(false);
    handleChange(moment(event.target.value, manualInputFormat));
  };

  const handleChange = (date: MaterialUiPickersDate) => {
    if (date?.isValid()) {
      props?.onChange(date.toISOString());
    }
  };

  const displayDateFormat = isManualEntry ? manualInputFormat : DATE_FORMAT_DISPLAY;
  const displayValue = isManualEntry
    ? moment(props.value ?? undefined).format(manualInputFormat)
    : props.value;

  // Dev Note:
  // I highly recommend that we move off this hacked solution as soon as possible
  // -> MUI v4 Date Range Picker => https://next.material-ui-pickers.dev/demo/daterangepicker (July 2022: still alpha / not stable)
  // -> MUI v5 Date Range Picker => https://mui.com/x/react-date-pickers/date-range-picker/#main-content

  // TODO (lsanwick) Figure out how to convert this to a design system select component.
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        allowKeyboardControl={false}
        className={classNames(
          classes.datePicker,
          "cw-w-[120px]",
          "cw-m-0",
          "focus-within:cw-bg-[#000000]/5",
        )}
        disablePast
        disableToolbar
        format={displayDateFormat}
        InputProps={{
          className: classes.datePickerInput,
          onBlur: handleManualEntryEnd,
          onFocus: handleManualEntryBegin,
          disableUnderline: true,
        }}
        inputVariant="filled"
        keyboardIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        mask=" __/__/____"
        maskChar="_"
        PopoverProps={{
          disableScrollLock: true,
        }}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        open={isOpen}
        variant="inline"
        {...props}
        onChange={handleChange}
        value={displayValue}
      />
    </MuiPickersUtilsProvider>
  );
};

export default SetupLinkDatePicker;
