import { pulsingBlackOverlay } from "@clockwise/web-commons/src/components/animations/pulsingBlackOverlay";
import classNames from "classnames";
import React from "react";
import { useReadCalendarColorForCalendarId } from "../hooks/CalendarColorsContext";

export const ProposalSlot = ({
  calendarId,
  widthPercent,
  fade,
}: {
  calendarId?: string;
  widthPercent: number;
  fade: boolean;
}) => {
  const calendarColorSet = useReadCalendarColorForCalendarId(calendarId, true);

  return (
    <div cw-id="proposal-slot" className="cw-h-full cw-w-full cw-flex cw-relative">
      <div
        className={classNames("cw-h-full cw-border cw-border-solid cw-rounded cw-min-h-[10px]", {
          "cw-opacity-25": fade,
        })}
        style={{
          borderColor: calendarColorSet.border,
          width: `${widthPercent}%`,
        }}
      >
        <div className={classNames("cw-w-full cw-h-full", { [pulsingBlackOverlay]: !fade })} />
      </div>
    </div>
  );
};
